import React from 'react';
import BounceLoader from './BounceLoader';

const BounceLoaderContainer = ({ isLoading, children, ...props }) => {
  if (isLoading) {
    return <BounceLoader {...props} />;
  }
  return <>{children}</>;
};

export default BounceLoaderContainer;
