import AnnouncementsIcon from '../Icons/AnnouncementsIcon';
import StoreMenuDropdownIcon from '../Icons/StoreMenuDropdownIcon';
import ViewNotificationsIcon from '../Icons/ViewNotificationsIcon';
import ViewReportsIcon from '../Icons/ViewReportsIcon';
import AnnoucementsMenu from '../Layout/AnnoucementsMenu';
import StoreMenu from '../Layout/StoreMenu';
import ViewNotificationsMenu from '../Layout/ViewNotificationsMenu';
import ViewReportsMenu from '../Layout/ViewReportsMenu';
import { getOptionsNameMap } from '../utils';

export const DURATION_FILTER_OPTIONS = [
  {
    value: 0,
    label: 'Lifetime',
  },
  {
    value: 1,
    label: 'Today',
  },
  {
    value: 2,
    label: 'Yesterday',
  },
  {
    value: 3,
    label: 'This Week',
  },
  {
    value: 4,
    label: 'This Month',
  },
  {
    value: 5,
    label: 'Last Month',
  },
];

export const DURATION_FILTER_OPTIONS_MAP = getOptionsNameMap(
  DURATION_FILTER_OPTIONS
);

export const KYC_MESSAGE =
  'I want to do my KYC in order to get all pending payments. Can you please let me know the next steps?';

export const NOTIFICATIONS_TYPE_KEY = 'notifications';
export const DOWNLOAD_TYPE_KEY = 'downloads';

export const MENU_LAYOUTS = {
  DRAWER: 'drawer',
  POPUP: 'popup',
};

export const APP_BAR_MENU_ITEMS = [
  {
    tooltipTitle: 'Alerts',
    Icon: ViewNotificationsIcon,
    Component: ViewNotificationsMenu,
    typeKey: NOTIFICATIONS_TYPE_KEY,
  },
  {
    tooltipTitle: 'Reports',
    Icon: ViewReportsIcon,
    Component: ViewReportsMenu,
    typeKey: DOWNLOAD_TYPE_KEY,
    layout: MENU_LAYOUTS.DRAWER,
  },
  // {
  //   tooltipTitle: 'Announcements',
  //   Icon: AnnouncementsIcon,
  //   Component: AnnoucementsMenu,
  //   typeKey: 'announcements',
  // },
  {
    tooltipTitle: 'More',
    Icon: StoreMenuDropdownIcon,
    Component: StoreMenu,
    typeKey: 'store',
    layout: MENU_LAYOUTS.POPUP,
  },
];

export const SALES_BREAKDOWN_KEYS = {
  TOTAL_SALES: 'totalSales',
  GROSS_SALES: 'grossSales',
  RETURNS: 'returns',
  CANCELLATIONS: 'cancellations',
};
