import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from '../constants';

const useResponsive = () => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });
  const isTablet = useMediaQuery({ maxWidth: TABLET_MAX_WIDTH });

  return {
    isMobile,
    isTablet,
  };
};

export default useResponsive;
