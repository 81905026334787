import React, { createRef } from 'react';
import cx from 'classnames';
import { UploadIcon } from '../SvgIcon';
import {
  filterFilesByMimeType,
  filterFilesBySize,
  getCdnUrl,
} from '../utils/file';
import useModal from '../hooks/useModal';
import UploadVideoModal from './UploadVideoModal';
import { noop } from '../utils';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { ACCEPTED_IMAGE_SIZE_MB, ACCEPTED_IMAGE_TYPES } from '../constants';

const UploadImage = (props) => {
  const {
    imgUrl = '',
    accept = 'image/*',
    disabled = false,
    multiple = false,
    uploadFile = noop,
    uploadCssClass,
    className = '',
    Icon = UploadIcon,
    imgText = '',
    fileSizeLimit = 100000000, // 100 MB
    isVideoAllowed = false,
    isAttribute = false,
    isSmallIcon = false,
    updateAttributeImage = false,
    setUploadingFiles = noop,
    iconHeight = 50,
    iconWidth = 50,
    textClass = '',
    iconClass = '',
  } = props;
  const fileUploadRef = createRef();
  const {
    isOpen: isAddVideoModalOpen,
    openModal: openAddVideoModal,
    closeModal: closeAddVideoModal,
  } = useModal();
  const { enqueueSnackbar } = useCustomSnackbar();

  let cssClass = 'file-image-upload';

  if (uploadCssClass) {
    cssClass = uploadCssClass;
  }

  const handleButtonClick = () => {
    fileUploadRef.current.click();
  };

  const handleFileChange = () => {
    const filteredFilesByMimeType = filterFilesByMimeType(
      fileUploadRef.current.files,
      accept
    );
    const filteredFilesBySize = filterFilesBySize(
      filteredFilesByMimeType,
      fileSizeLimit
    );

    if (filteredFilesByMimeType.length === 0) {
      enqueueSnackbar(`Please upload a valid image file!`, 'error');
    } else if (filteredFilesBySize.length === 0) {
      enqueueSnackbar(
        `File size exceeds ${ACCEPTED_IMAGE_SIZE_MB}. Please choose a smaller file and try again.`,
        'error'
      );
    }
    setUploadingFiles(Array.from(fileUploadRef.current.files));
    uploadFile(filteredFilesBySize);
    fileUploadRef.current.value = '';
  };

  const inputProps = {
    type: 'file',
    onChange: handleFileChange,
    disabled,
    accept,
    multiple,
  };

  const classes = cx(
    cssClass,
    {
      disabled,
      'has-image': !!imgUrl,
    },
    {
      'attribute-image': isAttribute,
    },
    {
      'update-attribute-image': updateAttributeImage,
    },
    className
  );

  const setWidth = () => {
    if (isSmallIcon) {
      return 36;
    }
    if (isAttribute || updateAttributeImage) {
      return 40;
    }
    return iconWidth;
  };

  const setHeight = () => {
    if (isSmallIcon) {
      return 32;
    }
    if (isAttribute || updateAttributeImage) {
      return 40;
    }
    return iconHeight;
  };

  return (
    <>
      <div className={classes} onClick={handleButtonClick}>
        <input
          {...inputProps}
          ref={fileUploadRef}
          accept={accept ?? ACCEPTED_IMAGE_TYPES}
        />
        <div className="d-flex flex-column align-center justify-center">
          {imgUrl ? (
            <img src={getCdnUrl(imgUrl)} alt="" />
          ) : (
            <Icon
              width={setWidth()}
              height={setHeight()}
              className={cx(
                { 'px4 py8': !isSmallIcon && !iconClass },
                iconClass
              )}
            />
          )}
          {imgText && (
            <p className="section-text-13 mt28 c-black-3">{imgText}</p>
          )}
          {isVideoAllowed && (
            <div
              className={cx(
                'text-10 text-medium underline cur-p c-black-3 hover-add-video',
                { pt18: !isSmallIcon && !textClass },
                textClass
              )}
              onClick={(event) => {
                event.stopPropagation();
                openAddVideoModal();
              }}
            >
              or add video
            </div>
          )}
        </div>
      </div>
      {isAddVideoModalOpen && (
        <UploadVideoModal
          open={isAddVideoModalOpen}
          close={closeAddVideoModal}
          uploadFile={uploadFile}
        />
      )}
    </>
  );
};

export default UploadImage;
