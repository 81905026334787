import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import io from 'socket.io-client';
import { useAppContext } from './AppContext';
import { SOCKET_URL } from '../constants';
import useNewOrderSocket from '../hooks/useNewOrderSocket';
import { noop } from '../utils';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

const SocketContext = createContext({});
export const JOIN_ROOM_V1 = 'join-room';
export const JOIN_ROOM_V2 = 'join-room-v2';

export const connectSocket = (
  roomId,
  joinRoomEventName = JOIN_ROOM_V1,
  socketCb = noop
) => {
  const socket = io.connect(SOCKET_URL);
  socket.on('connect', function () {
    socket.emit(joinRoomEventName, roomId, socketCb);
  });
  return socket;
};

const SocketProvider = ({ children }) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const [socket, setSocket] = useState(null);
  const { business, authToken, isVendor } = useAppContext();
  const businessId = business.id;
  const { onNewOrder, onAcceptOrder } = useNewOrderSocket();

  const on = useCallback(
    (event, callback) => {
      if (socket) socket.on(event, callback);
    },
    [socket]
  );

  const off = useCallback(
    (event, callback) => {
      if (socket) socket.off(event, callback);
    },
    [socket]
  );

  useEffect(() => {
    if (!isVendor && businessId) {
      const sock = connectSocket(authToken);

      setSocket(sock);
      sock.on('new_order', onNewOrder);
      sock.on('accept_order', onAcceptOrder);
      sock.on('coupon_automatic', ({ data: { message = '' } = {} }) => {
        if (message) enqueueSnackbar(message);
      });
      return () => sock.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  const contextValue = {
    on,
    off,
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
export default SocketProvider;
