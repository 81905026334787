import React from 'react';
import { useAppContext } from '../context/AppContext';
import Modal from '../shared/Modal';
import { DomainExpiryIcon } from '../SvgIcon';
import { APP_DOMAIN } from '../constants';
import { useSubscription } from '../context/SubscriptionProvider';

const CustomDomainExpiryModal = ({ open, closeModal }) => {
  const { business, primaryDomainDetails } = useAppContext();
  const { postContinueDeafultDomain } = useSubscription();

  return (
    <Modal open={open} closeModal={closeModal}>
      <div className="d-flex-c-c flex-column">
        <div className="mt8">
          <DomainExpiryIcon />
        </div>
        <div className="section-text-4 mt12">Custom domain expired!</div>
        <div className="text-2 c-black-3 mt6 text-center">
          Your{' '}
          <span className="text-medium">
            {primaryDomainDetails?.domain || ''}
          </span>{' '}
          is expired and has been changed to a Dukaan managed domain.
        </div>
        <div
          className="underline c-black-3 section-text-5 mt16 cur-p"
          onClick={postContinueDeafultDomain}
        >
          Continue with {`${APP_DOMAIN}${business.link}`}
        </div>
      </div>
    </Modal>
  );
};

export default CustomDomainExpiryModal;
