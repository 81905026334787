// Attributes constants

import { FIELD_TYPE } from '../../../Orders/CreateOrder/AdditionalInformationCard/FormFieldHelper';
import {
  ActiveDateFieldTypeIcon,
  ActiveImageFieldTypeIcon,
  ActiveListFieldTypeIcon,
  ActiveMultilineTextFieldTypeIcon,
  ActiveRadioFieldTypeIcon,
  ActiveTextFieldTypeIcon,
  DateFieldTypeIcon,
  ImageFieldTypeIcon,
  ListFieldTypeIcon,
  MultilineTextFieldTypeIcon,
  RadioFieldTypeIcon,
  TextFieldTypeIcon,
} from '../../../SvgIcon';

export const MAX_META_FIELD_LIMIT = 200;

export const ATTRIBUTE_FIELD_TYPE_MAP = {
  SINGLE_LINE_TEXT: 1,
  MULTI_LINE_TEXT: 3,
  RADIO: 4,
  FILE: 5,
  DATE: 6,
  LIST: 7,
  PRODUCT_PICKER: 8,
  CATEGORY_PICKER: 9,
};

export const NORMAL_STORE_ALLOWED_ATTRUIBUTE_TYPES = [
  ATTRIBUTE_FIELD_TYPE_MAP.RADIO,
  ATTRIBUTE_FIELD_TYPE_MAP.LIST,
];

export const ATTRIBUTE_FIELD_TYPES = {
  [ATTRIBUTE_FIELD_TYPE_MAP.SINGLE_LINE_TEXT]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
    },
    icon: TextFieldTypeIcon,
    activeIcon: ActiveTextFieldTypeIcon,
    field_type: FIELD_TYPE.TEXT,
    field_class: 'col-md-12',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.MULTI_LINE_TEXT]: {
    field_type: FIELD_TYPE.TEXTAREA,
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
    },
    icon: MultilineTextFieldTypeIcon,
    activeIcon: ActiveMultilineTextFieldTypeIcon,
    field_class: 'col-md-12',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.RADIO]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
      show_in_filter: true,
    },
    icon: RadioFieldTypeIcon,
    activeIcon: ActiveRadioFieldTypeIcon,
    field_type: FIELD_TYPE.RADIO,
    field_class: 'col-md-6',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.LIST]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
      show_in_filter: true,
    },
    icon: ListFieldTypeIcon,
    activeIcon: ActiveListFieldTypeIcon,
    field_type: FIELD_TYPE.LIST,
    field_class: 'col-md-6',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.FILE]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
    },
    icon: ImageFieldTypeIcon,
    activeIcon: ActiveImageFieldTypeIcon,
    field_type: FIELD_TYPE.FILE_PICKER,
    field_class: 'col-md-6',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.DATE]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
    },
    icon: DateFieldTypeIcon,
    activeIcon: ActiveDateFieldTypeIcon,
    field_type: FIELD_TYPE.DATE_PICKER,
    field_class: 'col-md-6',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.PRODUCT_PICKER]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
      show_in_filter: true,
    },
    icon: ListFieldTypeIcon,
    activeIcon: ActiveListFieldTypeIcon,
    field_type: FIELD_TYPE.PRODUCT_PICKER,
    field_class: 'col-md-6',
  },
  [ATTRIBUTE_FIELD_TYPE_MAP.CATEGORY_PICKER]: {
    default_rules: {
      show_in_product_spec: true,
      show_in_store_front_api: true,
      show_in_filter: true,
    },
    icon: ListFieldTypeIcon,
    activeIcon: ActiveListFieldTypeIcon,
    field_type: FIELD_TYPE.CATEGORY_PICKER,
    field_class: 'col-md-6',
  },
};

export const REORDER_ATTRIBUTIES_MAP = {
  layoutTitle: 'Reorder attributes',
  btnText: 'Save',
  backBtnText: 'Discard',
};

export const CREATE_ATTRIBUTIES_MAP = {
  layoutTitle: 'Create attribute',
  btnText: 'Save',
  backBtnText: 'Discard',
};

export const EDIT_ATTRIBUTIES_MAP = {
  layoutTitle: 'Edit attribute',
  btnText: 'Update',
  backBtnText: 'Discard',
};

export const FIELD_TYPES_TO_SELECT = {
  SINGLE_LINE_TEXT_FIELD: 'Single line text field',
  MULTI_LINE_TEXT_FIELD: 'Multi line text field',
  RADIO: 'Radio (Single-select)',
  FILE: 'File',
  DATE: 'Date',
  LIST: 'Checkbox (Multi-select)',
  PRODUCT_PICKER: 'Product Picker',
  CATEGORY_PICKER: 'Category Picker',
};

export const TEXT_FILE_EXTENSIONS = [
  '.doc',
  '.odt',
  '.pdf',
  '.rtf',
  '.tex',
  '.txt',
  '.wpd',
  '.csv',
];

export const OTHER_FILE_EXTENSIONS = [
  '.xlsx',
  '.xlsm',
  '.xlsb',
  '.xltx',
  '.xltm',
  '.xls',
  '.xlt',
  '.xml',
  '.xlam',
  '.xla',
  '.xlw',
  '.xlr',
  '.zip',
];

export const VIDEO_FILE_EXTENSION = [
  '.mov',
  '.mp4',
  '.mov',
  '.3g2',
  '.3gp',
  '.avi',
  '.flv',
  '.h264',
  '.m4v',
  '.mkv',
  '.mpg',
  '.mpeg',
  '.rm',
  '.swf',
  '.vod',
  '.wmv',
];
