import React, { createRef, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import {
  EVENT_CAT_UPLOAD_IMAGE,
  EVENT_SUB_CAT_UPLOAD_IMAGE,
} from '../../events';
import FormImageUpload from '../../shared/Form/FormImageUpload';
import { FormInput, Validation } from '../../shared/Form';
import ImageLoader from '../../shared/ImageLoader';
import ImageOverlayView from '../../shared/ImageOverlayView';
import UploadImage from '../../shared/UploadImage';
import { TrackEvent } from '../../utils/analytics';
import { UploadedImage } from '../../shared';
import { PlusIconGray, UploadCameraIcon } from '../../SvgIcon';
import FormCheckbox from '../../shared/Form/FormCheckbox';
import { noop } from '../../utils';
import CustomAccordion from '../../shared/CustomAccordian';
import ChangeLanguageDropdown from '../../Account/Sections/Languages/ChangeLanguageDropdown';
import useLanguages from '../../hooks/useLanguages';
import useScrollingRef from '../../hooks/useScrollingRef';
import CategoryModal from '../../shared/CategoryModalNew';
import useModal from '../../hooks/useModal';
import ToggleButtonGroup from '../../shared/ToggleButtonGroup';
import { CATEGORY_BANNER_SCREEN_SIZES as options } from './constants';
import Nudge from '../../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_SIZES,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';
import { ACCEPTED_IMAGE_AND_VIDEO_TYPES } from '../../constants';

export const useStyles = makeStyles(() =>
  createStyles({
    categoryUploadBanner: {
      border: '1px dashed #D9D9D9',
      borderRadius: 6,
      width: '100%',
      height: 130,
      display: 'block',
      position: 'relative',
      '&.has-image': {
        borderStyle: 'solid',
        '&:hover .banner-overlay': {
          display: 'flex',
        },
      },
    },
    uploadBanner: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
    banner: {
      maxHeight: 128,
      width: '100%',
      objectFit: 'contain',
      borderRadius: '6px',
    },
    bannerOverlay: {
      display: 'none',
      position: 'absolute',
      borderRadius: 6,
      zIndex: 1,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 80%)',
    },
    deleteBtn: {
      cursor: 'pointer',
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 2,
    },
    removeBanner: {
      borderRadius: 4,
      border: '1px solid white',
    },
  })
);

const CategoryInformation = (props) => {
  const styles = useStyles();
  const history = useHistory();

  const {
    addAsSubcategory = false,
    bannerProps: {
      bannerImage = '',
      setBannerImage = noop,
      bannerImageMobile = '',
      setBannerImageMobile = noop,
      bannerUploading = false,
      bannerUpload = noop,
    },
    categoryContext,
    categoryDetails = {},
    categoryUuid = '',
    editAsSubcategory = false,
    form,
    imageProps: {
      image = '',
      isUploading = noop,
      setImage = noop,
      localFile,
      percentage,
      uploading,
      upload,
    },
    isNewCategory = false,
    isNewSubcategory = false,
    isSubcategory,
    parentCategoryObject = {},
    setParentCategoryId = noop,
    setParentCategoryObject = noop,
    refetch = noop,
    setAddAsSubcategory = noop,
    showAddAsSubcategoryCheckbox = false,
    showEditAsSubcategoryCheckbox = false,
    setEditAsSubcategory,
    values = {},
    topLevelFormDescription = '',
    setTopLevelFormDescription = noop,
    languageData = [],
    setLanguageData = noop,
  } = props;

  const { business } = useAppContext();
  const {
    languages,
    fetchStoreLanguages,
    loading,
    hasMore,
    pageNumber,
    setPageNumber,
  } = useLanguages();

  const loadingElement = useScrollingRef(loading, hasMore, setPageNumber);

  const {
    isOpen: isCategoryModalOpen,
    openModal: openCategoryModal,
    closeModal: closeCategoryModal,
  } = useModal();

  const showImage = !image?.includes('category-def.jpg');

  const fileUploadRef = createRef();

  const [openBannerAccord, setOpenBannerAccord] = useState(false);
  const [currentCategoryTab, setCurrentCategoryTab] = useState(null);

  const isMobile = options[1].value === currentCategoryTab;

  const onUpdateClick = async (imgFile) => {
    if (addAsSubcategory) TrackEvent(EVENT_SUB_CAT_UPLOAD_IMAGE, business);
    TrackEvent(EVENT_CAT_UPLOAD_IMAGE, business);
    isUploading(true);
    const { cdnURL } = await upload(imgFile[0], () => {
      isUploading(false);
    });
    setImage(cdnURL);
  };
  const removeBannerImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    fileUploadRef.current.value = '';
    if (isMobile) {
      setBannerImageMobile('');
      form.mutators.modifyFormField('banner_images_mobile', []);
    } else {
      setBannerImage('');
      form.mutators.modifyFormField('banner_images', []);
    }
    setOpenBannerAccord(true);
  };

  const onTabChange = (optionValue) => {
    setCurrentCategoryTab(optionValue);
  };

  useEffect(() => {
    fetchStoreLanguages();
  }, [pageNumber]);

  return (
    <>
      <div>
        <div className="category-image-section card" id="category-information">
          <div className="d-flex space-between">
            <h4 className="section-text-5 mb24">Information</h4>
            {!isSubcategory && languages.length ? (
              <div>
                <ChangeLanguageDropdown
                  values={values}
                  form={form}
                  languages={languages}
                  isCategory
                  isNewCategory={isNewCategory}
                  categoryUuid={categoryUuid}
                  refetchCategoryDetails={refetch}
                  loadingElement={loadingElement}
                  loading={loading}
                  hasMore={hasMore}
                  topLevelFormDescription={topLevelFormDescription}
                  setTopLevelFormDescription={setTopLevelFormDescription}
                  languageData={languageData}
                  setLanguageData={setLanguageData}
                />
              </div>
            ) : null}
          </div>
          <p>
            {addAsSubcategory || editAsSubcategory ? 'Subcategory' : 'Category'}{' '}
            Image
          </p>
          <div className="category-image-wrap d-flex mt8">
            {image && showImage && (
              <UploadedImage
                imgUrl={image}
                onRemoveClick={() => setImage(null)}
                height={120}
                width={120}
                breathingClass="br4"
              />
            )}
            {(!image || !showImage) && !uploading && (
              <UploadImage
                disabled={uploading}
                uploadFile={onUpdateClick}
                Icon={UploadCameraIcon}
              />
            )}
            {uploading && (
              <ImageOverlayView
                percentage={percentage}
                src={localFile || image}
                large
              />
            )}
          </div>
          <div className="category-information-wrapper mt24">
            <div className="category-name-wrapper mr24">
              <FormInput
                labelText={`${
                  addAsSubcategory || editAsSubcategory
                    ? 'Subcategory'
                    : 'Category'
                } Name`}
                name="name"
                required
                showRequired
                autoFocus={isNewCategory || isNewSubcategory}
                placeholder={`Enter ${
                  addAsSubcategory || editAsSubcategory
                    ? 'subcategory'
                    : 'category'
                } name`}
                showCharLimit
                maxLength={50}
                validate={Validation.required()}
                className="mb0"
              />
            </div>
            {(addAsSubcategory || editAsSubcategory) && (
              <div className="parent-category-wrapper">
                <FormInput
                  className="mb0"
                  labelText="Parent Category"
                  name="parent_category"
                  onClick={openCategoryModal}
                  placeholder="Select category"
                  readOnly
                  required
                  showRequired
                  validate={Validation.required()}
                />
              </div>
            )}
          </div>
          {showAddAsSubcategoryCheckbox && (
            <div className="mt24">
              <FormCheckbox
                label="Add as subcategory"
                name="addAsSubcategory"
                required
                value={addAsSubcategory}
                checked={addAsSubcategory}
                onChange={() => setAddAsSubcategory((prev) => !prev)}
                className="mb12"
              />
            </div>
          )}
          {showEditAsSubcategoryCheckbox && (
            <div className="mt24">
              <FormCheckbox
                label="Edit as subcategory"
                name="editAsSubcategory"
                required
                value={editAsSubcategory}
                checked={editAsSubcategory}
                onChange={() => setEditAsSubcategory((prev) => !prev)}
                className="mb12"
              />
            </div>
          )}
        </div>
        <CustomAccordion
          defaultExpanded={bannerImage || bannerImageMobile || openBannerAccord}
          className="new-cat-form"
          heading={
            <div>
              <h4 className="section-text-5 mb4">Banner</h4>
              <p className="c-black-3">
                Add a{' '}
                {addAsSubcategory || editAsSubcategory
                  ? 'subcategory'
                  : 'category'}{' '}
                banner at the top of product listing.
              </p>
            </div>
          }
          id="category-banner"
          details={
            <div className="w-100">
              <ToggleButtonGroup
                options={options}
                defaultSelected={options[0].value}
                onChange={onTabChange}
                className="mb24"
                maintainToggleState
              />
              <FormImageUpload
                form={form}
                accept={ACCEPTED_IMAGE_AND_VIDEO_TYPES}
                hasCropper={false}
                hideInput
                onUploadSuccess={(imgUrl) => {
                  if (isMobile) setBannerImageMobile(imgUrl);
                  else setBannerImage(imgUrl);
                }}
                name={isMobile ? 'banner_images_mobile' : 'banner_images'}
                ref={fileUploadRef}
                upload={bannerUpload}
                uploading={bannerUploading}
              >
                {isMobile ? (
                  <div
                    className={`${styles.categoryUploadBanner} ${
                      bannerImageMobile ? 'has-image' : ''
                    }`}
                    onClick={() => fileUploadRef.current.click()}
                  >
                    {bannerUploading ? (
                      <ContentLoader speed={2} width="710" height="128">
                        <rect
                          x={0}
                          y={0}
                          rx={4}
                          ry={4}
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    ) : (
                      <>
                        {bannerImageMobile ? (
                          <>
                            <ImageLoader
                              src={bannerImageMobile}
                              width={710}
                              height={236}
                              className={styles.banner}
                            />
                            <div
                              className={`banner-overlay ${styles.bannerOverlay}`}
                            >
                              <div className="d-flex-c-c flex-column full-w">
                                <div
                                  className={`section-text-8 text-white px16 py6 cur-p ${styles.removeBanner}`}
                                  onClick={removeBannerImage}
                                >
                                  Remove this banner
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className={styles.uploadBanner}>
                            <PlusIconGray width={20} height={20} />
                            <p className="c-black-3 section-text-7 mt8 mb4">
                              Add category banner
                            </p>
                            <p className="text-10 c-gray-1">
                              Recommended size (328px*200px)
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.categoryUploadBanner} ${
                      bannerImage ? 'has-image' : ''
                    }`}
                    onClick={() => fileUploadRef.current.click()}
                  >
                    {bannerUploading ? (
                      <ContentLoader speed={2} width="710" height="128">
                        <rect
                          x={0}
                          y={0}
                          rx={4}
                          ry={4}
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    ) : (
                      <>
                        {bannerImage ? (
                          <>
                            <ImageLoader
                              src={bannerImage}
                              width={710}
                              height={236}
                              className={styles.banner}
                            />
                            <div
                              className={`banner-overlay ${styles.bannerOverlay}`}
                            >
                              <div className="d-flex-c-c flex-column full-w">
                                <div
                                  className={`section-text-8 text-white px16 py6 cur-p ${styles.removeBanner}`}
                                  onClick={removeBannerImage}
                                >
                                  Remove this banner
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className={styles.uploadBanner}>
                            <PlusIconGray width={20} height={20} />
                            <p className="c-black-3 section-text-7 mt8 mb4">
                              Add category banner
                            </p>
                            <p className="text-10 c-gray-1">
                              Recommended size (1296px*236px)
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </FormImageUpload>
              {isMobile && (
                <div className="mt24 d-none">
                  <Nudge
                    text="Add this banner for a better viewing experience on mobile."
                    // ctaLabel="Learn more"
                    size={NUDGE_SIZES.SMALL}
                    type={NUDGE_TYPES.PRIMARY}
                    variant={NUDGE_VARIANTS.INFO}
                    iconVariant={NUDGE_ICON_VARIANTS.BLUE}
                    // onClick={() =>
                    //   history.push(accountUrls.subscriptionPurchasePath)
                    // }
                  />
                </div>
              )}
            </div>
          }
        />
      </div>
      {isCategoryModalOpen && (
        <CategoryModal
          categoryContext={categoryContext}
          categoryDetails={categoryDetails}
          handleClose={({ categories, selectedCategoriesWhole }) => {
            if (parentCategoryObject.id !== selectedCategoriesWhole[0].id) {
              if (selectedCategoriesWhole?.[0]) {
                setParentCategoryId(categories);
                setParentCategoryObject(selectedCategoriesWhole[0]);
                form.mutators.modifyFormField(
                  'parent_category',
                  selectedCategoriesWhole[0].name
                );
              }
            }
            closeCategoryModal();
          }}
          heading="Select parent category"
          hideAddCategoryBtn
          initiallySelectedCategoriesWhole={[parentCategoryObject]}
          isRadioSelect
          open={isCategoryModalOpen}
          selected={[parentCategoryObject.id]}
        />
      )}
    </>
  );
};

export default CategoryInformation;
