import { Tooltip } from '@material-ui/core';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DINING_ORDER } from '../Account/constants';
import { useAppContext } from '../context/AppContext';
import { useCountry } from '../context/CountryProvider';
import { useOrdersContext } from '../context/OrdersProvider';
import { useSocket } from '../context/SocketProvider';
import { EVENT_ORDER_VIEW_DETAILS } from '../events';
import {
  LAST_MODIFIED_DATE,
  LAST_MODIFIED_DATE_UTC,
  ORDER_DATE_ON,
  ORDER_DATE_ON_UTC,
} from '../Manage/Delivery/constants';
import BounceLoader from '../shared/BounceLoader';
import TooltipTableHeader, {
  getOrderingDirection,
} from '../shared/Tables/TooltipTableHeader';
import {
  ReplacementRedIcon,
  ReturnRedIcon,
  ShipmentCancelIcon,
  TickGreenIcon,
} from '../SvgIcon';
import { orderDetailsUrl } from '../UrlHelper';
import { stickyTopStyle } from '../utils';
import { TrackEvent } from '../utils/analytics';
import { formatOrderTime } from '../utils/date';
import {
  AUTO_ACCEPTED_ORDER_TOOLTIP_TEXT,
  AUTO_REJECTED_ORDER_TOOLTIP_TEXT,
  CHANNEL_TEXT,
  DINING_PAYMENT_MODE_SHORT_MAP,
  GET_RETURN_STATUS_MAP,
  ORDER_CREATION,
  ORDER_STATUS_MAP,
  MANUAL_PAYMENT_METHOD_DRAFT_STATUS,
  PENDING_STATUS,
  ORDER_TAGS_COLUMN_PREFERENCE_KEY,
  PAYMENT_MODE_SHORT_MAP,
  PREPAID_ORDER,
  RESTAURANT_ORDER_STATUS_MAP,
  MANUAL_PAYMENT_METHOD_CHECK,
  MANUAL_PAYMENT_METHOD,
} from './constants';
import { NoOrderWithSvg } from './NoOrders';
import OrdersSelectedFilters from './OrdersSelectedFilters';
import { toTitleCase } from '../utils/string';
import OrdersFilters from './OrdersFilters';
import { useCredits } from '../context/CreditsProvider';

export const getPaymentBadge = (
  orderType,
  paymentMode,
  isManualPaymentOrder = false,
  isPrepaidOrder = false
) => {
  if (orderType === 1) {
    // dining order
    return DINING_PAYMENT_MODE_SHORT_MAP[paymentMode];
  }
  // manual order
  if (isManualPaymentOrder) return MANUAL_PAYMENT_METHOD;

  // normal order
  return PAYMENT_MODE_SHORT_MAP[isPrepaidOrder ? PREPAID_ORDER : paymentMode];
};

const OrdersList = ({
  headerOffsetRef,
  scrollPosition = 0,
  updateUrlOnPageUpdate,
  hasReadOnlyAccess,
  resetOrderParams,
  hasPagination = false,
}) => {
  const { business } = useAppContext();
  const { formatLocalMoney } = useCountry();
  const ordersContext = useOrdersContext();
  const { on } = useSocket();
  const {
    allStatsLoading,
    orders,
    ordersLoading,
    orderParams,
    updateOrderParams,
    refetch,
    orderTableColumns,
    gridColumnTemplate,
    setGridColumnTemplate,
    orderTags,
  } = ordersContext;
  const {
    filters,
    orderingDirection,
    orderDateType,
    orderingKey,
    search,
    status,
  } = orderParams;

  const [showBorder, setShowBorder] = useState(false);
  const containerRef = useRef();
  const updatedOrderParams = useRef(orderParams);

  const { isBalanceNegative } = useCredits();

  const handleOnSort = (key) => {
    if (ordersLoading) return;
    let sortingKey = key;
    if (key === ORDER_DATE_ON_UTC) sortingKey = ORDER_DATE_ON;
    if (key === LAST_MODIFIED_DATE_UTC) sortingKey = LAST_MODIFIED_DATE;

    updateOrderParams(
      {
        page: 1,
        orderingKey: sortingKey,
        orderingDirection: getOrderingDirection(
          key,
          orderingKey,
          orderingDirection
        ),
      },
      'sorting'
    );
  };

  const handleTags = (tags = []) => {
    const arr = tags.map((tag) => tag.name);
    return arr.join(', ');
  };

  const onContainerScroll = ({ currentTarget }) => {
    setShowBorder(currentTarget.scrollLeft > 0);
    const container = document.getElementById('order-container');
    const header = document.getElementById('order-header');
    const scrollbarDiv = document.getElementById('scrollbar-div');
    header.scrollLeft = container.scrollLeft;
    scrollbarDiv.scrollLeft = container.scrollLeft;
  };

  const onScrollbarDivScroll = ({ currentTarget }) => {
    setShowBorder(currentTarget.scrollLeft > 0);
    const scrollbarDiv = document.getElementById('scrollbar-div');
    const container = document.getElementById('order-container');
    const header = document.getElementById('order-header');
    header.scrollLeft = scrollbarDiv.scrollLeft;
    container.scrollLeft = scrollbarDiv.scrollLeft;
  };

  const onOrderClick = () => {
    TrackEvent(EVENT_ORDER_VIEW_DETAILS, business);
    localStorage.setItem('orders', JSON.stringify(orders));
  };

  useEffect(() => {
    on('new_order', () => {
      refetch(updatedOrderParams.current);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedColumnTemplate = [];
    orderTableColumns.forEach((item) => {
      if (
        orderTableColumns.find((i) => i.preferenceKey === item.preferenceKey)
          ?.show
      ) {
        updatedColumnTemplate.push(item.width);
      } else if (
        item.preferenceKey === ORDER_TAGS_COLUMN_PREFERENCE_KEY &&
        !orderTags?.length
      ) {
        updatedColumnTemplate.push('0px');
      } else {
        updatedColumnTemplate.push('0px');
      }
    });
    setGridColumnTemplate(updatedColumnTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTableColumns]);

  useEffect(() => {
    updatedOrderParams.current = orderParams;
    // This ref keeps the updated copy of orderParams for the new_order socket callback.
    // When the callback was registerd, it had the initial orderParams
    // which become stale after filters/ search is applied.
    // This helps to maintain users current working state.
  }, [orderParams]);

  return (
    <div
      className="card px32 scrollable-table-wrap d-flex flex-column spacer"
      id="orders-table-wrap"
    >
      <div
        style={{
          ...stickyTopStyle(headerOffsetRef?.current?.offsetHeight || 88),
        }}
        className="pt16"
      >
        <OrdersFilters
          hasReadOnlyAccess={hasReadOnlyAccess}
          updateUrlOnPageUpdate={updateUrlOnPageUpdate}
          search={search}
          availableTags={orderTags}
          resetOrderParams={resetOrderParams}
        />
        <div
          className="scrollable-table-header scrollable-orders-grid"
          style={{
            gridTemplateColumns: gridColumnTemplate.join(' '),
          }}
          id="order-header"
        >
          {orderTableColumns.map((col, index) => {
            if (
              !col.show ||
              (!orderTags.length &&
                col.preferenceKey === ORDER_TAGS_COLUMN_PREFERENCE_KEY)
            ) {
              return (
                <>
                  <div className="table-header" />
                </>
              );
            }
            return (
              <div className="table-header" key={index}>
                {col.sortable ? (
                  <TooltipTableHeader
                    handleOnSort={handleOnSort}
                    orderingKey={orderingKey}
                    orderingDirection={orderingDirection}
                    column={col}
                  />
                ) : (
                  <span className="cur-default" key={index}>
                    {col.title}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {orders.length <= 0 ? (
        <NoOrderWithSvg
          filters={filters}
          loading={allStatsLoading || ordersLoading}
          search={search}
          status={status}
        />
      ) : (
        <div>
          <div
            className="scrollable-table-container"
            id="order-container"
            onScroll={onContainerScroll}
            ref={containerRef}
          >
            {!ordersLoading &&
              orders.map((row) => {
                const isManualPaymentOrder =
                  row.type === MANUAL_PAYMENT_METHOD_CHECK.TYPE &&
                  row.payment_mode === MANUAL_PAYMENT_METHOD_CHECK.PAYMENT_MODE;
                return (
                  <Link
                    to={{
                      pathname: orderDetailsUrl(row.uuid),
                      state: {
                        orderDetails: row.return_status === null ? row : {},
                        orderParams,
                        scrollPosition,
                      },
                    }}
                    onClick={() => {
                      onOrderClick();
                    }}
                    className={cx(
                      'scrollable-table-conatiner-row scrollable-orders-grid cur-p',
                      {
                        'show-onscroll-border': showBorder,
                      }
                    )}
                    style={{
                      gridTemplateColumns: gridColumnTemplate.join(' '),
                    }}
                    key={row.id}
                  >
                    <div className="d-flex align-center">
                      {row.type === DINING_ORDER ? (
                        <span className="c-red-3 text-medium mr2">
                          {row?.store_table_data?.name}
                        </span>
                      ) : (
                        <span className="anchor-1 text-medium mr2 d-flex align-center">
                          #{row.display_order_id}
                          {typeof row?.return_status === 'number' &&
                            row?.return_status > 0 && (
                              <Tooltip
                                title={
                                  GET_RETURN_STATUS_MAP(
                                    row?.return_type || ''
                                  )?.[row?.return_status]
                                }
                                placement="top"
                                enterDelay={0}
                              >
                                {row?.return_type === 'replacement' ? (
                                  <ReplacementRedIcon
                                    className="ml4"
                                    height={16}
                                    width={16}
                                  />
                                ) : (
                                  <ReturnRedIcon
                                    className="ml4"
                                    height={16}
                                    width={16}
                                  />
                                )}
                              </Tooltip>
                            )}
                        </span>
                      )}
                      {row.is_new && <span className="new-order-tag">NEW</span>}
                      {row?.buyer_address?.auto_accepted && (
                        <Tooltip
                          title={AUTO_ACCEPTED_ORDER_TOOLTIP_TEXT}
                          placement="top"
                          enterDelay={0}
                        >
                          <TickGreenIcon
                            height={16}
                            width={16}
                            className="ml4"
                          />
                        </Tooltip>
                      )}
                      {row?.buyer_address?.auto_rejected && (
                        <Tooltip
                          title={AUTO_REJECTED_ORDER_TOOLTIP_TEXT}
                          placement="top"
                          enterDelay={0}
                        >
                          <ShipmentCancelIcon
                            height={16}
                            width={16}
                            className="ml4"
                          />
                        </Tooltip>
                      )}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.title === 'Date' && col.show
                      ) ? (
                        <span>{formatOrderTime(row[orderDateType])}</span>
                      ) : null}
                    </div>

                    <div className="d-flex flex-column">
                      {orderTableColumns.some(
                        (col) => col.title === 'Customer' && col.show
                      ) ? (
                        <span className="text-ellipsis">
                          {isBalanceNegative
                            ? '****'
                            : toTitleCase(
                                row?.buyer_address?.buyer?.name ||
                                  row?.order_meta?.buyer_address?.buyer?.name
                              )}
                        </span>
                      ) : null}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.title === 'Items' && col.show
                      ) ? (
                        <span>{row.product_count}</span>
                      ) : null}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.title === 'Payment' && col.show
                      ) ? (
                        <span
                          className={`payment-mode-static ${getPaymentBadge(
                            row.type,
                            row.payment_mode,
                            isManualPaymentOrder
                          )}`}
                        >
                          {getPaymentBadge(
                            row.type,
                            row.payment_mode,
                            isManualPaymentOrder,
                            row?.seller_marked_prepaid
                          )}
                          {/* Note: row.seller_marked_prepaid is isPrepaidOrder key */}
                        </span>
                      ) : null}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.title === 'Status' && col.show
                      ) ? (
                        <div
                          className={cx(
                            'd-flex align-center status-text-wrap',
                            isManualPaymentOrder &&
                              row.status === MANUAL_PAYMENT_METHOD_DRAFT_STATUS
                              ? ORDER_STATUS_MAP[PENDING_STATUS]
                              : (row.type === 1
                                  ? RESTAURANT_ORDER_STATUS_MAP
                                  : ORDER_STATUS_MAP)[row.status]
                          )}
                        >
                          <div
                            className={cx(
                              'status-dot h-8 w-8 min-w-unset',
                              'mr6',

                              isManualPaymentOrder &&
                                row.status ===
                                  MANUAL_PAYMENT_METHOD_DRAFT_STATUS
                                ? ORDER_STATUS_MAP[PENDING_STATUS]
                                : (row.type === 1
                                    ? RESTAURANT_ORDER_STATUS_MAP
                                    : ORDER_STATUS_MAP)[row.status]
                            )}
                          />
                          <span className="text-capitalize text-10 line-height-15">
                            {isManualPaymentOrder &&
                            row.status === MANUAL_PAYMENT_METHOD_DRAFT_STATUS
                              ? ORDER_STATUS_MAP[PENDING_STATUS]
                              : (row.type === 1
                                  ? RESTAURANT_ORDER_STATUS_MAP
                                  : ORDER_STATUS_MAP)[row.status]}
                          </span>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.title === 'Amount' && col.show
                      ) ? (
                        <span>{formatLocalMoney(row.total_cost)}</span>
                      ) : null}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.title === 'Channel' && col.show
                      )
                        ? CHANNEL_TEXT[row.channel] || '-'
                        : null}
                    </div>

                    <div className="text-ellipsis">
                      {orderTableColumns.some(
                        (col) => col.title === 'Source' && col.show
                      ) ? (
                        <>
                          {row?.channel === ORDER_CREATION.ONLINE
                            ? row?.source || 'Store'
                            : row?.source || '-'}
                        </>
                      ) : null}
                    </div>

                    <div className="word-break">
                      {orderTableColumns.some(
                        (col) => col.title === 'Tags' && col.show
                      )
                        ? handleTags(row?.tags) || '-'
                        : null}
                    </div>

                    <div>
                      {orderTableColumns.some(
                        (col) => col.key === 'utm_source' && col.show
                      )
                        ? row.utm_source
                        : null}
                    </div>
                    <div className="break-word">
                      {orderTableColumns.some(
                        (col) => col.key === 'utm_medium' && col.show
                      )
                        ? row.utm_medium
                        : null}
                    </div>
                    <div className="break-word">
                      {orderTableColumns.some(
                        (col) => col.key === 'utm_campaign' && col.show
                      )
                        ? row.utm_campaign
                        : null}
                    </div>
                    <div className="break-word">
                      {orderTableColumns.some(
                        (col) => col.key === 'utm_term' && col.show
                      )
                        ? row.utm_term
                        : null}
                    </div>
                    <div className="break-word">
                      {orderTableColumns.some(
                        (col) => col.key === 'utm_content' && col.show
                      )
                        ? row.utm_content
                        : null}
                    </div>
                    <div className="break-word">
                      {orderTableColumns.some(
                        (col) => col.key === 'utm_query' && col.show
                      )
                        ? row.utm_query
                        : null}
                    </div>
                  </Link>
                );
              })}
          </div>
          {ordersLoading && (
            <div className="d-flex-c-c my50">
              <BounceLoader />
            </div>
          )}
          <div
            className={cx(
              'scrollbar-div show-scrollbar scrollable-orders-grid',
              { 'pagination-active': hasPagination }
            )}
            style={{
              gridTemplateColumns: gridColumnTemplate.join(' '),
            }}
            id="scrollbar-div"
            onScroll={onScrollbarDivScroll}
          />
        </div>
      )}
    </div>
  );
};

export default OrdersList;
