import React, { memo, Suspense, useMemo } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import MenuSidebar from './Layout/MenuSidebar';
import SuspenseLoader from './Layout/SuspenseLoader';
import { isUserLoggedIn } from './utils/user';
import withTracker from './shared/WithTracker';
import {
  accountUrls,
  authUrls,
  manageUrls,
  pluginsUrls,
  rootUrls,
} from './Urls';
import useUtmParams from './hooks/utils/useUtmParams';
import { useAppContext } from './context/AppContext';
import { getDashboardRoutes } from './routes';
import { useSubscription } from './context/SubscriptionProvider';
import { RESELLER_ACCOUNT_TYPE, STAFF_ROLES } from './Account/constants';
import ErrorBoundary from './shared/ErrorBoundary';
import { HIDE_SIDEBAR } from './constants';

const RenderRoute = memo(
  ({
    mountComponent,
    routeProps,
    redirectRoute = '/',
    isDashboard = false,
    hideSidebar = false,
  }) => {
    useUtmParams();
    const {
      component: Component,
      extraProps = {},
      render,
      ...rest
    } = routeProps;
    const mobileVisiblePaths = [
      rootUrls.notAvailablePath,
      authUrls.forgotPasswordPath,
      accountUrls.subscriptionPurchaseMobilePath,
      accountUrls.subscriptionPurchaseMobilePlanLoginPath,
      accountUrls.staffAccountVerifySuccess,
      accountUrls.staffAccountVerifyError,
      accountUrls.staffAccountAccessRevoked,
      accountUrls.subscriptionPurchaseMobileSuccess,
      rootUrls.orderSelfShipPath,
      rootUrls.orderDeliveryCostPath,
      rootUrls.intlOrderDeliveryCostPath,
      rootUrls.orderDeliveryPartnerPath,
      rootUrls.orderDeliveryIntlPartnerPath,
      rootUrls.orderDeliveryShipmentWeightPath,
      rootUrls.orderHyperlocalPath,
      rootUrls.ordersMultipleSelectPartnersPath,
      manageUrls.editDukaanDelivery,
      manageUrls.connectIntlPartners,
      manageUrls.multiWarehouseSettingsPath,
      rootUrls.ordersMultipleShipConfirmPath,
      rootUrls.calculateShipmentPricePath,
      rootUrls.ordersTrackingPath,
      accountUrls.planDowngradePath,
      pluginsUrls.pluginSettingPath,
    ];
    // if (routeProps.isMobile) {
    //   if (!mobileVisiblePaths.includes(routeProps.path)) {
    //     return <Redirect to={rootUrls.notAvailablePath} />;
    //   }
    // } else if (routeProps.path === rootUrls.notAvailablePath) {
    //   return <Redirect to={{ pathname: rootUrls.homePath }} />;
    // }
    if (isDashboard) {
      return (
        <>
          {!hideSidebar && <MenuSidebar />}
          <ErrorBoundary innerError>
            <Suspense fallback={<SuspenseLoader className="dashboard" />}>
              <Route
                {...rest}
                render={render}
                component={
                  render
                    ? undefined
                    : withTracker((props) => {
                        if (mountComponent) {
                          return <Component {...props} {...extraProps} />;
                        }
                        return (
                          <Redirect
                            to={{
                              pathname: redirectRoute,
                              state: { from: props.location },
                            }}
                          />
                        );
                      })
                }
              />
            </Suspense>
          </ErrorBoundary>
        </>
      );
    }
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Route
          {...rest}
          component={withTracker((props) => {
            if (mountComponent) {
              return <Component {...props} {...extraProps} />;
            }
            return (
              <Redirect
                to={{
                  pathname: redirectRoute,
                  state: { from: props.location },
                }}
              />
            );
          })}
        />
      </Suspense>
    );
  }
);

RenderRoute.displayName = 'RenderRoute';

export const AuthenticatedRoute = (routeProps) => (
  <RenderRoute
    mountComponent={isUserLoggedIn()}
    routeProps={routeProps}
    redirectRoute={authUrls.loginPath}
  />
);

export const DashboardRoute = (routeProps) => {
  const { business, roleName } = useAppContext();
  const { hideSidebar } = useSubscription();
  const history = useHistory();

  const canAccessRoute = useMemo(
    () =>
      getDashboardRoutes(roleName)
        .map((route) => route.path)
        .includes(routeProps.path),
    [business]
  );

  const getHomeRouteForRole = () => {
    if (roleName === STAFF_ROLES.VENDOR) return manageUrls.dukaanDelivery;
    if (
      [
        STAFF_ROLES.STAFF,
        STAFF_ROLES.STAFF_L1,
        STAFF_ROLES.STAFF_L2,
        STAFF_ROLES.STAFF_L3,
        STAFF_ROLES.MANAGER_L1,
        STAFF_ROLES.MANAGER_L2,
      ].includes(roleName)
    ) {
      return rootUrls.ordersPath;
    }
    return '/';
  };

  return (
    <RenderRoute
      mountComponent={isUserLoggedIn() && canAccessRoute}
      routeProps={routeProps}
      redirectRoute={
        // eslint-disable-next-line no-nested-ternary
        isUserLoggedIn() && !canAccessRoute
          ? getHomeRouteForRole()
          : authUrls.loginPath
      }
      isDashboard
      hideSidebar={
        hideSidebar || HIDE_SIDEBAR.includes(history.location.pathname)
      }
    />
  );
};

export const UnAuthenticatedRoute = ({ redirectUrl, ...routeProps }) => (
  <RenderRoute
    mountComponent
    routeProps={routeProps}
    redirectRoute={redirectUrl || rootUrls.homePath}
  />
);

export const PublicRoute = (routeProps) => (
  <RenderRoute mountComponent routeProps={routeProps} />
);
