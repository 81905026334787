import React, { useEffect } from 'react';
import cx from 'classnames';
import { DATE_RANGE_OPTIONS } from './util';

const DateRangeItem = (props) => {
  const { active, value, name, range, onClick } = props;
  const classes = cx('dk__date-range-list-item', {
    active,
  });
  const handleClick = () => {
    onClick(value, range);
  };
  return (
    <div className={classes} onClick={handleClick}>
      {name}
    </div>
  );
};

const DateRangeList = (props) => {
  const {
    activeSelection,
    availableRanges,
    onDateRangeItemClick,
    maxDate,
    isPopperOpen,
  } = props;
  const options = DATE_RANGE_OPTIONS(maxDate);
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });

  return (
    <div className="dk__date-range-list">
      {options.map((option) => {
        const { value } = option;
        if (availableRanges.includes(value)) {
          return (
            <DateRangeItem
              key={value}
              {...option}
              active={activeSelection === value}
              onClick={onDateRangeItemClick}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default DateRangeList;
