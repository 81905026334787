import { useEffect, useRef } from 'react';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    if (value !== ref.current) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
};

export default usePrevious;
