import React, { forwardRef } from 'react';
import cx from 'classnames';

import { useSnackbar, SnackbarContent } from 'notistack';
import { CircleCaret, SnackbarCloseIcon } from '../SvgIcon';
import { useCountry } from '../context/CountryProvider';
import { SNACKBAR_VARIANTS } from '../utils/constant';

export const NewOrderSnackbar = forwardRef(({ totalCost, uuid }, ref) => {
  const Caret = CircleCaret;
  const { formatLocalMoney } = useCountry();
  return (
    <SnackbarContent ref={ref}>
      <a href={`/orders/${uuid}`}>
        <div className={cx('new-order-custom-snackbar')}>
          <div className="snackbar-text">
            <div className="section-text-5">New Order</div>
            <div className="text-8">
              An order of {formatLocalMoney(totalCost)} received.
            </div>
          </div>
          <Caret />
        </div>
      </a>
    </SnackbarContent>
  );
});

NewOrderSnackbar.displayName = 'NewOrderSnackbar';

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const DEFAULT_TIMEOUT = 4000;
  const TRANSITION_DURATION = { enter: 225, exit: 195 };

  const DEFAULT_ACTION = (key) => (
    <SnackbarCloseIcon
      className="cur-p"
      onClick={() => {
        closeSnackbar(key);
      }}
    />
  );

  const showSnackbar = (
    message,
    variant = SNACKBAR_VARIANTS.SUCCESS, // by default success snackbar
    config = {}
  ) => {
    // auto hide to be disabled for error snackbars (TBD)
    const autoHide =
      DEFAULT_TIMEOUT - TRANSITION_DURATION.enter - TRANSITION_DURATION.exit;

    enqueueSnackbar(message, {
      variant,
      action: DEFAULT_ACTION,
      autoHideDuration: autoHide,
      disableWindowBlurListener: true,
      transitionDuration: TRANSITION_DURATION,
      preventDuplicate: true,
      ...config,
    });
  };

  return { enqueueSnackbar: showSnackbar };
};

export default useCustomSnackbar;
