import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormInput } from '../../shared/Form';
import Validation from '../../shared/Form/Validation';
import { postRequest } from '../../utils/http';
import { ExternalLink } from '../../shared';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import Nudge from '../../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';
import { SAVE_PAYMENT_GATEWAY_CREDS } from '../../ApiUrls';
import { PAYMENT_GATEWAY_IDS } from '../Payments/constants';
import { usePayments } from '../../context/PaymentsProvider';

const CASHFREE_HELP_URL = 'https://docs.cashfree.com/docs/authentication-3';
const CASHFREE_CREATE_ACCOUNT_URL =
  'https://merchant.cashfree.com/merchants/signup';

const CashfreeSetupForm = ({
  isActivated = false,
  isPresent = false,
  successCallback = noop,
}) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { fetchPaymentGatewayCreds, paymentProviderData } = usePayments();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialCreds, setInitialCreds] = useState({
    client_id: '',
    client_secret: '',
  });

  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: SAVE_PAYMENT_GATEWAY_CREDS(
        business.uuid,
        PAYMENT_GATEWAY_IDS.CASHFREE
      ),
      data: values,
    })
      .then(() => {
        enqueueSnackbar('Cashfree Payments set up successfully!');
        successCallback();
        fetchBusinessDetails();
      })
      .catch(() => {
        enqueueSnackbar(
          'We are unable to verify your keys. Please enter correct key values.',
          'error'
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (isPresent) {
      setLoading(true);
      fetchPaymentGatewayCreds(
        paymentProviderData?.uuid,
        (data) => {
          const creds = data?.data?.payment_provider_account;
          if (creds) {
            setInitialCreds({
              client_id: creds?.client_id,
              client_secret: creds?.client_secret,
            });
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialCreds }}
      render={({ handleSubmit, invalid }) => (
        <form className="mt4">
          {!isPresent && (
            <>
              <div className="text-8 mb16">
                Please enter your Cashfree API keys. You can find your API keys
                on
                <ExternalLink
                  href={CASHFREE_HELP_URL}
                  className="text-medium c-purple-1-i ml6 underline"
                >
                  Cashfree API Key Dashboard
                </ExternalLink>
              </div>
              <Nudge
                variant={NUDGE_VARIANTS.PRIMARY}
                type={NUDGE_TYPES.PRIMARY}
                iconVariant={NUDGE_ICON_VARIANTS.BLUE}
                text={
                  <p className="c-black-3 text-8 d-flex align-center">
                    Don't have an Cashfree account?{' '}
                    <ExternalLink
                      href={CASHFREE_CREATE_ACCOUNT_URL}
                      className="text-medium c-black-3-i ml6 underline"
                    >
                      Create now
                    </ExternalLink>
                  </p>
                }
              />
            </>
          )}

          <FormInput
            labelText="App ID"
            name="client_id"
            placeholder="Enter App ID"
            className="my24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Secret Key"
            name="client_secret"
            placeholder="Enter Secret Key"
            className="mb24"
            required
            showRequired
            validate={Validation.required()}
          />
          <div className="text-center mt24">
            <SpinnerButton
              isLoading={submitting || loading}
              onClick={handleSubmit}
              disabled={invalid}
            >
              {isActivated && isPresent ? 'Update' : 'Set up'}
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default CashfreeSetupForm;
