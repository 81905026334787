import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { SketchPicker } from 'react-color';

import FormGroup from './FormGroup';
import withPopover from '../WithPopover';

const presetColorsList = [
  '#ff0000',
  '#ffa500',
  '#ffff00',
  '#a52a2a',
  '#008000',
  '#808000',
  '#ffc0cb',
  '#800080',
  '#0000ff',
  '#00ffff',
  '#000000',
  '#808080',
  '#c0c0c0',
  '#ffffff',
];

export const ColorPickerPopper = withPopover(
  ({ color, onColorChange, onChangeComplete }) => (
    <SketchPicker
      width={240}
      color={color}
      onChange={onColorChange}
      onChangeComplete={onChangeComplete}
      disableAlpha
      presetColors={presetColorsList}
    />
  )
);

const ColorInputWrap = (props) => {
  const {
    name,
    placeholder,
    value,
    colorRight = false,
    onColorChange,
    appendText,
    error,
    showColorName = true,
  } = props;
  const ntcValues = value ? window.ntc.name(value) : [];
  const [color, setColor] = useState(value || '');

  const handleColorChange = ({ hex }) => {
    setColor(hex);
  };

  const handleOnChangeComplete = ({ hex }) => {
    setColor(hex);
    onColorChange(hex);
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    if (!color) {
      const numberOfColorInputs =
        document.getElementsByClassName('color-popper').length;
      const colorWrapElements = document.getElementsByClassName(`${name}-wrap`);
      if (colorWrapElements.length && numberOfColorInputs > 1) {
        colorWrapElements[0].click();
      }
    }
  }, []);

  return (
    <div
      className={cx('form-color-select row', {
        'color-right': colorRight,
        'has-error': error,
      })}
    >
      <span className="spacer">
        <ColorPickerPopper
          popperClass={`color-popper ${name}`}
          color={color}
          onColorChange={handleColorChange}
          onChangeComplete={handleOnChangeComplete}
        >
          {value ? (
            <span className="color-select-value-container">
              {colorRight ? (
                <>
                  <span className="color-txt color-right">{value}</span>
                  <span
                    className="color-box mr0"
                    style={{ backgroundColor: value }}
                  />
                </>
              ) : (
                <>
                  <span
                    className="color-box"
                    style={{ backgroundColor: value }}
                  />
                  <span className="color-txt">
                    {showColorName ? ntcValues[1] : value?.toUpperCase()}
                  </span>
                </>
              )}
            </span>
          ) : (
            <span className="color-select-placeholder">{placeholder}</span>
          )}
        </ColorPickerPopper>
      </span>
      {appendText && <span>{appendText}</span>}
    </div>
  );
};

const FormColorSelect = (props) => {
  const {
    className,
    name,
    validate,
    labelText,
    required,
    size,
    helpText,
    showRequired,
    ...restProps
  } = props;

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const onColorChange = (color) => {
          input.onChange(color);
        };
        return (
          <FormGroup
            labelText={labelText}
            required={required}
            showRequired={showRequired}
            size={size}
            helpText={helpText}
            className={cx('form-color-select-group', className)}
          >
            <ColorInputWrap
              value={input.value}
              error={!input.value && meta.touched}
              onColorChange={onColorChange}
              name={name}
              {...restProps}
            />
          </FormGroup>
        );
      }}
    </Field>
  );
};

FormColorSelect.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: false,
  disabled: false,
  type: 'text',
  size: 'lg', // md
  helpText: '',
  placeholder: 'Choose colour',
  showRequired: false,
};

export default FormColorSelect;
