import React from 'react';
import cx from 'classnames';
import SwitchExpansionPanel from '../../../Layout/SwitchExpansionPanel';
import { generateTimepickerOptionsForDropdown } from '../../../Manage/StoreTimings/common';
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
} from '../../../Manage/StoreTimings/constants';
import { FormCustomSelect, FormInput } from '../../../shared/Form';
import RadioInputGroup from '../../../shared/RadioInputGroup';

const CountdownTimer = ({ form, values, externalClasses = '' }) => {
  const x = [
    { label: 'Daily', value: 'daily' },
    { label: 'One Time', value: 'onetime' },
    { label: 'Cookie Based', value: 'cookie' },
  ];

  return (
    <SwitchExpansionPanel
      className={cx('variant-form', externalClasses)}
      title="Countdown Timer"
      isChecked={!!values?.is_active}
      switchName="timer.is_active"
      id="countdown-timer"
    >
      <div className="full-w mt32">
        <div className="accordian-divider" />
        <div className="row">
          <div className="col-xs-12 pr10 pl10">
            <FormInput
              name="timer.timer_msg"
              placeholder="Enter message"
              required
              labelText="Message above the timer"
              showCharLimit
              maxLength={60}
            />
          </div>
          <div className="col-xs-12 p10">
            <RadioInputGroup
              name="timer.store_method"
              items={x}
              form={form}
              style={{ flexDirection: 'row', gap: '8px' }}
              value={values?.store_method}
            />
          </div>
          <div
            className="text-3 px10 col-sm-12 pb20"
            style={{ color: '#4d4d4d' }}
          >
            The countdown timer will be hide automatically after the time ends.
          </div>
          {values?.store_method !== 'daily' ? (
            <>
              <div className="col-lg-4 col-xs-12 pr10 pl10">
                <FormInput
                  name="timer.days"
                  placeholder="Days"
                  labelText="Days"
                  type="number"
                  min="0"
                />
              </div>
              <div className="col-lg-4 col-xs-12 pr10 pl10">
                <FormInput
                  name="timer.hours"
                  placeholder="Hours"
                  labelText="Hours"
                  type="number"
                  min="0"
                  max="23"
                />
              </div>
              <div className="col-lg-4 col-xs-12 pr10 pl10">
                <FormInput
                  name="timer.minutes"
                  placeholder="Minutes"
                  labelText="Minutes"
                  type="number"
                  min="0"
                  max="59"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-4 col-xs-12 pr10 pl10">
                <FormCustomSelect
                  name="timer.start_time"
                  labelText="Start time"
                  options={generateTimepickerOptionsForDropdown(
                    DEFAULT_START_TIME,
                    values.end_time
                  )}
                  placeholder=""
                />
              </div>
              <div className="col-lg-4 col-xs-12 pr10 pl10">
                <FormCustomSelect
                  name="timer.end_time"
                  labelText="End time"
                  options={generateTimepickerOptionsForDropdown(
                    values.start_time,
                    DEFAULT_END_TIME
                  )}
                  placeholder=""
                />
              </div>
            </>
          )}
        </div>
      </div>
    </SwitchExpansionPanel>
  );
};

export default CountdownTimer;
