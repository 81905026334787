import axios from 'axios';
import { useState } from 'react';

import { patchRequest, postRequest } from '../utils/http';
import {
  ORDERS_BULK_SHIPPING_URL,
  ORDER_DUKAAN_MULTIPLE_SHIPMENT_URL,
} from '../ApiUrls';
import { noop } from '../utils';
import useQuery from './useQuery';

const HTTP_FORBIDDEN_STATUS_CODE = 403;

const useOrderShipment = () => {
  const query = useQuery();
  const mode = query.get('mode');
  const carrier = query.get('carrier');
  const [loading, setLoading] = useState(false);
  const [orderShippingDetails, setShippingDetails] = useState({});
  const [orderSeviceable, setOrderSeviceable] = useState(false);
  const [servicebleError, setServicebleError] = useState(false);
  const [shipmentErrorMsg, setShipmentErrorMsg] = useState('');

  const getSelectedRate = (rates = []) => {
    if (rates?.length) {
      if (!carrier) {
        return rates[0];
      }
      if (carrier && !mode) {
        return rates.filter(
          (each) => String(each.carrier.id) === String(carrier)
        )[0];
      }
      if (carrier && mode) {
        return rates.filter(
          (each) =>
            String(each.carrier.id) === String(carrier) &&
            String(each.carrier_service.id) === String(mode)
        )[0];
      }
    }
    return {};
  };

  let cancel;

  const getShippingDetails = (payload = {}, successCb = noop, errCb = noop) => {
    setOrderSeviceable(false);
    setLoading(true);
    if (cancel) {
      cancel();
    }
    return postRequest({
      url: ORDERS_BULK_SHIPPING_URL,
      data: payload,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then(({ data }) => {
        const result = {
          ...data,
          ...data.orders_charges[0],
          ...getSelectedRate(data.orders_charges[0]?.rates || []),
        };
        setOrderSeviceable(result?.rates?.length > 0);
        setShippingDetails(result);
        successCb(data, result?.rates?.length > 0);
        if (result.status !== 'calculated') {
          setServicebleError(true);
        } else {
          setServicebleError(false);
        }
        setShipmentErrorMsg('');
      })
      .catch((err) => {
        errCb(err);
        console.log(err);
        if (err?.data?.status_code === HTTP_FORBIDDEN_STATUS_CODE)
          setShipmentErrorMsg(
            err?.data?.data?.error || 'Something went wrong, try again.'
          );
        setServicebleError(true);
        cancel = null;
      })
      .finally(() => setLoading(false));
  };

  const shipMultipleOrders = (
    ordersList = [],
    callback = noop,
    errCb = noop
  ) => {
    setLoading(true);
    const payload = { orders: ordersList };
    return patchRequest({
      url: ORDER_DUKAAN_MULTIPLE_SHIPMENT_URL,
      data: payload,
    })
      .then((res) => callback(res))
      .catch((err) => errCb(err))
      .finally(() => setLoading(false));
  };

  return {
    loading,
    setLoading,
    orderSeviceable,
    orderShippingDetails,
    getShippingDetails,
    shipOrder: shipMultipleOrders,
    servicebleError,
    shipmentErrorMsg,
  };
};

export default useOrderShipment;
