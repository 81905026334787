import { useState } from 'react';
import {
  ORDER_LINE_ITEM_FETCH_SHIPPING_URL,
  ORDER_LINE_ITEM_SHIPPING_URL,
} from '../ApiUrls';
import { noop } from '../utils';
import { postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';

const useLineItemShipment = (orderId = '') => {
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const getFetchPayload = (values, isShip = false) => {
    const payload = {
      order: orderId,
      products: [],
    };
    for (const each of Object.entries(values)) {
      const lineItem = each[0];
      const { staff, warehouse, shipment } = each[1];
      if (staff && warehouse) {
        const obj = {
          line_item: Number(lineItem),
          warehouse: warehouse.value,
          staff: staff.value,
        };
        if (isShip) {
          obj.carrier = shipment.value?.carrier?.id;
          obj.carrier_service = shipment.value?.carrier_service?.id;
        }
        payload.products.push(obj);
      }
    }
    return payload;
  };

  const fetchLineItemShippingPrice = (
    selectedValues = {},
    onSuccess = noop
  ) => {
    setLoading(true);
    postRequest({
      url: ORDER_LINE_ITEM_FETCH_SHIPPING_URL,
      data: getFetchPayload(selectedValues),
      doClean: false,
    })
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const shipOrder = (selectedValues = {}, onSuccess = noop) => {
    setSubmitting(true);
    postRequest({
      url: ORDER_LINE_ITEM_SHIPPING_URL,
      data: getFetchPayload(selectedValues, true),
      doClean: false,
    })
      .then((res) => {
        onSuccess(res.data);
        enqueueSnackbar('Order shipped successfully!');
      })
      .catch((error) => {
        enqueueSnackbar(
          typeof error?.data?.detail === 'string'
            ? error?.data?.detail
            : 'Something went wrong while shipping.',
          'error'
        );
        console.log(error);
      })
      .finally(() => setSubmitting(false));
  };

  return {
    isSubmitting,
    isLoading,
    fetchLineItemShippingPrice,
    shipOrder,
  };
};

export default useLineItemShipment;
