import { FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useAppContext } from '../context/AppContext';
import { useCountry } from '../context/CountryProvider';
import { EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_1 } from '../events';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useEnterPressAction from '../hooks/useEnterPressAction';
import CustomRadio from '../shared/CustomRadio';
import { FormNumberInput, Validation } from '../shared/Form';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { noop } from '../utils';
import { TrackEvent } from '../utils/analytics';

const RequestPartialPaymentModal = ({
  isOpen = true,
  closeModal,
  orderDetails,
  generatePartialPaymentLink,
  isGeneratingPaymentLink = false,
}) => {
  const { business } = useAppContext();
  const { currencySymbol } = useCountry();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [enterFixedAmount, setEnterFixedAmount] = useState(true);
  const [partialAmount, setPartialAmount] = useState(null);
  const handleRequest = (partialPaymentAmount) => {
    TrackEvent(
      EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_1,
      business
    );
    generatePartialPaymentLink(partialPaymentAmount, () => {
      closeModal();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  useEnterPressAction(
    isOpen,
    !partialAmount || isGeneratingPaymentLink
      ? noop
      : () => handleRequest(partialAmount)
  );

  return (
    <Modal
      open={isOpen}
      closeModal={closeModal}
      maxWidth="sm"
      paperClass="select-category-modal"
    >
      <Form
        onSubmit={noop}
        initialValues={{ amount: '', percentage: '' }}
        keepDirtyOnReinitialize
      >
        {({ values }) => {
          let showPaymentText = false;
          let partialPaymentAmount = null;
          if (enterFixedAmount && values.amount) {
            showPaymentText = true;
            partialPaymentAmount = values.amount;
          } else if (!enterFixedAmount && values.percentage) {
            showPaymentText = true;
            partialPaymentAmount =
              (orderDetails.total_cost * Number(values.percentage)) / 100;
          }
          setPartialAmount(partialPaymentAmount);
          return (
            <div className="request-partial-payment">
              <h2 className="section-text-4 mb2">
                Request partial online payment
              </h2>
              <div className="text-2 c-black-3 mb24">
                Partial online payment allows you to collect a partial deposit
                amount for an order.
              </div>
              <div className="text-8 c-black-8 mb8">Partial deposit type</div>
              <div className="d-flex mb24">
                <FormControlLabel
                  control={<CustomRadio className="p0-i" />}
                  label="Fixed amount"
                  className="mr24"
                  checked={enterFixedAmount}
                  name="fixed_amount"
                  onChange={() => {
                    setEnterFixedAmount(true);
                  }}
                />
                <FormControlLabel
                  control={<CustomRadio className="p0-i" />}
                  label="Percentage"
                  checked={!enterFixedAmount}
                  name="percentage"
                  onChange={() => {
                    setEnterFixedAmount(false);
                  }}
                />
              </div>
              {enterFixedAmount && (
                <FormNumberInput
                  labelText="Partial payment amount"
                  name="amount"
                  placeholder="Enter amount"
                  showRequired
                  required
                  prependText={currencySymbol}
                  autoFocus
                  className="col-md-6"
                  isAllowed={(val) => {
                    const { floatValue } = val;
                    if (
                      floatValue === undefined ||
                      (floatValue > 0 &&
                        floatValue <= Number(orderDetails.total_cost))
                    )
                      return true;
                    return false;
                  }}
                />
              )}
              {!enterFixedAmount && (
                <FormNumberInput
                  labelText="Partial payment percentage"
                  name="percentage"
                  placeholder="Enter percentage"
                  required
                  appendText="%"
                  showRequired
                  validate={Validation.required()}
                  isAllowed={(val) => {
                    const { floatValue } = val;
                    if (floatValue && floatValue > 100) {
                      enqueueSnackbar(
                        "Percent can't be greater than 100",
                        'error'
                      );
                    }
                    if (floatValue && floatValue < 1) {
                      enqueueSnackbar("Percent can't be less than 1", 'error');
                    }
                    return (
                      floatValue === undefined ||
                      (floatValue <= 100 && floatValue > 0)
                    );
                  }}
                  className="col-md-6"
                />
              )}

              {showPaymentText && (
                <div className="section-text-7 c-black-3 mt8">
                  Payment link of {currencySymbol}
                  {partialPaymentAmount} will be shared with your customer.
                </div>
              )}
              <div className="d-flex justify-center mt32">
                <SpinnerButton
                  label="Request online payment"
                  showAnimation
                  isLoading={isGeneratingPaymentLink}
                  disabled={!partialPaymentAmount || isGeneratingPaymentLink}
                  onClick={() => handleRequest(partialPaymentAmount)}
                />
              </div>
            </div>
          );
        }}
      </Form>
    </Modal>
  );
};

export default RequestPartialPaymentModal;
