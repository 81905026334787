import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { patchRequest } from '../utils/http';
import { CATEGORY_URL, CATEGORY_BULK_UPDATE_URL } from '../ApiUrls';
import { categoriesUrl } from '../UrlHelper';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';

const useCategoryUpdate = (uuid) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const updateCategory = (params, callback = noop, errorCallback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: CATEGORY_URL(uuid),
      data: { ...params },
      doClean: false,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          callback(res.data);
          enqueueSnackbar(
            `${
              res.data.parent !== null ? 'Subcategory' : 'Category'
            } updated successfully.`
          );
        }
      })
      .catch((err) => {
        if (errorCallback === noop)
          enqueueSnackbar('Something went wrong.', 'error');
        else errorCallback(err);
        setSubmitting(false);
      });
  };

  const bulkUpdateCategories = (params, callback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: CATEGORY_BULK_UPDATE_URL,
      data: { ...params },
      doClean: false,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          callback(res.data);
        }
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong.', 'error');
        setSubmitting(false);
      });
  };

  const updateCategoryVisibility = (params, successCallback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: CATEGORY_URL(uuid),
      data: params,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback(res.data);
        }
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong.', 'error');
        setSubmitting(false);
      });
  };

  return {
    isSubmitting,
    updateCategory,
    updateCategoryVisibility,
    bulkUpdateCategories,
  };
};

export default useCategoryUpdate;
