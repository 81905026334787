import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';
import { useAppContext } from '../context/AppContext';
import { catalogueUrls } from '../Urls';
import useKeyboardListener from '../hooks/utils/useKeyboardListener';
import { MENU_LAYOUTS } from '../Home/constants';

const AppBarMenuItem = ({
  typeKey,
  tooltipTitle,
  Icon,
  Component,
  layout = 'popup',
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { isVendor, isAppSumoStore, isHelloBarActive } = useAppContext();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    setShowTooltip(false);
  };

  const inInventory =
    history.location.pathname === catalogueUrls.productBulkPricesPath;

  if ((isVendor || isAppSumoStore) && typeKey === 'announcements') return null;

  useKeyboardListener('Escape', () => {
    setOpen(false);
  });

  return (
    <>
      <div
        aria-describedby={`menu-popover-${typeKey}`}
        id={`menu-popover-${typeKey}-btn`}
        onClick={handleClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="cur-p"
      >
        <Tooltip
          open={showTooltip && !open}
          title={tooltipTitle}
          placement="bottom"
          className="d-flex"
        >
          <div className="more-menu">
            <Icon active={false} />
          </div>
        </Tooltip>
      </div>
      {layout === MENU_LAYOUTS.POPUP && (
        <Popper
          id={`app-bar-menu-${typeKey}`}
          open={open}
          anchorEl={anchorEl}
          transition
          className="popover"
          disablePortal
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Fade timeout={350} {...TransitionProps}>
              <Paper elevation={0}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <Component />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      {layout === MENU_LAYOUTS.DRAWER && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Component
            open={open}
            onClose={() => {
              setOpen((previousOpen) => !previousOpen);
            }}
          />
        </ClickAwayListener>
      )}
    </>
  );
};

export default AppBarMenuItem;
