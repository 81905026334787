import React, { useEffect } from 'react';
import cx from 'classnames';
import BounceLoader from '../../shared/BounceLoader';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { noop } from '../../utils';
import { useTheme } from '../../context/ThemeProvider';
import ImageLoader from '../../shared/ImageLoader';
import { useAppContext } from '../../context/AppContext';
import { EVENT_PROD_ADD_SYSTEM_GENERATED_IMAGE } from '../../events';
import { TrackEvent } from '../../utils/analytics';
import useEnterPressAction from '../../hooks/useEnterPressAction';

const SuggestionImage = ({ image, onClick = noop, selectionNumber = null }) => {
  const { s3_url: src } = image;
  const { isProductImageAspectRatioDifferent: isAspectRatioDifferent } =
    useTheme();
  return (
    <div
      className={cx('suggested-image-container', {
        'suggested-theme-aspect-ratio': isAspectRatioDifferent === true,
      })}
      onClick={onClick}
    >
      {selectionNumber && (
        <div className="selection-number">
          <span>{selectionNumber}</span>
        </div>
      )}
      <ImageLoader src={src} className="suggested-image-preview" />
    </div>
  );
};

const SuggestedImagesModal = ({
  children,
  searchQuery = '',
  open = false,
  images = [],
  existingImagesCount = 0,
  maxImages = 1,
  isLoading,
  selectedImages,
  setSelectedImages,
  handleClose = noop,
  handleSubmit = noop,
}) => {
  const { business } = useAppContext();

  const toggleImage = (url) => {
    if (selectedImages.has(url)) {
      const filtered = new Set(selectedImages);
      filtered.delete(url);
      setSelectedImages(filtered);
    } else if (selectedImages.size + existingImagesCount < maxImages) {
      TrackEvent(EVENT_PROD_ADD_SYSTEM_GENERATED_IMAGE, business);
      setSelectedImages(new Set(selectedImages.add(url)));
    }
  };

  useEffect(() => {
    if (open) {
      const selectedImagesTemp = [...selectedImages];
      if (existingImagesCount + selectedImagesTemp.length > maxImages) {
        const remainingCount = maxImages - existingImagesCount;
        const slicedArray = selectedImagesTemp.slice(0, remainingCount);
        setSelectedImages(new Set([...slicedArray]));
      }
    } else {
      setSelectedImages(new Set([]));
    }
  }, [open]);

  useEffect(() => {
    if (searchQuery !== '') {
      setSelectedImages(new Set());
    }
  }, [searchQuery]);

  const handleDone = () => {
    handleSubmit(Array.from(selectedImages));
    handleClose();
  };

  useEnterPressAction(open, handleDone);

  return (
    <Modal
      open={open}
      closeModal={handleClose}
      className="suggested-images-modal"
    >
      <h2 className="section-text-4 mb24">
        Product images ({selectedImages.size}/{maxImages - existingImagesCount})
      </h2>
      <div className="edit-banner">
        {isLoading ? (
          <div className="suggestion-images-loader">
            <BounceLoader />
          </div>
        ) : (
          <div>
            <p className="section-text-7 mb16">Upload your own images</p>
            {children}

            {images.length > 0 && (
              <>
                <p className="section-text-7 mb16 mt24">
                  Or choose from suggested images
                </p>
                <div className="suggestion-images-grid">
                  {images.map((img) => (
                    <SuggestionImage
                      image={img}
                      key={img.uuid}
                      onClick={() => toggleImage(img.s3_url)}
                      selectionNumber={
                        selectedImages.has(img.s3_url) &&
                        Array.from(selectedImages).indexOf(img.s3_url) + 1
                      }
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        <SpinnerButton
          type="button"
          className="btn btn-primary-4 mt32"
          onClick={handleDone}
        >
          Done
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default SuggestedImagesModal;
