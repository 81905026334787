import React, { Component } from 'react';
import cx from 'classnames';

const WithPopper = (WrappedComponent, defaultProps = {}) => {
  class HOC extends Component {
    state = {
      anchorEl: null,
    };

    componentWillUnmount() {
      this.closePopper();
    }

    openPopper = (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.setState({
        anchorEl: event.currentTarget,
      });
    };

    closePopper = () => {
      const { anchorEl } = this.state;
      const { beforeClose } = this.props;
      if (anchorEl) {
        if (beforeClose) {
          beforeClose();
        }
        this.setState({
          anchorEl: null,
        });
      }
    };

    render() {
      const { children, popperClass } = this.props;

      const { anchorEl } = this.state;
      const isPopperOpen = Boolean(anchorEl);
      const classes = cx(`${popperClass}-wrap`, {
        [`${popperClass}-open`]: isPopperOpen,
      });

      return (
        <>
          <span
            className={classes}
            aria-owns={isPopperOpen ? popperClass : undefined}
            aria-haspopup="true"
            onClick={this.openPopper}
          >
            {children}
          </span>
          {anchorEl && (
            <WrappedComponent
              {...this.props}
              anchorEl={anchorEl}
              isPopperOpen={isPopperOpen}
              closePopper={this.closePopper}
            />
          )}
        </>
      );
    }
  }
  HOC.displayName = `withPopper(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  HOC.defaultProps = {
    popperClass: 'input-popper',
    ...defaultProps,
  };

  return HOC;
};

export default WithPopper;
