import { useEffect, useState } from 'react';
import { useSubscription } from '../context/SubscriptionProvider';
import { useCountry } from '../context/CountryProvider';
import { useAppContext } from '../context/AppContext';
import { BUNDLES_ENABLED_STORES, isNonLiveEnv } from '../constants';
import { SHIPPO_ID } from '../Plugins/constants';

export const PLAN_FEATURES = {
  COD_CHARGES: 'cod_charges',
  MANUAL_PAYMENTS: 'manual_payments',
  RAZORPAY_PAYMENT_GATEWAY: 'razorpay_payment_gateway',
  CODE_EDITOR: 'code_editor',
  RETURNS_AND_REPLACEMENTS: 'automated_return_and_refund',
  CONNECT_SHIPPING_PARTNER: 'connect_your_own_shipping_account',
  PRODUCT_BUNDLES: 'product_bundles',
  PRODUCT_ATTRIBUTES: 'product_attributes', // currently available on all plans, add plan check here as well
  MEDIA_LIBRARY: 'media_library',
  LANGUAGES: 'multiple_language_support',
  CUSTOM_DOMAIN: 'custom_domain',
};

const usePlanFeature = () => {
  const { planFeatures, subscriptionData } = useSubscription();
  const { isInternational } = useCountry(); // disable this when plans intl go live
  const [featuresMap, setFeaturesMap] = useState([]);
  const { isEnterprise, isAppSumoStore, business } = useAppContext();

  const pluginsData = subscriptionData?.plan_data?.plugins ?? [];

  const isBundleAllowed =
    // featuresMap[PLAN_FEATURES.PRODUCT_BUNDLES] ||
    BUNDLES_ENABLED_STORES.includes(business.id) || isNonLiveEnv;

  const checkFeatureAllowed = (key) => {
    return featuresMap[key] === undefined || isInternational || isAppSumoStore
      ? true
      : featuresMap[key];
  };

  const checkPluginAllowed = (pluginUuid) => {
    if (isEnterprise || isAppSumoStore || isNonLiveEnv) return true;
    if (pluginUuid === SHIPPO_ID) return true;
    if (pluginsData.length === 0) return true;
    return pluginsData.some((it) => it._id === pluginUuid);
  };

  useEffect(() => {
    const features = {};
    planFeatures.forEach((it) => (features[it.feature_key] = it.is_allowed));
    setFeaturesMap(features);
  }, [planFeatures]);

  return {
    featuresMap,
    isBundleAllowed,
    checkFeatureAllowed,
    checkPluginAllowed,
  };
};

export default usePlanFeature;
