import React, { memo, useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';

import { components } from 'react-select';
import FormGroup from './FormGroup';
import { noop } from '../../utils';
import { FormCustomSelect, Validation } from './index';
import { countriesByPhoneCode } from '../../utils/countries';

const CountryItem = memo((props) => {
  const { Option } = components;
  const {
    data: { icon, label, phone_code: phoneCode },
  } = props;
  return (
    <Option {...props}>
      <div className="d-flex-c-start">
        <img width={30} alt={label} src={icon} className="mr8" />
        <span>
          {label} (+{phoneCode})
        </span>
      </div>
    </Option>
  );
});
CountryItem.displayName = 'CountryItem';

const CountryItemCondensed = memo((props) => {
  const {
    data: { icon, label, phone_code: phoneCode },
  } = props;
  return (
    <div className="d-flex-c-start">
      <img width={24} alt={label} src={icon} className="mr8" />
      <span>+{phoneCode}</span>
    </div>
  );
});
CountryItemCondensed.displayName = 'CountryItemCondensed';

const PhoneCodeSelect = ({ selected, onChange, ...restProps }) => (
  <FormCustomSelect
    name="phone_code"
    className="phone-code-select"
    options={countriesByPhoneCode}
    components={{
      Option: CountryItem,
      SingleValue: CountryItemCondensed,
    }}
    defaultValue={countriesByPhoneCode[0]}
    placeholder=""
    required
    selected={selected}
    onChange={onChange}
    showRequired
    validate={Validation.required()}
    isInputPhoneCode
    {...restProps}
  />
);

const EmailAndPhoneInput = React.forwardRef((props, ref) => {
  const {
    className,
    name,
    onChange,
    labelText,
    showRequired,
    size,
    values,
    isPhone,
    handleCountry,
    selectedCountry,
    isIPInternational,
    menuPlacement,
    required,
    autoFocus,
    onInputFieldChange = noop,
    allValues,
    ...restInputProps
  } = props;
  const [focus, setFocus] = useState(false);
  const classes = cx('input-wrap email-phone-input-wrap', className);
  const phoneCode = values?.phone_code;
  const phoneCodeData = countriesByPhoneCode.find(
    (country) =>
      Number(country.value) === Number(phoneCode) &&
      country.country_code === values.country_code
  );

  const phoneValidate = phoneCodeData
    ? Validation.mobileValidation(
        phoneCodeData?.mobile_length_min,
        phoneCodeData?.mobile_length_max
      )
    : Validation.mobileValidation(
        allValues?.country_mobile?.mobile_length_min,
        allValues?.country_mobile?.mobile_length_max
      );

  const emailValidate = required
    ? Validation.validateEmail()
    : Validation.emptyValidateEmail();

  const selectProps = {
    menuPlacement,
  };

  useEffect(() => {
    onInputFieldChange();
  }, [isPhone]);

  return (
    <Field
      name={name}
      key={isPhone}
      // need to force re-render by passing key if validate function is being changed
      // based on a prop/variable
      // https://final-form.org/docs/react-final-form/types/FieldProps#validate
      validate={isPhone && !isIPInternational ? phoneValidate : emailValidate}
    >
      {({ input }) => {
        const handleChange = (e) => {
          input.onChange(e);
          onChange(e);
        };
        return (
          <FormGroup
            labelText={labelText}
            required
            showRequired={showRequired}
            size={size}
            className={classes}
          >
            <div className="input-group-wrap">
              <input
                {...input}
                {...restInputProps}
                ref={ref}
                onChange={handleChange}
                onFocus={() => setFocus(true)}
                autoComplete="off"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={focus || autoFocus}
              />
              {isPhone && !isIPInternational && (
                <PhoneCodeSelect
                  {...selectProps}
                  onChange={handleCountry}
                  selected={selectedCountry}
                />
              )}
            </div>
          </FormGroup>
        );
      }}
    </Field>
  );
});

EmailAndPhoneInput.displayName = 'EmailAndPhoneInput';

EmailAndPhoneInput.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: true,
  disabled: false,
  type: 'text',
  size: 'lg', // md
  onChange: noop,
  showRequired: false,
  autoFocus: true,
};

export default EmailAndPhoneInput;
