import React, { createContext, useContext } from 'react';
import countries, { US_AND_CANADA_IDS } from '../utils/countries';
import { formatMoney, roundTo } from '../utils/number';
import { useAppContext } from './AppContext';
import { defaultCurrency } from '../utils/currencies';
import { noop } from '../utils';

export const NON_INTERNATIONAL_COUNTRY_CODE = 77;
export const NON_INTERNATIONAL_COUNTRY_CODE_VIA_IP = 'IN';

const CountryContext = createContext({
  formatLocalMoney: noop,
  formatLocalNumber: noop,
  isInternational: false,
  isUsOrCanada: false,
  formatCurrencyToDollarOrRs: noop,
});

export const useCountry = () => useContext(CountryContext);

const DEFAULT_COUNTRY = countries[0];

const CountryProvider = ({ children }) => {
  const { business } = useAppContext();
  const {
    country: countryFromBusiness,
    country_code_v2: countryCodeV2,
    currency: currencyFromBusiness,
  } = business;

  const isInternational =
    countryFromBusiness !== NON_INTERNATIONAL_COUNTRY_CODE;
  const countryObject =
    countries.find(
      (country) =>
        country.country_code.toLowerCase() === countryCodeV2?.toLowerCase()
    ) || DEFAULT_COUNTRY;
  const currency = currencyFromBusiness || defaultCurrency;
  const formatLocalMoney = (num, places = 2) => {
    if (countryObject.locale) {
      let amount = new Intl.NumberFormat(countryObject.locale, {
        style: 'currency',
        currency: currency.cc,
        minimumFractionDigits: places,
      }).format(num);
      if (parseInt(num) === num) {
        amount = new Intl.NumberFormat(countryObject.locale, {
          style: 'currency',
          currency: currency.cc,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(num);
      }
      return amount?.replace(/\s/g, '');
    }

    return formatMoney(
      num,
      places,
      currency?.symbol,
      countryObject?.curr_group_char,
      countryObject?.curr_mon_char
    );
  };

  const formatCurrencyToDollarOrRs = (number = 0) => {
    if (isInternational) {
      return `$${number.toFixed(2)}`;
    } else {
      return formatLocalMoney(number);
    }
  };

  const formatLocalNumber = (num = 0) =>
    num.toLocaleString(countryObject.locale);

  const formatToThousands = (value) => {
    if (Number.isNaN(value)) return '';
    if (value < 10000) {
      return formatLocalMoney(value, 0);
    }
    return `${currency?.symbol}${roundTo(Number(value) / 1000)}K`;
  };

  const formatToLakhs = (value) => {
    if (Number.isNaN(value)) return '';
    if (value < 100000) {
      return formatLocalMoney(value, 0);
    }
    return `${currency?.symbol}${roundTo(Number(value) / 100000)}L`;
  };

  const contextValue = {
    country: countryObject,
    countryCode: countryObject.country_code,
    currencySymbol: currency?.symbol,
    currencyCode: currency?.cc,
    phoneCode: countryObject.phone_code,
    formatLocalMoney,
    formatLocalNumber,
    isInternational,
    isUsOrCanada: US_AND_CANADA_IDS.includes(countryFromBusiness),
    formatToThousands,
    formatToLakhs,
    locale: countryObject.locale,
    formatCurrencyToDollarOrRs,
  };

  return (
    <CountryContext.Provider value={contextValue}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
