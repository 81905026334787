import React from 'react';
import { Field } from 'react-final-form';
import { useAppContext } from '../context/AppContext';
import Switch from '../shared/Switch';
import { HelpIcon } from '../SvgIcon';
import { noop } from '../utils';
import { getDukaanHelpCenterUrl } from '../shared/utils';

export default function SwitchExpansionPanel({
  title,
  titleClass = 'section-text-5',
  isChecked,
  switchName,
  children,
  className = '',
  description = '',
  onSwitchToggle = noop,
  howItWorksUrl,
  hideSwitch = false,
  ...rest
}) {
  const { isAppSumoStore } = useAppContext();
  const toggler = ({ input: { onChange, value } }) => (
    <Switch
      checked={value}
      onChange={(checked) => {
        onChange(checked);
        onSwitchToggle(checked);
      }}
    />
  );
  return (
    <div className={`p24 card mb24 ${className}`} {...rest}>
      <div className="d-flex-start-s">
        <div>
          <h5 className={titleClass}>
            {title}
            {!isAppSumoStore && howItWorksUrl && (
              <a
                href={getDukaanHelpCenterUrl(howItWorksUrl)}
                target="_blank"
                rel="noreferrer"
                className="how-it-works d-inline-flex align-center text-10 c-black-3 pt2 ml12"
              >
                {' '}
                <HelpIcon className="mr4" /> How it works
              </a>
            )}
          </h5>
          {description && <p className="c-gray-1 mt4 mr8">{description}</p>}
        </div>
        {switchName && !hideSwitch && (
          <Field name={switchName} component={toggler} />
        )}
      </div>
      {isChecked || !switchName ? children : null}
    </div>
  );
}
