export const DINING_ORDER = 1;
export const NON_DINING_ORDER = 2;
export const BOTH_DINING_AND_NON_DINING_ORDER = 3;

export const RESELLER_ACCOUNT_TYPE = 1;

export const PERCENTAGE_CHARGE_TYPE = 0;
export const FLAT_CHARGE_TYPE = 1;

export const INTERNATIONAL = 'international';

export const APPLICABLE_TO_CHARGES = [
  {
    value: BOTH_DINING_AND_NON_DINING_ORDER,
    label: 'Both delivery & dining orders',
  },
  { value: NON_DINING_ORDER, label: 'Delivery orders only' },
  { value: DINING_ORDER, label: 'Dining orders only' },
];

export const STAFF_ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  MANAGER_L1: 'manager_l1',
  MANAGER_L2: 'manager_l2',
  STAFF: 'staff',
  STAFF_L1: 'staff_l1',
  STAFF_L2: 'staff_l2',
  STAFF_L3: 'staff_l3',
  VENDOR: 'vendor',
};

export const STAFF_ROLES_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
};

export const STAFF_ROLE_ALL_KEYS = [
  STAFF_ROLES.OWNER,
  STAFF_ROLES.ADMIN,
  STAFF_ROLES.MANAGER,
  STAFF_ROLES.STAFF,
  STAFF_ROLES.VENDOR,
];

export const STORE_SETTINGS_SECTION = {
  PREFERENCES: 'preferences',
  STORE: 'store',
  PAYMENTS: 'payments',
  CHECKOUT: 'checkout',
  DOMAINS: 'domains',
  POLICIES: 'policies',
  EXTRA_CHARGES: 'extra-charges',
  STORE_TIMINGS: 'store-timings',
  STAFF_ACCOUNTS: 'staff-accounts',
  VENDOR_ACCOUNTS: 'vendor-accounts',
  SOCIAL_PROFILE: 'social-profile',
  INTLTAX: 'intl-tax',
  WAREHOUSE: 'warehouse',
  RETURNS: 'returns',
  SHIPPING: 'shipping',
  LANGUAGES: 'languages',
  ATTRIBUTES: 'attributes',
  TAX: 'tax',
  SEO: 'seo',
};

export const AUTO_ACCEPT_ORDERS = {
  PAID_ORDERS_ONLY: 1,
  ALL_ORDERS: 2,
  COD_ORDERS_ONLY: 3,
  NEVER: 4,
};

export const AUTO_ACCEPT_ORDERS_TITLES = {
  never: 'Never',
  online: 'Prepaid orders only',
  cod: 'COD orders only',
  both: 'All (COD + Prepaid)',
};

export const NEVER_AUTO_ACCEPT_ORDERS = 'never';

export const AUTO_ACCEPT_ORDERS_SNACKBAR_TEXT = {
  never: 'Never',
  online: 'Prepaid',
  cod: 'COD',
  both: 'All',
};

export const VENDOR_ROLE_OPTION = {
  value: STAFF_ROLES.VENDOR,
  label: 'Vendor',
  description:
    'Can access only the shipment and delivery features of your store dashboard.',
};

export const STAFF_ROLE_OPTIONS = [
  {
    value: STAFF_ROLES.OWNER,
    label: 'Owner',
    description: 'Can access everything.',
    hiddenForCreate: true,
  },
  {
    value: STAFF_ROLES.ADMIN,
    label: 'Admin',
    description: 'Can access all the features of your store dashboard.',
  },
  {
    value: STAFF_ROLES.MANAGER,
    label: 'Manager - Store',
    description:
      'Can access all the features of your store dashboard except Payouts, Account & Report.',
  },
  {
    value: STAFF_ROLES.STAFF,
    label: 'Staff - Store',
    description:
      'Can access only the order and delivery features of your store dashboard.',
  },
  {
    value: STAFF_ROLES.VENDOR,
    label: 'Vendor',
    description:
      'Can access only the shipment and delivery features of your store dashboard.',
    hiddenForCreate: true,
  },
  {
    value: STAFF_ROLES.MANAGER_L1,
    label: 'Manager L1',
    description: 'Manager L1',
    hiddenForCreate: true,
  },
  {
    value: STAFF_ROLES.MANAGER_L2,
    label: 'Manager Finance',
    description: 'Manager Finance',
    hiddenForCreate: true,
  },
  {
    value: STAFF_ROLES.STAFF_L1,
    label: 'Support L1',
    description: 'Support L1',
    hiddenForCreate: true,
  },
  {
    value: STAFF_ROLES.STAFF_L2,
    label: 'Support L2',
    description: 'Support L2',
    hiddenForCreate: true,
  },
  {
    value: STAFF_ROLES.STAFF_L3,
    label: 'Support L3',
    description: 'Support L3',
    hiddenForCreate: true,
  },
];

export const ENTERPRISE_ADDITIONAL_STAFF_ROLES = [
  {
    value: STAFF_ROLES.MANAGER_L1,
    label: 'Manager L1',
    description: 'Manager L1',
  },
  {
    value: STAFF_ROLES.MANAGER_L2,
    label: 'Manager Finance',
    description: 'Manager Finance',
  },
  {
    value: STAFF_ROLES.STAFF_L1,
    label: 'Support L1',
    description: 'Support L1',
  },
  {
    value: STAFF_ROLES.STAFF_L2,
    label: 'Support L2',
    description: 'Support L2',
  },
  {
    value: STAFF_ROLES.STAFF_L3,
    label: 'Support L3',
    description: 'Support L3',
  },
];

export const SOURCE_REFUND_KEY = 'source';
export const LOYALTY_POINT_REFUND_KEY = 'wallet';

export const SOURCE_METHOD_KEY = 'source_method';
export const CUSTOMER_WALLET_KEY = 'customer_wallet';
export const BANK_TRANSFER_KEY = 'bank_transfer';

export const REFUND_KEYS = {
  [SOURCE_REFUND_KEY]: SOURCE_METHOD_KEY,
  [LOYALTY_POINT_REFUND_KEY]: CUSTOMER_WALLET_KEY,
};

// payment types
const CUSTOMER_PAYMENT_LINK = 'customer_payment_link';
const DELIVERY_PARTNER_COLLECTION = 'delivery_partner_collection';
const CUSTOMER_WALLET = 'customer_wallet';
const SELLER_MANUAL_COLLECTION = 'seller_manual_collection';

export const PAYMENT_TYPES = {
  [CUSTOMER_PAYMENT_LINK]: 'Payment link',
  [DELIVERY_PARTNER_COLLECTION]: 'Collected by Delivery partner',
  [CUSTOMER_WALLET]: 'Customer wallet',
  [SELLER_MANUAL_COLLECTION]: 'Manual payment',
};

export const REFUND_TYPES = {
  [CUSTOMER_WALLET_KEY]: 'Customer wallet',
  [BANK_TRANSFER_KEY]: 'Source',
  [SOURCE_METHOD_KEY]: 'Source',
};

export const REFUND_LABEL_MAPPING = {
  backlog: 'Pending',
  queued: 'Pending',
  created: 'Initiated',
};

export const MODE_OF_REFUND = [
  {
    value: LOYALTY_POINT_REFUND_KEY,
    label: 'Loyalty points',
    description: 'Send refund in the form of loyalty points.',
    classicEnabled: true,
  },
  {
    value: SOURCE_REFUND_KEY,
    label: 'Buyer’s account',
    description: 'Send refund directly into the buyer’s source account.',
    classicEnabled: false,
  },
  {
    value: 'wallet_or_source',
    label: 'Buyer’s account / Loyalty points',
    description:
      'The buyer will be able to choose the mode of refund as per their choice.',
    classicEnabled: false,
  },
];

export const SOCIAL_KEYS = [
  'facebook_url',
  'twitter_url',
  'instagram_url',
  'linkedin_url',
  'youtube_url',
  'pinterest_url',
];

export const SOCIAL_LINKS_MAP = {
  facebook_url: 'https://facebook.com/',
  twitter_url: 'https://twitter.com/',
  instagram_url: 'https://instagram.com/',
  linkedin_url: 'https://linkedin.com/',
  youtube_url: 'https://youtube.com/',
  pinterest_url: 'https://pinterest.com/',
};

export const generateFullSiteUrl = (value, key) => {
  if (value && value !== '') {
    return `${SOCIAL_LINKS_MAP[key]}${value}`;
  }
  return null;
};

export const getUsername = (url = '') => {
  if (!url) {
    return '';
  }
  return url.split('.com/')[1];
};

// Account section
export const STORE_DETAILS_ID = 1;
export const SUBSCRIPTION_ID = 2;
export const STAFF_ID = 3;
export const REFERRAL_ID = 4;
export const SOCIAL_PROFILE_ID = 5;
export const INVOICES = 6;
export const SELLER_WEB_LANGUAGE = 7;

export const STAFF_ACCOUNT_SECTION = 'staff_accounts';
export const genericEmailList = (customDomain) => [
  `info@${customDomain}`,
  `support@${customDomain}`,
  `help@${customDomain}`,
  `contact@${customDomain}`,
];

export const COMMISSION_MODEL_PERCENTAGE = 'percentage';
export const COMMISSION_MODEL_TRANSFER_PRICE = 'transfer_price';
export const COMMISSION_MODELS = {
  [COMMISSION_MODEL_PERCENTAGE]: {
    label: 'Percentage on Selling Price',
    value: COMMISSION_MODEL_PERCENTAGE,
    columnLabel: 'Percentage',
  },
  [COMMISSION_MODEL_TRANSFER_PRICE]: {
    label: 'Transfer Price (TP)',
    value: COMMISSION_MODEL_TRANSFER_PRICE,
    columnLabel: 'Transfer Price (TP)',
  },
};

export const defaultCommissionModel = {
  label: 'Select Vendor',
  value: null,
};

export const commissionModelOptions = Object.values(COMMISSION_MODELS);

export const DELIVERY_CHARGES = {
  FIXED: 1,
  VARIABLE: 2,
};

export const BAG_CONTROL_TYPE = {
  TOTAL_ORDER_TYPE: 0,
  PAYMENT_METHODS: 1,
};

export const COUNTRY_CHANGE_EMAIL = {
  SUBJECT: 'Requesting country change',
  BODY: 'Country change request',
};

export const TRANSFER_DOMAIN_EMAIL_SUBJECT = 'Domain transfer request';

export const getDomainTransferEmailBody = (customDomain) =>
  `Please change my domain: ${customDomain}.`;
