import { useCallback, useState } from 'react';

const useModal = (defaultOpen = false, options = {}) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const { prefix = '' } = options;

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  return {
    [`is${prefix}Open`]: isOpen,
    [`open${prefix}Modal`]: openModal,
    [`close${prefix}Modal`]: closeModal,
  };
};

export default useModal;
