import { CUSTOMER_SUPPORT_EMAIL } from '../constants';

export const ROADMAP_LINK = 'https://roadmap.mydukaan.io/roadmap';
export const APP_STORE_LINKS = {
  APPLE_LINK:
    'https://apps.apple.com/in/app/dukaan-create-online-dukan/id1560859298',
  GOOGLE_PLAYSTORE_LINK:
    'https://play.google.com/store/apps/details?id=com.dukaan.app',
};
export const SUBMIT_A_REQUEST_LINK =
  'https://roadmap.mydukaan.io/b/5m1qyp06/feature-ideas';

export const APP_SUMO_REVIEW_URL =
  'https://appsumo.com/products/dukaan/#reviews/';

export const APP_SUMO_PRODUCTS_URL = 'https://appsumo.com/products/dukaan/';

export const CONTACT_SUPPORT_FROM_MAIL_DOMAIN = 'mailto:support@mydukaan.io';

export const FEEDBACK_MAILTO = (link, storeId) =>
  `mailto:support@mydukaan.io?cc=${CUSTOMER_SUPPORT_EMAIL}&subject=Feedback%2FError%20report%20from%20store%20${link}&body=Type%20your%20feedback%2Fissue%20here.%20%20%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%20Store-Information%20-----%0D%0A${`Store link: mydukaan.io/${link}`}%20%20%0D%0A${`Store ID: ${storeId}`}%20%20%0D%0A${
    window.location.href
  }%20`;

export const FEEDBACK_GMAIL = (link, storeId) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=support@mydukaan.io&cc=${CUSTOMER_SUPPORT_EMAIL}&su=Feedback%2FError%20report%20from%20store%20${link}&body=Type%20your%20feedback%2Fissue%20here.%20%20%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%20Store-Information%20-----%0D%0A${`Store link: mydukaan.io/${link}`}%20%20%0D%0A${`Store ID: ${storeId}`}%20%20%0D%0A${
    window.location.href
  }%20`;

export const TRANSFER_DOMAIN_MAILTO = (customDomain) =>
  `mailto:support@mydukaan.io?cc=${CUSTOMER_SUPPORT_EMAIL}&subject=Domain%20transfer%20request&body=Please transfer my domain: ${customDomain}`;

export const TRANSFER_DOMAIN_GMAIL = (customDomain) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=support@mydukaan.io&su=Domain%20transfer%20request&body=Please transfer my domain: ${customDomain}`;

export const REQUEST_COUNTRY_CHANGE_MAILTO = (
  sendTo = CUSTOMER_SUPPORT_EMAIL,
  link,
  storeId
) =>
  `mailto:${sendTo}?&subject=Requesting%20country%20change%20for%20my%20store%20${link}&body=Please%20change%20my%20store%20country%20to:%20<Type%20country%20name%20here>%20%20%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%20Store-Information%20-----%0D%0A${`Store link: mydukaan.io/${link}`}%20%20%0D%0A${`Store ID: ${storeId}`}%20%20`;

export const REQUEST_COUNTRY_CHANGE_GMAIL = (
  sendTo = CUSTOMER_SUPPORT_EMAIL,
  link,
  storeId
) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=${sendTo}&su=Requesting%20country%20change%20for%20my%20store${link}%20&body=Country%20change%20request%2F%20.%20%20%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%20Store-Information%20-----%0D%0A${`Store-mydukaan.io/${link}`}%20%20%0D%0A${storeId}%20%20`;

export const REQUEST_MAX_TRANSACTION_LIMIT_INCREASE_MAILTO = (
  sendTo,
  link,
  storeId
) =>
  `mailto:${sendTo}?&subject=Requesting%20change%20of%20maximum%20transaction%20limit%20with%20Dukaan%20pay%20&body=Request%20maximum%20transaction%20limit.%20%20%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%20Store-Information%20-----%0D%0A${`Store-mydukaan.io/${link}`}%20%20%0D%0A${storeId}%20%20`;

export const REQUEST_MAX_TRANSACTION_LIMIT_INCREASE_GMAIL = (
  sendTo,
  link,
  storeId
) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=${sendTo}&su=Requesting%20change%20of%20maximum%20transaction%20limit%20with%20Dukaan%20pay%20&body=Request%20maximum%20transaction%20limit.%20%20%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%20Store-Information%20-----%0D%0A${`Store-mydukaan.io/${link}`}%20%20%0D%0A${storeId}%20%20`;

export const CONTACT_SUPPORT_FROM_GMAIL = (link, storeId, message) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=support@mydukaan.io&su=Feedback%2FError%20report%20from%20store%20${link}&body=Type%20your%20feedback%2Fissue%20here.%20%0A%0A${
    message || ''
  }%0A%0A%0A%0A-----%20Store-Information%20-----%0A${`Store%20-%20mydukaan.io/${link}`}%0AStore%20ID%20-%20${storeId}%0A${
    window.location.href
  }%0A%0A`;

export const CONTACT_SUPPORT_FROM_MAIL = (link, storeId, message) =>
  `mailto:support@mydukaan.io?subject=Feedback%2FError%20report%20from%20store%20${link}&body=Type%20your%20feedback%2Fissue%20here.%20%0A%0A${
    message || ''
  }%0A%0A%0A%0A-----%20Store-Information%20-----%0A${`Store%20-%20mydukaan.io/${link}`}%0AStore%20ID%20-%20${storeId}%0A${
    window.location.href
  }%0A%0A`;

export const getGmailEmailLink = (sendTo, subject, body, link, storeId) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=${sendTo}&su=${subject}&body=${body}%0A%0A%0A%0A-----%20Store-Information%20-----%0A${`Store%20-%20mydukaan.io/${link}`}%0AStore%20ID%20-%20${storeId}%0A${
    window.location.href
  }%0A%0A`;

export const getMailtoEmailLink = (sendTo, subject, body, link, storeId) =>
  `mailto:${sendTo}?subject=${subject}&body=${body}%0A%0A%0A%0A-----%20Store-Information%20-----%0A${`Store%20-%20mydukaan.io/${link}`}%0AStore%20ID%20-%20${storeId}%0A${
    window.location.href
  }%0A%0A`;
