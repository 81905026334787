import React from 'react';
import useCustomSnackbar, { NewOrderSnackbar } from './useCustomSnackbar';
import { useAppContext } from '../context/AppContext';
import { TrackEvent } from '../utils/analytics';
import { EVENT_ORDER_NEW_NOTIFICATION } from '../events';
import NewOrderAudioFile from '../../audio/new-order-notification.mp3';

const useNewOrderSocket = () => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { business } = useAppContext();

  const onNewOrder = ({ data }) => {
    const { order } = data;
    const { uuid, total_cost: totalCost, type } = order;

    TrackEvent(EVENT_ORDER_NEW_NOTIFICATION, business);
    const SnackbarComponent = (key) => (
      <NewOrderSnackbar id={key} totalCost={totalCost} uuid={uuid} />
    );
    enqueueSnackbar('New order', 'success', {
      content: SnackbarComponent,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      autoHideDuration: 5000,
    });
    new Audio(NewOrderAudioFile).play().catch(() => {});
  };

  const onAcceptOrder = ({ data }) => {
    const { table: orderAudioLink } = data;
    if (orderAudioLink) {
      new Audio(orderAudioLink).play().catch(() => {});
    }
  };

  return { onAcceptOrder, onNewOrder };
};

export default useNewOrderSocket;
