import React, { useEffect } from 'react';
import cx from 'classnames';
import Modal from '../shared/Modal';
import { ReactComponent as SuccessBlueIcon } from '../../images/icons/success-blue.svg';
import { capitaliseFirst } from '../utils/string';
import SpinnerButton from '../shared/SpinnerButton';
import useFileDownload from '../hooks/useFileDownload';
import FullScreenLoader from '../shared/FullscreenLoader';
import useResponsive from '../hooks/useResponsive';
import { isHyperlocalSelected } from '../context/DeliveryProvider';
import { useAppContext } from '../context/AppContext';
import { TrackEvent } from '../utils/analytics';
import {
  DDSET_INVOICE_DOWNLOAD,
  DDSET_TRACKING_ID,
  ORDERS_SHIP_SINGLE_INVOICE_DOWNLOAD,
  ORDERS_SHIP_SINGLE_INVOICE_TRACK,
} from '../events';
import useEnterPressAction from '../hooks/useEnterPressAction';
import { noop } from '../utils';

const ShipmentDetails = (props) => {
  const { business } = useAppContext();
  const { isMobile } = useResponsive();
  const { downloadFile, downloading } = useFileDownload();
  const {
    awb,
    tracking_link: trackingLink,
    carrier,
    carrier_json: carrierJson,
    carrier_service_json: carrierServiceJson,
    pdf_url: pdfUrl,
    isActiveStoreWarehouseExist,
    selectedRate,
    hideInvoiceAndLabel,
  } = props;
  const isHyperlocal = isHyperlocalSelected({
    carrier_service: carrierServiceJson,
  });

  return (
    <div className="ship-details-table">
      <div className="ship-details-table-row">
        <div className="ship-details-table-column">
          <p className="text-medium mb2">Shipping via</p>
          <p>
            {!isHyperlocal
              ? `${carrierJson?.name || ''} ${carrierServiceJson?.name || ''}`
              : capitaliseFirst(carrierJson?.name)}
          </p>
        </div>
        <div className="ship-details-table-column">
          <p className="text-medium mb2">Tracking ID</p>
          <span
            className={cx('text-8 text-medium break-word underline', {
              'anchor-1': awb,
            })}
            onClick={() => {
              if (trackingLink) {
                TrackEvent(
                  ORDERS_SHIP_SINGLE_INVOICE_TRACK(
                    selectedRate?.carrier_service?.service_type_json?.id,
                    isActiveStoreWarehouseExist
                  ),
                  business,
                  {}
                );
                window.open(trackingLink, '_blank');
              }
            }}
          >
            {awb || '-'}
          </span>
        </div>
      </div>
      {!hideInvoiceAndLabel && (
        <div className="ship-details-table-row mt16">
          <div className="ship-details-table-column">
            <p className="text-medium mb2">
              {isHyperlocal ? 'Order Invoice' : 'Shipping Label & Invoice'}
            </p>
            <span
              className={cx('link text-medium', {
                disabled: !pdfUrl,
              })}
              onClick={() => {
                if (pdfUrl) {
                  TrackEvent(
                    ORDERS_SHIP_SINGLE_INVOICE_DOWNLOAD(
                      selectedRate?.carrier_service?.service_type_json?.id,
                      isActiveStoreWarehouseExist
                    ),
                    business,
                    {}
                  );
                  downloadFile(pdfUrl, !isMobile);
                }
              }}
            >
              Download
            </span>
          </div>
        </div>
      )}
      {downloading > 0 && <FullScreenLoader message="Downloading..." />}
    </div>
  );
};

const ShipOrderModal = (props) => {
  const {
    open: shipmentData,
    closeModal,
    isActiveStoreWarehouseExist,
    selectedRate,
    title = 'Shipment created',
    hideInvoiceAndLabel = false,
    openReceiptModal = noop,
    setIsClassicNotAvailable = noop,
  } = props;

  const { isMobile } = useResponsive();
  useEnterPressAction(!!shipmentData, closeModal);

  return (
    <Modal
      open={!!shipmentData}
      className="shipment-created-modal"
      closeModal={closeModal}
      paperClass={cx({ 'mobile-modal': isMobile })}
      showCloseIcon={false}
    >
      <SuccessBlueIcon className="header-svg-icon" />
      <div className="ship-success-header">
        <div className="text-center">
          <h2 className="ship-header-2">{title}</h2>
          <h6 className="ship-header-6">
            The customer will receive a tracking link shortly.
          </h6>
        </div>
        <div className="ship-details-table-container">
          <ShipmentDetails
            {...shipmentData}
            openReceiptModal={openReceiptModal}
            hideInvoiceAndLabel={hideInvoiceAndLabel}
            isActiveStoreWarehouseExist={isActiveStoreWarehouseExist}
            selectedRate={selectedRate}
            setIsClassicNotAvailable={setIsClassicNotAvailable}
          />
        </div>
      </div>
      <div className="mt32 text-center">
        <SpinnerButton
          type="button"
          className="btn btn-primary shipment-success-btn"
          onClick={closeModal}
        >
          <span className="btn-txt text-2">Done</span>
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default ShipOrderModal;
