import { useEffect, useState } from 'react';
import qs from 'query-string';
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from '../utils/http';
import { noop } from '../utils';
import {
  APPLICABLE_METAFIELDS,
  METAFIELDS_LIST,
  META_FIELD_TYPE_LIST,
  ORDER_METAFIELDS_LIST,
  ORDER_METAFIELDS_RESOURCE_VALUE_LIST,
  REORDER_METAFIELDS,
  SKU_METAFIELDS_LIST,
  PRODUCT_METAFIELDS_LIST,
  CATEGORY_METAFIELDS_LIST,
  CATEGORY_METAFIELDS_RESOURCE_VALUE_LIST,
} from '../ApiUrls';
import {
  MAX_META_FIELD_LIMIT,
  ATTRIBUTE_FIELD_TYPES,
} from '../Account/Sections/CategoryAttributes/constants';
import { DIGITAL_PRODUCT_KEY } from '../Catalogue/Products/AdvanceCatalogue/constants';
import { deepClone } from '../Appearance/utils';
import { useAppContext } from '../context/AppContext';
import useCustomSnackbar from './useCustomSnackbar';

const useAttribute = ({
  isFetchMetaFieldTypeList = true,
  isFetchMetaFieldsList = true,
  isFetchOrderMetaFieldsList = false,
} = {}) => {
  const [metaFieldTypeLoading, setMetaFieldTypeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metafieldValuesLoading, setMetafieldValuesLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [metaFieldTypeList, setMetaFieldTypeList] = useState([]);
  const [metaFieldTypeMap, setMetaFieldTypeMap] = useState([]);
  const [metaFieldsList, setMetaFieldsList] = useState([]);
  const [skuMetaFieldsList, setSkuMetaFieldsList] = useState([]);
  const [orderMetaFieldsList, setOrderMetaFieldsList] = useState([]);
  const [categoryMetaFieldsList, setCategoryMetaFieldsList] = useState([]);
  const [categorySpecificMetaFieldsList, setCategorySpecificMetaFieldsList] =
    useState([]);
  const [orderSpecificMetaFieldsList, setOrderSpecificMetaFieldsList] =
    useState([]);
  const [digitalProductDetails, setDigitalProductDetails] = useState({});
  const [specificMetaFieldDetails, setSpecificMetaFieldDetails] =
    useState(null);
  const [categorySpcificMetafieldList, setCategorySpcificMetafieldList] =
    useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isDigitalProductsSetupDone, setIsDigitalProductsSetupDone] =
    useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();
  const { business } = useAppContext();

  const fetchMetaFieldTypeList = (successCallback = noop) => {
    setMetaFieldTypeLoading(true);
    return getRequest({ url: META_FIELD_TYPE_LIST })
      .then((res) => {
        const { results = [] } = res;
        const metaTypeList = [];
        results.forEach((item, index) => {
          if (ATTRIBUTE_FIELD_TYPES[item.id]) {
            metaTypeList[index] = {
              ...item,
              value: item.id,
              ...(ATTRIBUTE_FIELD_TYPES[item.id] || {}),
            };
          }
        });
        successCallback(results);
        setMetaFieldTypeList(metaTypeList);
        setMetaFieldTypeMap(metaTypeList);
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setMetaFieldTypeLoading(false));
  };

  const fetchMetaFieldsList = (page = 1, successCallback = noop) => {
    setLoading(true);
    setListLoading(true);
    if (page === 1) {
      setMetaFieldsList([]);
      setDigitalProductDetails({});
    }
    return getRequest({
      url: METAFIELDS_LIST,
      data: {
        page_size: MAX_META_FIELD_LIMIT,
        page,
      },
    })
      .then((res) => {
        const { results = [], next } = res;
        successCallback(results);
        setMetaFieldsList((pre) => {
          const allMetaFields = [...(pre ?? []), ...results];

          const isDigitalProductsSetupComplete = Boolean(
            allMetaFields?.find((eachMetafield) =>
              eachMetafield?.key?.includes('Digital_Products')
            )
          );

          setIsDigitalProductsSetupDone(isDigitalProductsSetupComplete);

          return allMetaFields;
        });
        const digitalKeyList = Object.values(DIGITAL_PRODUCT_KEY);
        const updatedDigitalProductObj = {};
        results.forEach((each) => {
          const attributeKey = each?.key?.toLowerCase() || '';
          if (digitalKeyList.includes(attributeKey)) {
            updatedDigitalProductObj[attributeKey] = deepClone(each);
          }
        });
        setDigitalProductDetails((pre) => ({
          ...deepClone(pre ?? {}),
          ...updatedDigitalProductObj,
        }));
        if (next) {
          fetchMetaFieldsList(page + 1);
        }
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => {
        setLoading(false);
        setListLoading(false);
      });
  };

  const fetchSKUMetaFieldsList = (page = 1, successCallback = noop) => {
    setLoading(true);
    return getRequest({
      url: SKU_METAFIELDS_LIST,
    })
      .then((res) => {
        const { results = [], next } = res;
        successCallback(results);
        setSkuMetaFieldsList((pre) => [...(pre ?? []), ...results]);
        if (next) {
          fetchSKUMetaFieldsList(page + 1);
        }
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const fetchOrderMetaFieldsList = (page = 1, successCallback = noop) => {
    setLoading(true);
    return getRequest({
      url: ORDER_METAFIELDS_LIST,
      data: {
        page_size: MAX_META_FIELD_LIMIT,
        page,
      },
    })
      .then((res) => {
        const { results = [], next } = res;
        successCallback(results);
        setOrderMetaFieldsList((pre) => [...(pre ?? []), ...results]);
        if (next) {
          fetchOrderMetaFieldsList(page + 1);
        }
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const fetchCategoryMetaFieldsList = (
    queryParams,
    successCallback = noop,
    isAllAttribute = false
  ) => {
    const page = queryParams.page || 1;
    if (page <= 1) {
      setCategoryMetaFieldsList([]);
    }
    setLoading(true);
    return getRequest({
      url: CATEGORY_METAFIELDS_LIST,
      data: {
        page_size: MAX_META_FIELD_LIMIT,
        page,
        ...queryParams,
      },
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((res) => {
        const { results = [], next } = res;
        successCallback(results);
        setCategoryMetaFieldsList([...results]);
        if (isAllAttribute && next) {
          fetchCategoryMetaFieldsList(
            { ...queryParams, page: page + 1 },
            undefined,
            Boolean(next)
          );
        }
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const fetchAllCategoryMetaFields = (
    categoryUuid,
    page = 1,
    allResults = []
  ) =>
    getRequest({
      url: `${CATEGORY_METAFIELDS_RESOURCE_VALUE_LIST}${categoryUuid}/`,
      data: {
        page_size: MAX_META_FIELD_LIMIT,
        page,
      },
    }).then((res) => {
      const { results = [], next } = res;
      const updatedResults = [...allResults, ...results];
      if (next) {
        return fetchAllCategoryMetaFields(
          categoryUuid,
          page + 1,
          updatedResults
        );
      }
      return updatedResults;
    });

  const fetchSpecificCategoryMetaFieldsList = (
    categoryUuid,
    page = 1,
    successCallback = noop
  ) => {
    if (page <= 1) {
      setCategorySpecificMetaFieldsList([]);
    }
    setMetafieldValuesLoading(true);

    fetchAllCategoryMetaFields(categoryUuid)
      .then((results) => {
        setCategorySpecificMetaFieldsList(results);
        successCallback(results);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setMetafieldValuesLoading(false));
  };

  const updateSpecificCategoryMetaFields = (
    categoryUuid,
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    postRequest({
      url: `${CATEGORY_METAFIELDS_RESOURCE_VALUE_LIST}${categoryUuid}/`,
      data: payload,
    })
      .then((res) => {
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error ||
          e?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(() => setSubmitting(false));
  };

  const fetchSpecificOrderMetaFieldsList = (
    orderUuid,
    page = 1,
    successCallback = noop
  ) => {
    if (page <= 1) {
      setOrderSpecificMetaFieldsList([]);
    }
    setLoading(true);
    return getRequest({
      url: `${ORDER_METAFIELDS_RESOURCE_VALUE_LIST}${orderUuid}/`,
      data: {
        page_size: MAX_META_FIELD_LIMIT,
        page,
      },
    })
      .then((res) => {
        const { results = [], next } = res;
        successCallback(results);
        setOrderSpecificMetaFieldsList((pre) => [...(pre ?? []), ...results]);
        if (next) {
          fetchSpecificOrderMetaFieldsList(orderUuid, page + 1);
        }
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const updateSpecificOrderMetaFields = (
    orderUuid,
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    postRequest({
      url: `${ORDER_METAFIELDS_RESOURCE_VALUE_LIST}${orderUuid}/`,
      data: payload,
    })
      .then((res) => {
        if (res) enqueueSnackbar('Order attributes updated successfully!');
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error ||
          e?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(() => setSubmitting(false));
  };

  const fetchCategorySpcificMetafieldList = (
    queryParams,
    successCallback = noop,
    isAllAttribute = false
  ) => {
    const page = queryParams.page || 1;
    if (page <= 1) {
      setDigitalProductDetails({});
      setCategorySpcificMetafieldList([]);
    }
    setLoading(true);
    return getRequest({
      url: APPLICABLE_METAFIELDS,
      data: {
        page_size: MAX_META_FIELD_LIMIT,
        page,
        ...queryParams,
      },
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((res) => {
        const digitalKeyList = Object.values(DIGITAL_PRODUCT_KEY);
        const { results = [], next } = res;
        const updatedDigitalProductObj = {};
        const updatedMetaList = results.reduce((acc, each) => {
          const attributeKey = each?.key?.toLowerCase() || '';
          if (digitalKeyList.includes(attributeKey)) {
            updatedDigitalProductObj[attributeKey] = deepClone(each);
          } else {
            acc.push(deepClone(each));
          }
          return acc;
        }, []);
        setDigitalProductDetails((pre) => ({
          ...deepClone(pre ?? {}),
          ...updatedDigitalProductObj,
        }));
        successCallback(results);
        setCategorySpcificMetafieldList([...updatedMetaList]);
        if (isAllAttribute && next) {
          fetchCategorySpcificMetafieldList(
            { ...queryParams, page: page + 1 },
            undefined,
            Boolean(next)
          );
        }
        return results;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const createMetaFields = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    postRequest({
      url: METAFIELDS_LIST,
      data: payload,
    })
      .then((res) => {
        if (res) enqueueSnackbar('Attribute created successfully!');
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error ||
          e?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(() => setSubmitting(false));
  };

  const fetchSpecificMetaField = (id, successCallback = noop) => {
    setLoading(true);
    return getRequest({
      url: `${METAFIELDS_LIST}${id}/`,
    })
      .then((res) => {
        const { data } = res;
        successCallback(data);
        setSpecificMetaFieldDetails(data);
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const updateMetaFields = (
    payload,
    successCallback = noop,
    errorCallback = noop,
    msg
  ) => {
    setSubmitting(true);

    const endpoint = payload.isProductUpdate
      ? PRODUCT_METAFIELDS_LIST
      : METAFIELDS_LIST;

    const requestData = payload.isProductUpdate
      ? payload.possible_values
      : payload;

    const requestPromise = payload.isProductUpdate
      ? postRequest({
          url: `${endpoint}${payload.id}/`,
          data: requestData,
          doClean: false,
        })
      : patchRequest({
          url: `${endpoint}${payload.id}/`,
          data: requestData,
          doClean: false,
        });

    return requestPromise
      .then((response) => {
        if (response && isFetchMetaFieldsList && msg !== null) {
          enqueueSnackbar(msg || 'Attributes updated successfully');
        }
        successCallback(response);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          error?.data?.data?.error ||
          error?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(errorMessage, 'error');
        errorCallback(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteMetaFields = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    deleteRequest({
      url: `${METAFIELDS_LIST}${payload.id}/`,
      data: payload,
    })
      .then((res) => {
        enqueueSnackbar(`${payload.key} deleted successfully`);
        fetchMetaFieldsList();
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error ||
          e?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(() => setSubmitting(false));
  };

  const reorderMetaFields = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: REORDER_METAFIELDS,
      data: payload,
    })
      .then((res) => {
        enqueueSnackbar('Attributes updated successfully');
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error || 'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (isFetchMetaFieldTypeList) fetchMetaFieldTypeList();
    if (isFetchMetaFieldsList) {
      fetchMetaFieldsList();
      fetchSKUMetaFieldsList();
    }
    if (isFetchOrderMetaFieldsList) {
      fetchOrderMetaFieldsList();
    }
  }, []);

  return {
    loading,
    listLoading,
    submitting,
    metaFieldsList,
    metaFieldTypeList,
    metaFieldTypeMap,
    categorySpcificMetafieldList,
    specificMetaFieldDetails,
    digitalProductDetails,
    skuMetaFieldsList,
    orderMetaFieldsList,
    categoryMetaFieldsList,
    orderSpecificMetaFieldsList,
    fetchSpecificMetaField,
    fetchMetaFieldsList,
    createMetaFields,
    updateMetaFields,
    deleteMetaFields,
    reorderMetaFields,
    fetchMetaFieldTypeList,
    fetchCategorySpcificMetafieldList,
    fetchCategoryMetaFieldsList,
    updateSpecificOrderMetaFields,
    fetchSpecificOrderMetaFieldsList,
    isDigitalProductsSetupDone,
    setIsDigitalProductsSetupDone,
    categorySpecificMetaFieldsList,
    fetchSpecificCategoryMetaFieldsList,
    updateSpecificCategoryMetaFields,
    metafieldValuesLoading,
  };
};

export default useAttribute;
