import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import cx from 'classnames';
import qs from 'query-string';
import { noop } from '../utils';
import SpinnerButton from './SpinnerButton';
import useResponsive from '../hooks/useResponsive';

const ToggleButtonGroup = ({
  options,
  defaultSelected,
  onChange,
  disabled,
  selected: propsSelected,
  setSelected: propsSetSelected,
  onOtherButtonClick,
  className = '',
  maintainToggleState,
  delay = 0,
}) => {
  const [selected, setSelected] = useState(defaultSelected);
  const history = useHistory();
  const useParams = qs.parse(window.location.search);
  const { isMobile } = useResponsive();

  const handleClick = (option, active) => {
    if (!active) {
      if (propsSetSelected) {
        propsSetSelected(option.label);
      } else {
        setSelected(option.value);
      }
      if (option.value === 5) {
        // delivered and served case
        onChange(option.value, option.label === 'Delivered' ? 0 : 1, option); // send status with type, type 0 is delivered and type 1 is served
      } else {
        onChange(option.value, undefined, option);
      }
      if (maintainToggleState) {
        window.history.replaceState(
          { ...history.location.state },
          null,
          `${history.location.pathname}?${qs.stringify({
            ...useParams,
            status: option.value,
          })}`
        );
      }
    }
  };

  const getActive = (option) => {
    let active = false;
    if (propsSelected) {
      if (
        [
          'Modified',
          'Rejected',
          'Cancelled',
          'Failed',
          'Returned',
          'Cancelled By Buyer',
        ].includes(propsSelected) &&
        option.label === 'Others'
      ) {
        active = true;
      } else {
        active = propsSelected === option.label;
      }
    } else {
      active =
        selected &&
        typeof selected === 'object' &&
        selected.length &&
        typeof option.value === 'object'
          ? selected[0] === option.value[0]
          : selected === option.value;
    }
    return active;
  };

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    if (maintainToggleState) {
      if (useParams.status) {
        const option = options.find(
          (optionItem) => optionItem.value.toString() === useParams.status
        );
        if (option) handleClick(option, getActive(option));
      }
      if (history.location.state?.status) {
        const option = options.find(
          (optionItem) =>
            optionItem.value.toString() === history.location.state.status
        );
        if (option) handleClick(option, getActive(option));
      }
    }
  }, []);

  return (
    <div className={`toggle-button-group ${className}`}>
      {options.map((option, index) => {
        const active = getActive(option);
        const classes = cx('toggle-button', { active, disabled });
        const renderButton = (
          <div key={index}>
            <SpinnerButton
              key={index}
              type="button"
              className={classes}
              disabled={disabled}
              onClick={() => {
                if (option.label === 'Others' && onOtherButtonClick) {
                  onOtherButtonClick();
                } else {
                  handleClick(option, active);
                }
              }}
            >
              <span className={isMobile ? 'text-medium' : 'd-flex-c-c'}>
                <span>{option.label}</span>
                {!!option.count && (
                  <span
                    className={cx('menu-badge toggle-button-badge', { active })}
                  >
                    {option.count}
                  </span>
                )}
              </span>
            </SpinnerButton>
          </div>
        );

        if (option.helpText && !isMobile)
          return (
            <Tooltip
              placement="top"
              title={option.helpText}
              enterDelay={delay}
              enterNextDelay={delay}
            >
              {renderButton}
            </Tooltip>
          );
        return renderButton;
      })}
    </div>
  );
};

ToggleButtonGroup.defaultProps = {
  options: [],
  defaultSelected: '',
  onChange: noop,
  disabled: false,
  maintainToggleState: false,
};

export default ToggleButtonGroup;
