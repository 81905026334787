const IframeComponent = (props) => {
  const { src, open } = props;
  if (!open) return null;
  return (
    <iframe
      title="Other Shipings Details"
      src={src}
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 9999,
      }}
    />
  );
};

export default IframeComponent;
