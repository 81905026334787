import { useState } from 'react';
import qs from 'query-string';
import { getRequest } from '../utils/http';
import { GENERATE_ORDERS_PDF_URL } from '../ApiUrls';
import { noop } from '../utils';

const useGenerateOrdersReport = (url = GENERATE_ORDERS_PDF_URL) => {
  const [generating, setGenerating] = useState(false);

  const generateReport = (params = {}, cb = noop) => {
    setGenerating(true);
    const timezone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.toLowerCase();
    params.tz = timezone;

    getRequest({
      url,
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((data) => {
        // setGenerating(false);
        cb(data);
      })
      .catch(() => {
        setGenerating(false);
      });
  };

  return {
    generating,
    generateReport,
    setGenerating,
  };
};

export default useGenerateOrdersReport;
