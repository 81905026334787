import React, { useState, useEffect } from 'react';

import { DISPLAY_FORMAT, momentDate } from '../../utils/date';
import { noop } from '../../utils';
import DateRangePopper from './DateRangePopper';
import DateInputFieldWrap from './DateInputFieldWrap';
import { DATE_RANGE_OPTIONS_NAME_MAP } from './util';
import { CalendarIcon } from '../../SvgIcon';

const DateRangePicker = (props) => {
  const {
    displayDateFormat,
    defaultSelection,
    onRangeChange,
    availableRanges,
    placeholder,
    startDateValue,
    endDateValue,
    className = '',
  } = props;

  const [activeSelection, setActiveSelection] = useState(defaultSelection);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPopperOpen, setPopperOpen] = useState(false);

  useEffect(() => {
    setStartDate(startDateValue ? momentDate(startDateValue) : '');
  }, [startDateValue]);

  useEffect(() => {
    setEndDate(endDateValue ? momentDate(endDateValue) : '');
  }, [endDateValue]);

  useEffect(() => {
    setActiveSelection(defaultSelection);
  }, [defaultSelection]);

  const generateLabelText = () => {
    const hasDates = startDate && endDate;
    let displayDate;
    if (hasDates) {
      displayDate = `${startDate.format(displayDateFormat)} - ${endDate.format(
        displayDateFormat
      )}`;
    }
    let labelText = '';
    if (activeSelection) {
      if (activeSelection === 'custom_range') {
        labelText = displayDate;
      } else {
        labelText = DATE_RANGE_OPTIONS_NAME_MAP[activeSelection];
      }
    } else {
      labelText = placeholder;
    }
    return labelText;
  };

  const handleRangeSelect = (sDate, eDate, selection) => {
    setStartDate(sDate);
    setEndDate(eDate);
    setActiveSelection(selection);
    onRangeChange(sDate, eDate, selection);
  };

  return (
    <DateRangePopper
      startDate={startDate}
      endDate={endDate}
      onRangeSelect={handleRangeSelect}
      activeSelection={activeSelection}
      availableRanges={availableRanges}
      isPopperOpen={isPopperOpen}
      setPopperOpen={setPopperOpen}
      className={className}
    >
      <DateInputFieldWrap
        isPopperOpen={isPopperOpen}
        onClick={() => {
          setPopperOpen(!isPopperOpen);
        }}
      >
        <span className="d-flex align-center cur-p">
          <CalendarIcon className="mr8 ml0-i" />
          {generateLabelText()}
        </span>
      </DateInputFieldWrap>
    </DateRangePopper>
  );
};

DateRangePicker.defaultProps = {
  displayDateFormat: DISPLAY_FORMAT,
  onRangeChange: noop,
  defaultSelection: '',
  placeholder: '',
  showCalendarIcon: true,
  startDateValue: '',
  endDateValue: '',
  availableRanges: [
    'today',
    'yesterday',
    'this_month',
    'last_month',
    'this_week',
    'last_30_days',
    'last_7_days',
    'last_week',
    'lifetime',
    'custom_range',
  ],
};

export default DateRangePicker;
