import React, { createRef, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from '../../../../context/AppContext';
import useCategoriesOptions from '../../../../hooks/useCategoriesOptions';
import useCustomSnackbar from '../../../../hooks/useCustomSnackbar';
import useModal from '../../../../hooks/useModal';
import useProducts from '../../../../hooks/useProductsAdvance';
import useProductImages from '../../../../hooks/useProductImages';
import useUploadMedia from '../../../../hooks/useUploadMedia';
import CategoryModal from '../../../../shared/CategoryModalNew';
import ProductImages from '../../../Products/ProductImages';
import ActionButtons from '../ActionButtons';
import CategoryBundleTypeTopSection from '../CategoryBundleTypeTopSection';
import SelectProductsModal from '../../../../Orders/CreateOrder/ProductCard/SelectProductsModal';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_SIZES,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../../../Subscription/constants';
import CustomAccordion from '../../../../shared/CustomAccordian';
import { CATEGORY_BANNER_SCREEN_SIZES as options } from '../../../Categories/constants';
import ToggleButtonGroup from '../../../../shared/ToggleButtonGroup';
import FormImageUpload from '../../../../shared/Form/FormImageUpload';
import ImageLoader from '../../../../shared/ImageLoader';
import { useStyles } from '../../../Categories/CategoryInformation';
import { PlusIconGray } from '../../../../SvgIcon';
import Nudge from '../../../../Subscription/components/Nudge';
import BundleContent from '../BundleContent';
import DeleteModal from '../../../../shared/DeleteModal';
import { catalogueUrls } from '../../../../Urls';
import { PRODUCT_URL_ADVANCE } from '../../../../ApiUrls';
import { APPLY_BUNDLE_ITEMS_DEFAULT } from '../../constants';
import { deepCompareIgnoreKeys } from '../../../../Appearance/utils';
import FaqModal from '../../../../Plugins/Faq/FaqModal';
import SpinnerButton from '../../../../shared/SpinnerButton';
import BounceLoader from '../../../../shared/BounceLoader';
import FaqContainer from '../../../../Plugins/Faq/FaqContainer';
import FaqCard from '../../../../Plugins/Faq/FaqCard';
import useProductFAQ from '../../../../hooks/useProductFAQ';
import DisplayAttributes from '../../../Products/AdvanceCatalogue/DisplayAttributes';
import SeoForm from '../../../Products/Variants/seoForm';
import { COMPONENT_TYPE } from '../../../constants';
import slugify from 'slugify';

const MixAndMatchBundleForm = ({
  isSubmitting,
  type,
  form,
  values,
  isFaqPluginExists,
  faqData,
  setFaqData,
  onActionButtonClick,
  isNewBundle = true,
  initialValues,
  bundleDetails,
  hasDataLoaded,
  setHasDataLoaded,
  setIsUnsavedChanges,
  attributeProps,
  isBundleDetailsLoading = false,
  isSeoPluginInstalled = false,
}) => {
  const history = useHistory();
  const [selectedFAQId, setSelectedFAQId] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const categoryContext = useCategoriesOptions({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesWhole, setSelectedCategoriesWhole] = useState([]);
  const [uploadingArray, setUploadingArray] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showDeleteConfirm, setDeleteConfirm] = useState(false);
  const fileUploadRef = createRef();
  const [openBannerAccord, setOpenBannerAccord] = useState(false);
  const [currentCategoryTab, setCurrentCategoryTab] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();
  const { business, isVendor, fetchBusinessDetails } = useAppContext();
  const { uuid: storeId } = business;
  const { uploading: bannerUploading, upload: bannerUpload } = useUploadMedia(
    {}
  );
  const styles = useStyles();
  const isMobile = options[1].value === currentCategoryTab;

  const [bannerImage, setBannerImage] = useState('');
  const [bannerImageMobile, setBannerImageMobile] = useState('');

  const {
    isOpen: isProductsModalOpen,
    openModal: openProductsModal,
    closeModal: closeProductsModal,
  } = useModal();

  const {
    isOpen: isCategoryModalOpen,
    openModal: openCategoryModal,
    closeModal: closeCategoryModal,
  } = useModal();

  const {
    openModal: openFaqModal,
    isOpen: isFaqModalOpen,
    closeModal: closeFaqModal,
  } = useModal();

  const productsProps = useProducts(true, {
    is_active: true,
    hide_bundles: true,
  });

  const {
    products: allProducts,
    loading,
    hasMore,
    pageNumber,
    setPageNumber,
    onSearch,
    search,
  } = productsProps;

  const { categorySpcificMetafieldList = [] } = attributeProps;

  const {
    loading: faqLoading,
    submitting: faqSubmitting,
    fetchProductFaqs,
    faqList,
    deleteProductFaq,
  } = useProductFAQ();

  const clearTempValues = () => {
    setSelectedFAQId(null);
    form.mutators.modifyFormField('question', '');
    form.mutators.modifyFormField('answer', '');
  };

  const deleteLocalFaq = (selectedFaq, closeModal) => {
    const currentFaqIndex = faqData.findIndex(
      (each) => String(each.id) === String(selectedFaq)
    );

    const preValues = faqData.slice(0, currentFaqIndex);
    const postValues = faqData.slice(currentFaqIndex + 1);
    const finalValues = [...preValues, ...postValues];

    setFaqData(finalValues);

    closeModal();
  };

  const closeFAQModalHandler = () => {
    clearTempValues();
    closeFaqModal();
  };

  const {
    images,
    addImage,
    removeImage,
    updateImage,
    fetchImageSuggestions,
    loading: isLoading,
    setImages,
    selectedImages,
    setSelectedImages,
  } = useProductImages([]);

  const imagesRef = useRef({
    initialImages: [],
  });

  const isUploading = (val) => {
    setUploadingArray((prevState) => {
      const tempArray = [...prevState];
      const index = tempArray.findIndex((obj) => obj.id === val.id);
      if (index > -1) {
        tempArray[index] = val;
        return [...tempArray];
      }
      return [...tempArray, val];
    });
  };

  const handleBannerImageMobile = (imgUrl) => {
    form.mutators.modifyFormField('banner_images_mobile', [imgUrl]);
    setBannerImageMobile(imgUrl);
  };
  const handleBannerImage = (imgUrl) => {
    form.mutators.modifyFormField('banner_images', [imgUrl]);
    setBannerImage(imgUrl);
  };

  const removeBannerImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    fileUploadRef.current.value = '';
    if (isMobile) {
      setBannerImageMobile('');
      form.mutators.modifyFormField('banner_images_mobile', []);
    } else {
      setBannerImage('');
      form.mutators.modifyFormField('banner_images', []);
    }
    setOpenBannerAccord(true);
  };

  const onTabChange = (optionValue) => {
    setCurrentCategoryTab(optionValue);
  };

  const handleDeleteClick = () => {
    // TrackEvent(EVENT_PROD_DELETE_BTN, business);
    setDeleteConfirm(true);
  };

  const closeDeleteModal = () => {
    setDeleteConfirm(false);
  };

  const afterDeleteProduct = () => {
    // TrackEvent(EVENT_PROD_DELETE_CONFIRM_BTN, business);
    enqueueSnackbar('Bundle deleted successfully.');
    history.replace(catalogueUrls.bundlesPath);
    fetchBusinessDetails();
  };

  const handleSelectedProductsData = (products = []) => {
    if (products?.length > 0) {
      const productsCategories = products
        ?.map((eachProduct) => eachProduct?.categories_data)
        ?.flat();

      form.mutators.modifyFormField(
        'categories_data',
        Array.from(new Set([...productsCategories]))
      );

      form.mutators.modifyFormField(
        'bundle_applicability',
        APPLY_BUNDLE_ITEMS_DEFAULT.SPECIFIC_PRODUCTS
      );

      form.mutators.modifyFormField(
        'product_names',
        products.map((item) => item?.product?.name ?? item?.name).join(', ')
      );

      form.mutators.modifyFormField('products', products);

      const matchedProducts = [];

      for (const selectedProduct of products) {
        const productUUID = selectedProduct.uuid;

        for (const product of allProducts) {
          const foundInSkus = product.skus.find(
            (sku) => sku.uuid === productUUID
          );

          if (foundInSkus) {
            matchedProducts.push(foundInSkus);
            break;
          }
        }
      }

      const productBundleApplicability =
        products?.map((each) => {
          return {
            content_object: each,
            object_id: each?.id,
            content_type: 'productsku',
            id: each?.id,
            quantity: each?.quantity,
          };
        }) ?? [];

      form.mutators.modifyFormField('product_bundle_applicability', [
        ...productBundleApplicability,
      ]);
    }
  };

  const handleSelectedProducts = (productOptions = []) => {
    setSelectedProducts(productOptions);
    handleSelectedProductsData(productOptions);
  };

  const isSeoExpanded =
    !isNewBundle &&
    (values?.seo_data?.title?.length > 0 ||
      values?.seo_data?.description?.length > 0);

  useEffect(() => {
    if (uploadingArray.find((obj) => obj.isUploading === true)) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [uploadingArray]);

  useEffect(() => {
    if (images?.length > 0) {
      form.mutators.modifyFormField(
        'all_images',
        images?.map((each) => each?.imgUrl)
      );
    }
  }, [images]);

  useEffect(() => {
    if (initialValues?.all_images?.length > 0) {
      setImages((prevImages) => {
        const allImages = initialValues?.all_images || [];
        imagesRef.current.initialImages = [
          ...prevImages,
          ...allImages.map((image, i) => ({
            id: uuidv4(),
            imgUrl: image,
          })),
        ];

        return [
          ...prevImages,
          ...allImages.map((image, i) => ({
            id: uuidv4(),
            imgUrl: image,
          })),
        ];
      });
    }
  }, []);

  useEffect(() => {
    if (initialValues?.categories?.length > 0) {
      setSelectedCategories(initialValues.categories);
    }

    if (
      !isNewBundle &&
      !hasDataLoaded &&
      initialValues?.banner_images?.length > 0
    ) {
      handleBannerImage(initialValues?.banner_images?.[0]);
    }
    if (
      !isNewBundle &&
      !hasDataLoaded &&
      initialValues?.banner_images_mobile?.length > 0
    ) {
      handleBannerImageMobile(initialValues?.banner_images_mobile?.[0]);
    }

    if (
      !isNewBundle &&
      !hasDataLoaded &&
      initialValues?.product_bundle_applicability?.length > 0 &&
      (initialValues.product_bundle_applicability?.[0]?.content_type ===
        'product' ||
        initialValues.product_bundle_applicability?.[0]?.content_type ===
          'productsku') &&
      allProducts?.length > 0
    ) {
      const selectedBundleProducts =
        initialValues.product_bundle_applicability?.map((eachProduct) => {
          if (eachProduct?.content_type === 'product') {
            const matchedProduct = allProducts?.find(
              (product) => product?.id === eachProduct?.content_object?.id
            );

            return { ...matchedProduct, sku: matchedProduct?.skus?.[0]?.uuid };
          }

          if (eachProduct?.content_type === 'productsku') {
            const matchedSkuProduct = allProducts?.find(
              (product) =>
                product?.id === eachProduct?.content_object?.product_id
            );

            const productSku = matchedSkuProduct?.skus?.find(
              (eachSku) => eachSku.id === eachProduct?.id
            );

            return {
              id: eachProduct?.id,
              ...productSku,
              image: eachProduct?.content_object?.product_image,
              name: eachProduct?.content_object?.product_name,
              sku: eachProduct?.content_object?.uuid,
              quantity: eachProduct?.quantity,
            };
          }
        });

      setSelectedProducts(selectedBundleProducts?.filter(Boolean));
      handleSelectedProductsData(selectedBundleProducts?.filter(Boolean));
      setHasDataLoaded(true);
    } else if (
      !isNewBundle &&
      !hasDataLoaded &&
      initialValues.product_bundle_applicability?.[0]?.content_type ===
        'productcategory'
    ) {
      setHasDataLoaded(true);
    }
    if (initialValues?.categories_data?.length > 0) {
      setSelectedCategoriesWhole(initialValues?.categories_data);
    }
  }, [initialValues?.product_bundle_applicability, allProducts]);

  useEffect(() => {
    if (hasDataLoaded || isNewBundle) {
      setIsUnsavedChanges(
        !deepCompareIgnoreKeys(values, initialValues, [
          'categories_data',
          'product_bundle_discount_value',
          'product_names',
          'products',
          'product_bundle_applicability',
        ])
      );
    }
  }, [values]);

  useEffect(() => {
    setFaqData(faqList);
  }, [faqList]);

  useEffect(() => {
    if (isFaqPluginExists && bundleDetails.id) {
      fetchProductFaqs(bundleDetails.id);
    }
  }, [bundleDetails]);

  return (
    <>
      <div className="bundle-form-wrap">
        <CategoryBundleTypeTopSection
          form={form}
          values={values}
          type={type}
          openCategoryModal={openCategoryModal}
          openProductsModal={openProductsModal}
          bundleDetails={bundleDetails}
          setSelectedCategoriesWhole={setSelectedCategoriesWhole}
          setSelectedCategories={setSelectedCategories}
          isBundleDetailsLoading={isBundleDetailsLoading}
        />

        <ProductImages
          images={images}
          searchQuery={values.name}
          addImage={addImage}
          removeImage={removeImage}
          updateImage={updateImage}
          isUploading={isUploading}
          isLoading={isLoading}
          fetchImageSuggestions={fetchImageSuggestions}
          setImages={setImages}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          fetchedImages={images}
          mediaTitle="Bundle Media"
          addDivider
          noBottomMargin
          id="bundle-media"
        />

        {isFaqPluginExists && (
          <div id="faq">
            <FaqCard
              isExpanding
              showBtn="true"
              btnText="Add an FAQ"
              sectionHeader={<h3 className="section-text-4">FAQs</h3>}
            >
              <section className="w-100 mx8">
                <div className="pos-rel mt-32">
                  <p className="section-text-14 c-black-3 mt2">
                    Add frequently asked questions to the product
                  </p>
                  <div className="hr-line mt12 mx0" />
                </div>
                {loading ? (
                  <BounceLoader />
                ) : (
                  <FaqContainer
                    faqList={faqData}
                    openFaqModal={openFaqModal}
                    setSelectedFAQId={setSelectedFAQId}
                    editProductFAQ
                    productID={bundleDetails?.id}
                    deleteProductFaq={deleteProductFaq}
                    deleteLocalFaq={deleteLocalFaq}
                    isProductOrCategoryFAQ
                  />
                )}

                <div className="d-flex-c-c mt24 mb8">
                  <SpinnerButton
                    className="btn-outline-4 text-8 c-black-1 py8 px20"
                    type="button"
                    showAnimation
                    onClick={openFaqModal}
                  >
                    Add {faqData?.length <= 0 ? 'an' : 'another'} FAQ
                  </SpinnerButton>
                </div>
              </section>
            </FaqCard>
            <FaqModal
              open={isFaqModalOpen}
              closeModal={closeFAQModalHandler}
              values={values}
              form={form}
              faqData={faqData}
              setFaqData={setFaqData}
              productID={bundleDetails?.id}
              selectedFAQId={selectedFAQId}
              setSelectedFAQId={setSelectedFAQId}
              clearTempValues={clearTempValues}
            />
          </div>
        )}

        <CustomAccordion
          defaultExpanded={bannerImage || bannerImageMobile || openBannerAccord}
          className="new-cat-form"
          heading={
            <div>
              <h4 className="section-text-5 mb4">Banner</h4>
              <p className="c-black-3">
                Add a bundle banner at the top of product listing.
              </p>
            </div>
          }
          id="bundle-banner"
          details={
            <div className="w-100">
              <ToggleButtonGroup
                options={options}
                defaultSelected={options[0].value}
                onChange={onTabChange}
                className="mb24"
                maintainToggleState
              />
              <FormImageUpload
                form={form}
                hasCropper={false}
                hideInput
                onUploadSuccess={(imgUrl) => {
                  if (isMobile) handleBannerImageMobile(imgUrl);
                  else handleBannerImage(imgUrl);
                }}
                name={isMobile ? 'banner_images_mobile' : 'banner_images'}
                ref={fileUploadRef}
                upload={bannerUpload}
                uploading={bannerUploading}
              >
                {isMobile ? (
                  <div
                    className={`${styles.categoryUploadBanner} ${
                      bannerImageMobile ? 'has-image' : ''
                    }`}
                    onClick={() => fileUploadRef.current.click()}
                  >
                    {bannerUploading ? (
                      <ContentLoader speed={2} width="100%" height="128">
                        <rect
                          x={0}
                          y={0}
                          rx={4}
                          ry={4}
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    ) : (
                      <>
                        {bannerImageMobile ? (
                          <>
                            <ImageLoader
                              src={bannerImageMobile}
                              width="100%"
                              height={236}
                              className={styles.banner}
                            />
                            <div
                              className={`banner-overlay ${styles.bannerOverlay}`}
                            >
                              <div className="d-flex-c-c flex-column full-w">
                                <div
                                  className={`section-text-8 text-white px16 py6 cur-p ${styles.removeBanner}`}
                                  onClick={removeBannerImage}
                                >
                                  Remove this banner
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className={styles.uploadBanner}>
                            <PlusIconGray width={20} height={20} />
                            <p className="c-black-3 section-text-7 mt8 mb4">
                              Add bundle banner
                            </p>
                            <p className="text-10 c-gray-1">
                              Recommended size (328px*200px)
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className={`${styles.categoryUploadBanner} ${
                      bannerImage ? 'has-image' : ''
                    }`}
                    onClick={() => fileUploadRef.current.click()}
                  >
                    {bannerUploading ? (
                      <ContentLoader speed={2} width="100%" height="128">
                        <rect
                          x={0}
                          y={0}
                          rx={4}
                          ry={4}
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    ) : (
                      <>
                        {bannerImage ? (
                          <>
                            <ImageLoader
                              src={bannerImage}
                              width="100%"
                              height={236}
                              className={styles.banner}
                            />
                            <div
                              className={`banner-overlay ${styles.bannerOverlay}`}
                            >
                              <div className="d-flex-c-c flex-column full-w">
                                <div
                                  className={`section-text-8 text-white px16 py6 cur-p ${styles.removeBanner}`}
                                  onClick={removeBannerImage}
                                >
                                  Remove this banner
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className={styles.uploadBanner}>
                            <PlusIconGray width={20} height={20} />
                            <p className="c-black-3 section-text-7 mt8 mb4">
                              Add bundle banner
                            </p>
                            <p className="text-10 c-gray-1">
                              Recommended size (1296px*236px)
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </FormImageUpload>
              {isMobile && (
                <div className="mt24 d-none">
                  <Nudge
                    text="Add this banner for a better viewing experience on mobile."
                    size={NUDGE_SIZES.SMALL}
                    type={NUDGE_TYPES.PRIMARY}
                    variant={NUDGE_VARIANTS.INFO}
                    iconVariant={NUDGE_ICON_VARIANTS.BLUE}
                  />
                </div>
              )}
            </div>
          }
        />

        <BundleContent form={form} values={values} />
        {categorySpcificMetafieldList.length > 0 && !isNewBundle && (
          <DisplayAttributes
            values={values}
            form={form}
            formProps={{ form, values }}
            isExpanded={attributeProps.categorySpcificMetafieldList}
            attributeProps={attributeProps}
            attributeExist
            isCreateableDropdown
            isLargeFileText
            isBundle
            metaList={attributeProps?.categorySpcificMetafieldList}
          />
        )}

        {isSeoPluginInstalled && (
          <SeoForm
            form={form}
            path="bundles"
            componentType={COMPONENT_TYPE.BUNDLE}
            values={values}
            defaultExpanded={isSeoExpanded}
            isNew={isNewBundle}
            imageFieldName="seo_data.imageUrl"
            imageCardText={{
              title: '',
              itemLink: `/bundles/${slugify(values?.slug || '', {
                lower: true,
              })}`,
            }}
            initSlug={values?.slug}
            isEditFlow={!isNewBundle}
            outerClasses="mt0-i"
          />
        )}
        {isProductsModalOpen && (
          <SelectProductsModal
            open={isProductsModalOpen}
            closeModal={closeProductsModal}
            selected={selectedProducts}
            setSelectedProducts={handleSelectedProducts}
            productsProps={productsProps}
            conditionOnSelectedProducts
            selectedProductsCondition={closeProductsModal}
          />
        )}
        {isCategoryModalOpen && (
          <CategoryModal
            canSelectedCategoriesBeEmpty
            categoryContext={categoryContext}
            initiallySelectedCategoriesWhole={selectedCategoriesWhole}
            handleClose={({
              categories,
              selectedCategoriesWhole: selectedCategoriesWholeArg,
            }) => {
              setSelectedCategories(categories);
              setSelectedCategoriesWhole(selectedCategoriesWholeArg);
              const formattedCategories = selectedCategoriesWholeArg.map(
                (category) => ({
                  id: category.id,
                  name: category.name,
                  uuid: category.uuid,
                  slug: category.slug,
                  type: 'category',
                  images: category?.image ?? '',
                })
              );
              form.mutators.modifyFormField('categories', formattedCategories);
              form.mutators.modifyFormField(
                'categories_data',
                formattedCategories
              );
              form.mutators.modifyFormField(
                'category_names',
                formattedCategories.map((item) => item.name).join(', ')
              );

              // set these when specific category flow is to be handled

              // const categoriesBundleApplicability =
              //   categories?.map((each) => ({
              //     object_id: each,
              //     content_type: 'productcategory',
              //   })) ?? [];

              // form.mutators.modifyFormField(
              //   'product_bundle_applicability',
              //   categoriesBundleApplicability
              // );
              closeCategoryModal();
            }}
            hideAddCategoryBtn
            open={isCategoryModalOpen}
            selected={selectedCategories}
            setselectedCategories={setSelectedCategories}
          />
        )}
        <div className="ml-auto">
          <ActionButtons
            onSubmit={() => onActionButtonClick(values)}
            isNewBundle={isNewBundle}
            bundleDetails={bundleDetails}
            initialValues={initialValues}
            enqueueSnackbar={enqueueSnackbar}
            handleDeleteClick={handleDeleteClick}
            submitting={isSubmitting}
          />
        </div>
      </div>

      <DeleteModal
        open={showDeleteConfirm}
        closeModal={closeDeleteModal}
        deleteUrl={
          bundleDetails?.uuid
            ? PRODUCT_URL_ADVANCE({ productUId: bundleDetails?.uuid, storeId })
            : ''
        }
        afterDelete={afterDeleteProduct}
        headerText="Delete Bundle"
        content="Do you really want to delete this bundle from your store?"
      />
    </>
  );
};

export default MixAndMatchBundleForm;
