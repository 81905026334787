import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useCountry } from '../context/CountryProvider';
import cx from 'classnames';
import useLocalStorage from '../hooks/useLocalStorage';
import useOrderUpdate from '../hooks/useOrderUpdate';
import { FormInput, FormNumberInput } from '../shared/Form';
import { required } from '../shared/Form/Validation';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { countriesByPhoneCode } from '../utils/countries';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useEnterPressAction from '../hooks/useEnterPressAction';

const SendPaymentLinkModal = ({
  open,
  closeModal,
  uuid,
  refetch,
  orderTotal = 0,
}) => {
  const { sendDiscountedPaymentLink, isSubmitting } = useOrderUpdate(uuid);
  const { currencySymbol, country } = useCountry();
  const [formValues, setFormValues] = useState(null);
  const phoneCode = country.phone_code;
  const [agentInfo, setAgentInfo] = useLocalStorage('agent_info', {});
  const { enqueueSnackbar } = useCustomSnackbar();

  const onSuccess = () => {
    refetch();
    closeModal();
  };

  const onSubmit = (values) => {
    const data = {
      discount: values.discount_amount,
      customer_name: values.customer_name,
      agent_name: values.agent_name,
      agent_number: values.agent_number,
      agent_email: values.agent_email,
    };
    const agentInfoLocalStorage = {
      agent_name: values.agent_name,
      agent_number: values.agent_number,
      agent_email: values.agent_email,
    };
    setAgentInfo(JSON.stringify(agentInfoLocalStorage));
    sendDiscountedPaymentLink(data, onSuccess);
  };

  const getAgentObj = () => {
    if (Object.keys(agentInfo).length === 0) return;
    return JSON.parse(agentInfo);
  };

  useEnterPressAction(open, () => onSubmit(formValues));

  return (
    <Modal open={open} closeModal={closeModal}>
      <h2 className="section-text-4 mb12">Send payment link</h2>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          phone_code: phoneCode,
          ...getAgentObj(),
        }}
        render={({ handleSubmit, values }) => {
          const phoneCodeData = countriesByPhoneCode.find(
            (eachCountry) =>
              Number(eachCountry.value) === Number(values.phone_code)
          );
          const isValidMobileNo = (value) => {
            if (
              value.length < phoneCodeData.mobile_length_min ||
              value.length > phoneCodeData.mobile_length_max
            ) {
              return false;
            }
            return true;
          };
          setFormValues(values);
          return (
            <form>
              <FormNumberInput
                labelText="Discount amount"
                name="discount_amount"
                placeholder="Enter discount amount"
                className="mb24"
                prependText={currencySymbol}
                required
                showRequired
                validate={required()}
                isAllowed={({ floatValue }) => {
                  if (floatValue > orderTotal) {
                    enqueueSnackbar(
                      'Discount cannot be greater than order total',
                      'error'
                    );
                    return false;
                  }
                  return true;
                }}
              />
              <FormInput
                labelText="Customer name"
                placeholder="Enter customer name"
                name="customer_name"
                className="mb24"
                required
                showRequired
                validate={required()}
              />
              <FormInput
                labelText="Agent name"
                placeholder="Enter agent name (optional)"
                name="agent_name"
                className="mb24"
                required
                showRequired
                validate={required()}
              />
              <div className="row">
                <FormInput
                  className={cx('country-flag-select mb24 col-sm-6 pr6', {
                    'country-1': String(values.phone_code).length === 1,
                    'country-2': String(values.phone_code).length === 2,
                    'country-3': String(values.phone_code).length === 3,
                  })}
                  labelText="Agent number"
                  placeholder="Enter agent number"
                  name="agent_number"
                  pattern="(^$)|(^\d+$)"
                  maxLength={phoneCodeData.mobile_length_max}
                  required
                  showRequired
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (isValidMobileNo(values.mobile || ''))
                        onSubmit(values, () => form.reset());
                    }
                  }}
                />
                <FormInput
                  labelText="Agent email"
                  placeholder="Enter agent email"
                  name="agent_email"
                  className="mb24 col-sm-6 pl6"
                  required
                  showRequired
                  validate={required()}
                />
              </div>
              <div className="text-center">
                <SpinnerButton onClick={handleSubmit} isLoading={isSubmitting}>
                  Send
                </SpinnerButton>
              </div>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default SendPaymentLinkModal;
