import { isNotEmptyOrNull } from '../shared/Form/Shared';

export const toTitleCase = (str = '') =>
  str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0)?.toUpperCase() + txt.substr(1).toLowerCase();
  }) ?? str;

export const joinWords = (listOfWords = []) => {
  let result = '';
  listOfWords.forEach((item, index) => {
    if (index === 0) {
      result = item;
    } else if (index < listOfWords.length - 1) {
      result = `${result}, ${item}`;
    } else {
      result = `${result} & ${item}`;
    }
  });
  return result;
};

export const convertSnakeToCamelCase = (str = '') =>
  str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const snakeCase = (string) =>
  string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');

export const capitaliseFirst = (txt = '') => {
  if (txt && txt[0] && txt[0].match(/[a-z]/i)) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  } else {
    return txt;
  }
};

export const sentenceCase = (string) =>
  string
    .split(' ')
    .map((str) => capitaliseFirst(str))
    .join(' ');

export const ellipsizeText = (text, limit = 20, showDots = true) => {
  if (typeof text === 'string') {
    if (text.length < limit) {
      return text;
    }
    return `${text.substring(0, limit)}${showDots ? '...' : ''}`;
  }
};

export const matchWildcard = (string, rule) => {
  const escapeRegex = (s) => s.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
  return new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`).test(
    string
  );
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const parseSellerUsername = (inputString = '') => {
  const result = {
    isPhoneNumber: false,
    countryCode: '',
    mobileNumber: '',
    email: '',
  };

  // Regular expression to match email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regular expression to match phone numbers in the format "+91-9898989898"

  if (emailRegex.test(inputString)) {
    result.email = inputString;
  } else if (inputString.startsWith('+')) {
    result.isPhoneNumber = true;
    const [countryCode, mobileNumber] = inputString.split('-');
    result.countryCode = countryCode;
    result.mobileNumber = mobileNumber;
  }

  return result;
};

export const padZeros = (number) => {
  if (Number(number) < 10 && isNotEmptyOrNull({ value: number })) {
    return `0${number}`;
  }

  return number;
};
export const escapeRegExp = (str = '') =>
  str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */

/* eslint-disable */
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const checkPlural = (word, size = 0) => {
  const s = `${size} ${word}`;
  return size > 1 ? `${s}s` : `${s}`;
};

export const singularize = (size, word) => {
  return size > 1 || size.includes('-') ? word : word.slice(0, -1);
};

export const truncateString = (text = '', length = 60) => {
  if (text.length <= length) return text;
  return `${text.substring(0, length)}...`;
};
