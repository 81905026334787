import { useState } from 'react';
import axios from 'axios';
import qs from 'query-string';

import { getRequest, patchRequest, postRequest } from '../utils/http';
import {
  PRODUCTS_URL_BASIC,
  PRODUCTS_BULK_ACTIONS,
  PRODUCTS_URL_SELLER_FRONT,
} from '../ApiUrls';
import useLocalStorage from './useLocalStorage';
import {
  LIST_VIEW,
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../constants';
import { PRODUCTS_PAGE_SIZE } from '../Catalogue/constants';
import { noop } from '../utils';
import useCustomSnackbar from './useCustomSnackbar';

const useProducts = (
  initialParams = {},
  storeId,
  pageSize = PRODUCTS_PAGE_SIZE
) => {
  const [layout, setLayout] = useLocalStorage('__product_view__', LIST_VIEW);
  const [search, setSearch] = useState('');
  const [prevSearchText, setPrevSearchText] = useState('');
  const [pageNumber, setCurrentPageNumber] = useState(1);
  const [isUpdatingOrderProducts, setIsUpdatingOrderProducts] = useState(false);
  const [productsCount, setProductsCount] = useLocalStorage(
    'productsCount',
    null
  );
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [showOutStock, setShowOutStock] = useState(false);
  const [category, setCategory] = useState(-1);
  const [warehouseFilter, setWarehouseFilter] = useState('ALL');
  const [tags, setTags] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [staff, setStaff] = useState([]);
  const [stockStatus, setStockStatus] = useState([]);

  const [orderingKey, setOrderingKey] = useState(
    initialParams?.ordering ?? 'created_at'
  );

  const [orderingDirection, setOrderingDirection] = useState(
    initialParams?.direction ?? SORTING_ORDER_DESCENDING
  );

  const { enqueueSnackbar } = useCustomSnackbar();

  const setPageNumber = (value, reason = '') => {
    // uncomment for debugging
    // console.log({ value, reason });

    setCurrentPageNumber(value);
  };

  const toggleOrderingSort = (key, orderingKey, orderDir) => {
    if (loading) {
      return;
    }
    setPageNumber(1, 'sort');
    if (orderDir) {
      setOrderingKey(key);
      setOrderingDirection(orderDir);
      return;
    } else {
      if (orderingKey !== key) {
        setOrderingKey(key);
        setOrderingDirection(SORTING_ORDER_DESCENDING);
      } else if (orderingDirection === SORTING_ORDER_DESCENDING) {
        setOrderingDirection(SORTING_ORDER_ASCENDING);
      } else {
        setOrderingKey('created_at'); // reset case
        setOrderingDirection(SORTING_ORDER_DESCENDING);
      }
    }
  };

  const onSearch = (text) => {
    setPageNumber(1, 'search');
    const curSearch = search;
    setPrevSearchText(curSearch);
    setSearch(text);
  };

  const tagPayload = tags?.map((each) => each?.id).filter(Boolean);
  const brandPayload = productBrands?.map((each) => each?.id).filter(Boolean);

  const fetchProducts = (
    page = null,
    categoryId = null,
    isInfiniteLoading = false,
    successCb = noop
  ) => {
    if (loading) {
      return false;
    }
    let cancel;
    setLoading(true);

    const params = {
      page_size: pageSize,
      pop_fields: 'variants_data',
      page: page || pageNumber,
      tags: tagPayload,
      brands: brandPayload,
      vendors: staff,
      stock_statuses: stockStatus,
      category: [categoryId],
      ...initialParams,
    };
    if (showOutStock) {
      params.in_stock = false;
    }
    if (search !== '') {
      params.search = search;
    }
    if (category !== -1) {
      params.category = category;
    }
    if (Number.isInteger(warehouseFilter)) {
      params.warehouse = warehouseFilter;
    }

    if (orderingKey !== '') {
      params.ordering = `${
        orderingDirection === SORTING_ORDER_DESCENDING ? '-' : ''
      }${orderingKey}`;
    }

    getRequest({
      url: PRODUCTS_URL_SELLER_FRONT(storeId),
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        const { count, results } = res;
        setProductsCount(count);
        successCb(results);
        if (page === 1 || !isInfiniteLoading) {
          setHasMore(results.length < count);
          setProducts(results);
        } else {
          setHasMore(products.length + results.length < count);
          setProducts((prev) => [...prev, ...results]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isCancel(e)) {
          return false;
        }
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const refetchProducts = (currentPage = 1) => {
    setPageNumber(currentPage, 'refetch');
    fetchProducts();
  };

  const filterByCategory = (cat) => {
    setPageNumber(1, 'fitler');
    setProducts([]);
    setCategory(cat);
  };

  const filterByWarehouse = (value) => {
    setPageNumber(1, 'warehouse');
    setProducts([]);
    setWarehouseFilter(value);
  };

  const updateSpecificProductCb = (product = {}) => {
    const index = products.findIndex((each) => each.uuid === product.uuid);
    products[index] = { ...product };
    setProducts([...products]);
  };

  const updateOrderOfProducts = (
    updatedProducts = [],
    categoryId,
    callback = noop
  ) => {
    const transformedProducts = updatedProducts.map((el, index) => ({
      id: el.id,
      name: el?.name ?? '',
      position: index,
    }));
    setIsUpdatingOrderProducts(true);
    patchRequest({
      url: PRODUCTS_URL_BASIC,
      data: {
        products: transformedProducts,
        category: categoryId,
      },
    })
      .then(() => {
        setIsUpdatingOrderProducts(false);
        callback();
        enqueueSnackbar('Products rearranged successfully.');
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong.', 'error');
        callback();
      });
  };

  const updateBulkProducts = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    postRequest({
      url: PRODUCTS_BULK_ACTIONS,
      data: payload,
    })
      .then((res) => {
        enqueueSnackbar('Bulk update is being processed', 'info');
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error ||
          e?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(noop);
  };

  return {
    loading,
    layout,
    products,
    setProducts,
    hasMore,
    pageNumber,
    showOutStock,
    setLayout,
    setShowOutStock,
    setPageNumber,
    fetchProducts,
    refetchProducts,
    search,
    setSearch,
    onSearch,
    prevSearchText,
    setPrevSearchText,
    productsCount,
    filterByCategory,
    orderingKey,
    orderingDirection,
    toggleOrderingSort,
    updateSpecificProductCb,
    warehouseFilter,
    filterByWarehouse,
    tags,
    setTags,
    staff,
    setStaff,
    productBrands,
    setProductBrands,
    stockStatus,
    setStockStatus,
    updateOrderOfProducts,
    isUpdatingOrderProducts,
    setIsUpdatingOrderProducts,
    updateBulkProducts,
  };
};

export default useProducts;
