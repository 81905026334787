import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import cx from 'classnames';

import { useOrdersContext } from '../context/OrdersProvider';
import useModal from '../hooks/useModal';

import DateRangePicker from '../shared/DatePicker/DateRangePicker';
import SearchFilter from '../shared/SearchFilter';
import TagsModal from '../shared/Tags/TagsModal';
import ColumnsFilter from './ColumnsFilter';
import SpecialFilters from './SpecialFilters';
import SortOrders from './components/SortOrders';

import { deepClone } from '../Appearance/utils';
import { formatServerDate } from '../utils/date';
import { noop } from '../utils';
import { GRID_VIEW } from '../constants';
import {
  DEBOUNCING_DELAY,
  TAGS_FOR,
  PENDING_STATUS,
  SELECT_ORDERS_MAP,
} from './constants';
import OrdersSelectedFilters from './OrdersSelectedFilters';
import DownloadOrdersReport from './DownloadOrdersReport';
import SpinnerButton from '../shared/SpinnerButton';
import PendingOrdersModal from './PendingOrdersModal';
import ConfirmModal from '../shared/ConfirmModal';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

const OrdersFilters = ({
  hasReadOnlyAccess = false,
  updateUrlOnPageUpdate = noop,
  search,
  resetOrderParams,
  availableTags,
}) => {
  const ordersContext = useOrdersContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const {
    orderParams,
    updateOrderParams,
    orderTableColumns,
    handleToggleColumn,
    ORDER_COLUMNS_PREFERENCE_KEY,
    orderTagsCount,
    setOrderTags,
    orderListingView,
    constructParams,
    canDownloadReport,
    orders,
    bulkOrderAction,
    submitting,
    refetch,
  } = ordersContext;
  const [selectedFilters, setSelectedFilters] = useState(
    deepClone(orderParams)
  );

  const {
    isOpen: isTagsModalOpen,
    openModal: openTagsModal,
    closeModal: closeTagsModal,
  } = useModal();
  const {
    isOpen: showPendingOrdersModal,
    openModal: openPendingOrdersModal,
    closeModal: closeSelectOrdersModal,
  } = useModal();
  const {
    isOpen: confirmModalOpen,
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
  } = useModal();
  const {
    isOpen: isSelfShip,
    openModal: openSelfShipModal,
    closeModal: closeSelfShipModal,
  } = useModal();
  const [acceptModalOpen, setAcceptModalOpen] = useState(null);
  const [selectedActionState, setSelectedActionState] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [unselectedOrders, setUnselectedOrders] = useState({});
  const [isAllOrderChecked, setAllOrderChecked] = useState(true);
  const [selectedAllOrdersCount, setSelectedAllOrdersCount] = useState(0);
  const [isPendingStatus, setIsPendingStatus] = useState(false);
  const openAcceptModal = (value = true) => setAcceptModalOpen(value);
  const closeAcceptModal = () => openAcceptModal(null);
  const debounceSearch = useCallback(
    debounce(DEBOUNCING_DELAY, (text, setSearchArg) => setSearchArg(text)),
    []
  );

  const updateFilters = (isChecked, filterName, value, isArray = false) => {
    if (isChecked) {
      setSelectedFilters((pre) => {
        const prevValue = deepClone(pre);
        const [parentKey, filterKey] = filterName.split('.');
        const existingValue = filterKey
          ? prevValue[parentKey][filterKey]
          : prevValue[parentKey];
        const updatedValue = isArray
          ? [...new Set(existingValue || []), value]
          : value;
        if (filterKey) {
          prevValue[parentKey][filterKey] = updatedValue;
        } else prevValue[parentKey] = updatedValue;
        return {
          ...prevValue,
          page: 1,
        };
      });
    } else {
      setSelectedFilters((pre) => {
        const prevValue = deepClone(pre);
        const [parentKey, filterKey] = filterName.split('.');
        if (isArray) {
          if (filterKey) {
            prevValue[parentKey][filterKey] = prevValue[parentKey][
              filterKey
            ].filter((each) => each !== value);
          } else {
            prevValue[parentKey] = prevValue[parentKey].filter(
              (each) => each !== value
            );
          }
        } else if (!isArray) {
          if (filterKey) {
            delete prevValue[parentKey][filterKey];
          } else {
            delete prevValue[parentKey];
          }
        }
        return {
          ...prevValue,
          page: 1,
        };
      });
    }
  };

  const handleDateRangeChange = (startDate, endDate, selection) => {
    updateOrderParams(
      {
        page: 1,
        startDate: startDate ? formatServerDate(startDate.toDate()) : '',
        endDate: endDate ? formatServerDate(endDate.toDate()) : '',
        selection,
      },
      'date range'
    );
    updateUrlOnPageUpdate(1);
  };

  const onSelectSubmit = (
    orderIds = [],
    excludeOrderList = [],
    orderCount = 0,
    allChecked = false
  ) => {
    closeSelectOrdersModal();
    setSelectedAllOrdersCount(orderCount);
    // setSelectedOrders(orderIds);
    setUnselectedOrders(excludeOrderList);
    setAllOrderChecked(allChecked);
    openAcceptModal();
  };
  useEffect(() => {
    if (orderParams.status === PENDING_STATUS) {
      setSelectedActionState(orderParams.status);
    }
  }, [orderParams.status]);

  const handleBulkAction = (updateData = {}) => {
    const payload = { ...updateData, status_filter: [selectedActionState] };

    if (isAllOrderChecked) {
      if (unselectedOrders.length) {
        payload.orders = Object.keys(unselectedOrders)
          .filter((key) => unselectedOrders[key])
          .map(Number);
        payload.action = 'exclude';
      }
    } else if (selectedOrders.length !== selectedAllOrdersCount) {
      payload.orders = Object.keys(selectedOrders)
        .filter((key) => selectedOrders[key])
        .map(Number);
      payload.action = 'include';
    }

    bulkOrderAction(
      payload,
      () => {
        let selectedCount = selectedOrders.length;
        if (isAllOrderChecked) {
          selectedCount =
            selectedAllOrdersCount === selectedOrders.length ||
            selectedOrders.length === orders.length
              ? selectedAllOrdersCount
              : selectedAllOrdersCount - unselectedOrders.length;
        }
        enqueueSnackbar(
          `${selectedCount} order${selectedCount > 1 ? 's' : ''} ${
            SELECT_ORDERS_MAP[selectedActionState].nextAction
          } successfully!`
        );
        closeAcceptModal();
        closeConfirmModal();
        closeSelfShipModal();
        refetch();
      },
      () => enqueueSnackbar('Something went wrong!', 'error')
    );
  };
  return (
    <div
      className={cx('d-flex-c-s mb16', {
        'pt32 shift-down': orderListingView === GRID_VIEW,
      })}
    >
      <div className="d-flex align-center">
        <SearchFilter
          placeholder="Order ID, phone or a name..."
          className="orders-search-filter mr10"
          searchText={orderParams.search}
          onFilter={(search, shouldUpdate = true) => {
            if (shouldUpdate) {
              updateOrderParams(
                {
                  search,
                  page: 1,
                  ...(search
                    ? {
                        startDate: '',
                        endDate: formatServerDate(moment()),
                        selection: 'lifetime',
                      }
                    : {}),
                },
                'search'
              );
              updateUrlOnPageUpdate(1, 'search');
            }
          }}
          resetParams={() => resetOrderParams({}, 'reset params')}
          minLength={2}
        />

        <OrdersSelectedFilters
          availableTags={availableTags}
          updateUrlOnPageUpdate={updateUrlOnPageUpdate}
        />
      </div>
      <div className="d-flex-c-c gap12">
        {orderParams.status === PENDING_STATUS && (
          <SpinnerButton
            className="special-filter-btn"
            onClick={openPendingOrdersModal}
          >
            Bulk accept orders
          </SpinnerButton>
        )}
        {canDownloadReport && (
          <DownloadOrdersReport
            skipDownload
            filters={constructParams(orderParams)}
          />
        )}

        {!hasReadOnlyAccess && orderListingView !== GRID_VIEW && (
          <ColumnsFilter
            tableColumns={orderTableColumns}
            handleToggleColumn={handleToggleColumn}
            preferenceKey={ORDER_COLUMNS_PREFERENCE_KEY}
            tagsCount={orderTagsCount}
          />
        )}

        <SortOrders />

        <SpecialFilters
          applyNewFilter={(newFilter) => {
            updateOrderParams(newFilter, 'special filter');
            updateUrlOnPageUpdate(1, 'special filter');
          }}
          updateFilters={updateFilters}
          orderTagsCount={orderTagsCount}
          openTagsModal={openTagsModal}
          isTagsModalOpen={isTagsModalOpen}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />

        {search === '' && (
          <DateRangePicker
            className="orders-time-picker"
            defaultSelection={orderParams.selection || 'lifetime'}
            endDateValue={orderParams.endDate}
            placeholder="Lifetime"
            onRangeChange={handleDateRangeChange}
            showCalendarIcon={false}
            startDateValue={orderParams.startDate}
          />
        )}
      </div>

      {isTagsModalOpen && (
        <TagsModal
          open={isTagsModalOpen}
          closeModal={closeTagsModal}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          updateFilters={updateFilters}
          debounceSearch={debounceSearch}
          tagsType={TAGS_FOR.ORDER}
          setOrderTags={setOrderTags}
        />
      )}

      {showPendingOrdersModal && (
        <PendingOrdersModal
          open={showPendingOrdersModal}
          closeModal={closeSelectOrdersModal}
          onSubmit={onSelectSubmit}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          unselectedOrders={unselectedOrders}
          setUnselectedOrders={setUnselectedOrders}
          isAllOrderChecked={isAllOrderChecked}
          setAllOrderChecked={setAllOrderChecked}
          {...SELECT_ORDERS_MAP?.[selectedActionState]}
        />
      )}

      {!!acceptModalOpen && (
        <ConfirmModal
          open={!!acceptModalOpen}
          // svgIcon={<ConfirmModalIcon className="header-svg-icon" />}
          headerText={`Accept ${Object.keys(selectedOrders).length} order${
            Object.keys(selectedOrders).length > 1 ? 's' : ''
          }`}
          confirmText="Yes, accept"
          content={`Do you want to accept ${
            Object.keys(selectedOrders).length
          } order${Object.keys(selectedOrders).length > 1 ? 's' : ''}?`}
          onCancel={closeAcceptModal}
          onConfirm={() => handleBulkAction()}
          confirmBtnClass="btn-success-4"
          loading={submitting}
          hideCancel
        />
      )}
    </div>
  );
};

export default OrdersFilters;
