import { FormNumberInput } from '../Form';
import { debounce } from 'throttle-debounce';
import { padZeros } from '../../utils/string';
import { useCallback, useState, useEffect } from 'react';
import cx from 'classnames';
import { noop } from '../../utils';

const useLongPress = (callback = () => {}, ms = 300) => {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
};

const TimePickerNumberStepper = ({
  form,
  values,
  initialValue = 0,
  limit = 0,
  inputName = '',
  isDateSame,
  currentTime,
  ampm,
  hours,
  minutes,
  defaultAMPM,
  formValues,
  handleIsAllowed = noop,
  setAMPM = noop,
  setIsDisabled,
}) => {
  const [focus, setFocus] = useState(false);

  const onIncrement = () => {
    if (values[inputName] === undefined) {
      form.mutators.modifyFormField(inputName, 1);
    } else if (values[inputName] < limit) {
      if (inputName === 'hours') {
        if (ampm === 'am' && values[inputName] == '11') setAMPM('pm');
        else if (ampm === 'pm' && values[inputName] == '11') {
          setAMPM('am');
        }
      }
      form.mutators.modifyFormField(
        inputName,
        padZeros(Number(values[inputName]) + 1)
      );
    } else if (values[inputName] == limit) {
      if (inputName === 'hours') {
        form.mutators.modifyFormField(inputName, '01');
      } else {
        form.mutators.modifyFormField(inputName, '00');
      }
    }
  };

  const onDecrement = () => {
    if (
      (inputName === 'hours' && values[inputName] > 1) ||
      (inputName === 'minutes' && values[inputName] > 0)
    ) {
      form.mutators.modifyFormField(
        inputName,
        padZeros(Number(values[inputName]) - 1)
      );
    } else {
      if (inputName === 'hours') {
        if (ampm === 'am' && values[inputName] == '01') setAMPM('pm');
        else if (ampm === 'pm' && values[inputName] == '01') {
          setAMPM('am');
        }
      }
      form.mutators.modifyFormField(inputName, limit);
    }
  };

  const debounceHandleIsAllowed = useCallback(
    debounce(800, handleIsAllowed),
    []
  );

  const onLongPressIncrement = useLongPress(onIncrement, 300);
  const onLongPressDecrement = useLongPress(onDecrement, 300);

  return (
    <div
      className={cx('number-stepper-container', {
        'number-stepper-focus': focus,
      })}
      onClick={() => {
        setFocus(true);
        setIsDisabled(true);
      }}
      onBlur={() => setFocus(false)}
      onMouseLeave={() => {
        setTimeout(() => {
          setIsDisabled(false);
        }, 500);
      }}
    >
      <div className="timepicker-time">
        <FormNumberInput
          name={inputName}
          initialValue={initialValue}
          onChange={(e) => {
            debounceHandleIsAllowed(
              e,
              ampm,
              setAMPM,
              values,
              formValues,
              form,
              inputName,
              isDateSame,
              defaultAMPM,
              hours,
              minutes,
              currentTime
            );
          }}
          isAllowed={(val) => {
            const { value } = val;
            return value <= limit;
          }}
          className="w-100 p0 timepicker-editable-input"
          onBlur={() => {
            if (values[inputName]?.length === 1) {
              form.mutators.modifyFormField(
                inputName,
                padZeros(Number(values[inputName]))
              );
            }
          }}
          value={padZeros(Number(values[inputName]))}
          max={limit}
          maxLength={2}
        />
      </div>
      <div className="stepper-divider">
        <button
          type="button"
          className="timepicker-stepper-btn cur-p up"
          onClick={() => {
            onIncrement();
          }}
          {...onLongPressIncrement}
        />
        <button
          type="button"
          className="timepicker-stepper-btn cur-p down"
          onClick={() => {
            onDecrement();
          }}
          {...onLongPressDecrement}
        />
      </div>
    </div>
  );
};

export default TimePickerNumberStepper;
