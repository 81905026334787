import React from 'react';
import { Image, Breathing } from 'react-shimmer';
import Placeholder from '../../images/placeholder-with-backdrop.jpg';
import { noop } from '../utils';
import { updateCDN } from '../utils/file';
import FileUploadManager from './FileUploadManager';

const ImageLoader = ({
  src,
  height,
  width,
  fallbackImage = null,
  className,
  alt = '',
  onLoad = noop,
  breathingClass = '',
  name = '',
  isAttribute = false,
  useCdn = true,
  cdnImgWidth,
  cdnImgHeight,
  ...rest
}) => (
  <Image
    src={updateCDN(src, cdnImgHeight ?? height, cdnImgWidth ?? width, useCdn)}
    alt={alt}
    fallback={
      <Breathing
        width={width}
        height={height}
        className={`${className} ${breathingClass}`}
      />
    }
    fadeIn
    errorFallback={() =>
      fallbackImage ||
      (isAttribute ? (
        <FileUploadManager name={name} />
      ) : (
        <img
          src={Placeholder}
          alt={alt}
          className={className}
          width={width}
          height={height}
          {...rest}
        />
      ))
    }
    onLoad={onLoad}
    NativeImgProps={{ className, ...rest }}
  />
);

export default ImageLoader;
