import React, { useState } from 'react';
import Countdown from 'react-countdown';
import OtpOptionsModal from './OtpOptionsModal';
import useModal from '../../hooks/useModal';
import { CALL_MODE, WHATSAPP_MODE } from './constants';
import { EVENT_OB_RESEND_CALL, EVENT_OB_RESEND_WA } from '../../events';
import { TrackEvent } from '../../utils/analytics';

const getTwoDigit = (timer) => (timer <= 9 ? `0${timer}` : timer);

const ResentOtpTimer = (props) => {
  const { handleResend, showModeOptions = true, otpInterval = 30000 } = props;
  const [timerDate, setTimerDate] = useState(Date.now() + otpInterval);
  const { isOpen, openModal, closeModal } = useModal();

  const onResendOtpLink = (mode) => {
    if (mode === CALL_MODE) {
      TrackEvent(EVENT_OB_RESEND_CALL);
    } else if (mode === WHATSAPP_MODE) {
      TrackEvent(EVENT_OB_RESEND_WA);
    }
    setTimerDate(Date.now() + otpInterval);
    handleResend(mode);
    closeModal();
  };

  const onResendClick = () => {
    if (showModeOptions) {
      return openModal();
    }
    setTimerDate(Date.now() + otpInterval);
    handleResend();
  };

  return (
    <>
      <Countdown
        key={timerDate}
        date={timerDate}
        renderer={({ minutes, seconds, completed }) => {
          if (completed) {
            return (
              <span>
                <span>Didn't get the code? </span>
                <span
                  className="resend-otp-link anchor-1"
                  onClick={onResendClick}
                >
                  Resend OTP
                </span>
              </span>
            );
          }
          return (
            <div className="resend-otp-timer">
              {getTwoDigit(minutes)}:{getTwoDigit(seconds)}
            </div>
          );
        }}
      />
      <OtpOptionsModal
        title="Get your OTP on"
        open={isOpen}
        closeModal={closeModal}
        handleCall={() => onResendOtpLink(CALL_MODE)}
        handleWhatsApp={() => onResendOtpLink(WHATSAPP_MODE)}
      />
    </>
  );
};

export default ResentOtpTimer;
