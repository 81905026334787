import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import qs from 'query-string';

import { getRequest, postRequest } from '../utils/http';
import { PRODUCTS_URL_ADVANCE, SEO_TAG_GENERATOR_URL } from '../ApiUrls';
import useLocalStorage from './useLocalStorage';
import {
  LIST_VIEW,
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../constants';
import { useAppContext } from '../context/AppContext';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';
import { isNotEmptyOrNull } from '../shared/Form/Shared';
import { seoGeneratorUrl } from '../Manage/ProductDescription/constants';

const DEFAULT_PARAMS = {
  category: -1,
  orderingDirection: '',
  orderingKey: null,
  page: 1,
  search: '',
  showOutStock: false,
  warehouseFilter: 'ALL',
  tags: [],
};

const useProducts = (canFetch, initialParams = {}, pageSize = 30) => {
  const { business, categories: allCategories } = useAppContext();
  const {
    id: storeId,
    uuid: storeUuid,
    name: storeName,
    address: { state, country_code: country } = {}, // Need to check this is not coming for now
    categories,
    other_category_name: otherCategoryName,
  } = business;
  const { enqueueSnackbar } = useCustomSnackbar();

  const [layout, setLayout] = useLocalStorage('__product_view__', LIST_VIEW);
  const [productsCount, setProductsCount] = useLocalStorage(
    'productsCount',
    null
  );
  const [prevSearchText, setPrevSearchText] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [products, setProducts] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [outOfStockCount, setOutOfStockCount] = useState(0);

  const [payload, setPayload] = useState(DEFAULT_PARAMS);

  const fetchProductsCancelToken = useRef();

  const updatePayload = (newParams, reason) => {
    // use reason to debug the cause for params update
    // console.log({ newParams }, reason);

    // Check if there are any previous pending requests
    if (typeof fetchProductsCancelToken.current !== typeof undefined) {
      fetchProductsCancelToken.current.cancel(
        'Operation canceled due to new request.'
      );
    }
    // Save the cancel token for the current request
    fetchProductsCancelToken.current = axios.CancelToken.source();
    setPayload((prevPayload) => ({ ...prevPayload, ...newParams }));
  };

  const toggleOrderingSort = (key, orderingKey) => {
    if (loading) {
      return;
    }
    setSortLoading(true);
    const { orderingDirection } = payload;
    let newKey = key;
    let newDirection;
    if (orderingKey !== key) {
      newKey = key;
      newDirection = SORTING_ORDER_DESCENDING;
    } else if (orderingDirection === SORTING_ORDER_DESCENDING) {
      newDirection = SORTING_ORDER_ASCENDING;
    } else {
      newKey = ''; // reset case
    }
    updatePayload(
      {
        page: 1,
        orderingDirection: newDirection,
        orderingKey: newKey,
      },
      'toggle direction'
    );
  };

  const onSearch = (text) => {
    const { search } = payload;
    setPrevSearchText(search);
    updatePayload({ page: 1, search: text }, 'search');
  };

  const fetchProducts = useCallback(() => {
    setLoading(true);

    const {
      category,
      orderingDirection,
      orderingKey,
      page,
      search,
      showOutStock,
      warehouseFilter,
      tags,
      staff,
    } = payload;

    const params = {
      page_size: pageSize,
      pop_fields: 'variants_data',
      page,
      tags,
      staff,
      ...initialParams,
    };
    if (initialParams.is_active) {
      params.product_type = [0, 1, 2]; // include bundles in product picker modal
    }
    if (initialParams.hide_bundles) {
      delete params.product_type;
    }
    if (showOutStock) {
      params.in_stock = false;
    }
    if (search !== '') {
      params.search = search;
    }
    if (category !== -1) {
      params.category = category;
    }
    if (Number.isInteger(warehouseFilter)) {
      params.warehouse = warehouseFilter;
    }

    if (orderingKey !== '' && orderingKey !== null) {
      params.ordering = `${
        orderingDirection === SORTING_ORDER_DESCENDING ? '-' : ''
      }${orderingKey}`;
    }

    getRequest({
      url: PRODUCTS_URL_ADVANCE(storeUuid),
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
      options: { cancelToken: fetchProductsCancelToken?.current?.token },
    })
      .then((res) => {
        const { count, results, out_of_stock_count: outStockCount } = res;
        setOutOfStockCount(outStockCount);
        setProductsCount(count);
        if (page === 1) {
          setProducts(results);
          setHasMore(results.length < count);
        } else {
          setHasMore(products.length + results.length < count);
          setProducts((prevResults) => [
            ...new Set([...prevResults, ...results]),
          ]);
        }
        setPaginatedProducts(results);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isCancel(e)) {
          return false;
        }
        if (hasMore) setHasMore(false);
      })
      .finally(() => {
        setSortLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const refetchProducts = () => {
    updatePayload({ page: 1 }, 'refetch');
    fetchProducts();
  };

  const filterByCategory = (cat) => {
    updatePayload({ category: cat, page: 1 }, 'filter by category');
  };

  const filterByWarehouse = (value) => {
    updatePayload({ page: 1, warehouseFilter: value }, 'filter by warehouse');
  };

  const updateSpecificProductCb = (product = {}) => {
    const index = products.findIndex((each) => each.uuid === product.uuid);
    products[index] = { ...product };
    setProducts([...products]);
  };

  const updateTags = (tags) => {
    updatePayload({ tags, page: 1 }, 'update tags');
  };

  const updatePageNumber = () => {
    const { page } = payload;
    updatePayload({ page: page + 1 }, 'scroll');
  };

  const updateSetOutOfStock = (value) => {
    updatePayload({ showOutStock: value, page: 1 }, 'show out of stock');
  };

  const generateSeoTags = (
    type,
    productName,
    blogTitle,
    pageTitle,
    categoryNameValue,
    successCallback = noop
  ) => {
    setIsSubmitting(true);

    const seoTagPayload = {
      product_name: productName || categoryNameValue || blogTitle || pageTitle,
    };

    postRequest({
      url: seoGeneratorUrl,
      data: seoTagPayload,
    })
      .then((res) => {
        if (isNotEmptyOrNull({ value: res?.data }))
          successCallback(type, res.data);
        else enqueueSnackbar('Something went wrong. Try again later.', 'error');

        if (res?.status_code > 499)
          enqueueSnackbar(
            'Unable to generate tags, please try again later after some time',
            'error'
          );
      })
      .catch((err) => {
        enqueueSnackbar(
          'Unable to generate tags, please try again later after some time',
          'error'
        );
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (canFetch) {
      fetchProducts();
    }
  }, [fetchProducts, canFetch]);

  return {
    loading,
    sortLoading,
    isSubmitting,
    layout,
    products,
    hasMore,
    setLayout,
    fetchProducts,
    refetchProducts,
    onSearch,
    prevSearchText,
    setPrevSearchText,
    productsCount,
    outOfStockCount,
    filterByCategory,
    toggleOrderingSort,
    updateSpecificProductCb,
    filterByWarehouse,
    tags: payload.tags,
    setTags: updateTags,
    setPageNumber: updatePageNumber,
    pageNumber: payload.page,
    search: payload.search,
    showOutStock: payload.showOutStock,
    setShowOutStock: updateSetOutOfStock,
    orderingDirection: payload.orderingDirection,
    orderingKey: payload.orderingKey,
    appliedCategoryFilter: payload.category,
    appliedWarehouseFilter: payload.warehouseFilter,
    generateSeoTags,
    paginatedProducts,
  };
};

export default useProducts;
