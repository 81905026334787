import { useState } from 'react';
import { Field } from 'react-final-form';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';

const SelectValueComponent = ({
  form,
  name,
  options: metaOptions,
  handleAddOption,
  submitting = false,
  isCreateableDropdown = false,
  ...restProps
}) => {
  const [dropdownOptions, setDropdownOptions] = useState(metaOptions);
  const [menuOpen, setMenuOpen] = useState(false);

  const createOption = (label) => ({
    label,
    value: label.trim(),
  });

  const renderOptions = () => {
    const options = [...dropdownOptions.map((source) => createOption(source))];
    return options;
  };

  const renderOption = (option) => <span>{option.label}</span>;

  return (
    <Field
      name={name}
      render={() => (
        <CreatableSelect
          blurInputOnSelect
          className={cx('source-select mb0 full-w creatable-select', {
            'createable-attribute-text': isCreateableDropdown,
          })}
          classNamePrefix="custom-select"
          dropdownIndicator
          createOptionPosition="first"
          disabled={submitting}
          formatOptionLabel={renderOption}
          menuIsOpen={menuOpen}
          menuPlacement="bottom"
          formatCreateLabel={(inputValue) => (
            <div className="d-flex-c-s cur-p text-8 py2">
              <span className="c-black-1 text-8">{inputValue}</span>
              <button
                type="button"
                className="btn-no-default text-medium c-purple-1"
                onClick={() => {
                  handleAddOption(inputValue);
                }}
              >
                Add
              </button>
            </div>
          )}
          onBlur={() => {
            setMenuOpen(false);
          }}
          onFocus={() => {
            if (dropdownOptions.length > 0) {
              setMenuOpen(true);
            }
          }}
          onInputChange={(val) => {
            if (val) form.mutators.modifyFormField(name, val);
            if (!val && !dropdownOptions.length) setMenuOpen(false);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              form.mutators.modifyFormField(name, event.target.value);
            } else if (!event.target.value && !dropdownOptions.length) {
              setMenuOpen(false);
            } else setMenuOpen(true);
          }}
          onChange={(option) => {
            if (!dropdownOptions.find((source) => source === option.value)) {
              setDropdownOptions([option.value, ...dropdownOptions]);
            }
            form.mutators.modifyFormField(name, option.value);
            setMenuOpen(false);
          }}
          options={renderOptions()}
          placeholder={
            isCreateableDropdown
              ? 'Search or create value'
              : 'Search or create source'
          }
          {...restProps}
        />
      )}
    />
  );
};

export default SelectValueComponent;
