import React, { createRef } from 'react';
import cx from 'classnames';
import { VideoCameraIcon } from '../SvgIcon';
import { filterFilesByMimeType, filterFilesBySize } from '../utils/file';
import useModal from '../hooks/useModal';
import UploadVideoModal from './UploadVideoModal';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { ACCEPTED_IMAGE_SIZE_MB } from '../constants';

const UploadVideo = (props) => {
  const {
    imgUrl = '',
    accept = 'image/*',
    disabled = false,
    uploadFile,
    uploadCssClass,
    className = '',
    Icon = VideoCameraIcon,
    imgText = '',
    fileSizeLimit = 100000000, // 100 MB
    isVideoAllowed = false,
    setUploadingFiles,
    iconHeight = 50,
    iconWidth = 50,
    textClass = '',
    iconClass = '',
  } = props;
  const fileUploadRef = createRef();
  const {
    isOpen: isAddVideoModalOpen,
    openModal: openAddVideoModal,
    closeModal: closeAddVideoModal,
  } = useModal();
  const { enqueueSnackbar } = useCustomSnackbar();

  let cssClass = uploadCssClass || 'file-image-upload';

  const handleButtonClick = () => {
    fileUploadRef.current.click();
  };

  const handleFileChange = () => {
    const filteredFilesByMimeType = filterFilesByMimeType(
      fileUploadRef.current.files,
      accept
    );
    const filteredFilesBySize = filterFilesBySize(
      filteredFilesByMimeType,
      fileSizeLimit
    );

    if (filteredFilesByMimeType.length === 0) {
      enqueueSnackbar(`Please upload a valid image file!`, 'error');
    } else if (filteredFilesBySize.length === 0) {
      enqueueSnackbar(
        `File size exceeds ${ACCEPTED_IMAGE_SIZE_MB}. Please choose a smaller file and try again.`,
        'error'
      );
    }
    setUploadingFiles(Array.from(fileUploadRef.current.files));
    uploadFile(filteredFilesBySize);
    fileUploadRef.current.value = '';
  };

  const classes = cx(
    cssClass,
    {
      disabled,
      'has-image': !!imgUrl,
    },
    className
  );

  const setWidth = () => (isVideoAllowed ? 36 : iconWidth);
  const setHeight = () => (isVideoAllowed ? 32 : iconHeight);

  return (
    <>
      {isVideoAllowed && (
        <div
          className={classes}
          onClick={(event) => {
            event.stopPropagation();
            openAddVideoModal();
          }}
        >
          <div className="d-flex flex-column align-center">
            {imgUrl ? (
              <img src={imgUrl} alt="" />
            ) : (
              <Icon
                width={setWidth()}
                height={setHeight()}
                className={cx({ 'px4 py8': !iconClass }, iconClass)}
              />
            )}
            <p>{imgText}</p>
            <div
              className={cx(
                'text-10 text-medium cur-p c-black-3 hover-add-video',
                { pt18: !textClass },
                textClass
              )}
            >
              Add video
            </div>
          </div>
        </div>
      )}
      {isAddVideoModalOpen && (
        <UploadVideoModal
          open={isAddVideoModalOpen}
          close={closeAddVideoModal}
          uploadFile={uploadFile}
        />
      )}
    </>
  );
};

export default UploadVideo;
