import React, { useCallback, useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import cx from 'classnames';
import '@brainhubeu/react-carousel/lib/style.css';
import { noop } from '../../utils';
import { ArrowIcon } from '../../SvgIcon';
import ImageLoader from '../../shared/ImageLoader';

const Dot = ({ isActive, onChange = noop }) => (
  <div
    className={cx('carousel-dot', isActive ? 'bg-purple-1' : 'bg-white-2')}
    onClick={onChange}
  />
);

const Thumbnail = ({ src = '', isActive, onChange }) => (
  <div
    className={cx(
      'carousel-thumbnail',
      isActive ? 'opacity-100' : 'opacity-50'
    )}
    onClick={onChange}
  >
    <ImageLoader src={src} className="full-w full-h" alt="thumbnail" />
  </div>
);

const Dots = ({
  number,
  onChange,
  value,
  thumbnails = [],
  isThumbnailsBottom,
}) => {
  const list = [];
  for (let i = 0; i < number; i += 1) {
    if (thumbnails.length > 0) {
      list.push(
        <Thumbnail
          isActive={value === i}
          key={`dot-${i}`}
          onChange={() => onChange(i)}
          src={thumbnails[i]}
        />
      );
    } else {
      list.push(
        <Dot
          isActive={value === i}
          key={`dot-${i}`}
          onChange={() => onChange(i)}
        />
      );
    }
  }
  return (
    <div
      className={cx(
        'd-flex-c-c',
        isThumbnailsBottom ? 'mt32' : 'thumbnail-bottom'
      )}
    >
      {list}
    </div>
  );
};

const Arrow = ({ right = false, ...restProps }) => (
  <div
    {...restProps}
    className={cx('arrowContainerStyle', right ? 'r-24' : 'l-24')}
  >
    <ArrowIcon className={`${right ? 'rotate-270' : 'rotate-90'}`} />
  </div>
);

let intervalId;

const ImageCarousel = ({
  slides,
  thumbnails = [],
  selectedIndex = 0,
  isThumbnailsBottom = false,
  isAutoPlayOn = true,
  intervalTime = 4000,
}) => {
  const INTERVAL_TIME = intervalTime;
  const [value, setValue] = useState(selectedIndex);
  const number = slides.length;

  const getNumber = (val, add) => {
    const sum = (val + add) % number;
    if (sum < 0) {
      return number - 1;
    }
    return sum;
  };

  const resetTimer = (add = 1) => {
    if (isAutoPlayOn) {
      clearInterval(intervalId);
      intervalId = setInterval(
        () => setValue((val) => getNumber(val, add)),
        INTERVAL_TIME
      );
    }
  };

  useEffect(() => {
    resetTimer();
    return () => clearInterval(intervalId);
  }, []);

  const onChange = (res) => {
    if (typeof res === 'number') {
      setValue(res);
      resetTimer();
      return;
    }
    if (res === 'left') {
      setValue((val) => getNumber(val, -1));
      resetTimer();
    } else {
      setValue((val) => getNumber(val, 1));
      resetTimer();
    }
  };

  const handleKeyboardKeys = useCallback(
    (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          onChange('left');
          break;
        case 'ArrowRight':
          onChange('right');
          break;
        default:
          break;
      }
    },
    [onChange]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardKeys);
    return () => {
      document.removeEventListener('keydown', handleKeyboardKeys);
    };
  }, [handleKeyboardKeys]);
  const isLeftArrowShown = slides.length !== 0 && value !== 0;
  const isRightArrowShown = slides.length !== 0 && value !== slides.length - 1;
  return (
    <div
      className={cx('full-h pos-rel w-100', {
        p24: thumbnails.length === 0,
        'd-flex-c-c': !isThumbnailsBottom,
      })}
    >
      {isLeftArrowShown && <Arrow onClick={() => onChange('left')} />}
      {isRightArrowShown && <Arrow right onClick={() => onChange('right')} />}
      <Carousel
        value={value}
        slides={slides}
        onChange={setValue}
        animationSpeed={1000}
      />
      {thumbnails.length > 0 ? (
        <Dots
          number={thumbnails.length}
          thumbnails={thumbnails}
          value={value}
          onChange={onChange}
          isThumbnailsBottom
        />
      ) : (
        <Dots value={value} onChange={onChange} number={number} />
      )}
    </div>
  );
};

export default ImageCarousel;
