import React, { memo } from 'react';
import { components } from 'react-select';

import Icon from '../Icon';
import { DownArrow16Icon } from '../../SvgIcon';

export const DropdownIndicator = memo((props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  const dropdownArrowDirectionStyleq = {
    transform: `rotate(${menuIsOpen ? 180 : 0}deg)`,
  };
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DownArrow16Icon style={dropdownArrowDirectionStyleq} />
      </components.DropdownIndicator>
    )
  );
});

DropdownIndicator.displayName = 'DropdownIndicator';

export const ClearIndicator = memo(
  (props) =>
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <Icon fontType="close" size="16" />
      </components.ClearIndicator>
    )
);

ClearIndicator.displayName = 'ClearIndicator';

export const NoOptionsMessage = memo((props) => {
  const { children, ...restProps } = props;
  return (
    components.NoOptionsMessage && (
      <components.NoOptionsMessage {...restProps}>
        {children}
      </components.NoOptionsMessage>
    )
  );
});

NoOptionsMessage.displayName = 'NoOptionsMessage';
