import React from 'react';
import {
  EVENT_FBAD_PAY,
  EVENT_FBAD_PAY_SUCCESS,
  EVENT_INSTAAD_PAY,
  EVENT_INSTAAD_PAY_SUCCESS,
} from '../events';

export const CHANNEL_NAMES_STATS_MAP = {
  1: 'SMS MESSAGES SENT',
  2: 'WHATSAPP SENT',
  6: 'EMAILS SENT',
};

export const CREDIT_DISCOUNTS = {
  MARKETING: 25,
};

export const REASON_CREDIT_SUPERADMIN = 'credit_from_superadmin';
export const REASON_CREDIT_PREMIUM_PLAN = 'credit_premium_plan';
export const REASON_CREDIT_PURCHASE = 'credit_purchase';
export const REASON_DEBIT_DUKAAN_SHIP = 'debit_dukaan_ship';
export const REASON_DEBIT_DUKAAN_BULK_SHIP = 'debit_bulk_dukaan_ship';
export const REASON_DEBIT_CAMPAIGN = 'debit_campaign';
export const REASON_CREDIT_REFUND_DELIVERY = 'credit_dukaan_ship_refund';
export const REASON_DEBIT_THEME_PURCHASE = 'debit_theme';
export const REASON_DEBIT_MARKETING = 'debit_marketing';
export const REASON_DEBIT_IVR = 'debit_ivr';
export const REASON_CREDIT_REFERRAL = 'credit_referral';
export const REASON_DEBIT_DELIVERY_RTO_CHARGE = 'debit_delivery_rto_charge';
export const REASON_PICKUP_FAILED = 'pickup_failed';
export const REASON_DEBIT_DELIVERY_CANCEL_CHARGE =
  'debit_delivery_cancel_charge';
export const REASON_DEBIT_RTO_CHARGE = 'debit_delivery_rto_charge';
export const REASON_DEBIT_RTO_CALCULATION = 'debit_rto_calculation';
export const REASON_DEBIT_RECOVERY_ABANDONED_CART =
  'debit_abandoned_cart_recovery';
export const REASON_DEBIT_WAREHOUSE_CREATE = 'warehouse';
export const REASON_CREDIT_PLAN_RENEWAL = 'plan_renewal';
export const REASON_DEBIT_WHITELABEL_WHATSAPP = 'whitelabel_whatsapp';
export const REASON_CREDIT_CAMPAIGN_REFUND = 'credit_campaign_refund';
export const REASON_DEBIT_NOTIFICATION = 'debit_notification';
export const REASON_DEBIT_WEIGHT_DISCREPANCY = 'debit_weight_discrepancy';
export const REASON_DEBIT_RTO_CHARGES = 'debit_rto_charges';
export const REASON_DEBIT_ODA_CHARGES = 'debit_oda_charges';
export const REASON_DEBIT_CHARGE_BACK = 'debit_charge_back';
export const REASON_DEBIT_ORDER_RETURNED = 'debit_order_returned';
export const REASON_DEBIT_ORDER_REFUND = 'debit_order_refund';
export const REASON_DEBIT_DOUBLE_PAYOUT = 'debit_double_payout';
export const REASON_DEBIT_DUKAAN_SERVICE_CHARGES = 'debit_service_charges';
export const REASON_CREDIT_REFUND_DUKAAN_SERVICE_CHARGES =
  'credit_refund_service_charges';

export const REASON_TYPE_MAP = {
  [REASON_CREDIT_SUPERADMIN]: 'Credits from Dukaan',
  [REASON_CREDIT_PREMIUM_PLAN]: 'Credits from Dukaan',
  [REASON_CREDIT_PURCHASE]: 'Wallet Recharge',
  [REASON_DEBIT_DUKAAN_SHIP]: 'Dukaan Delivery',
  [REASON_DEBIT_DUKAAN_BULK_SHIP]: 'Dukaan Bulk Delivery',
  [REASON_DEBIT_CAMPAIGN]: 'Dukaan Marketing',
  [REASON_DEBIT_WAREHOUSE_CREATE]: 'Added Dukaan Warehouse',
  [REASON_CREDIT_REFUND_DELIVERY]: 'Dukaan Delivery Refund',
  [REASON_DEBIT_DELIVERY_CANCEL_CHARGE]: 'Delivery Cancellation Charge',
  [REASON_DEBIT_RTO_CHARGE]: 'RTO Charge',
  [REASON_DEBIT_THEME_PURCHASE]: 'Theme Purchase',
  [REASON_DEBIT_MARKETING]: 'Dukaan Marketing',
  [REASON_DEBIT_IVR]: 'Auto-order Verification',
  [REASON_CREDIT_REFERRAL]: 'Referral Reward',
  [REASON_DEBIT_DELIVERY_CANCEL_CHARGE]: 'Delivery cancellation charge',
  [REASON_DEBIT_DELIVERY_RTO_CHARGE]: 'RTO charge',
  [REASON_DEBIT_RTO_CALCULATION]: 'RTO Prediction Charges',
  [REASON_DEBIT_RECOVERY_ABANDONED_CART]: 'Abandoned cart Recovery',
  [REASON_CREDIT_PLAN_RENEWAL]: 'Dukaan Credits',
  [REASON_DEBIT_WHITELABEL_WHATSAPP]: 'Whitelabel WhatsApp',
  [REASON_CREDIT_CAMPAIGN_REFUND]: 'Dukaan Marketing Refund',
  [REASON_DEBIT_NOTIFICATION]: 'Order Notification',
  [REASON_DEBIT_WEIGHT_DISCREPANCY]: 'Weight Discrepancy',
  [REASON_DEBIT_RTO_CHARGES]: 'RTO Charges',
  [REASON_DEBIT_ODA_CHARGES]: 'ODA Charges',
  [REASON_DEBIT_CHARGE_BACK]: 'Charge back',
  [REASON_DEBIT_ORDER_RETURNED]: 'Order Returned',
  [REASON_DEBIT_ORDER_REFUND]: 'Order Refund',
  [REASON_DEBIT_DOUBLE_PAYOUT]: 'Credits deducted due to double payout',
  [REASON_DEBIT_DUKAAN_SERVICE_CHARGES]: 'Dukaan service charges',
  [REASON_CREDIT_REFUND_DUKAAN_SERVICE_CHARGES]: 'Dukaan service charge refund',
};

export const MIN_CREDITS_TO_ADD = 500;
export const MIN_SUGGSESTED_CREDITS = 2000;

export const SMS_CHANNEL_ID = 1;
export const WHATSAPP_CHANNEL_ID = 2;
export const FACEBOOK_CHANNEL_ID = 3;
export const INSTAGRAM_CHANNEL_ID = 4;
export const APP_CHANNEL_ID = 5;
export const EMAIL_CHANNEL_ID = 6;

export const SOCIAL_CAMPAIGN_IDS = [3, 4];

export const CHANNEL_NAMES = {
  [SMS_CHANNEL_ID]: 'SMS',
  [WHATSAPP_CHANNEL_ID]: 'WhatsApp',
  [FACEBOOK_CHANNEL_ID]: 'Facebook',
  [INSTAGRAM_CHANNEL_ID]: 'Instagram',
  [APP_CHANNEL_ID]: 'App',
  [EMAIL_CHANNEL_ID]: 'Email',
};

export const FAQ_DATA = (type = 'Facebook') => [
  {
    query: `Why should I use ${type} Ads?`,
    answer: `${type} ads will help your business reach the more than 20 crore people of India on ${type}. The advanced Facebook algorithm will show the right ad to your target customer. It will also retarget the individual to convert them to a paying customer for your business and generate more sales.`,
  },
  {
    query: `Do I need to create a ${type} account to run the ad?`,
    answer: `No, the ads will be created and run from Dukaan’s account for your business. You do not need to create or link a ${type} account to run the ad.`,
  },
  {
    query: 'Who will run the ads?',
    answer: `Our in-house ${type} ad experts at Dukaan will run customized ad campaigns on behalf of your business.`,
  },
  {
    query: `Once I have paid the amount for the ${type} ad, do I need to do anything else?`,
    answer:
      'Once the payment is done, the ad campaign will get activated within 24 hours.',
  },
  {
    query: `How do I add money to run ${type} Ads?`,
    answer: `You can use your existing Dukaan credits or pay the amount via Razorpay for the ${type} Ad campaign.`,
  },
  {
    query:
      'Can I use my existing Dukaan credits and pay the difference amount via Razorpay?',
    answer: (
      <span>
        No, you would need to add the difference amount in your Dukaan credits,
        or pay the entire amount via Razorpay.
        <br />
        <br />
        For example, if you have Rs 3000 in Dukaan credits and you want to run a{' '}
        {type} Ad campaign for Rs 5000, you would need to add Rs 2000 to your
        existing Dukaan credits, or, pay Rs 5000 via Razorpay to run the ad. In
        the 2nd case, your existing Dukaan credits would not be used.
      </span>
    ),
  },
  {
    query: 'For what duration will the ad run?',
    answer:
      'You can see the duration your ad will run for by selecting the amount in the budget slider above.',
  },
];

export const SOCIAL_META = {
  [FACEBOOK_CHANNEL_ID]: {
    className: 'facebook-campaign',
    image: '/images/facebook-campaign.svg',
    message: (
      <>
        Reach your potential customers with{' '}
        <span className="text-medium">Facebook Ads</span>
      </>
    ),
    estimatesClickText: 'Clicks',
    estimatesClickTooltipText: 'Number of times an ad is clicked.',
    templateId: 74,
    howToLink:
      'https://help.mydukaan.io/en/articles/6025341-how-do-facebook-ads-work',
    faqs: FAQ_DATA(),
    adEvent: EVENT_FBAD_PAY,
    adEventSuccess: EVENT_FBAD_PAY_SUCCESS,
  },
  [INSTAGRAM_CHANNEL_ID]: {
    className: 'instagram-campaign',
    image: '/images/facebook-campaign.svg',
    message: (
      <>
        Reach your potential customers with{' '}
        <span className="text-medium">Instagram Ads</span>
      </>
    ),
    estimatesClickText: 'Clicks',
    estimatesClickTooltipText: 'Number of times an ad is clicked.',
    howToLink:
      'https://help.mydukaan.io/en/articles/6025076-how-do-instagram-ads-work',
    templateId: 75,
    faqs: FAQ_DATA('Instagram'),
    adEvent: EVENT_INSTAAD_PAY,
    adEventSuccess: EVENT_INSTAAD_PAY_SUCCESS,
  },
  [APP_CHANNEL_ID]: {
    className: 'app-campaign',
    image: '/images/app-campaign.svg',
    message: (
      <>
        Get your <span className="text-medium">Store App</span> installed &
        generate recurring sales from customers
      </>
    ),
    estimatesClickText: 'Installs',
    estimatesClickTooltipText: 'Total number of app installs',
    templateId: 76,
    howToLink: '',
    faqs: [],
  },
  [EMAIL_CHANNEL_ID]: {
    className: 'app-campaign',
    image: '/images/app-campaign.svg',
    message: (
      <>
        Get your <span className="text-medium">Store App</span> installed &
        generate recurring sales from customers
      </>
    ),
    estimatesClickText: 'Installs',
    estimatesClickTooltipText: 'Total number of app installs',
    templateId: 76,
    howToLink: '',
    faqs: [],
  },
};

export const OFFER_TYPE = 'offer_type';
export const CATEGORY = 'category';

export const convertToVariablesInjectedTags = (txt = '', id) => {
  const regex = /\{[^*]+?\}/g;
  return txt.replace(
    regex,
    (pick) =>
      `<span style="background-color: #FAB73B33; font-weight:600" class="${id}_${pick.replaceAll(
        /(\{)|(\})/g,
        ''
      )} editables" >{${pick}}</span>&#8205;`
  );
};

export const convertWhatsappToHtmlTranscode = (txt = '') => {
  const regex = /\*[^*]+?\*/g;
  return txt.replace(
    regex,
    (pick) => `<strong>${pick.replaceAll('*', '')}</strong>`
  );
};

export const OFFER_TYPES_LIST = [
  { name: 'Seasonal', id: 1, slug: 'seasonal' },
  { name: 'Festivals', id: 2, slug: 'festivals' },
  { name: 'New Customers', id: 3, slug: 'new_customers' },
  { name: 'Recurring Customers', id: 4, slug: 'recurring_customers' },
  { name: 'Promotional', id: 5, slug: 'promotional' },
  { name: 'Announcement', id: 6, slug: 'announcement' },
  { name: 'Funny', id: 7, slug: 'funny' },
];

export const CAMPAIGN_STATUS = {
  PENDING: 'pending',
  STARTED: 'started',
  RUNNING: 'running',
  PARTIAL_COMPLETE: 'partial',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const CAMPAIGN_STATUS_TEXT = {
  [CAMPAIGN_STATUS.PENDING]: 'Pending',
  [CAMPAIGN_STATUS.STARTED]: 'Started',
  [CAMPAIGN_STATUS.RUNNING]: 'Running',
  [CAMPAIGN_STATUS.PARTIAL_COMPLETE]: 'Partially Completed',
  [CAMPAIGN_STATUS.COMPLETED]: 'Completed',
  [CAMPAIGN_STATUS.FAILED]: 'Failed',
};

export const STEPS = (selectedCampaign) => {
  if (selectedCampaign === EMAIL_CHANNEL_ID) {
    return ['Campaign design', 'Campaign details', 'Preview & send'];
  }
  return ['Campaign message', 'Campaign details', 'Preview & pay'];
};
