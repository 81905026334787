import { UPLOAD_MEDIA_URL } from '../../ApiUrls';

const imageUploadHandler = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', UPLOAD_MEDIA_URL, true);
    xhr.responseType = 'json';

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: `HTTP Error: ${xhr.status}`, remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        reject(`HTTP Error: ${xhr.status}`);
        return;
      }

      const json = xhr.response;

      if (!json || json.error) {
        reject(`Invalid JSON: ${json}`);
        return;
      }
      const {
        data: { cdnURL, file },
      } = json;
      resolve(cdnURL);
    };

    xhr.onerror = () => {
      reject(
        `Image upload failed due to a XHR Transport error. Code: ${xhr.status}`
      );
    };

    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  });

export default imageUploadHandler;
