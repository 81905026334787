import React from 'react';
import cx from 'classnames';
import { toTitleCase } from '../utils/string';
import useFileDownload from '../hooks/useFileDownload';

const IntlDeliveryDetails = ({
  shipmentDetails,
  isDeliveryDetailsExpanding,
}) => {
  const { downloadFile } = useFileDownload();

  return (
    <div
      className={cx('customer-details order-details-card w-100 border-none', {
        'mt-32': isDeliveryDetailsExpanding,
      })}
    >
      <hr
        className={cx('hr-line mx0 w-100 pos-rel top-8', {
          'd-none': !isDeliveryDetailsExpanding,
        })}
      />
      <div className="customer-details-container">
        <div className="row">
          <div className="col-md-6">
            <p>
              {shipmentDetails?.carrier_service
                ? 'Courier Mode'
                : 'Courier Name'}
            </p>
            <span>
              {shipmentDetails?.carrier_service
                ? toTitleCase(
                    shipmentDetails?.carrier_service?.replace(/_/g, ' ')
                  )
                : shipmentDetails?.carrier?.toUpperCase()}
            </span>
          </div>
          <div className="col-md-6">
            <p>Tracking ID</p>
            <a
              href={shipmentDetails?.tracking_link}
              target="_blank"
              rel="noopener noreferrer"
              className={cx('text-medium', {
                'anchor-1 underline': shipmentDetails?.tracking_link,
              })}
            >
              {shipmentDetails?.awb}
            </a>
          </div>
        </div>
        {shipmentDetails?.label_link && (
          <div className="row">
            <div className="col-md-6">
              <p>Shipping Label</p>
              <div
                className={cx('anchor-1 text-medium underline cur-p', {
                  'text-disabled': !shipmentDetails?.label_link,
                })}
                onClick={() => {
                  if (shipmentDetails?.label_link) {
                    downloadFile(shipmentDetails?.label_link, true);
                  }
                }}
              >
                Download
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntlDeliveryDetails;
