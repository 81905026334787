import React, { useEffect, useState } from 'react';
import SelectProductModal from '../../Appearance/Components/SelectProductModal';
import useModal from '../../hooks/useModal';
import useProducts from '../../hooks/useProductsAdvance';
import { FormInput } from '../../shared/Form';
import { CrossIcon } from '../../SvgIcon';

const SelectedProductsPickerField = ({
  form,
  label,
  prefixName,
  fieldIndex,
  data = [],
  hideBundles = false,
  isAttribute = false,
}) => {
  const {
    products: allProducts,
    loading,
    hasMore,
    setPageNumber,
    onSearch,
  } = useProducts(true, { is_active: true, hide_bundles: hideBundles });

  const {
    isOpen: isProductsModalOpen,
    openModal: openProductsModal,
    closeModal: closeProductsModal,
  } = useModal();

  const [selectedProducts, setSelectedProducts] = useState([]);

  const pickerLabel =
    data.length > 0
      ? `${data.length} product${data.length > 1 ? 's' : ''} selected`
      : '';

  const handleSelect = (selected, e) => {
    const isChecked = e.target.checked;
    const newSelectedProducts = [];
    if (isChecked) {
      newSelectedProducts.push(selected);
      setSelectedProducts([...selectedProducts, ...newSelectedProducts]);
    } else {
      let tempUpdatedProducts = [...selectedProducts];
      tempUpdatedProducts = [
        ...tempUpdatedProducts.filter(
          (selectedProduct) => selectedProduct.id !== selected.id
        ),
      ];
      setSelectedProducts(tempUpdatedProducts);
    }
  };

  useEffect(() => {
    setSelectedProducts(
      isAttribute ? data.map((it) => ({ id: it })) : data?.value ?? []
    );
  }, []);

  return (
    <>
      <FormInput
        labelText={label}
        name={`${prefixName}[${fieldIndex}].product_names`}
        placeholder="Select products"
        onClick={openProductsModal}
        {...(isAttribute ? { value: pickerLabel } : {})}
        readOnly
        appendText={
          <>
            {data?.length || data?.value?.length ? (
              <CrossIcon
                height={16}
                width={16}
                className="cur-p mr8"
                onClick={() => {
                  if (isAttribute) {
                    form.mutators.modifyFormField(prefixName, []);
                  } else {
                    form.mutators.modifyFormField(
                      `${prefixName}[${fieldIndex}].value`,
                      []
                    );
                    form.mutators.modifyFormField(
                      `${prefixName}[${fieldIndex}].product_names`,
                      ''
                    );
                  }
                  setSelectedProducts([]);
                }}
              />
            ) : null}
          </>
        }
      />
      {isProductsModalOpen && (
        <SelectProductModal
          products={allProducts}
          tempSelectedProducts={selectedProducts}
          loading={loading}
          open={openProductsModal}
          handleClose={() => {
            if (isAttribute) {
              form.mutators.modifyFormField(prefixName, data ?? []);
            } else {
              form.mutators.modifyFormField(
                `${prefixName}[${fieldIndex}].value`,
                data?.value ?? []
              );
              form.mutators.modifyFormField(
                `${prefixName}[${fieldIndex}].product_names`,
                data?.value.map((item) => item.name).join(', ')
              );
            }
            closeProductsModal();
          }}
          hasMore={hasMore}
          isPluginSelectProductModal
          setPageNumber={setPageNumber}
          onSearch={onSearch}
          canDisable={false}
          handleOnSelectProducts={(product, e) => handleSelect(product, e)}
          canSelectedProductListBeEmpty
          saveProducts={(productOptions) => {
            const formattedProducts = productOptions.map((product) => ({
              id: product.id,
              name: product.name,
              uuid: product.uuid,
              slug: product.slug,
              type: 'product',
              images: product?.image ?? '',
            }));
            if (isAttribute) {
              form.mutators.modifyFormField(
                prefixName,
                formattedProducts.map((it) => it.id)
              );
            } else {
              form.mutators.modifyFormField(
                `${prefixName}[${fieldIndex}].value`,
                formattedProducts
              );
              form.mutators.modifyFormField(
                `${prefixName}[${fieldIndex}].product_names`,
                productOptions.map((item) => item.name).join(', ')
              );
            }
            closeProductsModal();
          }}
        />
      )}
    </>
  );
};

export default SelectedProductsPickerField;
