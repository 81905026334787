import { AUTH_DOMAIN, ORDER_DOMAIN, STORE_DOMAIN } from '../../constants';

export const authAPIUrl = (url) => `${AUTH_DOMAIN}/api/account/seller/${url}`;

export const storeAPIUrl = (url) => `${STORE_DOMAIN}/api/store/seller/${url}`;

export const orderAPIUrl = (url) => `${ORDER_DOMAIN}/api/order/seller/${url}`;

export const productAPIUrl = (url) =>
  `${STORE_DOMAIN}/api/product/seller/${url}`;

export const couponAPIUrl = (url) => `${STORE_DOMAIN}/api/coupon/seller/${url}`;

export const paymentAPIUrl = (url, baseURL) =>
  `${baseURL || STORE_DOMAIN}/api/payment/seller/${url}`;

export const ensureHttps = (url) => {
  // Check if the URL starts with "http://" or "https://"
  if (url && !url?.startsWith('http://') && !url?.startsWith('https://')) {
    // If not, append "https://"
    url = 'https://' + url;
  }

  return url;
};
