import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import { DukaanIcon } from '../SvgIcon';
import { accountUrls } from '../Urls';
import { noop } from '../utils';

const PremiumContentModal = ({
  heading = 'Upgrade your plan!',
  description = 'You need to upgrade your subscription plan in order to use this feature.',
  link = accountUrls.subscriptionPurchasePath,
  btnCta = 'Explore subscription plans',
  closeModal = noop,
  open = false,
}) => (
  <Modal open={open} className="premium-content-modal" closeModal={closeModal}>
    <div className="d-flex-c-s mb40">
      <DukaanIcon alt="dukaan" height={56} />
    </div>
    <h4 className="section-text-1 mb16">{heading}</h4>
    <p className="text-2 c-black-3 mb48">{description}</p>

    <Link to={link} className="btn-primary-0 upgrade-button">
      {btnCta}
    </Link>
  </Modal>
);

export default PremiumContentModal;
