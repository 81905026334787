import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  CREATE_CREDITS_ORDER,
  CREDIT_TRANSACTIONS_URL,
  CREDIT_TRANSACTION_DETAILS_URL,
} from '../ApiUrls';
import { campaignUrls } from '../Urls';
import { noop } from '../utils';
import Logo from '../../images/icons/logo_white.jpg';
import { numberWithCommas, roundTo } from '../utils/number';
import { useAppContext } from './AppContext';
import { useRazorpayCheckout } from '../Subscription/NewSubscription/PaymentButtons/RazorpayButton';
import { useCountry } from './CountryProvider';
import { getRequest } from '../utils/http';
import useLocalStorage from '../hooks/useLocalStorage';
import { RAZORPAY_API_KEY } from '../constants';
import usePrevious from '../hooks/utils/usePrevious';
import { isUserLoggedIn } from '../utils/user';

const CreditsContext = createContext({
  loading: false,
  isBalanceNegative: false,
  credits: 0,
  creditsToAdd: 0,
  transactions: [],
  setCreditsToAdd: noop,
  addCredits: noop,
  fetchTransactions: noop,
  fetchTransactionDetails: noop,
  generatePaymentOrder: noop,
});

export const useCredits = () => useContext(CreditsContext);

export const TRANSACTION_TYPE_OPTIONS = [
  { label: 'All', value: -1 },
  { label: 'Credit', value: 'credit' },
  { label: 'Debit', value: 'debit' },
];

const CreditsProvider = ({ children }) => {
  const { business, userDetails } = useAppContext();
  const { currencyCode } = useCountry();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [filters, setFilters] = useState({});
  const [type, setType] = useState(TRANSACTION_TYPE_OPTIONS[0]);
  const [orderingKey, setOrderingKey] = useState('');
  const [orderingDirection, setOrderingDirection] = useState(''); // asc or desc
  const [reasonType, setReasonType] = useState('');
  const preCredits = usePrevious(business?.store_wallet?.credit_balance ?? 0);

  const [credits, setCredits] = useState(
    business?.store_wallet?.credit_balance ?? 0
  );
  const [creditsToAdd, setCreditsToAdd] = useLocalStorage('creditsToAdd');

  const [transactions, setTransactions] = useState([]);

  const { name: storeName } = business;
  const { mobile } = userDetails;

  const openCheckout = useRazorpayCheckout({
    url: CREATE_CREDITS_ORDER(business.uuid),
    payload: {
      amount: roundTo(creditsToAdd),
    },
    failureRedirectPath: campaignUrls.addCreditsFailure,
  });

  const handler = () => {
    window.location.href = `${campaignUrls.addCreditsSuccess}?credits=${creditsToAdd}`;
  };

  const toggleOrderingSort = (key) => {
    if (loading) {
      return;
    }
    if (orderingKey !== key) {
      setOrderingKey(key);
      setOrderingDirection('asc');
    } else if (orderingDirection === 'asc') {
      setOrderingDirection('desc');
    } else {
      setOrderingKey(''); // reset case
    }
    setPageNumber(1);
  };

  const generatePaymentOrder = (
    amount = creditsToAdd,
    successHandler = handler,
    forcePayload = null,
    name = 'Dukaan Credits'
  ) => {
    const options = {
      name,
      key: RAZORPAY_API_KEY,
      amount: (Number(amount) * 100).toString(),
      currency: currencyCode,
      description: `Purchasing ${numberWithCommas(
        roundTo(amount || 0, 2)
      )} credits`,
      image: Logo,
      prefill: {
        name: storeName,
        email: 'orders@mydukaan.io',
        contact: mobile,
      },
      handler: successHandler,
      theme: {
        color: '#146eb4',
      },
    };
    openCheckout(options, forcePayload);
  };

  const fetchTransactions = (freshLoad = false) => {
    if (!business.uuid || loading || !isUserLoggedIn()) return;
    setLoading(true);
    let params = {
      page: pageNumber,
      ...filters,
      type: type.value,
      reason_type: reasonType,
    };
    if (orderingKey !== '') {
      params.ordering = `${
        orderingDirection === 'asc' ? '' : '-'
      }${orderingKey}`;
    }
    if (type.value === -1) {
      delete params.type;
    }
    if (freshLoad) {
      params = {
        page: 1,
      };
    }
    getRequest({
      url: CREDIT_TRANSACTIONS_URL(business.uuid),
      data: params,
    })
      .then((res) => {
        const { count, results } = res;
        if (pageNumber === 1) {
          setHasMore(results.length < count);
          setTransactions(results);
        } else {
          setHasMore(transactions.length + results.length < count);
          setTransactions((prevData) => [
            ...new Set([...prevData, ...results]),
          ]);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchTransactionDetails = (txnUuid, cb = noop) => {
    setLoading(true);

    getRequest({
      url: CREDIT_TRANSACTION_DETAILS_URL(business.uuid, txnUuid),
    })
      .then((res) => {
        cb(res.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (business?.store_wallet?.credit_balance !== preCredits)
      setCredits(business?.store_wallet?.credit_balance ?? 0);
    fetchTransactions();
  }, [filters, type, pageNumber, orderingKey, orderingDirection, business]);

  const contextValue = {
    loading,
    isBalanceNegative: credits < 0,
    hasMore,
    credits,
    creditsToAdd,
    transactions,
    filters,
    orderingKey,
    orderingDirection,
    type,
    reasonType,
    setCreditsToAdd,
    generatePaymentOrder,
    fetchTransactions,
    fetchTransactionDetails,
    toggleOrderingSort,
    setPageNumber,
    setFilters,
    setType,
    setReasonType,
  };

  return (
    <CreditsContext.Provider value={contextValue}>
      {children}
    </CreditsContext.Provider>
  );
};

export default CreditsProvider;
