import { useState } from 'react';
import { Shimmer } from 'react-shimmer';
import slugify from 'slugify';
import { DIGITAL_PRODUCTS_MAX_FILESIZE_LIMIT } from '../../../Catalogue/constants';
import useAttribute from '../../../hooks/useAttribute';
import useUploadMedia from '../../../hooks/useUploadMedia';
import { FileIcon } from '../../../SvgIcon';
import { noop } from '../../../utils';
import Modal from '../../Modal';
import SpinnerButton from '../../SpinnerButton';
import UploadImage from '../../UploadImage';
import { isNotEmptyOrNull } from '../Shared';
import EachFile from './EachFile';

const UploadModal = (props) => {
  const {
    allFileUrls = [],
    allowMultipleFiles,
    closeModal,
    files = [],
    metafieldId,
    multipleSelectedFiles = [],
    onUploadSuccess,
    open,
    setFiles = noop,
    setMultipleSelectedFiles = noop,
    setValue = noop,
    value,
    modifyFormField = noop,
  } = props;

  const { uploading, upload } = useUploadMedia({
    acceptTypes: 'all',
  });
  const { updateMetaFields } = useAttribute({
    isFetchMetaFieldTypeList: false,
    isFetchMetaFieldsList: false,
  });
  const [uploadingFiles, setUploadingFiles] = useState([]);

  const removeFile = (index, fileUrl) => {
    setFiles((pre) => pre.filter((el, idx) => idx !== index));
    updateMetaFields({
      id: metafieldId,
      possible_values: allFileUrls.filter((file) => file !== fileUrl),
    });
  };

  const onUpdateClick = (imgFiles) => {
    imgFiles.forEach(async (eachFile) => {
      const { cdnURL } = await upload(eachFile);

      updateMetaFields({
        id: metafieldId,
        possible_values: [...allFileUrls, cdnURL],
      });
      setFiles((pre) => [
        ...pre,
        {
          id: pre.length,
          imgUrl: cdnURL,
          name: slugify(eachFile.name, { lower: true }),
          depth: 0,
          removeFile,
        },
      ]);

      if (allowMultipleFiles) {
        setMultipleSelectedFiles((preSelectedFiles) => [
          ...preSelectedFiles,
          cdnURL,
        ]);
        setValue((preSelectedValues) => [
          ...preSelectedValues,
          slugify(eachFile.name, { lower: true }),
        ]);
      } else {
        setMultipleSelectedFiles([cdnURL]);
        setValue([slugify(eachFile.name, { lower: true })]);
      }
    });
  };

  const handleFileSelect = (file) => {
    if (allowMultipleFiles) {
      const filtered = new Set(multipleSelectedFiles);
      const fileNames = new Set(value);
      if (filtered.has(file.imgUrl)) {
        filtered.delete(file.imgUrl);
        fileNames.delete(file.name);
      } else {
        filtered.add(file.imgUrl);
        fileNames.add(file.name);
      }

      modifyFormField(Array.from(filtered)?.join(','));
      setMultipleSelectedFiles(Array.from(filtered));
      setValue(Array.from(fileNames));
    } else if (multipleSelectedFiles[0] === file.imgUrl) {
      modifyFormField('');
      setMultipleSelectedFiles([]);
      setValue([]);
    } else {
      modifyFormField(file.imgUrl);
      setMultipleSelectedFiles([file.imgUrl]);
      setValue([file.name]);
    }
  };

  return (
    <Modal open={open} closeModal={closeModal} maxWidth="md-760">
      <p className="section-text-4">Choose attribute file</p>

      <div className="mt24">
        <p className="c-black-1 section-text-7 mb16">Upload file</p>
        <UploadImage
          className="md"
          disabled={uploading}
          uploadFile={onUpdateClick}
          accept="*"
          updateAttributeImage
          fileSizeLimit={DIGITAL_PRODUCTS_MAX_FILESIZE_LIMIT}
          multiple={allowMultipleFiles}
          setUploadingFiles={setUploadingFiles}
        />
      </div>

      {files?.length > 0 && (
        <div className="mt24">
          <p className="c-black-1 section-text-7 mb16">
            Uploaded file{files.length > 1 ? 's' : ''} ({files.length})
          </p>
          <div className="product-images-variant">
            {files.map((ele, idx) => (
              <EachFile
                key={idx}
                allowMultipleFiles={allowMultipleFiles}
                file={ele}
                fileId={idx}
                multipleSelectedFiles={multipleSelectedFiles}
                productAttributeFileType
                removeFile={removeFile}
                setValue={setValue}
                setMultipleSelectedFiles={setMultipleSelectedFiles}
                value={value}
                handleFileSelect={handleFileSelect}
              />
            ))}

            {/* Loader with shimmer and uploaded file name */}
            {uploading &&
              uploadingFiles.length > 0 &&
              uploadingFiles.map((item) => (
                <div className="uploaded-image">
                  <Shimmer height={108} width={108} />
                  <div className="attribute-loader-contents">
                    <FileIcon />
                    <span className="attribute-loader-text">{item.name}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      <div className="mt32 d-flex-c-c">
        <SpinnerButton
          onClick={() => {
            const selectedFiles = multipleSelectedFiles
              .filter((item) => isNotEmptyOrNull({ value: item }))
              .join(',');
            onUploadSuccess(selectedFiles);
            closeModal();
          }}
          disabled={multipleSelectedFiles.length === 0}
        >
          Select
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default UploadModal;
