import { withStyles, Tooltip } from '@material-ui/core';

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: `0 2px 6px 0 rgba(26, 24, 30, 0.2)`,
    fontSize: 14,
    lineHeight: 20 / 14,
    padding: 12,
    maxWidth: 300,
    backgroundColor: 'white',
    color: '#1a181e',
  },
}))(Tooltip);
