import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import Slide from '@material-ui/core/Slide';
import { CrossIcon } from '../SvgIcon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const { closeModal, children, maxWidth, ...restProps } = props;

  const dialogProps = {
    ...restProps,
    maxWidth,
    classes: {
      paper: 'fullscreen-dialog-paper',
    },
    BackdropProps: {
      classes: {
        root: 'fullscreen-dialog-backdrop',
      },
    },
  };

  return (
    <Dialog
      {...dialogProps}
      disableEnforceFocus
      fullScreen
      TransitionComponent={Transition}
    >
      <div className="fullscreen-dialog-close-container">
        <CrossIcon onClick={closeModal} color="white" />
      </div>

      {children}
    </Dialog>
  );
}
