const useUpdatedCouponDiscount = () => {
  const calculateUpdatedDiscount = (couponData, cartValue) => {
    if (!couponData) return {};
    const {
      discount_type: type,
      discount_value: value,
      max_discount: maxDiscount,
      min_order_value: minOrderValue,
    } = couponData;

    let couponDiscount = 0;
    let couponApplied = false;

    if (minOrderValue && cartValue < minOrderValue)
      return { couponDiscount, couponApplied };

    couponApplied = true;
    if (type === 0) {
      couponDiscount = (cartValue * value) / 100;
      if (maxDiscount) {
        return {
          couponDiscount: Math.min(couponDiscount, maxDiscount),
          couponApplied,
        };
      }
    } else {
      couponDiscount = value;
    }

    return { couponDiscount, couponApplied };
  };

  return calculateUpdatedDiscount;
};

export default useUpdatedCouponDiscount;
