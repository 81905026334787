import React, {
  memo,
  useCallback,
  useState,
  forwardRef,
  useEffect,
} from 'react';
import { debounce } from 'throttle-debounce';

import { RemoveIcon, SearchIcon } from '../SvgIcon';
import { noop } from '../utils';

const ENTER_KEY = 'Enter';
const BACKSPACE_KEY = 'Backspace';

const SearchFilter = forwardRef(
  (
    {
      onFilter,
      showSearchIcon,
      showCrossIcon,
      onBlur = noop,
      minLength,
      maxLength = 300,
      placeholder,
      className = '',
      searchText = '',
      onResetValue = noop,
      resetParams = noop,
      debounceTime = 500,
      ...rest
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(searchText);

    const handleOnChange = ({ target }) => {
      setInputValue(target.value);
      if (resetParams && target.value === '') {
        resetParams();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFilter = useCallback(
      debounce(debounceTime, false, (value) => {
        onFilter(value);
      }),
      []
    );

    const resetValue = ({ shouldUpdate = true }) => {
      setInputValue('');
      onFilter('', shouldUpdate);
      if (onResetValue) onResetValue();
    };

    const triggerFilter = (event) => {
      const {
        key,
        target: { value },
      } = event;
      if (value && value.length > minLength) {
        debounceFilter(value);
      } else if (
        !value ||
        (key === BACKSPACE_KEY && value === '') ||
        key === ENTER_KEY
      ) {
        if (key === BACKSPACE_KEY && value === '') {
          resetValue({});
          resetParams();
        }
        debounceFilter(value);
      }
    };

    useEffect(
      () => () => {
        resetValue({ shouldUpdate: false });
      },
      []
    );

    useEffect(() => {
      setInputValue(searchText);
    }, [searchText]);

    return (
      <div className={`search-filter ${className}`}>
        {showSearchIcon && <SearchIcon className="search-img" />}
        <input
          type="text"
          value={inputValue}
          onChange={handleOnChange}
          onKeyUp={triggerFilter}
          placeholder={placeholder}
          spellCheck={false}
          onBlur={onBlur}
          maxLength={maxLength}
          ref={ref}
          {...rest}
        />
        {showCrossIcon && !!inputValue?.length && (
          <RemoveIcon
            className="remove-search"
            onClick={() => {
              resetValue({});
              resetParams();
            }}
          />
        )}
      </div>
    );
  }
);

SearchFilter.defaultProps = {
  placeholder: '',
  minLength: 0,
  showSearchIcon: true,
  showCrossIcon: true,
};

SearchFilter.displayName = 'SearchFilter';

export default memo(SearchFilter);
