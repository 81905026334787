import { toLowerCase } from './index';

export const getContrastYIQ = (hexcolor) => {
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 200 ? 'black' : 'white';
};

export const getFormattedColor = (color) => {
  const clr = toLowerCase(color);
  const bgColor = color.length === 7 ? clr : clr.replace('#ff', '#');
  const tickColor = getContrastYIQ(bgColor.replace('#', ''));
  return [bgColor, tickColor];
};
