import React from 'react';
import { Popover } from '@material-ui/core';

import withPopper from './WithPopper';
import useEnterPressAction from '../hooks/useEnterPressAction';

const WithPopover = (WrappedComponent, defaultProps = {}) => {
  const HOC = (props) => {
    const {
      anchorVertical,
      anchorHorizontal,
      transformVertical,
      transformHorizontal,
      popperClass,
      isPopperOpen,
      setPopperOpen,
      anchorEl,
      closePopper,
      ...restProps
    } = props;

    const closePopover = () => {
      if (isPopperOpen && setPopperOpen) setPopperOpen(false);
      closePopper();
      if (restProps.onClosePopper) restProps.onClosePopper();
    };

    useEnterPressAction(isPopperOpen, closePopover);

    return (
      <Popover
        id={popperClass}
        className={popperClass}
        open={isPopperOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: anchorVertical,
          horizontal: anchorHorizontal,
        }}
        transformOrigin={{
          vertical: transformVertical,
          horizontal: transformHorizontal,
        }}
      >
        <WrappedComponent
          {...restProps}
          closePopper={closePopper}
          isPopperOpen={isPopperOpen}
          setPopperOpen={setPopperOpen}
        />
      </Popover>
    );
  };

  HOC.displayName = `withPopover(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  HOC.defaultProps = {
    anchorVertical: 'bottom', // top, center, or bottom
    anchorHorizontal: 'left', // left, center or right
    transformVertical: 'top', // top, center, or bottom
    transformHorizontal: 'left', // left, center or right
    popperClass: 'filter-popper',
    ...defaultProps,
  };

  return withPopper(HOC, HOC.defaultProps);
};

export default WithPopover;
