export const MAX_ADDITION_OF_DELIVERY_FIELD = 50;

export const CHARGE_TYPE = {
  PRICE_BASED: 'price_based',
  WEIGHT_BASED: 'weight_based',
};

export const RESET_NEW_VALUE = {
  range_from: null,
  range_to: null,
  cod_delivery_charge: null,
  online_delivery_charge: null,
  charge_type: CHARGE_TYPE.PRICE_BASED,
};

export const INITIAL_FIXED_DATA = [
  {
    range_from: 0,
    range_to: null, // value for Free delivery above
    cod_delivery_charge: null,
    online_delivery_charge: null,
    charge_type: CHARGE_TYPE.PRICE_BASED,
  },
];

export const INITIAL_VARIABLE_DATA_FOR_INDIA = [
  {
    range_from: 0,
    range_to: 500,
    cod_delivery_charge: null,
    online_delivery_charge: null,
    charge_type: CHARGE_TYPE.PRICE_BASED,
  },
  {
    range_from: 500,
    range_to: null,
    cod_delivery_charge: null,
    online_delivery_charge: null,
    charge_type: CHARGE_TYPE.PRICE_BASED,
  },
];

export const INITIAL_VARIABLE_DATA_FOR_INTERNATIONAL = [
  {
    range_from: 0,
    range_to: null,
    cod_delivery_charge: null,
    online_delivery_charge: null,
    charge_type: CHARGE_TYPE.PRICE_BASED,
  },
  {
    range_from: null,
    range_to: null,
    cod_delivery_charge: null,
    online_delivery_charge: null,
    charge_type: CHARGE_TYPE.PRICE_BASED,
  },
];

export const DUKAAN_DELIVERY_SHIPPING_MODE = 'dukaan_delivery';
export const DUKAAN_DELIVERY_CONNECT_SHIPPING_MODE = 'connect_shipping_account';
export const SHIPROCKET_SHIPPING_MODE = 'shiprocket';
export const SELF_SHIP_SHIPPING_MODE = 'self_hook';

export const SHIPROCKET_API_LINK = 'https://app.shiprocket.in/api-user';

export const DELIVERY_SERVICE_NAMES = {
  [DUKAAN_DELIVERY_SHIPPING_MODE]: 'Dukaan Delivery',
  [DUKAAN_DELIVERY_CONNECT_SHIPPING_MODE]: 'Connected shipping partner',
  [SHIPROCKET_SHIPPING_MODE]: 'Shiprocket',
};

export const ORDER_SHIPMENT_MODES = [
  {
    key: DUKAAN_DELIVERY_CONNECT_SHIPPING_MODE,
    label: 'Connected Shipping Partners & self ship',
  },
  {
    key: DUKAAN_DELIVERY_SHIPPING_MODE,
    label: 'Dukaan Delivery & Self ship',
  },
  {
    key: SHIPROCKET_SHIPPING_MODE,
    label: 'Shiprocket & Self ship',
  },
];
