import {
  AlertTriangle,
  InfoIconFilled,
  NudgeBlueInfoIcon,
  PluginFbPixelIc,
  PluginGoogleAnalyticsIc,
  PluginIntercomIc,
  PluginPrivyIc,
  PluginReviewNRatingIc,
} from '../SvgIcon';

export const DUKAAN_PREMIUM_VIDEO_LINK =
  'https://www.youtube.com/watch?v=YwfImA_95RE';

export const DUKAAN_PREMIUM_WITH_CREDITS_VIDEO_LINK =
  'https://www.youtube.com/watch?v=IaQlj9fQwSc';

export const DUKAAN_PREMIUM_FEATURES_VIDEO_LINK =
  'https://www.youtube.com/watch?v=eMS_fiiWFYU';

export const DUKAAN_ORDER_TIPS_VIDEO_LINK = 'https://youtu.be/p-QMC7ynLyE';

export const DUKAAN_CAMPAIGN_VIDEO_LINK = 'https://youtu.be/F0NBCAedjOs';

export const DUKAAN_DELIVERY_VIDEO_LINK = 'https://youtu.be/x3Txy3SELbs';

export const DUKAAN_CREDITS_VIDEO_LINK =
  'https://www.youtube.com/watch?v=6wE6QpJfMkg';

export const MONTHLY_PERIOD = 'monthly';
export const QUARTERLY_PERIOD = 'quarterly';
export const YEARLY_PERIOD = 'yearly';
export const FORMATTED_PLAN_PERIOD = {
  [QUARTERLY_PERIOD]: 'quarter',
  [MONTHLY_PERIOD]: 'month',
  [YEARLY_PERIOD]: 'year',
};
export const DEFAULT_PLAN_PERIOD = YEARLY_PERIOD;

export const DEFAULT_PADDLE_VENDOR_ID = 51145;

export const GST_PERCENTAGE = 18;

export const SUBSCRIPTION_PLANS_MAP = {
  Free: {
    monthly: 1,
    yearly: 2,
  },
  Starter: {
    monthly: 3,
    yearly: 4,
  },
  Growth: {
    monthly: 5,
    yearly: 6,
  },
  Entrepreneur: {
    yearly: 8,
    monthly: 7,
  },
};

export const PLUGIN_ICONS = [
  {
    Icon: PluginPrivyIc,
    key: 'privy',
  },
  {
    Icon: PluginIntercomIc,
    key: 'intercom',
  },
  {
    Icon: PluginGoogleAnalyticsIc,
    key: 'GA',
  },
  {
    Icon: PluginFbPixelIc,
    key: 'fbpixel',
  },
  {
    Icon: PluginReviewNRatingIc,
    key: 'review',
  },
];

export const entrepreneurFeatures = [
  '1 online store',
  'Unlimited products',
  'Order management',
  'Payment gateways',
  'Free SSL certificate',
  'Discount codes',
  'Themes',
  'Plugins',
  '5 staff accounts',
];

export const agencyFeatures = [
  'Everything in Entrepreneur',
  '10 online stores',
  '10 staff accounts / store',
];

export const customFeatures = [
  'Everything in Agency',
  'Tailor-made themes',
  'Custom CRM integrations',
  'All other needs large enterprise might have',
];

export const intlPlansData = {
  entrepreneur: {
    title: 'Entrepreneur',
    subTitle: 'For small business and influencers.',
    features: entrepreneurFeatures,
  },
  agency: {
    title: 'Agency',
    subTitle: 'For agencies, D2C brands and multiple teams.',
    features: agencyFeatures,
  },
  custom: {
    title: 'Enterprise',
    subTitle: 'Customized plans for large companies.',
    features: customFeatures,
  },
};

const silverFeatures = [
  '2.99% Transaction Fee on Dukaan Pay',
  'Android + iOS Partner App',
  'Additional Pages',
  'Dukaan Credits',
  'Dukaan Delivery',
  'Dukaan Marketing',
  'Discount Coupons',
];

const goldFeatures = [
  '1.49% Transaction Fee on Dukaan Pay',
  '0% Additional Fee on other Payment Providers',
  'Web Partner App',
  'Store APK File',
  'Premium Themes',
  'Custom Domain',
  'Store Analytics',
  'COD RTO Optimizer',
];

const platinumFeatures = [
  '0.99% Transaction Fee on Dukaan Pay',
  'Store AAB File',
  'Free Whitelabeled WhatsApp Subscription',
  'Free Abandoned Cart Automation Messages',
  'Account Advisor',
  '10 Multi-Warehouse Support',
];

export const indianPlansData = {
  silver: {
    title: 'Silver',
    subTitle: 'For new businesses and influencers.',
    features: silverFeatures,
  },
  gold: {
    title: 'Gold',
    subTitle: 'For agencies, D2C brands, and growing businesses.',
    features: goldFeatures,
  },
  platinum: {
    title: 'Platinum',
    subTitle: 'For growing businesses with a comprehensive set of features.',
    features: platinumFeatures,
  },
};

export const SUBSCRIPTION_CYCLE_DAYS = {
  [MONTHLY_PERIOD]: 30,
  [QUARTERLY_PERIOD]: 90,
  [YEARLY_PERIOD]: 365,
};

export const SUBSCRIPTION_CYCLE_MONTHS = {
  [QUARTERLY_PERIOD]: 3,
  [YEARLY_PERIOD]: 12,
};

// plans hierarchy  follows MOBILE_PLAN_LABEL, FIRST_LEVEL_PLAN, SECOND_LEVEL_PLAN
export const MOBILE_PLAN_LABEL = 'Free';
export const FIRST_LEVEL_PLAN = 'Starter';
export const SECOND_LEVEL_PLAN = 'Growth';

export const PLAN_FEATURE_RTO_OPTIMISER_ID = 'rto_optimiser_commission';

export const DOMESTIC_SUBSCRIBE_LABEL = 'Select plan';
export const EARLY_BIRD_DISCOUNT_PERCENT = 20;
export const MODAL_OFFER_TEXT = 'Limited time offer';
export const OFFER_INELIGIBLE_TEXT =
  'This upgrade is not eligible with this discount.';

export const PLAN_ITEM_ID = {
  SILVER: 35,
  GOLD: 32,
  PLATINUM: 39,
};

export const NUDGE_SIZES = {
  DEFAULT: 'default',
  SMALL: 'small',
};

export const NUDGE_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const NUDGE_VARIANTS = {
  WARNING: 'warning',
  INFO: 'info',
  PRIMARY: 'primary',
  ERROR: 'error',
};

export const NUDGE_ICON_VARIANTS = {
  ORANGE: InfoIconFilled,
  BLUE: NudgeBlueInfoIcon,
  RED: AlertTriangle,
};

export const PLATINUM_PLAN_FEATURES = {
  ONLINE_PAYMENT_TRANSACTION_FEE: 'online_payments_transaction_fee',
  FREE_STAFF_ACCOUNT_SEATS: 'staff_account_limit',
  MULTI_WAREHOUSE: 'multi_warehouse',
};

export const HIDE_UPGRADE_BANNER_PLANS = ['DUKAAN_TIER3', 'DUKAANAS_TIER3'];

export const INVOICE_ITEM_TYPES = {
  ORDER_TRANSACTION_FEES: 'ORDER_TRANSACTION_FEES',
};

export const INVOICE_ITEMS_MAP = {
  [INVOICE_ITEM_TYPES.ORDER_TRANSACTION_FEES]:
    'Transaction fees on online orders',
};
