import { withRouter, useHistory } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import { BackArrowWhiteIcon } from '../SvgIcon';

const headerTextStyle = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'max-content',
};

const MobileHeader = (props) => {
  const { headerText, backLink, progressBar } = props;
  const history = useHistory();
  const query = useQuery();
  const redirectUrl = query.get('forceRedirectUrl');

  const handleBack = () => {
    if (redirectUrl) {
      history.push(redirectUrl);
      return;
    }
    if (typeof backLink === 'function') {
      backLink();
    } else if (backLink !== '') {
      history.replace(backLink);
    } else {
      history.goBack();
    }
  };

  return (
    <div className="mobile-header">
      <div className="mobile-header-content">
        <BackArrowWhiteIcon height={24} width={24} onClick={handleBack} />
        <p className="text-1 c-white text-medium" style={headerTextStyle}>
          {headerText}
        </p>
        <p> </p>
      </div>
      {progressBar > 0 && (
        <div
          className="progress-bar"
          style={{
            height: '4px',
            width: `${progressBar}%`,
            backgroundColor: '#FAB73B',
          }}
        />
      )}
    </div>
  );
};

MobileHeader.defaultProps = {
  headerText: '',
  backLink: '',
};

export default withRouter(MobileHeader);
