import React from 'react';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';

import Icon from './Icon';

const IconBtn = (props) => {
  const {
    size,
    className,
    onClick,
    disabled,
    fontType,
    iconClass,
    iconSize,
    customIcon,
  } = props;

  const classes = cx('icon-btn', className, {
    'icon-btn-disabled': disabled,
  });

  return (
    <IconButton
      disabled={disabled}
      size={size}
      className={classes}
      onClick={onClick}
      disableRipple
    >
      {customIcon || (
        <Icon size={iconSize} fontType={fontType} className={iconClass} />
      )}
    </IconButton>
  );
};

IconBtn.defaultProps = {
  fontType: '',
  iconSize: '16',
  size: 'small',
  iconClass: '',
  className: '',
  customIcon: null,
};

export default IconBtn;
