import React from 'react';
import { RedBadge, SuccessBadge, WarningBadge } from '../../shared/Badge';

export const SHOW_CATALOGUE_BANNER = 'advance_catalogue_nudge';
export const ANNOUNCE_NEW_ORDERS = 'announce_new_orders';
export const SHOW_NOTES_AND_ACTIVITIES = 'show_notes_and_activities';
export const SHOW_STORE_NAME = 'show_store_name';
export const AUTO_ACCEPT_ORDERS = 'auto_accept_orders';
export const SHOW_ADVANCED_FIELDS = 'show_advanced_fields';
export const RTO_OPTIMISER_PLUGIN = 'rto_optimiser_plugin';
export const FALLBACK_INVOICES = ['delivery'];
export const APPSUMO_REDIRECTION_URL = 'https://appsumo.com/account/products/';

export const DEFAULT_START_TIME = '00:00:00';
export const DEFAULT_END_TIME = '00:00:00';

export const isFullDay = (startTime, endTime) =>
  startTime === DEFAULT_START_TIME && endTime === DEFAULT_END_TIME;

export const DINING_ORDER = 1;
export const NON_DINING_ORDER = 2;
export const BOTH_DINING_AND_NON_DINING_ORDER = 3;

export const COD_PAYMENT_METHOD = 0;
export const ONLINE_PAYMENT_METHOD = 1;
export const BOTH_PAYMENT_METHOD = 2;

export const APPLICABLE_TO_CHARGES = [
  {
    value: BOTH_DINING_AND_NON_DINING_ORDER,
    label: 'Both delivery & dining orders',
  },
  { value: NON_DINING_ORDER, label: 'Delivery orders only' },
  { value: DINING_ORDER, label: 'Dining orders only' },
];

export const PERCENTAGE_CHARGE_TYPE = 0;
export const FLAT_CHARGE_TYPE = 1;

export const GSUITE_EMAIL_PROVIDER = 'gsuite';
export const ZOHO_EMAIL_PROVIDER = 'zoho_mail';
export const OPEN_SRS_EMAIL_PROVIDER = 'open_srs';

export const EMAIL_PROVIDERS = {
  [GSUITE_EMAIL_PROVIDER]: 'gsuite',
  [ZOHO_EMAIL_PROVIDER]: 'zoho_mail',
};

export const EMAIL_PROVIDERS_LABELS = {
  [GSUITE_EMAIL_PROVIDER]: 'G Suite',
  [ZOHO_EMAIL_PROVIDER]: 'Zoho Mail',
};

export const ABANDONED_KEY_PREFERENCE = 'abandoned_order_automatic_message';

export const AVALARA_PLUGIN_ID = 3;
export const TAXJAR_PLUGIN_ID = 4;
export const CREDENTIALS = 'credentials';
export const LICENSE_KEY = 'license_key';
export const AVALARA = 'Avalara';
export const TAX_JAR = 'TaxJar';
export const STORE_LEVEL_TAX = 'store_level';
export const TAX_THROUGH_PLUGINS = 'tax_through_plugins';
export const ABOUT_ALVARA =
  'https://help.mydukaan.io/en/articles/6281825-how-to-use-avalara-taxjar-to-calculate-taxes';
export const ABOUT_ADVANCE_CATALOGUE =
  'https://help.mydukaan.io/en/articles/6160886-how-to-use-advance-catalogue';
export const SMTP_PLUGIN_ID = 5;
export const INTERAKT_PLUGIN_ID = 25;
export const UPSELL_CROSSSELL_PLUGIN_ID = 23;
export const SHIPROCKET_CORE_PLUGIN_ID = 27;
export const CONNECT_SHIPPING_ACCOUNT_ID = 15;
export const LOYALTY_POINTS_CORE_PLUGIN_ID = 13;
export const COD_DISABLE_PRODUCTS_CORE_PLUGIN_ID = 29;
export const PINCODE_CHECKER_CORE_PLUGIN_ID = 7;

export const DEFAULT_SENDER_EMAIL = 'no-reply@mydukaan.io';

export const SEND_EMAIL_TO_BUYER = 30;
export const SEND_SMS_TO_BUYER = 31;
export const SEND_WHATSAPP_TO_BUYER = 32;

export const AUTO_ABANDONED_MEDIUM_WHATSAPP = 2;
export const AUTO_ABANDONED_MEDIUM_SMS = 1;
export const MIN_CREDITS_FOR_AUTO_ABANDONED_RESTRICTION = 1;
export const MIN_CREDITS_FOR_AUTO_ABANDONED = 250;
export const LOW_CREDITS_WARN_ON_AUTO_ABANDONED = 50;
export const MIN_WARN_WHATSAPP_COMM = 20;

export const DOMAIN_STATUS = {
  ONLINE: 'online',
  PENDING: 'pending',
  DNS_VERIFIED: 'dns_verified',
  SSL_VERIFIED: 'ssl_verified',
  SSL_EXPIRED: 'ssl_expired',
  OFFLINE: 'offline',
  ERROR: 'error',
  PURCHASED: 'purchased',
};

const DOMAIN_STATUS_TEXT = {
  [DOMAIN_STATUS.ONLINE]: 'LIVE',
  [DOMAIN_STATUS.PENDING]: 'PENDING',
  [DOMAIN_STATUS.DNS_VERIFIED]: 'DNS VERIFIED',
  [DOMAIN_STATUS.SSL_VERIFIED]: 'SSL VERIFIED',
  [DOMAIN_STATUS.SSL_EXPIRED]: 'SSL EXPIRED',
  [DOMAIN_STATUS.OFFLINE]: 'OFFLINE',
  [DOMAIN_STATUS.ERROR]: 'ERROR',
  [DOMAIN_STATUS.PURCHASED]: 'PURCHASED',
};

export const getDomainStatusBadge = (status, showExpiredBadge) => {
  if (showExpiredBadge) return <RedBadge text="EXPIRED" />;
  if (status === DOMAIN_STATUS.ONLINE)
    return <SuccessBadge text={DOMAIN_STATUS_TEXT[status]} />;
  if (status === DOMAIN_STATUS.ERROR || status === DOMAIN_STATUS.OFFLINE)
    return <RedBadge text={DOMAIN_STATUS_TEXT[status]} />;
  return <WarningBadge text={DOMAIN_STATUS_TEXT[status]} />;
};

export const ORDER_FORM_FIELD_OPTIONS = [
  {
    id: 0,
    title: 'Optional',
    desc: 'It will be optional for the customer to fill this field.',
    value: 'optional',
  },
  {
    id: 1,
    title: 'Required',
    desc: 'It will be mandatory for the customer to fill this field.',
    value: 'required',
  },
  {
    id: 2,
    title: 'Hidden',
    desc: 'Customer won’t see this field at checkout.',
    value: 'hidden',
  },
];

export const INVOICES_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
};

export const DUKAAN_MANAGED = 'dukaan_managed';
export const INTERAKT_MANAGED = 'interakt';

export const WHATSAPP_CHANNEL_PROVIDER_NAMES = {
  [DUKAAN_MANAGED]: 'Dukaan®',
  [INTERAKT_MANAGED]: 'Interakt',
};

export const INTERAKT_KEY_URL =
  'https://app.interakt.ai/settings/developer-setting';
