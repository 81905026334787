import { createContext, useContext, useEffect, useState } from 'react';
import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import {
  ORDERS_BULK_SHIPPING_URL,
  ORDER_DUKAAN_ACCEPTED_SERVICEABLE_URL,
  ORDER_DUKAAN_MULTIPLE_SHIPMENT_URL,
} from '../ApiUrls';
import { deepClone } from '../Appearance/utils';
import { ORDERS_SHIP_MULTIPLE_PROCEED } from '../events';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useResponsive from '../hooks/useResponsive';
import {
  BULK_DELIVERY_BATCH_SIZE,
  HYPERLOCAL_CARRIER_ID,
  SHIPPED_STATUS,
} from '../Orders/constants';
import { CUSTOM_WAREHOUSE_PINCODE } from '../Orders/NewShippingOptionsModal';
import { rootUrls } from '../Urls';
import { noop } from '../utils';
import { TrackEvent } from '../utils/analytics';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import { useAppContext } from './AppContext';
import usePrevious from '../hooks/utils/usePrevious';
import { useSocket } from './SocketProvider';

let timeout;
let timestamp;

export const debounceMethod = (func, wait) =>
  function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

const INITIAL_CONFIRM_DETAILS = {
  totalweight: 0,
  totalOrderCost: 0,
  totalDeliveryCost: 0,
  totalCodCost: 0,
  totalGstCharges: 0,
  totalCharges: 0,
  creditAmount: 0,
  totalCodOrders: 0,
  isAsPerContractActivated: false,
  orders: {},
};

const GET_ORDER_META = ({
  id,
  display_order_id: displayOrderId,
  uuid,
  created_at: createdAt,
  product_count: productCount,
  order_meta: { buyer_address: address } = {},
  payment_mode: paymentMode,
  total_cost: totalCost,
  default_carrier: { logo },
}) => ({
  id,
  uuid,
  createdAt,
  productCount,
  address,
  paymentMode,
  totalCost,
  displayOrderId,
  partnerLogo: logo,
});

const GET_ORDER_PRODUCT_META = (order) => {
  const orderObj = GET_ORDER_META(order);
  orderObj.products = order.products
    .filter((val) => val.quantity + Number(val?.quantity_freed) > 0)
    .map((product) => ({
      ...product,
      qty: product.quantity + Number(product?.quantity_freed),
    }));
  orderObj.isAutoAccepted = order?.buyer_address?.auto_accepted;
  return orderObj;
};

const GET_MINIFIED_ORDER_PRODUCT_META = (order) => {
  const newOrderObj = deepClone(order);
  newOrderObj.products = newOrderObj.products.map((product) => ({
    product_id: product.product_id,
    qty: product.qty,
  }));
  return newOrderObj;
};

export const isHyperlocalSelected = (partner = {}) =>
  partner?.carrier_service?.service_type_json?.id === HYPERLOCAL_CARRIER_ID;

export class OrderWeight {
  constructor(orderId, weight, mode, carrier, isReverseShipment) {
    this.order_id = orderId;
    this.weight = weight;
    if (mode) {
      this.carrier_service = mode;
    }
    if (carrier) {
      this.carrier = carrier;
    }
    if (isReverseShipment) {
      this.is_reverse_shipment = true;
    }
  }
}

const DeliveryContext = createContext({
  loading: false,
  sorting: true,
  isPriceCalculating: false,
  submitting: false,
  selectedOrdersMap: {},
  confirmDetails: {
    ...INITIAL_CONFIRM_DETAILS,
  },
  acceptedServiceableRes: {},
  fetchAcceptedServiceableOrders: noop,
  onOrderCheck: noop,
  onAllOrderCheckChange: noop,
  calculateShipmentPrice: noop,
  onCalculatePrice: noop,
  onProceedToShip: noop,
  reset: noop,
  onSort: noop,
});

export const useMultipleDeliveryContext = () => useContext(DeliveryContext);

export default function DeliveryProvider({ children }) {
  const { isMobile } = useResponsive();
  const { on } = useSocket();
  const [loading, setLoading] = useState(false);
  const [autoCalculate, setAutoCalculate] = useState(false);
  const [sorting, setSorting] = useState(true);
  const [currentPage, setPage] = useState(1);
  const [productWeights, setProductWeights] = useState({});
  const [isPriceCalculating, setPriceCalculating] = useState(false);
  const [acceptedServiceableRes, setAcceptedServiceableRes] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [selectedOrderIDs, setSelectedOrderIDs] = useState([]);
  const { business } = useAppContext();
  const { meta: { gst_number: storeGSTExists = false } = {} } = business || {};
  const { enqueueSnackbar } = useCustomSnackbar();
  const [confirmDetails, setConfirmDetails] = useState(
    deepClone(INITIAL_CONFIRM_DETAILS)
  );
  const [selectedOrdersMap, setSelectedOrdersMap] = useState({});
  const [selectedPartner, onPartnerChange] = useState({});
  const selectedOrderCount = Object.keys(selectedOrdersMap).length;
  const prevSelectedOrdersMapCount = usePrevious(selectedOrderCount);

  const updateWeight = (productId, weight) =>
    setProductWeights((preWeight) => ({
      ...preWeight,
      [`product_${productId}`]: weight,
    }));

  const resetFromConfirmDelivery = (history, resetPage = true) => {
    setConfirmDetails(deepClone(INITIAL_CONFIRM_DETAILS));
    setSelectedOrdersMap({});
    setProductWeights({});
    setAcceptedServiceableRes({});
    if (resetPage) setPage(1);
    if (history)
      history.push(`${rootUrls.ordersPath}?status=${SHIPPED_STATUS}`);
  };

  const fetchAcceptedServiceableOrdersResponse = ({
    results,
    count,
    ...res
  }) => {
    let isProductWeightPrefilled = false;
    const ordersList = results.map((each) => {
      const order = GET_ORDER_PRODUCT_META(each);
      for (const product of order.products) {
        const skuWeight = Number(product.shipping_weight_kgs);
        // User has manupulated the weight
        const enteredWeight = Number(
          productWeights[`product_${product.product_id}`] || 0
        );
        if (skuWeight > 0) {
          isProductWeightPrefilled = true;
          if (enteredWeight <= 0) {
            setProductWeights((pre) => ({
              ...pre,
              [`product_${product.product_id}`]: skuWeight,
            }));
          }
        }
      }
      return order;
    });
    setAcceptedServiceableRes({
      orders: ordersList,
      count: Math.min(count, BULK_DELIVERY_BATCH_SIZE),
      ...res,
    });
    if (isProductWeightPrefilled) setAutoCalculate(isProductWeightPrefilled);
    setLoading(false);
  };

  const fetchAcceptedServiceableOrders = (
    isResetPartner = false,
    pageSize = 50,
    filters = {}
  ) => {
    setLoading(true);
    if (isResetPartner) onPartnerChange({});
    timestamp = uuidv4();
    const payload = {
      ordering: `${sorting ? '-' : ''}created_at`,
      status: 1,
      page_size: pageSize,
      custom_warehouse_pincode: CUSTOM_WAREHOUSE_PINCODE,
      ...filters,
      page: currentPage,
      timestamp,
    };
    if (selectedOrderIDs.length) payload.id = selectedOrderIDs;
    if (isMobile || !storeGSTExists) payload.carrier_service_type = 4; // On mobile show hyperlocal only
    if (isResetPartner) delete payload.page;
    if (!isResetPartner) {
      if (selectedPartner?.carrier?.id)
        payload.carrier = selectedPartner?.carrier?.id;
      if (selectedPartner?.carrier_service?.id)
        payload.carrier_service = selectedPartner?.carrier_service?.id;
    }
    getRequest({
      url: ORDER_DUKAAN_ACCEPTED_SERVICEABLE_URL,
      data: payload,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then(noop)
      .catch(console.log);
  };

  const onAllOrderCheckChange = (checked) => {
    const { orders } = acceptedServiceableRes;
    orders?.forEach((each) =>
      checked
        ? (selectedOrdersMap[each.id] = GET_MINIFIED_ORDER_PRODUCT_META(each))
        : delete selectedOrdersMap[each.id]
    );
    if (!checked && !Object.keys(selectedOrdersMap).length)
      setConfirmDetails(deepClone(INITIAL_CONFIRM_DETAILS));
    setSelectedOrdersMap({ ...selectedOrdersMap });
    if (!autoCalculate) setAutoCalculate(true);
  };

  const calculateShipmentPrice = (
    check = true,
    sendCarrier = false,
    isHyperlocal = false
  ) => {
    const ordersList = [];
    for (const orderId of Object.keys(selectedOrdersMap)) {
      let sum = 0;
      for (const product of selectedOrdersMap[orderId].products) {
        const productWeigh = isHyperlocal
          ? 0.1
          : Number(productWeights[`product_${product.product_id}`]);
        if (productWeigh !== '') {
          sum += productWeigh * product.qty;
        } else if (check) {
          return [];
        }
      }
      if (sum === 0 && selectedOrdersMap[orderId]?.total_weight > 0) {
        sum = selectedOrdersMap[orderId]?.total_weight;
      }
      if (sum > 0) {
        selectedOrdersMap[orderId] = {
          ...selectedOrdersMap[orderId],
          total_weight: sum,
        };
        ordersList.push(
          new OrderWeight(
            orderId,
            Number(Number(sum).toFixed(3)),
            sendCarrier && confirmDetails.orders[orderId]?.carrier_service?.id,
            sendCarrier && confirmDetails.orders[orderId]?.carrier?.id
          )
        );
      }
    }
    setSelectedOrdersMap({ ...selectedOrdersMap });
    return ordersList;
  };

  const validateOrders = (errors = {}, skipKeyCheck = false) => {
    const selectedKeysList = Object.keys(selectedOrdersMap);
    if (!skipKeyCheck && selectedKeysList.length === 0) {
      enqueueSnackbar('Please select the order first.', 'error');
      return false;
    }
    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Add product weights of the selected orders first ',
        'error'
      );
      return false;
    }
    return true;
  };

  const getSelectedRate = (rates = []) => {
    if (!selectedPartner.carrier) {
      return rates[0];
    }
    if (selectedPartner.carrier && !selectedPartner?.carrier_service) {
      return rates.filter(
        (each) => each.carrier.id === selectedPartner.carrier.id
      )[0];
    }
    if (selectedPartner.carrier && selectedPartner?.carrier_service) {
      return rates.filter(
        (each) => each.carrier_service.id === selectedPartner.carrier_service.id
      )[0];
    }
    return {};
  };

  const onCalculatePrice = (
    errors,
    values,
    isShip = false,
    isHyperlocal = false
  ) => {
    setAutoCalculate(false);
    if (isShip && !validateOrders(errors)) return;
    const ordersList = calculateShipmentPrice(isShip, false, isHyperlocal);
    if (!ordersList.length) {
      if (isShip)
        enqueueSnackbar(
          'Add product weights of the selected orders first ',
          'error'
        );
      setConfirmDetails(deepClone(INITIAL_CONFIRM_DETAILS));
      return false;
    }
    const payload = { orders: ordersList };
    setPriceCalculating(true);
    return postRequest({
      url: ORDERS_BULK_SHIPPING_URL,
      data: payload,
    })
      .then(({ data }) => {
        const { orders_charges: orderCharges = [], ...restData } = data;
        const details = deepClone(INITIAL_CONFIRM_DETAILS);
        for (const {
          weight,
          order_id: id,
          order_total_cost: orderTotalCost,
          selected_warehouse: selectedWarehouse,
          rates,
        } of orderCharges) {
          const selected = getSelectedRate(rates);
          details.totalweight += weight;
          details.totalOrderCost += orderTotalCost;
          details.totalDeliveryCost += selected.delivery_charge;
          details.totalCodCost += selected.cod_charge || 0;
          details.totalGstCharges += selected.gst_charge || 0;
          details.totalCharges += selected.total_delivery_charge;
          details.creditAmount += selected.orders_credit_amount || 0;
          details.gst_charge_percentage = selected.gst_charge_percentage;
          details.orders[id] = {
            ...selected,
            weight,
            selectedWarehouse,
          };
          if (selected.cod_charge > 0) {
            details.totalCodOrders += 1;
          }
          if (selected.as_per_contract) {
            details.isAsPerContractActivated = true;
          }
        }
        details.totalOrders = orderCharges.length;
        setConfirmDetails({ ...restData, ...details });
        return data;
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          'Something went wrong while calculating prices, please try again',
          'error'
        );
        return false;
      })
      .finally(() => {
        setSubmitting(false);
        setPriceCalculating(false);
      });
  };

  const onProceedToShip = async (
    errors,
    values,
    history,
    isHyperlocal = false,
    { tabId, isActiveStoreWarehouseExist }
  ) => {
    if (Object.keys(selectedOrdersMap).length === 0) {
      enqueueSnackbar('Please select the order first.', 'error');
      return false;
    }
    setSubmitting(true);
    const res = await onCalculatePrice(errors, values, true, isHyperlocal);
    if (!res) {
      setSubmitting(false);
      return;
    }
    updateWeight(values);
    if (history) {
      TrackEvent(
        ORDERS_SHIP_MULTIPLE_PROCEED(tabId, isActiveStoreWarehouseExist),
        business
      );
      history.push(`${rootUrls.ordersMultipleShipConfirmPath}?tab_id=${tabId}`);
    }
  };

  const shipMultipleOrders = (payload = {}, callback = noop, errCb = noop) => {
    setSubmitting(true);
    return patchRequest({
      url: ORDER_DUKAAN_MULTIPLE_SHIPMENT_URL,
      data: payload,
    })
      .then((res) => callback(res))
      .catch((err) => errCb(err))
      .finally(() => setSubmitting(false));
  };

  const onOrderCheck = (order, errors, values) => {
    if (selectedOrdersMap[order.id]) {
      delete selectedOrdersMap[order.id];
    } else {
      if (
        isHyperlocalSelected(selectedPartner) &&
        Object.keys(selectedOrdersMap).length >= 4
      ) {
        enqueueSnackbar('Only 4 orders can be selected', 'error');
        return;
      }
      selectedOrdersMap[order.id] = GET_MINIFIED_ORDER_PRODUCT_META(order);
    }
    setSelectedOrdersMap({ ...selectedOrdersMap });
    debounceMethod(() => onCalculatePrice(errors, values), 1000)();
  };

  const onPageChange = (newPage, errors) => {
    if (newPage !== currentPage) {
      if (validateOrders(errors, true)) {
        setPage(newPage);
        setProductWeights(deepClone(productWeights));
      }
    }
  };

  const onSort = (isSort, errors) => {
    if (validateOrders(errors, true)) {
      setSorting(isSort);
      setProductWeights(deepClone(productWeights));
    }
  };

  useEffect(() => {
    on('pincode_serviceable_orders', ({ data }) => {
      // this log there for time if is it working properly or not
      console.log(data, 'acceptedServiceableTimestamp', timestamp);
      if (timestamp && data.timestamp === timestamp) {
        fetchAcceptedServiceableOrdersResponse(data);
      }
    });
  }, [on]);

  useEffect(() => {
    if (selectedOrderCount !== prevSelectedOrdersMapCount && autoCalculate) {
      onCalculatePrice({}, {});
    }
  }, [autoCalculate, selectedOrderCount]);

  const contextValue = {
    loading,
    sorting,
    isPriceCalculating,
    submitting,
    currentPage,
    onSort,
    onPageChange,
    productWeights,
    selectedPartner,
    updateWeight,
    selectedOrdersMap,
    confirmDetails,
    acceptedServiceableRes,
    fetchAcceptedServiceableOrders,
    onOrderCheck,
    onAllOrderCheckChange,
    onCalculatePrice,
    onProceedToShip,
    shipMultipleOrders,
    calculateShipmentPrice,
    resetFromConfirmDelivery,
    onPartnerChange,
    validateOrders,
    setSelectedOrderIDs,
  };

  return (
    <DeliveryContext.Provider value={contextValue}>
      {children}
    </DeliveryContext.Provider>
  );
}
