export const OutlineBadge = ({
  color = 'green', // allowed values -  red, green, orange
  showDot = true,
  text = '',
}) => (
  <div className={`outline-badge ${color}`}>
    {showDot && <div className={`status-dot mr6 ${color}`} />}

    <div className="section-text-7">{text}</div>
  </div>
);
