import React from 'react';
import cx from 'classnames';
import { ArrowIcon, UpArrowCloseIcon } from '../../SvgIcon';

import { noop } from '../../utils';

const DateInputFieldWrap = (props) => {
  const { className, children, onClick, isPopperOpen } = props;

  const classes = cx('date-input-field-wrap', className);

  return (
    <div className={classes} onClick={onClick}>
      <div className="calendar-text-wrap">{children}</div>
      {isPopperOpen ? <UpArrowCloseIcon /> : <ArrowIcon />}
    </div>
  );
};

DateInputFieldWrap.defaultProps = {
  onClick: noop,
  className: '',
  children: null,
  showCalendarIcon: true,
};

export default DateInputFieldWrap;
