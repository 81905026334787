import React from 'react';
import cx from 'classnames';
import SpinnerButton from '../shared/SpinnerButton';
import {
  AlertIcon,
  ExternalLinkBlack,
  PlusIconWhite,
  WarningTranslucentIcon,
} from '../SvgIcon';
import { useCredits } from '../context/CreditsProvider';
import AddCreditsModal from '../Campaign/components/AddCreditsModal';
import useModal from '../hooks/useModal';
import { useCountry } from '../context/CountryProvider';
import {
  MIN_CREDITS_TO_ADD,
  MIN_SUGGSESTED_CREDITS,
} from '../Campaign/constants';
import { useAppContext } from '../context/AppContext';
import { CREDITS_READMORE_LINK, DUKAAN_SERVICE_FEE_URL } from '../constants';

const NegativeCredits = ({
  bottomCta = false,
  hideAddCredits = false,
  bottomMargin = true,
}) => {
  const { isEnterprise, isAppSumoStore } = useAppContext();
  const { isInternational } = useCountry();
  const { isOpen, openModal, closeModal } = useModal();
  const { credits } = useCredits();

  const isWarningMode = credits >= 0 && credits < MIN_SUGGSESTED_CREDITS;
  const isNegativeMode = credits < 0;

  if (
    isInternational ||
    isEnterprise ||
    isAppSumoStore ||
    credits >= MIN_SUGGSESTED_CREDITS
  )
    return null;

  return (
    <div
      className={cx('card px12 py8', {
        'danger-stripe': isNegativeMode,
        'warning-stripe': isWarningMode,
        'd-flex-c-s': !bottomCta,
        mb24: bottomMargin,
      })}
    >
      <div className="d-flex align-center">
        {!bottomCta && (
          <>
            {isWarningMode ? (
              <WarningTranslucentIcon height={24} width={24} className="mr6" />
            ) : (
              <></>
            )}
          </>
        )}
        <div>
          <p className="section-text-5">
            {isWarningMode
              ? 'Wallet Balance Low'
              : 'Payouts on hold - Top-up required'}
          </p>
          <p className="c-black-3">
            {isWarningMode ? (
              <>
                To ensure seamless payouts and order updates, please maintain at
                least{' '}
                <span className="text-medium">{MIN_SUGGSESTED_CREDITS}</span>{' '}
                credits in your Dukaan Wallet.
              </>
            ) : (
              <>
                Your Dukaan Wallet balance is too low to view customer details
                and process payouts. <br /> Please add at least{' '}
                <span className="text-medium">{Math.abs(credits)}</span> credits
                to unlock order updates, resume payouts, and view customer
                details.
              </>
            )}
          </p>
        </div>
      </div>
      {!bottomCta && (
        <div className="d-flex align-center">
          <SpinnerButton
            type="button"
            className="btn-outline-4 text-medium mr10"
            onClick={() => {
              if (isWarningMode) {
                window.open(DUKAAN_SERVICE_FEE_URL, '_blank');
              } else {
                window.open(CREDITS_READMORE_LINK, '_blank');
              }
            }}
          >
            <ExternalLinkBlack className="mr8" height={12} />
            Learn more
          </SpinnerButton>
          {!hideAddCredits && (
            <SpinnerButton
              type="button"
              className={cx({
                'btn-danger-4': isNegativeMode,
                'btn-warning-4': isWarningMode,
              })}
              onClick={() => openModal()}
            >
              <PlusIconWhite className="mr8" height={12} />
              Add credits
            </SpinnerButton>
          )}
        </div>
      )}
      {bottomCta && (
        <div className="d-flex mt12">
          <SpinnerButton
            type="button"
            className="btn-outline-4 btn-small text-medium mr10"
            onClick={() => {
              if (isWarningMode) {
                window.open(DUKAAN_SERVICE_FEE_URL, '_blank');
              } else {
                window.open(CREDITS_READMORE_LINK, '_blank');
              }
            }}
          >
            <ExternalLinkBlack className="mr8" height={12} />
            Learn more
          </SpinnerButton>
          <SpinnerButton
            type="button"
            className={cx({
              'btn-danger-4 btn-small': isNegativeMode,
              'btn-warning-4': isWarningMode,
            })}
            onClick={() => openModal()}
          >
            <PlusIconWhite className="mr8" height={12} />
            Add credits
          </SpinnerButton>
        </div>
      )}
      <AddCreditsModal
        open={isOpen}
        closeModal={closeModal}
        initialValue={credits < 0 ? Math.abs(credits) : MIN_CREDITS_TO_ADD}
      />
    </div>
  );
};

export default NegativeCredits;
