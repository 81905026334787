import React, { cloneElement } from 'react';
import { useDrag, useDrop, useIsClosestDragging } from 'react-sortly';

const SwapRenderer = (props) => {
  const { children, disableDrag = false } = props;

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop();

  const dragging = useIsClosestDragging() || isDragging;

  if (disableDrag)
    return (
      <div>
        <div dragging={false}>{children}</div>
      </div>
    );

  return (
    <div ref={drop}>
      <div ref={drag} dragging={dragging}>
        {cloneElement(children, { dragging })}
      </div>
    </div>
  );
};

export default SwapRenderer;
