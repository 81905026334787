import React, { useEffect, useState } from 'react';
import useModal from '../../../hooks/useModal';
import { FormInput } from '../../../shared/Form';
import { CrossIcon } from '../../../SvgIcon';
import SelectAttributeModal from './SelectAttributeModal';

const MultiSelectAttribute = ({ field = {}, values = {}, form }) => {
  const {
    field_name: name,
    field_label: label = '',
    field_meta: fieldMeta,
    metafield_id: metafieldId,
  } = field ?? {};

  const metaFieldName = name.substring(name.indexOf('.') + 1);

  const { isOpen, openModal, closeModal } = useModal();

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const formattedSelectedOptions = values?.metafields?.[metaFieldName] ?? [];
    setSelectedOptions(formattedSelectedOptions);
  }, [values]);

  return (
    <div>
      <FormInput
        labelText={label || name}
        name={name}
        label={label}
        placeholder="Select options"
        onClick={openModal}
        className="mb0"
        readOnly
        appendText={
          selectedOptions.length > 0 && (
            <CrossIcon
              height={16}
              width={16}
              onClick={() => form.mutators.modifyFormField(name, [])}
            />
          )
        }
      />

      {isOpen && (
        <SelectAttributeModal
          open={isOpen}
          closeModal={() => {
            if (values?.metafields?.[metaFieldName]?.length)
              setSelectedOptions([...values?.metafields?.[metaFieldName]]);
            else setSelectedOptions([]);
            closeModal();
          }}
          options={fieldMeta?.options}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          name={name}
          onSelectClick={(val) => {
            form.mutators.modifyFormField(name, val);
            closeModal();
          }}
          metafieldId={metafieldId}
        />
      )}
    </div>
  );
};

export default MultiSelectAttribute;
