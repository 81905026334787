import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import { useCountry } from '../context/CountryProvider';
import { useSubscription } from '../context/SubscriptionProvider';
import useResponsive from '../hooks/useResponsive';
import Modal from '../shared/Modal';
import { DOMESTIC_SUBSCRIBE_LABEL } from '../Subscription/constants';
import RazorpayButton from '../Subscription/NewSubscription/PaymentButtons/RazorpayButton';
import { RightArrowPrimaryIcon } from '../SvgIcon';
import { accountUrls } from '../Urls';
import PlanNDomainPriceContent from './PlanNDomainPriceContent';

const RenewSubscriptionModal = ({
  open,
  closeModal,
  isPlanChanged = false,
  planDetails = {},
  showExploreOtherPlans = false,
  rzpOptions,
  showBlurBackDrop = false,
  festiveDiscount = 0,
  festiveDiscountPercent = 25,
}) => {
  const [isDomainRemoved, setIsDomainRemoved] = useState(false);
  const { festiveDiscountPercentage } = useAppContext();
  const history = useHistory();
  const { formatLocalMoney } = useCountry();
  const {
    renewalPlanDetails: {
      period: renewalPeriod,
      price: renewalPrice,
      custom_domain: renewalDomainPrice,
    },
    isInfinityPlan,
    activePlanName,
  } = useSubscription();
  const { isMobile } = useResponsive();
  const {
    price = renewalPrice,
    domainPrice = renewalDomainPrice,
    period = renewalPeriod,
    name = activePlanName,
  } = planDetails;

  const totalPrice = +price + (isDomainRemoved ? 0 : +domainPrice);

  return (
    <Modal
      open={open}
      maxWidth="xm"
      className={`renewal-modal ${showBlurBackDrop ? 'expiry-modal' : ''}`}
      closeModal={closeModal}
      paperClass={isMobile ? 'subscribe-modal-mobile-flow' : 'renew-plan'}
      hasTopBanner={Boolean(festiveDiscount)}
      topBannerTitle={`${
        festiveDiscountPercentage ?? 50
      }% discount auto-applied`}
      topBannerClasses={{
        crossIconClasses: 'subscription-plan-top-banner',
      }}
    >
      <div>
        <div className="section-text-4">Renew subscription</div>
        <div className="mt24">
          <PlanNDomainPriceContent
            isDomainRemoved={isDomainRemoved}
            setIsDomainRemoved={setIsDomainRemoved}
            priceDetails={{
              plan: price,
              domain: domainPrice,
              period,
              name,
            }}
            festiveDiscount={festiveDiscount}
            festiveDiscountPercent={festiveDiscountPercent}
          />
        </div>
        <div className="d-flex-c-c flex-column">
          <div className="mt32">
            <RazorpayButton
              buttonText={`Renew plan for ${formatLocalMoney(
                festiveDiscount
                  ? totalPrice - (festiveDiscount || 0)
                  : totalPrice
              )}`}
              isPlanRenewal
              planNDomainRenewalPayload={{
                custom_domain: !!domainPrice && !isDomainRemoved,
                ...(isPlanChanged && {
                  period: rzpOptions.period,
                  plan_id: rzpOptions.id,
                }),
                price:
                  (festiveDiscount
                    ? price - Number(festiveDiscount || 0)
                    : price) + domainPrice,
              }}
              name={`Dukaan® ${name} Plan`}
              subtitle="All the advanced features for scaling your business."
            />
          </div>
          {showExploreOtherPlans && (
            <div
              className="mt16 section-text-7 cur-p c-purple-1 d-flex-c-c"
              onClick={() =>
                history.push(
                  `${accountUrls.subscriptionPurchasePath}?isRenewFlow=true`
                )
              }
            >
              Explore other plans
              <RightArrowPrimaryIcon className="ml4" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RenewSubscriptionModal;
