import { useState } from 'react';
import { approveUnicommerceReturn } from '../ApiUrls';
import { noop } from '../utils';
import { postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';

const useUnicommerce = () => {
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const approveReturn = (orderUuid = '', successCB = noop) => {
    setSubmitting(true);

    return postRequest({
      url: approveUnicommerceReturn(orderUuid),
    })
      .then((res) => {
        successCB(res.data);
        return res.data;
      })
      .catch(() => enqueueSnackbar('Something went wrong.', 'error'))
      .finally(() => setSubmitting(false));
  };

  return {
    submitting,
    approveReturn,
  };
};

export default useUnicommerce;
