import { useState } from 'react';

import { patchRequest, postRequest } from '../utils/http';
import {
  NOTIFY_URL,
  UPDATE_STORE_MISC_DATA,
  STORE_URL,
  UPDATE_STORE_META,
} from '../ApiUrls';
import { useAppContext } from '../context/AppContext';
import useCustomSnackbar from './useCustomSnackbar';
import { rawCurrencies } from '../utils/currencies';
import { noop } from '../utils';

const useStoreUpdate = (updateAddress = false) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const updateAccount = (
    params,
    successCallback = noop,
    preventFetch = false
  ) => {
    setSubmitting(true);
    const { currency, country, address = {}, ...restParams } = params;
    const { sub_locality: subLocality, ...restAddress } = address || {};
    let countryParams = {};

    if (!!country && !!currency) {
      const currencyObject = rawCurrencies.find((c) => c.cc === currency);
      countryParams = {
        country: country.toLowerCase(),
        currency: currencyObject,
      };
    }

    const payload = { ...restParams, ...countryParams };

    if (updateAddress) {
      payload.address = {
        ...restAddress,
        ...(subLocality ? { sub_locality: subLocality } : {}),
      };
    }

    patchRequest({
      url: STORE_URL(business.uuid),
      data: payload,
      doClean: false,
    })
      .then((storeRes) => {
        if (storeRes.data) {
          successCallback({
            storeData: storeRes.data,
            addInfoData: {},
          });
          if (!preventFetch) {
            fetchBusinessDetails();
          }
        }
      })
      .catch((err) => {
        if (err?.data?.data?.link) {
          enqueueSnackbar('This link is not available', 'error');
        } else if (err?.data?.data?.fssai_number) {
          enqueueSnackbar('Please enter a valid FSSAI number.', 'error');
        } else if (err?.data?.data?.zonal_delivery_pincode) {
          enqueueSnackbar(err?.data?.data?.zonal_delivery_pincode, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const updateStoreMeta = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_STORE_META(business.id),
      data: payload,
      doClean: false,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback();
          fetchBusinessDetails();
        }
      })
      .catch((err) => {
        errorCallback(err);
        setSubmitting(false);
      });
  };

  const storeMiscUpdate = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_STORE_MISC_DATA(business.uuid),
      data: payload,
      doClean: false,
    })
      .then((res) => {
        if (res.data) {
          successCallback(res.data);
          fetchBusinessDetails();
        }
      })
      .catch(errorCallback)
      .finally(() => setSubmitting(false));
  };

  const notifyForFeature = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    postRequest({
      url: NOTIFY_URL,
      data: payload,
    })
      .then(() => {
        setSubmitting(false);
        successCallback();
      })
      .catch((err) => {
        errorCallback(err);
        setSubmitting(false);
      });
  };

  return {
    isSubmitting,
    updateAccount,
    updateStoreMeta,
    notifyForFeature,
    storeMiscUpdate,
  };
};

export default useStoreUpdate;
