import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LeaveSiteModal from '../shared/LeaveSiteModal';
import { noop } from '../utils';

const UnsavedChangesContext = createContext({
  isUnsavedChanges: false,
  setIsUnsavedChanges: noop,
  showLeaveSiteModal: false,
  setShowLeaveSiteModal: noop,
  setLinkRedirect: noop,
});

export const UnsavedModalChangesComponent = ({
  invalid = false,
  setIsUnsavedChanges = noop,
}) => {
  useEffect(() => {
    setIsUnsavedChanges(invalid);
  }, [invalid]);
  return null;
};

export const useUnsavedChangesModal = () => useContext(UnsavedChangesContext);

const UnsavedChangesModalProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [showLeaveSiteModal, setShowLeaveSiteModalState] = useState(false);
  const [linkRedirect, setLinkRedirect] = useState('');
  const ref = useRef({
    closeCallback: noop,
  });
  useEffect(() => {
    if (isUnsavedChanges) setIsUnsavedChanges(false);
  }, [location.pathname]);

  const setShowLeaveSiteModal = (bool, onCloseCallback = noop) => {
    setShowLeaveSiteModalState(bool);
    ref.current.closeCallback = onCloseCallback;
  };

  const contextValue = {
    isUnsavedChanges,
    setIsUnsavedChanges,
    showLeaveSiteModal,
    setShowLeaveSiteModal,
    setLinkRedirect,
  };

  return (
    <UnsavedChangesContext.Provider value={contextValue}>
      <LeaveSiteModal
        title="Unsaved changes"
        message="You have unsaved changes. Do you want to leave and discard these changes, or stay on this page?"
        btnText="Leave page"
        open={showLeaveSiteModal && isUnsavedChanges}
        closeModal={() => {
          setShowLeaveSiteModalState(false);
          ref.current.closeCallback();
          setLinkRedirect('');
        }}
        onSubmit={() => {
          setIsUnsavedChanges(false);
          if (linkRedirect) {
            if (typeof linkRedirect === 'function') {
              linkRedirect();
            } else {
              history.push(linkRedirect);
            }
          } else {
            history.goBack();
          }
        }}
      />
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export default UnsavedChangesModalProvider;
