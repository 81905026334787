export const ALL = -1;
export const PERCENTAGE_DISCOUNT = 0;
export const FLAT_DISCOUNT = 1;
export const BUY_X_GET_Y = 2;
export const FREEBIE = 3;
export const FREE_SHIPPING = 4;
export const LOYALTY_COUPON_TYPE = 5;

export const COUPON_LIST_OPTION = [
  {
    value: ALL,
    label: 'All',
    count: 0,
  },
  {
    value: PERCENTAGE_DISCOUNT,
    label: 'Percent discount',
    count: 0,
  },
  {
    value: FLAT_DISCOUNT,
    label: 'Flat discount',
    count: 0,
  },
  {
    value: BUY_X_GET_Y,
    label: 'Buy X get Y',
    count: 0,
  },
  {
    value: FREE_SHIPPING,
    label: 'Free shipping',
    count: 0,
  },
  {
    value: FREEBIE,
    label: 'Freebie',
    count: 0,
  },
  {
    value: LOYALTY_COUPON_TYPE,
    label: 'Loyalty points',
    count: 0,
  },
];

export const COUPON_CATEGORY_NAMES = {
  [ALL]: 'All',
  [PERCENTAGE_DISCOUNT]: 'Percentage discount',
  [FLAT_DISCOUNT]: 'Flat discount',
  [BUY_X_GET_Y]: 'Buy X Get Y Free',
  [FREEBIE]: 'Freebie',
  [FREE_SHIPPING]: 'Free shipping',
  [LOYALTY_COUPON_TYPE]: 'Loyalty points',
};

export const MANUAL_COUPON_KEY = 'manual';
export const AUTOMATIC_COUPON_KEY = 'automatic';
export const UPLOAD_COUPON_KEY = 'file_upload';

export const COUPON_CODE_OPTIONS = [
  { label: 'Manual', value: MANUAL_COUPON_KEY },
  { label: 'Automatic', value: AUTOMATIC_COUPON_KEY },
  // { label: 'Upload file', value: UPLOAD_COUPON_KEY },
];

export const USES_PER_CUSTOMER_OPTIONS = [
  { label: 'Only once', value: 1 },
  {
    label: 'Custom',
    value: -1,
  },
  { label: 'Unlimited', value: null },
];

export const SECONDS_AND_TIMEZONE = '00+05:30';

export const applyCouponItems = [
  { label: 'Specific products', value: 'specific_products' },
  { label: 'Specific categories', value: 'specific_categories' },
  { label: 'All products', value: 'all_products' },
];

export const automaticApplyCouponItems = [
  { label: 'All products', value: 'all_products' },
];

export const APPLY_COUPON_ITEMS_DEFAULT = {
  SPECIFIC_PRODUCTS: 'specific_products',
  SPECIFIC_CATEGORIES: 'specific_categories',
  ALL_PRODUCTS: 'all_products',
};

export const COUPON_CONTENT_TYPE = {
  PRODUCT: 'product',
  PRODUCT_SKU: 'productsku',
  PRODUCT_CATEGORY: 'productcategory',
};

export const COUPON_ALL_PRODUCTS_DEFAULT_VALUE = {
  value: 'all_products',
  label: 'All products',
};

export const CUSTOMER_TYPES = { ALL: 'all', NEW: 'new', CUSTOM: 'custom' };

export const COUPON_APPICABLE_TYPE = {
  STORE_LEAD: 'storelead',
  PRODUCT: 'product',
  PRODUCT_CATEGORY: 'productcategory',
};

export const SAMPLE_COUPON_CODE_BULK_UPLOAD =
  'https://dms.mydukaan.io/original/webp/example/bulk_coupon_upload.csv';

export const COUPON_OPTIONS = [
  {
    id: PERCENTAGE_DISCOUNT,
    title: 'Percentage discount',
  },
  {
    id: FLAT_DISCOUNT,
    title: 'Flat Discount',
  },
  {
    id: BUY_X_GET_Y,
    title: 'Buy X Get Y Free',
  },
  {
    id: FREEBIE,
    title: 'Freebie',
  },
  {
    id: FREE_SHIPPING,
    title: 'Free shipping',
  },
  {
    id: LOYALTY_COUPON_TYPE,
    title: 'Loyalty points',
  },
];

export const MINIMUM_ORDER_CONDITION = {
  MINIMUM_ORDER_VALUE: 'min_order_value',
  MINIMUM_ORDER_QUANTITY: 'min_lineitem_quantity',
};
