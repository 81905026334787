import firebase from '@firebase/app';
import '@firebase/analytics';
import mixpanel from 'mixpanel-browser';
import Axios from 'axios';
// import * as amplitude from '@amplitude/analytics-browser';

import { VERSION_CODE, GOOGLE_API_KEY, isNonLiveEnv } from '../constants';
import { DEFAULT_COUNTRY } from './countries';
import { getItem } from './storage';
import {
  SUBSCRIPTION_ACTIVE_KEY,
  SUBSCRIPTION_PRICE_KEY,
} from '../context/SubscriptionProvider';

export const FireDruidEvent = async (params) => {
  const data = JSON.stringify(params);
  const config = {
    method: 'post',
    url: 'https://api4.mydukaan.io/v1/post/events',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  Axios(config)
    .then(function () {})
    .catch(function () {});
};

export const FireDruidEventWithJson = async (params) => {
  const data = JSON.stringify(params);
  const config = {
    method: 'post',
    url: 'https://in-webhook.hevodata.com/t/lrhknwvns5',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  Axios(config)
    .then(function () {})
    .catch(function () {});
};

const getDeviceBrand = () => {
  if (process.browser && window) {
    return navigator.platform;
  }
  return 'NODE';
};

export const getIPAddress = () => {
  if (process.browser && window) {
    return localStorage.getItem('user_ip');
  }
  return '0.0.0.0';
};

const getDeviceModel = () => {
  if (process.browser && window) {
    return window.navigator.userAgent;
  }
  return 'AWS';
};

const getDeviceFingerprint = () => {
  if (process.browser && window) {
    return window.navigator.productSub;
  }
  return 'NODE';
};

const getOS = () => {
  if (process.browser && window) {
    let Name = 'Unknown OS';
    if (window.navigator.userAgent.indexOf('Win') !== -1) Name = 'Windows OS';
    if (window.navigator.userAgent.indexOf('Mac') !== -1) Name = 'Macintosh';
    if (window.navigator.userAgent.indexOf('Linux') !== -1) Name = 'Linux OS';
    if (window.navigator.userAgent.indexOf('Android') !== -1)
      Name = 'Android OS';
    if (window.navigator.userAgent.indexOf('like Mac') !== -1) Name = 'iOS';
    return Name;
  }
  return 'BROWSER';
};

export const TrackDruidEvent = (
  storeLink,
  storeId,
  eventName,
  business = {},
  fireWithJson
) => {
  const params = {
    timestamp: new Date(),
    u_lat: 0.0,
    u_long: 0.0,
    u_userID: 0,
    u_storeID: storeId,
    u_storeLink: storeLink,
    u_storePhone: 0,
    d_platform: 'REACT',
    d_source: 'SELLER-WEB',
    d_OSVersion: getOS(),
    d_appVersion: VERSION_CODE,
    d_deviceBrand: getDeviceBrand(),
    d_deviceModel: getDeviceModel(),
    d_deviceFingerprint: getDeviceFingerprint(),
    d_IPAddress: getIPAddress(),
    eventName: eventName?.toUpperCase().replace(' ', '_'),
    eventType: 'SELLER',
    u_utmCampaign: localStorage.getItem('utm_campaign') ?? '',
    u_utmMedium: localStorage.getItem('utm_medium') ?? '',
    u_utmSource: localStorage.getItem('utm_source') ?? '',
  };
  if (typeof fireWithJson === 'object') {
    const extraJson = {
      __time: new Date(),
      modified_at: new Date(),
      u_storePlan: getItem(SUBSCRIPTION_PRICE_KEY) || 0, // store plan price
      u_storeType: business?.type || 0, // store type
      u_storeCategory: business?.categories?.[0] || 0, // store categories
      u_storeCountry:
        business?.country_code_v2?.toUpperCase() ||
        DEFAULT_COUNTRY.country_code,
      entityCost: null,
      entityID: 0,
      entityName: '',
      entityParentID: 0,
      entityParentName: '',
      entityType: '',
      u_adClid: '',
      u_extraJson: '',
      u_isPremium: getItem(SUBSCRIPTION_ACTIVE_KEY) || null,
      u_utmCampaign: localStorage.getItem('utm_campaign') ?? '',
      u_utmMedium: localStorage.getItem('utm_medium') ?? '',
      u_utmSource: localStorage.getItem('utm_source') ?? '',
      coordinates: '',
      total_lineitems: 0,
      success_lineitems: 0,
      failed_lineitems: 0,
    };
    FireDruidEventWithJson({
      event: 'dukaan_custom_events',
      properties: { ...params, ...extraJson, ...fireWithJson },
    });
    return;
  }
  FireDruidEvent(params);
};

var firebaseConfig = {
  apiKey: GOOGLE_API_KEY,
  authDomain: 'dukaan-seller-web.firebaseapp.com',
  projectId: 'dukaan-seller-web',
  storageBucket: 'dukaan-seller-web.appspot.com',
  messagingSenderId: '193655940279',
  appId: '1:193655940279:web:728f14f149c3f938479932',
  measurementId: 'G-MTSF2067F5',
};

const eventEntityFromEventName = (eventName) => {
  if (eventName.startsWith('Dash_')) return 'dashboard';
  if (eventName.startsWith('Onboarding_')) return 'onboarding';
  if (eventName.startsWith('Product_')) return 'product';
  if (eventName.startsWith('Category_')) return 'category';
  if (eventName.startsWith('Marketing_')) return 'marketing';
  if (eventName.startsWith('Manage_')) return 'manage';
  if (eventName.startsWith('Discount_')) return 'coupon';
  if (eventName.startsWith('Orders_')) return 'order';
  if (eventName.startsWith('Account_')) return 'account';

  return 'misc';
};

const trimEventName = (name) => {
  if (name === 'Seller_Web-Launched') return name;

  const entities = name.split('_');
  entities.shift();
  return entities.join('_');
};

export const TrackEvent = (eventName, business = null, eventData) => {
  if (isNonLiveEnv) {
    return;
  }
  try {
    if (window?.track) {
      const eventEntity = eventEntityFromEventName(eventName);
      const newEventName = trimEventName(eventName);
      window.track.action(eventEntity, newEventName);
    }
  } catch (e) {
    //
  }

  if (!firebase?.apps?.length) {
    firebase.initializeApp(firebaseConfig);
  }
  try {
    mixpanel.track(eventName, eventData ? eventData : null);
    const analytics = firebase.analytics();
    analytics.logEvent(eventName);
  } catch (error) {
    console.log(error);
  }
  if (business) {
    TrackDruidEvent(business.link, business.id, eventName, business, eventData);
  } else {
    TrackDruidEvent('', '', eventName, {}, eventData);
  }

  // amplitude.track(eventName, eventData ? eventData : null);
};
