import React, { useState } from 'react';
import cx from 'classnames';
import {
  Menu as MaterialMenu,
  MenuItem as MaterialMenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { noop } from '../utils';

const MenuItemContent = (props) => {
  const {
    labelText,
    component: WrappedComponent,
    componentProps = {},
    closeMenu,
  } = props;
  if (WrappedComponent) {
    return <WrappedComponent {...componentProps} closeMenu={closeMenu} />;
  }
  return <div className="content-grp">{labelText}</div>;
};

export const MenuItemLink = (props) => {
  const { isExternalLink, url } = props;
  if (isExternalLink) {
    return (
      <a
        href={url}
        className="menu-link"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MenuItemContent {...props} />
      </a>
    );
  }

  return (
    <Link className="menu-link" to={url}>
      <MenuItemContent {...props} />
    </Link>
  );
};

export const MenuItem = (props) => {
  const {
    onClick,
    className,
    url,
    disableRipple = false,
    disabled,
    closeMenu,
  } = props;
  const itemProps = {
    ...props,
    className: cx('menu_list_item', className),
    disabled,
    disableRipple,
  };
  if (onClick) {
    itemProps.onClick = (e) => {
      closeMenu();
      onClick(e);
    };
  }

  return (
    <MaterialMenuItem {...itemProps}>
      {url ? (
        <MenuItemLink {...props} />
      ) : (
        <div className="menu-label">
          <MenuItemContent {...itemProps} />
        </div>
      )}
    </MaterialMenuItem>
  );
};

MenuItem.defaultProps = {
  className: '',
  iconUrl: null,
  url: null,
  labelText: null,
  subText: null,
  component: null,
  onClick: null,
  disabled: false,
};

export const MenuItems = ({
  menuItems,
  onCloseMenu = noop,
  menuItemClassName,
}) => (
  <>
    {menuItems.map((item, index) => {
      if (item?.hidden) {
        return null;
      }
      const menuItemProps = {
        closeMenu: onCloseMenu,
        className: menuItemClassName,
      };
      if (item?.onClick) {
        menuItemProps.onClick = (e) => {
          item.onClick(e);
          onCloseMenu();
        };
      }
      return <MenuItem key={index} {...menuItemProps} {...item} />;
    })}
  </>
);

const Menu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    id,
    className,
    menuItems,
    keepMounted,
    disabled,
    component: WrappedComponent,
    componentProps = {},
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
  } = props;
  const classes = cx('menu', className);
  const menuListClasses = {
    root: 'menu_list',
  };

  const openMenu = (event) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <WrappedComponent
        onClick={openMenu}
        aria-owns={anchorEl ? { id } : null}
        aria-haspopup="true"
        isOpen={isOpen}
        disabled={disabled}
        {...componentProps}
      />
      <MaterialMenu
        id={id}
        className={classes}
        MenuListProps={{
          classes: menuListClasses,
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted={keepMounted}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
      >
        <MenuItems menuItems={menuItems} onCloseMenu={closeMenu} />
      </MaterialMenu>
    </>
  );
};

Menu.defaultProps = {
  id: 'menu-id',
  className: '',
  keepMounted: false,
  disabled: false,
  menuItems: [],
};

export default Menu;
