import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Field } from 'react-final-form';

import FormGroup from './FormGroup';
import { noop } from '../../utils';
import InputDisabledTooltip from '../../Catalogue/Products/Variants/InputDisabledTooltip';

const FormInput = React.forwardRef((props, ref) => {
  const {
    className,
    name,
    validate,
    labelText,
    required,
    pattern,
    size,
    onChange,
    helpText,
    showRequired,
    prependText,
    appendText,
    largePrependText,
    showCharLimit,
    badgeOnRight,
    charLimitAtBottom,
    children,
    small,
    prependOffset,
    showCountOnlyOnfocus,
    error,
    hasTooltip = false,
    showTooltip = false,
    tooltipTitle = '',
    ...restInputProps
  } = props;

  const hasPrependText = !!prependText;
  const hasAppendText = !!appendText;
  const classes = cx('input-wrap', className, {
    'has-prepend-text': hasPrependText,
    'has-append-text': hasAppendText,
    'large-prepend-text': largePrependText,
  });

  const useStyles = makeStyles(() =>
    createStyles({
      tooltipPlacementBottom: {
        marginTop: '24px !important',
      },
    })
  );

  const styles = useStyles();

  const [isInputFocused, setInputFocused] = useState(false);

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const handleChange = (e) => {
          if (pattern) {
            const regex = new RegExp(pattern);
            if (!regex.test(e?.target?.value)) return;
          }
          input.onChange(e);
          onChange(e);
        };
        const { maxLength } = restInputProps;
        const { value } = input;
        let style = {};
        if (prependOffset) {
          style = { style: { paddingLeft: prependOffset } };
        }
        return (
          <FormGroup
            labelText={labelText}
            required={required}
            showRequired={showRequired}
            badgeOnRight={badgeOnRight}
            size={size}
            helpText={helpText}
            charLimitText={
              ((showCountOnlyOnfocus && isInputFocused) ||
                !showCountOnlyOnfocus) &&
              showCharLimit &&
              value.length > 0 ? (
                <span>
                  {`${value.length}/`}
                  <span className="text-medium">{maxLength}</span>
                </span>
              ) : null
            }
            charLimitAtBottom={charLimitAtBottom}
            className={cx('input-wrap', classes, {
              'has-error':
                error || (meta.error && meta.touched) || meta.submitError,
            })}
          >
            <div className={cx('input-group-wrap', { small })}>
              {hasPrependText && (
                <span className="prepend-txt-block fw500">{prependText}</span>
              )}
              {hasTooltip ? (
                <InputDisabledTooltip
                  title={tooltipTitle}
                  show={showTooltip}
                  tooltipProps={{
                    classes: {
                      tooltipPlacementBottom: styles.tooltipPlacementBottom,
                    },
                  }}
                >
                  <input
                    {...input}
                    onFocus={() => setInputFocused(true)}
                    onBlur={(e) => {
                      setInputFocused(false);
                      e.target.value.trim();
                      input.onBlur(e);
                    }}
                    {...restInputProps}
                    ref={ref}
                    onChange={handleChange}
                    {...style}
                  />
                </InputDisabledTooltip>
              ) : (
                <input
                  {...input}
                  onFocus={() => setInputFocused(true)}
                  onBlur={(e) => {
                    setInputFocused(false);
                    e.target.value.trim();
                    input.onBlur(e);
                  }}
                  {...restInputProps}
                  ref={ref}
                  onChange={handleChange}
                  {...style}
                />
              )}
              {hasAppendText && (
                <span className="append-txt">{appendText}</span>
              )}
            </div>
            {children}
          </FormGroup>
        );
      }}
    </Field>
  );
});

FormInput.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: false,
  disabled: false,
  type: 'text',
  autoComplete: 'off',
  size: 'lg', // md
  helpText: '',
  onChange: noop,
  showRequired: false,
  showCharLimit: false,
  charLimitAtBottom: false,
  showCountOnlyOnfocus: false,
};

FormInput.displayName = 'FormInput';
export default FormInput;
