export const BUTTON_TYPE_MAP = {
  UPDATE: 'update',
  DRAFT: 'draft',
  PUBLISH: 'publish',
};

export const BLOG_STATUS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
};

export const BUTTON_TEXT = {
  PUBLISH: 'Publish',
  SAVE: 'Save as draft',
  UPDATE: 'Update',
  PREVIEW: 'Preview',
  PUBLISH_BLOG: 'Publish blog',
};

export const BLOG_PATH = 'blog';

export const BLOGS_LINK = (domainWithLink = '', slug = '') =>
  `${domainWithLink}/${BLOG_PATH}/${slug}`;

export const SNACKBAR_MESSAGE = {
  LINK_EXISTS: 'This blog link already exists',
  SAVE_DRAFT: 'Blog saved as draft successfully!',
  SUCCESS: 'Blog created successfully!',
  SEO: (field) => `SEO ${field} is missing.`,
  CANNOT_PREVIEW: 'Can not preview a draft blog!',
};

export const BLOG_COLUMNS = [
  {
    title: 'Title',
    sortable: true,
    key: 'title',
  },
  // {
  //   title: 'Status',
  //   key: 'status',
  // },
  {
    title: 'Date',
    sortable: true,
    key: 'modified_at',
  },
  {
    title: 'Preview',
  },
];

export const CDN_URL = 'dms.mydukaan.io';
