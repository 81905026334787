import moment from 'moment';
import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'query-string';
import useUpdateEffect from './useUpdateEffect';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import {
  ALL_ORDERS_URL,
  CLUB_ORDER_URL,
  ORDER_BULK_STATUS_UPDATE_URL,
  ABANDONED_ORDERS_URL,
  STORE_TAGS,
  ORDERS_STATS_WITH_DURATION_MAPPING_URL,
} from '../ApiUrls';
import {
  ALL_STATUS,
  DELIVERED_STATUS,
  ORDER_STATUS_OPTIONS,
  TAGS_FOR,
} from '../Orders/constants';
import { useAppContext } from '../context/AppContext';
import { noop } from '../utils';
import {
  LAST_MODIFIED_DATE,
  LAST_MODIFIED_DATE_UTC,
  ORDER_DATE_ON,
  ORDER_DATE_ON_UTC,
} from '../Manage/Delivery/constants';
import { deepClone } from '../Appearance/utils';
import useLocalStorage from './useLocalStorage';
import {
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../constants';
import { formatServerDate } from '../utils/date';

const useFilters = (defaultFilters) => {
  const [filters, setFilters] = useState(defaultFilters || {});

  const applyFilters = (newFilters) => {
    const toApply = { ...filters, ...newFilters };
    if (Number(toApply.status) !== DELIVERED_STATUS) {
      delete toApply.type;
    }
    if (Boolean(toApply.dukaan_ship) === false) {
      delete toApply.dukaan_ship;
    }
    if (Boolean(toApply.returned_orders) === false) {
      delete toApply.returned_orders;
    }
    if (Boolean(toApply.self_ship) === false) {
      delete toApply.self_ship;
    }

    setFilters(toApply);
  };

  return [filters, applyFilters];
};

const useOrders = (
  defaultFilters = {},
  isTable,
  ignoreAPI = false,
  isNotInfiniteScroll = false
) => {
  const [search, setSearch] = useState('');
  const [ordersCount, setOrdersCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [tagsPageNumber, setTagsPageNumber] = useState(1);
  const [orderStatsLoading, setOrderStatsLoading] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [orderDateType, setOrderDateType] = useState(ORDER_DATE_ON);
  const [pageLoading, setPageLoading] = useState(true);
  const [appliedTags, setAppliedTags] = useState([]);

  const [orderingKey, setOrderingKey] = useState(ORDER_DATE_ON);
  const [orderingDirection, setOrderingDirection] = useState(
    SORTING_ORDER_DESCENDING
  ); // asc or desc
  const [pendingOrdersCount, setPendingOrdersCount] = useLocalStorage(
    'pending_orders',
    null
  );
  const [newOrdersCount, setNewOrdersCount] = useLocalStorage(
    'new_order',
    null
  );
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useFilters(defaultFilters, isTable);
  const [options, setOptions] = useState(ORDER_STATUS_OPTIONS);
  const [abandonedSearch, setAbandonedSearch] = useState('');
  const [orderTags, setOrderTags] = useState([]);

  const { isLoggedIn, setLifetimeOrderCount, business } = useAppContext();

  const constructParams = (status = null) => {
    const params = {
      ...filters,
      tags: appliedTags,
      ...(status !== null && { status }),
      page: pageNumber,
      search: abandonedSearch,
    };

    let dateType = orderDateType;
    if (orderDateType === ORDER_DATE_ON_UTC) {
      dateType = ORDER_DATE_ON;
    }
    if (orderDateType === LAST_MODIFIED_DATE_UTC) {
      dateType = LAST_MODIFIED_DATE;
    }

    if (params.startDate) {
      params[`${dateType}_after`] = params.startDate;
    }
    if (params.endDate) {
      params[`${dateType}_before`] = params.endDate;
    }
    if (parseInt(params.status) === ALL_STATUS) {
      delete params.status;
    }
    if (orderingKey !== '') {
      params.ordering = `${
        orderingDirection === SORTING_ORDER_DESCENDING ? '-' : ''
      }${orderingKey}`;
    }

    delete params.endDate;
    delete params.startDate;
    if (params.type) delete params.type;

    return params;
  };

  const toggleOrderingSort = (key) => {
    if (loading) {
      return;
    }
    setPageNumber(1);
    if (orderingKey !== key) {
      setOrderingKey(key);
      setOrderingDirection(SORTING_ORDER_DESCENDING);
    } else if (orderingDirection === SORTING_ORDER_DESCENDING)
      setOrderingDirection(SORTING_ORDER_ASCENDING);
    else setOrderingDirection(SORTING_ORDER_DESCENDING);
  };

  const onSearch = (text = '') => {
    setPageNumber(1);
    setSearch(text);
  };

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
    setPageNumber(1);
  };

  const onAbandonedSearch = (text = '') => {
    setAbandonedSearch(text.replace('#', ''));
    if (text) {
      applyFilters({
        ...filters,
        startDate: '',
        endDate: formatServerDate(moment()),
        selection: 'lifetime',
      });
    }
  };

  const fetchOrderStats = () => {
    if (!isLoggedIn) return;
    const params = {
      ...constructParams(),
      buyer: defaultFilters.buyer,
    };
    setOrderStatsLoading(true);
    getRequest({
      url: ORDERS_STATS_WITH_DURATION_MAPPING_URL,
      data: params,
    })
      .then(({ data }) => {
        let total = 0;

        const newOptions = deepClone(ORDER_STATUS_OPTIONS).map((opt) => {
          const newOpt = { ...opt };
          newOpt.count = data[newOpt.value] || 0;
          total += newOpt.count;
          return newOpt;
        });
        const allStatusOption = newOptions.find(
          (option) => option.value === ALL_STATUS
        );
        allStatusOption.count = total;
        setOptions(newOptions);
        setPendingOrdersCount(newOptions[1]?.count);
        setNewOrdersCount(data.new_order);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setOrderStatsLoading(false));
  };

  const fetchOrders = ({ status = null }) => {
    if (loading || !isLoggedIn) {
      return false;
    }
    let cancel;
    setLoading(true);
    const params = {
      ...constructParams(status),
      page: pageNumber,
      search,
    };
    getRequest({
      url: ALL_ORDERS_URL,
      data: params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((res) => {
        const {
          count,
          results,
          total_count: totalOrdersCount,
          page_size: newPageSize,
        } = res;
        setOrdersCount(count);
        if (pageSize !== newPageSize) setPageSize(newPageSize);
        if (isNotInfiniteScroll || pageNumber === 1) {
          setOrders(results);
        } else {
          setOrders((prevOrders) => [...new Set([...prevOrders, ...results])]);
        }
        setLifetimeOrderCount(totalOrdersCount);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isCancel(e)) {
          return false;
        }
      });
    return () => cancel();
  };

  // added "skiploading" in case two params(eg. search,filters) change simultaneously the call from the second change was getting canceled due to loading
  const fetchAbandonedOrders = (constructedParams, skipLoading = true) => {
    if (skipLoading && (loading || !isLoggedIn)) {
      return false;
    }
    let cancel;
    setLoading(true);

    const params = constructedParams ?? {
      ...constructParams(),
      page: pageNumber,
      search: abandonedSearch,
    };

    getRequest({
      url: ABANDONED_ORDERS_URL,
      data: params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((res) => {
        const { count, results } = res;
        if (pageNumber === 1) {
          setHasMore(results.length < count);
          setOrders(results);
        } else {
          setHasMore(orders.length + results.length < count);
          setOrders((prevOrders) => [...new Set([...prevOrders, ...results])]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isCancel(e)) {
          return false;
        }
      });
    return () => cancel();
  };

  const bulkOrderAction = (
    payload = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);

    patchRequest({ url: ORDER_BULK_STATUS_UPDATE_URL, data: payload })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (ignoreAPI) fetchAbandonedOrders({ ...constructParams() });
  }, [ignoreAPI]);

  useUpdateEffect(() => {
    if (ignoreAPI) {
      fetchAbandonedOrders({ ...constructParams() }, false);
    }
  }, [pageNumber, filters, orderingKey, orderingDirection]);

  useEffect(() => {
    if (!ignoreAPI) {
      fetchOrders({ ...constructParams() });
      fetchOrderStats({ ...constructParams() });
    }
  }, [
    pageNumber,
    filters,
    abandonedSearch,
    orderingKey,
    orderingDirection,
    appliedTags,
  ]);

  useEffect(() => {
    if (orders.length >= 0) {
      setPageLoading(false);
    }
  }, [orders]);

  const refetchOrders = (status) => {
    setPageNumber(1);
    fetchOrders({ status });
    fetchOrderStats();
  };

  const getStoreTags = () => {
    setLoadingTags(true);
    const params = { tag_for: TAGS_FOR.ORDER, page: tagsPageNumber };
    getRequest({
      url: STORE_TAGS(business.uuid),
      data: params,
    })
      .then((res) => {
        const { count, results = [] } = res ?? {};
        if (tagsPageNumber === 1) {
          setHasMoreTags(results.length < count);
          setOrderTags(results);
        } else {
          setHasMoreTags(orderTags.length + results.length < count);
          setOrderTags((prev) => [...new Set([...prev, ...results])]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingTags(false));
  };

  const clubOrders = (tableUuid) =>
    postRequest({
      url: CLUB_ORDER_URL(tableUuid),
    });

  return {
    loading,
    submitting,
    ordersCount,
    orders,
    search,
    hasMore,
    pageSize,
    pageNumber,
    setPageNumber,
    filters,
    applyFilters,
    fetchOrders,
    onSearch,
    refetchOrders,
    options,
    fetchOrderStats,
    bulkOrderAction,
    clubOrders,
    orderingKey,
    setOrderingKey,
    orderingDirection,
    toggleOrderingSort,
    constructParams,
    onAbandonedSearch,
    orderDateType,
    setOrderDateType,
    orderStatsLoading,
    pageLoading,
    pendingOrdersCount,
    newOrdersCount,
    getStoreTags,
    orderTags,
    appliedTags,
    setAppliedTags,
    setTagsPageNumber,
    hasMoreTags,
    loadingTags,
    tagsPageNumber,
    abandonedSearch,
  };
};

export default useOrders;
