import React from 'react';
import cx from 'classnames';
import { FormInput, FormTextArea, Validation } from '../../shared/Form';

const FaqFormInput = ({
  values = {},
  editableFAQItem = {},
  isCharBlack = false,
}) => (
  <section className="w-100">
    <FormInput
      className={cx('mb24', { 'char-input-black': isCharBlack })}
      labelText="Question"
      value={values.question || editableFAQItem?.question}
      name="question"
      id="question"
      placeholder="Enter the question"
      required
      showRequired
      validate={Validation.required()}
      showCharLimit
      maxLength={100}
    />
    <div className="faq-input-field">
      <FormTextArea
        labelText="Answer"
        value={values.answer || editableFAQItem?.answer}
        className={cx('lg', { 'char-input-black': isCharBlack })}
        name="answer"
        id="answer"
        maxLength={1000}
        showCharLimit
        placeholder="Enter the answer"
        textAreaClassName="d-block faq-text-area"
        required
        showRequired
        validate={Validation.required()}
      />
    </div>
  </section>
);

export default FaqFormInput;
