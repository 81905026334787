import React from 'react';
import cx from 'classnames';

const Icon = (props) => {
  const { hover, className, size, fontType, disabled, onClick, style } = props;
  const isClickable = !disabled && !!onClick;

  const classes = cx('material-icons', 'icon', className, `md-${size}`, {
    'md-hover': isClickable || hover,
    'md-inactive': disabled,
    disabled,
  });

  const iconProps = {
    className: classes,
    style,
  };

  if (isClickable) {
    iconProps.onClick = onClick;
  }

  return <i {...iconProps}>{fontType}</i>;
};

Icon.defaultProps = {
  className: '',
  size: 18, // sizes are 12, 14, 16, ... 48;
  hover: false,
  fontType: '',
  disabled: false,
  onClick: null,
  style: {},
};

export default Icon;
