import { useState, useEffect } from 'react';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import {
  CLEAR_CUSTOM_DOMAIN_BANNER_URL,
  CUSTOM_DOMAIN_PURCHASE_URL,
  CUSTOM_DOMAIN_URL,
  EXTERNAL_DOMAIN_URL,
  PREMIUM_DOMAIN_PURCHASE,
  PREMIUM_DOMAIN_PURCHASE_PADDLE,
  VERIFY_EXTERNAL_DOMAIN_URL,
  POST_DOMAIN_RENEWAL,
  DELINK_DOMAIN_URL,
} from '../ApiUrls';
import { useAppContext } from '../context/AppContext';
import { noop } from '../utils';
import useCustomSnackbar from './useCustomSnackbar';
import {
  useRazorpayCustomCheckout,
  useRazorpayCheckout,
} from '../Subscription/NewSubscription/PaymentButtons/RazorpayButton';
import Logo from '../../images/icons/logo_white.jpg';
import { accountUrls } from '../Urls';
import { useCountry } from '../context/CountryProvider';
import { isStagingEnv, RAZORPAY_API_KEY } from '../constants';
import { DEFAULT_PADDLE_VENDOR_ID } from '../Subscription/constants';

const useCustomDomain = (canFetch = true) => {
  const { business, fetchBusinessDetails, userDetails } = useAppContext();
  const { name: storeName } = business;
  const { mobile } = userDetails;
  const { enqueueSnackbar } = useCustomSnackbar();
  const { currencyCode, isInternational } = useCountry();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [premiumDomain, setPremiumDomain] = useState(false);
  const [setupDetails, setSetupDetails] = useState([]);

  const onSearch = (text) => {
    setSearch(text);
  };

  const fetchDomains = () => {
    if (loading || !canFetch) {
      return false;
    }
    setLoading(true);

    const params = { search };
    if (premiumDomain) {
      params.premium = true;
    }
    getRequest({
      url: CUSTOM_DOMAIN_URL,
      data: params,
    })
      .then((res) => {
        const { data } = res;
        if (premiumDomain) {
          setDomains(data.filter((domain) => domain.purchasePrice > 0));
        } else {
          setDomains(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const purchaseDomain = (data, callback = noop) => {
    setSubmitting(true);
    postRequest({
      url: CUSTOM_DOMAIN_PURCHASE_URL(business.id),
      data,
    })
      .then((res) => {
        callback(res);
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong.', 'error');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const fetchSetupDetails = (domain, onSuccess = noop, onError = noop) => {
    setLoading(true);
    postRequest({
      url: VERIFY_EXTERNAL_DOMAIN_URL,
      data: { custom_domain: domain },
    })
      .then((res) => {
        onSuccess(res);
        setSetupDetails(res.data.dns_record);
      })
      .catch((e) => {
        onError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyDomain = (domain, onSuccess = noop, onError = noop) => {
    setSubmitting(true);
    postRequest({
      url: VERIFY_EXTERNAL_DOMAIN_URL,
      data: { custom_domain: domain },
    })
      .then((res) => {
        onSuccess(res);
      })
      .catch((e) => {
        onError(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const linkExistingDomain = (domain, onSuccess = noop, onError = noop) => {
    setSubmitting(true);
    postRequest({
      url: EXTERNAL_DOMAIN_URL(business.id),
      data: { custom_domain: domain },
    })
      .then((res) => {
        onSuccess(res);
      })
      .catch((e) => {
        onError(e);
        enqueueSnackbar('Something went wrong.', 'error');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const setBannerShown = (cb = noop) => {
    patchRequest({
      url: CLEAR_CUSTOM_DOMAIN_BANNER_URL(business.uuid),
    }).then(cb);
  };

  const removeExternalDomain = (cb = noop) => {
    setSubmitting(true);
    postRequest({
      url: DELINK_DOMAIN_URL,
    })
      .then(() => {
        cb();
        enqueueSnackbar('Custom domain removed successfully!');
      })
      .catch(() =>
        enqueueSnackbar(
          'Unable to remove custom domain, please try later!',
          'error'
        )
      )
      .finally(() => setSubmitting(false));
  };

  const openCheckout = useRazorpayCustomCheckout({
    url: PREMIUM_DOMAIN_PURCHASE,
    payload: { custom_domain: selectedDomain?.domainName },
  });

  const generateOrderForPremiumDomain = () => {
    const oldCustomDomain = business.meta.misc_data?.old_custom_domain;
    const isRenew = selectedDomain?.domainName === oldCustomDomain;
    const handler = () => {
      window.location.href = accountUrls.customDomainPending;
    };
    const options = {
      name: `${isRenew ? 'Domain renewal' : 'Premium Domain'}`,
      key: RAZORPAY_API_KEY,
      amount: (Number(selectedDomain?.purchasePrice ?? 0) * 100).toString(),
      currency: currencyCode,
      description: `${isRenew ? 'Renewing' : 'Purchasing'} ${
        selectedDomain?.domainName
      }`,
      image: Logo,
      prefill: {
        name: storeName,
        email: 'orders@mydukaan.io',
        contact: mobile,
      },
      handler,
      theme: {
        color: '#146eb4',
      },
    };

    openCheckout(options);
  };

  const generateOrderForPremiumDomainPaddle = (domainName, isRenew = false) => {
    enqueueSnackbar('Payment dialog will open shortly', 'info');
    const url = isRenew ? POST_DOMAIN_RENEWAL : PREMIUM_DOMAIN_PURCHASE_PADDLE;
    postRequest({
      data: { custom_domain: domainName },
      url,
    }).then((resp) => {
      if (resp?.data?.url) {
        const { Paddle } = window;
        if (!Paddle) {
          window.open(resp.data.url, '_blank');
          return;
        }

        Paddle.Setup({
          vendor: DEFAULT_PADDLE_VENDOR_ID,
          eventCallback: (data) => {
            if (data.event === 'Checkout.Complete') {
              enqueueSnackbar('Domain purchased successfully!');
              setTimeout(() => {
                window.location.href = `${accountUrls.settingsPath}?section=4`;
              }, 2000);
            } else if (data.event === 'Checkout.Close') {
              window.location.reload();
            }
          },
        });
        if (isStagingEnv) {
          Paddle.Environment.set('sandbox');
        }
        Paddle.Checkout.open({
          override: resp.data.url,
          allowQuantity: false,
          // email,
        });
      }
    });
  };

  const openCheckoutRenew = useRazorpayCheckout({
    url: POST_DOMAIN_RENEWAL,
    payload: {},
    failureRedirectPath: '',
    enqueueSnackbar,
  });

  const customDomainRenewal = (displayDomain, resolveCb = noop) => {
    if (isInternational) {
      generateOrderForPremiumDomainPaddle(displayDomain, true);
    } else {
      enqueueSnackbar('Payment dialog will open shortly!', 'info', {
        action: null,
      });
      const options = {
        name: 'Domain Renewal',
        key: RAZORPAY_API_KEY,
        currency: currencyCode,
        description: `Renewing ${displayDomain}`,
        image: Logo,
        prefill: {
          name: storeName,
          email: 'orders@mydukaan.io',
          contact: mobile,
        },
        failCallCB: resolveCb,
        handler: () => {
          enqueueSnackbar('Custom Domain renewed successfully');
          resolveCb();
          setTimeout(() => fetchBusinessDetails(), 1000);
        },
        theme: {
          color: '#146eb4',
        },
      };

      openCheckoutRenew(options);
    }
  };

  useEffect(() => {
    console.log(selectedDomain);
  }, [selectedDomain]);

  useEffect(() => {
    if (search || premiumDomain) fetchDomains();
  }, [search, premiumDomain]);

  return {
    loading,
    submitting,
    search,
    domains,
    premiumDomain,
    setupDetails,
    setPremiumDomain,
    onSearch,
    purchaseDomain,
    fetchDomains,
    selectedDomain,
    verifyDomain,
    setSelectedDomain,
    generateOrderForPremiumDomain,
    generateOrderForPremiumDomainPaddle,
    setBannerShown,
    fetchSetupDetails,
    linkExistingDomain,
    customDomainRenewal,
    removeExternalDomain,
  };
};

export default useCustomDomain;
