import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { getRequest } from '../utils/http';

import {
  STORE_ORDERS_OVERVIEW_DATA_URL,
  STORE_VIEW_OVERVIEW_DATA_URL,
} from '../ApiUrls';
import { DEFAULT_DATE_RANGE } from '../shared/DatePicker/util';

const OverviewDataContext = createContext(null);

export const useOverviewData = () => useContext(OverviewDataContext);

const OverviewDataProvider = ({ children }) => {
  const [storeViewsData, setStoreViewsData] = useState([]);
  const [storeOrdersData, setStoreOrdersData] = useState([]);
  const [businessStats, setBusinessStats] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [frequencyKey, setFrequencyKey] = useState(null);
  const [orderStatsLoading, setOrderStatsLoading] = useState(false);
  const [viewsStatsLoading, setViewsStatsLoading] = useState(false);
  const [viewStatsHasError, setViewStatsHasError] = useState(false);

  const getDates = (start, end) => {
    var arr = {};
    var dt = new Date();
    for (dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr[new Date(dt).toISOString().slice(0, 10)] = 0;
    }
    return arr;
  };

  const formatData = (data, dataKey, params = {}, paramsKey) => {
    if (!data?.length) return [{ value: 0, label: new Date().getTime() }];
    var finalDates = [];
    var dateRange = getDates(
      new Date(params[paramsKey]),
      new Date(params[paramsKey])
    );
    for (var i = 0; i < data.length; i += 1) {
      var timeFrame = data[i];
      dateRange[timeFrame[paramsKey].slice(0, 10)] = {
        value: timeFrame[dataKey],
        ...timeFrame,
      };
    }
    Object.entries(dateRange).forEach(([key, value]) => {
      finalDates.push({
        label: key,
        ...value,
      });
    });
    finalDates.sort((a, b) => moment(a.label) - moment(b.label));
    return finalDates;
  };

  const fetchViewsData = useCallback(
    (params) =>
      getRequest({
        url: STORE_VIEW_OVERVIEW_DATA_URL,
        data: params,
        retries: 3,
      }),
    []
  );

  const fetchOrdersData = useCallback(
    (params) =>
      getRequest({
        url: STORE_ORDERS_OVERVIEW_DATA_URL,
        data: params,
      }),
    []
  );

  const fetchData = (params) => {
    setOrderStatsLoading(true);
    setViewsStatsLoading(true);
    setFilterParams(params);

    fetchOrdersData(params)
      .then((ordersRes) => {
        const startDate = new Date(params.created_at_after);
        const endDate = new Date(params.created_at_before);
        const dateDiff = moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asDays();

        let freq;

        if (params?.dateRangeType === 'lifetime') {
          freq = 'monthly';
        } else if (dateDiff === 0) {
          freq = 'hourly';
        } else {
          freq = 'daily';
        }
        setFrequencyKey(freq);

        const { orders_graph_data: orderGraphData, ...restOrdersData } =
          ordersRes.data || {};
        setStoreOrdersData(
          formatData(orderGraphData, 'sales', params, 'created_at')
        );
        setBusinessStats((prevState) => ({
          ...prevState,
          ...restOrdersData,
        }));
      })
      .finally(() => setOrderStatsLoading(false));

    fetchViewsData(params)
      .then((storeViewRes) => {
        const { store_view_graph_data: viewGraphData, ...restStoreViewData } =
          storeViewRes.data || {};

        setStoreViewsData(
          formatData(viewGraphData, 'views', params, 'created_at')
        );
        setBusinessStats((prevState) => ({
          ...prevState,
          ...restStoreViewData,
        }));
        setViewStatsHasError(false);
      })
      .catch((error) => {
        console.log(error);
        setViewStatsHasError(true);
      })
      .finally(() => setViewsStatsLoading(false));
  };

  useEffect(() => {
    if (
      !storeViewsData.length &&
      !storeOrdersData.length &&
      !(orderStatsLoading || viewsStatsLoading)
    ) {
      fetchData({
        delivered_at_before: DEFAULT_DATE_RANGE.END_DATE,
        created_at_before: DEFAULT_DATE_RANGE.END_DATE,
        delivered_at_after: DEFAULT_DATE_RANGE.START_DATE,
        created_at_after: DEFAULT_DATE_RANGE.START_DATE,
        dateRangeType: DEFAULT_DATE_RANGE.SELECTION,
      });
    }
  }, []);

  const formattedDateRange = useCallback(() => {
    const fromDateRaw = filterParams.delivered_at_after;
    const toDateRaw = filterParams.delivered_at_before;

    if (!fromDateRaw || !toDateRaw) return 'Lifetime';

    const fromDate = moment(fromDateRaw);
    const toDate = moment(toDateRaw);

    return `${fromDate.format('D MMM')} - ${toDate.format('D MMM')}`;
  }, [filterParams])();

  const contextValue = {
    storeOrdersData,
    storeViewsData,
    businessStats,
    fetchData,
    setFilterParams,
    filterParams,
    formattedDateRange,
    frequencyKey,
    orderStatsLoading,
    viewsStatsLoading,
    viewStatsHasError,
  };

  return (
    <OverviewDataContext.Provider value={contextValue}>
      {children}
    </OverviewDataContext.Provider>
  );
};

export default OverviewDataProvider;
