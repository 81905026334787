import React from 'react';
import cx from 'classnames';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { noop } from '../utils';

import {
  CheckboxChecked,
  CheckboxUnChecked,
  HalfCheckIcon,
  CheckboxDisabled,
} from '../SvgIcon';

const CustomCheckbox = React.forwardRef((props, ref) => {
  const { isHalfChecked = false, className = '', ...restProps } = props;
  return (
    <Checkbox
      disableRipple
      ref={ref}
      className={`custom-checkbox ${className}`}
      checkedIcon={isHalfChecked ? <HalfCheckIcon /> : <CheckboxChecked />}
      icon={props.disabled ? <CheckboxDisabled /> : <CheckboxUnChecked />}
      {...restProps}
    />
  );
});

CustomCheckbox.displayName = 'CustomCheckbox';

const CheckboxField = ({
  label,
  name,
  checked = false,
  onChange,
  disabled,
  className,
  checkBoxClass,
  isHalfChecked = false,
  labelPlacement = 'end',
  hideCheckbox = false,
}) => (
  <FormControlLabel
    checked={checked}
    className={cx(`checkbox-label-wrap ${checkBoxClass}`, className)}
    control={
      <CustomCheckbox
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        className={cx({ 'd-none': hideCheckbox })}
        name={name}
        isHalfChecked={isHalfChecked}
      />
    }
    label={<div className="checkbox-label cur-p">{label}</div>}
    labelPlacement={labelPlacement}
  />
);

CheckboxField.defaultProps = {
  disabled: false,
  name: '',
  label: '',
  checked: false,
  onChange: noop,
};

export default CheckboxField;
