import { useState } from 'react';
import { LIST_APPLIED_TAGS, REMOVE_STORE_TAGS, STORE_TAGS } from '../ApiUrls';
import {
  PAGE_SIZE_FOR_BRAND_AND_TAG,
  PAGINATION_BEHAVIOUR,
} from '../Catalogue/constants';
import { useAppContext } from '../context/AppContext';
import { CONTENT_TYPE, TAGS_FOR } from '../Orders/constants';
import { getErrorMessage, noop } from '../utils';
import { deleteRequest, getRequest, postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';
import useLocalStorage from './useLocalStorage';

const useTags = ({ type = TAGS_FOR.ORDER }) => {
  const { business } = useAppContext();
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [tagsPageNumber, setTagsPageNumber] = useState(1);
  const [tags, setTags] = useLocalStorage(type, []);
  const [appliedTagsListing, setAppliedTagsListing] = useState([]);
  const [appliedTags, setAppliedTags] = useState([]);
  const [search, setSearch] = useState(null);
  const [tagsCount, setTagsCount] = useState(null);

  const { enqueueSnackbar } = useCustomSnackbar();

  const getErrorMessageFromRes = (error) => {
    const { data = {} } = error;
    let errorObject = {};
    if (typeof data !== 'string' && data.data) {
      errorObject = data.data;
    }
    const errMsg = getErrorMessage(errorObject);
    return errMsg;
  };

  const getStoreTags = (
    pageSize = PAGE_SIZE_FOR_BRAND_AND_TAG,
    pagination = PAGINATION_BEHAVIOUR.INFINITE
  ) => {
    setLoadingTags(true);
    const params = {
      tag_for: type,
      page: tagsPageNumber,
      page_size: pageSize,
    };

    if (search !== '') {
      params.search = search;
    }

    getRequest({
      url: STORE_TAGS(business.uuid),
      data: params,
    })
      .then((res) => {
        const { count, results = [] } = res ?? {};
        setTagsCount(count);
        if (tagsPageNumber === 1) {
          setHasMoreTags(results.length < count);
          setTags(results);
        } else {
          setHasMoreTags(tags.length + results.length < count);

          if (pagination === PAGINATION_BEHAVIOUR.INFINITE) {
            setTags((prev) => {
              const seenTags = new Set();
              const tagsArray = [...prev, ...results];

              const filteredTagsArray = tagsArray.filter((each) => {
                const duplicate = seenTags.has(each.id);
                seenTags.add(each.id);

                return !duplicate;
              });

              return filteredTagsArray;
            });
          }
          if (pagination === PAGINATION_BEHAVIOUR.SPLIT) setTags([...results]);
        }
        setLoadingTags(false);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingTags(false));
  };

  const fetchAppliedTags = (objectId) => {
    const params = {
      object_id: objectId,
      content_type: CONTENT_TYPE[type],
    };
    getRequest({
      url: LIST_APPLIED_TAGS(business.uuid),
      data: params,
    })
      .then((res) => {
        setAppliedTags(res.results);
      })
      .catch((e) => console.log(e));
  };

  const applyStoreTags = (data, objectId, callbackFn = noop) => {
    const payload = {
      tag: data.id,
      object_id: objectId,
      content_type: type,
    };
    postRequest({
      url: LIST_APPLIED_TAGS(business.uuid),
      data: payload,
    })
      .then((res) => {
        callbackFn(res.data);
      })
      .catch((e) => console.log(e));
  };

  const createStoreTag = (
    payload,
    objectId,
    successCb = noop,
    afterApplyCallback = noop
  ) => {
    postRequest({
      url: STORE_TAGS(business.uuid),
      data: payload,
    })
      .then((res) => {
        successCb(res.data);
        if (objectId) {
          applyStoreTags(res.data, objectId, afterApplyCallback);
        }
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(getErrorMessageFromRes(e), 'error');
      });
  };

  const removeStoreTag = (tagUuid) => {
    deleteRequest({
      url: REMOVE_STORE_TAGS(business.uuid, tagUuid),
    })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  return {
    getStoreTags,
    createStoreTag,
    applyStoreTags,
    fetchAppliedTags,
    removeStoreTag,
    setTagsPageNumber,
    hasMoreTags,
    loadingTags,
    tagsPageNumber,
    appliedTags,
    setAppliedTags,
    appliedTagsListing,
    setAppliedTagsListing,
    tags,
    search,
    setSearch,
    tagsCount,
  };
};

export default useTags;
