import { createContext, useContext, useState } from 'react';
import { useCountry } from './CountryProvider';

const InvoiceContext = createContext({});
export const useInvoice = () => useContext(InvoiceContext);

const InvoiceProvider = ({ children }) => {
  const { currencySymbol: defaultSymbol } = useCountry();
  const [currencySymbol, setCurrencySymbol] = useState(defaultSymbol);
  const currencySymbolWithSpace = `${currencySymbol}`;
  return (
    <InvoiceContext.Provider
      value={{ currencySymbol, setCurrencySymbol, currencySymbolWithSpace }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceProvider;
