import { useState, useEffect, useCallback } from 'react';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import {
  CANCELLED_LINE_ITEMS_DETAILS,
  DELIVERY_DETAILS_URL,
  INITIAL_RETURN_REFUND,
  MARK_REPLACEMENT_COMPLETE_URL,
  ORDER_ACTIVITY_DETAILS_URL,
  ORDER_PAYMENTS_URL,
  ORDER_URL,
  POPULATE_RTO_SCORE_URL,
  REJECT_RETURN_ORDER_URL,
  RESEND_MANUAL_ORDER_PAYMENT_LINK,
  RETURN_REPLACEMENT_DETAILS_URL,
  UPDATE_ORDERS_NOTES,
} from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';
import {
  APPROVED_RETURN,
  COMPLETED_RETURN,
  REJECTED_RETURN,
  REQUESTED_RETURN,
  RETURN_REPLACEMENT_MAP,
} from '../Orders/constants';
import { SOURCE_METHOD_KEY } from '../Account/constants';
import { deepClone } from '../Appearance/utils';

const useOrderDetails = (uuid, historyInformation = {}, queryParams = {}) => {
  const [loading, setLoading] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [populatingRTOScore, setPopulatingRTOScore] = useState(false);
  const [orderDetails, setOrderDetails] = useState(
    Object.keys(historyInformation).length > 0
      ? {
          ...historyInformation,
          address: historyInformation.buyer_address,
        }
      : {}
  );

  const [products, setProducts] = useState([]);
  const [orderShipmentDetails, setOrderShipmentDetails] = useState(null);
  const [returnReplacementData, setReturnReplacementData] = useState({});
  const [orderPayments, setOrderPayments] = useState([]);
  const [orderActivityList, setOrderActivityList] = useState([]);
  const [cancelledLineItemDetailsMap, setCancelledLineItemDetailsMap] =
    useState({});

  const { enqueueSnackbar } = useCustomSnackbar();

  const url = ORDER_URL(uuid);

  const refetch = useCallback(() => {
    if (!uuid) return;
    setLoading(true);
    getRequest({ url })
      .then(({ data }) => {
        setOrderDetails(data);
        let updatedProduct = data.products.filter(
          (each) =>
            each?.quantity > 0 ||
            each?.quantity_returned > 0 ||
            each?.quantity_freed > 0
        );
        const isReturnedOrReplacementExist =
          data?.order_returns_data?.active_returns?.length > 0;
        const returnOrderStatus =
          data?.order_returns_data?.active_returns?.[0]?.status;
        if (
          isReturnedOrReplacementExist &&
          [REQUESTED_RETURN, REJECTED_RETURN].includes(returnOrderStatus)
        ) {
          updatedProduct = updatedProduct
            .filter((each) => !each.new_line_time)
            .map((each) => {
              if (typeof each?.line_item_state === 'number')
                each.badgeText = RETURN_REPLACEMENT_MAP[each?.line_item_state];
              return each;
            });
        }
        if (
          isReturnedOrReplacementExist &&
          [APPROVED_RETURN, COMPLETED_RETURN].includes(returnOrderStatus)
        ) {
          updatedProduct = updatedProduct
            .filter((each) => each.line_item_state === null)
            .map((each) => {
              if (each?.new_line_time)
                each.badgeText = RETURN_REPLACEMENT_MAP.UPDATED;
              return each;
            });
        }
        setProducts(updatedProduct);
      })
      .finally(() => setLoading(false));
  }, [url]);

  const populateRTOScore = ({ successCallback = noop }) => {
    if (!uuid) return;
    setPopulatingRTOScore(true);
    postRequest({ url: POPULATE_RTO_SCORE_URL(uuid) })
      .then(({ data }) => {
        setOrderDetails({ ...orderDetails, rto_score: data.rto_score });
        setPopulatingRTOScore(false);
        successCallback();
      })
      .catch((err) => {
        if (err.data.data.error) enqueueSnackbar(err.data.data.error, 'error');
        else if (err.data.data.msg) enqueueSnackbar(err.data.data.msg, 'error');
        else enqueueSnackbar('Something went wrong please try again.', 'error');

        setPopulatingRTOScore(false);
      })
      .finally(() => setPopulatingRTOScore(false));
  };

  const fetchReturnReplacementDetails = () => {
    setLoading(true);
    return getRequest({
      url: RETURN_REPLACEMENT_DETAILS_URL(uuid),
    })
      .then(({ data }) => {
        const isReturnedOrReplacementExist = data?.order_return_id > 0;

        const isReplacementOrderExist =
          isReturnedOrReplacementExist &&
          data?.replaced_items_data?.replaced_items?.length > 0;

        let returnedDetails = {};

        if (isReturnedOrReplacementExist) {
          returnedDetails = isReplacementOrderExist
            ? data?.replaced_items_data
            : data?.returned_items_data;
        }

        const isReturnedOrderExist =
          isReturnedOrReplacementExist &&
          returnedDetails?.returned_items?.length > 0;

        let replacementOrReturnOrderList = [];

        if (isReplacementOrderExist) {
          replacementOrReturnOrderList = returnedDetails?.replaced_items?.map(
            (each) => each?.item_getting_replaced?.line_item_id
          );
        }

        if (isReturnedOrderExist) {
          replacementOrReturnOrderList = returnedDetails?.returned_items?.map(
            (each) => each?.line_item_id
          );
        }

        const updatedData = {
          ...data,
          isReturnedOrReplacementExist,
          isReplacementOrderExist,
          isReturnedOrderExist,
          returnedDetails,
          replacementOrReturnOrderList,
        };

        if (isReturnedOrReplacementExist) {
          if (isReplacementOrderExist)
            updatedData.badgeText =
              updatedData?.return_replacement_status === COMPLETED_RETURN
                ? 'REPLACED'
                : 'REPLACEMENT';

          if (isReturnedOrderExist)
            updatedData.badgeText =
              updatedData?.return_replacement_status === COMPLETED_RETURN
                ? 'RETURNED'
                : 'RETURN';
        }

        setReturnReplacementData(updatedData);
        return data;
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const fetchOrderPayments = (refundMode = SOURCE_METHOD_KEY) =>
    getRequest({
      url: `${ORDER_PAYMENTS_URL(uuid)}?refund_mode=${refundMode}`,
    })
      .then((res) => {
        setOrderPayments(res.data);
        return res.data;
      })
      .catch(console.log)
      .finally(() => {});

  const markReplacementComplete = (returnUuid, successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: MARK_REPLACEMENT_COMPLETE_URL(returnUuid),
    })
      .then(() => {
        successCallback();
        enqueueSnackbar('Replacement marked completed!');
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong!', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const fetchDeliveryDetails = (successCallback = noop) => {
    setLoading(true);
    return getRequest({
      url: DELIVERY_DETAILS_URL(uuid),
    })
      .then((res) => res.data)
      .then((data) => {
        successCallback(data);
        setOrderShipmentDetails(data.shipment_data);
        return data;
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const rejectOrderReturn = (status, successCallback = noop) => {
    setSubmitting(true);
    return postRequest({
      url: REJECT_RETURN_ORDER_URL(uuid),
      data: { status },
    })
      .then((res) => {
        if (res.data) {
          successCallback(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something went wrong. Try again later.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const refundReturnOrReplacementOrder = (
    returnUuid,
    successCallback = noop,
    payload = {}
  ) => {
    setRefunding(true);
    postRequest({
      url: INITIAL_RETURN_REFUND(returnUuid),
      data: payload,
    })
      .then((res) => {
        enqueueSnackbar('Refund has been initiated.');
        successCallback(res.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.data?.data?.error)
          enqueueSnackbar(error.data.data.error, 'error');
        else if (error?.data?.data?.msg)
          enqueueSnackbar(error.data.data.msg, 'error');
        else enqueueSnackbar('Something went wrong. Try again later.', 'error');
      })
      .finally(() => setRefunding(false));
  };

  const resendManualPaymentLink = () => {
    setSubmitting(true);
    postRequest({
      url: RESEND_MANUAL_ORDER_PAYMENT_LINK(uuid),
    })
      .then(() => {
        enqueueSnackbar('Payment link resent successfully!.');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something went wrong.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const cancelledLineItemDetails = (payload = {}, successCallback = noop) => {
    postRequest({
      url: CANCELLED_LINE_ITEMS_DETAILS,
      data: payload,
    })
      .then((res) => {
        successCallback(res.data);
        if (res.data?.length > 0)
          setCancelledLineItemDetailsMap(
            res?.data?.reduce((acc, each) => {
              acc[each.line_item] = each;
              return acc;
            }, {})
          );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setRefunding(false));
  };

  const fetchOrderActivityList = (successCallback = noop) => {
    setActivityLoading(true);
    getRequest({
      url: ORDER_ACTIVITY_DETAILS_URL,
      data: {
        entity_uuid: uuid,
        entity: 'order',
      },
    })
      .then((res) => {
        successCallback(res.data);
        setOrderActivityList(res.data.logs ?? []);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setActivityLoading(false));
  };

  const updateOrderNotes = (notes, successCb = noop) => {
    patchRequest({
      url: UPDATE_ORDERS_NOTES(uuid),
      data: {
        notes,
      },
    })
      .then(() => {
        successCb(notes);
        setOrderDetails((pre) => {
          pre.notes = notes;
          return deepClone(pre);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(fetchOrderActivityList, []);

  useEffect(() => {
    if (products.length > 0) {
      cancelledLineItemDetails({
        line_items: products.map((each) => ({
          line_item: each?.line_item_uuid,
        })),
        order: orderDetails.uuid,
      });
    }
  }, [products]);

  useEffect(refetch, [refetch]);

  return {
    loading,
    activityLoading,
    refunding,
    orderLoading: loading,
    orderDetails,
    submitting,
    refetch,
    products,
    orderPayments,
    setProducts,
    populateRTOScore,
    populatingRTOScore,
    setPopulatingRTOScore,
    fetchOrderActivityList,
    fetchReturnReplacementDetails,
    rejectOrderReturn,
    orderShipmentDetails,
    orderActivityList,
    fetchDeliveryDetails,
    returnReplacementData,
    refundReturnOrReplacementOrder,
    resendManualPaymentLink,
    markReplacementComplete,
    fetchOrderPayments,
    updateOrderNotes,
    cancelledLineItemDetailsMap,
  };
};

export default useOrderDetails;
