import React from 'react';
import cx from 'classnames';
import CustomAccordion from '../../shared/CustomAccordian';
import { noop } from '../../utils';
import { HowItWorks } from '../../Layout/Header';

const OrderDetailSection = ({
  children,
  isExpanding = false,
  sectionHeader,
  expandIcon,
  expanded,
  onClick,
  isFirstSection = false,
  showWarningHeader = false,
}) => {
  const isSectionHeaderString = typeof sectionHeader === 'string';
  return isExpanding ? (
    <CustomAccordion
      className={cx({ my24: !isFirstSection, mb24: isFirstSection })}
      expandIcon={expandIcon}
      expanded={expanded}
      onClick={onClick}
      heading={
        <div className="w-100">
          <div className="section-text-4 text-semibold w-full">
            {sectionHeader}
          </div>
        </div>
      }
    >
      {children}
    </CustomAccordion>
  ) : (
    <div
      className={cx('card', {
        my24: !isFirstSection,
        mb24: isFirstSection,
        'card-with-top-banner': showWarningHeader,
      })}
    >
      <div className={cx('p24 pb16', { pt16: !isFirstSection })}>
        {isSectionHeaderString ? (
          <h4 className="section-text-4 text-semibold">{sectionHeader}</h4>
        ) : (
          sectionHeader
        )}
        <hr className={cx('hr-line mb20 mt12', { mt16: isFirstSection })} />
        {children}
      </div>
    </div>
  );
};

export const OrderDetailSectionHeader = ({
  title = '',
  hideBtn = false,
  btnText = '',
  btnClick = noop,
  btnClassName = 'btn-no-default cur-p text-medium c-purple-1 text-2 underline-h p0 edit-customer-button',
  helpText = '',
  helpUrl,
}) => (
  <div className="d-flex-c-s">
    <div className="d-flex align-center">
      <h4 className="section-text-4 text-semibold">{title}</h4>
      {helpUrl && (
        <HowItWorks url={helpUrl} useAbsoluteUrl customText={helpText} />
      )}
    </div>
    {!hideBtn && btnText && (
      <button type="button" className={btnClassName} onClick={btnClick}>
        {btnText}
      </button>
    )}
  </div>
);

export default OrderDetailSection;
