import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import Modal from './Modal';
import SpinnerButton from './SpinnerButton';
import useEnterPressAction from '../hooks/useEnterPressAction';

const GoOnlineModal = (props) => {
  const { isSubmitting, open, handleClose, handleConfirm } = props;
  const [value, setValue] = React.useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [AFTER_1_HR, AFTER_2_HR, AFTER_4_HR, TOMORROW, MANUAL] = [
    '0',
    '1',
    '2',
    '3',
    '4',
  ];

  const handleSubmit = () => handleConfirm(value);

  const getTimeToDisplay = () => {
    if (!value || value === MANUAL) return <div>&nbsp;</div>;

    let calculatedTime = null;
    if (value === AFTER_1_HR)
      calculatedTime = moment().add(1, 'hour').format('hh:mm A');
    if (value === AFTER_2_HR)
      calculatedTime = moment().add(2, 'hours').format('hh:mm A');
    if (value === AFTER_4_HR)
      calculatedTime = moment().add(4, 'hours').format('hh:mm A');
    if (value === TOMORROW) {
      calculatedTime = `Tomorrow, ${moment().format('hh:mm A')}`;
    }
    return (
      <p className="re-opens-message">
        Your store re-opens at{' '}
        <span className="re-opens-time">{calculatedTime}</span>
      </p>
    );
  };

  // (0, '1 hour'), (1, '2 Hours'), (2, '4 hours'), (3, 'Tomorrow at same time'), (4, 'I will go online myself')
  const GO_ONLINE_AFTER_TIME = [
    { label: '1 hour', value: AFTER_1_HR },
    { label: '2 hours', value: AFTER_2_HR },
    { label: '4 hours', value: AFTER_4_HR },
    { label: 'Tomorrow, at same time', value: TOMORROW },
    { label: 'I will go online myself', value: MANUAL },
  ];

  useEnterPressAction(open, handleSubmit);

  return (
    <Modal open={open} className="go-online-modal" closeModal={handleClose}>
      <div>
        <h2 className="section-text-4">Go online after</h2>
        <div className="time-selection-wrapper">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
          >
            {GO_ONLINE_AFTER_TIME.map((timeEntry) => (
              <FormControlLabel
                key={timeEntry.value}
                value={timeEntry.value}
                control={<Radio />}
                label={timeEntry.label}
                className="time-entry"
                classes={{ label: 'time-entry' }}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="text-center mb24">{getTimeToDisplay()}</div>
        <div className="text-center">
          <SpinnerButton
            showAnimation
            isLoading={isSubmitting}
            label="Confirm"
            onClick={handleSubmit}
            className="btn-primary-4"
            disabled={!value || isSubmitting}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GoOnlineModal;
