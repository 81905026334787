import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FormInput } from '../../shared/Form';
import ProgressBar from '../../shared/ProgressBar';
import SpinnerButton from '../../shared/SpinnerButton';
import { usePasswordStrength } from '../../hooks/misc/usePasswordStrength';
import { DEFAULT_PASSWORD_PROPS } from '../constants';

const CreatePassword = ({ email, onPasswordSubmit, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { getPasswordStrengthConfig } = usePasswordStrength();
  const onSubmit = ({ password }) => {
    onPasswordSubmit({ email, password });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, submitting, values }) => {
        const {
          className: barClassName,
          value,
          descElement,
        } = getPasswordStrengthConfig(values?.password);
        return (
          <>
            <h4 className="section-text-1 pt8 mb40">Create a password</h4>
            <div className="create-password-field">
              <div className="password-strength-container">
                {descElement}
                <ProgressBar
                  variant="determinate"
                  value={value}
                  classes={{
                    bar: barClassName,
                    root: 'progress-bar-root',
                  }}
                />
              </div>
              <FormInput
                labelText="Password"
                placeholder="Enter your password"
                name="password"
                {...DEFAULT_PASSWORD_PROPS}
                autoFocus
                type={showPassword ? 'text' : 'password'}
              />
              <button
                type="button"
                className="btn-no-default text-medium c-purple-1 toggle-button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'HIDE' : 'SHOW'}
              </button>
            </div>
            <SpinnerButton
              showAnimation
              isLoading={submitting || disabled}
              type="submit"
              className="btn-primary-4 full-w"
              onClick={handleSubmit}
              disabled={invalid || submitting || disabled}
            >
              Continue
            </SpinnerButton>
          </>
        );
      }}
    />
  );
};

export default CreatePassword;
