import { createStyles, makeStyles } from '@material-ui/core';

export const categoriesSeoStyle = makeStyles(() =>
  createStyles({
    seoForm: {
      '& .MuiAccordionDetails-root': {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
        '& .variant-form-row': {
          '& > div': {
            margin: 0,
            marginBottom: 16,
          },
        },
        '& .row': {
          margin: 0,
          '& > div': {
            paddingLeft: 0,
            paddingRight: '0 !important',
          },
        },
      },
    },
  })
);

export const CATEGORY_BANNER_SCREEN_SIZES = [
  {
    label: 'For Desktop',
    value: 'desktop',
  },
  {
    label: 'For Mobile',
    value: 'mobile',
  },
];
