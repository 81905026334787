import React, { useEffect, useState } from 'react';
import { Menu } from '@material-ui/core';
import { Form } from 'react-final-form';

import { useAppContext } from '../context/AppContext';
import { deepClone, deepCompare } from '../Appearance/utils';
import { useCountry } from '../context/CountryProvider';
import { useOrdersContext } from '../context/OrdersProvider';
import CheckboxField from '../shared/CheckboxField';
import SpinnerButton from '../shared/SpinnerButton';
import { FilterIcon } from '../SvgIcon';
import { noop } from '../utils';
import { truncateString } from '../utils/string';
import {
  CASH_PAYMENT_MODE,
  ONLINE_PAYMENT_MODE,
  ORDER_CREATION,
  PREPAID_ORDER,
  UNPAID_ORDER,
  AMOUNT_AND_QUANTITY_FILTER_OPTIONS,
  AMOUNT_AND_QUANTITY_FILTER_KEYS,
} from './constants';
import GroupedFilterMenuItems from './GroupedFilterMenuItems';
import { FILTER_LIST_SIZE } from '../Catalogue/constants';

const PAYMENT_MODE_LIST = [
  {
    value: ONLINE_PAYMENT_MODE,
    label: 'Paid orders',
  },
  {
    value: CASH_PAYMENT_MODE,
    label: 'COD orders',
  },
  {
    value: UNPAID_ORDER,
    label: 'Unpaid orders',
  },
  {
    value: PREPAID_ORDER,
    label: 'Prepaid orders',
  },
];

const CHANNEL_LIST = [
  {
    value: ORDER_CREATION.ONLINE,
    label: 'Online orders',
  },
  {
    value: ORDER_CREATION.MANUAL,
    label: 'Manual orders',
  },
];

const SpecialFilters = ({
  applyNewFilter = noop,
  orderTagsCount = null,
  openTagsModal,
  isTagsModalOpen,
  selectedFilters,
  setSelectedFilters,
  updateFilters,
  appliedFilters,
}) => {
  const { isInternational } = useCountry();
  const { canCreateVendor } = useAppContext();
  const {
    orderTags = [],
    orderSources = [],
    orderParams = {},
  } = useOrdersContext();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [tagsMenuItems, setTagsMenuItems] = useState([]);
  const [sourceMenuItems, setSourceMenuItems] = useState([]);

  const [renderTags, setRenderTags] = useState([...orderTags]);

  const paymentMenuItems = PAYMENT_MODE_LIST.map((each) => {
    let isSelected;

    if (each.value === PREPAID_ORDER) {
      isSelected = selectedFilters.filters[PREPAID_ORDER];
    } else {
      isSelected = selectedFilters?.filters?.payment_mode?.includes(each.value);
    }

    const onChange = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (each.value === PREPAID_ORDER) {
        updateFilters(!isSelected, `filters.${PREPAID_ORDER}`, true, false);
      } else {
        updateFilters(!isSelected, 'filters.payment_mode', each.value, true);
      }
    };
    return {
      labelText: (
        <CheckboxField
          checked={isSelected}
          label={<span className="text-8 c-black-1">{each.label}</span>}
        />
      ),
      onClick: onChange,
    };
  });

  const channelMenuItems = CHANNEL_LIST.map((each) => {
    const isSelected = selectedFilters?.filters?.channel?.includes(each.value);
    const onChange = (e) => {
      e.stopPropagation();
      e.preventDefault();
      updateFilters(!isSelected, 'filters.channel', each.value, true);
    };
    return {
      labelText: (
        <CheckboxField
          checked={isSelected}
          label={<span className="text-8 c-black-1">{each.label}</span>}
        />
      ),
      onClick: onChange,
    };
  });

  const otherMenuItems = [
    ...(!isInternational
      ? [
          {
            labelText: (
              <CheckboxField
                checked={Boolean(selectedFilters?.filters?.dukaan_ship)}
                label={
                  <span className="text-8 c-black-1">
                    Dukaan delivery orders
                  </span>
                }
              />
            ),
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              updateFilters(
                !selectedFilters?.filters?.dukaan_ship,
                'filters.dukaan_ship',
                true
              );
            },
          },
        ]
      : []),
    {
      labelText: (
        <CheckboxField
          checked={Boolean(selectedFilters?.filters?.self_ship)}
          label={<span className="text-8 c-black-1">Self shipped orders</span>}
        />
      ),
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        updateFilters(
          !selectedFilters?.filters?.self_ship,
          'filters.self_ship',
          true
        );
      },
    },
    ...(!isInternational
      ? [
          {
            labelText: (
              <CheckboxField
                checked={Boolean(selectedFilters?.filters?.returned_orders)}
                label={
                  <span className="text-8 c-black-1">Returned orders</span>
                }
              />
            ),
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              updateFilters(
                !selectedFilters?.filters?.returned_orders,
                'filters.returned_orders',
                true
              );
            },
          },
        ]
      : []),
    ...(canCreateVendor
      ? [
          {
            labelText: (
              <CheckboxField
                checked={Boolean(
                  selectedFilters?.filters?.vendor_rejected_unassigned_orders
                )}
                label={
                  <span className="text-8 c-black-1">Rejected by vendor</span>
                }
              />
            ),
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              updateFilters(
                !selectedFilters?.filters?.vendor_rejected_unassigned_orders,
                'filters.vendor_rejected_unassigned_orders',
                true
              );
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    const unselectedStoreTags = (orderTags ?? [])?.filter(
      (each) => !selectedFilters?.tags?.includes(each.id)
    );

    const selectedStoreTags = (orderTags ?? [])?.filter((each) =>
      selectedFilters?.tags?.includes(each.id)
    );

    setRenderTags([
      ...(selectedStoreTags ?? []),
      ...(unselectedStoreTags ?? []),
    ]);
  }, [isTagsModalOpen, orderTags, menuAnchorEl]);

  useEffect(() => {
    if (orderTags?.length > 0) {
      const initialMenuItems = renderTags?.map((tag) => {
        const isSelected = selectedFilters?.tags?.some(
          (current) => current === tag.id
        );
        const onChange = (e) => {
          e.stopPropagation();
          e.preventDefault();
          updateFilters(!isSelected, 'tags', tag.id, true);
        };

        return {
          labelText: (
            <CheckboxField
              checked={isSelected}
              label={
                <span className="tag-label text-8 c-black-1">
                  {truncateString(tag.name, 16)}
                </span>
              }
            />
          ),
          onClick: onChange,
        };
      });
      setTagsMenuItems(initialMenuItems);
    }
  }, [orderTags, selectedFilters?.tags, renderTags]);

  useEffect(() => {
    if (orderSources?.length > 0) {
      const initialMenuItems = orderSources?.map((source, index) => {
        const isSelected = selectedFilters?.source?.some(
          (current) => current === source
        );
        const onChange = (e) => {
          e.stopPropagation();
          e.preventDefault();
          updateFilters(!isSelected, 'source', source, true);
        };

        const sourcesObject = {
          labelText: (
            <CheckboxField
              checked={isSelected}
              label={
                <span className="tag-label text-8 c-black-1">
                  {truncateString(source, 16)}
                </span>
              }
            />
          ),
          onClick: onChange,
        };
        if (index < FILTER_LIST_SIZE) return sourcesObject;
      });
      setSourceMenuItems(initialMenuItems);
    }
  }, [orderSources, selectedFilters?.source]);

  const getFiltersCount = (filterObj) => {
    let count = 0;
    if (filterObj?.tags?.length) count += filterObj?.tags?.length;
    if (filterObj?.source?.length) count += filterObj?.source?.length;
    if (filterObj?.filters?.channel?.length)
      count += filterObj?.filters?.channel?.length;
    if (filterObj?.filters?.payment_mode?.length)
      count += filterObj?.filters?.payment_mode?.length;
    if (filterObj?.filters?.returned_orders) count += 1;
    if (filterObj?.filters?.self_ship) count += 1;
    if (filterObj?.filters?.dukaan_ship) count += 1;
    if (filterObj?.filters?.vendor_rejected_unassigned_orders) count += 1;
    if (filterObj?.filters?.seller_marked_prepaid) count += 1;

    return count;
  };

  const generateFilterParams = (values, isApply = false) => {
    const filterValues = {};
    const existingFilters = deepClone(selectedFilters);

    // reset amount and quantity filters
    AMOUNT_AND_QUANTITY_FILTER_KEYS.forEach(
      (key) => delete existingFilters.filters?.[key]
    );

    const removeFormatting = (value) => {
      if (typeof value === 'number') return value;
      return value ? Number.parseFloat(value.replaceAll(',', ''), 10) : null;
    };

    if (values.order_amount_action) {
      switch (values.order_amount_action) {
        case 'less_than': {
          filterValues.order_amount_lt = removeFormatting(
            values.order_amount_range_from
          );
          break;
        }
        case 'greater_than': {
          filterValues.order_amount_gt = removeFormatting(
            values.order_amount_range_from
          );
          break;
        }
        case 'equal_to': {
          filterValues.order_amount = removeFormatting(
            values.order_amount_range_from
          );
          break;
        }
        case 'is_between': {
          filterValues.order_amount_gte = removeFormatting(
            values.order_amount_range_from
          );
          filterValues.order_amount_lte = removeFormatting(
            values.order_amount_range_to
          );
          break;
        }
        default: {
          break;
        }
      }
    }

    if (values.order_item_action) {
      switch (values.order_item_action) {
        case 'less_than': {
          filterValues.order_item_lt = removeFormatting(
            values.order_item_range_from
          );
          break;
        }
        case 'greater_than': {
          filterValues.order_item_gt = removeFormatting(
            values.order_item_range_from
          );
          break;
        }
        case 'equal_to': {
          filterValues.order_item = removeFormatting(
            values.order_item_range_from
          );
          break;
        }
        case 'is_between': {
          filterValues.order_item_gte = removeFormatting(
            values.order_item_range_from
          );
          filterValues.order_item_lte = removeFormatting(
            values.order_item_range_to
          );
          break;
        }
        default: {
          break;
        }
      }
    }

    if (isApply) existingFilters.page = 1;

    return {
      ...existingFilters,
      filters: { ...existingFilters.filters, ...filterValues },
    };
  };

  const amountAndQuantityActionMap = (label) => {
    if (
      orderParams.filters[`order_${label}_gte`] &&
      orderParams.filters[`order_${label}_lte`]
    )
      return AMOUNT_AND_QUANTITY_FILTER_OPTIONS.IS_BETWEEN.value;

    if (orderParams.filters[`order_${label}_gt`])
      return AMOUNT_AND_QUANTITY_FILTER_OPTIONS.GREATER_THAN.value;

    if (orderParams.filters[`order_${label}_lt`])
      return AMOUNT_AND_QUANTITY_FILTER_OPTIONS.LESS_THAN.value;

    if (orderParams.filters[`order_${label}`])
      return AMOUNT_AND_QUANTITY_FILTER_OPTIONS.EQUAL_TO.value;

    return null;
  };

  const amountAndQuantityFormInitialValues = {
    order_amount_action: amountAndQuantityActionMap('amount'),
    order_item_action: amountAndQuantityActionMap('item'),
    order_amount_range_from:
      orderParams.filters.order_amount_gt ||
      orderParams.filters.order_amount ||
      orderParams.filters.order_amount_gte ||
      orderParams.filters.order_amount_lt ||
      null,
    order_amount_range_to: orderParams.filters.order_amount_lte || null,
    order_item_range_from:
      orderParams.filters.order_item_gt ||
      orderParams.filters.order_item ||
      orderParams.filters.order_item_gte ||
      orderParams.filters.order_item_lt ||
      null,
    order_item_range_to: orderParams.filters.order_item_lte || null,
  };

  return (
    <>
      <div
        className="d-flex-c-s special-filter-btn"
        onClick={(e) => {
          setSelectedFilters(deepClone(orderParams));
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <FilterIcon />
        <span className="pl6">Filter</span>
      </div>

      <Menu
        id="special-filter-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setSelectedFilters(deepClone(orderParams));
          setMenuAnchorEl(null);
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: 420,
            marginTop: 44,
          },
        }}
      >
        {menuAnchorEl && (
          <Form
            onSubmit={noop}
            initialValues={amountAndQuantityFormInitialValues}
          >
            {({ initialValues, values, form, invalid }) => {
              const resetAllFIlters = () => {
                const updatedFilter = {
                  filters: {},
                  tags: [],
                  source: [],
                  page: 1,
                };
                form.reset();
                applyNewFilter(updatedFilter);
                setSelectedFilters(updatedFilter);
                setMenuAnchorEl(null);
              };

              const selectedFiltersCount =
                getFiltersCount(selectedFilters) +
                (!!values.order_amount_action ? 1 : 0) +
                (!!values.order_item_action ? 1 : 0);

              return (
                <>
                  <GroupedFilterMenuItems
                    sourcesCount={orderSources?.length}
                    orderTagsCount={orderTags?.length}
                    totalTagsCount={orderTagsCount}
                    openTagsModal={openTagsModal}
                    menuItems={{
                      paymentMenuItems,
                      channelMenuItems,
                      otherMenuItems,
                      tagsMenuItems,
                      sourceMenuItems,
                    }}
                    updateFilters={updateFilters}
                    initialValues={initialValues}
                    values={values}
                  />
                  <div className="filter-actions d-flex-c-s px16 pb0">
                    <div>
                      {selectedFiltersCount > 0 && (
                        <p className="section-text-14">
                          {selectedFiltersCount} filter
                          {selectedFiltersCount > 1 ? 's' : ''} selected
                        </p>
                      )}
                    </div>
                    <div className="d-flex align-center">
                      <span
                        className="section-text-5 c-gray-1 mr20 cur-p"
                        onClick={resetAllFIlters}
                      >
                        Reset
                      </span>
                      <SpinnerButton
                        className="btn-primary-small"
                        disabled={
                          invalid ||
                          deepCompare(
                            orderParams,
                            generateFilterParams(deepClone(values))
                          )
                        }
                        onClick={() => {
                          applyNewFilter(
                            generateFilterParams(deepClone(values), true)
                          );
                          setMenuAnchorEl(null);
                        }}
                      >
                        Apply
                      </SpinnerButton>
                    </div>
                  </div>
                </>
              );
            }}
          </Form>
        )}
      </Menu>
    </>
  );
};

export default SpecialFilters;
