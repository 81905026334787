import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Switch as MaterialSwitch, FormControlLabel } from '@material-ui/core';

import { noop } from '../utils';

const Switch = (props) => {
  const {
    labelText,
    checked,
    className,
    parentClassName,
    onChange,
    disabled,
    lightOffTheme,
    alwaysBlue = false,
    dependsOnChecked = false,
  } = props;
  const [isChecked, setChecked] = useState(checked);

  const classes = cx('switch', className, {
    'switch-disabled': disabled,
    'MuiSwitch-light-off-theme': lightOffTheme,
    'always-blue': alwaysBlue,
  });

  const handleChange = (event) => {
    if (!disabled) {
      const newChecked = event.target.checked;
      if (!dependsOnChecked) setChecked(newChecked);
      onChange(newChecked);
    }
  };

  useEffect(() => {
    if (!disabled) {
      setChecked(checked);
    }
  }, [checked, disabled]);

  return (
    <FormControlLabel
      className={cx('switch-label', parentClassName)}
      control={
        <MaterialSwitch
          disableRipple
          disabled={disabled}
          className={classes}
          checked={isChecked}
          onChange={handleChange}
        />
      }
      label={labelText}
    />
  );
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
  onChange: noop,
  labelText: '',
  className: '',
  lightOffTheme: false,
};

export default Switch;
