import React, { useState } from 'react';
import { useSubscription } from '../context/SubscriptionProvider';
import useEnterPressAction from '../hooks/useEnterPressAction';
import CheckboxField from '../shared/CheckboxField';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { DeclineOrderIcon } from '../SvgIcon';

const StoreDeletionConfirmationModal = ({
  open,
  closeModal,
  selectedStores = [],
}) => {
  const { deleteStoreOnDownGrade } = useSubscription();
  const [isChecked, setIschecked] = useState(false);

  const handleDeleteStores = () => {
    deleteStoreOnDownGrade({
      stores: selectedStores,
    });
  };

  useEnterPressAction(open, handleDeleteStores);
  return (
    <Modal open={open} closeModal={closeModal} maxWidth="sm">
      <div className="d-flex-c-c flex-column">
        <DeclineOrderIcon />
        <div className="section-text-4 mt12">Confirm deletion</div>
        <div className="text-2 mt6 c-black-3 text-center">
          All the products, orders, customers, reports, etc., will be
          permanently deleted from these {selectedStores.length}{' '}
          {selectedStores?.length > 1 ? 'stores' : 'store'}.
        </div>
        <div className="mt24 d-flex-c-c">
          <CheckboxField
            onChange={(e) => setIschecked(e.target.checked)}
            checked={isChecked}
          />
          <span className="text-2 c-black-3">
            I understand I cannot undo this action.
          </span>
        </div>
        <SpinnerButton
          disabled={!isChecked}
          label="Yes, delete"
          className="btn btn-danger-4 mt32"
          onClick={handleDeleteStores}
        />
      </div>
    </Modal>
  );
};

export default StoreDeletionConfirmationModal;
