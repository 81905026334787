import { useEffect, useState } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import cx from 'classnames';

import 'react-circular-progressbar/dist/styles.css';
import SpinnerButton from '../shared/SpinnerButton';
import { AttentionIcon, TickGreenIcon } from '../SvgIcon';
import RequestPartialPaymentModal from './RequestPartialPaymentModal';
import useModal from '../hooks/useModal';

import {
  MINIMUM_CREDITS_TO_CHECK_RTO_STATUS,
  RTO_HIGH_RISK,
  RTO_LOW_RISK,
  RTO_MEDIUM_RISK,
  RTO_PARTIAL_PAYMENT,
} from './constants';
import { isNotEmptyOrNull } from '../shared/Form/Shared';
import { noop } from '../utils';
import AddCreditsModal from '../Campaign/components/AddCreditsModal';
import { usePayments } from '../context/PaymentsProvider';
import SetDukaanPayModal from '../Manage/Payments/SetDukaanPayModal';
import { useAppContext } from '../context/AppContext';
import { TrackEvent } from '../utils/analytics';
import {
  EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_1,
  EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_2,
  EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_3,
  EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MODAL_CLICK_MEDIUM_RISK,
  EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_2,
  EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_3,
  EVENT_ORDER_RTO_TOP_UP,
  EVENT_ORDER_RTO_RUN_OPTIMISER,
} from '../events';

export const RTOCard = ({
  riskStatus,
  credits,
  generatePaymentLink = noop,
  generatePartialPaymentLink = noop,
  isGeneratingPaymentLink = false,
  orderDetails,
  populateRTOScore = noop,
  populatingRTOScore,
  numberOfTimesFullPaymentLinkSent,
  numberOfTimesPartialPaymentLinkSent,
  handleKeyboardKeys,
  isOrderPartiallyPaid,
  paymentStatus,
  rtoScore,
}) => {
  const { fetchBusinessDetails } = useAppContext();

  const {
    isOpen: isPaymentModalOpen,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();

  const {
    isOpen: isTopupModalOpen,
    openModal: openTopupModal,
    closeModal: closeTopupModal,
  } = useModal();

  const { business } = useAppContext();

  useEffect(() => {
    if (isPaymentModalOpen)
      document.removeEventListener('keydown', handleKeyboardKeys);
    else document.addEventListener('keydown', handleKeyboardKeys);
  }, [handleKeyboardKeys, isPaymentModalOpen]);

  if (isOrderPartiallyPaid !== null) {
    return (
      <div className="d-flex gap24 card p24 mb24 align-center">
        <RiskGraph riskStatus={riskStatus} rtoScore={rtoScore} />
        <PaymentSuccessfulMessage paymentStatus={paymentStatus} />
      </div>
    );
  }

  if (
    credits < MINIMUM_CREDITS_TO_CHECK_RTO_STATUS &&
    !isNotEmptyOrNull({ value: riskStatus })
  )
    return (
      <div className="d-flex gap24 card p24 mb24 align-center">
        <div className="attention-icon">
          <AttentionIcon />
        </div>
        <LowBalanceText />
        <div className="rto-action-btn-wrapper top-up">
          <SpinnerButton
            label="Top up"
            showAnimation
            onClick={() => {
              TrackEvent(EVENT_ORDER_RTO_TOP_UP, business);
              openTopupModal();
            }}
            className="btn-outline-primary action-btn section-text-7"
          />
        </div>
        <AddCreditsModal
          open={isTopupModalOpen}
          closeModal={closeTopupModal}
          showMinimum={false}
        />
      </div>
    );
  if (
    credits >= MINIMUM_CREDITS_TO_CHECK_RTO_STATUS &&
    !isNotEmptyOrNull({ value: riskStatus })
  )
    return (
      <div className="d-flex gap24 card p24 mb24 align-center">
        <div className="attention-icon">
          <AttentionIcon />
        </div>
        <RunRTOOptimiserText />
        <div className="rto-action-btn-wrapper">
          <SpinnerButton
            label="Run RTO Optimiser"
            showAnimation
            isLoading={populatingRTOScore}
            disabled={populatingRTOScore}
            onClick={() => {
              TrackEvent(EVENT_ORDER_RTO_RUN_OPTIMISER, business);
              populateRTOScore({
                successCallback: () => fetchBusinessDetails(),
              });
            }}
            className="btn-outline-primary action-btn section-text-7"
          />
        </div>
      </div>
    );
  return (
    <div className="d-flex gap24 card p24 mb24 align-center">
      <RiskGraph riskStatus={riskStatus} rtoScore={rtoScore} />
      <RecommendedAction riskStatus={riskStatus} />
      <ActionButton
        riskStatus={riskStatus}
        openPaymentModal={openPaymentModal}
        generatePaymentLink={generatePaymentLink}
        generatePartialPaymentLink={generatePartialPaymentLink}
        isGeneratingPaymentLink={isGeneratingPaymentLink}
        numberOfTimesFullPaymentLinkSent={numberOfTimesFullPaymentLinkSent}
        numberOfTimesPartialPaymentLinkSent={
          numberOfTimesPartialPaymentLinkSent
        }
      />
      <RequestPartialPaymentModal
        isOpen={isPaymentModalOpen}
        closeModal={closePaymentModal}
        orderDetails={orderDetails}
        generatePartialPaymentLink={generatePartialPaymentLink}
        isGeneratingPaymentLink={isGeneratingPaymentLink}
      />
    </div>
  );
};

const RunRTOOptimiserText = () => (
  <div className="rto-recommended-action">
    <div className="section-text-5 c-black-1">
      Check RTO risk status for this order?
    </div>
    <div className="text-16">
      Run RTO optimiser to get RTO risk status for this order.
    </div>
  </div>
);

const LowBalanceText = () => (
  <div className="rto-recommended-action">
    <div className="section-text-5 c-black-1">
      RTO optimiser disabled due to low balance.
    </div>
    <div className="text-16">
      You need to add credits to continue getting RTO risk status.
    </div>
  </div>
);

const ProgressAnimator = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

// This function is used to get the exact value for displaying in graph
const calculateRiskPercent = (rtoScore) => 0.53 * Number(rtoScore) + 45;

const calculateModifiedRtoScore = ({ riskStatus, rtoScore }) => {
  // This step is done since medium values for rto are between 30-50 but its not exactly in the center of the risk graph
  // Hence I extrapolate the range from 30-50 to 30-70 while displaying in graph so that its more intuitive to the user
  // This is not business logic, just UI logic to display the score in a more intuitive manner

  // LOW -> Final = Original
  // MEDIUM -> Final = (Original - 15) * 2
  // HIGH -> Final = ((Original + 70.71) * 7) / 12
  if (riskStatus === RTO_LOW_RISK) return Number(rtoScore);
  if (riskStatus === RTO_MEDIUM_RISK) return (Number(rtoScore) - 15) * 2;
  if (riskStatus === RTO_HIGH_RISK)
    return ((Number(rtoScore) + 70.71) * 7) / 12;
};

const RiskGraph = ({ riskStatus, rtoScore }) => {
  let pathColor = '';
  let riskPercent = 0;
  const trailColor = '#E6E6E6';
  if (riskStatus === RTO_HIGH_RISK) {
    pathColor = '#E50B20';
    const modifiedRtoScore = calculateModifiedRtoScore({
      riskStatus,
      rtoScore,
    });
    riskPercent = calculateRiskPercent(modifiedRtoScore);
  } else if (riskStatus === RTO_MEDIUM_RISK) {
    pathColor = '#EE741F';
    const modifiedRtoScore = calculateModifiedRtoScore({
      riskStatus,
      rtoScore,
    });
    riskPercent = calculateRiskPercent(modifiedRtoScore);
  } else if (riskStatus === RTO_LOW_RISK) {
    pathColor = '#17B31B';
    const modifiedRtoScore = calculateModifiedRtoScore({
      riskStatus,
      rtoScore,
    });
    riskPercent = calculateRiskPercent(modifiedRtoScore);
  }
  return (
    <div className="rto-risk-graph">
      <ProgressAnimator valueStart={10} valueEnd={riskPercent}>
        {(value) => (
          <CircularProgressbarWithChildren
            value={value}
            strokeWidth={6}
            styles={buildStyles({
              pathColor,
              trailColor,
              rotation: 0.28,
            })}
          >
            <div className="section-text-20 c-gray-1">RTO risk</div>

            {riskStatus === RTO_HIGH_RISK && (
              <div className="section-text-5 c-red-1">High</div>
            )}
            {riskStatus === RTO_MEDIUM_RISK && (
              <div className="section-text-5 c-orange-1">Medium</div>
            )}
            {riskStatus === RTO_LOW_RISK && (
              <div className="section-text-5 c-green-1">Low</div>
            )}
          </CircularProgressbarWithChildren>
        )}
      </ProgressAnimator>
    </div>
  );
};

const PaymentSuccessfulMessage = ({ paymentStatus }) => (
  <div className="rto-recommended-action">
    <div className="section-text-5 c-black-1 d-flex align-center">
      <TickGreenIcon height={20} width={20} className="mr6" />
      <div>Payment successful!</div>
    </div>
    <div className="text-16 c-black-3">
      {paymentStatus === RTO_PARTIAL_PAYMENT ? 'Partial' : 'Full'} online
      payment received on this order successfully.
    </div>
  </div>
);

const RecommendedAction = ({ riskStatus }) => (
  <div className="rto-recommended-action">
    <div className="section-text-5 c-black-1">Recommended action:</div>
    {riskStatus === RTO_HIGH_RISK && (
      <div className="text-16">Collect full online payment to avoid RTO.</div>
    )}
    {riskStatus === RTO_MEDIUM_RISK && (
      <div className="text-16">
        Collect partial online payment to avoid RTO.
      </div>
    )}
    {riskStatus === RTO_LOW_RISK && (
      <div className="text-16">
        Proceed with shipping this order to your customer.
      </div>
    )}
  </div>
);

const ActionButton = ({
  riskStatus,
  openPaymentModal,
  generatePaymentLink,
  generatePartialPaymentLink,
  isGeneratingPaymentLink,
  numberOfTimesPartialPaymentLinkSent,
  numberOfTimesFullPaymentLinkSent,
}) => {
  const { business } = useAppContext();
  const { cashfreeKey } = usePayments();
  const {
    isOpen: isPaymentSetupModalOpen,
    openModal: openPaymentSetupModal,
    closeModal: closePaymentSetupModal,
  } = useModal();

  let label = '';
  let handleOnClick = noop;
  const isPaymentButtonDisabled =
    numberOfTimesPartialPaymentLinkSent >= 3 ||
    numberOfTimesFullPaymentLinkSent >= 3;

  if (riskStatus === RTO_HIGH_RISK || riskStatus === RTO_LOW_RISK) {
    label =
      riskStatus === RTO_HIGH_RISK
        ? 'Request online payment'
        : 'Request payment';
    handleOnClick = () => {
      if (numberOfTimesFullPaymentLinkSent === 0)
        TrackEvent(
          EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_1,
          business
        );
      else if (numberOfTimesFullPaymentLinkSent === 1)
        TrackEvent(
          EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_2,
          business
        );
      else if (numberOfTimesFullPaymentLinkSent === 2)
        TrackEvent(
          EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_3,
          business
        );
      return generatePaymentLink(() =>
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      );
    };
  } else if (riskStatus === RTO_MEDIUM_RISK) {
    label = 'Request partial payment';
    if (numberOfTimesPartialPaymentLinkSent === 0)
      handleOnClick = () => {
        TrackEvent(
          EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MODAL_CLICK_MEDIUM_RISK,
          business
        );
        return openPaymentModal();
      };
    else
      handleOnClick = () => {
        if (numberOfTimesPartialPaymentLinkSent === 1)
          TrackEvent(
            EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_2,
            business
          );
        else if (numberOfTimesPartialPaymentLinkSent === 2)
          TrackEvent(
            EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_3,
            business
          );

        return generatePartialPaymentLink(null, () =>
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        );
      };
  }

  if (!cashfreeKey) handleOnClick = () => openPaymentSetupModal();

  return (
    <div
      className={cx('rto-action-btn-wrapper', {
        'low-risk': riskStatus === RTO_LOW_RISK,
      })}
    >
      <SpinnerButton
        label={label}
        showAnimation
        disabled={isPaymentButtonDisabled}
        isLoading={isGeneratingPaymentLink}
        onClick={handleOnClick}
        className="btn-outline-success action-btn section-text-7"
      />
      <div className="total-links-shared mt6">
        Payment link shared -{' '}
        {numberOfTimesPartialPaymentLinkSent ||
          numberOfTimesFullPaymentLinkSent}
        /3
      </div>
      <SetDukaanPayModal
        open={isPaymentSetupModalOpen}
        closeModal={closePaymentSetupModal}
      />
    </div>
  );
};

export default RTOCard;
