import { Menu } from '@material-ui/core';
import cx from 'classnames';
import React, { useState } from 'react';
import { useStorePreferences } from '../context/StorePreferenceProvider';
import CheckboxField from '../shared/CheckboxField';
import { MenuItems } from '../shared/Menu';
import { ColumnsFilterIcon } from '../SvgIcon';
import { noop } from '../utils';
import { ORDER_TAGS_COLUMN_PREFERENCE_KEY } from './constants';

const ColumnsFilter = ({
  tableColumns = [],
  handleToggleColumn = noop,
  preferenceKey,
  tagsCount,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { preferences } = useStorePreferences();
  const colPreferences = preferences[preferenceKey]?.value;

  const menuItems = tableColumns
    .filter((i) => {
      if (
        i.preferenceKey === ORDER_TAGS_COLUMN_PREFERENCE_KEY &&
        tagsCount === 0
      )
        return null;
      return i.showAlways !== true;
    })
    .map((item) => ({
      labelText: (
        <CheckboxField
          checked={
            tableColumns.find((i) => i.preferenceKey === item.preferenceKey)
              ?.show ?? false
          }
          onChange={(e) => {
            const isChecked = e.target.checked;
            const updatedPreferences = {
              ...colPreferences,
              [item.preferenceKey]: isChecked,
            };
            handleToggleColumn(updatedPreferences);
          }}
          label={<span className="text-8 c-black-1">{item.title}</span>}
        />
      ),
    }));

  return (
    <>
      <div
        className={cx('d-flex-c-s special-filter-btn')}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      >
        <ColumnsFilterIcon />
        <span className="ml4">Columns</span>
      </div>
      <Menu
        id="special-filter-menu"
        className="menu-item-lh"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            width: 224,
            marginTop: 44,
            paddingTop: 10,
            paddingBottom: 10,
          },
        }}
      >
        <MenuItems menuItems={menuItems} />
      </Menu>
    </>
  );
};

export default ColumnsFilter;
