import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Collapse } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import LogoSection from '../shared/Logo';
import OAuthButtons from './OAuthButtons';
import '../../scss/pages/auth.scss';
import EmailAndPhoneInput from '../shared/Form/EmailAndPhoneInput';
import countries, {
  countriesByPhoneCode,
  DEFAULT_COUNTRY,
} from '../utils/countries';
import { FormCustomSelect, FormInput, FormTextArea } from '../shared/Form';
import OTPVerificationForm from './Mobile/OTPVerificationForm';
import useMobileLogin from './Mobile/useMobileLogin';
import useEmailLogin from './Email/useEmailLogin';
import EmailOTPVerificationForm from './Email/EmailOtpVerificationForm';
import { authUrls, rootUrls } from '../Urls';
import { useAppContext } from '../context/AppContext';
import useQuery from '../hooks/useQuery';
import { getRequest } from '../utils/http';
import { CHECK_IP_API, SEND_RESET_PASSWORD_URL } from '../ApiUrls';
import { DEFAULT_PASSWORD_PROPS, WHITELISTED_ADDRESSES } from './constants';
import CreatePassword from './Email/CreatePassword';
import { parseJwt } from '../utils/string';
import useUtmParams from '../hooks/utils/useUtmParams';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import useLocalStorage from '../hooks/useLocalStorage';
import { AuthBackground } from '../SvgIcon';
import SpinnerButton from '../shared/SpinnerButton';
import { modifyFormField } from '../shared/Form/Shared';
import { checkIsPhone } from '../shared/Form/Validation';
import {
  RECAPTCHA_SITE_KEY,
  DUKAAN_TERMS_AND_CONDITION,
  PRIVACY_URL,
  ENVIRONMENT_OPTIONS,
  SELECTED_ENVIRONMENT_KEY,
  isNonLiveEnv,
} from '../constants';
import useScript from '../hooks/useScript';
import GoogleOneTapLogin from './GoogleOneTapLogin';
import { noop } from '../utils';

const DefaultLogin = (props) => {
  const {
    value,
    values,
    invalid,
    submitting,
    handleSubmit,
    disabled,
    isPhone,
    isForgotPassword,
    isCreatePassword,
    setIsCreatePassword,
    setIsForgotPassword,
    setSuccessMessages,
    showPassword,
    onPasswordSubmit,
    setEmailLoading,
    showCreatePassword,
    isGetStarted,
    isIPInternational,
    form,
    isAppSumoUser,
    isWhitelistedAddress = true,
  } = props;

  let labelText = 'Email or mobile number';
  if (isIPInternational) labelText = 'Email address';
  else if (value) {
    if (isPhone) labelText = 'Mobile number';
    else labelText = 'Email address';
  }
  const [selectedCountry, setSelectedCountry] = useState({});
  const phoneCode = values.phone_code;
  const phoneCodeData = countriesByPhoneCode.find(
    (country) =>
      Number(country.value) === Number(phoneCode) &&
      country.country_code === values.country_code
  );

  const [isLoading, setIsLoading] = useState(false);
  const [showHidePassword, setShowHidePassword] = useState(false);

  const handleForgotPassword = () => {
    setIsLoading(true);
    getRequest({
      url: SEND_RESET_PASSWORD_URL,
      data: { email: value },
    })
      .then((res) => {
        if (res.status === 'success') {
          setSuccessMessages([
            `Email to ${
              isCreatePassword ? 'create' : 'reset'
            } password has been sent.`,
          ]);
          setIsForgotPassword(false);
          if (isCreatePassword) {
            setIsCreatePassword(false);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCountry = (data) => {
    const selected = countriesByPhoneCode.find(
      (country) =>
        Number(country.phone_code) ===
          Number(data.phone_code || data.bussiness_phone_code) &&
        country.country_code === data.country_code
    );
    setSelectedCountry(selected);
    form.mutators.modifyFormField('country_code', selected.country_code);
  };

  useEffect(() => {
    handleCountry(values);
  }, []);

  return (
    <>
      <h4 className="section-text-1 mt8 mb40">
        {isGetStarted &&
          !isForgotPassword &&
          !isAppSumoUser &&
          `Get started with Dukaan`}
        {!isGetStarted && !isForgotPassword && 'Log in'}
        {isForgotPassword && !isCreatePassword && 'Forgot your password?'}
        {isCreatePassword && 'Create your password'}
        {isAppSumoUser &&
          !isCreatePassword &&
          !showPassword &&
          isGetStarted &&
          'Get Started'}
      </h4>
      <EmailAndPhoneInput
        name="email_phone"
        selectedCountry={selectedCountry}
        handleCountry={handleCountry}
        labelText={labelText}
        placeholder={`Enter email ${
          !isIPInternational ? 'or mobile number' : ''
        }`}
        values={values}
        isPhone={isPhone}
        {...(isPhone ? { maxLength: phoneCodeData.mobile_length_max } : {})}
        required
        readOnly={showPassword || isAppSumoUser}
        isIPInternational={isIPInternational}
      />
      <Collapse in={showPassword && !isForgotPassword} mountOnEnter>
        {showPassword && !isForgotPassword && (
          <>
            <div className="login-password-field">
              <FormInput
                labelText={
                  <span className="d-flex-c-s">
                    <span>Password</span>
                    <span
                      className="c-purple-1-i c-h-purple-2-i underline-h cur-p"
                      onClick={() => setIsForgotPassword(true)}
                    >
                      Forgot password?
                    </span>
                  </span>
                }
                autoFocus
                placeholder="Enter your password"
                name="password"
                {...DEFAULT_PASSWORD_PROPS}
                type={showHidePassword ? 'text' : 'password'}
              />
              {!!values.password && (
                <button
                  type="button"
                  className="btn-no-default text-medium c-purple-1 toggle-button"
                  onClick={() => setShowHidePassword(!showHidePassword)}
                >
                  {showHidePassword ? 'HIDE' : 'SHOW'}
                </button>
              )}
            </div>
          </>
        )}
      </Collapse>
      {showPassword && !isForgotPassword && (
        <SpinnerButton
          showAnimation
          isLoading={disabled}
          type="submit"
          className="btn-primary-4 full-w"
          disabled={invalid || submitting || disabled}
          onClick={onPasswordSubmit}
        >
          {isGetStarted ? 'Get started' : 'Log in'}
        </SpinnerButton>
      )}
      {isForgotPassword && !showPassword && (
        <SpinnerButton
          showAnimation
          isLoading={isLoading}
          type="button"
          className="btn-primary-4 full-w"
          disabled={invalid || submitting || disabled}
          onClick={handleForgotPassword}
        >
          {isCreatePassword ? 'Create' : 'Reset'} password
        </SpinnerButton>
      )}
      {!isForgotPassword && !showPassword && !showCreatePassword && (
        <SpinnerButton
          showAnimation
          isLoading={disabled}
          type="submit"
          className="btn-primary-4 full-w"
          disabled={invalid || submitting || disabled}
          onClick={handleSubmit}
        >
          {isGetStarted ? 'Get started' : 'Log in'}
        </SpinnerButton>
      )}
      {isWhitelistedAddress && (
        <>
          <div className="pos-rel or-separator">
            <span className="pos-abs absolute-center text-8 c-gray-1 bg-white px12">
              {isGetStarted ? 'or get started with' : 'or log in with'}
            </span>
          </div>
          <GoogleOneTapLogin />
          <OAuthButtons setLoading={setEmailLoading} />
        </>
      )}
      {isGetStarted && (
        <p className="mt32 section-text-14 c-black-3">
          By continuing, you agree to our{' '}
          <a
            className="c-black-3 text-medium underline underline-h"
            href={DUKAAN_TERMS_AND_CONDITION}
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            className="c-black-3 text-medium underline underline-h"
            href={PRIVACY_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          .
        </p>
      )}
    </>
  );
};

const LoginForm = (props) => {
  const {
    values,
    disabled,
    showOtp,
    showEmailOtp,
    showPassword,
    showCreatePassword,
    setMobileErrors,
    setEmailErrors,
    mobileErrors,
    emailErrors,
    setMobileLoading,
    setEmailLoading,
    onPasswordSubmit,
    setForgotPassword,
    isForgotPassword,
    isCreatePassword,
    setIsCreatePassword,
    isIPInternational,
  } = props;
  const [successMessages, setSuccessMessages] = useState([]);
  const value = values.email_phone;
  const phoneCode = values.phone_code;
  const { setUser, setToken, setStoreId } = useAppContext();

  const history = useHistory();
  const isPhone = checkIsPhone(value);
  const errors = isPhone ? mobileErrors : emailErrors;

  const query = useQuery();
  const isWhitelistedAddress = WHITELISTED_ADDRESSES.some((address) =>
    (window.location.hostname ?? '').includes(address)
  );
  const showLoginView =
    (query.get('gs') === '0' || !isWhitelistedAddress) ?? false;
  const redirectUrl = query.get('redirectUrl') || '';
  const isAppSumoUser = query.get('source') === 'appsumo';

  const [isLoginView, setIsLoginView] = useState(showLoginView);

  const afterMobileOtpSuccess = (res) => {
    const {
      store_ids: storeIds,
      multiple_stores: multipleStores = false,
      email,
    } = parseJwt(res.access_token);
    setToken(res.access_token);
    if (Array.isArray(storeIds) && storeIds.length === 0) {
      // if there is no storeid present in jwt token then redirect to register page
      history.push(authUrls.registerPath);
      return;
    }
    if (multipleStores) {
      // if there is more than one storeid present in jwt token then redirect to select store page
      window.location.href = authUrls.selectStorePath;
      return;
    }
    setStoreId(storeIds[0]);
    setUser(res, { mobile: value, email, phoneCode }, () => {
      window.location.href = `${rootUrls.homePath}?redirectUrl=${redirectUrl}`;
    });
  };

  const afterEmailOtpSuccess = (res) => {
    const {
      username = '',
      store_ids: storeIds,
      multiple_stores: multipleStores,
    } = parseJwt(res.access_token);
    setToken(res.access_token);
    const phoneNumber = username.split('-')[1];
    const hasPhone = !Number.isNaN(phoneNumber);
    if (Array.isArray(storeIds) && storeIds.length === 0) {
      // if there is no storeid present in jwt token then redirect to register page
      history.push(authUrls.registerPath);
      return;
    }
    if (multipleStores) {
      // if there is more than one storeid present in jwt token then redirect to select store page
      window.location.href = authUrls.selectStorePath;
      return;
    }
    setStoreId(storeIds[0]);
    setUser(
      res,
      {
        email: value,
        mobile: hasPhone ? phoneNumber : undefined,
        phoneCode: hasPhone ? phoneCode : undefined,
      },
      () => {
        // after email otp user is signing up, not logging in
        window.location.href = `${rootUrls.homePath}?redirectUrl=${redirectUrl}`;
      }
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSuccessMessages([]);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [successMessages]);

  return (
    <div className="auth-layout full-h flex-column">
      <AuthBackground className="auth-left-background" />
      <AuthBackground className="auth-right-background" />
      <div className="auth-wrap card animate__animated animate__zoomIn">
        <form>
          {isWhitelistedAddress && (
            <div className="auth-logo-wrap">
              <LogoSection />
            </div>
          )}
          <Collapse in={!!errors.length}>
            {!!errors.length && (
              <div className="error-alert mb16">{errors.join('<br />')}</div>
            )}
          </Collapse>
          <Collapse in={!!successMessages.length}>
            <div className="success-alert mb16">
              {successMessages.join('<br />')}
            </div>
          </Collapse>
          <Collapse in={showOtp} mountOnEnter>
            <OTPVerificationForm
              mobile={value}
              countryCode={phoneCode}
              afterOtpSuccess={afterMobileOtpSuccess}
              error=""
              setError={setMobileErrors}
              showOtp={showOtp}
              setLoading={setMobileLoading}
              isLoading={disabled}
            />
          </Collapse>
          <Collapse in={showEmailOtp} mountOnEnter>
            <EmailOTPVerificationForm
              email={value}
              afterOtpSuccess={afterEmailOtpSuccess}
              error=""
              setError={setEmailErrors}
              showEmailOtp={showEmailOtp}
              setLoading={setEmailLoading}
              isLoading={disabled}
            />
          </Collapse>
          <Collapse in={showCreatePassword}>
            {showCreatePassword && (
              <CreatePassword
                email={value}
                onPasswordSubmit={onPasswordSubmit}
                {...props}
              />
            )}
          </Collapse>
          <Collapse in={!showOtp && !showEmailOtp && !showCreatePassword}>
            <DefaultLogin
              {...props}
              value={value}
              isPhone={isPhone}
              isForgotPassword={isForgotPassword}
              isCreatePassword={isCreatePassword}
              setIsCreatePassword={setIsCreatePassword}
              setIsForgotPassword={setForgotPassword}
              setSuccessMessages={setSuccessMessages}
              setEmailLoading={setEmailLoading}
              showPassword={showPassword}
              showCreatePassword={showCreatePassword}
              isGetStarted={!isLoginView}
              onPasswordSubmit={(e) => {
                e.preventDefault();
                onPasswordSubmit({ email: value, password: values.password });
              }}
              isIPInternational={isIPInternational}
              isAppSumoUser={isAppSumoUser}
              isWhitelistedAddress={isWhitelistedAddress}
            />
          </Collapse>
        </form>
      </div>
      {!showOtp &&
        !showEmailOtp &&
        !showCreatePassword &&
        isWhitelistedAddress && (
          <div className="mt32 auth-bottom-text animate__animated animate__fadeInUp">
            <span className="text-1">
              {isLoginView ? 'Don’t have an account?' : 'Already a member?'}{' '}
              <span
                className="text-medium underline cur-p"
                onClick={() => setIsLoginView(!isLoginView)}
              >
                {isLoginView ? 'Get started' : 'Log in'}
              </span>
            </span>
          </div>
        )}
    </div>
  );
};

const InternationalLogin = () => {
  const {
    isSubmitting: isMobileLoginSubmitting,
    showOtp,
    onLogin,
    errors: mobileErrors,
    setErrors: setMobileErrors,
    setMobileLoading,
  } = useMobileLogin();

  const [ipLoading, setIpLoading] = useState(false);
  const [countryItem, setCountryItem] = useState(DEFAULT_COUNTRY);
  // when ipinfo api fails due to client side blocking
  const [ipInfoFailed, setIpInfoFailed] = useState(false);
  const [localCountryCode, setLocalCountryCode] = useLocalStorage(
    '__ip_country_code__',
    {
      code: DEFAULT_COUNTRY.country_code,
    }
  );

  const [env, selectEnv] = useLocalStorage(
    SELECTED_ENVIRONMENT_KEY,
    ENVIRONMENT_OPTIONS[1] // prod
  );

  useUtmParams();
  useScript(
    'https://www.google.com/recaptcha/api.js?render=6Le1AaQjAAAAAG7VprkQcNmuuQsqDM93Yu_v7yNk'
  );

  const query = useQuery();
  const prefillEmail = query.get('email') ?? '';
  const prefillNumber = query.get('number')?.split('-') ?? '';
  const prefillNumberData = {
    number: prefillNumber[1],
    phone_code: prefillNumber[0],
    country_code: countriesByPhoneCode.find(
      (country) => Number(country.value) === Number(prefillNumber[0])
    )?.country_code,
  };

  const {
    onPasswordSubmit,
    verifyEmailExists,
    isEmailSubmitting,
    showOtp: showEmailOtp,
    showPassword,
    showCreatePassword,
    errors: emailErrors,
    setErrors: setEmailErrors,
    setEmailLoading,
    setForgotPassword,
    isForgotPassword,
    isCreatePassword,
    setIsCreatePassword,
  } = useEmailLogin();

  const history = useHistory();

  const handleSubmit = ({
    email_phone: emailOrPhone,
    phone_code: countryCode,
  }) => {
    const isPhone = checkIsPhone(emailOrPhone);
    if (isPhone) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
          .then(function (token) {
            onLogin({
              mobile: emailOrPhone,
              country_code: `+${countryCode}`,
              hashcode: token,
            });
          });
      });
    } else {
      verifyEmailExists({
        email: emailOrPhone,
        ...(query.get('source') && { source: query.get('source') }),
      });
    }
  };

  useEffect(() => {
    if (query.get('email') && !query.get('source')) {
      verifyEmailExists({
        email: query.get('email'),
      });
    }

    if (history.location.state?.from?.search) {
      const urlParams = new URLSearchParams(
        history.location.state?.from?.search
      );
      const params = Object.fromEntries(urlParams.entries());

      Object.keys(params).forEach((key) =>
        localStorage.setItem(key, params[key])
      );
    }
    setIpLoading(true);
    getRequest({
      url: CHECK_IP_API,
    })
      .then(({ country }) => {
        const matchedCountry =
          countries.find(
            (eachCountry) => eachCountry.country_code === country
          ) ?? DEFAULT_COUNTRY;
        setCountryItem(matchedCountry);
        setLocalCountryCode({ code: matchedCountry.country_code });
      })
      .catch((err) => {
        console.log('ip_error: ', JSON.stringify(err));
        setIpInfoFailed(true);
      })
      .finally(() => {
        setIpLoading(false);
      });
  }, []);

  return (
    <>
      <BounceLoaderContainer isLoading={ipLoading}>
        <Form
          onSubmit={handleSubmit}
          mutators={{ ...arrayMutators, modifyFormField }}
          keepDirtyOnReinitialize
          disabled={isMobileLoginSubmitting || isEmailSubmitting}
          initialValues={{
            phone_code: prefillNumberData.phone_code || countryItem.phone_code,
            email_phone: prefillNumberData.number || prefillEmail,
            country_code:
              prefillNumberData.country_code || countryItem.country_code,
          }}
          showOtp={showOtp}
          showEmailOtp={showEmailOtp}
          showPassword={showPassword}
          showCreatePassword={showCreatePassword}
          emailErrors={emailErrors}
          mobileErrors={mobileErrors}
          setEmailErrors={setEmailErrors}
          setMobileErrors={setMobileErrors}
          setMobileLoading={setMobileLoading}
          setEmailLoading={setEmailLoading}
          onPasswordSubmit={onPasswordSubmit}
          setForgotPassword={setForgotPassword}
          isForgotPassword={isForgotPassword}
          isCreatePassword={isCreatePassword}
          setIsCreatePassword={setIsCreatePassword}
          isIPInternational={
            localCountryCode?.code !== DEFAULT_COUNTRY.country_code
          }
          render={LoginForm}
        />
        {isNonLiveEnv && (
          <div className="card p24 env-switch">
            <Form onSubmit={noop}>
              {() => (
                <>
                  <p className="section-text-5 mb12">
                    Select branch environment
                  </p>
                  <FormCustomSelect
                    options={ENVIRONMENT_OPTIONS}
                    className="mb0"
                    value={env}
                    onChange={(val) => {
                      selectEnv(val);
                      window.location.reload();
                    }}
                    isSearchable={false}
                  />
                  <p className="section-text-5 my12">Login with token</p>
                  <FormTextArea
                    rows={2}
                    name="token"
                    placeholder="Paste token"
                    textAreaClassName="d-block"
                    onChange={(e) => {
                      const token = e.target.value;
                      try {
                        const tokenData = parseJwt(token); // check if token valid
                        window.location.href = `/token?jwt=${token}`;
                      } catch (e) {}
                    }}
                  />
                </>
              )}
            </Form>
          </div>
        )}
      </BounceLoaderContainer>
    </>
  );
};

export default InternationalLogin;
