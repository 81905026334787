import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ClickAwayListener, Fade, Paper, Popper } from '@material-ui/core';
import { useAppContext } from '../context/AppContext';
import useKeyboardListener from '../hooks/utils/useKeyboardListener';
import { HelpFilledIcon } from '../SvgIcon';
import {
  DUKAAN_CHANGELOG,
  DUKAAN_COMMUNITY_URL,
  DUKAAN_HELP,
  HELP_CENTER_URL,
} from '../constants';

const HELP_MENU_ITEMS = [
  {
    icon: '/images/icons/menu/menu-chat.svg',
    label: 'Chat with us',
    onClick: () => window.open(DUKAAN_HELP),
  },
  {
    icon: '/images/icons/menu/menu-tutorials.svg',
    label: 'Help center',
    onClick: () => window.open(HELP_CENTER_URL),
  },
  {
    icon: '/images/icons/menu/announcements.svg',
    label: `What's new`,
    onClick: () => window.open(DUKAAN_CHANGELOG),
  },
  {
    icon: '/images/icons/menu/menu-facebook.svg',
    label: `Join Dukaan VIP`,
    onClick: () => window.open(DUKAAN_COMMUNITY_URL),
  },
];

const HelpMenu = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isVendor, isAppSumoStore } = useAppContext();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  if (isVendor || isAppSumoStore) return null;

  useKeyboardListener('Escape', () => {
    setOpen(false);
  });

  return (
    <>
      <div
        onClick={handleClick}
        className="help-menu"
        aria-describedby={`menu-popover-help`}
        id={`menu-popover-help-btn`}
      >
        <HelpFilledIcon className="mr6" width={16} height={16} /> Help
      </div>
      <Popper
        id={`app-bar-menu-help`}
        open={open}
        anchorEl={anchorEl}
        transition
        className="popover"
        disablePortal
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Fade timeout={350} {...TransitionProps}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div className="store-menu-container">
                <div className="store-menu-items">
                  {HELP_MENU_ITEMS.map(({ icon, label, onClick }, index) => (
                    <div
                      className="store-menu-items-item"
                      key={index}
                      onClick={() => {
                        onClick();
                        setOpen(false);
                      }}
                    >
                      <img src={icon} alt={label} className="mr12" />
                      <p className="section-text-7 c-black-3 mr8">{label}</p>
                    </div>
                  ))}
                </div>
              </div>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default HelpMenu;
