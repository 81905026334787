import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import Modal from '../shared/Modal';
import { DukaanIcon } from '../SvgIcon';
import { accountUrls } from '../Urls';
import { EVENT_ACC_SIGNOUT } from '../events';
import { useAppContext } from '../context/AppContext';
import { logoutUser } from '../utils/user';
import { TrackEvent } from '../utils/analytics';
import { formatDate1 } from '../utils/date';
import { useSubscription } from '../context/SubscriptionProvider';
import { useOverviewData } from '../Home/OverviewDataProvider';
import { useCountry } from '../context/CountryProvider';
import { numberWithCommas } from '../utils/number';
import SpinnerButton from '../shared/SpinnerButton';
import {
  SUBSCRIPTION_CYCLE_DAYS,
  YEARLY_PERIOD,
} from '../Subscription/constants';
import RenewSubscriptionModal from './RenewSubscriptionModal';
import useModal from '../hooks/useModal';
import { ExternalLink } from '../shared';

const PlanStatCard = ({ loading = false, title = '', description = '' }) => (
  <div className="plan-stat-card">
    <p className="text-10 c-black-3 mb4">{title}</p>
    {loading ? (
      <ContentLoader speed={2} width={100} height={28} viewBox="0 0 100 28">
        <rect x="0" y="0" rx="2" ry="2" width="100" height="28" />
      </ContentLoader>
    ) : (
      <p className="section-text-11">{description}</p>
    )}
  </div>
);

const PlanExpiryModal = ({ link, open, ...modalProps }) => {
  const { business, domain, displayDomain } = useAppContext();
  const { formatLocalMoney } = useCountry();
  const { loading, plans, activePlanPeriod, isOldPlan } = useSubscription();
  const { planStartDate, planExpiryDate, fetchStorePlans, activePlan } =
    useSubscription();
  const { viewsStatsLoading, orderStatsLoading, businessStats } =
    useOverviewData();
  const { isInternational } = useCountry();
  const history = useHistory();

  const { isOpen, openModal, closeModal } = useModal();

  const selectedPlan = plans[0]?.price_data?.find(
    (plan) => plan.period === YEARLY_PERIOD
  );

  const expiredPlan = plans.find((item) => item.id === activePlan?.id);
  const expiredPriceData = expiredPlan?.price_data?.find(
    (item) => item.period === activePlanPeriod
  );

  const planStatData = [
    { title: 'ORDERS', description: businessStats?.total_orders ?? 0 },
    {
      title: 'TOTAL SALES',
      description: formatLocalMoney(businessStats?.total_sales ?? 0),
    },
    {
      title: 'STORE VIEWS',
      description: numberWithCommas(businessStats?.total_store_views ?? 0),
    },
    {
      title: 'PRODUCT VIEWS',
      description: numberWithCommas(businessStats?.total_product_views ?? 0),
    },
  ];

  useEffect(() => {
    fetchStorePlans();
  }, []);

  return (
    <div>
      <Modal open={open && !isOpen} {...modalProps}>
        <div className="d-flex-c-s mb40">
          <DukaanIcon alt="dukaan" height={56} />
          <span
            className="section-text-5 underline cur-p"
            onClick={() => {
              TrackEvent(EVENT_ACC_SIGNOUT, business);
              logoutUser();
            }}
          >
            Sign out
          </span>
        </div>

        <div className="d-flex-c-s mb24">
          <div>
            <p className="section-text-5 mb2">Plan duration:</p>
            <p className="c-black-3">
              {formatDate1(planStartDate)} - {formatDate1(planExpiryDate)}
            </p>
          </div>
          <div className="plan-expired-badge">
            <span className="section-text-5 text-white">EXPIRED</span>
          </div>
        </div>
        <div className="d-flex-c-s mb24">
          <div>
            <p className="section-text-5 mb2">Store link:</p>
            <p className="c-black-3 underline">
              <ExternalLink href={domain} text={displayDomain} />
            </p>
          </div>
        </div>
        <div className="mb48">
          <p className="section-text-5 mb12">
            Last {SUBSCRIPTION_CYCLE_DAYS[activePlanPeriod]} days overview
          </p>
          <div className="d-flex align-center plan-stats-container">
            {planStatData.map((stat) => (
              <PlanStatCard
                key={stat.title}
                loading={orderStatsLoading || viewsStatsLoading}
                {...stat}
              />
            ))}
          </div>
        </div>
        <h4 className="section-text-11 mb16">
          Renew your subscription and generate more sales!
        </h4>

        <div className="d-flex align-center">
          <div className="d-flex-c-start mr24">
            {isInternational ? (
              <SpinnerButton
                isLoading={loading}
                onClick={() =>
                  intlPlanSubscribe(selectedPlan?.id, selectedPlan?.period)
                }
              >
                Renew subscription
              </SpinnerButton>
            ) : (
              <SpinnerButton
                label="Renew subscription"
                onClick={() => {
                  if (isOldPlan) {
                    history.push(accountUrls.subscriptionPurchasePath);
                  } else {
                    openModal();
                  }
                }}
              />
            )}
          </div>
          <div
            className="anchor-1 underline section-text-5"
            onClick={() => history.push(accountUrls.subscriptionPurchasePath)}
          >
            View other plans
          </div>
        </div>
      </Modal>
      <RenewSubscriptionModal
        open={isOpen}
        closeModal={closeModal}
        showExploreOtherPlans
        showBlurBackDrop
      />
    </div>
  );
};

export default PlanExpiryModal;
