import React from 'react';
import { BIGHAAT_STORE } from '../../constants';

export const DELHIVERY_CARRIER_ID = 1;
export const XPRESSBESS_CARRIER_ID = 2;
export const BLUE_DART_CARRIER_ID = 3;
export const BORZO_CARRIER_ID = 4;
export const SMARTR_LOGISTICS_CARRIER_ID = 5;
export const DTDC_CARRIER_ID = 6;

export const DELHIVERY_EXPRESS_CARRIER_ID = 1;
export const DELHIVERY_SURFACE_CARRIER_ID = 2;
export const DELHIVERY_SURFACE_HEAVY_CARRIER_ID = 3;

export const XPRESSBESS_EXPRESS_CARRIER_ID = 4;
export const XPRESSBESS_SURFACE_CARRIER_ID = 5;
export const XPRESSBESS_SURFACE_HEAVY_CARRIER_ID = 6;

export const BLUE_DART_EXPRESS_CARRIER_ID = 7;
export const BLUE_DART_SURFACE_CARRIER_ID = 8;
export const BLUE_DART_SURFACE_HEAVY_CARRIER_ID = 9;

export const BUSINESS_TYPES = [
  'Proprietor',
  'Partnership',
  'Pvt. Ltd.',
  'Limited',
];

export const INDIA_STATES = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu And Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
  'Andaman And Nicobar Islands',
  'Chandigarh',
  'Dadra And Nagar Haveli',
  'Daman And Diu',
  'Delhi',
  'Lakshadweep',
  'Puducherry',
];

export const US_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'District of Columbia',
];

export const CANADA_STATES = [
  'Ontario',
  'Quebec',
  'Nova Scotia',
  'New Brunswick',
  'Manitoba',
  'British Columbia',
  'Prince Edward Island',
  'Saskatchewan',
  'Alberta',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Yukon',
  'Nunavut',
];

export const getOptionsFromArray = (data = []) => {
  const options = [];
  data.forEach((item) => options.push({ label: item, value: item }));
  return options;
};

export const getImagesFromArray = (images = []) => {
  const options = [];

  images.forEach((item) => {
    options.push({
      imgUrl: item,
      name: item.substring(item.lastIndexOf('/') + 1),
    });
  });
  return options;
};

export const INTERNATIONAL_FAQ_DATA_FOR_US = [
  {
    query: 'How can I start shipping with Dukaan’s Delivery service?',
    answer: (
      <p>
        Start using Dukaan’s Delivery in partnership with eshipz by enabling it
        from the Delivery section, if you have an account with any of the
        carriers mentioned in the Check supported partner, or add a plugin
        (Hyperlink Plugin) and connect your Shippo account.
      </p>
    ),
  },
  {
    query: 'How does Dukaan’s Delivery work?',
    answer: (
      <p>
        After enabling and connecting your direct courier company account while
        setting up the Delivery section, Start shipping orders from the order
        section.
      </p>
    ),
  },
  {
    query: 'How do Ship Multiple Orders work?',
    answer: (
      <p>
        After enabling and connecting your direct courier company account while
        setting up the Delivery section, Start shipping orders through the ship
        multiple buttons in the order section.
      </p>
    ),
  },
  {
    query: 'When will the order be picked up by the courier partner?',
    answer: (
      <p>
        Kindly contact your direct delivery carrier service or shippo for
        details.
      </p>
    ),
  },
  {
    query: 'What happens when the order is lost in transit?',
    answer: (
      <p>
        Kindly contact your direct delivery carrier service or shippo for
        details.
      </p>
    ),
  },
];

export const INTERNATIONAL_FAQ_DATA = [
  {
    query: 'How can I start shipping with Dukaan’s Delivery service?',
    answer: (
      <p>
        Start using Dukaan’s Delivery in partnership with eshipz by enabling it
        from the Delivery section, if you have an account with any of the
        carriers mentioned in the Check supported partner.
      </p>
    ),
  },
  {
    query: 'How does Dukaan’s Delivery work?',
    answer: (
      <p>
        After enabling and connecting your direct courier company account while
        setting up the Delivery section, Start shipping orders from the order
        section.
      </p>
    ),
  },
  {
    query: 'How do Ship Multiple Orders work?',
    answer: (
      <p>
        After enabling and connecting your direct courier company account while
        setting up the Delivery section, Start shipping orders through the ship
        multiple buttons in the order section.
      </p>
    ),
  },
  {
    query: 'When will the order be picked up by the courier partner?',
    answer: <p>Kindly contact your direct delivery carrier service.</p>,
  },
  {
    query: 'What happens when the order is lost in transit?',
    answer: (
      <p>Kindly contact your direct delivery carrier service (edited).</p>
    ),
  },
];

export const DELIVERY_FAQ_DATA = [
  {
    query: 'How can I start shipping with Dukaan Delivery?',
    answer:
      'Just enable Dukaan delivery from the Delivery section and you can start shipping your orders using Dukaan delivery.',
  },
  {
    query:
      'Why should I use Dukaan delivery instead of direct courier companies?',
    answer: (
      <p>
        Dukaan uses proprietary AI algorithms to automatically choose the most
        efficient courier partner for your order based on shipping cost,
        historical pickup, delivery performance, and RTO rates.
        <br /> <br />
        Dukaan has partnered & negotiated the best possible rates with leading
        courier companies to offer you the most affordable shipping and save you
        from the hassle of dealing with multiple providers, reconciling
        payments, manually, and the error-prone process of entering order
        information from Dukaan into providers interface, etc.
      </p>
    ),
  },
  {
    query: 'How does Dukaan delivery work ?',
    answer: (
      <p>
        When you ship an order using Dukaan delivery, Dukaan will show you the
        estimated pickup time, delivery time, and list of Courier Partners.
        <br />
        <br />
        Once you select the Courier Partners and Proceed, you will be asked to
        enter the Product weight after which the shipping charges will show up.
        <br /> <br />
        Once you click on “Proceed to Ship”, You will be prompted to pay the
        delivery fees using UPI, debit, or credit card. You can also add a
        one-off bulk amount in your account as credits and use that to pay for
        individual orders instead of paying one by one.
        <br /> <br />
        Upon payment confirmation, a popup will come and it will show Courier
        Name, Tracking ID, and Download Shipping Label & Invoice for this
        shipment.
        <br /> <br />
        You should download & print the Invoice & Shipping label. At the time of
        packing, insert the invoice inside the package and stick the shipping
        label onto the packing box.
        <br /> <br />
        Within the next 24 hrs, the courier partner will come to your warehouse
        address to pick up the package and the order will be delivered to your
        customer within the next 3-5 days. You as well as your customer will be
        able to track the order from your order tracking page.
        <br /> <br />
        Once the order is delivered, Dukaan will pay you the order amount 1 day
        after delivery.
      </p>
    ),
  },
  {
    query: 'How do Ship Multiple Orders work?',
    answer: (
      <p>
        When you click on Ship Multiple Orders, Dukaan will show you the
        estimated pickup time and list of delivery partners.
        <br /> <br />
        Once you select the Courier Partners and Proceed, you will be asked to
        enter the Product weights for all the orders after which the shipping
        charges will show up for individual orders. You can also deselect orders
        if you want.
        <br /> <br />
        Once you click on “Proceed to Ship”, You will be prompted to pay the
        delivery fees using UPI, debit, or credit card. You can also add a
        one-off bulk amount in your account as credits and use that to pay for
        individual orders instead of paying one by one.
        <br /> <br />
        Upon payment confirmation, a popup will come and it will show Courier
        Name, Tracking ID, and Download Shipping Label & Invoice for this
        shipment.
        <br /> <br />
        You should download & print the Invoice & Shipping label. At the time of
        packing, insert the invoice inside the package and stick the shipping
        label onto the packing box.
        <br /> <br />
        Within the next 24 hrs, the courier partner will come to your store's
        address to pick up the package and the order will be delivered to your
        customer within the next 3-5 days. You as well as your customer will be
        able to track the order from your order tracking page.
        <br /> <br />
        Once the order is delivered, Dukaan will pay you the order amount 1 day
        after delivery.
      </p>
    ),
  },
  {
    query: 'When will the order be picked up by the courier partner?',
    answer: (
      <p>
        Your order is likely to be picked up the same day if requested before 2
        pm else the next day if requested post 2 pm.
      </p>
    ),
  },
  {
    query: 'When will I get paid?',
    answer: (
      <p>Dukaan will process the payout 1 day after the order is delivered.</p>
    ),
  },
  // {
  //   query: 'What are the fees/charges for Dukaan delivery?',
  //   answer: (
  //     <div>
  //       Following are the charges that will be deducted from the final
  //       settlement amount for each order
  //       <table className="mt24">
  //         <tr>
  //           <th>Line item</th>
  //           <th>Description</th>
  //         </tr>
  //         <tr>
  //           <td>Shipping Charge</td>
  //           <td className="pl24">
  //             As show at the time of shipping the order, based on destination
  //             pincode & weight
  //           </td>
  //         </tr>
  //         <tr>
  //           <td>COD Charge</td>
  //           <td className="pl24">
  //             As shown at the time of shipping the order. Zero for online paid
  //             orders.
  //           </td>
  //         </tr>
  //         <tr>
  //           <td>Payout Charge</td>
  //           <td className="pl24">
  //             0.49% of the payout amount for premium users, rest 0.99%
  //           </td>
  //         </tr>
  //       </table>
  //     </div>
  //   ),
  // },
  {
    query: 'What happens when the order is lost in transit?',
    answer: (
      <p>
        You can raise a lost-in-transit request with Dukaan for shipments within
        one week of pickup. Dukaan will forward the claim to the courier partner
        and for all valid claims, you will get the order value reimbursed, up to
        INR 3000.
      </p>
    ),
  },
];

export const ALL_WAREHOUSES_OPTION = {
  label: 'All warehouses',
  value: 'ALL',
};

export const STATUS_MAP = {
  pending: 0,
  ready_to_ship: 1,
  pick_up_requested: 2,
  in_transit: 3,
  delivered: 4,
  on_hold: 5,
  rejected: 6,
  returned: 10,
  undelivered: 11,
  cancelled_by_customer: 12,
  out_for_delivery: 13,
  shipment_lost: 14,
  rejected_by_vendor: 15,
  out_for_pickup: 16,
  pickup_not_done: 17,
  pickup_failed: 18,
  Out_for_delivery: 19, // alternative of reached at destination for borzo
  In_transit: 20, // RTO_IN_TRANSIT
  Out_For_delivery: 21, // RTO_OUT_FOR_DELIVERY
  returns: -1,
};

export const PICKUP_FAILED_REASON = 'pickup_failed';

export const VENDOR_STATUS_MAP = {
  returned: 0,
  replaced: 1,
  cancelled: 2,
};

export const DELIVERY_ALL_STATUS = -1;
export const FORWARD_TYPE = 'forward';
export const REVERSE_TYPE = 'reverse';
export const UNDELIVERED_STATUS = 11;
export const LOST_STATUS = 14;
export const WAREHOUSE_SETTINGS_PAGE = 1;
export const WAREHOUSE_EDIT_PAGE = 2;
export const DTO_ORDERS = 'dto';
export const REJECTED_ORDERS = 'Rejected';

export const PENDING_STATUS_OPTION = {
  key: 'pending',
  value: 0,
  label: 'Pending',
  count: 0,
  type: FORWARD_TYPE,
  is_reverse_shipment: false,
};

export const REJECTED_STATUS_OPTION = {
  key: REJECTED_ORDERS,
  value: 15,
  label: 'Rejected',
  count: 0,
  type: FORWARD_TYPE,
};

export const DTO_STATUS_OPTION = {
  key: DTO_ORDERS,
  value: 50,
  label: 'Return',
  count: 0,
  is_reverse_shipment: true,
};

export const PICKUP_REQUESTED_STATUS = [1, 2, 16, 17, 18];

export const STATUS = [
  {
    key: 'total',
    value: -1,
    label: 'All',
    count: 0,
  },
  {
    key: 'pick_up_requested',
    value: [...PICKUP_REQUESTED_STATUS],
    label: 'Pickup pending',
    count: 0,
    type: FORWARD_TYPE,
    is_reverse_shipment: false,
  },
  {
    key: 'in_transit',
    value: 3,
    label: 'In transit',
    count: 0,
    type: FORWARD_TYPE,
    is_reverse_shipment: false,
  },
  {
    key: 'out_for_delivery',
    value: [13, 19],
    label: 'Out for delivery',
    count: 0,
    type: FORWARD_TYPE,
    is_reverse_shipment: false,
  },
  {
    key: 'delivered',
    value: 4,
    label: 'Delivered',
    count: 0,
    type: FORWARD_TYPE,
    is_reverse_shipment: false,
  },
  {
    key: 'undelivered',
    value: UNDELIVERED_STATUS,
    label: 'RTO',
    count: 0,
    type: REVERSE_TYPE,
    is_reverse_shipment: false,
  },
  {
    key: 'lost',
    value: LOST_STATUS,
    label: 'Lost',
    count: 0,
    type: FORWARD_TYPE,
    is_reverse_shipment: false,
  },
];

export const LAST_MODIFIED_DATE = 'modified_at';
export const LAST_MODIFIED_DATE_UTC = 'modified_at_utc';
export const ORDER_DATE_ON = 'created_at';
export const ORDER_DATE_ON_UTC = 'created_at_utc';
export const ORDER_CREATED_ON = 'order_created_at';
export const ORDER_MODIFIED_ON = 'order_modified_at';
export const TOTAL_AMOUNT = 'total_cost';
export const SLEF_SHIP_LOGO =
  'https://dms.mydukaan.io/original/webp/plugins/1617965209561-Self%20Ship.png';

export const WAREHOUSE_SECTION = {
  WAREHOUSE: 1,
  SHIPPING_PREFERENCE: 2,
  HELP: 3,
};

export const PRIMARY_FIRST = 'primary_first';
export const NEAREST_FIRST = 'nearest_first';

// CONSTANTS FOR RETURN ORDERS

export const RETURN_CREATED_ON = 'return_created_at';
export const RETURN_MODIFIED_ON = 'return_modified_at';
export const RETURN_DATE_MAP = {
  [RETURN_MODIFIED_ON]: LAST_MODIFIED_DATE,
  [RETURN_CREATED_ON]: ORDER_DATE_ON,
  [LAST_MODIFIED_DATE]: RETURN_MODIFIED_ON,
  [ORDER_DATE_ON]: RETURN_CREATED_ON,
};

export const ALL_DTO_STATUS = 'all_dto_status';

export const DTO_STATUS_OPTIONS = [
  {
    key: 'all_dto_status',
    value: -1,
    label: 'All status',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
  {
    key: 'to_be_picked',
    value: [1, 2, 5, 17],
    label: 'To be picked',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
  {
    key: 'out_for_pickup',
    value: 16,
    label: 'Out for pickup',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
  {
    key: 'in_transit',
    value: [3],
    label: 'In transit',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
  {
    key: 'out_for_delivery',
    value: [13, 19],
    label: 'Out for return',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
  {
    key: 'delivered',
    value: 4,
    label: 'Completed',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
  {
    key: 'lost',
    value: 14,
    label: 'Lost',
    type: FORWARD_TYPE,
    is_reverse_shipment: true,
  },
];

// BIGHAAT related test Store Id's can be added here

export const BIGHAAT_RELATED_STORE_ID = {
  BIGHAAT_STORE_ID: BIGHAAT_STORE,
  TEST_STORE_STAGE_ID: 4121,
  TEST_STORE_PROD_ID: 102258355,
};

// Warehouse related constants
export const WAREHOUSE_PAGE_SIZE = 10;
export const WAREHOUSE_PAGE_COUNT_SHOWN = 7;

export const ALLOW_VENDOR_SHIPMENT_REJECT_KEY = 'allow_vendor_shipment_reject';

export const ALLOW_ADMIN_ACTION_ON_VENDOR_SHIPMENT_KEY =
  'allow_admin_action_on_vendor_shipment';
