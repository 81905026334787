import cx from 'classnames';
import { useEffect, useState } from 'react';
import {
  OTHER_FILE_EXTENSIONS,
  TEXT_FILE_EXTENSIONS,
  VIDEO_FILE_EXTENSION,
} from '../../../Account/Sections/CategoryAttributes/constants';
import { Image } from '../../../Catalogue/Products/ProductImages';
import useModal from '../../../hooks/useModal';
import { CrossIcon } from '../../../SvgIcon';
import { noop } from '../../../utils';
import { getExtensionFromFileName } from '../../../utils/file';
import FileUploadManager from '../../FileUploadManager';
import SpinnerButton from '../../SpinnerButton';
import FormInput from '../FormInput';
import { isNotEmptyOrNull } from '../Shared';
import UploadModal from './UploadModal';

const FormFileUploadField = (props) => {
  const {
    allowMultipleFiles = false, // can be multiple or single file upload
    fileUrl = '', // selected files from before | comma separated list
    formProps,
    isFileAttribute = false,
    options: uploadedFiles, // previously uploaded files to be shown on modal
    onUploadSuccess = noop,
    metafieldId = null,
    ...restProps // name, required, shouldShowRequires, validate
  } = props;

  const { form } = formProps;

  const {
    isOpen: isUploadModalOpen,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
  } = useModal();

  const [allFileUrls, setAllFileUrls] = useState([]);
  const [files, setFiles] = useState(uploadedFiles);
  const [multipleSelectedFiles, setMultipleSelectedFiles] = useState([]);
  const [value, setValue] = useState([]);

  const getFileUrl = () => {
    const uploadedImgUrl = files?.filter((ele) => ele.imgUrl === fileUrl);
    return uploadedImgUrl[0]?.imgUrl;
  };

  useEffect(() => {
    if (fileUrl) {
      if (fileUrl.includes(','))
        setValue(
          fileUrl
            .split(',') // to get array of file urls from comma separated string
            .filter((item) => isNotEmptyOrNull({ value: item })) // to remove any empty string
            .map((item) => item.substring(item.lastIndexOf('/') + 1)) // to get the file names from the urls
        );
      else setValue([fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1)]);
    } else {
      setValue([]);
    }

    const previouslySelectedFiles = fileUrl
      ? fileUrl.split(',').filter((item) => isNotEmptyOrNull({ value: item }))
      : [];

    if (allowMultipleFiles) {
      form.mutators.modifyFormField(
        `metafields.attribute__${metafieldId}`,
        previouslySelectedFiles.join(',') || ''
      );
      setMultipleSelectedFiles(previouslySelectedFiles);
    } else {
      form.mutators.modifyFormField(
        `metafields.attribute__${metafieldId}`,
        previouslySelectedFiles[0] || ''
      );
      setMultipleSelectedFiles([previouslySelectedFiles[0]]);
    }
  }, [fileUrl]);

  useEffect(() => {
    const allFiles = [];
    files.forEach((file) => allFiles.push(file.imgUrl));
    setAllFileUrls(allFiles);
  }, [files]);

  return (
    <>
      <SpinnerButton
        className={cx('select-file-btn cur-p w-100', {
          'file-added': value[0] !== '',
        })}
        btnClass="d-flex w-100"
        onClick={openUploadModal}
      >
        <div className="d-flex uploaded-file-image w-100">
          {[
            ...TEXT_FILE_EXTENSIONS,
            ...VIDEO_FILE_EXTENSION,
            ...OTHER_FILE_EXTENSIONS,
          ].includes(getExtensionFromFileName(value?.[0] ?? '')) ? (
            <FileUploadManager
              name={value[0]}
              isFileAttribute={isFileAttribute}
            />
          ) : (
            <>
              {multipleSelectedFiles[0] ? (
                <Image imgUrl={getFileUrl() || ''} height={24} width={24} />
              ) : null}
            </>
          )}
          <div className="d-flex-c-s w-100">
            <div
              className={cx('uploaded-file-name', {
                'uploaded-file-input-placeholder': !value.length,
              })}
            >
              {!value.length ? 'Select file' : value.join(', ')}
            </div>
            {value.length > 0 && (
              <CrossIcon
                height={16}
                width={16}
                className="cross-icon-abs"
                onClick={(e) => {
                  e.stopPropagation();
                  form.mutators.modifyFormField(
                    `metafields.attribute__${metafieldId}`,
                    ''
                  );
                  setValue([]);
                  setMultipleSelectedFiles([]);
                }}
              />
            )}
            {/* <div>Add Actions here</div> */}
          </div>
          <FormInput labelText="" className="d-none" {...restProps} />
        </div>
      </SpinnerButton>

      {isUploadModalOpen && (
        <UploadModal
          allFileUrls={allFileUrls}
          allowMultipleFiles={allowMultipleFiles}
          closeModal={closeUploadModal}
          files={files}
          formProps={formProps}
          metafieldId={metafieldId}
          multipleSelectedFiles={multipleSelectedFiles}
          onUploadSuccess={onUploadSuccess}
          open={isUploadModalOpen}
          setFiles={setFiles}
          setMultipleSelectedFiles={setMultipleSelectedFiles}
          value={value}
          setValue={setValue}
          modifyFormField={(data = '') =>
            form.mutators.modifyFormField(
              `metafields.attribute__${metafieldId}`,
              data
            )
          }
        />
      )}
    </>
  );
};

export default FormFileUploadField;
