const COLORS = {
  BLACK: '#000000',
  BLACK_1: '1a181e',
  BLACK_2: '#1e2640',
  BLACK_3: '#4d4d4d',
  BLACK_4: '#101d2f',

  WHITE: '#ffffff',
  WHITE_1: '#f7f7f7',
  WHITE_2: '#d9d9d9',
  WHITE_3: '#ededed',
  WHITE_4: '#f5f5f5',
  WHITE_5: '#d8d8d8',
  WHITE_6: '#f2f2f2',
  WHITE_7: '#e8e8e8',
  WHITE_8: '#e5e5e5',
  WHITE_9: '#e6e6e6',
  WHITE_10: '#fafafa',

  GRAY_1: '#808080',
  GRAY_2: '#989898',
  GRAY_3: '#cccccc',
  GRAY_4: '#999999',
  GRAY_5: '#666666',
  GRAY_6: '#b2b2b2',
  GRAY_7: '#fab73b1a',
  GRAY_8: '#b3b3b3',
  GRAY_9: '#e7e7e74d',

  PURPLE_1: '#146eb4',
  PURPLE_2: '#115f9c',
  PURPLE_3: '#13458c',
  PURPLE_HOVER: '#0e4f82',

  RED_1: '#e50b20',
  RED_2: '#cc0a1d',
  RED_3: '#df1e5b',
  RED_4: '#d00081',
  RED_5: '#c13584',
  RED_6: '#e50b201a',
  RED_7: '#fde7e9',
  RED_8: '#9E130F',

  GREEN_1: '#17b31b',
  GREEN_2: '#139116',
  GREEN_3: '#00af87',
  GREEN_4: '#045e54',

  ORANGE_1: '#ee741f',
  ORANGE_2: '#cc631b',
  ORANGE_3: '#ea6f57',
  ORANGE_4: '#fab73b',
  ORANGE_5: '#fb7948',
  ORANGE_6: '#994b14',
  ORANGE_7: '#f6bf50',

  VIOLET_1: '#7d00be',

  BROWN_1: '#52362a',
  BROWN_2: '#452d23',

  SEO: {
    PREVIEW_LINK: '#5F6368',
    TOO_SHORT: '#E50B20',
    PERFECT: '#17B31B',
    TOO_LONG: '#FAB73B',
  },
};

export default COLORS;
