import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getRequest, postRequest } from '../utils/http';
import {
  SETUP_SHIPPO_URL,
  FETCH_INTL_SHIPPING_RATES_URL,
  SHIP_INTL_ORDER_URL,
  UNINSTALL_SHIPPO_URL,
  SIGNUP_ESHIP_URL,
  ESHIP_FETCH_CARRIERS_URL,
  ESHIP_FETCH_ALL_PARTNERS_URL,
} from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';
import { manageUrls } from '../Urls';

let counter = 0;

const useIntlOrderShipment = () => {
  const [loading, setLoading] = useState(false);
  const [fetchingError, setFetchingError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [partners, setPartners] = useState([]);
  const [eshipzRes, setEshipzRes] = useState(null);
  const [eshipzPartners, setEshipzPartners] = useState(null);
  const [eshipzSignUpRes, setEshipzSignUpRes] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();
  const history = useHistory();

  const signupEship = (successCb = noop, errorCb = noop) => {
    setSubmitting(true);
    postRequest({ url: SIGNUP_ESHIP_URL })
      .then(({ data = {} }) => {
        setEshipzSignUpRes(data);
        successCb(data);
        history.push(
          `${manageUrls.connectIntlPartners}?title=Connect%20Partners&redirectedUrl=${data?.embed_url}`
        );
      })
      .catch((error) => {
        // error.data.errors
        enqueueSnackbar('Something went wrong. Try again later.', 'error');
        errorCb(error);
      })
      .finally(() => setSubmitting(false));
  };

  const fetchEshipCarriersData = (successCb = noop, errorCb = noop) => {
    setLoading(true);
    getRequest({
      url: ESHIP_FETCH_CARRIERS_URL,
    })
      .then(({ data = {} }) => {
        setEshipzRes(data);
        successCb(data);
      })
      .catch(errorCb)
      .finally(() => setLoading(false));
  };

  const fetchEshipAllPartners = (successCb = noop, errorCb = noop) => {
    setLoading(true);
    getRequest({
      url: ESHIP_FETCH_ALL_PARTNERS_URL,
    })
      .then(({ data = {} }) => {
        setEshipzPartners(data);
        successCb(data);
      })
      .catch(errorCb)
      .finally(() => setLoading(false));
  };

  const setupShippo = (token, successCb = noop, errorCb = noop) => {
    setSubmitting(true);

    postRequest({ url: SETUP_SHIPPO_URL, data: { token } })
      .then((res) => {
        enqueueSnackbar('Shippo token updated successfully!');
        successCb(res);
      })
      .catch((e) => {
        enqueueSnackbar(
          'Error updating Shippo token, please check your token',
          'error'
        );
        errorCb(e);
      })
      .finally(() => setSubmitting(false));
  };

  const uninstallShippo = (successCb = noop, errorCb = noop) => {
    postRequest({ url: UNINSTALL_SHIPPO_URL })
      .then((res) => {
        successCb(res);
      })
      .catch((e) => {
        errorCb(e);
      });
  };
  
  const fetchShippingRatesForOrder = (
    payload,
    successCb = noop,
    errorCb = noop
  ) => {
    setFetchingError(false)
    counter += 1;
    const counterCopy = counter;
    const { order_id: orderId, weight, mode } = payload;
    setLoading(true);
    postRequest({
      url: FETCH_INTL_SHIPPING_RATES_URL,
      data: { order: Number(orderId), weight_in_pounds: Number(weight) },
    })
      .then((res) => {
        if (counter === counterCopy) {
          if (Array.isArray(res.data?.rates) && res.data.rates?.length) {
            setPartners(res.data.rates);
            successCb(res.data.rates);
            if(mode) {
              const selectedIntlPartner = res.data.rates.filter(
                (each) => each?.servicelevel?.token === mode
              )?.[0];
              if (!selectedIntlPartner) {
                throw new Error("Partner does not exist")
              }
            }
          } else {
            throw new Error("Partner not found")
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        errorCb(err);
        if (counter === counterCopy) {
          setFetchingError(true);
          setPartners([]);
          enqueueSnackbar('Could not fetch shipping rates', 'error');
          setLoading(false);
        }
      });
  };

  const shipIntlOrder = (payload, successCb = noop, errorCb = noop) => {
    setSubmitting(true);
    postRequest({
      url: SHIP_INTL_ORDER_URL,
      data: { ...payload },
    })
      .then(successCb)
      .catch(errorCb)
      .finally(() => setSubmitting(false));
  };

  return {
    loading,
    setLoading,
    fetchingError,
    submitting,
    partners,
    eshipzSignUpRes,
    eshipzRes,
    eshipzPartners,
    signupEship,
    fetchEshipCarriersData,
    fetchEshipAllPartners,
    setupShippo,
    uninstallShippo,
    fetchShippingRatesForOrder,
    shipIntlOrder,
  };
};

export default useIntlOrderShipment;
