import { useState } from 'react';

import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from '../utils/http';
import {
  GET_ORDER_FORM_FIELDS,
  GET_ORDER_FORM_FIELD,
  CREATE_ORDER_FORM_FIELD,
  UPDATE_ORDER_FORM_FIELD,
  DELETE_ORDER_FORM_FIELD,
  UPDATE_STORE_CHECKOUT_PREFERENCES,
  GET_STORE_CHECKOUT_FORM_DATA,
  GET_STORE_CHECKOUT_PREFERENCES,
  ABANDONED_CART_CHECKOUT_TEMPLATES,
  CAMPAIGN_TEMPLATES_BY_CHANNEL_URL,
  GET_RECOVERY_ABANDONED_CART,
  GET_STORE_CHECKOUT_FORM_DATA_COUNTRY,
} from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';
import { useAppContext } from '../context/AppContext';
import { SMS_CHANNEL_ID } from '../Campaign/constants';

const useOrderForm = () => {
  const [loading, setLoading] = useState(false);
  const [primaryContactLoading, setPrimaryContactLoading] = useState(false);
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const { business } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [fields, setFields] = useState([]);
  const [field, setField] = useState(null);
  const [orderFormData, setOrderFormData] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [primaryContactData, setPrimaryContactData] = useState({});
  const [abandonedTemplates, setAbandonedTemplates] = useState([]);
  const [recoveryAbandonedCartData, setRecoveryAbandonedCartData] = useState(
    {}
  );
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [pageNumberTemplates, setPageNumberTemplates] = useState(1);
  const [hasMoreTemplates, setHasMoreTemplates] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);

  const { enqueueSnackbar } = useCustomSnackbar();

  const fetchFields = () => {
    setLoading(true);
    getRequest({
      url: GET_ORDER_FORM_FIELDS,
    })
      .then((res) => {
        setLoading(false);
        if (res.results) {
          setFields(res.results);
        }
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong.', 'error');
        setLoading(false);
      });
  };

  const fetchField = (uuid) => {
    setLoading(true);
    getRequest({
      url: GET_ORDER_FORM_FIELD(uuid),
    })
      .then((res) => {
        setLoading(false);
        setField(res.data);
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong.', 'error');
        setLoading(false);
      });
  };

  const fetchStoreOrderFormDetails = (countryCode = false) => {
    setPreferencesLoading(true);
    getRequest({
      url: countryCode
        ? GET_STORE_CHECKOUT_FORM_DATA_COUNTRY(business.uuid, countryCode)
        : GET_STORE_CHECKOUT_FORM_DATA(business.uuid),
    })
      .then((res) => {
        setOrderFormData(res.data);
        setRequiredFields(res.data.filter((it) => it?.required));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreferencesLoading(false);
      });
  };

  const fetchPrimaryContactData = () => {
    setPrimaryContactLoading(true);
    getRequest({
      url: GET_STORE_CHECKOUT_PREFERENCES(business.uuid),
    })
      .then((res) => {
        let data = {};
        res.data.forEach((item) => {
          if (item.name === 'checkout_primary_contact') {
            data = item;
          }
        });
        setPrimaryContactData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setPrimaryContactLoading(false));
  };

  const fetchRecoveryAbandonedCart = () => {
    setFetchingDetails(true);
    getRequest({
      url: GET_RECOVERY_ABANDONED_CART(business?.uuid),
    })
      .then((res) => {
        setRecoveryAbandonedCartData(...res?.data);
      })
      .finally(() => setFetchingDetails(false));
  };

  const updateStoreCheckoutPreferences = (
    data,
    isAdvancedFieldChanged = false,
    isPrimaryContactChanged = false,
    isOrderFormDropdownChanged = false,
    successCb = noop,
    primaryContactSuccessCb = noop,
    isAbandonedCart = false
  ) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_STORE_CHECKOUT_PREFERENCES(business.uuid),
      data,
      doClean: false,
    })
      .then(() => {
        setSubmitting(false);
        if (isAbandonedCart) {
          fetchRecoveryAbandonedCart();
          return enqueueSnackbar('Abandoned cart updated successfully.');
        }
        if (isPrimaryContactChanged) {
          enqueueSnackbar('Primary contact updated successfully.');
          primaryContactSuccessCb();
        } else {
          enqueueSnackbar('Changes updated successfully');
          if (isOrderFormDropdownChanged) {
            fetchStoreOrderFormDetails();
          }
        }
        successCb();
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong.', 'error');
      });
  };

  const createField = (data, callback = noop) => {
    setSubmitting(true);
    postRequest({
      url: CREATE_ORDER_FORM_FIELD,
      data,
    })
      .then(() => {
        enqueueSnackbar('Form field added successfully');
        setSubmitting(false);
        callback();
      })
      .catch((resp) => {
        if (resp.data.data) {
          const errors = resp.data.data;

          Object.keys(errors).map((key) =>
            errors[key].map((err) => enqueueSnackbar(err, 'error'))
          );
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const updateField = (uuid, data, callback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_ORDER_FORM_FIELD(uuid),
      data,
    })
      .then(() => {
        enqueueSnackbar('Form field updated successfully');
        setSubmitting(false);
        callback();
      })
      .catch((resp) => {
        if (resp.data.data.error) {
          enqueueSnackbar(resp.data.data.error, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const deleteField = (uuid, callback = noop) => {
    setSubmitting(true);
    deleteRequest({
      url: DELETE_ORDER_FORM_FIELD(uuid),
    })
      .then(() => {
        enqueueSnackbar('Form field deleted successfully');
        setSubmitting(false);
        callback();
      })
      .catch((resp) => {
        if (resp.data.data.error) {
          enqueueSnackbar(resp.data.data.error, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const fetchAbanDonedTemplates = (channelId) => {
    setTemplateLoading(true);
    const queryParam =
      channelId === SMS_CHANNEL_ID
        ? `page=${pageNumberTemplates}`
        : `editable=true&page=${pageNumberTemplates}`;
    getRequest({
      url: ABANDONED_CART_CHECKOUT_TEMPLATES(
        business?.uuid,
        channelId,
        queryParam
      ),
    })
      .then((res) => {
        const { count, results } = res;
        if (pageNumberTemplates === 1) {
          setHasMoreTemplates(results.length < count);
          setAbandonedTemplates(results);
        } else {
          setHasMoreTemplates(
            abandonedTemplates.length + results.length < count
          );
          setAbandonedTemplates((prevData) => [
            ...new Set([...prevData, ...results]),
          ]);
        }
      })
      .catch(console.log)
      .finally(() => {
        setTemplateLoading(false);
      });
  };

  return {
    loading,
    submitting,
    fields,
    field,
    fetchFields,
    fetchField,
    createField,
    updateField,
    deleteField,
    fetchStoreOrderFormDetails,
    orderFormData,
    requiredFields,
    updateStoreCheckoutPreferences,
    preferencesLoading,
    primaryContactLoading,
    fetchPrimaryContactData,
    primaryContactData,
    fetchAbanDonedTemplates,
    abandonedTemplates,
    fetchRecoveryAbandonedCart,
    recoveryAbandonedCartData,
    fetchingDetails,
    templateLoading,
    pageNumberTemplates,
    setPageNumberTemplates,
    hasMoreTemplates,
  };
};

export default useOrderForm;
