import { useState } from 'react';
import {
  CREATE_NEW_PRODUCT_FAQ_URL,
  DELETE_PRODUCT_FAQ_URL,
  GET_PRODUCT_FAQS_URL,
  UPDATE_PRODUCT_FAQ_URL,
} from '../ApiUrls';
import { useAppContext } from '../context/AppContext';

import { noop } from '../utils';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';

const useProductFAQ = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const { enqueueSnackbar } = useCustomSnackbar();

  const fetchProductFaqs = (productID, successCallback = noop) => {
    setLoading(true);
    getRequest({
      url: GET_PRODUCT_FAQS_URL(productID),
    })
      .then((res) => {
        const { data } = res;
        setFaqList(data?.faqs_list);
        successCallback(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const createProductFaqs = (payload, productID, successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: CREATE_NEW_PRODUCT_FAQ_URL(productID),
      data: { faqs_list: payload },
    })
      .then((res) => {
        if (res.data) {
          successCallback(res.data);
          enqueueSnackbar(`Question added successfully`);
          fetchProductFaqs(productID);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something went wrong. Try again later.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const updateProductFaqs = (
    payload,
    faqID,
    productId,
    successCallback = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_PRODUCT_FAQ_URL(faqID),
      data: payload,
    })
      .then((res) => {
        if (res.data) {
          enqueueSnackbar(`Question updated successfully`);
          successCallback(res.data);
          fetchProductFaqs(productId);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something went wrong. Try again later.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const deleteProductFaq = (faqID, productId, successCb = noop) => {
    deleteRequest({
      url: DELETE_PRODUCT_FAQ_URL(faqID),
    }).then(() => {
      enqueueSnackbar('FAQ deleted successfully.');
      successCb();
      fetchProductFaqs(productId);
    });
  };

  return {
    loading,
    submitting,
    faqList,
    fetchProductFaqs,
    createProductFaqs,
    deleteProductFaq,
    updateProductFaqs,
  };
};

export default useProductFAQ;
