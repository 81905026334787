import cx from 'classnames';
import SpinnerButton from '../../../shared/SpinnerButton';
import { AlertTriangle, CloseMobilePlanIcon } from '../../../SvgIcon';
import { noop } from '../../../utils';

const MarketingPlainStripe = ({
  title = '',
  onClick = noop,
  parentClass = '',
  btnText = '',
  onClose,
  icon: Icon = AlertTriangle,
  showIcon = true,
  iconClass = 'mr6',
}) => (
  <div className={cx('card d-flex-c-s px16 py8', parentClass)}>
    <div className="d-flex align-center">
      {showIcon && <Icon className={iconClass} />}
      <p className="text-2 text-medium">{title}</p>
    </div>
    <div className="d-flex align-center">
      {btnText && (
        <SpinnerButton
          type="button"
          className="btn-primary-4 btn-small"
          onClick={onClick}
        >
          {btnText}
        </SpinnerButton>
      )}
      {typeof onClose === 'function' && (
        <CloseMobilePlanIcon
          style={{ width: 18 }}
          className="ml16 cur-p"
          onClick={onClose}
        />
      )}
    </div>
  </div>
);

export default MarketingPlainStripe;
