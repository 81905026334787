import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { WarningExclaimationIcon } from '../../SvgIcon';
import { catalogueUrls } from '../../Urls';
import useEnterPressAction from '../../hooks/useEnterPressAction';

const SKUFileUploadModal = ({ open, closeModal, submitting = false }) => {
  const history = useHistory();
  const callback = () =>
    history.push(catalogueUrls.productInventoriesBulkUpdatePath);

  useEnterPressAction(open, callback);
  return (
    <Modal
      open={open}
      className="shipment-created-modal new-shipment-modal"
      closeModal={closeModal}
      maxWidth="sm"
    >
      <div className="shipment-created-modal">
        <div className="flex-center">
          <div className="pt8">
            <WarningExclaimationIcon />
          </div>
          <h4 className="section-text-4 pt8">Please note</h4>
          <div className="text-2 c-black-3 d-bloc text-center pt6">
            You need to upload a CSV file in order to update the inventory
            across your multiple warehouses.
          </div>

          <SpinnerButton
            showAnimation
            type="button"
            className="btn btn-primary-4 mt32"
            isLoading={submitting}
            onClick={callback}
          >
            Upload now
          </SpinnerButton>
        </div>
      </div>
    </Modal>
  );
};

export default SKUFileUploadModal;
