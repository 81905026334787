import React, { useState } from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { FormControlLabel, Menu, RadioGroup } from '@material-ui/core';
import {
  LAST_MODIFIED_DATE,
  ORDER_DATE_ON,
  ORDER_DATE_ON_UTC,
} from '../../Manage/Delivery/constants';
import { noop } from '../../utils';
import { useOrdersContext } from '../../context/OrdersProvider';
import { SortIcon } from '../../SvgIcon';
import CustomRadio from '../../shared/CustomRadio';
import { modifyFormField } from '../../shared/Form/Shared';
import { deepClone } from '../../Appearance/utils';

const DEFAULT_DATE_FILTER = {
  page: 1,
  orderDateType: ORDER_DATE_ON,
  orderingKey: ORDER_DATE_ON,
  orderingDirection: 'desc',
};

const DEFAULT_SORTING = {
  page: 1,
  orderingKey: ORDER_DATE_ON,
  orderingDirection: 'desc',
};

const SortOrders = () => {
  const { updateOrderParams, orderParams } = useOrdersContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const getOrderDateType = (type) => {
    const orderTypeArray = type.split('_');
    if (orderTypeArray?.length < 3) return type;
    return orderTypeArray.splice(0, 2).join('_');
  };

  return (
    <div>
      <div
        className={cx('d-flex-c-s special-filter-btn')}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      >
        <SortIcon />
        <span className="pl6">Sort by</span>
      </div>
      <Menu
        id="special-filter-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            width: 220,
            marginTop: 156,
            paddingTop: 8,
            paddingBottom: 8,
          },
        }}
      >
        <div className="radio-filter-wrap orders-sort-filter">
          <Form
            initialValues={{
              ...deepClone(orderParams),
              orders_table_sort: `${orderParams.orderingKey}-${orderParams.orderingDirection}`,
            }}
            onSubmit={noop}
            mutators={{ modifyFormField }}
            render={({ form, values }) => (
              <>
                <RadioGroup
                  name="orderDateType"
                  value={values}
                  className="d-flex flex-row"
                  onChange={({ target: { name, value } }) => {
                    form.mutators.modifyFormField(name, value);
                    updateOrderParams(
                      {
                        ...DEFAULT_DATE_FILTER,
                        orderDateType: value,
                        orderingKey: value,
                      },
                      'date filter'
                    );
                  }}
                >
                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">
                        Order (Created date)
                      </p>
                    }
                    value={ORDER_DATE_ON}
                    checked={
                      values.orderDateType === ORDER_DATE_ON ||
                      values.orderDateType === ORDER_DATE_ON_UTC
                    }
                  />
                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">
                        Order (Modified date)
                      </p>
                    }
                    value={LAST_MODIFIED_DATE}
                    checked={values.orderDateType === LAST_MODIFIED_DATE}
                  />
                </RadioGroup>

                <hr className="hr-line my6 mx8" />

                <RadioGroup
                  name="orders_table_sort"
                  value={values}
                  className="d-flex flex-row"
                  onChange={({ target: { name, value } }) => {
                    form.mutators.modifyFormField(name, value);
                    const [orderingKey, orderingDirection] = value?.split('-');
                    updateOrderParams(
                      {
                        ...DEFAULT_SORTING,
                        orderingKey,
                        orderingDirection,
                      },
                      'sorting'
                    );
                  }}
                >
                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">Date (Newest first)</p>
                    }
                    value={`${getOrderDateType(values.orderDateType)}-desc`}
                    checked={
                      values.orders_table_sort ===
                      `${getOrderDateType(orderParams.orderDateType)}-desc`
                    }
                  />

                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">Date (Oldest first)</p>
                    }
                    value={`${getOrderDateType(values.orderDateType)}-asc`}
                    checked={
                      values.orders_table_sort ===
                      `${getOrderDateType(orderParams.orderDateType)}-asc`
                    }
                  />

                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">Items (High to low)</p>
                    }
                    value="product_count-desc"
                    checked={values.orders_table_sort === 'product_count-desc'}
                  />

                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">Items (Low to high)</p>
                    }
                    value="product_count-asc"
                    checked={values.orders_table_sort === 'product_count-asc'}
                  />

                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">
                        Amount (High to low)
                      </p>
                    }
                    value="total_cost-desc"
                    checked={values.orders_table_sort === 'total_cost-desc'}
                  />

                  <FormControlLabel
                    control={<CustomRadio className="p0-i" />}
                    label={
                      <p className="section-text-14 py8">
                        Amount (Low to high)
                      </p>
                    }
                    value="total_cost-asc"
                    checked={values.orders_table_sort === 'total_cost-asc'}
                  />
                </RadioGroup>
              </>
            )}
          />
        </div>
      </Menu>
    </div>
  );
};

export default SortOrders;
