import React, { useState } from 'react';
import { deepCompare } from '../../Appearance/utils';
import { noop } from '../../utils';
import { truncateString } from '../../utils/string';
import { BounceLoaderSmall } from '../BounceLoader';
import CheckboxField from '../CheckboxField';
import Modal from '../Modal';
import SearchFilter from '../SearchFilter';
import SpinnerButton from '../SpinnerButton';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';

const ManageTagsModal = ({
  open,
  closeModal,
  options,
  selectedTags,
  setSelectedTags,
  setChips,
  chips,
  handleTagsCallback = noop,
  removeCallback = noop,
  loadingElement,
  hasMore,
  loading,
}) => {
  const [appliedTags, setAppliedTags] = useState(selectedTags ?? []);
  const { enqueueSnackbar } = useCustomSnackbar();
  const handleOnSelectTags = (e, option) => {
    if (!e.target.checked) {
      const selectedTemp = selectedTags.filter(
        (tag) => (tag.tag_name || tag.name) !== option.label
      );
      const chipToDelete = chips.find(
        (item) => (item?.tag_name || item?.name) === option.label
      );
      if (chipToDelete) {
        setChips((prev) => prev.filter((chip) => chip !== chipToDelete));

        if (chipToDelete.uuid) removeCallback(chipToDelete.uuid);
      }
      setSelectedTags(selectedTemp);
    } else {
      handleTagsCallback(option.label);
      setSelectedTags((prev) => [
        ...new Set([...prev, { tag_name: option.label }]),
      ]);
    }

    setAppliedTags(selectedTags);

    const isAlreadyPresent = chips.some(
      (item) => item.tag_name === option.label
    );
    if (!isAlreadyPresent) {
      setChips((prev) => [...new Set([...prev, { tag_name: option.label }])]);
    }
  };

  const handleSave = () => {
    enqueueSnackbar('Tags updated successfully!');
    closeModal();
  };

  return (
    <Modal open={open} closeModal={closeModal} className="manage-tags-modal">
      <p className="section-text-4 c-black-1">Manage tags</p>
      {/* <SearchFilter
        placeholder="Search or create new tag"
        onFilter={() => {}}
      /> */}
      <hr className="hr-line mb16 mt12" />
      {options.length > 0 ? (
        <>
          <div className="tags-modal-options-container">
            <div className="tags-modal-grid-wrapper">
              {options.map((option) => {
                const isChecked = selectedTags.some(
                  (el) => (el.tag_name || el.name) === option.label
                );
                return (
                  <div>
                    <CheckboxField
                      checked={isChecked}
                      onChange={(e) => handleOnSelectTags(e, option)}
                      label={
                        <p className="c-black-1 text-2">
                          {truncateString(option.label, 26)}
                        </p>
                      }
                    />
                  </div>
                );
              })}
            </div>
            <div ref={loadingElement} style={{ height: 1 }} />
            {loading && hasMore && (
              <div className="py24 more-table-row">
                <BounceLoaderSmall inline />
              </div>
            )}
          </div>
          <hr className="hr-line mb32 mt12" />

          <div className="text-center">
            <SpinnerButton
              onClick={handleSave}
              disabled={deepCompare(appliedTags, selectedTags)}
            >
              Done
            </SpinnerButton>
          </div>
        </>
      ) : (
        <div className="text-center py20">No tags created</div>
      )}
    </Modal>
  );
};

export default ManageTagsModal;
