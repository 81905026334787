import cx from 'classnames';
import { RightArrow2Icon } from '../SvgIcon';
import { noop } from '../utils';
import useKeyboardListener from '../hooks/utils/useKeyboardListener';

const PreviousPage = ({ currentPage, onCounterClick = noop }) => {
  const disabled = currentPage === 1;

  return (
    <div
      className={cx('pagination-button cur-p previous', {
        disabled,
      })}
      onClick={() => {
        if (!disabled) {
          onCounterClick(currentPage - 1);
        }
      }}
    >
      <span className="d-flex">
        <RightArrow2Icon width={18} height={18} transform="rotate(180)" />
      </span>
      Previous
    </div>
  );
};

const PageCounter = ({
  currentPage,
  frontBoundaryCount = 1,
  endBoundaryCount = 1,
  onCounterClick = noop,
  siblingCount = 4,
  totalPages,
  pagesCountShown = 10,
}) => {
  if (totalPages <= 10 || currentPage <= 5) {
    return (
      <div className="page-counter-container">
        {Array.from(
          {
            length:
              totalPages <= pagesCountShown ? totalPages : pagesCountShown,
          },
          (_, i) => i + 1
        ).map((pageNumber) => (
          <div
            className={cx('page-counter cur-p', {
              'current-page': currentPage === pageNumber,
            })}
            key={pageNumber}
            onClick={() => {
              onCounterClick(pageNumber);
            }}
          >
            {pageNumber}
          </div>
        ))}

        {totalPages > 10 && (
          <>
            <div className="page-counter cur-default ">...</div>

            {Array.from({ length: endBoundaryCount }, (_, i) => totalPages - i)
              .reverse()
              .map((pageNumber) => (
                <div
                  className={cx('page-counter cur-p', {
                    'current-page': currentPage === pageNumber,
                  })}
                  onClick={() => {
                    onCounterClick(pageNumber);
                  }}
                  key={pageNumber}
                >
                  {pageNumber}
                </div>
              ))}
          </>
        )}
      </div>
    );
  }

  if (currentPage >= totalPages - siblingCount - endBoundaryCount - 1) {
    return (
      <div className="page-counter-container">
        {Array.from({ length: frontBoundaryCount }, (_, i) => i + 1).map(
          (pageNumber) => (
            <div
              className={cx('page-counter cur-p', {
                'current-page': currentPage === pageNumber,
                [`extra-size-${String(currentPage).length}`]:
                  currentPage > 99 && currentPage <= 999999,
                'extra-large-size': currentPage > 999999,
              })}
              onClick={() => {
                onCounterClick(pageNumber);
              }}
              key={pageNumber}
            >
              {pageNumber}
            </div>
          )
        )}

        {currentPage - siblingCount > frontBoundaryCount + 1 && (
          <div className="page-counter cur-default ">...</div>
        )}

        {Array.from(
          {
            length:
              totalPages - endBoundaryCount - 1 <= pagesCountShown
                ? totalPages
                : pagesCountShown,
          },
          (_, i) => totalPages - i
        )
          .reverse()
          .map((pageNumber) => (
            <div
              className={cx('page-counter cur-p', {
                'current-page': currentPage === pageNumber,
                [`extra-size-${String(currentPage).length}`]:
                  currentPage > 99 && currentPage <= 999999,
                'extra-large-size': currentPage > 999999,
              })}
              onClick={() => {
                onCounterClick(pageNumber);
              }}
              key={pageNumber}
            >
              {pageNumber}
            </div>
          ))}
      </div>
    );
  }

  return (
    <div className="page-counter-container">
      {Array.from({ length: frontBoundaryCount }, (_, i) => i + 1).map(
        (pageNumber) => (
          <div
            className={cx('page-counter cur-p', {
              'current-page': currentPage === pageNumber,
              [`extra-size-${String(currentPage).length}`]:
                currentPage > 99 && currentPage <= 999999,
              'extra-large-size': currentPage > 999999,
            })}
            onClick={() => {
              onCounterClick(pageNumber);
            }}
            key={pageNumber}
          >
            {pageNumber}
          </div>
        )
      )}

      {currentPage - siblingCount > frontBoundaryCount + 1 && (
        <div className="page-counter cur-default ">...</div>
      )}

      {Array.from({ length: siblingCount * 2 + 1 }, (_, i) =>
        totalPages - currentPage >= siblingCount
          ? currentPage - siblingCount + i
          : totalPages - siblingCount * 2 + i
      ).map((pageNumber) => (
        <div
          className={cx('page-counter cur-p', {
            'current-page': currentPage === pageNumber,
            [`extra-size-${String(currentPage).length}`]:
              currentPage > 99 && currentPage <= 999999,
            'extra-large-size': currentPage > 999999,
          })}
          key={pageNumber}
          onClick={() => {
            onCounterClick(pageNumber);
          }}
        >
          {pageNumber}
        </div>
      ))}

      {currentPage < totalPages - siblingCount - endBoundaryCount - 1 && (
        <>
          <div className="page-counter cur-default ">...</div>

          {Array.from({ length: endBoundaryCount }, (_, i) => totalPages - i)
            .reverse()
            .map((pageNumber) => (
              <div
                className={cx('page-counter cur-p', {
                  'current-page': currentPage === pageNumber,
                  [`extra-size-${String(currentPage).length}`]:
                    currentPage > 99 && currentPage <= 999999,
                  'extra-large-size': currentPage > 999999,
                })}
                onClick={() => {
                  onCounterClick(pageNumber);
                }}
                key={pageNumber}
              >
                {pageNumber}
              </div>
            ))}
        </>
      )}
    </div>
  );
};

const NextPage = ({ currentPage, onCounterClick = noop, totalPages }) => {
  const disabled = currentPage === totalPages;

  return (
    <div
      className={cx('pagination-button cur-p next', {
        disabled,
      })}
      onClick={() => {
        if (!disabled) {
          onCounterClick(currentPage + 1);
        }
      }}
    >
      Next
      <span className="d-flex">
        <RightArrow2Icon width={18} height={18} />
      </span>
    </div>
  );
};

function generateResultString(currentPage, pageSize, totalResults) {
  const startResult = (currentPage - 1) * pageSize + 1;
  const endResult = Math.min(currentPage * pageSize, totalResults);

  return (
    <span>
      Viewing{' '}
      <span className="text-semibold">
        {startResult}-{endResult}
      </span>{' '}
      of {totalResults} results
    </span>
  );
}

/**
 * Pagination component
 * @param {*} props
 * 1. currentPage = required;
 * 2. frontBoundaryCount = optional; defaults to 1. the number of pages that
 *  should be visible before ellipsis
 * 3. onCounterClick = required; action to be performed on number/button click,
 * 4. siblingCount = optional; defaults to 4. the number of pages that should be
 * visible before and after the current page
 * 5. pageSize = optional; defaults to 20
 * 6. totalRecords = required;
 */

const Pagination = (props) => {
  const {
    className = { root: '' },
    currentPage = 1,
    pageSize = 20,
    totalRecords = 1,
    hidePage = false,
    onCounterClick = noop,
  } = props;
  const totalPages = Math.ceil(totalRecords / pageSize);

  useKeyboardListener('ArrowRight', () => {
    if (currentPage < totalPages) {
      onCounterClick(currentPage + 1);
    }
  });
  useKeyboardListener('ArrowLeft', () => {
    if (currentPage > 1) {
      onCounterClick(currentPage - 1);
    }
  });

  return (
    <div className={cx('pagination', className.root)}>
      {!hidePage && (
        <div className="pages-of-container">
          <p className="c-black-3 text-4 cur-default">
            {generateResultString(currentPage, pageSize, totalRecords)}
          </p>
        </div>
      )}

      <div className="page-navigation-container">
        <div>
          <PreviousPage {...props} />
        </div>
        <PageCounter {...props} totalPages={totalPages} />
        <div>
          <NextPage {...props} totalPages={totalPages} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
