import React, { useState } from 'react';

export const usePasswordStrength = () => {
  // using this to maintain state of the last strength, when the field gets cleared
  const [latestClassName, setLatestClassName] = useState('progress-bar-empty');

  const getPasswordStrengthConfig = (password) => {
    const digits = /\d/.test(password);
    const lower = /[a-z]/.test(password);
    const upper = /[A-Z]/.test(password);
    const special = /\W/.test(password);

    // If the user has not typed anything
    if (!password) {
      return { className: latestClassName, value: 0, descElement: null };
    }

    if (digits && lower && upper && special && password.length >= 12) {
      setLatestClassName('progress-bar-success');
      return {
        className: 'progress-bar-success',
        value: 100,
        descElement: <p className="section-text-7 c-green-1">Excellent</p>,
      };
    }
    // If it has lower, upper, length of atleast 10 and ( atleast a digit or special )
    if (lower && upper && password.length >= 10 && (digits || special)) {
      setLatestClassName('progress-bar-primary');
      return {
        className: 'progress-bar-primary',
        value: 80,
        descElement: <p className="section-text-7 c-purple-1">Strong</p>,
      };
    }
    if (password.length >= 8) {
      setLatestClassName('progress-bar-warning');
      return {
        className: 'progress-bar-warning',
        value: 50,
        descElement: <p className="section-text-7 c-orange-1">Average</p>,
      };
    }
    setLatestClassName('progress-bar-danger');
    return {
      className: 'progress-bar-danger',
      value: 25,
      descElement: <p className="section-text-7 c-red-1">Weak</p>,
    };
  };

  return {
    getPasswordStrengthConfig,
  };
};
