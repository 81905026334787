import React, { useState } from 'react';
import cx from 'classnames';
import { useCountry } from '../context/CountryProvider';
import ImageLoader from '../shared/ImageLoader';
import { RightGrayForwardArrow, TickGreenIcon } from '../SvgIcon';
import { VariantColor } from './common';
import ReturnShipmentImagesModal from './components/ReturnShipmentImagesModal';
import { PLACEHOLDER_IMAGE } from '../constants';
import { addCDN } from '../utils/file';
import { RETURN_REFUND_STATUS_MAP } from './constants';
import { LOYALTY_POINT_REFUND_KEY } from '../Account/constants';
import { VARIANT_COLOR_TYPE } from '../Catalogue/constants';
import { isColor } from '../utils';

const ReplacementDetails = ({ returnReplacementData, orderDetails }) => {
  const { formatLocalMoney } = useCountry();
  const [imagesObj, setImagesObj] = useState(null);

  const isRefundCompleted = orderDetails?.payment_details?.order_refunds?.every(
    (each) => each.refund_status === RETURN_REFUND_STATUS_MAP.COMPLETED
  );

  const openReturnShipmentImagesModal = (images = [], index = 0) =>
    setImagesObj({
      slides: images.map((image) => (
        <ImageLoader
          container="img-wrapper"
          src={addCDN(image ?? PLACEHOLDER_IMAGE, 560, 560)}
          alt={image}
          fallbackImage={PLACEHOLDER_IMAGE}
          height={560}
          width={560}
        />
      )),
      thumbnails: images.map((image) => addCDN(image, 60, 60)),
      selectedIndex: index,
    });

  const isReturnedOrReplacementExist =
    returnReplacementData?.order_return_id > 0;

  const isReplacementOrderExist =
    isReturnedOrReplacementExist &&
    returnReplacementData?.replaced_items_data?.replaced_items?.length > 0;

  const returnedDetails = isReplacementOrderExist
    ? returnReplacementData?.replaced_items_data
    : returnReplacementData?.returned_items_data;

  const isExtraAmountToPay = returnReplacementData?.extra_amount_to_pay > 0;

  const isReturnedOrderExist =
    isReturnedOrReplacementExist && returnedDetails?.returned_items?.length > 0;

  const productListTobeReplaced = isReplacementOrderExist
    ? returnedDetails?.replaced_items
    : returnedDetails?.returned_items;

  const returnOrReplacementCount = isReplacementOrderExist
    ? returnedDetails?.replaced_items?.reduce(
        (sum, each) => sum + each.quantity_returned,
        0
      )
    : returnedDetails?.returned_items?.reduce(
        (sum, each) => sum + each.quantity_returned,
        0
      );

  const amountToPay = (value) => {
    if (value > 0) {
      return `${formatLocalMoney(Math.abs(value))}`;
    }
    if (value < 0) {
      return `-${formatLocalMoney(Math.abs(value))}`;
    }
    return formatLocalMoney(Math.abs(value));
  };
  const refundAmountToPay = (value) => {
    // Will always negative or zero
    if (value > 0) {
      return `-${formatLocalMoney(Math.abs(value))}`;
    }
    return formatLocalMoney(Math.abs(value));
  };

  const totalItemsCost = isReturnedOrderExist
    ? refundAmountToPay(returnedDetails?.return_item_selling_price_total)
    : amountToPay(
        returnedDetails?.replacement_item_selling_price_total -
          returnedDetails?.original_item_selling_price_total
      );

  const totalGstApplied = isReturnedOrderExist
    ? refundAmountToPay(returnedDetails?.return_item_total_tax_applied)
    : amountToPay(
        returnedDetails?.replacement_item_total_tax_applied -
          returnedDetails?.original_item_total_tax_applied
      );

  const totalDifference = isExtraAmountToPay
    ? amountToPay(returnReplacementData?.extra_amount_to_pay)
    : refundAmountToPay(returnReplacementData?.refund_amount);

  const toReceiveOrToRefundAmount = isExtraAmountToPay
    ? returnReplacementData?.extra_amount_to_pay
    : returnReplacementData?.refund_amount;

  const serviceChargeAndHandlingFees = (value) => {
    if (value < 0) {
      return `${formatLocalMoney(Math.abs(value))}`;
    }
    if (value > 0) {
      return `-${formatLocalMoney(Math.abs(value))}`;
    }
    return formatLocalMoney(value);
  };

  const refundModeText = () => {
    if (toReceiveOrToRefundAmount === 1) {
      return `Refunded as ${toReceiveOrToRefundAmount} loyalty point`;
    }
    if (toReceiveOrToRefundAmount > 1) {
      return `Refunded as ${toReceiveOrToRefundAmount} loyalty points`;
    }
  };

  return (
    <div className="card mt24">
      <h4 className="section-text-4 p24">
        {isReplacementOrderExist ? 'Replacement details' : 'Return'}
        <span className="section-text-5 c-gray-1 text-ucase ml8">
          ({returnOrReplacementCount} ITEM{returnOrReplacementCount > 1 && 'S'})
        </span>
      </h4>
      <div
        className={cx('table-header-wrapper order-replacement-table mx12', {
          'returned-table': isReturnedOrderExist,
        })}
      >
        <span className="text-medium">Item</span>
        {isReplacementOrderExist && (
          <span className="text-medium">Replace with</span>
        )}
        <span className="text-medium text-right">Difference</span>
      </div>
      <div className="order-replacement-table-data">
        <div className="table-data-container mx12 pb24 mt4">
          {productListTobeReplaced?.map((item) => {
            const priceDiffrence = isReplacementOrderExist
              ? Math.abs(
                  item.replacement_item.sku_selling_price -
                    item.item_getting_replaced.sku_selling_price
                )
              : item.sku_selling_price;
            const replacementItem = item.replacement_item;
            const returnedItem = isReplacementOrderExist
              ? item.item_getting_replaced
              : item;
            const returnQty = item.quantity_returned;
            const totalChargesAfterReplacement = () => {
              if (isReplacementOrderExist) {
                if (
                  item?.replacement_item?.sku_selling_price >
                  item?.item_getting_replaced?.sku_selling_price
                ) {
                  return `${formatLocalMoney(priceDiffrence * returnQty)}`;
                }
                if (
                  item?.replacement_item?.sku_selling_price <
                  item?.item_getting_replaced?.sku_selling_price
                ) {
                  return `-${formatLocalMoney(priceDiffrence * returnQty)}`;
                }
                return formatLocalMoney(priceDiffrence * returnQty);
              }
              return `-${formatLocalMoney(priceDiffrence * returnQty)}`;
            };

            const editableReturnLabelNameMap = returnedItem?.attributes?.map(
              (each) => each?.master_attribute_name?.toUpperCase()
            );
            const editableReturnLabelValueMap = returnedItem?.attributes?.map(
              (each) => each?.value
            );

            const editableReplacementLabelNameMap =
              replacementItem?.attributes?.map((each) =>
                each?.master_attribute_name?.toUpperCase()
              );
            const editableReplacementLabelValueMap =
              replacementItem?.attributes?.map((each) => each?.value);

            return (
              <div className="table-data-container-item py20">
                <div
                  className={cx('table-data-row order-replacement-table', {
                    'returned-table': isReturnedOrderExist,
                  })}
                >
                  <div className="d-flex gap12 item-container">
                    <div>
                      <ImageLoader
                        src={returnedItem.image}
                        alt="product"
                        className="product-image-container"
                        height={40}
                        width={40}
                      />
                    </div>
                    <div>
                      <p className="text-2 c-black-1">{returnedItem.name}</p>
                      <p
                        className={cx('text-4', {
                          mt4: editableReturnLabelNameMap.length > 0,
                        })}
                      >
                        {editableReturnLabelValueMap.length > 0 &&
                          editableReturnLabelValueMap.map((each, index) =>
                            !isColor(each) ? (
                              <span className="mb4">
                                {editableReturnLabelValueMap.length > 1 &&
                                  index !== 0 &&
                                  ' | '}
                                {editableReturnLabelNameMap[index]}: {each}{' '}
                              </span>
                            ) : (
                              <span className="variant-color-wrap">
                                {editableReturnLabelNameMap.length > 1 &&
                                  index !== 0 &&
                                  ' | '}
                                COLOR:&nbsp;
                                <VariantColor
                                  color={editableReturnLabelValueMap[index]}
                                />
                              </span>
                            )
                          )}
                      </p>
                      {returnedItem?.sku && (
                        <p className="mt4 text-4 c-black-3">
                          SKU ID: {returnedItem?.sku}
                        </p>
                      )}
                      <p className="mt4 text-4 c-black-3">Qty: {returnQty}</p>
                      <p className="mt4 text-4 c-black-3">
                        Price: {formatLocalMoney(returnedItem.selling_price)}
                      </p>
                    </div>
                    {isReplacementOrderExist && (
                      <div className="d-flex ml20 align-center">
                        <RightGrayForwardArrow />
                      </div>
                    )}
                  </div>
                  {isReplacementOrderExist && (
                    <div>
                      <p className="text-2 c-black-1">{replacementItem.name}</p>
                      <p
                        className={cx('text-4', {
                          mt4: editableReplacementLabelNameMap.length > 0,
                        })}
                      >
                        {editableReplacementLabelValueMap.length > 0 &&
                          editableReplacementLabelValueMap.map((each, index) =>
                            !isColor(each) ? (
                              <span className="mb4">
                                {editableReplacementLabelValueMap.length > 1 &&
                                  index !== 0 &&
                                  ' | '}
                                {editableReplacementLabelNameMap[index]}: {each}{' '}
                              </span>
                            ) : (
                              <span className="variant-color-wrap">
                                {editableReplacementLabelNameMap.length > 1 &&
                                  index !== 0 &&
                                  ' | '}
                                COLOR:&nbsp;
                                <VariantColor
                                  color={
                                    editableReplacementLabelValueMap[index]
                                  }
                                />
                              </span>
                            )
                          )}
                      </p>
                      {replacementItem?.sku && (
                        <p className="mt4 text-4 c-black-3">
                          SKU ID: {replacementItem?.sku}
                        </p>
                      )}
                      <p className="mt4 text-4 c-black-3">Qty: {returnQty}</p>
                      <p className="mt4 text-4 c-black-3">
                        Price: {formatLocalMoney(replacementItem.selling_price)}
                      </p>
                    </div>
                  )}
                  <div className="d-flex justify-end">
                    <p className="text-2 c-black-1">
                      {totalChargesAfterReplacement()}
                    </p>
                  </div>
                </div>
                <div className="reason-container mt20">
                  <p className="section-text-7">Buyer images & reason</p>
                  <div className="d-flex gap8 pt12 mb8">
                    {item.images.map((imageLink, idx) => (
                      <ImageLoader
                        key={idx + imageLink}
                        src={addCDN(imageLink, 40, 40)}
                        alt="product"
                        className="img-wrapper"
                        onClick={(e) => {
                          e.stopPropagation();
                          openReturnShipmentImagesModal(item.images, idx);
                        }}
                        height={40}
                        width={40}
                      />
                    ))}
                  </div>
                  <p>{item?.reason}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="px24">
        <hr className="hr-line m0 w-100" />
      </div>

      <div className="order-total p24">
        <div>
          <span>Total Item Price Difference</span>
          <span>{totalItemsCost}</span>
        </div>
        {isReturnedOrderExist && (
          <div>
            <span>Return item total discount</span>
            <span>
              {returnedDetails.return_item_total_discount < 0
                ? `-${formatLocalMoney(
                    returnedDetails.return_item_total_discount
                  )}`
                : `${formatLocalMoney(
                    returnedDetails.return_item_total_discount
                  )}`}
            </span>
          </div>
        )}
        <div>
          <span>GST Amount Difference</span>
          <span>{totalGstApplied}</span>
        </div>
        {returnReplacementData?.service_charge_data?.map((each) => (
          <div>
            <span>{each.name}</span>
            <span>
              {serviceChargeAndHandlingFees(
                each?.cost_decreased_from_original_state
              )}
            </span>
          </div>
        ))}
        <div className="divider-dashed my12" />

        <div>
          <span className="d-flex-c-c">
            <span>Total Difference</span>
          </span>
          <span>{totalDifference}</span>
        </div>
        {totalDifference !== 0 && (
          <div className="mt24 grand-total-row">
            <span className="d-flex-c-c">
              <span>{isExtraAmountToPay ? 'To Receive' : 'To Refund'}</span>
              {[
                RETURN_REFUND_STATUS_MAP.INITIATED,
                RETURN_REFUND_STATUS_MAP.COMPLETED,
              ].includes(returnReplacementData?.refund_status) &&
                !(orderDetails?.payment_details?.order_refunds?.length > 0) && (
                  <p className="d-flex align-center ml8 text-italic text-8 c-black-3 text-normal">
                    <span className="d-flex mr6">
                      <TickGreenIcon />
                    </span>
                    {returnReplacementData?.refund_preference !==
                    LOYALTY_POINT_REFUND_KEY
                      ? 'Refunded'
                      : refundModeText()}
                  </p>
                )}
              {orderDetails?.payment_details?.order_refunds?.length > 0 && (
                <p className="d-flex align-center ml8 text-italic text-8 c-black-3 text-normal">
                  <span className="d-flex mr6">
                    <TickGreenIcon />
                  </span>
                  {isRefundCompleted ? 'Refunded' : 'Refund initiated'}
                </p>
              )}
            </span>
            <span>{formatLocalMoney(toReceiveOrToRefundAmount)}</span>
          </div>
        )}
      </div>
      {imagesObj && (
        <ReturnShipmentImagesModal
          open={!!imagesObj}
          closeModal={() => setImagesObj(null)}
          returnShipmentImages={imagesObj}
        />
      )}
    </div>
  );
};

export default ReplacementDetails;
