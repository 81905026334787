import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = ({
  cropConfig,
  src,
  imageStyles = {},
  reactCroperStyles,
  ...rest
}) => {
  const [crop, setCrop] = useState({ ...cropConfig });

  return (
    <ReactCrop
      src={src}
      crop={crop}
      onChange={(newCrop) => setCrop(newCrop)}
      imageStyle={{ height: 'auto', ...imageStyles }}
      className={reactCroperStyles}
      {...rest}
    />
  );
};

export default ImageCropper;
