import React from 'react';
import cx from 'classnames';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  // DialogTitle,
} from '@material-ui/core';

import { noop } from '../utils';
import { CrossIcon } from '../SvgIcon';
import SpinnerButton from './SpinnerButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalFooter = ({ actions, alertText = '' }) => (
  <DialogActions className="dialog-footer">
    {actions.map(
      ({
        disabled,
        loading = false,
        onClick,
        text,
        className,
        showAnimation,
        type,
      }) => (
        <SpinnerButton
          key={text}
          type={type}
          className={cx(className, { disabled })}
          isLoading={loading}
          disabled={disabled}
          onClick={onClick}
          showAnimation={showAnimation}
        >
          {text}
        </SpinnerButton>
      )
    )}
    {alertText}
  </DialogActions>
);

const Modal = (props) => {
  const {
    closeModal,
    children,
    actions,
    className,
    paperClass,
    maxWidth,
    showCloseIcon,
    title,
    alertText,
    hasTopBanner = false,
    topBannerTitle = '',
    topBannerClasses = {},
    disableEscapeKeyDown = false,
    ...restProps
  } = props;

  const dialogProps = {
    ...restProps,
    maxWidth,
    className: cx('dialog-modal', className),
    onClose: closeModal,
    classes: {
      paper: cx('dialog-paper', paperClass, maxWidth),
    },
    BackdropProps: {
      classes: {
        root: 'dialog-backdrop',
      },
    },
  };

  const handleKeydown = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      {...dialogProps}
      disableEnforceFocus
      onKeyDown={handleKeydown}
    >
      {hasTopBanner && (
        <div className="modal-top-banner text-center">
          <span className="section-text-13 c-black-1">{topBannerTitle}</span>
        </div>
      )}

      <DialogContent className="dialog-content show-scrollbar">
        <div>
          {title ? <h4 className="dialog-title-text">{title}</h4> : ''}
          {showCloseIcon && (
            <div
              className={cx(`dialog-close-icon`, {
                [topBannerClasses.crossIconClasses]: hasTopBanner,
              })}
            >
              <CrossIcon onClick={closeModal} />
            </div>
          )}
        </div>
        {children}
      </DialogContent>
      {Boolean(actions.length) && (
        <ModalFooter actions={actions} alertText={alertText} />
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  scroll: 'paper',
  fullWidth: false,
  maxWidth: 'sm', // 'xs', 'md', 'lg', 'xl' (refer _modal.scss for more size options)
  paperClass: '',
  open: false,
  closeModal: noop,
  className: '',
  actions: [],
  showCloseIcon: true,
  alertText: '',
};

export default Modal;
