import React from 'react';

const DEFAULT_INPUT_ID = 'copy-link-input';

export const copyToClipboard = (value, id = DEFAULT_INPUT_ID) => {
  const copyInput = document.getElementById(id);
  copyInput.value = value;
  copyInput.select();
  document.execCommand('copy');
};

const CopyLinkInput = ({ id }) => (
  <input
    type="text"
    id={id}
    style={{
      position: 'fixed',
      zIndex: -1,
      width: '10px',
      display: 'none',
    }}
  />
);

CopyLinkInput.defaultProps = {
  id: DEFAULT_INPUT_ID,
};

export default CopyLinkInput;
