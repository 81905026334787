import React from 'react';
import {
  BXGYBundleGrayIcon,
  ComboBundleGrayIcon,
  MixAndMatchBundleGrayIcon,
} from '../../SvgIcon';
import BuyXGetYBundleForm from './components/BundleForm/BuyXGetYBundleForm';
import ComboBundleForm from './components/BundleForm/ComboBundleForm';
import MixAndMatchBundleForm from './components/BundleForm/MixAndMatchBundleForm';

export const BUNDLE_TYPE_ID = {
  combo: 0,
  mix_and_match: 1,
  x_items_for_y_each: 2,
};

export const BUNDLE_TYPE = {
  [BUNDLE_TYPE_ID.combo]: 'combo',
  [BUNDLE_TYPE_ID.mix_and_match]: 'mix_and_match',
  [BUNDLE_TYPE_ID.x_items_for_y_each]: 'x_items_for_y_each',
};

export const BUNDLE_TYPE_TITLE = {
  [BUNDLE_TYPE[BUNDLE_TYPE_ID.combo]]: 'Combo',
  [BUNDLE_TYPE[BUNDLE_TYPE_ID.mix_and_match]]: 'Mix & Match',
  [BUNDLE_TYPE[BUNDLE_TYPE_ID.x_items_for_y_each]]: 'X items for ₹Y each',
};

export const bundleFormType = {
  [BUNDLE_TYPE_ID.combo]: ComboBundleForm,
  [BUNDLE_TYPE_ID.mix_and_match]: MixAndMatchBundleForm,
  [BUNDLE_TYPE_ID.x_items_for_y_each]: BuyXGetYBundleForm,
};

export const applicableBundleItems = [
  { label: 'Specific products', value: 'specific_products' },
  { label: 'Specific categories', value: 'specific_categories' },
];

export const APPLY_BUNDLE_ITEMS_DEFAULT = {
  SPECIFIC_PRODUCTS: 'specific_products',
  SPECIFIC_CATEGORIES: 'specific_categories',
};

export const BUNDLE_DISCOUNT_TYPE = {
  PERCENT_DISCOUNT: 'percent_discount',
  FLAT_DISCOUNT: 'flat_discount',
};

export const BUNDLE_DISCOUNT_TYPE_IDS = {
  percent_discount: 0,
  flat_discount: 1,
};

// TO be changed according to payload requirements
const ALL = 0;
const COMBO = 1;
const MIX_N_MATCH = 2;
const X_FOR_Y = 3;

export const TOGGLE_FILTER_OPTIONS = [
  {
    value: ALL,
    label: 'All',
    count: 0,
  },
  {
    value: COMBO,
    label: 'Combo',
    count: 0,
  },
  {
    value: MIX_N_MATCH,
    label: 'Mix & Match',
    count: 0,
  },
  {
    value: X_FOR_Y,
    label: 'X items for ₹Y each',
    count: 0,
  },
];

export const BUNDLE_TYPE_ICON = {
  [BUNDLE_TYPE_ID.combo]: <ComboBundleGrayIcon />,
  [BUNDLE_TYPE_ID.mix_and_match]: <MixAndMatchBundleGrayIcon />,
  [BUNDLE_TYPE_ID.x_items_for_y_each]: <BXGYBundleGrayIcon />,
};
