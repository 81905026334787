import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getRequest, postRequest } from '../../utils/http';
import {
  EMAIL_LOGIN_URL,
  STAFF_ACCEPT_INVITE_URL,
  VERIFY_EMAIL_URL,
  VERIFY_STAFF_INVITE,
} from '../../ApiUrls';
import { parseJwt } from '../../utils/string';
import { authUrls, rootUrls } from '../../Urls';
import { useAppContext } from '../../context/AppContext';
import { TrackEvent } from '../../utils/analytics';
import { EVENT_EMAIL_OTP_REQUESTED } from '../../events';
import useQuery from '../../hooks/useQuery';
import { noop } from '../../utils';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';

const useEmailLogin = () => {
  const [isEmailSubmitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);
  const [appSumoLoginDetails, setAppSumoLoginDetails] = useState({
    fromAppSumo: false,
    isPasswordSet: false,
  });
  const [errors, setErrors] = useState([]);
  const [isStaffInviteVerfying, setIsStaffInviteVerifying] = useState(false);

  const { setUser, setToken, setStoreId } = useAppContext();
  const history = useHistory();
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl') || '';

  const { enqueueSnackbar } = useCustomSnackbar();

  const verifyEmailExists = (params, errorCb = noop, cb = noop) => {
    setSubmitting(true);
    getRequest({
      url: VERIFY_EMAIL_URL,
      data: params,
    })
      .then((res) => {
        if (res.status === 'success') {
          setAppSumoLoginDetails({
            fromAppSumo: res.data.from_appsumo,
            isPasswordSet: res.data.password_set,
          });
          cb(res.data.exists);
          if (res.data.from_appsumo) {
            if (res.data.password_set) {
              setShowPassword(true);
            } else {
              setShowCreatePassword(true);
            }
            return;
          }
          if (res.data.exists) {
            if (res.data.password_set) {
              setShowPassword(true);
            } else {
              setIsCreatePassword(true);
              setIsForgotPassword(true);
            }
          } else {
            setShowCreatePassword(true);
          }
        }
      })
      .catch((err) => {
        errorCb(err);
        if (err.status === 429) {
          enqueueSnackbar('Too many requests, please try again', 'error');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const verifyStaffAccount = (uuid, params, cb = noop, errorCb = noop) => {
    setSubmitting(true);
    postRequest({
      url: STAFF_ACCEPT_INVITE_URL(uuid),
      data: params,
    })
      .then((res) => {
        if (res.status === 'success') {
          cb(res);
        }
      })
      .catch((err) => {
        errorCb(err);
        if (err.status === 429) {
          enqueueSnackbar('Too many requests, please try again', 'error');
        }
        if (err.status === 400) {
          enqueueSnackbar('Invite link has already been used', 'error');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const verifyIfStaffInviteValid = (payload, errorCallback = noop) => {
    setIsStaffInviteVerifying(true);
    getRequest({ url: VERIFY_STAFF_INVITE, data: payload })
      .then(noop)
      .catch(() => errorCallback())
      .finally(() => {
        setIsStaffInviteVerifying(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrors([]);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [errors.length]);

  const onPasswordSubmit = (params) => {
    setSubmitting(true);
    const payload = { ...params };
    if (localStorage.getItem('utm_source')) {
      payload.utm_source = localStorage.getItem('utm_source'); // to remove extra double quotes
      payload.utm_medium = localStorage.getItem('utm_medium');
      payload.utm_campaign = localStorage.getItem('utm_campaign');
    }
    postRequest({
      url: EMAIL_LOGIN_URL,
      data: payload,
      headers: {
        mode: 'web',
      },
    })
      .then((res) => {
        if (res.data.access_token) {
          const {
            user_email_verified: userVerified,
            email: jwtEmail,
            username,
            store_ids: storeIds,
            multiple_stores: multipleStores,
          } = parseJwt(res.data.access_token);
          const phoneNumber = username.split('-')[1];
          const hasPhone = !Number.isNaN(phoneNumber);
          setEmail(jwtEmail);
          setToken(res.data.access_token);
          if (Array.isArray(storeIds) && storeIds.length === 0) {
            // if there is no storeid present in jwt token then redirect to register page
            history.push(authUrls.registerPath);
            return;
          }
          if (multipleStores) {
            // if there is more than one storeid present in jwt token then redirect to select store page
            window.location.href = authUrls.selectStorePath;
            return;
          }
          setStoreId(storeIds[0]);
          setUser(
            res.data,
            { email: jwtEmail, mobile: hasPhone ? phoneNumber : undefined },
            () => {
              if (
                userVerified ||
                (appSumoLoginDetails.fromAppSumo &&
                  appSumoLoginDetails.isPasswordSet)
              ) {
                window.location.href = `${rootUrls.homePath}?redirectUrl=${redirectUrl}`;
              } else {
                TrackEvent(EVENT_EMAIL_OTP_REQUESTED);
                setSubmitting(false);
                setShowOtp(true);
              }
              setShowPassword(false);
              setShowCreatePassword(false);
            }
          );
        } else {
          TrackEvent(EVENT_EMAIL_OTP_REQUESTED);
          setSubmitting(false);
          setShowOtp(true);
          setShowPassword(false);
          setShowCreatePassword(false);
        }
      })
      .catch((error) => {
        const { status } = error;
        if (!status) {
          setErrors(['Request timeout. Please try again!']);
        } else
          setErrors([
            error?.data?.data?.error ||
              'Something went wrong. Please try a different login method',
          ]);
        setSubmitting(false);
      });
  };

  const setForgotPassword = (val) => {
    setIsForgotPassword(val);
    setShowPassword(false);
    setShowCreatePassword(false);
  };

  return {
    isEmailSubmitting,
    email,
    errors,
    onPasswordSubmit,
    verifyEmailExists,
    showOtp,
    showPassword,
    showCreatePassword,
    setErrors,
    setEmailLoading: setSubmitting,
    setForgotPassword,
    isForgotPassword,
    isCreatePassword,
    setIsCreatePassword,
    verifyStaffAccount,
    verifyIfStaffInviteValid,
    isStaffInviteVerfying,
  };
};

export default useEmailLogin;
