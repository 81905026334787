import React from 'react';
import cx from 'classnames';
import useHover from '../hooks/useHover';

const AnnouncementsIcon = ({ active = false, ...rest }) => {
  const [hoverRef, isHovered] = useHover();

  const bgColors = {
    active: 'rgba(20, 110, 180, 0.1)',
    hover: '#d9d9d9',
    normal: '#e6e6e6',
  };

  const iconColors = {
    normal: '#4d4d4d',
    active: '#146EB4',
  };

  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={hoverRef}
      {...rest}
    >
      <circle
        cx={20}
        cy={20}
        r={20}
        fill={cx({
          [bgColors.active]: active,
          [bgColors.hover]: isHovered && !active,
          [bgColors.normal]: !active && !isHovered,
        })}
      />
      <path
        d="M28.421 10.024l-17.305 3.358c-.653.117-1.116.747-1.116 1.47v7.65c0 .723.463 1.353 1.116 1.47l1.768.35c.716 1.305 2.063 3.568 3.874 5.41.61.63 1.62.07 1.495-.863-.169-1.236-.127-2.565-.064-3.498l10.232 2.006c.821.163 1.579-.537 1.579-1.47V11.493c0-.933-.758-1.633-1.579-1.47zm-11.958 9.61c0 .256-.19.466-.42.466h-1.727c-.232 0-.421-.21-.421-.467v-1.912c0-.257.19-.467.42-.467h1.727c.232 0 .421.21.421.467v1.912zm4.821 0c0 .256-.19.466-.42.466h-1.727c-.232 0-.421-.21-.421-.467v-1.912c0-.257.19-.467.42-.467h1.727c.232 0 .421.21.421.467v1.912zm4.842 0c0 .256-.19.466-.42.466h-1.727c-.232 0-.421-.21-.421-.467v-1.912c0-.257.19-.467.42-.467h1.706c.232 0 .421.21.421.467v1.912h.021z"
        fill={cx({ [iconColors.active]: active, [iconColors.normal]: !active })}
      />
    </svg>
  );
};

export default AnnouncementsIcon;
