import React from 'react';
import cx from 'classnames';

const StoreMenuDropdownIcon = ({ active = false, ...rest }) => {
  const iconColors = {
    normal: '#4d4d4d',
    active: '#146EB4',
  };

  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_38919_39204)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 18C9.25 17.3096 9.80964 16.75 10.5 16.75H26.5C27.1904 16.75 27.75 17.3096 27.75 18C27.75 18.6904 27.1904 19.25 26.5 19.25H10.5C9.80964 19.25 9.25 18.6904 9.25 18Z"
          fill={cx({
            [iconColors.active]: active,
            [iconColors.normal]: !active,
          })}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 12C9.25 11.3096 9.80964 10.75 10.5 10.75H26.5C27.1904 10.75 27.75 11.3096 27.75 12C27.75 12.6904 27.1904 13.25 26.5 13.25H10.5C9.80964 13.25 9.25 12.6904 9.25 12Z"
          fill={cx({
            [iconColors.active]: active,
            [iconColors.normal]: !active,
          })}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 24C9.25 23.3096 9.80964 22.75 10.5 22.75H26.5C27.1904 22.75 27.75 23.3096 27.75 24C27.75 24.6904 27.1904 25.25 26.5 25.25H10.5C9.80964 25.25 9.25 24.6904 9.25 24Z"
          fill={cx({
            [iconColors.active]: active,
            [iconColors.normal]: !active,
          })}
        />
      </g>
      <defs>
        <clipPath id="clip0_38919_39204">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StoreMenuDropdownIcon;
