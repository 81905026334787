import { useState } from 'react';

import { patchRequest } from '../utils/http';
import { STORE_ONLINE_STATUS_CHANGE } from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';

const useStoreStatusChange = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const changeStoreStatus = (params, storeUuid, successCallback) => {
    setSubmitting(true);
    patchRequest({
      url: STORE_ONLINE_STATUS_CHANGE(storeUuid),
      data: params,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback(!params.is_online);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('Something went wrong.', 'error');
        setSubmitting(false);
      });
  };

  return {
    isSubmitting,
    changeStoreStatus,
  };
};

export default useStoreStatusChange;
