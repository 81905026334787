import React, { useState, forwardRef } from 'react';
import cx from 'classnames';
import useModal from '../../hooks/useModal';
import FormInput from './FormInput';
import ImageCropModal from '../ImageCropModal';
import { noop } from '../../utils';
import { b64toBlob } from '../../utils/file';
import { ACCEPTED_IMAGE_TYPES } from '../../constants';

const FormImageUpload = forwardRef(
  (
    {
      form,
      width,
      height,
      hideInput = false,
      children = null,
      onUploadSuccess = noop,
      onUploadFailure = noop,
      hasCropper = true,
      upload = noop,
      clearFile = false,
      buttonLabel,
      customCrop = {},
      imageStyles = {},
      reactCroperStyles = {},
      inputStyles = '',
      accept = ACCEPTED_IMAGE_TYPES,
      checkForAcceptedType = false,
      ...restProps
    },
    ref
  ) => {
    const { name } = restProps;
    const [image, setImage] = useState(null);
    const [value, setValue] = useState(null);
    const { isOpen, openModal, closeModal } = useModal();

    const handleImageUpload = async (result, e) => {
      setImage(result);
      const { cdnURL } = await upload(result);
      onUploadSuccess(cdnURL);
    };

    const handleSelectImage = (e) => {
      if (e.target.files.length > 0) {
        if (
          checkForAcceptedType &&
          !accept.split(', ').includes(e.target.files[0].type)
        ) {
          setValue('');
          onUploadFailure();
          return;
        }
        const reader = new FileReader();
        if (!hasCropper) {
          handleImageUpload(e.target.files[0]);
          return;
        }
        reader.addEventListener('load', () => setImage(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        openModal();
      }
    };

    const onCloseModal = () => {
      setValue('');
      closeModal();
    };

    return (
      <>
        <FormInput
          {...restProps}
          data-buttonlabel={buttonLabel}
          ref={ref}
          value={value}
          type="file"
          accept={accept}
          className={cx({ 'd-none': hideInput }, inputStyles)}
          onChange={handleSelectImage}
        />
        {children}
        {hasCropper && (
          <ImageCropModal
            image={image}
            open={isOpen}
            onUploadSuccess={onUploadSuccess}
            closeModal={closeModal}
            handleClose={onCloseModal}
            form={form}
            modalTitle="Crop image"
            modalBtnText="Select"
            name={`${name}Url`}
            width={width}
            height={height}
            customCrop={customCrop}
            imageStyles={imageStyles}
            reactCroperStyles={reactCroperStyles}
          />
        )}
      </>
    );
  }
);

FormImageUpload.displayName = 'FormImageUpload';

FormImageUpload.defaultProps = {
  buttonLabel: 'Upload Image',
};

export default FormImageUpload;
