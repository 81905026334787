import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import CreatableSelect from 'react-select/creatable';
import { debounce } from 'throttle-debounce';
import cx from 'classnames';
import { noop } from '../utils';
import { Validation } from './Form';
import Chip from './Chip';
import { isNotEmptyOrNull } from './Form/Shared';
import { useAppContext } from '../context/AppContext';

const ENTER_KEYCODE = 13;
const DEBOUNCING_DELAY = 500;

const CreatableSelectComponent = ({
  chips = [],
  setChips,
  dropdownOptions,
  setDropdownOptions,
  renderOptions,
  handleTagsCallback = noop,
  removeCallback = noop,
  handleRemoveNewProduct = noop,
  createEnabled = true,
  setSearch,
  isStandAloneComponent,
}) => {
  const { isOwner, isAdmin } = useAppContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState(null);

  const addNewTag = () => {
    if (isNotEmptyOrNull({ value }) && createEnabled) {
      setDropdownOptions([{ tag_name: value }, ...dropdownOptions]);
    }
    setMenuOpen(false);
  };

  const setPlaceholder = () => {
    if ((isOwner || isAdmin) && createEnabled) {
      return 'Search or create tags';
    }

    return 'Search tags';
  };

  const debounceSearch = useCallback(
    debounce(DEBOUNCING_DELAY, (text, setSearchArg) => setSearchArg(text)),
    []
  );

  return (
    <Form
      onSubmit={noop}
      render={() => (
        <>
          <CreatableSelect
            required
            className="mb0 creatable-select tags-select"
            classNamePrefix="custom-select"
            options={renderOptions()}
            menuIsOpen={menuOpen}
            createOptionPosition="first"
            formatCreateLabel={(val) =>
              createEnabled ? (
                <div className="d-flex-c-s cur-p text-8 py2">
                  <span
                    className="c-black-1 text-8 new-tag-chip-container"
                    onChange={() => setValue(val)}
                  >
                    {val}
                  </span>
                  <button
                    type="button"
                    className="btn-no-default text-medium c-purple-1"
                    onClick={() => {
                      addNewTag();
                    }}
                  >
                    Add
                  </button>
                </div>
              ) : (
                <div className="d-flex-c-s cur-p text-8 py2">
                  <p className="text-2 c-black-3">No results found.</p>
                </div>
              )
            }
            isValidNewOption={(val) =>
              createEnabled && val && (isOwner || isAdmin)
            }
            onFocus={() => {
              if (dropdownOptions.length) {
                setMenuOpen(true);
              }
            }}
            onBlur={() => {
              setMenuOpen(false);
              setValue(null);
            }}
            onKeyDown={(e) => {
              if (
                e.keyCode === ENTER_KEYCODE &&
                (isOwner || isAdmin) &&
                createEnabled
              ) {
                addNewTag();
              } else {
                setMenuOpen(true);
              }
            }}
            value={value}
            blurInputOnSelect
            onInputChange={(val) => {
              debounceSearch(val, setSearch);
              setValue(val);
            }}
            components={{
              DropdownIndicator: null,
            }}
            placeholder={setPlaceholder()}
            onChange={(option) => {
              const isAlreadyPresent = chips.some(
                (item) => item.tag_name === option.label
              );
              if (!isAlreadyPresent) {
                handleTagsCallback(option.label);
                if (option?.label?.length <= 100)
                  setChips((prev) => [
                    ...new Set([...prev, { tag_name: option.label }]),
                  ]);
              }
              setMenuOpen(false);
            }}
            validate={Validation.required()}
            noOptionsMessage={() => (
              <div className="d-flex-c-c my24">
                <p className="text-2 c-black-3">No results found.</p>
              </div>
            )}
          />
          {chips.length ? (
            <div
              className={cx('mt12 chips-row', {
                'small-row': isStandAloneComponent,
              })}
            >
              {chips.map((chip, i) => (
                <Chip
                  key={i}
                  isRemovable
                  onClick={() => {
                    setChips((prev) => [
                      ...new Set(
                        prev.filter((p) => p.tag_name !== chip.tag_name)
                      ),
                    ]);
                    if (chip.uuid) {
                      removeCallback(chip?.uuid);
                    }
                    handleRemoveNewProduct(chip.tag_name);
                  }}
                >
                  {chip.tag_name}
                </Chip>
              ))}
            </div>
          ) : null}
        </>
      )}
    />
  );
};

export default CreatableSelectComponent;
