import React, { useState } from 'react';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';
import {
  RemoveFilledIcon,
  CrossRoundIcon,
  OrderTipsThumbnailCloseIcon,
  OrderTipsThumbnail,
  TickIcon,
  VariantImageCrossIcon,
  YoutubeLightIcon,
} from '../SvgIcon';
import { SuccessBadge } from './Badge';
import { HTML_TAGS_REGEX } from '../Catalogue/constants';
import { noop } from '../utils';
import YouTubePlayer from './YouTubePlayer';
import { DUKAAN_ORDER_TIPS_VIDEO_LINK } from '../Subscription/constants';
import SpinnerButton from './SpinnerButton';
import { useAppContext } from '../context/AppContext';
import ImageLoader from './ImageLoader';
import { THUMBNAIL_TYPE } from '../utils/constant';
import { getCdnUrl, getExtensionFromFileName } from '../utils/file';
import FileUploadManager from './FileUploadManager';
import {
  OTHER_FILE_EXTENSIONS,
  TEXT_FILE_EXTENSIONS,
  VIDEO_FILE_EXTENSION,
} from '../Account/Sections/CategoryAttributes/constants';
import { LightTooltip } from './View/LightTooltip';

export const ExternalLink = ({
  href,
  children,
  text,
  className = '',
  isNew = false,
  preTextChildren = false,
  externalLinkStyle = true,
  disabled = false,
  textStyle = '',
  onClick = noop,
}) => {
  const pointerEventsStyle = disabled ? 'none' : '';
  return (
    <a
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      className={cx(
        {
          'external-link': externalLinkStyle,
          'external-link-disabled': disabled,
        },
        className
      )}
      style={{ pointerEvents: pointerEventsStyle }}
      onClick={onClick}
    >
      <div className={cx(`d-flex align-center pos-rel ${textStyle}`)}>
        {preTextChildren && children}
        {text}
        {!preTextChildren && children}
        {isNew && <SuccessBadge text="NEW" className="ml8" />}
      </div>
    </a>
  );
};

export const UploadedImage = (props) => {
  const {
    imgUrl,
    onRemoveClick = noop,
    onSelectedBadgeClick = noop,
    uploadedCssClass,
    className = '',
    isSelected = false,
    showRemoveIcon = true,
    showSelectedNumber = false,
    isMultiple = false,
    imgIdx,
    overlayMsg = '',
    updateAttributeImage = false,
    name = '',
    productAttributeFileType = false,
    height = 120,
    width = 120,
    breathingClass = '',
  } = props;

  let cssClass = 'uploaded-image';

  if (uploadedCssClass) {
    cssClass = uploadedCssClass;
  }
  const classes = cx(cssClass, className, {
    'uploaded-product-image': updateAttributeImage,
  });

  const ProductFileType = getExtensionFromFileName(name);

  return (
    <div className={classes}>
      {[
        ...TEXT_FILE_EXTENSIONS,
        ...VIDEO_FILE_EXTENSION,
        ...OTHER_FILE_EXTENSIONS,
      ].includes(ProductFileType) ? (
        <FileUploadManager
          name={name}
          productAttributeFileType={productAttributeFileType}
        />
      ) : (
        imgUrl && (
          <ImageLoader
            height={height}
            width={width}
            src={imgUrl}
            alt=""
            breathingClass={breathingClass}
          />
        )
      )}
      {isSelected && (
        <div
          className="uploaded-img-overlay-selected"
          onClick={onSelectedBadgeClick}
        >
          {showSelectedNumber ? (
            <div className="c-white">{imgIdx}</div>
          ) : (
            <TickIcon width={12} height={12} />
          )}
        </div>
      )}
      {showRemoveIcon && (
        <>
          <div className="uploaded-img-overlay overlay-msg">{overlayMsg}</div>
          <div className="uploaded-img-overlay img-upload-overlay-br" />
          <VariantImageCrossIcon
            className="remove-uploaded-image"
            onClick={onRemoveClick}
          />
        </>
      )}
    </div>
  );
};

export const UploadedImage2 = ({
  imgUrl,
  onRemoveClick,
  dragging = false,
  imagesLength = 0,
  thumbnailType = '',
  isAttribute = false,
  name = '',
  height = 120,
  width = 120,
  breathingClass = '',
}) => (
  <div>
    <ImageLoader
      src={imgUrl}
      height={height}
      width={width}
      name={name}
      isAttribute={isAttribute}
      breathingClass={breathingClass}
    />
    {thumbnailType === THUMBNAIL_TYPE.YOUTUBE && (
      <div className="video-icon">
        <YoutubeLightIcon width={24} height={24} />
      </div>
    )}
    <div
      className={cx('uploaded-img-overlay', {
        'cur-grab': !dragging,
        'cur-grabbing': dragging,
        'cur-auto': imagesLength === 1,
      })}
    >
      {!isAttribute && imagesLength > 1 && (
        <p
          className={cx(
            'w-100 absolute-center text-medium text-white text-center',
            { 'section-text-13': isAttribute }
          )}
        >
          Drag to sort
        </p>
      )}
      <RemoveFilledIcon
        className="remove-uploaded-image"
        onClick={onRemoveClick}
      />
    </div>
  </div>
);

export const UploadedImageSmall = (props) => {
  const { imgUrl, onRemoveClick } = props;

  return (
    <div className="uploaded-image-small">
      <img src={getCdnUrl(imgUrl)} alt="uploaded-img" />
      <div className="uploaded-img-overlay">
        <CrossRoundIcon
          className="remove-uploaded-image"
          onClick={onRemoveClick}
        />
      </div>
    </div>
  );
};

export const ClickPrevent = ({
  children,
  stopPreventDefault = true,
  ...rest
}) => {
  const handleClick = (e) => {
    if (stopPreventDefault) {
      e.preventDefault();
    }
    e.stopPropagation();
  };

  return (
    <div onClick={handleClick} {...rest}>
      {children}
    </div>
  );
};

export const CustomTooltip = (props) => {
  const { title, children, placement = 'top', ...restProps } = props;
  return (
    <LightTooltip title={title} placement={placement} {...restProps}>
      {children}
    </LightTooltip>
  );
};

export const ProgressButton = ({
  label,
  className,
  isLoading = false,
  ...rest
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const onClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      rest.onClick();
    }, 1000);
  };

  return (
    <div className="progress-button-wrap">
      <div
        className={cx('progress-button-overlay', {
          active: isClicked,
        })}
      />
      <SpinnerButton
        isLoading={isLoading}
        type="button"
        className={className}
        {...rest}
        onClick={onClick}
      >
        {label}
      </SpinnerButton>
    </div>
  );
};

export const Spacer = () => <div style={{ flex: 1 }} />;

export const getCharecterCount = (text = '') => {
  const textWithoutTag = text.replace(HTML_TAGS_REGEX, '');
  const textSpaceHtmlCodeCount = text.match(/&nbsp;/)?.length || 0;
  const textCount = textWithoutTag.length + textSpaceHtmlCodeCount;
  return textCount;
};

export const YoutubeThumbnail = ({
  thumbnail = <OrderTipsThumbnail />,
  youtubeUrl = DUKAAN_ORDER_TIPS_VIDEO_LINK,
  playerOpen = false,
  closePlayer = false,
  isOnTrial = false,
  setPlayerOpen = noop,
  setPlayerClose = noop,
  setShowYoutubeThumbnail = noop,
  setTimeoutId = noop,
}) => (
  <div
    className={cx('youtube-thumbnail-container', {
      'animate-slide-right': closePlayer,
      'subscription-footer': isOnTrial,
    })}
  >
    <div className="youtube-thumbnail" onClick={() => setPlayerOpen(true)}>
      {thumbnail}
    </div>
    <div
      className="youtube-thumbnail-close"
      onClick={() => {
        setPlayerClose(true);
        const timeoutId = setTimeout(() => {
          setShowYoutubeThumbnail(false);
        }, 800);
        setTimeoutId(timeoutId);
      }}
    >
      <OrderTipsThumbnailCloseIcon />
    </div>
    <YouTubePlayer
      url={youtubeUrl}
      open={playerOpen}
      handleClose={() => {
        setPlayerOpen(false);
      }}
    />
  </div>
);
