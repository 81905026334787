import moment from 'moment';
import { formatServerDate } from '../utils/date';

export default {
  authToken: '',
  refreshToken: '',
  userDetails: {
    mobile: '',
  },
  business: {},
  storeServiceCharges: [],
  stats: {
    orders: 0,
    total_sales: 0,
    product_views: 0,
    store_views: 0,
  },
  pending_orders: 0,
  statsDuration: 0,
  storeTable: {},
  isFashion: false,
  country: 77,
  total_lifetime_orders: 0,
  fetchBusinessDetails: null,
  canAvailFreeDukaanDelivery: false,
  stores: [],
  switchToStore: null,
  isPrimaryStore: false,
  isBusinessLoading: false,
  isVendor: false,
  canCreateVendor: false,
  isHelloBarActive: false,
  isEnterprise: false,
  isAppSumoStore: false,
  isOldAppSumoStore: false,
  productCount: 0,
  categoryCount: 0,
  activeWarehouseCount: 0,
  email: null,
  sellerMobile: null,
  activePaymentProvider: {},
  paymentKeys: [],
  roleName: '',
  storesListPageNumber: 1,
};
