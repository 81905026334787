import React, { useState } from 'react';
import cx from 'classnames';
import {
  GENERATE_A4_RECEIPT,
  GENERATE_ORDER_RECEIPT,
  GENERATE_THERMAL_RECEIPT,
} from '../ApiUrls';
import { useAppContext } from '../context/AppContext';
import { EVENT_ORDER_SHARE_RECEIPT } from '../events';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useFileDownload from '../hooks/useFileDownload';
import { TrackEvent } from '../utils/analytics';
import { getRequest } from '../utils/http';
import Modal from './Modal';

const DownloadOrderReceiptModal = ({ uuid, open, closeModal }) => {
  const [recLoading, setRecLoading] = useState(false);

  const { business } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { downloadFile } = useFileDownload();

  const handleDownload = (fileType) => {
    if (!recLoading) {
      TrackEvent(EVENT_ORDER_SHARE_RECEIPT, business);
      setRecLoading(true);
      enqueueSnackbar('Your receipt download will begin shortly!');
      let url = null;
      if (fileType === 'a4') url = GENERATE_A4_RECEIPT;
      if (fileType === 'thermal') url = GENERATE_THERMAL_RECEIPT;
      if (fileType === 'classic') url = GENERATE_ORDER_RECEIPT;
      getRequest({ url: url(uuid) })
        .then((res) => {
          let fileLink = null;
          if (fileType === 'a4') {
            fileLink = res.data?.a4_receipt_link;
          }
          if (fileType === 'thermal') {
            fileLink = res.data?.thermal_receipt_link;
          }
          if (fileType === 'classic') {
            fileLink = res.data?.receipt_link;
          }
          downloadFile(fileLink, true);
        })
        .catch((e) => {
          if (e?.data?.data?.error) {
            enqueueSnackbar(e.data.data.error, 'error');
          }
        })
        .finally(() => {
          setRecLoading(false);
        });
      closeModal();
    }
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      className="download-report-modal order-report-modal"
    >
      <div className="download-report-modal-content">
        <h2 className="section-text-4">Choose receipt type</h2>
        <div className="download-via mt8">
          <div
            className="tile order-receipt-type-icon mr24"
            onClick={() => {
              handleDownload('a4');
            }}
          >
            <img src="/images/a4-pdf.svg" alt="A4" />
            <span className="section-text-5">A4</span>
          </div>
          <div
            className={cx('tile order-receipt-type-icon')}
            onClick={() => {
              handleDownload('thermal');
            }}
          >
            <img src="/images/print.svg" alt="Thermal" />
            <span className="section-text-5">Thermal</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadOrderReceiptModal;
