import { createContext, useContext, useState, useEffect } from 'react';
import { ONBOARDING_API_URL } from '../ApiUrls';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useLocalStorage from '../hooks/useLocalStorage';
import { noop } from '../utils';
import { getRequest, patchRequest } from '../utils/http';
import { useAppContext } from './AppContext';

const StoreProfileContext = createContext({
  loading: false,
  steps: [],
  stepsCompleted: 0,
  stepsRemaining: 0,
  onboardingData: false,
  fetchStoreProfileSteps: noop,
  updateOnboardingData: noop,
});

export const useStoreProfile = () => useContext(StoreProfileContext);

const StoreProfileProvider = ({ children }) => {
  const { business } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const [steps, setSteps] = useState([]);
  const [stepsCompleted, setStepsCompleted] = useState(0);
  const [onboardingData, setOnboardingData] = useState({});
  const [stepsRemaining, setStepsRemaining] = useLocalStorage(
    'store-profile-steps-remaining',
    0
  );

  const fetchStoreProfileSteps = () => {
    if (!business.uuid) return;
    setLoading(true);
    getRequest({ url: ONBOARDING_API_URL(business.uuid) })
      .then((res) => {
        const stepsData = res?.data?.store_profile_progressive_steps ?? [];
        setSteps(stepsData);
        setOnboardingData(res?.data);
        setStepsCompleted(
          stepsData.filter((step) => step.value === true).length
        );
        const newStepsRemaining = stepsData.filter(
          (step) => step.value === false
        ).length;
        if (stepsRemaining === 1 && newStepsRemaining === 0) {
          enqueueSnackbar(
            'Congratulations! Your store profile is now complete'
          );
        }
        setStepsRemaining(newStepsRemaining);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const updateOnboardingData = (payload, successCallback = noop) => {
    if (!business.uuid) return;
    patchRequest({ url: ONBOARDING_API_URL(business.uuid), data: payload })
      .then((res) => {
        successCallback(res);
      })
      .catch(console.log);
  };

  const contextValue = {
    loading,
    steps,
    stepsCompleted,
    stepsRemaining,
    onboardingData,
    fetchStoreProfileSteps,
    updateOnboardingData,
  };

  return (
    <StoreProfileContext.Provider value={contextValue}>
      {children}
    </StoreProfileContext.Provider>
  );
};

export default StoreProfileProvider;
