import React from 'react';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { WarningTranslucentIcon } from '../../SvgIcon';
import useEnterPressAction from '../../hooks/useEnterPressAction';

const ChangePaymentMethodDialog = ({
  open,
  closeModal,
  onContinueClick,
  title,
  buttonLabel = 'Continue',
  caption,
  content,
  isLoading = false,
  showSkip = false,
}) => {
  useEnterPressAction(open, onContinueClick);

  return (
    <Modal open={open} closeModal={closeModal}>
      <div className="start-xs">
        {/* <WarningTranslucentIcon className="mb12" /> */}
        <h2 className="section-text-4 mb6">{title}</h2>
        {content || <p className="c-black-3 text-2 mb28">{caption}</p>}

        <div className="d-flexi align-center">
          {showSkip && (
            <SpinnerButton
              type="button"
              className="btn-outline-primary-4 mr12"
              onClick={closeModal}
            >
              No keep existing
            </SpinnerButton>
          )}
          <SpinnerButton
            type="button"
            className="btn-primary-4"
            onClick={onContinueClick}
            isLoading={isLoading}
          >
            {buttonLabel}
          </SpinnerButton>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePaymentMethodDialog;
