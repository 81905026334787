import { useState } from 'react';
import { vinculumReturnOrderUrl } from '../ApiUrls';
import { noop } from '../utils';
import { postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';

const useVinculum = () => {
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();

  const approveVinculumReturn = (orderUuid = '', successCB = noop) => {
    setSubmitting(true);

    return postRequest({
      url: vinculumReturnOrderUrl(orderUuid),
    })
      .then((res) => {
        successCB(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('Something went wrong.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  return {
    submitting,
    approveVinculumReturn,
  };
};

export default useVinculum;
