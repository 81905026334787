import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Field } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { Validation, FormGroup } from '../../shared/Form';
import { DownArrowIcon } from '../../SvgIcon';
import { noop } from '../../utils';
import InputDisabledTooltip from './Variants/InputDisabledTooltip';

const QuantityInput = ({ unit, onClick = noop, disabled = false }) => {
  const [focused, setFocused] = useState(false);
  const [hasError, setHasError] = useState(false);
  const classes = cx('form-quantity-input-wrap', {
    focused,
    'has-error': hasError,
  });

  const useStyles = makeStyles(() =>
    createStyles({
      tooltipPlacementBottom: {
        marginLeft: '200px !important',
      },
    })
  );

  const styles = useStyles();

  return (
    <FormGroup
      labelText="Product Unit"
      required
      size="lg"
      showRequired
      className="form-quantity-input-group"
    >
      <div className={classes}>
        <Field name="base_qty" validate={Validation.required()}>
          {({ input, meta }) => {
            if ((meta.error && meta.touched) || meta.submitError) {
              setHasError(true);
            } else setHasError(false);
            const handleChange = ({ value }) => {
              input.onChange({ target: { value, name: 'base_qty' } });
            };
            return (
              <InputDisabledTooltip
                title="Since you have added variants, you can only edit this field in the variants section below."
                show={disabled}
                tooltipProps={{
                  classes: {
                    tooltipPlacementBottom: styles.tooltipPlacementBottom,
                  },
                }}
              >
                <NumberFormat
                  {...input}
                  required
                  disabled={disabled}
                  autoComplete="off"
                  decimalScale={0}
                  maxLength={5}
                  onFocus={() => setFocused(true)}
                  onBlur={(e) => {
                    setFocused(false);
                    input.onBlur(e);
                  }}
                  onValueChange={handleChange}
                  isAllowed={(val) => {
                    const { floatValue } = val;
                    return floatValue === undefined || floatValue > 0;
                  }}
                />
              </InputDisabledTooltip>
            );
          }}
        </Field>
        <div
          role="button"
          tabIndex="-1"
          className={cx('unit-input', {
            disabled,
          })}
          onClick={disabled ? noop : onClick}
        >
          <div className="d-flex-c-s cur-p">
            <div className="unit-label">{unit}</div>
            <DownArrowIcon className="mr12" />
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default QuantityInput;
