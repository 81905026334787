import { HEALTH_XP_STORE, HEALTH_XP_TESTING_STORE } from '../../../constants';

export const LOADING_TEXT = 'loading';

export const makeInventoryMandatoryForStore = [
  HEALTH_XP_STORE,
  HEALTH_XP_TESTING_STORE,
];

export const commonProductPath = '/catalogue/products';
export const DIGITAL_PRODUCT_KEY = {
  LINKS_VALIDITY_DAYS: 'digital_products_links_validity_days',
  MAX_NO_OF_DOWNLOADS: 'digital_products_max_number_downloads',
  FILE: 'digital_products_file',
};
