import React, { useState } from 'react';
import cx from 'classnames';
import { Menu } from '@material-ui/core';
import { noop } from '../../utils';
import { ArrowIcon, UpArrowCloseIcon } from '../../SvgIcon';
import { MenuItems } from '../../shared/Menu';

const BulkReorderProductAction = ({ handleBulkAction = noop }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const onMenuClick = (moveTop = true) => {
    setMenuAnchorEl(null);
    handleBulkAction(moveTop);
  };

  const menuItems = [
    {
      labelText: 'Move to top',
      onClick: onMenuClick,
    },
    {
      labelText: 'Move to bottom',
      onClick: onMenuClick.bind(null, false),
    },
  ];

  return (
    <div>
      <div
        className={cx('d-flex-c-s special-filter-btn mr12')}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      >
        <span className="pr4">Bulk actions</span>
        {menuAnchorEl ? <UpArrowCloseIcon /> : <ArrowIcon />}
      </div>
      <Menu
        id="special-filter-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            width: 180,
            marginTop: 44,
            paddingTop: 10,
            paddingBottom: 10,
          },
        }}
      >
        <MenuItems
          menuItems={menuItems}
          onCloseMenu={() => setMenuAnchorEl(null)}
        />
      </Menu>
    </div>
  );
};

export default BulkReorderProductAction;
