import React from 'react';
import MarketingPlainStripe from '../Manage/Customers/components/MarketingPlainStripe';

const InfoStripV2 = ({
  title,
  btnText,
  onClick,
  onClose,
  className,
  variant = 'red',
}) => {
  return (
    <div>
      <MarketingPlainStripe
        showIcon={false}
        title={title}
        btnText={btnText}
        parentClass={`mb24 info-v2-stripe ${variant} ${className}`}
        onClose={onClose}
        onClick={onClick}
      />
    </div>
  );
};

export default InfoStripV2;
