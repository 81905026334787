import React from 'react';
import CustomAccordion from '../../shared/CustomAccordian';
import SpinnerButton from '../../shared/SpinnerButton';
import { noop } from '../../utils';
import FaqFormInput from './FaqFormInput';

const FaqCard = ({
  children,
  faqCardTitle = '',
  isExpanding = false,
  btnText = '',
  btnClick = noop,
  btnClassName = 'btn-outline-4',
  showIcon = false,
  showBtn = false,
  sectionHeader,
  values,
}) => {
  const isSectionHeaderString = typeof sectionHeader === 'string';
  return isExpanding ? (
    <CustomAccordion
      id="faqs"
      values={values}
      heading={
        <div>
          <h3 className="section-text-4">{sectionHeader}</h3>
        </div>
      }
      defaultExpanded
    >
      {children}
    </CustomAccordion>
  ) : (
    <section>
      <h3 className="section-text-5">{sectionHeader}</h3>
      <p className="section-text-14 c-black-3 mt2">{faqCardTitle}</p>
      {values?.fields?.map((name, index) => (
        <div className="questions-container mt24">
          <FaqFormInput name={name} index={index} />
        </div>
      ))}
      {children}
      {showBtn && (
        <div className="d-flex-c-c mt32">
          <SpinnerButton
            className={btnClassName}
            onClick={btnClick}
            showAnimation
          >
            {showIcon}
            {btnText}
          </SpinnerButton>
        </div>
      )}
    </section>
  );
};

export default FaqCard;
