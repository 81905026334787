import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import useModal from '../../hooks/useModal';
import useScrollingRef from '../../hooks/useScrollingRef';
import ManageTagsModal from './ManageTagsModal';
import useTags from '../../hooks/useTags';
import CreatableSelectComponent from '../CreatableSelectComponent';
import { noop } from '../../utils';
import { EditThinIcon } from '../../SvgIcon';

const Tags = ({
  title = 'Tags',
  isStandAloneComponent = true,
  type,
  objectId,
  setTagIds = noop,
  onTagSelect,
  createEnabled = true,
}) => {
  const {
    loadingTags,
    hasMoreTags,
    tagsPageNumber,
    setTagsPageNumber,
    getStoreTags,
    tags,
    appliedTags,
    createStoreTag,
    applyStoreTags,
    fetchAppliedTags,
    removeStoreTag,
    search,
    setSearch,
  } = useTags({ type });

  const { isOpen, openModal, closeModal } = useModal();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [chips, setChips] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const loadingElement = useScrollingRef(
    loadingTags,
    hasMoreTags,
    setTagsPageNumber
  );

  const createOption = (label = '') => ({
    label,
    value: label.replace(/\W/g, ''),
  });

  const createTag = (label) => {
    const payload = {
      tag_for: type,
      name: label,
    };

    createStoreTag(
      payload,
      objectId,
      (data) => {
        // create tag callback
        setTagIds([{ tag_id: data.id }], 'add');
      },
      (data) => {
        // after store tag applied callback
        setChips((prev) =>
          prev.map((chip) =>
            chip.tag_name === label ? { ...chip, uuid: data.uuid } : chip
          )
        );
      }
    );
  };

  const applyTag = (label) => {
    const selectedTag = tags.find((tag) => tag.name === label);

    applyStoreTags(selectedTag, objectId, (data) => {
      // after store tag applied callback
      setChips(() => [...chips, { tag_name: label, uuid: data.uuid }]);
    });
  };

  const handleTags = (label) => {
    if (createEnabled || objectId) {
      const tagsNameMap = tags.map((each) => each.name);
      if (tagsNameMap.includes(label) && objectId) {
        applyTag(label);
      } else if (createEnabled && !tagsNameMap.includes(label)) {
        const trimmedLabel = label.trim();
        createTag(trimmedLabel);
      }
    }
  };

  const removeTag = (tagUuid) => {
    removeStoreTag(tagUuid);
  };

  const handleRemoveNewProduct = (tagName) => {
    const tagsToBeRemoved = dropdownOptions.filter(
      (item) => item.name === tagName
    );
    setTagIds(
      tagsToBeRemoved.map((el) => ({ tag_id: el.id })),
      'remove'
    );
  };

  const renderOptions = () => {
    const options = [
      ...dropdownOptions.map((tag) => createOption(tag.tag_name || tag.name)),
    ];
    return options;
  };

  useEffect(() => {
    if (appliedTags?.length) {
      const tempAppliedTags = appliedTags.map((item) => ({
        tag_name: item.tag_name,
        uuid: item.uuid,
      }));
      setChips(tempAppliedTags);
      setSelectedTags(tempAppliedTags || []);
    }
  }, [appliedTags]);

  useEffect(() => {
    setSelectedTags(chips || []);
    const tagsTemp = dropdownOptions.filter((option) =>
      chips.find((chip) => chip.tag_name === option.name)
    );
    const tagIds = tagsTemp.map((tag) => ({ tag_id: tag.id }));
    setTagIds(tagIds, 'add');
  }, [chips]);

  useEffect(() => {
    getStoreTags();
  }, [tagsPageNumber, search]);

  useEffect(() => {
    setDropdownOptions(tags);
    setTagsOptions(tags.map((tag) => createOption(tag.name || tag.tag_name)));
  }, [tags]);

  useEffect(() => {
    if (objectId) {
      fetchAppliedTags(objectId);
    }
  }, [objectId]);

  useEffect(() => {
    if (onTagSelect) {
      const tagNames = selectedTags.map((each) => each.tag_name);
      const selectedTagIds = tags.filter((tag) => tagNames.includes(tag.name));
      onTagSelect(selectedTagIds);
    }
  }, [selectedTags]);

  return (
    <div className={cx({ 'card p24': isStandAloneComponent })}>
      <div className={cx('d-flex space-between', { mb8: isStandAloneComponent })}>
        <p
          className={cx('section-text-5', {
            'text-8': !isStandAloneComponent,
            'text-semibold': isStandAloneComponent,
          })}
        >
          {title}
        </p>
        <div
          className="edit-customer-button shift-top cur-p"
          onClick={openModal}
        >
          <EditThinIcon />
        </div>
      </div>
      <CreatableSelectComponent
        dropdownOptions={dropdownOptions}
        setDropdownOptions={setDropdownOptions}
        renderOptions={renderOptions}
        chips={chips}
        setChips={setChips}
        handleTagsCallback={handleTags}
        removeCallback={removeTag}
        handleRemoveNewProduct={handleRemoveNewProduct}
        createEnabled={createEnabled}
        setSearch={setSearch}
        isStandAloneComponent={isStandAloneComponent}
      />
      {isOpen && (
        <ManageTagsModal
          open={isOpen}
          closeModal={closeModal}
          options={tagsOptions}
          chips={chips}
          setChips={setChips}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          handleTagsCallback={handleTags}
          removeCallback={removeTag}
          loadingElement={loadingElement}
          hasMore={hasMoreTags}
          loading={loadingTags}
        />
      )}
    </div>
  );
};

export default Tags;
