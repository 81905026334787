import React from 'react';
import Lottie from 'react-lottie';

import * as NoOrderJson from './lottie/EmptyStates/NoOrder.json';
import * as NoCouponJson from './lottie/EmptyStates/NoCoupons.json';
import * as NoCustomerJson from './lottie/EmptyStates/NoCustomer.json';
import * as NoResultJson from './lottie/NoResult.json';
import * as NoCategoryJson from './lottie/EmptyStates/NoCategory.json';
import * as NoProductsJson from './lottie/EmptyStates/NoProducts.json';
import * as NoPageJson from './lottie/EmptyStates/NoPage.json';
import * as StonksJson from './lottie/Stonks.json';
import * as NoBlogJson from './lottie/EmptyStates/NoBlog.json';
import * as NoAbandonedOrder from './lottie/EmptyStates/NoAbandonedOrder.json';
import * as NoMediaJson from './lottie/EmptyStates/NoMedia.json';
import * as NoInstalledPluginsJson from './lottie/EmptyStates/NoInstalledPlugins.json';
import * as NoWareHouseJson from './lottie/EmptyStates/NoWareHouse.json';
import * as NoStaffJson from './lottie/EmptyStates/NoStaff.json';
import * as NoExtraCharge from './lottie/EmptyStates/NoExtraCharges.json';
import * as NoReturns from './lottie/EmptyStates/NoReturns.json';
import * as DukaanLoaderJson from './lottie/DukaanLoader.json';

const ImageLoaders = ({
  json,
  isStopped,
  width = 324,
  height = 192,
  className = '',
}) => {
  const JsonMap = {
    no_coupon: NoCouponJson,
    no_order: NoOrderJson,
    no_abandon_order: NoAbandonedOrder,
    no_customer: NoCustomerJson,
    no_result: NoResultJson,
    no_category: NoCategoryJson,
    no_products: NoProductsJson,
    no_page: NoPageJson,
    stonks: StonksJson,
    no_blog: NoBlogJson,
    no_media: NoMediaJson,
    no_plugins: NoInstalledPluginsJson,
    no_warehouse: NoWareHouseJson,
    no_staff: NoStaffJson,
    no_extra_charge: NoExtraCharge,
    no_return: NoReturns,
    dukaan_loader: DukaanLoaderJson,
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: JsonMap[json].default,
  };

  return (
    <div className={className}>
      <div>
        <Lottie
          options={defaultOptions}
          isStopped={isStopped}
          height={height}
          width={width}
        />
      </div>
    </div>
  );
};

export default ImageLoaders;
