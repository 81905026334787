import React from 'react';
import cx from 'classnames';
import { Radio } from '@material-ui/core';
import { RadioChecked, RadioUnChecked } from '../SvgIcon';

const CustomRadio = (props) => {
  const { className, disabled, ...restProps } = props;
  const classes = cx('custom-radio', className);

  return (
    <Radio
      disableRipple
      className={classes}
      checkedIcon={<RadioChecked className="custom-radio-icon" />}
      icon={
        <RadioUnChecked className={cx('custom-radio-icon', { disabled })} />
      }
      disabled={disabled}
      {...restProps}
    />
  );
};

export default CustomRadio;
