import React, { useState } from 'react';
import cx from 'classnames';
import { partnerImgStyle } from './MultipleDeliveryPartners';
import { capitaliseFirst, toTitleCase } from '../utils/string';
import useFileDownload from '../hooks/useFileDownload';
import { isHyperlocalSelected } from '../context/DeliveryProvider';
import FullScreenLoader from '../shared/FullscreenLoader';
import SpinnerButton from '../shared/SpinnerButton';
import { noop } from '../utils';
import useModal from '../hooks/useModal';
import DownloadInvoiceLabelModal from '../shared/DownloadInvoiceLabelModal';
import { ShipRocketPoweredIcon } from '../SvgIcon';
import ImageLoader from '../shared/ImageLoader';

const OrderDeliveryDetails = ({
  showReturnTrackBtn,
  returnShipmentId,
  shipmentDetails,
  alwaysCardExpanded = true,
  hideDownloadInvoiceAndLabel = false,
  trackOrder = noop,
  trackBtnText = '',
  isCancelledShipment = false,
}) => {
  const { downloadFile, downloading } = useFileDownload();
  const [printType, setPrintType] = useState(null);
  const {
    isOpen: isInvoiceLabelModalOpen,
    openModal: openInvoiceLabelModal,
    closeModal: closeInvoiceLabelModal,
  } = useModal();

  const formatCarrierName = (name = '') => {
    if (name && typeof name === 'string') {
      return toTitleCase(name.replace('_', ' '));
    }
    return '';
  };

  const isHyperlocal = isHyperlocalSelected({
    carrier_service: shipmentDetails?.carrier_service_json,
  });

  const hasMagin =
    !isHyperlocal &&
    !hideDownloadInvoiceAndLabel &&
    shipmentDetails?.warehouse?.id;

  const handleReceiptModal = (documentPrintType) => {
    openInvoiceLabelModal();
    setPrintType(documentPrintType);
  };

  return (
    <div
      className={cx('customer-details order-details-card border-none w-100', {
        'mt-32': !alwaysCardExpanded,
      })}
    >
      <hr
        className={cx('hr-line mx0 w-100 pos-rel top-8', {
          'd-none': alwaysCardExpanded,
        })}
      />
      {shipmentDetails?.carrier_json?.logo && (
        <div className="d-flex align-center mt20">
          {shipmentDetails?.rates_dict?.account?.signup_channel_id === 3 && (
            <ShipRocketPoweredIcon height={20} className="mr12" />
          )}
          {shipmentDetails?.carrier_json?.logo && (
            <ImageLoader
              alt="partner logo"
              src={shipmentDetails?.carrier_json?.logo}
              height={20}
              style={{ height: 20 }}
            />
          )}
          {isCancelledShipment && (
            <div className="c-red-1 section-text-5 ml12">(Cancelled)</div>
          )}
        </div>
      )}
      <div className="customer-details-container">
        <div className="row">
          <div className="col-md-6">
            <p>Courier Name</p>
            <span>
              {shipmentDetails?.carrier_json?.name
                ? `${shipmentDetails?.carrier_json?.name || ''} ${
                    shipmentDetails?.carrier_service_json?.name || ''
                  }`
                : formatCarrierName(shipmentDetails?.carrier)}
            </span>
          </div>
          <div className="col-md-6">
            <p>Tracking ID</p>
            <span
              className={cx('text-medium break-word ', {
                'anchor-1 underline': shipmentDetails.tracking_link,
              })}
              onClick={() => {
                if (shipmentDetails.tracking_link) {
                  window.open(shipmentDetails.tracking_link, '_blank');
                }
              }}
            >
              {shipmentDetails.awb || '-'}
            </span>
          </div>
        </div>
        {!shipmentDetails?.is_external_shipment && (
          <div className="row mb0-i">
            {!isHyperlocal && !hideDownloadInvoiceAndLabel && (
              <div className={cx('col-md-6', { mb16: hasMagin })}>
                <p>Shipping Label</p>
                <div
                  className={cx({
                    'text-2 c-gray-2': !shipmentDetails?.label_link,
                    'anchor-1 text-medium underline text-2 cur-p':
                      shipmentDetails?.label_link,
                  })}
                  onClick={() => {
                    if (shipmentDetails?.label_link) {
                      handleReceiptModal('label');
                    }
                  }}
                >
                  {shipmentDetails?.label_link ? (
                    'Download'
                  ) : (
                    <div className="d-flex align-center">
                      <div className="spinner-border mr8" role="status" />
                      generating...
                    </div>
                  )}
                </div>
              </div>
            )}

            {downloading > 0 && <FullScreenLoader message="Downloading" />}

            {!hideDownloadInvoiceAndLabel && (
              <div className={cx('col-md-6', { mb16: hasMagin })}>
                <p>Order Invoice</p>
                <div
                  className={cx({
                    'text-2 c-gray-2': !shipmentDetails?.invoice_link,
                    'anchor-1 text-medium underline text-2 cur-p':
                      shipmentDetails?.invoice_link,
                  })}
                  onClick={() => {
                    if (shipmentDetails?.invoice_link) {
                      handleReceiptModal('invoice');
                    }
                  }}
                >
                  {shipmentDetails?.invoice_link ? (
                    'Download'
                  ) : (
                    <div className="d-flex align-center">
                      <div className="spinner-border mr8" role="status" />
                      generating...
                    </div>
                  )}
                </div>
              </div>
            )}
            {shipmentDetails?.warehouse?.id && (
              <div className="col-md-6">
                <p>Warehouse</p>
                <span>{capitaliseFirst(shipmentDetails?.warehouse?.name)}</span>
              </div>
            )}
          </div>
        )}
        {trackBtnText && (
          <SpinnerButton
            showAnimation
            type="button"
            className={cx('btn edit-order-btn py8 mt4', {
              'btn-outline-primary': !isCancelledShipment,
              'btn-primary': isCancelledShipment,
            })}
            onClick={trackOrder}
          >
            <div className="btn-txt">{trackBtnText}</div>
          </SpinnerButton>
        )}
      </div>
      {isInvoiceLabelModalOpen && (
        <DownloadInvoiceLabelModal
          shipmentUuid={
            showReturnTrackBtn ? returnShipmentId : shipmentDetails?.uuid
          }
          open={isInvoiceLabelModalOpen}
          closeModal={closeInvoiceLabelModal}
          printType={printType}
        />
      )}
    </div>
  );
};

export default OrderDeliveryDetails;
