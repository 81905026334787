import { useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Field } from 'react-final-form';
import AttributeValueSelect from '../../../Account/Sections/CategoryAttributes/AttributeValueSelect';
import useUploadMedia from '../../../hooks/useUploadMedia';
import {
  getImagesFromArray,
  getOptionsFromArray,
} from '../../../Manage/Delivery/constants';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import CustomDatePicker from '../../../shared/DatePicker';
import { FormCustomSelect, FormInput, Validation } from '../../../shared/Form';
import FormImageUpload from '../../../shared/Form/FormImageUpload';
import TimePicker from '../../../shared/TimePicker/TimePicker';
import { BlackClockIcon } from '../../../SvgIcon';
import { noop } from '../../../utils';
import { formatServerDate, formatZone } from '../../../utils/date';
import { getFileNameFromUrl } from '../../../utils/file';
import { deepClone } from '../../../Appearance/utils';
import MultiSelectAttribute from '../../../Account/Sections/CategoryAttributes/MultiSelectAttribute';
import SingleSelectRadioAttribute from '../../../Account/Sections/CategoryAttributes/SingleSelectRadioAttribute';
import FormFileUploadField from '../../../shared/Form/FormFileUploadField';
import { isNotEmptyOrNull } from '../../../shared/Form/Shared';
import FormFieldTextarea from '../../../shared/Form/FormFieldTextarea';
import { ATTRIBUTE_FIELD_TYPE_MAP } from '../../../Account/Sections/CategoryAttributes/constants';
import SelectedProductsPickerField from '../../../Themes/ThemeBuilder/SelectedProductsPickerField';
import SelectedCategoriesPickerField from '../../../Themes/ThemeBuilder/SelectedCategoriesPickerField';

const DatePickerComponent = ({ form, name, values, required }) => {
  const nameList = name.includes('.') ? name.split('.') : [name];
  const value =
    nameList.length > 1
      ? nameList.reduce((acc, each) => acc?.[each] || undefined, values)
      : values[nameList[0]];
  return (
    <Field
      name={name}
      validate={required ? Validation.required() : noop}
      render={() => (
        <CustomDatePicker
          className="p0"
          defaultDate={value}
          onPopperClose={(date) =>
            form.mutators.modifyFormField(name, formatServerDate(date))
          }
          placeholder="Select date"
          readOnly
        />
      )}
    />
  );
};

const TimePickerComponent = ({ form, name, values, required }) => {
  // returns a time string from a date T string
  const getTimeFromDate = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).format('hh:mm A');
  };

  // changes values.start_date passed as setTime
  const handleStartTime = (timeString) => {
    const currentDate = moment();
    let dateString = '';

    if (values[name]) {
      dateString = `${values[name].split('T')[0]}T${timeString.slice(0, 5)}:00`;
    } else {
      dateString = `${currentDate.format('YYYY-MM-DD')}T${timeString.slice(
        0,
        5
      )}:00`;
    }
    form.mutators.modifyFormField(name, formatZone(dateString));
  };

  return (
    <Field
      name={name}
      validate={required ? Validation.required() : noop}
      render={() => (
        <TimePicker
          isDisabled={false}
          open={false}
          prependText={<BlackClockIcon />}
          setTime={handleStartTime}
          timeString={getTimeFromDate(values[name])}
          formValues={values}
          form={form}
          value={values[name]}
        />
      )}
    />
  );
};

export const FIELD_TYPE = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  EMAIL: 'email',
  DATE_PICKER: 'date_picker',
  TIME_PICKER: 'time_picker',
  FILE_PICKER: 'file_picker',
  DROPDOWN: 'dropdown',
  RADIO: 'str',
  CREATABLE_DROPDOWN: 'createable_dropdown',
  LIST: 'list',
  PRODUCT_PICKER: 'list',
  CATEGORY_PICKER: 'list',
};

const useFormFieldHelper = (setIsImageUploading = noop) => {
  const { upload, uploading } = useUploadMedia({});
  const { enqueueSnackbar } = useCustomSnackbar();

  const getField = (
    field,
    form,
    index,
    values,
    formProps,
    isFileUploading = false,
    attributeExist = false,
    isCreateableDropdown = false,
    isLargeFileText = false
  ) => {
    const {
      field_name: name,
      field_label: label = '',
      field_type: fieldType,
      field_meta: fieldMeta,
      required,
      is_active: isActive,
      submitting,
      metafield_id: metafieldId,
      uuid,
      allow_multiple_files: allowMultipleFiles = false,
      metafield_type_id: metafieldTypeId,
    } = field;

    const isRequiredField = {
      ...(required
        ? {
            required: true,
            showRequired: true,
          }
        : {}),
    };

    if (metafieldTypeId === ATTRIBUTE_FIELD_TYPE_MAP.PRODUCT_PICKER) {
      return (
        <SelectedProductsPickerField
          form={form}
          prefixName={name}
          isAttribute
          label={label}
          data={values?.metafields?.[`attribute__${metafieldId}`]}
        />
      );
    }

    if (metafieldTypeId === ATTRIBUTE_FIELD_TYPE_MAP.CATEGORY_PICKER) {
      return (
        <SelectedCategoriesPickerField
          form={form}
          label={label}
          prefixName={name}
          isAttribute
          data={values?.metafields?.[`attribute__${metafieldId}`]}
        />
      );
    }

    switch (fieldType) {
      case FIELD_TYPE.TEXT:
        if (isActive)
          return (
            <FormInput
              name={uuid}
              labelText={label || name}
              placeholder={`Enter ${label || name}`}
              maxLength={attributeExist ? 2500 : null}
              className={cx('mb0')}
              {...isRequiredField}
              {...(required ? { validate: Validation.required() } : {})}
            />
          );
        return <></>;
      case FIELD_TYPE.TEXTAREA:
        if (isActive)
          return (
            <FormFieldTextarea
              name={uuid}
              form={form}
              values={values}
              label={label}
              metafieldId={metafieldId}
            />
          );
        return <></>;

      case FIELD_TYPE.EMAIL:
        if (isActive)
          return (
            <FormInput
              name={uuid}
              labelText={label || name}
              placeholder={`Enter ${label || name}`}
              className="mb0"
              {...isRequiredField}
              validate={
                required
                  ? Validation.validateEmail()
                  : Validation.emptyValidateEmail()
              }
            />
          );
        return <></>;

      case FIELD_TYPE.FILE_PICKER:
        if (isActive) {
          const nameList = name?.split('.');
          // using reduce function to get the value of the field from form values, if it exists
          // comma separated list
          const fileUrl = nameList.reduce(
            (acc, each) => acc?.[each],
            deepClone(values)
          );

          const possibleValuesSet = new Set([
            ...(fieldMeta?.options ?? []), // possible values
            ...(fileUrl || '') // already selected files
              ?.split(',')
              ?.filter((item) => isNotEmptyOrNull({ value: item })),
          ]);

          if (isFileUploading)
            return (
              <div
                className={cx('additional-information-file-field', {
                  'attribute-file-text': isLargeFileText,
                })}
              >
                <div className="form-group mb0 form-input-container">
                  <label className={cx('field-label', { required })}>
                    {label || name}
                    {required && <span className="c-red-1"> *</span>}
                  </label>
                  <FormFileUploadField
                    metafieldId={metafieldId}
                    formProps={formProps}
                    options={getImagesFromArray(Array.from(possibleValuesSet))}
                    onUploadSuccess={(url) => {
                      form.mutators.modifyFormField(uuid, url);
                    }}
                    fileUrl={fileUrl}
                    isFileAttribute
                    allowMultipleFiles={allowMultipleFiles}
                    name={uuid}
                    {...isRequiredField}
                    {...(required ? { validate: Validation.required() } : {})}
                  />
                  {values[uuid] && (
                    <p className="image-file-name">
                      {getFileNameFromUrl(values[uuid])}
                    </p>
                  )}
                </div>
              </div>
            );

          return (
            <div className="additional-information-file-field">
              <div className="form-group mb0 form-input-container">
                <label className={cx('field-label', { required })}>
                  {label || name}
                  {required && <span className="c-red-1"> *</span>}
                </label>
                <FormImageUpload
                  buttonLabel={!values[uuid] ? 'Upload Image' : 'Change Image'}
                  checkForAcceptedType
                  form={form}
                  hasCropper={false}
                  inputStyles="mb0"
                  name={uuid}
                  onUploadSuccess={(url, fileSize, fileName) => {
                    form.mutators.modifyFormField(uuid, url);
                    form.mutators.modifyFormField(
                      `file-size-${index}`,
                      fileSize
                    );
                    form.mutators.modifyFormField(
                      `${uuid}-original__file__name`,
                      fileName
                    );
                  }}
                  onUploadFailure={() => {
                    enqueueSnackbar(
                      `Please upload image in supported format. image/png, image/jpg, image/jpeg`,
                      'error'
                    );
                    form.mutators.modifyFormField(uuid, values?.[uuid] || null);
                    form.mutators.modifyFormField(
                      `file-size-${index}`,
                      values?.[`file-size-${index}`] || null
                    );
                  }}
                  upload={upload}
                  {...isRequiredField}
                  {...(required ? { validate: Validation.required() } : {})}
                />
                {values[`${uuid}-original__file__name`] && (
                  <p className="image-file-name">
                    {values[`${uuid}-original__file__name`]}
                  </p>
                )}
              </div>
            </div>
          );
        }

        return <></>;

      case FIELD_TYPE.DROPDOWN:
        if (isActive)
          return (
            <FormCustomSelect
              className="fill-available mb0 additional-information-dropdown-field"
              labelText={label || name}
              menuPosition="fixed"
              name={uuid}
              options={getOptionsFromArray(fieldMeta.options)}
              placeholder={`Select ${label || name}`}
              {...isRequiredField}
              {...(required ? { validate: Validation.required() } : {})}
            />
          );
        return <></>;

      case FIELD_TYPE.RADIO:
        if (isActive)
          return (
            <SingleSelectRadioAttribute
              field={field}
              values={values}
              form={form}
            />
          );
        return <></>;

      case FIELD_TYPE.LIST:
        if (isActive) {
          return (
            <MultiSelectAttribute field={field} values={values} form={form} />
          );
        }
        return <></>;

      case FIELD_TYPE.CREATABLE_DROPDOWN: {
        const nameList = name.split('.');
        const fieldValue = nameList.reduce(
          (acc, each) => acc?.[each],
          deepClone(values)
        );
        if (isActive)
          return (
            <AttributeValueSelect
              form={form}
              name={uuid}
              label={label}
              options={fieldMeta?.options}
              handleAddOption={field?.handleAddOption}
              submitting={submitting}
              isCreateableDropdown={isCreateableDropdown}
              defaultValue={
                fieldValue
                  ? { label: fieldValue, value: fieldValue }
                  : undefined
              }
            />
          );
        return <></>;
      }

      case FIELD_TYPE.DATE_PICKER:
        if (isActive)
          return (
            <div className="form-group mb0 p0">
              <label className={cx('field-label', { required })}>
                {label || name}
                {required && <span className="c-red-1"> *</span>}
              </label>
              <DatePickerComponent
                form={form}
                values={values}
                name={uuid}
                required={required}
              />
            </div>
          );
        return <></>;

      case FIELD_TYPE.TIME_PICKER:
        if (isActive)
          return (
            <div className="form-group mb0 form-input-container additional-information-time-picker-field">
              <label className={cx('field-label', { required })}>
                {label || name}
                {required && <span className="c-red-1"> *</span>}
              </label>
              <TimePickerComponent
                form={form}
                values={values}
                name={uuid}
                required={required}
              />
            </div>
          );
        return <></>;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    setIsImageUploading(uploading);
  }, [setIsImageUploading, uploading]);

  return { getField };
};

export default useFormFieldHelper;
