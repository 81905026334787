import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import '../../scss/pages/not_found.scss';

const NotFound = () => (
  <div className="not-found-page wv-center">
    <div className="logo-wrap">
      <Logo />
    </div>
    <div className="not-found-wrap">
      <img src="/images/404.svg" alt="404" />
      <h4 className="section-text-1 mb12">Whoops, looks like 404!</h4>
      <p className="text-info mb48">
        Yes, this is definitely 404.
        <br />
        We couldn’t find the page you were looking for, sorry.
      </p>
      <div>
        <Link to="/" className="btn-primary-0">
          Back to homepage
        </Link>
      </div>
    </div>
  </div>
);

export default NotFound;
