import React from 'react';
import cx from 'classnames';
import { FormCustomSelect, FormNumberInput, Validation } from '../shared/Form';

import { MenuItems } from '../shared/Menu';
import { FILTER_LIST_SIZE } from '../Catalogue/constants';
import { AMOUNT_AND_QUANTITY_FILTER_OPTIONS } from './constants';
import { noop } from '../utils';
import { minValidation } from '../shared/Form/Validation';
import { useCountry } from '../context/CountryProvider';

const AmountAndQuantityMenuItems = ({ values, initialValues }) => {
  const dropdownOptions = Object.values(AMOUNT_AND_QUANTITY_FILTER_OPTIONS);
  const { currencySymbol, isInternational = false } = useCountry();

  const removeFormatting = (value) => {
    if (typeof value === 'number') return value;
    return value ? Number.parseFloat(value.replaceAll(',', ''), 10) : null;
  };

  const handleKeyDown = (e) => {
    // prevent menu from closing on tab key press
    if (e.keyCode === 9) e.stopPropagation();
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <p className="section-text-5 c-black-1 mb12 mt16">Amount</p>
      <div className="dropdown-options-grid">
        <FormCustomSelect
          name="order_amount_action"
          size="sm"
          labelText="Condition"
          placeholder="Select"
          options={dropdownOptions}
          className="mb8"
        />
        {values.order_amount_action &&
          (values.order_amount_action !== 'is_between' ? (
            <FormNumberInput
              size="sm"
              name="order_amount_range_from"
              labelText={`Amount (${currencySymbol})`}
              placeholder="Enter amount"
              className="mb8"
              thousandSeparator
              thousandsGroupStyle={isInternational ? 'thousand' : 'lakh'}
              initialValue={initialValues.order_amount_range_from}
              validate={Validation.numberRequired()}
            />
          ) : (
            <div className="d-flex align-end gap6">
              <FormNumberInput
                size="sm"
                name="order_amount_range_from"
                labelText={`Range (${currencySymbol})`}
                placeholder="From"
                className="mb8"
                thousandSeparator
                thousandsGroupStyle={isInternational ? 'thousand' : 'lakh'}
                initialValue={initialValues.order_amount_range_from}
                validate={Validation.numberRequired()}
              />
              <div className="mb16 d-flex-c-c">
                <span>-</span>
              </div>

              <FormNumberInput
                size="sm"
                name="order_amount_range_to"
                placeholder="To"
                className="mb8"
                thousandSeparator
                thousandsGroupStyle={isInternational ? 'thousand' : 'lakh'}
                initialValue={initialValues.order_amount_range_to}
                validate={(val) =>
                  Validation.numberRequired(val) &&
                  minValidation(
                    removeFormatting(values.order_amount_range_from) + 1
                  )(removeFormatting(val))
                }
              />
            </div>
          ))}
      </div>
      <hr className="hr-line" />
      <p className="section-text-5 c-black-1 mb12 mt16">Quantity</p>
      <div className="dropdown-options-grid">
        <FormCustomSelect
          name="order_item_action"
          size="sm"
          labelText="Condition"
          placeholder="Select"
          options={dropdownOptions}
          className="mb8"
        />
        {values.order_item_action &&
          (values.order_item_action !== 'is_between' ? (
            <FormNumberInput
              size="sm"
              name="order_item_range_from"
              labelText="Quantity"
              placeholder="Enter quantity"
              className="mb8"
              thousandSeparator
              thousandsGroupStyle={isInternational ? 'thousand' : 'lakh'}
              initialValue={initialValues.order_item_range_from}
              validate={Validation.numberRequired()}
            />
          ) : (
            <div className="d-flex align-end gap6">
              <FormNumberInput
                size="sm"
                name="order_item_range_from"
                labelText="Range (Qty)"
                placeholder="From"
                className="mb8"
                thousandSeparator
                thousandsGroupStyle={isInternational ? 'thousand' : 'lakh'}
                initialValue={initialValues.order_item_range_from}
                validate={Validation.numberRequired()}
              />
              <div className="mb16 d-flex-c-c">
                <span>-</span>
              </div>

              <FormNumberInput
                size="sm"
                name="order_item_range_to"
                placeholder="To"
                className="mb8"
                thousandSeparator
                thousandsGroupStyle={isInternational ? 'thousand' : 'lakh'}
                initialValue={initialValues.order_item_range_to}
                validate={(val) =>
                  Validation.numberRequired(val) &&
                  minValidation(
                    removeFormatting(values.order_item_range_from) + 1
                  )(removeFormatting(val))
                }
              />
            </div>
          ))}
      </div>
      <hr className="hr-line" />
    </div>
  );
};

const GroupedFilterMenuItems = ({
  menuItems = {},
  openTagsModal = noop,
  sourcesCount = 0,
  orderTagsCount = 0,
  totalTagsCount = 0,
  values = {},
  initialValues = {},
}) => {
  const {
    paymentMenuItems,
    channelMenuItems,
    otherMenuItems,
    tagsMenuItems,
    sourceMenuItems,
  } = menuItems ?? {};

  return (
    <div className="order-filter-menu menu-item-lh px16 show-scrollbar">
      <div
        className={cx({
          'mb8 pb50': !(sourcesCount > 0 && orderTagsCount > 0),
        })}
      >
        <p className="section-text-5 c-black-1 mb6 mt16">Payment</p>
        <div className="row">
          <MenuItems menuItems={paymentMenuItems} />
        </div>

        <hr className="hr-line" />

        <AmountAndQuantityMenuItems
          initialValues={initialValues}
          values={values}
        />

        <p className="section-text-5 c-black-1 mb4 mt16">Channel</p>
        <div className="row">
          <MenuItems menuItems={channelMenuItems} />
        </div>

        <hr className="hr-line" />
        <p className="section-text-5 c-black-1 mb4 mt16">Others</p>
        <div className="row">
          <MenuItems menuItems={otherMenuItems} />
        </div>

        {orderTagsCount > 0 && (
          <div>
            <hr className="hr-line" />
            <p className="section-text-5 c-black-1 mb4 mt16">Tags</p>
            <div className="row">
              <MenuItems menuItems={tagsMenuItems} />
            </div>
            {orderTagsCount > FILTER_LIST_SIZE && (
              <div
                className="section-text-7 c-black-3 underline mt8 pb6 ml26 cur-p"
                onClick={() => openTagsModal()}
              >
                +{totalTagsCount - FILTER_LIST_SIZE} more
              </div>
            )}
          </div>
        )}

        {sourcesCount > 0 && (
          <div>
            <hr className="hr-line" />
            <p className="section-text-5 c-black-1 mb4 mt16">Source</p>
            <div className="row mb60">
              <MenuItems menuItems={sourceMenuItems} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupedFilterMenuItems;
