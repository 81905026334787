import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import Modal from '../shared/Modal';
import { SHIPPED_STATUS, TIME_SLOTS } from './constants';
import { TrackEvent } from '../utils/analytics';
import { EVENT_ORDER_ACCEPT_DELIVERY_TIMING } from '../events';
import { useAppContext } from '../context/AppContext';
import SpinnerButton from '../shared/SpinnerButton';
import useEnterPressAction from '../hooks/useEnterPressAction';

const TimeSlot = ({ slot, value, onClick }) => {
  const classes = cx('time-slot', {
    selected: slot.value === value,
  });

  return (
    <div
      className={classes}
      onClick={() => onClick(slot.value)}
      role="button"
      tabIndex={-1}
    >
      {slot.label}
    </div>
  );
};

const AcceptOrderModal = (props) => {
  const {
    open,
    handleClose,
    disabled,
    handleConfirm,
    actionText = 'Ship order',
  } = props;
  const [selectedTimeValue, setSelectedTimeValue] = useState(null);
  const [displayErrorMessage, showDisplayErrorMessage] = useState(false);
  const { business } = useAppContext();
  const handleSelection = (index) => {
    setSelectedTimeValue(index);
    showDisplayErrorMessage(false);
  };
  const handleAcceptOrder = () => {
    TrackEvent(EVENT_ORDER_ACCEPT_DELIVERY_TIMING, business);
    if (selectedTimeValue === null) {
      showDisplayErrorMessage(true);
    } else {
      handleConfirm({
        status: SHIPPED_STATUS,
        time_to_deliver: selectedTimeValue,
      });
    }
  };

  const errorClasses = cx('c-red-1', 'text-10', 'mb8', {
    'v-hidden': !displayErrorMessage,
  });

  useEnterPressAction(open, handleAcceptOrder)

  return (
    <Modal
      showCloseIcon
      open={open}
      className="accept-order-modal"
      closeModal={handleClose}
    >
      <div className="text-center">
        <h2 className="section-text-4 mb16">Choose delivery time</h2>
        <div className="time-slot-wrapper">
          {TIME_SLOTS.map((slot, idx) => (
            <TimeSlot
              key={idx}
              slot={slot}
              value={selectedTimeValue}
              onClick={handleSelection}
            />
          ))}
        </div>
        <div className={errorClasses}>Please choose delivery time</div>
        <SpinnerButton
          showAnimation
          disabled={disabled}
          type="button"
          className="btn-warning-4"
          onClick={handleAcceptOrder}
        >
          {actionText}
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default AcceptOrderModal;
