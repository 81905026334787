import { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import Modal from '../shared/Modal';
import { formatDate, formatOrderTime } from '../utils/date';
import VerticalStepper, {
  VerticalStep,
} from '../shared/VerticalStepper/VerticalStepper';
import { capitaliseFirst } from '../utils/string';
import useResponsive from '../hooks/useResponsive';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import {
  mobileClose,
  MOBILE_DAIL_PAD,
} from './components/SelectShipmentPartner';
import { ORDER_DUKAAN_SHIPMENT_TRACKING_URL } from '../ApiUrls';
import { getRequest } from '../utils/http';

const CANCELLED_STATE = 'cancelled';

const getCurrentState = (text = '') =>
  capitaliseFirst(text.split('_').join(' '));

const TrackingStatus = ({
  isHyperLocal = true,
  awb,
  estimated_pickup_time: expectedPickup,
  expected_delivery_date: expectedDate,
  current_state: state,
  tracking_link: trackingLink,
}) => {
  const getDay = (date) => {
    let isSame = moment(new Date()).isSame(date, 'day');
    if (isSame)
      return `Today${isHyperLocal ? `, ${formatDate(date, 'LT')}` : ''}`;
    isSame = moment(new Date()).add(1, 'day').isSame(date, 'day');
    if (isSame)
      return `Tomorrow${isHyperLocal ? `, ${formatDate(date, 'LT')}` : ''}`;
    return formatDate(date, 'DD-MM-YYYY');
  };
  return (
    <div className="pb24 border-bottom">
      <div className="row mx-12">
        <div className="col-xs-6 px12">
          <p className="text-5 c-gray-1">CURRENT STATUS</p>
          <p className="text-medium">{getCurrentState(state)}</p>
        </div>
        <div className="col-xs-6 px12">
          <p className="text-5 c-gray-1">TRACKING ID</p>
          <a
            rel="noreferrer"
            href={trackingLink}
            className="c-purple-1 anchor-1 text-ellipsis underline w-100 d-block"
            target="_blank"
          >
            {awb}
          </a>
        </div>
        {expectedPickup && (
          <div className="col-xs-6 px12 mt12">
            <p className="text-5 c-gray-1">EXPECTED PICKUP</p>
            <p className="text-medium">{getDay(expectedPickup)}</p>
          </div>
        )}
        {expectedDate && (
          <div className="col-xs-6 px12 mt12">
            <p className="text-5 c-gray-1">EXPECTED DELIVERY</p>
            <p className="text-medium">{getDay(expectedDate)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const DeliveryPartnerDetails = ({ name, contact }) => {
  const { isMobile } = useResponsive();
  return (
    <div
      className={cx('pt16', {
        pb24: isMobile,
      })}
    >
      <div className="border-bottom" />
      <div className="text-2 text-medium mt24">Delivery partner details</div>
      <div className="row mx-12 mt16">
        <div className="col-xs-6 px12">
          <p className="text-5 c-gray-1">NAME</p>
          <p className="text-medium">{capitaliseFirst(name)}</p>
        </div>
        <div className="col-xs-6 px12">
          <p className="text-5 c-gray-1">MOBILE</p>
          <div
            className="text-medium"
            onClick={() => mobileClose(contact, MOBILE_DAIL_PAD)}
          >
            {contact}
          </div>
        </div>
      </div>
    </div>
  );
};

let intervalId;

export const ShipmentTracking = (props) => {
  const { open, shipmentUuid } = props;
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [trackingMetaDetails, setTrackingMetaDetails] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isMobile } = useResponsive();

  const getTrackingStatus = () => {
    const successCB = (data) => {
      setError(null);
      const details = [];
      setTrackingMetaDetails(data.meta);
      Object.values(data.states).forEach((each) => {
        const isActive = each.is_active && !each.is_completed;
        let desc = null;
        if (isActive) desc = each.description;
        if (each.is_completed) desc = formatOrderTime(each.timestamp);
        const obj = {
          title: each.msg,
          desc,
          isActive,
          isCompleted: each.is_completed,
          isCancelled: each.state === CANCELLED_STATE,
        };
        details.push(obj);
      });
      setTrackingDetails(details);
      setLoading(false);
    };
    const errCb = (err) => {
      setError(err.data);
      setLoading(false);
    };
    getRequest({
      url: ORDER_DUKAAN_SHIPMENT_TRACKING_URL(shipmentUuid),
    })
      .then(({ data }) => successCB(data))
      .catch(errCb);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      getTrackingStatus();
      intervalId = setInterval(getTrackingStatus, 5000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [open]);

  return (
    <BounceLoaderContainer isLoading={isLoading}>
      {!error ? (
        <div
          className={cx({
            pt16: !isMobile,
            p16: isMobile,
          })}
        >
          <TrackingStatus {...trackingMetaDetails} />
          <div
            className="stepper-container"
            style={{ height: '100%', border: 'none' }}
          >
            <VerticalStepper>
              {trackingDetails.map((step) => (
                <VerticalStep key={step.title} {...step} />
              ))}
            </VerticalStepper>
          </div>
          {trackingMetaDetails?.delivery_person?.name && (
            <DeliveryPartnerDetails {...trackingMetaDetails.delivery_person} />
          )}
        </div>
      ) : (
        <h5 className="section-text-5 mt40 mb20 text-center mx16">
          {error?.msg ? error.msg : 'Something went wrong'}
        </h5>
      )}
    </BounceLoaderContainer>
  );
};

const TrackOrderDetailsModal = (props) => {
  const { open, closeModal } = props;

  return (
    <Modal
      open={open}
      className="track-order-modal"
      closeModal={closeModal}
      title="Track pickup"
    >
      <div className="min-h-250 mt26">
        <ShipmentTracking {...props} />
      </div>
    </Modal>
  );
};

export default TrackOrderDetailsModal;
