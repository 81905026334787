import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Menu } from '@material-ui/core';
import moment from 'moment';
import TimePickerModal from './TimePickerModal';
import { DownArrowIconValidity, UpArrowIcon } from '../../SvgIcon';
import { noop } from '../../utils';

const TimePicker = ({
  formValues,
  prependText,
  open,
  labelText = '',
  isDateSame = false,
  value,
  setTime = noop,
  timeString = '',
  isDisabled = false,
  displayText = '',
  setDisplayText = noop,
  isTimeReset = false,
  setIsTimeReset = noop,
  isStartTimeInput = false,
  isEndTimeInput = false,
  isCurrentDate = noop,
  handleIsAllowed,
}) => {
  const [openPopper, setOpenPopper] = useState(open);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const handlePopover = (e) => {
    setOpenPopper((oldState) => !oldState);
    setPopoverAnchorEl(e.currentTarget);
  };

  const today = new Date();
  const todayHours = today.getHours();
  const todayAMPM = todayHours >= 12 ? 'pm' : 'am';
  const defaultAMPM = isStartTimeInput || isEndTimeInput ? todayAMPM : 'am';
  const [ampm, setAmpm] = useState(
    value ? moment(value).format('a') : defaultAMPM
  );
  const [hours, setHours] = useState(
    value ? Number(moment(value, ['HH:mm']).format('h')) : today.getHours()
  );

  const [minutes, setMinutes] = useState(
    value ? Number(moment(value, ['HH:mm']).format('m')) : today.getMinutes()
  );

  const handleTime = (timeArgument, ampmArgument) => {
    if (setTime) {
      let timeString = moment(`${timeArgument} ${ampmArgument.toUpperCase()}`, [
        'HH:mm',
      ]).format('HH:mm A');
      setTime(timeString);
    }
  };

  useEffect(() => {
    if (value) {
      setAmpm(moment(value).format('a'));
    }
  }, [value]);

  return (
    <>
      {labelText && <p className="mb8 text-8 c-black-3">{labelText}</p>}
      <div
        className={cx(
          'time-picker-div-input d-flex space-between align-center',
          { 'time-picker-div-input-disabled': isDisabled },
          { 'cur-p': !isDisabled },
          { focused: openPopper }
        )}
        name="coupon_validity_time"
        readOnly
        maxLength={10}
        onFocus={handlePopover}
        onBlur={handlePopover}
        onClick={(e) => {
          if (!isDisabled) handlePopover(e);
        }}
      >
        <div
          className={`picker-text-content ${
            timeString ? 'c-black-1' : 'c-gray-4'
          }`}
        >
          <span className="mr8 mt4">{prependText}</span>
          {timeString ? timeString : 'Select time'}
        </div>
        <span
          className="timepicker-arrow-icon cur-p"
          onClick={(e) => {
            if (!isDisabled) handlePopover(e);
          }}
        >
          {openPopper ? <UpArrowIcon /> : <DownArrowIconValidity />}
        </span>
      </div>
      {openPopper && (
        <Menu
          // keepMounted
          anchorEl={popoverAnchorEl}
          open={Boolean(popoverAnchorEl)}
          onClose={() => setPopoverAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          PaperProps={{
            style: {
              transform: 'translateX(2%) translateY(-25%)',
              width: '352px',
              padding: '24px 16px',
            },
          }}
          onExiting={() => setOpenPopper(false)}
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
        >
          <TimePickerModal
            formValues={formValues}
            setOpenPopper={setOpenPopper}
            onChange={handleTime}
            hours={hours == 12 || hours == 0 ? 12 : hours % 12}
            minutes={minutes}
            defaultAMPM={defaultAMPM}
            ampm={ampm}
            setAmpm={setAmpm}
            isDateSame={isDateSame}
            timeString={timeString}
            isCurrentDate={isCurrentDate}
            isStartTimeInput={isStartTimeInput}
            isEndTimeInput={isEndTimeInput}
            handleIsAllowed={handleIsAllowed}
          />
        </Menu>
      )}
    </>
  );
};

export default TimePicker;
