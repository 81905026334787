import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { useAppContext } from '../context/AppContext';
import useModal from '../hooks/useModal';
import { TrackEvent } from '../utils/analytics';
import { getAuthToken, logoutUser } from '../utils/user';
import { EVENT_ACC_SIGNOUT } from '../events';

import { accountUrls } from '../Urls';

import SelectStoreModal from '../Home/SelectStoreModal';
import { SuccessBadge } from '../shared/Badge';
import OnlineOfflineBadge from '../shared/OnlineOfflineBadge';
import { AddArrowLeftGray, RightArrowIcon } from '../SvgIcon';

const mountedStyle = {
  animation: 'inAnimation 100ms ease-in',
};
const unmountedStyle = {
  animation: 'outAnimation 200ms ease-out',
  animationFillMode: 'forwards',
};

const StoreMenu = () => {
  const {
    email,
    business,
    isOwner,
    isAdmin,
    isManager,
    sellerLimits,
    stores,
    isEnterprise,
    isPrimaryStore,
  } = useAppContext();
  const token = getAuthToken();
  const history = useHistory();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [activeSubmenuItemHeader, setActiveSubmenuItemHeader] = useState('');
  const { isOpen, openModal, closeModal } = useModal();

  // const { multiple_stores: multipleStores } = parseJwt(token);
  const multipleStores = stores.length > 1;

  const MENU_ITEMS = [
    ...(isOwner && isPrimaryStore
      ? [
          {
            icon: '/images/icons/menu/menu-billing.svg',
            label: 'Billing & Invoices',
            onClick: () => history.push(accountUrls.accountPath),
          },
        ]
      : []),
    ...(isOwner && !isEnterprise && isPrimaryStore
      ? [
          {
            icon: '/images/icons/menu/menu-subscription.svg',
            label: 'Subscription',
            onClick: () => history.push(accountUrls.subscriptionPurchasePath),
          },
        ]
      : []),
    // ...(isOwner
    //   ? [
    //       {
    //         icon: '/images/icons/menu/menu-help.svg',
    //         label: 'Help & support',
    //         children: [
    //           {
    //             icon: '/images/icons/menu/menu-chat.svg',
    //             label: 'Chat with us',
    //             onClick: () => window.open(DUKAAN_HELP),
    //           },
    //           {
    //             icon: '/images/icons/menu/announcements.svg',
    //             label: 'Send feedback',
    //             onClick: openZeda,
    //           },
    //         ],
    //       },
    //       {
    //         icon: '/images/icons/menu/menu-tutorials.svg',
    //         label: 'Changelog',
    //         onClick: () => window.open(DUKAAN_CHANGELOG),
    //       },
    //       {
    //         icon: '/images/icons/menu/menu-community.svg',
    //         label: 'Dukaan community',
    //         onClick: () => window.open(DUKAAN_COMMUNITY_URL),
    //       },
    //     ]
    //   : []),
    ...(sellerLimits?.isMultiStoreFlow || multipleStores
      ? [
          {
            icon: '/images/icons/menu/switch-store.svg',
            label: 'Switch store',
            onClick: () => openModal(),
          },
        ]
      : []),
    {
      icon: '/images/icons/menu/menu-signout.svg',
      label: 'Sign out',
      onClick: () => {
        TrackEvent(EVENT_ACC_SIGNOUT, business);
        logoutUser();
      },
    },
  ];

  useEffect(() => {
    window.zeda.init({
      email: email,
      name: business.id,
    });
  }, []);

  return (
    <>
      <div className="store-menu-container">
        {[isOwner, isAdmin, isManager].includes(true) && (
          <div className="py0 px8">
            {subMenuOpen ? (
              <div
                className="d-flex align-center"
                style={subMenuOpen ? mountedStyle : unmountedStyle}
              >
                <AddArrowLeftGray
                  className="mr16 cur-p back-arrow"
                  height={24}
                  width={24}
                  onClick={() => setSubMenuOpen(false)}
                />
                <p className="section-text-5">{activeSubmenuItemHeader}</p>
              </div>
            ) : (
              <div className="d-flex-c-s">
                <p className="section-text-7 mr16">Store status</p>
                <OnlineOfflineBadge />
              </div>
            )}
            <div className="divider mt12 mb8" />
          </div>
        )}
        {subMenuOpen ? (
          <>
            <div
              className="store-menu-items"
              style={subMenuOpen ? mountedStyle : unmountedStyle}
            >
              {subMenuItems.map(
                ({ icon, label, onClick: onItemClick, event }, index) => (
                  <div
                    className="store-menu-items-item"
                    key={index}
                    onClick={() => {
                      onItemClick();
                      if (event) {
                        TrackEvent(event, business);
                      }
                    }}
                  >
                    <img src={icon} alt={label} className="mr12" />
                    <p className="section-text-7 c-black-3 mr8">{label}</p>
                  </div>
                )
              )}
            </div>
          </>
        ) : (
          <>
            <div className="store-menu-items">
              {MENU_ITEMS.map(
                (
                  {
                    icon,
                    isNew = false,
                    label,
                    onClick: onItemClick,
                    event,
                    children = [],
                  },
                  index
                ) => (
                  <div
                    className="store-menu-items-item d-flex"
                    key={index}
                    onClick={() => {
                      if (children.length > 0) {
                        setActiveSubmenuItemHeader(label);
                        setSubMenuItems(children);
                        setSubMenuOpen(true);
                      } else {
                        onItemClick();
                      }
                      if (event) {
                        TrackEvent(event, business);
                      }
                    }}
                  >
                    <img src={icon} alt={label} className="mr12" />
                    <p className="section-text-7 c-black-3 mr8 spacer">
                      {label}
                    </p>
                    {isNew && <SuccessBadge text="NEW" />}
                    {children.length ? (
                      <RightArrowIcon widht={20} height={20} />
                    ) : null}
                  </div>
                )
              )}
            </div>
            {/* {!isAppSumoStore && (
              <div className="underline-anchor-section px8">
                <div className="divider mt8 mb12" />
                <div className="d-flex space-between align-center">
                  <a
                    href={ROADMAP_LINK}
                    target="_blank"
                    rel="noreferrer"
                    className="underline-anchor-text section-text-7 c-black-3 cur-p"
                  >
                    Our roadmap
                  </a>
                  <div className="app-store-icons">
                    <a
                      href={APP_STORE_LINKS.APPLE_LINK}
                      target="_blank"
                      rel="noreferrer"
                      className="cur-p mr10"
                    >
                      <AppleIcon />
                    </a>
                    <a
                      href={APP_STORE_LINKS.GOOGLE_PLAYSTORE_LINK}
                      target="_blank"
                      rel="noreferrer"
                      className="cur-p"
                    >
                      <GooglePlaystoreIcon />
                    </a>
                  </div>
                </div>
              </div>
            )} */}
          </>
        )}
      </div>
      {isOpen && <SelectStoreModal open={isOpen} closeModal={closeModal} />}
    </>
  );
};

export default withRouter(StoreMenu);
