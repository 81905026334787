import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: '#e5e5e5',
  },
  bar: {
    borderRadius: 8,
  },
}))(LinearProgress);

export default ProgressBar;
