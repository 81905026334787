/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  LocalizationProvider,
  StaticDateRangePicker,
} from '@material-ui/pickers';
import * as R from 'ramda';
import MomentUtils from '@date-io/moment';

import { momentDate, DISPLAY_FORMAT } from '../../utils/date';
import {
  CalendarLeftArrowIcon,
  CalendarRightArrowIcon,
  CrossIcon,
} from '../../SvgIcon';
import SpinnerButton from '../SpinnerButton';

const getDisplayDate = ([sDt, eDt]) =>
  `${sDt.format(DISPLAY_FORMAT)} - ${eDt.format(DISPLAY_FORMAT)}`;

const CustomRangePicker = (props) => {
  const { startDate, endDate, maxDate } = props;
  const today = new Date();
  const defaultSDate = startDate ? startDate.toDate() : today;
  const defaultEDate = endDate ? endDate.toDate() : today;
  const [selectedDate, handleDateChange] = useState([
    momentDate(defaultSDate),
    momentDate(defaultEDate),
  ]);

  const validDates = R.reject(R.not, selectedDate);
  const isValidRange = validDates.length === 2;

  const displayDate = isValidRange ? getDisplayDate(validDates) : '';

  const handleSelectRange = () => {
    props.onRangeSelect(selectedDate[0], selectedDate[1], 'custom_range');
    props.closePopper();
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  });

  return (
    <div className="custom-range-picker">
      <div className="custom-range-header">
        <span className="section-text-5">Select date range</span>
        <div className="date-range-picker-close-icon">
          <CrossIcon onClick={() => props.setShowCustomRange(false)} />
        </div>
      </div>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          calendars={2}
          value={selectedDate}
          onChange={handleDateChange}
          disableOpenPicker
          minDate={moment(maxDate).subtract(1, 'year')}
          maxDate={maxDate}
          leftArrowIcon={<CalendarLeftArrowIcon />}
          rightArrowIcon={<CalendarRightArrowIcon />}
        />
      </LocalizationProvider>
      <div className="date-and-btn-display-wrap">
        <span className="display-date-wrap">{displayDate}</span>
        <SpinnerButton
          type="button"
          className="btn-primary-4"
          disabled={!isValidRange}
          onClick={handleSelectRange}
        >
          Show results
        </SpinnerButton>
      </div>
    </div>
  );
};

export default CustomRangePicker;
