import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from './Modal';

import SpinnerButton from './SpinnerButton';

const LeaveSiteModal = ({
  title = 'Leave Site?',
  message = 'You have unsaved changes. Do you want to leave and discard these changes, or stay on this page?',
  open = false,
  closeModal = () => {},
  onSubmit,
  btnText = 'Leave',
}) => {
  const history = useHistory();

  const handleEvent = (e) => {
    if (e.keyCode === 13) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', handleEvent);
      return () => {
        document.removeEventListener('keyup', handleEvent);
      };
    }
  }, [open]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      paperClass="info-dialog text-start"
    >
      <p className="section-text-4 pb12">{title}</p>
      <p className=" c-black-3">{message}</p>
      <SpinnerButton
        type="button"
        className="btn-outline-4 mt32 mr12"
        onClick={() => {
          if (typeof onSubmit === 'function') onSubmit();
          else history.goBack();
          closeModal();
        }}
      >
        {btnText}
      </SpinnerButton>
      <SpinnerButton
        type="button"
        className="btn-primary-4 mt32"
        onClick={closeModal}
      >
        Stay on page
      </SpinnerButton>
    </Modal>
  );
};

export default LeaveSiteModal;
