import { Tooltip } from '@material-ui/core';
import {
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../../constants';
import { ORDER_DATE_ON } from '../../Manage/Delivery/constants';
import { SortDownIcon, SortUpIcon } from '../../SvgIcon';
import { noop } from '../../utils';

export const getTableHeaderTooltipTitle = (
  key,
  descText,
  ascText,
  sortingKey,
  sortingDirection
) => {
  if (sortingKey === key) {
    if (sortingDirection === SORTING_ORDER_DESCENDING) return descText;
    return ascText;
  }

  if (key === ORDER_DATE_ON) return ascText;

  return ascText;
};

export const getOrderingDirection = (key, sortingKey, sortingDirection) => {
  if (sortingKey === key) {
    return sortingDirection === SORTING_ORDER_ASCENDING
      ? SORTING_ORDER_DESCENDING
      : SORTING_ORDER_ASCENDING;
  }
  return SORTING_ORDER_DESCENDING;
};

const TooltipTableHeader = ({
  handleOnSort = noop,
  orderingKey,
  orderingDirection,
  column,
}) => {
  const { title, tooltipAscText, tooltipDscText, key } = column;
  return (
    <div
      className="d-flex align-center cur-p"
      onClick={() => {
        handleOnSort(key);
      }}
    >
      <Tooltip
        title={getTableHeaderTooltipTitle(
          key,
          tooltipDscText,
          tooltipAscText,
          orderingKey,
          orderingDirection
        )}
        placement="top"
        enterDelay={0}
      >
        <div className="d-flex align-center">
          <span className="mr8 d-flex align-center">{title}</span>

          {orderingKey === key ? (
            <>
              {orderingDirection === SORTING_ORDER_ASCENDING && <SortUpIcon />}
              {orderingDirection === SORTING_ORDER_DESCENDING && (
                <SortDownIcon />
              )}
            </>
          ) : (
            <SortDownIcon className="not-active" />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default TooltipTableHeader;
