import { createContext, useContext, useEffect, useState } from 'react';
import qs from 'query-string';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import {
  DOWNLOAD_WAREHOUSE_INVENTORY_URL,
  GET_WAREHOUSE_INVENTORY_LIST_URL,
  SETUP_WAREHOUSE_URL,
  UPDATE_WAREHOUSE_URL,
  UPLOAD_WAREHOUSE_INVENTORY_URL,
} from '../ApiUrls';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useLocalStorage from '../hooks/useLocalStorage';
import { WAREHOUSE_PAGE_SIZE } from '../Manage/Delivery/constants';
import { noop } from '../utils';
import * as User from '../utils/user';
import { parseJwt } from '../utils/string';
import { useCountry } from './CountryProvider';
import { useAppContext } from './AppContext';

const WarehouseContext = createContext({
  isLoading: false,
  warehouses: [],
  submitting: false,
  pageNumber: 1,
  totalWarehouseCount: null,
  queryString: null,
  warehouseInventoryList: [],
  activeWarehouseCount: 0,
  isActiveStoreWarehouseExist: false,
  isMultiWarehouseActiveExist: false,
  fetchWarehouseList: noop,
  createWarehouse: noop,
  updateWarehouse: noop,
  setWarehousePrimary: noop,
  toggleWarehouse: noop,
  downloadWarehouseInventoryList: noop,
  uploadWarehouseInventoryList: noop,
  downloadedWarehouseInventoryLink: noop,
  fetchWarehouseSKUInventoryList: noop,
  updateWarehouseSKUInventoryList: noop,
  setPageNumber: noop,
  setQueryString: noop,
  onSearch: noop,
});

export const useStoreWarehouse = () => useContext(WarehouseContext);

export const NO_SKU_IN_STORE_ERROR = 'store7';

export default function StoreWarehouseProvider({ children }) {
  const [isLoading, setLoading] = useState(false);
  const [
    downloadedWarehouseInventoryLink,
    setDownloadedWarehouseInventoryLink,
  ] = useLocalStorage('downloadedWarehouseInventoryLink', '');
  const [submitting, setSubmitting] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [activeWarehouseCount, setActiveWarehouseCount] = useState(0);
  const [warehouseInventoryList, setWarehouseInventoryList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalWarehouseCount, setTotalWarehouseCount] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();
  const authToken = User.getAuthToken();
  const { isInternational } = useCountry();
  const { business } = useAppContext();

  const fetchWarehouseList = (
    queryParams = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    const params = {
      page_size: WAREHOUSE_PAGE_SIZE,
      page: pageNumber,
      search: queryString,
      ...queryParams,
    };

    if (params.search === '') {
      delete params.search;
    }

    setLoading(true);
    getRequest({
      url: SETUP_WAREHOUSE_URL,
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then(
        ({ count, results, active_warehouse_count: warehouseCount, next }) => {
          successCallback(results, next);
          setTotalWarehouseCount(count);
          setActiveWarehouseCount(warehouseCount);
          setWarehouses(results);
        }
      )
      .catch((err) => errorCallback(err))
      .finally(() => setLoading(false));
  };

  const createWarehouse = (
    payload = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    if (payload?.country?.code_v2) {
      payload.country = payload?.country?.code_v2;
    } else {
      payload.country = business.country_code_v2;
    }
    if (!payload?.gst_number && isInternational) {
      delete payload?.gst_number;
    }
    postRequest({
      url: SETUP_WAREHOUSE_URL,
      data: payload,
    })
      .then(({ data }) => {
        successCallback(data);
        fetchWarehouseList();
        enqueueSnackbar('Store warehouse information saved successfully');
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.data?.error ?? 'Unable to create warehouse.',
          'error'
        );
        errorCallback(err);
      })
      .finally(() => setSubmitting(false));
  };

  const updateWarehouse = (
    payload = {},
    successCallback = noop,
    errorCallback = noop,
    successMsg = 'Store warehouse information updated successfully'
  ) => {
    setSubmitting(true);
    if (business?.country_code_v2) payload.country = business?.country_code_v2;
    if (!payload?.gst_number && isInternational) {
      delete payload?.gst_number;
    }
    if (!payload?.staff_id) delete payload.staff_id;

    patchRequest({
      url: UPDATE_WAREHOUSE_URL(payload.uuid),
      data: payload,
      doClean: false,
    })
      .then(({ data }) => {
        successCallback(data);
        fetchWarehouseList();
        enqueueSnackbar(successMsg);
        setSubmitting(false);
      })
      .catch((err) => {
        if (Object.keys(err?.data?.data).length) {
          enqueueSnackbar(Object.values(err?.data?.data).join(', '), 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        errorCallback(err);
      })
      .finally(() => setSubmitting(false));
  };

  const setWarehousePrimary = (
    payload = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    const newPayload = {
      ...payload,
      is_primary_warehouse: true,
    };
    updateWarehouse(
      newPayload,
      successCallback,
      errorCallback,
      `${payload.name} set as primary warehouse`
    );
  };

  const toggleWarehouse = (
    payload = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    const newPayload = {
      ...payload,
      is_active: !payload.is_active,
    };
    updateWarehouse(
      newPayload,
      successCallback,
      errorCallback,
      `${payload.name} is ${payload.is_active ? 'deactivated' : 'reactivated'}.`
    );
  };

  const downloadWarehouseInventoryList = (
    params = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    getRequest({
      url: DOWNLOAD_WAREHOUSE_INVENTORY_URL,
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then(({ data: { csv_link: csvLink } }) => {
        successCallback(csvLink);
        setDownloadedWarehouseInventoryLink(csvLink);
      })
      .catch((err) => {
        if (err.data) {
          if (
            !(
              err.data?.errors?.length &&
              err.data?.errors.some(
                (each) => each.error_code === NO_SKU_IN_STORE_ERROR
              )
            )
          ) {
            errorCallback(err);
            enqueueSnackbar(
              'Something went wrong while downloading. Try later.',
              'error'
            );
          } else {
            errorCallback(err, true);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchWarehouseSKUInventoryList = (
    skuUuid,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    getRequest({
      url: GET_WAREHOUSE_INVENTORY_LIST_URL(skuUuid),
    })
      .then(({ data: { inventory_list: inventoryList = [] } = {} }) => {
        successCallback(inventoryList);
        setWarehouseInventoryList(inventoryList);
      })
      .catch((err) => errorCallback(err))
      .finally(() => setLoading(false));
  };

  const updateWarehouseSKUInventoryList = (
    skuUuid,
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: GET_WAREHOUSE_INVENTORY_LIST_URL(skuUuid),
      data: payload,
    })
      .then(({ data: { inventory_list: inventoryList } = {} }) => {
        enqueueSnackbar('Warehouse inventory updated successfully');
        successCallback(inventoryList);
        setWarehouseInventoryList(inventoryList);
      })
      .catch((err) => errorCallback(err))
      .finally(() => setSubmitting(false));
  };

  const uploadWarehouseInventoryList = (
    file,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append('inventory', file);
    postRequest({
      url: UPLOAD_WAREHOUSE_INVENTORY_URL,
      data: formData,
    })
      .then(({ data }) => {
        enqueueSnackbar('Warehouse inventory uploaded successfully');
        successCallback(data);
      })
      .catch((e) => {
        if (e && e.data && e.data.status_code)
          if (e.data.status_code === 400)
            if (e.data.data.error) enqueueSnackbar(e.data.data.error, 'error');
            else
              enqueueSnackbar(
                'Please check the fields in excel file!',
                'error'
              );
        errorCallback(e);
      })
      .finally(() => setSubmitting(false));
  };

  const onSearch = (query) => {
    setQueryString(query);
    setPageNumber(1);
  };

  useEffect(() => {
    if (authToken) {
      const { store_ids: storeIds = [] } = parseJwt(authToken);
      if (storeIds.length > 0) fetchWarehouseList();
    }
  }, [pageNumber, queryString, authToken]);

  const contextValue = {
    isLoading,
    warehouses,
    submitting,
    activeWarehouseCount,
    isActiveStoreWarehouseExist: activeWarehouseCount > 0,
    isMultiWarehouseActiveExist: activeWarehouseCount > 1,
    fetchWarehouseList,
    warehouseInventoryList,
    createWarehouse,
    updateWarehouse,
    setWarehousePrimary,
    toggleWarehouse,
    downloadWarehouseInventoryList,
    uploadWarehouseInventoryList,
    downloadedWarehouseInventoryLink,
    fetchWarehouseSKUInventoryList,
    updateWarehouseSKUInventoryList,
    setPageNumber,
    pageNumber,
    totalWarehouseCount,
    queryString,
    setQueryString,
    onSearch,
  };

  return (
    <WarehouseContext.Provider value={contextValue}>
      {children}
    </WarehouseContext.Provider>
  );
}
