import axios from 'axios';
import moment from 'moment';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useFileDownload from '../hooks/useFileDownload';
import {
  BACKGROUND_TASK,
  BACKGROUND_TASK_PROGRESS,
  BACKGROUND_TASK_RETRY,
} from '../ApiUrls';
import { getRequest } from '../utils/http';
import { useAppContext } from './AppContext';

const REPORTS_TASK_STATUS = {
  SUCCEEDED: 'succeeded',
  PICKED: 'picked',
  QUEUED: 'queued',
  ERRORED: 'errored',
  FAILED_TO_PUBLISH: 'failed_to_publish',
};
const MAX_FILES_IN_DROPDOWN = 20;
const PAGE_SIZE = 30;
const MAX_RETRY_ATTEMPTS = 3;

const ViewReportsContext = createContext(null);

const ViewReportsProvider = ({ children }) => {
  const { isLoggedIn } = useAppContext();
  const { downloadFile } = useFileDownload();

  const [params, setParams] = useState({
    page: 1,
  });

  const [hasSucceededReports, setHasSucceededReports] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);
  const [totalTasksInProgress, setTotalTasksInProgress] = useState(0);
  const [formattedReports, setFormattedReports] = useState({});
  const [newReport, setNewReport] = useState([]);
  const [reports, setReports] = useState([]);
  const [reportsCount, setReportsCount] = useState(null);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [getSucceededResultsOnly, setGetSucceededResultsOnly] = useState(false);
  const [getGroupReports, setGetGroupedReports] = useState(true);

  const fetchReportsCancelToken = useRef();
  const fetchReportsProgressCancelToken = useRef();
  const newReportsRef = useRef([]);

  const constructParams = useCallback(() => {
    const payload = { ...params };
    const { search, startDate, endDate, selection } = payload;

    if (startDate) {
      payload.created_at_after = payload.startDate;
    }
    if (endDate) {
      payload.created_at_before = payload.endDate;
    }
    if (selection) {
      delete payload.selection;
    }
    delete payload.endDate;
    delete payload.startDate;
    if (!search) {
      delete payload.search;
    }
    if (getSucceededResultsOnly) {
      payload.task_status = REPORTS_TASK_STATUS.SUCCEEDED;
    }
    return payload;
  }, [getSucceededResultsOnly, params]);

  const groupReports = (tasks = []) => {
    const grouped = tasks?.reduce((accumulator, task) => {
      const date = moment(task.created_at).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'DD MMM YYYY',
        sameElse: 'DD MMM YYYY',
      });
      accumulator[`${date}`] = [...(accumulator?.[`${date}`] || []), task];
      return accumulator;
    }, {});

    return grouped;
  };

  const updateParams = useCallback((newParams, reason) => {
    // Check if there are any previous pending requests
    if (typeof fetchReportsCancelToken.current !== typeof undefined) {
      fetchReportsCancelToken.current.cancel(
        'Operation canceled due to new request.'
      );
    }
    // Save the cancel token for the current request
    fetchReportsCancelToken.current = axios.CancelToken.source();

    // use reason to debug the cause for order params update
    // console.log({ newParams }, reason);

    setParams((prev) => ({ ...prev, ...newParams }));
  }, []);

  const fetchReportsProgress = (showSomeDownload = false) => {
    getRequest({
      url: BACKGROUND_TASK_PROGRESS,
      options: { cancelToken: fetchReportsProgressCancelToken?.current?.token },
    })
      .then((res) => {
        const {
          total_progress: progress = 0,
          total_tasks_in_progress: tasks = 0,
        } = res?.data || {};
        if (showSomeDownload && tasks === 0) {
          setTotalTasksInProgress(1);
        } else setTotalTasksInProgress(tasks);
        setTotalProgress(progress === 100 && tasks === 0 ? 0 : progress);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const fetchReports = useCallback(() => {
    setReportsLoading(true);
    getRequest({
      url: BACKGROUND_TASK,
      data: { ...constructParams() },
      options: { cancelToken: fetchReportsCancelToken?.current?.token },
    })
      .then((res) => {
        const { count, results } = res;
        setHasSucceededReports(
          results?.some(
            (result) => result?.task_status === REPORTS_TASK_STATUS.SUCCEEDED
          )
        );
        setReportsCount(count);
        if (getGroupReports) {
          const formattedResults = groupReports(
            results?.slice(0, MAX_FILES_IN_DROPDOWN)
          );
          const newItems = results
            ?.slice(0, MAX_FILES_IN_DROPDOWN)
            .filter(
              (item) => item.task_status !== REPORTS_TASK_STATUS.SUCCEEDED
            );
          if (
            newReportsRef.current.length > 0 &&
            newReportsRef.current.length !== newItems.length
          ) {
            const nowSucceededItems = newReportsRef.current.filter(
              (item) => !newItems.some((file) => file.task_id === item.task_id)
            );
            if (nowSucceededItems.length > 0) {
              nowSucceededItems.forEach((item) => {
                const file =
                  results.find((newFile) => newFile.task_id === item.task_id) ??
                  {};
                if (file?.asset_url) {
                  setNewReport(file);
                }
              });
            }
          }
          newReportsRef.current = newItems;
          setFormattedReports(formattedResults);
        } else setReports(results);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return false;
        }
      })
      .finally(() => {
        setReportsLoading(false);
      });
  }, [constructParams, getGroupReports]);

  const refetchReports = () => {
    setParams({ page: 1 });
  };

  const refetchReportsProgress = useCallback((props) => {
    const { showSomeDownload = false } = props || {};
    // Check if there are any previous pending requests
    if (typeof fetchReportsProgressCancelToken.current !== typeof undefined) {
      fetchReportsProgressCancelToken.current.cancel(
        'Operation canceled due to new request.'
      );
    }
    // Save the cancel token for the current request
    fetchReportsProgressCancelToken.current = axios.CancelToken.source();
    fetchReportsProgress(showSomeDownload);
  }, []);

  const retryReportDownload = (taskId = '') => {
    getRequest({
      url: BACKGROUND_TASK_RETRY(taskId),
    })
      .then(() => {
        refetchReports();
        refetchReportsProgress({ showSomeDownload: true });
      })
      .catch((err) => console.log(err));
  };

  const updateResultsForTable = (exit = false) => {
    setGetSucceededResultsOnly(exit);
    setGetGroupedReports(!exit);
  };

  useEffect(() => {
    const taskIds = JSON.parse(localStorage.getItem('local_task_id'));

    if (
      newReport?.asset_url &&
      Array.isArray(taskIds) &&
      taskIds.includes(newReport?.task_id)
    ) {
      const taskIdIndex = taskIds.indexOf(newReport?.task_id);
      if (taskIdIndex > -1) {
        const updatedTaskIds = taskIds.filter(
          (id, index) => index !== taskIdIndex
        );
        downloadFile(newReport?.asset_url, true);
        localStorage.setItem('local_task_id', JSON.stringify(updatedTaskIds));
      }
    }
  }, [newReport]);

  useEffect(() => {
    if (!getSucceededResultsOnly && isLoggedIn) fetchReportsProgress();
  }, [getSucceededResultsOnly, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) fetchReports();
  }, [fetchReports, isLoggedIn]);

  useEffect(() => {
    let interval;
    if (getSucceededResultsOnly && interval) {
      clearInterval(interval);
    }
    if (totalTasksInProgress > 0 && !getSucceededResultsOnly) {
      interval = setInterval(() => {
        refetchReports();
        refetchReportsProgress({ showSomeDownload: false });
      }, 3000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [getSucceededResultsOnly, refetchReportsProgress, totalTasksInProgress]);

  const contextValue = {
    hasSucceededReports,
    fetchReportsProgress,
    formattedReports,
    pageSize: PAGE_SIZE,
    params,
    refetchReports,
    refetchReportsProgress,
    retryReportDownload,
    reports,
    reportsCount,
    reportsLoading,
    totalProgress,
    totalTasksInProgress,
    updateParams,
    updateResultsForTable,
    REPORTS_TASK_STATUS,
    MAX_RETRY_ATTEMPTS,
  };

  return (
    <ViewReportsContext.Provider value={contextValue}>
      {children}
    </ViewReportsContext.Provider>
  );
};

export const useViewReports = () => useContext(ViewReportsContext);

export default ViewReportsProvider;
