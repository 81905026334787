import { GOOGLE_CLIENT_ID, GOOGLE_IDENTITY_SERVICES_URL } from '../constants';
import { useAppContext } from '../context/AppContext';
import { EVENT_OB_ONETAP_LOGIN } from '../events';
import useFirebaseOauth from '../hooks/useFirebaseOauth';
import useScript from '../hooks/useScript';

const GoogleOneTapLogin = () => {
  const { business } = useAppContext();
  const { handleAuthSuccess } = useFirebaseOauth();

  const handleOneTapLogin = (response) => {
    handleAuthSuccess(response, 'google');
    TrackEvent(EVENT_OB_ONETAP_LOGIN, business);
  };

  useScript(
    GOOGLE_IDENTITY_SERVICES_URL,
    () => {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        auto_select: true,
        ux_mode: 'popup',
        callback: handleOneTapLogin,
      });

      window.google.accounts.id.prompt();
    },
    true
  );

  return <div />;
};

export default GoogleOneTapLogin;
