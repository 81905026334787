import { generatePath } from 'react-router-dom';
import {
  catalogueUrls,
  manageUrls,
  pluginsUrls,
  restaurantUrls,
  rootUrls,
} from './Urls';

export const editProductUrl = (uuid) =>
  generatePath(catalogueUrls.editProductPath, { uuid });

export const editBundleUrl = (uuid) =>
  generatePath(catalogueUrls.editBundlePath, { uuid });

export const editCategoryUrl = ({ uuid, categoryId }) =>
  generatePath(catalogueUrls.editCategoryPath, { uuid, categoryId });

export const editSubcategoryUrl = ({ uuid, categoryId }) =>
  generatePath(catalogueUrls.editSubcategoryPath, { uuid, categoryId });

export const singleCategoryProductUrl = (categoryId) =>
  generatePath(catalogueUrls.singleCategoryProductsPath, { categoryId });

export const orderDetailsUrl = (uuid) =>
  generatePath(rootUrls.orderDetailsPath, { uuid });

export const abandonedOrderDetailsUrl = (uuid) =>
  generatePath(rootUrls.abandonedOrderDetailsPath, { uuid });

export const returnOrderDetailsUrl = (uuid) =>
  generatePath(rootUrls.returnOrderDetailsPath, { uuid });

export const productsUrl = generatePath(catalogueUrls.cataloguePath, {
  tabId: 'products',
});

export const editAttributesPath = (attributeId) =>
  generatePath(catalogueUrls.editAttributes, { attributeId });

export const pluginsUrl = generatePath(catalogueUrls.cataloguePath, {
  tabId: 'plugins',
});

export const categoriesUrl = generatePath(catalogueUrls.cataloguePath, {
  tabId: 'categories',
});

export const editCouponUrl = (uuid) =>
  generatePath(manageUrls.editCouponPath, { uuid });

export const storeLocatorUrl = (uuid) =>
  generatePath(pluginsUrls.pluginSettingPath, { uuid });

export const wishlistUrl = (uuid) =>
  generatePath(pluginsUrls.pluginSettingPath, { uuid });

export const editOfflineStoreUrl = (uuid) =>
  generatePath(`${pluginsUrls.storeLocatorPath}/${uuid}/edit`, { uuid });

export const tabelOrdersUrl = (uuid) =>
  generatePath(restaurantUrls.tableOrders, { uuid });

export const customerOrdersUrl = (id) =>
  `${manageUrls.customersPath}/${id}/orders`;

export const pluginSettingsPathGenerator = (uuid) =>
  generatePath(pluginsUrls.pluginSettingPath, { uuid });
