import React from 'react';
import { useHistory } from 'react-router-dom';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { WarningExclaimationIcon } from '../../SvgIcon';
import { manageUrls } from '../../Urls';

const SetDukaanPayModal = ({ open, closeModal }) => {
  const history = useHistory();
  const handleClick = () => {
    history.replace(
      `${manageUrls.paymentsUrl}?sp=1&dr=1&redirectUrl=${window.location.pathname}`
    );
  };
  useEnterPressAction(open, handleClick);

  return (
    <Modal open={open} closeModal={closeModal} className="text-center">
      <WarningExclaimationIcon />
      <p className="section-text-4 mb6 c-black-1">Dukaan Pay not set up</p>
      <p className="text-2 mb32 c-black-3">
        To receive online payments in your bank account from your customers,
        please set up Dukaan Pay.
      </p>
      <SpinnerButton onClick={handleClick}>Set up Dukaan Pay</SpinnerButton>
    </Modal>
  );
};

export default SetDukaanPayModal;
