/* eslint-disable no-nested-ternary */
import COLORS from '../../colorConstants';
import { StarIconYellow } from '../../SvgIcon';
import { accountUrls } from '../../Urls';

export const HELLOBAR_CONFIG = ({
  isMobile = false,
  isInternational = false,
  discountPercent = 50,
}) => [
  {
    classes: {
      root: {
        width: '100%',
        background: isMobile
          ? 'radial-gradient(circle closest-corner at 50% -210%, rgba(221,49,134,1) 62%, rgba(94,34,169,1) 100%)'
          : '#5E22A9',
        padding: isMobile ? '12px 32px' : '0px',
        justifyContent: 'center',
        position: 'relative',
      },
      gradientDiv: {
        padding: isMobile ? '' : '8px 32px',
        background: isMobile
          ? ''
          : 'radial-gradient(circle closest-corner at 50% 375%, rgba(221,49,134,1) 62%, rgba(94,34,169,1) 95%)',
        height: isMobile ? '' : '100%',
        position: isInternational || isMobile ? 'relative' : 'absolute',
        left: 0,
      },
      icon: {
        '& path': { fill: COLORS.WHITE },
      },
      boldText: {
        color: COLORS.WHITE,
        fontStyle: 'italic',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 900,
      },
      discountTextDiv: {
        paddingRight: !isMobile ? 32 : 0,
      },
      button: {
        padding: '6px 12px',
        backgroundColor: COLORS.SEO.TOO_LONG,
        color: '#220158',
      },
      timer: {
        padding: isMobile ? 0 : '8px 32px 8px 0px',
      },
    },
    dealName: 'LIMITED TIME OFFER',
    icon: StarIconYellow,
    showIconOnLeft: true,
    showIconOnRight: true,
    discountText: `Flat ${discountPercent}% off on Gold and Platinum plan`,
    discountTextInternational: `🔥 ${discountPercent}% discount on all plans till 28th November, 2022. Use code: DUKAANCM50`,
    showButtonRedirect: !isMobile,
    buttonText: 'Avail 50% OFF',
    buttonRedirectLink: accountUrls.subscriptionPurchasePath,
    showTimer: true,
    showDealAfterTimerEnd: true,
    startDateInternational: '11/26/2022',
    endDateInternational: '11/29/2022',
    timerEndDateInternational: '11/29/20223',
    startDate: '10/26/2022',
    endDate: '11/29/2022',
    timerEndDate: '11/29/2022',
    subscriptionStripColor: '#5E22A9',
  },
  {
    classes: {
      root: {
        background:
          'radial-gradient(68.13% 61318.26% at 6.21% 50%, #4F038A 0%, #1B0050 100%)',
        padding: isMobile ? '12px 32px' : '8px 32px',
      },
      gradientDiv: {},
      boldText: {
        color: COLORS.WHITE,
        fontSize: 20,
        lineHeight: '100%',
        fontWeight: 700,
      },
      discountTextDiv: {},
      button: {
        padding: '6px 12px',
        backgroundColor: COLORS.SEO.TOO_LONG,
        color: '#220158',
      },
      timer: {},
    },
    dealName: 'DIWALI OFFER',
    icon: StarIconYellow,
    showIconOnLeft: true,
    showIconOnRight: true,
    discountText: `Flat ${discountPercent}% off on Gold and Platinum plan`,
    discountTextInternational: `🔥 ${discountPercent}% discount on all plans till 28th November, 2022. Use code: DUKAANBF50`,
    showButtonRedirect: !isMobile,
    buttonText: 'Subscribe',
    buttonRedirectLink: accountUrls.subscriptionPurchasePath,
    showTimer: !isInternational,
    showDealAfterTimerEnd: false,
    startDateInternational: '11/24/2022',
    endDateInternational: '11/26/2022',
    timerEndDateInternational: '11/26/2022',
    startDate: '10/30/2022',
    endDate: '11/15/2022',
    timerEndDate: '11/26/2022',
    subscriptionStripColor:
      'linear-gradient(90deg, #1B0050 3.31%, #490383 150.75%)',
  },
  {
    classes: {
      root: {
        width: '100%',
        background: isMobile
          ? 'radial-gradient(circle closest-corner at 50% -210%, rgba(221,49,134,1) 62%, rgba(94,34,169,1) 100%)'
          : 'linear-gradient(65deg, rgba(255,153,51,1) 24%, rgba(255,255,255,1) 44%, rgba(187,221,184,1) 68%, rgba(19,136,8,1) 100%)',
        padding: isMobile ? '12px 32px' : '0px',
        justifyContent: 'center',
        position: 'relative',
      },
      gradientDiv: {
        padding: isMobile ? '' : '8px 32px',
        background: isMobile ? 'transparent' : 'transparent',
        height: isMobile ? '' : '100%',
        position: isMobile ? 'relative' : 'absolute',
        left: 0,
      },
      icon: {
        '& path': { fill: COLORS.WHITE },
      },
      boldText: {
        color: COLORS.WHITE,
        fontStyle: 'italic',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 900,
      },
      discountTextDiv: {
        paddingRight: !isMobile ? 32 : 0,
      },
      button: {
        padding: '6px 12px',
        backgroundColor: COLORS.ORANGE_4,
        color: COLORS.BLACK_1,
      },
      timer: {
        padding: isMobile ? 0 : '8px 32px 8px 0px',
        ...(isMobile ? {} : { position: 'absolute', right: 0 }),
      },
    },
    dealName: 'Republic day sale',
    icon: StarIconYellow,
    showIconOnLeft: true,
    showIconOnRight: true,
    discountText: `Upgrade now to Yearly plan and avail upto 26% discount!`,
    discountTextInternational: `🔥 ${discountPercent}% discount on all plans.`,
    showButtonRedirect: !isMobile,
    buttonText: `Upgrade now`,
    buttonRedirectLink: accountUrls.subscriptionPurchasePath,
    showTimer: true,
    showDealAfterTimerEnd: true,
    startDateInternational: '02/24/2023',
    endDateInternational: '03/01/2023',
    timerEndDateInternational: '03/01/2023',
    startDate: '01/19/2024',
    endDate: '01/26/2024',
    timerEndDate: '01/26/2024',
    subscriptionStripColor: 'black',
  },
];
