import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { FormInput } from '../../shared/Form';
import { required } from '../../shared/Form/Validation';
import { postRequest } from '../../utils/http';
import { SAVE_STRIPE_PG_KEY_API_ENDPOINT } from '../../utils/urls/payments/international-payments';
import { ExternalLink } from '../../shared';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import { usePayments } from '../../context/PaymentsProvider';
import InfoStripV2 from '../../shared/InfoStripV2';
import Nudge from '../../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';

const STRIPE_HELP_URL = 'https://dashboard.stripe.com/apikeys';
const STRIPE_CREATE_ACCOUNT_URL = 'https://dashboard.stripe.com/register';

const StripeSetupForm = ({
  isActivated,
  isPresent,
  successCallback = noop,
}) => {
  const { fetchBusinessDetails } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { fetchStripeKeys } = usePayments();
  const [stripeKeys, setStripeKeys] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isInvalidKey, setIsInvalidKey] = useState({
    public_key: false,
    secret_key: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: SAVE_STRIPE_PG_KEY_API_ENDPOINT,
      data: values,
    })
      .then(() => {
        enqueueSnackbar('Stripe linked successfully');
        successCallback();
        fetchBusinessDetails();
      })
      .catch(() => {
        enqueueSnackbar(
          'We are unable to verify your keys. Please enter correct key values.',
          'error'
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    fetchStripeKeys(({ data }) => {
      setStripeKeys({ ...data });
    });
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ public_key: '', secret_key: '', ...stripeKeys }}
      render={({ handleSubmit, dirtyFields }) => (
        <form className="mt4">
          <div className="text-8 mb16">
            Please enter your Stripe API keys. You can find your API keys on
            <ExternalLink
              href={STRIPE_HELP_URL}
              className="text-medium c-purple-1-i ml6 underline"
            >
              Stripe API Key Dashboard
            </ExternalLink>
          </div>
          <Nudge
            variant={NUDGE_VARIANTS.PRIMARY}
            type={NUDGE_TYPES.PRIMARY}
            iconVariant={NUDGE_ICON_VARIANTS.BLUE}
            className="mb24"
            text={
              <p className="c-black-3 text-8 d-flex align-center">
                Don't have an Stripe account?{' '}
                <ExternalLink
                  href={STRIPE_CREATE_ACCOUNT_URL}
                  className="text-medium c-black-3-i ml6 underline"
                >
                  Create now
                </ExternalLink>
              </p>
            }
          />
          <FormInput
            labelText="Publishable Key"
            name="public_key"
            required
            showRequired
            className="mb24"
            validate={required()}
            onChange={(e) => {
              if (!e.target.value.startsWith('pk')) {
                setIsInvalidKey({ ...isInvalidKey, public_key: true });
              } else setIsInvalidKey({ ...isInvalidKey, public_key: false });
            }}
            error={isInvalidKey.public_key}
            defaultValue={' '}
            placeholder="Enter publishable key"
          />
          <FormInput
            labelText="Secret Key"
            name="secret_key"
            required
            showRequired
            validate={required()}
            onChange={(e) => {
              if (!e.target.value.startsWith('sk')) {
                setIsInvalidKey({ ...isInvalidKey, secret_key: true });
              } else setIsInvalidKey({ ...isInvalidKey, secret_key: false });
            }}
            defaultValue={' '}
            error={isInvalidKey.secret_key}
            type={showPassword ? 'text' : 'password'}
            appendText={
              (dirtyFields.secret_key || (isPresent && isActivated)) && (
                <button
                  type="button"
                  className="btn-no-default text-medium c-purple-1 toggle-button append-text-input"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'HIDE' : 'SHOW'}
                </button>
              )
            }
            autoComplete="new-password"
            placeholder="Enter secret key"
          />
          {(isInvalidKey.public_key || isInvalidKey.secret_key) && (
            <span className="c-red-1">
              The entered key
              {isInvalidKey.public_key && isInvalidKey.secret_key
                ? 's are'
                : ' is'}{' '}
              incorrect, please re-enter.
            </span>
          )}
          <div className="text-center mt24">
            <SpinnerButton
              type="submit"
              isLoading={submitting}
              className="btn-primary-4"
              onClick={handleSubmit}
              disabled={isInvalidKey.public_key || isInvalidKey.secret_key}
            >
              {isActivated && isPresent ? 'Update' : 'Set up'}
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default StripeSetupForm;
