import { CDN_URL } from '../Blog/constants';
import { SVG_EXTENSION } from '../constants';
import { matchWildcard } from './string';

export const IMAGE_CDN_DOMAIN = 'https://dms.mydukaan.io';

export const getExtensionFromFileName = (name, includeDot = true) => {
  const nameSplitArray = name?.split('.');
  return `${includeDot ? '.' : ''}${nameSplitArray[
    nameSplitArray.length - 1
  ]?.toLowerCase()}`;
};

export const filterFilesByExtension = (fileList, extensions = []) =>
  Array.from(fileList).filter((file) =>
    extensions.includes(getExtensionFromFileName(file.name))
  );

export const filterFilesByMimeType = (fileList, mimeTypeWildcard = '') =>
  Array.from(fileList).filter((file) =>
    matchWildcard(file.type, mimeTypeWildcard)
  );

export const filterFilesBySize = (fileList, sizeLimitInBytes = 1000) =>
  Array.from(fileList).filter((file) => file.size <= sizeLimitInBytes);

export const changeCDNRes = (image = '', resToChange = 100, finalRes = 300) => {
  const finalUrl = image?.replace(
    `/${resToChange}x${resToChange}`,
    `/${finalRes}x${finalRes}`
  );
  if (
    image?.includes('mydukaan-prod') &&
    !image?.includes('mydukaan-prod-new')
  ) {
    return finalUrl.replace('mydukaan-prod', 'mydukaan-prod-new');
  }

  return finalUrl;
};

export const addCDN = (image = '', height = 100, width = 100, factor = 1) => {
  if (image?.includes('upload_file_service')) {
    const base = `https://dms.mydukaan.io/${height * factor}x${
      width * factor
    }/webp/upload_file_service`;
    const slug = image.split('/upload_file_service');
    return `${base}${slug[1]}`;
  }
  if (image?.includes('product-bulk-upload')) {
    const base = `https://dms.mydukaan.io/${height * factor}x${
      width * factor
    }/webp/product-bulk-upload`;
    const slug = image.split('/product-bulk-upload');
    return `${base}${slug[1]}`;
  }
  if (image?.includes('amazonaws.com')) {
    const base = `https://dms.mydukaan.io/${height * factor}x${
      width * factor
    }/webp`;
    const slug = image.split('amazonaws.com');
    return `${base}${slug[1]}`;
  }

  if (image?.includes('wasabisys.com')) {
    const base = `https://dms.mydukaan.io/${height * factor}x${
      width * factor
    }/jpeg`;
    const slug = image.split('wasabisys.com');
    return `${base}${slug[1]}`;
  }
  if (image?.includes('cdn.mydukaan.io')) {
    const string = new URL(image);
    const result = string.searchParams.get('url');
    return result;
  }
  if (image?.includes('https://dms.mydukaan.io/original')) {
    const base = `https://dms.mydukaan.io/${height * factor}x${
      width * factor
    }/webp`;
    const slug = image.split('https://dms.mydukaan.io/original/webp');
    return `${base}${slug[1]}`;
  }
  return image;
  // `${'https://cdn.mydukaan.io'}/app/image/${height * factor}x${
  //   width * factor
  // }/?url=${image}`;
};

export const getCdnUrl = (image = '', height = 100, width = 100, factor = 1) =>
  image.startsWith('https://cdn.mydukaan.io') || !image.startsWith('https://')
    ? image
    : addCDN(image, height, width, factor);

export const updateCDN = (
  image = '',
  height = 100,
  width = 100,
  useCdn = true
) => {
  const imageWithCDN =
    !image.includes('cdn.mydukaan.io') && (image.includes(CDN_URL) || !useCdn)
      ? image
      : addCDN(image, height, width, 2);
  return imageWithCDN;
};

export const getFileNameFromUrl = (url) =>
  url.substring(url.lastIndexOf('/') + 1);

export const b64toBlob = (dataURI, type = 'image/png') => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
};
