import {
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../constants';
import { ORDER_DATE_ON } from '../Manage/Delivery/constants';
import { MENU_ITEM_TYPES } from './constants';

export const transformToUIItems = (menuItems = []) => {
  const items = [];
  if (menuItems.length > 0) {
    menuItems.forEach((item) => {
      items.push({
        id:
          item.content_type === MENU_ITEM_TYPES.CUSTOM_LINK
            ? item.id
            : item.content_object?.id,
        name: item.label,
        type: item.content_type,
        url: item.url,
        depth: 0,
        originalName: item.name,
        file_url: item.file_url,
        hasItems: item.child.length !== 0,
        showItem: true,
      });

      if (item.child.length > 0) {
        item.child.forEach((childOne) => {
          items.push({
            id:
              childOne.content_type === MENU_ITEM_TYPES.CUSTOM_LINK
                ? childOne.id
                : childOne.content_object?.id,
            name: childOne.label,
            originalName: childOne.name,
            type: childOne.content_type,
            url: childOne.url,
            depth: 1,
            file_url: childOne.file_url,
            hasItems: childOne.child.length !== 0,
            showItem: false,
          });

          if (childOne.child.length > 0) {
            childOne.child.forEach((childTwo) => {
              items.push({
                id:
                  childTwo.content_type === MENU_ITEM_TYPES.CUSTOM_LINK
                    ? childTwo.id
                    : childTwo.content_object?.id,
                name: childTwo.label,
                originalName: childTwo.name,
                type: childTwo.content_type,
                url: childTwo.url,
                depth: 2,
                file_url: childTwo.file_url,
                showItem: false,
              });
            });
          }
        });
      }
    });
  }

  return items;
};

const recur = (root, parent, child) => {
  if (root && root?.label === parent?.label) {
    const temp = [...(root?.child ?? [])];
    temp.push(child);
    root.child = temp;
    return;
  }
  if (root?.child) {
    root.child.forEach((el) => recur(el, parent, child));
  }
};

const formatData = (item, selectedOption) => {
  const child = { ...item };
  if (item?.type === MENU_ITEM_TYPES.CUSTOM_LINK) {
    // url case
    child.label = item?.name;
    child.url = item?.url;
    child.store_menu_type = selectedOption?.toLowerCase();
  } else {
    // other cases
    child.label = item?.name;
    child.content_type = item?.type;
    child.object_id = item?.id;
    child.store_menu_type = selectedOption?.toLowerCase();
  }
  return child;
};

export const transformToServerItems = (menuItems, selectedOption) => {
  const processed = [];

  menuItems.forEach((item, index) => {
    if (item.depth <= 2) {
      const currDepth = item.depth;
      const child = formatData(item, selectedOption);
      if (currDepth === 0) {
        processed.push(child);
      } else {
        const parent = formatData(
          menuItems.findLast(
            (i, ind) => currDepth - i.depth === 1 && ind < index
          ),
          selectedOption
        );
        const root = processed.pop();
        recur(root, parent, child);
        processed.push(root);
      }
    }
  });

  return processed;
};

export const validateUrl = (url = '') => {
  // a URL might be without http:// in the beginnging so we need to ensure that
  if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const deepCompare = (arg1, arg2) => {
  if (
    Object.prototype.toString.call(arg1) ===
    Object.prototype.toString.call(arg2)
  ) {
    if (
      Object.prototype.toString.call(arg1) === '[object Object]' ||
      Object.prototype.toString.call(arg1) === '[object Array]'
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every(function (key) {
        return deepCompare(arg1[key], arg2[key]);
      });
    }
    return arg1 === arg2;
  }
  return false;
};

export const deepCompareIgnoreKey = (obj1, obj2, ignoreKey) => {
  if (
    obj1 === null ||
    obj1 === undefined ||
    obj2 === null ||
    obj2 === undefined
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i += 1) {
    const key = keys1[i];

    if (key === ignoreKey) {
      continue;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (Array.isArray(value1)) {
      if (!Array.isArray(value2) || value1.length !== value2.length) {
        return false;
      }

      for (let j = 0; j < value1.length; j += 1) {
        if (!deepCompareIgnoreKey(value1[j], value2[j], ignoreKey)) {
          return false;
        }
      }
    } else if (typeof value1 === 'object' && value1 !== null) {
      if (typeof value2 !== 'object' || value2 === null) {
        return false;
      }

      if (!deepCompareIgnoreKey(value1, value2, ignoreKey)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
};

export const deepCompareIgnoreKeys = (obj1, obj2, ignoreKeys) => {
  if (
    obj1 === null ||
    obj1 === undefined ||
    obj2 === null ||
    obj2 === undefined
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i += 1) {
    const key = keys1[i];

    if (ignoreKeys.includes(key)) {
      continue;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (Array.isArray(value1)) {
      if (!Array.isArray(value2) || value1.length !== value2.length) {
        return false;
      }

      for (let j = 0; j < value1.length; j += 1) {
        if (!deepCompareIgnoreKeys(value1[j], value2[j], ignoreKeys)) {
          return false;
        }
      }
    } else if (typeof value1 === 'object' && value1 !== null) {
      if (typeof value2 !== 'object' || value2 === null) {
        return false;
      }

      if (!deepCompareIgnoreKeys(value1, value2, ignoreKeys)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
};
