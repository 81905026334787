import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import NumberFormat from 'react-number-format';
import { Field } from 'react-final-form';

import FormGroup from './FormGroup';
import { noop } from '../../utils';
import InputDisabledTooltip from '../../Catalogue/Products/Variants/InputDisabledTooltip';

const FormNumberInput = (props) => {
  const {
    className,
    name,
    validate,
    labelText,
    required,
    size,
    onChange,
    helpText,
    showRequired,
    prependText,
    appendText,
    largePrependText,
    children,
    initialValue = '',
    error,
    max = Number.MAX_SAFE_INTEGER,
    decimalScale = 2,
    hasTooltip = false,
    showTooltip = false,
    tooltipTitle = '',
    isPriceInput = false,
    prependOffset,
    appendOffset,
    ...restInputProps
  } = props;

  const hasPrependText = !!prependText;
  const hasAppendText = !!appendText;
  const classes = cx(
    `input-wrap prepend-text-${
      hasPrependText && !isPriceInput ? prependText.length : 0
    }`,
    className,
    {
      'has-prepend-text': hasPrependText,
      'has-append-text': hasAppendText,
      'large-prepend-text': largePrependText,
    }
  );

  const useStyles = makeStyles(() =>
    createStyles({
      tooltipPlacementBottom: {
        marginTop: '24px !important',
      },
    })
  );

  const styles = useStyles();

  return (
    <Field name={name} validate={validate} initialValue={initialValue}>
      {({ input, meta }) => {
        const handleChange = ({ value }) => {
          if (value && typeof max === 'number' && Number(value) > max) return;
          input.onChange({
            target: { value, name },
          });
          onChange({
            target: { value, name },
          });
        };

        const style = { style: {} };
        if (prependOffset) {
          style.style.paddingLeft = prependOffset;
        }
        if (appendOffset) {
          style.style.paddingRight = appendOffset;
        }
        return (
          <FormGroup
            labelText={labelText}
            required={required}
            size={size}
            helpText={helpText}
            showRequired={showRequired}
            className={cx(classes, {
              'has-error':
                error || (meta.error && meta.touched) || meta.submitError,
            })}
          >
            <div className="input-group-wrap">
              {hasPrependText && (
                <span className="prepend-txt">{prependText}</span>
              )}
              {hasTooltip ? (
                <InputDisabledTooltip
                  title={tooltipTitle}
                  show={showTooltip}
                  tooltipProps={{
                    classes: {
                      tooltipPlacementBottom: styles.tooltipPlacementBottom,
                    },
                  }}
                >
                  <NumberFormat
                    decimalScale={decimalScale}
                    {...input}
                    {...restInputProps}
                    {...style}
                    onValueChange={handleChange}
                  />
                </InputDisabledTooltip>
              ) : (
                <NumberFormat
                  decimalScale={decimalScale}
                  {...input}
                  {...restInputProps}
                  {...style}
                  onValueChange={handleChange}
                />
              )}

              {hasAppendText && (
                <span className="append-txt">{appendText}</span>
              )}
            </div>
            {children}
          </FormGroup>
        );
      }}
    </Field>
  );
};

FormNumberInput.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: false,
  disabled: false,
  autoComplete: 'off',
  size: 'lg', // md
  helpText: '',
  onChange: noop,
  allowNegative: false,
  showRequired: false,
  prependText: '',
  largePrependText: false,
};

export default FormNumberInput;
