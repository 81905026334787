/* eslint-disable import/no-cycle */
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { useViewReports } from '../context/ViewReportsProvider';
import ProgressBar from '../shared/ProgressBar';
import { FileIcon, RefreshIcon, ViewAllReportsEmpty } from '../SvgIcon';
import { Drawer } from '@material-ui/core';
import { ellipsizeText } from '../utils/string';
import { GrayBadge, RedBadge } from '../shared/Badge';
import SpinnerButton from '../shared/SpinnerButton';
import useFileDownload from '../hooks/useFileDownload';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

const ViewReportsMenu = ({ open, onClose }) => {
  const {
    formattedReports,
    retryReportDownload,
    REPORTS_TASK_STATUS,
    MAX_RETRY_ATTEMPTS,
  } = useViewReports();

  const { downloadFile, downloading: fileDownloading } = useFileDownload();
  const { enqueueSnackbar } = useCustomSnackbar();

  const hasResults = Object.keys?.(formattedReports)?.length > 0;

  return (
    <>
      <Drawer anchor="right" open={open} paperClass="md" onClose={onClose}>
        <div className={cx('view-download-dropdown')}>
          <div className="d-flex-c-s p24 pb12">
            <p className="section-text-4 text-semibold">Reports</p>
            {/* {hasSucceededReports && (
              <Link to={rootUrls.viewAllReportsPath} className="btn-outline-4">
                View all reports
              </Link>
            )} */}
          </div>

          {hasResults ? (
            <div className="reports-grid show-scrollbar px24">
              {Object.keys?.(formattedReports)?.map((date, index) => (
                <div key={index}>
                  {/* <p className="text-10">{date}</p> */}
                  <div className="d-flex mb12">
                    <GrayBadge text={date} />
                  </div>
                  <div className="date-wise-reports-grid">
                    {formattedReports[`${date}`].map((report) => (
                      <div
                        className={cx('report-div', {
                          downloaded:
                            report.task_status ===
                            REPORTS_TASK_STATUS.SUCCEEDED,
                        })}
                        key={report.task_id}
                      >
                        <div className="report-icon">
                          <FileIcon width={24} height={24} />
                        </div>
                        <div
                          onClick={() => {
                            if (
                              report.task_status ===
                                REPORTS_TASK_STATUS.SUCCEEDED &&
                              report?.asset_url &&
                              !fileDownloading
                            ) {
                              enqueueSnackbar(
                                'Report download will begin shortly!',
                                'info'
                              );
                              downloadFile(report.asset_url, true);
                            }

                            if (
                              [
                                REPORTS_TASK_STATUS.ERRORED,
                                REPORTS_TASK_STATUS.FAILED_TO_PUBLISH,
                              ].includes(report.task_status) &&
                              report.retry_attempts < MAX_RETRY_ATTEMPTS
                            ) {
                              retryReportDownload(report.task_id);
                            }
                          }}
                        >
                          <div className="report-title d-flex-c-s align-center">
                            <div className={cx('report')}>
                              <span>
                                {report.task_status !==
                                  REPORTS_TASK_STATUS.SUCCEEDED ||
                                !report?.asset_name
                                  ? report.task_feature_name
                                  : ellipsizeText(report.asset_name, 35, false)}
                                {[
                                  REPORTS_TASK_STATUS.ERRORED,
                                  REPORTS_TASK_STATUS.FAILED_TO_PUBLISH,
                                ].includes(report.task_status) && (
                                  <span className="report-error ml8 text-10">
                                    Failed
                                  </span>
                                )}
                              </span>
                            </div>
                            {[
                              REPORTS_TASK_STATUS.ERRORED,
                              REPORTS_TASK_STATUS.FAILED_TO_PUBLISH,
                            ].includes(report.task_status) &&
                              report.retry_attempts < MAX_RETRY_ATTEMPTS && (
                                <SpinnerButton
                                  className="btn-outline-4 mr8 retry-button"
                                  onClick={() =>
                                    retryReportDownload(report.task_id)
                                  }
                                >
                                  <RefreshIcon height={14} />
                                  Retry
                                </SpinnerButton>
                              )}
                          </div>
                          {report.task_status ===
                            REPORTS_TASK_STATUS.QUEUED && (
                            <p className="text-4 c-gray-1">
                              Download will begin shortly.
                            </p>
                          )}
                          {report.task_status ===
                            REPORTS_TASK_STATUS.PICKED && (
                            <div className="report-progress">
                              <ProgressBar
                                classes={{
                                  root: 'spacer analytics__overview-progress-bar',
                                  colorPrimary:
                                    'analytics__overview-progress-bar--background',
                                  bar: 'analytics__overview-progress-bar--progress',
                                }}
                                variant="determinate"
                                value={report.task_progress}
                              />
                              <p className="report-progress-percentage mr8 mb2">
                                {Math.round(report.task_progress)}%
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="hr-line my12" />
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex-c-c flex-column gap8 mb16 px16 flex-1">
              <ViewAllReportsEmpty height={36} width={36} />
              <p className="c-gray-1 text-center">
                Downloaded reports can be viewed here.
              </p>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default withRouter(ViewReportsMenu);
