import React from 'react';
import cx from 'classnames';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  CUSTOMER_WALLET_KEY,
  LOYALTY_POINT_REFUND_KEY,
  REFUND_TYPES,
  SOURCE_METHOD_KEY,
  SOURCE_REFUND_KEY,
} from '../Account/constants';
import CustomRadio from '../shared/CustomRadio';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { modifyFormField, noop } from '../utils';
import { useCountry } from '../context/CountryProvider';
import { SuccessBadge } from '../shared/Badge';
import { FormNumberInput } from '../shared/Form';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

const REFUND_FULL = 'full';
const REFUND_PARTIAL = 'partial';

const InitiateRefundModal = ({
  open,
  closeModal,
  submitting = false,
  onSubmit = noop,
  orderPayments: { amount_available_for_refund: totalAmountForRefund = 0 } = {},
  preferredDestination,
  refundAmount,
  showRefundMode = true,
}) => {
  const { formatLocalMoney } = useCountry();
  const { enqueueSnackbar } = useCustomSnackbar();
  return (
    <Modal
      open={open}
      className="shipment-created-modal new-shipment-modal"
      closeModal={closeModal}
      maxWidth={showRefundMode ? 'md' : 'xs'}
    >
      <div className="shipment-created-modal">
        <h5 className="section-text-4 mb24">Initiate refund</h5>
        <Form
          keepDirtyOnReinitialize
          mutators={{ ...arrayMutators, modifyFormField }}
          onSubmit={(values) => {
            const payload = { refund_mode: values.refund_mode };
            if (
              values.refund_type === REFUND_PARTIAL &&
              values.amount_to_refund >= 0
            ) {
              payload.amount_to_refund = values.amount_to_refund;
            }
            onSubmit(payload);
          }}
          initialValues={{
            refund_mode:
              REFUND_TYPES[preferredDestination] ?? SOURCE_METHOD_KEY,
            refund_type: REFUND_FULL,
          }}
          render={({ handleSubmit, form, values }) => (
            <div>
              {showRefundMode && (
                <div>
                  <p className="mt24 mb10 section-text-7 c-gray-1 text-ucase">
                    Refund mode
                  </p>
                  <RadioGroup
                    aria-label="refund_mode"
                    name="refund_mode"
                    value={values.refund_mode}
                    className="d-flex mb24"
                    onChange={({ target: { name, value } }) => {
                      form.mutators.modifyFormField(name, value);
                    }}
                  >
                    <FormControlLabel
                      className="mb16"
                      control={<CustomRadio className="p0-i" />}
                      label={
                        <div className="d-flex align-center">
                          <span className="text-2 mr8">
                            Into customer’s source account
                          </span>
                          {preferredDestination === SOURCE_REFUND_KEY && (
                            <SuccessBadge text="Customer preferred" />
                          )}
                        </div>
                      }
                      value={SOURCE_METHOD_KEY}
                      checked={values.refund_mode === SOURCE_METHOD_KEY}
                    />
                    <FormControlLabel
                      control={<CustomRadio className="p0-i" />}
                      label={
                        <div className="d-flex align-center">
                          <span className="text-2 mr8">As loyalty points</span>
                          {preferredDestination ===
                            LOYALTY_POINT_REFUND_KEY && (
                            <SuccessBadge text="Customer preferred" />
                          )}
                        </div>
                      }
                      value={CUSTOMER_WALLET_KEY}
                      checked={values.refund_mode === CUSTOMER_WALLET_KEY}
                    />
                  </RadioGroup>
                </div>
              )}
              <p className="mt24 mb10 section-text-7 c-gray-1 text-ucase">
                Refund Amount
              </p>
              <RadioGroup
                aria-label="refund_type"
                name="refund_type"
                value={values.refund_type}
                className={cx('d-flex', {
                  mb24: values.refund_type === REFUND_FULL,
                })}
                onChange={({ target: { name, value } }) => {
                  form.mutators.modifyFormField(name, value);
                }}
              >
                <FormControlLabel
                  className="mb16"
                  control={<CustomRadio className="p0-i" />}
                  label={
                    <div className="d-flex align-center">
                      <span className="text-2 mr8">
                        Full refund of{' '}
                        {formatLocalMoney(refundAmount || totalAmountForRefund)}
                      </span>
                    </div>
                  }
                  value={REFUND_FULL}
                  checked={values.refund_type === REFUND_FULL}
                />
                <FormControlLabel
                  className="mb8"
                  control={<CustomRadio className="p0-i" />}
                  label={
                    <div className="d-flex align-center">
                      <span className="text-2 mr8">Custom amount</span>
                    </div>
                  }
                  value={REFUND_PARTIAL}
                  checked={values.refund_type === REFUND_PARTIAL}
                />
              </RadioGroup>
              {values.refund_type === REFUND_PARTIAL && (
                <FormNumberInput
                  className="mt12"
                  labelText=""
                  name="amount_to_refund"
                  placeholder="Enter custom amount"
                  required
                  showRequired
                  isAllowed={({ floatValue }) =>
                    floatValue === undefined ||
                    (floatValue <= (refundAmount || totalAmountForRefund) &&
                      floatValue >= 0)
                  }
                />
              )}
              <div className="d-flex-c-c">
                <SpinnerButton
                  showAnimation
                  type="button"
                  className="btn btn-success-4 mt8"
                  isLoading={submitting}
                  disabled={submitting}
                  onClick={() => {
                    if (
                      values.refund_type === REFUND_PARTIAL &&
                      !(values.amount_to_refund >= 0)
                    )
                      return enqueueSnackbar(
                        'Please enter the custom amount.',
                        'error'
                      );
                    handleSubmit();
                  }}
                >
                  Refund customer
                </SpinnerButton>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default InitiateRefundModal;
