import React from 'react';
import SpinnerButton from '../../../shared/SpinnerButton';
import { noop } from '../../../utils';

const ActionButtons = ({
  onSubmit = noop,
  isTopNav = false,
  isNewBundle = true,
  bundleDetails = {},
  initialValues = {},
  enqueueSnackbar = noop,
  handleDeleteClick,
  loading = false,
  submitting = false,
}) => (
  <>
    {!isNewBundle && isTopNav && (
      <SpinnerButton
        showAnimation
        type="button"
        className="btn btn-outline-4"
        onClick={() => {
          if (!bundleDetails.is_active) {
            enqueueSnackbar(
              'Sorry, you cannot preview a hidden product.',
              'error'
            );
          } else {
            window.open(bundleDetails.web_url, '_blank');
          }
        }}
      >
        Preview
      </SpinnerButton>
    )}

    {initialValues?.name && !isTopNav && (
      <SpinnerButton
        showAnimation
        type="button"
        className="btn-outline-danger-4 mr12"
        onClick={handleDeleteClick}
      >
        Delete
      </SpinnerButton>
    )}

    <SpinnerButton
      showAnimation
      type="button"
      className=" btn-primary-4 ml12"
      isLoading={loading || submitting}
      disabled={loading || submitting}
      onClick={onSubmit}
    >
      {!initialValues?.name ? 'Create' : 'Update'} bundle
    </SpinnerButton>
  </>
);

export default ActionButtons;
