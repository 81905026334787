import React from 'react';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { noop } from '../utils';
import { capitaliseFirst } from '../utils/string';

const ManualPaymentStatusModal = ({
  open = false,
  closeModal = noop,
  isLoading = false,
  onButtonClick = noop,
  selectedOption = {},
}) => (
  <Modal open={open} closeModal={closeModal} className="text-center">
    <div className="mb24">
      <p className="section-text-4 c-black-1 mb6">
        Change payment status to {capitaliseFirst(selectedOption?.label)}
      </p>
      <p className="text-2 c-black-3">
        Please note that this change cannot be undone later.
      </p>
    </div>
    <div>
      <SpinnerButton
        className="btn-primary-4 section-text-5"
        onClick={() => onButtonClick(selectedOption?.value)}
        isLoading={isLoading}
      >
        Change to {capitaliseFirst(selectedOption?.label)}
      </SpinnerButton>
    </div>
  </Modal>
);

export default ManualPaymentStatusModal;
