import cx from 'classnames';
import { CustomTooltip } from '../../../shared';

const InputDisabledTooltip = ({
  show = false,
  children,
  widthFull = true,
  title = '',
  tooltipProps,
  ...props
}) => {
  if (show) {
    return (
      <CustomTooltip title={title} placement="bottom" {...tooltipProps}>
        <span className={cx({ 'full-w': widthFull })} {...props}>
          {children}
        </span>
      </CustomTooltip>
    );
  }

  return children;
};

export default InputDisabledTooltip;
