import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import Modal from '../shared/Modal';
import { useSubscription } from '../context/SubscriptionProvider';
import InvoiceContent from '../Account/InvoiceContent';

const InvoiceDueModal = ({ open, ...modalProps }) => {
  const { dueInvoiceData } = useSubscription();

  const invoicePastDueDate = moment(dueInvoiceData?.due_date).isBefore(
    moment()
  );

  return (
    <div>
      <Modal
        open={open}
        {...modalProps}
        disableEscapeKeyDown
        disableBackdropClick
        paperClass="lg p24"
        className={cx({ 'expiry-modal': invoicePastDueDate })}
        showCloseIcon={!invoicePastDueDate}
      >
        <InvoiceContent type="modal" />
      </Modal>
    </div>
  );
};

export default InvoiceDueModal;
