import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOrdersContext } from '../context/OrdersProvider';
import { useStoreProfile } from '../context/StoreProfileProvider';
import { useSubscription } from '../context/SubscriptionProvider';
import { ExternalLink, YoutubeThumbnail } from '../shared';
import { isNotEmptyOrNull } from '../shared/Form/Shared';
import ImageLoaders from '../shared/ImageLoaders';
import SpinnerButton from '../shared/SpinnerButton';
import { rootUrls } from '../Urls';
import {
  ALL_STATUS,
  CANCELLED_BY_CUSTOMER,
  CANCELLED_STATUS,
  ORDER_STATUS_MAP,
} from './constants';
import { useAppContext } from '../context/AppContext';
import PremiumContentModal from '../Layout/PremiumContentModal';
import useModal from '../hooks/useModal';

export const NoOrderWithSvg = ({
  filters = [],
  loading = false,
  isCustomerDetailPage = false,
  isReturnPage = false,
  noDataContentType = '',
  search = '',
  status = null,
  overrideStatusText = '',
  hasCreateOrderAccess = true,
}) => {
  const { onboardingData } = useStoreProfile();
  const { orderParams } = useOrdersContext();
  const { isOnTrial, isFreePlan } = useSubscription();
  const { isOwner } = useAppContext();
  const history = useHistory();

  const [playerOpen, setPlayerOpen] = useState(false);
  const [closePlayer, setPlayerClose] = useState(false);
  const { isOpen, openModal, closeModal } = useModal();

  const [showYoutubeThumbnail, setShowYoutubeThumbnail] = useState(
    onboardingData?.show_order_tutorial_thumbnail ?? false
  );

  const isFilterApplied =
    Object.keys(orderParams.filters || {}).length > 0 ||
    orderParams.tags?.length > 0;

  let timeoutId;
  let statusText = '';
  if (isReturnPage) {
    statusText = '';
  } else if (
    Array.isArray(status) &&
    (status.includes(CANCELLED_STATUS) ||
      status.includes(CANCELLED_BY_CUSTOMER))
  ) {
    statusText = 'cancelled';
  } else if (status === ALL_STATUS) {
    statusText = '';
  } else {
    statusText = ORDER_STATUS_MAP[status] ?? '';
  }

  useEffect(
    () => () => {
      clearTimeout(timeoutId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="no-orders-list d-flex-c-c spacer ">
      <div
        className={cx('py48 text-center', { 'add-footer-height': isOnTrial })}
      >
        {overrideStatusText !== 'abandoned' ? (
          <ImageLoaders json="no_order" />
        ) : (
          <ImageLoaders json="no_abandon_order" />
        )}

        <h4 className="section-text-3 mb8 mt24 text-semibold">
          {search !== '' || Object.keys(filters).length > 0 ? (
            `No ${overrideStatusText || statusText} orders found`
          ) : (
            <>
              {!isNotEmptyOrNull({ value: noDataContentType }) &&
              !isNotEmptyOrNull({ value: overrideStatusText }) &&
              !isNotEmptyOrNull({ value: statusText })
                ? 'Orders will appear here'
                : //     : `${
                  //         noDataContentType
                  //           ? `${noDataContentType} doesn't`
                  //           : "You don't"
                  //       }
                  // have any ${overrideStatusText || statusText} orders.`
                  'Abandoned orders will appear here'}
            </>
          )}
        </h4>
        {!isCustomerDetailPage && (
          <>
            {status === ALL_STATUS && !search && (
              <>
                {overrideStatusText !== 'abandoned' ? (
                  <>
                    {hasCreateOrderAccess ? (
                      <>
                        {isFilterApplied ? (
                          <p className="text-2 c-black-3 mb32">
                            We could not find any order for the applied
                            filter(s). <br />
                            Please try a different filter
                          </p>
                        ) : (
                          <p className="text-2 c-black-3 mb32">
                            View and manage your orders here once received, or
                            start by <br /> creating a manual order.
                          </p>
                        )}

                        {!isFilterApplied && (
                          <>
                            <div className="d-flex-c-c">
                              <SpinnerButton
                                label="Create manual order"
                                type="button"
                                onClick={() => {
                                  if (isFreePlan) {
                                    openModal();
                                  } else {
                                    history.push(rootUrls.createOrder);
                                  }
                                }}
                              />
                            </div>
                            <div className="mt20 flex-row justify-center d-flex">
                              <ExternalLink
                                href="https://help.mydukaan.io/article/5064-how-to-use-the-orders-section"
                                className="underline c-purple-1-i c-h-purple-2-i section-text-8"
                              >
                                Learn more about orders
                              </ExternalLink>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="text-2 c-black-3 mb32">
                          Track and manage your orders here once you receive
                          them.
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="text-2 c-black-3 mb32">
                      View your abandoned orders and send automatic recovery{' '}
                      <br />
                      messages to regain your potential orders.
                    </p>

                    <div className="flex-row justify-center d-flex">
                      <ExternalLink
                        href="https://help.mydukaan.io/article/5001-how-to-use-the-abandoned-orders-section"
                        className="underline c-purple-1-i c-h-purple-2-i section-text-8"
                      >
                        Learn more about abandoned orders
                      </ExternalLink>
                    </div>
                  </>
                )}
              </>
            )}
            {search && (
              <p className="mt8">
                Please check the spelling or try searching for something else.
              </p>
            )}
          </>
        )}
      </div>

      {showYoutubeThumbnail && isOwner && !loading && !isFilterApplied && (
        <YoutubeThumbnail
          playerOpen={playerOpen}
          closePlayer={closePlayer}
          isOnTrial={isOnTrial}
          setPlayerOpen={setPlayerOpen}
          setPlayerClose={setPlayerClose}
          setShowYoutubeThumbnail={setShowYoutubeThumbnail}
          setTimeoutId={(id) => {
            timeoutId = id;
          }}
        />
      )}
      <PremiumContentModal open={isOpen} closeModal={closeModal} />
    </div>
  );
};

export const NoOrderWithoutSvg = ({
  noDataContent = '',
  status = '',
  overrideStatusText = '',
}) => (
  <div className="orders-not-found-without-svg">
    <p className="text-1 c-black-3 no-orders-text">
      {noDataContent || `You don't`} have any{' '}
      {overrideStatusText || ORDER_STATUS_MAP[status]} orders.
    </p>
  </div>
);
