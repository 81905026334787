export const CANVA_ALLOWED_ACTIONS = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  COPY: 'COPY',
  MODIFY: 'MODIFY',
  REORDER: 'REORDER',
};

export const CANVA_UNDO_ACTIONS_MAPPING = {
  ADD: 'DELETE',
  DELETE: 'ADD',
  COPY: 'DELETE',
  MODIFY: 'MODIFY',
  REORDER: 'REORDER',
};

export const INITIAL_SHAPES = {
  WHATSAPP: {
    'header-text': {
      key: 'header-text',
      id: 'header-text',
      type: 'textbox',
      x: 20,
      y: 32,
      text: '',
      fontSize: 35,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 250,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'paragraph-text': {
      key: 'paragraph-text',
      id: 'paragraph-text',
      type: 'textbox',
      x: 45,
      y: 210,
      text: '',
      fontSize: 20,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'footer-text': {
      key: 'footer-text',
      id: 'footer-text',
      type: 'textbox',
      x: 45,
      y: 380,
      text: 'SHOP FROM',
      fontSize: 16,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'store-link': {
      key: 'store-link',
      id: 'store-link',
      type: 'textbox',
      x: 20,
      y: 410,
      text: '',
      fontSize: 18,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 250,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
  },
  BUSINESS_CARD: {
    'header-text': {
      key: 'header-text',
      id: 'header-text',
      type: 'textbox',
      x: 58,
      y: 32,
      text: '',
      fontSize: 35,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 400,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'paragraph-text': {
      key: 'paragraph-text',
      id: 'paragraph-text',
      type: 'textbox',
      x: 158,
      y: 118,
      text: '',
      fontSize: 20,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'store-link': {
      key: 'store-link',
      id: 'store-link',
      type: 'textbox',
      x: 133,
      y: 231,
      text: '',
      fontSize: 20,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 250,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
  },
  STORE_BANNER: {
    'header-text': {
      key: 'header-text',
      id: 'header-text',
      type: 'textbox',
      x: 18,
      y: 32,
      text: '',
      fontSize: 35,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 400,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'paragraph-text': {
      key: 'paragraph-text',
      id: 'paragraph-text',
      type: 'textbox',
      x: 118,
      y: 130,
      text: '',
      fontSize: 20,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'footer-text': {
      key: 'footer-text',
      id: 'footer-text',
      type: 'textbox',
      x: 118,
      y: 340,
      text: 'SHOP FROM',
      fontSize: 16,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'store-link': {
      key: 'store-link',
      id: 'store-link',
      type: 'textbox',
      x: 93,
      y: 370,
      text: '',
      fontSize: 18,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 250,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
  },
};

export const NON_EDITABLE_INITIAL_SHAPES = {
  WHATSAPP: {
    'footer-text': {
      key: 'footer-text',
      id: 'footer-text',
      type: 'textbox',
      x: 45,
      y: 380,
      text: 'SHOP FROM',
      fontSize: 16,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'store-link': {
      key: 'store-link',
      id: 'store-link',
      type: 'textbox',
      x: 20,
      y: 410,
      text: '',
      fontSize: 18,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 250,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
  },
  STORE_BANNER: {
    'footer-text': {
      key: 'footer-text',
      id: 'footer-text',
      type: 'textbox',
      x: 118,
      y: 340,
      text: 'SHOP FROM',
      fontSize: 16,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 200,
      align: 'center',
      fontStyle: '',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
    'store-link': {
      key: 'store-link',
      id: 'store-link',
      type: 'textbox',
      x: 93,
      y: 370,
      text: '',
      fontSize: 18,
      fontFamily: 'GalanoGrotesque',
      fill: '#FFFFFF',
      scaleX: 1,
      scaleY: 1,
      width: 250,
      align: 'center',
      fontStyle: 'bold',
      opacity: 1,
      textDecoration: '',
      rotation: 0,
    },
  },
};
