export const ALL = -1;
export const GENERAL = 0;
export const PAYMENTS = 1;
export const ORDERS = 2;
export const DELIVERY = 3;
export const RETURNS = 4;
export const APP = 5;
export const PRICING = 6;

export const TUTORIAL_CATEGORY_NAMES = {
  [ALL]: 'All',
  [GENERAL]: 'General',
  [PAYMENTS]: 'Payouts',
  [ORDERS]: 'Orders',
  [APP]: 'Dukaan App',
  [PRICING]: 'Pricing',
};

export const getThumbnailUrl = (youtubeLink = '') => {
  var regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|shorts\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  var match = youtubeLink.match(regExp);
  const videoId = match && match[1].length === 11 ? match[1] : false;
  return 'https://img.youtube.com/vi/%s/maxresdefault.jpg'.replace(
    '%s',
    videoId
  );
};

export const VIDEO_FILTERS = [
  {
    value: ALL,
    label: 'All',
  },
  {
    value: GENERAL,
    label: 'General',
  },
  {
    value: PAYMENTS,
    label: 'Payouts',
  },
  {
    value: ORDERS,
    label: 'Orders',
  },
  {
    value: DELIVERY,
    label: 'Delivery',
  },
  {
    value: RETURNS,
    label: 'Returns',
  },
  {
    value: APP,
    label: 'Dukaan App',
  },
  {
    value: PRICING,
    label: 'Pricing',
  },
];

export const VIDEOS_DATASET = [
  {
    category: GENERAL,
    title: 'Grow your business online with Dukaan Premium',
    link: 'https://youtu.be/IaQlj9fQwSc',
    thumb: 'grow-business.jpg',
    hideInternational: true,
  },
  {
    category: GENERAL,
    title: 'Getting Started with Dukaan Premium',
    link: 'https://youtu.be/eMS_fiiWFYU',
    thumb: 'dukaan-premium.jpg',
    hideInternational: true,
  },
  {
    category: APP,
    title: 'How to use Dukaan Delivery?',
    link: 'https://youtu.be/x3Txy3SELbs',
    thumb: 'dukaan-delivery.jpg',
    hideInternational: true,
  },
  {
    category: APP,
    title: 'How to use Dukaan Credits?',
    link: 'https://www.youtube.com/watch?v=6wE6QpJfMkg',
    thumb: 'dukaan-credits.jpg',
    hideInternational: true,
  },
  {
    category: GENERAL,
    title: 'How to create Marketing Campaigns?',
    link: 'https://youtu.be/F0NBCAedjOs',
    thumb: 'marketing.jpg',
    hideInternational: true,
  },
  {
    category: GENERAL,
    title: 'What is Dukaan Plus?',
    link: 'https://youtu.be/-kpvoS45ctk',
    thumb: 'reseller.jpg',
    hideInternational: true,
  },
  {
    category: GENERAL,
    title: 'How to set up Store Timings?',
    link: 'https://www.youtube.com/watch?v=pcTaCQ72aDs',
    thumb: 'store-timings.jpg',
    hideInternational: true,
  },
  {
    category: APP,
    title: 'How to create Categories?',
    link: 'https://www.youtube.com/watch?v=g8Ez1S3746M',
    thumb: 'categories.jpg',
  },
  {
    category: APP,
    title: 'How to bulk add products?',
    link: 'https://www.youtube.com/watch?v=HWmmB6uBQnc',
    thumb: 'bulk-upload.jpg',
  },
  {
    category: PAYMENTS,
    title: 'How to activate Online Payments?',
    link: 'https://www.youtube.com/watch?v=AsK9Bz8gNIE',
    thumb: 'online-payments.jpg',
    hideInternational: true,
  },
  {
    category: ORDERS,
    title: 'How to create Discount Coupons?',
    link: 'https://www.youtube.com/watch?v=ss_99M0QT8c',
    thumb: 'coupons.jpg',
  },
  {
    category: APP,
    title: 'How to get your Customers Database?',
    link: 'https://www.youtube.com/watch?v=jbzCwOWoaDw',
    thumb: 'customer-list.jpg',
  },
  {
    category: GENERAL,
    title: 'How to generate Store QR Code?',
    link: 'https://www.youtube.com/watch?v=TH2hw8SfVic',
    thumb: 'store-qr.jpg',
  },
  {
    category: PRICING,
    title: 'How to add Delivery, GST & Extra Charges?',
    link: 'https://www.youtube.com/watch?v=o_8l4d1mm8A',
    thumb: 'extra-charges.jpg',
  },
  {
    category: ORDERS,
    title: 'How to use Order Form?',
    link: 'https://www.youtube.com/watch?v=TEHDJX8Uywo',
    thumb: 'order-form.jpg',
  },
  {
    category: APP,
    title: 'How to add Custom Pages?',
    link: 'https://www.youtube.com/watch?v=RwvseTj1vLU',
    thumb: 'pages.jpg',
  },
  {
    category: GENERAL,
    title: 'How to change Store Link?',
    link: 'https://www.youtube.com/watch?v=rJI1Krs-VcQ',
    thumb: 'store-link.jpg',
  },
  {
    category: APP,
    title: 'How to use Marketing Designs?',
    link: 'https://www.youtube.com/watch?v=Yl3_eoHrROk',
    thumb: 'marketing-designs.jpg',
  },
  {
    category: APP,
    title: 'How to change App Language?',
    link: 'https://www.youtube.com/watch?v=0IooWabHxxI',
    thumb: 'app-language.jpg',
    hideInternational: true,
  },
  {
    category: APP,
    title: 'How to add Product Variants?',
    link: 'https://www.youtube.com/watch?v=u2aklAo8kkY',
    thumb: 'product-variants.jpg',
  },
  {
    category: APP,
    title: 'How to install All-in-one SEO Plugin?',
    link: 'https://www.youtube.com/watch?v=4pJec2sBKbU',
    thumb: 'seo-plugin.jpg',
  },
  {
    category: APP,
    title: 'How to add Menus?',
    link: 'https://www.youtube.com/watch?v=HW73zp7uWc8',
    thumb: 'menus.jpg',
  },
  {
    category: APP,
    title: 'How to add Custom Domain?',
    link: 'https://www.youtube.com/watch?v=hcIJ2HPQspY',
    thumb: 'custom-domain.jpg',
  },
  {
    category: APP,
    title: 'How to change Themes?',
    link: 'https://www.youtube.com/watch?v=rcmTJ3n7MH8',
    thumb: 'themes.jpg',
  },
  {
    category: GENERAL,
    title: 'How to join Dukaan VIP Group?',
    link: 'https://www.youtube.com/watch?v=5-ibb_a_DbY',
    thumb: 'dukaan-vip.jpg',
  },
];
