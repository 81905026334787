import React from 'react';
import cx from 'classnames';
import { FieldInfo } from './Shared';
import { noop } from '../../utils';

const FormGroup = (props) => {
  const {
    labelText,
    required,
    size,
    className,
    helpText,
    badgeOnRight,
    children,
    showRequired,
    charLimitText,
    charLimitAtBottom,
    onClick = noop,
  } = props;
  const wrapperClasses = cx('form-group', className, `form-group-${size}`);
  const labelClasses = cx('field-label', { required });

  return (
    <div
      className={wrapperClasses}
      onClick={() => {
        onClick();
      }}
    >
      {!!labelText && (
        <label className={labelClasses}>
          {labelText}
          {required && showRequired && <span className="c-red-1"> *</span>}
          {!charLimitAtBottom && !!charLimitText && (
            <span className="char-limit-text pull-right">{charLimitText}</span>
          )}
          {!!badgeOnRight && badgeOnRight}
        </label>
      )}
      {children}
      {!!helpText && <FieldInfo helpText={helpText} />}
      {charLimitAtBottom && charLimitText && (
        <span className="char-limit-text pull-right mt8">{charLimitText}</span>
      )}
    </div>
  );
};

FormGroup.defaultProps = {
  labelText: '',
  showRequired: false,
  required: false,
  size: 'lg', // md
  className: '',
  helpText: '',
  charLimitText: null,
  charLimitAtBottom: false,
};

export default FormGroup;
