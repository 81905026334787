import React from 'react';
import TopCategoriesIcon from '../../images/top-categories-section-icon.svg';
import FeaturedProductIcon from '../../images/featured-product-section-icon.svg';
import ImageOverlayIcon from '../../images/image-overlay-section-icon.svg';
import TestimonialsIcon from '../../images/testimonials-section-icon.svg';
import BrandStoryIcon from '../../images/brandstory-section-icon.svg';
import NewsletterIcon from '../../images/newsletter-section-icon.svg';

const SOCIAL_LINKS_MAP = {
  facebookUrl: 'https://facebook.com/',
  twitterUrl: 'https://twitter.com/',
  instagramUrl: 'https://instagram.com/',
  linkedinUrl: 'https://linkedin.com/in/',
  youtubeUrl: 'https://youtube.com/',
  pinterestUrl: 'https://pinterest.com/',
};

export const ENTERPRISE_THEME_CTA = 'https://mydukaan.page.link/dukaan-themes';

export const LUXURY_APPEARENCE_THEME = 'luxury';
export const LIGHTSPEED_APPEARENCE_THEME = 'lightspeed';
export const D2C_APPEARENCE_THEME = 'd2c';
export const LEO = 'littleleo';
export const MATRIX_THEME = 'matrix';
export const MANA_THEME = 'vogue';
export const OFF_DUTY_THEME = 'offduty';
export const STYLEUP_THEME = 'styleup';
export const LUXURY = 'luxury';
export const URSA = 'ursa';
export const ENIGMA = 'enigma';
export const TINKER = 'tinker';
export const BRACER = 'bracer';
export const CATALYST = 'catalyst';
export const DAVID = 'david';
export const LINKEN = 'linken';
export const METEORA = 'meteora';
export const PALADIN = 'paladin';
export const SAGE = 'sage';
export const YASHA = 'yasha';
export const ZENITH = 'zenith';

export const THEME_NAMES_MAP = {
  [LIGHTSPEED_APPEARENCE_THEME]: 'Lightspeed',
  [MATRIX_THEME]: 'Matrix',
  [LEO]: 'Leo',
  [D2C_APPEARENCE_THEME]: 'Nirvana',
  [MANA_THEME]: 'Mana',
  [LUXURY]: 'Oxford',
  [URSA]: 'Ursa',
  [ENIGMA]: 'Enigma',
  [TINKER]: 'Tinker',
};

export const THEME_KEY_MAP = {
  OXFORD: LUXURY,
  LIGHTSPEED_APPEARENCE_THEME,
  D2C_APPEARENCE_THEME,
  LEO,
  MATRIX_THEME,
  MANA_THEME,
  OFF_DUTY_THEME,
  STYLEUP_THEME,
  URSA,
  ENIGMA,
  TINKER,
};

export const ENTERPRISE_THEMES_BLACKLISTED_STORES = [
  102167053, // ravi products,
  102196829, // nutkets
  102003183, // gini and joni
  101959141, // vectorx
  101954780, // chai point
  102180545, // healthxpprod
  102125067, // bighaat
  102192029, // bioayurveda
  102273998, // truke
  102165663, // jaipur mart
  102287394, // lemonade
  102202974,
  102299046, // uppercase
  102299051, // bodycupid
  102299052, // wow
  102299050, // wow 2
  102170163, // offduty
];

export const ENTERPRISE_THEMES = [
  {
    id: 'bracer',
    price: 0,
    preview_url: 'https://mydukaan.io/bracer',
    name: 'Bracer',
    thumbnail: 'bracer-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  {
    id: 'catalyst',
    price: 0,
    preview_url: 'https://mydukaan.io/catalystshop',
    name: 'Catalyst',
    thumbnail: 'catalyst-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  {
    id: 'david',
    price: 0,
    preview_url: 'https://beta.bighaat.com/',
    name: 'David',
    thumbnail: 'david-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  {
    id: 'linken',
    price: 0,
    preview_url: 'https://healthxp.in/',
    name: 'Linken',
    thumbnail: 'linken-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  // {
  //   id: 'luna',
  //   price: 0,
  //   preview_url: 'https://mydukaan.io/ginijony',
  //   name: 'Luna',
  //   thumbnail: 'luna-theme.png',
  //   is_active: false,
  //   default_meta: { enterprise: true },
  // },
  {
    id: 'meteora',
    price: 0,
    preview_url: 'https://mydukaan.io/truke1',
    name: 'Meteora',
    thumbnail: 'meteora-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  {
    id: 'paladin',
    price: 0,
    preview_url: 'https://mydukaan.io/bioayurveda',
    name: 'Paladin',
    thumbnail: 'paladin-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  // {
  //   id: 'reaver',
  //   price: 0,
  //   preview_url: 'https://chaipointshop.com',
  //   name: 'Reaver',
  //   thumbnail: 'reaver-theme.png',
  //   is_active: false,
  //   default_meta: { enterprise: true },
  // },
  // {
  //   id: 'riki',
  //   price: 0,
  //   preview_url: 'https://vector-x.com',
  //   name: 'Riki',
  //   thumbnail: 'riki-theme.png',
  //   is_active: false,
  //   default_meta: { enterprise: true },
  // },
  {
    id: 'sage',
    price: 0,
    preview_url: 'https://mydukaan.io/manrevive2',
    name: 'Sage',
    thumbnail: 'sage-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  {
    id: 'yasha',
    price: 0,
    preview_url: 'https://nutkets.com/',
    name: 'Yasha',
    thumbnail: 'yasha-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
  {
    id: 'zenith',
    price: 0,
    preview_url: 'https://mydukaan.io/jaipurmart3',
    name: 'Zenith',
    thumbnail: 'zenith-theme.png',
    is_active: false,
    default_meta: { enterprise: true },
  },
];

export const ACTIVE_THEME_ID = {
  LIGHTSPEED: 1,
  MATRIX: 2,
  LEO: 3,
  OXFORD: 4,
  NIRVANA: 6,
  OFF_DUTY: 11,
  URSA: 28,
  ENIGMA: 25,
};

export const generateFullSiteUrl = (value, key) => {
  if (value && value !== '') {
    return `${SOCIAL_LINKS_MAP[key]}${value}`;
  }
  return '';
};

export const getUsername = (url = '') => {
  if (!url) {
    return '';
  }
  const parts = url.split('/'); // assuming any new urls added have a .com tld
  if (parts.length > 1) {
    return parts[parts.length - 1];
  }
  return '';
};

export const getFontsAndColors = ({
  PRIMARY: primary,
  SECONDARY: secondary,
  MENU_BAR: menuBar,
  NAME: name,
  URL: url,
}) => ({
  color: {
    primary,
    secondary,
    menuBar,
  },
  font: {
    name,
    url,
  },
});

export const CUSTOMIZE_THEME_TABS = {
  BACKGROUND: 'background',
  FOOTER: 'footer',
  FONTSANDCOLORS: 'fontsAndColors',
  HEADER: 'header',
  BANNER: 'banners',
  SECTIONS: 'sections',
  ADVANCED: 'advanced',
};

export const NO_COLOR_THEMES = ['luxury'];

export const COLOR_THEME_MAP = {
  [D2C_APPEARENCE_THEME]: {
    key: D2C_APPEARENCE_THEME,
    default: {
      menuBar: '#F1F2EA',
      primary: '#7D3658',
      secondary: '#EE741F',
    },
    presetColors: [
      // [primaryColor, secondaryColor, menuBarColor]
      ['#146EB4', '#EE741F', '#146EB4'],
      ['#206A5D', '#81B214', '#F58634'],
      ['#073642', '#7D6005', '#55AE51'],
      ['#EB5C33', '#1A0D3F', '#E37400'],
      ['#003580', '#0896FF', '#D3941E'],
      ['#E46D47', '#F21170', '#72147E'],
      ['#346751', '#C84B31', '#161616'],
      ['#09454F', '#FF4E75', '#112035'],
      ['#6534AC', '#FF7800', '#2D364C'],
      ['#730947', '#1164A3', '#2BAC76'],
      ['#3B4064', '#E51E57', '#00A5AE'],
      ['#7D3658', '#EE741F', '#F1F2EA'],
      ['#e27d73', '#232225', '#FFFFFF'],
      ['#CD004B', '#990038', '#FFFFFF'],
    ],
  },
  [D2C_APPEARENCE_THEME]: {
    key: D2C_APPEARENCE_THEME,
    default: {
      menuBar: '#F1F2EA',
      primary: '#e27d73',
      secondary: '#232225',
    },
    presetColors: [
      // [primaryColor, secondaryColor, menuBarColor]
      ['#146EB4', '#EE741F', '#146EB4'],
      ['#206A5D', '#81B214', '#F58634'],
      ['#073642', '#7D6005', '#55AE51'],
      ['#EB5C33', '#1A0D3F', '#E37400'],
      ['#003580', '#0896FF', '#D3941E'],
      ['#E46D47', '#F21170', '#72147E'],
      ['#346751', '#C84B31', '#161616'],
      ['#09454F', '#FF4E75', '#112035'],
      ['#6534AC', '#FF7800', '#2D364C'],
      ['#730947', '#1164A3', '#2BAC76'],
      ['#3B4064', '#E51E57', '#00A5AE'],
      ['#7D3658', '#EE741F', '#F1F2EA'],
      ['#e27d73', '#232225', '#FFFFFF'],
      ['#CD004B', '#990038', '#FFFFFF'],
    ],
  },
};

export const DEFAULT_COLORS = {
  PRIMARY: '#146EB4',
  SECONDARY: '#EE741F',
  MENU_BAR: '#146EB4',
};

export const D2C_DEFAULT_COLOR = {
  menuBar: '#F1F2EA',
  primary: '#7D3658',
  secondary: '#EE741F',
};

export const DEFAULT_FONT = {
  NAME: 'GalanoGrotesque',
  URL: '',
};

export const PRESET_COLORS = [
  // [primaryColor, secondaryColor, menuBarColor]
  ['#146EB4', '#EE741F', '#146EB4'],
  ['#206A5D', '#81B214', '#F58634'],
  ['#073642', '#7D6005', '#55AE51'],
  ['#EB5C33', '#1A0D3F', '#E37400'],
  ['#003580', '#0896FF', '#D3941E'],
  ['#E46D47', '#F21170', '#72147E'],
  ['#346751', '#C84B31', '#161616'],
  ['#09454F', '#FF4E75', '#112035'],
  ['#6534AC', '#FF7800', '#2D364C'],
  ['#730947', '#1164A3', '#2BAC76'],
  ['#3B4064', '#E51E57', '#00A5AE'],
  ['#7D3658', '#EE741F', '#F1F2EA'], // Nirvana default colors
  ['#A14C21', '#1A181E', '#FFFFFF'], // Mana default colors
  ['#1A181E', '#2E3193', '#F1F2EA'], // Enigma default colors
  ['#e27d73', '#232225', '#FFFFFF'], // Ursa default colors
  ['#CD004B', '#990038', '#FFFFFF'], // Tinker default colors
];

export const DEFAULT_FAVICON_URL = 'https://mydukaan.io/favicon.png';
export const GALANO_GROTESQUE_REGULAR_FONT_NAME = 'GalanoGrotesque';
export const GALANO_GROTESQUE_REGULAR_FONT_URL =
  'https://mydukaan.io/fonts/GalanoGrotesque/galanogrotesque-medium.otf';

export const LUXURY_THEME_APPEARENCE = {
  LIGHT: 'light',
  DIM: 'dim',
  DARK: 'dark',
};

export const DEFAULT_THEMES_WIDGET_TITLE = {
  header: { title: 'Header & Favicon', key: 'header', index: 1 },
  fontsAndColors: {
    title: 'Fonts & Colors',
    key: 'fontsAndColors',
    index: 5,
  },
  fonts: {
    title: 'Fonts',
    key: 'fontsAndColors',
    index: 5,
  },
  banner: { title: 'Banners', key: 'banners', index: 2 },
  background: { title: 'Background', key: 'background', index: 0 },
  sections: { title: 'Sections', key: 'sections', index: 4 },
  advanced: { title: 'Advanced', key: 'advanced', index: 7 },
};

export const LUXURY_THEME = {
  theme_name: 'Luxury',
  key: 'luxury',
  widgets: [
    { title: 'Background', key: 'background' },
    { title: 'Header', key: 'header' },
    { title: 'Footer', key: 'footer' },
    { title: 'Sections', key: 'sections' },
    { title: 'Fonts & Colors', key: 'fontsAndColors' },
    { title: 'Banners', key: 'banners' },
  ],
  widgets_details: [
    {
      key: 'header',
      components: [
        {
          key: 'store_name',
          default: {
            show_store_image_only: true,
          },
        },
        {
          key: 'header_banner',
        },
      ],
    },
    {
      key: 'footer',
      components: [
        {
          key: 'highlights',
          default: {
            delivery_happens_with_in: '30-60 minutes',
            cx_support: 'buyersupport@mydukaan.io',
          },
        },
        {
          key: 'social_profiles',
          default: {
            facebook_url: '',
            twitter_url: '',
            instagram_url: '',
            linkedin_url: '',
            youtube_url: '',
            pinterest_url: '',
          },
        },
      ],
    },
    {
      key: 'colors',
      components: [
        {
          key: 'theme_colors',
          default: {
            primary_color: '#146eb4',
            secondary_color: '#EE741F',
            menu_bar_color: '#146eb4',
          },
        },
      ],
    },
    {
      key: 'sections',
      components: [
        {
          key: 'image_overlay',
          default: {
            title: 'Image with text overlay',
            description:
              'Use overlay text to give your customers insight into your brand. Select imagery and text that relates to your style and story.',
            cta_label: 'Know more',
            bg_image: '',
            link: '',
          },
        },
        {
          key: 'brand_story',
          default: {
            title: 'Image with text overlay',
            description:
              'Use overlay text to give your customers insight into your brand. Select imagery and text that relates to your style and story.',
            cta_label: 'Know more',
            bg_image: '',
            link: '',
          },
        },
        {
          key: 'featured_product',
        },
        {
          key: 'testimonial',
          default: [
            {
              name: "Customer's Name",
              message:
                "Add customer reviews and testimonials to showcase your store's happy customers.",
            },
            {
              name: "Customer's Name",
              message:
                "Add customer reviews and testimonials to showcase your store's happy customers.",
            },
            {
              name: "Customer's Name",
              message:
                "Add customer reviews and testimonials to showcase your store's happy customers.",
            },
          ],
        },
        {
          key: 'newsletter',
          default: {
            title: 'Sign up & save',
            description:
              'Be updated on new arrivals, trends and offers. Sign up now!',
          },
        },
      ],
    },
    {
      key: 'favicon',
      components: [
        {
          key: 'favicon',
          default: {
            favicon_url: '',
          },
        },
      ],
    },
  ],
};

export const THEME_META = {
  background: { mode: 'light' },
  header: {
    showStoreImageOnly: false,
  },
  footer: {
    highlights: {
      cxSupport: '',
      deliveryHappensWithIn: '',
    },
    socialProfiles: {
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      linkedinUrl: '',
      pinterestUrl: '',
      youtubeUrl: '',
    },
  },
  fontsAndColors: {
    colors: {
      primary: '',
      secondary: '',
      menuBar: '',
    },
    font: {
      url: '',
      name: '',
    },
  },
  favicon: {
    faviconUrl: DEFAULT_FAVICON_URL,
  },
  sections: {
    featureProduct: {
      isActive: false,
      products: [],
    },
    brandStory: {
      isActive: false,
      brandStoryImageUrl: '',
      title: 'Your article title goes here',
      description:
        'The article description will help your customers get a better understanding of your product.',
      ctaLabel: 'Know more',
      ctaLink: '',
    },
    imageOverlay: {
      isActive: false,
      overlayBackgroundImageUrl: '',
      title: 'Image with text overlay',
      description:
        'Use overlay text to give your customers insight into your brand. Select imagery and text that relates to your style and story.',
      ctaLabel: 'Know more',
      ctaLink: '',
    },
    newsletter: {
      isActive: false,
      headerText: 'Sign up & save',
      description:
        'Be updated on new arrivals, trends and offers. Sign up now!',
    },
    testimonials: {
      isActive: false,
      list: [
        {
          customerReview: '',
          customerName: '',
        },
        {
          customerReview: '',
          customerName: '',
        },
        {
          customerReview: '',
          customerName: '',
        },
      ],
    },
  },
  sectionsOrdering: [],
};

export const getThemeConfig = (theme = '') => {
  switch (theme) {
    case LEO:
    case MATRIX_THEME:
    case LIGHTSPEED_APPEARENCE_THEME:
      return {
        CROP: {
          aspect: 360 / 151,
          height: 151,
          width: 360,
          unit: 'px',
        },
        MODAL: { maxWidth: 'sm', paperClass: 'sm' },
        MODAL_MOBILE: { maxWidth: 'xm', paperClass: 'xm' },
        IMAGE: {
          height: '298px',
          width: 'auto',
          objectFit: 'contain',
          overflow: 'hidden',
          maxWidth: '100%',
          maxHeight: '100%',
        },
        IMAGE_MOBILE: {
          height: '316px',
          width: 'auto',
          objectFit: 'contain',
          overflow: 'hidden',
          maxWidth: '100%',
          maxHeight: '100%',
        },
        RES_TEXT: 'Recommended size (1296px*544px)',
        RES_TEXT_MOBILE: 'Recommended size (360px*324px)',
      };

    default:
      return {
        CROP: {},
        MODAL: { maxWidth: 'sm', paperClass: 'sm' },
        MODAL_MOBILE: { maxWidth: 'xm', paperClass: 'xm' },
        IMAGE: {
          height: '298px',
          width: 'auto',
          objectFit: 'contain',
          overflow: 'hidden',
          maxWidth: '100%',
          maxHeight: '100%',
        },
        IMAGE_MOBILE: {
          height: '316px',
          width: 'auto',
          objectFit: 'contain',
          overflow: 'hidden',
          maxWidth: '100%',
          maxHeight: '100%',
        },
        RES_TEXT: 'Recommended size (1296px*544px)',
        RES_TEXT_MOBILE: 'Recommended size (360px*324px)',
      };
  }
};

export const FEATURED_BRANDS_ARRAY = {
  [D2C_APPEARENCE_THEME]: Array.from({ length: 6 }, (_, i) => ({
    id: i + 1,
    url: '',
  })),
  [URSA]: Array.from({ length: 3 }, (_, i) => ({ id: i + 1, url: '' })),
  [TINKER]: Array.from({ length: 3 }, (_, i) => ({ id: i + 1, url: '' })),
};

const textStyle = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '36px',
  width: '100%',
};

export const THEME_DATA = {
  [LUXURY]: {
    text: `Give your store a unique and premium feel with Oxford Pro`,
    slides: [
      <div className="flex-center slides-container">
        <img
          alt="lightspeed-pro-theme"
          src="/images/appearance/luxury-theme-appearance.png"
          className="theme-image"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Choose from 3 color modes (Light, Dim & Dark)
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/Single product Section.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Showcase top products with featured sections
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/Testimonials.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Build trust among new buyers with customer testimonials
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/Newsletter.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Reach out to customers with new offers, deals & promotions
        </p>
      </div>,
    ],
  },
  [D2C_APPEARENCE_THEME]: {
    text: `A stylish theme with particular attention to branding and visual storytelling.`,
    slides: [
      <div className="flex-center slides-container">
        <img
          alt="lightspeed-pro-theme"
          src="/images/appearance/nirvana-image-1.png"
          className="theme-image"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Enhance your customer experience with fresh and bright design.
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/nirvana-image-2.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Let your brand personality shine through the story section.
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/nirvana-image-3.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Build trust with your brand featured in media platforms.
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/nirvana-image-4.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Build trust among new buyers with customer testimonials.
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/nirvana-image-5.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Theme setup steps are minimal to allow for quick launch.
        </p>
      </div>,
    ],
  },
  [URSA]: {
    text: `Sleek and modern design perfect for stores selling a single product.`,
    slides: [
      <div className="flex-center slides-container">
        <img
          alt="lightspeed-pro-theme"
          src="/images/appearance/ursa-image-1.png"
          className="theme-image"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Best suited for single product stores.
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/ursa-image-2.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Showcase top products with featured video.
        </p>
      </div>,
      <div className="flex-center">
        <img
          width="85%"
          alt="lightspeed-pro-theme"
          src="/images/appearance/ursa-image-3.png"
        />
        <p style={textStyle} className="section-text-3 text-center">
          Build trust among new buyers with customer & brand testimonials
        </p>
      </div>,
    ],
  },
};

export const SORTABLE_SECTIONS = [
  {
    key: 'promoBanners',
    image: '/images/placeholder-image.jpg',
    name: 'Promo banners',
    isSortable: false,
    showTooltip: false,
    showLast: false,
    isFixed: true,
    id: 1,
  },
  {
    key: 'topCategories',
    image: TopCategoriesIcon,
    name: 'Top categories',
    isSortable: false,
    showTooltip: false,
    showLast: false,
    isFixed: true,
    id: 2,
  },
  {
    key: 'featureProduct',
    image: FeaturedProductIcon,
    name: 'Featured product',
    isSortable: true,
    showTooltip: false,
    showLast: false,
    id: 3,
    isFixed: false,
  },
  {
    key: 'imageOverlay',
    image: ImageOverlayIcon,
    name: 'Image with overlay',
    isSortable: true,
    showTooltip: false,
    showLast: false,
    id: 4,
    isFixed: false,
  },
  {
    key: 'testimonials',
    image: TestimonialsIcon,
    name: 'Testimonials',
    isSortable: true,
    showTooltip: false,
    showLast: false,
    id: 5,
    isFixed: false,
  },
  {
    key: 'brandStory',
    image: BrandStoryIcon,
    name: 'Brand story',
    isSortable: true,
    showTooltip: false,
    showLast: false,
    id: 6,
    isFixed: false,
  },
  {
    key: 'newsletter',
    image: NewsletterIcon,
    name: 'Newsletter',
    isSortable: false,
    showTooltip: false,
    showLast: true,
    id: 7,
    isFixed: true,
  },
  {
    key: 'imageWithText',
    image: '/images/placeholder-image.jpg',
    name: 'Image with text',
    isSortable: true,
    showTooltip: false,
    showLast: false,
    id: 8,
    isFixed: false,
  },
];
export const CUSTOMIZE_HOW_TO_USE_LINK_MAPPING = {
  [LUXURY]:
    'https://help.mydukaan.io/en/articles/5648852-how-to-use-the-oxford-pro-theme',
  [D2C_APPEARENCE_THEME]:
    'https://help.mydukaan.io/en/articles/6077884-how-to-use-the-nirvana-theme',
  [MANA_THEME]:
    'https://help.mydukaan.io/en/articles/6550500-how-to-use-the-mana-theme',
  [URSA]:
    'https://help.mydukaan.io/en/articles/6903746-how-to-use-the-ursa-theme',
};

export const FONTS = [
  {
    label: 'Roboto',
    value: {
      name: 'Roboto',
      url: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
    },
  },
  {
    label: 'Abel',
    value: {
      name: 'Abel',
      url: 'https://fonts.googleapis.com/css2?family=Abel:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
    },
  },
  {
    label: 'Poppins',
    value: {
      name: 'Poppins',
      url: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'IBM Plex Sans',
    value: {
      name: 'IBM Plex Sans',
      url: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Open Sans',
    value: {
      name: 'Open Sans',
      url: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Montserrat',
    value: {
      name: 'Montserrat',
      url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Oswald',
    value: {
      name: 'Oswald',
      url: 'https://fonts.googleapis.com/css2?family=Oswald:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Rubik',
    value: {
      name: 'Rubik',
      url: 'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Inter',
    value: {
      name: 'Inter',
      url: 'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Domine',
    value: {
      name: 'Domine',
      url: 'https://fonts.googleapis.com/css2?family=Domine:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Ubuntu',
    value: {
      name: 'Ubuntu',
      url: 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Work Sans',
    value: {
      name: 'Work Sans',
      url: 'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Mukta',
    value: {
      name: 'Mukta',
      url: 'https://fonts.googleapis.com/css2?family=Mukta:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Barlow',
    value: {
      name: 'Barlow',
      url: 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Bitter',
    value: {
      name: 'Bitter',
      url: 'https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
  {
    label: 'Frank Ruhl Libre',
    value: {
      name: 'Frank Ruhl Libre',
      url: 'https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
  },
];

export const ALL_GOOGLE_FONTS = [
  'ABeeZee',
  'Abel',
  'Abril Fatface',
  'Aclonica',
  'Acme',
  'Actor',
  'Adamina',
  'Advent Pro',
  'Aguafina Script',
  'Akronim',
  'Aladin',
  'Aldrich',
  'Alef',
  'Alegreya',
  'Alegreya SC',
  'Alegreya Sans',
  'Alegreya Sans SC',
  'Alex Brush',
  'Alfa Slab One',
  'Alice',
  'Alike',
  'Alike Angular',
  'Allan',
  'Allerta',
  'Allerta Stencil',
  'Allura',
  'Almendra',
  'Almendra Display',
  'Almendra SC',
  'Amarante',
  'Amaranth',
  'Amatic SC',
  'Amethysta',
  'Amiri',
  'Anaheim',
  'Andada',
  'Andika',
  'Angkor',
  'Annie Use Your Telescope',
  'Anonymous Pro',
  'Antic',
  'Antic Didone',
  'Antic Slab',
  'Anton',
  'Arapey',
  'Arbutus',
  'Arbutus Slab',
  'Architects Daughter',
  'Archivo Black',
  'Archivo Narrow',
  'Arimo',
  'Arizonia',
  'Armata',
  'Artifika',
  'Arvo',
  'Asap',
  'Asset',
  'Assistant',
  'Astloch',
  'Asul',
  'Atomic Age',
  'Aubrey',
  'Audiowide',
  'Autour One',
  'Average',
  'Average Sans',
  'Averia Gruesa Libre',
  'Averia Libre',
  'Averia Sans Libre',
  'Averia Serif Libre',
  'Bad Script',
  'Balthazar',
  'Bangers',
  'Barlow',
  'Basic',
  'Battambang',
  'Baumans',
  'Bayon',
  'Belgrano',
  'Belleza',
  'BenchNine',
  'Bentham',
  'Berkshire Swash',
  'Bevan',
  'Bigelow Rules',
  'Bigshot One',
  'Bilbo',
  'Bilbo Swash Caps',
  'Biryani',
  'Bitter',
  'Black Ops One',
  'Bokor',
  'Bonbon',
  'Boogaloo',
  'Borel',
  'Bowlby One',
  'Bowlby One SC',
  'Brawler',
  'Bree Serif',
  'Bubblegum Sans',
  'Bubbler One',
  'Buda',
  'Buenard',
  'Butcherman',
  'Butterfly Kids',
  'Cabin',
  'Cabin Condensed',
  'Cabin Sketch',
  'Caesar Dressing',
  'Cagliostro',
  'Calligraffitti',
  'Cambay',
  'Cambo',
  'Candal',
  'Cantarell',
  'Cantata One',
  'Cantora One',
  'Capriola',
  'Cardo',
  'Carme',
  'Carrois Gothic',
  'Carrois Gothic SC',
  'Carter One',
  'Caudex',
  'Cedarville Cursive',
  'Ceviche One',
  'Changa One',
  'Chango',
  'Chau Philomene One',
  'Chela One',
  'Chelsea Market',
  'Chenla',
  'Cherry Cream Soda',
  'Cherry Swash',
  'Chewy',
  'Chicle',
  'Chivo',
  'Cinzel',
  'Cinzel Decorative',
  'Clicker Script',
  'Coda',
  'Coda Caption',
  'Codystar',
  'Combo',
  'Comfortaa',
  'Coming Soon',
  'Concert One',
  'Condiment',
  'Content',
  'Contrail One',
  'Convergence',
  'Cookie',
  'Copse',
  'Corben',
  'Courgette',
  'Cousine',
  'Coustard',
  'Covered By Your Grace',
  'Crafty Girls',
  'Creepster',
  'Crete Round',
  'Crimson Text',
  'Croissant One',
  'Crushed',
  'Cuprum',
  'Cutive',
  'Cutive Mono',
  'Damion',
  'Dancing Script',
  'Dangrek',
  'Dawning of a New Day',
  'Days One',
  'Dekko',
  'Delius',
  'Delius Swash Caps',
  'Delius Unicase',
  'Della Respira',
  'Denk One',
  'Devonshire',
  'Dhurjati',
  'Didact Gothic',
  'Diplomata',
  'Diplomata SC',
  'Domine',
  'Donegal One',
  'Doppio One',
  'Dorsa',
  'Dosis',
  'Dr Sugiyama',
  'Droid Sans',
  'Droid Sans Mono',
  'Droid Serif',
  'Duru Sans',
  'Dynalight',
  'EB Garamond',
  'Eagle Lake',
  'Eater',
  'Economica',
  'Ek Mukta',
  'Electrolize',
  'Elsie',
  'Elsie Swash Caps',
  'Emblema One',
  'Emilys Candy',
  'Engagement',
  'Englebert',
  'Enriqueta',
  'Erica One',
  'Esteban',
  'Euphoria Script',
  'Ewert',
  'Exo',
  'Exo 2',
  'Expletus Sans',
  'Fanwood Text',
  'Fascinate',
  'Fascinate Inline',
  'Faster One',
  'Fasthand',
  'Fauna One',
  'Federant',
  'Federo',
  'Felipa',
  'Fenix',
  'Figtree',
  'Finger Paint',
  'Fira Mono',
  'Fira Sans',
  'Fjalla One',
  'Fjord One',
  'Flamenco',
  'Flavors',
  'Fondamento',
  'Fontdiner Swanky',
  'Forum',
  'Francois One',
  'Frank Ruhl Libre',
  'Freckle Face',
  'Fredericka the Great',
  'Fredoka One',
  'Freehand',
  'Fresca',
  'Frijole',
  'Fruktur',
  'Fugaz One',
  'GFS Didot',
  'GFS Neohellenic',
  'Gabriela',
  'Gafata',
  'GalanoGrotesque',
  'Galdeano',
  'Galindo',
  'Gentium Basic',
  'Gentium Book Basic',
  'Geo',
  'Geostar',
  'Geostar Fill',
  'Germania One',
  'Gidugu',
  'Gilda Display',
  'Give You Glory',
  'Glass Antiqua',
  'Glegoo',
  'Gloria Hallelujah',
  'Goblin One',
  'Gochi Hand',
  'Gorditas',
  'Goudy Bookletter 1911',
  'Graduate',
  'Grand Hotel',
  'Gravitas One',
  'Great Vibes',
  'Griffy',
  'Gruppo',
  'Gudea',
  'Gurajada',
  'Habibi',
  'Halant',
  'Hammersmith One',
  'Hanalei',
  'Hanalei Fill',
  'Handlee',
  'Hanuman',
  'Happy Monkey',
  'Headland One',
  'Henny Penny',
  'Herr Von Muellerhoff',
  'Hind',
  'Holtwood One SC',
  'Homemade Apple',
  'Homenaje',
  'IBM Plex Sans',
  'IBM Plex Serif',
  'IM Fell DW Pica',
  'IM Fell DW Pica SC',
  'IM Fell Double Pica',
  'IM Fell Double Pica SC',
  'IM Fell English',
  'IM Fell English SC',
  'IM Fell French Canon',
  'IM Fell French Canon SC',
  'IM Fell Great Primer',
  'IM Fell Great Primer SC',
  'Iceberg',
  'Iceland',
  'Imprima',
  'Inconsolata',
  'Inder',
  'Indie Flower',
  'Inika',
  'Inter',
  'Irish Grover',
  'Istok Web',
  'Italiana',
  'Italianno',
  'Jacques Francois',
  'Jacques Francois Shadow',
  'Jaldi',
  'Jim Nightshade',
  'Jockey One',
  'Jolly Lodger',
  'Josefin Sans',
  'Josefin Slab',
  'Joti One',
  'Judson',
  'Julee',
  'Julius Sans One',
  'Junge',
  'Jura',
  'Just Another Hand',
  'Just Me Again Down Here',
  'Kalam',
  'Kameron',
  'Kantumruy',
  'Karla',
  'Karma',
  'Kaushan Script',
  'Kavoon',
  'Kdam Thmor',
  'Keania One',
  'Kelly Slab',
  'Kenia',
  'Khand',
  'Khmer',
  'Khula',
  'Kite One',
  'Knewave',
  'Kotta One',
  'Koulen',
  'Kranky',
  'Kreon',
  'Kristi',
  'Krona One',
  'La Belle Aurore',
  'Laila',
  'Lakki Reddy',
  'Lancelot',
  'Lateef',
  'Lato',
  'League Script',
  'Leckerli One',
  'Ledger',
  'Lekton',
  'Lemon',
  'Libre Baskerville',
  'Life Savers',
  'Lilita One',
  'Lily Script One',
  'Limelight',
  'Linden Hill',
  'Lobster',
  'Lobster Two',
  'Londrina Outline',
  'Londrina Shadow',
  'Londrina Sketch',
  'Londrina Solid',
  'Lora',
  'Love Ya Like A Sister',
  'Loved by the King',
  'Lovers Quarrel',
  'Luckiest Guy',
  'Lusitana',
  'Lustria',
  'Macondo',
  'Macondo Swash Caps',
  'Magra',
  'Maiden Orange',
  'Mako',
  'Mallanna',
  'Mandali',
  'Manrope',
  'Marcellus',
  'Marcellus SC',
  'Marck Script',
  'Margarine',
  'Marko One',
  'Marmelad',
  'Martel',
  'Martel Sans',
  'Marvel',
  'Mate',
  'Mate SC',
  'Maven Pro',
  'McLaren',
  'Meddon',
  'MedievalSharp',
  'Medula One',
  'Megrim',
  'Meie Script',
  'Merienda',
  'Merienda One',
  'Merriweather',
  'Merriweather Sans',
  'Metal',
  'Metal Mania',
  'Metamorphous',
  'Metrophobic',
  'Michroma',
  'Milonga',
  'Miltonian',
  'Miltonian Tattoo',
  'Miniver',
  'Miss Fajardose',
  'Modak',
  'Modern Antiqua',
  'Molengo',
  'Molle',
  'Monda',
  'Monofett',
  'Monoton',
  'Monsieur La Doulaise',
  'Montaga',
  'Montez',
  'Montserrat',
  'Montserrat Alternates',
  'Montserrat Subrayada',
  'Moul',
  'Moulpali',
  'Mountains of Christmas',
  'Mouse Memoirs',
  'Mr Bedfort',
  'Mr Dafoe',
  'Mr De Haviland',
  'Mrs Saint Delafield',
  'Mrs Sheppards',
  'Muli',
  'Mukta',
  'Mystery Quest',
  'NTR',
  'Neucha',
  'Neuton',
  'New Rocker',
  'News Cycle',
  'Niconne',
  'Nixie One',
  'Nobile',
  'Nokora',
  'Norican',
  'Nosifer',
  'Nothing You Could Do',
  'Noticia Text',
  'Noto Sans',
  'Noto Serif',
  'Nova Cut',
  'Nova Flat',
  'Nova Mono',
  'Nova Oval',
  'Nova Round',
  'Nova Script',
  'Nova Slim',
  'Nova Square',
  'Numans',
  'Nunito',
  'Odor Mean Chey',
  'Offside',
  'Old Standard TT',
  'Oldenburg',
  'Oleo Script',
  'Oleo Script Swash Caps',
  'Open Sans',
  'Open Sans Condensed',
  'Oranienbaum',
  'Orbitron',
  'Oregano',
  'Orienta',
  'Original Surfer',
  'Oswald',
  'Over the Rainbow',
  'Overlock',
  'Overlock SC',
  'Ovo',
  'Oxygen',
  'Oxygen Mono',
  'PT Mono',
  'PT Sans',
  'PT Sans Caption',
  'PT Sans Narrow',
  'PT Serif',
  'PT Serif Caption',
  'Pacifico',
  'Palanquin',
  'Palanquin Dark',
  'Paprika',
  'Parisienne',
  'Passero One',
  'Passion One',
  'Pathway Gothic One',
  'Patrick Hand',
  'Patrick Hand SC',
  'Patua One',
  'Paytone One',
  'Peddana',
  'Peralta',
  'Permanent Marker',
  'Petit Formal Script',
  'Petrona',
  'Philosopher',
  'Piedra',
  'Pinyon Script',
  'Pirata One',
  'Plaster',
  'Play',
  'Playball',
  'Playfair Display',
  'Playfair Display SC',
  'Podkova',
  'Poiret One',
  'Poller One',
  'Poly',
  'Pompiere',
  'Pontano Sans',
  'Poppins',
  'Port Lligat Sans',
  'Port Lligat Slab',
  'Pragati Narrow',
  'Prata',
  'Preahvihear',
  'Press Start 2P',
  'Princess Sofia',
  'Prociono',
  'Prosto One',
  'Puritan',
  'Purple Purse',
  'Quando',
  'Quantico',
  'Quattrocento',
  'Quattrocento Sans',
  'Questrial',
  'Quicksand',
  'Quintessential',
  'Qwigley',
  'Racing Sans One',
  'Radley',
  'Rajdhani',
  'Raleway',
  'Raleway Dots',
  'Ramabhadra',
  'Ramaraja',
  'Rambla',
  'Rammetto One',
  'Ranchers',
  'Rancho',
  'Ranga',
  'Rationale',
  'Ravi Prakash',
  'Redressed',
  'Reenie Beanie',
  'Revalia',
  'Ribeye',
  'Ribeye Marrow',
  'Righteous',
  'Risque',
  'Roboto',
  'Roboto Condensed',
  'Roboto Slab',
  'Rochester',
  'Rock Salt',
  'Rokkitt',
  'Romanesco',
  'Ropa Sans',
  'Rosario',
  'Rosarivo',
  'Rouge Script',
  'Rozha One',
  'Rubik',
  'Rubik Mono One',
  'Rubik One',
  'Ruda',
  'Rufina',
  'Ruge Boogie',
  'Ruluko',
  'Rum Raisin',
  'Ruslan Display',
  'Russo One',
  'Ruthie',
  'Rye',
  'Sacramento',
  'Sail',
  'Salsa',
  'Sanchez',
  'Sancreek',
  'Sansita One',
  'Sarina',
  'Sarpanch',
  'Satisfy',
  'Scada',
  'Scheherazade',
  'Schoolbell',
  'Seaweed Script',
  'Sevillana',
  'Seymour One',
  'Shadows Into Light',
  'Shadows Into Light Two',
  'Shanti',
  'Share',
  'Share Tech',
  'Share Tech Mono',
  'Shojumaru',
  'Short Stack',
  'Siemreap',
  'Sigmar One',
  'Signika',
  'Signika Negative',
  'Simonetta',
  'Sintony',
  'Sirin Stencil',
  'Six Caps',
  'Skranji',
  'Slabo 13px',
  'Slabo 27px',
  'Slackey',
  'Smokum',
  'Smythe',
  'Sniglet',
  'Snippet',
  'Snowburst One',
  'Sofadi One',
  'Sofia',
  'Sonsie One',
  'Sorts Mill Goudy',
  'Source Code Pro',
  'Source Sans Pro',
  'Source Serif Pro',
  'Space Grotesk',
  'Special Elite',
  'Spicy Rice',
  'Spinnaker',
  'Spirax',
  'Squada One',
  'Sree Krushnadevaraya',
  'Stalemate',
  'Stalinist One',
  'Stardos Stencil',
  'Stint Ultra Condensed',
  'Stint Ultra Expanded',
  'Stoke',
  'Strait',
  'Sue Ellen Francisco',
  'Sumana',
  'Sunshiney',
  'Supermercado One',
  'Suranna',
  'Suravaram',
  'Suwannaphum',
  'Swanky and Moo Moo',
  'Syncopate',
  'Syne',
  'Tangerine',
  'Taprom',
  'Tauri',
  'Teko',
  'Telex',
  'Tenali Ramakrishna',
  'Tenor Sans',
  'Text Me One',
  'The Girl Next Door',
  'Tienne',
  'Timmana',
  'Tinos',
  'Titan One',
  'Titillium Web',
  'Trade Winds',
  'Trocchi',
  'Trochut',
  'Trykker',
  'Tulpen One',
  'Ubuntu',
  'Ubuntu Condensed',
  'Ubuntu Mono',
  'Ultra',
  'Uncial Antiqua',
  'Underdog',
  'Unica One',
  'UnifrakturCook',
  'UnifrakturMaguntia',
  'Unkempt',
  'Unlock',
  'Unna',
  'VT323',
  'Vampiro One',
  'Varela',
  'Varela Round',
  'Vast Shadow',
  'Vesper Libre',
  'Vibur',
  'Vidaloka',
  'Viga',
  'Voces',
  'Volkhov',
  'Vollkorn',
  'Voltaire',
  'Waiting for the Sunrise',
  'Wallpoet',
  'Walter Turncoat',
  'Warnes',
  'Wellfleet',
  'Wendy One',
  'Wire One',
  'Work Sans',
  'Yanone Kaffeesatz',
  'Yellowtail',
  'Yeseva One',
  'Yesteryear',
  'Zeyada',
];

export const NORMAL = [
  {
    label: 'Galano Grotesque',
    value: {
      name: 'GalanoGrotesque',
      url: 'https://mydukaan.io/fonts/GalanoGrotesque/galanogrotesque-medium.otf',
    },
    recommended: true,
  },
];

export const D2C = [
  {
    label: 'IBM Plex Serif',
    value: {
      name: 'IBM Plex Serif',
      url: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
    },
    recommended: true,
  },
];

export const OFF_DUTY_BANNER_INDEX = [
  'Off Duty - Web',
  'Off Duty - Web',
  'Off Duty - Web',
  'Off Duty - Mobile',
  'Off Duty - Mobile',
  'Off Duty - Mobile',
  'Baesic - Web',
  'Baesic - Web',
  'Baesic - Web',
  'Baesic - Mobile',
  'Baesic - Mobile',
  'Baesic - Mobile',
];

export const STYLEUP_BANNER_INDEX = [
  'StyleUp - Web',
  'StyleUp - Web',
  'StyleUp - Web',
  'StyleUp - Mobile',
  'StyleUp - Mobile',
  'StyleUp - Mobile',
];

export const customThemeConfig = {
  [URSA]: {
    testimonials: {
      showImage: true,
      showRating: true,
      imageProps: { required: false },
    },
    featuredBrands: { showTitle: true },
    imageOverlay: { imageProps: { hasCropper: false } },
  },
  [TINKER]: {
    testimonials: {
      showImage: true,
      showRating: true,
      imageProps: { required: false },
    },
    featuredBrands: {
      showTitle: true,
      showDescription: true,
      sectionTitle: 'Store information',
      sectionDescription:
        'Showcase unique details of your store to attract customers and help them make informed choices.',
    },
    imageOverlay: {
      imageProps: { hasCropper: false, required: true, showRequired: true },
    },
    sectionReordering: { hasSectionReordering: false },
  },
  [D2C_APPEARENCE_THEME]: {
    imageOverlay: { imageProps: { hasCropper: false } },
  },
  [LUXURY]: {
    sectionReordering: { hasSectionReordering: true },
    imageOverlay: { imageProps: { hasCropper: false } },
    brandStory: { imageProps: { hasCropper: false } },
  },
};

export const fontsAndColorDefaultConfig = {
  [URSA]: {
    fontsAndColors: {
      color: {
        primary: '#e27d73',
        secondary: '#232225',
      },
      font: {
        url: 'https://fonts.googleapis.com/css2?family=Source+Sans Pro:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
        name: 'Source Sans Pro',
      },
    },
  },
};

export const THEME_HAS_FOOTER = [D2C_APPEARENCE_THEME, TINKER];

export const BANNER_SECTION_HIDDEN_THEME = [ENIGMA];
