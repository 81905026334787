import React from 'react';

export const SuccessBadge = ({ text, className }) => (
  <span className={`badge success ${className}`}>{text}</span>
);

export const PrimaryBadge = ({ text, className }) => (
  <span className={`badge primary ${className}`}>{text}</span>
);

export const WarningBadge = ({ text, className }) => (
  <span className={`badge warning ${className}`}>{text}</span>
);

export const OrangeBadge = ({ text, className }) => (
  <span className={`badge orange ${className}`}>{text}</span>
);

export const GrayBadge = ({ text, className }) => (
  <span className={`badge gray ${className}`}>{text}</span>
);

export const RedBadge = ({ text, className }) => (
  <span className={`badge red ${className}`}>{text}</span>
);

export const BrownBadge = ({ text, className }) => (
  <span className={`badge brown ${className}`}>{text}</span>
);
