import { getCurrencyByCode } from './currencies';
import COUNTRIES_RAW from './countries-raw.json';
import IN from '../../images/flags/flag_in.png';
import ID from '../../images/flags/flag_id.png';
import BD from '../../images/flags/flag_bd.png';
import LK from '../../images/flags/flag_lk.png';
import PK from '../../images/flags/flag_pk.png';
import AR from '../../images/flags/flag_ar.png';
import AU from '../../images/flags/flag_au.png';
import BR from '../../images/flags/flag_br.png';
import CA from '../../images/flags/flag_ca.png';
import CL from '../../images/flags/flag_cl.png';
import CN from '../../images/flags/flag_cn.png';
import CO from '../../images/flags/flag_co.png';
import CZ from '../../images/flags/flag_cz.png';
import DK from '../../images/flags/flag_dk.png';
import HU from '../../images/flags/flag_hu.png';
import IL from '../../images/flags/flag_il.png';
import JP from '../../images/flags/flag_jp.png';
import MY from '../../images/flags/flag_my.png';
import MX from '../../images/flags/flag_mx.png';
import MA from '../../images/flags/flag_ma.png';
import NZ from '../../images/flags/flag_nz.png';
import NO from '../../images/flags/flag_no.png';
import PH from '../../images/flags/flag_ph.png';
import PL from '../../images/flags/flag_pl.png';
import RU from '../../images/flags/flag_ru.png';
import SA from '../../images/flags/flag_sa.png';
import SY from '../../images/flags/flag_sy.png';
import SG from '../../images/flags/flag_sg.png';
import ZA from '../../images/flags/flag_za.png';
import SE from '../../images/flags/flag_se.png';
import CH from '../../images/flags/flag_ch.png';
import TH from '../../images/flags/flag_th.png';
import TR from '../../images/flags/flag_tr.png';
import GB from '../../images/flags/flag_gb.png';
import US from '../../images/flags/flag_us.png';
import AT from '../../images/flags/flag_at.png';
import BE from '../../images/flags/flag_be.png';
import LT from '../../images/flags/flag_lt.png';
import IE from '../../images/flags/flag_ie.png';
import KH from '../../images/flags/flag_kh.png';
import HR from '../../images/flags/flag_hr.png';
import CU from '../../images/flags/flag_cu.png';
import EG from '../../images/flags/flag_eg.png';
import ET from '../../images/flags/flag_et.png';
import FI from '../../images/flags/flag_fi.png';
import FR from '../../images/flags/flag_fr.png';
import DE from '../../images/flags/flag_de.png';
import GR from '../../images/flags/flag_gr.png';
import IT from '../../images/flags/flag_it.png';
import KW from '../../images/flags/flag_kw.png';
import LB from '../../images/flags/flag_lb.png';
import MU from '../../images/flags/flag_mu.png';
import MM from '../../images/flags/flag_mm.png';
import NP from '../../images/flags/flag_np.png';
import NL from '../../images/flags/flag_nl.png';
import NG from '../../images/flags/flag_ng.png';
import PY from '../../images/flags/flag_py.png';
import PE from '../../images/flags/flag_pe.png';
import PT from '../../images/flags/flag_pt.png';
import QA from '../../images/flags/flag_qa.png';
import ES from '../../images/flags/flag_es.png';
import UA from '../../images/flags/flag_ua.png';
import AE from '../../images/flags/flag_ae.png';
import UY from '../../images/flags/flag_uy.png';
import VE from '../../images/flags/flag_ve.png';
import HK from '../../images/flags/flag_hk.png';
import VN from '../../images/flags/flag_vn.png';
import AF from '../../images/flags/flag_af.png';
import BG from '../../images/flags/flag_bg.png';
import ZW from '../../images/flags/flag_zw.png';
import SI from '../../images/flags/flag_si.png';
import SR from '../../images/flags/flag_sr.png';
import RO from '../../images/flags/flag_ro.png';
import TW from '../../images/flags/flag_tw.png';
import KE from '../../images/flags/flag_ke.png';
import GH from '../../images/flags/flag_gh.png';
import EC from '../../images/flags/flag_ec.png';
import TT from '../../images/flags/flag_tt.png';
import RE from '../../images/flags/flag_re.png';
import EE from '../../images/flags/flag_ee.png';
import DZ from '../../images/flags/flag_dz.png';

const flagUrlByCountryCode = (countryCode) =>
  `https://dms.mydukaan.io/original/webp/flags/flag_${countryCode}.png`;

export const DEFAULT_COUNTRY = {
  id: 77,
  name: 'India',
  country_code: 'IN',
  currency_code: 'INR',
  icon: IN,
  locale: 'en-IN',
  phone_code: 91,
  postal_code_length: 6,
  postal_code_type: 'int',
  mobile_length_min: 10,
  mobile_length_max: 10,
};

export const US_AND_CANADA_IDS = [185, 32];
export const US_COUNTRY_CODE = 185;
export const CANADA_COUNTRY_CODE = 32;
export const US_COUNTRY = 'us';
export const INDIA_COUNTRY = 'in';

export const INTL_DEFAULT_COUNTRY = {
  name: 'United States',
  currency_code: 'USD',
  country_code: 'US',
  icon: US,
  locale: 'en-US',
  phone_code: 1,
  postal_code_length: 10,
  postal_code_type: 'int',
  mobile_length_min: 10,
  mobile_length_max: 10,
  id: 185,
};

const OTHER_COUNTRIES = [
  {
    name: 'Austria',
    currency_code: 'EUR',
    country_code: 'AT',
    icon: AT,
    locale: 'en-AT',
    phone_code: 43,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 11,
    id: 10,
  },
  {
    name: 'Belgium',
    currency_code: 'EUR',
    country_code: 'BE',
    icon: BE,
    locale: 'fr-BE',
    phone_code: 32,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 17,
  },
  {
    name: 'Lithuania',
    currency_code: 'EUR',
    country_code: 'LT',
    icon: LT,
    locale: 'lt-LT',
    phone_code: 370,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 101,
  },
  {
    name: 'Ireland',
    currency_code: 'EUR',
    country_code: 'IE',
    icon: IE,
    locale: 'en-IE',
    phone_code: 353,
    postal_code_length: 7,
    postal_code_type: 'string',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 81,
  },
  {
    name: 'Cambodia',
    currency_code: 'KHR',
    country_code: 'KH',
    icon: KH,
    locale: 'km-KH',
    phone_code: 855,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 30,
  },
  {
    name: 'Croatia',
    currency_code: 'HRK',
    country_code: 'HR',
    icon: HR,
    locale: 'hr-HR',
    phone_code: 385,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 43,
  },
  {
    name: 'Cuba',
    currency_code: 'CUC',
    country_code: 'CU',
    icon: CU,
    locale: 'es-CU',
    phone_code: 53,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 44,
  },
  {
    name: 'Egypt',
    currency_code: 'EGP',
    country_code: 'EG',
    icon: EG,
    locale: 'ar-EG',
    phone_code: 20,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 52,
  },
  {
    name: 'Ethiopia',
    currency_code: 'ETB',
    country_code: 'ET',
    icon: ET,
    locale: 'am-ET',
    phone_code: 251,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 12,
    mobile_length_max: 12,
    id: 58,
  },
  {
    name: 'Finland',
    currency_code: 'EUR',
    country_code: 'FI',
    icon: FI,
    locale: 'en-FI',
    phone_code: 358,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 60,
  },
  {
    name: 'France',
    currency_code: 'EUR',
    country_code: 'FR',
    icon: FR,
    locale: 'fr-FR',
    phone_code: 33,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 61,
  },
  {
    name: 'Germany',
    currency_code: 'EUR',
    country_code: 'DE',
    icon: DE,
    locale: 'de-DE',
    phone_code: 49,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 11,
    id: 65,
  },
  {
    name: 'Greece',
    currency_code: 'EUR',
    country_code: 'GR',
    icon: GR,
    locale: 'el-GR',
    phone_code: 30,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 67,
  },
  {
    name: 'Italy',
    currency_code: 'EUR',
    country_code: 'IT',
    icon: IT,
    locale: 'it-IT',
    phone_code: 39,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 13,
    id: 83,
  },
  {
    name: 'Kuwait',
    currency_code: 'KWD',
    country_code: 'KW',
    icon: KW,
    locale: 'ar-KW',
    phone_code: 965,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 92,
  },
  {
    name: 'Lebanon',
    currency_code: 'LBP',
    country_code: 'LB',
    icon: LB,
    locale: 'ar-LB',
    phone_code: 961,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 10,
    id: 96,
  },
  {
    name: 'Mauritius',
    currency_code: 'MUR',
    country_code: 'MU',
    icon: MU,
    locale: 'en-MU',
    phone_code: 230,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 111,
  },
  {
    name: 'Myanmar',
    currency_code: 'MMK',
    country_code: 'MM',
    icon: MM,
    locale: 'my-MM',
    phone_code: 95,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 120,
  },
  {
    name: 'Nepal',
    currency_code: 'NPR',
    country_code: 'NP',
    icon: NP,
    locale: 'ne-IN',
    phone_code: 977,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 123,
  },
  {
    name: 'Netherlands',
    currency_code: 'EUR',
    country_code: 'NL',
    icon: NL,
    locale: 'nl-NL',
    phone_code: 31,
    postal_code_length: 6,
    postal_code_type: 'string',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 124,
  },
  {
    name: 'Nigeria',
    currency_code: 'NGN',
    country_code: 'NG',
    icon: NG,
    locale: 'en-NG',
    phone_code: 234,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 12,
    id: 128,
  },
  {
    name: 'Paraguay',
    currency_code: 'PYG',
    country_code: 'PY',
    icon: PY,
    locale: 'es-PY',
    phone_code: 595,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 136,
  },
  {
    name: 'Peru',
    currency_code: 'PEN',
    country_code: 'PE',
    icon: PE,
    locale: 'es-PE',
    phone_code: 51,
    postal_code_length: 6,
    postal_code_type: 'string',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 137,
  },
  {
    name: 'Portugal',
    currency_code: 'EUR',
    country_code: 'PT',
    icon: PT,
    locale: 'pt-PT',
    phone_code: 351,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 140,
  },
  {
    name: 'Qatar',
    currency_code: 'QAR',
    country_code: 'QA',
    icon: QA,
    locale: 'ar-QA',
    phone_code: 974,
    postal_code_length: 5,
    postal_code_type: 'string',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 141,
  },
  {
    name: 'Spain',
    currency_code: 'EUR',
    country_code: 'ES',
    icon: ES,
    locale: 'es-ES',
    phone_code: 34,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 163,
  },
  {
    name: 'Ukraine',
    currency_code: 'UAH',
    country_code: 'UA',
    icon: UA,
    locale: 'ru-UA',
    phone_code: 380,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 182,
  },
  {
    name: 'UAE',
    currency_code: 'AED',
    country_code: 'AE',
    icon: AE,
    phone_code: 971,
    postal_code_length: 6,
    postal_code_type: 'string',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 183,
  },
  {
    name: 'Uruguay',
    currency_code: 'UYU',
    country_code: 'UY',
    icon: UY,
    locale: 'es-UY',
    phone_code: 598,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 186,
  },
  {
    name: 'Venezuela',
    currency_code: 'VEB',
    country_code: 'VE',
    icon: VE,
    locale: 'es-VE',
    phone_code: 58,
    postal_code_length: 6,
    postal_code_type: 'string',
    mobile_length_min: 7,
    mobile_length_max: 10,
    id: 189,
  },
  {
    name: 'Vietnam',
    currency_code: 'VND',
    country_code: 'VN',
    icon: VN,
    locale: 'vi-VN',
    phone_code: 84,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 190,
  },
  {
    name: 'Afghanistan',
    currency_code: 'AFN',
    country_code: 'AF',
    icon: AF,
    locale: 'ps-AF',
    phone_code: 93,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 1,
  },
  {
    id: 78,
    name: 'Indonesia',
    country_code: 'ID',
    currency_code: 'IDR',
    icon: ID,
    locale: 'id-ID',
    phone_code: 62,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 11,
  },
  {
    id: 14,
    name: 'Bangladesh',
    country_code: 'BD',
    currency_code: 'BDT',
    icon: BD,
    phone_code: 880,
    curr_group_char: ',',
    curr_mon_char: '.',
    keyboard_code: 0,
    group_size: 2,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
  },
  {
    id: 164,
    name: 'Sri Lanka',
    country_code: 'LK',
    currency_code: 'LKR',
    locale: 'ta-LK',
    icon: LK,
    phone_code: 94,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 10,
  },
  {
    id: 132,
    name: 'Pakistan',
    country_code: 'PK',
    currency_code: 'PKR',
    icon: PK,
    locale: 'en-PK',
    phone_code: 92,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
  },
  {
    name: 'Argentina',
    currency_code: 'ARS',
    country_code: 'AR',
    icon: AR,
    locale: 'en-AR',
    phone_code: 54,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 5,
    mobile_length_max: 20,
    id: 7,
  },
  {
    name: 'Australia',
    currency_code: 'AUD',
    country_code: 'AU',
    icon: AU,
    locale: 'en-AU',
    phone_code: 61,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 9,
  },
  {
    name: 'Brazil',
    currency_code: 'BRL',
    country_code: 'BR',
    icon: BR,
    locale: 'pt-BR',
    phone_code: 55,
    postal_code_length: 9,
    postal_code_type: 'int',
    mobile_length_min: 11,
    mobile_length_max: 11,
    id: 24,
  },
  {
    name: 'Canada',
    currency_code: 'CAD',
    country_code: 'CA',
    icon: CA,
    locale: 'en-CA',
    phone_code: 1,
    postal_code_length: 7,
    postal_code_type: 'string',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 32,
  },
  {
    name: 'Chile',
    currency_code: 'CLP',
    country_code: 'CL',
    icon: CL,
    locale: 'es-CL',
    phone_code: 56,
    postal_code_length: 8,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 35,
  },
  {
    name: 'China',
    currency_code: 'CNY',
    country_code: 'CN',
    icon: CN,
    locale: 'zh-CN',
    phone_code: 86,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 11,
    mobile_length_max: 13,
    id: 36,
  },
  {
    name: 'Columbia',
    currency_code: 'COP',
    country_code: 'CO',
    icon: CO,
    locale: 'es-CO',
    phone_code: 57,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 37,
  },
  {
    name: 'Czech Republic',
    currency_code: 'CZK',
    country_code: 'CZ',
    icon: CZ,
    locale: 'cs-CZ',
    phone_code: 420,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 46,
  },
  {
    name: 'Denmark',
    currency_code: 'DKK',
    country_code: 'DK',
    icon: DK,
    locale: 'da-DK',
    phone_code: 45,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 47,
  },
  {
    name: 'Hungary',
    currency_code: 'HUF',
    country_code: 'HU',
    icon: HU,
    locale: 'hu-HU',
    phone_code: 36,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 75,
  },
  {
    name: 'Israel',
    currency_code: 'ILS',
    country_code: 'IL',
    icon: IL,
    locale: 'he-IL',
    phone_code: 972,
    postal_code_length: 7,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 82,
  },
  {
    name: 'Japan',
    currency_code: 'JPY',
    country_code: 'JP',
    icon: JP,
    locale: 'ja-JP',
    phone_code: 81,
    postal_code_length: 8,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 85,
  },
  {
    name: 'Malaysia',
    currency_code: 'MYR',
    country_code: 'MY',
    icon: MY,
    locale: 'ms-MY',
    phone_code: 60,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 10,
    id: 105,
  },
  {
    name: 'Mexico',
    currency_code: 'MXN',
    country_code: 'MX',
    icon: MX,
    locale: 'es-MX',
    phone_code: 52,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 112,
  },
  {
    name: 'Morocco',
    currency_code: 'MAD',
    country_code: 'MA',
    icon: MA,
    locale: 'ar-MA',
    phone_code: 212,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 5,
    mobile_length_max: 20,
    id: 118,
  },
  {
    name: 'New Zealand',
    currency_code: 'NZD',
    country_code: 'NZ',
    icon: NZ,
    locale: 'en-NZ',
    phone_code: 64,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 12,
    id: 125,
  },
  {
    name: 'Norway',
    currency_code: 'NOK',
    country_code: 'NO',
    icon: NO,
    locale: 'no-NO',
    phone_code: 47,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 130,
  },
  {
    name: 'Philippines',
    currency_code: 'PHP',
    country_code: 'PH',
    icon: PH,
    locale: 'en-PH',
    phone_code: 63,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 138,
  },
  {
    name: 'Poland',
    currency_code: 'PLN',
    country_code: 'PL',
    icon: PL,
    locale: 'pl-PL',
    phone_code: 48,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 139,
  },
  {
    name: 'Russia',
    currency_code: 'RUB',
    country_code: 'RU',
    icon: RU,
    locale: 'ru-RU',
    phone_code: 7,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 143,
  },
  {
    name: 'Saudi Arabia',
    currency_code: 'SAR',
    country_code: 'SA',
    icon: SA,
    locale: 'ar-SA',
    phone_code: 966,
    postal_code_length: 10,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 151,
  },
  {
    name: 'Singapore',
    currency_code: 'SGD',
    country_code: 'SG',
    icon: SG,
    locale: 'zh-SG',
    phone_code: 65,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
    id: 156,
  },
  {
    name: 'South Africa',
    currency_code: 'ZAR',
    country_code: 'ZA',
    icon: ZA,
    locale: 'en-ZA',
    phone_code: 27,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 161,
  },
  {
    name: 'Sweden',
    currency_code: 'SEK',
    country_code: 'SE',
    icon: SE,
    locale: 'sv-SE',
    phone_code: 46,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 10,
    id: 167,
  },
  {
    name: 'Switzerland',
    currency_code: 'CHF',
    country_code: 'CH',
    icon: CH,
    locale: 'it-CH',
    phone_code: 41,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 168,
  },
  {
    name: 'Thailand',
    currency_code: 'THB',
    country_code: 'TH',
    icon: TH,
    locale: 'th-TH',
    phone_code: 66,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 10,
    id: 172,
  },
  {
    name: 'Turkey',
    currency_code: 'TRY',
    country_code: 'TR',
    icon: TR,
    locale: 'tr-TR',
    phone_code: 90,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 11,
    mobile_length_max: 11,
    id: 178,
  },
  {
    name: 'United Kingdom/England',
    currency_code: 'GBP',
    country_code: 'GB',
    icon: GB,
    locale: 'en-GB',
    phone_code: 44,
    postal_code_length: 4,
    postal_code_type: 'string',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 184,
  },
  {
    name: 'United States',
    currency_code: 'USD',
    country_code: 'US',
    icon: US,
    locale: 'en-US',
    phone_code: 1,
    postal_code_length: 10,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 185,
  },
  {
    id: 169,
    name: 'Syria',
    country_code: 'SY',
    currency_code: 'SYP',
    icon: SY,
    locale: 'ar-SY',
    phone_code: 963,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 10,
  },
  {
    id: 344,
    icon: HK,
    symbol: 'HK$',
    name: 'Hong Kong',
    currency_code: 'HKD',
    country_code: 'HK',
    locale: 'zh-HK',
    phone_code: 852,
    postal_code_length: 0,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
  },
  {
    id: 170,
    name: 'Bulgarian lev',
    country_code: 'BG',
    currency_code: 'BGN',
    icon: BG,
    locale: 'bg-BG',
    phone_code: 359,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 10,
  },
  {
    id: 193,
    name: 'Zimbabwe',
    country_code: 'ZW',
    currency_code: 'USD',
    icon: ZW,
    locale: 'en-ZW',
    phone_code: 263,
    postal_code_length: 0,
    postal_code_type: 'int',
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 158,
    name: 'Slovenia',
    country_code: 'SI',
    currency_code: 'EUR',
    icon: SI,
    phone_code: 386,
    mobile_length_min: 9,
    mobile_length_max: 10,
  },
  {
    id: 166,
    name: 'Suriname',
    country_code: 'SR',
    currency_code: 'SRD',
    icon: SR,
    locale: 'nl-SR',
    phone_code: 697,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 142,
    name: 'Romania',
    country_code: 'RO',
    currency_code: 'RON',
    icon: RO,
    locale: 'ro-RO',
    phone_code: 40,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 224,
    name: 'Taiwan',
    country_code: 'TW',
    currency_code: 'TWD',
    icon: TW,
    locale: 'zh-TW',
    phone_code: 886,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 88,
    name: 'Kenya',
    country_code: 'KE',
    currency_code: 'KES',
    icon: KE,
    locale: 'en-KE',
    phone_code: 254,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 66,
    name: 'Ghana',
    country_code: 'GH',
    currency_code: 'GHS',
    icon: GH,
    locale: 'en-GH',
    phone_code: 233,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 51,
    name: 'Ecuador',
    country_code: 'EC',
    currency_code: 'USD',
    icon: EC,
    locale: 'es-EC',
    phone_code: 593,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 176,
    name: 'Trinidad and Tobago',
    country_code: 'TT',
    currency_code: 'TTD',
    icon: TT,
    locale: 'en-TT',
    phone_code: 1,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 225,
    name: 'Réunion',
    country_code: 'RE',
    currency_code: 'EUR',
    icon: RE,
    locale: 'fr-RE',
    phone_code: 262,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 56,
    name: 'Estonia',
    country_code: 'EE',
    currency_code: 'EUR',
    icon: EE,
    locale: 'et-EE',
    phone_code: 372,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
  {
    id: 3,
    name: 'Algeria',
    country_code: 'DZ',
    currency_code: 'DZD',
    icon: DZ,
    locale: 'ar-DZ',
    phone_code: 213,
    mobile_length_min: 5,
    mobile_length_max: 10,
  },
];

const remainingCountries = COUNTRIES_RAW.map((country) => ({
  name: country.name,
  country_code: country.alpha2,
  currency_code: country?.currencies[0] ?? INTL_DEFAULT_COUNTRY.currency_code,
  icon: flagUrlByCountryCode(country.alpha2.toLowerCase()),
  phone_code: parseInt(
    country.countryCallingCodes?.[0]?.replace('+', '').split(' ')[0]
  ),
  mobile_length_min: 5,
  mobile_length_max: 10,
}));

const countries = [
  DEFAULT_COUNTRY,
  ...OTHER_COUNTRIES,
  ...remainingCountries,
].sort((a, b) => (a.name < b.name ? -1 : 1));

export default countries.map((country) => ({
  ...country,
  value: country.country_code,
  label: country.name,
}));

export const countriesByPhoneCode = countries.map((country) => ({
  ...country,
  value: country.phone_code,
  label: country.name,
}));

export const countriesWithCurrency = countries.map((country) => {
  const currency = getCurrencyByCode(country.currency_code, 'symbol');
  return {
    ...country,
    value: country.country_code,
    label: `${country.name} (${currency})`,
  };
});

export const getCountryNameFromCountryCode = (countryCode = '') =>
  countries?.find(
    (country) => country.country_code === countryCode?.toUpperCase()
  )?.name ?? '';
