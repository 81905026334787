import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { FormNumberInput, Validation } from '../../../shared/Form';
import SpinnerButton from '../../../shared/SpinnerButton';
import { noop } from '@babel/types';
import Modal from '../../../shared/Modal';
import ImageLoader from '../../../shared/ImageLoader';
import { Form } from 'react-final-form';

const BundleProductQuantityModal = ({
  open,
  handleClose,
  products = [],
  onSubmit = noop,
}) => {
  const getInitialValues = () => {
    const values = {};
    products.forEach((product) => {
      values[`${product.id}-quantity`] = product.quantity;
    });
    return values;
  };

  return (
    <Modal
      open={open}
      closeModal={() => {
        handleClose();
      }}
      maxWidth="md"
      className="reorder-product-modal"
    >
      <Form onSubmit={noop} initialValues={getInitialValues()}>
        {({ values, invalid }) => (
          <>
            <h2 className="redorder-modal-header">Update product quantities</h2>
            <hr className="hr-line mt16 mb0" />
            <div
              className={cx('reorder-modal-content show-scrollbar with-scroll')}
            >
              {products.map((product) => (
                <div className="d-flex-c-s" key={product.id}>
                  <div className="d-flex gap8">
                    <ImageLoader
                      height={40}
                      width={40}
                      src={product?.content_object?.image}
                      key={product.id}
                      style={{ height: 40, width: 40 }}
                    />
                    <div>
                      <p className="section-text-5">
                        {product?.content_object?.name}
                      </p>
                      <p className="text-4 c-gray-1">
                        SKU: {product?.content_object?.sku_code}
                      </p>
                    </div>
                  </div>
                  <FormNumberInput
                    name={`${product.id}-quantity`}
                    placeholder="Enter quantity"
                    initialValue={values[`${product.id}-quantity`]}
                    decimalScale={0}
                    required
                    showRequired
                    validate={Validation.validateNumber(0, 1000)}
                  />
                </div>
              ))}
            </div>

            <hr className="hr-line mt0 mb16" />
            <div className="pos-rel reorder-modal-footer">
              <div className="absolute-center">
                <SpinnerButton
                  showAnimation
                  onClick={() => {
                    onSubmit(values);
                  }}
                  disabled={invalid}
                >
                  Update
                </SpinnerButton>
              </div>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default BundleProductQuantityModal;
