import React, { useState } from 'react';
import cx from 'classnames';

import WithPopover from '../WithPopover';
import DateRangeList from './DateRangeList';
import CustomRangePicker from './CustomRangePicker';

const DateRangePopper = WithPopover((props) => {
  const [showCustomRange, setShowCustomRange] = useState(false);
  const {
    activeSelection,
    availableRanges,
    className,
    maxDate,
    showCustomPicker,
    isPopperOpen,
    setPopperOpen,
  } = props;

  const classes = cx('date-range-picker', className);
  const handleDateRangeClick = (value, range) => {
    if (value === 'custom_range' && showCustomPicker) {
      setShowCustomRange(true);
    } else {
      if (value === 'custom_range') {
        props.onRangeSelect(props.startDate, props.endDate, value);
      } else {
        props.onRangeSelect(...range, value);
      }
      props.closePopper();
    }
    setPopperOpen(!isPopperOpen);
  };

  return (
    <div className={classes}>
      {showCustomRange && showCustomPicker ? (
        <CustomRangePicker
          {...props}
          setShowCustomRange={setShowCustomRange}
          maxDate={maxDate}
        />
      ) : (
        <DateRangeList
          activeSelection={activeSelection}
          availableRanges={availableRanges}
          onDateRangeItemClick={handleDateRangeClick}
          maxDate={maxDate}
        />
      )}
    </div>
  );
});

DateRangePopper.defaultProps = {
  className: '',
  showCustomPicker: true,
  maxDate: new Date(),
  anchorVertical: 'bottom', // top, center, or bottom
  anchorHorizontal: 'right', // left, center or right
  transformVertical: 'top', // top, center, or bottom
  transformHorizontal: 'right',
};

export default DateRangePopper;
