import React, { createRef } from 'react';
import cx from 'classnames';
import FormImageUpload from '../../shared/Form/FormImageUpload';
import { Validation } from '../../shared/Form';
import { SnackbarCloseIcon } from '../../SvgIcon';
import useModal from '../../hooks/useModal';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import useAccountUpdate from '../../hooks/useStoreUpdate';
import DeleteModal from '../../shared/DeleteModal';
import { noop } from '../../utils';
import { addCDN } from '../../utils/file';
import ImageLoader from '../../shared/ImageLoader';
import useUploadMedia from '../../hooks/useUploadMedia';

const ImageCard = ({
  values,
  form,
  isNew = false,
  isActive,
  outerStyles = {},
  showDescription = true,
  alternateRemoveImage,
  onUploadSuccess = noop,
  imageFieldName = '',
  imageCardText,
  previewHiddenItemError = noop,
}) => {
  const {
    title: cardTitle = '',
    itemLink = '',
    description: cardDescription = '',
  } = imageCardText || {};
  const { business, domain } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { isSubmitting, updateAccount } = useAccountUpdate();
  const { isOpen, openModal, closeModal } = useModal();
  const fileUploadRef = createRef();
  const { upload, uploading } = useUploadMedia({});

  const triggerImageUpload = () => {
    fileUploadRef.current.click();
  };

  const removeImage = () => {
    if (alternateRemoveImage) {
      alternateRemoveImage(fileUploadRef);
      closeModal();
    } else {
      const { seo_data: seoData = {} } = business;
      const payload = { seo_data: { ...seoData } };
      delete payload.seo_data?.image;
      updateAccount(payload, () => {
        enqueueSnackbar('Seo image removed successfully.');
        form.mutators.modifyFormField('image', '');
        form.mutators.modifyFormField('imageUrl', '');
        closeModal();
      });
    }
  };

  const handlePreviewLinkClick = () => {
    /* 
    Prevent link from opening if either the item is not in edit mode
    or if it's in hidden state.
    */
    if (isNew) return;
    if (!isActive) return previewHiddenItemError();

    window.open(`${domain}${itemLink}`, '_blank');
  };

  return (
    <div className={cx('card p24 mt24 ', outerStyles?.card)}>
      <div className={cx('header pb24', outerStyles?.heading)}>
        <div className="text-medium text-2">Social sharing image preview</div>

        {showDescription && (
          <p className="text-info">
            When you share a link to your store on social media, an image is
            usually shown in your post. This one will be used if another
            relevant image can’t be found.
          </p>
        )}
      </div>

      <div className="row mx-12">
        <div className="col-xs-12 px12">
          <div className="seo-image-wrapper p8">
            <FormImageUpload
              ref={fileUploadRef}
              name={imageFieldName || 'image'}
              form={form}
              hideInput
              width={1200}
              height={628}
              onUploadSuccess={(url) => {
                form.mutators.modifyFormField('imageUrl', url);
                onUploadSuccess(url);
              }}
              hasCropper={false}
              upload={upload}
              uploading={uploading}
              customCrop={{
                aspect: 1200 / 628,
                width: 100,
                unit: '%',
              }}
              reactCroperStyles="full-w show-scrollbar reactCropperModal"
              imageStyles={{ width: '100%' }}
              // validate={Validation.optionalField({
              //   errMsg: 'Image upload in progress',
              //   validateFn: Validation.httpUrlValidation,
              // })}
            >
              <div
                className={cx('seo-image-upload cur-p', {
                  'dotted-border': !values?.imageUrl,
                  'has-image': values?.imageUrl,
                })}
                onClick={triggerImageUpload}
              >
                {!values?.imageUrl ? (
                  <>
                    <p className="c-purple-1 text-medium">+ Add image</p>
                    <p className="text-5 c-gray-1">
                      (Recommended size : 1200 x 628 px)
                    </p>
                  </>
                ) : (
                  <div className="d-flex-c-c pos-rel">
                    <SnackbarCloseIcon
                      className="overlay-delete-btn"
                      onClick={(event) => {
                        event.stopPropagation();
                        openModal();
                      }}
                    />
                    <ImageLoader
                      src={addCDN(values.imageUrl, 247, 129, 2)}
                      alt="seo_image"
                      height={129}
                      width={247}
                      className="seo-image"
                    />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <p className="text-5 c-white">Replace image</p>
                    </div>
                  </div>
                )}
              </div>
            </FormImageUpload>

            <div className="px24 pb12 break-word">
              <p
                className={cx(
                  'section-text-3 mb8 d-block word-break',
                  outerStyles?.previewTitle
                )}
              >
                {values?.title || cardTitle || 'Homepage Title'}
              </p>
              <span
                className={cx(
                  'mb8 text-2 d-block anchor-1',
                  outerStyles?.previewLink,
                  {
                    'preview-link': isNew,
                  }
                )}
                onClick={handlePreviewLinkClick}
              >
                {domain}
                {itemLink}
              </span>
              <p
                className={cx(
                  'text-8 c-gray-4 line-clamp-2 word-break',
                  outerStyles?.previewDescription
                )}
              >
                {values?.description || cardDescription || 'Meta Description'}
              </p>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <DeleteModal
          open={isOpen}
          headerText="Are you sure you want to remove this image?"
          closeModal={closeModal}
          content="This can not be undone!"
          submitting={isSubmitting}
          deleteAction={removeImage}
          deletingText="Removing..."
          buttonText="Confirm"
        />
      )}
    </div>
  );
};

export default ImageCard;
