import { createContext, useContext, useState, useEffect } from 'react';
import { GET_SIZE_CHARTS_PLUGIN } from '../ApiUrls';
import { useAppContext } from './AppContext';
import {
  GINI_AND_JONY_TEMPLATES,
  DEFAULT_TEMPLATES,
  giniAndJonyStoreId,
  DEFAULT_MEASUREMENT_UNIT,
} from '../Plugins/SizeChart/constants';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../utils/http';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

const SizeChartContext = createContext(null);

export const useSizeChartData = () => useContext(SizeChartContext);

const SizeChartProvider = ({ children }) => {
  const { business } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [selectedCategoryAndproduct, setSelectedCategoryAndproduct] = useState({
    products: null,
    categories: null,
  });
  const [isTableView, setIsTableView] = useState(false);
  const [apiTemplates, setApiTemplates] = useState([]);
  const [isEditFlow, setEditFlow] = useState(false);
  const [isDuplicateFlow, setIsDuplicateFlow] = useState(false);
  const [highlightHeaderData, setHighlightHeaderData] = useState({
    row: false,
    col: false,
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [editUuid, setEditUuid] = useState('');
  const [editTempdata, setEditTempData] = useState({});
  const [editTemplate, setEditTemplate] = useState({});
  const [isEditApiCall, setIsEditApiCall] = useState(false);
  const [isCreateCustom, setIsCreateCustom] = useState(false);
  const [sizeChartName, setSizeChartName] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [measurementSelected, setMeasurementSelected] = useState(
    DEFAULT_MEASUREMENT_UNIT
  );

  const resetStates = () => {
    setIsTableView(false);
    setActiveTemplate(null);
    setSelectedCategoryAndproduct({
      products: null,
      categories: null,
    });
    setIsEditApiCall(false);
    setEditTempData({});
    setEditUuid('');
    setEditFlow(false);
    setIsDuplicateFlow(false);
    setSizeChartName('');
    setMeasurementSelected(DEFAULT_MEASUREMENT_UNIT);
  };

  const getSizeCharts = () => {
    setLoading(true);
    const params = {
      page: pageNumber,
      page_size: 30,
    };
    getRequest({
      url: GET_SIZE_CHARTS_PLUGIN(business.uuid),
      data: params,
    })
      .then((res) => {
        const { count, results: data } = res;
        const findCreateCustom = data.find(
          (obj) => obj.name === 'Create custom'
        );
        if (findCreateCustom) {
          setIsCreateCustom(true);
        } else {
          setIsCreateCustom(false);
        }
        setTemplatesList(
          business.uuid === giniAndJonyStoreId
            ? [...GINI_AND_JONY_TEMPLATES]
            : [...DEFAULT_TEMPLATES]
        );
        if (pageNumber === 1) {
          setHasMore(data.length < count);
          setApiTemplates(data);
        } else {
          setHasMore(apiTemplates.length + data.length < count);
          setApiTemplates((prevState) => [...new Set([...prevState, ...data])]);
        }
        resetStates();
      })
      .finally(() => setLoading(false));
  };

  const handleGetSizeChartApiCall = () => {
    if (pageNumber === 1) {
      getSizeCharts();
    } else {
      setPageNumber(1);
    }
  };

  const checkIfNameAlreadyExists = () => {
    if (isEditApiCall && editTempdata.name === sizeChartName) return;
    const sizeChartNames = apiTemplates.map((item) => item.name.toLowerCase());
    if (sizeChartNames.includes(sizeChartName.toLowerCase())) return true;
    else return false;
  };

  const editSizeChart = (apiData) => {
    patchRequest({
      url: `${GET_SIZE_CHARTS_PLUGIN(business.uuid)}${editUuid}/`,
      data: {
        ...apiData,
        ...selectedCategoryAndproduct,
        header_highlights: highlightHeaderData,
        name: sizeChartName,
        template_name: editTempdata.templateName,
        measurement_unit: measurementSelected,
      },
      doClean: false,
    }).then(() => {
      enqueueSnackbar('Size chart updated successfully.');
      setTimeout(() => handleGetSizeChartApiCall(), [2000]);
    });
  };

  useEffect(() => {
    if (pageNumber > 1) {
      getSizeCharts();
    }
  }, [pageNumber]);

  const postSizeChart = () => {
    const apiData = { ...editTempdata };
    delete apiData.templateName;
    if (checkIfNameAlreadyExists()) {
      return enqueueSnackbar('Size chart name already exists.', 'error');
    }
    if (isEditApiCall) {
      editSizeChart(apiData);
    } else {
      postRequest({
        url: GET_SIZE_CHARTS_PLUGIN(business.uuid),
        data: {
          ...apiData,
          ...selectedCategoryAndproduct,
          header_highlights: highlightHeaderData,
          name: sizeChartName,
          template_name: editTempdata.templateName,
          measurement_unit: measurementSelected,
        },
        doClean: false,
      })
        .then((res) => {
          enqueueSnackbar('Size chart created successfully.');
          setTimeout(() => handleGetSizeChartApiCall(), [1000]);
        })
        .catch((res) => {
          console.log(res);
        });
    }
    setIsCreateCustom(false);
  };

  const deleteSizeChart = (uuid) => {
    deleteRequest({
      url: `${GET_SIZE_CHARTS_PLUGIN(business.uuid)}${uuid}/`,
    }).then((res) => {
      enqueueSnackbar('Size chart deleted successfully.');
      setTimeout(() => handleGetSizeChartApiCall(), [2000]);
    });
  };

  const contextValue = {
    loading,
    templatesList,
    activeTemplate,
    selectedCategoryAndproduct,
    isTableView,
    apiTemplates,
    isSaveDisabled,
    editTempdata,
    isEditFlow,
    isDuplicateFlow,
    getSizeCharts,
    postSizeChart,
    setActiveTemplate,
    setSelectedCategoryAndproduct,
    setIsTableView,
    editSizeChart,
    deleteSizeChart,
    setEditFlow,
    setIsDuplicateFlow,
    setHighlightHeaderData,
    setEditUuid,
    setEditTempData,
    editTemplate,
    setEditTemplate,
    setIsEditApiCall,
    isEditApiCall,
    setIsSaveDisabled,
    isCreateCustom,
    sizeChartName,
    setSizeChartName,
    hasMore,
    pageNumber,
    setPageNumber,
    measurementSelected,
    setMeasurementSelected,
    resetStates,
  };

  return (
    <SizeChartContext.Provider value={contextValue}>
      {children}
    </SizeChartContext.Provider>
  );
};

export default SizeChartProvider;
