import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Redirect, useHistory } from 'react-router-dom';

import Header from './Header';
import { useSubscription } from '../context/SubscriptionProvider';
import { getTotalHeight } from '../utils';
import { useAppContext } from '../context/AppContext';
import { accountUrls, appearanceUrls, catalogueUrls, rootUrls } from '../Urls';
import useLocalStorage from '../hooks/useLocalStorage';
import CustomDomainModal from '../Home/CustomDomainModal';
import { getItem, setItem } from '../utils/storage';
import PlanExpiryModal from './PlanExpiryModal';
import OverviewDataProvider from '../Home/OverviewDataProvider';
import useModal from '../hooks/useModal';
import CustomDomainExpiryModal from './CustomDomainExpiryModal';
import { domainBufferDaysForPurchase } from '../Home/common';
import DeleteStoreModal from '../Home/DeleteStoreModal';
import StoreDeletionConfirmationModal from '../Home/StoreDeletionConfirmationModal';
import MobileHeader from './MobileHeader';
import useResponsive from '../hooks/useResponsive';
import ErrorBoundary from '../shared/ErrorBoundary';
import InvoiceDueModal from './InvoiceDueModal';

const HEADER_HEIGHT = 64;
const MOBILE_HEADER_HEIGHT = 56;
const SUBSCRIPTION_EXPIRY_FOOTER_HEIGHT = 72;
const OFFER_BAR_HEIGHT = 65; // 56 for hellobar, 48 for invoice bar - yellow bar

const Layout = ({
  noHeader,
  children,
  headerText,
  className,
  documentTitle,
  renderHeaderText,
  headerClass,
  headerFixed = false,
  backLink,
  headerActions,
  howItWorksUrl,
  showStoreImage,
  showMenu,
  showSearchBar = false,
  showOfflineBadge,
  isAdvance = false,
  isStandard = false,
  hideSideNavigation = false,
  hideSubscription = false,
  progressBar = 0,
  headerParentClass = '',
  mainLayoutClass = '',
  editActions,
  isEditMode,
  fullWidth = false,
}) => {
  const { isMobile } = useResponsive();
  const [openCustomDomainModal, setCustomDomainModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedStoresForDel, setSelectedStoresForDel] = useState([]);
  const [invoiceModalClosed, setInvoiceModalClosed] = useLocalStorage(
    'invoice-modal',
    false
  );
  const history = useHistory();
  const handleSidebarToggle = () => setSidebarOpen(!sidebarOpen);
  const {
    business,
    sellerLimits,
    fetchSellerPlanStats,
    isAppSumoStore,
    primaryDomainDetails,
    isHelloBarActive,
    isEnterprise,
  } = useAppContext();

  const classes = cx('app-layout', className);
  const HIDE_MODAL_FOR = [
    accountUrls.subscriptionPurchasePath,
    accountUrls.customDomain,
    accountUrls.planDowngradePath,
  ];
  const modalHidden = HIDE_MODAL_FOR.includes(window.location.pathname);
  const isCodeEditor = appearanceUrls.codeEditor === window.location.pathname;
  const isInvoicesPages = window.location.pathname.includes('/invoice');
  const isTrialBannerHidden = [
    accountUrls.subscriptionPurchasePath,
    accountUrls.customDomain,
    catalogueUrls.newProductPath,
    catalogueUrls.newProductBulkPath,
    catalogueUrls.productBulkPricesPath,
    rootUrls.deliveryPath,
  ].includes(window.location.pathname);

  const {
    isOpen: isDeleteStoreModalOpen,
    openModal: openDeleteStoreModal,
    closeModal: closeDeleteStoreModal,
  } = useModal();

  const {
    isOpen: isDeleteConfirmationStoreModalOpen,
    openModal: openDeleteConfirmationStoreModal,
    closeModal: closeDeleteConfirmationStoreModal,
  } = useModal();

  const {
    isOpen: customDomainExpiryModal,
    openModal: openCustomDomainExpiryModal,
    closeModal: closeCustomDomainExpiryModal,
  } = useModal();

  const {
    isOpen: invoiceModalOpen,
    openModal: openInvoiceModal,
    closeModal: closeInvoiceModal,
  } = useModal();

  const {
    storeInactive,
    isOnTrial,
    isPlanExpired,
    daysFromExpiry,
    activePlanName,
    hideSidebar,
    fetchStoreRenewalPlanDetails,
    deletedStoresSuccess,
    planRenewalDate,
    dueInvoiceData,
    showRenewalBufferDays,
  } = useSubscription();

  const totalHeaderHeight = getTotalHeight([
    {
      height: isMobile
        ? MOBILE_HEADER_HEIGHT + (progressBar > 0 ? 4 : 0)
        : HEADER_HEIGHT +
          (!isAppSumoStore && isHelloBarActive ? OFFER_BAR_HEIGHT : 0),
      applicable: (!storeInactive || modalHidden) && !noHeader,
    },
  ]);

  const totalFooterHeight = getTotalHeight([
    {
      height: SUBSCRIPTION_EXPIRY_FOOTER_HEIGHT,
      applicable:
        !isCodeEditor &&
        !hideSubscription &&
        ((isOnTrial && !isTrialBannerHidden) ||
          (planRenewalDate === null &&
            daysFromExpiry <= showRenewalBufferDays &&
            daysFromExpiry > 0)),
    },
  ]);

  const routesWithoutSidebar = [
    accountUrls.subscriptionPurchasePath,
    accountUrls.planDowngradePath,
  ];

  const { remainingBufferDays } = domainBufferDaysForPurchase(business);
  const handleDeleteStoreModal = (storeListflow, selectStores = []) => {
    if (storeListflow) {
      closeDeleteStoreModal();
      openDeleteConfirmationStoreModal();
      setSelectedStoresForDel([...selectStores]);
    } else {
      closeDeleteConfirmationStoreModal();
      openDeleteStoreModal();
    }
  };

  useEffect(() => {
    if (documentTitle) {
      document.title = documentTitle;
    }
  }, [documentTitle]);

  useEffect(() => {
    if (
      business.meta?.misc_data?.domain_deployment_banner_show === true &&
      getItem('custom_domain_banner_shown') !== 'true'
    ) {
      setCustomDomainModal(true);
    }
  }, [business]);

  useEffect(() => {
    if (
      (daysFromExpiry <= showRenewalBufferDays && !isOnTrial) ||
      isPlanExpired
    ) {
      fetchStoreRenewalPlanDetails();
    }
    setTimeout(() => {
      openCustomDomainExpiryModal();
    }, 2000);
  }, []);

  useEffect(() => {
    if (sellerLimits?.appsumo_licence_downgraded && !isEnterprise) {
      openDeleteStoreModal();
    }
  }, [sellerLimits]);

  useEffect(() => {
    if (
      dueInvoiceData?.status === 'pending' &&
      (!invoiceModalClosed ||
        moment(dueInvoiceData?.due_date).isBefore(moment())) &&
      !isInvoicesPages
    ) {
      openInvoiceModal();
    }
  }, [dueInvoiceData]);

  useEffect(() => {
    closeDeleteConfirmationStoreModal();
  }, [deletedStoresSuccess]);

  useEffect(() => {
    if (isAppSumoStore) {
      fetchSellerPlanStats();
    }
  }, [activePlanName]);

  if (
    hideSidebar &&
    !routesWithoutSidebar.includes(history.location.pathname)
  ) {
    return <Redirect to={accountUrls.subscriptionPurchasePath} />;
  }
  return (
    <div
      className={classes}
      style={{
        '--header': noHeader ? 0 : `${totalHeaderHeight}px`,
      }}
    >
      {!noHeader && (
        <>
          {isMobile ? (
            <MobileHeader
              headerText={headerText}
              backLink={backLink}
              progressBar={progressBar}
            />
          ) : (
            <Header
              fullWidth={fullWidth}
              headerText={headerText}
              headerClass={headerClass}
              headerFixed={headerFixed}
              renderHeaderText={renderHeaderText}
              handleSidebarToggle={handleSidebarToggle}
              backLink={backLink}
              headerActions={headerActions}
              howItWorksUrl={howItWorksUrl}
              showSearchBar={showSearchBar}
              showMenu={showMenu}
              showStoreImage={showStoreImage}
              showOfflineBadge={showOfflineBadge}
              isAdvance={isAdvance}
              isStandard={isStandard}
              progressBar={progressBar}
              headerParentClass={headerParentClass}
              editActions={editActions}
              isEditMode={isEditMode}
            />
          )}
        </>
      )}
      <main
        className={cx('app-layout-content', mainLayoutClass)}
        id="app-layout-content"
        style={{
          paddingTop: totalHeaderHeight,
          paddingBottom: totalFooterHeight,
          marginLeft: hideSidebar || hideSideNavigation || fullWidth ? 0 : 223,
          '--header': totalHeaderHeight,
        }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      {!isOnTrial && isPlanExpired && !modalHidden && !isMobile && (
        <OverviewDataProvider>
          <PlanExpiryModal
            link={business.link}
            showCloseIcon={false}
            open
            className="expiry-modal"
          />
        </OverviewDataProvider>
      )}

      {invoiceModalOpen && (
        <InvoiceDueModal
          open={invoiceModalOpen}
          closeModal={() => {
            closeInvoiceModal();
            setInvoiceModalClosed(true);
          }}
        />
      )}

      <CustomDomainExpiryModal
        open={
          customDomainExpiryModal &&
          remainingBufferDays <= 0 &&
          !isMobile &&
          !isPlanExpired &&
          !business.meta?.misc_data?.processing_renew_domain &&
          business.custom_domain &&
          primaryDomainDetails &&
          !(
            primaryDomainDetails?.meta?.old_custom_domain &&
            primaryDomainDetails?.domain === null
          ) &&
          !primaryDomainDetails?.is_external
        }
      />

      <CustomDomainModal
        open={openCustomDomainModal}
        closeModal={() => {
          setItem('custom_domain_banner_shown', true);
          setCustomDomainModal();
        }}
      />
      {!modalHidden && (
        <DeleteStoreModal
          open={isDeleteStoreModalOpen && !isMobile}
          sellerLimits={sellerLimits}
          onClick={handleDeleteStoreModal}
        />
      )}
      <StoreDeletionConfirmationModal
        open={isDeleteConfirmationStoreModalOpen && !isMobile}
        closeModal={() => handleDeleteStoreModal(false)}
        selectedStores={selectedStoresForDel}
      />
    </div>
  );
};

Layout.defaultProps = {
  noHeader: false,
  headerText: '',
  className: '',
  documentTitle: 'Dukaan',
  backLink: null,
  headerActions: null,
  showMenu: false,
};

export default Layout;
