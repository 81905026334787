import React, { useState } from 'react';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import CheckboxField from '../../shared/CheckboxField';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { OnlinePaymentFlowDiagram } from '../../SvgIcon';
import { noop } from '../../utils';

const RequestOnlinePaymentModal = ({
  open = false,
  closeModal = () => {},
  loading = false,
  onSubmit = () => {},
  setShowRequestPaymentConfirmation = noop,
}) => {
  const [checked, setChecked] = useState(false);

  const handleClickSubmit = () => {
    setShowRequestPaymentConfirmation(checked);
    onSubmit();
  };
  useEnterPressAction(open, handleClickSubmit);
  return (
    <Modal open={open} closeModal={closeModal} paperClass="sm">
      <p className="section-text-4 mb2">How request online payment works?</p>
      <div className="mt40 mb40">
        <OnlinePaymentFlowDiagram />
      </div>

      <div className="d-flex-c-s flex-column mt40">
        <SpinnerButton
          showAnimation
          type="button"
          className="btn btn-primary-4 mb16"
          onClick={handleClickSubmit}
          isLoading={loading}
        >
          Request payment from buyer
        </SpinnerButton>
        <CheckboxField
          label="Don’t show this again"
          className="mr16"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </div>
    </Modal>
  );
};

export default RequestOnlinePaymentModal;
