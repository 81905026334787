import firebase from '@firebase/app';
import '@firebase/auth';
import { postRequest } from '../utils/http';
import { AUTH_CALLBACK_URL_MAP } from '../ApiUrls';
import { authUrls, rootUrls } from '../Urls';
import { useAppContext } from '../context/AppContext';
import { noop } from '../utils';
import { parseJwt } from '../utils/string';
import useCustomSnackbar from './useCustomSnackbar';
import { GOOGLE_API_KEY } from '../constants';

const FIREBASE_CONFIG = {
  apiKey: GOOGLE_API_KEY,
  authDomain: 'dukaan-seller-web.firebaseapp.com',
  projectId: 'dukaan-seller-web',
  storageBucket: 'dukaan-seller-web.appspot.com',
  messagingSenderId: '193655940279',
  appId: '1:193655940279:web:728f14f149c3f938479932',
  measurementId: 'G-EQXVJVLYWW',
};

const useFirebaseOauth = () => {
  const { setUser, setToken, setStoreId } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();

  const initializeFirebase = () => {
    try {
      firebase.initializeApp(FIREBASE_CONFIG);
      firebase.auth();
    } catch {
      console.log('error');
    }

    return firebase;
  };

  const handleAuthSuccess = (result, platform) => {
    const profile = result?.additionalUserInfo?.profile;
    let token;

    if (platform === 'google') {
      token = result?.credential?.idToken ?? result.credential;
    } else if (platform === 'facebook') {
      token = result?.credential?.accessToken;
    }

    const payload = { token, mode: 'web' };
    if (localStorage.getItem('utm_source')) {
      payload.utm_source = localStorage.getItem('utm_source');
      payload.utm_medium = localStorage.getItem('utm_medium');
      payload.utm_campaign = localStorage.getItem('utm_campaign');
    }
    postRequest({
      url: AUTH_CALLBACK_URL_MAP[platform],
      data: payload,
      headers: {
        mode: 'web',
      },
    }).then(({ data = {} }) => {
      const {
        username,
        store_ids: storeIds,
        multiple_stores: multipleStores,
      } = parseJwt(data.access_token);
      const phoneNumber = username.split('-')[1];
      const hasPhone = !Number.isNaN(phoneNumber);
      window.currentAuthToken = data.access_token;
      setToken(data.access_token);
      if (Array.isArray(storeIds) && storeIds.length === 0) {
        // if there is no storeid present in jwt token then redirect to register page
        window.location.href = authUrls.registerPath;
        return;
      }
      if (multipleStores) {
        // if there is more than one storeid present in jwt token then redirect to select store page
        window.location.href = authUrls.selectStorePath;
        return;
      }
      setStoreId(storeIds[0]);
      setUser(
        data,
        { email: profile?.email, mobile: hasPhone ? phoneNumber : undefined },
        () => {
          window.location.href = rootUrls.homePath;
        }
      );
    });
  };

  const oauth = (platform, successCallback = noop, errorCallback = noop) => {
    const firebaseLocal = initializeFirebase();
    const auth = firebaseLocal.auth();
    auth.useDeviceLanguage();
    let provider;
    if (platform === 'facebook') {
      provider = new firebaseLocal.auth.FacebookAuthProvider();
      provider.addScope('email');
    } else {
      provider = new firebaseLocal.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    }
    auth
      .signInWithPopup(provider)
      .then((result) => {
        handleAuthSuccess(result, platform, errorCallback);
        successCallback();
      })
      .catch(() => {
        errorCallback();
      });
  };

  return { oauth, handleAuthSuccess };
};

export default useFirebaseOauth;
