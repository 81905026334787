import moment from 'moment';

const today = moment();

export const daysFromToday = (date) => {
  if (!date) return '';
  return moment(date).diff(today, 'days') + 1;
};

export const formatProductTime = (dt) =>
  moment(dt).calendar(null, {
    sameDay: '[Today] hh:mm A',
    nextDay: '[Tomorrow] hh:mm A',
    nextWeek: 'dddd hh:mm A',
    lastDay: '[Yesterday] hh:mm A',
    lastWeek: 'dddd hh:mm A',
    sameElse: 'DD/MM/YYYY hh:mm A',
  });

export const formatOrderTime = (dt) =>
  moment(dt).calendar(null, {
    sameDay: '[Today], hh:mm A',
    nextDay: '[Tomorrow], hh:mm A',
    nextWeek: 'dddd, hh:mm A',
    lastDay: '[Yesterday], hh:mm A',
    lastWeek: 'dddd, hh:mm A',
    sameElse: 'DD MMM YYYY, h:mm A',
  });

export const pluginFormatDate = (dt) => moment(dt).format('D MMMM YYYY');

export const addTime = (min) => moment().add(min, 'm').format('hh:mm A');

export const DISPLAY_FORMAT = 'MMM D, YYYY';
export const SERVER_FORMAT = 'YYYY-MM-DD';

export const dateDifferenceInDays = (dt, dt1) => moment(dt).diff(dt1, 'days');

export const fromNow = (dt) => moment(dt).fromNow();

export const updateLocale = () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '1 m',
      ss: '1 m',
      m: '%d m',
      mm: '%d m',
      h: '%d h',
      hh: '%d h',
      d: '%d d',
      dd: '%d d',
      w: '%d week',
      ww: '%d w',
      M: '%d M',
      MM: '%d M',
      y: '%d Y',
      yy: '%d Y',
    },
  });
};

export const updateDefaultLocale = () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '1 minute',
      ss: '1 minute',
      m: '%d minute',
      mm: '%d minutes',
      h: '%d hour',
      hh: '%d hours',
      d: '%d day',
      dd: '%d days',
      w: '%d week',
      ww: '%d weeks',
      M: '%d month',
      MM: '%d months',
      y: '%d year',
      yy: '%d years',
    },
  });
};

export const resetLocale = () => {
  moment.locale(false);
};

export const dateFromToday = (days) => moment().add(days, 'days').toDate();

// count : how many need to add
// date : from which date
// type : can be days,years,weeks..etc,
export const addDays = (count, date, type) =>
  moment(date).add(count, type).toDate();

export const fromNowWithLocale = (dt) => {
  const mdt = moment(dt);
  const diff = mdt.diff(today, 'days');
  if (Math.abs(diff) >= 28) {
    return mdt.format('DD MMM');
  }
  updateLocale();
  const formatted = mdt.fromNow(true);
  resetLocale();
  return formatted;
};

export const fromNowWithDefaultLocale = (dt) => {
  if (dt) {
    updateDefaultLocale();
    const formatted = moment(dt).fromNow();
    resetLocale();
    return formatted;
  }
  return '';
};

export const momentDate = (dt) => moment(dt);

export const formatDate = (dt, format = DISPLAY_FORMAT) =>
  dt ? moment(dt).format(format) : '';

export const formatDate1 = (dt) => formatDate(dt, 'DD MMM YYYY');

export const formatDate2 = (dt) => formatDate(dt, 'DD MMM [‘]YY');

export const formatDate3 = (dt) => formatDate(dt, 'MMMM D, YYYY');

export const formatDate4 = (dt) => formatDate(dt, 'Do MMM YYYY');

export const formatDate5 = (dt) => formatDate(dt, 'DD/MM/YY, hh:mm A');

export const formatDate6 = (dt) => formatDate(dt, 'DD MMM,YYYY');

export const formatDateTime = (dt) => formatDate(dt, 'MMMM D, YYYY, LT');
export const formatDateTimeSmall = (dt) => formatDate(dt, 'MMM D, YYYY, LT');

export const formatServerDate = (dt) => formatDate(dt, SERVER_FORMAT);

export const formatZone = (dt) => moment(dt).format();

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export const remainingTime = (distance) => {
  const days = Math.floor(distance / ONE_DAY);
  const hours = Math.floor((distance % ONE_DAY) / ONE_HOUR);
  const minutes = Math.floor((distance % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor((distance % ONE_MINUTE) / ONE_SECOND);

  return [days, hours, minutes, seconds];
};

export const formatLastVisited = (lastVisited) => {
  if (lastVisited) {
    const days = dateDifferenceInDays(new Date(), moment(lastVisited));
    if (days <= 31) {
      return `${days} days`;
    }
    return formatDate2(lastVisited);
  }
  return '';
};
