import { createContext, useContext, useState } from 'react';
import IframeComponent from '../shared/IframeComponent';
import { useAppContext } from './AppContext';

const IframeContext = createContext({});

export const useIframeContext = () => useContext(IframeContext);

export default function IframeProvider({ children }) {
  const [iframeSrc, setIframeSrc] = useState(null);
  const [currentKey, setCurrentKey] = useState(null);
  const { domainName } = useAppContext();
  const iframeCallbackObj = {};

  function setIframe(key, src) {
    setCurrentKey(key);
    setIframeSrc(src);
    console.log(currentKey);
  }

  function setIframeCbKey(key, cb) {
    iframeCallbackObj[key] = cb;
  }

  function removeIframeEvent(key) {
    delete iframeCallbackObj[key];
  }

  function onMessage(event) {
    // Check sender origin to be trusted
    const trustedOrigins = [
      'apps.mydukaan.io',
      'localhost',
      'web.mydukaan.dev',
      'mydukaan.io',
      domainName,
    ];
    if (!trustedOrigins.some((origin) => event.origin.includes(origin))) return;
    var { data } = event;
    if (typeof window[data.func] === 'function') {
      window[data.func].call(null, ...data.args);
    }
  }

  function closeIframe(isModal, data) {
    setIframeSrc(null);
    setCurrentKey((key) => {
      if (
        iframeCallbackObj[key] &&
        typeof iframeCallbackObj[key] === 'function'
      ) {
        iframeCallbackObj[key](data);
      }
      return null;
    });
  }

  function bindCloseIframeEventOnWindow(key, callback) {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }
    setIframeCbKey(key, callback);
    window.closeModal = closeIframe;
  }

  const contextValue = {
    setIframe,
    bindCloseIframeEventOnWindow,
    removeIframeEvent,
  };

  return (
    <IframeContext.Provider value={contextValue}>
      <IframeComponent open={!!iframeSrc} src={iframeSrc} />
      {children}
    </IframeContext.Provider>
  );
}
