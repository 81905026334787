import React, { useState } from 'react';
import cx from 'classnames';
import { noop } from '../utils';

const SpinnerButton = ({
  children = undefined,
  label = '',
  isLoading = false,
  disabled = false,
  onClick = noop,
  className = 'btn-primary-4',
  btnClass = 'd-flex-c-c',
  persistLoader = false,
  showAnimation = false,
  skipTab = false,
  ...restProps
}) => {
  const [isPersistLoader, setLoader] = useState(false);

  const handleClick = (e) => {
    if (isLoading) return;
    if (persistLoader) {
      setLoader(true);
      setTimeout(() => {
        onClick(e);
        setLoader(false);
      }, 1000);
    } else {
      onClick(e);
    }
  };

  return (
    <button
      className={cx('pos-rel spinner-btn', className, {
        'no-hover': isLoading || isPersistLoader,
        'press-effect': showAnimation,
      })}
      disabled={isLoading || disabled || isPersistLoader}
      {...(skipTab ? { tabIndex: -1 } : {})}
      type="button"
      onClick={handleClick}
      {...restProps}
    >
      <span
        className={cx(btnClass, {
          'visibility-hidden': isLoading || isPersistLoader,
        })}
      >
        {!label ? children : label}
      </span>
      {(isLoading || isPersistLoader) && (
        <div className="absolute-center d-flex">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </button>
  );
};

export default SpinnerButton;
