import cx from 'classnames';
import { CloseMobilePlanIcon, WarningTranslucentIcon } from '../../../SvgIcon';
import { noop } from '../../../utils';

const MarketingStripe = ({
  title = '',
  description = '',
  btnLabel = '',
  variant = 'warning',
  size = undefined,
  disabled = false,
  onClick = noop,
  parentClass = '',
  onClose,
}) => (
  <div
    className={cx(
      'card pr32 pl32 py8 mb24 d-flex-c-s',
      size,
      parentClass,
      `${variant}-stripe`
    )}
  >
    <div className="d-flex align-center">
      <div className="">
        <p className="section-text-5">{title}</p>
        <p className="c-black-3">{description}</p>
      </div>
    </div>
    <div className="d-flex align-center">
      {btnLabel && (
        <button
          type="button"
          className={cx(`btn-${variant}-4`)}
          onClick={onClick}
          disabled={disabled}
        >
          {btnLabel}
        </button>
      )}
      {typeof onClose === 'function' && (
        <CloseMobilePlanIcon
          style={{ width: 18 }}
          className="ml16 mr8 cur-p"
          onClick={onClose}
        />
      )}
    </div>
  </div>
);

export default MarketingStripe;
