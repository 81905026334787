import React, { useState } from 'react';
import cx from 'classnames';
import { useAppContext } from '../context/AppContext';
import {
  EVENT_ORDER_DOWNLOAD_REPORT_BTN,
  EVENT_ORDER_DOWNLOAD_REPORT_EXCEL,
  EVENT_ORDER_DOWNLOAD_REPORT_PDF,
} from '../events';

import useGenerateOrdersReport from '../hooks/useGenerateOrdersReport';
import useOrderReportSocket from '../hooks/useOrderReportSocket';
import DownloadReportModal from '../shared/DownloadReportModal';
import FullScreenLoader from '../shared/FullscreenLoader';
import { DownloadGrayIcon } from '../SvgIcon';
import { TrackEvent } from '../utils/analytics';
import { ALL_STATUS } from './constants';
import { useViewReports } from '../context/ViewReportsProvider';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { useCredits } from '../context/CreditsProvider';

const DownloadOrdersReport = ({
  skipDownload = false,
  filters = {},
  className = '',
  disabled = false,
}) => {
  const { business, canCreateVendor, isVendor } = useAppContext();
  const { refetchReports, refetchReportsProgress } = useViewReports();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { isBalanceNegative } = useCredits();
  const { generating, generateReport, setGenerating } =
    useGenerateOrdersReport();
  const { setTaskId, setLocalTaskId } = useOrderReportSocket(
    () => {
      setGenerating(false);
      if (skipDownload) {
        refetchReports();
        refetchReportsProgress({});
      }
    },
    'Order report downloaded successfully.',
    skipDownload
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClick = (format) => {
    if (skipDownload) {
      enqueueSnackbar('Downloading report…');
    }
    if (format === 'pdf') {
      TrackEvent(EVENT_ORDER_DOWNLOAD_REPORT_PDF, business);
    } else if (format === 'xlsx') {
      TrackEvent(EVENT_ORDER_DOWNLOAD_REPORT_EXCEL, business);
    }
    if (generating && !skipDownload) return null;
    const params = { ...filters };
    if (parseInt(params.status) === ALL_STATUS) {
      delete params.status;
    }
    params.file = format;
    generateReport(params, ({ data }) => {
      setTaskId(data?.task_id);
      setLocalTaskId((prev) => [...prev, data?.task_id]);
      if (skipDownload) {
        refetchReports();
        refetchReportsProgress({ showSomeDownload: true });
      }
      setShowConfirmModal(false);
    });
    if (skipDownload) {
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      <div
        className={cx('d-flex-c-s special-filter-btn', className)}
        onClick={() => {
          if ((!skipDownload && generating) || disabled) return;
          if (isBalanceNegative) {
            enqueueSnackbar(
              'Can not download order report due to negative credits balance',
              'error'
            );
            return;
          }
          TrackEvent(EVENT_ORDER_DOWNLOAD_REPORT_BTN, business);
          if (canCreateVendor || isVendor) {
            handleClick('xlsx');
            return;
          }
          setShowConfirmModal(true);
        }}
      >
        <DownloadGrayIcon height={14} width={14} />
        <span className="ml4">Export</span>
      </div>

      {generating && !skipDownload && (
        <FullScreenLoader
          closeCallback={() => {
            setGenerating(false);
            setTaskId(null);
          }}
          message="Downloading report..."
        />
      )}
      <DownloadReportModal
        title="Choose report type"
        open={showConfirmModal}
        loading={!skipDownload && generating}
        closeModal={() => setShowConfirmModal(false)}
        handleXLS={() => handleClick('xlsx')}
        handlePDF={() => handleClick('pdf')}
      />
    </div>
  );
};

export default DownloadOrdersReport;
