import React, { useState } from 'react';
import cx from 'classnames';
import { MULTILINE_ATTRIBUTE_TEXT_MAX_COUNT } from '../../Catalogue/constants';
import { getCharecterCount } from '..';
import { Editor } from '../Editor';

const FormFieldTextarea = ({ form, values, label, name, metafieldId }) => {
  const [textCharacterCount, setTextCharacterCount] = useState(0);
  return (
    <>
      <div className="mb8 d-flex-c-s">
        <div className="black-3">{label || name}</div>
        {textCharacterCount ? (
          <div className="black-3">
            <span
              className={cx({
                'c-red-1':
                  textCharacterCount > MULTILINE_ATTRIBUTE_TEXT_MAX_COUNT,
              })}
            >
              {textCharacterCount}
            </span>
            /
            <span className="text-medium">
              {MULTILINE_ATTRIBUTE_TEXT_MAX_COUNT}
            </span>
          </div>
        ) : null}
      </div>

      <Editor
        data={values?.metafields?.[`attribute__${metafieldId}`] ?? ''}
        onChange={(val) => {
          form.mutators.modifyFormField(
            `metafields.attribute__${metafieldId}`,
            val
          );
          const characterCount = getCharecterCount(val);
          setTextCharacterCount(characterCount);
        }}
      />
    </>
  );
};

export default FormFieldTextarea;
