import React from 'react';
import { ONLINE_PAYMENT_METHOD } from '../Account/Sections/constants';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { WarningTranslucentIcon } from '../SvgIcon';
import { noop } from '../utils';
import { CASH_PAYMENT_MODE } from './constants';

const ReceivePaymentModal = ({
  open,
  closeModal,
  onButtonClick = noop,
  isLoading = false,
}) => (
  <Modal open={open} closeModal={closeModal} className="text-center">
    <WarningTranslucentIcon />
    <div className="mb24">
      <p className="section-text-4 c-black-1 mb6">Payment not received yet!</p>
      <p className="text-2 c-black-3">
        To accept this order, you need to change the payment method to either
        COD or PAID
      </p>
    </div>
    <div className="receive-payment-btn-container">
      <SpinnerButton
        className="btn-outline-primary section-text-5"
        onClick={() => onButtonClick(CASH_PAYMENT_MODE)}
        isLoading={isLoading}
      >
        Accept & change to COD
      </SpinnerButton>
      <SpinnerButton
        className="btn-primary-4 section-text-5"
        onClick={() => onButtonClick(ONLINE_PAYMENT_METHOD)}
        isLoading={isLoading}
      >
        Accept & change to PAID
      </SpinnerButton>
    </div>
  </Modal>
);

export default ReceivePaymentModal;
