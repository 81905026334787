import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import cx from 'classnames';
import { deepClone } from '../Appearance/utils';
import { useAppContext } from '../context/AppContext';
import { useCountry } from '../context/CountryProvider';
import useLineItemShipment from '../hooks/useLineItemShipment';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import { FormCustomSelect, Validation } from '../shared/Form';
import ImageLoader from '../shared/ImageLoader';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { noop, modifyFormField } from '../utils';
import { changeCDNRes } from '../utils/file';
import { toTitleCase, truncateString } from '../utils/string';
import { SizeColor, VariantColor } from './common';

const columns = [
  {
    title: 'Product name',
    key: 'product_name',
  },
  {
    title: 'Vendor',
    key: 'vendor',
  },
  {
    title: 'Warehouse',
    key: 'warehouse',
  },
  {
    title: 'Shipping partner',
    key: 'shipping_partner',
  },
];

class DropdownOption {
  constructor(label, value) {
    this.label = label;
    this.value = value;
  }
}

const RenderLineItem = (props) => {
  const {
    index,
    product = {},
    staffWarehouseList = [],
    selectedValues,
    vendorOptions,
    handleOnChange,
    isLoading,
    rejectedWarehouse,
  } = props;

  const warehouseOptions = staffWarehouseList
    .filter((each) => each?.staff_id === null)
    .map((each) => new DropdownOption(each.name, each.id));
  return (
    <div
      key={product.product_sku_id}
      className="table-data-row vendor-dukaan-delivery-table"
    >
      <div className="d-flex">
        <div className="d-flex">
          <ImageLoader src={changeCDNRes(product.image)} alt={product.name} />
        </div>
        <div className="d-flex flex-column">
          <p className="text-2">{product.name}</p>
          <p>
            <SizeColor attributes={product.attributes} className="mt4" />
          </p>
          <p className="text-4 c-black-3 mt2">Qty: {product.quantity}</p>
        </div>
      </div>
      <div className="w-100 d-flex align-center">
        <FormCustomSelect
          name={`${product.line_item_id}_vendor`}
          placeholder="Select vendor"
          className="w-100"
          options={vendorOptions}
          required
          showRequired
          value={selectedValues?.[product.line_item_id]?.staff}
          validate={Validation.required()}
          onChange={(option) =>
            handleOnChange(option, product?.line_item_id, 'staff')
          }
          menuPlacement={
            vendorOptions?.length > 3 && index >= 3 ? 'top' : 'auto'
          }
          error={
            selectedValues?.[product.line_item_id]?.staff.value ===
            rejectedWarehouse?.vendorId
              ? true
              : false
          }
        />
      </div>
      <div className="w-100 d-flex align-center">
        <FormCustomSelect
          name={`${product.line_item_id}_warehouse`}
          placeholder="Select warehouse"
          className="w-100"
          options={warehouseOptions}
          required
          showRequired
          validate={Validation.required()}
          value={selectedValues?.[product.line_item_id]?.warehouse}
          onError={
            !selectedValues?.[product.line_item_id]?.warehouse && !isLoading
          }
          helpText={
            !selectedValues?.[product.line_item_id]?.warehouse && !isLoading ? (
              <span className="c-red-1">Warehouse does not exist.</span>
            ) : null
          }
          onChange={(option) =>
            handleOnChange(option, product?.line_item_id, 'warehouse')
          }
          menuPlacement={
            warehouseOptions?.length > 3 && index >= 3 ? 'top' : 'auto'
          }
        />
      </div>
      <div className="w-100 d-flex align-center">
        <FormCustomSelect
          name={`${product.line_item_id}_shipping_partner`}
          placeholder="Select partner"
          className="w-100"
          options={
            selectedValues?.[product.line_item_id]?.shippingOptions || []
          }
          required
          showRequired
          validate={Validation.required()}
          value={selectedValues?.[product.line_item_id]?.shipment}
          onChange={(option) =>
            handleOnChange(option, product?.line_item_id, 'shipment')
          }
          menuPlacement={
            selectedValues?.[product.line_item_id]?.shippingOptions?.length >
              4 && index >= 4
              ? 'top'
              : 'auto'
          }
        />
      </div>
    </div>
  );
};

const RenderTable = (props) => {
  const {
    products = [],
    orderDetails,
    closeModal,
    btnText = 'Ship order',
    afterShipCb,
    rejectedWarehouse,
  } = props;
  const { business } = useAppContext();
  const { fetchLineItemShippingPrice, isLoading, shipOrder, isSubmitting } =
    useLineItemShipment(orderDetails.id);
  const { formatLocalMoney } = useCountry();
  const {
    staff_list: staffList = [],
    staff_warehouse_list: staffWarehouseList = [],
  } = orderDetails;
  const [selectedValues, setSelectedValues] = useState({});

  const vendorOptions = staffList.reduce((acc, each) => {
    acc[each.id] = [
      new DropdownOption(each.name, each.id),
      new DropdownOption(business.name, null),
    ];
    return acc;
  }, {});

  const isFormValid = products.every((each) => {
    const lineObj = selectedValues?.[each?.line_item_id];
    return lineObj?.warehouse && lineObj?.shipment && lineObj?.staff;
  });

  const fetchLineItemShippingPartners = (
    changeForLineItemId,
    updatedSelectedValues = selectedValues
  ) =>
    fetchLineItemShippingPrice(updatedSelectedValues, (data) => {
      const selectedValuesCopy = deepClone(updatedSelectedValues);
      for (const each of Object.entries(data)) {
        const lineItem = each[0];
        const rates = each[1]?.[0]?.rates || [];
        const shippingOptions = [];
        for (const rate of rates) {
          const name = `${truncateString(
            rate?.carrier_service?.full_service_name || '',
            20
          )} (${formatLocalMoney(rate.total_delivery_charge)})`;
          shippingOptions.push(new DropdownOption(name, rate));
        }
        shippingOptions.push(
          new DropdownOption('Self ship', {
            carrier_service: { id: -1 },
            carrier: { id: -1 },
          })
        );
        const [shipment] = shippingOptions;
        selectedValuesCopy[lineItem].shippingOptions = shippingOptions;
        if (
          !changeForLineItemId ||
          (changeForLineItemId && String(changeForLineItemId) === lineItem)
        )
          selectedValuesCopy[lineItem].shipment = shipment;
      }
      setSelectedValues(selectedValuesCopy);
    });

  const getUpdatedSelectedValue = (
    selectedValuesObj,
    option,
    lineItemId,
    key
  ) => {
    const updatedData = {
      ...(selectedValuesObj?.[lineItemId] || {}),
      [key]: { ...option },
    };
    if (key === 'staff') {
      const selectedWarehouse = staffWarehouseList.filter(
        (each) => each?.staff_id === option?.value
      )?.[0];

      if (selectedWarehouse) {
        updatedData.warehouse = new DropdownOption(
          selectedWarehouse?.name,
          selectedWarehouse?.id
        );
      } else {
        delete updatedData.warehouse;
      }
    }
    if (key !== 'shipment') {
      delete updatedData.shippingOptions;
      delete updatedData.shipment;
    }
    const updatedSelectedValues = {
      ...selectedValuesObj,
      [lineItemId]: { ...updatedData },
    };
    return {
      updatedSelectedValues,
      updatedData,
    };
  };

  const handleOnChange = (option, lineItemId, key) => {
    const { updatedSelectedValues, updatedData } = getUpdatedSelectedValue(
      selectedValues,
      option,
      lineItemId,
      key
    );
    setSelectedValues(updatedSelectedValues);

    if (key !== 'shipment' && updatedData?.warehouse && updatedData?.staff) {
      fetchLineItemShippingPartners(lineItemId, updatedSelectedValues);
    }
  };

  const handleShipOrder = () =>
    shipOrder(selectedValues, () => {
      closeModal();
      setTimeout(
        () => (afterShipCb ? afterShipCb() : window.location.reload()),
        3000
      );
    });

  useEffect(() => {
    if (products?.length) {
      let updatedSelectedValues = {};
      products.forEach((each) => {
        const { updatedSelectedValues: updatedValues } =
          getUpdatedSelectedValue(
            updatedSelectedValues,
            {
              ...(vendorOptions?.[each.default_staff_id]?.find(
                (item) => item.value === each?.default_staff_id
              ) || new DropdownOption(business.name, null)),
            },
            each.line_item_id,
            'staff'
          );
        updatedSelectedValues = updatedValues;
      });
      setSelectedValues(updatedSelectedValues);
      fetchLineItemShippingPartners(undefined, updatedSelectedValues);
    }
  }, [products]);

  return (
    <div>
      <div className="mx-12">
        <div className="table-header-wrapper vendor-dukaan-delivery-table">
          {columns.map((col) => (
            <div className="table-header" key={col.key}>
              <div
                className="d-flex align-center cur-p"
                // onClick={() => (col.sortable ? onSort(col.key) : noop)}
              >
                <span>{col.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={cx('table-data-container mx-12 show-scrollbar', {
          'ship-order-modal': products?.length > 4,
        })}
      >
        <div>
          {products.map((product, index) => (
            <RenderLineItem
              index={index}
              key={product?.line_item_id}
              product={product}
              selectedValues={selectedValues}
              handleOnChange={handleOnChange}
              vendorOptions={
                vendorOptions?.[product?.default_staff_id] || [
                  new DropdownOption(business.name, null),
                ]
              }
              rejectedWarehouse={rejectedWarehouse}
              staffWarehouseList={staffWarehouseList}
            />
          ))}
        </div>
      </div>
      <div className="horizontal-line my16" />
      <div className="d-flex-c-c">
        <SpinnerButton
          isLoading={isSubmitting}
          disabled={!isFormValid || isLoading || isSubmitting}
          showAnimation
          className="btn-4 btn-warning"
          onClick={handleShipOrder}
        >
          {btnText}
        </SpinnerButton>
      </div>
    </div>
  );
};

const VendorShipOrderModal = (props) => {
  const { open, closeModal, isLoading, rejectedWarehouse } = props;
  return (
    <Modal
      open={open}
      className="shipment-created-modal new-shipment-modal"
      closeModal={closeModal}
      paperClass="modal-visible"
      maxWidth="xl"
    >
      <div style={{ minHeight: 200 }}>
        <h5 className="section-text-4">
          Ship {rejectedWarehouse ? 'rejected' : ''} order
        </h5>
        {rejectedWarehouse?.vendorId && (
          <p className="tex-2">
            The shipment was rejected by {rejectedWarehouse?.warehouseName}
          </p>
        )}
        <div className="horizontal-line my16" />
        <BounceLoaderContainer isLoading={isLoading}>
          <Form
            onSubmit={noop}
            keepDirtyOnReinitialize
            initialValues={{}}
            mutators={{ modifyFormField }}
            render={(formProps) => <RenderTable {...formProps} {...props} />}
          />
        </BounceLoaderContainer>
      </div>
    </Modal>
  );
};

export default VendorShipOrderModal;
