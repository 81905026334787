import { useEffect } from 'react';
import useQuery from '../hooks/useQuery';
import useFirebaseOauth from '../hooks/useFirebaseOauth';
import { useAppContext } from '../context/AppContext';

const SendStoreID = () => {
  const { isLoggedIn, business, authToken } = useAppContext();

  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');

  if (isLoggedIn) {
    window.location.href = `${redirectUrl}?storeId=${business.id}&name=${business.name}&authToken=${authToken}`;
  } else {
    // redirect to login and after successfull login redirect to redirectUrl with store ID
    window.location.href = `${window.location.origin}/login?redirectUrl=${redirectUrl}`;

  }

  return null;
};

export default SendStoreID;
