import React, { useState } from 'react';
import Lottie from 'react-lottie';
import Dialog from '@material-ui/core/Dialog';
import { RemoveIcon } from '../SvgIcon';
import * as animationData from './lottie/FullScreenLoader.json';
import { noop } from '../utils';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const FullScreenLoader = ({
  message = '',
  renderFooter = noop,
  closeCallback = noop,
  disableClose = false,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(true);

  return (
    <Dialog
      fullScreen
      open={isOpenDialog}
      classes={{
        root: 'bulk-loader',
      }}
    >
      <div className="upload-container">
        {!disableClose && (
          <RemoveIcon
            className="close-fullscreen"
            onClick={() => {
              setIsOpenDialog(false);
              closeCallback();
            }}
          />
        )}
        <div className="loader-container">
          <Lottie options={defaultOptions} height={140} width={140} />
          <span className="section-text-4 mt14 fw400">{message}</span>
          <div className="loader-footer">{renderFooter()}</div>
        </div>
      </div>
    </Dialog>
  );
};

export default FullScreenLoader;
