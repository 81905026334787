import React from 'react';

import { FormControlLabel, RadioGroup, Typography } from '@material-ui/core';
import { noop } from '../utils';
import CustomRadio from './CustomRadio';

const styles = {
  formControlLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#1a181e',
  },
};

const RadioInputGroup = (props) => {
  const {
    items,
    form,
    name,
    value: selectedValue,
    onChange,
    style = {},
    disabled,
    row = false,
  } = props;

  const handleOnChange = (e) => {
    const { value } = e.target;
    form.change(name, value);
    onChange(value);
  };

  return (
    <RadioGroup
      name={name}
      value={selectedValue}
      onChange={handleOnChange}
      style={style}
      row={row}
    >
      {items.map((item, index) => {
        const { value, label } = item;
        return (
          <FormControlLabel
            value={value}
            key={index}
            control={<CustomRadio />}
            label={
              <Typography style={styles.formControlLabel}>{label}</Typography>
            }
            labelPlacement="end"
            disabled={disabled}
          />
        );
      })}
    </RadioGroup>
  );
};

RadioInputGroup.defaultProps = {
  onChange: noop,
  items: [],
  name: null,
  value: '',
  form: null,
  disabled: false,
};

export default RadioInputGroup;
