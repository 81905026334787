export const MAILCHIMP_APPID = '613600fc059ec68819d70222';

export const MAILCHIMP_INSTALL_LINK = `/plugins/${MAILCHIMP_APPID}`;
export const MAILCHIMP_SETTING_LINK = `${MAILCHIMP_INSTALL_LINK}/settings`;

export const APPEARANCE_TYPE_MAP = {
  url: 'Custom Link',
  storepage: 'Pages',
  productcategory: 'Categories',
  product: 'Products',
};

export const APPEARANCE_TYPE_MAP_SINGULAR = {
  url: 'Custom Link',
  storepage: 'Page',
  productcategory: 'Category',
  product: 'Product',
};

export const MENU_ITEM_TYPES = {
  PRODUCT: 'product',
  STORE_PAGE: 'storepage',
  CATEGORY: 'productcategory',
  CUSTOM_LINK: 'url',
};

export const MENU_OPTIONS = [
  { label: 'Header', value: 0 },
  { label: 'Footer', value: 1 },
];
