import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import ReactHtmlParser from 'react-html-parser';
import { StepConnector } from '@material-ui/core';
import {
  WhatsappActiveIcon,
  WhatsappInactiveIcon,
  SMSActiveIcon,
  SMSInactiveIcon,
  ExternalLinkBlack,
} from '../SvgIcon';
import { RAZORPAY_API_KEY } from '../constants';
import { RECOVERY_CHANNEL_CAMPAIGN_ID } from './constants';
import { CAMPAIGN_ONLINE_PAYMENT_URL } from '../ApiUrls';
import useCampaign from '../hooks/useCampaign';
import { noop, modifyFormField, urlify, parseHTML } from '../utils';
import Modal from '../shared/Modal';
import { useRazorpayCheckout } from '../Subscription/NewSubscription/PaymentButtons/RazorpayButton';
import SpinnerButton from '../shared/SpinnerButton';
import { useCountry } from '../context/CountryProvider';
import { useAppContext } from '../context/AppContext';
import BounceLoader, { BounceLoaderSmall } from '../shared/BounceLoader';
import Logo from '../../images/icons/logo.svg';
import { useCredits } from '../context/CreditsProvider';
import { campaignUrls, rootUrls } from '../Urls';
import StepIndicator from '../Home/Stepper/StepIndicator';
import { TemplateItem } from '../Campaign/components/MessageTemplatesModal';
import {
  convertWhatsappToHtmlTranscode,
  SMS_CHANNEL_ID,
  WHATSAPP_CHANNEL_ID,
} from '../Campaign/constants';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useEnterPressAction from '../hooks/useEnterPressAction';
import useScrollingRef from '../hooks/useScrollingRef';

const BroadcastOptionButton = ({
  label = '',
  subtitle = '',
  selected = true,
  onClick = noop,
  Icon = null,
  ActiveIcon,
  className,
  ...rest
}) => {
  const BroadcastMediumIcon = selected ? ActiveIcon : Icon;
  return (
    <div
      className={cx(
        'payment-option-button broadcast-button-item py12 d-flex-c-c',
        className,
        {
          selected,
          'selected-text': selected,
        }
      )}
      onClick={onClick}
      {...rest}
    >
      <BroadcastMediumIcon className={cx('mx8', { 'c-purple-1': selected })} />
      <span className="">{label}</span>
      {subtitle && <span className="text-10 mx4">{subtitle}</span>}
    </div>
  );
};

const RecoveryMessageModal = ({
  open,
  handleClose,
  templates = [{ label: '', id: null }],
  storeLeadIds = [],
  fetchTemplates = noop,
  isWishlist = false,
  templatesLoading = false,
  isEmailFlow = false,
  customerLength = 0,
  pageNumberTemplates = 1,
  setPageNumberTemplates = noop,
  hasMoreTemplates = false,
}) => {
  const [recoveryTemplate, setRecoveryTemplate] = useState({
    label: '',
    id: null,
  });
  const [selectedChannel, setSelectedChannel] = useState(
    RECOVERY_CHANNEL_CAMPAIGN_ID.WHATSAPP
  );
  const [overlayLoader, setOverlayLoader] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('online');
  const [priceData, setPriceData] = useState({
    cost: 0,
    discount: 0,
    discountPercentage: 0,
    discountedPrice: 0,
  });
  const history = useHistory();

  const [finalAmount, setFinalAmount] = useState(0);
  const { fetchCampaignCostEstimate, createCampaign } = useCampaign(false);
  const { credits, generatePaymentOrder, creditsToAdd, setCreditsToAdd } =
    useCredits();
  const { formatLocalMoney, currencyCode } = useCountry();
  const { enqueueSnackbar } = useCustomSnackbar();

  const { business, userDetails, fetchBusinessDetails } = useAppContext();
  const { name: storeName } = business;
  const { mobile } = userDetails;

  const templatesLoadingElement = useScrollingRef(
    templatesLoading,
    hasMoreTemplates,
    setPageNumberTemplates
  );

  useEffect(() => {
    fetchTemplates(
      RECOVERY_CHANNEL_CAMPAIGN_ID.WHATSAPP,
      {
        page: 1,
        offer_type: isWishlist ? 'wishlist' : 'cart_abandoned',
      },
      () => {
        setOverlayLoader(false);
      }
    );
  }, []);

  const openCheckout = useRazorpayCheckout({
    url: CAMPAIGN_ONLINE_PAYMENT_URL(business.uuid),
    payload: {},
  });

  const createCampaignPayload = {
    name: 'Abandoned cart recovery',
    store_lead_type: 'abandoned',
    template: recoveryTemplate?.id ? recoveryTemplate?.id : templates[0]?.id,
    channel: [selectedChannel],
    store_lead_ids: storeLeadIds,
  };

  const wishlistNotifyPayload = {
    name: 'Wishlist',
    store_lead_type: 'all',
    template: recoveryTemplate?.id ? recoveryTemplate?.id : templates[0]?.id,
    channel: [selectedChannel],
    type: 'wishlist',
    product_sku: storeLeadIds,
  };

  const createCampaignHandler = () => {
    const payload = isWishlist ? wishlistNotifyPayload : createCampaignPayload;
    createCampaign(payload, () => {
      if (isWishlist) {
        handleClose();
        enqueueSnackbar('Sent message successfully');
      } else {
        fetchBusinessDetails();
        history.push(
          `${rootUrls.successRecoveryMessageBroadcastPath}?channel=${selectedChannel}`
        );
      }
    });
  };

  const handleRecoveryTemplate = () => {
    if (paymentMethod === 'online') {
      const config = {
        name: 'Dukaan Marketing',
        key: RAZORPAY_API_KEY,
        amount: finalAmount,
        currency: currencyCode,
        description: `Payment for Dukaan Marketing campaign creation`,
        image: Logo,
        prefill: {
          name: storeName,
          email: 'orders@mydukaan.io',
          contact: mobile,
        },
        handler: () =>
          isWishlist
            ? history.push(
                `${campaignUrls.wishlistSuccessMessagePath}?channel=${selectedChannel}`
              )
            : history.push(
                `${rootUrls.successRecoveryMessageBroadcastPath}?channel=${selectedChannel}`
              ),
        theme: {
          color: '#146eb4',
        },
      };
      const payload = isWishlist
        ? wishlistNotifyPayload
        : createCampaignPayload;
      openCheckout(config, payload); // send payload here
    } else if (paymentMethod === 'credits') {
      createCampaignHandler();
    } else {
      enqueueSnackbar('Payment dialog will open shortly!', 'info', {
        action: null,
      });
      generatePaymentOrder(
        creditsToAdd,
        createCampaignHandler,
        null,
        'Abandoned cart recovery'
      );
    }
  };
  const handleDiscountCalculation = (data) => {
    const abandonedDiscount =
      data?.store_lead_type?.abandoned - data?.credit_discounted?.abandoned;
    const wishlistDiscount =
      data?.store_lead_type?.wishlist - data?.credit_discounted?.wishlist;

    return isWishlist ? wishlistDiscount : abandonedDiscount;
  };

  const handlePrice = (data) => {
    setPriceData({
      cost: isWishlist
        ? data?.store_lead_type?.wishlist
        : data?.store_lead_type?.abandoned,
      discountPercentage: data?.dukaan_credit_discount_percent,
      discount: handleDiscountCalculation(data),
      discountedPrice: isWishlist
        ? data?.credit_discounted?.wishlist
        : data?.credit_discounted?.abandoned,
    });
    const finalAmountTemp = isWishlist
      ? data?.store_lead_type?.wishlist
      : data?.store_lead_type?.abandoned;
    setFinalAmount(finalAmountTemp);
  };

  useEffect(() => {
    if (templates.length) setRecoveryTemplate(templates[0]);
  }, [templates]);

  useEffect(() => {
    if (credits <= 0) {
      setPaymentMethod('online');
    } else if (credits >= finalAmount) {
      setPaymentMethod('credits');
    } else {
      setPaymentMethod('partial');
    }
    const toAdd = finalAmount - credits;
    // razorpay won't take payments which are < 1
    setCreditsToAdd(toAdd < 1 ? 1 : toAdd);
  }, [finalAmount]);

  useEffect(() => {
    const payload = {
      channel: [selectedChannel],
      store_lead_count: storeLeadIds.length,
    };

    if (isWishlist) {
      payload.type = 'wishlist';
      payload.product_sku = storeLeadIds;
      delete payload.store_lead_count;
    }
    fetchCampaignCostEstimate(payload, (data) => handlePrice(data));
  }, []);

  const broadcastOptions = [
    ...(!isEmailFlow
      ? [
          {
            id: RECOVERY_CHANNEL_CAMPAIGN_ID.WHATSAPP,
            label: 'WhatsApp broadcast',
            Icon: WhatsappInactiveIcon,
            ActiveIcon: WhatsappActiveIcon,
          },
          {
            id: RECOVERY_CHANNEL_CAMPAIGN_ID.SMS,
            label: 'SMS broadcast',
            Icon: SMSInactiveIcon,
            ActiveIcon: SMSActiveIcon,
          },
        ]
      : []),
    ...(isEmailFlow
      ? [
          {
            id: RECOVERY_CHANNEL_CAMPAIGN_ID.WHATSAPP,
            label: 'Email (manosuperstore@gmail.com)',
            Icon: SMSInactiveIcon,
            ActiveIcon: SMSActiveIcon,
          },
        ]
      : []),
  ];

  const renderBody = () => {
    if (selectedChannel === WHATSAPP_CHANNEL_ID) {
      return ReactHtmlParser(
        urlify(convertWhatsappToHtmlTranscode(recoveryTemplate.label))
      );
    }
    return ReactHtmlParser(parseHTML(urlify(recoveryTemplate.label)));
  };

  const totalAmount = () => {
    if (paymentMethod === 'partial') return finalAmount - credits;
    if (paymentMethod === 'online') return finalAmount;
    return 0;
  };

  useEnterPressAction(open, () => setCurrentStep(2));

  return (
    <Modal
      open={open}
      maxWidth="md-lg"
      showCloseIcon={!overlayLoader}
      closeModal={handleClose}
      className="abandoned-recovery-modal"
    >
      {overlayLoader && <BounceLoader overlayLoader />}
      <Form
        onSubmit={noop}
        initialValues={{
          recovery_message: '',
          broadcast_medium: true,
        }}
        keepDirtyOnReinitialize
        mutators={{ modifyFormField }}
      >
        {({ values, form }) => (
          <>
            <div className="recovery-msg-container">
              <h5 className="section-text-4">
                {isWishlist ? 'Notify customers' : 'Send recovery message'}
              </h5>
              <div className="mt24 d-flex-c-c">
                <div className="d-flex-c-c">
                  <StepIndicator
                    {...(currentStep === 1
                      ? { active: true }
                      : { checked: true })}
                    className={`abandoned-recovery checked `}
                    last={false}
                    number="1"
                  />
                  <div className={`abandoned-recovery-modal-label enabled `}>
                    Campaign details
                  </div>
                </div>
                <StepConnector
                  className={cx('mx16 abandoned-recovery-modal ', {
                    active: currentStep === 2,
                  })}
                />
                <div className="d-flex-c-c">
                  <StepIndicator
                    disabled
                    className={`abandoned-recovery ${
                      currentStep === 2 ? 'checked' : 'disabled'
                    } `}
                    last={false}
                    number="2"
                  />
                  <div
                    className={`abandoned-recovery-modal-label ${
                      currentStep === 1 ? 'disabled' : 'enabled'
                    } `}
                  >
                    Preview &amp; pay
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <div>
                  <div className="mt24">
                    <div className="section-text-5 ">Broadcast medium</div>
                    <div className="mt12 medium-btn d-flex mb24">
                      {broadcastOptions.map((item) => (
                        <BroadcastOptionButton
                          {...item}
                          selected={selectedChannel === item.id}
                          onClick={() => {
                            setSelectedChannel(item.id);
                            fetchTemplates(
                              item.id,
                              {
                                page: 1,
                                offer_type: isWishlist
                                  ? 'wishlist'
                                  : 'cart_abandoned',
                              },

                              noop,
                              (res) => {
                                setRecoveryTemplate({
                                  label: '',
                                  id: null,
                                });
                                form.mutators.modifyFormField(
                                  'recovery_message',
                                  res[0]?.body
                                );
                              }
                            );
                            const createCampaignData = {
                              channel: [item.id],
                              store_lead_count: storeLeadIds.length,
                            };
                            const wishlistData = {
                              channel: [item.id],
                              type: 'wishlist',
                              product_sku: storeLeadIds,
                            };

                            const payloadData = isWishlist
                              ? wishlistData
                              : createCampaignData;
                            fetchCampaignCostEstimate(payloadData, (data) =>
                              handlePrice(data)
                            );
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="mt24">
                    <div className="section-text-5 mb12">
                      {isWishlist ? (
                        <span className="pr4">Messages</span>
                      ) : (
                        <span className="pr4">Recovery messages</span>
                      )}
                      {templates.length ? `(${templates.length})` : ''}
                    </div>
                    <div className="templates-body show-scrollbar">
                      {templates.map((template) => (
                        <div className="mb12">
                          <TemplateItem
                            body={template.label}
                            selected={recoveryTemplate?.id === template.id}
                            onClick={() => {
                              setRecoveryTemplate(template);
                            }}
                            key={template.id}
                            className="recovery-message"
                          />
                        </div>
                      ))}
                      <div ref={templatesLoadingElement} />
                      {templatesLoading && pageNumberTemplates > 1 && (
                        <BounceLoaderSmall inline />
                      )}
                    </div>
                  </div>
                  <div className="mt4 py16 text-center footer">
                    <SpinnerButton
                      onClick={() => setCurrentStep(2)}
                      label="Continue"
                      disabled={templatesLoading}
                    />
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div className="mt24">
                  <div className="d-flex preview-window">
                    <div className="flex-1">
                      <table className="mb24 c-black-1 w-100">
                        <tbody>
                          <tr className="text-2">
                            <td
                              className={cx({
                                pb16: !credits > 0,
                              })}
                            >
                              Broadcast charges
                            </td>
                            <td className="text-right">
                              {formatLocalMoney(priceData.cost)}
                            </td>
                          </tr>
                          {credits > 0 && (
                            <tr className="text-2">
                              <td className="pt8 pb16">Dukaan credits</td>
                              <td className="text-right pt8 pb16">
                                -
                                {formatLocalMoney(
                                  paymentMethod === 'credits'
                                    ? priceData.cost
                                    : credits
                                )}
                              </td>
                            </tr>
                          )}
                          <tr className="section-text-4 border-gray remove-horizontal-border">
                            <td className="py16">Total Amount</td>
                            <td className="text-right py16">
                              {formatLocalMoney(totalAmount())}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <div className="row w-100">
                          <div className="col-md-6">
                            <p className="c-black-3 mb8">NO. OF CUSTOMERS</p>
                            <p className="section-text-5">
                              {isWishlist
                                ? customerLength
                                : storeLeadIds.length}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="c-black-3 mb8">MEDIUM</p>
                            <div className="d-flex align-center">
                              {selectedChannel === SMS_CHANNEL_ID && (
                                <SMSInactiveIcon className="mr6" />
                              )}
                              {selectedChannel === WHATSAPP_CHANNEL_ID && (
                                <WhatsappInactiveIcon className="mr6" />
                              )}
                              <p className="section-text-5">
                                {selectedChannel === WHATSAPP_CHANNEL_ID
                                  ? 'WhatsApp'
                                  : 'SMS'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`msg-preview ${
                        selectedChannel === WHATSAPP_CHANNEL_ID
                          ? 'whatsapp'
                          : 'sms'
                      }`}
                    >
                      <p className="text-2 body arial-font">{renderBody()}</p>
                      {selectedChannel === WHATSAPP_CHANNEL_ID && (
                        <div className="url py8 d-flex-c-c">
                          <ExternalLinkBlack />{' '}
                          <span className="ml6">Buy Now</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex-c-c footer py16">
                    <SpinnerButton
                      type="button"
                      className="btn-outline-4 text-medium mr16"
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </SpinnerButton>
                    <SpinnerButton
                      onClick={() => handleRecoveryTemplate(values)}
                      label={
                        ['partial', 'online'].includes(paymentMethod)
                          ? `Pay ${formatLocalMoney(totalAmount())} & broadcast`
                          : 'Broadcast now'
                      }
                      disabled={templatesLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default RecoveryMessageModal;
