import React, { useState } from 'react';
import { Form } from 'react-final-form';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { getThumbnailUrl } from '../Tutorials/constants';
import { noop } from '../utils';
import { THUMBNAIL_TYPE } from '../utils/constant';
import { FormInput, Validation } from './Form';
import { checkYoutubeOrVimeoLink } from './Form/Validation';
import Modal from './Modal';
import SpinnerButton from './SpinnerButton';
import useEnterPressAction from '../hooks/useEnterPressAction';

const UploadVideoModal = ({ open, close, uploadFile }) => {
  const [videoValues, setVideoValues] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();

  const onAddVideo = (values) => {
    if (checkYoutubeOrVimeoLink(values.videoUrl)) {
      const thumbnail = getThumbnailUrl(values.videoUrl);
      uploadFile(thumbnail, THUMBNAIL_TYPE.YOUTUBE);
      close();
    } else enqueueSnackbar('Please enter link from YouTube.', 'error');
  };

  useEnterPressAction(open, () => onAddVideo(videoValues));

  return (
    <Modal open={open} closeModal={close} maxWidth="sm">
      <p className="section-text-4 mb24">Add video</p>
      <Form
        onSubmit={noop}
        initialValues={{ videoUrl: '' }}
        render={({ invalid, values }) => {
          setVideoValues(values);
          return (
            <>
              <FormInput
                labelText="Youtube URL"
                name="videoUrl"
                placeholder="Paste your link here.."
                validate={Validation.urlValidation()}
                autoFocus
              />
              <div className="text-center">
                <SpinnerButton
                  type="button"
                  className="btn-primary-4 section-text-5 mt12"
                  disabled={invalid}
                  onClick={() => onAddVideo(values)}
                >
                  Add video
                </SpinnerButton>
              </div>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default UploadVideoModal;
