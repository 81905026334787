/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CustomSelect from './index';

import { hasOwnProperty } from '../../utils';
import { getRequest, postRequest } from '../../utils/http';

const DEFAULT_CACHE_KEY = '__initial_cache_key__';

const AsyncSelect = (props) => {
  const noOptionsDefaultMsg = 'No records available';
  const [cache, setCache] = useState({});

  const {
    queryUrl,
    useCache,
    queryParams,
    requestType,
    minimumInputLength,
    placeholder = '',
    isClearOptions,
    defaultOptions = [],
    ...restProps
  } = props;

  const selectProps = {
    placeholder,
  };

  const minimumLengthMsg = `Please enter ${minimumInputLength} or more characters`;

  if (minimumInputLength && !placeholder) {
    selectProps.placeholder = minimumLengthMsg;
  }

  const [options, setOptions] = useState(defaultOptions);
  const [isLoading, setIsLoading] = useState(false);
  const [noOptionsMsg, setNoOptionsMsg] = useState(
    minimumInputLength ? minimumLengthMsg : noOptionsDefaultMsg
  );

  const requestData = (params, resolve, cacheKey) => {
    const request = requestType === 'post' ? postRequest : getRequest;
    setIsLoading(true);
    request({
      url: queryUrl,
      data: {
        ...queryParams,
        ...params,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res) {
          const { results = [] } = res;
          const processedData = results.map(({ id, text, ...rest }) => ({
            value: id,
            label: text,
            ...rest,
          }));
          if (useCache) {
            setCache({
              ...cache,
              [cacheKey]: processedData,
            });
          }
          resolve(processedData);
        } else {
          resolve([]);
        }
      })
      .catch((e) => {
        resolve([]);
        console.log(e);
      });
  };

  const checkCacheAndRequest = (params, resolve, cacheKey) => {
    if (useCache && hasOwnProperty(cache, cacheKey)) {
      resolve(cache[cacheKey]);
    } else {
      requestData(params, resolve, cacheKey);
    }
  };

  const promiseOptions = (inputValue) => {
    const promise = new Promise((resolve) => {
      if (minimumInputLength) {
        setNoOptionsMsg(noOptionsDefaultMsg);
        if (inputValue) {
          if (minimumInputLength && inputValue.length < minimumInputLength) {
            setNoOptionsMsg(minimumLengthMsg);
            resolve([]);
          } else {
            checkCacheAndRequest(
              {
                search: inputValue,
                page: 1,
              },
              resolve,
              inputValue
            );
          }
        } else {
          resolve([]);
        }
      } else {
        checkCacheAndRequest({}, resolve, DEFAULT_CACHE_KEY);
      }
    });

    return promise;
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      promiseOptions(inputValue).then((res) => setOptions(res));
    }
  };

  useEffect(() => {
    if (!minimumInputLength) {
      promiseOptions().then((res) => setOptions(res));
    }
  }, []);

  return (
    <CustomSelect
      isLoading={isLoading}
      onInputChange={onInputChange}
      noOptionsMessage={() => (isClearOptions ? null : noOptionsMsg)}
      options={isClearOptions ? [] : options}
      {...restProps}
      {...selectProps}
    />
  );
};

AsyncSelect.defaultProps = {
  queryUrl: '',
  queryParams: {},
  requestType: 'get',
  useCache: true,
  minimumInputLength: 2,
  defaultOptions: [],
  isClearOptions: false,
};

export default AsyncSelect;
