import React, { useEffect, useState } from 'react';
import { Menu } from '@material-ui/core';
import cx from 'classnames';
import { ArrowIcon, UpArrowCloseIcon } from '../../../SvgIcon';
import { MenuItems } from '../../../shared/Menu';
import useModal from '../../../hooks/useModal';
import { noop } from '../../../utils';
import { useAppContext } from '../../../context/AppContext';
import useProductUpdate from '../../../hooks/useProductUpdateAdvance';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import useCategoryUpdate from '../../../hooks/useCategoryUpdate';
import { getCharecterCount } from '../../../shared';
import { PRODUCT_DESC_MAX_COUNT } from '../../../Catalogue/constants';
import { isNotEmptyOrNull } from '../../../shared/Form/Shared';
import UpdateLanguageDetailsModal from './UpdateLanguageDetailsModal';
import { BounceLoaderSmall } from '../../../shared/BounceLoader';
import { getProductFormData } from '../../../Catalogue/Products/AdvanceCatalogue/common';

const ChangeLanguageDropdown = ({
  values,
  form,
  refetchProductDetails = noop,
  refetchCategoryDetails = noop,
  productUuid,
  isNewProduct = true,
  isCategory = false,
  isNewCategory = true,
  localVariants = [],
  images = [],
  productDetails = {},
  selectedCategories = [],
  categoryUuid,
  languages = [],
  loadingElement,
  loading = false,
  hasMore = false,
  topLevelFormDescription = '',
  setTopLevelFormDescription = noop,
  languageData = [],
  setLanguageData = noop,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const { isOpen, openModal, closeModal } = useModal();
  const [toggleBtnOptions, setToggleBtnOptions] = useState([]);
  const [primaryLanguageData, setPrimaryLanguageData] = useState({});
  const { enqueueSnackbar } = useCustomSnackbar();

  const { business } = useAppContext();
  const { uuid: storeId } = business;
  const {
    isSubmitting: isSubmittingProduct,
    updateProduct,
    updateMiscData,
  } = useProductUpdate({
    uuid: productUuid,
    storeId,
  });

  const {
    isSubmitting: isSubmittingCategory,
    updateCategory,
    bulkUpdateCategories,
  } = useCategoryUpdate(categoryUuid);

  const handleSubmitEditCategory = (
    formValues,
    nameInPrimaryLanguage,
    descInPrimaryLanguage
  ) => {
    if (!isNotEmptyOrNull({ value: values.description }))
      delete values.description;

    if (!isNotEmptyOrNull({ value: values.banner_images })) {
      delete values.banner_images;
    }
    if (!isNotEmptyOrNull({ value: values.banner_images_mobile })) {
      delete values.banner_images_mobile;
    }

    if (
      values?.seo_data?.description === '' &&
      values?.seo_data?.title === ''
    ) {
      delete values.seo_data.title;
      delete values.seo_data.description;
    }
    if (values?.seo_data?.imageUrl) {
      values.seo_data = {
        ...(values?.seo_data || {}),
        image: values?.seo_data?.imageUrl || '',
      };
    }
    if (values?.seoImageUrl) delete values.seoImageUrl;
    if (values?.seoImage) delete values.seoImage;

    form.mutators.modifyFormField('name', nameInPrimaryLanguage);
    form.mutators.modifyFormField('description', descInPrimaryLanguage);
    values.name = nameInPrimaryLanguage;
    values.description = descInPrimaryLanguage;

    updateCategory(
      { ...formValues, ...values },
      () => {
        refetchCategoryDetails();
      },
      (err) => {
        if (err?.data?.data && Array.isArray(err?.data?.data)) {
          if (err.data.data.length > 1)
            enqueueSnackbar(
              'One or more products are not linked to any category.',
              'error'
            );
          else {
            enqueueSnackbar(
              'One product is not linked to any category.',
              'error'
            );
          }
        }
      }
    );
    closeModal();
  };

  const handleSubmitEditProducts = (
    formValues,
    nameInPrimaryLanguage,
    descInPrimaryLanguage
  ) => {
    values.description = (document.getElementById('product_desc') || {}).value;

    const descriptionCount = getCharecterCount(values.description || '');
    if (descriptionCount > PRODUCT_DESC_MAX_COUNT) {
      enqueueSnackbar(
        'Please reduce product description, content length exceeded!',
        'error'
      );
      return;
    }

    form.mutators.modifyFormField('name', nameInPrimaryLanguage);
    form.mutators.modifyFormField('description', descInPrimaryLanguage);
    values.name = nameInPrimaryLanguage;
    values.description = descInPrimaryLanguage;

    if (!isNotEmptyOrNull({ value: values.is_taxable }))
      delete values.is_taxable;

    const languageDataPayload = formValues?.language_data;
    const payload = getProductFormData(
      images,
      {
        languageDataPayload,
        ...values,
        categories: selectedCategories,
      },
      productDetails,
      localVariants
    );
    if (!isNotEmptyOrNull({ value: payload?.image })) {
      delete payload?.image;
    }

    if (!isNotEmptyOrNull({ value: payload?.inventory_quantity })) {
      delete payload?.inventory_quantity;
    }

    updateProduct(payload, () => {
      refetchProductDetails();
      enqueueSnackbar('Language data updated successfully!');
      window.location.reload();
    });

    closeModal();
  };

  const handleEditSubmit = (
    formValues,
    nameInPrimaryLanguage,
    descInPrimaryLanguage,
    languageValues
  ) => {
    if (isCategory) {
      handleSubmitEditCategory(
        formValues,
        nameInPrimaryLanguage,
        descInPrimaryLanguage
      );
    } else
      handleSubmitEditProducts(
        formValues,
        nameInPrimaryLanguage,
        descInPrimaryLanguage,
        languageValues
      );
  };

  useEffect(() => {
    if (languages.length) {
      //  Filtering out
      const languagesData = languages.map((item) => ({
        id: item.id,
        language: item.language,
      }));

      const primaryLanguage = languages.find((item) => item.is_default);
      setPrimaryLanguageData(primaryLanguage);

      const menuItemsArr = languagesData.map((item) => ({
        labelText: item.language.name,
        className: '',
        onClick: () => {
          openModal();
          setCurrentLanguage(item.language.name);
        },
      }));
      const optionsArr = languagesData.map((item) => ({
        label: item.language.name,
        value: item.language.name,
        id: item.id,
      }));
      setMenuItems(menuItemsArr);
      setToggleBtnOptions(optionsArr);
    }
  }, [languages]);

  return (
    <div>
      <div
        className={cx('d-flex-c-s language-dropdown-btn')}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      >
        <span className="mr8">
          {primaryLanguageData?.language?.name ?? 'English'}
        </span>
        {menuAnchorEl ? <UpArrowCloseIcon /> : <ArrowIcon />}
      </div>
      <Menu
        id="language-dropdown-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            width: 180,
            marginTop: 42,
            maxHeight: 450,
          },
        }}
      >
        <MenuItems
          menuItems={menuItems}
          onCloseMenu={() => setMenuAnchorEl(null)}
        />
        <div ref={loadingElement} />
        {loading && hasMore && (
          <div className="py24 more-table-row">
            <BounceLoaderSmall inline />
          </div>
        )}
      </Menu>
      {isOpen && (
        <UpdateLanguageDetailsModal
          open={isOpen}
          closeModal={closeModal}
          options={toggleBtnOptions}
          currentLanguage={currentLanguage}
          topLevelFormValues={values}
          topLevelForm={form}
          isNew={isCategory ? isNewCategory : isNewProduct}
          title={`Update ${isCategory ? 'category' : 'product'} details`}
          modalDescription={`Update the ${
            isCategory ? 'category' : 'product'
          } name and description in your store languages.`}
          isSubmitting={isCategory ? isSubmittingCategory : isSubmittingProduct}
          handleEditSubmit={handleEditSubmit}
          isCategory={isCategory}
          primaryLanguage={primaryLanguageData}
          topLevelFormDescription={topLevelFormDescription}
          setTopLevelFormDescription={setTopLevelFormDescription}
          setLanguageData={setLanguageData}
          languageData={languageData}
        />
      )}
    </div>
  );
};

export default ChangeLanguageDropdown;
