import React, { useEffect } from 'react';
import cx from 'classnames';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';

import BounceLoader from '../shared/BounceLoader';

const SuspenseLoader = ({ className }) => {
  useEffect(() => {
    nprogress.configure({ showSpinner: false });
    nprogress.start();
    setTimeout(() => {
      nprogress.done();
    }, 400);
  }, []);
  return (
    <div className={cx('suspense-loader', className)}>
      <BounceLoader />
    </div>
  );
};

export default SuspenseLoader;
