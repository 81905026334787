import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import useScrollingRef from '../hooks/useScrollingRef';
import { SelectStoreRightArrow } from '../SvgIcon';
import { accountUrls } from '../Urls';
import BounceLoader from './BounceLoader';

const SelectStoreList = ({ onAuthPage = false }) => {
  const {
    stores,
    switchToStore,
    business,
    storesListPageNumber,
    storesListLoading: loading,
    incrementStoresListPageNumber,
    hasMoreStores,
    fetchStores,
  } = useAppContext();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const loadingElement = useScrollingRef(loading, hasMoreStores, setPageNumber);

  useEffect(() => {
    incrementStoresListPageNumber(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber === storesListPageNumber) fetchStores();
  }, [storesListPageNumber]);

  return (
    <div
      className={`store-list-container d-flex flex-column show-scrollbar ${
        onAuthPage ? 'max-height' : ''
      }`}
    >
      {stores.map((item, index) => (
        <div
          className={`store-list-item ${
            business?.id === item.id ? 'first-order' : ''
          }`}
          onClick={() => {
            if (business?.id === item.id && !onAuthPage) {
              history.push(accountUrls.settingsPath);
            } else {
              switchToStore(item.id);
            }
          }}
          key={`store-name-${index}`}
        >
          <div className="store-info">
            <div
              className={`store-description ${
                business?.id === item.id && !onAuthPage ? 'current' : ''
              }`}
            >
              <p className="section-text-5 pb2 text-ellipsis">{item.name}</p>
              <p className="text-8 c-gray-1 text-ellipsis">{item.link}</p>
            </div>
            {business?.id === item.id && !onAuthPage ? (
              <div className="current-label">current</div>
            ) : (
              <SelectStoreRightArrow />
            )}
          </div>
          {stores.length > 1 && item.id === stores[0]?.id && (
            <div className="primary-store section-text-13 c-black-3 py4 px16">
              PRIMARY STORE
            </div>
          )}
        </div>
      ))}
      {loading && (
        <div className="loader-wrapper">
          <BounceLoader />
        </div>
      )}
      {!loading && <div ref={loadingElement} />}
    </div>
  );
};

export default SelectStoreList;
