import { useEffect } from 'react';
import { setDataToLocalStorage } from '../../utils/storage';
import useQuery from '../useQuery';

const useUtmParams = () => {
  const query = useQuery();

  useEffect(() => {
    const source = query.get('utm_source');
    const medium = query.get('utm_medium');
    const campaign = query.get('utm_campaign');
    const linkMinkAffiliateId = query.get('lmref');

    if (source) setDataToLocalStorage('utm_source', source);
    if (medium) setDataToLocalStorage('utm_medium', medium);
    if (campaign) setDataToLocalStorage('utm_campaign', campaign);
    if (linkMinkAffiliateId) localStorage.setItem('lmref', linkMinkAffiliateId);
  }, [query]);
};

export default useUtmParams;
