import { createContext, useContext, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CANVA_UNDO_ACTIONS_MAPPING,
  CANVA_ALLOWED_ACTIONS,
} from '../shared/Canva/constants';

const CanvaContext = createContext({});
const { ADD, COPY, DELETE, MODIFY, REORDER } = CANVA_ALLOWED_ACTIONS;

const CanvaProvider = ({ children }) => {
  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const [shapes, setShapes] = useState({});
  const [zIndexOrder, setZIndexOrder] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedShapeId, setSelectedShapeId] = useState(null);
  const [fontFamily, setFontFamily] = useState('Inter');
  const [fontSize, setFontSize] = useState(35);
  const [fontColor, setFontColor] = useState('#000000');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);
  const [opacity, setOpacity] = useState(100);
  const [textAlignmentNumber, setTextAlignmentNumber] = useState(1);
  const [uniqueColors, setUniqueColors] = useState([]);
  const [oldSvgColor, setOldSvgColor] = useState('#000000');
  const [isTextEditing, setTextEditing] = useState({
    status: false,
    shapeId: null,
  });
  const [copiedShapeId, setCopiedShapeId] = useState(null);
  const [isUserTriggered, setIsUserTriggered] = useState(false);

  // STACK OPERATIONS
  const addToUndoStack = ({ type, ...args }) => {
    if (!CANVA_ALLOWED_ACTIONS[type]) return;
    const undoStackArray = [...undoStack];
    undoStackArray.push({
      actionType: CANVA_UNDO_ACTIONS_MAPPING[type],
      ...args,
    });

    setUndoStack(undoStackArray);
  };

  // HELPERS
  const resetTextFormattingStates = () => {
    setIsBold(false);
    setIsItalic(false);
    setIsUnderlined(false);
    setTextAlignmentNumber(1);
  };

  const replaceSvgColor = (hex) => {
    const oldProps = { ...shapes[selectedShapeId] };
    addToUndoStack({
      type: MODIFY,
      shapeId: selectedShapeId,
      shapeProps: { ...oldProps },
    });
    const svgUrl = oldProps.string;
    const re = new RegExp(oldSvgColor, 'gi');
    const newSvgCode = svgUrl.replaceAll(re, `${hex}`);

    setShapes({
      ...shapes,
      [selectedShapeId]: {
        ...shapes[selectedShapeId],
        string: newSvgCode,
      },
    });
  };

  // Z INDEX LAYERING OPERATIONS
  const addNewShapeToZIndex = ({ shapeId }) => {
    const zIndexOrderArray = [...zIndexOrder];
    zIndexOrderArray.push(shapeId);
    setZIndexOrder(zIndexOrderArray);
  };

  const deleteShapeFromZIndex = ({ shapeId }) => {
    const zIndexOrderArray = [...zIndexOrder];
    const index = zIndexOrderArray.indexOf(shapeId);
    zIndexOrderArray.splice(index, 1);
    setZIndexOrder(zIndexOrderArray);
  };

  const reorderShapeZIndex = ({
    shapeId,
    increment = false,
    decrement = false,
    toBottom = false,
    toTop = false,
    toMid = false,
  }) => {
    if (!shapeId) return;
    const zIndexOrderArray = [...zIndexOrder];
    const index = zIndexOrderArray.indexOf(shapeId);
    if (increment) {
      if (index < zIndexOrderArray.length - 1) {
        zIndexOrderArray[index] = zIndexOrderArray[index + 1];
        zIndexOrderArray[index + 1] = shapeId;
      }
    } else if (decrement) {
      if (index > 0) {
        zIndexOrderArray[index] = zIndexOrderArray[index - 1];
        zIndexOrderArray[index - 1] = shapeId;
      }
    } else if (toBottom) {
      zIndexOrderArray.splice(index, 1);
      zIndexOrderArray.unshift(shapeId);
    } else if (toTop) {
      zIndexOrderArray.splice(index, 1);
      zIndexOrderArray.push(shapeId);
    } else if (toMid) {
      const mid = zIndexOrderArray.length / 2;
      zIndexOrderArray.splice(index, 1);
      zIndexOrderArray.splice(mid, 0, shapeId);
    } else return;

    setZIndexOrder(zIndexOrderArray);
  };

  const realignShapePosition = ({
    canvasWidth,
    canvasHeight,
    shapeId,
    toTop,
    toMiddle,
    toBottom,
    toLeft,
    toCenter,
    toRight,
  }) => {
    if (!shapeId) return;
    const shapeProps = { ...shapes[shapeId] };
    const node = stageRef.current.find(`#${shapeId}`)[0];
    var box = node.getClientRect();
    if (toTop) {
      shapeProps.y = 0;
    } else if (toBottom) {
      shapeProps.y = canvasHeight - box.height;
    } else if (toMiddle) {
      shapeProps.y = (canvasHeight - box.height) / 2;
    }
    if (toLeft) {
      shapeProps.x = 0;
    } else if (toRight) {
      shapeProps.x = canvasWidth - box.width;
    } else if (toCenter) {
      shapeProps.x = (canvasWidth - box.width) / 2;
    }
    setShapes({ ...shapes, [shapeId]: { ...shapeProps } });
  };

  // SHAPE OPERATIONS
  const addNewShape = ({ shapeId, shapeProps }) => {
    const sid = shapeId || uuidv4();
    const shapesObj = { ...shapes };
    shapesObj[sid] = { ...shapeProps, id: sid, key: sid };
    setShapes({
      ...shapesObj,
    });
    return sid;
  };

  const deleteShape = ({ shapeId }) => {
    if (!shapeId) return;
    const shapesObj = { ...shapes };
    delete shapesObj[shapeId];
    setShapes({ ...shapesObj });
  };

  const modifyShape = ({ shapeId, newProps }) => {
    if (!shapeId) return;
    const shapesObj = { ...shapes };
    const oldProps = { ...shapesObj[shapeId] };
    shapesObj[shapeId] = {
      ...shapesObj[shapeId],
      ...newProps,
    };
    setShapes({ ...shapesObj });
    return oldProps;
  };

  const deselectShape = () => {
    // first click - editing textbox -> selected textbox
    // second click - selected textbox -> unselected

    if (isTextEditing.status) {
      setSelectedShapeId(isTextEditing.shapeId);
      setTextEditing({ status: false, shapeId: null });
    } else {
      setSelectedShapeId(null);
      resetTextFormattingStates();
    }
  };

  const handleAddShape = ({
    type,
    svgString = '',
    canvasWidth,
    canvasHeight,
  }) => {
    if (!type) return;
    setSelectedShapeId(null);
    let newShape = {};
    if (type === 'textbox') {
      resetTextFormattingStates();
      newShape = {
        type: 'textbox',
        x: 16,
        y: (canvasHeight - 19) / 2,
        text: 'Add Text',
        fontSize: 20,
        fontFamily,
        fill: '#FFFFFF',
        scaleX: 1,
        scaleY: 1,
        width: canvasWidth - 32,
        align: 'center',
        rotation: 0,
      };
    } else if (type === 'svg') {
      newShape = {
        type: 'svg',
        x: (canvasWidth - 60) / 2,
        y: (canvasHeight - 60) / 2,
        string: svgString,
        url: '',
        scaleX: 3,
        scaleY: 3,
        rotation: 0,
      };
    }
    const uid = addNewShape({ shapeProps: newShape });
    addNewShapeToZIndex({
      shapeId: uid,
    });
    addToUndoStack({
      type: ADD,
      shapeId: uid,
      shapeProps: { ...newShape },
    });
    setSelectedShapeId(uid);
  };

  // HANDLERS
  const handleUndo = () => {
    const redoStackArray = [...redoStack];
    const undoStackArray = [...undoStack];
    const undoAction = undoStackArray.pop();
    if (!undoAction) return;

    if (undoAction.actionType === ADD) {
      const newShapeId = addNewShape({
        shapeId: undoAction.shapeId,
        shapeProps: undoAction.shapeProps,
      });
      addNewShapeToZIndex({ shapeId: newShapeId });
      redoStackArray.push({
        actionType: CANVA_UNDO_ACTIONS_MAPPING[ADD],
        shapeId: newShapeId,
        shapeProps: undoAction.shapeProps,
      });
    }
    if (undoAction.actionType === DELETE) {
      deleteShape({ shapeId: undoAction.shapeId });
      deleteShapeFromZIndex({ shapeId: undoAction.shapeId });
      setSelectedShapeId(null);
      redoStackArray.push({
        ...undoAction,
        actionType: CANVA_UNDO_ACTIONS_MAPPING[DELETE],
      });
    }
    if (undoAction.actionType === MODIFY) {
      const oldProps = modifyShape({
        shapeId: undoAction.shapeId,
        newProps: undoAction.shapeProps,
      });
      redoStackArray.push({
        actionType: CANVA_UNDO_ACTIONS_MAPPING[MODIFY],
        shapeId: undoAction.shapeId,
        shapeProps: oldProps,
      });
    }
    if (undoAction.actionType === REORDER) {
      const prevZIndexOrder = [...zIndexOrder];
      setZIndexOrder(undoAction.zIndexOrder);
      redoStackArray.push({
        actionType: CANVA_UNDO_ACTIONS_MAPPING[REORDER],
        zIndexOrder: prevZIndexOrder,
      });
    }

    setRedoStack(redoStackArray);
    setUndoStack(undoStackArray);
  };
  const handleRedo = () => {
    const undoStackArray = [...undoStack];
    const redoStackArray = [...redoStack];
    const redoAction = redoStackArray.pop();
    if (!redoAction) return;
    if (redoAction.actionType === ADD) {
      const newShapeId = addNewShape({
        shapeId: redoAction.shapeId,
        shapeProps: redoAction.shapeProps,
      });
      addNewShapeToZIndex({ shapeId: newShapeId });
      undoStackArray.push({
        actionType: CANVA_UNDO_ACTIONS_MAPPING[ADD],
        shapeId: newShapeId,
        shapeProps: redoAction.shapeProps,
      });
    }

    if (redoAction.actionType === DELETE) {
      deleteShape({ shapeId: redoAction.shapeId });
      deleteShapeFromZIndex({ shapeId: redoAction.shapeId });
      setSelectedShapeId(null);
      undoStackArray.push({
        ...redoAction,
        actionType: CANVA_UNDO_ACTIONS_MAPPING[DELETE],
      });
    }
    if (redoAction.actionType === MODIFY) {
      const oldProps = modifyShape({
        shapeId: redoAction.shapeId,
        newProps: redoAction.shapeProps,
      });
      undoStackArray.push({
        actionType: CANVA_UNDO_ACTIONS_MAPPING[MODIFY],
        shapeId: redoAction.shapeId,
        shapeProps: oldProps,
      });
    }
    if (redoAction.actionType === REORDER) {
      const prevZIndexOrder = [...zIndexOrder];
      setZIndexOrder(redoAction.zIndexOrder);
      undoStackArray.push({
        actionType: CANVA_UNDO_ACTIONS_MAPPING[REORDER],
        zIndexOrder: prevZIndexOrder,
      });
    }
    setUndoStack(undoStackArray);
    setRedoStack(redoStackArray);
  };

  const handleDecrementZIndex = () => {
    setIsUserTriggered(true);
    reorderShapeZIndex({ shapeId: selectedShapeId, decrement: true });
  };

  const handleIncrementZIndex = () => {
    setIsUserTriggered(true);
    reorderShapeZIndex({ shapeId: selectedShapeId, increment: true });
  };

  const handleAlign = ({ canvasHeight, canvasWidth, ...args }) => {
    setIsUserTriggered(true);
    realignShapePosition({
      canvasWidth,
      canvasHeight,
      shapeId: selectedShapeId,
      ...args,
    });
  };

  const handleDeleteShape = () => {
    const shapesObj = { ...shapes };
    if (!selectedShapeId) return;
    deleteShapeFromZIndex({ shapeId: selectedShapeId });
    const deletedShape = { ...shapesObj[selectedShapeId] };
    delete shapesObj[selectedShapeId];

    setShapes(shapesObj);
    setSelectedShapeId(null);
    setTextEditing({ status: false, shapeId: null });
    addToUndoStack({
      type: DELETE,
      shapeId: selectedShapeId,
      shapeProps: { ...deletedShape },
    });
  };

  const handleCopyShape = ({ shapeId }) => {
    const zIndexOrderArray = [...zIndexOrder];
    const shapesObj = { ...shapes };
    const copyShapeId = shapeId || selectedShapeId;
    if (!copyShapeId) return;
    if (!shapesObj[copyShapeId]) return;

    const newShapeObj = {
      ...shapesObj[copyShapeId],
      x: shapesObj[copyShapeId].x + 10,
      y: shapesObj[copyShapeId].y + 10,
      fill: shapesObj[copyShapeId].fill,
    };

    const newShapeId = addNewShape({
      shapeProps: newShapeObj,
    });

    zIndexOrderArray.push(newShapeId);
    setSelectedShapeId(newShapeId);
    setZIndexOrder(zIndexOrderArray);

    addToUndoStack({
      type: COPY,
      shapeId: newShapeId,
      shapeProps: { ...newShapeObj },
    });
  };

  const handleOnClickCanvasContainer = (e) => {
    const clickedOnEmpty = e.target.id === 'canvas-container';
    if (clickedOnEmpty) {
      deselectShape();
    }
  };

  const handleMouseDown = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target.getId() === 'background-image';
    if (clickedOnEmpty) {
      deselectShape();
    }
  };

  const handleOutsideClickEvent = (e) => {
    const canvaToolbarDiv = document.getElementById('canva-toolbar');
    const menuDiv = document.getElementsByClassName(
      'shapes-menu-item-content'
    )[0];
    const opacityMenuDiv = document.getElementsByClassName(
      'opacity-slider-menu'
    )[0];
    const colorPickerDiv = document.getElementsByClassName('sketch-picker')[0];

    // check if canva toolbar or any of its children are not clicked on
    if (canvaToolbarDiv !== e.target && !canvaToolbarDiv?.contains(e.target)) {
      if (
        !e.target.getAttribute('aria-hidden') &&
        menuDiv !== e.target &&
        !menuDiv?.contains(e.target) &&
        // is color picker menu open or clicked ?
        (colorPickerDiv === undefined ||
          (colorPickerDiv !== e.target &&
            !colorPickerDiv?.contains(e.target))) &&
        // is opacity menu open or clicked ?
        (opacityMenuDiv === undefined ||
          (opacityMenuDiv !== e.target && !opacityMenuDiv?.contains(e.target)))
      ) {
        deselectShape();
      }
    }
  };

  const handleOnMouseHover = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const handleOnMouseLeaveHover = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
  };

  const handleKeyboardKeys = (e, onPrevClick, onNextClick) => {
    if (!isTextEditing.status) {
      // copy
      if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'c') {
        if (selectedShapeId) {
          setCopiedShapeId(selectedShapeId);
        }
        return;
      }
      // paste
      if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'v') {
        if (copiedShapeId) {
          handleCopyShape({ shapeId: copiedShapeId });
          setCopiedShapeId(null);
        }
        return;
      }
      // undo
      if (
        (e.metaKey || e.ctrlKey) &&
        e.key.toLowerCase() === 'z' &&
        !e.shiftKey
      ) {
        handleUndo();
        return;
      }
      // redo
      if (
        (e.metaKey && e.shiftKey && e.key.toLowerCase() === 'z') ||
        (e.ctrlKey && e.key.toLowerCase() === 'y')
      ) {
        handleRedo();
        return;
      }
      // delete
      if (
        e.key.toLowerCase() === 'delete' ||
        e.key.toLowerCase() === 'backspace'
      ) {
        handleDeleteShape();
      }
      // prev background
      if (e.key.toLowerCase() === 'arrowleft' && !selectedShapeId) {
        onPrevClick();
      }
      // next background
      if (e.key.toLowerCase() === 'arrowright' && !selectedShapeId) {
        onNextClick();
      }
    }
  };

  return (
    <CanvaContext.Provider
      value={{
        stageRef,
        undoStack,
        setUndoStack,
        redoStack,
        setRedoStack,
        shapes,
        setShapes,
        zIndexOrder,
        setZIndexOrder,
        selectedShapeId,
        setSelectedShapeId,
        layerRef,
        fontFamily,
        setFontFamily,
        fontSize,
        setFontSize,
        fontColor,
        setFontColor,
        opacity,
        setOpacity,
        isBold,
        setIsBold,
        isItalic,
        setIsItalic,
        isUnderlined,
        setIsUnderlined,
        textAlignmentNumber,
        setTextAlignmentNumber,
        uniqueColors,
        setUniqueColors,
        replaceSvgColor,
        oldSvgColor,
        setOldSvgColor,
        isTextEditing,
        setTextEditing,
        isUserTriggered,
        setIsUserTriggered,
        realignShapePosition,
        addToUndoStack,
        addNewShapeToZIndex,
        deleteShapeFromZIndex,
        deleteShape,
        resetTextFormattingStates,
        handleAddShape,
        handleDeleteShape,
        handleCopyShape,
        handleMouseDown,
        handleOnMouseHover,
        handleOnMouseLeaveHover,
        handleDecrementZIndex,
        handleIncrementZIndex,
        handleAlign,
        handleUndo,
        handleRedo,
        handleOutsideClickEvent,
        handleOnClickCanvasContainer,
        handleKeyboardKeys,
      }}
    >
      {children}
    </CanvaContext.Provider>
  );
};

export default CanvaProvider;
export const useCanvaContext = () => useContext(CanvaContext);
