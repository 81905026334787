import cx from 'classnames';
import React from 'react';
import { WhiteTick } from '../../SvgIcon';

const StepStatusIndicator = ({ className, children }) => (
  <div className={cx('custom-stepper-step-status-indicator', className)}>
    {children}
  </div>
);

const NumberStatusIndicator = ({ number, ...rest }) => (
  <StepStatusIndicator {...rest}>{number}</StepStatusIndicator>
);

const CheckedStatusIndicator = ({ ...rest }) => (
  <StepStatusIndicator {...rest}>
    <WhiteTick />
  </StepStatusIndicator>
);

const StepConnector = ({ className }) => (
  <div className={cx('custom-stepper-step-connector', className)} />
);

const StepIndicator = (props) => {
  const {
    checked,
    active,
    disabled,
    last,
    className,
    stepperIndicatorClassName = '',
  } = props;
  return (
    <div className="custom-stepper-step-indicator">
      {checked && (
        <CheckedStatusIndicator {...props} className={`checked ${className}`} />
      )}
      {active && (
        <NumberStatusIndicator {...props} className={`active ${className}`} />
      )}
      {disabled && (
        <NumberStatusIndicator {...props} className={`disabled ${className}`} />
      )}
      {!last && (
        <>
          {checked && <StepConnector last={last} className="active" />}
          {active && (
            <StepConnector
              last={last}
              className={
                stepperIndicatorClassName !== ''
                  ? `${stepperIndicatorClassName}`
                  : 'disabled'
              }
            />
          )}
          {disabled && <StepConnector last={last} className="hidden" />}
        </>
      )}
    </div>
  );
};

export default StepIndicator;
