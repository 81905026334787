import React, { useEffect } from 'react';
import { Field } from 'react-final-form';

const HiddenInput = ({ form, name, value }) => {
  useEffect(() => form.change(name, value), [form, name, value]);

  return (
    <Field name={name}>
      {({ input }) => <input {...input} type="hidden" />}
    </Field>
  );
};

export default HiddenInput;
