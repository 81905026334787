import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { noop } from '../utils';
import SpinnerButton from '../shared/SpinnerButton';
import ImageLoader from '../shared/ImageLoader';
import { useAppContext } from '../context/AppContext';
import BounceLoader from '../shared/BounceLoader';
import { ExternalLink } from '../shared';
import { addCDN } from '../utils/file';
import { EXTRA_LARGE_SCREEN_WIDTH } from '../constants';

const AnnoucementsMenu = ({ closeMenu = noop, isDashboard = false }) => {
  const history = useHistory();
  const {
    announcementsLoading,
    announcements = [],
    isAppSumoStore,
    isEnterprise,
  } = useAppContext();

  // on screen 1920px and above show 3 cards
  const show2Cards = useMediaQuery({ maxWidth: EXTRA_LARGE_SCREEN_WIDTH - 1 });
  const SHOW_2_CARDS = 2;
  const SHOW_3_CARDS = 3;
  let MAX_CARDS_ON_DASHBOARD;
  if (isEnterprise) {
    MAX_CARDS_ON_DASHBOARD = SHOW_3_CARDS;
  } else if (show2Cards) {
    MAX_CARDS_ON_DASHBOARD = SHOW_2_CARDS;
  } else MAX_CARDS_ON_DASHBOARD = SHOW_3_CARDS;
  // const MAX_CARDS_ON_DASHBOARD = show2Cards ? SHOW_2_CARDS : SHOW_3_CARDS;
  const announcementsList = isDashboard
    ? announcements.slice(0, MAX_CARDS_ON_DASHBOARD)
    : announcements;

  if (isAppSumoStore) return null;

  return (
    <>
      {isDashboard && announcementsList.length > 0 && (
        <div className="d-flex-c-s mb24">
          <p className="section-text-3">What’s new</p>
        </div>
      )}
      <div
        className={cx('announcement-grid', {
          'dashboard-announcement-grid': isDashboard,
          'show-3-cards':
            (isDashboard && !show2Cards && !isEnterprise) ||
            (isDashboard && isEnterprise),
          'announcement-dropdown-menu show-scrollbar': !isDashboard,
        })}
      >
        {announcementsList.map(
          (
            {
              title,
              description,
              image_url: image,
              action_endpoint: action,
              content_url: helpLink,
            },
            index
          ) => (
            <div className="announcement-item" key={index}>
              {image && (
                <div
                  onClick={() => {
                    if (action) {
                      history.push(
                        `${action.startsWith('/') ? action : `/${action}`}`
                      );
                      closeMenu();
                    }
                  }}
                  className="image-container cur-p mb12"
                >
                  <ImageLoader
                    width={352}
                    height={isDashboard ? 154 : 166}
                    src={addCDN(image, 352, 166, 3)}
                    className="image"
                  />
                </div>
              )}
              <p className="text-2 text-medium mb8">{title}</p>
              <p className="announcement-description">{description}</p>
              <div
                className={`d-flex align-center ${
                  isDashboard ? 'mt20' : 'mt16'
                }`}
              >
                {action && (
                  <SpinnerButton
                    className={
                      isDashboard
                        ? 'btn-outline-gray btn-small mr16 px20'
                        : 'btn-primary-4 btn-small mr12'
                    }
                    onClick={() => {
                      history.push(
                        `${action.startsWith('/') ? action : `/${action}`}`
                      );
                      closeMenu();
                    }}
                    label="Try now"
                  />
                )}
                {helpLink && (
                  <>
                    {isDashboard ? (
                      <ExternalLink
                        href={helpLink}
                        className="announcement-learn-more"
                        text="Learn more"
                      />
                    ) : (
                      <SpinnerButton
                        type="button"
                        className="btn-small btn-outline-primary-4"
                        onClick={() => {
                          window.open(helpLink, '_blank');
                          closeMenu();
                        }}
                        label="Read more"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )
        )}
        {announcementsLoading && !isDashboard && <BounceLoader inline />}
      </div>
    </>
  );
};

export default withRouter(AnnoucementsMenu);
