import { createContext, useContext, useState } from 'react';

import { DELETE_EMAIL_FORWARDING, EMAIL_FORWARDING } from '../ApiUrls';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { getErrorMessage, noop } from '../utils';
import { deleteRequest, getRequest, postRequest } from '../utils/http';

const ForwardingEmailContext = createContext({
  isAddingEmail: false,
  isDeletingEmail: false,
  isEmailsLoading: false,
  emails: [],
  fetchForwardingEmails: noop,
  postForwardingEmail: noop,
  deleteForwardingEmail: noop,
});

export const useForwardingEmails = () => useContext(ForwardingEmailContext);

const ForwardingEmailProvider = ({ children }) => {
  const [isEmailsLoading, setIsEmailsLoading] = useState(false);
  const [isAddingEmail, setIsAddingEmail] = useState(false);
  const [isDeletingEmail, setIsDeletingEmail] = useState(false);
  const [emails, setEmails] = useState([]);
  const { enqueueSnackbar } = useCustomSnackbar();

  const fetchForwardingEmails = () => {
    setIsEmailsLoading(true);
    getRequest({
      url: EMAIL_FORWARDING,
    })
      .then(({ results }) => {
        setEmails(results);
      })
      .catch((e) => console.log('err', e))
      .finally(() => setIsEmailsLoading(false));
  };

  const postForwardingEmail = (payload, callbackFunc = noop) => {
    setIsAddingEmail(true);
    postRequest({
      url: EMAIL_FORWARDING,
      data: payload,
    })
      .then(({ status }) => {
        if (status === 'success') {
          enqueueSnackbar('Email added successfully!');
          fetchForwardingEmails();
        }
      })
      .catch((e) => {
        console.log('err', e);
        const { data = {} } = e;
        let errorObject = {};
        if (typeof data !== 'string' && data.data) {
          errorObject = data.data;
        }
        const errMsg = getErrorMessage(errorObject);
        enqueueSnackbar(errMsg, 'error');
      })
      .finally(() => {
        setIsAddingEmail(false);
        if (callbackFunc) callbackFunc();
      });
  };

  const deleteForwardingEmail = (id, callbackFunc = noop) => {
    setIsDeletingEmail(true);
    deleteRequest({
      url: DELETE_EMAIL_FORWARDING(id),
    })
      .then(({ data, status }) => {
        if (status === 'success') {
          enqueueSnackbar(data.message);
          fetchForwardingEmails();
        }
      })
      .catch((e) => {
        console.log('err', e);
        const { data = {} } = e;
        let errorObject = {};
        if (typeof data !== 'string' && data.data) {
          errorObject = data.data;
        }
        const errMsg = getErrorMessage(errorObject);
        enqueueSnackbar(errMsg, 'error');
      })
      .finally(() => {
        setIsDeletingEmail(false);
        if (callbackFunc) callbackFunc();
      });
  };

  const contextValue = {
    isAddingEmail,
    isDeletingEmail,
    isEmailsLoading,
    emails,
    fetchForwardingEmails,
    postForwardingEmail,
    deleteForwardingEmail,
  };

  return (
    <ForwardingEmailContext.Provider value={contextValue}>
      {children}
    </ForwardingEmailContext.Provider>
  );
};

export default ForwardingEmailProvider;
