import React from 'react';
import { Hidden, Drawer } from '@material-ui/core';
import MenuList from './MenuList';

const MenuSidebar = () => {
  const classes = {
    paper: 'menu-sidebar',
  };

  return (
    <nav className="menu-sidebar-wrap">
      <Hidden xsDown implementation="css">
        <Drawer classes={classes} variant="permanent" open>
          <MenuList />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MenuSidebar;
