import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Shimmer } from 'react-shimmer';
import { formatDate5, formatOrderTime } from '../utils/date';
import {
  RightArrow1Icon,
  DiningIcon,
  SuccessIcon,
  CrossIcon,
  DownloadIcon,
  TickGreenIcon,
  ShipmentCancelIcon,
  ReturnRedIcon,
  ArrowDownScrollIcon,
  TickThinIcon,
  ReplacementRedIcon,
  DeclineOrderIcon,
  CopyShapeIcon,
  ErrorIcon,
  ShareRecieptIcon,
} from '../SvgIcon';
import Modal from '../shared/Modal';
import { FormNumberInput } from '../shared/Form';
import CustomRadio from '../shared/CustomRadio';
import {
  PERCENTAGE_CHARGE_TYPE,
  FLAT_CHARGE_TYPE,
  PAYMENT_TYPES,
  REFUND_TYPES,
  BANK_TRANSFER_KEY,
  SOURCE_METHOD_KEY,
  CUSTOMER_WALLET_KEY,
  REFUND_LABEL_MAPPING,
} from '../Account/constants';
import useOrderUpdate from '../hooks/useOrderUpdate';
import ManualPaymentStatusToggle from './ManualPaymentStatusToggle';

import {
  ORDER_STATUS_MAP,
  PAYMENT_MODE_MAP,
  PAYMENT_MODE_SHORT_MAP,
  RESTAURANT_ORDER_STATUS_MAP,
  PENDING_STATUS,
  MANUAL_PAYMENT_METHOD_DRAFT_STATUS,
  CASH_PAYMENT_MODE,
  MANUAL_PAYMENT_MODE,
  FORM_FIELD_TYPES,
  DINING_PAYMENT_MODE_SHORT_MAP,
  DINING_PAYMENT_MODE_MAP,
  ONLINE_PAYMENT_MODE,
  OrderPaymentProviderLogo,
  REJECTED_RETURN,
  COMPLETED_RETURN,
  RTO_HIGH_RISK,
  RTO_MEDIUM_RISK,
  RTO_LOW_RISK,
  ORDER_LABELS_NAME_MAP,
  RETURN_STATUS,
  GET_RETURN_STATUS_MAP,
  UNPAID_ORDER,
  RETURN_REFUND_STATUS_MAP,
  REFUND_ACCOUNT_TYPE,
  SHIPPED_STATUS,
  ORDER_CREATION,
  CHANNEL_TEXT,
  ORDER_TYPES,
  CANCELLED_STATUS,
  FAILED_STATUS,
  PREPAID_ORDER,
  FREEBIE_DISCOUNT_COUPON_KEY,
  DISCOUNT_TYPE_COUPON,
  DISCOUNT_TYPE_MANUAL,
  MANUAL_PAYMENT_METHOD,
  LOGIN_AS_CUSTOMER_ENABLED,
  ABANDONED_STATUS,
  DISCOUNT_TYPE_MEMBERSHIP,
  LOYALTY_POINTS_COUPON_KEY,
} from './constants';
import { getFormattedColor } from '../utils/color';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useUpdatedCouponDiscount from '../hooks/useUpdatedCouponDiscount';
import { useAppContext } from '../context/AppContext';
import {
  capitaliseFirst,
  ellipsizeText,
  humanFileSize,
  sentenceCase,
  toTitleCase,
} from '../utils/string';
import { changeCDNRes } from '../utils/file';
import { ExternalLink } from '../shared';
import { useCountry } from '../context/CountryProvider';
import { isColor, noop } from '../utils';
import SpinnerButton from '../shared/SpinnerButton';
import {
  GrayBadge,
  OrangeBadge,
  SuccessBadge,
  BrownBadge,
  RedBadge,
} from '../shared/Badge';
import CopyLinkInput, { copyToClipboard } from '../shared/CopyLinkInput';
import useFileDownload from '../hooks/useFileDownload';
import { getCountryNameFromCountryCode } from '../utils/countries';
import { BUY_X_GET_Y } from '../Manage/Coupons/constants';
import useModal from '../hooks/useModal';
import { manageUrls, rootUrls } from '../Urls';
import { OutlineBadge } from '../shared/OutlineBadge';
import { isNotEmptyOrNull } from '../shared/Form/Shared';
import ImageLoader from '../shared/ImageLoader';
import { deepClone } from '../Appearance/utils';
import { partnerImgStyle } from './MultipleDeliveryPartners';
import useOnScreen from '../hooks/utils/useOnScreen';
import ReturnShipmentImagesModal from './components/ReturnShipmentImagesModal';
import useDuplicateOrders from '../hooks/useDuplicateOrders';
import { editProductUrl, orderDetailsUrl } from '../UrlHelper';
import useEnterPressAction from '../hooks/useEnterPressAction';
import {
  BIGHAAT_STORE,
  CREDITS_READMORE_LINK,
  CURRENCY_SYMBOL,
  PLACEHOLDER_IMAGE,
} from '../constants';
import {
  OrderTotalShimmer,
  ProductsShimmer,
} from '../Catalogue/components/OrderDetailShimmer';
import InitiateRefundModal from './InitiateRefundModal';
import { getRequest, postRequest } from '../utils/http';
import { INITIATE_ORDER_REFUND, LOGIN_AS_CUSTOMER } from '../ApiUrls';
import { usePlugins } from '../context/PluginsProvider';
import { DUPLICATE_ORDER_ID } from '../Plugins/constants';
import {
  PICKUP_FAILED_REASON,
  REVERSE_TYPE,
  STATUS_MAP,
  VENDOR_STATUS_MAP,
} from '../Manage/Delivery/constants';
import VendorShipOrderModal from './VendorShipOrderModal';
import OrderConfirmationModal from './OrderConfirmationModal';
import CustomerModal from './CreateOrder/CustomerDetailCard/CustomerModal';
import LineItemsRefundDetailsModal from './LineItemsRefundDetailsModal';
import CancelAndRefundLineItem from './CancelAndRefundLineItem';
import { useStorePreferences } from '../context/StorePreferenceProvider';
import {
  DELIVERY_SERVICE_NAMES,
  DUKAAN_DELIVERY_SHIPPING_MODE,
} from '../Account/Sections/Shipping/constants';
import { BUNDLE_TYPE_TITLE } from '../Catalogue/Bundles/constants';
import { HowItWorks } from '../Layout/Header';
import { useCredits } from '../context/CreditsProvider';
import ConfirmModal from '../shared/ConfirmModal';
import { getPaymentBadge } from './OrdersList';
import { LightTooltip } from '../shared/View/LightTooltip';
import DownloadOrderReceiptModal from '../shared/DownloadOrderReceiptModal';
import { formatNumber } from '../utils/number';

export const DELHIVERY_CARRIER = 'dehlivery';
export const DELHIVERY_EXPRESS = 'delhivery_express';
export const DELHIVERY_SURFACE = 'delhivery_surface';
export const SELF_ORDER_SHIP = 'self_hook';

export const DELHIVERY_PARTNER_START_PRICE_MAP = {
  [DELHIVERY_EXPRESS]: 29,
  [DELHIVERY_SURFACE]: 28,
};

const getProductTotals = (product) => {
  const {
    add_on_data: addOns,
    selling_price: sellingPrice,
    variant_selling_price: variantSellingPrice,
    quantity,
  } = product;
  const addOnPrice = (addOns || []).reduce(
    (total, addOn) => total + addOn.price,
    0
  );
  const basePrice = Number(variantSellingPrice || sellingPrice) + addOnPrice;
  const totalCost = basePrice * quantity;

  return {
    addOnPrice,
    totalCost,
    basePrice,
  };
};

export const DetailsButton = ({ orderUrl }) => {
  const history = useHistory();

  return (
    <SpinnerButton
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        history.push(orderUrl, {
          ...history.location.state, // pass the filter status coming from /customers
          fromLocation: history.location, // pass the url of /customers/:id/orders for goBack
          customerOrderFilters: window.location.search, // pass search params from /customers/:id/orders
        });
      }}
      className="btn btn-outline-6 order-detail-btn"
    >
      <span>Details</span>
      <RightArrow1Icon className="ml4" />
    </SpinnerButton>
  );
};

export const DiningOrderButton = () => (
  <SpinnerButton type="button" className="btn btn-dining dining-order-btn">
    <DiningIcon className="mr4" />
    <span>Dining Order</span>
  </SpinnerButton>
);

export const getProductCountText = (productCount) => {
  if (productCount) {
    if (productCount > 1) {
      return `${productCount} items`;
    }
    return `1 item`;
  }
  return 'No items';
};

export const StatusText = ({
  status,
  newStatus,
  isAnimated,
  count,
  isRestaurantOrder,
  isManualPaymentOrder = false,
}) => {
  let text = '';

  if (isManualPaymentOrder && status === MANUAL_PAYMENT_METHOD_DRAFT_STATUS)
    text = ORDER_STATUS_MAP[PENDING_STATUS];
  else {
    text = (isRestaurantOrder ? RESTAURANT_ORDER_STATUS_MAP : ORDER_STATUS_MAP)[
      newStatus === RETURN_STATUS ? newStatus : status
    ];
  }
  const classes = cx('status-text-wrap section-text-8', text);

  if (isAnimated) {
    return (
      <div className="ring-container">
        <div className={`ringring ${text}`} />
        <div className={`circle ${text}`} />
      </div>
    );
  }
  return (
    <span className={classes}>
      <span className="status-badge" />
      {capitaliseFirst(text)} {count !== undefined && `(${count})`}
    </span>
  );
};

export const TransactionStatusText = ({ status }) => {
  const text = ORDER_STATUS_MAP[status];
  const classes = cx('status-text-wrap', text);

  return (
    <span className={classes}>
      <span className="status-badge" />
      {text}
    </span>
  );
};

export const CustomerDetails = (props) => {
  const {
    isOrderDetailsReadOnly = false,
    address = {},
    buyer,
    store_lead_id: buyerId,
    payment_mode: paymentMode,
    payment_details: paymentDetails,
    order_meta: orderMeta = {},
    seller_marked_prepaid: sellerMarkedPrepaid,
    customer_referral_link: referralLink,
    type,
    products = [],
    isRestaurantOrder,
    isManualPaymentOrder = false,
    status,
    submitting = false,
    isExpanding,
    uuid,
    editCustomerDetailsOpen,
    editCustomerDetailsCloseModal,
    isParentModalOpen,
    isStatusAbandoned = false,
    handleKeyboardKeys = noop,
    resendManualPaymentLink = noop,
    customerDetailsFields = {},
  } = props;
  const { isOpen } = useModal();
  const { formatLocalMoney, isInternational } = useCountry();
  const { enqueueSnackbar } = useCustomSnackbar();
  const history = useHistory();
  const {
    domain,
    isVendor,
    isOwner,
    isAdmin,
    isStaffL2,
    isStaffL3,
    isManager,
    isManagerL1,
    isManagerL2,
  } = useAppContext();
  const { updateBuyerAddress } = useOrderUpdate(uuid);
  const { preferences } = useStorePreferences();
  const { credits } = useCredits();

  const giftWrapMessage = products[0]?.gift_wrap_message;

  const manualPaymentOrderStatus =
    paymentDetails?.amount > 0 ? 'Paid' : 'Awaiting payment';

  const loginAsCustomerEnabled =
    ((isOwner ||
      isAdmin ||
      isManager ||
      isStaffL2 ||
      isStaffL3 ||
      isManagerL1 ||
      isManagerL2) &&
      preferences?.[LOGIN_AS_CUSTOMER_ENABLED]?.value) ??
    false;

  const loginAsCustomer = (customerId) => {
    enqueueSnackbar('Logging in customer, please wait!', 'info');
    getRequest({ url: LOGIN_AS_CUSTOMER(customerId) })
      .then((res) => {
        if (res?.data?.token) {
          window.open(`${domain}/token?jwt=${res?.data?.token}`);
        } else {
          enqueueSnackbar('Error logging in!', 'error');
        }
      })
      .catch(() => {
        enqueueSnackbar('Error logging in!', 'error');
      });
  };

  useEffect(() => {
    if (isOpen) document.removeEventListener('keydown', handleKeyboardKeys);
    else document.addEventListener('keydown', handleKeyboardKeys);
  }, [handleKeyboardKeys, isOpen]);

  const getFullPaymentData = (preOrderData) => {
    if (preOrderData.pending_amount_paid) {
      return (
        <div className="d-flex-c-s">
          <span className="text-2">Paid</span>
          <SuccessBadge text="PAID" />
        </div>
      );
    }
    if (preOrderData.pending_amount_payment_link) {
      return (
        <div className="d-flex-c-s">
          <span className="text-2">Requested</span>
          <span
            className="anchor-1 section-text-5 underline"
            onClick={() => {
              copyToClipboard(
                preOrderData.pending_amount_payment_link?.web,
                'payment_link'
              );
              enqueueSnackbar('Payment link copied successfully!');
            }}
          >
            Copy Link
          </span>
          <CopyLinkInput id="payment_link" />
        </div>
      );
    }
    return (
      <div className="d-flex-c-s">
        <span className="text-2">Pending</span>
        <GrayBadge text="PENDING" className="text-2" />
      </div>
    );
  };

  const customerShippingAddress = `  ${address.line} ${address?.line_1 ?? ''} ${
    address?.area ?? ''
  }
  ${address?.landmark ?? ''}, ${address?.city ?? ''}, ${address.pin}${' '}
  ${address.state}`;

  return (
    <div
      className={cx('customer-details order-details-card border-none w-100', {
        'mt-32': isExpanding,
      })}
    >
      <hr
        className={cx({
          'pos-rel top-8 hr-line mx0 w-100 ': isExpanding,
          hidden: !isExpanding,
        })}
      />
      <p
        className={cx({
          'mt4 section-text-5 text-semibold': isParentModalOpen,
          hidden: !isParentModalOpen,
          pt20: isVendor,
        })}
      >
        <div className="d-flex align-center">
          <p>Customer details</p>
          {credits < 0 && (
            <HowItWorks
              url={CREDITS_READMORE_LINK}
              useAbsoluteUrl
              customText="Why aren't the customer details visible?"
            />
          )}
        </div>
      </p>
      {isRestaurantOrder ? (
        <div className="customer-details-container">
          {address?.buyer?.name && !isVendor && (
            <div className="row">
              <div className="col-md-6">
                <p>Name</p>
                <span>{address?.buyer?.name}</span>
              </div>
            </div>
          )}
          <div className="row">
            {address?.buyer?.mobile && !isVendor ? (
              <div className="col-md-6">
                <p>Mobile</p>
                <span>{address?.buyer?.mobile}</span>
              </div>
            ) : null}
            <div className="col-md-6 d-none">
              <p>Payment</p>
              <span>{DINING_PAYMENT_MODE_MAP[paymentMode]}</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cx('customer-details-container', {
            'vendor-margin': isVendor,
          })}
        >
          <div className="row">
            <div className="col-md-6 pr24">
              {!isVendor && (
                <>
                  <p>Name</p>
                  <div className="d-flex space-between">
                    <span
                      className={cx({
                        'cur-p user-select-text c-purple-1 text-medium':
                          !isOrderDetailsReadOnly,
                      })}
                      onClick={() => {
                        if (!isOrderDetailsReadOnly) {
                          const redirectPath = `${manageUrls.customersPath}/${buyerId}/orders`;
                          const backLink = orderDetailsUrl(uuid);
                          history.push({
                            pathname: redirectPath,
                            state: { backLink },
                          });
                        }
                      }}
                    >
                      {toTitleCase(address?.buyer?.name)}
                    </span>
                  </div>
                </>
              )}
              {loginAsCustomerEnabled && (
                <span
                  className="c-gray-1 cur-p"
                  onClick={() => loginAsCustomer(buyerId)}
                >
                  Login as customer
                </span>
              )}
            </div>
            {address?.buyer?.mobile && !isVendor && (
              <div className="col-md-6">
                <p>Mobile</p>
                <span>{address?.buyer?.mobile}</span>
              </div>
            )}
          </div>
          <div className="row">
            {address?.buyer?.email && (
              <div className="col-md-6">
                <p>Email</p>
                <span
                  className="cur-p user-select-text text-medium c-purple-1"
                  onClick={() => {
                    window.open(`mailto:${address?.buyer?.email}`);
                  }}
                >
                  {address?.buyer?.email}
                </span>
              </div>
            )}
            <div className="col-md-12 mt16">
              <p>Shipping address</p>
              <div className="d-flex-c-s">
                <span>{customerShippingAddress}</span>
                <LightTooltip title="copy shipping address" placement="top">
                  <SpinnerButton
                    className="btn-outline-4"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(customerShippingAddress)
                        .then(() => {
                          enqueueSnackbar('Shipping address copied!', 'info');
                        })
                        .catch((err) => {
                          console.error('Unable to copy text: ', err);
                        });
                    }}
                  >
                    <CopyShapeIcon className="mr4" />{' '}
                    <span className="ws-nowrap" style={{ fontSize: 14 }}>
                      Copy
                    </span>
                  </SpinnerButton>
                </LightTooltip>
              </div>
            </div>
            {!!giftWrapMessage && (
              <div className="col-md-12 mt16">
                <p>Gift wrap message</p>
                <div className="d-flex-c-s">
                  <span>{giftWrapMessage}</span>
                  <LightTooltip title="copy message" placement="top">
                    <SpinnerButton
                      className="btn-outline-4"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(giftWrapMessage)
                          .then(() => {
                            enqueueSnackbar('Message copied!', 'info');
                          })
                          .catch((err) => {
                            console.error('Unable to copy text: ', err);
                          });
                      }}
                    >
                      <CopyShapeIcon className="mr4" />{' '}
                      <span className="ws-nowrap" style={{ fontSize: 14 }}>
                        Copy
                      </span>
                    </SpinnerButton>
                  </LightTooltip>
                </div>
              </div>
            )}
            {referralLink && (
              <div className="col-md-12 mt16">
                <p>Referral Link</p>
                <div className="d-flex-c-s">
                  <span
                    className="underline text-2 cur-p"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(referralLink)
                        .then(() => {
                          enqueueSnackbar('Referral link copied!', 'info');
                        })
                        .catch((err) => {
                          console.error('Unable to copy text: ', err);
                        });
                    }}
                  >
                    Copy referral link
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* {typeof address.area !== 'undefined' &&
            typeof address.landmark !== 'undefined' && (
              <div className="row">
                <div className="col-md-6">
                  <p>Locality / Area</p>
                  <span>{address?.area ? address.area : '_'}</span>
                </div>
                <div className="col-md-6">
                  <p>Landmark</p>
                  <span>{address?.landmark ? address.landmark : '_'}</span>
                </div>
              </div>
            )} */}

          {/* <div className="row">
            <div className="col-md-6">
              <p>City</p>
              <span>{address.city}</span>
            </div>
            <div className="col-md-6">
              <p>Pin Code</p>
              <span>{address.pin}</span>
            </div>
          </div> */}
          {isManualPaymentOrder ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <p>Payment</p>
                  {isStatusAbandoned ? (
                    <span>-</span>
                  ) : (
                    <span>
                      {orderMeta?.manual_payment_method || 'Manual Payment'}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <p>Payment Status</p>
                  {isStatusAbandoned ? (
                    <span>-</span>
                  ) : (
                    <>
                      <span>{manualPaymentOrderStatus}</span>
                      <div
                        className={`payment-mode ${
                          isRestaurantOrder
                            ? DINING_PAYMENT_MODE_SHORT_MAP
                            : isManualPaymentOrder
                            ? MANUAL_PAYMENT_METHOD
                            : PAYMENT_MODE_SHORT_MAP[paymentMode]
                        }`}
                      >
                        {isManualPaymentOrder
                          ? MANUAL_PAYMENT_METHOD
                          : (isRestaurantOrder
                              ? DINING_PAYMENT_MODE_SHORT_MAP
                              : PAYMENT_MODE_SHORT_MAP)[
                              sellerMarkedPrepaid ? PREPAID_ORDER : paymentMode
                            ]}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            !isInternational &&
            paymentMode === UNPAID_ORDER && (
              <div className="row">
                {/* {address?.state && (
                <div className="col-md-6">
                  <p>State</p>
                  <span>{sentenceCase(address.state)}</span>
                </div>
              )} */}
                {!orderMeta?.pre_order_data && (
                  <div className="col-md-6">
                    <p>Payment</p>
                    {isStatusAbandoned ? (
                      <span>-</span>
                    ) : (
                      <>
                        {' '}
                        <span>
                          {PAYMENT_MODE_MAP[paymentMode]}
                          {!isInternational && paymentMode === UNPAID_ORDER && (
                            <button
                              type="button"
                              className="btn-no-default c-purple-1 cur-p text-medium p0-i"
                              onClick={resendManualPaymentLink}
                              disabled={submitting}
                            >
                              Resend payment link
                            </button>
                          )}
                        </span>
                        {/* <div
                        className={`payment-mode ${
                          (isRestaurantOrder
                            ? DINING_PAYMENT_MODE_SHORT_MAP
                            : PAYMENT_MODE_SHORT_MAP)[paymentMode]
                        }`}
                      >
                        {
                          (isRestaurantOrder
                            ? DINING_PAYMENT_MODE_SHORT_MAP
                            : PAYMENT_MODE_SHORT_MAP)[
                            sellerMarkedPrepaid ? PREPAID_ORDER : paymentMode
                          ]
                        }
                      </div> */}
                      </>
                    )}
                  </div>
                )}
              </div>
            )
          )}

          {orderMeta?.pre_order_data && (
            <div className="row">
              <div className="col-md-6">
                <p>Pre-order Amount</p>
                <span>
                  {formatLocalMoney(
                    orderMeta?.pre_order_data?.pre_order_amount
                  )}
                </span>
              </div>
              <div className="col-md-6">
                <p>Full Payment</p>
                {getFullPaymentData(orderMeta.pre_order_data)}
              </div>
            </div>
          )}
          {orderMeta?.abandoned_discount && (
            <div className="row">
              <div className="col-md-6">
                <p>Discount provided</p>
                <span>{formatLocalMoney(orderMeta?.abandoned_discount)}</span>
              </div>
              <div className="col-md-6">
                <p>Agent name</p>
                <span>{orderMeta?.agent_name ?? '-'}</span>
              </div>
              <div className="col-md-6 mt24">
                <p>Discounted payment link</p>
                <ExternalLink href={orderMeta?.abondoned_order_payment_link}>
                  {orderMeta?.abondoned_order_payment_link}
                </ExternalLink>
              </div>
            </div>
          )}
        </div>
      )}
      {editCustomerDetailsOpen && (
        <CustomerModal
          buttonText="Update"
          closeModal={(values, formInvalid, formPristine) => {
            if (values && !formPristine && !formInvalid) {
              const payload = {
                name: values.name,
                pin: values.pin,
                state: values.state,
                city: values.city,
                line: values.line,
                area: values.area,
                landmark: values.landmark,
                email: values.email,
                mobile: `+${values.phone_code}-${values.mobile}`,
              };

              if (values.line_1) payload.line_1 = values.line_1;
              if (!values?.email) delete payload.email;

              updateBuyerAddress(payload, () => {
                editCustomerDetailsCloseModal();
                enqueueSnackbar('Customer details updated successfully.');
                setTimeout(() => window.location.reload(), 3000);
              });
            } else editCustomerDetailsCloseModal();
          }}
          customer={{
            ...address,
            ...address.buyer,
            state: toTitleCase(address?.state || ''),
          }}
          customerDetailsFields={customerDetailsFields}
          open={editCustomerDetailsOpen}
          title="Edit customer details"
        />
      )}
    </div>
  );
};

const RefundAccountDetailsModal = ({ open, closeModal, customer = {} }) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const handleCopy = (label, value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        enqueueSnackbar(`${label} copied`, 'info');
      })
      .catch((err) => {
        console.error('Unable to copy text: ', err);
      });
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title="Refund details"
      paperClass="xs"
    >
      <div className="mt32">
        <div className="mb16">
          <p className="section-text-5">Completed time</p>
          <span className="c-gray-1">
            {formatDate5(customer?.refund_completion_date)}
          </span>
        </div>
        {customer?.refund_arn && (
          <div className="mb16">
            <p className="section-text-5">Refund ARN</p>
            <LightTooltip title="Copy Refund ARN" placement="right">
              <span
                className="c-gray-1 cur-p"
                onClick={() => {
                  handleCopy('Refund ARN', customer?.refund_arn);
                }}
              >
                {customer?.refund_arn}
              </span>
            </LightTooltip>
          </div>
        )}
        <div className="mb16">
          <p className="section-text-5">Customer name</p>
          <LightTooltip title="Copy customer name" placement="right">
            <span
              className="c-gray-1 cur-p"
              onClick={() => {
                handleCopy('Customer name', customer?.account_holder_name);
              }}
            >
              {customer?.account_holder_name}
            </span>
          </LightTooltip>
        </div>
        <div className="mb16">
          <p className="section-text-5">Mode</p>
          <span className="c-gray-1">
            {customer?.account_type === REFUND_ACCOUNT_TYPE.BANK
              ? 'Bank transfer'
              : 'UPI'}
          </span>
        </div>
        {customer?.account_type === REFUND_ACCOUNT_TYPE.UPI && (
          <>
            <div>
              <p className="section-text-5">UPI ID</p>
              <LightTooltip title="Copy UPI ID" placement="right">
                <span
                  className="c-gray-1 cur-p"
                  onClick={() => {
                    handleCopy('UPI ID', customer?.upi_id);
                  }}
                >
                  {customer?.upi_id}
                </span>
              </LightTooltip>
            </div>
          </>
        )}

        {customer?.account_type === REFUND_ACCOUNT_TYPE.BANK && (
          <>
            <div className="mb16">
              <p className="section-text-5">Account Number</p>
              <LightTooltip title="Copy account number" placement="right">
                <span
                  className="c-gray-1 cur-p"
                  onClick={() => {
                    handleCopy('Account number', customer?.account_number);
                  }}
                >
                  {customer?.account_number}
                </span>
              </LightTooltip>
            </div>
            <div>
              <p className="section-text-5">IFSC code</p>
              <span className="c-gray-1">
                <LightTooltip title="Copy IFSC code" placement="right">
                  <span
                    className="c-gray-1 cur-p"
                    onClick={() => {
                      handleCopy('IFSC code', customer?.ifsc_code);
                    }}
                  >
                    {customer?.ifsc_code}
                  </span>
                </LightTooltip>
              </span>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export const OrderPaymentTransactionStatusText = ({ text, color }) => {
  const classes = cx('status-text-wrap', color);

  return (
    <span className={classes}>
      <span className="status-badge" />
      {toTitleCase(text)}
    </span>
  );
};

export const OrderPaymentAndRefund = (props) => {
  const {
    orderPayments: { order_payments: orderPayments = [] } = {},
    isExpanding,
    isParentModalOpen,
    isAbandonedOrder = false,
  } = props;
  const { formatLocalMoney } = useCountry();
  const { enqueueSnackbar } = useCustomSnackbar();

  const [customerDetials, setCustomerDetials] = useState(null);

  const {
    isOpen: isCustomerDetailsModalOpen,
    closeModal: closeCustomerDetailsModal,
    openModal: openCustomerDetailsModal,
  } = useModal();

  const handleViewCustomerDetails = (customer) => {
    setCustomerDetials(customer);
    openCustomerDetailsModal();
  };

  const handleCloseCustomerDetailsModal = () => {
    closeCustomerDetailsModal();
    setCustomerDetials(null);
  };

  let orderPaymentsData = orderPayments;

  if (!isAbandonedOrder) {
    orderPaymentsData = orderPayments.filter(
      (item) => Number(item.amount_paid) > 0
    );
  }

  const refunds = [];

  orderPaymentsData.forEach((item) => {
    item.refunds.map((refundItem) => refunds.push(refundItem));
  });

  return (
    <div
      className={cx('border-none w-100', {
        'mt-32': isExpanding,
      })}
    >
      <hr
        className={cx({
          'pos-rel top-8 hr-line mx0 w-100 ': isExpanding,
          hidden: !isExpanding,
        })}
      />
      <p
        className={cx({
          'text-ucase mt4 section-text-5 c-gray-1': isParentModalOpen,
          hidden: !isParentModalOpen,
        })}
      >
        PAYMENTS & REFUNDS
      </p>

      <p className="section-text-5 mb16">Payments</p>
      <div className="table-header-wrapper payments-table">
        <div>Payment source</div>
        <div>Transaction ID</div>
        <div>Amount</div>
        <div>Payment status</div>
      </div>
      <div className="table-data-container no-border">
        {orderPaymentsData.map((row) => (
          <div className="table-data-row payments-table px12">
            <div>{PAYMENT_TYPES[row.payment_type]}</div>
            <LightTooltip title="Copy transaction ID" placement="top">
              <div
                className="line-clamp-1 cur-p"
                onClick={() => {
                  navigator.clipboard
                    .writeText(row?.provider_payment_request_id ?? row.id)
                    .then(() => {
                      enqueueSnackbar('Transaction ID copied', 'info');
                    })
                    .catch((err) => {
                      console.error('Unable to copy text: ', err);
                    });
                }}
              >
                {row?.provider_payment_request_id ?? row.id}
              </div>
            </LightTooltip>
            <div>{formatLocalMoney(Number(row.amount_requested))}</div>
            <div>
              {row.amount_paid === row.amount_requested
                ? 'Completed'
                : 'Unpaid'}
            </div>
          </div>
        ))}
        {orderPaymentsData.length === 0 && (
          <div className="d-flex-c-c">
            <p>No record found</p>
          </div>
        )}
      </div>

      <hr className="hr-line mt16 mb20 mx8" />

      <p className="section-text-5 mb16">Refunds</p>
      <div className="table-header-wrapper payments-table">
        <div>Refund source</div>
        <div>Transaction ID</div>
        <div>Amount</div>
        <div>Refund status</div>
      </div>
      <div className="table-data-container no-border">
        {refunds.map((row) => (
          <div className="table-data-row payments-table px12">
            <div>{REFUND_TYPES[row.refund_mode]}</div>
            <LightTooltip title="Copy transaction ID" placement="top">
              <div
                className="line-clamp-1 cur-p"
                onClick={() => {
                  navigator.clipboard
                    .writeText(row?.provider_refund_id ?? row.id)
                    .then(() => {
                      enqueueSnackbar('Transaction ID copied', 'info');
                    })
                    .catch((err) => {
                      console.error('Unable to copy text: ', err);
                    });
                }}
              >
                {row?.provider_refund_id ?? row.id}
              </div>
            </LightTooltip>
            <div>{formatLocalMoney(Number(row.amount_to_refund))}</div>
            <div>
              <p>
                {REFUND_LABEL_MAPPING[row.refund_status] ??
                  toTitleCase(row.refund_status)}
              </p>
              {row.refund_status === 'completed' &&
                row.refund_mode !== CUSTOMER_WALLET_KEY && (
                  <span
                    className="c-purple-1 text-10 cur-p text-medium underline"
                    onClick={() =>
                      handleViewCustomerDetails({
                        refund_completion_date: row.refund_completion_date,
                        refund_arn: row.refund_arn,
                        ...row.customer_bank_details,
                      })
                    }
                  >
                    View details
                  </span>
                )}
            </div>
          </div>
        ))}
        {refunds.length === 0 && (
          <div className="d-flex-c-c">
            <p>No record found</p>
          </div>
        )}
      </div>
      <RefundAccountDetailsModal
        customer={customerDetials}
        open={isCustomerDetailsModalOpen}
        closeModal={handleCloseCustomerDetailsModal}
      />
    </div>
  );
};

export const IntlCustomerDetails = (props) => {
  const {
    address = {},
    payment_mode: paymentMode,
    type,
    status,
    payment_details: paymentDetails,
    order_meta: orderMeta = {},
    isManualPaymentOrder = false,
    isStatusAbandoned = false,
    isParentModalOpen = false,
    isExpanding,
  } = props;

  const manualPaymentOrderStatus =
    paymentDetails?.amount > 0 ? 'Paid' : 'Awaiting payment';

  const OrderFormItems = Object.entries(address).reduce(
    (accum, [key, value]) => {
      const prevValue = [...accum];
      const removeKeys = ['line', 'city', 'email', 'country'];
      if (
        key !== 'buyer' &&
        value !== null &&
        ORDER_LABELS_NAME_MAP[key] &&
        !removeKeys.includes(key)
      ) {
        prevValue.push([ORDER_LABELS_NAME_MAP[key], value]);
      }
      return prevValue;
    },
    []
  );

  const countryName = getCountryNameFromCountryCode(address?.country);
  return (
    <div
      className={cx('w-100', {
        'mt-32': isExpanding,
      })}
    >
      <hr
        className={cx({
          'pos-rel top-8 hr-line mx0 w-100 ': isExpanding,
          hidden: !isExpanding,
        })}
      />
      {isParentModalOpen && (
        <div className="text-ucase mt4 section-text-5 c-gray-1">
          CUSTOMER DETAILS
        </div>
      )}

      <div
        className={cx('customer-details-container', {
          mt16: isParentModalOpen,
        })}
      >
        <div className="text-2">
          <div className="row mb16">
            {address?.buyer?.name ? (
              <div className="col-md-6">
                <p className="mb2 text-medium">Name</p>
                <span>{toTitleCase(address?.buyer?.name)}</span>
              </div>
            ) : null}

            {address?.buyer?.email ? (
              <div className="col-md-6">
                <p className="mb2 text-medium">Email Address</p>
                <span
                  className="cur-p text-medium c-purple-1"
                  onClick={() => {
                    window.open(`mailto:${address?.buyer?.email}`);
                  }}
                >
                  {address?.buyer?.email}
                </span>
              </div>
            ) : null}
          </div>
          <div className="row mb16">
            <div className="col-md-12">
              <p className="mb2 text-medium">Address</p>
              <span>{address.line}</span>
            </div>
          </div>
          <div className="row">
            {OrderFormItems[0] && (
              <div className="col-md-6">
                <p className="mb2 text-medium">{OrderFormItems[0][0]}</p>
                <span>{OrderFormItems[0][1]}</span>
              </div>
            )}
            {address?.buyer?.mobile ? (
              <div className="col-md-6 mb16">
                <p className="mb2 text-medium">Phone Number</p>
                <span>{address?.buyer?.mobile}</span>
              </div>
            ) : null}
            {OrderFormItems.length > 1 &&
              OrderFormItems.map(([label, value], index) => {
                if (index !== 0) {
                  return (
                    <div className="col-md-6 mb16">
                      <p className="mb2 text-medium">{label}</p>
                      {/* state field will be sentence case */}
                      <span>
                        {label === ORDER_LABELS_NAME_MAP.state
                          ? sentenceCase(value)
                          : value}
                      </span>
                    </div>
                  );
                }
                return null;
              })}
            {address?.city ? (
              <div className="col-md-6 mb16">
                <p className="mb2 text-medium">City</p>
                <span>{address?.city}</span>
              </div>
            ) : null}
            <div className="col-md-6 mb16">
              <p className="mb2 text-medium">Country</p>
              <span>{countryName}</span>
            </div>
          </div>

          <div className="row mb16">
            {isManualPaymentOrder ? (
              <>
                <div className="col-md-6">
                  <p className="mb2 text-2 text-medium">Payment</p>
                  {isStatusAbandoned ? (
                    <span>-</span>
                  ) : (
                    <span>
                      {orderMeta?.manual_payment_method || 'Manual Payment'}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <p>Payment Status</p>
                  {isStatusAbandoned ? (
                    <span>-</span>
                  ) : (
                    <div clasName="d-flex-c-s">
                      <span>{manualPaymentOrderStatus}</span>
                      <BrownBadge
                        text={MANUAL_PAYMENT_METHOD}
                        className="text-medium payment-badge"
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="col-md-6 ">
                <p className="mb2 text-2 text-medium">Payment</p>
                {isStatusAbandoned ? (
                  <span>-</span>
                ) : (
                  <>
                    {paymentMode === CASH_PAYMENT_MODE && (
                      <div className="d-flex-c-s">
                        <span className="text-2">Cash on Delivery</span>
                        <OrangeBadge
                          text="COD"
                          className="text-medium payment-badge"
                        />
                      </div>
                    )}
                    {paymentMode === ONLINE_PAYMENT_MODE && (
                      <div className="d-flex-c-s">
                        <OrderPaymentProviderLogo
                          provider={paymentDetails?.payment_provider}
                        />
                        <SuccessBadge
                          text="PAID"
                          className="text-medium payment-badge"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShipmentDetails = ({
  plugins,
  partner_id: partnerId,
  trackingDetails,
  shipping_charge: shippingCharge,
}) => {
  const { formatLocalMoney } = useCountry();
  const plugin = plugins.find((each) => each.id === partnerId);
  if (!plugin) return null;
  const { meta, states } = trackingDetails;
  const updatedStates = Object.values(states).filter(
    (each) => each.isCompleted
  );
  const status = updatedStates[updatedStates.length - 1];
  return (
    <div className="customer-details mt24">
      <h4 className="section-text-5 c-gray-1">SHIPMENT DETAILS</h4>
      <div className="d-flex align-center mt20">
        <img className="mr8" width="32px" src={plugin.logo} alt={plugin.name} />
        <p className="section-text-5">{plugin.name}</p>
      </div>
      <table>
        <tbody>
          {meta?.runner?.name ? (
            <tr>
              <td>Partner Name:</td>
              <td>{meta.runner.name}</td>
            </tr>
          ) : null}
          {shippingCharge > 0 ? (
            <tr>
              <td>Price:</td>
              <td>{formatLocalMoney(shippingCharge)}</td>
            </tr>
          ) : null}
          {status ? (
            <tr>
              <td>Status: </td>
              <td>{status.msg}</td>
            </tr>
          ) : null}
          {status ? (
            <tr>
              <td>Completed On: </td>
              <td>{formatOrderTime(status.timestamp)}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};
export const OrderFormDetails = (props) => {
  const {
    order_meta: { order_custom_data: fields = [] } = {},
    isParentModalOpen,
    isReturnedOrReplacementExist,
  } = props;

  const { enqueueSnackbar } = useCustomSnackbar();

  const FilePreview = (field) => {
    const { meta, value } = field;
    const { file_name: fileName, file_size: fileSize } = meta;
    const { downloadFile } = useFileDownload();
    return (
      <div
        className="additional-info-file cur-p mt8"
        onClick={() => {
          enqueueSnackbar('File download will begin shortly!');
          downloadFile(value, true);
        }}
      >
        <div className="d-flex-c-s">
          <div>
            <p>{ellipsizeText(fileName, 30)}</p>
            <span className="text-10 c-gray-1">
              {humanFileSize(fileSize, true)}
            </span>
          </div>
          <DownloadIcon />
        </div>
      </div>
    );
  };

  const renderValue = (field) => {
    if (field.field_type === FORM_FIELD_TYPES.DATE_PICKER) {
      return moment(field.value).format('D MMM YYYY');
    }
    if (field.field_type === FORM_FIELD_TYPES.TIME_PICKER) {
      return moment(field.value, ['HH:mm:ss']).format('hh:mm A');
    }
    return field.value;
  };

  return (
    <>
      {fields.length > 0 && (
        <div
          className={cx(
            'customer-details order-details-card w-100 border-none',
            { 'mt-32': isReturnedOrReplacementExist }
          )}
        >
          {isReturnedOrReplacementExist ||
            (isParentModalOpen && (
              <hr
                className={cx('hr-line mx0 pos-rel w-100', {
                  'top-8': !isParentModalOpen,
                  my24: isParentModalOpen,
                })}
              />
            ))}
          {isParentModalOpen && (
            <h4
              className={cx('section-text-4', {
                'mt4 section-text-5 text-semibold mb20': isParentModalOpen,
              })}
            >
              Additional details
            </h4>
          )}
          {fields.map((field, index) => (
            <div key={index} className="additional-info-row">
              <p className="section-text-5 mb2 word-break">
                {field.field_name}
              </p>
              {field.field_type === FORM_FIELD_TYPES.FILE_PICKER ? (
                <FilePreview {...field} />
              ) : (
                <p className="text-2 word-break">{renderValue(field)}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export const VariantColor = ({ color }) => {
  const [bgColor] = getFormattedColor(color);

  return (
    <span className="variant-color" style={{ backgroundColor: bgColor }} />
  );
};

export const SizeColor = ({
  addOns,
  className,
  isRestaurantOrder,
  attributes,
}) => {
  const editableLabelNameMap = attributes?.map(
    (each) => each?.master_attribute_name || ''
  );
  const editableLabelValueMap = attributes?.map((each) => each?.value);
  const { formatLocalMoney } = useCountry();

  if (editableLabelValueMap || (addOns && addOns.length)) {
    return (
      <p className={cx('text-4 mt6', className)}>
        {editableLabelValueMap.length > 0 &&
          editableLabelValueMap.map((each, index) =>
            !isColor(each) ? (
              <span className="mb4">
                {editableLabelValueMap.length > 1 && index !== 0 && ' | '}
                {isRestaurantOrder
                  ? 'Variant'
                  : editableLabelNameMap[index]}: {each}{' '}
              </span>
            ) : (
              <span className="variant-color-wrap">
                {editableLabelValueMap.length > 1 && index !== 0 && ' | '}
                COLOR:&nbsp;
                <VariantColor color={editableLabelValueMap[index]} />
              </span>
            )
          )}

        {!!addOns && !!addOns.length > 0 && (
          <div className="text-7 c-black-2">
            {addOns.map((addOn) => (
              <p className="mb4 addon-price">
                Add-On: {addOn.name}: +{formatLocalMoney(addOn.price)}
              </p>
            ))}
          </div>
        )}
      </p>
    );
  }
  return null;
};

export const AddonString = ({ addons }) => {
  if (addons) {
    let finalString = addons
      .reduce((prev, cur) => `${prev}${cur.name},`, ``)
      .trim();
    finalString =
      finalString.slice(-1) === ','
        ? finalString.slice(0, finalString.length - 1)
        : '';
    if (finalString) {
      return <p className="size-color">Addons: {finalString}</p>;
    }
  }
  return null;
};

export const OrderBundleInfo = ({ bundleItem }) => {
  const { domain } = useAppContext();
  const {
    id,
    name,
    image,
    product_slug: productSlug,
    product_bundle_type: productBundleType,
  } = bundleItem;

  const buyerSideProductLink = `${domain}/bundles/${productSlug}`;

  return (
    <div className="order-bundle-info">
      <ExternalLink
        href={buyerSideProductLink}
        className="product-img-container"
      >
        <ImageLoader src={image} alt={name} />
      </ExternalLink>
      <div className="bundle-details">
        <span className="text-2 ml2 py4">{name}</span>
        <GrayBadge
          text={`${BUNDLE_TYPE_TITLE[productBundleType].toUpperCase()} BUNDLE`}
          className="section-text-15"
        />
      </div>
    </div>
  );
};

export const OrderProductInfo = ({
  orderUUID,
  name,
  image,
  product_id: productId,
  selling_price: sellingPrice,
  line_item_uuid: uuid,
  line_item_id: lineItemId,
  product_slug: slug,
  product_uuid: productUuid,
  sku: skuId,
  quantity: startQuantity,
  base_qty: baseQty,
  unit,
  add_on_data: addOns,
  variant_color: color,
  variant_size: size,
  editable,
  onProductUpdated,
  badgeText,
  quantity_freed: quantityFreed,
  variant_selling_price: variantSellingPrice,
  line_item_total_cost: lineItemTotalCost = 0,
  isMultipleUnits = false,
  isQuantityFreed = false,
  isBundleProductOrder = false,
  attributes,
  storeName,
  cancelledLineItemDetailsMap = {},
  paymentMode,
  orderStatus,
  isFreebie,
}) => {
  const [lineItemsRefundDetails, setLineItemsRefundDetails] = useState(null);
  const [showLineItemsInitiateRefund, setLineItemsInitiateRefund] =
    useState(false);
  const showLineItemsRefundModal = Boolean(lineItemsRefundDetails);
  const closeLineItemsRefundModal = () => setLineItemsRefundDetails(null);
  const totals = getProductTotals({
    add_on_data: addOns,
    selling_price: sellingPrice,
    variant_selling_price: variantSellingPrice,
    quantity: quantityFreed,
  });

  const { basePrice } = totals;
  const bQty = Number(baseQty);
  const displayUnitText = bQty ? `${bQty === 1 ? 'per' : bQty} ${unit}` : unit;

  const [quantity, setQuantity] = useState(startQuantity);
  const history = useHistory();
  const { quantityModalOpen, setQuantityModalOpen } = useOrderUpdate();
  const { business, domain } = useAppContext();
  const { formatLocalMoney } = useCountry();
  const { installedPlugins } = usePlugins();

  const isDuplicateOrderPluginInstalled = !!installedPlugins.find(
    (each) => each.id === DUPLICATE_ORDER_ID
  );

  const {
    loading,
    ordersToCheck = {},
    fetchCheckDuplicateOrders,
  } = useDuplicateOrders({}, false);
  const handleTotalCost = () => {
    if (isMultipleUnits) {
      return formatLocalMoney(basePrice * (startQuantity + quantityFreed));
    }
    return formatLocalMoney(basePrice * quantityFreed);
  };

  const getStaffName = (staffName) => {
    if (staffName?.length === 0 || staffName === null) {
      return storeName;
    }
    return staffName;
  };

  const buyerSideProductLink = `${domain}/products/${slug}`;

  const productLink =
    business.id === BIGHAAT_STORE
      ? editProductUrl(productUuid)
      : buyerSideProductLink;

  const isLineItemsCancelled = Boolean(
    cancelledLineItemDetailsMap?.[lineItemId]
  );

  const cancelledRefundDetailsList =
    cancelledLineItemDetailsMap?.[lineItemId]?.refund_data;

  const totalRefundAmount =
    cancelledRefundDetailsList?.reduce(
      (sum, each) => sum + each?.refund_amount,
      0
    ) ?? 0;

  const codRefundAmount = lineItemTotalCost - totalRefundAmount;

  useEffect(() => {
    if (isDuplicateOrderPluginInstalled) {
      fetchCheckDuplicateOrders(orderUUID);
    }
  }, []);

  return (
    <div className="order-product-info">
      <div className="prod-item-wrap">
        <div className="prod-item-container">
          <ExternalLink href={productLink} className="product-img-container">
            <ImageLoader src={image} alt={name} />
            {badgeText && (
              <span className={cx('badge', badgeText.toLowerCase())}>
                {badgeText}
              </span>
            )}
          </ExternalLink>
          <div className="text-2 d-flex flex-1 space-between">
            <div>
              <div className="break-word">
                <div className="d-flex align-center">
                  <ExternalLink
                    className="py4"
                    text={name}
                    href={productLink}
                  />
                  {isQuantityFreed && (
                    <span className="section-text-5 ml2 py4">
                      ({isMultipleUnits ? `${quantityFreed} ` : ''}FREE)
                    </span>
                  )}
                  {!loading &&
                  (ordersToCheck[productId]?.duplicate_order_uuid !== null ||
                    undefined) &&
                  ordersToCheck[productId] !== undefined ? (
                    <span
                      className="ml12 px12 py4 duplicate-order-text-container text-2 text-medium c-black-3 cur-p underline"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          orderDetailsUrl(
                            ordersToCheck[productId]?.duplicate_order_uuid
                          )
                        );
                      }}
                    >
                      {`Duplicate order of #${ordersToCheck[productId]?.duplicate_order_id}`}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {!size && <div className="text-4 mt2">{displayUnitText}</div>}
              <SizeColor
                attributes={attributes}
                color={color}
                addOns={addOns}
                className="mt4"
              />
              {skuId && (
                <span className="mt4 text-4 c-black-3">SKU ID: {skuId}</span>
              )}
              <div className="mt10">
                {isQuantityFreed ? (
                  <span className="mr6 qty-box">
                    {isMultipleUnits
                      ? startQuantity + quantityFreed
                      : quantityFreed}
                  </span>
                ) : (
                  <span className="mr6 qty-box">{quantity}</span>
                )}
                x {formatLocalMoney(basePrice)}
                {isFreebie && <span className="c-green-1"> (FREE)</span>}
                {editable && (
                  <span
                    className="edit-btn-orange cur-p ml12"
                    onClick={() => {
                      setQuantityModalOpen(true);
                    }}
                  >
                    Edit quantity
                  </span>
                )}
              </div>
            </div>
            <div className="text-2 total-cost align-self-end">
              {isQuantityFreed
                ? handleTotalCost()
                : formatLocalMoney(basePrice * quantity)}
            </div>
          </div>
        </div>
      </div>
      {isLineItemsCancelled && (
        <div className="w-100">
          {paymentMode === CASH_PAYMENT_MODE && codRefundAmount > 0 && (
            <div className="d-flex justify-end align-center mt8">
              <p className="text-right text-italic c-black-3">
                {`${formatLocalMoney(
                  codRefundAmount
                )} will be deducted from this order.`}
              </p>
            </div>
          )}
          {![CANCELLED_STATUS, FAILED_STATUS].includes(orderStatus) &&
            cancelledRefundDetailsList?.length > 0 &&
            cancelledRefundDetailsList?.map((each) => (
              <div className="d-flex justify-end align-center mt8">
                <TickGreenIcon className="mr6" />
                <p className="text-right text-italic c-black-3">
                  {[SOURCE_METHOD_KEY, BANK_TRANSFER_KEY].includes(
                    each?.refund_mode
                  ) &&
                    `${formatLocalMoney(each.refund_amount)} refunded
            into customer’s account.`}

                  {[CUSTOMER_WALLET_KEY].includes(each?.refund_mode) &&
                    `${formatLocalMoney(
                      each.refund_amount
                    )} refunded as loyalty points.`}

                  {/* Commented for now Price break up modal */}
                  {/* <span
                  className="cur-p text-medium c-purple-1 underline"
                  onClick={() => {
                    setLineItemsRefundDetails({
                      showShipment:
                        ![STATUS_MAP.rejected_by_vendor].includes(
                          shipmentDetails.status
                        ) && shipmentDetails?.id,
                      shipmentId: shipmentDetails?.id,
                      trackingLink: shipmentDetails?.tracking_link,
                      staffName: getStaffName(shipmentDetails?.staff_name),
                      products: [productDetails],
                      lineItemDetails:
                        cancelledLineItemDetailsMap?.[lineItemId] ?? {},
                    });
                  }}
                >
                  View details
                </span> */}
                </p>
              </div>
            ))}
          {/* {!cancelledRefundDetails?.refund_status &&
            ((!canCreateVendor && (isAdmin || isOwner)) ||
              (canCreateVendor && !isVendor)) && (
              <p className="text-right text-italic mb16 c-black-3">
                <span
                  className="cur-p text-medium c-purple-1 underline"
                  onClick={() => {
                    // Open modal logic here
                    setLineItemsInitiateRefund({
                      maxRefundAmount:
                        cancelledLineItemDetailsMap?.[lineItemId]
                          ?.max_refund_amount,
                      lineItemId,
                      lineItemUuid: uuid,
                    });
                  }}
                >
                  Initiate refund
                </span>
              </p>
            )} */}
        </div>
      )}
      {showLineItemsRefundModal && (
        <LineItemsRefundDetailsModal
          open={showLineItemsRefundModal}
          closeModal={closeLineItemsRefundModal}
          refundDetails={lineItemsRefundDetails}
        />
      )}
      {Boolean(showLineItemsInitiateRefund) && (
        <InitiateRefundModal
          // submitting={submitting}
          open={Boolean(showLineItemsInitiateRefund)}
          closeModal={() => setLineItemsInitiateRefund(false)}
          editable={editable}
          refundAmount={showLineItemsInitiateRefund?.maxRefundAmount} // refundAmount
          // onSubmit={handleRefundInitiate}
        />
      )}
      <EditQuantity
        quantity={isQuantityFreed ? quantityFreed : startQuantity}
        basePrice={basePrice}
        open={quantityModalOpen}
        closeModal={() => setQuantityModalOpen(false)}
        onChangeQuantity={(qty) => {
          setQuantity(qty);
          onProductUpdated(
            uuid,
            qty >= 0 ? qty - startQuantity : startQuantity
          );
        }}
      />
    </div>
  );
};

const CancellationReason = ({ reason, className }) => (
  <div className={`cancellation-reason  ${className}`}>
    <span className="text-8 c-red-1">Reason: {reason}</span>
  </div>
);

export const OrderProducts = ({
  orderId,
  orderUUID,
  products = [],
  itemCount = 0,
  editable,
  onProductUpdated,
  isCancelledByCustomer = false,
  reason = '',
  renderRight = noop,
  isAbandonedCart = false,
  showProductDropMessage = false,
  activeReturns,
  orderDetails,
  expandedCard = false,
  returnReplacementData = {},
  isReturnedOrReplacementExist,
  cancelledLineItemDetailsMap = {},
  closeOrderDetailsModal,
}) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { editOrder, cancelLineItem, isSubmitting } = useOrderUpdate(orderUUID);
  const { canCreateVendor, isVendor } = useAppContext();
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showShipingModal, setShowShipingModal] = useState(false);
  const [vendorRejected, setVendorRejected] = useState(false);
  const [showCancelRefundModal, setShowCancelRefundModal] = useState(false);
  const showInitiateRefundModal = Boolean(showCancelRefundModal);
  const openInitiateRefundModal = (value) => setShowCancelRefundModal(value);
  const closeInitiateRefundModal = () => setShowCancelRefundModal(false);
  const { formatLocalMoney } = useCountry();

  const { badgeText } = products;
  const productsLineItemObj = products?.reduce((acc, each) => {
    acc[each.line_item_id] = deepClone(each);
    return acc;
  }, {});

  const pickupStatusList = [
    STATUS_MAP.pick_up_requested,
    STATUS_MAP.pickup_not_done,
    STATUS_MAP.pickup_failed,
    STATUS_MAP.out_for_pickup,
  ];
  const inTransitList = [STATUS_MAP.in_transit, STATUS_MAP.shipment_lost];

  const staffIdObj = {};
  products.forEach((product) => {
    if (staffIdObj[product.default_staff_id] && product.default_staff_id) {
      staffIdObj[product.default_staff_id] = {
        staffName: product.default_staff_name,
        products: [
          ...staffIdObj[product.default_staff_id].products,
          deepClone(product),
        ],
      };
    } else {
      staffIdObj[product.default_staff_id] = {
        staffName: product.default_staff_name,
        products:
          staffIdObj[product.default_staff_id]?.products?.length > 0
            ? [
                ...staffIdObj[product.default_staff_id].products,
                deepClone(product),
              ]
            : [deepClone(product)],
      };
    }
  });

  const closeShipmentModel = () => setShowShipingModal(false);

  const cancelShipmentItemObj = {
    header: `Cancel order item${showConfirmModal?.length > 1 ? 's' : ''}?`,
    content: `Once you cancel the order item${
      showConfirmModal?.length > 1 ? 's' : ''
    }, you can’t ship it anymore.`,
    icon: <DeclineOrderIcon className="header-svg-icon" />,
    confirmText: 'Yes, cancel',
    confirmBtnClass: 'btn-danger-4',
    showAnimation: true,
  };

  const handleUpdateOrder = (lineItemList = [], isRefund = false) => {
    const { payment_mode: paymentMode } = orderDetails;
    const isCodOrder = paymentMode === CASH_PAYMENT_MODE;
    if (canCreateVendor) {
      const payload = lineItemList?.map((lineItem) => {
        if (isRefund) {
          return {
            line_item: lineItem.line_item,
            refund_amount: isCodOrder
              ? 0
              : Number(lineItem.refund_amount).toFixed(2),
          };
        }
        const product = productsLineItemObj[lineItem];
        return {
          line_item: product.line_item_uuid,
          refund_amount: isCodOrder
            ? 0
            : Number(product.line_item_total_cost).toFixed(2),
        };
      });
      return cancelLineItem(payload, () => {
        enqueueSnackbar(
          `Order line item${lineItemList?.length > 1 ? 's' : ''} cancelled.`
        );
        setConfirmModal(false);
        closeInitiateRefundModal();
        if (closeOrderDetailsModal) {
          closeOrderDetailsModal();
        } else {
          setTimeout(() => window.location.reload(), 3000);
        }
      });
    }

    const params = {
      line_item_change_data: lineItemList.map((lineItemId) => {
        const product = productsLineItemObj[lineItemId];
        return {
          line_item: product.line_item_uuid,
          qty_change: -product.quantity,
        };
      }),
      delivery_cost: orderDetails.delivery_cost,
    };

    editOrder(params, () => {
      enqueueSnackbar(
        `Order line item${lineItemList?.length > 1 ? 's' : ''} cancelled.`
      );
      setConfirmModal(false);
      if (closeOrderDetailsModal) {
        closeOrderDetailsModal();
      } else {
        setTimeout(() => window.location.reload(), 3000);
      }
    });
  };

  const shipmentData =
    orderDetails?.shipment_data?.reduce((acc, each) => {
      const lineItemsIds = [];
      each?.line_item_ids?.forEach((item) => {
        if (productsLineItemObj[item]) lineItemsIds.push(item);
      });
      if (lineItemsIds.length) {
        each.line_item_ids = lineItemsIds;
        acc.push(each);
      }
      return acc;
    }, []) || [];

  const statusMap = Object.entries(STATUS_MAP).reduce((acc, [k, v]) => {
    acc[v] = k;
    return acc;
  }, {});

  const getStaffName = (staffName) => {
    if (staffName?.length === 0 || staffName === null) {
      return orderDetails?.store_data?.name;
    }
    return staffName;
  };

  const getLineItemState = (lineItemIds = []) => {
    const item = lineItemIds?.find((each) =>
      isNotEmptyOrNull({
        value: productsLineItemObj?.[each]?.line_item_state,
      })
    );
    if (item) {
      return productsLineItemObj[item];
    }
    return false;
  };

  const getOrderStatus = (shipment) => {
    const shipmentStatus = shipment.status;
    if (statusMap[shipmentStatus]) {
      if (
        shipmentStatus === STATUS_MAP.rejected_by_vendor &&
        shipment?.cancellation_reason === PICKUP_FAILED_REASON
      )
        return capitaliseFirst(PICKUP_FAILED_REASON.replaceAll('_', ' '));
      return capitaliseFirst(statusMap[shipmentStatus].replaceAll('_', ' '));
    }
    return '-';
  };

  const getVendorOrderStatus = (shipment) => {
    const productHavingLineItemState = getLineItemState(
      shipment?.line_item_ids
    );
    const label = Object.entries(VENDOR_STATUS_MAP).reduce(
      (a, [k, v]) =>
        Number(productHavingLineItemState?.line_item_state) === Number(v)
          ? capitaliseFirst(k.replaceAll('_', ' '))
          : a,
      '-'
    );
    return label;
  };

  const getOrderStatusDot = (shipment) => {
    const shipmentStatus = shipment.status;
    if (statusMap[shipmentStatus]) {
      return statusMap[shipmentStatus].split('_')[0];
    }
    return '-';
  };

  const getVendorOrderStatusDot = (shipment) => {
    const productHavingLineItemState = getLineItemState(
      shipment?.line_item_ids
    );
    return Object.entries(VENDOR_STATUS_MAP).reduce(
      (a, [k, v]) =>
        Number(productHavingLineItemState?.line_item_state) === Number(v)
          ? k.split('_')[0]
          : a,
      '-'
    );
  };

  const getTotalRefundValue = (lineItems) =>
    lineItems?.reduce((sum, id) => {
      const productWithLineItemId = productsLineItemObj[id];
      return sum + productWithLineItemId.line_item_total_cost;
    }, 0);

  const bundleOrderIds = Array.from(
    new Set(
      products
        ?.map((eachProduct) => {
          if (eachProduct?.line_item_group)
            return eachProduct?.line_item_group?.id;
        })
        ?.filter(Boolean)
    )
  );

  const orderBundles = products?.map((eachProduct) => {
    if (bundleOrderIds?.includes(eachProduct?.line_item_group?.id)) {
      return eachProduct?.line_item_group;
    }
  });

  const uniqueOrderBundles = Array.from(
    new Set(
      orderBundles.filter((item) => item !== undefined)?.map((item) => item?.id)
    )
  ).map((id) => orderBundles.find((item) => item?.id === id));

  const productsInBundle = products?.filter((eachProduct) => {
    if (bundleOrderIds?.includes(eachProduct?.line_item_group?.id)) {
      return eachProduct;
    }
  });

  const productsNotInBundle = products?.filter((eachProduct) => {
    if (!bundleOrderIds?.includes(eachProduct?.line_item_group?.id)) {
      return eachProduct;
    }
  });

  return (
    <div className={`order-products ${editable && 'edit-screen'}`}>
      {isReturnedOrReplacementExist && (
        <hr className={cx('hr-line mx0 mb16 mt0', { 'mt-8': expandedCard })} />
      )}
      {showProductDropMessage && (
        <div className="orange-1-info-stripe mb16">
          Drop this order at nearest carrier point or reschedule pickup via
          Shippo.
        </div>
      )}
      {isCancelledByCustomer && (
        <CancellationReason reason={reason} className="mb16" />
      )}
      <div className="d-flex-c-s">
        {itemCount < 1 ? (
          <Shimmer height={24} width={70} className="mb12" />
        ) : (
          <div className="section-text-5 c-gray-1 mb12">
            {getProductCountText(itemCount)}
          </div>
        )}
        {renderRight()}
      </div>
      {products.length < 1 ? (
        <ProductsShimmer itemCount={Math.min(3, itemCount)} />
      ) : (
        <>
          {canCreateVendor &&
            !isVendor &&
            shipmentData.length > 0 &&
            shipmentData?.map((shipment, index) => (
              <div>
                <div className="d-flex-c-s mb12">
                  <div className="d-flex">
                    {![STATUS_MAP.rejected_by_vendor].includes(
                      shipment.status
                    ) && (
                      <h4 className="section-text-5 mr12">
                        Shipment ID:{' '}
                        {shipment?.tracking_link ? (
                          <a
                            href={shipment?.tracking_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="anchor-1 underline text-medium"
                          >
                            {shipment?.id}
                          </a>
                        ) : (
                          <span>{shipment?.id}</span>
                        )}
                      </h4>
                    )}

                    <p className="duplicate-order-text-container px16 py4 text-8">
                      {getStaffName(shipment?.staff_name)}
                    </p>
                  </div>
                  <div className="d-flex align-center ">
                    <div
                      className={cx(
                        'status-dot text-8',
                        'mr6',
                        (shipment.is_reverse_shipment ||
                          shipment.type === REVERSE_TYPE) &&
                          'undelivered',
                        !shipment.is_reverse_shipment &&
                          shipment.type !== REVERSE_TYPE &&
                          inTransitList.includes(shipment.status) &&
                          'in',
                        !shipment.is_reverse_shipment &&
                          shipment.type !== REVERSE_TYPE &&
                          !inTransitList.includes(shipment.status) &&
                          !shipment.is_reverse_shipment &&
                          pickupStatusList.includes(shipment.status) &&
                          'pick',
                        !shipment.is_reverse_shipment &&
                          shipment.type !== REVERSE_TYPE &&
                          !pickupStatusList.includes(shipment.status) &&
                          !inTransitList.includes(shipment.status) &&
                          !getLineItemState(shipment?.line_item_ids) &&
                          getOrderStatusDot(shipment),
                        !shipment.is_reverse_shipment &&
                          shipment.type !== REVERSE_TYPE &&
                          !pickupStatusList.includes(shipment.status) &&
                          !inTransitList.includes(shipment.status) &&
                          Boolean(getLineItemState(shipment?.line_item_ids)) &&
                          getVendorOrderStatusDot(shipment)
                      )}
                    />
                    <span className="status-text-wrap">
                      {!shipment.is_reverse_shipment &&
                        shipment.type !== REVERSE_TYPE &&
                        !pickupStatusList.includes(shipment.status) &&
                        !inTransitList.includes(shipment.status) &&
                        !getLineItemState(shipment?.line_item_ids) &&
                        getOrderStatus(shipment)}
                      {!shipment.is_reverse_shipment &&
                        shipment.type !== REVERSE_TYPE &&
                        !pickupStatusList.includes(shipment.status) &&
                        !inTransitList.includes(shipment.status) &&
                        Boolean(getLineItemState(shipment?.line_item_ids)) &&
                        getVendorOrderStatus(shipment)}

                      {!shipment.is_reverse_shipment &&
                        shipment.type === REVERSE_TYPE &&
                        'RTO'}
                      {!shipment.is_reverse_shipment &&
                        shipment.type !== REVERSE_TYPE &&
                        pickupStatusList.includes(shipment.status) &&
                        'Pickup pending'}
                      {!shipment.is_reverse_shipment &&
                        shipment.type !== REVERSE_TYPE &&
                        inTransitList.includes(shipment.status) &&
                        'In transit'}
                      {shipment.is_reverse_shipment && 'Return'}
                    </span>
                  </div>
                </div>
                {shipment?.line_item_ids?.map((lineItemId) => {
                  const productWithLineItemId = productsLineItemObj[lineItemId];
                  if (!productWithLineItemId) return null;
                  return (
                    <OrderProductInfo
                      orderUUID={orderUUID}
                      orderId={orderId}
                      badgeText={badgeText}
                      activeReturns={activeReturns}
                      returnReplacementData={returnReplacementData}
                      isAbandonedCart={isAbandonedCart}
                      key={productWithLineItemId?.product_uuid}
                      {...productWithLineItemId}
                      editable={
                        editable &&
                        (shipment?.line_item_ids?.length > 1 ||
                          productWithLineItemId.quantity > 1)
                      }
                      isQuantityFreed={
                        productWithLineItemId?.quantity === 0 &&
                        productWithLineItemId?.quantity_freed > 0
                      }
                      isFreebie={productWithLineItemId?.is_freebie}
                      isMultipleUnits={
                        productWithLineItemId?.quantity !== 0 &&
                        productWithLineItemId?.quantity_freed !== null &&
                        productWithLineItemId?.quantity_freed !== undefined
                      }
                      onProductUpdated={(lineItemUuid, quantityDelta) =>
                        onProductUpdated(lineItemUuid, quantityDelta, index)
                      }
                      shipmentDetails={shipment}
                      storeName={orderDetails?.store_data?.name}
                      paymentMode={orderDetails?.payment_mode}
                      productDetails={productWithLineItemId}
                      cancelledLineItemDetailsMap={cancelledLineItemDetailsMap}
                      orderStatus={orderDetails.status}
                    />
                  );
                })}
                {orderDetails.status === SHIPPED_STATUS &&
                  canCreateVendor &&
                  !isVendor &&
                  [STATUS_MAP.rejected_by_vendor].includes(shipment.status) &&
                  products?.find((each) =>
                    shipment?.line_item_ids?.includes(each?.line_item_id)
                  )?.line_item_state === null && (
                    <div className="mb20 d-flex-c-end">
                      <SpinnerButton
                        type="button"
                        showAnimation
                        className="pos-rel spinner-btn btn-no-default section-text-7 c-red-1 btn-small"
                        onClick={() => {
                          if (orderDetails?.payment_mode === CASH_PAYMENT_MODE)
                            return setConfirmModal(shipment?.line_item_ids);
                          return openInitiateRefundModal(
                            shipment?.line_item_ids
                          );
                        }}
                      >
                        Cancel item
                        {shipment?.line_item_ids?.length > 1 ? 's' : ''}
                        {orderDetails?.payment_mode === ONLINE_PAYMENT_MODE &&
                          ' & Refund'}
                      </SpinnerButton>
                      <SpinnerButton
                        type="button"
                        showAnimation
                        className="btn btn-warning btn-small"
                        onClick={() => {
                          setShowShipingModal(shipment?.line_item_ids);
                          if (
                            [STATUS_MAP.rejected_by_vendor].includes(
                              shipment.status
                            )
                          ) {
                            setVendorRejected({
                              warehouseName: shipment.warehouse.name,
                              vendorId: shipment.staff_id,
                            });
                          }
                        }}
                      >
                        Re-ship item
                        {shipment?.line_item_ids?.length > 1 ? 's' : ''}
                      </SpinnerButton>
                    </div>
                  )}
                {index !== shipmentData?.length - 1 && (
                  <div className="hr-line mx0 mb16" />
                )}
              </div>
            ))}
          {/* // When vendor is there, On ship show this modal */}
          {showShipingModal && canCreateVendor && (
            <VendorShipOrderModal
              open={Boolean(showShipingModal)}
              products={products.filter((each) =>
                showShipingModal?.includes(each.line_item_id)
              )}
              rejectedWarehouse={vendorRejected}
              orderId={orderId}
              closeModal={closeShipmentModel}
              orderDetails={orderDetails}
              btnText={`Re-ship item${showShipingModal?.length > 1 ? 's' : ''}`}
            />
          )}
          {showConfirmModal && canCreateVendor && (
            <OrderConfirmationModal
              open={Boolean(showConfirmModal)}
              closeModal={() => setConfirmModal(false)}
              handleConfirm={() => handleUpdateOrder(showConfirmModal)}
              headerText={cancelShipmentItemObj.header}
              content={
                <>
                  <span className="d-block">
                    {cancelShipmentItemObj.content}
                  </span>{' '}
                  {getTotalRefundValue(showConfirmModal) > 0 && (
                    <span className="d-block text-medium mt4">
                      Note:
                      {formatLocalMoney(
                        getTotalRefundValue(showConfirmModal)
                      )}{' '}
                      will be deducted from this order.
                    </span>
                  )}
                </>
              }
              icon={cancelShipmentItemObj.icon}
              confirmText={cancelShipmentItemObj.confirmText}
              confirmBtnClass={cancelShipmentItemObj.confirmBtnClass}
              loading={isSubmitting}
              showCloseIcon={!isSubmitting} ///
            />
          )}
          {canCreateVendor &&
            !isVendor &&
            orderDetails?.shipment_data?.length <= 0 &&
            Object.entries(staffIdObj).map(([key, value]) => (
              <div key={key} className="vendor-order-product-info">
                <div className="d-flex mb12">
                  {(value.staffName || value.staffName === null) && (
                    <p className="duplicate-order-text-container px12 py4 text-8">
                      {getStaffName(value.staffName)}
                    </p>
                  )}
                </div>

                {value.products.map((product, index) => (
                  <OrderProductInfo
                    orderUUID={orderUUID}
                    orderId={orderId}
                    badgeText={badgeText}
                    returnReplacementData={returnReplacementData}
                    activeReturns={activeReturns}
                    isAbandonedCart={isAbandonedCart}
                    key={product?.product_uuid}
                    {...product}
                    isQuantityFreed={product?.quantity_freed > 0}
                    isMultipleUnits={
                      product?.quantity !== 0 &&
                      product?.quantity_freed !== null &&
                      product?.quantity_freed !== undefined
                    }
                    editable={editable && product.quantity > 1}
                    onProductUpdated={(lineItemUuid, quantityDelta) =>
                      onProductUpdated(lineItemUuid, quantityDelta, index)
                    }
                    storeName={orderDetails?.store_data?.name}
                    paymentMode={orderDetails?.payment_mode}
                    productDetails={product}
                    cancelledLineItemDetailsMap={cancelledLineItemDetailsMap}
                    isFreebie={product?.is_freebie}
                  />
                ))}
              </div>
            ))}

          {(!canCreateVendor || isVendor || editable) &&
            products
              .filter(
                (product) =>
                  product?.quantity !== 0 &&
                  !isNotEmptyOrNull({ value: product?.quantity_freed })
              )
              .map((product, index) => (
                <OrderProductInfo
                  orderUUID={orderUUID}
                  orderId={orderId}
                  badgeText={badgeText}
                  activeReturns={activeReturns}
                  returnReplacementData={returnReplacementData}
                  isAbandonedCart={isAbandonedCart}
                  key={product?.product_uuid}
                  {...product}
                  paymentMode={orderDetails?.payment_mode}
                  editable={
                    editable && (products.length > 1 || product.quantity > 1)
                  }
                  onProductUpdated={(lineItemUuid, quantityDelta) =>
                    onProductUpdated(lineItemUuid, quantityDelta, index)
                  }
                  isFreebie={product?.is_freebie}
                />
              ))}

          {uniqueOrderBundles?.length > 0 &&
            uniqueOrderBundles?.map((eachBundle) => {
              const exactBundleProducts = productsInBundle?.filter(
                (eachProduct) =>
                  eachProduct?.line_item_group?.id === eachBundle.id
              );

              return (
                <>
                  <OrderBundleInfo bundleItem={eachBundle} />
                  <div className={cx({ mb24: exactBundleProducts?.length })}>
                    {(!canCreateVendor || isVendor || editable) &&
                      exactBundleProducts.map((product) => {
                        if (
                          product?.quantity === 0 &&
                          product?.quantity_freed > 0
                        ) {
                          // render free product
                          return (
                            <OrderProductInfo
                              isBundleProductOrder
                              isAbandonedCart={isAbandonedCart}
                              {...product}
                              key={product?.uuid}
                              isQuantityFreed={product?.quantity_freed > 0}
                              badgeText={badgeText}
                              activeReturns={activeReturns}
                              paymentMode={orderDetails?.payment_mode}
                              returnReplacementData={returnReplacementData}
                              isFreebie={product?.is_freebie}
                            />
                          );
                        }
                        if (
                          product?.quantity !== 0 &&
                          product?.quantity_freed !== null &&
                          product?.quantity_freed !== undefined
                        ) {
                          // render product and free product with units modified
                          return (
                            <div className="bundle-product">
                              <OrderProductInfo
                                isBundleProductOrder
                                orderUUID={orderUUID}
                                orderId={orderId}
                                activeReturns={activeReturns}
                                badgeText={badgeText}
                                returnReplacementData={returnReplacementData}
                                isAbandonedCart={isAbandonedCart}
                                {...product}
                                paymentMode={orderDetails?.payment_mode}
                                key={product?.uuid}
                                isQuantityFreed={product?.quantity_freed > 0}
                                isMultipleUnits
                                isFreebie={product?.is_freebie}
                              />
                            </div>
                          );
                        }
                        return true;
                      })}
                  </div>
                </>
              );
            })}

          {(!canCreateVendor || isVendor || editable) &&
            productsNotInBundle.map((product) => {
              if (product?.quantity === 0 && product?.quantity_freed > 0) {
                // render free product
                return (
                  <OrderProductInfo
                    isAbandonedCart={isAbandonedCart}
                    {...product}
                    key={product?.uuid}
                    isQuantityFreed={product?.quantity_freed > 0}
                    badgeText={badgeText}
                    activeReturns={activeReturns}
                    paymentMode={orderDetails?.payment_mode}
                    returnReplacementData={returnReplacementData}
                    isFreebie={product?.is_freebie}
                  />
                );
              }
              if (
                product?.quantity !== 0 &&
                product?.quantity_freed !== null &&
                product?.quantity_freed !== undefined
              ) {
                // render product and free product with units modified
                return (
                  <OrderProductInfo
                    orderUUID={orderUUID}
                    orderId={orderId}
                    activeReturns={activeReturns}
                    badgeText={badgeText}
                    returnReplacementData={returnReplacementData}
                    isAbandonedCart={isAbandonedCart}
                    {...product}
                    paymentMode={orderDetails?.payment_mode}
                    key={product?.uuid}
                    isQuantityFreed={product?.quantity_freed > 0}
                    isMultipleUnits
                    isFreebie={product?.is_freebie}
                  />
                );
              }
              return true;
            })}
        </>
      )}
      {showInitiateRefundModal && (
        <CancelAndRefundLineItem
          open={showInitiateRefundModal}
          closeModal={closeInitiateRefundModal}
          lineItemIds={showCancelRefundModal}
          productsLineItemObj={productsLineItemObj}
          submitting={isSubmitting}
          onSubmit={(values) => handleUpdateOrder(values.lineItems, true)}
        />
      )}
    </div>
  );
};

export const OrderTotal = (props) => {
  const {
    isOrderDetailsReadOnly = false,
    delivery_cost: initialDeliveryCost = 0,
    total_cost: totalCost = 0,
    products = [],
    coupon_code: couponCode,
    coupon_discount: couponDiscount,
    coupon_data: couponData = {},
    isRestaurantOrder,
    editable,
    onChangeDeliveryCost,
    order_meta: {
      order_service_charge_data: orderServiceChargeData = [],
      order_discount: orderDiscount = [],
      total_additional_discount_on_skus: specialPromoDiscount,
    } = {},
    uuid,
    showEstimateDeliveryCharge,
    isReturnedOrReplacementExist,
    isParentModalOpen,
    id: orderId,
    isAbandonedCart = false,
    payment_details: paymentDetails,
    customer_credits_used: customerCreditsUsed = 0,
    customer_credits_earned: customerCreditsEarned = 0,
    order_discounts: orderDiscounts = [],
    shippingMode,
    seller_marked_prepaid: sellerMarkedPrepaid,
    type,
    payment_mode: paymentMode,
    isManualPaymentOrder,
  } = props;

  const { canCreateVendor } = useAppContext();
  const { deliveryModalOpen, setDeliveryModalOpen } = useOrderUpdate();
  const [deliveryCost, setDeliveryCost] = useState(initialDeliveryCost);
  const calculateUpdatedDiscount = useUpdatedCouponDiscount();
  const { formatLocalMoney, isInternational } = useCountry();
  const history = useHistory();
  const gstChargeData = orderServiceChargeData?.find((each) =>
    Boolean(each.only_store_gst_present)
  );

  const isRefundCompleted = paymentDetails?.order_refunds?.every(
    (each) => each.refund_status === RETURN_REFUND_STATUS_MAP.COMPLETED
  );

  useEffect(() => {
    setDeliveryCost(initialDeliveryCost);
  }, [initialDeliveryCost]);

  // calculate item total and grand total
  let productsCost = 0;
  let grandTotal = 0;
  let bxgyCouponDiscount = 0;

  products?.forEach((product) => {
    const { totalCost: cost } = getProductTotals(product);
    productsCost += cost;
    grandTotal += cost;
    if (product.quantity_freed) {
      const {
        selling_price: sellingPrice,
        variant_selling_price: variantSellingPrice,
        quantity_freed: quantityFreed,
      } = product;
      const price = Number(variantSellingPrice || sellingPrice);
      const bxgyProductCost = quantityFreed * price;
      bxgyCouponDiscount += bxgyProductCost;
    }
  });

  const { couponDiscount: calculatedCouponDiscount = 0 } =
    calculateUpdatedDiscount(couponData, productsCost);

  const manualCouponDiscount = couponCode ? 0 : couponDiscount;

  if (couponData?.discount_type === BUY_X_GET_Y) {
    productsCost += bxgyCouponDiscount;
  }

  const calculationCost = productsCost - calculatedCouponDiscount;

  orderServiceChargeData.forEach((charge) => {
    if (charge.type === PERCENTAGE_CHARGE_TYPE) {
      grandTotal += (charge.charge * calculationCost) / 100;
    }
    if (charge.type === FLAT_CHARGE_TYPE) {
      grandTotal += charge.charge;
    }
  });
  grandTotal += deliveryCost; // add delivery charges to grandtotal
  grandTotal -= calculatedCouponDiscount + manualCouponDiscount;

  const getChargeValue = (charge, amount) => {
    if (!editable) return formatLocalMoney(charge.cost_applied);
    let output = 0;
    if (charge.type === PERCENTAGE_CHARGE_TYPE) {
      output = (amount * charge.charge) / 100;
    }
    if (charge.type === FLAT_CHARGE_TYPE) {
      output = charge.charge;
    }

    return formatLocalMoney(output);
  };

  const calculateClickOption = () => {
    history.push(
      `${rootUrls.calculateShipmentPricePath.replace(
        ':uuid',
        uuid
      )}?orderId=${orderId}&shippingMode=${
        shippingMode ?? DUKAAN_DELIVERY_SHIPPING_MODE
      }`
    );
  };

  const isOrderPartiallyPaid =
    Number(paymentDetails?.amount) > 0 &&
    Number(paymentDetails?.amount) <
      Number(totalCost) -
        orderDiscount
          .map((discount) => discount.value)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) &&
    !customerCreditsUsed &&
    !isReturnedOrReplacementExist;

  const partialPaymentAmount = Number(paymentDetails?.amount);

  const renderOrderDiscounts = (discounts = []) => {
    const filteredDiscounts = discounts
      .filter((each) =>
        [
          DISCOUNT_TYPE_COUPON,
          DISCOUNT_TYPE_MANUAL,
          DISCOUNT_TYPE_MEMBERSHIP,
        ].includes(each.type)
      )
      .filter((each) => each.subtype !== 'free_shipping')
      .filter((it) => {
        if (
          it.subtype === LOYALTY_POINTS_COUPON_KEY &&
          it.discount_value === 0 // hide 0 value coupons of loyalty type
        ) {
          return false;
        }
        return true;
      });

    const getTitle = (discount) => {
      if (
        discount.type === DISCOUNT_TYPE_MEMBERSHIP &&
        discount.subtype === 'freebie'
      ) {
        return (
          <div>
            Freebie discount <SuccessBadge text="Membership" className="ml12" />
          </div>
        );
      }
      if (
        discount.type === DISCOUNT_TYPE_COUPON &&
        discount.subtype === FREEBIE_DISCOUNT_COUPON_KEY
      ) {
        return `Freebie coupon discount (${discount.title})`;
      }

      if (discount.type === DISCOUNT_TYPE_MANUAL) {
        return <div>Coupon discount (Manual)</div>;
      }

      return <div>Coupon discount ({discount.title})</div>;
    };

    const getDiscount = (discount) => {
      if (discount.subtype === LOYALTY_POINTS_COUPON_KEY) {
        return <span>{discount.discount_value} points</span>;
      }
      if (discount.discount_value === 0) {
        return <span className="c-green-1">FREE</span>;
      }
      if (discount.discount_value === 0) {
        return <span className="c-green-1">FREE</span>;
      }
      return `-${formatLocalMoney(discount?.discount_value)}`;
    };

    return filteredDiscounts.map((each) => (
      <div className="d-flex-c-s">
        <span>{getTitle(each)}</span>
        <span>{getDiscount(each)}</span>
      </div>
    ));
  };

  const isMembershipFreeDelivery = orderDiscounts.some(
    (it) =>
      it.subtype === 'free_shipping' && it.type === DISCOUNT_TYPE_MEMBERSHIP
  );

  return products.length < 1 ? (
    <OrderTotalShimmer />
  ) : (
    <div className="order-total">
      <div>
        <span>Subtotal</span>
        <span>{formatLocalMoney(productsCost)}</span>
      </div>
      {Boolean(gstChargeData) && (
        <div>
          <span>
            {gstChargeData.name}
            {gstChargeData.type === PERCENTAGE_CHARGE_TYPE &&
              gstChargeData.only_store_gst_present &&
              gstChargeData.charge &&
              ` (${gstChargeData.charge}%)`}
          </span>
          <span>{getChargeValue(gstChargeData, calculationCost)}</span>
        </div>
      )}
      {renderOrderDiscounts(orderDiscounts)}

      {specialPromoDiscount > 0 && (
        <div>
          <span>Special Promo</span>
          <span>-{formatLocalMoney(specialPromoDiscount)}</span>
        </div>
      )}
      {!isAbandonedCart && (
        <div>
          <div>
            <span className="mr12">Delivery </span>
            {/* {editable && !isOrderDetailsReadOnly && (
              <span
                className="edit-btn-orange cur-p"
                onClick={() => setDeliveryModalOpen(true)}
              >
                Edit delivery fee
              </span>
            )} */}
            {isMembershipFreeDelivery && <SuccessBadge text="Membership" />}
          </div>
          <span>
            {deliveryCost ? (
              formatLocalMoney(deliveryCost)
            ) : (
              <span className="c-green-1">FREE</span>
            )}
          </span>
        </div>
      )}
      {orderServiceChargeData.map((charge, index) => {
        if (charge.only_store_gst_present) return null;
        return (
          <div key={index}>
            <span>
              {charge.name}
              {charge.type === PERCENTAGE_CHARGE_TYPE &&
                charge.only_store_gst_present &&
                charge.charge &&
                ` (${charge.charge}%)`}
            </span>
            <span>{getChargeValue(charge, calculationCost)}</span>
          </div>
        );
      })}
      {orderDiscount.map((charge, index) => (
        <div key={index}>
          <span>{charge.key}</span>
          <span>-{formatLocalMoney(charge.value)}</span>
        </div>
      ))}
      <hr className="hr-line mt12 mb12" />
      <div
        className={cx('mt24 grand-total-row', {
          pb16: isOrderPartiallyPaid,
        })}
      >
        <span className="d-flex align-center">
          Total
          {paymentDetails?.order_refunds?.length > 0 && (
            <p className="d-flex align-center ml8 text-italic text-8 c-black-3 text-normal">
              <span className="d-flex mr6">
                <TickGreenIcon />
              </span>
              {isRefundCompleted ? 'Refunded' : 'Refund initiated'}
            </p>
          )}
        </span>
        <div className="d-flex align-center">
          <span
            className={`payment-mode-static mr8 ${getPaymentBadge(
              type,
              paymentMode,
              isManualPaymentOrder
            )}`}
          >
            {getPaymentBadge(
              type,
              paymentMode,
              isManualPaymentOrder,
              sellerMarkedPrepaid
            )}
          </span>
          <span>{formatLocalMoney(editable ? grandTotal : totalCost)}</span>
        </div>
      </div>

      {customerCreditsUsed > 0 && (
        <>
          <div className="mt16 divider" />
          <div className="mt16">
            <span>Loyalty Points</span>
            <span>-{formatLocalMoney(customerCreditsUsed)}</span>
          </div>
          <div className="grand-total-row">
            <span>Net Amount</span>
            <span>
              {formatLocalMoney(
                (editable ? grandTotal : totalCost) - customerCreditsUsed
              )}
            </span>
          </div>
        </>
      )}
      {customerCreditsEarned > 0 && (
        <>
          <div className="mt16 divider" />
          <div className="mt16">
            <span>Loyalty Points earned</span>
            <span>{formatNumber(customerCreditsEarned)} points</span>
          </div>
        </>
      )}
      {isOrderPartiallyPaid && (
        <>
          <div
            className={cx('divider', {
              mt8: isParentModalOpen,
            })}
          />
          <div className="d-flex-start-s mt24">
            <span className="text-2">Partial Payment</span>
            <span>-{formatLocalMoney(partialPaymentAmount)}</span>
          </div>
          <div className="d-flex-start-s">
            <span className="section-text-4">Pending Amount (COD)</span>
            <span className="section-text-4">
              {formatLocalMoney(totalCost - partialPaymentAmount)}
            </span>
          </div>
        </>
      )}
      <hr
        className={cx('hr-line mx0 my24', {
          'd-none': !isParentModalOpen,
        })}
      />

      <EditDeliveryFee
        open={deliveryModalOpen}
        deliveryCost={deliveryCost}
        onChangeDeliveryCost={(cost) => {
          let newCost = 0;

          if (typeof cost === 'string') {
            newCost = Number(cost);
          }
          setDeliveryCost(newCost);
          onChangeDeliveryCost(newCost);
        }}
        closeModal={() => setDeliveryModalOpen(false)}
      />

      {/* <ShipmentEstimateModal
        data={orderShippingDetails}
        open={isOpen}
        orderUuid={uuid}
        weight={orderWeight}
        orderStatus={status}
        closeModal={closeModal}
      /> */}
    </div>
  );
};

export const OrderDetailHeader = (props) => {
  const {
    isOrderDetailsReadOnly = false,
    display_order_id: id,
    activeReturns,
    is_modified: isModified,
    status,
    new_status: newStatus,
    address,
    created_at: createdAt,
    uuid,
    products = [],
    type,
    payment_mode: paymentMode,
    trackOrder,
    showTrackBtn,
    showSelfTrackEditBtn = false,
    coupon_data: couponData = {},
    order_meta: orderMeta,
    onSelfTrackOrder = noop,
    riskStatus,
    showRiskStatus,
    isPaymentLinkSent,
    returnReplacementData,
    parentClassName = 'mb16',
    isManualPaymentOrder = false,
    seller_marked_prepaid: sellerMarkedPrepaid,
    updateSpamStatus = noop,
    refetch = noop,
    submitting = false,
    isStatusAbandoned,
  } = props;
  const { canCreateVendor, isVendor, isEnterprise } = useAppContext();
  const isReturnedOrReplacementExist =
    returnReplacementData?.order_return_id > 0;
  const { order_source: orderSource, is_spam: isSpam = false } =
    orderMeta ?? {};

  const {
    openModal: openMarkAsSpamModal,
    closeModal: closeMarkAsSpamModal,
    isOpen: isMarkAsSpamModalOpen,
  } = useModal();

  const {
    isOpen: isReceiptModalOpen,
    openModal: openReceiptModal,
    closeModal: closeReceiptModal,
  } = useModal();

  const productsQuantity = products.some((each) => each?.quantity <= 0);
  const isReplacementOrderExist =
    isReturnedOrReplacementExist &&
    returnReplacementData?.replaced_items_data?.replaced_items?.length > 0;

  const RETURN_STATUS_MAP_COMMON = isReturnedOrReplacementExist
    ? GET_RETURN_STATUS_MAP(isReplacementOrderExist ? 'replacement' : 'return')
    : {};
  return (
    <div className={parentClassName}>
      {isNotEmptyOrNull({ value: riskStatus }) && (
        <div className="d-flex space-between mb8">
          {riskStatus === RTO_HIGH_RISK &&
            isNotEmptyOrNull({ value: riskStatus }) && (
              <OutlineBadge text="RTO risk: High" color="red" />
            )}
          {riskStatus === RTO_MEDIUM_RISK &&
            isNotEmptyOrNull({ value: riskStatus }) && (
              <OutlineBadge text="RTO risk: Medium" color="orange" />
            )}
          {riskStatus === RTO_LOW_RISK &&
            isNotEmptyOrNull({ value: riskStatus }) && (
              <OutlineBadge text="RTO risk: Low" color="green" />
            )}
        </div>
      )}

      <div className={cx('d-flex space-between', { mb8: !productsQuantity })}>
        <div>
          <div className="d-flex align-center mb6">
            <h4 className="section-text-4 text-semibold cur-default user-select-text">
              #{id}
            </h4>
            {isReturnedOrReplacementExist && (
              <div className="d-flex ml12 align-center">
                {isReplacementOrderExist ? (
                  <ReplacementRedIcon />
                ) : (
                  <ReturnRedIcon />
                )}

                <span className="ml6 text-8 text-normal c-black-3">
                  {
                    RETURN_STATUS_MAP_COMMON?.[
                      returnReplacementData?.return_replacement_status
                    ]
                  }
                </span>
              </div>
            )}
            {address?.auto_accepted && (
              <div className="d-flex align-center ml12">
                <TickGreenIcon height={20} width={20} className="mr4" />
                <span className="text-italic c-black-3">
                  Auto-accepted order
                </span>
              </div>
            )}
            {address?.auto_rejected && (
              <div className="d-flex align-center ml12">
                <ShipmentCancelIcon height={20} width={20} className="mr4" />
                <span className="text-italic c-black-3">
                  Auto-rejected order
                </span>
              </div>
            )}
          </div>
          <div className="d-flex align-center">
            <p className=" text-normal c-black-1">
              {formatOrderTime(createdAt)}
            </p>
            {type === ORDER_TYPES.MANUAL && (
              <>
                <div className="gray-circle" />
                <p className=" c-black-1">
                  {CHANNEL_TEXT[ORDER_CREATION.MANUAL]}{' '}
                  {orderSource ? `(${orderSource})` : ''}
                </p>
              </>
            )}
            {isSpam && <RedBadge text="SPAM" className="ml12" />}
          </div>
        </div>

        <div className="d-flex align-center">
          {showSelfTrackEditBtn && !isOrderDetailsReadOnly && (
            <SpinnerButton
              type="button"
              className="btn-outline-primary-4 mr16"
              onClick={onSelfTrackOrder}
            >
              <span className="btn-txt">Add tracking</span>
            </SpinnerButton>
          )}
          {!isStatusAbandoned && (
            <SpinnerButton
              className="btn-outline-primary-4"
              onClick={() => {
                openReceiptModal();
              }}
            >
              <ShareRecieptIcon className="mr6" height={14} width={14} />
              Receipt
            </SpinnerButton>
          )}
        </div>
      </div>

      {isMarkAsSpamModalOpen && (
        <ConfirmModal
          open={isMarkAsSpamModalOpen}
          svgIcon={<ErrorIcon className="mb16" />}
          onCancel={closeMarkAsSpamModal}
          onConfirm={() => {
            updateSpamStatus(!isSpam, () => {
              refetch();
              closeMarkAsSpamModal();
            });
          }}
          confirmBtnClass="btn-danger-4"
          confirmText="Yes, mark order as spam"
          headerText="Mark as spam order"
          content={
            <span className="section-text-7">
              Are you sure that you want to mark this order as spam? <br /> The
              order will be cancelled.
            </span>
          }
          showAnimation
          maxWidth="sm"
          loading={submitting}
        />
      )}
      <DownloadOrderReceiptModal
        uuid={uuid}
        open={isReceiptModalOpen}
        closeModal={closeReceiptModal}
      />
    </div>
  );
};

export const RestaurantOrderDetailHeader = (props) => {
  const {
    display_order_id: id,
    status,
    created_at: createdAt,
    storeTableData,
    trackOrder,
    showTrackBtn,
    showSelfTrackEditBtn = false,
    onSelfTrackOrder = noop,
  } = props;
  let tableName = '';

  if (storeTableData) {
    if (typeof storeTableData === 'string' && storeTableData !== '') {
      const { name } = JSON.parse(storeTableData);
      tableName = name;
    } else {
      tableName = storeTableData.name;
    }
  }

  return (
    <div>
      <div className="d-flex-c-s mb6">
        <h4 className="section-text-4 text-dining">{tableName}</h4>
        <StatusText status={status} isRestaurantOrder />
      </div>
      {showTrackBtn ? (
        <SpinnerButton
          type="button"
          className="btn-outline-primary-4 mt8 mb16"
          onClick={trackOrder}
        >
          <span className="btn-txt">Track order</span>
        </SpinnerButton>
      ) : null}
      {showSelfTrackEditBtn && (
        <SpinnerButton
          type="button"
          className="btn-outline-primary-4 mt8 mb16"
          onClick={onSelfTrackOrder}
        >
          <span className="btn-txt">Add tracking</span>
        </SpinnerButton>
      )}
      <div className="d-flex-c-s mb8">
        <p className="text-2 mb4">Order ID #{id}</p>
        <p className="text-2 text-normal c-gray-1">
          {formatOrderTime(createdAt)}
        </p>
      </div>
    </div>
  );
};

export const EditQuantity = ({
  quantity,
  basePrice,
  open = false,
  closeModal,
  onChangeQuantity,
}) => {
  const [checked, setChecked] = useState(quantity);
  const { formatLocalMoney } = useCountry();

  const handleConfirm = () => {
    onChangeQuantity(checked);
    closeModal();
  };

  useEnterPressAction(open, checked === null ? noop : handleConfirm);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      paperClass="info-dialog edit-quantity-modal"
      showCloseIcon={false}
    >
      <div className="sticky-header">
        <h2 className="section-text-4 modal-title">Edit quantity</h2>
        <div className="dialog-close-icon">
          <CrossIcon onClick={closeModal} />
        </div>
      </div>
      <Form
        onSubmit={() => {}}
        render={() => (
          <div>
            <div className="edit-quantity-modal-list">
              {new Array(quantity).fill(0).map((_, idx) => (
                <div className="d-flex space-between mb24 cur-p" key={idx}>
                  <div
                    className="d-flex align-center"
                    onClick={() => setChecked(quantity - idx)}
                  >
                    <CustomRadio checked={checked === quantity - idx} />
                    <span className="text-2">{quantity - idx}</span>
                  </div>
                  <div>
                    <span className="text-2">
                      {formatLocalMoney(basePrice * (quantity - idx))}
                    </span>
                  </div>
                </div>
              ))}
              <div
                className="d-flex space-between mb24 cur-p"
                onClick={() => setChecked(0)}
              >
                <div className="d-flex align-center">
                  <CustomRadio checked={checked === 0} />
                  <span className="text-2">Remove product</span>
                </div>
              </div>
            </div>
            <div className="text-center mt24">
              <SpinnerButton
                type="button"
                className="btn-primary-4"
                disabled={checked === null}
                onClick={handleConfirm}
              >
                Confirm
              </SpinnerButton>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};

export const ReturnDetails = ({
  orderDetails,
  isOrderDetailsPage,
  returnReplacementData,
}) => {
  const { formatLocalMoney } = useCountry();
  const { order_returns_data: { active_returns: activeReturns = [] } = {} } =
    orderDetails;

  const [returnShipmentImages, setReturnShipmentImages] = useState({
    slides: [],
    thumbnails: [],
    selectedIndex: 0,
  });

  const {
    isOpen: showReturnShipmentImagesModal,
    closeModal: closeReturnShipmentImagesModal,
    openModal: openReturnShipmentImagesModal,
  } = useModal();

  const formatCarrierName = (name = '') => {
    if (name && typeof name === 'string') {
      return toTitleCase(name.replace('_', ' '));
    }
    return '';
  };

  const returnDetailsRef = useRef();
  const isVisible = useOnScreen(returnDetailsRef);

  const returnShipmentDetails = activeReturns?.[0]?.return_shipment;

  const scrollReturnDetailsIntoView = () => {
    returnDetailsRef.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  };

  const combineReturnShipmentImages = (
    rowData,
    returnShipmentId,
    imageIndex
  ) => {
    const { return_items: returnItems } = rowData;
    const finalImagesArray = [];
    let selectedIndex = 0;

    returnItems.forEach((item) => {
      item.buyer_item_images.forEach((imageLink, index) => {
        finalImagesArray.push(imageLink);
        if (returnShipmentId === item.id && index === imageIndex) {
          selectedIndex = finalImagesArray.length - 1;
        }
      });
    });

    setReturnShipmentImages({
      thumbnails: finalImagesArray,
      slides: finalImagesArray.reduce((accum, currentImageLink) => {
        const slidesImageElements = [...accum];
        slidesImageElements.push(<img src={currentImageLink} alt="slide" />);
        return slidesImageElements;
      }, []),
      selectedIndex,
    });
    openReturnShipmentImagesModal();
  };
  const isReturnCompleted = activeReturns?.[0]?.status === COMPLETED_RETURN;
  return (
    <>
      <ReturnShipmentImagesModal
        open={showReturnShipmentImagesModal}
        closeModal={closeReturnShipmentImagesModal}
        returnShipmentImages={returnShipmentImages}
      />
      {!isVisible && isOrderDetailsPage && (
        <button
          type="button"
          onClick={scrollReturnDetailsIntoView}
          className="view-return-details"
        >
          <ArrowDownScrollIcon /> View return details
        </button>
      )}

      <div ref={returnDetailsRef} className="card my24">
        <div className="d-flex-c-s px24">
          <p className="py24 text-1 text-medium">
            Return details
            <span className="section-text-5 c-gray-1 text-ucase ml8">
              (
              {getProductCountText(
                returnReplacementData.returned_items_data.returned_items.length
              )}
              )
            </span>
          </p>
          {activeReturns?.[0]?.status === REJECTED_RETURN && (
            <p className="c-red-1 text-italic">
              You rejected this return request
            </p>
          )}
        </div>

        <div className="table-header-wrapper order-return-table mx12">
          <span className="text-medium">Item</span>
          <span className="text-medium">Buyer images & reason </span>
          <span className="text-medium">Refund</span>
        </div>

        <div className="order-return-table-data">
          {returnReplacementData.returned_items_data.returned_items.map(
            (el) => (
              <div className="table-data-container mx12">
                <div className="table-data-row order-return-table">
                  <div className="d-flex gap12">
                    <div>
                      <ImageLoader
                        src={el.image}
                        alt="product"
                        className="product-image-container"
                      />
                    </div>
                    <div>
                      <p className="text-2 c-black-1">{el?.name}</p>
                      <p className="text-4 mt6">
                        <p className="mb4">
                          {el.variant_size && (
                            <span className="variant-color-wrap">
                              Size:&nbsp;
                              {el.variant_size}
                            </span>
                          )}
                          {el.variant_color && (
                            <span className="variant-color-wrap">
                              {' '}
                              | Colour:&nbsp;
                              <VariantColor color={el.variant_color} />
                            </span>
                          )}
                        </p>
                      </p>
                      <p className="mt4 text-4 c-black-3">
                        SKU ID:{' '}
                        <span className="text-ucase">{el.product_sku_id}</span>
                      </p>
                      <p className="mt4 text-4 c-black-3">Qty: {el.quantity}</p>
                      <p className="mt4 text-4 c-black-3">
                        Price: {formatLocalMoney(el.selling_price)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="reason-container ">
                      <div className="d-flex gap8 mb8">
                        {el.images.map((item, index) => (
                          <ImageLoader
                            src={item}
                            alt="product"
                            className="product-image-container"
                            onClick={(e) => {
                              e.stopPropagation();
                              combineReturnShipmentImages(
                                activeReturns?.[0],
                                el.id,
                                index
                              );
                              openReturnShipmentImagesModal();
                            }}
                          />
                        ))}
                      </div>
                      <p>{el.return_reason}</p>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex ">
                      <p>- {formatLocalMoney(el.item_refund)}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className="d-flex-c-s px24 py14">
          <div className="text-1 text-medium d-flex gap12">
            Total amount to refund {isReturnCompleted && <TickThinIcon />}
          </div>
          <div className="text-1 text-medium">
            {formatLocalMoney(
              returnReplacementData.returned_items_data
                .return_item_selling_price_total
            )}
          </div>
        </div>
        {returnShipmentDetails && (
          <div className="customer-details mx24 pb24">
            <h4 className="section-text-5">Return delivery details</h4>
            <div className="d-flex-c-s mt20">
              {activeReturns?.[0]?.return_shipment.carrier_json?.logo && (
                <img
                  alt="partner logo"
                  src={activeReturns?.[0]?.return_shipment.carrier_json?.logo}
                  style={partnerImgStyle()}
                />
              )}
            </div>
            <div className="customer-details-container">
              <div className="row">
                <div className="col-md-6">
                  <p>Courier Name</p>
                  <span>
                    {returnShipmentDetails?.carrier_json?.name
                      ? `${returnShipmentDetails?.carrier_json?.name || ''} ${
                          returnShipmentDetails?.carrier_service_json?.name ||
                          ''
                        }`
                      : formatCarrierName(returnShipmentDetails?.carrier)}
                  </span>
                </div>
                <div className="col-md-6">
                  <p>Tracking ID</p>
                  <a
                    rel="noreferrer"
                    href={returnShipmentDetails?.tracking_link}
                    className={cx('text-2 text-medium', {
                      'c-purple-1 anchor-1':
                        returnShipmentDetails?.tracking_link,
                    })}
                    target="_blank"
                  >
                    <u>{returnShipmentDetails?.awb}</u>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const EditDeliveryFee = ({
  deliveryCost = 0,
  open = false,
  closeModal,
  onChangeDeliveryCost,
}) => {
  const [formValues, setFormValues] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();
  const { currencySymbol } = useCountry();
  const handleChange = (values) => {
    onChangeDeliveryCost(values);
    closeModal();
  };

  useEnterPressAction(open, () => handleChange(formValues));

  return (
    <Modal open={open} closeModal={closeModal} paperClass="info-dialog">
      <p className="section-text-4 pb24">Edit delivery fee</p>
      <Form
        onSubmit={() => {}}
        initialValues={{ deliveryCost }}
        render={({ values }) => {
          setFormValues(values);
          return (
            <div>
              <FormNumberInput
                labelText="Delivery price"
                name={deliveryCost}
                placeholder="Enter delivery price"
                showRequired
                required
                prependText={currencySymbol}
                value={values.deliveryCost}
                autoFocus
                isAllowed={(val) => {
                  const { floatValue } = val;
                  const max = 9999;
                  if (floatValue && floatValue > max) {
                    enqueueSnackbar(
                      "Delivery charge can't be greater than 9999",
                      'error'
                    );
                  }
                  return floatValue === undefined || floatValue <= max;
                }}
              />
              <div className="text-center mt24">
                <SpinnerButton
                  type="submit"
                  className="btn-primary-4"
                  onClick={() => handleChange(values)}
                >
                  Change
                </SpinnerButton>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
};

export const OrderUpdatedModal = ({ open = false, onSubmit }) => {
  useEnterPressAction(open, onSubmit);

  return (
    <Modal open={open} paperClass="info-dialog" showCloseIcon={false} maxWidth>
      <div className="text-center">
        <SuccessIcon className="mb12" />
        <h4 className="section-text-4 mb6">Order items updated!</h4>
        <p className="text-2 c-black-3">
          We have sent your items modification request to the customer. You
          should be able to see the same order under "pending orders" once the
          customer confirms the order.
        </p>

        <SpinnerButton
          type="button"
          className="btn btn-primary btn-4 mt28"
          onClick={onSubmit}
        >
          Got it
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export const DiscountedAbandonedInformation = ({ details }) => {
  const {
    abandoned_discount: abandonedDiscount = '',
    abandoned_order_payment_link: paymentLink,
    agent_email: agentEmail,
    agent_name: agentName,
    agent_phone: agentPhone,
    customer_name: customerName,
  } = details ?? {};
  return (
    <>
      <div className="divider mb24 mt16" />
      <div>
        <p className="c-gray-1 section-text-5 mb20">AGENT DETAILS</p>
        <div className="row mb16">
          <div className="col-md-6">
            <p className="mb2 section-text-5 c-black-1">Customer name</p>
            <span className="text-2 c-black-1">{customerName}</span>
          </div>
          <div className="col-md-6">
            <p className="mb2 section-text-5 c-black-1">Abandoned discount</p>
            <span className="text-2 c-black-1">{abandonedDiscount}</span>
          </div>
        </div>
        <div className="row mb16">
          <div className="col-md-6">
            <p className="mb2 section-text-5 c-black-1">Agent name</p>
            <span className="text-2 c-black-1">{agentName}</span>
          </div>
          <div className="col-md-6">
            <p className="mb2 section-text-5 c-black-1">Agent phone</p>
            <span className="text-2 c-black-1">{agentPhone}</span>
          </div>
        </div>
        <div className="row mb16">
          <div className="col-md-6">
            <p className="mb2 section-text-5 c-black-1">Agent Email</p>
            <span className="text-2 c-black-1">{agentEmail}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const getUrlParams = (orderParams) => ({
  ...orderParams,
  ...(orderParams?.return_reason
    ? {
        returnReason: Array.isArray(orderParams.return_reason)
          ? orderParams.return_reason.map((res) => Number(res))
          : [Number(orderParams.return_reason)],
      }
    : {}),
  ...(orderParams?.return_type ? { returnType: orderParams.return_type } : {}),
});

export const formattedReturnsReasonsList = (returnReason) =>
  returnReason.map((id) => Number(id));
