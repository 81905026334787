import React, { useState } from 'react';

import ConfirmModal from './ConfirmModal';
import { deleteRequest, patchRequest } from '../utils/http';
import { noop } from '../utils';
import { DeleteModalIcon, DeleteRedIcon } from '../SvgIcon';

const DeleteModal = ({
  open,
  closeModal,
  headerText,
  content,
  renderContent,
  buttonText,
  requestType,
  deleteUrl,
  deleteParams,
  options = {},
  afterDelete = noop,
  deleteAction = noop,
  deleteErrorCallback = noop,
  deletingText = 'Deleting...',
  submitting,
  confirmDisabled,
  maxWidth,
  headerClass,
  redIcon = false,
  hideIcon = true,
  confirmBtnClass = '',
}) => {
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = () => {
    if (deleteUrl) {
      setDeleting(true);
      const fn = requestType === 'patch' ? patchRequest : deleteRequest;
      fn({
        url: deleteUrl,
        data: deleteParams,
        doClean: false,
        options,
      })
        .then((res) => {
          afterDelete(res, deleteParams);
        })
        .catch((e) => {
          deleteErrorCallback(e?.data?.data?.error ?? 'Something went wrong!');
        })
        .finally(() => {
          setDeleting(false);
          closeModal();
        });
    } else {
      deleteAction();
    }
  };

  if (!open) {
    return <></>;
  }

  const SvgIcon = hideIcon ? null : redIcon ? (
    <DeleteRedIcon className="header-svg-icon" />
  ) : (
    <DeleteModalIcon className="header-svg-icon" />
  );

  return (
    <ConfirmModal
      open={open}
      onConfirm={handleDelete}
      onCancel={closeModal}
      confirmDisabled={isDeleting || submitting || confirmDisabled}
      confirmText={isDeleting || submitting ? deletingText : buttonText}
      svgIcon={SvgIcon}
      confirmBtnClass={`btn-danger-4 ${confirmBtnClass}`}
      headerText={headerText}
      content={content}
      renderContent={renderContent}
      hideCancel
      showAnimation
      maxWidth={maxWidth}
      headerClass={headerClass}
    />
  );
};

DeleteModal.defaultProps = {
  open: false,
  closeModal: noop,
  headerText: 'Confirm Deletion',
  content:
    'Do you really want to delete these records? This process cannot be undone.',
  deleteUrl: null,
  buttonText: 'Yes, delete',
  deleteParams: {},
  afterDelete: noop,
  requestType: 'delete',
};

export default DeleteModal;
