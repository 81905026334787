import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { noop } from '../../utils';
import FaqFormInput from './FaqFormInput';
import useProductFAQ from '../../hooks/useProductFAQ';
import useCategoryFAQ from '../../hooks/useCategoryFAQ';

const FaqModal = ({
  open,
  closeModal,
  values,
  categoryId,
  productID,
  setFaqData,
  faqData,
  selectedFAQId,
  clearTempValues = noop,
}) => {
  const { createProductFaqs, updateProductFaqs, fetchProductFaqs } =
    useProductFAQ();
  const { createCategoryFaqs, updateCategoryFaqs } = useCategoryFAQ();

  const editableFAQItem = faqData?.find(
    (each) => String(each.id) === String(selectedFAQId)
  );

  const createFAQData = () => {
    if (selectedFAQId) {
      if (productID) {
        const currentFAQItem = faqData?.find(
          (each) => String(each.id) === String(selectedFAQId)
        );

        const currentEditedItem = {
          ...currentFAQItem,
          question: values?.question || currentFAQItem.question,
          answer: values?.answer || currentFAQItem.answer,
        };

        updateProductFaqs(
          currentEditedItem,
          selectedFAQId,
          productID,
          closeModal
        );
      }
      if (categoryId) {
        const currentFAQItem = faqData?.find(
          (each) => String(each.id) === String(selectedFAQId)
        );
        const currentEditedItem = {
          ...currentFAQItem,
          question: values?.question || currentFAQItem.question,
          answer: values?.answer || currentFAQItem.answer,
        };
        updateCategoryFaqs(
          currentEditedItem,
          selectedFAQId,
          categoryId,
          closeModal
        );
      }

      const newFaqData = faqData?.map((each, idx) => {
        if (each.id === selectedFAQId) {
          faqData[idx].question = values.question || faqData[idx].question;
          faqData[idx].answer = values.answer || faqData[idx].answer;
        }

        return each;
      });

      setFaqData(() => {
        closeModal();
        return newFaqData;
      });
    } else {
      if (productID) {
        createProductFaqs(
          [{ question: values.question, answer: values.answer, id: uuidv4() }],
          productID,
          (updatedFAQ) => {
            setFaqData([...faqData, ...updatedFAQ.faqs_list]);
            closeModal();
          }
        );
      }
      if (categoryId) {
        createCategoryFaqs(
          [{ question: values.question, answer: values.answer, id: uuidv4() }],
          categoryId,
          (updatedFAQ) => {
            setFaqData([...faqData, ...updatedFAQ.faqs_list]);
            closeModal();
          }
        );
      }
      setFaqData(() => {
        closeModal();
        return [
          ...faqData,
          { question: values.question, answer: values.answer, id: uuidv4() },
        ];
      });

      fetchProductFaqs(productID || categoryId);
    }

    clearTempValues();
  };

  const handleDisable = (btnType = 'update') => {
    if (btnType === 'add') {
      return !editableFAQItem && (!values?.question || !values?.answer);
    }
    return (
      !(editableFAQItem?.question && values?.question) &&
      !(editableFAQItem?.answer && values?.answer)
    );
  };

  return (
    <Modal open={open} closeModal={closeModal} maxWidth="md-760">
      <section>
        <h3 className="section-text-4">
          {editableFAQItem ? 'Edit' : 'Add'} FAQ
        </h3>
        <div className="mt24">
          <FaqFormInput
            values={values}
            editableFAQItem={editableFAQItem}
            isCharBlack
          />
          <div className="d-flex-c-c mt32">
            <SpinnerButton
              className="btn-primary-4 ml16"
              onClick={createFAQData}
              disabled={
                editableFAQItem ? handleDisable('update') : handleDisable('add')
              }
            >
              {editableFAQItem ? 'Update' : 'Save'}
            </SpinnerButton>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default FaqModal;
