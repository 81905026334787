import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { FormControlLabel } from '@material-ui/core';
import SearchFilter from '../../shared/SearchFilter';
import SpinnerButton from '../../shared/SpinnerButton';
import Modal from '../../shared/Modal';
import { noop } from '../../utils';
import CustomRadio from '../../shared/CustomRadio';
import useScrollingRef from '../../hooks/useScrollingRef';
import { ellipsizeText } from '../../utils/string';
import ImageLoader from '../../shared/ImageLoader';
import CheckboxField from '../../shared/CheckboxField';
import { useCountry } from '../../context/CountryProvider';
import BounceLoader, { BounceLoaderSmall } from '../../shared/BounceLoader';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import { PRODUCT_TYPES } from '../../Catalogue/constants';
import { GrayBadge, SuccessBadge, WarningBadge } from '../../shared/Badge';

const SelectProductModal = ({
  selectedProduct,
  setSelectedProduct,
  open,
  handleClose,
  products,
  loading,
  hasMore,
  setPageNumber,
  onSearch,
  isPluginSelectProductModal,
  handleOnSelectProducts,
  saveProducts,
  tempSelectedProducts,
  canSelectedProductListBeEmpty = false,
  pageNumber,
}) => {
  const loadingElement = useScrollingRef(loading, hasMore, setPageNumber);
  const onProductModalClose = () => {
    handleClose();
    onSearch('');
  };

  useEnterPressAction(
    open,
    isPluginSelectProductModal
      ? () => saveProducts(tempSelectedProducts)
      : () => handleClose()
  );

  return (
    <Modal
      open={open}
      closeModal={onProductModalClose}
      paperClass="select-product-modal"
    >
      <h2 className="section-text-4 mb12">Select your product</h2>
      <SearchFilter
        placeholder="Search product..."
        onFilter={onSearch}
        className="mb16 select-product-search-filter"
        autoFocus
      />

      {isPluginSelectProductModal ? (
        <>
          <hr className="hr-line mb8" />
          {loading && pageNumber === 1 ? (
            <BounceLoader inline />
          ) : (
            <div className="product-list show-scrollbar px24 -m24 my0">
              <form>
                {products.length > 0 ? (
                  products.map((option, index) => (
                    <div className="product-option-container" key={option.id}>
                      <Field
                        name={`checkbox-${option.name}`}
                        key={index}
                        type="checkbox"
                      >
                        {(fieldProps) => (
                          <CheckboxField
                            {...fieldProps.input}
                            label={
                              <ProductOption key={option.id} option={option} />
                            }
                            labelPlacement="start"
                            className="d-flex full-w gap16"
                            onChange={(e) => {
                              handleOnSelectProducts(
                                {
                                  id: option.id,
                                  name: option.name,
                                  ...option,
                                },
                                e
                              );
                            }}
                            checked={tempSelectedProducts?.some(
                              (el) => el.id === option.id
                            )}
                          />
                        )}
                      </Field>
                    </div>
                  ))
                ) : (
                  <p className="d-flex align-center justify-center">
                    No products found
                  </p>
                )}
              </form>
              <div ref={loadingElement} style={{ height: 1 }} />
              {loading && hasMore && <BounceLoaderSmall inline />}
            </div>
          )}
          <hr className="hr-line mt8 mb16 " />
        </>
      ) : (
        <div className="grid-8 scrolling-modal mb24 show-scrollbar">
          {products.map((product) => (
            <ProductItem
              product={product}
              selected={product.id === selectedProduct?.id}
              onClick={() => setSelectedProduct(product)}
              key={product.id}
            />
          ))}
          {products.length === 0 && !loading && <p>No results found.</p>}
          <div ref={loadingElement} style={{ height: 1 }} />
          {loading && <BounceLoaderSmall inline />}
        </div>
      )}

      <div className="text-center mt24">
        <SpinnerButton
          type="button"
          className="btn-primary-4 cur-p"
          onClick={() =>
            isPluginSelectProductModal
              ? saveProducts(tempSelectedProducts)
              : handleClose(true)
          }
          disabled={
            !canSelectedProductListBeEmpty &&
            (isPluginSelectProductModal
              ? !tempSelectedProducts?.length
              : !selectedProduct)
          }
        >
          Select
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export const ProductItem = ({
  product = {},
  selected = false,
  onClick = noop,
}) => (
  <div
    className={cx('select-product-item d-flex align-center', { selected })}
    onClick={onClick}
  >
    <FormControlLabel
      control={<CustomRadio className="p0-i" />}
      checked={selected}
      onClick={onClick}
    />
    <span
      className={cx('text-2', {
        'c-purple-1': selected,
        'text-medium': selected,
      })}
    >
      {ellipsizeText(product.name, 40)}
    </span>
  </div>
);

export const ProductOption = ({ option }) => {
  const { formatLocalMoney } = useCountry();
  return (
    <div className="product-option" role="button" tabIndex={-1}>
      <ImageLoader
        alt={option.name}
        src={option.image}
        height={48}
        width={48}
        className="product-option-image"
      />
      <div className="product-option-desc">
        <span className="section-text-7 c-black-1">
          {ellipsizeText(option.name, 30)}
        </span>
        <span className="section-text-14 c-black-1">
          {option.product_type === PRODUCT_TYPES.BUNDLES ? (
            <GrayBadge text="Bundle" />
          ) : (
            <>
              {formatLocalMoney(option.selling_price)}
              {!!option.original_price &&
                option.original_price !== option.selling_price && (
                  <span className="original-cost">
                    {formatLocalMoney(option.original_price)}
                  </span>
                )}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default SelectProductModal;
