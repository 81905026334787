import { useState } from 'react';

import { postRequest } from '../utils/http';
import { UPLOAD_MEDIA_URL } from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';
import { filterFilesBySize } from '../utils/file';
import { noop } from '../utils';
import {
  ACCEPTED_FILE_SIZE_MB,
  ACCEPTED_IMAGE_SIZE,
  ACCEPTED_IMAGE_SIZE_MB,
  ACCEPTED_IMAGE_TYPES,
} from '../constants';
import { replaceMediaURLSegment } from '../shared/utils';

const useUploadMedia = ({
  localUpload,
  fileSize = ACCEPTED_IMAGE_SIZE, // default values set for images
  acceptTypes = ACCEPTED_IMAGE_TYPES,
}) => {
  const [uploading, setUploading] = useState(false);
  const [percentage, setPercentage] = useState(null);
  const [localFile, setLocalFile] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();

  const upload = async (
    file,
    successCallback = noop,
    failureCallback = noop,
    showSnackbar = true
  ) => {
    setPercentage(1);
    setUploading(true);
    const fd = new FormData();
    fd.append('file', file);

    const processUpload = async () => {
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          if (percent >= 99) {
            setPercentage(null);
          } else {
            setPercentage(percent);
          }
        },
      };

      // handles case of error when errors are thrown from frontend
      const inCaseOfFailure = () => {
        setPercentage(100);
        failureCallback();
        setUploading(false);
        if (localUpload) setLocalFile(null);
      };

      const isImage = ACCEPTED_IMAGE_TYPES.includes(file.type);

      // adding checks from frontend if file is of unsuspported type or file size exceeds limit if not only then post will get called
      const filteredFileBySize = filterFilesBySize([file], fileSize);
      const isAcceptedFileType =
        acceptTypes?.includes(file?.type) || acceptTypes === 'all';

      if (!isAcceptedFileType) {
        enqueueSnackbar(
          `Please upload a valid ${isImage ? 'image' : ''} file!`,
          'error'
        );
        inCaseOfFailure();
      } else if (filteredFileBySize.length === 0) {
        enqueueSnackbar(
          `File size exceeds ${
            isImage ? ACCEPTED_IMAGE_SIZE_MB : ACCEPTED_FILE_SIZE_MB
          }. Please choose a smaller file and try again.`,
          'error'
        );
        inCaseOfFailure();
      } else {
        // showSnackbar will check if the call needs the snackbar or not, in case if there is a loader already in place
        if (showSnackbar) {
          enqueueSnackbar('Uploading file!', 'info');
        }
        try {
          const resp = await postRequest({
            url: UPLOAD_MEDIA_URL,
            data: fd,
            options,
          });
          const { data } = resp; // urls consist of two keys, use cdnURL for public and file for private
          const result = {
            cdnURL: replaceMediaURLSegment(data.cdnURL),
            file: data.file,
          };
          successCallback(result);
          setUploading(false);
          return result;
        } catch (err) {
          if (err?.status === 422)
            enqueueSnackbar(`Unable to upload image!`, 'error');
          else if (err?.status === 413)
            enqueueSnackbar(
              'Image size should not be more than 10 MB',
              'error'
            );
          else
            enqueueSnackbar(
              typeof err?.data?.detail === 'string'
                ? err?.data?.detail
                : 'Something went wrong.',
              'error'
            );
          failureCallback();
          if (localUpload) setLocalFile(null);
          setUploading(false);
          return null;
        }
      }
    };

    if (localUpload) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setLocalFile(e.target.result);
      };
      reader.readAsDataURL(file);
      return processUpload();
    }
    return processUpload();
  };

  return {
    uploading,
    upload,
    percentage,
    localFile,
    setLocalFile,
  };
};

export default useUploadMedia;
