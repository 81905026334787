import { useEffect } from 'react';
import useQuery from '../hooks/useQuery';
import useFirebaseOauth from '../hooks/useFirebaseOauth';

const OneTapLogin = () => {
  const { handleAuthSuccess } = useFirebaseOauth();
  const query = useQuery();
  const jwt = query.get('token');

  useEffect(() => {
    if (jwt) {
      window.currentAuthToken = jwt;
      handleAuthSuccess({ credential: jwt }, 'google');
    }
  }, [jwt]);

  return null;
};

export default OneTapLogin;
