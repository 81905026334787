import axios from 'axios';
import moment from 'moment';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { MARK_NOTIFICATION_READ_URL, NOTIFICATIONS_LIST_URL } from '../ApiUrls';
import { getRequest, postRequest } from '../utils/http';
import { useAppContext } from './AppContext';
import { noop } from '../utils';

const REPORTS_TASK_STATUS = {
  SUCCEEDED: 'succeeded',
  PICKED: 'picked',
  QUEUED: 'queued',
  ERRORED: 'errored',
  FAILED_TO_PUBLISH: 'failed_to_publish',
};
const MAX_FILES_IN_DROPDOWN = 20;
const PAGE_SIZE = 30;
const MAX_RETRY_ATTEMPTS = 3;

const ViewNotificationsContext = createContext(null);

const ViewNotificationsProvider = ({ children }) => {
  const { business, isLoggedIn } = useAppContext();

  const [params, setParams] = useState({
    page: 1,
  });

  const [formattedNotifications, setFormattedNotifications] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [notificationsLoading, setNotificationsLoading] = useState(false);

  const fetchNotificationsCancelToken = useRef();

  const groupReports = (tasks = []) => {
    const grouped = tasks?.reduce((accumulator, task) => {
      const date = moment(task.created_at).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'DD MMM YYYY',
        sameElse: 'DD MMM YYYY',
      });
      accumulator[`${date}`] = [...(accumulator?.[`${date}`] || []), task];
      return accumulator;
    }, {});

    return grouped;
  };

  const fetchNotifications = () => {
    if (!business.uuid) return;
    setNotificationsLoading(true);
    getRequest({
      url: NOTIFICATIONS_LIST_URL(business.uuid),
      data: { ...params },
      options: { cancelToken: fetchNotificationsCancelToken?.current?.token },
    })
      .then((res) => {
        const { count, results, unread_count: unreadCount } = res;

        setNotificationsCount(count);
        setUnreadNotificationsCount(unreadCount);
        const formattedResults = groupReports(
          results?.slice(0, MAX_FILES_IN_DROPDOWN)
        );
        setFormattedNotifications(formattedResults);
        setNotifications(results);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return false;
        }
      })
      .finally(() => {
        setNotificationsLoading(false);
      });
  };

  const markNotificationRead = (
    notificationUuid,
    callback = noop,
    errorCallback = noop
  ) => {
    postRequest({
      url: MARK_NOTIFICATION_READ_URL(notificationUuid),
    })
      .then((res) => {
        callback();
        fetchNotifications();
      })
      .catch(() => {
        errorCallback();
      });
  };

  useEffect(() => {
    if (isLoggedIn) fetchNotifications();
  }, [isLoggedIn]);

  const contextValue = {
    formattedNotifications,
    pageSize: PAGE_SIZE,
    params,
    notifications,
    notificationsCount,
    unreadNotificationsCount,
    notificationsLoading,
    REPORTS_TASK_STATUS,
    MAX_RETRY_ATTEMPTS,
    markNotificationRead,
  };

  return (
    <ViewNotificationsContext.Provider value={contextValue}>
      {children}
    </ViewNotificationsContext.Provider>
  );
};

export const useViewNotifications = () => useContext(ViewNotificationsContext);

export default ViewNotificationsProvider;
