import { useState } from 'react';
import {
  CREATE_CATEGORY_FAQ_URL,
  DELETE_CATEGORY_FAQ_URL,
  GET_CATEGORY_FAQS_URL,
  UPDATE_CATEGORY_FAQ_URL,
} from '../ApiUrls';
import { useAppContext } from '../context/AppContext';

import { noop } from '../utils';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';

const useCategoryFAQ = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const { enqueueSnackbar } = useCustomSnackbar();

  const fetchCategoryFaqs = (categoryID, successCallback = noop) => {
    setLoading(true);
    getRequest({
      url: GET_CATEGORY_FAQS_URL(categoryID),
    })
      .then((res) => {
        const { data } = res;
        setFaqList(data?.faqs_list);
        successCallback(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const createCategoryFaqs = (payload, categoryID, successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: CREATE_CATEGORY_FAQ_URL(categoryID),
      data: { faqs_list: payload },
    })
      .then((res) => {
        if (res.data) {
          enqueueSnackbar(`Question added successfully`);
          successCallback(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something went wrong. Try again later.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const updateCategoryFaqs = (
    payload,
    faqID,
    categoryID,
    successCallback = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_CATEGORY_FAQ_URL(faqID),
      data: payload,
    })
      .then((res) => {
        if (res.data) {
          enqueueSnackbar(`Question updated successfully`);
          successCallback(res.data);
          fetchCategoryFaqs(categoryID);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something went wrong. Try again later.', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const deleteCategoryFaq = (faqID, categoryID, successCb = noop) => {
    deleteRequest({
      url: DELETE_CATEGORY_FAQ_URL(faqID),
    }).then(() => {
      enqueueSnackbar('FAQ deleted successfully.');
      successCb();
      fetchCategoryFaqs(categoryID);
    });
  };

  return {
    loading,
    submitting,
    faqList,
    fetchCategoryFaqs,
    createCategoryFaqs,
    deleteCategoryFaq,
    updateCategoryFaqs,
  };
};

export default useCategoryFAQ;
