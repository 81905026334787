import cx from 'classnames';
import { ProductCost } from '../../../../Catalogue/Products/AdvanceCatalogue/common';
import ImageLoader from '../../../../shared/ImageLoader';
import { addCDN } from '../../../../utils/file';
import { PRODUCT_TYPES } from '../../../../Catalogue/constants';
import { GrayBadge } from '../../../../shared/Badge';

const EachProduct = ({
  product,
  onClick,
  selected,
  childSelected,
  hasAttributes = false,
}) => {
  const classes = cx('product-option', {
    selected,
    'child-selected': childSelected,
  });

  return (
    <div
      className={classes}
      onClick={() => onClick()}
      role="button"
      tabIndex={-1}
    >
      <ImageLoader
        alt={product.label}
        src={product.image}
        height={48}
        width={48}
        className="product-option-image"
      />
      <div className="product-option-desc">
        <p className="section-text-7 line-clamp-2 word-break">{product.name}</p>
        {product.product_type === PRODUCT_TYPES.BUNDLES ? (
          <GrayBadge text="Bundle" />
        ) : (
          <>
            {!hasAttributes && (
              <div className="text-8 c-black-3 mt2">
                <ProductCost {...product} showIfOutOfStock />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EachProduct;
