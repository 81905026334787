import {
  SHIPPING_MODE_MAP,
  SHIPPING_MULTIPLE_MODE_MAP,
} from './Orders/constants';

export const SELLER_WEB_VIEWED = 'Seller_Web-Launched';
export const EVENT_OB_ONETAP_LOGIN = 'ONBOARDING_LOGIN-GOOGLE-ONETAP';
export const EVENT_OB_OTP_REQUESTED = 'Onboarding_OTP-Requested';
export const EVENT_OB_RESEND_CALL = 'Onboarding_Resend-OTP-Call';
export const EVENT_OB_RESEND_WA = 'Onboarding_Resend-OTP-Whatsapp';
export const EVENT_OB_PREFILLED_CATEGORY = 'Onboarding_PreFilled-Category';
export const EVENT_OB_MANUAL_CATEGORY = 'Onboarding_Manual-Category';
export const EVENT_OB_FINISH_BTN = 'Onboarding_Finish-Button-Click';

export const EVENT_DASH_STORE_LINK = 'Dash_Store-Link-Click';
export const EVENT_DASH_VIEW = 'Dash_Screen-View';
export const EVENT_DASH_SHARE_WA = 'Dash_Share-WhatsApp-Click';
export const EVENT_DASH_SHARE_FB = 'Dash_Share-Facebook-Click';
export const EVENT_DASH_SHARE_TWITTER = 'Dash_Share-Twitter-Click';
export const EVENT_DASH_ORDERS = 'Dash_Orders';
export const EVENT_DASH_TOTAL_SALES = 'Dash_Total-Sales';
export const EVENT_DASH_STORE_VIEWS = 'Dash_Store-Views';
export const EVENT_DASH_PRODUCT_VIEWS = 'Dash_Product-Views';
export const EVENT_DASH_DINING_ORDERS = 'Dash_Dining-Orders';
export const EVENT_DASH_STORE_OFF = 'Dash_Store-Offline';
export const EVENT_DASH_STORE_OFF_CONFIRM = 'Dash_Store-Offline-Confirm';
export const EVENT_DASH_STORE_ON = 'Dash_Store-Online';
export const EVENT_DASH_CHAT_BTN = 'Dash_Chat-Button-Click';
export const EVENT_DASH_BUSINESS_NAME_CLICK = 'Dash_Business-Name-Click';
export const EVENT_DASH_ORDERS_PENDING = 'Dash_Active-Orders-Pending';
export const EVENT_DASH_ORDERS_ACCEPTED = 'Dash_Active-Orders-Accepted';
export const EVENT_DASH_ORDERS_SHIPPED = 'Dash_Active-Orders-Shipped';
export const EVENT_DASH_ORDERS_VIEW_ALL = 'Dash_Active-Orders-View-All';

export const EVENT_PROD_ADD_NEW_PRODUCT_BTN =
  'Product_Add-New-Product-Button-Click';
export const EVENT_PROD_ADD_HOW_TO_USE =
  'Product_Add-New-Product-How-to-Use-Click';
export const EVENT_PROD_ADD_IMAGES = 'Product_Add-Images-Click';
export const EVENT_PROD_ADD_SYSTEM_GENERATED_IMAGE =
  'PRODUCT_ADD-NEW-PRODUCT_IMAGE-SYSTEM_GENERATED';
export const EVENT_PROD_ADD_SIZE = 'Product_Add-Product-Add-Size';
export const EVENT_PROD_ADD_ANOTHER_SIZE =
  'Product_Add-Product-Add-Another-Size';
export const EVENT_PROD_ADD_COLOR = 'Product_Add-Product-Add-Color';
export const EVENT_PROD_ADD_ANOTHER_COLOR =
  'Product_Add-Product-Add-Another-Color';
export const EVENT_PROD_ADD_PRODUCT_SAVE = 'Product_Add-Product-Save';
export const EVENT_PROD_ADD_NEW_CATEGORY =
  'Product_Add-Product-New-Category-Click';
export const EVENT_PROD_ADD_CREATE_CATEGORY =
  'Product_Add-Product-Create-Category-Click';
export const EVENT_PROD_ADD_LEAVE = 'Product_Add-Product-Leave-Click';

export const EVENT_PROD_BULK_ADD_BTN = 'Product_Bulk-Add-Button-Click';
export const EVENT_PROD_BULK_HOW_TO_USE = 'Product_Bulk-Add-How-to-Use-Click';
export const EVENT_PROD_BULK_TEMPLATE = 'Product_Bulk-Add-Template-Click';
export const EVENT_PROD_BULK_ADD_FILE = 'Product_Bulk-Add-File-Add-Click';
export const EVENT_PROD_BULK_REPLACE_FILE =
  'Product_Bulk-Add-File-Replace-Click';
export const EVENT_PROD_BULK_UPLOAD_FILE = 'Product_Bulk-Add-File-Upload-Click';
export const EVENT_PROD_BULK_UPLOAD_FILE_ERROR =
  'Product_Bulk-Add-File-Upload-Error';
export const EVENT_PROD_BULK_SAVE_PRODUCTS =
  'Product_Bulk-Add-Save-Product-Click';

export const EVENT_PROD_EDIT_DETAILS = 'Product_Edit-Details-Click';
export const EVENT_PROD_DELETE_BTN = 'Product_Delete-Product-Click';
export const EVENT_PROD_DELETE_CONFIRM_BTN =
  'Product_Delete-Product-Confirm-Click';
export const EVENT_PROD_OFF_HIDE = 'Product_Availability-Off-Hide';
export const EVENT_PROD_OFF_OUT_OF_STOCK =
  'Product_Availability-Off-Out-of-Stock';
export const EVENT_PROD_SHARE = 'Product_Share-Product-Click';

export const EVENT_PROD_SHOW = 'Product_Availability-On';

export const EVENT_RMC_OPEN = 'Product_Readymade_Catalog';

export const EVENT_RMC_FRUITS_CLICK = 'Product_RMC-Fruit-Click';
export const EVENT_RMC_FRUITS_ADDED = 'Product_Add-RMC-Fruits-Products-Added';

export const EVENT_RMC_GROCERY_CLICK = 'Product_RMC-Grocery-Click';
export const EVENT_RMC_GROCERY_ADDED = 'Product_Add-RMC-Grocery-Products-Added';

export const EVENT_RMC_REST_CLICK = 'Product_RMC-Restaurants-Click';
export const EVENT_RMC_REST_ADDED = 'Product_Add-RMC-Restaurant-Products-Added';

export const EVENT_RMC_CHICKEN_CLICK = 'Product_RMC-Chicken-Click';
export const EVENT_RMC_CHICKEN_ADDED = 'Product_Add-RMC-Meat-Products-Added';

export const EVENT_RMC_SAVE = 'Product_RMC-Add-Product-Save';

export const EVENT_BEP_OPEN = 'Product_BEP-Click';
export const EVENT_BEP_UPDATE = 'Product_BEP-Update';

export const EVENT_SHOW_LOW_OOS = 'Product_Show-LOW-OOS';

export const EVENT_CAT_ADD_NEW_BTN = 'Category_Add-New-Click';
export const EVENT_CAT_UPLOAD_IMAGE = 'Category_Add-New-Upload-Image';
export const EVENT_CAT_ADD_CATEGORY_BTN =
  'Category_Add-New-Add-Category-Button-Click';
export const EVENT_CAT_DELETE = 'Category_Delete-Category';
export const EVENT_CAT_UPDATE = 'Category_Update-Category';
export const EVENT_CAT_OFF_HIDE = 'Category_Availability-Off-Hide';
export const EVENT_CAT_OFF_OUT_OF_STOCK =
  'Category_Availability-Off-Out-of-Stock';
export const EVENT_CAT_ON = 'Category_Availability-On';
export const EVENT_CAT_SHARE = 'Category_Share-Category-Click';

export const EVENT_SUB_CAT_ADD_NEW_BTN = 'Subcategory_Add-New-Click';
export const EVENT_SUB_CAT_UPLOAD_IMAGE = 'Subcategory_Add-New-Upload-Image';
export const EVENT_SUB_CAT_ADD_CATEGORY_BTN =
  'Subcategory_Add-New-Add-Category-Button-Click';
export const EVENT_SUB_CAT_DELETE = 'Subcategory_Delete-Category';
export const EVENT_SUB_CAT_UPDATE = 'Subcategory_Update-Category';
export const EVENT_SUB_CAT_OFF_HIDE = 'Subcategory_Availability-Off-Hide';
export const EVENT_SUB_CAT_OFF_OUT_OF_STOCK =
  'Subcategory_Availability-Off-Out-of-Stock';
export const EVENT_SUB_CAT_ON = 'Subcategory_Availability-On';
export const EVENT_SUB_CAT_SHARE = 'Subcategory_Share-Category-Click';

export const EVENT_MARK_DESIGNS_SCREEN_VIEW = 'Marketing_Designs-Screen-View';
export const EVENT_MARK_BUSINESS_CARD_SCREEN_VIEW =
  'Marketing_BusinessCard-Screen-View';
export const EVENT_MARK_BUSINESS_CARD_SHARE = 'Marketing_BusinessCard-Share';
export const EVENT_MARK_BUSINESS_CARD_HOW_TO_USE =
  'Marketing_BusinessCard-How-to-use';
export const EVENT_MARK_PROMO_BANNER_SCREEN_VIEW =
  'Marketing_Promo-Banners-Screen-View';
export const EVENT_MARK_PROMO_BANNER_EDIT = 'Marketing_Promo-Banners-Edit-Text';
export const EVENT_MARK_PROMO_BANNER_SHARE = 'Marketing_Promo-Banners-Share';
export const EVENT_MARK_PROMO_BANNER_HOW_TO_USE =
  'Marketing_Promo-Banners-How-to-use';
export const EVENT_MARK_WA_STORY_SCREEN_VIEW =
  'Marketing_Whatsapp-Stories-Screen-View';
export const EVENT_MARK_WA_STORY_EDIT = 'Marketing_Whatsapp-Stories-Edit-Text';
export const EVENT_MARK_WA_STORY_SHARE = 'Marketing_Whatsapp-Stories-Share';
export const EVENT_MARK_WA_STORY_HOW_TO_USE =
  'Marketing_Whatsapp-Stories-How-to-use';

export const EVENT_MANAGE_PAY_SCREEN_VIEW = 'Manage_Payments-Screen-View';
export const EVENT_MANAGE_PAY_VERIFY_BTN =
  'Manage_Payments-Verify-Button-Click';
export const EVENT_MANAGE_PAY_VERIFY_SUCCESS = 'Manage_Payments-Verify-Success';
export const EVENT_MANAGE_PAY_VERIFY_FAILED = 'Manage_Payments-Verify-Failed';
export const EVENT_MANAGE_PAY_HOW_TO_USE = 'Manage_Payments-How-to-use';
export const EVENT_MANAGE_PAY_DEFAULT_BOTH =
  'Manage_Payments-Default-Method-Both';
export const EVENT_MANAGE_PAY_DEFAULT_COD =
  'Manage_Payments-Default-Method-COD';
export const EVENT_MANAGE_PAY_DEFAULT_ONLINE =
  'Manage_Payments-Default-Method-Online';
export const EVENT_MANAGE_PAY_PROFILE_CHANGE = 'Manage_Payments-Profile-Change';

export const EVENT_MANAGE_ORDER_FORM_VIEW = 'Manage_Order-From-View';
export const EVENT_MANAGE_ORDER_FORM_ADD = 'Manage_Order-From-Added';
export const EVENT_MANAGE_DOWNLOAD_INVOICE = 'Manage_IG-Download';
export const EVENT_MANAGE_SAVE_STORE_TIMING = 'Manage_Store-Timings-Save';
export const EVENT_MANAGE_DUKAAN_DELIVERY_VIEW = 'Manage_DD-Screen-View';
export const EVENT_MANAGE_DUKAAN_DELIVERY_SAVE =
  'Dukaan_Delivery-Setup-Finished';
export const EVENT_MANAGE_PROMOTIONAL_DESIGNS_DOWNLOAD =
  'Manage_Promotional-Designs-Download';

export const EVENT_COUPON_SCREEN_VIEW = 'Discount_Coupons-Screen-View';
export const EVENT_COUPON_CREATE_COUPON_CTA =
  'Discount_Coupons-Create-Coupon-CTA';
export const EVENT_COUPON_CREATE_COUPON_CREATE =
  'Discount_Coupons-Create-Coupon-Create';
export const EVENT_COUPON_SHARE = 'Discount_Coupons-Share-Coupon';
export const EVENT_COUPON_DELETE = 'Discount_Coupons-Delete-Coupon';
export const EVENT_COUPON_ON = 'Discount_Coupons-Toggle-On';
export const EVENT_COUPON_OFF = 'Discount_Coupons-Toggle-Off';
export const EVENT_COUPON_HOW_TO_USE = 'Discount_Coupons-How-to-use';

export const EVENT_ORDER_HOW_TO_USE = 'Orders_How-to-use';
export const EVENT_ORDER_DOWNLOAD_REPORT_BTN = 'Orders_Download-Report-Click';
export const EVENT_ORDER_DOWNLOAD_REPORT_PDF = 'Orders_Download-Report-PDF';
export const EVENT_ORDER_DOWNLOAD_REPORT_EXCEL = 'Orders_Download-Report-Excel';
export const EVENT_DELIVERY_DOWNLOAD_REPORT_BTN = 'Delivery-Report-Click';
export const EVENT_DELIVERY_DOWNLOAD_REPORT_PDF =
  'Delivery_Download-Report-PDF';
export const EVENT_DELIVERY_DOWNLOAD_REPORT_EXCEL =
  'Delivery_Download-Report-Excel';
export const EVENT_ORDER_VIEW_DETAILS = 'Orders_View-Details-Click';
export const EVENT_ORDER_ACCEPT_CTA = 'Orders_Accept-Order';
export const EVENT_ORDER_ACCEPT_DELIVERY_TIMING =
  'Orders_Accept-Order-Delivery-Time';
export const EVENT_ORDER_REJECT_CTA = 'Orders_Reject-Order';
export const EVENT_ORDER_SHIP_CTA = 'Orders_Ship-Order';
export const EVENT_ORDER_CANCEL_CTA = 'Orders_Cancel-Order';
export const EVENT_ORDER_DELIVERED_CTA = 'Orders_Delivered-Order';
export const EVENT_ORDER_FAILED_CTA = 'Orders_Failed-Order';
export const EVENT_ORDER_SHARE_RECEIPT = 'Orders_Share-Receipt';
export const EVENT_ORDER_NEW_NOTIFICATION = 'Order_New-Order';
export const EVENT_ORDER_REQUEST_ONLINE_PAYMENT =
  'Orders_Request-Online-Payment';
export const EVENT_ORDER_EDIT_ORDER = 'Orders_Edit-Order';
export const EVENT_ORDER_EDIT_ORDER_CONFIRMATION =
  'Orders_Edit-Order-Confirmation';
export const EVENT_ORDER_SHIP_ORDER_DUKAAN_DELIVERY =
  'Orders_Ship-Dukaan-Delivery';
export const EVENT_ORDER_ADD_WEIGHT_DUKAAN_SHIPING =
  'Orders-Ship-DD-Add-Weight';
export const EVENT_ORDER_SHIP_CONTINUE = 'Orders_Ship-Dukaan-Delivery-Continue';
export const EVENT_ORDER_SHIP_PAY = 'Orders_Ship-Dukaan-Delivery-Request';
export const EVENT_ORDER_SHIP_PAY_SUCCESS =
  'Orders_Ship-Dukaan-Delivery-Request-Success';
export const EVENT_ORDER_SHIP_PAY_FAILURE =
  'Orders_Ship-Dukaan-Delivery-Request-Fail';

export const EVENT_MANAGE_CUSTOMERS_SCREEN_VIEW =
  'Manage_My-Customers-Screen-View';
export const EVENT_MANAGE_STORE_QR_SCREEN_VIEW = 'Manage_Store-QR-Screen-View';
export const EVENT_MANAGE_STORE_QR_PRINT_PDF = 'Manage_Store_QR-Print-PDF';
export const EVENT_MANAGE_STORE_QR_HOW_TO_USE = 'Manage_Store_QR-How-to-use';
export const EVENT_MANAGE_EXTRA_CHARGES_SCREEN_VIEW =
  'Manage_Extra-Charges-Screen-View';
export const EVENT_MANAGE_EXTRA_CHARGES_GST_ON = 'Manage_Extra-Charges-GST-On';
export const EVENT_MANAGE_EXTRA_CHARGES_GST_OFF =
  'Manage_Extra-Charges-GST-Off';
export const EVENT_MANAGE_EXTRA_CHARGES_CREATE_BTN =
  'Manage_Extra-Charges-Create-Click';
export const EVENT_MANAGE_EXTRA_CHARGES_CREATE_SAVE_BTN =
  'Manage_Extra-Charges-Create-Save-Click';
export const EVENT_MANAGE_EXTRA_CHARGES_SAVE_CHANGES =
  'Manage_Extra-Charges-Save-Changes';
export const EVENT_MANAGE_EXTRA_CHARGES_HOW_TO_USE =
  'Manage_Extra-Charges-How-to-use';
export const EVENT_MANAGE_WCS_OFF = 'Manage_WCS-Toggle-Off';
export const EVENT_MANAGE_WCS_ON = 'Manage_WCS-Toggle-On';

export const EVENT_MANAGE_DINING_SCREEN_VIEW = 'Manage_Dining-Screen-View';
export const EVENT_MANAGE_DINING_ADD_TABLE = 'Manage_Dining-Add-Table-Click';
export const EVENT_MANAGE_DINING_ADD_TABLE_SAVE =
  'Manage_Dining-Add-Table-Save';
export const EVENT_MANAGE_DINING_DELETE_TABLE = 'Manage_Dining-Delete-Table';
export const EVENT_MANAGE_DINING_DOWNLOAD_QR = 'Manage_Dining-Download-QR';
export const EVENT_MANAGE_DINING_CLOSE_TABLE = 'Manage_Dining-Close-Table';
export const EVENT_MANAGE_DINING_HOW_TO_USE = 'Manage_Dining-How-to-use';

export const EVENT_MANAGE_GET_OWN_APP_VIEW = 'TOOLS_ANDROID_APP_CLICK';
export const EVENT_MANAGE_INVOICE_GEN_VIEW = 'TOOLS_INVOICE_GEN_CLICK';
export const EVENT_MANAGE_DESC_GEN_VIEW = 'TOOLS_DESC_GEN_CLICK';
export const EVENT_MANAGE_BG_REMOVER_VIEW = 'TOOLS_BG_REMOVER_CLICK';
export const EVENT_MANAGE_IMAGE_ENHANCE_VIEW = 'TOOLS_IMG_ENHANCE_CLICK';

export const EVENT_ACC_EBD_SCREEN_VIEW =
  'Account_EditBusinessDetails-Screen-View';
export const EVENT_ACC_EBD_UPLOAD_IMAGE =
  'Account_EditBusinessDetails-Upload-Image';
export const EVENT_ACC_EBD_BUSINESS_CATEGORY =
  'Account_EditBusinessDetails-Business-Category';
export const EVENT_ACC_EBD_CATEGORY_NOT_LISTED =
  'Account_EditBusinessDetails-Category-Not-Listed';
export const EVENT_ACC_EBD_CATEGORY_NOT_LISTED_CREATE =
  'Account_EditBusinessDetails-Category-Not-Listed-Create';
export const EVENT_ACC_EBD_ADDRESS_SAVE =
  'Account_EditBusinessDetails-Address-Save';
export const EVENT_ACC_EBD_DELETE_ACCOUNT =
  'Account_EditBusinessDetails-Delete-Account';
export const EVENT_ACC_EBD_SAVE_CTA = 'Account_EditBusinessDetails-Save-CTA';
export const EVENT_ACC_SIGNOUT = 'Account_Sign-Out';

export const EVENT_ACC_UPGRADE_CTA = 'Account_Subscription-Upgrade-CTA';

export const EVENT_MOBILE_OTP_VERIFIED = 'Onboarding-OTP-Verified';
export const EVENT_MOBILE_OTP_INCORRECT = 'Onboarding-OTP-Incorrect';
export const EVENT_MOBILE_OTP_FAILED = 'Onboarding-OTP-Failed';

export const EVENT_EMAIL_OTP_REQUESTED = 'Onboarding_Email-OTP-Requested';
export const EVENT_EMAIL_OTP_VERIFIED = 'Onboarding_Email-OTP-Verified';
export const EVENT_EMAIL_OTP_INCORRECT = 'Onboarding_Email-OTP-Incorrect';
export const EVENT_EMAIL_OTP_FAILED = 'Onboarding_Email-OTP-Failed';

export const EVENT_BUSINESS_PROFILE_CREATED =
  'Account_Business-Profile-Created';
export const EVENT_BUSINESS_PROFILE_CREATION_FAILED =
  'Account_Business-Profile-Creation-Failed';

export const EVENT_FIRST_PRODUCT_ADDED = 'Product_First-Product-Added';

export const EVENT_CUSTOM_DOMAIN_CTA = 'Dash_Get-Custom-Domain-CTA';
export const EVENT_PREMIUM_START_TRIAL = 'Onboarding_Trial-Start';
export const EVENT_PREMIUM_PAY_CTA = 'Premium_Pay-CTA';
export const EVENT_PREMIUM_PAY_SUCCESS = 'Premium_Pay-Success';
export const EVENT_PREMIUM_PAY_FAILURE = 'Premium_Pay-Failure';

export const EVENT_CAMPAIGN_CREATE_EMAIL =
  'Dukaan_Marketing_Create-Campaigns-Email';
export const EVENT_CAMPAIGN_CREATE_EMAIL_SUCCESS =
  'Dukaan_Marketing_Create-Campaigns-Email-Success';
export const EVENT_CAMPAIGN_CREATE = 'Dukaan_Marketing_Create-Campaigns';
export const EVENT_CAMPAIGN_DETAILS = 'Dukaan_Marketing_Campaigns-Details';
export const EVENT_CAMPAIGN_PUBLISH = 'Dukaan_Marketing_Publish-Campaigns';
export const EVENT_CAMPAIGN_PUBLISH_SUCCESS =
  'Dukaan_Marketing_Campaigns-Creation-Success';
export const EVENT_CAMPAIGN_PUBLISH_FAILURE =
  'Dukaan_Marketing_Campaigns-Creation-Failed';
export const EVENT_CAMPAIGN_CREATE_FB =
  'Dukaan_Marketing_Create-Campaigns-FBads';
export const EVENT_FBAD_PAY = 'Dukaan_Marketing_Create-Campaigns-FBads-Pay';
export const EVENT_FBAD_PAY_SUCCESS =
  'Dukaan_Marketing_Create-Campaigns-FBads-Pay-Success';
export const EVENT_CAMPAIGN_CREATE_INSTA =
  'Dukaan_Marketing_Create-Campaigns-InstaAds';
export const EVENT_INSTAAD_PAY = 'Dukaan_Marketing_Create-Campaigns-FBads-Pay';
export const EVENT_INSTAAD_PAY_SUCCESS =
  'Dukaan_Marketing_Create-Campaigns-FBads-Pay-Success';

export const EVENT_NOTIFY_STAFF_FEATURE = 'Account_Staff-Account-Notify';

/* Dukaan Delivery Single Events */
// On Ship order click from order action
export const ORDERS_SHIP_ORDER_CLICK = 'Orders_Ship-Order-Click';
// On DD options Modal
export const ORDERS_SHIP_ORDER_DD_SERVICEABLE =
  'Orders_Ship-Order-DD-Serviceable';
export const ORDERS_SHIP_ORDER_DD_UNSERVICEABLE =
  'Orders_Ship-Order-DD-Unserviceable';

/* Dukaan Delivery Multiple Events */
// On Ship multiple order click from order list page
export const ORDERS_SHIP_ORDER_MULTIPLE_CLICK =
  'Orders_Ship-Order-Multiple-Click';
// On DD options Modal
export const ORDERS_SHIP_ORDER_MULTIPLE_PARTNERS_LOADED =
  'Orders_Ship-Order-Multiple-Partners-loaded';

export const EVENT_ACCOUNT_REFERRAL = 'Account_Referral';
export const EVENT_ACCOUNT_REFERRAL_COPY = 'Account_Referral-Copy';
export const EVENT_ACCOUNT_REFERRAL_SHARE_WHATSAPP =
  'Account_Referral-Refer-Whatsapp';
export const EVENT_ACCOUNT_REFERRAL_SHARE_FACEBOOK =
  'Account_Referral-Refer-Facebook';
export const EVENT_ACCOUNT_REFERRAL_SHARE_TWITTER =
  'Account_Referral-Refer-Twitter';
export const EVENT_ACCOUNT_REFERRAL_SHARE_COPY =
  'Account_Referral-Refer-CopyCode';
export const EVENT_ACCOUNT_REFERRAL_SUCCESSFUL_REFERRALS =
  'Account_Referral-Successful Referral';
export const EVENT_SUBSCRIBE_REFERRAL_ENTERED_REFERRAL_CODE =
  'Subscribe_ReferralCode';
export const EVENT_SUBSCRIBE_REFERRAL_ENTERED_REFERRAL_CODE_VERIFY =
  'Subscribe_ReferralCode-Verify';
export const EVENT_REFER_BANNER_CLICK = 'Refer_Banner-Click';

// Upgrade plan

export const EVENT_UPGRADE_PLAN = 'Account_Subscription-Plan-Upgrade-View';
export const EVENT_UPGRADE_PLAN_UPGRADE_CTA =
  'Account_Subscription-Plan-Upgrade-Buy';
export const EVENT_UPGRADE_PLAN_PAY =
  'Account_Subscription-Plan-Upgrade-Buy-Pay';
export const EVENT_UPGRADE_PLAN_PAYMENT_SUCCESS =
  'Account_Subscription-Plan-Upgrade-Buy-Pay-Success';

// Plan Purchase

export const EVENT_ACTIVATE_PLAN = 'Account_Subscription-plan-Buy';
export const EVENT_ACTIVATE_PLAN_PAY = 'Account_Subscription-Plan-Buy-Pay';
export const EVENT_ACTIVATE_PLAN_PAYMENT_SUCCESS =
  'Account_Subscription-Plan-Buy-Pay-Success';

// touchpoints for subscription page

export const EVENT_FOOTER_ACTIVATE_SUBSCRIPTION_PAGE = 'Activate_cta-on-footer';

export const EVENT_SUBSCRIPTION_SECTION_ACTIVATE_SUBSCRIPTION_PAGE =
  'Activate_cta-on-subscription-section';

// subscription page

export const EVENT_SUBSCRIPTION_LANDING_PAGE = 'Landed_on-subscription-page';

export const EVENT_SUBSCRIPTION_SHOW_FEATURES =
  'Show_plan_features-on-subscription-page';

// Preferences
export const EVENT_PREFERENCES_VIEW = 'Account_StoreSettings_Preferences_view';

export const EVENT_STORE_NAME_ON =
  'Account_StoreSettings_Preferences_StoreNameToggle_on';
export const EVENT_STORE_NAME_OFF =
  'Account_StoreSettings_Preferences_StoreNameToggle_off';
export const EVENT_AUTO_ACCEPT_ON =
  'Account_StoreSettings_Preferences_AutoAccept_on';
export const EVENT_AUTO_ACCEPT_OFF =
  'Account_StoreSettings_Preferences_AutoAccept_off';
export const EVENT_NOTES_ON = 'Account_StoreSettings_Preferences_Notes_on';
export const EVENT_NOTES_OFF = 'Account_StoreSettings_Preferences_Notes_off';
export const EVENT_ANNOUNCE_ORDERS_ON =
  'Account_StoreSettings_Preferences_OrderAnnounce_on';
export const EVENT_ANNOUNCE_ORDERS_OFF =
  'Account_StoreSettings_Preferences_OrderAnnounce_off';
export const ADVANCE_CATALAOGUE_ON =
  'Account_StoreSettings_Preferences_AdvanceCatalogue_on';
export const ADVANCE_CATALAOGUE_OFF =
  'Account_StoreSettings_Preferences_AdvanceCatalogue_off';

// MORE_DROPDOWN
export const EVENT_STORE_SETTINGS = 'Account_StoreSettings_view';

export const QUICK_SEARCH_COUPON = 'quick_search_coupon';
export const QUICK_SEARCH_CUSTOMER = 'quick_search_customer';
export const QUICK_SEARCH_TEXT = 'quick_search';

// plugins
export const EVENT_PLUGIN_CLICK = 'MANAGE_PLUGINS-CLICK';
export const EVENT_PLUGIN_INSTALL = 'MANAGE_PLUGINS-INSTALL';
export const EVENT_PLUGIN_UNINSTALL = 'MANAGE_PLUGINS-UNINSTALL';

// Dukaan Delivery Events when warehouse setup
export const DDSET_CANCEL_ORDER = 'DDSET_CANCEL_ORDER';
export const DDSET_SHIP_ORDER = 'DDSET_SHIP_ORDER';
export const DDSET_DD_PROCEED = 'DDSET_DD_PROCEED';
export const DDSET_DP_CONTINUE = 'DDSET_DP_CONTINUE';
export const DDSET_SW_PROCEED_TO_SHIP = 'DDSET_SW_PROCEED_TO_SHIP';
export const DDSET_REQUEST_DELIVERY = 'DDSET_REQUEST_DELIVERY';
export const DDSET_REQUEST_DELIVERY_SUCCESS = 'DDSET_REQUEST_DELIVERY_SUCCESS';
export const DDSET_PAY_REQUEST_DELIVERY = 'DDSET_PAY_REQUEST_DELIVERY';
export const DDSET_PAY_REQUEST_DELIVERY_SUCCESS =
  'DDSET_PAY_REQUEST_DELIVERY_SUCCESS';

export const DDSET_INVOICE_DOWNLOAD = 'DDSET_INVOICE_DOWNLOAD';
export const DDSET_TRACKING_ID = 'DDSET_TRACKING_ID';
export const DDSET_DONE = 'DDSET_DONE';

// Dukaan Delivery Events when warehouse not setup
export const DDNOTSET_CANCEL_ORDER = 'DDNOTSET_CANCEL_ORDER';
export const DDNOTSET_SHIP_ORDER = 'DDNOTSET_SHIP_ORDER';
export const DDNOTSET_DD_PROCEED = 'DDNOTSET_DD_PROCEED';
export const DDNOTSET_DP_CONTINUE_ACTIVE = 'DDNOTSET_DP_CONTINUE_ACTIVE';
export const DDNOTSET_SW_PROCEED_TO_SHIP = 'DDNOTSET_SW_PROCEED_TO_SHIP';
export const DDNOTSET_SETDD = 'DDNOTSET_SETDD';

// Redirected warehouse events
export const DDNOTSET_HOW_IT_WORKS = 'DDNOTSET_HOW_IT_WORKS';
export const DDNOTSET_GST_ENTERED = 'DDNOTSET_GST_ENTERED';
export const DDNOTSET_CLICK_TOGGLE_ACTIVE = 'DDNOTSET_CLICK_TOGGLE_ACTIVE';
export const DDNOTSET_FINISH_SETUP = 'DDNOTSET_FINISH_SETUP';
export const DDNOTSET_POPUP_ADD_GST_NOW = 'DDNOTSET_POPUP_ADD_GST_NOW';
export const DDNOTSET_POPUP_FINISH_SETUP = 'DDNOTSET_POPUP_FINISH_SETUP';

// Add warehouse events
export const DD_AW_HOW_IT_WORKS = 'DD_AW_HOW_IT_WORKS';
export const DD_AW_GST_ENTERED = 'DD_AW_GST_ENTERED';
export const DD_AW_CLICK_TOGGLE_ACTIVE = 'DD_AW_CLICK_TOGGLE_ACTIVE';
export const DD_AW_FINISH_SETUP = 'DD_AW_FINISH_SETUP';
export const DD_AW_POPUP_ADD_GST_NOW = 'DDNOTSET_POPUP_ADD_GST_NOW';
export const DD_AW_POPUP_FINISH_SETUP = 'DDNOTSET_POPUP_FINISH_SETUP';

// Dukaan Delivery Events for multiple warehouse
export const DDSET_SM_ORDERS = 'DDSET_SM_ORDERS';

// Hyperlocal events
export const DDSET_SML_CONTINUE = 'DDSET_SML_CONTINUE';
export const DDSET_SML_PROCEED_TO_SHIP = 'DDSET_SML_PROCEED_TO_SHIP';
export const DDSET_SML_REQUEST_DELIVERY = 'DDSET_SML_REQUEST_DELIVERY';
export const DDSET_SML_REQUEST_DELIVERY_SUCCESS =
  'DDSET_SML_REQUEST_DELIVERY_SUCCESS';
export const DDSET_SML_PAY_REQUEST_DELIVERY = 'DDSET_SML_PAY_REQUEST_DELIVERY';
export const DDSET_SML_PAY_REQUEST_DELIVERY_SUCCESS =
  'DDSET_SML_PAY_REQUEST_DELIVERY_SUCCESS';

export const DDSET_SML_INVOICE_DOWNLOAD = 'DDSET_SML_INVOICE_DOWNLOAD';
export const DDSET_SML_DONE = 'DDSET_SML_DONE';

// Non-Hyperlocal events
export const DDSET_SM_CONTINUE = 'DDSET_SM_CONTINUE';
export const DDSET_SM_VIEW_ORDER_UNSERVICEABLE =
  'DDSET_SM_VIEW_ORDER_UNSERVICEABLE';
export const DDSET_SM_PROCEED_TO_SHIP = 'DDSET_SM_PROCEED_TO_SHIP';
export const DDSET_SM_REQUEST_DELIVERY = 'DDSET_SM_REQUEST_DELIVERY';
export const DDSET_SM_REQUEST_DELIVERY_SUCCESS =
  'DDSET_SM_REQUEST_DELIVERY_SUCCESS';
export const DDSET_SM_PAY_REQUEST_DELIVERY = 'DDSET_SM_PAY_REQUEST_DELIVERY';
export const DDSET_SM_PAY_REQUEST_DELIVERY_SUCCESS =
  'DDSET_SM_PAY_REQUEST_DELIVERY_SUCCESS';

export const DDSET_SM_INVOICE_DOWNLOAD = 'DDSET_SM_INVOICE_DOWNLOAD';
export const DDSET_SM_DONE = 'DDSET_SM_DONE';

export const ORDERS_SHIP_SINGLE_PROCEED = (isDukaanDelivery) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single-proceed`;

export const ORDERS_SHIP_SINGLE = (shippingMode, isDukaanDelivery) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }`;

export const ORDERS_SHIP_SINGLE_PROCEED_TO_SHIP = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }_proceed`;

export const ORDERS_SHIP_SINGLE_PAY_AND_REQUEST_DELIVERY = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }_proceed_pay`;

export const ORDERS_SHIP_SINGLE_PAYMENT_SUCCESS = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }_proceed_pay_success`;

export const ORDERS_SHIP_SINGLE_INVOICE_DOWNLOAD = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }_proceed_pay_success_invoice`;

export const ORDERS_SHIP_SINGLE_INVOICE_TRACK = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }_proceed_pay_success_invoice_track`;

export const ORDERS_SHIP_SINGLE_DONE = (shippingMode, isDukaanDelivery) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-single_${
    SHIPPING_MODE_MAP[shippingMode]
  }_proceed_pay_success_done`;

/* Single ship events when Dukaan Delivery is setup */

export const ORDERS_SHIP_DUKAAN_DELIVERY_SINGLE =
  'Orders_Ship-Dukaan-Delivery-single';
export const ORDERS_SHIP_DUKAAN_DELIVERY_SINGLE_SELF =
  'Orders_Ship-Dukaan-Delivery-single-self';

// Proceed
export const ORDERS_SHIP_DUKAAN_DELIVERY_SINGLE_SELF_PROCEED =
  'Orders_Ship-Dukaan-Delivery-single-self-proceed';

/* Single ship events when Dukaan Delivery is NOT setup */

export const ORDERS_SHIP_SELF_SINGLE = 'Orders_Ship-Self-single';
export const ORDERS_SHIP_SELF_SINGLE_PROCEED =
  'Orders_Ship-Self-single-proceed';

export const ORDERS_SHIP_MULTIPLE = (isDukaanDelivery) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple`;

export const ORDERS_SHIP_MULTIPLE_TYPE = (shippingMode, isDukaanDelivery) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple_${
    SHIPPING_MULTIPLE_MODE_MAP[shippingMode]
  }`;
export const ORDERS_SHIP_MULTIPLE_PROCEED = (shippingMode, isDukaanDelivery) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple_${
    SHIPPING_MULTIPLE_MODE_MAP[shippingMode]
  }_proceed`;

export const ORDERS_SHIP_MULTIPLE_PROCEED_PAY = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple_${
    SHIPPING_MULTIPLE_MODE_MAP[shippingMode]
  }_proceed_pay`;

export const ORDERS_SHIP_MULTIPLE_PROCEED_PAY_SUCCESS = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple_${
    SHIPPING_MULTIPLE_MODE_MAP[shippingMode]
  }_proceed_pay_success`;

export const ORDERS_SHIP_MULTIPLE_PROCEED_PAY_SUCCESS_INVOICE = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple_${
    SHIPPING_MULTIPLE_MODE_MAP[shippingMode]
  }_proceed_pay_success_invoice`;

export const ORDERS_SHIP_MULTIPLE_PROCEED_PAY_SUCCESS_DONE = (
  shippingMode,
  isDukaanDelivery
) =>
  `Orders_Ship-${isDukaanDelivery ? 'Dukaan-Delivery' : 'Self'}-multiple_${
    SHIPPING_MULTIPLE_MODE_MAP[shippingMode]
  }_proceed_pay_success_done`;

/* Multiple ship events when Dukaan Delivery is setup */
export const ORDERS_SHIP_DUKAAN_DELIVERY_MULTIPLE_PROCEED =
  'Orders_Ship-Dukaan-Delivery-multiple-proceed';

// Tracking ID
export const ORDERS_SHIP_DUKAAN_DELIVERY_MULTIPLE_RECOMMENDED_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Dukaan-Delivery-multiple_recommended_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_DUKAAN_DELIVERY_MULTIPLE_LOCAL_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Dukaan-Delivery-multiple_local_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_DUKAAN_DELIVERY_MULTIPLE_EXPRESS_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Dukaan-Delivery-multiple_express_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_DUKAAN_DELIVERY_MULTIPLE_SURFACE_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Dukaan-Delivery-multiple_surface_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_DUKAAN_DELIVERY_MULTIPLE_SURFACE_HEAVY_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Dukaan-Delivery-multiple_surface-heavy_proceed_pay_success_invoice_track';

/* Multiple ship events when Dukaan Delivery is NOT setup */
export const ORDERS_SHIP_SELF_MULTIPLE_PROCEED =
  'Orders_Ship-Self-multiple-proceed';

// Tracking ID
export const ORDERS_SHIP_SELF_MULTIPLE_RECOMMENDED_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Self-multiple_recommended_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_SELF_MULTIPLE_LOCAL_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Self-multiple_local_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_SELF_MULTIPLE_EXPRESS_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Self-multiple_express_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_SELF_MULTIPLE_SURFACE_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Self-multiple_surface_proceed_pay_success_invoice_track';
export const ORDERS_SHIP_SELF_MULTIPLE_HEAVY_PROCEED_PAY_SUCCESS_INVOICE_TRACK =
  'Orders_Ship-Self-multiple_surface-heavy_proceed_pay_success_invoice_track';

// RTO Optimiser events
// High risk
export const EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_1 =
  'Orders_Request-Online-RTO-H-1-request';
export const EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_2 =
  'Orders_Request-Online-RTO-H-2-request';
export const EVENT_ORDER_REQUEST_ONLINE_PAYMENT_HIGH_RISK_REQUEST_3 =
  'Orders_Request-Online-RTO-H-3-request';

// Medium risk
export const EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MODAL_CLICK_MEDIUM_RISK =
  'Orders_Request-Online-RTO-M-1';
export const EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_1 =
  'Orders_Request-Online-RTO-M-1-request';
export const EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_2 =
  'Orders_Request-Online-RTO-M-2-request';
export const EVENT_ORDER_REQUEST_PARTIAL_PAYMENT_MEDIUM_RISK_REQUEST_3 =
  'Orders_Request-Online-RTO-M-3-request';

// Low risk
export const EVENT_ORDER_REQUEST_PAYMENT_LOW_RISK_REQUEST =
  'Orders_Request-Online-RTO-L-request';

export const EVENT_ORDER_RTO_TOP_UP = 'Orders_RTO-Top-up';
export const EVENT_ORDER_RTO_RUN_OPTIMISER = 'Orders_RTO-run';

// Amplitude Events
export const EVENT_SECTION_NAVIGATED = 'Section_Navigated';

// Analytics Page
export const EVENT_OPEN_ANALYTICS_PAGE = 'OPEN_ANALYTICS';
export const EVENT_ANALYTICS_DASHBOARD_L2 = 'ANALYTICS_DASHBOARD_L2';
