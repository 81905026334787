import React, { createContext, useContext, useEffect, useState } from 'react';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import {
  GET_STORE_PREFERENCES,
  GET_STORE_PREFERENCES_NUDGE,
  UPDATE_STORE_PREFERENCES,
  BULK_UPDATE_STORE_PREFERENCES,
  GET_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATION_PREFERENCES,
} from '../ApiUrls';
import { noop } from '../utils';
import { SHOW_CATALOGUE_BANNER } from '../Account/Sections/constants';
import { useAppContext } from './AppContext';
import {
  AUTO_ACCEPT_ORDERS_SNACKBAR_TEXT,
  NEVER_AUTO_ACCEPT_ORDERS,
} from '../Account/constants';
import { useCountry } from './CountryProvider';

const PreferenceContext = createContext({
  isLoading: false,
  submitting: false,
  preferences: {},
  updatePreferences: noop,
  updateNotificationPreferences: noop,
  whatsappCommunicationPreferences: null,
});

export const useStorePreferences = () => useContext(PreferenceContext);

const WHATSAPP_CHANNEL_KEY = 'whatsapp';

const StorePreferenceProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [nudgeLoading, setNudgeLoading] = useState(false);
  const [preferences, setPreference] = useState({});
  const [preferencesNudge, setPreferencesNudge] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { business } = useAppContext();
  const { isInternational } = useCountry();

  const [
    whatsappCommunicationPreferences,
    setWhatsappCommunicationPreferences,
  ] = useState(null);

  const {
    business: { uuid: storeId },
    isLoggedIn,
  } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();

  const fetchPreferences = () => {
    if (!isLoggedIn) return;
    getRequest({
      url: GET_STORE_PREFERENCES,
    })
      .then((res) => {
        const prefData = res.data.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.name]: { id: curr.id, value: curr.value.value },
          }),
          {}
        );
        setPreference(prefData);
      })
      .finally(() => setLoading(false));
  };

  const fetchNotificationPreferences = () => {
    if (!isLoggedIn || isInternational) return;
    getRequest({
      url: GET_NOTIFICATION_PREFERENCES(business.uuid),
    })
      .then((res) => {
        const { results } = res;
        setWhatsappCommunicationPreferences(
          results.find((it) => it.channel === WHATSAPP_CHANNEL_KEY)
        );
      })
      .finally(() => setLoading(false));
  };

  const updateNotificationPreferences = (
    channelId,
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    patchRequest({
      url: UPDATE_NOTIFICATION_PREFERENCES(business.uuid, channelId),
      data: payload,
      doClean: false,
    })
      .then(() => {
        fetchNotificationPreferences();
        successCallback();
      })
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  const fetchPreferencesNudge = () => {
    getRequest({
      url: GET_STORE_PREFERENCES_NUDGE(storeId),
    })
      .then((res) => {
        const prefData = res.data.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.name]: { id: curr.id, value: curr.value.value },
          }),
          {}
        );
        setPreferencesNudge(prefData);
      })
      .finally(() => setNudgeLoading(false));
  };

  const hideCatalogueBanner = () => {
    patchRequest({
      url: BULK_UPDATE_STORE_PREFERENCES(storeId),
      data: {
        preferences: [
          {
            preference: preferencesNudge[SHOW_CATALOGUE_BANNER].id,
            type: 'boolean',
            value: false,
          },
        ],
      },
    }).then((res) => {
      if (res.data) {
        fetchPreferencesNudge();
      }
    });
  };

  const updatePreferences = (
    { id, value, name },
    successCallback,
    showSnackbar = true
  ) => {
    setSubmitting(true);
    postRequest({
      url: UPDATE_STORE_PREFERENCES(id),
      data: { value },
    })
      .then((res) => {
        if (res.data) {
          if (value === NEVER_AUTO_ACCEPT_ORDERS && showSnackbar) {
            enqueueSnackbar('Auto-accept order is disabled', 'default');
          } else if (AUTO_ACCEPT_ORDERS_SNACKBAR_TEXT[value] && showSnackbar) {
            enqueueSnackbar(
              `${AUTO_ACCEPT_ORDERS_SNACKBAR_TEXT[value]} orders will be auto accepted`,
              'default'
            );
          } else if (showSnackbar) {
            enqueueSnackbar(
              `${name} is ${value ? 'enabled' : 'disabled'}`,
              'default'
            );
          }
        }
        fetchPreferences();
        successCallback();
      })
      .catch(() => {
        fetchPreferences();
      })
      .finally(() => setSubmitting(false));
  };

  const bulkUpdatePreferences = (payload = [], successCallback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: BULK_UPDATE_STORE_PREFERENCES(business.uuid),
      data: { preferences: payload },
    })
      .then(() => {
        fetchPreferences();
        successCallback();
      })
      .catch(() => {
        fetchPreferences();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (isLoggedIn && storeId) {
      fetchPreferences();
      fetchPreferencesNudge();
      fetchNotificationPreferences();
    }
  }, [isLoggedIn]);

  const contextValue = {
    showNotesTimeline: true,
    whatsappCommunicationPreferences,
    isLoading,
    submitting,
    nudgeLoading,
    preferences,
    preferencesNudge,
    updatePreferences,
    bulkUpdatePreferences,
    fetchPreferences,
    hideCatalogueBanner,
    setPreference,
    updateNotificationPreferences,
  };
  return (
    <PreferenceContext.Provider value={contextValue}>
      {children}
    </PreferenceContext.Provider>
  );
};

export default StorePreferenceProvider;
