import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useAppContext } from './AppContext';
import { daysFromToday, formatDate, formatServerDate } from '../utils/date';
import {
  ACCOUNT_INVOICES_API_URL,
  GET_DOMAIN_PRICE,
  INTL_PLAN_SUBSCRIBE,
  SELLER_PAYMENT_HISTORY,
  PADDLE_INTL_SUBSCRIPTION_URL,
  POST_CONTINUE_DEFAULT_DOMAIN,
  POST_DELETE_STORES,
  STORE_PLAN_BALANCE,
  STORE_PLAN_STATS_URL,
  STORE_RENEWAL_PLAN_DETAILS,
  UPGRADE_PREMIUM_ORDER,
  STORE_PLAN_DETAILS,
  START_PREMIUM_TRIAL_URL,
  STORE_PLANS_V3_URL,
  DUE_INVOICE,
  INVOICES_LISTING,
  STORE_PLAN_FEATURES_URL,
  TRANSACTION_FEE_FOR_ORDERS_LSITING,
  CANCEL_SUBSCRIPTION_URL,
} from '../ApiUrls';
import { getRequest, postRequest, deleteRequest } from '../utils/http';
import { noop } from '../utils';
import { useCountry } from './CountryProvider';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { accountUrls, rootUrls } from '../Urls';
import useScript from '../hooks/useScript';
import {
  DEFAULT_PADDLE_VENDOR_ID,
  MOBILE_PLAN_LABEL,
  MONTHLY_PERIOD,
} from '../Subscription/constants';
import useResponsive from '../hooks/useResponsive';
import {
  isStagingEnv,
  LINK_MINK_KEY,
  TRIAL_PERIOD_DEFAULT_DURATION,
} from '../constants';
import { setItem } from '../utils/storage';
import { LIFETIME_DATE_RANGE } from '../shared/DatePicker/util';

export const ACTIVE_PLAN_NAME_KEY = 'active_plan_name';
export const SUBSCRIPTION_ACTIVE_KEY = 'active_plan_paid';
export const SUBSCRIPTION_PRICE_KEY = 'active_plan_price';
export const LINK_MINK_COMMISSION_FACTOR = 0.3; // 30% commission

const PAGE_SIZE = 30;

const DEFAULT_SUBSCRIPTION_PARAMS = {
  page: 1,
  page_size: PAGE_SIZE,
  created_at_before: formatServerDate(moment()),
  created_at_after: formatServerDate(moment().subtract(7, 'days')),
  selection: LIFETIME_DATE_RANGE.SELECTION,
};

const SubscriptionContext = createContext({
  planStats: {},
  isFreePlan: false,
  dueInvoiceData: {},
  fetchDukaanInvoicesData: noop,
  updateSubscriptionParams: noop,
  subscriptionParams: DEFAULT_SUBSCRIPTION_PARAMS,
  showSubscriptionExpiring: false,
});

export const useSubscription = () => useContext(SubscriptionContext);

const SubscriptionProvider = ({ children }) => {
  const {
    business,
    isLoggedIn,
    stores,
    switchToStore,
    isAppSumoStore,
    fetchBusinessDetails,
    isPrimaryStore,
  } = useAppContext();
  const { isInternational, currencyCode } = useCountry();
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useCustomSnackbar();

  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [loading, setLoading] = useState(false);
  const [planStats, setPlanStats] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [subscriptionPaymentHistory, setSubscriptionPaymentHistory] = useState(
    []
  );
  const [storePlanBalance, setStorePlanBalance] = useState(0);
  const [accountInvoices, setAccountInvoices] = useState({});
  const [accountInvoicesLoading, setAccountInvoicesLoading] = useState(false);
  const [dukaanInvoicesLoading, setDukaanInvoicesLoading] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [dukaanInvoices, setDukaanInvoices] = useState([]);
  const [dukaanInvoicesCount, setDukaanInvoicesCount] = useState(0);
  const [dukaanInvoicesPageNumber, setDukaanInvoicesPageNumber] = useState(1);
  const [isIntlPlanUpgraded, setIsIntlPlanUpgraded] = useState(false);
  const [deletedStoresSuccess, setDeletedStoresSuccess] = useState(false);
  const [planUpgradeLoading, setPlanUpgradeLoading] = useState(false);
  const [planDowngradeLoading, setPlanDowngradeLoading] = useState(false);
  const [paddleUpdateInfoLink, setPaddleUpdateInfoLink] = useState(null);
  const [renewalPlanDetails, setRenewalPlanDetails] = useState({});
  const [renewalDataLoading, setRenewalDataLoading] = useState(false);
  const [customDomainPrice, setCustomDomainPrice] = useState('');
  const [hideSideBarState, setHideSideBarState] = useState(false);
  const [transactionFeeData, setTransactionFeeData] = useState([]);
  const [transactionFeeDataCount, setTransactionFeeDataCount] = useState(0);
  const [transactionFeeDataPageNumber, setTransactionFeeDataPageNumber] =
    useState(1);
  const [dueInvoiceData, setDueInvoiceData] = useState({});
  const [subscriptionParams, setSubscriptionParams] = useState(
    DEFAULT_SUBSCRIPTION_PARAMS
  );
  const [subscriptionHistoryLoading, setSubscriptionHistoryLoading] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const { id: storeId, email } = business;

  const {
    id,
    plan_id,
    plan_period,
    provider,
    features: planFeatures = [],
    renewal_date: planRenewalDate,
    is_expired: isPlanExpired,
    expiry_date: planExpiryDate,
    created_at: planStartDate,
    name: activePlanName,
    on_trial: isOnTrial,
    can_start_premium_trial_web: canStartPremiumTrial,
    can_upgrade_plan: canShowUpgradePlan,
    currency: { symbol: currencySymbol } = {},
    price_data: priceData,
    subscription_status: subscriptionStatus,
    trial_period: trialPeriod = TRIAL_PERIOD_DEFAULT_DURATION,
    subscription_note: subscriptionNote,
    is_subscription_autopay: isSubscriptionAutopay,
    is_old_plan: isOldPlan,
  } = subscriptionData || {};

  const isPlanActive = subscriptionStatus === 'active';
  const activePlanPeriod = plan_period ?? priceData?.period;
  const showRenewalBufferDays = activePlanPeriod === MONTHLY_PERIOD ? 15 : 28;

  const onTrial = isOnTrial || !subscriptionData;
  const isFreePlan = subscriptionData.name?.includes('Free');
  const onSubscription = !isOnTrial && !isPlanExpired && !isFreePlan;
  const storeInactive =
    (onTrial && isPlanExpired) || (!onTrial && isPlanExpired);

  const isMobilePlan = subscriptionData.name?.includes(MOBILE_PLAN_LABEL);
  const isInfinityPlan = subscriptionData.name?.includes('Infinity');

  let localPlanName = '';
  if (isAppSumoStore) {
    localPlanName = 'AppSumo';
  } else if (onTrial) {
    localPlanName = 'Trial';
  } else {
    localPlanName = `${activePlanName} - ${activePlanPeriod}`;
  }

  setItem(ACTIVE_PLAN_NAME_KEY, localPlanName);

  const updateSubscriptionParams = (params, reason) => {
    // use reason to debug the cause for order params update
    // console.log({ params }, reason);
    if (reason === 'search' && params.search === '') return false;
    setSubscriptionParams((prev) => {
      const prevParams = prev;
      return { ...prev, ...params };
    });
  };

  const fetchStorePlanDetails = () => {
    if (!business?.uuid) return;
    setLoading(true);
    getRequest({
      url: STORE_PLAN_DETAILS(business.uuid),
    })
      .then((res) => {
        setSubscriptionData(res.data);
        fetchStorePlanBalance();
        const subscriptionActive = res.data?.subscription_status === 'active';
        const subscriptionPrice = res.data?.price_data?.price;

        setItem(SUBSCRIPTION_ACTIVE_KEY, subscriptionActive);
        setItem(SUBSCRIPTION_PRICE_KEY, subscriptionPrice);
      })
      .finally(() => setLoading(false));
  };

  const fetchDueInvoice = () => {
    if (!business?.uuid) return;
    setLoading(true);
    getRequest({
      url: DUE_INVOICE,
    })
      .then((res) => {
        setDueInvoiceData(res.data);
      })
      .finally(() => setLoading(false));
  };

  const fetchPaymentHistory = () => {
    if (!isLoggedIn) return;
    setSubscriptionHistoryLoading(true);
    let params = {
      page: pageNumber,
    };
    getRequest({
      url: SELLER_PAYMENT_HISTORY(storeId),
      data: params,
    })
      .then((res) => {
        const { count, results } = res;
        if (pageNumber === 1) {
          setHasMore(results.length < count);
          setSubscriptionPaymentHistory(results);
        } else {
          setHasMore(
            subscriptionPaymentHistory.length + results.length < count
          );
          setSubscriptionPaymentHistory((prevData) => [
            ...new Set([...prevData, ...results]),
          ]);
        }
      })
      .catch((err) => console.log(err?.data))
      .finally(() => setSubscriptionHistoryLoading(false));
  };

  const fetchPlanStats = () => {
    if (!business?.uuid) return;
    setLoading(true);
    if (isLoggedIn) {
      getRequest({
        url: STORE_PLAN_STATS_URL(business.uuid),
      })
        .then((res) => {
          if (res.status === 'success') {
            const stats = res.data;
            setPlanStats(stats);
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const intlPlanSubscribe = (planId, planPeriod) => {
    enqueueSnackbar('Payment dialog will open shortly', 'info');
    postRequest({
      url: INTL_PLAN_SUBSCRIBE,
      data: { period: planPeriod, plan_id: planId },
    }).then((resp) => {
      if (resp?.data?.order_create_event_data) {
        const {
          subscription_payment_link: paymentLink,
          dukaan_plan: { name: planName },
        } = resp.data.order_create_event_data;

        const { Paddle } = window;
        if (!Paddle) {
          window.open(paymentLink, '_blank');
          return;
        }
        Paddle.Setup({
          vendor: DEFAULT_PADDLE_VENDOR_ID,
          eventCallback: (data) => {
            if (
              data.event === 'Checkout.Complete' ||
              data.event === 'Checkout.PaymentComplete'
            ) {
              if (isMobile) {
                window.location.href = `${accountUrls.subscriptionPurchaseMobileSuccess}?plan=${planName}`;
              } else {
                window.location.href = `${accountUrls.subscriptionSuccessPath}?checkPlanStatus=true&planName=${planName} ${planPeriod}`;
              }
            }
          },
        });
        if (isStagingEnv) {
          Paddle.Environment.set('sandbox');
        }
        Paddle.Checkout.open({
          override: paymentLink, // render the paymentlink using paddle
          allowQuantity: false,
          email,
        });
      }
    });
  };

  const fetchPlanFeatures = (page = 1) => {
    if (page === 1) {
      setFeatures([]);
    }
    getRequest({
      url: STORE_PLAN_FEATURES_URL,
      data: { page_size: 50, page },
    })
      .then((res) => {
        const { results = [], next } = res;

        setFeatures((pre) => [...(pre ?? []), ...results]);
        if (next) {
          fetchPlanFeatures(page + 1);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchStorePlans = (callback = noop) => {
    setLoading(true);
    let url = `${STORE_PLANS_V3_URL}?currency_code=${
      isInternational ? 'USD' : 'INR'
    }`;
    if (isLoggedIn) {
      getRequest({
        url,
      })
        .then((res) => {
          callback(res.results);
          setPlans(res.results);
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchStorePlanBalance = () => {
    getRequest({
      url: STORE_PLAN_BALANCE,
      data: { provider: isInternational ? 'paddle' : 'razor_pay' },
    }).then((res) => {
      setStorePlanBalance(res.data?.plan_balance_amount);
      if (isInternational) {
        setPaddleUpdateInfoLink(res.data?.update_link);
      }
    });
  };

  const fetchInvoicesData = () => {
    setAccountInvoicesLoading(true);
    getRequest({
      url: ACCOUNT_INVOICES_API_URL(business.uuid),
    })
      .then((res) => {
        const { results } = res;
        setAccountInvoices({
          delivery: results,
        });
      })
      .catch((res) => {
        console.log(res.data);
      })
      .finally(() => setAccountInvoicesLoading(false));
  };

  const fetchDukaanInvoicesData = () => {
    setDukaanInvoicesLoading(true);
    const params = {
      ...subscriptionParams,
    };

    if (params?.created_at_after === '') delete params.created_at_after;
    if (params?.created_at_before === '') delete params.created_at_before;

    getRequest({
      url: INVOICES_LISTING,
      data: params,
    })
      .then((res) => {
        const { results, count } = res;
        setDukaanInvoices(results);
        setDukaanInvoicesCount(count);
      })
      .catch((res) => {
        console.log(res.data);
      })
      .finally(() => setDukaanInvoicesLoading(false));
  };

  const fetchTransactionFeeData = (
    invoiceUuid,
    transactionPageNumber,
    pageSize = 30
  ) => {
    setTransactionsLoading(true);

    const params = {
      page: transactionPageNumber ?? transactionFeeDataPageNumber,
      page_size: pageSize,
    };
    getRequest({
      url: TRANSACTION_FEE_FOR_ORDERS_LSITING(invoiceUuid),
      data: params,
    })
      .then((res) => {
        const { results, count } = res;
        setTransactionFeeData(results);
        setTransactionFeeDataCount(count);
        // set results and add pagination
      })
      .catch((res) => {
        console.log(res.data);
      })
      .finally(() => setTransactionsLoading(false));
  };

  const downgradePlan = (payload, successCallback = noop) => {
    setPlanDowngradeLoading(true);
    postRequest({
      url: UPGRADE_PREMIUM_ORDER,
      data: {
        ...payload,
      },
    })
      .then(() => {
        setIsIntlPlanUpgraded(true);
        successCallback();
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.data.data.error || 'Something went wrong',
          'error'
        );
      })
      .finally(() => setPlanDowngradeLoading(false));
  };

  const upgradeInternationalPlan = (payload) => {
    setPlanUpgradeLoading(true);
    postRequest({
      url: UPGRADE_PREMIUM_ORDER,
      data: {
        ...payload,
        provider: 'paddle',
      },
    })
      .then(() => {
        setIsIntlPlanUpgraded(true);
        window.location.href = `${
          accountUrls.subscriptionSuccessPath
        }?checkPlanStatus=true${
          payload?.customPrice && `&price=${payload?.customPrice}`
        }`;
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.data.data.error || 'Something went wrong',
          'error'
        );
      })
      .finally(() => setPlanUpgradeLoading(false));
  };

  const fetchStoreRenewalPlanDetails = () => {
    setRenewalDataLoading(true);
    getRequest({
      url: STORE_RENEWAL_PLAN_DETAILS,
    })
      .then((res) => {
        setRenewalPlanDetails({
          ...res.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => setRenewalDataLoading(false));
  };

  const postContinueDeafultDomain = () => {
    postRequest({
      url: POST_CONTINUE_DEFAULT_DOMAIN,
    })
      .then(() => {
        window.location.href = rootUrls.homePath;
      })
      .catch((err) => {
        enqueueSnackbar(err.data.data.error, 'error');
      });
  };

  const deleteStoreOnDownGrade = (payload) => {
    deleteRequest({
      url: POST_DELETE_STORES,
      data: payload,
      deleteInParams: false,
    }).then(() => {
      setDeletedStoresSuccess(true);
      enqueueSnackbar(
        `${
          payload.stores?.length > 0 ? 'Stores' : 'Store'
        } deleted successfully!`
      );
      const remainingStores = stores.filter(
        ({ uuid }) => !payload.stores.includes(uuid)
      );
      switchToStore(remainingStores[0].id);
    });
  };

  const getDomainPrice = () => {
    getRequest({
      url: GET_DOMAIN_PRICE,
    })
      .then((res) => setCustomDomainPrice(res.data.price))
      .catch((err) =>
        console.log(err?.data?.data?.error, 'custom_domain_price')
      );
  };

  const cancelSubscription = (successCallback = noop) =>
    getRequest({
      url: CANCEL_SUBSCRIPTION_URL,
    })
      .then(() => {
        enqueueSnackbar(
          'Your current plan has been cancelled successfully!',
          'info'
        );
        fetchStorePlanDetails();
        successCallback();
      })
      .catch(() => {
        enqueueSnackbar(
          'Unable to cancel subscription, please contact us for manual cancellation.',
          'error'
        );
      });

  const startStoreTrial = (cb = noop, errorCb = noop) => {
    if (!business?.uuid) return;
    setLoading(true);
    if (isLoggedIn) {
      postRequest({
        url: START_PREMIUM_TRIAL_URL,
      })
        .then((res) => {
          fetchBusinessDetails();
          cb(res);
        })
        .catch(() => {
          errorCb();
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleHideSideBar = (bool = false) => setHideSideBarState(bool);

  useEffect(() => {
    if (business.id) {
      fetchPlanStats();
      fetchStorePlanDetails();
      fetchDueInvoice();
    }
  }, [isLoggedIn, business.id]);

  useEffect(() => {
    fetchPaymentHistory();
  }, [pageNumber]);

  useScript('https://cdn.paddle.com/paddle/paddle.js');

  const daysFromExpiry = daysFromToday(planExpiryDate || planRenewalDate);

  const isApplePayProvider = provider === 'apple_pay';

  const showSubscriptionExpiring =
    isPrimaryStore &&
    daysFromExpiry <= showRenewalBufferDays &&
    daysFromExpiry > 0 &&
    !isMobile &&
    !isInternational &&
    planRenewalDate === null &&
    !isApplePayProvider;

  const contextValue = {
    storeInactive,
    isOnTrial: onTrial,
    isOnSubscription: onSubscription,
    currencySymbol,
    currencyCode,
    activePlanPeriod,
    activePlanName,
    planRenewalDate: planRenewalDate ? formatDate(planRenewalDate) : null,
    isPlanExpired,
    planStartDate,
    planExpiryDate: formatDate(planExpiryDate || planRenewalDate),
    daysFromExpiry,
    isShowRenewStrip:
      daysFromExpiry <= showRenewalBufferDays &&
      daysFromExpiry > 0 &&
      !isInternational,
    isPlanActive,
    isPlanCancelled: subscriptionStatus === 'cancelled',
    plans,
    selectedDomain,
    loading,
    activePlan: plans.find((plan) => plan.name === activePlanName) || {},
    isApplePayProvider,
    subscriptionPaymentHistory,
    planStats,
    canStartPremiumTrial,
    isFreePlan,
    isMobilePlan,
    isInfinityPlan,
    hideSidebar:
      (isOnTrial && isPlanExpired && !canStartPremiumTrial) || hideSideBarState,
    storePlanBalance,
    canShowUpgradePlan,
    accountInvoices,
    accountInvoicesLoading,
    isIntlPlanUpgraded,
    deletedStoresSuccess,
    planUpgradeLoading,
    paddleUpdateInfoLink,
    renewalPlanDetails,
    renewalDataLoading,
    trialPeriod,
    customDomainPrice,
    hideSideBarState,
    subscriptionNote,
    subscriptionHistoryLoading,
    hasMore,
    dueInvoiceData,
    dukaanInvoices,
    dukaanInvoicesCount,
    dukaanInvoicesPageNumber,
    setDukaanInvoicesPageNumber,
    dukaanInvoicesLoading,
    features,
    currentPlanId: plan_id,
    transactionFeeData,
    transactionFeeDataCount,
    transactionFeeDataPageNumber,
    setTransactionFeeDataPageNumber,
    transactionsLoading,
    subscriptionParams,
    planFeatures,
    subscriptionData,
    showRenewalBufferDays,
    showSubscriptionExpiring,
    planDowngradeLoading,
    isSubscriptionAutopay,
    isOldPlan,

    // methods
    setPageNumber,
    setSelectedDomain,
    cancelSubscription,
    fetchPlanStats,
    startStoreTrial,
    fetchStorePlans,
    fetchStorePlanDetails,
    fetchStorePlanBalance,
    fetchInvoicesData,
    upgradeInternationalPlan,
    fetchStoreRenewalPlanDetails,
    postContinueDeafultDomain,
    deleteStoreOnDownGrade,
    getDomainPrice,
    intlPlanSubscribe,
    fetchPaymentHistory,
    handleHideSideBar,
    fetchDukaanInvoicesData,
    fetchPlanFeatures,
    fetchTransactionFeeData,
    updateSubscriptionParams,
    downgradePlan,
  };
  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
