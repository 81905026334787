import cx from 'classnames';
import { useCountry } from '../../../../context/CountryProvider';
import ImageLoader from '../../../../shared/ImageLoader';
import { isColor } from '../../../../utils';

const EachSku = ({ sku = {} }) => {
  const productVariants = (sku.attributes || []).map((attribute) => {
    if (isColor(attribute.value)) return window.ntc.name(attribute.value)[1];
    return attribute.value;
  });

  const isProductOutOfStock = sku.inventory === 0;

  const { formatLocalMoney } = useCountry();

  return (
    <div className="product-option">
      <ImageLoader
        alt="sku"
        src={sku.primary_image}
        height={48}
        width={48}
        className="product-option-image"
      />
      <div className="product-option-desc">
        <p className={cx('section-text-7 label line-clamp-2 word-break')}>
          {productVariants.join(' | ')}
        </p>
        {isProductOutOfStock ? (
          <p className="c-red-1">Out of stock</p>
        ) : (
          <p className="text-8 c-black-3 mt2">
            {formatLocalMoney(sku.selling_price)}
          </p>
        )}
      </div>
    </div>
  );
};

export default EachSku;
