import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import {
  ACCEPTED_STATUS,
  ORDER_ACTION_MAPS,
  RESTAURANT_ORDER_ACTION_MAPS,
  SHIPPED_STATUS,
  REJECTED_STATUS,
  CANCELLED_STATUS,
  DELIVERED_STATUS,
  FAILED_STATUS,
  UNPAID_ORDER,
  REQUESTED_RETURN,
  REJECTED_RETURN,
  APPROVED_RETURN,
  COMPLETED_RETURN,
  ONLINE_PAYMENT_MODE,
  CASH_PAYMENT_MODE,
  PENDING_STATUS,
  MANUAL_PAYMENT_METHOD_DRAFT_STATUS,
} from './constants';
import useOrderUpdate from '../hooks/useOrderUpdate';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import OrderConfirmationModal from './OrderConfirmationModal';
import AcceptOrderModal from './AcceptOrderModal';
import { useIframeContext } from '../context/IframeProvider';
import { TrackEvent } from '../utils/analytics';
import {
  DDNOTSET_CANCEL_ORDER,
  DDNOTSET_SHIP_ORDER,
  DDSET_CANCEL_ORDER,
  DDSET_SHIP_ORDER,
  EVENT_ORDER_ACCEPT_CTA,
  EVENT_ORDER_CANCEL_CTA,
  EVENT_ORDER_DELIVERED_CTA,
  EVENT_ORDER_FAILED_CTA,
  EVENT_ORDER_REJECT_CTA,
  EVENT_ORDER_SHIP_CTA,
  ORDERS_SHIP_ORDER_CLICK,
  ORDERS_SHIP_SINGLE_PROCEED,
} from '../events';
import { useAppContext } from '../context/AppContext';
import ShipOrderModal from './ShipOrderModal';
import { rootUrls } from '../Urls';
import { useCountry } from '../context/CountryProvider';
import SpinnerButton from '../shared/SpinnerButton';
import NewShipOptionsModal from './NewShippingOptionsModal';
import ProductWeightModal from './ProductWeightModal';
import { COD_PAYMENT_METHOD } from '../Account/Sections/constants';
import { noop } from '../utils';
import useModal from '../hooks/useModal';
import ReceivePaymentModal from './ReceivePaymentModal';
import VendorShipOrderModal from './VendorShipOrderModal';
import AcceptReturnModal from './AcceptReturnModal';
import RejectReturnModal from './RejectReturnModal';
import ConfirmModal from '../shared/ConfirmModal';
import { LOYALTY_POINT_REFUND_KEY } from '../Account/constants';
import { roundTo } from '../utils/number';
import {
  AUTO_SHIP_ENABLED_STORES,
  INITIATE_REFUND_ADVANCED_ENABLED,
  UNICOMMERCE_RETURN_ENABLED_STORES,
  VINCULUM_ENABLED_STORES,
} from '../constants';
import InitiateRefundModal from './InitiateRefundModal';
import { useStoreWarehouse } from '../context/StoreWarehouseProvider';
import useUnicommerce from '../hooks/useUnicommerce';
import useVinculum from '../hooks/useVinculum';
import ManualPaymentStatusToggle from './ManualPaymentStatusToggle';

const shippoAppConfig = {
  id: '1234',
  key: 'shippo_shipping',
  hookPageType: 'order_details',
  element: 'button',
  class: 'btn-success-4',
  action_text: 'Shippo',
  action_type: 'popup',
  position: 'order-actions',
  type: 'delivery',
  icon: 'https://mydukaan.s3.ap-south-1.amazonaws.com/bg-remover/2022/5/24/1653395835366-goshippo-icon%201.png',
  help_action_text: 'How it works',
  help_redirection_link:
    'https://help.mydukaan.io/en/articles/5516681-how-to-use-dukaan-shipping',
};

const selfShipConfig = {
  id: '0',
  key: 'self_hook',
  hookPageType: 'order_details',
  element: 'button',
  class: 'btn-success-4',
  action_text: 'Self Ship',
  action_type: 'popup',
  position: 'order-actions',
  type: 'delivery',
  icon: 'https://mydukaan.s3.ap-south-1.amazonaws.com/plugins/1617965209561-Self%20Ship.png',
};

const SHIPPO_KEY = 'shippo_shipping';

const OrderActions = (props) => {
  const {
    uuid,
    orderId,
    status,
    blockedStatuses = [],
    afterOrderAction,
    isRestaurantOrder,
    isManualPaymentOrder = false,
    paymentMode,
    refetch,
    hideAction = false,
    orderShipmentObj,
    isStatusAbandoned = false,
    handleKeyboardKeys = noop,
    products,
    orderDetails,
    returnReplacementData,
    submitting,
    rejectOrderReturn,
    refundReturnOrReplacementOrder,
    reverseFwdShipmentDetails,
    refunding,
    orderShipmentDetails,
    markReplacementComplete,
    orderPayments,
    showShipingModal,
    setShowShipingModal,
    isCancelledReturnShipment = false,
    isCancelledForwardReplacementShipment = false,
    isOrderDetails = false,
    orderLoading = false,
  } = props;

  const {
    submitting: unicommerceSubmitting,
    approveReturn: approveUnicommerceReturn,
  } = useUnicommerce();

  const { submitting: vinculumSubmitting, approveVinculumReturn } =
    useVinculum();

  const { orderSeviceable, loading } = orderShipmentObj;
  const { isInternational, formatLocalMoney } = useCountry();
  const history = useHistory();

  const {
    business,
    isEnterprise,
    canCreateVendor,
    isAdmin,
    isOwner,
    isStaffL1,
    isStaffL2,
    isStaffL3,
    isManagerL1,
    isManagerL2,
  } = useAppContext();
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();
  const { id: storeId } = business || {};
  const { enqueueSnackbar } = useCustomSnackbar();
  const { isSubmitting, updateOrder, markManualOrderPayment } =
    useOrderUpdate(uuid);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInitiateRefundModal, setInitiateRefundModal] = useState(false);

  const [showPartnerWeightModal, setShowPartnerWeightModal] = useState();
  const [
    showDeliveryShipmentCreatedModal,
    setShowDeliveryShipmentCreatedModal,
  ] = useState(false);
  const {
    isOpen: isOpenReceivePaymentModal,
    openModal: openReceivePaymentModal,
    closeModal: closeReceivePaymentModal,
  } = useModal();

  const {
    isOpen: showRejectReturnModal,
    closeModal: closeRejectReturnModal,
    openModal: openRejectReturnModal,
  } = useModal();

  const {
    isOpen: showRefundModal,
    closeModal: closeRefundModal,
    openModal: openRefundModal,
  } = useModal();

  const {
    isOpen: showReturnConfirmModal,
    closeModal: closeReturnConfirmModal,
    openModal: openReturnConfirmModal,
  } = useModal();

  const {
    isOpen: showReplacementConfirmModal,
    closeModal: closeReplacementConfirmModal,
    openModal: openReplacementConfirmModal,
  } = useModal();

  const [updatedOrder, setUpdatedOrder] = useState(null);
  const { isReturnedOrReplacementExist, returnedDetails } =
    returnReplacementData;

  const showReturnAcceptedModal = !!updatedOrder;
  const closeReturnAcceptedModal = () => setUpdatedOrder(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [activeApps, setActiveApps] = useState([]);

  const closeInitiateOrderModal = () => {
    setInitiateRefundModal(false);
    setCurrentAction(null);
  };
  const { setIframe, bindCloseIframeEventOnWindow, removeIframeEvent } =
    useIframeContext();
  const iframeKey = 'OrderActions';

  const returnsDetails =
    orderDetails?.order_returns_data?.active_returns?.[0] || {};

  const isReplacementOrderExist =
    isReturnedOrReplacementExist &&
    returnReplacementData?.replaced_items_data?.replaced_items?.length > 0;

  const isReturnedOrderExist =
    isReturnedOrReplacementExist && returnedDetails?.returned_items?.length > 0;

  function onIframeClose(data) {
    if (data?.shipped) {
      refetch();
    }
  }
  useEffect(() => {
    bindCloseIframeEventOnWindow(iframeKey, onIframeClose);
    return () => removeIframeEvent(iframeKey);
  }, []);

  useEffect(() => {
    if (status === ACCEPTED_STATUS) {
      const successCB = () => {
        let activeHooks = [];
        activeHooks.push(selfShipConfig);
        if (isInternational && business?.meta?.misc_data?.shippo_token) {
          activeHooks = [{ ...shippoAppConfig }, ...activeHooks];
        }
        setActiveApps(activeHooks);
      };
      successCB();
    }
  }, [status, orderSeviceable]);

  useEffect(() => {
    if (
      showAcceptModal ||
      showConfirmModal ||
      showShipingModal ||
      showPartnerWeightModal ||
      showDeliveryShipmentCreatedModal
    )
      document.removeEventListener('keydown', handleKeyboardKeys);
    else document.addEventListener('keydown', handleKeyboardKeys);
  }, [
    handleKeyboardKeys,
    showAcceptModal,
    showConfirmModal,
    showPartnerWeightModal,
    showShipingModal,
    showDeliveryShipmentCreatedModal,
  ]);

  let actions = (
    isRestaurantOrder ? RESTAURANT_ORDER_ACTION_MAPS : ORDER_ACTION_MAPS
  )[status];

  if ((isStaffL1 || isStaffL2 || isManagerL2) && Array.isArray(actions)) {
    actions = [...actions?.filter((it) => it.showForStaff)];
  }

  if (
    !isReturnedOrReplacementExist &&
    (blockedStatuses.includes(status) || !actions || isStatusAbandoned)
  ) {
    return null;
  }

  const closeAcceptOrderModal = () => setShowAcceptModal(false);

  const handleOrder = (updateData) => {
    const { status: newStatus } = updateData;
    updateOrder(updateData, (data) => {
      if (newStatus === ACCEPTED_STATUS) {
        enqueueSnackbar('Order accepted successfully!');
        if (AUTO_SHIP_ENABLED_STORES.includes(business?.id)) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
      if (
        newStatus === SHIPPED_STATUS ||
        (currentAction && currentAction.newStatus === SHIPPED_STATUS)
      ) {
        setShowConfirmModal(false);
        closeAcceptOrderModal();
      }
      afterOrderAction({
        data,
        newStatus: newStatus || currentAction.newStatus,
      });
      if (
        currentAction &&
        currentAction.message &&
        paymentMode !== UNPAID_ORDER
      ) {
        closeInitiateOrderModal();
        setShowConfirmModal(false);
        enqueueSnackbar(currentAction.message);
      }
    });
  };

  const handleOrderEvents = (newStatus) => {
    if (newStatus === ACCEPTED_STATUS) {
      TrackEvent(EVENT_ORDER_ACCEPT_CTA, business);
    } else if (newStatus === REJECTED_STATUS) {
      TrackEvent(EVENT_ORDER_REJECT_CTA, business);
    } else if (newStatus === SHIPPED_STATUS) {
      TrackEvent(EVENT_ORDER_SHIP_CTA, business);
    } else if (newStatus === CANCELLED_STATUS) {
      TrackEvent(EVENT_ORDER_CANCEL_CTA, business);
    } else if (newStatus === DELIVERED_STATUS) {
      TrackEvent(EVENT_ORDER_DELIVERED_CTA, business);
    } else if (newStatus === FAILED_STATUS) {
      TrackEvent(EVENT_ORDER_FAILED_CTA, business);
    }
  };

  const onButtonClick = (action, otherConfig = {}) => {
    if (action.newStatus === SHIPPED_STATUS) {
      closeAcceptOrderModal();
    } else {
      handleOrderEvents(action.newStatus);
      handleOrder({ status: action.newStatus, ...otherConfig });
    }
  };

  const manualPaymentStatusChangeSuccessCb = () => {
    enqueueSnackbar('Payment status successfully updated');
    refetch();
  };

  const confirmModalProps = currentAction?.confirmation || {};
  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setCurrentAction(null);
  };

  const selfShipAction = (isTracking = false) => {
    setShowShipingModal(false);
    if (isTracking) {
      history.push(
        `${rootUrls.orderSelfShipPath.replace(
          ':uuid',
          uuid
        )}?orderId=${orderId}`
      );
    } else setShowAcceptModal(true);
  };

  const handleShipmentWeightAdded = (weight, mode, carrier, pincode) => {
    setShowPartnerWeightModal();
    history.push(
      `${rootUrls.orderDeliveryCostPath.replace(
        ':uuid',
        uuid
      )}?weight=${weight}&mode=${mode}&carrier=${carrier}&pincode=${pincode}`
    );
  };

  const deliveryShipAction = (key) => {
    const queryObj = {
      orderId,
      deliveryType: key,
    };
    if (isReturnedOrderExist) {
      queryObj.is_reverse_shipment = true;
      return history.push(
        `${rootUrls.orderReturnPickupPartnerPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryObj)}`
      );
    }
    if (
      isCancelledReturnShipment ||
      (isReplacementOrderExist &&
        returnReplacementData?.return_replacement_status <= REQUESTED_RETURN)
    ) {
      queryObj.is_reverse_shipment = true;
      return history.push(
        `${rootUrls.orderReplacementPickupPartnerPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryObj)}`
      );
    }
    if (
      (isReplacementOrderExist &&
        returnReplacementData?.return_replacement_status > REQUESTED_RETURN) ||
      isCancelledForwardReplacementShipment
    ) {
      queryObj.is_replacement_shipment = true;
      return history.push(
        `${rootUrls.orderReplacementForwardPartnerPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryObj)}`
      );
    }
    const isDukaanDelivery = key !== SHIPPO_KEY;
    if (isDukaanDelivery) {
      TrackEvent(
        ORDERS_SHIP_SINGLE_PROCEED(isActiveStoreWarehouseExist),
        business,
        {}
      );
      setShowShipingModal(false);
      history.push(
        `${rootUrls.orderDeliveryShipmentWeightPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryObj)}`
      );
    } else {
      history.push(
        `${rootUrls.orderDeliveryIntlPartnerPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryObj)}`
      );
    }
  };

  const closeShipmentModel = () => {
    setCurrentAction(null);
    setShowShipingModal(false);
  };

  const otherShipAction = (link) => {
    setShowShipingModal(false);
    setIframe(iframeKey, link.replace('{{order_id}}', uuid));
  };

  const handleBtnClick = (action, event) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentAction(action);
    if (
      !isRestaurantOrder &&
      status === ACCEPTED_STATUS &&
      action?.newStatus !== CANCELLED_STATUS
    ) {
      TrackEvent(ORDERS_SHIP_ORDER_CLICK, business);
      if (isActiveStoreWarehouseExist) TrackEvent(DDSET_SHIP_ORDER, business);
      else TrackEvent(DDNOTSET_SHIP_ORDER, business);
      setShowShipingModal(true);
    } else if (action.confirmation) {
      if (status === ACCEPTED_STATUS) {
        if (isActiveStoreWarehouseExist)
          TrackEvent(DDSET_CANCEL_ORDER, business);
        else TrackEvent(DDNOTSET_CANCEL_ORDER, business);
      }
      if (
        // Comment below line stage use only
        (INITIATE_REFUND_ADVANCED_ENABLED.includes(storeId) || isEnterprise) &&
        (isOwner || isAdmin) &&
        paymentMode === ONLINE_PAYMENT_MODE &&
        [REJECTED_STATUS, CANCELLED_STATUS, FAILED_STATUS].includes(
          action?.newStatus
        ) &&
        !(orderDetails?.payment_details?.order_refunds?.length > 0)
      ) {
        return setInitiateRefundModal(true);
      }
      setShowConfirmModal(true);
    } else if (isManualPaymentOrder && status === PENDING_STATUS) {
      handleOrder({
        status: ACCEPTED_STATUS,
        new_payment_mode: ONLINE_PAYMENT_MODE,
      });
    } else if (paymentMode === UNPAID_ORDER) {
      openReceivePaymentModal();
    } else onButtonClick(action);
  };

  const handlePaymentInUnpaidOrders = (val) => {
    handleOrder({ status: ACCEPTED_STATUS, new_payment_mode: val });
    closeReceivePaymentModal();
  };

  const getModalheadingText = () => {
    if (
      isReplacementOrderExist &&
      returnReplacementData?.return_replacement_status >= APPROVED_RETURN
    ) {
      return 'Ship replacement via...';
    }
    if (isReturnedOrderExist) return 'Request return pickup via...';
    if (isReplacementOrderExist) return 'Request replacement pickup via...';
    return 'Ship order via';
  };

  if (orderLoading) {
    return null;
  }

  return (
    <div className="order-actions d-flex-c-c">
      {(!loading || canCreateVendor) &&
        !hideAction &&
        !isReturnedOrReplacementExist &&
        !(canCreateVendor && status === SHIPPED_STATUS) && (
          <>
            {actions?.map((action, index) => (
              <SpinnerButton
                showAnimation={action.showAnimation || false}
                type="button"
                key={index}
                className={action.buttonClass}
                disabled={isSubmitting}
                onClick={(event) => handleBtnClick(action, event)}
              >
                <span className="btn-txt">{action.buttonText}</span>
              </SpinnerButton>
            ))}
            {isManualPaymentOrder &&
              status === MANUAL_PAYMENT_METHOD_DRAFT_STATUS && (
                <ManualPaymentStatusToggle
                  uuid={uuid}
                  successCallback={manualPaymentStatusChangeSuccessCb}
                />
              )}
          </>
        )}
      {returnReplacementData?.return_replacement_status ===
        REQUESTED_RETURN && (
        <>
          <SpinnerButton
            type="button"
            onClick={() => openRejectReturnModal()}
            className="btn-no-default mr6 section-text-5 c-red-1"
            disabled={refunding || submitting}
          >
            Reject
          </SpinnerButton>
          <SpinnerButton
            type="button"
            onClick={() => {
              if (VINCULUM_ENABLED_STORES.includes(storeId)) {
                approveVinculumReturn(returnsDetails?.uuid, () =>
                  window.location.reload()
                );
                return;
              }
              if (UNICOMMERCE_RETURN_ENABLED_STORES.includes(storeId)) {
                openReturnConfirmModal();
                return;
              }
              setShowShipingModal(true);
            }}
            className={cx('btn-primary-4', {
              'bg-green-1': isReturnedOrReplacementExist,
            })}
            isLoading={submitting || vinculumSubmitting}
            disabled={refunding || submitting || vinculumSubmitting}
          >
            Accept & schedule pickup
          </SpinnerButton>
        </>
      )}
      {[APPROVED_RETURN, COMPLETED_RETURN].includes(
        returnReplacementData?.return_replacement_status
      ) &&
        (isOwner || isAdmin) &&
        returnReplacementData?.refund_amount > 0 &&
        !returnReplacementData?.refund_status &&
        !(orderDetails?.payment_details?.order_refunds?.length > 0) && (
          <SpinnerButton
            type="button"
            onClick={() => {
              setInitiateRefundModal(true);
            }}
            className="btn-outline-7 text-medium"
            isLoading={refunding}
            disabled={refunding || submitting}
          >
            Initiate refund
          </SpinnerButton>
        )}
      {isReplacementOrderExist &&
        [APPROVED_RETURN].includes(
          returnReplacementData?.return_replacement_status
        ) &&
        !reverseFwdShipmentDetails &&
        !orderShipmentDetails?.easyecom_replacement_shipped &&
        !orderShipmentDetails?.manual_replacement_created && (
          <>
            {INITIATE_REFUND_ADVANCED_ENABLED.includes(business.id) ? (
              <SpinnerButton
                type="button"
                onClick={() => {
                  const customer = {
                    ...orderDetails.address,
                    ...orderDetails.address?.buyer,
                    id: orderDetails.store_lead_id,
                    buyer_id: orderDetails.buyer,
                    address_line: `${orderDetails?.address?.line} ${orderDetails?.address?.line_1}`,
                  };
                  delete customer.buyer; // extra key, creates problem in validate cart

                  history.push(rootUrls.createOrder, {
                    isReplacementOrder: true,
                    originalOrderUuid: orderDetails.uuid,
                    orderId,
                    products:
                      returnReplacementData?.replaced_items_data?.replaced_items.map(
                        (item) => item.replacement_item
                      ) ?? [],
                    customer,
                  });
                }}
                className="btn-warning-4 ml16"
                isLoading={submitting}
                disabled={refunding || submitting}
              >
                Create replacement order
              </SpinnerButton>
            ) : (
              <SpinnerButton
                type="button"
                onClick={() => {
                  setShowShipingModal(true);
                }}
                className="btn-warning-4 ml16"
                isLoading={submitting}
                disabled={refunding || submitting}
              >
                Ship replacement
              </SpinnerButton>
            )}
          </>
        )}
      {isReplacementOrderExist &&
        [APPROVED_RETURN].includes(
          returnReplacementData?.return_replacement_status
        ) &&
        !reverseFwdShipmentDetails &&
        INITIATE_REFUND_ADVANCED_ENABLED.includes(business.id) &&
        orderShipmentDetails?.manual_replacement_created && (
          <SpinnerButton
            type="button"
            onClick={() =>
              markReplacementComplete(returnsDetails?.uuid, refetch)
            }
            className="btn-success-4 ml16"
            isLoading={submitting}
            disabled={refunding || submitting}
          >
            Mark replacement complete
          </SpinnerButton>
        )}
      <InitiateRefundModal
        submitting={submitting}
        open={showInitiateRefundModal}
        closeModal={closeInitiateOrderModal}
        onSubmit={(config = {}) => {
          if (isReturnedOrderExist) {
            // return flow
            const payload = {};
            if (config.amount_to_refund) {
              payload.amount_to_refund = config.amount_to_refund;
            }
            refundReturnOrReplacementOrder(
              returnsDetails?.uuid,
              () => {
                closeInitiateOrderModal();
                setTimeout(() => window.location.reload(), 3000);
              },
              payload
            );
          } else {
            // cancel flow
            onButtonClick(currentAction, config);
          }
        }}
        totalCost={orderDetails?.total_cost}
        orderPayments={orderPayments}
        preferredDestination={returnReplacementData?.refund_preference}
        refundAmount={returnReplacementData?.refund_amount}
        showRefundMode={!canCreateVendor && !isReturnedOrderExist}
      />
      {showReturnAcceptedModal && (
        <AcceptReturnModal
          open={showReturnAcceptedModal}
          closeModal={closeReturnAcceptedModal}
          updatedOrder={updatedOrder}
        />
      )}
      {showRejectReturnModal && (
        <RejectReturnModal
          isReplacementOrderExist={isReplacementOrderExist}
          open={showRejectReturnModal}
          closeModal={closeRejectReturnModal}
          onSubmit={() =>
            rejectOrderReturn(REJECTED_RETURN, () => {
              enqueueSnackbar(
                `${
                  isReplacementOrderExist ? 'Replacement' : 'Return'
                } request rejected`
              );
              closeRejectReturnModal();
              setTimeout(() => window.location.reload(), 1000);
            })
          }
        />
      )}
      <AcceptOrderModal
        disabled={isSubmitting}
        open={showAcceptModal}
        handleClose={closeAcceptOrderModal}
        handleConfirm={handleOrder}
      />
      <OrderConfirmationModal
        open={showConfirmModal}
        closeModal={closeConfirmModal}
        handleConfirm={() => onButtonClick(currentAction)}
        headerText={confirmModalProps.header}
        content={confirmModalProps.content}
        icon={confirmModalProps.icon}
        confirmText={confirmModalProps.confirmText}
        confirmBtnClass={confirmModalProps.confirmBtnClass}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
      {/* // When vendor is not there, On ship show this modal */}
      {showShipingModal && !canCreateVendor && (
        <NewShipOptionsModal
          open={showShipingModal}
          plugins={activeApps}
          orderId={orderId}
          closeModal={closeShipmentModel}
          selfShipAction={selfShipAction}
          deliveryShipAction={deliveryShipAction}
          otherShipAction={otherShipAction}
          orderShipmentObj={orderShipmentObj}
          isCodOrder={paymentMode === COD_PAYMENT_METHOD}
          handleKeyboardKeys={handleKeyboardKeys}
          title={getModalheadingText()}
          isReturnedOrderExist={isReturnedOrderExist}
          isOrderDetails={isOrderDetails}
        />
      )}
      {isOpenReceivePaymentModal && (
        <ReceivePaymentModal
          open={isOpenReceivePaymentModal}
          closeModal={closeReceivePaymentModal}
          onButtonClick={handlePaymentInUnpaidOrders}
          isLoading={isSubmitting}
        />
      )}
      {/* // When vendor is there, On ship show this modal */}
      {showShipingModal && canCreateVendor && (
        <VendorShipOrderModal
          open={showShipingModal}
          products={products}
          orderId={orderId}
          closeModal={closeShipmentModel}
          orderDetails={orderDetails}
        />
      )}
      <ProductWeightModal
        open={showPartnerWeightModal}
        products={products}
        orderId={orderId}
        closeModal={() => setShowPartnerWeightModal()}
        handleShip={handleShipmentWeightAdded}
        orderShipmentObj={orderShipmentObj}
      />
      {showDeliveryShipmentCreatedModal && (
        <ShipOrderModal
          open={showDeliveryShipmentCreatedModal}
          closeModal={() => setShowDeliveryShipmentCreatedModal(false)}
        />
      )}
      {showRefundModal && (
        <ConfirmModal
          open={showRefundModal}
          onCancel={closeRefundModal}
          onConfirm={() =>
            refundReturnOrReplacementOrder(returnsDetails?.uuid, () => {
              closeRefundModal();
              setTimeout(() => window.location.reload(), 3000);
            })
          }
          confirmBtnClass="btn-success-4 mt8"
          confirmText="Refund customer"
          headerText={
            <span className="bold-text-48">
              {returnReplacementData?.refund_preference ===
              LOYALTY_POINT_REFUND_KEY
                ? roundTo(returnReplacementData?.refund_amount || 0)
                : formatLocalMoney(returnReplacementData?.refund_amount)}
            </span>
          }
          content={
            <span className="section-text-7">
              {returnReplacementData?.refund_preference ===
              LOYALTY_POINT_REFUND_KEY
                ? 'Loyalty points will be sent to the customer.'
                : 'To be refunded into customer’s source account.'}
            </span>
          }
          hideCancel
          showAnimation
          maxWidth="xm"
          loading={refunding}
          showCloseIcon={!refunding}
        />
      )}
      {showReturnConfirmModal && (
        <ConfirmModal
          open={showReturnConfirmModal}
          onCancel={closeReturnConfirmModal}
          onConfirm={() =>
            approveUnicommerceReturn(returnsDetails?.uuid, () => {
              enqueueSnackbar(
                `Order ${
                  isReturnedOrderExist ? 'return' : 'replacement'
                } accepted & scheduled pickup successfully.`
              );
              closeReturnConfirmModal();
              setTimeout(() => window.location.reload(), 3000);
            })
          }
          confirmBtnClass="btn-success-4 mt8"
          confirmText="Yes, confirm"
          headerText={
            <span>
              Confirm{' '}
              {isReturnedOrderExist ? 'pickup return' : 'pickup replacement'}
            </span>
          }
          content={
            <span className="section-text-7">
              Are you sure that you want to{' '}
              {isReturnedOrderExist
                ? 'pickup return'
                : 'pickup replacement for'}{' '}
              the order?
            </span>
          }
          hideCancel
          showAnimation
          maxWidth="xm"
          loading={unicommerceSubmitting}
          showCloseIcon={!unicommerceSubmitting}
        />
      )}

      {showReplacementConfirmModal && (
        <ConfirmModal
          open={showReplacementConfirmModal}
          onCancel={closeReplacementConfirmModal}
          onConfirm={() =>
            shipOrderUsingEasyEcom(false, returnsDetails?.uuid, () => {
              enqueueSnackbar(`Order shipped replacement successfully.`);
              closeReplacementConfirmModal();
              setTimeout(() => window.location.reload(), 3000);
            })
          }
          confirmBtnClass="btn-success-4 mt8"
          confirmText="Yes, confirm"
          headerText={<span>Confirm ship replacement</span>}
          content={
            <span className="section-text-7">
              Are you sure that you want to ship replacement for the order?
            </span>
          }
          hideCancel
          showAnimation
          maxWidth="xm"
          loading={easyEcomSubmitting}
          showCloseIcon={!easyEcomSubmitting}
        />
      )}
    </div>
  );
};

export default OrderActions;
