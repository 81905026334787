import React from 'react';
import cx from 'classnames';
import { FileIcon, VideoCameraIcon } from '../SvgIcon';
import { getExtensionFromFileName } from '../utils/file';
import { VIDEO_FILE_EXTENSION } from '../Account/Sections/CategoryAttributes/constants';

const FileUploadManager = ({
  name = '',
  productAttributeFileType = false,
  isFileAttribute = false,
}) => {
  const fileType = getExtensionFromFileName(name, [...VIDEO_FILE_EXTENSION]);

  const fileTypeIcon = () => {
    if (VIDEO_FILE_EXTENSION.includes(fileType)) return <VideoCameraIcon />;
    return <FileIcon />;
  };

  return (
    <div>
      {isFileAttribute ? (
        <div className="product-file-attribute-icon">{fileTypeIcon()}</div>
      ) : (
        <div
          className={cx(
            'd-flex flex-column align-center justify-center w-100 file-upload',
            { 'product-file-upload': productAttributeFileType }
          )}
        >
          {fileTypeIcon()}
          <p className="section-text-13 mt12 text-ellipsis w-100 text-center pl12 pr6">
            {name}
          </p>
        </div>
      )}
    </div>
  );
};

export default FileUploadManager;
