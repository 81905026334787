import { storeAPIUrl } from '../helper';

export const SAVE_STRIPE_PG_KEY_API_ENDPOINT = storeAPIUrl(
  'save-stripe-pg-key/'
);

export const SAVE_PAYPAL_PG_KEY_API_ENDPOINT = storeAPIUrl(
  'save-paypal-pg-key/'
);

export const SAVE_RAZORPAY_PG_KEY_API_ENDPOINT = (uuid) =>
  storeAPIUrl(`${uuid}/save-razor-pay-pg-key/`);

export const CREATE_RAZORPAY_OAUTH_FLOW_ENDPOINT = (uuid) =>
  storeAPIUrl(`${uuid}/create-razor-pay-oauth-flow/`);
