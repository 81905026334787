import { FormControlLabel, RadioGroup, Tooltip } from '@material-ui/core';
import React from 'react';
import CustomRadio from '../../../shared/CustomRadio';
import { GrayInfoIcon } from '../../../SvgIcon';
import { BUNDLE_DISCOUNT_TYPE } from '../constants';

const BundleDiscountRadioGroup = ({ form, values }) => (
  <>
    <p className="text-8 c-black-3 mb8">Discount Type</p>
    <RadioGroup
      name="product_bundle_discount_type"
      className="d-flex flex-row"
      onChange={({ target: { name, value } }) => {
        form.mutators.modifyFormField(name, value);
        form.mutators.modifyFormField('product_bundle_discount_value', '');
      }}
      defaultValue={BUNDLE_DISCOUNT_TYPE.FLAT_DISCOUNT}
      value={values.product_bundle_discount_type}
    >
      <FormControlLabel
        control={<CustomRadio className="p0-i" />}
        label={
          <div className="d-flex align-center">
            <span>Flat price</span>

            <Tooltip
              title="The entire bundle will be sold for this price."
              placement="bottom"
              enterDelay={0}
            >
              <GrayInfoIcon height={16} width={16} className="ml6" />
            </Tooltip>
          </div>
        }
        className="radio-item"
        value={BUNDLE_DISCOUNT_TYPE.FLAT_DISCOUNT}
        checked={
          values.product_bundle_discount_type ===
          BUNDLE_DISCOUNT_TYPE.FLAT_DISCOUNT
        }
      />
      <FormControlLabel
        control={<CustomRadio className="p0-i" />}
        label={
          <div className="d-flex align-center">
            <span>Percentage off</span>

            <Tooltip
              title="This percentage will be deducted from the total cost of the individual items in the bundle."
              placement="bottom"
              enterDelay={0}
            >
              <GrayInfoIcon height={16} width={16} className="ml6" />
            </Tooltip>
          </div>
        }
        className="mr24 radio-item"
        value={BUNDLE_DISCOUNT_TYPE.PERCENT_DISCOUNT}
        checked={
          values.product_bundle_discount_type ===
          BUNDLE_DISCOUNT_TYPE.PERCENT_DISCOUNT
        }
      />
    </RadioGroup>
  </>
);

export default BundleDiscountRadioGroup;
