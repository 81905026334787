import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import { useAppContext } from '../context/AppContext';
import { authUrls, rootUrls } from '../Urls';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import { parseJwt } from '../utils/string';
import { setItem } from '../utils/storage';
import { SELECTED_STORE_ID_KEY } from '../context/AppProvider';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { SELECTED_ENVIRONMENT_KEY } from '../constants';

const Token = () => {
  const { setUser } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const history = useHistory();
  const query = useQuery();
  const jwt = query.get('jwt');
  const redirectUrl = query.get('redirectUrl') || rootUrls.homePath;

  useEffect(() => {
    if (jwt) {
      window.currentAuthToken = jwt;
      document.title = 'Logging in to Dukaan';

      // const env = localStorage.getItem(SELECTED_ENVIRONMENT_KEY);
      // localStorage.clear();
      // localStorage.setItem(SELECTED_ENVIRONMENT_KEY, env);

      try {
        // to check if token is valid
        const tokenData = parseJwt(jwt);
        if (new Date(tokenData.exp * 1000) <= new Date()) {
          enqueueSnackbar(
            'Your session is expired. Please login again.',
            'error'
          );
          history.push(authUrls.loginPath);
          return;
        }
        setItem(SELECTED_STORE_ID_KEY, tokenData.store_ids[0]);
        setUser({ access_token: jwt }, {}, () => {
          if (tokenData.multiple_stores) {
            history.push(authUrls.selectStorePath);
          } else {
            history.push(redirectUrl);
          }
        });
      } catch (e) {
        history.replace(redirectUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  return <BounceLoaderContainer isLoading />;
};

export default Token;
