import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { modifyFormField, noop } from '../utils';
import { FormNumberInput } from '../shared/Form';
import { WarningExclaimationIcon } from '../SvgIcon';
import { validateNumber } from '../shared/Form/Validation';
import ImageLoader from '../shared/ImageLoader';
import { changeCDNRes } from '../utils/file';
import { CURRENCY_SYMBOL, PLACEHOLDER_IMAGE } from '../constants';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { SizeColor } from './common';
import { truncateString } from '../utils/string';
import { useCountry } from '../context/CountryProvider';

const CancelAndRefundLineItem = ({
  open,
  closeModal,
  submitting = false,
  onSubmit = noop,
  lineItemIds = [],
  productsLineItemObj = {},
}) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { formatLocalMoney } = useCountry();
  const [initialState, setInitialState] = useState({});

  const getTotalRefundValue = (values) =>
    values?.reduce((sum, each) => sum + Number(each.refund_amount ?? 0), 0);

  useEffect(() => {
    if (open) {
      setInitialState({
        lineItems: lineItemIds?.map((each) => {
          const lineItemObj = productsLineItemObj[each];
          return {
            line_item: lineItemObj.line_item_uuid,
            line_item_id: each,
            refund_amount:
              lineItemObj.line_item_total_cost || lineItemObj.selling_price,
            max_refund_amount:
              lineItemObj.line_item_total_cost || lineItemObj.selling_price,
          };
        }),
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      className="shipment-created-modal new-shipment-modal"
      closeModal={closeModal}
      showCloseIcon={!submitting}
    >
      <div className="cancel-refund-modal">
        <div className="d-flex-c-c flex-column">
          <WarningExclaimationIcon />
          <h5 className="section-text-4 mt12 mb8">Cancel & Refund</h5>
          <p className="c-black-3 text-2 mb24">
            Once you cancel the order item, you cannot ship it anymore.
          </p>
        </div>
        <Form
          keepDirtyOnReinitialize
          mutators={{ ...arrayMutators, modifyFormField }}
          onSubmit={onSubmit}
          initialValues={initialState}
          render={({ handleSubmit, values }) => (
            <div>
              <div className="d-flex-c-s bg-white-6 py10 pl8 pr20 mx-8 br4">
                <p className="c-black-3 text-medium">Order item</p>
                <p className="c-black-3 text-medium">Refund amount</p>
              </div>
              <div className="mb12">
                {values?.lineItems?.map((lineItem, index) => {
                  const lineItemObj =
                    productsLineItemObj[lineItem.line_item_id];
                  const {
                    image,
                    name,
                    variant_color: color,
                    attributes,
                    sku: skuId,
                  } = lineItemObj;
                  return (
                    <div className="d-flex-c-s order-product py12">
                      <div className="d-flex align-center">
                        <ImageLoader
                          fallbackImage={PLACEHOLDER_IMAGE}
                          src={changeCDNRes(image)}
                          width={48}
                          height={48}
                          alt={name}
                        />
                        <div className="ml16 c-black-3">
                          <p className="text-2 c-black-1">
                            {truncateString(name, 30)}
                          </p>
                          <SizeColor
                            attributes={attributes}
                            color={color}
                            className="mt4"
                          />
                          {skuId && (
                            <p className="mt4 text-4 c-black-3">
                              SKU ID: {truncateString(skuId, 30)}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="refund-input">
                        <FormNumberInput
                          labelText=""
                          name={`lineItems.${index}.refund_amount`}
                          initialValue={lineItem?.refund_amount}
                          prependText={CURRENCY_SYMBOL}
                          validate={validateNumber(
                            0,
                            lineItem.max_refund_amount
                          )}
                          isAllowed={(val) => {
                            const { floatValue } = val;
                            if (
                              floatValue &&
                              floatValue > lineItem.max_refund_amount
                            ) {
                              enqueueSnackbar(
                                `Entered amount can't be greater than ${lineItem.max_refund_amount}`,
                                'error'
                              );
                              return false;
                            }
                            return floatValue === undefined || floatValue >= 0;
                          }}
                          required
                          showRequired
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <hr className="hr-line mb20 mx-0" />

              <div className="d-flex-c-s mb32">
                <p className="text-2 text-medium">Total amount to refund</p>
                <p className="text-2 text-medium">
                  {formatLocalMoney(
                    getTotalRefundValue(values?.lineItems ?? [])
                  )}
                </p>
              </div>

              <div className="d-flex-c-c">
                <SpinnerButton
                  showAnimation
                  type="button"
                  className="btn btn-primary-4"
                  isLoading={submitting}
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  Cancel item & Refund customer
                </SpinnerButton>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

export default CancelAndRefundLineItem;
