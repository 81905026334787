import React, { useState, createContext, useContext } from 'react';
import {
  GET_ACTIVE_THEME,
  GET_STORE_THEMES,
  NEWSLETTER_APP_CONFIG_URL,
  PURCHASE_INTERNATIONAL_THEME,
  PURCHASE_THEME,
  UPDATE_STORE_THEME,
} from '../ApiUrls';
import { deepClone } from '../Appearance/utils';
import { isStagingEnv } from '../constants';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useScript from '../hooks/useScript';
import { DEFAULT_PADDLE_VENDOR_ID } from '../Subscription/constants';
import {
  D2C_APPEARENCE_THEME,
  LEO,
  LIGHTSPEED_APPEARENCE_THEME,
  MATRIX_THEME,
  getThemeConfig,
  MANA_THEME,
  LUXURY,
  URSA,
} from '../Themes/constants';
import { noop } from '../utils';
import { getRequest, postRequest } from '../utils/http';
import { useAppContext } from './AppContext';

const ThemeContext = createContext({
  loading: false,
  submitting: false,
  themes: [],
  activeTheme: {},
  purchaseInternationalStoreTheme: noop,
  isProductImageAspectRatioDifferent: false,
  activeThemeKey: '',
});

const initialNewsletterConfig = {
  isInstalled: false,
  isConnected: false,
};

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({ children }) => {
  const { business, userDetails = {}, isEnterprise } = useAppContext();
  const { email } = userDetails;
  const { enqueueSnackbar } = useCustomSnackbar();
  const [newsletterConfig, setNewsletterConfig] = useState({
    ...initialNewsletterConfig,
  });
  const [themeConfig, setThemeConfig] = useState(getThemeConfig());
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [themes, setThemes] = useState([]);
  const [activeTheme, setActiveTheme] = useState({});
  const [activeThemeKey, setActiveThemeKey] = useState('');

  useScript('https://cdn.paddle.com/paddle/paddle.js');

  const isProductImageAspectRatioDifferent = [
    MATRIX_THEME,
    D2C_APPEARENCE_THEME,
    LUXURY,
    MANA_THEME,
    URSA,
  ].includes(activeThemeKey);

  const hasCropperInBanner = [
    MATRIX_THEME,
    LIGHTSPEED_APPEARENCE_THEME,
    LEO,
  ].includes(activeThemeKey);

  const getNewsletter = (themeData) => {
    const intialValue = {
      isActive: false,
      description:
        'Be updated on new arrivals, trends and offers. Sign up now!',
      headerText: 'Sign up & save',
    };
    getRequest({
      url: NEWSLETTER_APP_CONFIG_URL,
    })
      .then(
        ({ result: { config = intialValue, isConnected, isInstalled } }) => {
          setNewsletterConfig({
            isConnected,
            isInstalled,
          });
          if (isInstalled) {
            themeData.meta.sections.newsletter = {
              ...config,
            };
            setActiveTheme(deepClone(themeData));
          }
        }
      )
      .catch(() => {
        setNewsletterConfig({ ...initialNewsletterConfig });
        themeData.meta.sections.newsletter = {
          ...intialValue,
        };
        setActiveTheme(deepClone(themeData));
      })
      .finally(() => setLoading(false));
  };

  const updateNewsletter = (data) =>
    postRequest({
      url: NEWSLETTER_APP_CONFIG_URL,
      data: { ...data.meta.sections.newsletter },
    })
      .then(({ success }) => success)
      .catch(() => false);

  const fetchAvailableThemes = () => {
    if (loading) {
      return false;
    }
    setLoading(true);
    getRequest({
      url: GET_STORE_THEMES,
    })
      .then((res) => {
        const { results } = res;
        const appliedTheme = results.find((theme) => theme.is_active === true);

        if (isEnterprise) {
          setThemes(Array.from(new Set([appliedTheme])));
        } else {
          setThemes(Array.from(new Set([appliedTheme, ...results])));
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const fetchActiveTheme = () => {
    getRequest({
      url: GET_ACTIVE_THEME(business.id),
    })
      .then((res) => {
        setActiveTheme(res.data);
        if (res.data.theme.key?.includes('/')) {
          setActiveThemeKey(res.data.theme.key?.split('/')[1]);
        } else {
          setActiveThemeKey(res.data.theme.key);
        }
        setThemeConfig(getThemeConfig(res.data.theme.key));
        setLoading(false);
        if (res.data?.meta?.sections?.newsletter?.isActive) {
          return getNewsletter(res.data);
        }
      })
      .catch(console.log);
  };

  const purchaseStoreTheme = (data, cb = noop) => {
    if (submitting) {
      return false;
    }
    setSubmitting(true);
    postRequest({
      url: PURCHASE_THEME(business.id),
      data,
    })
      .then(() => {
        cb();
        fetchAvailableThemes();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setSubmitting(false));
  };

  const purchaseInternationalStoreTheme = (themeId) => {
    if (submitting) {
      return false;
    }
    setSubmitting(true);
    postRequest({
      url: PURCHASE_INTERNATIONAL_THEME(business.id),
      data: { theme: themeId },
    })
      .then((resp) => {
        if (resp?.data?.url) {
          const { Paddle } = window;
          if (!Paddle) {
            window.open(resp.data.url, '_blank');
            return;
          }

          Paddle.Setup({
            vendor: DEFAULT_PADDLE_VENDOR_ID,
            eventCallback: (data) => {
              if (data.event === 'Checkout.Complete') {
                enqueueSnackbar('Theme purchased successfully!');
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else if (data.event === 'Checkout.Close') {
                window.location.reload();
              }
            },
          });
          if (isStagingEnv) {
            Paddle.Environment.set('sandbox');
          }
          Paddle.Checkout.open({
            override: resp.data.url,
            allowQuantity: false,
            email,
          });
        }
      })
      .catch(console.log)
      .finally(() => setSubmitting(false));
  };

  const applyStoreTheme = async (data, cb = noop) => {
    if (submitting) {
      return false;
    }
    setSubmitting(true);
    postRequest({
      url: UPDATE_STORE_THEME(business.id),
      data,
    })
      .then(({ data: result }) => {
        cb();
        setActiveTheme(result);
        fetchAvailableThemes();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setSubmitting(false));
  };

  const updateStoreTheme = async (data, cb = noop) => {
    if (submitting) {
      return false;
    }
    setSubmitting(true);
    postRequest({
      url: UPDATE_STORE_THEME(business.id),
      data,
      doClean: false,
    })
      .then(({ data: result }) => {
        cb();
        setActiveTheme(result);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setSubmitting(false));
    if (data?.meta?.sections?.newsletter?.isActive) {
      try {
        await updateNewsletter(data);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const refetch = () => {
    fetchAvailableThemes();
    fetchActiveTheme();
  };

  const contextValue = {
    loading,
    newsletterConfig,
    submitting,
    themes,
    activeTheme,
    themeConfig,
    isProductImageAspectRatioDifferent,
    hasCropperInBanner,
    activeThemeKey,
    fetchAvailableThemes,
    fetchActiveTheme,
    updateStoreTheme,
    applyStoreTheme,
    purchaseStoreTheme,
    purchaseInternationalStoreTheme,
    refetch,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
