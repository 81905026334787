import React, { useEffect } from 'react';
import { NotAvailable } from '../SvgIcon';
import '../../scss/pages/not_available.scss';
import { RATE_US_URL } from '../constants';
import { ExternalLink } from '../shared';
import { mobileClose } from '../Orders/components/SelectShipmentPartner';

const NotAvailableForMobile = () => {
  useEffect(() => {
    mobileClose();
  }, []);
  return (
    <div className="text-center d-flex-c-c not-available-page">
      <div className="dialog-close-icon mb24">
        <NotAvailable />
      </div>
      <h4 className="section-text-3 all-set-text">You’re all set!</h4>
      <p className="text-2 get-started-text">
        Please log in via a desktop or laptop to use Dukaan’s web version.
      </p>
    </div>
  );
};

export default NotAvailableForMobile;
