import React, { useState } from 'react';
import Countdown from 'react-countdown';

const getTwoDigit = (timer) => (timer <= 9 ? `0${timer}` : timer);
const OTP_INTERVAL = 30000; // 30 sec

const ResentOtpTimer = (props) => {
  const { handleResend } = props;
  const [timerDate, setTimerDate] = useState(Date.now() + OTP_INTERVAL);
  const onResendOtpLink = (mode) => {
    setTimerDate(Date.now() + OTP_INTERVAL);
    handleResend(mode);
  };

  return (
    <>
      <Countdown
        key={timerDate}
        date={timerDate}
        renderer={({ minutes, seconds, completed }) => {
          if (completed) {
            return (
              <span>
                <span>Didn't get the code? </span>
                <span
                  className="resend-otp-link anchor-1"
                  onClick={onResendOtpLink}
                >
                  Resend OTP
                </span>
              </span>
            );
          }
          return (
            <div className="resend-otp-timer">
              {getTwoDigit(minutes)}:{getTwoDigit(seconds)}
            </div>
          );
        }}
      />
    </>
  );
};

export default ResentOtpTimer;
