import React from 'react';
import cx from 'classnames';
import { useCountry } from '../context/CountryProvider';
import { useAppContext } from '../context/AppContext';
import { APP_DOMAIN_BASE_URL } from '../constants';
import { useSubscription } from '../context/SubscriptionProvider';
import BounceLoader from '../shared/BounceLoader';
import { MODAL_OFFER_TEXT } from '../Subscription/constants';

const PlanNDomainPriceContent = ({
  isDomainRemoved,
  setIsDomainRemoved,
  priceDetails,
  festiveDiscount = 0,
  festiveDiscountPercent = 25,
}) => {
  const { formatLocalMoney } = useCountry();
  const { business } = useAppContext();
  const { renewalDataLoading } = useSubscription();

  return (
    <div>
      {renewalDataLoading ? (
        <BounceLoader inline />
      ) : (
        <div>
          <div className="d-flex-c-s section-text-5 c-black-1">
            <div>
              Dukaan {priceDetails.name} ({priceDetails.period})
            </div>
            <div>{formatLocalMoney(priceDetails.plan)}</div>
          </div>
          {festiveDiscount ? (
            <>
              <div className="d-flex-c-s mt10">
                <p className="text-8 c-black-3">
                  {MODAL_OFFER_TEXT} ({festiveDiscountPercent}%)
                </p>
                <div className="c-green-1">
                  -{formatLocalMoney(festiveDiscount)}
                </div>
              </div>
              <div className="divider my16" />
            </>
          ) : (
            <></>
          )}
          {priceDetails.domain && (
            <div className="d-flex-c-s mt12 text-8">
              <div className="d-flex-c-c">
                <div
                  className={cx({
                    strikethrough: isDomainRemoved,
                    'c-gray-1': isDomainRemoved,
                  })}
                >
                  Domain renewal fee (yearly)
                </div>
                <div
                  className={cx('underline cur-p text-4 fw500 ml6', {
                    'c-gray-1': !isDomainRemoved,
                    'c-purple-1': isDomainRemoved,
                  })}
                  onClick={() => setIsDomainRemoved((prev) => !prev)}
                >
                  {isDomainRemoved ? 'ADD BACK' : 'REMOVE'}
                </div>
              </div>
              <div
                className={cx({
                  strikethrough: isDomainRemoved,
                  'c-gray-1': isDomainRemoved,
                })}
              >
                {formatLocalMoney(priceDetails.domain)}
              </div>
            </div>
          )}
          {isDomainRemoved && (
            <div className="mt12 caution-points">
              <ul>
                <li>
                  Your custom domain will change to{' '}
                  <span className="text-medium">
                    {`${APP_DOMAIN_BASE_URL}${business.link}`}
                  </span>
                </li>
                <li className="mt8">
                  You can renew it later but the price may increase and the
                  domain might also not be available.
                </li>
              </ul>
            </div>
          )}
          <div className="d-flex-c-s section-text-5 mt20">
            <div>Amount to pay</div>
            <div>
              {formatLocalMoney(
                +priceDetails.plan +
                  (isDomainRemoved ? 0 : +priceDetails.domain) -
                  (festiveDiscount || 0)
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanNDomainPriceContent;
