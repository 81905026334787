import { useCallback, useState } from 'react';

const useTimeoutLoader = (interval = 3000) => {
  const [loading, setLoading] = useState(false);
  const setLoadingTimeOut = useCallback(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), interval);
  }, [interval]);

  return { loading, setLoadingTimeOut };
};

export default useTimeoutLoader;
