import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { useAppContext } from '../context/AppContext';
import Modal from '../shared/Modal';
import { ShareVia } from './common';

import * as animationData from '../shared/lottie/congrats.json';
import useCustomDomain from '../hooks/useCustomDomain';
import { useCountry } from '../context/CountryProvider';
import ImageLoader from '../shared/ImageLoader';

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CustomDomainModal = (props) => {
  const { open, closeModal } = props;
  const { business, domain, displayDomain } = useAppContext();
  const { setBannerShown } = useCustomDomain(false);
  const { isInternational } = useCountry();
  const [showAnimation, setAnimation] = useState(true);
  const shareContent = `${
    isInternational ? '' : 'Namaste!%0a%0a'
  }You can now order online from ${encodeURIComponent(
    business.name
  )} using this link: ${domain}%0a%0aContact us if you need any help with ordering online.%0a%0aThank you.`;

  useEffect(() => {
    if (open) {
      setBannerShown();
    }
  }, [open]);

  return (
    <Modal open={open} className="custom-domain-modal" closeModal={closeModal}>
      {showAnimation && (
        <Lottie
          options={defaultOptions}
          style={{ position: 'absolute', margin: '-24px', width: 'auto' }}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => setAnimation(false),
            },
          ]}
        />
      )}
      <div className="d-flex-c-c flex-column mt16 pos-rel">
        <div className="store-image-container">
          <ImageLoader
            className="store-image"
            src={business.image}
            alt={business.name}
          />
        </div>
        <p className="my16 mx8 section-text-4 text-center">
          Congratulations, your custom domain{' '}
          <a
            className="anchor-4 underline"
            rel="noreferrer"
            href={domain}
            target="_blank"
          >
            {displayDomain}
          </a>{' '}
          is now live!
        </p>
        <p className="section-text-5 c-black-3 mt16 mb12">Share via</p>
        <ShareVia horizontal shareContent={shareContent} shareLink={domain} />
      </div>
    </Modal>
  );
};

export default CustomDomainModal;
