import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import OtpInput from 'react-otp-input';

import ResentOtpTimer from './ResentOtpTimer';
import { FormGroup } from '../../shared/Form';
import { getRequest, patchRequest } from '../../utils/http';
import {
  EMAIL_VERIFICATION_URL,
  SEND_EMAIL_VERIFICATION_URL,
} from '../../ApiUrls';
import { TrackEvent } from '../../utils/analytics';
import {
  EVENT_EMAIL_OTP_FAILED,
  EVENT_EMAIL_OTP_INCORRECT,
  EVENT_EMAIL_OTP_VERIFIED,
} from '../../events';
import { useAppContext } from '../../context/AppContext';
import SpinnerButton from '../../shared/SpinnerButton';
import useLocalStorage from '../../hooks/useLocalStorage';
import { NON_INTERNATIONAL_COUNTRY_CODE_VIA_IP } from '../../context/CountryProvider';

const EmailOTPVerificationForm = ({
  email,
  afterOtpSuccess,
  error,
  setError,
  showEmailOtp,
  setLoading,
  isLoading,
}) => {
  const [otp, setOtp] = useState('');
  const [prevOtp, setPrevOtp] = useState('');
  const { business } = useAppContext();
  const otpRef = useRef(null);
  const [invalidOtp, setInvalidOtp] = useState('');
  const isValidOtp = otp.length === 6 && invalidOtp !== otp;
  const classes = cx('otp-verfication-form', {
    'invalid-otp-verfication-form': !!invalidOtp,
  });

  const [localCountryCode] = useLocalStorage('__ip_country_code__');

  const isInternational =
    localCountryCode?.code !== NON_INTERNATIONAL_COUNTRY_CODE_VIA_IP;

  const handleResend = () => {
    getRequest({
      url: SEND_EMAIL_VERIFICATION_URL,
      data: {
        email,
      },
    }).then(() => {});
  };

  const handleVerify = (val) => {
    setLoading(true);
    const payload = {
      otp: val,
      email,
    };
    if (localStorage.getItem('utm_source')) {
      payload.utm_source = localStorage.getItem('utm_source');
      payload.utm_medium = localStorage.getItem('utm_medium');
      payload.utm_campaign = localStorage.getItem('utm_campaign');
    }
    patchRequest({
      url: EMAIL_VERIFICATION_URL,
      data: payload,
    })
      .then((res) => {
        const { data, status } = res;
        if (status === 'success') {
          TrackEvent(EVENT_EMAIL_OTP_VERIFIED, business);
          afterOtpSuccess(data);
        } else {
          TrackEvent(EVENT_EMAIL_OTP_INCORRECT, business);
          setInvalidOtp(val);
          setError(['Incorrect OTP. Please try again.']);
          setLoading(false);
        }
      })
      .catch(({ status }) => {
        if (status === 400) {
          TrackEvent(EVENT_EMAIL_OTP_INCORRECT, business);
          setInvalidOtp(val);
          setError(['Incorrect OTP. Please try again.']);
        } else if (!status) {
          TrackEvent(EVENT_EMAIL_OTP_FAILED, business);
          setError(['Request timeout. Please try again!']);
        } else {
          TrackEvent(EVENT_EMAIL_OTP_FAILED, business);
          setError(['Something went wrong. Please try again!']);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleVerify(otp);
    }
    if (otp?.length < prevOtp?.length) otpRef.current.focusInput(otp.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handleOtpChange = (val) => {
    setPrevOtp(otp);
    setOtp(val);
    if (invalidOtp) {
      setInvalidOtp('');
    }
    setError([]);
  };

  return (
    <div className={classes}>
      {!!error && <p className="error-alert mb16">{error}</p>}
      <h4 className="section-text-1 pt8 mb6">Verify code</h4>
      <p className="text-1 c-gray-1 mb40">
        Enter code sent to you at
        <br />
        <span className="c-black-1 fw500">{email}</span>
      </p>
      <div className="otp-form-content">
        <FormGroup
          labelText={
            <>
              <span>Code</span>
              {showEmailOtp && <ResentOtpTimer handleResend={handleResend} />}
            </>
          }
          className="otp-input-wrap"
        >
          <OtpInput
            ref={otpRef}
            value={otp}
            onChange={handleOtpChange}
            numInputs={6}
            isInputNum
            shouldAutoFocus={showEmailOtp}
          />
        </FormGroup>
      </div>
      <SpinnerButton
        showAnimation
        isLoading={isLoading}
        type="submit"
        className="btn-primary-4 full-w"
        disabled={!isValidOtp}
        onClick={(e) => {
          e.preventDefault();
          handleVerify(otp);
        }}
      >
        Verify
      </SpinnerButton>
    </div>
  );
};

export default EmailOTPVerificationForm;
