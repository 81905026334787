import DOMPurify from 'dompurify';
import domtoimage from 'dom-to-image';
import JSPDF from 'jspdf';
import { DEFAULT_ALLOWED_ATTR, DEFAULT_ALLOWED_TAGS } from './constant';
import { convertSnakeToCamelCase } from './string';

export const trackPage = (page, options = {}) => {
  console.log(page, options);
};

export const hasOwnProperty = (obj, key) =>
  Object.prototype.hasOwnProperty.call(obj, key);

export const noop = () => {};

export const arrayPull = function (arr = [], values = []) {
  // It will help you in removing values element from arr
  return arr.filter(function (item) {
    return values.indexOf(item) < 0;
  });
};

export const downloadFileByUrl = (url, openInNewTab = false) => {
  const link = document.createElement('a');
  link.href = url;
  if (openInNewTab) {
    link.target = '_blank';
  }
  document.body.appendChild(link);
  link.click();
};

export const downloadMultipleFilesByUrl = (urls, openInNewTab = false) => {
  // removing existing links from dom
  let links = document.querySelectorAll('.download-link');
  links?.forEach((link) => {
    link.parentNode.removeChild(link);
  });

  for (let i = 0; i < urls.length; i += 1) {
    const url = urls[i];
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download');
    link.classList.add(`download-link`);
    document.body.appendChild(link);
  }

  links = document.querySelectorAll('.download-link');
  links?.forEach((link) => {
    link.click();
  });
};

export const download = (url, filename) => {
  fetch(url).then(async function (t) {
    const b = await t.blob();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(b);
    a.setAttribute('download', filename);
    a.click();
  });
};

export const getOptionsNameMap = (array) =>
  array.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

export const toLowerCase = (name) =>
  name ? name.toString().toLowerCase() : '';

export const modifyFormField = ([name, val], state, { changeValue }) => {
  changeValue(state, name, () => val);
};

export const getTotalHeight = (heights = []) =>
  heights.reduce(
    (total, height) => total + (height.applicable ? height.height : 0),
    0
  );

export const downloadPDFById = (id, filename = 'new') => {
  const input = document.getElementById(id);
  return domtoimage
    .toPng(input)
    .then((dataUrl) => {
      const img = new Image();
      img.src = dataUrl;
      const imageHeight = input.scrollHeight;
      const imageWidth = input.scrollWidth;
      const aspectRatio = imageHeight / imageWidth;

      const doc = new JSPDF('p', 'mm', 'a4'); // optional parameters
      const width = doc.internal.pageSize.getWidth() - 32;
      const height = width * aspectRatio;
      doc.addImage(img, 'png', 16, 16, width, height);
      doc.save(`invoice_${filename}.pdf`);
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
};

export const urlify = (text = '', className = '') => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return `<a class="anchor-1 ${className}" href="${url}" target="_blank" rel="noreferrer"> ${url} </a>`;
  });
};

export const parseHTML = (
  text,
  allowedTags = DEFAULT_ALLOWED_TAGS,
  allowedAttributes = []
) => {
  const cleanHTML = DOMPurify.sanitize(text, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: [...new Set([...allowedAttributes, ...DEFAULT_ALLOWED_ATTR])],
  });
  return cleanHTML;
};

export const stickyTopStyle = (top = 0, color = 'white', index = 99) => ({
  position: 'sticky',
  top,
  zIndex: index,
  backgroundColor: color,
});

export const noEventPropagation = (e) => e.stopPropagation();

export const getKeyByValue = (obj, value) =>
  Object.keys(obj).find((key) => obj[key] === value);

export const getErrorMessage = (errorObject = {}) => {
  let errMsg = 'Something went wrong.';
  if (Object.keys(errorObject).length) {
    Object.keys(errorObject).forEach((key) => {
      if (typeof errorObject[key] === 'string') {
        errMsg = errorObject[key];
        return errMsg;
      }
      errorObject[key].forEach((err) => {
        errMsg = err;
      });
    });
  }
  return errMsg;
};

export const getErrorRecursively = (errorsArray, errorObject) => {
  for (const [, value] of Object.entries(errorObject)) {
    if (Array.isArray(value) && typeof value[0] === 'string') {
      errorsArray.push(value[0]);
    } else if (typeof value === 'string') {
      errorsArray.push(value);
    } else if (Object.keys(value).length) {
      getErrorRecursively(errorsArray, value);
    }
    return errorsArray;
  }
};

export const convertObjKeyFromSnakeToCamelCase = (obj = {}) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc[convertSnakeToCamelCase(key)] = value;
    return acc;
  }, {});

// why "==", when we are dealing with forms datatypes might vary
export const getIsObjectsDifferent = (obj1 = {}, obj2 = {}) =>
  Object.keys(obj1).some((key) => obj1?.[key] != obj2?.[key]);

// You use it as uniqueByKey(array, (item) => item.age);
export const uniqueByKey = (arr, selector = (item) => item) => {
  const map = new Map();
  arr.forEach((item) => {
    const prop = selector(item);
    if (!map.has(prop)) map.set(prop, item);
  });
  return [...map.values()];
};

// Test whether the string is a hex value
export const isColor = (str) => {
  // regex for hex values
  const hexValueRegex = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;

  return hexValueRegex.test(str);
};

export const getErrorMessageWithKey = (errorObj) => {
  const topLevelErrorObj = errorObj[Object.keys(errorObj)?.[0]];

  const errorMsgToShow = topLevelErrorObj[Object.keys(topLevelErrorObj)?.[0]];
  const errorKeyToShow = Object.keys(topLevelErrorObj)?.[0];

  return {
    errorKeyToShow: errorKeyToShow === '0' ? null : errorKeyToShow,
    errorMsgToShow,
  };
};
