import * as R from 'ramda';

export const isArray = (array) => Array.isArray(array);

export const mapByKey = (array, key) => R.map(R.prop(key), array);

export const findBy = (array, key, value) =>
  R.find(R.propEq(key, value))(array);

export const findIndexBy = (array, key, value) =>
  R.findIndex(R.propEq(key, value))(array);

export const removeItemFromArray = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const isObjectEmpty = (value) =>
  Object.prototype.toString.call(value) === '[object Object]' &&
  JSON.stringify(value) === '{}';
