import cx from 'classnames';
import SelectValueComponent from './SelectValueComponent';

const AttributeValueSelect = ({
  label,
  isCreateableDropdown,
  ...restProps
}) => (
  <div className="attribute-select">
    <div
      className={cx('d-flex-c-start mb16', {
        mb8: isCreateableDropdown,
      })}
    >
      <p
        className={cx('section-text-7', {
          'section-text-14 c-black-3': isCreateableDropdown,
        })}
      >
        {label}
      </p>
    </div>
    <SelectValueComponent
      {...restProps}
      isCreateableDropdown={isCreateableDropdown}
    />
  </div>
);

export default AttributeValueSelect;
