import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import Modal from './Modal';
import { noop } from '../utils';

const ConfirmModal = ({
  open,
  confirmText,
  cancelText,
  confirmBtnClass,
  cancelBtnClass,
  confirmDisabled,
  onConfirm,
  onCancel,
  headerText,
  headerClass,
  content,
  imgUrl,
  svgIcon,
  hideCancel,
  renderContent,
  showAnimation,
  loading = false,
  maxWidth = 'sm',
  className = '',
  extraButtonText,
  extraButtonClass,
  ...restProps
}) => {
  const actions = [
    {
      text: cancelText,
      className: cancelBtnClass,
      onClick: onCancel,
      hidden: hideCancel,
    },
    {
      text: extraButtonText,
      className: extraButtonClass,
      onClick: onCancel,
      hidden: !extraButtonText,
    },
    {
      text: confirmText,
      className: confirmBtnClass,
      onClick: onConfirm,
      disabled: confirmDisabled,
      loading,
      showAnimation,
    },
  ];

  const handleEvent = (e) => {
    if (e.keyCode === 13 && !confirmDisabled) {
      onConfirm();
    } else if (e.keyCode === 27) {
      onCancel();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keyup', handleEvent);
      return () => {
        document.removeEventListener('keyup', handleEvent);
      };
    }
  }, [handleEvent, open]);

  return (
    <Modal
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={maxWidth}
      closeModal={onCancel}
      actions={actions.filter((action) => !action.hidden)}
      className={cx('confirm-modal', className)}
      {...restProps}
    >
      {!!imgUrl && <img src={imgUrl} alt="" className="header-img" />}
      {svgIcon}
      {!!headerText && (
        <h2 className={`section-text-4 mb6 start-xs ${headerClass}`}>{headerText}</h2>
      )}
      {renderContent ? (
        renderContent()
      ) : (
        <p className="text-2 c-black-3 start-xs">{content}</p>
      )}
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  onConfirm: noop,
  onCancel: noop,
  confirmBtnClass: 'btn-primary-4',
  cancelBtnClass: 'btn-outline-4',
  confirmDisabled: false,
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  headerText: '',
  content: '',
  imgUrl: '',
  svgIcon: null,
};

export default ConfirmModal;
