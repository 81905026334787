import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sortly, {
  ContextProvider,
  useDrag,
  useDrop,
  useIsClosestDragging,
} from 'react-sortly';
import { Shimmer } from 'react-shimmer';

import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import ImageLoader from '../../shared/ImageLoader';
import useLocalStorage from '../../hooks/useLocalStorage';
import { noop } from '../../utils';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import useScrollingRef from '../../hooks/useScrollingRef';
import { BounceLoaderSmall } from '../../shared/BounceLoader';
import useProducts from '../../hooks/useProductsListing';
import { useAppContext } from '../../context/AppContext';
import { DragIcon } from '../../SvgIcon';
import CheckboxField from '../../shared/CheckboxField';
import BulkReorderProductAction from './BulkReorderProductAction';

const SortableItem = ({
  data,
  setDisableSaveChanges,
  handleShowToolTip,
  index,
  length,
  selectedProductIdList,
  handleCheckboxChange,
  isStaticReordering = false,
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const inStock =
    data.inventory_quantity > 0 || data.inventory_quantity === null;
  const [, drop] = useDrop();
  const dragging = useIsClosestDragging() || isDragging;

  const getDataStatus = () => {
    if (!data.is_active) {
      return 'Hidden';
    }
    if (inStock) {
      return 'In stock';
    }
    return 'Out of stock';
  };

  return (
    <div
      ref={(ref) => drop(preview(ref))}
      key={data.id}
      className={cx('reordering-card cur-grab', {
        'hr-break': index !== length - 1,
        'cur-grabbing': dragging,
      })}
      onDragStart={() => {
        handleShowToolTip();
      }}
      onDragEnd={() => setDisableSaveChanges(false)}
    >
      <div ref={drag} className="d-flex">
        <div className="d-flex-c-c mr16">
          <CheckboxField
            checked={selectedProductIdList.includes(data.id) ?? false}
            onChange={() => handleCheckboxChange(data.id)}
          />
        </div>
        <div className="d-flex-c-start reorder-index">{index + 1}.</div>
        <div className="d-flex-c-start">
          <ImageLoader
            alt={data?.name ?? ''}
            src={data?.image ?? ''}
            className="reordering-card-icon"
          />
        </div>

        <div className="reorder-product-title pl16 mr24">
          <div>
            <p> {data?.name ?? ''}</p>
            {data?.sku_code && (
              <span className="text-5 c-gray-1">SKU: {data?.sku_code}</span>
            )}
          </div>
        </div>

        <div className="ml-auto d-flex-c-s flex-row">
          <span
            className={cx('text-8 reorder-in-stock mr30', {
              'c-red-1': !inStock || !data.is_active,
              'c-green-1': inStock && data.is_active,
            })}
          >
            {!isStaticReordering && getDataStatus()}
          </span>
          <DragIcon />
        </div>
      </div>
    </div>
  );
};

const ProductReorderingModal = ({
  open = false,
  handleClose = noop,
  categoryId,
  closeReorderModal,
  reFetch = noop,
  isStaticReordering = false,
  initialProducts = [],
  onChange = noop,
}) => {
  const { business } = useAppContext();
  const { uuid: storeId } = business;
  const productProps = useProducts(
    { ordering: 'position', direction: 'asc', product_type: [0, 1, 2] },
    storeId
  );
  const {
    updateOrderOfProducts,
    fetchProducts,
    pageNumber: page,
    setPageNumber,
    loading,
    hasMore,
    products,
  } = productProps;

  const loadingEl = useScrollingRef(loading, hasMore, setPageNumber);

  const [reOrderArray, setReOrderArray] = useState([]);
  const [selectedProductIdList, setSelectedProductIdList] = useState([]);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [showTooltip, setShowTooltip] = useLocalStorage(
    'showReorderTooltip',
    true
  );
  const handleShowToolTip = () => {
    setShowTooltip(false);
  };

  const handleSaveChanges = () => {
    if (isStaticReordering) {
      onChange(reOrderArray);
      closeReorderModal();
    } else {
      updateOrderOfProducts(reOrderArray, categoryId, () => {
        reFetch();
        closeReorderModal();
      });
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedProductIdList.includes(id)) {
      setSelectedProductIdList((pre) => pre.filter((each) => each !== id));
    }
    setSelectedProductIdList((pre) => [...pre, id]);
  };

  const handleBulkAction = (moveTop) => {
    setReOrderArray((pre) => {
      const filterData = [];
      const nonFilteredData = [];
      pre.forEach((each) => {
        if (selectedProductIdList.includes(each.id)) filterData.push(each);
        else nonFilteredData.push(each);
      });
      const final = moveTop
        ? [...filterData, ...nonFilteredData]
        : [...nonFilteredData, ...filterData.reverse()];
      return final;
    });
    setSelectedProductIdList([]);
    setDisableSaveChanges(false);
  };

  useEffect(() => {
    if (isStaticReordering) {
      setReOrderArray(initialProducts);
    } else {
      fetchProducts(page, categoryId, true, (results = []) =>
        setReOrderArray((prev) => [...prev, ...results])
      );
    }
  }, [page, isStaticReordering]);

  useEnterPressAction(open, disableSaveChanges ? noop : handleSaveChanges);

  return (
    <Modal
      open={open}
      closeModal={() => {
        handleShowToolTip();
        handleClose();
      }}
      className="reorder-product-modal"
      maxWidth="md"
    >
      <h2 className="redorder-modal-header">Reorder Products</h2>
      {selectedProductIdList.length > 0 && (
        <div className="mt20 d-flex">
          <CheckboxField
            className="mr16"
            checked
            isHalfChecked
            onChange={() => setSelectedProductIdList([])}
          />
          <BulkReorderProductAction handleBulkAction={handleBulkAction} />
        </div>
      )}
      <hr className="hr-line mt16 mb0" />
      <div
        className={cx('reorder-modal-content show-scrollbar', {
          'with-scroll': loading || reOrderArray.length > 7,
        })}
      >
        {loading && page === 1 ? (
          [...Array(7).keys()].map((i) => (
            <div className="show-shimmer" key={i}>
              <Shimmer width={516} height={56} />
            </div>
          ))
        ) : (
          <DndProvider backend={HTML5Backend}>
            <ContextProvider>
              <Sortly
                items={reOrderArray.map((el) => ({ ...el, depth: 0 }))}
                onChange={(newItems) => {
                  setReOrderArray(newItems);
                }}
              >
                {(props) => (
                  <SortableItem
                    {...props}
                    data={
                      isStaticReordering
                        ? props.data?.content_object
                        : props.data
                    }
                    setDisableSaveChanges={setDisableSaveChanges}
                    showTooltip={showTooltip}
                    selectedProductIdList={selectedProductIdList}
                    handleCheckboxChange={handleCheckboxChange}
                    handleShowToolTip={handleShowToolTip}
                    length={reOrderArray.length}
                    isStaticReordering={isStaticReordering}
                  />
                )}
              </Sortly>
              <div ref={loadingEl} style={{ height: 1 }} />
              {loading && <BounceLoaderSmall inline />}
            </ContextProvider>
          </DndProvider>
        )}
      </div>

      <hr className="hr-line mt0 mb16" />
      <div className="pos-rel reorder-modal-footer">
        {selectedProductIdList.length > 0 && (
          <div className="py10">{selectedProductIdList.length} selected</div>
        )}
        <div className="absolute-center">
          <SpinnerButton
            showAnimation
            type="button"
            className="btn btn-primary-4"
            onClick={handleSaveChanges}
            disabled={disableSaveChanges}
          >
            Update
          </SpinnerButton>
        </div>
      </div>
    </Modal>
  );
};

export default ProductReorderingModal;
