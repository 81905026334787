import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import ReactPlayer from 'react-player/youtube';
import { noop } from '../utils';
import { CloseModalIcon } from '../SvgIcon';
import BounceLoader from './BounceLoader';

const YouTubePlayer = ({
  url,
  open,
  handleClose = noop,
  startPlaying = false,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <Dialog
      fullScreen
      open={open}
      onEscapeKeyDown={handleClose}
      className="youtube-player"
      onClick={handleClose}
    >
      <CloseModalIcon onClick={handleClose} className="modal-close-icon" />
      {loading && <BounceLoader />}
      <ReactPlayer
        playing={startPlaying}
        url={url}
        height="100%"
        width="100%"
        controls
        className="youtube-player-item"
        onReady={() => setLoading(false)}
      />
    </Dialog>
  );
};

export default YouTubePlayer;
