import React, { useState } from 'react';
import useEnterPressAction from '../hooks/useEnterPressAction';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { DeclineOrderIcon } from '../SvgIcon';
import { noop } from '../utils';

const RejectReturnModal = ({
  open,
  closeModal,
  submitting = false,
  onSubmit = noop,
  isReplacementOrderExist,
}) => {
  useEnterPressAction(open, onSubmit);
  return (
    <Modal
      open={open}
      className="shipment-created-modal new-shipment-modal"
      closeModal={closeModal}
      maxWidth="sm"
    >
      <div className="shipment-created-modal">
        <div className="flex-center">
          <DeclineOrderIcon />
          <h4 className="section-text-4 mt12">
            Reject {isReplacementOrderExist ? 'replacement' : 'return'} request?
          </h4>
          <div className="text-2 c-black-3 d-bloc text-center mt6">
            Once you reject the{' '}
            {isReplacementOrderExist ? 'replacement' : 'return'} request, you
            can’t accept it anymore.
          </div>

          <SpinnerButton
            showAnimation
            type="button"
            className="btn btn-danger-4 mt28"
            isLoading={submitting}
            onClick={onSubmit}
          >
            Yes, reject
          </SpinnerButton>
        </div>
      </div>
    </Modal>
  );
};

export default RejectReturnModal;
