import { useCallback, useState } from 'react';

import { getRequest } from '../utils/http';
import { PRODUCT_URL_ADVANCE } from '../ApiUrls';
import { formatProductWarehouseData } from '../Catalogue/Products/AdvanceCatalogue/common';

const useProductDetails = ({ uuid, storeId }) => {
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [skus, setSkus] = useState('');

  const url = PRODUCT_URL_ADVANCE({ productUId: uuid, storeId });

  const refetch = useCallback(() => {
    setLoading(true);
    getRequest({ url })
      .then(({ data }) => {
        setLoading(false);
        setSkus(JSON.stringify(data.sku_data));
        setProductDetails(formatProductWarehouseData(data));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [url]);

  const refetchPromise = async () => {
    const getProductDetailsPromise = new Promise((resolve) => {
      setLoading(true);
      getRequest({ url })
        .then(({ data }) => {
          setLoading(false);
          setProductDetails(data);
          resolve({ error: false, data });
        })
        .catch(() => {
          setLoading(false);
          resolve({ error: true, data: {} });
        });
    });

    return getProductDetailsPromise;
  };

  return {
    loading,
    productDetails,
    refetch,
    refetchPromise,
    skus,
  };
};

export default useProductDetails;
