import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sortly, { ContextProvider } from 'react-sortly';
import { v4 as uuidv4 } from 'uuid';
import useUploadMedia from '../../hooks/useUploadMedia';
import UploadImage from '../../shared/UploadImage';
import { UploadedImage2 } from '../../shared';
import { CameraIcon, VideoCameraIcon } from '../../SvgIcon';
import ImageOverlayView from '../../shared/ImageOverlayView';
import { TrackEvent } from '../../utils/analytics';
import { EVENT_PROD_ADD_IMAGES } from '../../events';
import { useAppContext } from '../../context/AppContext';
import { noop } from '../../utils';
import useModal from '../../hooks/useModal';
import SuggestedImagesModal from './SuggestedImagesModal';
import SwapRenderer from '../../shared/SwapRenderer';
import {
  imageRecommendedSize,
  MAX_IMAGES_MAIN_PRODUCT_OTHERS,
  MAX_IMAGES_MAIN_PRODUCT_RESTAURANT,
} from '../constants';
import { useTheme } from '../../context/ThemeProvider';
import { THUMBNAIL_TYPE } from '../../utils/constant';
import UploadVideoModal from '../../shared/UploadVideoModal';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { isNotEmptyOrNull } from '../../shared/Form/Shared';
import UploadVideo from '../../shared/UploadVideo';

const ImageUploadView = (props) => {
  const {
    imgFile,
    updateImage,
    id,
    imgUrl,
    uploading,
    upload,
    percentage,
    localFile,
    isUploading,
    isLargeImg = false,
    setImages,
  } = props;

  useEffect(() => {
    if (!imgUrl) {
      isUploading({ id, isUploading: true });
      upload(
        imgFile,
        ({ cdnURL }) => {
          updateImage(id, cdnURL);
          isUploading({ id, isUploading: false });
        },
        () => {
          isUploading({ id, isUploading: false });
          setImages((prev) => prev.filter((item) => item.id !== id));
        }
      );
    }
  }, [id]);

  return uploading ? (
    <ImageOverlayView
      percentage={percentage}
      src={localFile}
      large={isLargeImg}
    />
  ) : null;
};

export const Image = (props) => {
  const {
    imgUrl,
    id,
    file,
    removeImage,
    updateImage,
    isUploading,
    dragging = false,
    imagesLength,
    thumbnailType,
    height = 120,
    width = 120,
    isAttribute = false,
    breathingClass = '',
    setImages,
    ...restProps
  } = props;
  const imageProps = useUploadMedia({});

  if (file) {
    return (
      <ImageUploadView
        {...imageProps}
        key={id}
        id={id}
        imgFile={file}
        imgUrl={imgUrl}
        updateImage={updateImage}
        isUploading={isUploading}
        setImages={setImages}
      />
    );
  }
  return (
    <UploadedImage2
      key={id}
      name={id}
      imgUrl={imgUrl}
      onRemoveClick={() => removeImage(id, imgUrl)}
      dragging={dragging}
      imagesLength={imagesLength}
      thumbnailType={thumbnailType}
      isAttribute={isAttribute}
      height={height}
      width={width}
      breathingClass={breathingClass}
      {...restProps}
    />
  );
};

const SuggestionOpener = ({
  onClick = noop,
  disabled = false,
  isVideoAllowed = false,
  uploadFile = noop,
  className = '',
  iconClass = '',
  textClass = '',
}) => {
  const { isProductImageAspectRatioDifferent: isAspectRatioDifferent } =
    useTheme();
  const {
    isOpen: isAddVideoModalOpen,
    openModal: openAddVideoModal,
    closeModal: closeAddVideoModal,
  } = useModal();

  const classes = cx(
    'file-image-upload flex-column',
    {
      'product-theme-aspect-ratio': isAspectRatioDifferent === true,
      disabled,
    },
    className
  );

  return (
    <>
      <div
        className={classes}
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
      >
        <CameraIcon width={40} height={34} className={iconClass} />
        {isVideoAllowed && (
          <div
            className={cx(
              'text-10 text-medium underline cur-p c-black-3 hover-add-video',
              textClass
            )}
            onClick={(event) => {
              event.stopPropagation();
              openAddVideoModal();
            }}
          >
            or add video
          </div>
        )}
      </div>
      {isAddVideoModalOpen && (
        <UploadVideoModal
          open={isAddVideoModalOpen}
          close={closeAddVideoModal}
          uploadFile={uploadFile}
        />
      )}
    </>
  );
};

const ProductImages = (props) => {
  const {
    images,
    addImage,
    removeImage,
    updateImage,
    isUploading,
    searchQuery = '',
    fetchImageSuggestions = noop,
    setImages,
    isLoading,
    selectedImages,
    setSelectedImages,
    fetchedImages,
    mediaTitle,
    addDivider = false,
    noBottomMargin = false,
    id = 'product-images',
  } = props;
  const IMAGE_WIDTH = 106;
  const IMAGE_HEIGHT = 106;
  const IMAGE_MAX_HEIGHT = 132;
  const MAX_IMAGES = MAX_IMAGES_MAIN_PRODUCT_OTHERS;
  const disableUpload = fetchedImages?.length >= MAX_IMAGES;

  const { business } = useAppContext();
  const { isOpen, openModal, closeModal } = useModal();
  const [suggestionImages, setSuggestionImages] = useState([]);
  const {
    activeThemeKey,
    isProductImageAspectRatioDifferent: isAspectRatioDifferent,
    fetchActiveTheme,
  } = useTheme();

  const { enqueueSnackbar } = useCustomSnackbar();

  const onUpdateClick = (imgFiles, thumbnailType = '') => {
    TrackEvent(EVENT_PROD_ADD_IMAGES, business);
    const currentMaxImages = MAX_IMAGES - images.length;

    if (Array.isArray(imgFiles))
      addImage(imgFiles.slice(0, currentMaxImages), thumbnailType);
    // adding video thumbnail only if currentMaxImages is > 0
    else if (currentMaxImages) {
      const isNotAlreadyPresent = (obj) =>
        String(obj.imgUrl) !== String(imgFiles);

      if (!images.every(isNotAlreadyPresent))
        return enqueueSnackbar('Similar video is already added', 'error');
      addImage(imgFiles, thumbnailType);
    }
    closeModal();
  };

  const addSelectedImages = (imgUrls) => {
    const data = [...images];

    imgUrls.forEach((img) => {
      if (data.find((d) => d.imgUrl === img) === undefined) {
        return data.push({
          imgUrl: img,
          id: uuidv4(),
        });
      }
    });
    setImages(data);
  };

  const handleChangePosition = (newItems) => {
    setImages(newItems.map((el, idx) => ({ ...el, position: idx })));
  };

  useEffect(() => {
    fetchActiveTheme();
  }, []);

  return (
    <div
      className={cx('product-images-section card', {
        'mb0-imp': noBottomMargin,
      })}
      id={id}
    >
      <div
        className={cx('d-flex flex-column', {
          mb24: !addDivider,
        })}
      >
        <h4 className="section-text-5">{mediaTitle}</h4>
        <p className="text-8 c-gray-1">
          Upload captivating images and videos to make your product stand out.
        </p>
      </div>
      {addDivider && <div className="divider mb24 mt8" />}
      <div
        className={cx('product-images-wrap overflow-hidden d-block', {
          pb16: images?.length > 0,
          'product-theme-aspect-ratio': isAspectRatioDifferent === true,
        })}
      >
        {suggestionImages.length === 0 && !isLoading ? (
          <div className="d-flex gap12 align-center">
            <UploadImage
              disabled={images.length >= MAX_IMAGES}
              Icon={CameraIcon}
              iconHeight={34}
              iconWidth={40}
              textClass={cx({
                mt18: !isAspectRatioDifferent,
                mt28: isAspectRatioDifferent,
              })}
              iconClass={cx({
                'p0 mt14': !isAspectRatioDifferent,
                'p0 mt30': isAspectRatioDifferent,
              })}
              uploadFile={onUpdateClick}
              multiple
              isVideoAllowed={false}
              imgText="Upload images"
            />
            <p className="text-10 text-medium c-black-3">Or</p>
            <UploadVideo
              disabled={images.length >= MAX_IMAGES}
              Icon={VideoCameraIcon}
              iconHeight={36}
              iconWidth={40}
              textClass={cx({
                mt18: !isAspectRatioDifferent,
                mt28: isAspectRatioDifferent,
              })}
              iconClass={cx({
                'p0 mt14': !isAspectRatioDifferent,
                'p0 mt34': isAspectRatioDifferent,
              })}
              uploadFile={onUpdateClick}
              multiple
              isVideoAllowed
            />
          </div>
        ) : (
          <SuggestionOpener
            onClick={openModal}
            disabled={images.length >= MAX_IMAGES}
            isVideoAllowed
            uploadFile={onUpdateClick}
            iconClass={cx({
              'p0 mt14': !isAspectRatioDifferent,
              'p0 mt30': isAspectRatioDifferent,
            })}
            textClass={cx({
              mt18: !isAspectRatioDifferent,
              mt28: isAspectRatioDifferent,
            })}
          />
        )}
        <div className="image-container">
          <DndProvider backend={HTML5Backend}>
            <ContextProvider>
              <Sortly
                items={images.map((el) => ({
                  ...el,
                  depth: 0,
                  // for youtube videos, show youtube icon
                  thumbnailType: el?.imgUrl?.includes('img.youtube.com')
                    ? THUMBNAIL_TYPE.YOUTUBE
                    : '',
                }))}
                onChange={handleChangePosition}
              >
                {(prop) => (
                  <div
                    className={cx('uploaded-image', {
                      'product-theme-aspect-ratio-uploaded-image':
                        isAspectRatioDifferent === true,
                    })}
                  >
                    <SwapRenderer
                      {...prop}
                      className="uploaded-img-overlay"
                      disableDrag={images.length === 1}
                    >
                      <Image
                        {...prop.data}
                        removeImage={removeImage}
                        updateImage={updateImage}
                        isUploading={isUploading}
                        imagesLength={images.length}
                        isLargeImg={isAspectRatioDifferent}
                        width={IMAGE_WIDTH}
                        height={
                          isAspectRatioDifferent
                            ? IMAGE_MAX_HEIGHT
                            : IMAGE_HEIGHT
                        }
                        breathingClass="br4"
                        setImages={setImages}
                      />
                    </SwapRenderer>
                  </div>
                )}
              </Sortly>
            </ContextProvider>
          </DndProvider>
        </div>
        <SuggestedImagesModal
          searchQuery={searchQuery}
          open={isOpen}
          images={suggestionImages}
          handleClose={closeModal}
          handleSubmit={addSelectedImages}
          existingImagesCount={images.length}
          maxImages={MAX_IMAGES}
          isLoading={isLoading}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
        >
          <UploadImage
            disabled={disableUpload}
            uploadFile={onUpdateClick}
            multiple
            isSmallIcon
            className={cx('sm', {
              'file-image-upload-aspect-ratio-different':
                isAspectRatioDifferent === true,
              'file-image-upload-aspect-ratio-same':
                isAspectRatioDifferent === false,
            })}
          />
        </SuggestedImagesModal>
      </div>
      {isNotEmptyOrNull({ value: imageRecommendedSize[activeThemeKey] }) ? (
        <p className="section-text-7 c-gray-1 pb16 pt-8">
          Recommended size {imageRecommendedSize[activeThemeKey]}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductImages;
