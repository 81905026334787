import React, { useState } from 'react';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import ActionMenu from '../../shared/ActionMenu';
import DeleteModal from '../../shared/DeleteModal';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import useModal from '../../hooks/useModal';
import { noop } from '../../utils';
import { FAQ_ID } from '../constants';

const FaqItem = ({
  item,
  deleteFaq,
  deleteProductFaq,
  deleteCategoryFaq,
  deleteLocalFaq,
  openFaqModal = noop,
  setSelectedFAQId = noop,
  editProductFAQ,
  editCategoryFAQ,
  productID,
  categoryId,
  isProductOrCategoryFAQ = false,
}) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const history = useHistory();
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const location = useLocation();

  const faqPluginPath = location.pathname.split('/').includes(FAQ_ID);
  const { question, answer } = item;
  const {
    isOpen: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const handleEditFAQ = (selectedItemId) => {
    if (productID || categoryId || selectedItemId) {
      openFaqModal();
      if (faqPluginPath) {
        history.push(`${window.location.pathname}?edit=${selectedItemId}`);
      }
    }
  };

  const faqItem = (selectedItem) => [
    {
      labelText: <p className="">Edit question</p>,
      onClick: () => {
        handleEditFAQ(selectedItem.id);
        setSelectedFAQId(selectedItem.id);
      },
    },
    {
      labelText: <p className="c-red-1">Delete question</p>,
      onClick: () => {
        openDeleteModal();
        setSelectedFAQ(selectedItem.id);
      },
    },
  ];

  const handleDeleteFAQ = () => {
    if (editProductFAQ) {
      deleteProductFaq(selectedFAQ, productID, closeDeleteModal);
    }
    if (editCategoryFAQ) {
      deleteCategoryFaq(selectedFAQ, categoryId, closeDeleteModal);
    }

    deleteFaq(selectedFAQ, closeDeleteModal);
    deleteLocalFaq(selectedFAQ, closeDeleteModal);
  };

  return (
    <section
      className={cx('faq-item-card', {
        'accordion-faq-design': isProductOrCategoryFAQ,
      })}
    >
      <div className="content">
        <div
          className={cx({
            'section-text-6 c-black-1': isProductOrCategoryFAQ,
            'section-text-7 c-black-3': !isProductOrCategoryFAQ,
          })}
        >
          {question}
        </div>
        <div
          className={cx('text-8 c-black-3 break-word', {
            mt4: !isProductOrCategoryFAQ,
          })}
        >
          {answer}
        </div>
      </div>
      <ActionMenu
        iconBtnClass="action-menu-icon"
        menuItems={faqItem(item)}
        iconSize="20"
      />
      {isOpenDeleteModal && (
        <DeleteModal
          open={isOpenDeleteModal}
          closeModal={closeDeleteModal}
          headerText="Delete FAQ"
          maxWidth="xm"
          renderContent={() => (
            <p className="text-2 c-black-3">
              Are you sure you want to delete this FAQ?
            </p>
          )}
          buttonText="Delete"
          deleteAction={() => handleDeleteFAQ()}
          afterDelete={() => {
            enqueueSnackbar('FAQ deleted.', 'info');
          }}
          redIcon
        />
      )}
    </section>
  );
};

const FaqContainer = ({
  faqList = [],
  deleteFaq = noop,
  deleteProductFaq = noop,
  deleteCategoryFaq = noop,
  deleteLocalFaq = noop,
  openFaqModal = noop,
  setSelectedFAQId = noop,
  editProductFAQ = false,
  editCategoryFAQ = false,
  isProductOrCategoryFAQ = false,
  productID,
  categoryId,
}) => (
  <div
    className={cx('faq-items', {
      mt24: faqList.length > 0,
      'accordion-faq-items': isProductOrCategoryFAQ,
    })}
  >
    {faqList?.map((item) => (
      <FaqItem
        key={item.id}
        id={item.id}
        item={item}
        deleteProductFaq={deleteProductFaq}
        deleteCategoryFaq={deleteCategoryFaq}
        deleteFaq={deleteFaq}
        openFaqModal={openFaqModal}
        deleteLocalFaq={deleteLocalFaq}
        setSelectedFAQId={setSelectedFAQId}
        editProductFAQ={editProductFAQ}
        editCategoryFAQ={editCategoryFAQ}
        productID={productID}
        categoryId={categoryId}
        isProductOrCategoryFAQ={isProductOrCategoryFAQ}
      />
    ))}
  </div>
);

export default FaqContainer;
