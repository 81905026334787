import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import BounceLoader from './BounceLoader';

const FullscreenBounceLoader = ({ message = '', description = '' }) => (
  <Dialog fullScreen open>
    <div className="upload-container">
      <div className="loader-container">
        <BounceLoader inline />
        <span className="section-text-1 mt40 mb12">{message}</span>
        <span className="text-1 c-black-3">{description}</span>
      </div>
    </div>
  </Dialog>
);

export default FullscreenBounceLoader;
