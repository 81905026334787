import React, { useState } from 'react';
import { CUSTOMER_SUPPORT_EMAIL } from '../constants';
import useEmailSupport from '../hooks/utils/useEmailSupport';
import CheckboxField from '../shared/CheckboxField';
import Modal from '../shared/Modal';
import { WarningTranslucentIcon } from '../SvgIcon';
import { COUNTRY_CHANGE_EMAIL } from './constants';

const RequestCountryChangeModal = ({ open, closeModal }) => {
  const [checked, setChecked] = useState({
    checkboxOne: false,
    checkboxTwo: false,
  });

  const { sendEmail } = useEmailSupport();

  const handleChecked = (checkboxName) => {
    setChecked((prev) => ({ ...prev, [checkboxName]: !prev[checkboxName] }));
  };

  return (
    <Modal open={open} closeModal={closeModal}>
      <div className="text-center mb16">
        <WarningTranslucentIcon className="mb10" />
        <p className="section-text-4 mb6">Confirm change country</p>
        <p className="text-2 c-black-3">
          Before you change your country, please accept the following
          conditions:
        </p>
      </div>
      <div className="mb24 mr-auto checkbox-pt4">
        <div className="mb12">
          <CheckboxField
            checked={checked.checkboxOne}
            onChange={() => handleChecked('checkboxOne')}
            className="align-start-imp"
            label={
              <span className="text-2 c-black-3">
                Country-specific features & plugins will not be available.
              </span>
            }
          />
        </div>
        <CheckboxField
          checked={checked.checkboxTwo}
          onChange={() => handleChecked('checkboxTwo')}
          className="align-start-imp"
          label={
            <span className="text-2 c-black-3">
              This will customize your Dukaan experience based on the country
              you live in.
            </span>
          }
        />
      </div>
      <div className="text-center">
        <div
          className="btn btn-primary-4 mb12 text-white no-underline"
          disabled={!checked.checkboxOne || !checked.checkboxTwo}
          onClick={() => {
            closeModal();
            sendEmail(COUNTRY_CHANGE_EMAIL.SUBJECT, COUNTRY_CHANGE_EMAIL.BODY);
          }}
        >
          Request Change
        </div>
        <p className="text-10 c-black-3">
          <span className="section-text-13">Note:</span> This request may take
          up to 48 hours to be processed. <br />
          Please contact us at {CUSTOMER_SUPPORT_EMAIL} if you face any issue.
        </p>
      </div>
    </Modal>
  );
};

export default RequestCountryChangeModal;
