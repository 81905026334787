import { createTheme } from '@material-ui/core';
import colors from './shared/themeColors';

export const muiTheme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  overrides: {
    MuiTooltip: {
      tooltipPlacementTop: {
        marginBottom: '8px !important',
      },
      tooltipPlacementBottom: {
        marginTop: '8px !important',
      },
      tooltip: {
        fontSize: '12px',
        lineHeight: '12px',
        backgroundColor: colors.black3,
        padding: '8px 8px 6px',
        fontWeight: 'normal',
      },
      arrow: {
        color: colors.black2,
      },
    },
    MuiTabs: {
      root: {
        minHeight: '40px',
      },
      indicator: {
        backgroundColor: colors.purple1,
      },
    },
    MuiTab: {
      root: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: '500',
        color: colors.black3,
        opacity: 1,
        marginRight: '24px',
        minHeight: '40px',
        minWidth: '75px !important',
        padding: '0px 8px 16px',
        textTransform: 'none',
        '&.Mui-selected': {
          color: colors.purple1,
        },
        '&:hover': {
          color: colors.purple2,
        },
        '&:focus': {
          color: colors.purple2,
        },
        '&:active': {
          color: colors.purple2,
        },
      },
      textColorInherit: {
        color: colors.black3,
        opacity: 1,
      },
    },
    MuiCircularProgress: {
      root: {
        '&.MuiCircularProgress-colorPrimary': {
          color: colors.purple1,
        },
      },
    },
  },
});
