import { useState, useEffect } from 'react';
import axios from 'axios';
import { getRequest } from '../utils/http';

import { useAppContext } from '../context/AppContext';
import {
  CHECK_DUPLICATE_ORDERS_URL,
  DUPLICATE_ORDERS_LISTING_URL,
} from '../ApiUrls';
import { ORDER_DATE_ON } from '../Manage/Delivery/constants';

const useFilters = (defaultFilters) => {
  const [filters, setFilters] = useState(defaultFilters || {});

  const applyFilters = (newFilters, keysToDelete = []) => {
    const toApply = { ...filters, ...newFilters };
    keysToDelete.forEach((each) => delete toApply[each]);

    setFilters(toApply);
  };

  return [filters, applyFilters];
};

const useDuplicateOrders = (defaultFilters = {}, canFetch = true) => {
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderDateType, setOrderDateType] = useState(ORDER_DATE_ON);
  const [duplicateOrders, setDuplicateOrders] = useState({});
  const [ordersToCheck, setOrdersToCheck] = useState([]);
  const [totalDuplicateOrdersCount, setTotalDuplicateOrdersCount] = useState(0);
  const [filters, setFilters] = useFilters(defaultFilters);

  const { isLoggedIn } = useAppContext();

  const onSearch = (text = '') => {
    setSearch(text.replace('#', ''));
    setPageNumber(1);
  };

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
    setPageNumber(1);
    setDuplicateOrders([]);
  };

  const fetchOrders = (params) => {
    if (loading || !isLoggedIn) {
      return false;
    }
    setLoading(true);
    getRequest({
      url: DUPLICATE_ORDERS_LISTING_URL,
      data: params,
    })
      .then((res) => {
        const { count, results = {} } = res;
        const resultsCount = Object.keys(results).length || 0;
        setTotalDuplicateOrdersCount(count);
        if (pageNumber === 1) {
          setHasMore(resultsCount < count);
          setDuplicateOrders(results);
        } else {
          setHasMore(
            Object.keys(duplicateOrders).length + resultsCount < count
          );
          setDuplicateOrders((prevOrders) => ({ ...prevOrders, ...results }));
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isCancel(e)) {
          return false;
        }
      });
  };

  const fetchCheckDuplicateOrders = (uuid) => {
    setLoading(true);
    getRequest({
      url: CHECK_DUPLICATE_ORDERS_URL(uuid),
    })
      .then((res) => {
        const { data } = res;
        setOrdersToCheck(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const params = {
      ...filters,
      page: pageNumber,
    };
    if (params.startDate) {
      params[`${orderDateType}_after`] = params.startDate;
      delete params.startDate;
    }
    if (params.endDate) {
      params[`${orderDateType}_before`] = params.endDate;
      delete params.endDate;
    }

    if (params.selection === 'lifetime') {
      params.created_at_after = '2021-05-15';
    }

    if (canFetch) fetchOrders(params);
  }, [pageNumber, filters, canFetch]);

  const refetchOrders = () => {
    setPageNumber(1);
  };

  return {
    loading,
    duplicateOrders,
    search,
    totalDuplicateOrdersCount,
    ordersToCheck,
    fetchCheckDuplicateOrders,
    hasMore,
    pageNumber,
    setPageNumber,
    filters,
    applyFilters,
    fetchOrders,
    refetchOrders,
  };
};

export default useDuplicateOrders;
