import React, { useEffect } from 'react';
import Countdown from 'react-countdown';
import cx from 'classnames';
import SpinnerButton from '../../shared/SpinnerButton';
import { noop } from '../../utils';
import { padZeros } from '../../utils/string';
import { BlackRemoveIcon } from '../../SvgIcon';
import { useAppContext } from '../../context/AppContext';

const OfferBar = ({
  title = '',
  btnLabel = '',
  onClick = noop,
  date = '',
  onClose,
  isMobile = false,
  danger = false,
  extraClasses = { mobileClasses: '' },
}) => {
  const { setIsHelloBarActive } = useAppContext();
  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="row countdown-row">
      <div className="mr12">
        <p className="timer-data">{padZeros(days)} :</p>
        <span className="timer-period">Days</span>
      </div>
      <div className="mr12">
        <p className="timer-data">{padZeros(hours)} :</p>
        <span className="timer-period">Hours</span>
      </div>
      <div className="mr12">
        <p className="timer-data">{padZeros(minutes)} :</p>
        <span className="timer-period">Minutes</span>
      </div>
      <div className="mr12">
        <p className="timer-data">{padZeros(seconds)}</p>
        <span className="timer-period">Seconds</span>
      </div>
    </div>
  );

  useEffect(() => {
    setIsHelloBarActive(true);

    return () => {
      setIsHelloBarActive(false);
    };
  }, []);

  return (
    <div className={cx('offer-banner', { px16: isMobile, danger })}>
      <span
        className={cx(
          'section-text-5',
          { mr16: !isMobile },
          extraClasses.mobileClasses
        )}
      >
        {title}
      </span>
      {btnLabel && (
        <SpinnerButton className="btn-black btn-small" onClick={onClick}>
          {btnLabel}
        </SpinnerButton>
      )}
      {date && (
        <div className="row align-center">
          <div className={cx('black mx24', { 'vertical-divider': date })} />
          <Countdown date={date} renderer={renderer} />
        </div>
      )}

      {onClose && (
        <div className="offer-badge-close cur-p" onClick={onClose}>
          <BlackRemoveIcon />
        </div>
      )}
    </div>
  );
};

export default OfferBar;
