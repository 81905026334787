import React from 'react';
import Modal from '../../shared/Modal';
import { WhatsappOutline, CallOutline } from '../../SvgIcon';

const OtpOptionsModal = ({
  title,
  open,
  closeModal,
  handleCall,
  handleWhatsApp,
}) => (
  <Modal
    open={open}
    maxWidth="sm"
    closeModal={closeModal}
    className="download-report-modal"
  >
    <div className="download-report-modal-content">
      <h2 className="section-text-4">{title}</h2>
      <div className="download-via mt8">
        <div className="tile" onClick={handleCall}>
          <CallOutline alt="PDF" />
          <span className="section-text-5">Call</span>
        </div>
        <div className="divider" />
        <div className="tile" onClick={handleWhatsApp}>
          <WhatsappOutline alt="XLS" />
          <span className="section-text-5">WhatsApp</span>
        </div>
      </div>
    </div>
  </Modal>
);

export default OtpOptionsModal;
