import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PRODUCT_IMAGE_SUGGESTIONS } from '../ApiUrls';
import { noop } from '../utils';
import { findIndexBy } from '../utils/collection';
import { getRequest } from '../utils/http';

let UNIQ_KEY = 0;

const useProductImages = (imgUrls = []) => {
  UNIQ_KEY += 1;
  const defaultImages = imgUrls.map((imgUrl) => ({
    imgUrl,
    id: UNIQ_KEY,
  }));

  const [fetchedImages, setFetchedImages] = useState(defaultImages);
  const [images, setImages] = useState(defaultImages);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(new Set());

  const addImage = (files, thumbnailType = '') => {
    // for video upload, we create image url from video id.
    // others are recieved from input field value
    if (typeof files === 'string') {
      setImages((imgs) => [
        ...imgs,
        {
          imgUrl: files,
          id: uuidv4(),
          thumbnailType,
        },
      ]);
    } else if (Array.isArray(files)) {
      setImages((imgs) => [
        ...imgs,
        ...files.map((file) => ({
          file,
          id: uuidv4(),
          thumbnailType,
        })),
      ]);
    } else {
      setImages([
        ...images,
        {
          files,
          id: uuidv4(),
          thumbnailType,
        },
      ]);
    }
  };

  const removeImage = (id, url) => {
    setFetchedImages((prevFetchedImages) => [
      ...prevFetchedImages.filter((each) => each.id !== id),
    ]);

    setImages((prevImages) => {
      const index = findIndexBy(prevImages, 'id', id);
      if (index !== -1) {
        const newArray = [...prevImages];
        newArray.splice(index, 1);
        return newArray;
      }
      return prevImages;
    });

    if (url) {
      setSelectedImages((prevSelectedImages) => {
        const filtered = new Set(prevSelectedImages);
        filtered.delete(url);
        return filtered;
      });
    }
  };

  const updateImage = (id, imgUrl) => {
    setImages((imgs) => {
      const newImages = [...imgs];
      const index = findIndexBy(images, 'id', id);
      newImages[index] = {
        ...newImages[index],
        imgUrl,
        file: null,
      };

      return newImages;
    });
  };

  const fetchImageSuggestions = (
    query,
    callback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    getRequest({
      url: PRODUCT_IMAGE_SUGGESTIONS(query),
      headers: { 'Access-Control-Allow-Origin': '*' },
      options: {
        timeout: 5000,
      },
    })
      .then((res) => {
        callback(res.body.splice(0, 12));
      })
      .catch(errorCallback)
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    images,
    fetchedImages,
    loading,
    addImage,
    setImages,
    setFetchedImages,
    removeImage,
    updateImage,
    selectedImages,
    setSelectedImages,
    fetchImageSuggestions,
  };
};

export default useProductImages;
