import React from 'react';
import { useUnsavedChangesModal } from '../context/UnsavedChangesProvider';

const LogoSection = ({ url, imgUrl, className }) => {
  const { isUnsavedChanges, setShowLeaveSiteModal, setLinkRedirect } =
    useUnsavedChangesModal();
  return (
    <a
      href={url}
      className={className}
      onClick={(e) => {
        if (isUnsavedChanges) {
          e.preventDefault();
          setShowLeaveSiteModal(true);
          setLinkRedirect('/');
        }
      }}
    >
      <img src={imgUrl} alt="dukaan" />
    </a>
  );
};

LogoSection.defaultProps = {
  className: 'logo',
  url: '/',
  imgUrl: '/images/logo.svg',
};

export default LogoSection;
