import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Menu, MenuItem } from '@material-ui/core';
import Switch from '../../shared/Switch';
import { noop } from '../../utils';
import { DEFAULT_END_TIME, DEFAULT_START_TIME, isFullDay } from './constants';
import { toTitleCase } from '../../utils/string';
import SpinnerButton from '../../shared/SpinnerButton';
import { isNotEmptyOrNull } from '../../shared/Form/Shared';

export const defaultCountdownTimerValue = ({
  is_active: isActive = false,
  timer_msg: msg = 'Special deal ends in',
  store_method: method = 'daily',
  start_time: start = '10:00:00',
  end_time: end = '22:00:00',
  days = 0,
  hours = 0,
  minutes = 0,
}) => ({
  is_active: isActive,
  timer_msg: msg,
  store_method: method,
  start_time: start,
  end_time: end,
  days,
  hours,
  minutes,
});

export const generateTimepickerOptions = (
  start = DEFAULT_START_TIME,
  end = DEFAULT_END_TIME
) => {
  const startTime = moment(start, 'HH:mm:ss');
  const endTime = moment(end === '00:00:00' ? '23:59:59' : end, 'HH:mm:ss');
  // this is to generate dropdown options, hence need to pass highest end time instead of 00:00

  const allTimes = [];

  while (startTime < endTime) {
    startTime.add(30, 'minutes');
    allTimes.push(startTime.format('HH:mm:ss'));
  }

  allTimes.unshift(allTimes.pop());

  return allTimes;
};

export const generateTimepickerOptionsForDropdown = (startTime, endTime) => {
  const timeList = generateTimepickerOptions(startTime, endTime);
  for (let i = 0; i < timeList.length; i += 1) {
    timeList[i] = {
      label: moment(timeList[i], 'HH:mm:ss').format('hh:mm A'),
      value: timeList[i],
    };
  }
  return timeList;
};

export class TimeslotPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      options: generateTimepickerOptions(DEFAULT_START_TIME, DEFAULT_END_TIME),
      currentlyOpen: '',
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onChangeTime = (selectedTime) => {
    const { currentlyOpen } = this.state;
    const { onUpdateTimeSlot, fromTime, toTime } = this.props;

    if (currentlyOpen === 'fromTime') {
      if (selectedTime === toTime) {
        this.onSet24Hours();
        return;
      }
      if (
        toTime === DEFAULT_START_TIME &&
        selectedTime === DEFAULT_START_TIME
      ) {
        onUpdateTimeSlot({
          from_time: DEFAULT_START_TIME,
          to_time: DEFAULT_END_TIME,
        });
      } else {
        onUpdateTimeSlot({ from_time: selectedTime });
      }
    } else if (currentlyOpen === 'toTime') {
      if (selectedTime === fromTime) {
        this.onSet24Hours();
        return;
      }
      onUpdateTimeSlot({ to_time: selectedTime });
    } else if (currentlyOpen === '24hours') {
      onUpdateTimeSlot({
        from_time: selectedTime,
        to_time: moment(selectedTime, 'HH:mm:ss')
          .add(12, 'hours')
          .format('HH:mm:ss'),
      });
    }
    this.handleClose();
  };

  onSet24Hours = () => {
    const { onUpdateTimeSlot } = this.props;

    onUpdateTimeSlot({
      from_time: DEFAULT_START_TIME,
      to_time: DEFAULT_END_TIME,
    });
    this.handleClose();
  };

  render() {
    const { options, currentlyOpen, anchorEl } = this.state;
    const { fromTime, toTime } = this.props;
    const selectedOption = currentlyOpen === 'fromTime' ? fromTime : toTime;

    return (
      <>
        {isFullDay(fromTime, toTime) ? (
          <SpinnerButton
            type="button"
            className={cx('store-timing-time-picker-btn mr16', {
              'time-picker-focus': isNotEmptyOrNull({ value: anchorEl }),
            })}
            aria-controls="timepicker-menu"
            aria-haspopup="true"
            onClick={(e) => {
              this.setState({
                currentlyOpen: '24hours',
                options: generateTimepickerOptions(
                  DEFAULT_START_TIME,
                  DEFAULT_END_TIME
                ),
                anchorEl: e.currentTarget,
              });
            }}
          >
            24 hours
          </SpinnerButton>
        ) : (
          <div>
            <SpinnerButton
              type="button"
              className={cx('store-timing-time-picker-btn mr16', {
                'time-picker-focus':
                  isNotEmptyOrNull({ value: anchorEl }) &&
                  currentlyOpen === 'fromTime',
              })}
              aria-controls="timepicker-menu"
              aria-haspopup="true"
              onClick={(e) => {
                this.setState({
                  currentlyOpen: 'fromTime',
                  options: generateTimepickerOptions(
                    DEFAULT_START_TIME,
                    DEFAULT_END_TIME
                  ),
                  anchorEl: e.currentTarget,
                });
              }}
            >
              {moment(fromTime, 'HH:mm').format('hh:mm A')}
            </SpinnerButton>
            -
            <SpinnerButton
              type="button"
              className={cx('store-timing-time-picker-btn ml16', {
                'time-picker-focus':
                  isNotEmptyOrNull({ value: anchorEl }) &&
                  currentlyOpen === 'toTime',
              })}
              aria-controls="timepicker-menu"
              aria-haspopup="true"
              onClick={(e) => {
                this.setState({
                  currentlyOpen: 'toTime',
                  options: generateTimepickerOptions(
                    DEFAULT_START_TIME,
                    DEFAULT_END_TIME
                  ),
                  anchorEl: e.currentTarget,
                });
              }}
            >
              {moment(toTime, 'HH:mm').format('hh:mm A')}
            </SpinnerButton>
          </div>
        )}

        <Menu
          id="timepicker-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          transformOrigin={{
            vertical: 'top',
          }}
          classes={{ paper: 'show-scrollbar' }}
          PaperProps={{
            style: {
              maxHeight: 152,
              width: 128,
              marginTop: 48,
            },
          }}
        >
          <MenuItem
            onClick={this.onSet24Hours}
            classes={{
              root: 'store-timing-menu-item',
            }}
          >
            <span
              className={cx({
                'selected-time-slot': isFullDay(fromTime, toTime),
              })}
            >
              24 hours
            </span>
          </MenuItem>
          <div className="menu-items-divider" />
          {options.map((option) => (
            <MenuItem
              key={option}
              classes={{
                root: 'store-timing-menu-item',
              }}
              onClick={() => {
                this.onChangeTime(option);
              }}
            >
              <span
                className={cx({
                  'selected-time-slot':
                    selectedOption === option && !isFullDay(fromTime, toTime),
                })}
              >
                {moment(option, 'HH:mm:ss').format('hh:mm A')}
              </span>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export const Day = ({
  online = false,
  from_time: fromTime,
  to_time: toTime,
  day,
  onUpdateDay = noop,
  hasCustomDefaultTime = false,
  customDefaultFromTime = DEFAULT_START_TIME,
  customDefaultToTime = DEFAULT_START_TIME,
}) => {
  const toggleOnline = () => {
    if (online === true) {
      onUpdateDay({ online: false, from_time: null, to_time: null });
    } else {
      onUpdateDay({
        online: true,
        from_time: hasCustomDefaultTime
          ? customDefaultFromTime
          : DEFAULT_START_TIME,
        to_time: hasCustomDefaultTime ? customDefaultToTime : DEFAULT_END_TIME,
      });
    }
  };

  return (
    <div className={cx('store-timing-day-item', { online })}>
      <span className="text-8 day pt2">{toTitleCase(day)}</span>
      <Switch
        checked={online}
        onChange={toggleOnline}
        className="mr16 red-green-switch"
      />
      <span className="text-8 c-gray-1 mr24">{online ? 'Open' : 'Closed'}</span>
      {online && (
        <TimeslotPicker
          fromTime={fromTime}
          toTime={toTime}
          onUpdateTimeSlot={onUpdateDay}
        />
      )}
    </div>
  );
};
