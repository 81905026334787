/* eslint-disable eqeqeq */
import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import CustomSelect from '../CustomSelect';
import AsyncSelect from '../CustomSelect/AsyncSelect';
import { noop } from '../../utils';
import { isArray, mapByKey } from '../../utils/collection';

import FormGroup from './FormGroup';

const FormCustomSelect = (props) => {
  const {
    name,
    validate,
    labelText,
    className,
    required,
    options,
    onChange,
    isAsync,
    helpText,
    size,
    error,
    selected,
    showRequired,
    isInputPhoneCode,
    ...restProps
  } = props;

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        let defaultValue;
        if (isArray(input.value)) {
          defaultValue = options.filter((option) =>
            input.value.includes(option.value)
          );
        } else if (typeof selected === 'object' && selected !== null) {
          defaultValue = selected;
        } else {
          defaultValue = options.find((option) => option.value == input.value);
        }

        const onSelectChange = (option) => {
          if (option) {
            if (isArray(option)) {
              input.onChange(mapByKey(option, 'value'));
            } else {
              const { value } = option;
              input.onChange(value);
            }
          } else {
            input.onChange('');
          }
          onChange(option);
        };

        const selectProps = {
          name,
          value: defaultValue,
          onSelectChange,
        };

        return (
          <FormGroup
            labelText={labelText}
            required={required}
            size={size}
            helpText={helpText}
            showRequired={showRequired}
            className={cx('custom-select-wrap', className, {
              'has-error':
                error || (meta.error && meta.touched) || meta.submitError,
            })}
          >
            {isAsync ? (
              <AsyncSelect {...restProps} {...selectProps} />
            ) : (
              <CustomSelect
                options={options}
                {...selectProps}
                {...restProps}
                isInputPhoneCode={isInputPhoneCode}
              />
            )}
          </FormGroup>
        );
      }}
    </Field>
  );
};

FormCustomSelect.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: false,
  options: [], // [{label: '', value: ''}]
  onChange: noop,
  isAsync: false,
  helpText: '',
  size: 'lg', // md
  showRequired: false,
  selected: null,
  isInputPhoneCode: false,
};

export default FormCustomSelect;
