import cx from 'classnames';
import { ChipCrossIcon } from '../SvgIcon';
import { noop } from '../utils';

export default function Chip({
  color = null,
  isRemovable = false,
  onClick = noop,
  children,
  className = '',
  isAttribute = false,
  ...props
}) {
  return (
    <div className={cx('chip', className)} {...props}>
      {color && (
        <span
          className="chip-color"
          style={{
            backgroundColor: color,
          }}
        />
      )}
      <p
        className={cx({
          'section-text-14': isAttribute,
          'chip-text': !isAttribute,
          'remove-icon-present': isRemovable,
        })}
      >
        {children}
      </p>
      {isRemovable && (
        <ChipCrossIcon
          className="chip-remove-icon"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        />
      )}
    </div>
  );
}
