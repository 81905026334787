export const rawCurrencies = [
  { cc: 'AED', symbol: 'AED', name: 'UAE dirham' },
  { cc: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  { cc: 'ALL', symbol: 'L', name: 'Albanian lek' },
  { cc: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  { cc: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
  { cc: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  { cc: 'ARS', symbol: '$', name: 'Argentine peso' },
  {
    cc: 'AUD',
    symbol: '$',
    name: 'Australia',
    currency_code: 'AUD',
    country_code: 'AU',
    icon: 'AU',
    locale: 'en-AU',
    phone_code: 61,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 9,
    id: 9,
  },
  { cc: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  { cc: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  {
    cc: 'BAM',
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka',
  },
  { cc: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  {
    cc: 'BDT',
    symbol: '\u09f3',
    name: 'Bangladesh',
    id: 14,
    country_code: 'BD',
    currency_code: 'BDT',
    icon: 'BD',
    phone_code: 880,
    curr_group_char: ',',
    curr_mon_char: '.',
    keyboard_code: 0,
    group_size: 2,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
  },
  { cc: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  { cc: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  { cc: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  { cc: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  { cc: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  { cc: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  {
    cc: 'BRL',
    symbol: 'R$',
    name: 'Brazil',
    currency_code: 'BRL',
    country_code: 'BR',
    icon: 'BR',
    locale: 'pt-BR',
    phone_code: 55,
    postal_code_length: 9,
    postal_code_type: 'int',
    mobile_length_min: 11,
    mobile_length_max: 11,
    id: 24,
  },
  { cc: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  { cc: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { cc: 'BWP', symbol: 'P', name: 'Botswana pula' },
  { cc: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  { cc: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { cc: 'CDF', symbol: 'F', name: 'Congolese franc' },
  { cc: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  {
    cc: 'CLP',
    symbol: '$',
    name: 'Chile',
    currency_code: 'CLP',
    country_code: 'CL',
    icon: 'CL',
    locale: 'es-CL',
    phone_code: 56,
    postal_code_length: 8,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 9,
    id: 35,
  },
  { cc: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  { cc: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  { cc: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  { cc: 'CUC', symbol: '$', name: 'Cuban peso' },
  { cc: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  { cc: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  { cc: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  { cc: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  { cc: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  { cc: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  { cc: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  { cc: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  { cc: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  { cc: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { cc: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  { cc: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { cc: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  { cc: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { cc: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  { cc: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  { cc: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  { cc: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  { cc: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  {
    cc: 'HKD',
    symbol: 'HK$',
    name: 'Hong Kong',
    currency_code: 'HKD',
    country_code: 'HK',
    icon: 'HK',
    locale: 'zh-HK',
    phone_code: 852,
    postal_code_length: 0,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 8,
    id: 0,
  },
  { cc: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  { cc: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  { cc: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  { cc: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  {
    cc: 'IDR',
    symbol: 'Rp',
    name: 'Indonesia',
    id: 78,
    country_code: '"id"',
    currency_code: '"id"R',
    icon: 'id',
    locale: '"id"-"id"',
    phone_code: 62,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 11,
  },
  {
    cc: 'ILS',
    symbol: '\u20aa',
    name: 'Israel',
    currency_code: 'ILS',
    country_code: 'IL',
    icon: 'IL',
    locale: 'he-IL',
    phone_code: 972,
    postal_code_length: 7,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 9,
    id: 82,
  },
  {
    cc: 'INR',
    symbol: '\u20b9',
    name: 'India',
    id: 77,
    country_code: 'IN',
    currency_code: 'INR',
    icon: 'IN',
    locale: 'en-IN',
    phone_code: 91,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
  },
  { cc: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { cc: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  { cc: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { cc: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  { cc: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  {
    cc: 'JPY',
    symbol: '\u00a5',
    name: 'Japan',
    currency_code: 'JPY',
    country_code: 'JP',
    icon: 'JP',
    locale: 'ja-JP',
    phone_code: 81,
    postal_code_length: 8,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 85,
  },
  { cc: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  { cc: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
  { cc: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  { cc: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  { cc: 'KPW', symbol: 'W', name: 'North Korean won' },
  {
    cc: 'KRW',
    symbol: 'W',
    name: 'South Korea',
    currency_code: 'KPW',
    country_code: 'KR',
    icon: 'KR',
    locale: 'ko-KR',
    phone_code: 82,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 5,
    mobile_length_max: 20,
    id: 90,
  },
  { cc: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  { cc: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  { cc: 'LAK', symbol: 'KN', name: 'Lao kip' },
  { cc: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  {
    cc: 'LKR',
    symbol: 'Rs',
    name: 'Sri Lanka',
    id: 164,
    country_code: 'LK',
    currency_code: 'LKR',
    locale: 'ta-LK',
    icon: 'LK',
    phone_code: 94,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 7,
  },
  { cc: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  { cc: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  { cc: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  { cc: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  { cc: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  { cc: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
  { cc: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  { cc: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  { cc: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  { cc: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  { cc: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  { cc: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  { cc: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  { cc: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { cc: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  { cc: 'MXN', symbol: '$', name: 'Mexican peso' },
  {
    cc: 'MYR',
    symbol: 'RM',
    name: 'Malaysia',
    currency_code: 'MYR',
    country_code: 'MY',
    icon: 'MY',
    locale: 'ms-MY',
    phone_code: 60,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 7,
    mobile_length_max: 7,
    id: 105,
  },
  { cc: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  { cc: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  { cc: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  { cc: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { cc: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  { cc: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  {
    cc: 'NZD',
    symbol: 'NZ$',
    name: 'New Zealand',
    currency_code: 'NZD',
    country_code: 'NZ',
    icon: 'NZ',
    locale: 'en-NZ',
    phone_code: 64,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 12,
    id: 125,
  },
  { cc: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  { cc: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  { cc: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { cc: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  { cc: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  {
    cc: 'PKR',
    symbol: 'Rs.',
    name: 'Pakistan',
    id: 132,
    country_code: 'PK',
    currency_code: 'PKR',
    icon: 'PK',
    locale: 'en-PK',
    phone_code: 92,
    postal_code_length: 5,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
  },
  { cc: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  { cc: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  { cc: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  { cc: 'RON', symbol: 'L', name: 'Romanian leu' },
  { cc: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  {
    cc: 'RUB',
    symbol: 'R',
    name: 'Russia',
    currency_code: 'RUB',
    country_code: 'RU',
    icon: 'RU',
    locale: 'ru-RU',
    phone_code: 7,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 143,
  },
  { cc: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  { cc: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  { cc: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  { cc: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  { cc: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  {
    cc: 'SGD',
    symbol: 'S$',
    name: 'Singapore',
    currency_code: 'SGD',
    country_code: 'SG',
    icon: 'SG',
    locale: 'zh-SG',
    phone_code: 65,
    postal_code_length: 6,
    postal_code_type: 'int',
    mobile_length_min: 8,
    mobile_length_max: 8,
    id: 156,
  },
  { cc: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  { cc: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  { cc: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  { cc: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  { cc: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  { cc: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  { cc: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  { cc: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  { cc: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  { cc: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  { cc: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  {
    cc: 'TWD',
    symbol: 'NT$',
    name: 'Taiwan',
    currency_code: 'TWD',
    country_code: 'TW',
    icon: 'TW',
    locale: 'zh-TW',
    phone_code: 886,
    postal_code_length: 7,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 9,
    id: 0,
  },
  { cc: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  { cc: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { cc: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  {
    cc: 'USD',
    symbol: '$',
    name: 'United States',
    currency_code: 'USD',
    country_code: 'US',
    icon: 'US',
    locale: 'en-US',
    phone_code: 1,
    postal_code_length: 10,
    postal_code_type: 'int',
    mobile_length_min: 10,
    mobile_length_max: 10,
    id: 185,
  },
  { cc: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  { cc: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  { cc: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  { cc: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  { cc: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  { cc: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  { cc: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  { cc: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  { cc: 'XPF', symbol: 'F', name: 'CFP franc' },
  { cc: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  {
    cc: 'ZAR',
    symbol: 'R',
    name: 'South Africa',
    currency_code: 'ZAR',
    country_code: 'ZA',
    icon: 'ZA',
    locale: 'en-ZA',
    phone_code: 27,
    postal_code_length: 4,
    postal_code_type: 'int',
    mobile_length_min: 9,
    mobile_length_max: 9,
    id: 161,
  },
  { cc: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  { cc: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' },
  { cc: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
];

export const PAYPAL_SUPPORTED_CURRENCY_CODES = [
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MYR',
  'MXN',
  'TWD',
  'NZD',
  'NOK',
  'PHP',
  'PLN',
  'GBP',
  'RUB',
  'SGD',
  'SEK',
  'CHF',
  'THB',
  'USD',
  'NGN',
  'PKR',
  'KES',
];

export const PAYPAL_UNSUPPORTED_CURRENCY_CODES = [
  'AFN',
  'BDT',
  'XAF',
  'XOF',
  'KPW',
  'GHS',
  'HTG',
  'IRR',
  'IQD',
  'LBP',
  'LRD',
  'LYD',
  'MDL',
  'MMK',
  'PKR',
  'PYG',
  'XCD',
  'SSP',
  'SDG',
  'SYP',
  'UZS',
  'ILS',
];

export const PAYPAL_UNSUPPORTED_COUNTRY_CODES = [
  'AF',
  'BD',
  'LB',
  'MM',
  'PK',
  'PY',
  'ZW',
  'SR',
  'GH',
  'PS',
];

const currencies = rawCurrencies.map((currency) => ({
  ...currency,
  value: currency.cc,
  label: currency.cc,
}));

export const currenciesWithSymbolLabel = currencies.map((currency) => ({
  ...currency,
  label: currency.symbol,
}));

export const getCurrencyByCode = (code, key = 'value') =>
  (currencies.find((currency) => code === currency.cc) || {})[key] ||
  currencies[0][key];

export const defaultCurrency = currencies.find(
  (currency) => currency.cc === 'INR'
);

export default currencies;
