import axios from 'axios';
import moment from 'moment';
import qs from 'query-string';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  ALL_ORDERS_URL,
  CLUB_ORDER_URL,
  ORDERS_STATS_WITH_DURATION_MAPPING_URL,
  ORDER_BULK_STATUS_UPDATE_URL,
  ORDER_COUNT_STATS,
  ORDER_SOURCES,
  STORE_TAGS,
} from '../ApiUrls';
import { LIST_VIEW } from '../constants';
import {
  LAST_MODIFIED_DATE,
  LAST_MODIFIED_DATE_UTC,
  ORDER_DATE_ON,
  ORDER_DATE_ON_UTC,
} from '../Manage/Delivery/constants';
import {
  ACCEPTED_STATUS,
  ALL_STATUS,
  CANCELLED_BY_CUSTOMER,
  CANCELLED_STATUS,
  DELIVERED_STATUS,
  FAILED_STATUS,
  MODIFIED_STATUS,
  PENDING_STATUS,
  REJECTED_STATUS,
  RETURN_STATUS,
  RTO_STATUS,
  SHIPPED_STATUS,
  TAGS_FOR,
  PAGE_SIZE,
} from '../Orders/constants';
import { DEFAULT_DATE_RANGE } from '../shared/DatePicker/util';
import { rootUrls } from '../Urls';
import { noop } from '../utils';
import { formatServerDate } from '../utils/date';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import { useAppContext } from './AppContext';
import { useStorePreferences } from './StorePreferenceProvider';

const DEFAULT_ORDER_PARAMS = {
  filters: {},
  orderDateType: ORDER_DATE_ON_UTC,
  orderingKey: ORDER_DATE_ON,
  orderingDirection: 'desc',
  page: 1,
  search: '',
  status: ALL_STATUS,
  tags: [],
  source: [],
  endDate: formatServerDate(moment()),
  startDate: formatServerDate(moment().subtract(7, 'days')),
  selection: DEFAULT_DATE_RANGE.SELECTION,
};

const ORDER_COLUMNS_PREFERENCE_KEY = 'order_listing_column';
const ORDER_COLUMNS_PREFERENCE_ID = 40;

const TOGGLE_OPTIONS = [
  {
    value: ALL_STATUS,
    label: 'All',
    count: 0,
  },
  {
    value: PENDING_STATUS,
    label: 'Pending',
    count: 0,
  },
  {
    value: ACCEPTED_STATUS,
    label: 'Accepted',
    count: 0,
  },
  {
    value: SHIPPED_STATUS,
    label: 'Shipped',
    count: 0,
  },
  {
    value: DELIVERED_STATUS,
    label: 'Delivered',
    count: 0,
  },
  {
    label: 'Others',
    count: 0,
  },
];

const OTHER_TOGGLE_OPTIONS = [
  {
    value: MODIFIED_STATUS,
    label: 'Modified',
    count: 0,
  },
  {
    value: REJECTED_STATUS,
    label: 'Rejected',
    count: 0,
  },
  {
    value: CANCELLED_BY_CUSTOMER,
    label: 'Cancelled by buyer',
    count: 0,
  },
  {
    value: CANCELLED_STATUS,
    label: 'Cancelled',
    count: 0,
  },
  {
    value: FAILED_STATUS,
    label: 'Failed',
    count: 0,
  },
  {
    value: RETURN_STATUS,
    label: 'Returned',
    count: 0,
  },
];

const ORDER_TABLE_COLUMNS = [
  { title: 'Order ID', width: '165px', show: true, showAlways: true },
  {
    title: 'Date',
    tooltipAscText: 'Sort by latest to oldest',
    tooltipDscText: 'Sort by oldest to latest',
    sortable: true,
    key: ORDER_DATE_ON,
    preferenceKey: 'date',
    width: '200px',
  },
  {
    title: 'Customer',
    width: 'minmax(240px, 1fr)',
    preferenceKey: 'customer',
  },
  {
    title: 'Items',
    tooltipAscText: 'Sort by high to low',
    tooltipDscText: 'Sort by low to high',
    sortable: true,
    key: 'product_count',
    preferenceKey: 'items',
    width: '80px',
  },
  {
    title: 'Payment',
    width: '120px',
    preferenceKey: 'payment',
  },
  {
    title: 'Status',
    width: '180px',
    preferenceKey: 'status',
  },
  {
    title: 'Amount',
    tooltipAscText: 'Sort by high to low',
    tooltipDscText: 'Sort by low to high',
    sortable: true,
    key: 'total_cost',
    preferenceKey: 'amount',
    width: '120px',
  },
  {
    title: 'Channel',
    sortable: false,
    key: 'channel',
    preferenceKey: 'channel',
    width: '120px',
  },
  {
    title: 'Source',
    sortable: false,
    key: 'source',
    preferenceKey: 'source',
    width: '120px',
  },
  {
    title: 'Tags',
    sortable: false,
    key: 'tags',
    preferenceKey: 'tags',
    width: '200px',
  },
  {
    title: 'UTM Source',
    sortable: false,
    key: 'utm_source',
    preferenceKey: 'utm_source',
    width: '200px',
  },
  {
    title: 'UTM Medium',
    sortable: false,
    key: 'utm_medium',
    preferenceKey: 'utm_medium',
    width: '200px',
  },
  {
    title: 'UTM Campaign',
    sortable: false,
    key: 'utm_campaign',
    preferenceKey: 'utm_campaign',
    width: '200px',
  },
  {
    title: 'UTM Term',
    sortable: false,
    key: 'utm_term',
    preferenceKey: 'utm_term',
    width: '200px',
  },
  {
    title: 'UTM Content',
    sortable: false,
    key: 'utm_content',
    preferenceKey: 'utm_content',
    width: '200px',
  },
  {
    title: 'UTM Query',
    sortable: false,
    key: 'utm_query',
    preferenceKey: 'utm_query',
    width: '200px',
  },
];

const OrdersContext = createContext({
  allOptionTabsData: [],
  allOrdersCount: null,
  allStatsLoading: false,
  acceptedOrdersCount: 0,
  optionTabs: {},
  otherOptionTabs: {},
  selectedOption: null,
  setSelectedOption: noop,
  isInfiniteScroll: false,
  orderListingView: LIST_VIEW,
  setOrderListingView: noop,
  ordersLoading: false,
  orders: [],
  submitting: false,
  ordersCount: null,
  orderParams: DEFAULT_ORDER_PARAMS,
  appliedTags: [],
  setAppliedTags: noop,
  hasMoreTags: false,
  orderTags: [],
  orderTagsCount: null,
  tagsLoading: false,
  tagsPageNumber: 1,
  setTagsPageNumber: noop,
  orderSources: [],
  sourcesLoading: false,
  sourcesPageNumber: 1,
  setSourcesPageNumber: noop,
  bulkOrderAction: noop,
  fetchAllStats: noop,
  fetchOrders: noop,
  clubOrders: noop,
  getStoreTags: noop,
  getOrderSources: noop,
  updateOrderParams: noop,
  constructParams: noop,
  refetch: noop,
  setIsInfiniteScroll: noop,
  pageSize: PAGE_SIZE,
  updateOrdersCount: noop,
  orderTableColumns: ORDER_TABLE_COLUMNS,
  setOrderTableColumns: noop,
  handleToggleColumn: noop,
  ORDER_COLUMNS_PREFERENCE_KEY,
  ORDER_COLUMNS_PREFERENCE_ID,
  isLifetimeOrdersLoading: false,
  lifetimeOrdersExists: undefined,
  resetOrderParams: noop,
  canDownloadReport: false,
});

export const useOrdersContext = () => useContext(OrdersContext);

const OrdersProvider = ({ children }) => {
  const {
    business,
    isLoggedIn,
    canCreateVendor,
    isAdmin,
    isOwner,
    isManagerL1,
    isManagerL2,
  } = useAppContext();
  const { preferences, updatePreferences, fetchPreferences } =
    useStorePreferences();

  const canDownloadReport = isAdmin || isOwner || isManagerL1 || isManagerL2;

  // stats
  const [allOrdersCount, setAllOrdersCount] = useState(null);
  const [allStatsLoading, setAllStatsLoading] = useState(false);
  const [acceptedOrdersCount, setAcceptedOrdersCount] = useState(0);
  const [allOptionTabsData, setAllOptionsTabsData] = useState([
    ...TOGGLE_OPTIONS,
    ...OTHER_TOGGLE_OPTIONS,
  ]);
  const [optionTabs, setOptionsTabs] = useState(TOGGLE_OPTIONS);
  const [otherOptionTabs, setOtherOptionTabs] = useState(OTHER_TOGGLE_OPTIONS);
  const [selectedOption, setSelectedOption] = useState(null);
  // general
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(false);
  const [orderListingView, setOrderListingView] = useState(LIST_VIEW);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [ordersCount, setOrdersCount] = useState(null);
  // url params
  const [orderParams, setOrderParams] = useState(DEFAULT_ORDER_PARAMS);
  // tags
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [orderTags, setOrderTags] = useState([]);
  const [orderTagsCount, setOrderTagsCount] = useState(null);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tagsPageNumber, setTagsPageNumber] = useState(1);
  const [rejectedByVendorOrderCount, setRejectedByVendorOrderCount] =
    useState(0);
  // sources
  const [orderSources, setOrderSources] = useState([]);
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [sourcesPageNumber, setSourcesPageNumber] = useState(1);

  //  Order columns filter
  const [orderTableColumns, setOrderTableColumns] =
    useState(ORDER_TABLE_COLUMNS);
  const [gridColumnTemplate, setGridColumnTemplate] = useState([
    '165px',
    '200px',
    'minmax(240px, 1fr)',
    '80px',
    '120px',
    '180px',
    '120px',
    '120px',
    '120px',
    '200px',
    '200px',
    '200px',
    '200px',
  ]);

  // lifetime orders exist
  const [isLifetimeOrdersLoading, setIsLifetimeOrdersLoading] = useState(false);
  const [lifetimeOrdersExists, setLifetimeOrdersExists] = useState(false);

  const constructParams = (payload, noStatus = false) => {
    const {
      filters,
      orderDateType,
      orderingKey,
      orderingDirection,
      page,
      search,
      status,
      startDate,
      endDate,
      tags,
      source,
    } = payload;

    const params = {
      ...filters,
      page,
      search,
      status,
      startDate,
      endDate,
      tags,
      source,
    };

    let dateType = orderDateType;
    if (orderDateType === ORDER_DATE_ON_UTC) {
      dateType = ORDER_DATE_ON;
    }
    if (orderDateType === LAST_MODIFIED_DATE_UTC) {
      dateType = LAST_MODIFIED_DATE;
    }
    if (params.startDate) {
      params[`${dateType}_after`] = params.startDate;
    }
    if (params.endDate) {
      params[`${dateType}_before`] = params.endDate;
    }
    delete params.endDate;
    delete params.startDate;

    if (orderingKey !== '') {
      params.ordering = `${
        orderingDirection === 'asc' ? '' : '-'
      }${orderingKey}`;
    }

    if (!search) {
      delete params.search;
    }

    if (status === null || parseInt(status) === ALL_STATUS || noStatus) {
      delete params.status;
    }

    if (tags === null || tags?.length === 0) {
      delete params.tags;
    }

    if (true) {
      // if any utm column is enabled in preferences
      params.utm_data = 'True';
    }

    return params;
  };

  const bulkOrderAction = (
    payload = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);

    patchRequest({ url: ORDER_BULK_STATUS_UPDATE_URL, data: payload })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setSubmitting(false));
  };

  const clubOrders = (tableUuid) =>
    postRequest({
      url: CLUB_ORDER_URL(tableUuid),
    });

  const updateOrdersCount = (status, stats = allOptionTabsData) => {
    const { count = 0 } =
      stats.find((item) => {
        if (Array.isArray(status)) {
          return (
            JSON.stringify(item.value) ===
            JSON.stringify(status.map((i) => Number(i)))
          );
        }
        return item.value === status;
      }) ?? {};
    setOrdersCount(count);
  };

  const fetchOrders = (updatedOrderParams = {}) => {
    let cancel;
    setOrdersLoading(true);
    const apiData =
      Object.keys(updatedOrderParams)?.length > 0
        ? { ...constructParams(updatedOrderParams) }
        : { ...constructParams(orderParams) };
    getRequest({
      url: ALL_ORDERS_URL,
      data: apiData,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((res) => {
        const { results } = res;

        // check lifetime data after first order
        if (!lifetimeOrdersExists && orders.length === 0)
          fetchLifetimeOrdersExists();

        setOrders(results);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return false;
        }
      })
      .finally(() => {
        setOrdersLoading(false);
      });
    return () => cancel();
  };

  const fetchAllStats = (params) => {
    if (!isLoggedIn) return;
    setAllStatsLoading(true);
    getRequest({
      url: ORDERS_STATS_WITH_DURATION_MAPPING_URL,
      data: { ...constructParams(params, true) },
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then(({ data: allData }) => {
        const data = allData;
        // fetch data using order_creation as key
        // sum the counts accordingly
        const allOrdersSum =
          data[PENDING_STATUS] +
          data[MODIFIED_STATUS] +
          data[ACCEPTED_STATUS] +
          data[SHIPPED_STATUS] +
          data[DELIVERED_STATUS] +
          data[CANCELLED_BY_CUSTOMER] +
          data[CANCELLED_STATUS] +
          data[REJECTED_STATUS] +
          data[FAILED_STATUS] +
          data[RETURN_STATUS] +
          data[RTO_STATUS];
        setAllOrdersCount(allOrdersSum);

        const otherOrdersSum =
          data[MODIFIED_STATUS] +
          data[REJECTED_STATUS] +
          data[CANCELLED_BY_CUSTOMER] +
          data[CANCELLED_STATUS] +
          data[RETURN_STATUS] +
          data[FAILED_STATUS] +
          data[RTO_STATUS];

        const newOptionTabs = TOGGLE_OPTIONS.map((option) => {
          if (option.value === ALL_STATUS) option.count = allOrdersSum;
          else if (option.label === 'Others') option.count = otherOrdersSum;
          else option.count = data[option.value];
          return option;
        });

        const newOtherTabOptions = [
          {
            value: MODIFIED_STATUS,
            label: 'Modified',
            count: data[MODIFIED_STATUS],
          },
          {
            value: REJECTED_STATUS,
            label: 'Rejected',
            count: data[REJECTED_STATUS],
          },
          {
            value: CANCELLED_BY_CUSTOMER,
            label: 'Cancelled by buyer',
            count: data[CANCELLED_BY_CUSTOMER],
          },
          {
            value: CANCELLED_STATUS,
            label: 'Cancelled',
            count: data[CANCELLED_STATUS],
          },
          {
            value: FAILED_STATUS,
            label: 'Failed',
            count: data[FAILED_STATUS] + data[RTO_STATUS],
          },
          {
            value: RETURN_STATUS,
            label: 'Returned',
            count: data[RETURN_STATUS],
          },
        ];

        setAcceptedOrdersCount(data[ACCEPTED_STATUS]);
        setOptionsTabs(newOptionTabs);
        setOtherOptionTabs(newOtherTabOptions);
        setAllOptionsTabsData([...newOptionTabs, ...newOtherTabOptions]);
        updateOrdersCount(params.status, [
          ...newOptionTabs,
          ...newOtherTabOptions,
        ]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setAllStatsLoading(false));
  };

  const fetchRejectedByVendorStats = (params) => {
    getRequest({
      url: ORDERS_STATS_WITH_DURATION_MAPPING_URL,
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then(({ data }) => {
        setRejectedByVendorOrderCount(data[SHIPPED_STATUS]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getStoreTags = () => {
    setTagsLoading(true);
    const params = { tag_for: TAGS_FOR.ORDER, page: tagsPageNumber };
    getRequest({
      url: STORE_TAGS(business.uuid),
      data: params,
    })
      .then((res) => {
        const { count, results = [] } = res ?? {};
        setOrderTagsCount(count);

        if (tagsPageNumber === 1) {
          setHasMoreTags(results.length < count);
          setOrderTags(results);
        } else {
          setHasMoreTags(orderTags.length + results.length < count);
          setOrderTags((prev) => [...new Set([...prev, ...results])]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setTagsLoading(false));
  };

  const getOrderSources = () => {
    setSourcesLoading(true);

    getRequest({
      url: ORDER_SOURCES,
    })
      .then((res) => {
        const {
          data: { sources },
        } = res ?? {};

        setOrderSources(sources);
      })
      .catch((e) => console.log(e))
      .finally(() => setSourcesLoading(false));
  };

  const refetch = (updatedOrderParams = {}) => {
    if (Object.keys(updatedOrderParams)?.length > 0) {
      fetchAllStats(updatedOrderParams);
      fetchOrders(updatedOrderParams);
    } else {
      fetchAllStats(orderParams);
      fetchOrders();
    }
  };

  const updateOrderParams = (params, reason) => {
    // use reason to debug the cause for order params update
    // console.log({ params }, reason);
    if (reason === 'search' && params.search === '') return false;
    let prevParams;
    setOrderParams((prev) => {
      prevParams = prev;
      return { ...prev, ...params };
    });

    if (
      Object.keys(params).some((item) =>
        [
          'search',
          'filters',
          'tags',
          'endDate',
          'selection',
          'startDate',
        ].includes(item)
      )
    ) {
      fetchAllStats({ ...prevParams, ...params });
    }
  };

  const resetOrderParams = (params = {}, reason = '') => {
    // use reason to debug the cause for order params update
    // console.log({ params }, reason);
    setOrderParams({ ...DEFAULT_ORDER_PARAMS, ...params });
    fetchAllStats({ ...DEFAULT_ORDER_PARAMS, ...params });
  };

  const handleRefreshColumnsFromPreferences = (updatedColPreferences) => {
    const colPreferences =
      updatedColPreferences ??
      preferences[ORDER_COLUMNS_PREFERENCE_KEY]?.value ??
      {};

    const updatedColumns = [];
    orderTableColumns.forEach((col) => {
      updatedColumns.push({
        ...col,
        show: col.showAlways ?? colPreferences[col.preferenceKey],
      });
    });

    setOrderTableColumns(updatedColumns);
  };

  const handleToggleColumn = (colPreferences) => {
    handleRefreshColumnsFromPreferences(colPreferences);
    updatePreferences(
      { id: ORDER_COLUMNS_PREFERENCE_ID, value: colPreferences },
      fetchPreferences,
      false // showSnackbar: false
    );
  };

  const fetchLifetimeOrdersExists = () => {
    setIsLifetimeOrdersLoading(true);
    getRequest({ url: ORDER_COUNT_STATS })
      .then((res) => {
        const { order_exists: orderExists = false } = res?.data || {};
        setLifetimeOrdersExists(orderExists);
      })
      .catch()
      .finally(() => {
        setIsLifetimeOrdersLoading(false);
      });
  };

  useEffect(() => {
    if (window.location.pathname === rootUrls.ordersPath) {
      // fetchAllStats(); called in updateOrderParams
      // stats should be called once initially. refetch on search, tags, filters, time period change
      fetchOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParams]);

  useEffect(() => {
    handleRefreshColumnsFromPreferences();
  }, [preferences, orderTags]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchLifetimeOrdersExists();
      fetchPreferences();
      if (canCreateVendor)
        fetchRejectedByVendorStats({
          vendor_rejected_unassigned_orders: true,
          page: 1,
        });
    }
  }, []);

  const contextValue = {
    allOptionTabsData,
    allOrdersCount,
    allStatsLoading,
    acceptedOrdersCount,
    optionTabs,
    otherOptionTabs,
    selectedOption,
    orderTableColumns,
    gridColumnTemplate,
    setSelectedOption,
    isInfiniteScroll,
    orderListingView,
    setOrderListingView,
    ordersLoading,
    orders,
    submitting,
    ordersCount,
    orderParams,
    hasMoreTags,
    orderTags,
    setOrderTags,
    orderTagsCount,
    tagsLoading,
    tagsPageNumber,
    setTagsPageNumber,
    orderSources,
    sourcesLoading,
    sourcesPageNumber,
    setSourcesPageNumber,
    bulkOrderAction,
    fetchAllStats,
    fetchOrders,
    clubOrders,
    getStoreTags,
    getOrderSources,
    updateOrderParams,
    resetOrderParams,
    constructParams,
    refetch,
    setIsInfiniteScroll,
    setOrderTableColumns,
    setGridColumnTemplate,
    pageSize,
    updateOrdersCount,
    handleToggleColumn,
    rejectedByVendorOrderCount,
    ORDER_COLUMNS_PREFERENCE_KEY,
    ORDER_COLUMNS_PREFERENCE_ID,
    isLifetimeOrdersLoading,
    lifetimeOrdersExists,
    canDownloadReport,
  };

  return (
    <OrdersContext.Provider value={contextValue}>
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersProvider;
