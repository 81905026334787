import React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  WaIcon,
  FbIcon,
  CopyIcon,
  PDFDownloadIcon,
  SnackbarCloseIcon,
  XIcon,
} from '../SvgIcon';
import { CustomTooltip, ExternalLink } from '../shared';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import CopyLinkInput, { copyToClipboard } from '../shared/CopyLinkInput';
import { useAppContext } from '../context/AppContext';
import { TrackEvent } from '../utils/analytics';
import { getRequest } from '../utils/http';
import { GENERATE_CATEGORY_PDF, GENERATE_PRODUCT_PDF } from '../ApiUrls';
import SpinnerButton from '../shared/SpinnerButton';
import { manageUrls } from '../Urls';
import { noop } from '../utils';
import { addDays, daysFromToday } from '../utils/date';
import { useCountry } from '../context/CountryProvider';
import useSocketV2 from '../hooks/useSocketV2';
import useFileDownload from '../hooks/useFileDownload';

const getShareContent = (shareLink) =>
  `Visit my online store and place your orders from ${shareLink}`;

const CopyLink = ({ link, className, eventName }) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { business } = useAppContext();
  const copyLink = (e) => {
    e.preventDefault();
    copyToClipboard(link);
    enqueueSnackbar('Link copied');
    if (eventName) {
      TrackEvent(eventName, business);
    }
  };

  return (
    <CustomTooltip title="Copy link">
      <a href="#" onClick={copyLink} className={`${className}`}>
        <CopyIcon className="share-icon copy-icon" />
        <CopyLinkInput />
      </a>
    </CustomTooltip>
  );
};

export const FreeDeliveryBanner = ({
  parentClass = 'mb32',
  onClose = noop,
}) => {
  const history = useHistory();
  return (
    <div
      className={cx('px24 py16 pos-rel', parentClass)}
      style={{ backgroundColor: '#13458C', borderRadius: 6 }}
    >
      <p className="c-white section-text-4">
        Set up Dukaan Delivery & get your next delivery FREE!
      </p>
      <p className="c-white mt4">
        Ship your order via Dukaan Delivery & get up to ₹100 off on your next
        delivery.
      </p>
      <SpinnerButton
        showAnimation
        type="button"
        className="btn mt16"
        style={{
          padding: '8px 16px',
          backgroundColor: '#ffffff',
          fontSize: 14,
          lineHeight: '20px',
          color: '#13458C',
          fontWeight: 500,
        }}
        onClick={() => history.push(manageUrls.editDukaanDelivery)}
      >
        Ship now
      </SpinnerButton>
      <img
        style={{
          position: 'absolute',
          right: 24,
          bottom: 16,
        }}
        src="/images/dukaan-delivery-white-logo.svg"
        alt="Dukaan delivery logo"
      />
      <div
        className="cur-p"
        style={{
          position: 'absolute',
          top: 16,
          right: 32,
        }}
        onClick={onClose}
      >
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            padding: '12px',
            borderRadius: '50%',
          }}
        />
        <SnackbarCloseIcon
          style={{ position: 'relative', top: '4px', left: '4px' }}
        />
      </div>
    </div>
  );
};

export const ShareVia = ({
  shareContent,
  shareLink = '',
  showLabel,
  showCopyLink,
  closeModal,
  showPdfDownload = false,
  productUuid,
  categoryUuid,
  horizontal = false,
  copyLinkContent = '', // for custom content purpose
  socialEvents,
  isHomepage = false,
}) => {
  const content = shareContent || getShareContent(shareLink);
  const { business } = useAppContext();
  const { downloadFile } = useFileDownload();

  const { enqueueSnackbar } = useCustomSnackbar();

  const { setTaskId } = useSocketV2(
    (res) => {
      const { pdf_link: pdfLink } = res || {};
      downloadFile(pdfLink, true);
      enqueueSnackbar('PDF downloaded successfully');
    },
    () => {
      enqueueSnackbar('Something went wrong in downloading the PDF', 'error');
    }
  );

  const marginClass = isHomepage ? 'mr12' : 'mr16';

  const onDownloadPDFClick = () => {
    let url = '';
    if (productUuid) {
      url = GENERATE_PRODUCT_PDF(productUuid);
    } else {
      url = GENERATE_CATEGORY_PDF(categoryUuid);
    }

    getRequest({ url }).then((res) => {
      const { task_id: taskId } = res?.data ?? {};
      setTaskId(taskId);
    });
    enqueueSnackbar('PDF Download will begin shortly');
    closeModal();
  };

  const handleTrackEvent = (eventName) => {
    if (eventName) {
      TrackEvent(eventName, business);
    }
  };

  return (
    <div className={cx({ 'd-flex-c-start': horizontal })}>
      <div
        className={cx({
          mb12: !horizontal,
          mr16: horizontal,
        })}
      >
        {showLabel && (
          <label className={cx('section-text-5', { 'c-black-3': horizontal })}>
            Share via
          </label>
        )}
      </div>

      <ExternalLink
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}&p[title]=${content}`}
        className={cx(marginClass, { 'd-flex': horizontal })}
      >
        <CustomTooltip title="Share via Facebook">
          <FbIcon
            className="share-icon fb-share"
            onClick={() => handleTrackEvent(socialEvents?.facebook)}
          />
        </CustomTooltip>
      </ExternalLink>
      <ExternalLink
        href={`https://wa.me/?text=${content}`}
        className={cx(marginClass, { 'd-flex': horizontal })}
      >
        <CustomTooltip title="Share via WhatsApp">
          <WaIcon
            className="share-icon wa-share"
            onClick={() => handleTrackEvent(socialEvents?.whatsApp)}
          />
        </CustomTooltip>
      </ExternalLink>
      <ExternalLink
        href={`https://x.com/intent/tweet?text=${content}`}
        className={cx(marginClass, { 'd-flex': horizontal })}
      >
        <CustomTooltip title="Share via X">
          <XIcon
            height={32}
            onClick={() => handleTrackEvent(socialEvents?.twitter)}
          />
        </CustomTooltip>
      </ExternalLink>
      {showCopyLink && (
        <CopyLink
          link={copyLinkContent || shareLink}
          className={cx(marginClass, { 'd-flex': horizontal })}
          eventName={socialEvents?.copy}
        />
      )}
      {showPdfDownload && (
        <CustomTooltip title="Download PDF">
          <PDFDownloadIcon
            className="share-icon cur-p"
            onClick={onDownloadPDFClick}
          />
        </CustomTooltip>
      )}
    </div>
  );
};

export const OverviewItem = ({
  headerText,
  count,
  icon: Icon,
  onClick,
  className = '',
}) => (
  <div
    className={`overview-card-item card cur-p ${className}`}
    onClick={onClick}
  >
    <div className="text-2 c-black-3 mb4 d-flex">
      <span>{headerText}</span>
      {Icon && <Icon className="mt2 ml8" onClick={onClick} />}
    </div>
    <h2 className="overview-count">{count}</h2>
  </div>
);

ShareVia.defaultProps = {
  showCopyLink: false,
};

export const TotalSalesTooltip = (props) => {
  const { active, payload, xAxisProps, yAxisProps } = props;
  const { formatLocalNumber, currencySymbol } = useCountry();

  if (!payload || !payload?.length) return null;

  const { payload: tooltipData } = payload[0];
  const label = xAxisProps.tickFormatter(tooltipData[xAxisProps.dataKey]);
  const value = yAxisProps.tickFormatter(tooltipData[yAxisProps.dataKey]);
  const orders = formatLocalNumber(tooltipData?.order_count ?? 0);

  if (active && payload && payload.length) {
    return (
      <div className="__rechart_default-tooltip p8">
        <p className="label mb8">{label}</p>
        <p className="value mb4">Sales: {value || `${currencySymbol}0`}</p>
        <p className="value">Orders: {orders}</p>
      </div>
    );
  }

  return null;
};

export const Tooltip = (props) => {
  const { active, payload, xAxisProps, yAxisProps } = props;
  if (!payload || !payload?.length) return null;

  const { payload: tooltipData } = payload[0];
  const label = xAxisProps.tickFormatter(tooltipData[xAxisProps.dataKey]);
  const value = yAxisProps.tickFormatter(tooltipData[yAxisProps.dataKey]);

  if (active && payload && payload.length) {
    return (
      <div className="__rechart_default-tooltip p8">
        <p className="label mb8">{label}</p>
        <p className="value mb4">Conversion rate: {value || 0}%</p>
        <p className="value">Sessions: {tooltipData?.sessions || 0}</p>
      </div>
    );
  }

  return null;
};

export const StoreViewsTooltip = (props) => {
  const { active, payload, xAxisProps, yAxisProps } = props;
  const { formatLocalNumber } = useCountry();

  if (!payload || !payload?.length) return null;

  const { payload: tooltipData } = payload[0];
  const label = xAxisProps.tickFormatter(tooltipData[xAxisProps.dataKey]);
  const value = yAxisProps.tickFormatter(tooltipData[yAxisProps.dataKey]);
  const views = formatLocalNumber(tooltipData?.productViews || 0);

  if (active && payload && payload.length) {
    return (
      <div className="__rechart_default-tooltip">
        <p className="label">{label}</p>
        <p className="value">Store views: {value}</p>
        <p className="label mb5">Product views: {views}</p>
      </div>
    );
  }

  return null;
};

export const domainBufferDaysForPurchase = (business) => {
  const primaryDomainDetails = business.domains?.[0] ?? null;
  const customDomainExpiry = addDays(
    1,
    primaryDomainDetails?.added_on ?? '',
    'years'
  );
  const remainingBufferDays = daysFromToday(customDomainExpiry);
  const showBufferLabel =
    remainingBufferDays <= 30 &&
    remainingBufferDays > 0 &&
    !business.meta?.misc_data?.processing_renew_domain &&
    !primaryDomainDetails?.is_external;

  return {
    showBufferLabel,
    remainingBufferDays,
  };
};
