import { Collapse } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';
import useModal from '../../hooks/useModal';
import { FormTextArea } from '../../shared/Form';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { formatDate2, formatDate5, formatOrderTime } from '../../utils/date';
import { useUnsavedChangesModal } from '../../context/UnsavedChangesProvider';
import { noop } from '../../utils';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import { ArrowDownIcon, BlueDownArrowIcon, EditThinIcon } from '../../SvgIcon';

const NoteUpdateAccordion = ({
  isActive = false,
  author_name: authorName,
  message,
  created_at: createdAt,
  secondary_message: description,
}) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="d-flex timeline">
      <span className={cx('ellipses', { active: isActive })} />
      <div className="d-flex flex-column gap2 pl10 w-100 word-break">
        <div className="text-2 d-flex-c-s w-100">
          {message}
          <div
            className="d-flex cur-p"
            onClick={() => setExpanded((prev) => !prev)}
          >
            <ArrowDownIcon
              height={16}
              width={16}
              transform={`rotate(${expanded ? 180 : 0})`}
            />
          </div>
        </div>
        <Collapse in={expanded}>
          <p className="c-black-3 pt2 pb8 break-word">{description}</p>
        </Collapse>
        <span className="text-4 c-black-1">{authorName}</span>
        <span className="text-4 c-gray-1">{formatOrderTime(createdAt)}</span>
      </div>
    </div>
  );
};

const AuditList = ({ activities, showActive = false }) =>
  activities.map((each, idx) => {
    const isActive = idx === 0 && showActive;
    // if (each.secondary_message)
    //   return <NoteUpdateAccordion {...each} isActive={isActive} />;
    return (
      <div className="d-flex timeline">
        <span className={cx('ellipses', { active: isActive })} />
        <div className="d-flex flex-column gap2 space-between pl10 word-break flex-1">
          <div className="d-flex space-between">
            <div>
              <span className="text-2 ">{each.message}</span>
            </div>
            <span className="text-10 c-gray-1 ml10 min-w-fit">
              {formatDate5(each.created_at)}
            </span>
          </div>
          <span className="text-4 c-white-4" style={{ color: '#5e5e5e' }}>
            {each.author_name}
          </span>
          {each.secondary_message && (
            <p className="c-black-1 pb8">{each.secondary_message}</p>
          )}
        </div>
      </div>
    );
  });

export default function NotesTimeline({
  notesTimelineData = {},
  uuid = null,
  handleKeyboardKeys = noop,
  setNotes = noop,
  fetchOrderActivityList = noop,
  updateOrderNotes = noop,
  hideNotes = false,
  activityClasses = 'card p24',
}) {
  let formRef = null;
  const [notesValue, setNotesValue] = useState('');
  const [seeMore, setSeeMore] = useState(false);
  const { notes: notesData, activities = [] } = notesTimelineData;

  const [initialNotesData, setInitialNotesData] = useState(notesData);

  const {
    isOpen: isNotesModalOpen,
    openModal: openNotesModal,
    closeModal: closeNotesModal,
  } = useModal();

  const {
    setIsUnsavedChanges,
    isUnsavedChanges,
    setShowLeaveSiteModal,
    setLinkRedirect,
  } = useUnsavedChangesModal();

  const ref = useRef({
    initialNoteValue: '',
  });

  const modifyFormField = ([name, val], state, { changeValue }) => {
    changeValue(state, name, () => val);
  };

  const handleNotesSubmit = ({ notes: value }, form) => {
    if (Object.keys(uuid).length) {
      updateOrderNotes(value, () => {
        closeNotesModal();
        fetchOrderActivityList();
        setIsUnsavedChanges(true);
        ref.current.initialNoteValue = value;
        form.restart({
          notes: value,
        });
        setInitialNotesData(value);
      });
    } else {
      closeNotesModal();
      setIsUnsavedChanges(true);
      ref.current.initialNoteValue = value;
      form.restart({
        notes: value,
      });
      setNotes(value);
      setInitialNotesData(value);
    }
  };

  const closeModal = () => {
    if (isUnsavedChanges) {
      setShowLeaveSiteModal(true);
    } else {
      closeNotesModal();
      setIsUnsavedChanges(false);
    }
    setLinkRedirect(() => () => {
      closeNotesModal();
      formRef.mutators.modifyFormField('notes', ref.current.initialNoteValue);
      setIsUnsavedChanges(false);
    });
  };

  const openModel = () => {
    const {
      values: { notes },
    } = formRef.getState();
    ref.current.initialNoteValue = notes;
    openNotesModal();
  };

  useEffect(() => {
    if (isNotesModalOpen)
      document.removeEventListener('keydown', handleKeyboardKeys);
    else document.addEventListener('keydown', handleKeyboardKeys);
  }, [handleKeyboardKeys, isNotesModalOpen]);

  useEnterPressAction(isNotesModalOpen, () => {
    if (notesValue) handleNotesSubmit({ notes: notesValue }, formRef);
  });

  return (
    <div className="notes-timeline-inner-container">
      <Form
        onSubmit={handleNotesSubmit}
        mutators={{ modifyFormField }}
        initialValues={{
          notes: notesData,
        }}
        render={({ handleSubmit, values, dirty, form }) => {
          const { notes } = values;
          formRef = form;
          setIsUnsavedChanges(dirty);
          return (
            <div>
              {!hideNotes && (
                <div
                  className={cx('card p24 d-flex flex-column', {
                    mb24: activities?.length > 0,
                  })}
                >
                  <div className="d-flex space-between">
                    <span className="text-2 text-semibold">Notes</span>

                    <div
                      className="edit-customer-button shift-top cur-p"
                      onClick={openModel}
                    >
                      <EditThinIcon />
                    </div>
                  </div>
                  <div
                    className="mt16"
                    onClick={() => {
                      if (!notes) {
                        openModel();
                      }
                    }}
                  >
                    {notes ? (
                      <span className="text-2 break-word">{notes}</span>
                    ) : (
                      <span
                        className={cx('text-2 c-gray-1', {
                          'cur-p': !notes,
                        })}
                      >
                        Add order notes here.
                      </span>
                    )}
                  </div>
                </div>
              )}

              <Modal open={isNotesModalOpen} closeModal={closeModal}>
                <div>
                  <h5 className="section-text-4 text-medium">
                    {notes ? 'Edit' : 'Add'} note
                  </h5>
                  <div className="pt24">
                    <FormTextArea
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Enter your note"
                      name="notes"
                      labelText="Note"
                      rows={2}
                      maxLength={100}
                      onChange={(e) => setNotesValue(e.target.value)}
                      showCharLimit
                      textAreaClassName="d-block"
                    />
                    <div className="d-flex justify-center mt32">
                      <SpinnerButton
                        id="notes-btn"
                        disabled={initialNotesData === notes}
                        onClick={handleSubmit}
                      >
                        {notes ? 'Update' : 'Save'}
                      </SpinnerButton>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          );
        }}
      />

      {activities.length > 0 && (
        <div className={`${activityClasses} d-flex flex-column gap16`}>
          <div className="d-flex-c-s align-center">
            <p className="text-2 text-semibold">Activity</p>
            <SpinnerButton className="btn-outline-4" onClick={openModel}>
              Add note
            </SpinnerButton>
          </div>

          <div className="d-flex flex-column gap20">
            <AuditList activities={activities} showActive />
          </div>
        </div>
      )}
    </div>
  );
}
