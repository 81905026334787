import { useState } from 'react';

import { patchRequest, postRequest } from '../utils/http';
import {
  CANCEL_LINE_ITEM_URL,
  DUKAAN_SHIPMENT_CANCEL_URL,
  EDIT_ORDER_URL,
  GENERATE_PARTIAL_PAYMENT_LINK,
  GENERATE_PAYMENT_LINK,
  GENERATE_PREORDER_PAYMENT_LINK,
  ORDER_BUYER_ADDRESS_UPDATE_URL,
  ORDER_CANCEL_DUKAAN_SHIPMENT_URL,
  ORDER_URL,
  SEND_PAYMENT_LINK_ABANDONED_ORDER,
  UPDATE_ORDER_SPAM_STATUS,
} from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';

import {
  REJECTED_STATUS,
  PENDING_STATUS,
  CASH_PAYMENT_MODE,
} from '../Orders/constants';

const useOrderUpdate = (orderUuid, showError = true) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [quantityModalOpen, setQuantityModalOpen] = useState(false);
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  const { enqueueSnackbar } = useCustomSnackbar();

  const updateOrder = (
    params,
    successCallback,
    errorCallback = noop,
    uuid = ''
  ) => {
    setSubmitting(true);
    return patchRequest({
      url: ORDER_URL(orderUuid || uuid),
      data: params,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
        errorCallback(e);
        if (showError)
          enqueueSnackbar(
            e?.data?.data?.error ?? 'Something went wrong.',
            'error'
          );
        setSubmitting(false);
      });
  };

  const updateBuyerAddress = (
    data,
    successCallback,
    errorCallback = noop,
    uuid = ''
  ) => {
    setSubmitting(true);
    return patchRequest({
      url: ORDER_BUYER_ADDRESS_UPDATE_URL(orderUuid || uuid),
      data,
    })
      .then((res) => {
        setSubmitting(false);
        successCallback(res.data);
      })
      .catch((e) => {
        console.log(e);
        errorCallback(e);
        if (showError) enqueueSnackbar('Something went wrong.', 'error');
        setSubmitting(false);
      });
  };

  const editOrder = (params, successCallback) => {
    setSubmitting(true);
    patchRequest({
      url: EDIT_ORDER_URL(orderUuid),
      data: params,
      doClean: false,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.data.data) {
          const errors = e.data.data;

          Object.keys(errors).map((key) =>
            errors[key].map((err) => enqueueSnackbar(err, 'error'))
          );
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const cancelLineItem = (lineItemID, successCallback = noop, uuid = '') => {
    setSubmitting(true);
    patchRequest({
      url: CANCEL_LINE_ITEM_URL,
      data: { line_items: lineItemID, order: orderUuid ?? uuid },
      doClean: false,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback(res.data);
        }
      })
      .catch((e) => {
        if (e?.data?.non_field_errors) {
          enqueueSnackbar(e?.data?.non_field_errors, 'error');
        } else if (e?.data?.detail) {
          enqueueSnackbar(e?.data?.detail, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const cancelDukaanShipment = (successCallback = noop, shipmentUuid = '') => {
    setSubmitting(true);
    postRequest({
      url: shipmentUuid
        ? DUKAAN_SHIPMENT_CANCEL_URL(shipmentUuid)
        : ORDER_CANCEL_DUKAAN_SHIPMENT_URL(orderUuid),
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          successCallback(res.data);
        }
      })
      .catch((e) => {
        if (e?.data?.data?.error) {
          enqueueSnackbar(e.data.data.error, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const updateSpamStatus = (isSpam = false, successCallback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_ORDER_SPAM_STATUS(orderUuid),
      data: { is_spam: isSpam },
    })
      .then(() => {
        setSubmitting(false);
        enqueueSnackbar(`Order marked as ${isSpam ? 'spam' : 'not spam'}!`);
        successCallback();
      })
      .catch((e) => {
        if (e?.data?.data?.error) {
          enqueueSnackbar(e.data.data.error, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const generatePaymentLink = (successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: GENERATE_PAYMENT_LINK(orderUuid),
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          enqueueSnackbar(
            'Payment link shared with your customer on Whatsapp/SMS successfully.'
          );
          successCallback(res.data);
        }
      })
      .catch((e) => {
        if (Array.isArray(e?.data?.errors)) {
          if (e.data.data.order_uuid) {
            enqueueSnackbar(e.data.data.order_uuid[0], 'error');
          }
          e.data.errors.forEach((err) => {
            if (err.error_code === 'payment5') {
              enqueueSnackbar(
                `Only valid on order value less than ${e?.data?.data?.max_online_order_val}`,
                'error'
              );
            } else {
              enqueueSnackbar(err.message, 'error');
            }
          });
        } else if (e?.data?.data?.error) {
          enqueueSnackbar(e.data.data.error, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const generatePartialPaymentLink = (
    paymentAmount = null,
    successCallback = noop
  ) => {
    const data = {};
    if (paymentAmount) data.amount = paymentAmount;
    setSubmitting(true);
    postRequest({
      url: GENERATE_PARTIAL_PAYMENT_LINK(orderUuid),
      data,
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          enqueueSnackbar(
            'Payment link shared with your customer on Whatsapp/SMS successfully.'
          );
          successCallback(res.data);
        }
      })
      .catch((e) => {
        if (Array.isArray(e?.data?.errors)) {
          if (e.data.data.order_uuid) {
            enqueueSnackbar(e.data.data.order_uuid[0], 'error');
          }
          e.data.errors.forEach((err) => {
            if (err.error_code === 'payment5') {
              enqueueSnackbar(
                `Only valid on order value less than ${e?.data?.data?.max_online_order_val}`,
                'error'
              );
            } else {
              enqueueSnackbar(err.message, 'error');
            }
          });
        } else if (e?.data?.data?.error) {
          enqueueSnackbar(e.data.data.error, 'error');
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const generatePreorderFullPaymentLink = (successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: GENERATE_PREORDER_PAYMENT_LINK(orderUuid),
    })
      .then((res) => {
        setSubmitting(false);
        if (res.data) {
          enqueueSnackbar(
            'Payment link shared with your customer on Whatsapp/SMS successfully.'
          );
          successCallback(res.data);
        }
      })
      .catch((e) => {
        if (Array.isArray(e?.data?.data?.errors)) {
          e.data.data.errors.forEeach((err) =>
            enqueueSnackbar(err.message, 'error')
          );
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
        setSubmitting(false);
      });
  };

  const sendDiscountedPaymentLink = (data = {}, successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: SEND_PAYMENT_LINK_ABANDONED_ORDER(orderUuid),
      data,
    })
      .then(() => {
        enqueueSnackbar('Payment link sent successfully!');
        successCallback();
      })
      .catch((err) => {
        if (err?.data?.data && !err?.data?.data?.error) {
          enqueueSnackbar('Please check form data and try again', 'error');
        } else {
          enqueueSnackbar('Something went wrong', 'error');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const markManualOrderPayment = (type, successCallback) => {
    let payload = {};

    if (type === 'CANCELLED') payload = { status: REJECTED_STATUS };
    else if (type === 'COD')
      payload = { status: PENDING_STATUS, new_payment_mode: CASH_PAYMENT_MODE };
    else if (type === 'PAID') payload = { status: PENDING_STATUS };

    updateOrder(payload, successCallback);
  };

  return {
    isSubmitting,
    updateOrder,
    editOrder,
    cancelLineItem,
    quantityModalOpen,
    setQuantityModalOpen,
    deliveryModalOpen,
    setDeliveryModalOpen,
    cancelDukaanShipment,
    generatePaymentLink,
    generatePartialPaymentLink,
    generatePreorderFullPaymentLink,
    updateBuyerAddress,
    sendDiscountedPaymentLink,
    markManualOrderPayment,
    updateSpamStatus,
  };
};

export default useOrderUpdate;
