import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useAppContext } from '../../context/AppContext';
import {
  isHyperlocalSelected,
  OrderWeight,
} from '../../context/DeliveryProvider';
import useOrderShipment from '../../hooks/useOrderShipment';
import useQuery from '../../hooks/useQuery';
import useResponsive from '../../hooks/useResponsive';
import Layout from '../../Layout';
import BounceLoaderContainer from '../../shared/BounceLoaderContainer';
import SpinnerButton from '../../shared/SpinnerButton';
import { noop, modifyFormField } from '../../utils';
import NewShippingOptionsTable from './NewShippingOptionsTable';
import { rootUrls } from '../../Urls';
import {
  DDNOTSET_DP_CONTINUE_ACTIVE,
  DDSET_DP_CONTINUE,
  ORDERS_SHIP_SINGLE,
} from '../../events';
import { TrackEvent } from '../../utils/analytics';
import { useStoreWarehouse } from '../../context/StoreWarehouseProvider';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';

export const CUSTOM_WAREHOUSE_PINCODE = 400001;
export const MOBILE_DAIL_PAD = 'phoneCall';
export const LIVE_CHAT = 'liveChat';
export const CLOSE_N_REFRESH = 'closeNRefresh';
export const UPDATE_SHIPPO_TOKEN = 'updateShippoToken';

export const mobileClose = (refresh = false, action = 'close') => {
  try {
    window.Android[action](refresh);
  } catch (error) {
    console.log('Android error', error);
  }
  try {
    const msg = {
      func: action,
      args: [refresh],
    };
    window.parent.postMessage(msg, '*');
    window.webkit.messageHandlers.iosListener.postMessage(msg);
  } catch (error) {
    console.log('iOS error', error);
  }
};

const SelectShipmentPartner = (props) => {
  const {
    match: {
      params: { uuid },
    },
  } = props;
  const history = useHistory();
  const { isMobile } = useResponsive();
  const query = useQuery();
  const orderId = query.get('orderId');
  const weight = query.get('weight');
  const shippingPackage = query.get('shipping_package');
  const [initialValue, setInitialValues] = useState({
    weight: '',
    shippingMode: '',
    shippingPartner: '',
  });
  const { business } = useAppContext();
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();
  const { enqueueSnackbar } = useCustomSnackbar();
  const {
    loading,
    orderShippingDetails,
    getShippingDetails,
    orderSeviceable,
    servicebleError,
  } = useOrderShipment();
  const { rates = [] } = orderShippingDetails || {};
  const [customPincode, setCustomPincode] = useState('0');
  const pincodeFromURL = query.get('pincode') || '0';
  const warehouseFromURL = query.get('preferred_warehouse') || '';
  const [formDirty, setFormDirty] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const changeValueOfURL = (key, value) => {
    const url = new URL(window.location);
    url.searchParams.set(key, value);
    window.history.pushState(null, '', url.toString());
  };

  const onSubmit = ({ shippingMode, shippingPartner }) => {
    const maxWeight = rates.find(
      (each) =>
        each?.carrier_service?.id === shippingMode &&
        each?.carrier?.id === shippingPartner
    )?.carrier_service?.max_allowed_weight_grams;
    const maxWeightInkg = maxWeight / 1000;
    if (isActiveStoreWarehouseExist) TrackEvent(DDSET_DP_CONTINUE, business);
    else TrackEvent(DDNOTSET_DP_CONTINUE_ACTIVE, business);
    const selectedRate = rates.find(
      (each) => each?.carrier_service?.id === shippingMode
    );
    TrackEvent(
      ORDERS_SHIP_SINGLE(
        selectedRate?.carrier_service?.service_type_json?.id,
        isActiveStoreWarehouseExist
      ),
      business,
      {}
    );
    const isHyperlocal = isHyperlocalSelected(selectedRate || {});
    const queryParams = {
      mode: shippingMode,
      carrier: shippingPartner,
      'max-weight': maxWeightInkg,
      pincode: customPincode,
      orderId,
      weight,
    };
    if (shippingPackage) {
      queryParams.shipping_package = shippingPackage;
    }
    if (selectedWarehouse)
      queryParams.preferred_warehouse = selectedWarehouse?.value;
    if (isHyperlocal) {
      queryParams.weight = 1;
      history.push(
        `${rootUrls.orderDeliveryCostPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryParams)}`
      );
    } else {
      history.push(
        `${rootUrls.orderDeliveryCostPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify(queryParams)}`
      );
    }
  };

  const isDukaanDelivery = orderSeviceable;

  const getGetShippingPayload = (...resArgs) => {
    const payload = { orders: [new OrderWeight(...resArgs)] };
    if (selectedWarehouse) {
      payload.orders[0].preferred_warehouse = selectedWarehouse?.value;
    }
    if (shippingPackage) {
      payload.orders[0].shipping_package = shippingPackage;
    }
    if (!isActiveStoreWarehouseExist)
      payload.custom_warehouse_pincode =
        Number(customPincode) || CUSTOM_WAREHOUSE_PINCODE;

    return payload;
  };

  useEffect(() => {
    if (!weight) {
      history.replace(
        `${rootUrls.orderDeliveryShipmentWeightPath.replace(
          ':uuid',
          uuid
        )}?${qs.stringify({ orderId: orderId })}`
      );
    }
    if (orderId) {
      getShippingDetails(getGetShippingPayload(orderId, Number(weight)));
    }
  }, [orderId, customPincode, selectedWarehouse]);

  useEffect(() => {
    if (pincodeFromURL.length === 6) {
      setCustomPincode(pincodeFromURL);
    }
  }, []);

  return (
    <Form
      onSubmit={noop}
      keepDirtyOnReinitialize
      initialValues={initialValue}
      mutators={{ modifyFormField }}
    >
      {({ form, values }) => (
        <Layout
          documentTitle="Pickup time & partner"
          headerText="Pickup time & partner"
          backLink={isMobile ? mobileClose : ''}
          hideSideNavigation={isMobile}
          hideSubscription
          showMobileHeader={isMobile}
          progressBar={50}
          headerParentClass="fixed mw-760"
          headerActions={
            <div className="header-actions">
              <div className="header-action-container">
                <div className="text-right">
                  <SpinnerButton
                    showAnimation
                    type="button"
                    className="btn btn-primary-4"
                    onClick={() => {
                      if (!isActiveStoreWarehouseExist) {
                        if (
                          customPincode <= 6 ||
                          (customPincode && !isDukaanDelivery)
                        ) {
                          if (customPincode === '0') {
                            enqueueSnackbar(
                              'Please enter warehouse pin code to continue!',
                              'error'
                            );
                          }
                          setFormDirty(true);
                          return;
                        }
                        return onSubmit(values);
                      }
                      return onSubmit(values);
                    }}
                  >
                    Continue
                  </SpinnerButton>
                </div>
              </div>
            </div>
          }
        >
          <BounceLoaderContainer isLoading={loading}>
            <div
              className="new-shipment-container"
              style={{ maxWidth: 760, margin: 'auto' }}
            >
              <div className={cx({ mt20: !isMobile })}>
                {(isDukaanDelivery ||
                  (!isActiveStoreWarehouseExist && customPincode)) && (
                  <NewShippingOptionsTable
                    form={form}
                    priceLoading={loading}
                    orderShippingDetails={orderShippingDetails}
                    formInitialValues={initialValue}
                    setFormInitialValues={setInitialValues}
                    isDukaanDelivery={isDukaanDelivery}
                    formValues={values}
                    showRadioBtn
                    setPincode={(pincode) => {
                      setCustomPincode(pincode);
                      changeValueOfURL('pincode', pincode);
                    }}
                    pincode={
                      Number(customPincode) === 0 ? null : Number(customPincode)
                    }
                    formDirty={formDirty}
                    customPincode={customPincode}
                    warehouseFromURL={warehouseFromURL}
                    selectedWarehouse={selectedWarehouse}
                    setSelectedWarehouse={setSelectedWarehouse}
                  />
                )}
                <div
                  className={cx({
                    'd-flex justify-end py32': !isMobile,
                    'btn-fixed-bottom card px16 py12': isMobile,
                  })}
                >
                  <SpinnerButton
                    className={cx('btn-primary-4', { 'w-100': isMobile })}
                    showAnimation
                    onClick={() => {
                      if (!isActiveStoreWarehouseExist) {
                        if (
                          customPincode <= 6 ||
                          (customPincode && !isDukaanDelivery)
                        ) {
                          if (customPincode === '0') {
                            enqueueSnackbar(
                              'Please enter warehouse pin code to continue!',
                              'error'
                            );
                          }
                          setFormDirty(true);
                          return;
                        }
                        return onSubmit(values);
                      }
                      return onSubmit(values);
                    }}
                  >
                    Continue
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </BounceLoaderContainer>
        </Layout>
      )}
    </Form>
  );
};

export default SelectShipmentPartner;
