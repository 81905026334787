/* eslint-disable import/no-cycle */
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  NotificationInfoIcon,
  NotificationSuccessIcon,
  NotificationWarningIcon,
  ViewAllNotificationsEmpty,
} from '../SvgIcon';
import { useViewNotifications } from '../context/ViewNotificationsProvider';
import { formatOrderTime } from '../utils/date';
import ActionMenu from '../shared/ActionMenu';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { parseHTML, urlify } from '../utils';
import { DEFAULT_ALLOWED_TAGS } from '../utils/constant';

const NOTIFICATION_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
};

const getNotificationIcon = (notificationType) => {
  switch (notificationType) {
    case NOTIFICATION_TYPES.INFO:
      return <NotificationInfoIcon />;
    case NOTIFICATION_TYPES.WARNING:
      return <NotificationWarningIcon />;
    case NOTIFICATION_TYPES.SUCCESS:
      return <NotificationSuccessIcon />;
  }
};

const ViewNotificationsMenu = () => {
  const { formattedNotifications, notificationsCount, markNotificationRead } =
    useViewNotifications();
  const { enqueueSnackbar } = useCustomSnackbar();

  const hasResults = Object.keys?.(formattedNotifications)?.length > 0;

  const handleMarkNotificationRead = (notificationUuid) => {
    markNotificationRead(notificationUuid);
  };

  return (
    <>
      <div
        className={cx('view-download-dropdown notification py16', {
          gap24: hasResults,
          gap16: !hasResults,
        })}
      >
        <div className="d-flex-c-s px16">
          <p className="section-text-5">
            Alerts {notificationsCount > 0 && `(${notificationsCount})`}
          </p>
        </div>

        {hasResults ? (
          <div className="reports-grid show-scrollbar px16">
            {Object.keys?.(formattedNotifications)?.map((date, index) => (
              <div key={index}>
                <p className="section-text-7 mb16">{date}</p>
                <div className="date-wise-reports-grid notification">
                  {formattedNotifications[`${date}`].map((notification) => (
                    <>
                      <div
                        className="report-div notification"
                        key={notification.uuid}
                      >
                        <div className="notification-icon">
                          {getNotificationIcon(
                            notification?.dashboard_alert?.alert_type ||
                              NOTIFICATION_TYPES.INFO
                          )}
                        </div>
                        <div>
                          <div className="d-flex-c-s align-center">
                            <div className="word-break notification-message">
                              <p className="text-medium">
                                {notification?.dashboard_alert?.alert_title}
                              </p>
                              {ReactHtmlParser(
                                parseHTML(
                                  urlify(
                                    notification?.dashboard_alert?.alert_body,
                                    'c-purple-i text-medium underline'
                                  ),
                                  [...DEFAULT_ALLOWED_TAGS, 'a'],
                                  ['href', 'target']
                                )
                              )}
                            </div>
                            {notification?.marked_read_on === null && (
                              <div className="notification-unread" />
                            )}
                          </div>
                          <div className="mt8 d-flex-c-s align-center mb4">
                            <span
                              className={cx('text-10', {
                                'c-purple-1 text-medium':
                                  notification?.marked_read_on === null,
                                'c-black-3':
                                  notification?.marked_read_on !== null,
                              })}
                            >
                              {formatOrderTime(notification?.created_at)}
                            </span>
                            {notification?.marked_read_on === null && (
                              <ActionMenu
                                vertical={false}
                                iconBtnClass="action-menu-icon notification-action"
                                menuItems={[
                                  {
                                    labelText: 'Mark as read',
                                    onClick: () => {
                                      handleMarkNotificationRead(
                                        notification.uuid,
                                        () => {
                                          enqueueSnackbar(
                                            'Notification marked read!'
                                          );
                                        }
                                      );
                                    },
                                  },
                                ]}
                                iconSize="24"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex-c-c flex-column gap8 mb16 px16">
            <ViewAllNotificationsEmpty height={36} width={36} />
            <p className="c-gray-1 text-center">
              Your notifications will be shown here.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(ViewNotificationsMenu);
