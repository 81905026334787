import React, { useEffect } from 'react';
import {
  FILTER_OPTIONS,
  PAGINATION_BEHAVIOUR,
} from '../../Catalogue/constants';
import useTags from '../../hooks/useTags';
import { TAGS_FOR } from '../../Orders/constants';
import { noop } from '../../utils';
import { BounceLoaderSmall } from '../BounceLoader';
import CheckboxField from '../CheckboxField';
import Modal from '../Modal';
import Pagination from '../Pagination';
import SearchFilter from '../SearchFilter';
import SpinnerButton from '../SpinnerButton';

const TAGS_PAGE_SIZE = 50;

const TagsModal = ({
  open = false,
  closeModal = noop,
  selectedFilters = {},
  setSelectedFilters = noop,
  debounceSearch = noop,
  tagsType = TAGS_FOR.PRODUCT,
  updateFilters,
  setOrderTags = noop,
}) => {
  const { TAGS } = FILTER_OPTIONS;

  const updateTags = (isChecked, filterName, value, isArray = false) => {
    if (isChecked) {
      setSelectedFilters((pre) => {
        const existingValue = pre[filterName];
        const updatedValue = isArray
          ? [...new Set(existingValue || []), value]
          : value;

        pre[filterName] = updatedValue;
        return {
          ...pre,
          page: 1,
        };
      });
    } else {
      setSelectedFilters((pre) => {
        if (isArray) {
          pre[filterName] = pre[filterName].filter(
            (each) => each.id !== value.id
          );
        } else if (!isArray) {
          delete pre[filterName];
        }
        return {
          ...pre,
          page: 1,
        };
      });
    }
  };

  const {
    tags,
    getStoreTags,
    setSearch: setTagsSearch,
    search: searchTagTerm,
    tagsCount,
    tagsPageNumber,
    setTagsPageNumber,
    loadingTags,
  } = useTags({
    type: tagsType,
  });

  const onFiltersSearch = (text) => {
    debounceSearch(text.toLowerCase(), setTagsSearch);
    setTagsPageNumber(1);
  };

  useEffect(() => {
    getStoreTags(TAGS_PAGE_SIZE, PAGINATION_BEHAVIOUR.SPLIT);
  }, [tagsPageNumber, searchTagTerm]);

  return (
    <Modal open={open} closeModal={closeModal} className="tags-modal">
      <p className="section-text-4 c-black-1 mb16">Tags</p>
      <SearchFilter
        className="mb12"
        placeholder="Search tag"
        onFilter={onFiltersSearch}
      />
      <hr className="hr-line" />

      {!loadingTags && tags?.length <= 0 && (
        <div className="text-center py20">No tags found</div>
      )}

      <div className="tags-modal-options-container pos-rel">
        {loadingTags && (
          <div className="loader-container pos-rel">
            <BounceLoaderSmall />
          </div>
        )}

        {!loadingTags && tags?.length > 0 && (
          <div className="tags-modal-grid-wrapper">
            {tags?.map((item, index) => {
              const isSelected = selectedFilters?.tags?.some((current) => {
                if (updateFilters) return current === item.id;
                return current.id === item.id;
              });
              const handleChange = (e) => {
                e.stopPropagation();
                e.preventDefault();

                if (updateFilters) {
                  setOrderTags((pre) => [...pre, item]);
                  updateFilters(!isSelected, 'tags', item.id, true);
                } else
                  updateTags(
                    !isSelected,
                    TAGS,
                    { id: item.id, name: item.name },
                    true
                  );
              };

              return (
                <CheckboxField
                  checked={isSelected}
                  onChange={handleChange}
                  label={
                    <span className="product-filter-option tag-label text-8 c-black-1 text-ellipsis">
                      {item.name}
                    </span>
                  }
                />
              );
            })}
          </div>
        )}
      </div>

      <hr className="hr-line" />
      {Math.ceil(tagsCount / TAGS_PAGE_SIZE) > 1 && tags?.length > 0 && (
        <Pagination
          currentPage={tagsPageNumber}
          onCounterClick={(newPage) => {
            setTagsPageNumber(newPage);
          }}
          pageSize={TAGS_PAGE_SIZE}
          totalRecords={tagsCount}
          siblingCount={2}
          pagesCountShown={7}
          hidePage
        />
      )}

      <div className="text-center mt16">
        <SpinnerButton
          onClick={() => {
            closeModal();
          }}
          disabled={false}
        >
          Select
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default TagsModal;
