import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../shared/Modal';
import { DukaanIcon } from '../SvgIcon';
import { EVENT_ACC_SIGNOUT } from '../events';
import { useAppContext } from '../context/AppContext';
import { logoutUser } from '../utils/user';
import { TrackEvent } from '../utils/analytics';
import SpinnerButton from '../shared/SpinnerButton';
import useStoreUpdate from '../hooks/useStoreUpdate';

const Reseller = ({ ...modalProps }) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { isSubmitting, updateAccount } = useStoreUpdate();
  const [isLoading, setIsLoading] = useState(false);

  const handleTurnoffResellerMode = () => {
    setIsLoading(true);
    updateAccount({
      type: 0,
    });
  };

  useEffect(() => {
    document.title = 'Switch off Dukaan Plus';

    setIsLoading(true);

    fetchBusinessDetails(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Modal open showCloseIcon={false} className="expiry-modal" {...modalProps}>
      <div className="d-flex-c-s mb40">
        <DukaanIcon alt="dukaan" height={56} />
        <span
          className="section-text-5 underline cur-p"
          onClick={() => {
            TrackEvent(EVENT_ACC_SIGNOUT, business);
            logoutUser();
          }}
        >
          Sign out
        </span>
      </div>
      <h4 className="section-text-1 mb40">
        To access Dukaan Web, you need to switch off Dukaan Plus.
      </h4>

      <SpinnerButton
        isLoading={isSubmitting || isLoading}
        onClick={handleTurnoffResellerMode}
        showAnimation
        className="btn-primary-0 upgrade-button"
      >
        Switch off Dukaan Plus
      </SpinnerButton>
    </Modal>
  );
};

export default Reseller;
