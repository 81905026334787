import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { useAppContext } from '../context/AppContext';

import { useCountry } from '../context/CountryProvider';
import { analyticsUrl } from '../Urls';
import { formatServerDate } from '../utils/date';
import { APP_DOMAIN } from '../constants';
import { isObjectEmpty } from '../utils/collection';

const useAnalyticsHelper = () => {
  const { formatLocalMoney, formatLocalNumber } = useCountry();

  const { domainWithLink, business } = useAppContext();
  const { created_at: createdAt } = business;

  const getComparisonDateRange = (startDate, endDate) => {
    const noOfDaysInSelectedRange = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .asDays();
    // in case of today and yesterday, difference is 0 hence subtract 1 day
    return [
      moment(startDate).subtract(
        Math.round(noOfDaysInSelectedRange || 1),
        'days'
      ), // end date
      moment(startDate).subtract(1, 'days'), // start date
    ];
  };

  const getDateRange = (startDate, endDate, type) => {
    let dateRangeParams;
    let dateRangeParamsForComparison;
    if (type === 'lifetime') {
      dateRangeParams = {
        created_at_before: formatServerDate(moment().toDate()),
      };
      dateRangeParamsForComparison = {
        created_at_before: formatServerDate(moment().toDate()),
      };
    } else {
      dateRangeParams = {
        created_at_after: startDate
          ? formatServerDate(moment(startDate).toDate())
          : undefined,
        created_at_before: endDate
          ? formatServerDate(moment(endDate).toDate())
          : undefined,
      };
      if (type === 'custom_range') {
        dateRangeParamsForComparison = dateRangeParams;
      } else {
        const [createdAtAfter, createdAtBefore] = getComparisonDateRange(
          startDate,
          endDate,
          type
        );
        dateRangeParamsForComparison = {
          created_at_after: createdAtAfter
            ? formatServerDate(createdAtAfter.toDate())
            : undefined,
          created_at_before: createdAtBefore
            ? formatServerDate(createdAtBefore.toDate())
            : undefined,
        };
      }
    }
    return { dateRangeParams, dateRangeParamsForComparison };
  };

  const descriptionText = {
    lifetime: 'from the first day of the store',
    today: 'from the last day',
    yesterday: 'from the last day',
    this_week: 'from the last week',
    last_7_days: 'from the last 7 days',
    last_week: 'from the last week',
    this_month: 'from the last month',
    last_30_days: 'from the last 30 days',
    last_month: 'from the last month',
  };

  const MONTH = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  const formatDate = (dt) =>
    moment(dt).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[Yesterday]',
      lastWeek: 'DD MMM YYYY',
      sameElse: 'DD MMM YYYY',
    });

  const handleDynamicTooltipText = (dateRangeType) => {
    if (dateRangeType === 'today' || dateRangeType === 'yesterday') {
      return 'Percentage change in comparison with the previous day';
    }
    if (
      dateRangeType === 'this_week' ||
      dateRangeType === 'last_week' ||
      dateRangeType === 'last_7_days'
    ) {
      return 'Percentage change in comparison with the previous week';
    }
    if (
      dateRangeType === 'this_month' ||
      dateRangeType === 'last_month' ||
      dateRangeType === 'last_30_days'
    ) {
      return 'Percentage change in comparison with the previous month';
    }
    return '';
  };

  const calculatePercentage = (data1, data2) => {
    let percentage;
    if (data2 > 0 && data1 > 0) {
      percentage = Math.round((Math.abs(data1 - data2) / data2) * 100);
    } else percentage = 0;

    return percentage;
  };

  const getDates = (start, end) => {
    var arr = {};
    var dt = new Date();
    for (dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr[new Date(dt).toISOString().slice(0, 10)] = 0;
    }
    return arr;
  };
  const getMonths = (start, end) => {
    const arr = {};
    let dt = new Date();
    for (dt = new Date(start); dt <= end; dt.setMonth(dt.getMonth() + 1)) {
      arr[new Date(dt).toISOString(0, 8)] = 0;
    }
    return arr;
  };

  const getTime = () => {
    const time = Array.from({ length: 24 }, (_, i) => moment().hours(i));
    const arr = {};
    time.forEach((item) => (arr[item] = 0));
    return arr;
  };

  const formatDataForGraph = (
    data,
    dataKey,
    params = {},
    dateRangeType = '',
    areKeysDifferent = false,
    getHourData = false
  ) => {
    if (!data?.length) return [{ value: 0, label: new Date().getTime() }];
    const finalDates = [];

    const dateRange = getHourData
      ? getTime()
      : getDates(
          new Date(params.created_at_after),
          new Date(params.created_at_before)
        );

    for (let i = 0; i < data.length; i += 1) {
      const timeFrame = data[i];
      if (getHourData) {
        dateRange[moment().hours(timeFrame.hour)] = {
          value: timeFrame[dataKey],
          ...timeFrame,
        };
      } else if (dateRangeType === 'lifetime') {
        if (areKeysDifferent) {
          const dt = new Date(timeFrame.key);
          dateRange[MONTH[dt.getMonth() + 1].concat(' ', dt.getFullYear())] = {
            value: timeFrame[dataKey],
            ...timeFrame,
          };
        } else {
          dateRange[MONTH[timeFrame.month].concat(' ', timeFrame.year)] = {
            value: timeFrame[dataKey],
            ...timeFrame,
          };
        }
      } else if (areKeysDifferent) {
        dateRange[timeFrame.key.slice(0, 10)] = {
          value: timeFrame[dataKey],
          ...timeFrame,
        };
      } else {
        dateRange[timeFrame.date.slice(0, 10)] = {
          value: timeFrame[dataKey],
          ...timeFrame,
        };
      }
    }
    Object.entries(dateRange).forEach(([key, value]) => {
      finalDates.push({
        label: key,
        ...(value || { value: 0 }),
      });
    });
    finalDates.sort((a, b) => moment(a.label) - moment(b.label));
    return finalDates;
  };

  const getOverviewFormatedData = (
    avgOrdersPerDayNew,
    avgOrderValueNew,
    avgSalesNew,
    returningBuyersDataNew,
    returningBuyersDataNewPercentage,
    avgOrdersPerDayOld,
    avgOrderValueOld,
    avgSalesOld,
    returningBuyersDataOld,
    dateRangeType
  ) => [
    {
      title: 'Average orders per day',
      total: Math.round(avgOrdersPerDayNew),
      percentage:
        dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
          ? 0
          : calculatePercentage(avgOrdersPerDayNew, avgOrdersPerDayOld),
      isPositive: avgOrdersPerDayNew - avgOrdersPerDayOld > 0,
      helpText: handleDynamicTooltipText(dateRangeType),
      headerHelpText: 'Average number of orders per day in the selected time.',
    },
    {
      title: 'Average order value',
      total: formatLocalMoney(Math.round(avgOrderValueNew)),
      percentage:
        dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
          ? 0
          : calculatePercentage(avgOrderValueNew, avgOrderValueOld),
      isPositive: avgOrderValueNew - avgOrderValueOld > 0,
      helpText: handleDynamicTooltipText(dateRangeType),
      headerHelpText:
        'Average order value of all the delivered orders in the selected time.',
    },
    {
      title: 'Average sales per day',
      total: formatLocalMoney(Math.round(avgSalesNew)),
      percentage:
        dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
          ? 0
          : calculatePercentage(avgSalesNew, avgSalesOld),
      isPositive: avgSalesNew - avgSalesOld > 0,
      helpText: handleDynamicTooltipText(dateRangeType),
      headerHelpText:
        'Average sales generated from all the delivered orders per day in the selected time.',
    },
    {
      title: 'Returning customers',
      total: `${Math.round(returningBuyersDataNewPercentage)}%`,
      percentage:
        dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
          ? 0
          : calculatePercentage(returningBuyersDataNew, returningBuyersDataOld),
      isPositive: returningBuyersDataNew - returningBuyersDataOld > 0,
      helpText: handleDynamicTooltipText(dateRangeType),
      headerHelpText:
        'Percentage of users who have placed more than 1 order in the selected time.',
    },
  ];

  const getFormatedTotalOrdersData = (
    totalOrdersNew,
    totalOrdersOld,
    dateRangeType
  ) => ({
    isPositive: totalOrdersNew.total_orders - totalOrdersOld.total_orders > 0,
    number: totalOrdersNew.total_orders,
    percentage:
      dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
        ? 0
        : calculatePercentage(
            totalOrdersNew.total_orders,
            totalOrdersOld.total_orders
          ),
    helpText: handleDynamicTooltipText(dateRangeType),
    description:
      dateRangeType === 'custom_range'
        ? ''
        : `${
            totalOrdersNew.total_orders - totalOrdersOld.total_orders > 0
              ? 'increase'
              : 'decrease'
          } ${descriptionText[dateRangeType]}.`,
    title: 'Total orders',
    tooltipText: 'Total number of orders received in the selected time.',
    formattingFunction: formatLocalNumber,
    redirectionLink: analyticsUrl.ordersReport,
    graphTooltipValueLabel: 'Orders',
  });

  const getFormatedTotalSalesData = (
    totalSalesNew,
    totalSalesOld,
    dateRangeType
  ) => ({
    isPositive: totalSalesNew.total_sales - totalSalesOld.total_sales > 0,
    number: Math.round(totalSalesNew.total_sales ?? 0),
    percentage:
      dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
        ? 0
        : calculatePercentage(
            totalSalesNew.total_sales,
            totalSalesOld.total_sales
          ),
    helpText: handleDynamicTooltipText(dateRangeType),
    description:
      dateRangeType === 'custom_range'
        ? ''
        : `${
            totalSalesNew.total_sales - totalSalesOld.total_sales > 0
              ? 'increase'
              : 'decrease'
          } ${descriptionText[dateRangeType]}.`,
    title: 'Gross sales',
    tooltipText: 'Total value of all the received/created orders.',
    formattingFunction: formatLocalMoney,
    redirectionLink: analyticsUrl.salesReport,
    graphTooltipValueLabel: 'Sales',
    showBreakdown: true,
  });

  const getConversionsFormattedData = ({
    ordersNew = 0,
    sessionsNew = 0,
    // ordersOld = 0,
    // sessionsOld = 0,
    // dateRangeType,
    formattedOrdersData,
    formattedSesionsData,
  }) => {
    const graphData = [];

    Object.entries(formattedSesionsData).forEach(([key, itemValue]) => {
      graphData.push({
        label: key,
        ...itemValue,
        value: formattedSesionsData?.[key]?.value
          ? parseFloat(
              (
                ((formattedOrdersData?.[key]?.value || 0) /
                  formattedSesionsData[key].value) *
                100
              ).toFixed(2)
            )
          : 0,
      });
    });
    const conversionNew = ((ordersNew / sessionsNew) * 100).toFixed(2);
    // const conversionOld = Math.round((ordersOld / sessionsOld) * 100);

    return {
      isPositive: false,
      number: <span className="d-flex-c-c gap12">{conversionNew}%</span>,
      sessions: sessionsNew,
      percentage: undefined,
      showAnimatedCounter: false,
      description: '',
      title: 'Store conversion rate',
      tooltipText:
        'Percentage of sessions that converted into orders, calculated from the total number of sessions',
      formattingFunction: formatLocalNumber,
      redirectionLink: '',
      graphTooltipValueLabel: 'Conversion rate',
      data: graphData || [{ value: 0, label: new Date().getTime() }],
    };
  };

  const getFormatedRegionSalesChart = (topRegions) => ({
    title: 'Sales by top region',
    helpText: 'Top performing regions in terms of sales.',
    data: topRegions
      .reverse()
      .slice(0, 5)
      .map((item, index) => ({
        title: item.city,
        percentage: Math.round(item.sales_share),
        id: index,
      }))
      .filter((item) => item.percentage > 0),
    redirectionLink: analyticsUrl.salesByRegionReport,
  });

  const getFormattedRegionsTableData = (regionsData) =>
    regionsData.map(
      ({
        city,
        sales_share: salesShare,
        sales,
        state,
        order_count: orderCount,
      }) => ({
        region: state,
        city,
        orders: orderCount,
        sales: formatLocalMoney(Math.round(sales)),
        share: `${Math.round(salesShare)}%`,
      })
    );

  const getFormatedTrafficSourceSalesChart = (data) => ({
    title: 'Sales by traffic source',
    helpText: 'Top performing traffic sources in terms of sales.',
    data: data
      .reverse()
      .slice(0, 5)
      .map((item, index) => ({
        title: item.value,
        percentage: Math.round(item.sales_share),
        id: index,
      })),
    formattingFunction: formatLocalMoney,
    redirectionLink: analyticsUrl.salesByTrafficSource,
  });

  // get formatted data to display in table for Orders / Sales
  const getFormatedOrdersTableData = (
    totalOrdersData,
    dateType,
    dataKey,
    dateRange,
    orderingDirectionAnalytics
  ) => {
    const allDatesInRange = Object.keys(
      getDates(
        new Date(dateRange.created_at_after),
        new Date(dateRange.created_at_before)
      )
    ).reverse();

    let data;
    if (dateType === 'lifetime') {
      data = totalOrdersData.sort((a, b) => a.year - b.year).reverse();
      const allMonthsInRange = Object.keys(
        getMonths(new Date(createdAt), new Date())
      );
      data = allMonthsInRange
        .map((month) => {
          const dm = data.find(
            (item) =>
              Number(item.month) === new Date(month).getMonth() + 1 &&
              Number(item.year) === new Date(month).getFullYear()
          );

          if (dm) return dm;
          return {
            month: new Date(month).getMonth() + 1,
            order_count: 0,
            order_share: 0,
            sales: 0,
            sales_share: 0,
            year: new Date(month).getFullYear(),
          };
        })
        .reverse();
    } else {
      const sortedData = totalOrdersData.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);

        return bDate.getTime() - aDate.getTime();
      });
      data = allDatesInRange.map((date) => {
        const dt = sortedData.find((item) => item.date === date);
        if (dt) return dt;
        return {
          date,
          order_count: 0,
          sales: 0,
          order_share: 0,
          sales_share: 0,
        };
      });
    }

    let tableData = data.map((item, index) => {
      const isPositive = data[index + 1]
        ? item[dataKey] - data[index + 1][dataKey] > 0
        : false;

      return {
        month:
          dateType === 'lifetime'
            ? MONTH[item.month].concat(' ', item.year)
            : formatDate(item.date),
        ...(dataKey === 'order_count'
          ? {
              sales: formatLocalMoney(Math.round(item.sales)),
              orders: formatLocalNumber(item.order_count),
            }
          : {
              orders: formatLocalNumber(item.order_count),
              sales: formatLocalMoney(Math.round(item.sales)),
            }),
        change: {
          isPositive,
          percentage: data[index + 1]
            ? calculatePercentage(item[dataKey], data[index + 1][dataKey])
            : 0,
        },
      };
    });

    tableData =
      dateType === 'lifetime'
        ? tableData
        : tableData.slice(0, tableData.length - 1);
    if (orderingDirectionAnalytics === 'desc') {
      tableData = tableData.reverse();
    }

    return tableData;
  };

  const getStoreOverviewFormatedData = (
    avgViewsPerDayNew,
    avgViewsPerDayOld,
    avgReturningBuyersNew,
    avgReturningBuyersOld,
    dateRangeType
  ) => [
    {
      title: 'Average store views per day',
      total: formatLocalNumber(avgViewsPerDayNew),
      percentage:
        dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
          ? 0
          : calculatePercentage(avgViewsPerDayNew, avgViewsPerDayOld),
      isPositive: avgViewsPerDayNew - avgViewsPerDayOld > 0,
      helpText: handleDynamicTooltipText(dateRangeType),
      headerHelpText:
        'Average count of times, content of the home page is loaded or re-loaded per day in the selected time',
    },
    {
      title: 'Returning visitors',
      total: formatLocalNumber(avgReturningBuyersNew),
      percentage:
        dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
          ? 0
          : calculatePercentage(avgReturningBuyersNew, avgReturningBuyersOld),
      isPositive: avgReturningBuyersNew - avgReturningBuyersOld > 0,
      helpText: handleDynamicTooltipText(dateRangeType),
      headerHelpText:
        'The total number of users that visited the store more than once in the selected time.',
    },
  ];

  const getSessionByDevicePieData = (sessionsData) => {
    const chartColors = ['#146eb4', '#5a9aca', '#a0c5e0'];
    let totalSessions = 0;
    const pieData = sessionsData.map((item, index) => {
      totalSessions += item.sessions_per_day ?? 0;
      return {
        name: item.device_type,
        value: item.sessions_per_day ?? 0,
        fill: chartColors[index],
      };
    });
    if (totalSessions === 0) {
      pieData.push({ name: 'no fill', value: 1, fill: '#e6e6e6' });
    }
    return {
      title: 'Views by device type',
      tooltipText:
        'Comparison of different device types in terms of number of views.',
      pieData,
      pieChartTotalText: 'Total views',
      pieChartTotalNumber: totalSessions,
    };
  };

  const getFormattedStoreViewsOverviewData = (
    storeViewsDataNew,
    storeViewsDataOld,
    dateRangeType
  ) => ({
    isPositive:
      storeViewsDataNew.total_views - storeViewsDataOld.total_views > 0,
    number: storeViewsDataNew.total_views || 0,
    percentage:
      dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
        ? 0
        : calculatePercentage(
            storeViewsDataNew.total_views,
            storeViewsDataOld.total_views
          ),
    helpText: handleDynamicTooltipText(dateRangeType),
    description:
      dateRangeType === 'custom_range'
        ? ''
        : `${
            storeViewsDataNew.total_views - storeViewsDataOld.total_views > 0
              ? 'increase'
              : 'decrease'
          } ${descriptionText[dateRangeType]}.`,
    title: 'Store views',
    tooltipText: 'Total number of store views in the selected time.',
    formattingFunction: formatLocalNumber,
    redirectionLink: analyticsUrl.storeOverviewReport,
    graphTooltipValueLabel: 'Views',
  });

  const getFormattedSessionsOverviewData = (
    sessionViewsNew,
    sessionViewsOld,
    dateRangeType
  ) => ({
    isPositive: sessionViewsNew.total_views - sessionViewsOld.total_views > 0,
    number: sessionViewsNew.total_views,
    percentage:
      dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
        ? 0
        : calculatePercentage(
            sessionViewsNew.total_views,
            sessionViewsOld.total_views
          ),
    helpText: handleDynamicTooltipText(dateRangeType),
    description:
      dateRangeType === 'custom_range'
        ? ''
        : `${
            sessionViewsNew.total_views - sessionViewsOld.total_views > 0
              ? 'increase'
              : 'decrease'
          } ${descriptionText[dateRangeType]}.`,
    title: 'Sessions',
    tooltipText:
      'A session refers to a continuous period of activity by a visitor on your store.',
    formattingFunction: formatLocalNumber,
    redirectionLink: analyticsUrl.storeSessionsReport,
    graphTooltipValueLabel: 'Sessions',
  });

  const replaceViewLink = (link) => {
    let displayLink = link
      .replace(domainWithLink, '')
      .replace(`${APP_DOMAIN}${business.link}`, '');
    if (displayLink === '') {
      displayLink = '/';
    }
    return displayLink;
  };

  const getFormattedSessionsByPageChart = (data = []) => {
    const formattedData = isObjectEmpty(data)
      ? []
      : data
          ?.sort((a, b) => b.sessions - a.sessions)
          ?.filter((item) => !item.u_pageURL.includes('localhost'))
          ?.slice(0, 6)
          ?.map((item, index) => ({
            title: replaceViewLink(item.u_pageURL),
            link: item.u_pageURL,
            percentage: Math.round(item.sessions),
            id: index,
          }));

    return {
      title: 'Top landing pages by UTM sessions',
      helpText:
        'Top pages that generated the most sessions and brought visitors to your store.',
      data: formattedData,
      redirectionLink: analyticsUrl.storeSessionsByPageReport,
    };
  };

  const getFormattedSessionsByPageTableData = (data = []) =>
    data
      ?.filter((item) => !item.u_pageURL.includes('localhost'))
      ?.map(({ u_pageURL: link, sessions }) => ({
        product: {
          name: replaceViewLink(link),
          redirectLink: link,
        },
        sessions,
      }));

  const getFormattedSessionsByUTMChart = (data = []) => {
    const formattedData = isObjectEmpty(data)
      ? []
      : data
          ?.sort((a, b) => b.sessions - a.sessions)
          ?.slice(0, 6)
          ?.map((item, index) => ({
            title: item.u_utmSource || 'None',
            percentage: Math.round(item.sessions),
            id: index,
          }));

    return {
      title: 'Top landing pages by UTM source',
      helpText:
        'Top utm soruces that generated the most sessions and brought visitors to your store.',
      data: formattedData,
      redirectionLink: analyticsUrl.storeSessionsByUTMReport,
    };
  };

  const getFormattedSessionsByUTMTableData = (data = []) =>
    data.map(({ u_utmSource: source, sessions }) => ({
      product: {
        name: source || 'None',
      },
      sessions,
    }));

  const getFormattedStoreViewsTableData = (
    storeViewsData,
    dateType,
    dateRange,
    orderingDirectionAnalytics
  ) => {
    const allDatesInRange = Object.keys(
      getDates(
        new Date(dateRange.created_at_after),
        new Date(dateRange.created_at_before)
      )
    ).reverse();
    const sortedData = storeViewsData.sort((a, b) => {
      const aDate = new Date(a.key);
      const bDate = new Date(b.key);

      return bDate.getTime() - aDate.getTime();
    });

    let data;
    if (dateType === 'lifetime') {
      const allMonthsInRange = Object.keys(
        getMonths(new Date(createdAt), new Date())
      );
      data = allMonthsInRange
        .map((month) => {
          const dm = sortedData.find(
            (item) => item.key.slice(0, 8) === month.slice(0, 8)
          );
          if (dm) return dm;
          return {
            key: month,
            views: 0,
          };
        })
        .reverse();
    } else {
      data = allDatesInRange.map((date) => {
        const dt = sortedData.find((item) => item.key === date);
        if (dt) return dt;
        return {
          key: date,
          views: 0,
        };
      });
    }

    let tableData = data.map((item, index) => {
      const isPositive = data[index + 1]
        ? item.views - data[index + 1].views > 0
        : false;

      const date = new Date(item.key);
      return {
        month:
          dateType === 'lifetime'
            ? MONTH[date.getMonth() + 1].concat(' ', date.getFullYear())
            : formatDate(date),
        storeViews: formatLocalNumber(item.views),
        change: {
          isPositive,
          percentage: data[index + 1]
            ? calculatePercentage(item.views, data[index + 1].views)
            : 0,
        },
      };
    });

    tableData =
      dateType === 'lifetime'
        ? tableData
        : tableData.slice(0, tableData.length - 1);
    if (orderingDirectionAnalytics === 'desc') {
      tableData = tableData.reverse();
    }
    return tableData;
  };

  const getFormattedStoreSessionsTableData = (
    storeSessionsData,
    dateType,
    dateRange,
    orderingDirectionAnalytics
  ) => {
    const allDatesInRange = Object.keys(
      getDates(
        new Date(dateRange.created_at_after),
        new Date(dateRange.created_at_before)
      )
    ).reverse();
    const sortedData = storeSessionsData.sort((a, b) => {
      const aDate = new Date(a.key);
      const bDate = new Date(b.key);

      return bDate.getTime() - aDate.getTime();
    });

    let data;
    if (dateType === 'lifetime') {
      const allMonthsInRange = Object.keys(
        getMonths(new Date(createdAt), new Date())
      );
      data = allMonthsInRange
        .map((month) => {
          const dm = sortedData.find(
            (item) => item.key.slice(0, 8) === month.slice(0, 8)
          );
          if (dm) return dm;
          return {
            key: month,
            views: 0,
          };
        })
        .reverse();
    } else {
      data = allDatesInRange.map((date) => {
        const dt = sortedData.find((item) => item.key === date);
        if (dt) return dt;
        return {
          key: date,
          views: 0,
        };
      });
    }

    let tableData = data.map((item, index) => {
      const isPositive = data[index + 1]
        ? item.views - data[index + 1].views > 0
        : false;

      const date = new Date(item.key);
      return {
        month:
          dateType === 'lifetime'
            ? MONTH[date.getMonth() + 1].concat(' ', date.getFullYear())
            : formatDate(date),
        sessions: formatLocalNumber(item.sessions),
        change: {
          isPositive,
          percentage: data[index + 1]
            ? calculatePercentage(item.sessions, data[index + 1].sessions)
            : 0,
        },
      };
    });

    tableData =
      dateType === 'lifetime'
        ? tableData
        : tableData.slice(0, tableData.length - 1);
    if (orderingDirectionAnalytics === 'desc') {
      tableData = tableData.reverse();
    }
    return tableData;
  };

  const getAverageProductViews = (
    productData,
    productDataForComparison,
    categoryData,
    categoryDataForComparison,
    dateRangeType
  ) => {
    // Product data calculation
    let totalProductViews = 0;
    let totalProductViewsForComparison = 0;

    productData.forEach((item) => {
      totalProductViews += item.views;
    });

    productDataForComparison.forEach((item) => {
      totalProductViewsForComparison += item.views;
    });

    const avgViewsPerProduct = productData?.length
      ? totalProductViews / productData.length
      : 0;
    const avgViewsPerProductForComparison = productDataForComparison?.length
      ? totalProductViewsForComparison / productDataForComparison.length
      : 0;

    const productPercentage =
      avgViewsPerProductForComparison === 0 && avgViewsPerProduct === 0
        ? 0
        : calculatePercentage(
            avgViewsPerProduct,
            avgViewsPerProductForComparison
          );

    const isProductPositive =
      avgViewsPerProduct - avgViewsPerProductForComparison > 0;

    //  Category data calculation
    let totalCategoryViews = 0;
    let totalCategoryViewsForComparison = 0;

    categoryData.forEach((item) => {
      totalCategoryViews += item.views;
    });

    categoryDataForComparison.forEach((item) => {
      totalCategoryViewsForComparison += item.views;
    });

    const avgViewsPerCategory = categoryData?.length
      ? totalCategoryViews / categoryData.length
      : 0;
    const avgViewsPerCategoryForComparison = categoryDataForComparison?.length
      ? totalCategoryViewsForComparison / categoryDataForComparison.length
      : 0;

    const categoryPercentage =
      avgViewsPerCategoryForComparison === 0 && avgViewsPerCategory === 0
        ? 0
        : calculatePercentage(
            avgViewsPerCategory,
            avgViewsPerCategoryForComparison
          );

    const isCategoryPositive =
      avgViewsPerCategory - avgViewsPerCategoryForComparison > 0;

    const productOverviewAnalyticsData = [
      {
        title: 'Average views per category',
        total: formatLocalNumber(totalCategoryViews),
        percentage: categoryPercentage,
        isPositive: isCategoryPositive,
        helpText: handleDynamicTooltipText(dateRangeType),
        headerHelpText:
          'Average number of views per category page in the selected time.',
      },
      {
        title: 'Average views per product',
        total: formatLocalNumber(totalProductViews),
        percentage: productPercentage,
        isPositive: isProductPositive,
        helpText: handleDynamicTooltipText(dateRangeType),
        headerHelpText:
          'Average number of views per product page in the selected time.',
      },
    ];
    return productOverviewAnalyticsData;
  };

  const getFormattedProductViewsGraphData = (data1, data2, dateRangeType) => ({
    isPositive: data1.total_views - data2.total_views > 0,
    number: data1.total_views,
    percentage:
      dateRangeType === 'custom_range' || dateRangeType === 'lifetime'
        ? 0
        : calculatePercentage(data1.total_views, data2.total_views),
    helpText: handleDynamicTooltipText(dateRangeType),
    description:
      dateRangeType === 'custom_range'
        ? ''
        : `${
            data1.total_views - data2.total_views > 0 ? 'increase' : 'decrease'
          } ${descriptionText[dateRangeType]}.`,
    title: 'Product views',
    tooltipText: ' Number of views of all the products in the selected time.',
    formattingFunction: formatLocalNumber,
    redirectionLink: analyticsUrl.productViewsReport,
    graphTooltipValueLabel: 'Views',
  });

  const getFormattedTopCategoryData = (data, totalSales) => ({
    title: 'Top categories by sales',
    helpText: 'Top performing categories in terms of sales.',
    data: data
      .sort((a, b) => b.sales_amount - a.sales_amount)
      .slice(0, 5)
      .map((item) => ({
        title: item.category_name,
        percentage: Math.round((item.sales_amount / totalSales) * 100),
      })),
    redirectionLink: analyticsUrl.salesByCategoryReport,
  });

  const getFormattedTopProductsByUnits = (data) => ({
    title: 'Top products by units sold',
    helpText: 'Top performing products in terms of units sold.',
    data: data
      .sort((a, b) => b.units_sold - a.units_sold)
      .slice(0, 6)
      .map((item) => ({
        title: item.product_name,
        units: item.units_sold,
        imgUrl: item.product_image,
        slug: item.product_slug,
      })),
    redirectionLink: analyticsUrl.salesByProductReport,
  });

  const getProductViewsTableData = (
    productData,
    dateType,
    dateRange,
    orderingDirectionAnalytics
  ) => {
    const allDatesInRange = Object.keys(
      getDates(
        new Date(dateRange.created_at_after),
        new Date(dateRange.created_at_before)
      )
    ).reverse();
    const sortedData = productData.sort((a, b) => {
      const aDate = new Date(a.key);
      const bDate = new Date(b.key);

      return bDate.getTime() - aDate.getTime();
    });

    let data;
    if (dateType === 'lifetime') {
      const allMonthsInRange = Object.keys(
        getMonths(new Date(createdAt), new Date())
      );
      data = allMonthsInRange
        .map((month) => {
          const dm = sortedData.find(
            (item) => item.key.slice(0, 8) === month.slice(0, 8)
          );
          if (dm) return dm;
          return {
            key: month,
            views: 0,
          };
        })
        .reverse();
    } else {
      data = allDatesInRange.map((date) => {
        const dt = sortedData.find((item) => item.key === date);
        if (dt) return dt;
        return {
          key: date,
          views: 0,
        };
      });
    }

    let tableData = data.map((item, index) => {
      const isPositive = data[index + 1]
        ? item.views - data[index + 1].views > 0
        : false;
      const date = new Date(item.key);
      return {
        month:
          dateType === 'lifetime'
            ? MONTH[date.getMonth() + 1].concat(' ', date.getFullYear())
            : formatDate(date),
        views: item.views,
        change: {
          isPositive,
          percentage: data[index + 1]
            ? calculatePercentage(item.views, data[index + 1].views)
            : null,
        },
      };
    });

    tableData =
      dateType === 'lifetime'
        ? tableData
        : tableData.slice(0, tableData.length - 1);
    if (orderingDirectionAnalytics === 'desc') {
      tableData = tableData.reverse();
    }
    return tableData;
  };

  const getFormattedSalesByProductData = (data) => {
    const tableData = data.map(
      ({
        product_name: productName,
        units_sold: unitsSold,
        total_sales: totalSales,
        product_slug: productSlug,
      }) => ({
        product: {
          name: productName,
          redirectLink: `${domainWithLink}/products/${productSlug}`,
        },
        unitsSold,
        totalSales: formatLocalMoney(Math.round(totalSales)),
      })
    );
    return tableData;
  };

  const getCategoryBySalesTableData = (data) =>
    data.map(
      ({
        category_name: categoryName,
        total_products: totalProducts,
        sales_amount: totalSales,
        category_slug: categorySlug,
        is_deleted: isDeleted = false,
      }) => ({
        product: {
          name: categoryName,
          redirectLink: `${domainWithLink}/categories/${categorySlug}`,
          isDeleted,
        },
        totalProducts,
        totalSales: formatLocalMoney(Math.round(totalSales)),
      })
    );

  const getSalesByTrafficSourceTableData = (dataNew, dataOld) => ({
    totalTrafficSources: dataNew?.length,
    isPositive: dataNew?.length - dataOld?.length > 0,
    percentage: calculatePercentage(dataNew?.length, dataOld?.length),
    tableData: dataNew?.map((item) => ({
      source: item.value,
      totalOrders: item.order_count,
      // visitors: item.visitors ?? 0, // show when api returns data
      // sessions: item.sessions ?? 0, // show when api returns data
      percentage: `${item.sales_share}%`,
    })),
  });

  const renderOverViewChartShimmer = ({
    cardCount = 2,
    extraClassName = '',
    adjustGraphHeight = 0,
    renderHalfView = false,
  }) => {
    const shimmerArray = Array(cardCount)
      .fill(1)
      .map((_, index) => (
        <div
          className={`analytics__overview-chart-card d-flex flex-column card p20 ${extraClassName}`}
          key={index}
        >
          <ContentLoader speed={2} width="100%" height="100%">
            <rect x="0" y="0" rx="4" ry="4" width="25%" height="24" />
            <rect x="0" y="32" rx="4" ry="4" width="30%" height="40" />
            <rect
              x="0"
              y="80"
              rx="4"
              ry="4"
              width="100%"
              height={230 + adjustGraphHeight}
            />
            <rect
              x="40%"
              y={342 + adjustGraphHeight}
              rx="4"
              ry="4"
              width="20%"
              height="20"
            />
          </ContentLoader>
        </div>
      ));
    if (renderHalfView) {
      shimmerArray.push(
        <div
          className={`analytics__overview-chart-card p20 ${'ml12'} ${extraClassName}`}
        />
      );
    }
    return shimmerArray;
  };
  const renderOverViewCardShimmer = ({
    cardCount = 4,
    viewWidth = '100%',
    viewHeight = '100%',
    rowCount = 3,
    extraClassName = '',
  }) =>
    Array(cardCount)
      .fill(1)
      .map((_, index) => (
        <div className={`card p16 ${extraClassName}`} key={index}>
          <ContentLoader speed={2} width={viewWidth} height={viewHeight}>
            <rect x="0" y="0" rx="4" ry="4" width="140" height="22" />
            {rowCount === 3 && (
              <rect x="0" y="30" rx="4" ry="4" width="90" height="22" />
            )}
            <rect
              x="0"
              y={rowCount === 3 ? '70' : '50'}
              rx="4"
              ry="4"
              width="70"
              height="30"
            />
          </ContentLoader>
        </div>
      ));

  const renderTopProductsByUnitsShimmer = () => (
    <div className="card pl10 pr20 mt8 height-256 d-flex">
      <ContentLoader speed={2} width="100%" height="100%">
        <rect x="20" y="20" rx="4" ry="4" width="289" height="24" />
        <rect x="20" y="60" rx="4" ry="4" width="100%" height="175" />
        <rect x="324" y="193" rx="4" ry="4" width="1" height="0" />
      </ContentLoader>
    </div>
  );

  return {
    getComparisonDateRange,
    getDateRange,
    getFormatedTotalOrdersData,
    getFormatedTotalSalesData,
    getConversionsFormattedData,
    getFormatedRegionSalesChart,
    getOverviewFormatedData,
    getFormatedTrafficSourceSalesChart,
    getFormatedOrdersTableData,
    getFormattedRegionsTableData,
    formatDataForGraph,
    getStoreOverviewFormatedData,
    getSessionByDevicePieData,
    getFormattedStoreViewsOverviewData,
    getFormattedSessionsOverviewData,
    getFormattedSessionsByPageChart,
    getFormattedSessionsByPageTableData,
    getFormattedSessionsByUTMChart,
    getFormattedSessionsByUTMTableData,
    getFormattedStoreViewsTableData,
    getAverageProductViews,
    getFormattedProductViewsGraphData,
    getFormattedTopCategoryData,
    getFormattedTopProductsByUnits,
    getProductViewsTableData,
    getFormattedSalesByProductData,
    getCategoryBySalesTableData,
    getSalesByTrafficSourceTableData,
    renderOverViewChartShimmer,
    renderOverViewCardShimmer,
    renderTopProductsByUnitsShimmer,
    getFormattedStoreSessionsTableData,
  };
};

export default useAnalyticsHelper;
