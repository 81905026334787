import { useEffect, useState } from 'react';
import {
  AVAILABLE_LANGUAGES,
  UPDATE_STORE_LANGUAGE,
  STORE_LANGUAGES,
  LANGUAGE_BULK_UPDATE_FILE,
} from '../ApiUrls';
import { useSocket } from '../context/SocketProvider';
import { noop } from '../utils';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';

const useLanguages = () => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [languages, setLanguages] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableLanguageLoading, setAvailableLanguageLoading] =
    useState(false);
  const [downloadSampleFileClicked, setDownloadSampleFileClicked] =
    useState(false);
  const [downloadFileLoading, setDownloadFileLoading] = useState(false);
  const [updatedPrimaryLanguage, setUpdatedPrimaryLanguage] = useState(null);
  const { enqueueSnackbar } = useCustomSnackbar();
  const { on } = useSocket();
  const [timeoutLoading, setTimeoutLoading] = useState(false);

  const getErrorRecursively = (errorsArray, errorObject) => {
    for (const [, value] of Object.entries(errorObject)) {
      if (Array.isArray(value) && typeof value[0] === 'string') {
        errorsArray.push(value[0]);
      } else if (typeof value === 'string') {
        errorsArray.push(value);
      } else if (Object.keys(value).length) {
        getErrorRecursively(errorsArray, value);
      }
      return errorsArray;
    }
  };

  const filterAvailableLanguages = () => {
    let filteredOptions = [];
    if (queryString === '') {
      filteredOptions = availableLanguages;
    } else {
      filteredOptions = availableLanguages.filter((item) =>
        item.name.toLowerCase().includes(queryString.toLowerCase().trim())
      );
    }
    return filteredOptions;
  };

  const fetchAvailableLanguages = () => {
    setAvailableLanguageLoading(true);
    const params = {};
    getRequest({
      url: AVAILABLE_LANGUAGES,
      data: params,
    })
      .then((res) => {
        setAvailableLanguages(res.data.languages);
      })
      .catch((e) => console.log(e))
      .finally(() => setAvailableLanguageLoading(false));
  };

  const addLanguage = (data, successCallback = noop) => {
    setLoading(true);
    const payload = { languages: data };
    postRequest({
      url: STORE_LANGUAGES,
      data: payload,
    })
      .then(() => {
        successCallback();
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const deleteStoreLanguage = (languageUuid, successCallback = noop) => {
    setLoading(true);
    deleteRequest({
      url: UPDATE_STORE_LANGUAGE(languageUuid),
    })
      .then(() => {
        successCallback();
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const changePrimaryLanguage = (languageUuid, successCallback = noop) => {
    setLoading(true);
    const payload = { is_default: true };
    patchRequest({
      url: UPDATE_STORE_LANGUAGE(languageUuid),
      data: payload,
    })
      .then((res) => {
        setPageNumber(1);
        setTimeoutLoading(true);
        enqueueSnackbar('This might take some time, please wait', 'info');
        successCallback();
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const fetchStoreLanguages = () => {
    setLoading(true);
    const params = { page: pageNumber };
    getRequest({
      url: STORE_LANGUAGES,
      data: params,
    })
      .then((res) => {
        const { count, results = [] } = res ?? {};
        if (pageNumber === 1) {
          setHasMore(results.length < count);
          setLanguages(results);
        } else {
          setHasMore(languages.length + results.length < count);
          setLanguages((prev) => [...new Set([...prev, ...results])]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const downloadSampleFile = (successCallback = noop) => {
    setDownloadFileLoading(true);
    getRequest({
      url: LANGUAGE_BULK_UPDATE_FILE,
    })
      .then(() => {
        setDownloadSampleFileClicked(true);
        successCallback();
      })
      .catch((e) => console.log(e))
      .finally(() => setDownloadFileLoading(false));
  };

  const handleFileUploadSuccess = (successCb) => {
    on('bulk_update_store_resource_language', (socketResponse) => {
      const { status } = socketResponse?.data ?? {};
      if (status) {
        successCb();
      }
    });
  };

  const bulkUploadLanguageFile = (file, successCb = noop, errorCb = noop) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    postRequest({
      url: LANGUAGE_BULK_UPDATE_FILE,
      data: { file_url: file },
    })
      .then(() => {
        handleFileUploadSuccess(successCb);
      })
      .catch((e) => {
        const { data = {} } = e;
        let errorObject = {};
        if (typeof data !== 'string' && data?.data) {
          errorObject = data?.data;
        } else {
          enqueueSnackbar('Something went wrong', 'error');
        }
        const errorMessages = getErrorRecursively([], errorObject);
        errorCb(errorMessages);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    on('update_default_store_language', (socketResponse) => {
      setTimeoutLoading(false);
      const { status } = socketResponse?.data ?? {};
      if (status) {
        if (updatedPrimaryLanguage) {
          enqueueSnackbar(`Primary language changed ${updatedPrimaryLanguage}`);
        }
        fetchStoreLanguages();
      }
    });
  }, [updatedPrimaryLanguage]);

  return {
    loading,
    timeoutLoading,
    hasMore,
    pageNumber,
    availableLanguageLoading,
    downloadFileLoading,
    languages,
    availableLanguages: filterAvailableLanguages(),
    downloadSampleFileClicked,
    setPageNumber,
    setQueryString,
    fetchStoreLanguages,
    fetchAvailableLanguages,
    addLanguage,
    deleteStoreLanguage,
    changePrimaryLanguage,
    downloadSampleFile,
    bulkUploadLanguageFile,
    setUpdatedPrimaryLanguage,
    setDownloadSampleFileClicked,
  };
};

export default useLanguages;
