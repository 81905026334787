import React from 'react';
import ContentLoader from 'react-content-loader';
import cx from 'classnames';

const Shimmer = ({ width, height }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <rect x="0" y="0" rx="2" ry="2" width={width} height={height} />
  </ContentLoader>
);

export const ProductsShimmer = ({ itemCount = 1 }) => (
  <div className="mb20">
    {[...Array(itemCount).keys()].map((_, index) => (
      <div className="d-flex space-between mb12 w-100">
        <ContentLoader width="100%" height="96" speed={2} key={index}>
          <rect x="0" y="0" rx="4" ry="4" height="96" width="96" />
          <rect x="116" y="0" rx="4" ry="4" height="32" width="121" />
          <rect x="116" y="40" rx="4" ry="4" height="16" width="103" />
          <rect x="116" y="64" rx="4" ry="4" height="24" width="43" />
        </ContentLoader>
      </div>
    ))}
  </div>
);

export const OrderTotalShimmer = () => (
  <div className="order-total order-total-shimmer pt24">
    <div>
      <div>
        <Shimmer height={24} width={78} />
      </div>
      <div>
        <Shimmer height={24} width={50} />
      </div>
    </div>
    <div>
      <div>
        <Shimmer height={24} width={63} />
      </div>
      <div>
        <Shimmer height={24} width={41} />
      </div>
    </div>
    <div className="mt24">
      <div>
        <Shimmer height={26} width={105} />
      </div>
      <div>
        <Shimmer height={26} width={56} />
      </div>
    </div>
  </div>
);

export const NotesTimelineShimmer = ({ hasSomeOrderDetails = false }) => (
  <div className={cx('notes-timeline', { 'ml0 pb0': hasSomeOrderDetails })}>
    <div className="card pt24 px24 pb56 d-flex flex-column">
      <div className="pb16">
        <Shimmer height={24} width={74} />
      </div>
      <div className="d-flex pb20 timeline">
        <div className="d-flex flex-column space-between pl10">
          <div>
            <Shimmer height={26} width={198} />
          </div>
          <div>
            <Shimmer height={20} width={198} />
          </div>
        </div>
      </div>
      <div className="d-flex pb20 timeline">
        <div className="d-flex flex-column space-between pl10">
          <div>
            <Shimmer height={26} width={198} />
          </div>
          <div>
            <Shimmer height={20} width={198} />
          </div>
        </div>
      </div>
      <div className="d-flex pb20 timeline">
        <div className="d-flex flex-column space-between pl10">
          <div>
            <Shimmer height={26} width={198} />
          </div>
          <div>
            <Shimmer height={20} width={198} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const RTOCardShimmer = () => (
  <div className="d-flex gap24 card p24 mb24 align-center">
    <div className="attention-icon">
      <Shimmer height={100} width={100} />
    </div>
    <div className="rto-recommended-action">
      <div className="section-text-5 c-black-1">
        <Shimmer height={24} width={360} />
      </div>
      <div className="text-16">
        <Shimmer height={22} width={360} />
      </div>
    </div>
    <div className="rto-action-btn-wrapper">
      <Shimmer height={54} width={204} />
    </div>
  </div>
);

const OrderDetailShimmer = ({
  showNotesTimeline,
  isStatusAbandoned = false,
}) => (
  <div className="d-flex justify-center w-100">
    <div className="d-flex flex-column fill-available">
      <div className="card p24">
        <div className="d-flex space-between border-bottom pb8 ">
          <div>
            <div className="section-text-4">
              <Shimmer height={26} width={140} />
            </div>
            <div className="section-text-8 ">
              <Shimmer height={24} width={160} />
            </div>
          </div>
          <div className="section-text-4">
            <div>
              <Shimmer height={26} width={140} />
            </div>
          </div>
        </div>
        <div className="d-flex space-between border-bottom mt16">
          <div>
            <div className="section-text-4 ">
              <Shimmer height={24} width={62} />
            </div>
            <ProductsShimmer />
          </div>
          <div>
            {!isStatusAbandoned && (
              <div className="section-text-4">
                {' '}
                <Shimmer height={24} width={124} />
              </div>
            )}
          </div>
        </div>
        <OrderTotalShimmer />
      </div>
      <div className="card p24 mt24">
        <div>
          <div className="pt24">
            <div className="section-text-5 c-gray-1">
              <Shimmer height={24} width={161} />
            </div>
          </div>
          <div className="d-flex space-between">
            <div className="pt20">
              <div>
                <Shimmer height={24} width={47} />
              </div>
              <div>
                <Shimmer height={24} width={100} />
              </div>
            </div>
            <div className="pt20" style={{ width: '50%' }}>
              <div>
                <Shimmer height={24} width={53} />
              </div>
              <div>
                <Shimmer height={24} width={121} />
              </div>
            </div>
          </div>
          <div className="pt16">
            <div>
              <Shimmer height={24} width={64} />
            </div>
            <div>
              <Shimmer height={24} width={712} />
            </div>
          </div>
          <div className="d-flex space-between mb8">
            <div className="pt16">
              <div>
                <Shimmer height={24} width={43} />
              </div>
              <div>
                <Shimmer height={24} width={344} />
              </div>
            </div>

            <div className="pt16" style={{ width: '50%' }}>
              <div>
                <Shimmer height={24} width={33} />
              </div>
              <div>
                <Shimmer height={24} width={344} />
              </div>
            </div>
          </div>
          <div className="d-flex space-between mb8">
            <div className="pt16">
              <div>
                <Shimmer height={24} width={71} />
              </div>
              <div>
                <Shimmer height={24} width={344} />
              </div>
            </div>
            <div className="pt16" style={{ width: '50%' }}>
              <div>
                <Shimmer height={24} width={71} />
              </div>
              <div>
                <Shimmer height={24} width={132} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {showNotesTimeline && <NotesTimelineShimmer />}
  </div>
);

export default OrderDetailShimmer;
