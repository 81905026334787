import React, { useState } from 'react';
import moment from 'moment';
import SpinnerButton from '../SpinnerButton';

import { noop } from '../../utils';
import { Form } from 'react-final-form';
import { modifyFormField } from '../../shared/Form/Shared';
import TimePickerNumberStepper from './TimePickerNumberStepper';

const TimePickerModal = ({
  formValues,
  setOpenPopper,
  onChange = noop,
  title = 'Select time',
  hours,
  minutes,
  defaultAMPM,
  ampm,
  setAmpm,
  isDateSame,
  timeString,
  isCurrentDate = noop,
  isStartTimeInput = false,
  isEndTimeInput = false,
  handleIsAllowed,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const createTimeString = (values) => {
    onChange(
      moment(`${values.hours}:${values.minutes} ${ampm}`, ['h:mm a']).format(
        'HH:mm'
      ),
      ampm
    );
  };

  const prevHour = timeString ? timeString.split(':')[0] : null;
  const prevMinute = timeString ? timeString.split(':')[1].slice(0, 2) : null;

  const handleClose = (values) => {
    createTimeString(values);
    setOpenPopper(false);
  };

  const checkValidTime = (form, values, defaultAMPM, ampm) => {
    let validHour = moment(formValues?.start_date).hour();
    let validMinute = moment(formValues?.start_date).minute();
    let formattedValidHour =
      validHour == '12' || validHour == '00' ? '12' : Number(validHour) % 12;

    if (isEndTimeInput && validHour < 12) {
      if (!(values.hours !== 12 && values.hours > formattedValidHour)) {
        form.mutators.modifyFormField('hours', formattedValidHour);
        if (values.minutes < validMinute) {
          form.mutators.modifyFormField('minutes', validMinute);
        }
      }

      if (values.hours == formattedValidHour && values.minutes < validMinute) {
        form.mutators.modifyFormField('minutes', validMinute);
      }
    } else if (
      ((isCurrentDate && isCurrentDate(formValues?.start_date)) ||
        formValues?.start_date === undefined) &&
      defaultAMPM === 'am'
    ) {
      if (!(values.hours !== 12 && values.hours > hours)) {
        form.mutators.modifyFormField('hours', hours);
      }
      if (
        values.hours === hours &&
        defaultAMPM === ampm &&
        values.minutes < minutes
      ) {
        form.mutators.modifyFormField('minutes', minutes);
      }
    }
  };

  // manages +1 hour logic
  const calcHour = () => {
    if (isEndTimeInput) return 11;
    else if (isStartTimeInput) {
      if (isCurrentDate(formValues?.start_date) || !formValues?.start_date) {
        if (hours == 12) {
          return '01';
        } else if (hours == 11) {
          if (defaultAMPM === 'am') setAmpm('pm');
        }
        return Number(hours) + 1;
      }
    } else return 12;
  };

  const calcMinute = () => {
    if (isEndTimeInput) return 59;
    else if (
      ((isStartTimeInput || isEndTimeInput) && !formValues?.start_date) ||
      isCurrentDate(formValues?.start_date)
    )
      return minutes;
    return '00';
  };

  return (
    <Form
      onSubmit={noop}
      mutators={{ modifyFormField }}
      initialValues={{
        hours: hours,
        minutes: minutes,
      }}
      render={({ values, form }) => (
        <div className="timepicker-modal">
          <div>
            <span className="text-1 mt4 text-medium">
              <div className="d-flex-c-c">{title}</div>
            </span>

            <div className="mt24 mb32 d-flex align-center">
              <TimePickerNumberStepper
                form={form}
                values={values}
                initialValue={prevHour || calcHour()}
                limit={12}
                inputName="hours"
                isDateSame={isDateSame}
                currentTime={hours}
                ampm={ampm}
                hours={hours}
                minutes={minutes}
                defaultAMPM={defaultAMPM}
                formValues={formValues}
                handleIsAllowed={handleIsAllowed}
                setAMPM={setAmpm}
                setIsDisabled={setIsDisabled}
              />
              <span className="section-text-1 ml16 mr16">:</span>
              <TimePickerNumberStepper
                form={form}
                values={values}
                initialValue={prevMinute || calcMinute()}
                limit={59}
                inputName="minutes"
                isDateSame={isDateSame}
                currentTime={minutes}
                ampm={ampm}
                hours={hours}
                defaultAMPM={defaultAMPM}
                formValues={formValues}
                handleIsAllowed={handleIsAllowed}
                setIsDisabled={setIsDisabled}
              />

              <div className="ml24">
                <span
                  className={
                    ampm === 'am'
                      ? 'c-purple-1 section-text-3 mr16 cur-p'
                      : 'section-text-3 c-gray-6 mr16 cur-p'
                  }
                  onClick={() => {
                    if (
                      (isStartTimeInput &&
                        (isCurrentDate(formValues?.start_date) ||
                          !formValues?.start_date) &&
                        defaultAMPM === 'pm') ||
                      (isEndTimeInput &&
                        (isDateSame || !formValues?.expiry_date) &&
                        moment(formValues?.start_date).hour() >=
                          12)
                    ) {
                      return;
                    } else {
                      if (isStartTimeInput || isEndTimeInput) {
                        checkValidTime(form, values, defaultAMPM, ampm);
                      }
                      setAmpm('am');
                    }
                  }}
                >
                  AM
                </span>
                <span
                  className={
                    ampm === 'pm'
                      ? 'c-purple-1 section-text-3 cur-p'
                      : 'section-text-3 c-gray-6 cur-p'
                  }
                  onClick={() => {
                    setAmpm('pm');
                  }}
                >
                  PM
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-center align-center">
            <SpinnerButton
              className="btn-primary-4 time-picker-button"
              onClick={() => handleClose(values)}
              disabled={
                isDisabled ||
                values.hours < 1 ||
                !values.hours ||
                !values.minutes
              }
            >
              <span className="section-text-5">Done</span>
            </SpinnerButton>
          </div>
        </div>
      )}
    />
  );
};

export default TimePickerModal;
