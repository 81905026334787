import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Field } from 'react-final-form';
import slugify from 'slugify';
import COLORS from '../../../colorConstants';
import { useAppContext } from '../../../context/AppContext';
import CustomAccordion from '../../../shared/CustomAccordian';
import { FormInput, FormTextArea } from '../../../shared/Form';
import { NewGoogleSeoLogo } from '../../../SvgIcon';
import { noop } from '../../../utils';
import ImageCard from '../../../Plugins/AllInOneSEO/ImageCard';
import useProducts from '../../../hooks/useProductsAdvance';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import { SEO_ERROR_MESSAGE, MAX_SLUG_LENGTH } from '../../constants';

const truncate = (text = '', length = 60) => {
  if (text.length <= length) return text;
  return `${text.substring(0, length)}...`;
};

const seoBadgeConst = {
  title: [
    {
      min: 1,
      max: 39,
      status: 'Too Short',
      color: COLORS.SEO.TOO_SHORT,
    },
    {
      min: 40,
      max: 60,
      status: 'Perfect',
      color: COLORS.SEO.PERFECT,
    },
    {
      min: 61,
      max: Number.POSITIVE_INFINITY,
      status: 'Too Long',
      color: COLORS.SEO.TOO_LONG,
    },
  ],
  description: [
    {
      min: 1,
      max: 139,
      status: 'Too Short',
      color: COLORS.SEO.TOO_SHORT,
    },
    {
      min: 140,
      max: 160,
      status: 'Perfect',
      color: COLORS.SEO.PERFECT,
    },
    {
      min: 161,
      max: Number.POSITIVE_INFINITY,
      status: 'Too Long',
      color: COLORS.SEO.TOO_LONG,
    },
  ],
};

const useStyles = makeStyles(() =>
  createStyles({
    previewLink: { color: COLORS.SEO.PREVIEW_LINK },
    badgeStyle: (props) => ({
      width: 10,
      height: 10,
      borderRadius: 5,
      marginRight: 6,
      position: 'relative',
      display: 'inline-block',
      top: 0,
      backgroundColor: props.matchBadgeColor,
    }),
    imageCardContainer: {
      maxWidth: '100%',
      margin: 0,
    },
    imageCard: {
      marginTop: 0,
      padding: '0 10px',
      borderRadius: 0,
      boxShadow: 'none',
    },
    imageCardHeding: {
      paddingBottom: 0,
      marginBottom: 8,
      '& > div': {
        color: COLORS.BLACK_3,
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
      },
    },
    imageCardPreviewTitle: {
      color: COLORS.GRAY_4,
      cursor: 'default',
    },
    imageCardPreviewLink: {
      color: COLORS.GRAY_4,
      userSelect: 'none',
      '&:hover': {
        color: COLORS.GRAY_4,
      },
    },
    imageCardPreviewDesc: {
      cursor: 'default',
    },
  })
);

const SeoPreview = ({
  seo_data: seoData,
  categoryName = '',
  productName = '',
  path = '',
  linkSlug = '',
}) => {
  const { domainWithLink } = useAppContext();
  const styles = useStyles();

  if (!seoData?.title || !seoData?.description) return null;

  return (
    <div className="row mt8 mb8">
      <div className="col-xs-12 pr10 pl10">
        <h4 className="section-text-5 mb16 align-center d-flex">
          <NewGoogleSeoLogo className="mr8" />
          Google Search Preview
        </h4>
        <div className={cx('text-8 break-word', styles.previewLink)}>
          <span className="arial-font c-">{domainWithLink}</span>

          {path && <span className="ml4 arial-font"> &gt; {path}</span>}

          {linkSlug && <span className="ml4 arial-font"> &gt; {linkSlug}</span>}

          {categoryName && (
            <span className="ml4 arial-font"> &gt; {categoryName}</span>
          )}

          {productName && (
            <span className="ml4 arial-font"> &gt; {productName}</span>
          )}

          <span className="seo-link arrow-up" />
        </div>

        <div className="seo-main-text arial-font my8">
          {truncate(seoData?.title)}
        </div>

        <p className="text-8 seo-desc arial-font">
          {truncate(seoData?.description, 160)}
        </p>
      </div>
    </div>
  );
};

const GetSeoBadge = (name, value = '') => {
  const matchBadge = seoBadgeConst[name]?.find(
    (each) => each.min <= value?.length && each.max >= value.length
  );
  const styles = useStyles({
    matchBadgeColor: matchBadge?.color || COLORS.GRAY_1,
  });

  if (!seoBadgeConst[name]) return null;

  if (!matchBadge) return null;

  return (
    <span className="pull-right">
      <span className={styles.badgeStyle} />
      {matchBadge.status}
    </span>
  );
};

const SeoForm = ({
  form,
  values = {},
  defaultExpanded = false,
  outerClasses = '',
  categoryName = '',
  productName = '',
  isEditFlow = false,
  isNew = false,
  isActive = true,
  setMutatedSlug = noop,
  path = '',
  componentType = '',
  linkSlug = '',
  allowImageUpload = true,
  imageCardText = {},
  imageFieldName = '',
  isProduct = false,
  initSlug = '',
  showSlugError = false,
}) => {
  const getSlug = () =>
    slugify(isProduct ? productName : categoryName, { lower: true });

  const { domainWithLink } = useAppContext();
  const { generateSeoTags, isSubmitting } = useProducts();
  const { enqueueSnackbar } = useCustomSnackbar();
  const styles = useStyles();

  const [focusEl, setFocusEl] = useState(false);
  const [slug, setSlug] = useState(getSlug());
  const seoTagType = useRef(null);

  const previewHiddenItemError = () =>
    enqueueSnackbar(
      `Sorry, you cannot preview a hidden ${componentType.toLowerCase()}`,
      'error'
    );

  const removeImage = (fileUploadRef) => {
    fileUploadRef.current.value = '';
    form.mutators.modifyFormField('seo_data.imageUrl', '');
  };

  const showError = () =>
    enqueueSnackbar(
      `You need to add ${SEO_ERROR_MESSAGE[componentType]} first.`,
      'error'
    );

  const getGeneratedTag = (type, data) => {
    const text = JSON.parse(data);
    if (type === 'title') {
      return form.mutators.modifyFormField('seo_data.title', text?.title);
    }
    return form.mutators.modifyFormField(
      'seo_data.description',
      text?.description
    );
  };

  const handleTag = (type) => {
    if (values?.name || values?.title) {
      seoTagType.current = type;
      const categoryNameValue = values?.name;
      const blogTitle = values?.title;
      const pageTitle = values?.title;

      generateSeoTags(
        type,
        productName,
        blogTitle,
        pageTitle,
        categoryNameValue,
        getGeneratedTag
      );
    } else {
      showError();
    }
  };

  useEffect(() => {
    if (initSlug) setSlug(initSlug);
  }, [initSlug]);

  return (
    <CustomAccordion
      defaultExpanded={defaultExpanded}
      className={cx('variant-form', outerClasses)}
      heading={
        <div className="d-flex flex-column">
          <h4 className="section-text-5">Dukaan SEO</h4>
          <p className="text-8 c-gray-1">
            Optimize your product with meta tags to boost its visibility on
            search engines.
          </p>
        </div>
      }
      id="dukaan-seo"
      onChange={(isExpanded) => {
        if (isExpanded && values.slug === '') {
          form.mutators.modifyFormField(
            'slug',
            slugify(values.name ?? '', { lower: true })
          );
          setSlug(slugify(values.name ?? '', { lower: true }));
        }
        if (!isExpanded) {
          form.mutators.modifyFormField('seo_data.title', '');
          form.mutators.modifyFormField('seo_data.description', '');
          form.mutators.modifyFormField('slug', '');
        }
      }}
      details={
        <div className="full-w">
          <div className="accordian-divider" />
          <div className="variant-form-row row">
            <div className="seo-tag col-xs-12 pr10 pl10">
              <FormInput
                name="seo_data.title"
                placeholder="Enter title tag"
                labelText="Title Tag"
                badgeOnRight={GetSeoBadge('title', values?.seo_data?.title)}
                maxLength={300}
              />
              <span onClick={() => handleTag('title')}>
                {isSubmitting && seoTagType.current === 'title' ? (
                  <span className="section-text-7 c-black-3 ml2">
                    Generating
                    <div className="spinner-xs">
                      <div className="bounce1" />
                      <div className="bounce2" />
                      <div className="bounce3" />
                    </div>
                  </span>
                ) : (
                  <span className="anchor-1 underline ml2 text-medium c-black-3">
                    Generate Title
                  </span>
                )}
              </span>
            </div>

            <div className="seo-tag col-xs-12 pr10 pl10 mb16">
              <FormTextArea
                rows={2}
                maxLength={4000}
                name="seo_data.description"
                id="description"
                placeholder="Enter description"
                labelText="Meta Description Tag"
                badgeOnRight={GetSeoBadge(
                  'description',
                  values?.seo_data?.description
                )}
                textAreaClassName="d-block"
              />
              <span onClick={() => handleTag('description')}>
                {isSubmitting && seoTagType.current === 'description' ? (
                  <span className="section-text-7 c-black-3 ml2">
                    Generating
                    <div className="spinner-xs">
                      <div className="bounce1" />
                      <div className="bounce2" />
                      <div className="bounce3" />
                    </div>
                  </span>
                ) : (
                  <span className="anchor-1 underline ml2 text-medium c-black-3">
                    Generate Meta Description
                  </span>
                )}
              </span>
            </div>

            {isEditFlow && (
              <>
                <FormInput
                  name="slug"
                  className="col-xs-12 px10 permalink-input"
                  showCharLimit
                  maxLength={300}
                  labelText="Permalink"
                />
                <Field
                  name="slug"
                  render={() => (
                    <div className="col-xs-12 px10">
                      <div
                        className={cx('edit-page-link-field mb16', {
                          'show-focus': focusEl,
                          'show-error': showSlugError,
                        })}
                        onClick={() => {
                          setFocusEl(true);
                          document.getElementById('edit-page-path').focus();
                        }}
                        onBlur={() => {
                          setFocusEl(false);
                          form.mutators.modifyFormField('slug', slug);
                          document.getElementById(
                            'edit-page-path'
                          ).textContent = slug;
                        }}
                      >
                        <span className="text-medium">
                          {domainWithLink}/{path}/
                        </span>

                        <input
                          name="slug"
                          id="edit-page-path"
                          style={{
                            border: 'none',
                            padding: '0',
                            margin: '0',
                            display: 'inline',
                            outline: 'none',
                            width: '390px',
                          }}
                          value={slug}
                          onChange={(e) => {
                            const val = e.target.value?.slice(
                              0,
                              MAX_SLUG_LENGTH
                            );

                            // if slug input is empty, reset slug to initial slug.
                            // if not, set input as new slug
                            const newSlug = slugify(val, { lower: true });

                            // update the count as the user types
                            form.mutators.modifyFormField('slug', val);
                            setSlug(newSlug);
                            setMutatedSlug(newSlug);
                          }}
                        />
                        {/* <span
                          contentEditable
                          ref={contentEditableRef}
                          suppressContentEditableWarning
                          id="edit-page-path"
                          className="edit-page-link-field--text-area"
                          onInput={(e) => {
                            const val = e.target.textContent?.slice(
                              0,
                              MAX_SLUG_LENGTH
                            );

                            // if slug input is empty, reset slug to initial slug.
                            // if not, set input as new slug
                            const newSlug =
                              slugify(val, { lower: true }) || initSlug;

                            // update the count as the user types
                            form.mutators.modifyFormField('slug', val);
                            setSlug(newSlug);
                            setMutatedSlug(newSlug);
                          }}
                        >
                          {slug}
                        </span> */}
                      </div>
                    </div>
                  )}
                />
              </>
            )}

            {allowImageUpload && (
              <div
                className={cx(
                  'all-in-one-seo-container w-100',
                  styles.imageCardContainer
                )}
              >
                <ImageCard
                  values={values.seo_data}
                  form={form}
                  isNew={isNew}
                  isActive={isActive}
                  showDescription={false}
                  alternateRemoveImage={removeImage}
                  onUploadSuccess={(imgUrl) => {
                    form.mutators.modifyFormField('seo_data.imageUrl', imgUrl);
                  }}
                  outerStyles={{
                    card: styles.imageCard,
                    heading: styles.imageCardHeding,
                    previewTitle: styles.imageCardPreviewTitle,
                    previewLink: styles.imageCardPreviewLink,
                    previewDescription: styles.imageCardPreviewDesc,
                  }}
                  imageCardText={imageCardText}
                  imageFieldName={imageFieldName}
                  previewHiddenItemError={previewHiddenItemError}
                />
              </div>
            )}
          </div>

          <SeoPreview
            {...values}
            categoryName={slug}
            linkSlug={linkSlug}
            path={path}
          />
        </div>
      }
    />
  );
};

export default SeoForm;
