import React from 'react';
import { NavLink } from 'react-router-dom';
import { useUnsavedChangesModal } from '../context/UnsavedChangesProvider';

const NavSpan = (props) => {
  const { children, to, isActive, exact } = props;
  const { isUnsavedChanges, setShowLeaveSiteModal, setLinkRedirect } =
    useUnsavedChangesModal();

  const linkProps = { to };
  if (exact) {
    linkProps.exact = true;
  } else {
    linkProps.isActive = isActive;
  }

  return (
    <NavLink
      {...linkProps}
      onClick={(e) => {
        if (isUnsavedChanges) {
          e.preventDefault();
          setShowLeaveSiteModal(true);
          setLinkRedirect(to);
        }
      }}
    >
      <div className="nav-span-content">{children}</div>
    </NavLink>
  );
};

NavSpan.defaultProps = {
  to: null,
  isActive: null,
  exact: false,
};

export default NavSpan;
