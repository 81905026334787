import React from 'react';
import { useHistory } from 'react-router-dom';
// import useScript from '../../../hooks/useScript';
import { useAppContext } from '../../../context/AppContext';
import Logo from '../../../../images/icons/logo_white.jpg';
import { postRequest } from '../../../utils/http';
import { accountUrls } from '../../../Urls';
import {
  CREATE_PREMIUM_ORDER,
  CREATE_RENEW_ORDER,
  POST_DOMAIN_RENEWAL,
  POST_STORE_RENEWAL_PLANS,
  UPGRADE_PREMIUM_ORDER,
} from '../../../ApiUrls';
import {
  APPS_SUBSCRIPTION_DEEP_LINK,
  RAZORPAY_API_KEY,
} from '../../../constants';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import SpinnerButton from '../../../shared/SpinnerButton';
import { TrackEvent } from '../../../utils/analytics';
import {
  EVENT_PREMIUM_PAY_CTA,
  EVENT_PREMIUM_PAY_SUCCESS,
} from '../../../events';
import { useSubscription } from '../../../context/SubscriptionProvider';
import useTimeoutLoader from '../../../hooks/useTimeoutLoader';
import useResponsive from '../../../hooks/useResponsive';
import { noop } from '../../../utils';
import useEnterPressAction from '../../../hooks/useEnterPressAction';

// const RAZORPAY_SDK_URL = 'https://checkout.razorpay.com/v1/checkout.js';

export const useRazorpayCustomCheckout =
  ({ url, payload }) =>
  // useScript(RAZORPAY_SDK_URL);

  (options) =>
    postRequest({
      url,
      data: payload,
    })
      .then((res) => {
        if (res.status === 'success') {
          const orderId = res?.data?.order_create_event_data?.id;
          const subscriptionId =
            res?.data?.order_create_event_data?.subscription_id;
          if (!(orderId || subscriptionId)) return;

          const payload = {};

          if (subscriptionId) {
            payload.subscription_id = subscriptionId;
          } else {
            payload.order_id = orderId;
          }

          new window.Razorpay({
            ...options,
            ...payload,
            notes: {
              ...res.data.order_create_event_data.notes,
              mode: 'web',
            },
          })?.open();
          return res.data;
        }
      })
      .catch(() => {});

export const useRazorpayCheckout =
  ({ url, payload = {}, failureRedirectPath, enqueueSnackbar = noop }) =>
  (options, optionalPayload) =>
    postRequest({
      url,
      data: optionalPayload ?? payload,
    })
      .then((res) => {
        if (res.status === 'success') {
          const orderId = res?.data?.order_create_event_data?.id;
          const subscriptionId =
            res?.data?.order_create_event_data?.subscription_id;
          if (!(orderId || subscriptionId)) return;

          const payload = {};

          if (subscriptionId) {
            payload.subscription_id = subscriptionId;
          } else {
            payload.order_id = orderId;
          }

          new window.Razorpay({
            ...options,
            ...payload,
            notes: {
              ...res.data.order_create_event_data.notes,
              mode: 'web',
            },
            key: RAZORPAY_API_KEY,
            image: Logo,
            theme: {
              color: '#146eb4',
            },
          })?.open();
          return res.data;
        }
      })
      .catch((err) => {
        if (failureRedirectPath) {
          window.location.href = failureRedirectPath;
        } else {
          enqueueSnackbar(
            (err?.data?.detail || err?.data?.data?.error) ??
              'Something went wrong!',
            'error'
          );
          options?.failCallCB();
        }
      });

const RazorpayButton = ({
  id,
  buttonText,
  name,
  cost,
  currencyCode,
  subtitle,
  period,
  disabled = false,
  isRenew = false,
  referralCode,
  className,
  trackEventName = EVENT_PREMIUM_PAY_CTA,
  trackEventNameSuccessPay = EVENT_PREMIUM_PAY_SUCCESS,
  isPlanUpgrade,
  isPlanRenewal = false,
  planNDomainRenewalPayload,
  isAttachEnterAction = false,
  planId = null,
  planPeriod = null,
}) => {
  const { business, userDetails } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { isMobilePlan } = useSubscription();
  const history = useHistory();
  const openCheckout = useRazorpayCheckout({
    url: CREATE_PREMIUM_ORDER,
    ...(isPlanUpgrade && { url: UPGRADE_PREMIUM_ORDER }),
    ...(isRenew && {
      url: CREATE_RENEW_ORDER,
    }),
    ...(isPlanRenewal && { url: POST_STORE_RENEWAL_PLANS }),
    payload: {
      plan_id: id,
      period,
      ...(referralCode && { referral_code: referralCode }),
    },
    failureRedirectPath: '',
    enqueueSnackbar,
  });
  const { isMobile } = useResponsive();

  const { loading, setLoadingTimeOut } = useTimeoutLoader(5000);

  const { name: storeName } = business;
  const { mobile } = userDetails;

  const handlerenderQueryparams = () => {
    if (planNDomainRenewalPayload.custom_domain) {
      return `?isSubscriptionPage=true&isRenewFlow=true&price=${planNDomainRenewalPayload.price}`;
    }
    return `?isSubscriptionPage=true`;
  };

  const handler = () => {
    TrackEvent(trackEventNameSuccessPay, business, {
      planId,
      planPeriod,
    });

    if (isMobile) {
      setTimeout(() => {
        history.push(`${accountUrls.subscriptionPurchaseMobileSuccess}`);
      }, 1000);
    } else {
      setTimeout(() => {
        window.location.href = `${accountUrls.subscriptionSuccessPath}${
          isPlanRenewal
            ? handlerenderQueryparams()
            : `?checkPlanStatus=true&track=true&planName=${name} ${period}`
        }`;
      }, 2000);
    }
  };

  const options = {
    name,
    key: RAZORPAY_API_KEY,
    amount: (Number(cost) * 100).toString(),
    currency: currencyCode,
    description: subtitle,
    image: Logo,
    prefill: {
      name: storeName,
      email: 'orders@mydukaan.io',
      contact: mobile,
    },
    handler,
    theme: {
      color: '#146eb4',
    },
  };

  const handleClick = () => {
    const customPaylod = planNDomainRenewalPayload;
    setLoadingTimeOut();
    TrackEvent(trackEventName, business, {
      planId,
      planPeriod,
    });
    openCheckout(options, customPaylod);
    enqueueSnackbar('Payment dialog will open shortly!', 'info', {
      action: null,
    });
  };

  // if (isMobilePlan && !isMobileFlow && name.includes('Lite')) return null;

  useEnterPressAction(isAttachEnterAction && !disabled, handleClick);

  return (
    <SpinnerButton
      isLoading={loading}
      showAnimation
      disabled={disabled}
      onClick={handleClick}
      className={className}
    >
      {buttonText}
    </SpinnerButton>
  );
};

export default RazorpayButton;
