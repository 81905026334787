import React from 'react';
import BounceLoader from './BounceLoader';
import Modal from './Modal';

const DownloadReportModal = ({
  title,
  open,
  closeModal,
  handlePDF,
  handleXLS,
  loading = false,
}) => (
  <Modal
    open={open}
    maxWidth="sm"
    closeModal={closeModal}
    className="download-report-modal"
  >
    <div className="download-report-modal-content">
      <h2 className="section-text-4">{title}</h2>
      {loading ? (
        <div className="modal-loader d-flex-c-c">
          <BounceLoader inline />
        </div>
      ) : (
        <div className="download-via mt8">
          <div className="tile" onClick={handlePDF}>
            <img src="/images/download-pdf.svg" alt="PDF" />
            <span className="section-text-5">PDF</span>
          </div>
          <div className="divider" />
          <div className="tile" onClick={handleXLS}>
            <img src="/images/download-xls.svg" alt="XLS" />
            <span className="section-text-5">XLSX</span>
          </div>
        </div>
      )}
    </div>
  </Modal>
);

export default DownloadReportModal;
