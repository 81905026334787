import React, { memo } from 'react';
import IconButton from './IconButton';
import Menu from './Menu';

const ActionMenuComponent = memo((props) => {
  const { vertical, iconBtnSize, iconBtnClass, ...restProps } = props;

  return (
    <IconButton
      size={iconBtnSize}
      className={iconBtnClass}
      fontType={vertical ? 'more_vert' : 'more_horiz'}
      {...restProps}
    />
  );
});

ActionMenuComponent.displayName = 'ActionMenuComponent';

const ActionMenu = (props) => {
  const {
    disabled,
    vertical,
    iconBtnSize,
    iconClass,
    iconBtnClass,
    iconSize,
    customIcon,
    ...restProps
  } = props;

  return (
    <Menu
      {...restProps}
      disabled={disabled}
      component={(menuProps) => (
        <ActionMenuComponent {...menuProps} customIcon={customIcon} />
      )}
      componentProps={{
        disabled,
        vertical,
        iconBtnSize,
        iconClass,
        iconBtnClass,
        iconSize,
      }}
    />
  );
};

ActionMenu.defaultProps = {
  id: 'action-menu-id',
  className: '',
  iconBtnSize: 'small',
  iconBtnClass: '',
  iconSize: '16',
  iconClass: '',
  vertical: true,
  keepMounted: false,
  disabled: false,
  menuItems: [],
};

export default ActionMenu;
