import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ALL_ACTIVE_PLUGINS_URL,
  ALL_PLUGINS_URL,
  WHATSAPP_BUSINESS_ACCOUNT_PLANS,
  WHATSAPP_ACCOUNT_PLUGIN_STATUS,
  ACTIVATE_WHITELABEL_WHATSAPP,
  REQUEST_CALLBACK_WHATSAPP_WHITE_LABEL,
  GET_WHITE_LABEL_WHATSAPP_NUM,
  WHITELABEL_WHATSAPP_TOP_UP,
  WHITELABEL_WHATSAPP_TRANSACTIONS,
} from '../ApiUrls';
import { APPS_DOMAIN, DUNZO_APP_DOMAIN } from '../constants';

import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useIntlOrderShipment from '../hooks/useIntlOrderShipment';
import useStoreUpdate from '../hooks/useStoreUpdate';
import { isValidHttpUrl } from '../Plugins/PluginTitle';
import {
  LOYALTY_POINTS_PLUGIN_ID,
  BAG_CONTROL_ID,
  SERVER_EVENTS_PLUGIN,
  SHIPPO_ID,
  STOCK_OVERRIDE_ID,
  STORE_LOCATOR_APP_ID,
  UPSELL_CROSSSELL_PLUGIN_ID,
  WISHLIST_PLUGIN_ID,
} from '../Plugins/constants';
import { noop } from '../utils';
import { getRequest, postRequest } from '../utils/http';
import { useAppContext } from './AppContext';
import { useCountry } from './CountryProvider';
import useStoreOrderSettings from '../hooks/useStoreOrderSettings';

const PluginsContext = createContext({
  loading: false,
  plugins: [],
  individualPlugin: {},
  installedPlugins: [],
  pluginsStats: { all: 0, installed: 0 },
  pluginCategory: 'allPlugins',
  hasActivePluginsBeenFetched: false,
  fetchAllActivePlugins: noop,
  fetchAllPlugins: noop,
  fetchSpecificPlugin: noop,
  getActiveApps: noop,
  getShipmentTaskStatus: noop,
  getShippingQuotes: noop,
  getWhatsappAccountSubscriptionStatus: noop,
  getWhatsappAccountSubscriptionPlans: noop,
  isPluginInstalled: noop,
  setPlugins: noop,
  setPluginCategory: noop,
  shipOrder: noop,
  togglePlugin: noop,
  togglePluginSpecific: noop,
  uninstallPlugins: noop,
  uninstallPluginSpecific: noop,
});

export const usePlugins = () => useContext(PluginsContext);

class PluginSummary {
  constructor(
    id,
    shortName,
    title,
    description,
    logo,
    rating,
    ratingsCount,
    activationLink,
    isInstalled,
    isActive,
    homeUrl,
    tags,
    status
  ) {
    this.id = id;
    this.name = shortName;
    this.title = title;
    this.description = description;
    this.logo = logo;
    this.rating = rating;
    this.ratingsCount = ratingsCount;
    this.isInstalled = isInstalled;
    this.isAppActive = isActive;
    this.activationLink = activationLink;
    this.homeUrl = homeUrl;
    this.tags = tags;
    this.status = status;
  }
}

const generatePluginSummary = (plugin) => {
  const {
    id,
    Name,
    listingInfo: {
      name,
      short_name: shortName,
      short_desc: description,
      logo,
      rating,
      ratings_count: ratingsCount,
      activation_link: activationLink,
      tags,
    },
    isInstalled = false,
    isAppActiveOnStore,
    home_url: homeUrl,
    status,
  } = plugin;
  return new PluginSummary(
    id,
    Name || shortName,
    name,
    description,
    logo,
    rating,
    ratingsCount,
    activationLink,
    isInstalled,
    isAppActiveOnStore,
    homeUrl,
    tags,
    status
  );
};

const APPSUMO_OLD_ACCOUNT_BLACKLISTED_PLUGINS = [
  WISHLIST_PLUGIN_ID,
  STORE_LOCATOR_APP_ID,
  LOYALTY_POINTS_PLUGIN_ID,
  STOCK_OVERRIDE_ID,
  SERVER_EVENTS_PLUGIN,
  UPSELL_CROSSSELL_PLUGIN_ID,
];

const generatePluginsSummaryList = (
  plugins,
  blacklistOldAppsumoPlugins = false
) => {
  const results = [];
  if (blacklistOldAppsumoPlugins) {
    for (const plugin of plugins) {
      if (!APPSUMO_OLD_ACCOUNT_BLACKLISTED_PLUGINS.includes(plugin.id)) {
        results.push(generatePluginSummary(plugin));
      }
    }
  } else {
    for (const plugin of plugins) {
      results.push(generatePluginSummary(plugin));
    }
  }
  return results;
};

const PluginsProvider = ({ children }) => {
  const { business, isOldAppSumoStore, primaryDomainDetails } = useAppContext();
  const { isInternational } = useCountry();
  const { updateStoreMeta, updateAccount } = useStoreUpdate();
  const { uninstallShippo } = useIntlOrderShipment();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { updateStoreOrderSettings } = useStoreOrderSettings();

  const [plugins, setPlugins] = useState([]);
  const [individualPlugin, setIndividualPlugin] = useState({});
  const [installedPlugins, setInstalledplugins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allPluginsLoading, setAllPluginsLoading] = useState(true);
  const [pluginsStats, setPluginsStats] = useState({ all: 0, installed: 0 });
  const [pluginCategory, setPluginCategory] = useState('allPlugins');
  const [isAppsLoading, setIsAppsLoading] = useState(false);
  const [whatsappPluginData, setWhatsappPluginData] = useState({
    paid: false,
    planData: {},
  });
  const [whiteLabelWhatsappNumberID, setWhitelabelWhatsappNumberID] =
    useState('');
  const [whitelabelWhatsappTrans, setWhitelabelWhatsappTrans] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [whiteLabelWhatsappLoading, setWhiteLabelWhatsappLoading] =
    useState(false);
  const [whitelabelWhatsappCtaLoader, setWhiteLabelWhatsappCtaLoader] =
    useState(false);
  const [whiteLabelWhatsappNumber, setWhiteLabelWhatsappNumber] = useState('');
  const [hasActivePluginsBeenFetched, setHasActivePluginsBeenFetched] =
    useState(false);

  const history = useHistory();

  const isPluginInstalled = (pluginId) =>
    installedPlugins.some((plugin) => plugin.id === pluginId);

  const fetchAllPlugins = () => {
    const params = {};
    if (isInternational) {
      params.is_intl = isInternational;
    }
    getRequest({
      url: ALL_PLUGINS_URL,
      data: params,
      options: {
        withCredentials: true,
      },
    })
      .then((data) => {
        const pluginSummaryList = generatePluginsSummaryList(data);
        let installedCount = 0;
        pluginSummaryList.forEach((plugin) => {
          if (plugin.isInstalled) installedCount += 1;
        });
        setPluginsStats({
          installed: installedCount,
          all: pluginSummaryList.length,
        });
        setPlugins(pluginSummaryList);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setAllPluginsLoading(false));
  };

  const fetchAllActivePlugins = () => {
    const params = {};
    if (isInternational) {
      params.is_intl = true;
    }
    setLoading(true);
    getRequest({
      url: ALL_ACTIVE_PLUGINS_URL,
      data: params,
    })
      .then((data) => {
        setHasActivePluginsBeenFetched(true);
        setInstalledplugins(generatePluginsSummaryList(data));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const fetchSpecificPlugin = (id) => {
    if (individualPlugin?.id === id) return null;
    setLoading(true);
    const url = `${ALL_PLUGINS_URL}/${id}`;
    getRequest({ url })
      .then((p) => {
        setIndividualPlugin({
          ...p,
          hasSettingsPage: isValidHttpUrl(p.home_url),
        });
        if (!pluginsStats.all) {
          fetchAllPlugins();
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const updatePluginsDetails = (prevPlugins, appId, isActive) => {
    for (const plugin of prevPlugins) {
      if (plugin.id === appId) {
        plugin.isAppActive = isActive;
        if (isActive) plugin.isInstalled = isActive;
        break;
      }
    }
    return [...prevPlugins];
  };

  const updateAppActiveOnStore = (appId, isActive, msg, response) => {
    if (response.success) {
      let plugin;
      if (plugins.length) {
        plugin = plugins.find((each) => each.id === appId);
      } else {
        plugin = installedPlugins.find((each) => each.id === appId);
      }
      enqueueSnackbar(`${plugin.name} is sucessfully ${msg || 'installed'}`);
      setPlugins((prevPlugins) =>
        updatePluginsDetails(prevPlugins, appId, isActive)
      );
      setInstalledplugins((prevPlugins) =>
        updatePluginsDetails(prevPlugins, appId, isActive, true)
      );
    }
  };

  const togglePlugin = (appId, isActive, msg) => {
    setLoading(true);
    const data = {
      store_link: business?.link || '',
      custom_domain: primaryDomainDetails?.domain || '',
    };
    const action = !isActive ? 'deactivate' : 'activate';
    const url = `${ALL_PLUGINS_URL}/${appId}/${action}`;
    postRequest({ url, data })
      .then(() => {
        updateAppActiveOnStore.bind(null, appId, isActive, msg);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const uninstallPlugins = (appId, appName) => {
    setLoading(true);
    const url = `${ALL_PLUGINS_URL}/${appId}/uninstall`;
    postRequest({ url })
      .then(() => {
        enqueueSnackbar(`${appName} is successfully uninstalled`);
        fetchAllPlugins();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getActiveApps = (payment = 0, successCB, errCB) => {
    setIsAppsLoading(true);
    const url = `${APPS_DOMAIN}/api/v2/apphooks`;
    return getRequest({
      url,
      data: {
        payment_mode: payment,
        is_intl: isInternational,
      },
    })
      .then(successCB)
      .catch(errCB)
      .finally(() => setIsAppsLoading(false));
  };

  const getShippingQuotes = (orderId = '', successCB, errCB) => {
    setIsAppsLoading(true);
    const url = `${DUNZO_APP_DOMAIN}/api/getquotes`;
    return getRequest({ url, data: { order_id: orderId } })
      .then(successCB)
      .catch(errCB)
      .finally(() => setIsAppsLoading(false));
  };

  const shipOrder = (orderId = '', successCB, errCB) => {
    setIsAppsLoading(true);
    const url = `${DUNZO_APP_DOMAIN}/api/ship?order_id=${orderId}`;
    return postRequest({ url })
      .then(successCB)
      .catch(errCB)
      .finally(() => setIsAppsLoading(false));
  };

  const getShipmentTaskStatus = (orderId = '', successCB, errCB) => {
    if (!orderId) return;
    setIsAppsLoading(true);
    const url = `${APPS_DOMAIN}/api/tracking-redirect?orderid=${orderId}`;
    return getRequest({ url })
      .then(successCB)
      .catch(errCB)
      .finally(() => setIsAppsLoading(false));
  };

  const updateAppActiveOnStoreSpecific = (response) => {
    if (response.success) {
      fetchAllPlugins();
      fetchAllActivePlugins();
      setIndividualPlugin((prevPlugin) => {
        enqueueSnackbar(`${prevPlugin.Name} is successfully installed`);
        prevPlugin.isInstalled = true;
        prevPlugin.isAppActiveOnStore = !prevPlugin.isAppActiveOnStore;
        if (prevPlugin.hasSettingsPage) {
          history?.push(`/plugins/${prevPlugin.id}/settings`, {
            ...history.location.state,
          });
        }
        return prevPlugin;
      });
    }
  };

  const updateAppInstalledOnStoreSpecific = () => {
    setIndividualPlugin((prevPlugin) => {
      enqueueSnackbar(`${prevPlugin.Name} is successfully uninstalled`);
      prevPlugin.isInstalled = false;
      prevPlugin.isAppActiveOnStore = false;
      return prevPlugin;
    });
  };

  const togglePluginSpecific = (id, extraData) => {
    setLoading(true);
    let data = {
      store_link: business?.link || '',
      custom_domain: primaryDomainDetails?.domain || '',
    };
    if (extraData) {
      data = { ...data, extra_data: extraData };
    }
    const action = individualPlugin.isAppActiveOnStore
      ? 'deactivate'
      : 'activate';
    const url = `${ALL_PLUGINS_URL}/${id}/${action}`;
    postRequest({ url, data })
      .then(updateAppActiveOnStoreSpecific)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const uninstallPluginSpecific = (id, uuid) => {
    setLoading(true);
    const url = `${ALL_PLUGINS_URL}/${id}/uninstall`;
    postRequest({ url })
      .then(() => {
        updateAppInstalledOnStoreSpecific();
        if (id === SHIPPO_ID) {
          uninstallShippo();
        }
        if (id === BAG_CONTROL_ID) {
          updateStoreOrderSettings(
            uuid,
            {
              max_cod_order_value: null,
              max_online_order_value: null,
              min_cod_order_value: null,
              min_online_order_value: null,
              max_manual_payment_order_value: null,
              min_manual_payment_order_value: null,
            },
            noop,
            false // values updated snackbar hidden when plugin uninstall
          );
          // also deactiavte two plugins
        }
        fetchAllPlugins();
        fetchAllActivePlugins();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getWhatsappAccountSubscriptionStatus = (
    successCallback = noop,
    errorCallback = noop
  ) => {
    setWhiteLabelWhatsappLoading(true);
    return getRequest({
      url: WHATSAPP_ACCOUNT_PLUGIN_STATUS,
    })
      .then(({ data }) => {
        setWhatsappPluginData({
          paid: true,
          planData: data,
        });
        successCallback(data);
      })
      .catch((error) => {
        errorCallback(error);
      })
      .finally(() => setWhiteLabelWhatsappLoading(false));
  };

  const getWhatsappAccountSubscriptionPlans = (
    successCallback = noop,
    errorCallback = noop
  ) => {
    setWhiteLabelWhatsappLoading(true);
    return getRequest({ url: WHATSAPP_BUSINESS_ACCOUNT_PLANS })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setWhiteLabelWhatsappLoading(false));
  };

  const activateWhiteLabelWhatsApp = () => {
    setWhiteLabelWhatsappCtaLoader(true);
    postRequest({
      url: ACTIVATE_WHITELABEL_WHATSAPP,
    })
      .then(() => {
        enqueueSnackbar('Activated successfully!');
        getWhatsappAccountSubscriptionStatus();
      })
      .catch((err) => {
        enqueueSnackbar(err?.data?.detail || 'Something went wrong', 'error');
      })
      .finally(() => {
        // need to change page view based in the status
        setWhiteLabelWhatsappCtaLoader(false);
      });
  };

  const requestCallbackWhatsappWhitelabel = () => {
    setWhiteLabelWhatsappCtaLoader(true);
    postRequest({
      url: REQUEST_CALLBACK_WHATSAPP_WHITE_LABEL,
    })
      .then(() => {
        enqueueSnackbar(`Request received. We'll contact you shortly!`);
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.data?.data?.error || 'Something went wrong',
          err?.status === 400 ? 'info' : 'error'
        );
      })
      .finally(() => setWhiteLabelWhatsappCtaLoader(false));
  };

  const getWhitelabelWhatsappNumber = () => {
    getRequest({
      url: GET_WHITE_LABEL_WHATSAPP_NUM,
    }).then((res) => {
      setWhitelabelWhatsappNumberID(res.data.id);
      setWhiteLabelWhatsappNumber(res.data.wa_number);
    });
  };

  const getWhiteLableWhatsappTrans = (params) => {
    getRequest({
      url: WHITELABEL_WHATSAPP_TRANSACTIONS(whiteLabelWhatsappNumberID),
      data: params,
    }).then((res) => {
      setWhitelabelWhatsappTrans(res);
    });
  };

  const topUpWhiteLableWhatsApp = (credits) => {
    postRequest({
      url: WHITELABEL_WHATSAPP_TOP_UP(whiteLabelWhatsappNumberID),
      data: {
        credits_to_be_added: credits,
      },
    })
      .then(() => {
        enqueueSnackbar('Balance added successfully!');
        getWhiteLableWhatsappTrans();
      })
      .catch((err) => {
        enqueueSnackbar(err.data.data.error || 'Something went wrong', 'error');
      });
  };

  const contextValue = {
    loading,
    plugins,
    individualPlugin,
    installedPlugins,
    pluginsStats,
    pluginCategory,
    allPluginsLoading,
    isAppsLoading,
    whatsappPluginData,
    whitelabelWhatsappTrans,
    whiteLabelWhatsappNumberID,
    subscriptionDetails,
    whiteLabelWhatsappLoading,
    whitelabelWhatsappCtaLoader,
    whiteLabelWhatsappNumber,
    hasActivePluginsBeenFetched,
    setWhatsappPluginData,
    isPluginInstalled,
    fetchAllActivePlugins,
    fetchAllPlugins,
    fetchSpecificPlugin,
    getActiveApps,
    getShipmentTaskStatus,
    getShippingQuotes,
    setPlugins,
    setPluginCategory,
    shipOrder,
    togglePlugin,
    togglePluginSpecific,
    uninstallPlugins,
    uninstallPluginSpecific,
    getWhatsappAccountSubscriptionStatus,
    getWhatsappAccountSubscriptionPlans,
    activateWhiteLabelWhatsApp,
    requestCallbackWhatsappWhitelabel,
    getWhitelabelWhatsappNumber,
    topUpWhiteLableWhatsApp,
    getWhiteLableWhatsappTrans,
    setSubscriptionDetails,
  };

  return (
    <PluginsContext.Provider value={contextValue}>
      {children}
    </PluginsContext.Provider>
  );
};

export default PluginsProvider;
