import React, { useContext } from 'react';

import initialState from './initialState';

const AppContext = React.createContext({
  ...initialState,
});

export const useAppContext = () => useContext(AppContext);

export default AppContext;
