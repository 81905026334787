import cx from 'classnames';
import { useCountry } from '../../../../context/CountryProvider';
import {
  CountryOption,
  FormatCountryOptionWithoutLabel,
} from '../../../../InternationalLogin/CountrySelect';
import {
  CANADA_STATES,
  getOptionsFromArray,
  INDIA_STATES,
  US_STATES,
} from '../../../../Manage/Delivery/constants';
import {
  FormCustomSelect,
  FormInput,
  FormNumberInput,
  Validation,
} from '../../../../shared/Form';
import {
  countriesByPhoneCode,
  countriesWithCurrency,
  INDIA_COUNTRY,
  US_COUNTRY,
} from '../../../../utils/countries';

const FIELD_TYPE = {
  TEXT: 'text',
  MOBILE: 'mobile',
  EMAIL: 'email',
  PINCODE: 'pincode',
  DROPDOWN: 'dropdown',
};

const useFormFieldHelper = ({
  countryMobile,
  mobileValidation,
  onPincodeChange,
  setCountryMobile,
}) => {
  const { isInternational } = useCountry();
  const COUNTRIES = countriesWithCurrency.map((country) => ({
    label: country.name,
    value: country.country_code.toLocaleLowerCase(),
  }));

  const getField = (field, form, values) => {
    const {
      name,
      label,
      field_type: fieldType,
      required,
      field_size: fieldSize,
      is_active: isActive,
      placeholder,
      ordering,
    } = field;

    const INTERNATIONAL_STATES =
      values.country === US_COUNTRY ? US_STATES : CANADA_STATES;

    const isRequiredField = {
      ...(required
        ? {
            required: true,
            showRequired: true,
          }
        : {}),
    };

    switch (fieldType) {
      case FIELD_TYPE.TEXT:
        if (isActive)
          return (
            <FormInput
              name={name}
              labelText={label}
              placeholder={
                placeholder ||
                `Enter ${name === 'line' ? 'address' : name.toLowerCase()}`
              }
              className={cx('mb0', {
                'col-span-2': fieldSize === 'full' && name === 'line',
              })}
              {...isRequiredField}
              {...(required ? { validate: Validation.required() } : {})}
              {...(ordering === 1 ? { autoFocus: true } : {})}
            />
          );
        return <></>;

      case FIELD_TYPE.MOBILE: {
        const phoneCodeData = countriesByPhoneCode.find(
          (eachCountry) =>
            Number(eachCountry.value) === Number(values.phone_code)
        );

        const handleMobileCodeChange = (data) => {
          const selected = countriesByPhoneCode.find(
            (eachCountry) =>
              Number(eachCountry.phone_code) === Number(data.phone_code) &&
              eachCountry.currency_code === data.currency_code &&
              eachCountry.country_code === data.country_code
          );
          setCountryMobile(selected);
          form.mutators.modifyFormField('phone_code', selected.phone_code);
          form.mutators.modifyFormField(
            'country',
            selected.country_code.toLocaleLowerCase()
          );
          form.mutators.modifyFormField('mobile', '');
        };
        return (
          <FormInput
            className={cx('country-flag-select mb0', {
              'country-1': String(values.phone_code).length === 1,
              'country-2': String(values.phone_code).length === 2,
              'country-3': String(values.phone_code).length === 3,
            })}
            name={name}
            labelText={label}
            placeholder={placeholder || '0123456789'}
            pattern="(^$)|(^\d+$)"
            maxLength={phoneCodeData?.mobile_length_max}
            largePrependText
            prependText={
              <div className="d-flex text-normal">
                <FormCustomSelect
                  name="phone_code"
                  menuPosition="fixed"
                  className="registration-select left"
                  options={countriesByPhoneCode}
                  selected={countryMobile}
                  components={{
                    Option: CountryOption,
                  }}
                  onChange={(value) => handleMobileCodeChange(value)}
                  formatOptionLabel={FormatCountryOptionWithoutLabel}
                  isInputPhoneCode
                />
                <span className="post-select-text">+{values.phone_code}</span>
              </div>
            }
            {...isRequiredField}
            validate={mobileValidation(values, phoneCodeData)}
          />
        );
      }

      case FIELD_TYPE.EMAIL:
        if (isActive)
          return (
            <FormInput
              name={name}
              labelText={label}
              placeholder={placeholder || `Enter ${name.toLowerCase()}`}
              className="mb0"
              {...isRequiredField}
              validate={
                required
                  ? Validation.validateEmail()
                  : Validation.emptyValidateEmail()
              }
            />
          );
        return <></>;

      case FIELD_TYPE.PINCODE:
        if (isActive)
          if (isInternational) {
            return (
              <FormInput
                name={name}
                labelText={label}
                placeholder={placeholder || `Enter ${name.toLowerCase()}`}
                initialValue={values.pin}
                className="mb0"
                {...isRequiredField}
                {...(required ? { validate: Validation.required() } : {})}
              />
            );
          } else
            return (
              <FormNumberInput
                name={name}
                maxLength={6}
                labelText={label}
                placeholder={placeholder || `Enter ${name.toLowerCase()}`}
                initialValue={values.pin}
                onChange={(e) => onPincodeChange(e, form)}
                className="mb0"
                allowLeadingZeros
                {...isRequiredField}
                {...(required ? { validate: Validation.required() } : {})}
              />
            );
        return <></>;

      case FIELD_TYPE.DROPDOWN: {
        const handleCountryChange = (data) => {
          const selected = countriesByPhoneCode.find(
            (eachCountry) =>
              eachCountry.country_code.toLocaleLowerCase() === data.value &&
              eachCountry.name === data.label
          );
          setCountryMobile(selected);
          form.mutators.modifyFormField('phone_code', selected.phone_code);
          form.mutators.modifyFormField(
            'country',
            selected.country_code.toLocaleLowerCase()
          );
          form.mutators.modifyFormField('mobile', '');
        };

        // returning dropdown for countries whose state data(i.e canada,usa) is available else just text field
        if (isActive && name === 'state') {
          return (
            <FormCustomSelect
              menuPlacement="top"
              name={name}
              labelText={label}
              menuPosition="fixed"
              placeholder={placeholder || `Select ${name.toLowerCase()}`}
              options={getOptionsFromArray(
                values.country === INDIA_COUNTRY
                  ? INDIA_STATES
                  : INTERNATIONAL_STATES
              )}
              className="fill-available mb0 create-order-country-dropdown"
              {...isRequiredField}
              {...(required ? { validate: Validation.required() } : {})}
            />
          );
        }

        if (isActive && name === 'country' && isInternational)
          return (
            <FormCustomSelect
              menuPlacement="bottom"
              name={name}
              labelText={label}
              menuPosition="fixed"
              placeholder={placeholder || `Select ${name.toLowerCase()}`}
              options={COUNTRIES}
              className="fill-available mb0 create-order-country-dropdown"
              {...isRequiredField}
              {...(required ? { validate: Validation.required() } : {})}
              onChange={handleCountryChange}
            />
          );
        return <></>;
      }

      default:
        return <></>;
    }
  };

  return { getField };
};

export default useFormFieldHelper;
