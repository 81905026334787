/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-final-form';
import cx from 'classnames';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import Layout from '../Layout';
import useOrderDetails from '../hooks/useOrderDetails';
import {
  CustomerDetails,
  OrderProducts,
  OrderTotal,
  OrderDetailHeader,
  RestaurantOrderDetailHeader,
  OrderFormDetails,
  ShipmentDetails,
  IntlCustomerDetails,
  DiscountedAbandonedInformation,
  getUrlParams,
  OrderPaymentAndRefund,
  StatusText,
} from './common';
import { orderDetailsUrl, returnOrderDetailsUrl } from '../UrlHelper';
import {
  SlideLeftIcon,
  SlideRightIcon,
  ShareRecieptIcon,
  DeleteModalIcon,
  DownArrowBlueIcon,
  UpArrowIcon,
  EditThinIcon,
} from '../SvgIcon';
import OrderActions from './OrderActions';
import {
  ACCEPTED_STATUS,
  CANCELLED_BY_CUSTOMER,
  CASH_PAYMENT_MODE,
  MINI_ORDER_STATE,
  MODIFIED_STATUS,
  NAV_BACK_ORDER_STATUSES,
  PENDING_STATUS,
  SHIPMENT_CANCELLABLE_STATUSES,
  SHIPPED_STATUS,
  ABANDONED_STATUS,
  RTO_LOW_RISK,
  RTO_HIGH_RISK,
  RTO_MEDIUM_RISK,
  RTO_SCORE_LOWER_LIMIT,
  RTO_SCORE_UPPER_LIMIT,
  FAILED_STATUS,
  RTO_STATUS,
  REJECTED_STATUS,
  CANCELLED_STATUS,
  DELIVERED_STATUS,
  RTO_FULL_PAYMENT,
  RTO_PARTIAL_PAYMENT,
  TAGS_FOR,
  APPROVED_RETURN,
  COMPLETED_RETURN,
  REQUESTED_RETURN,
  MANUAL_PAYMENT_METHOD_CHECK,
  ONLINE_PAYMENT_MODE,
} from './constants';
import { getRequest } from '../utils/http';
import { ORDER_DUKAAN_SHIPMENT_LABEL_URL } from '../ApiUrls';
import { useIframeContext } from '../context/IframeProvider';
import {
  CREDITS_READMORE_LINK,
  DUKAAN_SERVICE_FEE_URL,
  VU_STORE_ID,
} from '../constants';

import { TrackEvent } from '../utils/analytics';
import {
  EVENT_ORDER_REQUEST_ONLINE_PAYMENT,
  EVENT_ORDER_REQUEST_PAYMENT_LOW_RISK_REQUEST,
} from '../events';
import { useAppContext } from '../context/AppContext';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import TrackOrderDetailsModal from './TrackOrderDetailsModal';
import { accountUrls, manageUrls, pluginsUrls, rootUrls } from '../Urls';
import useQuery from '../hooks/useQuery';
import useOrderUpdate from '../hooks/useOrderUpdate';
import ConfirmModal from '../shared/ConfirmModal';
import MarketingStripe from '../Manage/Customers/components/MarketingStripe';
import RequestOnlinePaymentModal from './components/RequestOnlinePaymentModal';
import useModal from '../hooks/useModal';
import useLocalStorage from '../hooks/useLocalStorage';
import { useCountry } from '../context/CountryProvider';
import { DINING_ORDER, STORE_SETTINGS_SECTION } from '../Account/constants';
import ChangePaymentMethodDialog from '../Manage/InternationalPayments/ChangePaymentMethodDialog';
import { RAZORPAY_KEY } from '../context/PaymentsProvider';
import useOrderShipment from '../hooks/useOrderShipment';
import { isHyperlocalSelected, OrderWeight } from '../context/DeliveryProvider';
import { CUSTOM_WAREHOUSE_PINCODE } from './NewShippingOptionsModal';
import NotesTimeline from './components/NotesTimeline';
import OrderDetailShimmer, {
  NotesTimelineShimmer,
  RTOCardShimmer,
} from '../Catalogue/components/OrderDetailShimmer';
import { useStorePreferences } from '../context/StorePreferenceProvider';
import RecoveryMessageModal from './RecoveryMessageModal';
import useCampaign from '../hooks/useCampaign';
import { useCredits } from '../context/CreditsProvider';
import SendPaymentLinkModal from './SendPaymentLinkModal';
import SpinnerButton from '../shared/SpinnerButton';
import { usePlugins } from '../context/PluginsProvider';
import { RTOCard } from './RTOCard';
import { isNotEmptyOrNull, modifyFormField } from '../shared/Form/Shared';
import {
  DUPLICATE_ORDER_ID,
  RTO_OPTIMISER_PLUGIN_ID,
} from '../Plugins/constants';
import OrderDetailSection, {
  OrderDetailSectionHeader,
} from './OrderDetails/OrderDetailSection';
import Tags from '../shared/Tags';
import ReplacementDetails from './ReplacementDetails';
import OrderDeliveryDetails from './OrderDeliveryDetails';
import IntlDeliveryDetails from './IntlDeliveryDetails';
import useOrderForm from '../hooks/useOrderForm';
import { STATUS_MAP } from '../Manage/Delivery/constants';
import { useStoreWarehouse } from '../context/StoreWarehouseProvider';
import DisplayAttributes from '../Catalogue/Products/AdvanceCatalogue/DisplayAttributes';
import useAttribute from '../hooks/useAttribute';
import { noop } from '../utils';
import Nudge from '../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_SIZES,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../Subscription/constants';
import {
  DUKAAN_DELIVERY_SHIPPING_MODE,
  SHIPROCKET_SHIPPING_MODE,
} from '../Account/Sections/Shipping/constants';
import useCorePlugin from '../hooks/useCorePlugin';
import { SHIPROCKET_CORE_PLUGIN_ID } from '../Account/Sections/constants';
import NegativeCredits from '../Home/NegativeCredits';

const OrderDetails = (props) => {
  const {
    match: {
      params: { uuid },
    },
  } = props;

  const {
    isOpen: isOpenRecoveryModal,
    openModal: openRecoveryModal,
    closeModal: closeRecoveryModal,
  } = useModal();
  const [showShipingModal, setShowShipingModal] = useState(false);
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();
  const { credits, isBalanceNegative } = useCredits();

  const orders = localStorage.getItem('orders')
    ? JSON.parse(localStorage.getItem('orders'))
    : [];

  const history = useHistory();
  const query = useQuery();
  const appId = query.get('appID');
  const {
    business,
    isVendor,
    isStaffL1,
    isStaffL2,
    isStaffL3,
    isManagerL1,
    canCreateVendor,
    isEnterprise,
    activePaymentProvider = {},
  } = useAppContext();

  const isOrderDetailsReadOnly =
    isStaffL1 || isStaffL2 || isStaffL3 || isManagerL1 || isBalanceNegative;

  const {
    isLoading: preferenceLoading,
    showNotesTimeline: showNotesTimelineArg,
  } = useStorePreferences();
  const {
    allPluginsLoading,
    plugins,
    fetchAllPlugins,
    isPluginInstalled,
    fetchAllActivePlugins,
  } = usePlugins();
  const attributeProps = useAttribute({
    isFetchMetaFieldTypeList: false,
    isFetchMetaFieldsList: false,
    isFetchOrderMetaFieldsList: true,
  });

  const {
    orderMetaFieldsList = [],
    fetchSpecificOrderMetaFieldsList,
    orderSpecificMetaFieldsList,
    updateSpecificOrderMetaFields,
  } = attributeProps;

  const {
    loading,
    activityLoading,
    refunding,
    orderDetails,
    refetch,
    populateRTOScore,
    populatingRTOScore,
    submitting,
    setPopulatingRTOScore,
    products,
    fetchReturnReplacementDetails,
    rejectOrderReturn,
    fetchDeliveryDetails,
    returnReplacementData,
    orderShipmentDetails,
    orderActivityList,
    refundReturnOrReplacementOrder,
    resendManualPaymentLink,
    markReplacementComplete,
    orderPayments,
    fetchOrderPayments,
    cancelledLineItemDetailsMap,
    fetchOrderActivityList,
    updateOrderNotes,
  } = useOrderDetails(uuid, history?.location?.state?.orderDetails ?? {});
  const hasSomeOrderDetails =
    Object.keys(history?.location?.state?.orderDetails ?? {}).length > 0;

  const isManualPaymentOrder =
    orderDetails.type === MANUAL_PAYMENT_METHOD_CHECK.TYPE &&
    orderDetails.payment_mode === MANUAL_PAYMENT_METHOD_CHECK.PAYMENT_MODE;
  const isStatusAbandoned =
    !isManualPaymentOrder && orderDetails?.status === ABANDONED_STATUS;

  const showNotesTimeline = showNotesTimelineArg && !isStatusAbandoned;
  const orderAdditionalDetails = orderDetails?.order_meta?.order_custom_data;
  const {
    meta: { online_payment_activated: paymentActivated = false },
  } = business;
  const { payment_provider: paymentProvider = '' } =
    activePaymentProvider || {};
  const [pageTitle, setPageTitle] = useState('Order details');
  const [prevUuid, setPrevUuid] = useState(null);
  const [nextUuid, setNextUuid] = useState(null);
  const [showTrackingModal, setTrackingModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [
    confirmCancelReverseShipmentModalOpen,
    setConfirmCancelReverseShipmentModalOpen,
  ] = useState(false);
  const [isCancelledReturnShipment, setCancelledReturnShipment] =
    useState(false);
  const [
    isCancelledForwardReplacementShipment,
    setCancelledForwardReplacementShipment,
  ] = useState(false);
  const [returnsLoading, setReturnsLoading] = useState(false);

  const {
    fetchTemplates,
    templates,
    templatesLoading,
    pageNumberTemplates,
    setPageNumberTemplates,
    hasMoreTemplates,
  } = useCampaign();

  const {
    isOpen: editCustomerDetailsOpen,
    openModal: editCustomerDetailsOpenModal,
    closeModal: editCustomerDetailsCloseModal,
  } = useModal();

  const [
    dontShowRequestPaymentConfirmation,
    setShowRequestPaymentConfirmation,
  ] = useLocalStorage('dontShowRequestPaymentConfirmation', false);

  const {
    isOpen: requestPaymentModalOpen,
    openModal: openRequestPaymentModal,
    closeModal: closeRequestPaymentModal,
  } = useModal();

  const {
    isOpen: isMissingPaymentOpen,
    openModal: openMissingPaymentModal,
    closeModal: closeMissingPaymentModal,
  } = useModal();

  const {
    openModal: openPaymentLinkModal,
    isOpen: isOpenPaymentLinkModal,
    closeModal: closePaymentLinkModal,
  } = useModal();

  const {
    isReturnedOrReplacementExist,
    isReplacementOrderExist,
    isReturnedOrderExist,
    return_replacement_status: returnReplacementStatus,
  } = returnReplacementData;

  const orderShipmentObj = useOrderShipment();
  const { orderSeviceable, orderShippingDetails, getShippingDetails } =
    orderShipmentObj;

  const { corePluginDetails: shiprocketDetails, fetchCorePluginDetails } =
    useCorePlugin(SHIPROCKET_CORE_PLUGIN_ID);

  const {
    cancelDukaanShipment,
    isSubmitting,
    generatePaymentLink,
    generatePartialPaymentLink,
    generatePreorderFullPaymentLink,
    updateSpamStatus,
  } = useOrderUpdate(uuid);
  const {
    fetchStoreOrderFormDetails,
    orderFormData: customerDetailsFields,
    preferencesLoading: isCustomerDetailsFieldsLoading,
  } = useOrderForm();
  const [trackingDetails, setTrackingDetails] = useState(null);
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [returnShipmentId, setReturnShipmentId] = useState(null);
  const { isInternational } = useCountry();
  const [initialFormValues, setInitialFormValues] = useState({});

  const { enqueueSnackbar } = useCustomSnackbar();

  const { setIframe, bindCloseIframeEventOnWindow, removeIframeEvent } =
    useIframeContext();
  const iframeKey = 'OrderDetails';

  useEffect(() => {
    fetchCorePluginDetails();
    fetchAllPlugins();
    fetchAllActivePlugins();
    fetchStoreOrderFormDetails();
  }, []);

  useEffect(() => {
    bindCloseIframeEventOnWindow(iframeKey);
    return () => removeIframeEvent(iframeKey);
  }, []);

  const {
    order_meta: orderMeta = {},
    order_returns_data: returnOrders = {},
    type,
    notes,
  } = orderDetails;
  const { active_returns: activeReturns = [] } = returnOrders;
  const { store_table_data: storeTableData, reason = '' } = orderMeta;
  const isRestaurantOrder = type === 1;

  const calculateRTORiskStatus = () => {
    let riskStatus = null;
    const rtoScoreValue = orderDetails.rto_score;

    if (!isNotEmptyOrNull({ value: rtoScoreValue })) riskStatus = null;
    else if (rtoScoreValue < RTO_SCORE_LOWER_LIMIT) riskStatus = RTO_LOW_RISK;
    else if (rtoScoreValue > RTO_SCORE_UPPER_LIMIT) riskStatus = RTO_HIGH_RISK;
    else if (
      rtoScoreValue >= RTO_SCORE_LOWER_LIMIT &&
      rtoScoreValue <= RTO_SCORE_UPPER_LIMIT
    )
      riskStatus = RTO_MEDIUM_RISK;

    return riskStatus;
  };

  const isRTOPluginEnabled = isPluginInstalled(RTO_OPTIMISER_PLUGIN_ID);

  const calculatedRiskStatus = calculateRTORiskStatus();

  const handleAfterOrderAction = (response) => {
    const { newStatus } = response;
    if (NAV_BACK_ORDER_STATUSES.includes(newStatus)) {
      history.goBack();
    } else {
      document.location.reload();
    }
  };

  const movePrev = () => {
    const currentPath = history.location.pathname;
    if (prevUuid) {
      if (
        history.location?.state?.fromLocation?.pathname.includes(
          manageUrls.customersPath
        )
      ) {
        const { fromLocation } = history.location?.state;
        history.replace(
          orderDetailsUrl(prevUuid),
          { fromLocation } // filter status for /customers
        );
      } else if (currentPath.includes(rootUrls.returnOrdersPath)) {
        history.replace(returnOrderDetailsUrl(prevUuid));
      } else history.replace(orderDetailsUrl(prevUuid));
    }
  };

  const moveNext = () => {
    const currentPath = history.location.pathname;
    if (nextUuid) {
      if (
        history.location?.state?.fromLocation?.pathname.includes(
          manageUrls.customersPath
        )
      ) {
        const { fromLocation } = history.location?.state;
        history.replace(
          orderDetailsUrl(nextUuid),
          { fromLocation } // filter status for /customers
        );
      } else if (currentPath.includes(rootUrls.returnOrdersPath)) {
        history.replace(returnOrderDetailsUrl(nextUuid));
      } else history.replace(orderDetailsUrl(nextUuid));
    }
  };

  const handleCanclePickup = () => {
    cancelDukaanShipment(() => {
      enqueueSnackbar('Shipment pickup cancelled successfully!');
      setConfirmModalOpen(false);
      setConfirmCancelReverseShipmentModalOpen(false);
      setTimeout(() => {
        document.location.reload();
      }, 1000);
    }, confirmCancelReverseShipmentModalOpen);
  };

  const showRequestPaymentBanner = () => {
    if (
      allPluginsLoading ||
      loading ||
      isInternational ||
      !paymentActivated ||
      orderDetails.type === DINING_ORDER
    )
      return false;
    if (orderDetails.total_cost < 1) return false;
    if (orderDetails.payment_mode !== CASH_PAYMENT_MODE) return false;
    if (paymentProvider === RAZORPAY_KEY) {
      return false;
    }
    if ([PENDING_STATUS, ACCEPTED_STATUS].includes(orderDetails.status)) {
      return true;
    }
    if (orderDetails.status === SHIPPED_STATUS && orderDetails.shipment_data) {
      if (
        Array.isArray(orderDetails.shipment_data) &&
        orderDetails.shipment_data.length > 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleClickRequestPayment = () => {
    // check if dukaan pay details exist
    // if user ever activated dukaan pay but uses razopay after that, then it will work
    // but if dukaan pay was never set up then it needs to be setup first

    if (business.payment_detail === null) {
      openMissingPaymentModal(); // this will take user to setup dukaan pay
      return;
    }
    if (isRTOPluginEnabled)
      TrackEvent(EVENT_ORDER_REQUEST_PAYMENT_LOW_RISK_REQUEST, business);
    else TrackEvent(EVENT_ORDER_REQUEST_ONLINE_PAYMENT);

    if (dontShowRequestPaymentConfirmation) {
      generatePaymentLink();
    } else {
      openRequestPaymentModal();
    }
  };

  const handleClickRequestFullPayment = () => {
    if (orderDetails.total_cost > 50000) {
      enqueueSnackbar('Only valid on order value less than Rs. 50000', 'error');
      return;
    }
    generatePreorderFullPaymentLink();
  };

  useEffect(() => {
    if (orders.length > 1) {
      const currentIndex = orders
        .map((order) => order.uuid || order.order_uuid)
        .indexOf(uuid);
      if (currentIndex === -1) {
        return;
      }

      if (currentIndex > 0) {
        const { uuid: prevId, order_uuid: returnPrevId } =
          orders[currentIndex - 1];
        if (!prevId) {
          setPrevUuid(returnPrevId);
        } else {
          setPrevUuid(prevId);
        }
      }

      if (currentIndex < orders.length - 1) {
        const { uuid: nextId, order_uuid: returnNextId } =
          orders[currentIndex + 1];
        if (!nextId) {
          setNextUuid(returnNextId);
        } else {
          setNextUuid(nextId);
        }
      }
    }
  }, [orders]);

  useEffect(() => {
    if (confirmModalOpen) {
      if (orderDetails?.id && !isInternational) {
        getShippingDetails(getGetShippingPayload(orderDetails.id, 0.1));
      }
    }
  }, [confirmModalOpen]);

  const getInvoice = async (shipmentUuid) => {
    try {
      getRequest({
        url: ORDER_DUKAAN_SHIPMENT_LABEL_URL(shipmentUuid),
      })
        .then(({ data: newShipmentData }) => {
          setShipmentDetails((prevShipmentData) => ({
            ...prevShipmentData,
            ...newShipmentData,
          }));
        })
        .catch(console.log);
    } catch (e) {
      console.log(e);
    }
  };

  const AddTrackingDetails = () =>
    history.push(`${rootUrls.orderSelfShipPath.replace(':uuid', uuid)}`);

  useEffect(() => {
    if (orderDetails.id) {
      setPageTitle(`#${orderDetails.id} order details`);
    }
    if (
      Array.isArray(orderDetails?.shipment_data) &&
      orderDetails.shipment_data.length > 0
    ) {
      const shipmentData = orderDetails.shipment_data[0];
      const {
        invoice_link: invoiceLink = '',
        label_link: labelLink = '',
        uuid: shipmentUuid,
        grouped_orders: groupedOrders = [],
      } = shipmentData;

      setShipmentDetails((prevShipmentData) => ({
        ...prevShipmentData,
        ...shipmentData,
        grouped_orders: groupedOrders.filter(
          (each) => each.id !== orderDetails.id
        ),
      }));
      if (!isInternational && (!invoiceLink || !labelLink)) {
        getInvoice(shipmentUuid);
      }
    }
  }, [orderDetails]);

  const saveNotes = () => {
    const button = document.getElementById('notes-btn');
    if (button) {
      button.click();
    }
  };

  const handleKeyboardKeys = useCallback(
    (e) => {
      const returnsImagesModal = document.querySelector(
        '.return-shipment-images-modal'
      );
      if (!(returnsImagesModal || editCustomerDetailsOpen)) {
        switch (e.key) {
          case 'ArrowLeft':
            movePrev();
            break;
          case 'ArrowRight':
            moveNext();
            break;
          case 'Enter':
            saveNotes();
            break;
          default:
            break;
        }
      }
    },
    [moveNext, movePrev]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardKeys);
    return () => {
      document.removeEventListener('keydown', handleKeyboardKeys);
    };
  }, [handleKeyboardKeys]);

  const goBack = () => {
    if (appId === DUPLICATE_ORDER_ID) {
      return history.push(
        pluginsUrls.pluginSettingPath.replace(':uuid', appId)
      );
    }

    const path = window.location.pathname;
    if (
      history.location?.state?.fromLocation?.pathname.includes(
        manageUrls.customersPath
      )
    ) {
      history.push(
        history.location.state.fromLocation.pathname +
          history.location.state?.customerOrderFilters,
        { status: history.location.state?.status } // filter status for /cutomers
      );
    } else if (isStatusAbandoned) {
      history.push(rootUrls.abandonedOrdersPath);
    } else if (path.includes(rootUrls.returnOrdersPath)) {
      const {
        returnUrlSearch = '',
        orderParams = {},
        scrollPosition,
      } = history.location?.state ?? {};
      if (orderParams.search === '') delete orderParams.search;

      const queryParams = { ...getUrlParams(orderParams) };

      history.push(
        `${rootUrls.returnOrdersPath}${returnUrlSearch}?${qs.stringify(
          queryParams
        )}`,
        {
          scrollPosition,
        }
      );
    } else if (
      history.location?.state?.fromLocation?.pathname.includes(
        manageUrls.paymentsUrl
      )
    ) {
      history.push(manageUrls.paymentsUrl);
    } else if (history.location?.state?.orderParams) {
      const { orderParams, scrollPosition } = history.location.state;
      const { status } = orderParams;
      const historyState = {
        orderParams,
      };
      if (scrollPosition) {
        historyState.scrollPosition = scrollPosition;
      }
      history.push(
        `${rootUrls.ordersPath}?${qs.stringify({ status })}`,
        historyState
      );
    } else {
      const { scrollPosition } = history.location?.state ?? {};
      history.push(rootUrls.ordersPath, { scrollPosition });
    }
  };

  const getGetShippingPayload = (...resArgs) => {
    const orderPayload = new OrderWeight(...resArgs);

    if (
      isReturnedOrderExist ||
      isCancelledReturnShipment ||
      (isReplacementOrderExist &&
        returnReplacementData?.return_replacement_status <= REQUESTED_RETURN)
    )
      orderPayload.is_reverse_shipment = true;
    else if (
      (isReplacementOrderExist &&
        returnReplacementData?.return_replacement_status > REQUESTED_RETURN) ||
      isCancelledForwardReplacementShipment
    )
      orderPayload.is_replacement_shipment = true;

    const payload = { orders: [orderPayload] };

    if (!isActiveStoreWarehouseExist)
      payload.custom_warehouse_pincode = CUSTOM_WAREHOUSE_PINCODE;

    return payload;
  };

  useEffect(() => {
    fetchOrderPayments();
  }, [
    orderDetails?.id,
    isCancelledForwardReplacementShipment,
    isCancelledReturnShipment,
  ]);

  const showSelfTrackEditBtn =
    orderDetails.status === SHIPPED_STATUS &&
    !shipmentDetails?.carrier &&
    !canCreateVendor;

  const showTrackBtn =
    !!trackingDetails ||
    (orderDetails.status === SHIPPED_STATUS &&
      shipmentDetails?.carrier &&
      !shipmentDetails?.is_external_shipment);

  const isHyperlocal = isHyperlocalSelected({
    carrier_service: shipmentDetails?.carrier_service_json,
  });
  const paymentModalcontent = {
    heading: isInternational
      ? 'Missing payment method'
      : 'Missing bank account',
    body: isInternational
      ? 'To receive online payments on your store, you need to set up payment methods.'
      : 'To receive online payments on your store, please add your bank account details.',
    btnText: isInternational ? 'Set up payment method' : 'Add bank account',
  };

  let isOrderPartiallyPaid = null;
  if (orderDetails?.payment_details?.amount > 0) {
    if (
      Number(orderDetails?.payment_details?.amount) <
      Number(orderDetails.total_cost)
    )
      isOrderPartiallyPaid = true;
    else if (
      Number(orderDetails?.payment_details?.amount) ===
      Number(orderDetails.total_cost)
    )
      isOrderPartiallyPaid = false;
  }
  const paymentStatus = isOrderPartiallyPaid
    ? RTO_PARTIAL_PAYMENT
    : RTO_FULL_PAYMENT;

  const rtoScore = orderDetails.rto_score;

  const showRTOCard =
    isRTOPluginEnabled &&
    orderDetails?.order_meta &&
    ![
      FAILED_STATUS,
      RTO_STATUS,
      SHIPPED_STATUS,
      REJECTED_STATUS,
      CANCELLED_STATUS,
      DELIVERED_STATUS,
      CANCELLED_BY_CUSTOMER,
    ].includes(orderDetails.status) &&
    orderDetails.type === 0 &&
    !isStatusAbandoned &&
    !(
      !isNotEmptyOrNull({ value: rtoScore }) &&
      isNotEmptyOrNull({ value: isOrderPartiallyPaid }) &&
      !isOrderPartiallyPaid
    );

  const showRTOShimmer =
    isRTOPluginEnabled &&
    ![
      FAILED_STATUS,
      RTO_STATUS,
      SHIPPED_STATUS,
      REJECTED_STATUS,
      CANCELLED_STATUS,
      DELIVERED_STATUS,
      CANCELLED_BY_CUSTOMER,
    ].includes(orderDetails.status) &&
    orderDetails.type === 0 &&
    !isStatusAbandoned &&
    !(
      !isNotEmptyOrNull({ value: rtoScore }) &&
      isNotEmptyOrNull({ value: isOrderPartiallyPaid }) &&
      !isOrderPartiallyPaid
    );

  const numberOfTimesPartialPaymentLinkSent =
    Number(
      orderDetails?.order_meta?.order_partial_payment_link_data?.send_count
    ) || 0;

  const numberOfTimesFullPaymentLinkSent =
    Number(orderDetails?.order_meta?.order_payment_link_data?.send_count) || 0;

  const showRTOBadge =
    isRTOPluginEnabled &&
    ![
      FAILED_STATUS,
      RTO_STATUS,
      REJECTED_STATUS,
      ACCEPTED_STATUS,
      PENDING_STATUS,
    ].includes(orderDetails.status) &&
    !isStatusAbandoned;

  useEffect(() => {
    if (
      showTrackingModal ||
      requestPaymentModalOpen ||
      confirmModalOpen ||
      isMissingPaymentOpen ||
      isOpenPaymentLinkModal ||
      isOpenRecoveryModal
    )
      document.removeEventListener('keydown', handleKeyboardKeys);
    else document.addEventListener('keydown', handleKeyboardKeys);
  }, [
    handleKeyboardKeys,
    showTrackingModal,
    requestPaymentModalOpen,
    confirmModalOpen,
    isMissingPaymentOpen,
    isOpenPaymentLinkModal,
    isOpenRecoveryModal,
  ]);

  const showReturnTrackBtn =
    activeReturns[0]?.status === APPROVED_RETURN &&
    orderDetails.status === DELIVERED_STATUS &&
    activeReturns[0].return_shipment;

  const isCustomerDetailsExpanding =
    !(
      !isRestaurantOrder &&
      [PENDING_STATUS, ACCEPTED_STATUS].includes(orderDetails.status)
    ) && isReturnedOrReplacementExist;

  useEffect(() => {
    fetchDeliveryDetails();
    fetchReturnReplacementDetails();
    fetchSpecificOrderMetaFieldsList(uuid);
  }, []);

  const [expandedCard, setExpandedCard] = useState(false);

  const handleAccordionExpansion = () => {
    setExpandedCard((value) => !value);
  };

  const onUpdateAttribute = ({ metafields = {} } = {}) => {
    if (Object.keys(metafields)?.length > 0) {
      const fieldList = [];
      for (const [key, value] of Object.entries(metafields)) {
        const metafield = key.split('__')?.[1] || '';
        if (
          metafield !== 'undefined' &&
          (isNotEmptyOrNull({ value }) || value === '')
        ) {
          fieldList.push({
            metafield,
            value: JSON.stringify(value),
          });
        }
      }
      if (fieldList?.length > 0) updateSpecificOrderMetaFields(uuid, fieldList);
    }
  };

  const reverseShipmentDetails = activeReturns?.[0]?.return_shipment;

  const reverseFwdShipmentDetails = activeReturns?.[0]?.replacement_shipment;

  const isReverseCancelledShipment =
    reverseShipmentDetails?.status === STATUS_MAP.rejected_by_vendor;
  const isForwardCancelledShipment =
    reverseFwdShipmentDetails?.status === STATUS_MAP.rejected_by_vendor;

  const getDeleiveryTrackPickupText = () => {
    if (isReverseCancelledShipment) {
      if (isOrderDetailsReadOnly) return '';
      return 'Reassign pickup';
    }
    return 'Track pickup';
  };

  const getDeleiveryTrackReplacementText = () => {
    if (isForwardCancelledShipment) {
      if (isOrderDetailsReadOnly) return '';
      return 'Reassign replacement';
    }
    return 'Track replacement';
  };

  const renderActions = () => {
    if (loading) return;

    if (!canCreateVendor && showTrackBtn && !isOrderDetailsReadOnly) {
      return (
        <SpinnerButton
          className="btn-outline-primary-4"
          onClick={() => setTrackingModal(shipmentDetails?.uuid)}
        >
          Track order
        </SpinnerButton>
      );
    }
    if (isStatusAbandoned && business.id === VU_STORE_ID) {
      return (
        <SpinnerButton
          type="button"
          onClick={openPaymentLinkModal}
          className="btn-primary-4 mr16"
        >
          Send payment link
        </SpinnerButton>
      );
    }
    return (
      <OrderActions
        uuid={uuid}
        orderDetails={orderDetails}
        products={products}
        orderId={orderDetails.id}
        status={orderDetails.status}
        afterOrderAction={handleAfterOrderAction}
        isRestaurantOrder={isRestaurantOrder}
        isManualPaymentOrder={isManualPaymentOrder}
        refetch={refetch}
        paymentMode={orderDetails.payment_mode}
        hideAction={isStatusAbandoned}
        orderShipmentObj={orderShipmentObj}
        orderShipmentDetails={orderShipmentDetails}
        handleKeyboardKeys={handleKeyboardKeys}
        returnReplacementData={returnReplacementData}
        rejectOrderReturn={rejectOrderReturn}
        submitting={submitting}
        refundReturnOrReplacementOrder={refundReturnOrReplacementOrder}
        refunding={refunding}
        reverseFwdShipmentDetails={reverseFwdShipmentDetails}
        markReplacementComplete={markReplacementComplete}
        orderPayments={orderPayments}
        showShipingModal={showShipingModal}
        setShowShipingModal={setShowShipingModal}
        isCancelledReturnShipment={isCancelledReturnShipment}
        isCancelledForwardReplacementShipment={
          isCancelledForwardReplacementShipment
        }
        isOrderDetails
        orderLoading={loading}
      />
    );
  };

  useEffect(() => {
    if (orderSpecificMetaFieldsList.length > 0) {
      setInitialFormValues({
        metafields: orderSpecificMetaFieldsList?.reduce((acc, each) => {
          acc[`attribute__${each.id}`] = each.value;
          return acc;
        }, {}),
      });
    }
  }, [orderSpecificMetaFieldsList]);

  return (
    <Layout
      backLink={goBack}
      renderHeaderText={() => (
        <div className="d-flex align-center">
          <span className="section-text-3 mr16">
            Order ID #{orderDetails?.display_order_id}
          </span>
          <StatusText
            status={orderDetails?.status}
            newStatus={orderDetails?.new_status}
            isManualPaymentOrder={isManualPaymentOrder}
          />
        </div>
      )}
      documentTitle={pageTitle}
      headerParentClass={cx('fixed', {
        standard: isStatusAbandoned && !showNotesTimeline,
      })}
      headerActions={
        <div className="header-actions">
          <div className={cx('header-action-container pull-right')}>
            {renderActions()}
          </div>
        </div>
      }
    >
      <div>
        <div className="order-details-wrapper">
          {/* {prevUuid && (
            <SlideLeftIcon onClick={movePrev} className="carousel-btn-left" />
          )} */}
          <div className="order-detail-container">
            <div className={cx(`d-flex fixed-container-large`)}>
              {(loading ||
                preferenceLoading ||
                returnsLoading ||
                !orderDetails?.uuid) &&
              !hasSomeOrderDetails ? (
                <OrderDetailShimmer
                  showNotesTimeline={showNotesTimeline}
                  isStatusAbandoned={isStatusAbandoned}
                />
              ) : (
                <div className="d-flex fill-available justify-center">
                  <div
                    className={
                      !showNotesTimeline && isStatusAbandoned
                        ? 'abandoned-order'
                        : 'flex-1'
                    }
                  >
                    {credits < 0 && <NegativeCredits bottomCta={true} />}

                    {/* {!isOrderDetailsReadOnly &&
                      showRequestPaymentBanner() &&
                      !showRTOShimmer &&
                      !showRTOCard && (
                        <MarketingStripe
                          variant="success"
                          size="sm"
                          title="Request online payment"
                          description="Share payment link with your customer & get paid online."
                          btnLabel="Request"
                          disabled={isSubmitting}
                          onClick={handleClickRequestPayment}
                        />
                      )} */}
                    {!isOrderDetailsReadOnly &&
                      orderDetails?.order_meta?.pre_order_data &&
                      orderDetails?.order_meta?.pre_order_data
                        ?.pre_order_amount_paid === true &&
                      orderDetails?.order_meta?.pre_order_data
                        ?.pending_amount_paid === false && (
                        <MarketingStripe
                          variant="success"
                          size="sm"
                          title="Request online payment"
                          description="Request customer to make full payment for pre-order."
                          btnLabel="Request"
                          disabled={isSubmitting}
                          onClick={handleClickRequestFullPayment}
                        />
                      )}
                    {orderDetails.status === MODIFIED_STATUS && (
                      <div className="pending-confirmation-banner text-center">
                        <span className="section-text-5">
                          Waiting for the customer confirmation
                        </span>
                      </div>
                    )}
                    {/* {orderDetails.status === REJECTED_STATUS &&
                      orderDetails.payment_mode === ONLINE_PAYMENT_MODE && (
                        <Nudge
                          text="Refund will automatically be processed in the customer’s account within 7 business days."
                          size={NUDGE_SIZES.SMALL}
                          type={NUDGE_TYPES.PRIMARY}
                          variant={NUDGE_VARIANTS.INFO}
                          iconVariant={NUDGE_ICON_VARIANTS.BLUE}
                          className="fw500 mb24"
                        />
                      )} */}
                    <div className="order-details-section-container">
                      <OrderDetailSection
                        isExpanding={isReturnedOrReplacementExist}
                        expanded={expandedCard}
                        isFirstSection
                        expandIcon={false}
                        showWarningHeader={
                          orderDetails.status === MODIFIED_STATUS
                        }
                        sectionHeader={
                          isRestaurantOrder ? (
                            <RestaurantOrderDetailHeader
                              {...orderDetails}
                              storeTableData={storeTableData}
                              showTrackBtn={showTrackBtn}
                              trackOrder={() =>
                                setTrackingModal(shipmentDetails?.uuid)
                              }
                              showSelfTrackEditBtn={showSelfTrackEditBtn}
                              onSelfTrackOrder={AddTrackingDetails}
                            />
                          ) : (
                            <>
                              <OrderDetailHeader
                                isOrderDetailsReadOnly={isOrderDetailsReadOnly}
                                {...orderDetails}
                                showTrackBtn={showTrackBtn}
                                trackOrder={() =>
                                  setTrackingModal(shipmentDetails?.uuid)
                                }
                                showSelfTrackEditBtn={showSelfTrackEditBtn}
                                onSelfTrackOrder={AddTrackingDetails}
                                isStatusAbandoned={isStatusAbandoned}
                                isManualPaymentOrder={isManualPaymentOrder}
                                activeReturns={activeReturns}
                                riskStatus={calculatedRiskStatus}
                                showRiskStatus={showRTOBadge}
                                isPaymentLinkSent={
                                  numberOfTimesFullPaymentLinkSent > 0 ||
                                  numberOfTimesPartialPaymentLinkSent > 0
                                }
                                returnReplacementData={returnReplacementData}
                                parentClassName={
                                  isReturnedOrReplacementExist &&
                                  products?.length > 0 &&
                                  'mb16'
                                }
                                refetch={refetch}
                                updateSpamStatus={updateSpamStatus}
                                submitting={isSubmitting}
                              />
                              {isReturnedOrReplacementExist &&
                                products?.length > 0 && (
                                  <div
                                    className="d-flex align-center"
                                    onClick={handleAccordionExpansion}
                                  >
                                    <p className="section-text-5 anchor-1 underline mr4">
                                      {[
                                        APPROVED_RETURN,
                                        COMPLETED_RETURN,
                                      ].includes(returnReplacementStatus)
                                        ? 'Updated order details'
                                        : 'Order details'}
                                    </p>
                                    {expandedCard === true ? (
                                      <div className="upper-arrow-order-list d-flex align-center">
                                        <UpArrowIcon />
                                      </div>
                                    ) : (
                                      <DownArrowBlueIcon />
                                    )}
                                  </div>
                                )}
                            </>
                          )
                        }
                      >
                        <div className="d-flex flex-column w-100">
                          <OrderProducts
                            orderDetails={orderDetails}
                            orderId={orderDetails?.id}
                            orderUUID={orderDetails?.uuid}
                            activeReturns={activeReturns}
                            returnReplacementData={returnReplacementData}
                            isAbandonedCart={isStatusAbandoned}
                            products={products}
                            itemCount={orderDetails?.product_count || 0}
                            lineItemCount={orderDetails?.product_count}
                            isCancelledByCustomer={
                              orderDetails.status === CANCELLED_BY_CUSTOMER
                            }
                            expandedCard={expandedCard}
                            isReturnedOrReplacementExist={
                              isReturnedOrReplacementExist
                            }
                            reason={reason}
                            showProductDropMessage={
                              isInternational &&
                              shipmentDetails?.carrier_meta &&
                              !shipmentDetails?.carrier_meta
                                ?.shippo_pickup_scheduled &&
                              SHIPMENT_CANCELLABLE_STATUSES.includes(
                                shipmentDetails?.status
                              )
                            }
                            cancelledLineItemDetailsMap={
                              cancelledLineItemDetailsMap
                            }
                          />
                          <OrderTotal
                            isOrderDetailsReadOnly={isOrderDetailsReadOnly}
                            {...orderDetails}
                            products={products}
                            showEstimateDeliveryCharge={
                              orderDetails.status <= ACCEPTED_STATUS
                            }
                            shippingMode={
                              shiprocketDetails?.id
                                ? SHIPROCKET_SHIPPING_MODE
                                : DUKAAN_DELIVERY_SHIPPING_MODE
                            }
                            isReturnedOrReplacementExist={
                              isReturnedOrReplacementExist
                            }
                            isRestaurantOrder={isRestaurantOrder}
                            orderShipmentObj={orderShipmentObj}
                            isAbandonedCart={
                              orderDetails?.status === ABANDONED_STATUS
                            }
                            orderPayments={orderPayments}
                            returnReplacementData={returnReplacementData}
                            isManualPaymentOrder={isManualPaymentOrder}
                          />
                        </div>
                        {orderDetails?.order_meta?.abandoned_discount && (
                          <DiscountedAbandonedInformation
                            details={orderDetails?.order_meta}
                          />
                        )}

                        {orderDetails?.order_meta?.partner_id &&
                        plugins.length &&
                        !!trackingDetails ? (
                          <ShipmentDetails
                            {...orderDetails?.order_meta}
                            plugins={plugins}
                            trackingDetails={trackingDetails}
                          />
                        ) : null}
                      </OrderDetailSection>
                    </div>
                    {isReturnedOrReplacementExist && (
                      <ReplacementDetails
                        orderDetails={orderDetails}
                        returnReplacementData={returnReplacementData}
                        products={products}
                      />
                    )}
                    {reverseShipmentDetails?.uuid && (
                      <OrderDetailSection
                        sectionHeader={
                          <OrderDetailSectionHeader
                            title="Pickup from Customer"
                            btnText="Cancel"
                            btnClassName="btn-no-default c-red-1 section-text-5"
                            btnClick={() =>
                              setConfirmCancelReverseShipmentModalOpen(
                                reverseShipmentDetails.uuid
                              )
                            }
                            hideBtn={
                              isOrderDetailsReadOnly ||
                              !SHIPMENT_CANCELLABLE_STATUSES.includes(
                                reverseShipmentDetails?.status
                              )
                            }
                          />
                        }
                      >
                        <OrderDeliveryDetails
                          isReturnedOrReplacementExist
                          hideDownloadInvoiceAndLabel
                          shipmentDetails={{
                            ...reverseShipmentDetails,
                            ...(reverseShipmentDetails?.carrier_meta || {}),
                          }}
                          uuid={uuid}
                          trackBtnText={getDeleiveryTrackPickupText()}
                          trackOrder={() => {
                            if (!isReverseCancelledShipment) {
                              setReturnShipmentId(reverseShipmentDetails?.uuid);
                              setTrackingModal(true);
                              return;
                            }
                            setCancelledReturnShipment(true);
                            setShowShipingModal(true);
                          }}
                          isCancelledShipment={isReverseCancelledShipment}
                        />
                      </OrderDetailSection>
                    )}
                    {reverseFwdShipmentDetails?.uuid && (
                      <OrderDetailSection
                        sectionHeader={
                          <OrderDetailSectionHeader
                            title="Deliver to Customer"
                            btnText="Cancel"
                            btnClassName="btn-no-default c-red-1 section-text-5"
                            btnClick={() =>
                              setConfirmCancelReverseShipmentModalOpen(
                                reverseFwdShipmentDetails.uuid
                              )
                            }
                            hideBtn={
                              isOrderDetailsReadOnly ||
                              !SHIPMENT_CANCELLABLE_STATUSES.includes(
                                reverseFwdShipmentDetails?.status
                              )
                            }
                          />
                        }
                      >
                        <OrderDeliveryDetails
                          isReturnedOrReplacementExist
                          shipmentDetails={{
                            ...reverseFwdShipmentDetails,
                            ...(reverseFwdShipmentDetails?.carrier_meta || {}),
                          }}
                          uuid={uuid}
                          trackBtnText={getDeleiveryTrackReplacementText()}
                          trackOrder={() => {
                            if (!isForwardCancelledShipment) {
                              setReturnShipmentId(
                                reverseFwdShipmentDetails?.uuid
                              );
                              setTrackingModal(true);
                              return;
                            }
                            setCancelledForwardReplacementShipment(true);
                            setShowShipingModal(true);
                          }}
                          isCancelledShipment={isForwardCancelledShipment}
                        />
                      </OrderDetailSection>
                    )}
                    {isInternational ? (
                      <section className="mt24">
                        <OrderDetailSection
                          isExpanding={isReturnedOrReplacementExist}
                          sectionHeader="Customer details"
                        >
                          <IntlCustomerDetails
                            {...orderDetails}
                            isExpanding={isReturnedOrReplacementExist}
                            isManualPaymentOrder={isManualPaymentOrder}
                            isStatusAbandoned={isStatusAbandoned}
                          />
                        </OrderDetailSection>
                      </section>
                    ) : (
                      <section className="mt24">
                        <OrderDetailSection
                          isExpanding={isCustomerDetailsExpanding}
                          sectionHeader={
                            <OrderDetailSectionHeader
                              title="Customer details"
                              btnText={<EditThinIcon />}
                              helpUrl={
                                credits < 0 ? CREDITS_READMORE_LINK : null
                              }
                              helpText="Why aren't the customer details visible?"
                              btnClick={editCustomerDetailsOpenModal}
                              hideBtn={
                                (orderDetails.status > ACCEPTED_STATUS &&
                                  !isCustomerDetailsFieldsLoading) ||
                                isOrderDetailsReadOnly
                              }
                            />
                          }
                        >
                          <CustomerDetails
                            isOrderDetailsReadOnly={isOrderDetailsReadOnly}
                            {...orderDetails}
                            isExpanding={isCustomerDetailsExpanding}
                            editCustomerDetailsCloseModal={
                              editCustomerDetailsCloseModal
                            }
                            submitting={submitting}
                            editCustomerDetailsOpen={editCustomerDetailsOpen}
                            isRestaurantOrder={isRestaurantOrder}
                            isManualPaymentOrder={isManualPaymentOrder}
                            isStatusAbandoned={isStatusAbandoned}
                            handleKeyboardKeys={handleKeyboardKeys}
                            resendManualPaymentLink={resendManualPaymentLink}
                            customerDetailsFields={customerDetailsFields}
                          />
                        </OrderDetailSection>
                      </section>
                    )}
                    {orderPayments?.order_payments?.length > 0 && (
                      <OrderDetailSection
                        isExpanding={isReturnedOrReplacementExist}
                        sectionHeader="Payments & refunds"
                      >
                        <OrderPaymentAndRefund
                          orderPayments={orderPayments}
                          isExpanding={isCustomerDetailsExpanding}
                          isAbandonedOrder={isStatusAbandoned}
                        />
                      </OrderDetailSection>
                    )}
                    {orderAdditionalDetails && (
                      <OrderDetailSection
                        isExpanding={isReturnedOrReplacementExist}
                        sectionHeader="Additional details"
                      >
                        <OrderFormDetails
                          isReturnedOrReplacementExist={
                            isReturnedOrReplacementExist
                          }
                          {...orderDetails}
                        />
                      </OrderDetailSection>
                    )}
                    {!isInternational &&
                      shipmentDetails?.carrier &&
                      !(isVendor || canCreateVendor) && (
                        <OrderDetailSection
                          isExpanding={isReturnedOrReplacementExist}
                          sectionHeader={
                            <div className="d-flex-c-s">
                              <div className="section-text-4 text-semibold">
                                Delivery details
                                {isReturnedOrReplacementExist && (
                                  <span className="ml8 c-gray-1 text-2 text-normal">
                                    (Original order)
                                  </span>
                                )}
                              </div>
                              {orderDetails.status === SHIPPED_STATUS &&
                                shipmentDetails?.is_external_shipment &&
                                !isOrderDetailsReadOnly && (
                                  <button
                                    type="button"
                                    className="btn-no-default cur-p text-medium c-purple-1 text-3 p0"
                                    onClick={AddTrackingDetails}
                                  >
                                    EDIT
                                  </button>
                                )}
                              {!shipmentDetails.is_external_shipment &&
                                SHIPMENT_CANCELLABLE_STATUSES.includes(
                                  shipmentDetails?.status
                                ) &&
                                !isOrderDetailsReadOnly && (
                                  <button
                                    type="button"
                                    className="btn-no-default section-text-5 cur-p text-medium c-red-1 p0"
                                    onClick={() => setConfirmModalOpen(true)}
                                    disabled={isSubmitting}
                                  >
                                    CANCEL
                                  </button>
                                )}
                            </div>
                          }
                        >
                          <OrderDeliveryDetails
                            shipmentDetails={shipmentDetails}
                            uuid={uuid}
                            alwaysCardExpanded={!isReturnedOrReplacementExist}
                            showReturnTrackBtn={showReturnTrackBtn}
                            returnShipmentId={returnShipmentId}
                          />
                        </OrderDetailSection>
                      )}
                    {isInternational &&
                      shipmentDetails?.uuid &&
                      !(isVendor || canCreateVendor) && (
                        <OrderDetailSection
                          isExpanding={isReturnedOrReplacementExist}
                          sectionHeader={
                            <div className="d-flex-c-s">
                              <div className="section-text-4">
                                Delivery details
                              </div>
                              {orderDetails.status === SHIPPED_STATUS &&
                                shipmentDetails?.is_external_shipment &&
                                !isOrderDetailsReadOnly && (
                                  <button
                                    type="button"
                                    className="btn-no-default cur-p text-medium c-purple-1 text-3 p0"
                                    onClick={AddTrackingDetails}
                                  >
                                    EDIT
                                  </button>
                                )}
                              {shipmentDetails?.carrier_service && (
                                <div className="d-flex-c-s mt20">
                                  <p className="text-medium text-2">
                                    {shipmentDetails?.carrier?.toUpperCase()}
                                  </p>

                                  {!shipmentDetails.is_external_shipment &&
                                    SHIPMENT_CANCELLABLE_STATUSES.includes(
                                      shipmentDetails?.status
                                    ) &&
                                    !isOrderDetailsReadOnly && (
                                      <p className="text-right">
                                        <button
                                          type="button"
                                          className="btn-no-default cur-p text-medium c-red-1 p0"
                                          onClick={() =>
                                            setConfirmModalOpen(true)
                                          }
                                          disabled={isSubmitting}
                                        >
                                          CANCEL
                                        </button>
                                      </p>
                                    )}
                                </div>
                              )}
                            </div>
                          }
                        >
                          <IntlDeliveryDetails
                            shipmentDetails={shipmentDetails}
                            isDeliveryCtaExist={!isReturnedOrReplacementExist}
                          />
                        </OrderDetailSection>
                      )}
                    {orderMetaFieldsList?.length > 0 && (
                      <Form
                        onSubmit={noop}
                        mutators={{ modifyFormField }}
                        initialValues={{ ...initialFormValues }}
                        render={(formProps) => (
                          <div className="order-attributes">
                            <DisplayAttributes
                              values={formProps.values}
                              form={formProps.form}
                              formProps={formProps}
                              isExpanded
                              attributeProps={attributeProps}
                              isOrderPage
                              attributeExist
                              isLargeFileText
                              onUpdateClick={() =>
                                onUpdateAttribute(formProps.values)
                              }
                              description=""
                              metaList={attributeProps?.orderMetaFieldsList}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>

                  {(!isStatusAbandoned || showNotesTimeline) && (
                    <div className="notes-timeline order-details-sidebar">
                      {showNotesTimeline && activityLoading && (
                        <NotesTimelineShimmer
                          hasSomeOrderDetails={hasSomeOrderDetails}
                        />
                      )}
                      {showNotesTimeline && !activityLoading && (
                        <NotesTimeline
                          uuid={uuid}
                          notesTimelineData={{
                            notes,
                            activities: orderActivityList,
                          }}
                          hideNotes
                          handleKeyboardKeys={handleKeyboardKeys}
                          fetchOrderActivityList={fetchOrderActivityList}
                          updateOrderNotes={updateOrderNotes}
                        />
                      )}
                      {!isStatusAbandoned && (
                        <Tags
                          type={TAGS_FOR.ORDER}
                          objectId={orderDetails?.id}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* {nextUuid && (
            <SlideRightIcon onClick={moveNext} className="carousel-btn-right" />
          )} */}
        </div>
        {showTrackingModal && (shipmentDetails?.uuid || returnShipmentId) && (
          <TrackOrderDetailsModal
            shipmentUuid={
              Boolean(returnShipmentId)
                ? returnShipmentId
                : shipmentDetails?.uuid
            }
            open={showTrackingModal}
            closeModal={() => setTrackingModal(false)}
          />
        )}
        <RequestOnlinePaymentModal
          open={requestPaymentModalOpen}
          closeModal={closeRequestPaymentModal}
          setShowRequestPaymentConfirmation={setShowRequestPaymentConfirmation}
          onSubmit={() => {
            generatePaymentLink();
            closeRequestPaymentModal();
          }}
        />
        {confirmModalOpen && (
          <ConfirmModal
            open={confirmModalOpen}
            loading={isSubmitting}
            headerText="Cancel delivery"
            svgIcon={<DeleteModalIcon className="header-svg-icon" />}
            confirmText="Yes, cancel"
            renderContent={() =>
              isHyperlocal && shipmentDetails?.status >= 2 ? (
                <>
                  {shipmentDetails?.grouped_orders?.length > 1 ? (
                    <p className="text-2 c-black-3">
                      <span className="text-medium">Note:</span> A total
                      cancellation fee of ₹30 will be charged to cancel this
                      delivery and deliveries of{' '}
                      <span className="text-medium">Order IDs </span>
                      <span>
                        {shipmentDetails?.grouped_orders.map((order, idx) => (
                          <span className="text-medium">
                            {idx !== 0 && ', '}#{order.id}
                          </span>
                        ))}
                      </span>
                      . The remaining amount will be refunded into your Dukaan
                      Credits.
                    </p>
                  ) : (
                    <p className="text-2 c-black-3">
                      <span className="text-medium">Note:</span> A cancellation
                      fee of ₹30 will be charged and the remaining amount will
                      be refunded into your Dukaan Credits.
                    </p>
                  )}
                </>
              ) : (
                <>
                  {isHyperlocal &&
                  shipmentDetails?.grouped_orders?.length > 1 ? (
                    <p className="text-2 c-black-3">
                      <span className="text-medium">Note:</span> You will be
                      refunded instantly in your Dukaan Credits and the pickup
                      for <span className="text-medium">Order IDs </span>
                      <span>
                        {shipmentDetails?.grouped_orders.map((order, idx) => (
                          <span className="text-medium">
                            {idx !== 0 && ', '}#{order.id}
                          </span>
                        ))}
                      </span>{' '}
                      will be cancelled as well.
                    </p>
                  ) : (
                    <p className="text-2 c-black-3">
                      Do you really want to cancel this delivery?
                      {!isInternational &&
                        orderShippingDetails?.shipping_mode ===
                          DUKAAN_DELIVERY_SHIPPING_MODE && (
                          <span>
                            <br /> <span className="text-medium">Note:</span>{' '}
                            You will get your refund instantly in your Dukaan
                            Credits.
                          </span>
                        )}
                    </p>
                  )}
                </>
              )
            }
            onCancel={() => setConfirmModalOpen(false)}
            onConfirm={handleCanclePickup}
            confirmBtnClass="btn-primary-4 btn-danger"
            hideCancel
          />
        )}
        {confirmCancelReverseShipmentModalOpen && (
          <ConfirmModal
            open={Boolean(confirmCancelReverseShipmentModalOpen)}
            loading={isSubmitting}
            headerText={`Cancel ${
              reverseFwdShipmentDetails?.uuid ===
              confirmCancelReverseShipmentModalOpen
                ? 'replacement'
                : 'reverse'
            } pickup?`}
            svgIcon={<DeleteModalIcon className="header-svg-icon" />}
            confirmText="Yes, cancel"
            renderContent={() => (
              <p className="text-2 c-black-3">
                The pickup will be cancelled and you will be instantly refunded
                in your Dukaan credits.
              </p>
            )}
            onCancel={() => setConfirmCancelReverseShipmentModalOpen(false)}
            onConfirm={handleCanclePickup}
            confirmBtnClass="btn-primary-4 btn-danger"
            hideCancel
          />
        )}
        <ChangePaymentMethodDialog
          title={
            paymentProvider === RAZORPAY_KEY
              ? 'Set up Dukaan Pay'
              : paymentModalcontent.heading
          }
          caption={
            paymentProvider === RAZORPAY_KEY
              ? 'Requesting online payments with Razorpay is not supported. Please set up Dukaan Pay to use this feature.'
              : paymentModalcontent.body
          }
          buttonLabel={paymentModalcontent.btnText}
          open={isMissingPaymentOpen}
          closeModal={closeMissingPaymentModal}
          onContinueClick={() => {
            // send to payment setup for dukaan pay
            history.replace(
              `${accountUrls.settingsPath}?section=${STORE_SETTINGS_SECTION.PAYMENTS}`
            );
          }}
        />
        <SendPaymentLinkModal
          open={isOpenPaymentLinkModal}
          closeModal={closePaymentLinkModal}
          uuid={uuid}
          refetch={refetch}
          orderTotal={orderDetails?.total_cost}
        />
      </div>
      {isOpenRecoveryModal && (
        <RecoveryMessageModal
          open={isOpenRecoveryModal}
          handleClose={closeRecoveryModal}
          templates={templates.map((item) => ({
            label: item?.body,
            id: item?.id,
          }))}
          storeLeadIds={[orderDetails?.store_lead_id]}
          fetchTemplates={fetchTemplates}
          templatesLoading={templatesLoading}
          pageNumberTemplates={pageNumberTemplates}
          setPageNumberTemplates={setPageNumberTemplates}
          hasMoreTemplates={hasMoreTemplates}
        />
      )}
    </Layout>
  );
};

export default OrderDetails;
