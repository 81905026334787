import React from 'react';
import Select from 'react-select';
import { createStyles, makeStyles } from '@material-ui/core';

import { noop } from '../../utils';
import { ClearIndicator, DropdownIndicator } from './components';
import InputDisabledTooltip from '../../Catalogue/Products/Variants/InputDisabledTooltip';

const CustomSelect = (props) => {
  const {
    disabled: isDisabled,
    onSelectChange,
    options,
    components: childComponents,
    isInputPhoneCode = false,
    hasTooltip = false,
    showTooltip = false,
    tooltipTitle = '',
    ...restProps
  } = props;

  const useStyles = makeStyles(() =>
    createStyles({
      tooltipPlacementBottom: {
        marginTop: '24px !important',
      },
    })
  );

  const styles = useStyles();

  const selectProps = {
    isDisabled,
    classNamePrefix: 'custom-select',
    components: {
      LoadingIndicator: null,
      DropdownIndicator,
      ClearIndicator,
      ...childComponents,
    },
    options,
    onChange: onSelectChange,
    ...restProps,
  };
  return hasTooltip ? (
    <InputDisabledTooltip
      title={tooltipTitle}
      show={showTooltip}
      tooltipProps={{
        classes: {
          tooltipPlacementBottom: styles.tooltipPlacementBottom,
        },
      }}
    >
      <Select
        {...selectProps}
        onFocus={(e) => {
          if (e.target.nodeName === 'INPUT' && isInputPhoneCode) {
            e.target.maxLength = 5;
            e.currentTarget.style.maxWidth = '48px';
          }
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            boxShadow: isInputPhoneCode && 'none !important',
          }),
        }}
      />
    </InputDisabledTooltip>
  ) : (
    <Select
      {...selectProps}
      onFocus={(e) => {
        if (e.target.nodeName === 'INPUT' && isInputPhoneCode) {
          e.target.maxLength = 5;
          e.currentTarget.style.maxWidth = '48px';
        }
      }}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          boxShadow: isInputPhoneCode && 'none !important',
        }),
      }}
    />
  );
};

CustomSelect.defaultProps = {
  components: {},
  options: [], // [{label: '', value: ''}]
  onSelectChange: noop,
  placeholder: '',
  noOptionsMessage: () => 'No results found',
  isClearable: false,
};

export default CustomSelect;
