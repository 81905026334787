import useCustomSnackbar from './useCustomSnackbar';
import useFileDownload from './useFileDownload';
import { noop } from '../utils';
import useSocketV2 from './useSocketV2';

const useOrderReportSocket = (
  cb = noop,
  msg = 'Order report downloaded successfully.',
  skipDownload = false
) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { downloadFile } = useFileDownload();

  const successCb = (data) => {
    if (data.pdf_link || data.file_url) {
      if (skipDownload) {
        cb();
      } else {
        downloadFile(data.pdf_link || data.file_url, true, () => {
          enqueueSnackbar(msg);
          cb();
        });
      }
    }
  };

  const errorCb = () => {
    enqueueSnackbar('Something went wrong!', 'error');
    cb();
  };

  const { setTaskId, setLocalTaskId } = useSocketV2(successCb, errorCb);

  return { setTaskId, setLocalTaskId };
};

export default useOrderReportSocket;
