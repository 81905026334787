import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormInput } from '../../shared/Form';
import Validation from '../../shared/Form/Validation';
import { postRequest } from '../../utils/http';
import { ExternalLink } from '../../shared';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import Nudge from '../../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';
import { SAVE_PAYMENT_GATEWAY_CREDS } from '../../ApiUrls';
import { PAYMENT_GATEWAY_IDS } from '../Payments/constants';
import { PHONEPE_KEY, usePayments } from '../../context/PaymentsProvider';

const PHONEPE_HELP_URL = 'https://business.phonepe.com/login';
const PHONEPE_CREATE_ACCOUNT_URL =
  'https://www.phonepe.com/business-solutions/payment-gateway/register/';

const PhonepeSetupForm = ({
  isActivated = false,
  isPresent = false,
  successCallback = noop,
}) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { fetchPaymentGatewayCreds, paymentKeys } = usePayments();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialCreds, setInitialCreds] = useState({
    salt_key: '',
    salt_index: '',
    merchant_id: '',
  });

  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: SAVE_PAYMENT_GATEWAY_CREDS(
        business.uuid,
        PAYMENT_GATEWAY_IDS.PHONEPE
      ),
      data: {
        salt_key: values.salt_key,
        salt_index: values.salt_index,
        payment_provider_account_meta: { merchant_id: values.merchant_id },
      },
    })
      .then(() => {
        enqueueSnackbar('PhonePe Payments set up successfully!');
        successCallback();
        fetchBusinessDetails();
      })
      .catch(() => {
        enqueueSnackbar(
          'We are unable to verify your keys. Please enter correct key values.',
          'error'
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (isPresent) {
      setLoading(true);
      fetchPaymentGatewayCreds(
        paymentKeys.find((it) => it.provider === PHONEPE_KEY).uuid,
        (data) => {
          const creds = data?.data?.payment_provider_account;
          if (creds) {
            setInitialCreds({
              salt_key: creds?.client_secret,
              salt_index: creds?.client_id,
              merchant_id: creds?.payment_provider_account_meta?.merchant_id,
            });
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialCreds }}
      render={({ handleSubmit, invalid }) => (
        <form className="mt4">
          {!isPresent && (
            <>
              <div className="text-8 mb16">
                Please enter your PhonePe. You can find your credentials on
                <ExternalLink
                  href={PHONEPE_HELP_URL}
                  className="text-medium c-purple-1-i ml6 underline"
                >
                  PhonePe Dashboard
                </ExternalLink>
              </div>
              <Nudge
                variant={NUDGE_VARIANTS.PRIMARY}
                type={NUDGE_TYPES.PRIMARY}
                iconVariant={NUDGE_ICON_VARIANTS.BLUE}
                text={
                  <p className="c-black-3 text-8 d-flex align-center">
                    Don't have an PhonePe account?{' '}
                    <ExternalLink
                      href={PHONEPE_CREATE_ACCOUNT_URL}
                      className="text-medium c-black-3-i ml6 underline"
                    >
                      Create now
                    </ExternalLink>
                  </p>
                }
              />
            </>
          )}

          <FormInput
            labelText="Salt Key"
            name="salt_key"
            placeholder="Enter Salt Key"
            className="my24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Salt Index"
            name="salt_index"
            placeholder="Enter Salt Index"
            className="my24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Merchant Id"
            name="merchant_id"
            placeholder="Enter Merchant Id"
            className="mb24"
            required
            showRequired
            validate={Validation.required()}
          />
          <div className="text-center mt24">
            <SpinnerButton
              isLoading={submitting || loading}
              onClick={handleSubmit}
              disabled={invalid}
            >
              {isActivated && isPresent ? 'Update' : 'Set up'}
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default PhonepeSetupForm;
