import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useAppContext } from '../context/AppContext';
import GoOnlineModal from './GoOnlineModal';
import { noop } from '../utils';
import useStoreStatusChange from '../hooks/useStoreStatusChange';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import {
  EVENT_DASH_STORE_OFF,
  EVENT_DASH_STORE_OFF_CONFIRM,
  EVENT_DASH_STORE_ON,
} from '../events';
import { TrackEvent } from '../utils/analytics';

const OnlineOfflineBadge = () => {
  const { business, fetchBusinessDetails } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const { isSubmitting, changeStoreStatus } = useStoreStatusChange();
  const { enqueueSnackbar } = useCustomSnackbar();

  const { meta: { is_online: online } = {}, uuid: storeUuid } = business;
  const [isStoreOnline, setIsStoreOnline] = useState(online);

  const handleClose = () => {
    setShowModal(false);
  };

  const successCallback = (value) => {
    enqueueSnackbar(`Store set to ${!value ? 'open' : 'closed'}`);
    setIsStoreOnline(!value);
    fetchBusinessDetails();
    if (value) {
      TrackEvent(EVENT_DASH_STORE_OFF);
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    } else {
      TrackEvent(EVENT_DASH_STORE_ON);
    }
  };

  useEffect(() => {
    setIsStoreOnline(online);
  }, [online]);

  const handleConfirm = (value = null) => {
    let params = {};
    if (value) {
      TrackEvent(EVENT_DASH_STORE_OFF_CONFIRM, business);
      params = { is_online: false, store_opens_in: value };
    } else {
      params = { is_online: true, store_opens_in: 0 };
    }
    changeStoreStatus(params, storeUuid, successCallback);
  };

  const handleChange = () => {
    if (isStoreOnline) {
      setShowModal(true);
    } else {
      handleConfirm();
    }
  };

  const classes = cx('onoffswitch', {
    offline: !isStoreOnline,
  });

  const switchClasses = cx('onoffswitch-switch', {
    switchposition: !isStoreOnline,
  })

  return (
    <div className="online-offline-badge">
      <div className={classes} onClick={handleChange}>
        <input
          type="checkbox"
          name="online-status"
          className="onoffswitch-checkbox"
          id="myonoffswitch"
          checked={isStoreOnline}
          onChange={noop}
        />
        <label className="onoffswitch-label">
          <span className="onoffswitch-inner" />
          <span className={switchClasses} />
        </label>
      </div>
      <GoOnlineModal
        open={showModal}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

OnlineOfflineBadge.defaultProps = {
  checked: false,
  disabled: false,
  onChange: noop,
  labelText: 'Online',
  className: '',
  lightOffTheme: false,
};

export default OnlineOfflineBadge;
