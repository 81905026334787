import cx from 'classnames';
import { useCountry } from '../context/CountryProvider';
import { isNotEmptyOrNull } from '../shared/Form/Shared';
import Modal from '../shared/Modal';
import { TickGreenIcon } from '../SvgIcon';
import { noop } from '../utils';
import { formatDateTime } from '../utils/date';
import { OrderProductInfo } from './common';
import { RETURN_REFUND_STATUS_MAP } from './constants';

const LineItemsRefundDetailsModal = ({
  open,
  closeModal = noop,
  refundDetails: {
    showShipment,
    shipmentId,
    trackingLink,
    staffName,
    products,
    lineItemDetails,
  } = {},
}) => {
  const { formatLocalMoney } = useCountry();
  const couponDiscount = lineItemDetails?.discount;
  const refundData = lineItemDetails?.refund_data;
  return (
    <Modal
      open={open}
      className="shipment-created-modal"
      closeModal={closeModal}
      title={
        <span className="mb2">
          {refundData?.refund_status
            ? 'Refund details'
            : 'Cancelled order details'}
        </span>
      }
      paperClass="md-lg"
    >
      <div className="mt46 order-detail-container p0">
        <div className="d-flex">
          {showShipment && (
            <h4 className="section-text-4 mr12">
              Shipment ID:{' '}
              {trackingLink ? (
                <a
                  href={trackingLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="anchor-1 underline text-medium"
                >
                  {shipmentId}
                </a>
              ) : (
                <span>{shipmentId}</span>
              )}
            </h4>
          )}
          <p className="duplicate-order-text-container px12 py4 text-8">
            {staffName}
          </p>
        </div>
        <div className="order-products mt16">
          {products.map((product, index) => (
            <OrderProductInfo
              key={product?.product_uuid}
              {...product}
              isQuantityFreed={product?.quantity_freed > 0}
              isMultipleUnits={
                product?.quantity !== 0 &&
                product?.quantity_freed !== null &&
                product?.quantity_freed !== undefined
              }
            />
          ))}
        </div>
        <div className="order-total">
          <div>
            <span>Item Total</span>
            <span>{formatLocalMoney(lineItemDetails.item_total)}</span>
          </div>
          {couponDiscount > 0 && (
            <div>
              <span>
                <p>Discount</p>
              </span>
              <div>
                {!!couponDiscount && (
                  <span className={cx('ml5')}>
                    -{formatLocalMoney(couponDiscount)}
                  </span>
                )}
              </div>
            </div>
          )}
          <div>
            <span>GST</span>
            <span>{formatLocalMoney(lineItemDetails?.tax || 0)}</span>
          </div>
          <div className="mt24 grand-total-row">
            <span className="d-flex align-center">
              <span>Total Amount</span>
              {refundData?.refund_status && (
                <span className="d-flex align-center ml8 text-italic text-8 c-black-3 text-normal">
                  <span className="d-flex mr6">
                    <TickGreenIcon />
                  </span>
                  {RETURN_REFUND_STATUS_MAP.COMPLETED ===
                  refundData?.refund_status
                    ? 'Refunded'
                    : 'Refund initiated'}
                </span>
              )}
            </span>
            <span>{formatLocalMoney(lineItemDetails?.total_cost || 0)}</span>
          </div>
          {Boolean(refundData?.refund_status) && (
            <hr className="hr-line mt24 mb0 mx-0" />
          )}
        </div>
        {Boolean(refundData?.refund_status) && (
          <div className="order-total">
            {refundData?.refund_date && (
              <div>
                <span>Refund Date</span>
                <span>{formatDateTime(refundData.refund_date)}</span>
              </div>
            )}
            {refundData?.refund_arn && (
              <div>
                <span>Refund ARN/UTR</span>
                <span>{refundData.refund_arn}</span>
              </div>
            )}
            {refundData?.provider_refund_id && (
              <div>
                <span>Provider refund ID</span>
                <span>{refundData.provider_refund_id}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LineItemsRefundDetailsModal;
