import React, { useCallback } from 'react';
import { useAppContext } from '../../context/AppContext';
import { postRequest } from '../../utils/http';
import { CREATE_RAZORPAY_OAUTH_FLOW_ENDPOINT } from '../../utils/urls/payments/international-payments';
import ChangePaymentMethodDialog from './ChangePaymentMethodDialog';
import useModal from '../../hooks/useModal';
import {
  PAYMENT_KEY_LABELS,
  usePayments,
} from '../../context/PaymentsProvider';
import SpinnerButton from '../../shared/SpinnerButton';
import usePlanFeature, { PLAN_FEATURES } from '../../hooks/usePlanFeature';
import PremiumContentModal from '../../Layout/PremiumContentModal';

const RazorpaySetupForm = () => {
  const { business } = useAppContext();
  const { isOpen, openModal, closeModal } = useModal();
  const { paymentProvider } = usePayments();
  const { checkFeatureAllowed } = usePlanFeature();
  const { uuid } = business;

  const isAllowed = checkFeatureAllowed(PLAN_FEATURES.RAZORPAY_PAYMENT_GATEWAY);

  const {
    isOpen: isPremiumContentModalOpen,
    openModal: openPremiumContentModal,
    closeModal: closePremiumContentModal,
  } = useModal();

  const handleContinueClick = useCallback(() => {
    if (!isAllowed) {
      openPremiumContentModal();
      return;
    }
    postRequest({
      url: CREATE_RAZORPAY_OAUTH_FLOW_ENDPOINT(uuid),
    })
      .then((res) => {
        if (res.status === 'success') {
          window.location = res.data.oauth_uri;
        }
      })
      .catch(() => {});
  }, [uuid, isAllowed]);

  return (
    <>
      <SpinnerButton
        onClick={!!paymentProvider ? openModal : handleContinueClick}
      >
        Set up
      </SpinnerButton>
      <ChangePaymentMethodDialog
        title="Set up Razorpay?"
        content={
          <p className="c-black-3 text-2 mb28">
            Setting up Razorpay will deactivate your current payment provider (
            {PAYMENT_KEY_LABELS[paymentProvider]}) and all your new payments
            will be taken through Razorpay. <br /> <br /> Do you want to proceed
            and setup Razorpay?
          </p>
        }
        open={isOpen}
        closeModal={closeModal}
        onContinueClick={handleContinueClick}
        buttonLabel="Continue with set up"
        showSkip
      />
      <PremiumContentModal
        open={isPremiumContentModalOpen}
        closeModal={closePremiumContentModal}
      />
    </>
  );
};

export default RazorpaySetupForm;
