import { createContext, useContext, useState } from 'react';
import {
  ACCEPTED_STATUS,
  ACTIVE_ORDER_STATUS_OPTIONS,
  ACTIVE_ORDER_STATUS_OPTIONS_DYNAMIC_DB,
  CANCELLED_BY_CUSTOMER,
  CANCELLED_STATUS,
  DELIVERED_STATUS,
  FAILED_STATUS,
  MODIFIED_STATUS,
  PENDING_STATUS,
  REJECTED_STATUS,
  RETURN_STATUS,
  RTO_STATUS,
  SHIPPED_STATUS,
} from '../Orders/constants';
import { getRequest } from '../utils/http';
import { useAppContext } from './AppContext';
import useLocalStorage from '../hooks/useLocalStorage';
import { noop } from '../utils';
import {
  GET_STORE_RETURN_POLICY,
  ORDERS_STATS_WITH_DURATION_MAPPING_URL,
} from '../ApiUrls';

const DEFAULT_CONTEXT_VALUE = {
  isLoading: false,
  setIsLoading: noop,
  options: ACTIVE_ORDER_STATUS_OPTIONS,
  pendingOrdersWorth: 0,
  acceptedOrdersWorth: 0,
  pendingOrdersCount: 0,
  newOrdersCount: 0,
  fetchOrderStats: noop,
};

const OrderOptionsContext = createContext(DEFAULT_CONTEXT_VALUE);

export const useOrderOptions = () => useContext(OrderOptionsContext);

const OrderOptionsProvider = ({ children }) => {
  const { isLoggedIn, isStaff } = useAppContext();
  const [pendingOrdersCount, setPendingOrdersCount] = useLocalStorage(
    'pending_orders',
    null
  );
  const [newOrdersCount, setNewOrdersCount] = useLocalStorage(
    'new_order',
    null
  );
  const [allOrdersCount, setAllOrdersCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(ACTIVE_ORDER_STATUS_OPTIONS);
  const [pendingOrdersWorth, setPendingOrdersWorth] = useState(0);
  const [acceptedOrdersWorth, setAcceptedOrdersWorth] = useState(0);

  const [, setAllOrdersCountLocalStorage] = useLocalStorage('all_orders');
  const [
    isReturnReplacementEnabledBySeller,
    setIsReturnReplacementEnabledBySeller,
  ] = useState(false);

  const fetchOrderStats = () => {
    if (!isLoggedIn) return;
    setIsLoading(true);
    getRequest({
      url: ORDERS_STATS_WITH_DURATION_MAPPING_URL,
    })
      .then(({ data }) => {
        const allOrdersSum =
          data[PENDING_STATUS] +
          data[MODIFIED_STATUS] +
          data[ACCEPTED_STATUS] +
          data[SHIPPED_STATUS] +
          data[DELIVERED_STATUS] +
          data[CANCELLED_BY_CUSTOMER] +
          data[CANCELLED_STATUS] +
          data[REJECTED_STATUS] +
          data[FAILED_STATUS] +
          data[RETURN_STATUS] +
          data[RTO_STATUS];
        setAllOrdersCount(allOrdersSum);
        setAllOrdersCountLocalStorage(allOrdersSum);
        let newOptions;
        if (!isStaff) {
          newOptions = ACTIVE_ORDER_STATUS_OPTIONS_DYNAMIC_DB.map((opt) => {
            opt.count = data[opt.value] || 0;
            return opt;
          });
        } else {
          newOptions = ACTIVE_ORDER_STATUS_OPTIONS.map((opt) => {
            opt.count = data[opt.value] || 0;
            return opt;
          });
        }
        setPendingOrdersWorth(data?.pending_order_sum ?? 0);
        setAcceptedOrdersWorth(data?.accepted_order_sum ?? 0);
        setOptions(newOptions);
        setPendingOrdersCount(newOptions[0]?.count);
        setNewOrdersCount(data.new_order);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchStoreReturnPolicy = () => {
    getRequest({
      url: GET_STORE_RETURN_POLICY,
    })
      .then((res) => {
        const {
          replacements_enabled: replacementsEnabled,
          returns_enabled: returnsEnabled,
        } = res?.data ?? {};
        setIsReturnReplacementEnabledBySeller(
          replacementsEnabled || returnsEnabled
        );
      })
      .catch((e) => console.log({ e }));
  };

  const contextValue = {
    isLoading,
    setIsLoading,
    options,
    pendingOrdersWorth,
    acceptedOrdersWorth,
    pendingOrdersCount,
    newOrdersCount,
    fetchOrderStats,
    allOrdersCount,
    fetchStoreReturnPolicy,
    isReturnReplacementEnabledBySeller,
  };

  return (
    <OrderOptionsContext.Provider value={contextValue}>
      {children}
    </OrderOptionsContext.Provider>
  );
};

export default OrderOptionsProvider;
