import { useEffect, useState } from 'react';
import { connectSocket, JOIN_ROOM_V2 } from '../context/SocketProvider';
import { noop } from '../utils';
import { getRequest } from '../utils/http';
import { BACKGROUND_TASK_POLLING_URL } from '../ApiUrls';
import useLocalStorage from './useLocalStorage';

const EVENT_TYPE = {
  TASK_SUCCESS: 'task_success',
  TASK_ERROR: 'task_error',
  TASK_PROGRESS: 'task_progress',
};

const useSocketV2 = (
  successCb = noop,
  errorCb = noop,
  joinRoomEventName = JOIN_ROOM_V2
) => {
  const taskIds = JSON.parse(localStorage.getItem('local_task_id'));
  const [socket, setSocket] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [localTaskId, setLocalTaskId] = useLocalStorage(
    'local_task_id',
    taskIds ?? []
  );
  const [isEventFired, setIsEventFired] = useState(false);

  const socketFallback = (callback = noop) => {
    getRequest({
      url: BACKGROUND_TASK_POLLING_URL(taskId),
      data: {},
    })
      .then((res) => {
        const eventRes = res?.data?.client_events?.find((each) =>
          [EVENT_TYPE.TASK_ERROR, EVENT_TYPE.TASK_SUCCESS].includes(
            each?.event_type
          )
        );
        if (!isEventFired && taskId && eventRes) {
          callback(eventRes);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsEventFired(false));
  };

  const socketEventHandler = (
    { data, event_type: eventType },
    socketEventType
  ) => {
    if (socketEventType)
      console.log(`TaskId ${socketEventType} socket response ==> `, data);
    setIsEventFired(true);
    if ([eventType, socketEventType].includes(EVENT_TYPE.TASK_SUCCESS)) {
      successCb(data);
    } else if ([eventType, socketEventType].includes(EVENT_TYPE.TASK_ERROR)) {
      errorCb(data);
    }

    setTaskId(null);
  };

  useEffect(() => {
    let interval;
    if (taskId && localTaskId) {
      const newSocket = connectSocket(taskId, joinRoomEventName);

      /*
      poll immediately after API response to cover cases where
      task executes so quickly in backend, before the 
      frontend socket listener starts listening
      */
      socketFallback(socketEventHandler);

      newSocket.on(EVENT_TYPE.TASK_ERROR, (res) =>
        socketEventHandler(res, EVENT_TYPE.TASK_ERROR)
      );
      newSocket.on(EVENT_TYPE.TASK_SUCCESS, (res) =>
        socketEventHandler(res, EVENT_TYPE.TASK_SUCCESS)
      );

      /*
     fallback polling that runs every fixed number of seconds
     */
      interval = setInterval(() => {
        const res = socketFallback(socketEventHandler);
      }, 15000);

      setSocket(newSocket);
      console.log('Socket v2 connected', taskId);
    } else {
      if (socket) {
        console.log('Socket v2 disconnected');
        socket.disconnect();
      }
      setSocket(null);
      setIsEventFired(false);
      console.log('Socket v2 cleared');
    }
    return () => {
      if (socket) socket.disconnect();
      clearInterval(interval);
    };
  }, [taskId]);

  return { setTaskId, setLocalTaskId };
};

export default useSocketV2;
