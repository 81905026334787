import React from 'react';
import { Form } from 'react-final-form';
import Modal from '../../shared/Modal';

import SpinnerButton from '../../shared/SpinnerButton';
import { FormInput, Validation } from '../../shared/Form';
import { noop } from '../../utils';
import { ExternalLink } from '../../shared';
import { SHIPROCKET_API_LINK } from '../../Account/Sections/Shipping/constants';

const ConnectShiprocketModal = ({
  open = false,
  submitting = false,
  closeModal = noop,
  handleSubmit = noop,
}) => {
  return (
    <Modal open={open} closeModal={closeModal} paperClass="info-dialog">
      <Form onSubmit={noop} keepDirtyOnReinitialize initialValues={{}}>
        {({ invalid, values }) => (
          <>
            <p className="section-text-4 pb4">Connect Shiprocket API account</p>
            <p className="c-black-3 mb24">
              Please enter your Shiprocket API user details. You can find it on{' '}
              <ExternalLink
                href={SHIPROCKET_API_LINK}
                className="d-inline-flex"
              >
                <span className="c-purple-1 text-medium underline">
                  API configuration page
                </span>
              </ExternalLink>
            </p>
            <div>
              <FormInput
                labelText="User email address"
                name="username"
                placeholder="Enter email addresss"
                required
                showRequired
                validate={Validation.validateEmail()}
              />
              <FormInput
                labelText="Password"
                name="password"
                type="password"
                placeholder="Enter password"
                required
                showRequired
                validate={Validation.required()}
              />
            </div>
            <div className="mt24 text-center">
              <SpinnerButton
                disabled={invalid}
                isLoading={submitting}
                onClick={() => handleSubmit(values)}
              >
                Connect
              </SpinnerButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ConnectShiprocketModal;
