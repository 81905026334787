import { FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import CheckboxField from '../../../shared/CheckboxField';
import CustomRadio from '../../../shared/CustomRadio';
import Modal from '../../../shared/Modal';
import SpinnerButton from '../../../shared/SpinnerButton';
import { editAttributesPath } from '../../../UrlHelper';
import { noop } from '../../../utils';

const SelectAttributeModal = ({
  open,
  closeModal,
  options,
  name,
  selectedOptions = [],
  setSelectedOptions = noop,
  onSelectClick = noop,
  metafieldId = '',
  isRadioSelect = false,
}) => {
  const history = useHistory();
  const isChecked = (option) => selectedOptions.some((item) => item === option);

  const onChange = (option) => {
    if (isRadioSelect) {
      setSelectedOptions([option]);
    } else {
      let temp = [...selectedOptions];
      if (isChecked(option)) {
        temp = temp.filter((item) => item !== option);
        setSelectedOptions(temp);
      } else {
        temp.push(option);
        setSelectedOptions(temp);
      }
    }
  };

  return (
    <Modal open={open} closeModal={closeModal}>
      <h2 className="section-text-4">Select options</h2>
      <hr className="hr-line mt16 mb12" />
      <div className="attribute-list-modal-options">
        {options.map((option) => (
          <div className="d-flex space-between">
            <p className="c-black-1 text-2">{option}</p>
            {isRadioSelect ? (
              <FormControlLabel
                control={<CustomRadio disabled={false} />}
                checked={isChecked(option)}
                disabled={false}
                onChange={() => onChange(option)}
                style={{ margin: 0 }}
              />
            ) : (
              <Field name={`checkbox-${name}`} type="checkbox">
                {(fieldProps) => (
                  <CheckboxField
                    {...fieldProps.input}
                    onChange={() => onChange(option)}
                    checked={isChecked(option)}
                    disabled={false}
                  />
                )}
              </Field>
            )}
          </div>
        ))}
      </div>
      <hr className="hr-line mt8 mb16 " />
      <SpinnerButton
        className="btn btn-outline-4 text-medium btn-outline-warning full-w"
        onClick={() =>
          history.push({
            pathname: editAttributesPath(metafieldId),
            state: { fromProductPage: true },
          })
        }
        showAnimation
      >
        Edit or add options
      </SpinnerButton>
      <div className="text-center mt24">
        <SpinnerButton
          className="btn-primary-4"
          onClick={() => onSelectClick(selectedOptions)}
          showAnimation
        >
          Select
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default SelectAttributeModal;
