import { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Menu, MenuItem } from '@material-ui/core';
import cx from 'classnames';
import { Field } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { FormGroup } from '../../../shared/Form';
import { PRODUCT_WEIGHTS, PRODUCT_WEIGHTS_OPTIONS } from '../../constants';
import { noop } from '../../../utils';
import { DownArrow16DarkIcon } from '../../../SvgIcon';
import InputDisabledTooltip from './InputDisabledTooltip';
import { isNotEmptyOrNull } from '../../../shared/Form/Shared';

const ProductWeightInput = ({
  form,
  values,
  value,
  name = 'weight',
  unitName = 'weight_unit',
  labelText = 'Product Weight',
  className = '',
  disabled = false,
  showAddedVariantsTooltip = true,
  setToMinOfSKUS = false,
  initialValue = null,
  defaultUnit = 'kg',
  required = false,
  index,
  localVariants,
  setLocalVariants,
  lg = false,
  sm = false,
  isAdvance,
}) => {
  const [weightUnit, setWeightUnit] = useState(
    defaultUnit || PRODUCT_WEIGHTS[0]
  );

  const dropdownRef = useRef(null);

  const [isWeightInputFocused, setIsWeightInputFocused] = useState(false);
  const [weightValue, setWeightValue] = useState(initialValue);
  const [minVariant, setMinVariant] = useState({
    volumetric_weight: initialValue,
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    let minVar = {
      volumetric_weight: initialValue,
    };
    const variantsArray = JSON.parse(JSON.stringify(values?.skus || []));
    (variantsArray || []).forEach((variant, i) => {
      if (
        !isNotEmptyOrNull({ value: variant?.original_price }) &&
        (variant?.selling_price === '' ||
          variant?.selling_price === null ||
          variant?.selling_price === undefined)
      ) {
        variantsArray[i].selling_price = variant?.original_price;
      }
    });
    if (variantsArray?.length > 1) {
      minVar = variantsArray.reduce((prevVal, curVal) =>
        Number(curVal?.selling_price) < Number(prevVal?.selling_price)
          ? curVal
          : prevVal
      );
    } else if (variantsArray?.length === 1) {
      minVar = { ...variantsArray[0] };
    }
    if (lg && minVar.unit) {
      setWeightUnit(minVar.unit);
    }
    setMinVariant(minVar);
  }, [values.skus]);

  useEffect(() => {
    form.mutators.modifyFormField(unitName, weightUnit);
    if (localVariants) {
      const localVariantsTemp = localVariants;
      localVariantsTemp[index].unit = weightUnit;
      setLocalVariants(localVariantsTemp);
    }
  }, [weightUnit]);

  useEffect(() => {
    form.mutators.modifyFormField(name, weightValue);
    if (localVariants) {
      const localVariantsTemp = localVariants;
      localVariantsTemp[index].volumetric_weight = weightValue;
      setLocalVariants(localVariantsTemp);
    }
  }, [weightValue]);

  const useStyles = makeStyles(() =>
    createStyles({
      tooltipPlacementBottom: {
        marginTop: '-36px !important',
        marginLeft: isAdvance ? '-380px !important' : '-330px !important',
      },
    })
  );

  const styles = useStyles();

  const getWeight = () => {
    if (disabled && lg) return minVariant.volumetric_weight || values.weight;
    return initialValue;
  };

  return (
    <>
      <FormGroup
        labelText={labelText}
        size="md"
        className={cx('form-weight-input-group', className)}
      >
        <InputDisabledTooltip
          show={showAddedVariantsTooltip && disabled}
          widthFull={false}
          title="Since you have added variants, you can only edit this field in the variants section below."
          tooltipProps={{
            classes: {
              tooltipPlacementBottom: styles.tooltipPlacementBottom,
            },
          }}
        >
          <div
            className={cx('form-weight-input-wrap', {
              focused: isWeightInputFocused,
              lg,
              sm,
            })}
          >
            <Field name={name}>
              {({ input }) => {
                const handleChange = ({ floatValue }) => {
                  setWeightValue(floatValue);
                };

                return (
                  <NumberFormat
                    {...input}
                    autoComplete="off"
                    onFocus={() => setIsWeightInputFocused(true)}
                    onBlur={() => setIsWeightInputFocused(false)}
                    onValueChange={handleChange}
                    placeholder="Eg. 1.2"
                    isAllowed={(val) => {
                      const { floatValue } = val;
                      return floatValue === undefined || floatValue > 0;
                    }}
                    disabled={disabled}
                    value={getWeight()}
                  />
                );
              }}
            </Field>
            <div
              className={cx('weight-input btn-no-default d-flex-c-s', {
                disabled,
              })}
              ref={dropdownRef}
              onClick={
                disabled ? noop : (e) => setMenuAnchorEl(e.currentTarget)
              }
            >
              <span className="weight-label">{weightUnit}</span>
              <DownArrow16DarkIcon className="mr12" />
            </div>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              PaperProps={{
                style: {
                  transform: 'translateX(1%) translateY(100%)',
                  width: dropdownRef?.current?.offsetWidth ?? 0,
                  height: '81px',
                },
              }}
            >
              {PRODUCT_WEIGHTS_OPTIONS.map((option) => (
                <MenuItem
                  onClick={() => {
                    setMenuAnchorEl(null);
                    setWeightUnit(option.value);
                  }}
                  selected={weightUnit === option.value}
                >
                  <div
                    className={cx('weight-unit-menu-item', {
                      active: weightUnit === option.value,
                    })}
                  >
                    {option.label}
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </InputDisabledTooltip>
      </FormGroup>
    </>
  );
};

export default ProductWeightInput;
