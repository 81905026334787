import React from 'react';
import ConfirmModal from '../shared/ConfirmModal';
import { noop } from '../utils';

const OrderConfirmationModal = (props) => {
  const {
    open,
    handleConfirm,
    closeModal,
    headerText,
    content,
    icon,
    confirmBtnClass,
    confirmText,
    showAnimation,
    showCloseIcon = true,
    ...restProps
  } = props;

  return (
    <ConfirmModal
      open={open}
      onConfirm={handleConfirm}
      onCancel={closeModal}
      svgIcon={icon}
      confirmBtnClass={confirmBtnClass}
      headerText={headerText}
      content={content}
      hideCancel
      confirmText={confirmText}
      showCloseIcon={showCloseIcon}
      showAnimation={showAnimation}
      {...restProps}
    />
  );
};

OrderConfirmationModal.defaultProps = {
  open: false,
  closeModal: noop,
  headerText: 'Confirm Action',
  content: 'Are you sure?',
  buttonText: 'Yes, confirm it',
  hideCancel: true,
  showAnimation: true,
};

export default OrderConfirmationModal;
