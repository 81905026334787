import moment from 'moment';
import { formatServerDate } from '../../utils/date';

export const DATE_RANGE_OPTIONS_NAME_MAP = {
  lifetime: 'Lifetime',
  today: 'Today',
  yesterday: 'Yesterday',
  this_week: 'This week',
  last_7_days: 'Last 7 days',
  last_week: 'Last week',
  this_month: 'This Month',
  last_30_days: 'Last 30 days',
  last_month: 'Last Month',
  custom_range: 'Custom range',
};

export const DATE_RANGE_OPTIONS = (maxDate) => {
  let endOfMonth = moment().endOf('month');
  if (maxDate && endOfMonth > maxDate) {
    endOfMonth = moment(maxDate);
  }
  let endOfWeek = moment().endOf('isoWeek');
  if (maxDate && endOfWeek > maxDate) {
    endOfWeek = moment(maxDate);
  }

  return [
    {
      name: 'Lifetime',
      value: 'lifetime',
      range: [undefined, undefined],
    },
    {
      name: 'Today',
      value: 'today',
      range: [moment(), moment()],
    },
    {
      name: 'Yesterday',
      value: 'yesterday',
      range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    },
    {
      name: 'This Week',
      value: 'this_week',
      range: [moment().startOf('isoWeek'), endOfWeek],
    },
    {
      name: 'Last 7 days',
      value: 'last_7_days',
      range: [moment().subtract(7, 'days'), moment()],
    },
    {
      name: 'Last Week',
      value: 'last_week',
      range: [
        moment().subtract(1, 'isoWeek').startOf('isoWeek'),
        moment().subtract(1, 'isoWeek').endOf('isoWeek'),
      ],
    },
    {
      name: 'This Month',
      value: 'this_month',
      range: [moment().startOf('month'), endOfMonth],
    },
    {
      name: 'Last 30 days',
      value: 'last_30_days',
      range: [moment().subtract(30, 'days'), moment()],
    },
    {
      name: 'Last Month',
      value: 'last_month',
      range: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
    {
      name: 'Custom Range',
      value: 'custom_range',
    },
  ];
};

export const DEFAULT_DATE_RANGE = {
  LABEL: 'Last 30 days',
  SELECTION: 'last_30_days',
  START_DATE: formatServerDate(moment().subtract(30, 'days')),
  END_DATE: formatServerDate(moment()),
};

export const LIFETIME_DATE_RANGE = {
  LABEL: 'Lifetime',
  SELECTION: 'lifetime',
  START_DATE: undefined,
  END_DATE: undefined,
}
export const THIS_MONTH_DATE_RANGE = {
  LABEL: 'This Month',
  SELECTION: 'this_month',
  START_DATE: formatServerDate(moment().startOf('month')),
  END_DATE: formatServerDate(moment()),
};
