import { Popover } from '@material-ui/core';
import { LocalizationProvider, StaticDatePicker } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CalendarLeftArrowIcon,
  CalendarRightArrowIcon,
  CrossIcon,
} from '../../SvgIcon';
import { noop } from '../../utils';
import { formatDate, momentDate, SERVER_FORMAT } from '../../utils/date';
import SpinnerButton from '../SpinnerButton';
import DateInputFieldWrap from './DateInputFieldWrap';

const CustomDatePicker = ({
  datePickerProps = {},
  defaultDate = '',
  disabled = false,
  inputFormat = 'DD MMM, YYYY ',
  maxDate = new Date('2030-1-1'),
  minDate = new Date('1970-1-1'),
  onDateChange = noop,
  onPopperClose = noop,
  placeholder = '',
  prependText = '',
  readOnly = false,
  serverFormat = SERVER_FORMAT,
}) => {
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputDate, setInputDate] = useState(
    defaultDate ? formatDate(defaultDate, inputFormat) : ''
  );
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const closePopper = useCallback(() => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  }, [anchorEl]);

  const setDate = (date) => {
    setSelectedDate(date);
    onDateChange(date ? formatDate(date, serverFormat) : '');
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleOnInputBlur = () => {
    if (inputDate) {
      const md = momentDate(inputDate);
      if (md.isValid()) {
        const minMd = minDate ? momentDate(minDate) : null;
        const maxMd = maxDate ? momentDate(maxDate) : null;
        let isValid = true;
        if (minMd && md < minMd) {
          isValid = false;
        }
        if (maxMd && md > maxMd) {
          isValid = false;
        }
        if (isValid) {
          const newSelectedDate = md.toDate();
          setDate(newSelectedDate);
        } else {
          setDate(selectedDate);
        }
      } else {
        setDate(selectedDate);
      }
    } else {
      setDate('');
    }
  };

  const handleOnInputChange = (event) => {
    const { value } = event.target;
    setInputDate(value);
  };

  const handleSubmit = () => {
    const date = selectedDate ? formatDate(selectedDate, inputFormat) : '';
    setInputDate(date);
    closePopper();
    onPopperClose(date);
  };

  const openPopper = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (!disabled) {
        setAnchorEl(event.currentTarget);
        if (inputRef) {
          inputRef.current.focus();
        }
      }
    },
    [disabled]
  );

  useEffect(
    () => () => {
      closePopper();
    },
    [closePopper]
  );

  useEffect(() => {
    setInputDate(defaultDate ? formatDate(defaultDate, inputFormat) : '');
  }, [defaultDate]);

  const isPopperOpen = Boolean(anchorEl);

  const classes = cx('py12 px16', { disabled });
  const pickerProps = {
    minDate,
    maxDate,
    disabled,
    ...datePickerProps,
  };

  return (
    <>
      <DateInputFieldWrap className={classes} onClick={openPopper}>
        {prependText && (
          <span className="prepend-text mr8 pt4">{prependText}</span>
        )}
        <input
          className="cur-p padding-0"
          disabled={disabled}
          onBlur={handleOnInputBlur}
          onChange={handleOnInputChange}
          placeholder={placeholder}
          readOnly
          ref={inputRef}
          type="tel"
          value={inputDate}
        />
      </DateInputFieldWrap>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={closePopper}
          open={isPopperOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="custom-day-picker">
            <div className="custom-day-header">
              <span className="section-text-5">Select date</span>
              <CrossIcon
                onClick={closePopper}
                className="cur-p"
                width={16}
                height={16}
              />
            </div>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <StaticDatePicker
                autoOk
                disableOpenPicker
                displayStaticWrapperAs="desktop"
                inputFormat={inputFormat}
                leftArrowIcon={<CalendarLeftArrowIcon />}
                onChange={handleDateChange}
                open
                readOnly={readOnly}
                rightArrowIcon={<CalendarRightArrowIcon />}
                showToolbar={false}
                value={selectedDate}
                {...pickerProps}
              />
            </LocalizationProvider>
            <div className="date-and-btn-display-wrap">
              <span className="display-date-wrap">
                {formatDate(selectedDate)}
              </span>
              <SpinnerButton
                type="button"
                className="btn-primary-4"
                disabled={!selectedDate}
                onClick={handleSubmit}
              >
                Select date
              </SpinnerButton>
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};
export default CustomDatePicker;
