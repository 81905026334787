import axios from 'axios';
import { useState } from 'react';
import { DOWNLOAD_FILE_URL } from '../ApiUrls';
import { downloadFileByUrl, noop } from '../utils';
import { getAuthToken } from '../utils/user';

const useFileDownload = () => {
  const [downloading, setDownloading] = useState(0);
  const downloadFile = async (fileUrl, isPrivate = false, cb = noop) => {
    if (isPrivate) {
      try {
        setDownloading((pre) => pre + 1);
        const response = await axios.post(
          DOWNLOAD_FILE_URL,
          { file_url: fileUrl },
          {
            headers: { Authorization: `Bearer ${getAuthToken()}` },
          }
        );
        const link = response?.data?.data?.presigned_url;
        downloadFileByUrl(link, true);

        setDownloading((pre) => pre - 1);
        cb();
      } catch (e) {
        setDownloading((pre) => pre - 1);
        return null;
      }
    } else {
      downloadFileByUrl(fileUrl);
    }

    return downloading;
  };

  return {
    downloadFile,
    downloading,
  };
};

export default useFileDownload;
