import React, { useState } from 'react';
import cx from 'classnames';

import Modal from '../shared/Modal';
import { noop, stickyTopStyle } from '../utils';
import SpinnerButton from '../shared/SpinnerButton';
import NonServiceableOrdersTable from './components/NonServiceableOrdersTable';
import useOrders from '../hooks/useOrders';
import { PENDING_STATUS, SHIPPED_STATUS } from './constants';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import { deepClone } from '../Appearance/utils';
import useEnterPressAction from '../hooks/useEnterPressAction';

export const NoOrdersTable = ({
  ordersCount = 0,
  title,
  children,
  center = false,
}) => {
  if (ordersCount <= 0) {
    return (
      <div
        className={cx('d-flex-c-c', {
          'absolute-center': center,
        })}
        style={{ height: 300 }}
      >
        <h4 className="section-text-3 ">
          You don't have any {title.toLowerCase()}.
        </h4>
      </div>
    );
  }
  return <div>{children}</div>;
};

const PendingOrdersModal = ({
  open,
  closeModal,
  onSubmit = noop,
  title = 'Pending orders',
  btnText = 'Change status to Accepted',
  status = PENDING_STATUS,
  btnClass = 'btn-success-4',
  selectedOrders = {},
  setSelectedOrders,
  unselectedOrders = {},
  setUnselectedOrders,
  isAllOrderChecked,
  setAllOrderChecked,
}) => {
  const params = { status };
  if (status === SHIPPED_STATUS) params.self_ship = true;
  const { loading, pageSize, setPageNumber, pageNumber, orders, options } =
    useOrders(params);
  // const [selectedOrders, setSelectedOrders] = useState({});
  // const [unselectedOrders, setUnselectedOrders] = useState({});
  // const [isAllOrderChecked, setAllOrderChecked] = useState(true);
  const selectedOrdersLength = Object.keys(selectedOrders).length;
  const unselectedOrdersLength = Object.keys(unselectedOrders).length;
  const totalStatusOrdersCount = options.find(
    (option) => option.value === status
  )?.count;

  const totalPages = Math.ceil(totalStatusOrdersCount / pageSize);

  const hasMore = pageNumber < totalPages;

  let selectedCount = selectedOrdersLength;
  if (isAllOrderChecked) {
    selectedCount =
      totalStatusOrdersCount === selectedOrdersLength ||
      selectedOrdersLength === orders.length
        ? totalStatusOrdersCount
        : totalStatusOrdersCount - unselectedOrdersLength;
  }

  const handleBtnClick = () => {
    onSubmit(
      Object.keys(deepClone(selectedOrders)),
      Object.keys(deepClone(unselectedOrders)),
      totalStatusOrdersCount,
      isAllOrderChecked
    );
    // setSelectedOrders({});
    // setUnselectedOrders({});
    // setAllOrderChecked(true);
  };

  useEnterPressAction(open, selectedOrdersLength === 0 ? noop : handleBtnClick);

  return (
    <Modal
      open={open}
      className="shipment-unserviceable-modal"
      closeModal={() => {
        setSelectedOrders([]);
        closeModal();
      }}
      paperClass="xl"
    >
      <div className="d-flex align-center">
        <p className="section-text-4 mr16">
          {title} {totalStatusOrdersCount > 0 && ` (${totalStatusOrdersCount})`}
        </p>
        {selectedCount > 0 && (
          <span>
            {selectedCount} order{selectedCount > 1 ? 's' : ''} selected
          </span>
        )}
      </div>
      {loading && !orders.length && (
        <div style={{ minHeight: 300, width: '100%' }} />
      )}
      <BounceLoaderContainer isLoading={loading && !orders.length}>
        <NoOrdersTable ordersCount={orders.length} title={title}>
          <div className={cx('row mt12 shipment-unserviceable-container')}>
            <NonServiceableOrdersTable
              ignoreRowClick
              openOrdersInNewTab
              columns={[
                {
                  title: 'Order ID',
                  hasCheckbox: true,
                },
                {
                  title: 'Date',
                  key: 'created_at',
                },
                {
                  title: 'Customer',
                },
                {
                  title: 'Items',
                  key: 'product_count',
                },
                {
                  title: 'Payment',
                },
                {
                  title: 'Status',
                },
                {
                  title: 'Amount',
                  key: 'total_cost',
                },
              ]}
              data={orders}
              tableClass="w-100"
              showBuyerAddress
              loading={loading}
              hasMore={hasMore}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              columnStyle={stickyTopStyle(0)}
              preselectOrders
              ordersCount={totalStatusOrdersCount}
              selectedSelfShipMap={selectedOrders}
              setSelectedSelfShipMap={setSelectedOrders}
              unselectedSelfShipMap={unselectedOrders}
              setUnselectedSelfShipMap={setUnselectedOrders}
              orderSelectKey="id"
              isAllOrderChecked={isAllOrderChecked}
              setAllOrderChecked={setAllOrderChecked}
            />
          </div>
          <div className="flex-center pt16">
            <SpinnerButton
              showAnimation
              className={btnClass}
              disabled={selectedOrdersLength === 0}
              onClick={handleBtnClick}
            >
              {btnText}
            </SpinnerButton>
            {status === PENDING_STATUS && (
              <p className="pt8 c-black-3">
                <span className="text-medium">Note:</span> Unpaid manual orders
                will be ignored in this bulk action.
              </p>
            )}
          </div>
        </NoOrdersTable>
      </BounceLoaderContainer>
    </Modal>
  );
};

export default PendingOrdersModal;
