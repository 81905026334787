import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SelectStoreList from '../shared/SelectStoreList';
import Modal from '../shared/Modal';
import { authUrls } from '../Urls';
import { useAppContext } from '../context/AppContext';

const SelectStoreModal = ({ open, closeModal }) => {
  const { sellerLimits, isOwner, business, stores } = useAppContext();
  const [filteredStores, setFilteredStores] = useState([]);

  useEffect(() => {
    if (stores.length) {
      setFilteredStores(
        stores.filter((item) => item.seller === business.seller)
      );
    }
  }, [stores]);

  return (
    <Modal
      open={open}
      maxWidth="xm"
      paperClass="select-store-modal"
      closeModal={closeModal}
    >
      <div className="select-store-modal-container">
        <p className="section-text-4 pb8">
          Choose your store{' '}
          {filteredStores.length > 0 &&
            (!isOwner ||
            sellerLimits?.isUnlimitedPlan ||
            !sellerLimits?.isMultiStoreFlow
              ? ''
              : `(${filteredStores.length}/${sellerLimits.max_limit})`)}
        </p>
        {sellerLimits?.used_limit < sellerLimits?.max_limit && isOwner && (
          <Link
            to={authUrls.registerPath}
            className="anchor-1 underline section-text-5"
          >
            or create a new store
          </Link>
        )}
        <div className="mt24">
          <SelectStoreList />
        </div>
      </div>
    </Modal>
  );
};

export default SelectStoreModal;
