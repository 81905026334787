import { useEffect } from 'react';
import { noop } from '../utils';

const useScript = (scriptUrl, callback = noop, isAsync = false) => {
  useEffect(() => {
    const existingScript = document.getElementById(scriptUrl);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.id = scriptUrl;
      script.async = isAsync;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) {
          callback();
        }
      };

      if (existingScript && callback) {
        callback();
      }

      return () => {
        if (existingScript && callback) {
          existingScript.remove();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptUrl]);
};

export default useScript;
