import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FormInput } from '../../shared/Form';
import { validateEmail } from '../../shared/Form/Validation';
import { postRequest } from '../../utils/http';
import { SAVE_PAYPAL_PG_KEY_API_ENDPOINT } from '../../utils/urls/payments/international-payments';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import { usePayments } from '../../context/PaymentsProvider';

const PaypalSetupForm = ({
  isActivated,
  successCallback = noop,
  errorCallback = noop,
}) => {
  const { fetchBusinessDetails } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { paypalKey } = usePayments();
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: SAVE_PAYPAL_PG_KEY_API_ENDPOINT,
      data: values,
    })
      .then(() => {
        enqueueSnackbar('Paypal linked successfully');
        successCallback();
        fetchBusinessDetails();
      })
      .catch((err) => {
        errorCallback(err);
        enqueueSnackbar(
          'Something went wrong. Please try again after some time.',
          'error'
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ paypal_email: paypalKey?.public_key ?? '' }}
      render={({ values, handleSubmit, invalid }) => (
        <form className="mt24">
          <FormInput
            labelText="Paypal email"
            placeholder="Enter email"
            name="paypal_email"
            required
            showRequired
            validate={validateEmail()}
          />
          <div className="text-center">
            <SpinnerButton
              type="submit"
              isLoading={submitting}
              className="btn-primary-4 mt12"
              onClick={handleSubmit}
              disabled={
                isActivated
                  ? invalid || values?.paypal_email === paypalKey?.public_key
                  : invalid
              }
            >
              {isActivated ? 'Update' : 'Set up'}
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default PaypalSetupForm;
