import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { PRODUCT_DESC_MAX_COUNT } from '../../../Catalogue/constants';
import { getCharecterCount } from '../../../shared';
import { FormInput, FormTextArea } from '../../../shared/Form';
import Modal from '../../../shared/Modal';
import ToggleButtonGroup from '../../../shared/ToggleButtonGroup';
import SpinnerButton from '../../../shared/SpinnerButton';
import { isNotEmptyOrNull, modifyFormField } from '../../../shared/Form/Shared';
import { noop } from '../../../utils';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import { Editor } from '../../../shared/Editor';
import { handleGetFieldValue } from './utils';

const UpdateLanguageDetailsModal = ({
  open,
  closeModal,
  options,
  currentLanguage = 'English',
  topLevelFormValues,
  topLevelForm,
  isSubmitting = false,
  isNew = false,
  title = 'Update product details',
  modalDescription = 'Update the product name and description in your store languages.',
  handleEditSubmit = noop,
  isCategory = false,
  primaryLanguage = {},
  topLevelFormDescription = '',
  setTopLevelFormDescription = noop,
  languageData = [],
  setLanguageData = noop,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [currentTab, setCurrentTab] = useState(currentLanguage);
  const { enqueueSnackbar } = useCustomSnackbar();

  const onSubmit = (values) => {
    let hasDescriptionCountExceeded = false;
    Object.keys(values).forEach((item) => {
      const descriptionCount = getCharecterCount(
        values[item].description || ''
      );
      if (descriptionCount > PRODUCT_DESC_MAX_COUNT) {
        hasDescriptionCountExceeded = true;
      }
    });

    if (hasDescriptionCountExceeded) {
      enqueueSnackbar(
        'Please reduce product description, content length exceeded!',
        'error'
      );
      return;
    }

    const filteredFormPayload = Object.keys(values).filter(
      (ele) => values[ele].name !== '' || values[ele].description !== ''
    );
    const formPayload = filteredFormPayload.map((item) => ({
      store_language: values[item].languageId,
      fields: [
        ...(isNotEmptyOrNull({ value: values[item]?.name })
          ? [
              {
                field_name: 'name',
                field_value: values[item]?.name,
              },
            ]
          : []),

        ...(isNotEmptyOrNull({ value: values[item]?.description })
          ? [
              {
                field_name: 'description',
                field_value: values[item]?.description,
              },
            ]
          : []),
      ],
    }));

    const nameInPrimaryLanguage = values[primaryLanguage.language.name].name;
    const descInPrimaryLanguage =
      values[primaryLanguage.language.name].description;

    const payload = {
      language_data: formPayload,
    };
    if (isNew) {
      topLevelForm.mutators.modifyFormField('language_data', formPayload);
      setLanguageData(formPayload);
      topLevelForm.mutators.modifyFormField('name', nameInPrimaryLanguage);
      setTopLevelFormDescription(
        values[primaryLanguage.language.name].description
      );
      enqueueSnackbar(
        `${isCategory ? 'Category' : 'Product'} details updated successfully.`
      );
      closeModal();
    } else {
      handleEditSubmit(
        payload,
        nameInPrimaryLanguage,
        descInPrimaryLanguage,
        values
      );
    }
  };

  useEffect(() => {
    setCurrentTab(currentLanguage);
  }, []);

  useEffect(() => {
    const languageObj = {};
    let alreadyPresentLanguage = null;
    options.forEach((item) => {
      if (!isNew) {
        alreadyPresentLanguage = topLevelFormValues?.languages?.find(
          (language) => language.language.store_language === item.id
        );
      }

      if (alreadyPresentLanguage) {
        const objTemp = {};

        alreadyPresentLanguage.fields.forEach(
          (el) => (objTemp[el.field_name] = el.field_value)
        );

        languageObj[item.label] = {
          languageName: item.label,
          languageId: item.id,
          ...objTemp,
        };
      } else {
        languageObj[item.label] = {
          languageName: item.label,
          languageId: item.id,
          name: handleGetFieldValue(
            topLevelFormValues,
            languageData,
            item,
            'name'
          ),
          description: handleGetFieldValue(
            topLevelFormValues,
            languageData,
            item,
            'description'
          ),
        };
      }
    });

    languageObj[primaryLanguage.language.name] = {
      languageName: primaryLanguage.language.name,
      languageId: primaryLanguage.id,
      name: topLevelFormValues?.name ?? '',
      description: isNotEmptyOrNull({ value: topLevelFormValues?.description })
        ? topLevelFormValues?.description ?? ''
        : topLevelFormDescription,
    };
    setInitialValues(languageObj);
  }, [open, topLevelFormValues]);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      paperClass="md-lg update-language-details-modal"
    >
      <>
        <h2 className="section-text-4 mb4">{title}</h2>
        <p className="text-2 c-black-3">{modalDescription}</p>
      </>
      <hr className="hr-line mt16 mb16" />

      <>
        <Form
          onSubmit={onSubmit}
          mutators={{ modifyFormField }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ values, form, invalid, handleSubmit }) => (
            <>
              <div className="show-scrollbar scroll-container">
                <ToggleButtonGroup
                  options={options}
                  onChange={(tab) => {
                    setCurrentTab(tab);
                  }}
                  className="mb24"
                  defaultSelected={currentLanguage}
                />
                {options.map((item) => {
                  const descriptionCount = getCharecterCount(
                    values[item.label].description
                  );
                  return (
                    <>
                      <div
                        className={cx({ hidden: currentTab !== item.label })}
                      >
                        <FormInput
                          labelText={`${
                            isCategory ? 'Category' : 'Product'
                          } Name`}
                          name={`${item.label}.name`}
                          maxLength={isCategory ? 30 : 200}
                          placeholder={`Enter ${
                            isCategory ? 'Category' : 'Product'
                          } name`}
                          showCharLimit
                        />
                        <div className="mb8 d-flex-c-s">
                          <div className="text-8 c-black-3 mb8">
                            {`${
                              isCategory ? 'Category' : 'Product'
                            } Description`}
                          </div>
                          <FormTextArea
                            id={`product_desc_modal-${item.label}`}
                            rows={3}
                            value={values[item.label].description}
                            name={`${item.label}.description`}
                            className="mb0"
                          />
                        </div>
                        <div className="language-ckeditor">
                          <Editor
                            data={values[item.label].description}
                            onChange={(val) => {
                              form.mutators.modifyFormField(
                                `${item.label}.description`,
                                val
                              );
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <hr className="hr-line mt16 mb16" />
              <div className="text-center">
                <SpinnerButton
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  disabled={
                    JSON.stringify(initialValues) === JSON.stringify(values)
                  }
                >
                  Save
                </SpinnerButton>
              </div>
            </>
          )}
        />
      </>
    </Modal>
  );
};

export default UpdateLanguageDetailsModal;
