import React, { createContext, useCallback, useContext, useState } from 'react';
import moment from 'moment';

import {
  ANALYTICS_BUSINESS_OVERVIEW,
  ANALYTICS_PRODUCT_OVERVIEW,
  ANALYTICS_OVERVIEW,
  ANALYTICS_STORE_AVG_SESSIONS,
  ANALYTICS_STORE_AVG_VIEWS,
  ANALYTICS_STORE_RETURNING_BUYERS,
  ANALYTICS_STORE_VIEWS,
  ANALYTICS_CATEGORY_OVERVIEW,
  ANALYTICS_PRODUCT_VIEW_DATA,
  ANALYTICS_CATEGORY_BY_SALES,
  ANALYTICS_TOP_PRODUCTS_BY_UNITS_SOLD,
  CUSTOMER_COUNT_URL,
  ANALYTICS_SALES_BREAKDOWN_API,
  ANALYTICS_STORE_CONVERSION,
  ANALYTICS_AVG_STORE_SESSIONS,
  ANALYTICS_UTM_WISE_SESSIONS,
} from '../ApiUrls';
import { getRequest } from '../utils/http';
import { noop } from '../utils';
import useAnalyticsHelper from '../hooks/useAnalyticsHelper';
import { useAppContext } from './AppContext';
import { DEFAULT_DATE_RANGE } from '../shared/DatePicker/util';

const AnalyticsDataContext = createContext({
  isLoading: false,
  analyticsOverviewData: [],
  totalOrdersOverviewData: {},
  totalOrdersGraphData: [],
  totalSalesOverviewData: {},
  totalSalesGraphData: [],
  conversionData: {},
  regionSalesChart: {},
  ordersSalesTableData: [],
  frequency: 'monthly',
  businessForwardedDateParams: { startDate: '', endDate: '' },
  sessionDayWiseOverviewData: {},
  sessionDayWiseGraphData: [],
  storeOverviewData: [],
  storeSessionsChartData: {},
  storeViewsOverviewData: {},
  storeViewsGraphData: [],
  storeViewsTableData: [],
  sessionsByPageChart: {},
  sessionsByPageComparisonData: {},
  sessionsByPageTable: [],
  isLoadingAnalyticsOverviewData: false,
  isOrdersSalesDataLoading: false,
  isCoversionDataLoading: false,
  isRegionsDataLoading: false,
  isTrafficSourceDataLoading: false,
  isUtmDataLoading: false,
  isStoreOverviewStatsLoading: false,
  isStoreViewsGraphViewLoading: false,
  isProductOverviewDataLoading: false,
  isProductViewsReportLoading: false,
  isCategoryBySalesLoading: false,
  isTopProductsByUnitsLoading: false,
  productOverviewData: [],
  salesByTrafficSourceTableData: {},
  showErrorState: false,
  orderingKeyAnalytics: '',
  orderingDirectionAnalytics: '',
  isSalesBreakdownDataLoading: false,
  salesBreakdownData: [],
  showErrorStateSalesStats: false,
  fetchOrdersSalesStats: noop,
  fetchTotalOrdersSalesData: noop,
  fetchConvertionGraphData: noop,
  fetchRegionsData: noop,
  fetchTrafficSourceData: noop,
  fetchOrdersSalesTableData: noop,
  setBusinessForwardedDateParams: noop,
  fetchStoreOverviewStats: noop,
  fetchStoreViewsGraphView: noop,
  fetchStoreViewsTableData: noop,
  fetchStoreSessionsTableData: noop,
  fetchSalesByProduct: noop,
  fetchTopCategoryBySales: noop,
  fetchTopCategoryBySalesTable: noop,
  fetchTrafficSourceTableData: noop,
  onSortAnalytics: noop,
  onSortSessionsByPage: noop,
  resetOrderingDirection: noop,
  fetchSalesBreakdownData: noop,
});

export const useAnalyticsData = () => useContext(AnalyticsDataContext);

const AnalyticsProvider = ({ children }) => {
  const {
    getFormatedTotalOrdersData,
    getFormatedTotalSalesData,
    getConversionsFormattedData,
    getFormatedRegionSalesChart,
    getOverviewFormatedData,
    getFormatedTrafficSourceSalesChart,
    getFormatedOrdersTableData,
    getFormattedRegionsTableData,
    formatDataForGraph,
    getFormattedSessionsOverviewData,
    getStoreOverviewFormatedData,
    getSessionByDevicePieData,
    getFormattedStoreViewsOverviewData,
    getFormattedSessionsByPageChart,
    getFormattedSessionsByPageTableData,
    getFormattedStoreViewsTableData,
    getAverageProductViews,
    getFormattedProductViewsGraphData,
    getFormattedTopCategoryData,
    getFormattedTopProductsByUnits,
    getProductViewsTableData,
    getFormattedSalesByProductData,
    getCategoryBySalesTableData,
    getSalesByTrafficSourceTableData,
    getDateRange,
    getFormattedStoreSessionsTableData,
    getFormattedSessionsByUTMChart,
    getFormattedSessionsByUTMTableData,
  } = useAnalyticsHelper();
  const { business } = useAppContext();
  const { id: storeId } = business;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnalyticsOverviewData, setIsLoadingAnalyticsOverviewData] =
    useState(false);
  const [analyticsOverviewData, setAnalyticsOverviewData] = useState([]);
  const [totalOrdersOverviewData, setTotalOrdersOverviewData] = useState({});
  const [totalOrdersGraphData, setTotalOrdersGraphData] = useState([]);
  const [totalSalesOverviewData, setTotalSalesOverviewData] = useState({});
  const [totalSalesGraphData, setTotalSalesGraphData] = useState([]);
  const [conversionData, setConversionData] = useState({});
  const [regionSalesChart, setRegionSalesChart] = useState([]);
  const [regionsTableData, setRegionsTableData] = useState([]);
  const [regionPercentChange, setRegionPercentChange] = useState(null);
  const [trafficSourceSalesData, setTrafficSourceSalesData] = useState({});
  const [frequency, setFrequency] = useState('monthly');
  const [ordersSalesTableData, setOrdersSalesTableData] = useState([]);
  const [sessionDayWiseOverviewData, setSessionDayWiseOverviewData] = useState(
    {}
  );
  const [sessionDayWiseGraphData, setSessionDayWiseGraphData] = useState([]);
  const [storeOverviewData, setStoreOverviewData] = useState([]);
  const [storeSessionsChartData, setStoreSessionsChartData] = useState({});
  const [storeViewsOverviewData, setStoreViewsOverviewData] = useState({});
  const [sessionsByPageChart, setSessionsByPageChart] = useState({});
  const [sessionsByPageComparisonData, setSessionsByPageComparisonData] =
    useState({});
  const [sessionsByPageTable, setSessionsByPageTable] = useState([]);
  const [storeViewsGraphData, setStoreViewsGraphData] = useState([]);
  const [storeViewsTableData, setStoreViewsTableData] = useState([]);
  const [productOverviewData, setProductOverviewData] = useState([]);
  const [productViewsGraphOverview, setProductViewsGraphOverview] = useState(
    {}
  );
  const [productViewsGraphData, setProductViewsGraphData] = useState([]);
  const [businessForwardedDateParams, setBusinessForwardedDateParams] =
    useState({
      startDate: DEFAULT_DATE_RANGE.START_DATE,
      endDate: DEFAULT_DATE_RANGE.END_DATE,
      selection: DEFAULT_DATE_RANGE.SELECTION,
    });

  const [productViewsTableData, setProductViewsTableData] = useState([]);
  const [salesByProductData, setSalesByProductData] = useState([]);
  const [salesByCategoryTableData, setSalesByCategoryTableData] = useState([]);

  const [topCategoryBySales, setTopCategoryBySales] = useState({});
  const [topProductsByUnits, setTopProductsByUnits] = useState({});
  const [isOrdersSalesDataLoading, setIsOrdersSalesDataLoading] =
    useState(false);
  const [isCoversionDataLoading, setIsConversionDataLoading] = useState(false);
  const [isRegionsDataLoading, setIsRegionsDataLoading] = useState(false);
  const [isTrafficSourceDataLoading, setIsTrafficSourceDataLoading] =
    useState(false);
  const [isUtmDataLoading, setIsUtmDataLoading] = useState(false);
  const [isStoreOverviewStatsLoading, setIsStoreOverviewStatsLoading] =
    useState(false);
  const [isStoreViewsGraphViewLoading, setIsStoreViewsGraphViewLoading] =
    useState(false);
  const [isProductOverviewDataLoading, setIsProductOverviewDataLoading] =
    useState(false);
  const [isProductViewsReportLoading, setIsProductViewsReportLoading] =
    useState(false);
  const [isCategoryBySalesLoading, setIsCategoryBySalesLoading] =
    useState(false);
  const [isTopProductsByUnitsLoading, setIsTopProductsByUnitsLoading] =
    useState(false);
  const [salesByTrafficSourceTableData, setSalesByTrafficSourceTableData] =
    useState({});
  const [showErrorState, setShowErrorState] = useState(false);
  const [orderingDirectionAnalytics, setOrderingDirection] = useState('desc');
  const [orderingKeyAnalytics, setOrderingKey] = useState('');
  const [isSalesBreakdownDataLoading, setIsSalesBreakdownDataLoading] =
    useState(false);
  const [salesBreakdownData, setSalesBreakdownData] = useState([]);

  const [sessionsByUTMGraphData, setSessionsByUTMGraphData] = useState([]);
  const [sessionsByUTMTableData, setSessionsByUTMTableData] = useState({});
  const [showErrorStateSalesStats, setShowErrorStateSalesStats] =
    useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onSortAnalytics = (key, onlyTwoSortingMode = false) => {
    if (orderingKeyAnalytics !== key) {
      setOrderingKey(key);
      setOrderingDirection('desc');
    } else if (orderingDirectionAnalytics === 'desc')
      setOrderingDirection('asc');
    else if (orderingDirectionAnalytics === 'asc' && !onlyTwoSortingMode)
      setOrderingDirection('');
    else {
      setOrderingDirection('desc');
    }
  };
  const resetOrderingDirection = (direction) =>
    setOrderingDirection(direction || '');

  const onSortSessionsByPage = (
    data,
    sortingDirection,
    key,
    onlyTwoSortingMode = false
  ) => {
    if (orderingKeyAnalytics !== key) {
      setOrderingKey(key);
      setOrderingDirection('desc');
    } else if (sortingDirection === 'desc') {
      setOrderingDirection('asc');
      setSessionsByPageTable(
        getFormattedSessionsByPageTableData(
          data.sort((a, b) => a[key] - b[key])
        )
      );
    } else if (orderingDirectionAnalytics === 'asc' && !onlyTwoSortingMode) {
      setOrderingDirection('');
      setSessionsByPageTable(
        getFormattedSessionsByPageTableData(
          data.sort((a, b) => b[key] - a[key])
        )
      );
    } else {
      setOrderingDirection('desc');
      setSessionsByPageTable(
        getFormattedSessionsByPageTableData(
          data.sort((a, b) => b[key] - a[key])
        )
      );
    }
  };

  const constructOrdering = () => {
    let params = {};
    if (orderingKeyAnalytics === '') return params;

    if (orderingDirectionAnalytics === 'asc')
      params = { ordering: orderingKeyAnalytics };

    if (orderingDirectionAnalytics === 'desc')
      params = { ordering: `-${orderingKeyAnalytics}` };
    return params;
  };
  const orderingParams = constructOrdering();

  const fetchOverview = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_OVERVIEW(storeId),
        data: { ...params, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchAvgStoreConversions = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_AVG_STORE_SESSIONS(storeId),
        data: { ...params, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchReturningBuyers = useCallback(
    (params) =>
      getRequest({
        url: CUSTOMER_COUNT_URL,
        data: { ...params, timezone },
        retries: 3,
      }),
    [timezone]
  );

  const fetchBusinessOverview = useCallback(
    (dateRangeParams, otherParams, shouldIncludeOrderingParams = true) => {
      const ordering = shouldIncludeOrderingParams ? orderingParams : {};
      return getRequest({
        url: ANALYTICS_BUSINESS_OVERVIEW(storeId),
        data: {
          ...dateRangeParams,
          ...otherParams,
          timezone,
          ...ordering,
        },
        retries: 3,
      });
    },
    [storeId, timezone, orderingKeyAnalytics, orderingDirectionAnalytics]
  );

  const fetchProductOverview = useCallback(
    (dateRangeParams) =>
      getRequest({
        url: ANALYTICS_PRODUCT_OVERVIEW(storeId),
        data: {
          ...dateRangeParams,
          timezone,
        },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchCategoryOverview = useCallback(
    (dateRangeParams) =>
      getRequest({
        url: ANALYTICS_CATEGORY_OVERVIEW(storeId),
        data: {
          ...dateRangeParams,
          timezone,
        },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchProductViewData = useCallback(
    (dateRangeParams) =>
      getRequest({
        url: ANALYTICS_PRODUCT_VIEW_DATA(storeId),
        data: { ...dateRangeParams, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchOrdersSalesStats = useCallback(
    (dateRangeParams, dateRangeParamsForComparison, dateRangeType) => {
      setIsLoadingAnalyticsOverviewData(true);
      setShowErrorStateSalesStats(false);
      Promise.all([
        fetchOverview(dateRangeParams),
        fetchReturningBuyers(dateRangeParams),
        fetchOverview(dateRangeParamsForComparison),
        fetchReturningBuyers(dateRangeParamsForComparison),
      ])
        .then(
          ([
            overviewResNew,
            returningBuyersResNew,
            overviewResOld,
            returningBuyersResOld,
          ]) => {
            const {
              avg_orders_per_day: avgOrdersPerDayNew,
              avg_order_value: avgOrderValueNew,
              avg_sales: avgSalesNew,
            } = overviewResNew?.data || {};

            const returningBuyersDataNew =
              returningBuyersResNew?.data?.returning || 0;

            const returningBuyersDataNewPercentage = returningBuyersResNew?.data
              ?.returning
              ? (returningBuyersResNew?.data?.returning /
                  returningBuyersResNew?.data?.all) *
                100
              : 0;

            const {
              avg_orders_per_day: avgOrdersPerDayOld,
              avg_order_value: avgOrderValueOld,
              avg_sales: avgSalesOld,
            } = overviewResOld?.data || {};

            const returningBuyersOld =
              returningBuyersResOld?.data?.returning || 0;

            setAnalyticsOverviewData(
              getOverviewFormatedData(
                avgOrdersPerDayNew,
                avgOrderValueNew,
                avgSalesNew,
                returningBuyersDataNew,
                returningBuyersDataNewPercentage,
                avgOrdersPerDayOld,
                avgOrderValueOld,
                avgSalesOld,
                returningBuyersOld,
                dateRangeType
              )
            );
          }
        )
        .catch((err) => {
          console.log(err);
          setShowErrorStateSalesStats(true);
          setAnalyticsOverviewData(
            getOverviewFormatedData(0, 0, 0, 0, 0, 0, 0, 0, 0, dateRangeType)
          );
        })
        .finally(() => setIsLoadingAnalyticsOverviewData(false));
    },
    [fetchOverview, fetchReturningBuyers, getOverviewFormatedData]
  );

  // get total orders and sales stats data for forming graphs
  const fetchTotalOrdersSalesData = useCallback(
    (dateRangeParams, dateRangeParamsForComparison, dateRangeType) => {
      setIsOrdersSalesDataLoading(true);
      const startDate = new Date(dateRangeParams.created_at_after);
      const endDate = new Date(dateRangeParams.created_at_before);
      const dateDiff = moment
        .duration(moment(endDate).diff(moment(startDate)))
        .asDays();
      let groupBy = '';
      if (dateRangeType === 'lifetime') groupBy = 'month_wise';
      else if (dateDiff === 0) groupBy = 'hour_wise';

      Promise.all([
        fetchBusinessOverview(
          dateRangeParams,
          {
            distribution_type: 'date_wise',
            group_by: groupBy,
          },
          false
        ),
        fetchBusinessOverview(
          dateRangeParamsForComparison,
          {
            distribution_type: 'date_wise',
            group_by: groupBy,
          },
          false
        ),
      ])
        .then(([totalOrdersNew, totalOrdersOld]) => {
          const { data: totalOrdersNewData } = totalOrdersNew || {};
          const { data: totalOrdersOldData } = totalOrdersOld || {};

          setTotalOrdersOverviewData(
            getFormatedTotalOrdersData(
              totalOrdersNewData,
              totalOrdersOldData,
              dateRangeType
            )
          );

          setTotalOrdersGraphData(
            formatDataForGraph(
              totalOrdersNew.data.distribution,
              'order_count',
              dateRangeParams,
              dateRangeType,
              false,
              dateDiff === 0
            )
          );

          setTotalSalesOverviewData(
            getFormatedTotalSalesData(
              totalOrdersNewData,
              totalOrdersOldData,
              dateRangeType
            )
          );

          setTotalSalesGraphData(
            formatDataForGraph(
              totalOrdersNew.data.distribution,
              'sales',
              dateRangeParams,
              dateRangeType,
              false,
              dateDiff === 0
            )
          );

          let freq;
          if (dateRangeType === 'lifetime') {
            freq = 'monthly';
          } else if (dateDiff === 0) {
            freq = 'hourly';
          } else {
            freq = 'daily';
          }
          setFrequency(freq);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsOrdersSalesDataLoading(false));
    },
    [
      fetchBusinessOverview,
      formatDataForGraph,
      getFormatedTotalOrdersData,
      getFormatedTotalSalesData,
    ]
  );

  // get table data for orders or sales table based on dataKey
  const fetchOrdersSalesTableData = (
    dateRangeParams,
    dateRangeType,
    dataKey,
    onSuccessCallback = noop
  ) => {
    setIsLoading(true);
    const groupBy = dateRangeType === 'lifetime' ? 'month_wise' : '';

    getRequest({
      url: ANALYTICS_BUSINESS_OVERVIEW(storeId),
      data: {
        created_at_after: dateRangeParams.created_at_after,
        created_at_before: dateRangeParams.created_at_before,
        distribution_type: 'date_wise',
        group_by: groupBy,
        timezone,
      },
      retries: 3,
    })
      .then((totalOrders) => {
        const { data: totalOrdersData } = totalOrders || {};
        setOrdersSalesTableData(
          getFormatedOrdersTableData(
            totalOrdersData.distribution,
            dateRangeType,
            dataKey,
            dateRangeParams,
            orderingDirectionAnalytics
          )
        );
        onSuccessCallback();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const fetchConvertionData = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_STORE_CONVERSION(storeId),
        data: { ...params },
        retries: 3,
      }),
    [storeId]
  );

  const fetchConvertionGraphData = useCallback(
    (dateRangeParams, dateRangeParamsForComparison, dateRangeType) => {
      setIsConversionDataLoading(true);
      const startDate = new Date(dateRangeParams.created_at_after);
      const endDate = new Date(dateRangeParams.created_at_before);
      const dateDiff = moment
        .duration(moment(endDate).diff(moment(startDate)))
        .asDays();
      let groupBy = '';
      if (dateRangeType === 'lifetime') groupBy = 'month_wise';
      else if (dateDiff === 0) groupBy = 'hour_wise';
      const dateRangeParamsNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise', timezone }
          : {
              ...dateRangeParams,
              distribution_type: 'date_wise',
              timezone,
            };

      // uncomment to get the comparison percent
      // const dateRangeParamsComparisonNewFormat =
      //   dateRangeType === 'lifetime'
      //     ? { distribution_type: 'month_wise', timezone }
      //     : {
      //         ...dateRangeParamsForComparison,
      //         distribution_type: 'date_wise',
      //         timezone,
      //       };
      Promise.all([
        fetchBusinessOverview(
          dateRangeParams,
          {
            distribution_type: 'date_wise',
            group_by: groupBy,
          },
          false
        ),
        // fetchBusinessOverview(
        //   dateRangeParamsForComparison,
        //   {
        //     distribution_type: 'date_wise',
        //     group_by: groupBy,
        //   },
        //   false
        // ),
        fetchAvgStoreConversions(dateRangeParamsNewFormat),
        // fetchAvgStoreConversions(dateRangeParamsComparisonNewFormat),
      ])
        .then(
          ([
            totalOrdersNew,
            // totalOrdersOld,
            totalSessionsNew,
            // totalSessionsOld,
          ]) => {
            const { data: totalOrdersNewData } = totalOrdersNew || {};
            // const { data: totalOrdersOldData } = totalOrdersOld || {};
            const {
              data: { conversion_sessions: totalSessionsNewData },
            } = totalSessionsNew || {};
            // const {
            //   data: { conversion_sessions: totalSessionsOldData },
            // } = totalSessionsOld || {};

            const formattedOrdersData = formatDataForGraph(
              totalOrdersNew.data.distribution,
              'order_count',
              dateRangeParams,
              dateRangeType,
              false,
              dateDiff === 0
            );

            const formattedSesionsData = formatDataForGraph(
              totalSessionsNewData.distribution,
              'sessions',
              dateRangeParams,
              dateRangeType,
              true
            );

            setConversionData(
              getConversionsFormattedData({
                ordersNew: totalOrdersNewData.total_orders || 0,
                sessionsNew:
                  totalSessionsNewData.total_conversion_sessions || 0,
                // ordersOld: totalOrdersOldData.total_orders || 0,
                // sessionsOld:
                //   totalSessionsOldData.total_conversion_sessions || 0,
                dateRangeType,
                formattedOrdersData,
                formattedSesionsData,
              })
            );
          }
        )
        .catch((err) => {
          console.log(err);
          setConversionData({
            title: 'STORE CONVERSION RATE',
            tooltipText:
              'Percentage of sessions that converted into orders, calculated from the total number of sessions',
            data: [{ value: 0, label: new Date().getTime() }],
            number: '0%',
            graphTooltipValueLabel: 'Conversion rate',
          });
        })
        .finally(() => {
          setIsConversionDataLoading(false);
        });
    },
    [
      fetchAvgStoreConversions,
      formatDataForGraph,
      getConversionsFormattedData,
      timezone,
    ]
  );

  const fetchRegionsData = (
    dateRangeParams,
    dateRangeParamsForComparison,
    type,
    onSuccessCallback = noop
  ) => {
    setIsRegionsDataLoading(true);
    Promise.all([
      fetchBusinessOverview(dateRangeParams, {
        distribution_type: 'region_wise',
      }),
      fetchBusinessOverview(dateRangeParamsForComparison, {
        distribution_type: 'region_wise',
      }),
    ])
      .then(([regionsData, regionsDataForComparison]) => {
        const { distribution } = regionsData.data ?? {};
        const { distribution: distributionForComparison } =
          regionsDataForComparison.data ?? {};

        let changeInRegionPercent = distributionForComparison.length
          ? Math.round(
              (distribution.length -
                distributionForComparison.length /
                  distributionForComparison.length) *
                100
            )
          : 100;
        const isLifetimeOrCustomRange =
          type === 'lifetime' || type === 'custom_range';

        if (isLifetimeOrCustomRange) {
          changeInRegionPercent = null;
        }

        const topRegion = [...distribution].sort(
          (a, b) => a.sales_share - b.sales_share
        );

        const regionSalesData = getFormatedRegionSalesChart(topRegion);
        const regionTableDataArray = getFormattedRegionsTableData(distribution);
        setRegionSalesChart(regionSalesData);
        setRegionsTableData(regionTableDataArray);
        setRegionPercentChange(changeInRegionPercent);
        onSuccessCallback();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsRegionsDataLoading(false));
  };

  const fetchTrafficSources = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_BUSINESS_OVERVIEW(storeId),
        data: {
          ...params,
          distribution_type: 'source_wise',
          source_key: 37,
          timezone,
          ...orderingParams,
        },
        retries: 3,
      }),
    [storeId, timezone, orderingDirectionAnalytics, orderingKeyAnalytics]
  );

  const fetchTrafficSourceData = useCallback(
    (dateRangeParams) => {
      setIsTrafficSourceDataLoading(true);
      getRequest({
        url: ANALYTICS_BUSINESS_OVERVIEW(storeId),
        data: {
          ...dateRangeParams,
          distribution_type: 'source_wise',
          source_key: 37,
          timezone,
        },
        retries: 3,
      })
        .then((trafficData) => {
          const { distribution } = trafficData.data ?? {};
          const sortedTrafficSources = distribution.sort(
            (a, b) => a.sales_share - b.sales_share
          );
          const totalTrafficSourceData =
            getFormatedTrafficSourceSalesChart(sortedTrafficSources);
          setTrafficSourceSalesData(totalTrafficSourceData);
          setSalesByTrafficSourceTableData(
            getSalesByTrafficSourceTableData(distribution)
          );
        })
        .catch((err) => console.log(err))
        .finally(() => setIsTrafficSourceDataLoading(false));
    },
    [
      getFormatedTrafficSourceSalesChart,
      getSalesByTrafficSourceTableData,
      storeId,
      timezone,
    ]
  );

  const fetchUTMData = useCallback(
    (dateRangeParams) => {
      setIsUtmDataLoading(true);
      getRequest({
        url: ANALYTICS_UTM_WISE_SESSIONS,
        data: {
          ...dateRangeParams,
          timezone,
          utm_tag: 'u_utmSource',
        },
        retries: 3,
      })
        .then((utmData) => {
          const { store_session_breakdown_utmwise: utmwiseData } =
            utmData.data ?? {};
          setSessionsByUTMGraphData(
            getFormattedSessionsByUTMChart(utmwiseData)
          );
          setSessionsByUTMTableData(
            getFormattedSessionsByUTMTableData(utmwiseData)
          );
        })
        .catch((err) => console.log(err))
        .finally(() => setIsUtmDataLoading(false));
    },
    [timezone]
  );

  const fetchTrafficSourceTableData = useCallback(
    (
      dateRangeParams,
      dateRangeParamsForComparison,
      onSuccessCallback = noop
    ) => {
      setIsTrafficSourceDataLoading(true);
      Promise.all([
        fetchTrafficSources(dateRangeParams),
        fetchTrafficSources(dateRangeParamsForComparison),
      ])
        .then(([trafficResNew, trafficResOld]) => {
          const { distribution: distributionNew } = trafficResNew?.data ?? [];
          const { distribution: distributionOld } = trafficResOld?.data ?? [];
          const sortedTrafficSourcesNew = distributionNew;
          const sortedTrafficSourcesOld = distributionOld
            ?.sort((a, b) => a.sales_share - b.sales_share)
            .reverse();
          setSalesByTrafficSourceTableData(
            getSalesByTrafficSourceTableData(
              sortedTrafficSourcesNew,
              sortedTrafficSourcesOld
            )
          );
          onSuccessCallback();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsTrafficSourceDataLoading(false));
    },
    [
      fetchTrafficSources,
      getSalesByTrafficSourceTableData,
      orderingDirectionAnalytics,
      orderingKeyAnalytics,
    ]
  );

  const fetchStoreAvgViews = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_STORE_AVG_VIEWS(storeId),
        data: { ...params, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchStoreSessions = useCallback(
    (dateParams, byDeviceType) =>
      getRequest({
        url: ANALYTICS_STORE_AVG_SESSIONS(storeId),
        data: { ...dateParams, by_device_type: byDeviceType, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchStoreReturningBuyers = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_STORE_RETURNING_BUYERS(storeId),
        data: { ...params, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchStoreViews = useCallback(
    (params) =>
      getRequest({
        url: ANALYTICS_STORE_VIEWS(storeId),
        data: { ...params, timezone },
        retries: 3,
      }),
    [storeId, timezone]
  );

  const fetchStoreOverviewStats = useCallback(
    (dateRangeParams, dateRangeParamsForComparison, dateRangeType) => {
      setIsStoreOverviewStatsLoading(true);
      setShowErrorState(false);
      Promise.all([
        fetchStoreAvgViews(dateRangeParams),
        fetchStoreReturningBuyers(dateRangeParams),
        fetchStoreAvgViews(dateRangeParamsForComparison),
        fetchStoreReturningBuyers(dateRangeParamsForComparison),
        fetchStoreSessions(dateRangeParams, true),
      ])
        .then(
          ([
            avgViewResNew,
            avgReturningBuyersResNew,
            avgViewResOld,
            avgReturningBuyersResOld,
            sessionByDeviceRes,
          ]) => {
            const avgViewsPerDayNew =
              avgViewResNew?.data[0]?.avg_views_per_day || 0;
            const avgViewsPerDayOld =
              avgViewResOld?.data[0]?.avg_views_per_day || 0;
            const avgReturningBuyersNew =
              avgReturningBuyersResNew?.data?.number_of_returning_users || 0;
            const avgReturningBuyersOld =
              avgReturningBuyersResOld?.data?.number_of_returning_users || 0;

            const { distributions: sessionByDeviceData } =
              sessionByDeviceRes?.data || {};

            setStoreOverviewData(
              getStoreOverviewFormatedData(
                avgViewsPerDayNew,
                avgViewsPerDayOld,
                avgReturningBuyersNew,
                avgReturningBuyersOld,
                dateRangeType
              )
            );
            setStoreSessionsChartData(
              getSessionByDevicePieData(sessionByDeviceData)
            );
          }
        )
        .catch((err) => {
          console.log(err);
          setShowErrorState(true);
          setStoreOverviewData(
            getStoreOverviewFormatedData(0, 0, 0, 0, dateRangeType)
          );
          setStoreSessionsChartData(getSessionByDevicePieData([]));
        })
        .finally(() => setIsStoreOverviewStatsLoading(false));
    },
    [
      fetchStoreAvgViews,
      fetchStoreReturningBuyers,
      fetchStoreSessions,
      getSessionByDevicePieData,
      getStoreOverviewFormatedData,
    ]
  );

  const fetchStoreViewsGraphView = useCallback(
    (
      dateRangeParams,
      dateRangeParamsForComparison,
      dateRangeType,
      onSuccessCallback = noop
    ) => {
      setIsStoreViewsGraphViewLoading(true);

      const dateRangeParamsNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise', timezone }
          : {
              ...dateRangeParams,
              distribution_type: 'date_wise',
              timezone,
            };
      const dateRangeParamsComparisonNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise', timezone }
          : {
              ...dateRangeParamsForComparison,
              distribution_type: 'date_wise',
              timezone,
            };
      Promise.all([
        fetchStoreViews(dateRangeParamsNewFormat),
        fetchStoreViews(dateRangeParamsComparisonNewFormat),
      ])
        .then(([storeViewsResNew, storeViewsResOld]) => {
          const { data: storeViewsDataNew } = storeViewsResNew || {};
          const { data: storeViewsDataOld } = storeViewsResOld || {};

          const { session_views: sessionViewsNew } = storeViewsDataNew || {};
          const { session_views: sessionViewsOld } = storeViewsDataOld || {};

          const { store_session_breakdown_pagewise: sessionsByPageNew = [] } =
            storeViewsDataNew || {};
          const { store_session_breakdown_pagewise: sessionsByPageOld = [] } =
            storeViewsDataOld || {};

          setStoreViewsOverviewData(
            getFormattedStoreViewsOverviewData(
              storeViewsDataNew,
              storeViewsDataOld,
              dateRangeType
            )
          );

          setSessionDayWiseOverviewData(
            getFormattedSessionsOverviewData(
              sessionViewsNew,
              sessionViewsOld,
              dateRangeType
            )
          );
          setSessionDayWiseGraphData(
            formatDataForGraph(
              sessionViewsNew.distribution,
              'sessions',
              dateRangeParams,
              dateRangeType,
              true // areKeysDifferent
            )
          );

          setSessionsByPageChart(
            getFormattedSessionsByPageChart(sessionsByPageNew)
          );
          const totalNewSessionsByPage = sessionsByPageNew?.reduce(
            (acc, cur) => acc + cur.sessions,
            0
          );
          const totalOldSessionsByPage = Array.isArray(sessionsByPageOld)
            ? sessionsByPageOld?.reduce((acc, cur) => acc + cur.sessions, 0)
            : 0;
          setSessionsByPageComparisonData({
            total: totalNewSessionsByPage,
            percentChange:
              totalOldSessionsByPage > 0 && totalNewSessionsByPage > 0
                ? (totalNewSessionsByPage -
                    totalOldSessionsByPage / totalOldSessionsByPage) *
                  100
                : 0,
          });
          setSessionsByPageTable(
            getFormattedSessionsByPageTableData(sessionsByPageNew)
          );

          setStoreViewsGraphData(
            formatDataForGraph(
              storeViewsDataNew.distribution,
              'views',
              dateRangeParams,
              dateRangeType,
              true // areKeysDifferent
            )
          );

          let freq;
          if (dateRangeType === 'lifetime') {
            freq = 'monthly';
          } else if (
            dateRangeType === 'today' ||
            dateRangeType === 'yesterday'
          ) {
            freq = 'hourly';
          } else {
            freq = 'daily';
          }
          setFrequency(freq);
          onSuccessCallback();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsStoreViewsGraphViewLoading(false));
    },
    [
      fetchStoreViews,
      formatDataForGraph,
      getFormattedSessionsByPageChart,
      getFormattedSessionsByPageTableData,
      getFormattedSessionsOverviewData,
      getFormattedStoreViewsOverviewData,
      timezone,
    ]
  );

  const fetchStoreViewsTableData = useCallback(
    (dateRangeParams, dateRangeType, onSuccessCallback = noop) => {
      setIsLoading(true);
      const dateRangeParamsNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise' }
          : {
              ...dateRangeParams,
              distribution_type: 'date_wise',
            };
      getRequest({
        url: ANALYTICS_STORE_VIEWS(storeId),
        data: { ...dateRangeParamsNewFormat, timezone },
        retries: 3,
      })
        .then((storeViewsRes) => {
          const { distribution: storeViewsData } = storeViewsRes?.data || [];
          setStoreViewsTableData(
            getFormattedStoreViewsTableData(
              storeViewsData,
              dateRangeType,
              dateRangeParams,
              orderingDirectionAnalytics
            )
          );
          onSuccessCallback();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    },
    [getFormattedStoreViewsTableData, storeId, timezone]
  );

  const fetchStoreSessionsTableData = useCallback(
    (dateRangeParams, dateRangeType, onSuccessCallback = noop) => {
      setIsLoading(true);
      const dateRangeParamsNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise' }
          : {
              ...dateRangeParams,
              distribution_type: 'date_wise',
            };
      getRequest({
        url: ANALYTICS_STORE_VIEWS(storeId),
        data: { ...dateRangeParamsNewFormat, timezone },
        retries: 3,
      })
        .then((storeViewsRes) => {
          const { session_views: sessionViewsNew = {} } =
            storeViewsRes?.data || {};

          setStoreViewsTableData(
            getFormattedStoreSessionsTableData(
              sessionViewsNew?.distribution || [],
              dateRangeType,
              dateRangeParams,
              orderingDirectionAnalytics
            )
          );
          onSuccessCallback();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    },
    [
      getFormattedStoreSessionsTableData,
      orderingDirectionAnalytics,
      storeId,
      timezone,
    ]
  );

  const fetchProductOverviewData = useCallback(
    (dateRangeParams, dateRangeParamsForComparison, dateRangeType) => {
      setIsProductOverviewDataLoading(true);
      const dateRangeParamsNewFormat =
        dateRangeType === 'lifetime' ? {} : dateRangeParams;
      const dateRangeParamsComparisonNewFormat =
        dateRangeType === 'lifetime' ? {} : dateRangeParamsForComparison;
      Promise.all([
        fetchProductOverview(dateRangeParamsNewFormat),
        fetchProductOverview(dateRangeParamsComparisonNewFormat),
        fetchCategoryOverview(dateRangeParamsNewFormat),
        fetchCategoryOverview(dateRangeParamsComparisonNewFormat),
      ])
        .then(
          ([
            productData,
            productDataForComparison,
            categoryData,
            categoryDataForComparison,
          ]) => {
            const { distribution: productDistribution } =
              productData?.data ?? {};
            const { distribution: productDistributionForComparison } =
              productDataForComparison.data ?? {};
            const { distribution: categoryDistribution } =
              categoryData?.data ?? {};
            const { distribution: categoryDistributionForComparison } =
              categoryDataForComparison.data ?? {};
            const productDetails = getAverageProductViews(
              Array.isArray(productDistribution) ? productDistribution : [],
              Array.isArray(productDistributionForComparison)
                ? productDistributionForComparison
                : [],
              Array.isArray(categoryDistribution) ? categoryDistribution : [],
              Array.isArray(categoryDistributionForComparison)
                ? categoryDistributionForComparison
                : [],
              dateRangeType
            );

            setProductOverviewData(productDetails);
          }
        )
        .catch((e) => console.log(e))
        .finally(() => setIsProductOverviewDataLoading(false));
    },
    [getAverageProductViews, fetchCategoryOverview, fetchProductOverview]
  );

  const fetchProductViewsReport = useCallback(
    (dateRangeParams, dateRangeParamsForComparison, dateRangeType) => {
      setIsProductViewsReportLoading(true);
      const dateRangeParamsNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise', timezone }
          : {
              ...dateRangeParams,
              distribution_type: 'date_wise',
              timezone,
            };
      const dateRangeParamsComparisonNewFormat =
        dateRangeType === 'lifetime'
          ? { distribution_type: 'month_wise', timezone }
          : {
              ...dateRangeParamsForComparison,
              distribution_type: 'date_wise',
              timezone,
            };
      Promise.all([
        fetchProductViewData(dateRangeParamsNewFormat),
        fetchProductViewData(dateRangeParamsComparisonNewFormat),
      ])
        .then(([totalProductViewsNew, totalProductViewsOld]) => {
          const { data: productViewsDataNew } = totalProductViewsNew || {};
          const { data: productViewsDataOld } = totalProductViewsOld || {};

          const totalProductViewsGraphData = getFormattedProductViewsGraphData(
            productViewsDataNew,
            productViewsDataOld,
            dateRangeType
          );
          setProductViewsGraphOverview(totalProductViewsGraphData);
          setProductViewsGraphData(
            formatDataForGraph(
              productViewsDataNew?.distribution,
              'views',
              dateRangeParams,
              dateRangeType,
              true
            )
          );
          let freq;
          if (dateRangeType === 'lifetime') {
            freq = 'monthly';
          } else if (
            dateRangeType === 'today' ||
            dateRangeType === 'yesterday'
          ) {
            freq = 'hourly';
          } else {
            freq = 'daily';
          }
          setFrequency(freq);
        })
        .catch((e) => console.log(e))
        .finally(() => setIsProductViewsReportLoading(false));
    },
    [
      fetchProductViewData,
      formatDataForGraph,
      getFormattedProductViewsGraphData,
      timezone,
    ]
  );

  const fetchProductViewsTableData = useCallback(
    (dateRange, dateRangeType, onSuccessCallback = noop) => {
      setIsLoading(true);
      const distributionType =
        dateRangeType === 'lifetime' ? 'month_wise' : 'date_wise';
      getRequest({
        url: ANALYTICS_PRODUCT_VIEW_DATA(storeId),
        data: { ...dateRange, distribution_type: distributionType, timezone },
        retries: 3,
      })
        .then((productData) => {
          const { data: productViewsData } = productData || {};
          const productViewsReportTableData = getProductViewsTableData(
            productViewsData?.distribution &&
              Array.isArray(productViewsData.distribution)
              ? productViewsData.distribution
              : [],
            dateRangeType,
            dateRange,
            orderingDirectionAnalytics
          );
          setProductViewsTableData(productViewsReportTableData);
          onSuccessCallback();
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false));
    },
    [
      getProductViewsTableData,
      storeId,
      timezone,
      orderingDirectionAnalytics,
      orderingKeyAnalytics,
    ]
  );

  const fetchCategoryBySales = useCallback(
    (dateRangeParams) => {
      setIsCategoryBySalesLoading(true);
      getRequest({
        url: ANALYTICS_CATEGORY_BY_SALES(storeId),
        data: {
          ...dateRangeParams,
        },
        retries: 3,
      })
        .then((categoryData) => {
          const { distribution } = categoryData.data ?? {};
          let totalSales = 0;
          distribution.forEach((item) => {
            totalSales += item.sales_amount;
          });

          const topCategories = getFormattedTopCategoryData(
            distribution,
            totalSales
          );
          setTopCategoryBySales(topCategories);
        })
        .catch((e) => console.log('error', e))
        .finally(() => setIsCategoryBySalesLoading(false));
    },
    [getFormattedTopCategoryData, storeId]
  );

  const fetchTopCategoryBySalesTable = useCallback(
    (dateRangeParams, onSuccessCallback = noop) => {
      setIsLoading(true);
      getRequest({
        url: ANALYTICS_CATEGORY_BY_SALES(storeId),
        data: {
          ...dateRangeParams,
          ...orderingParams,
        },
        retries: 3,
      })
        .then((res) => {
          const { distribution } = res?.data ?? [];
          setSalesByCategoryTableData(
            getCategoryBySalesTableData(distribution)
          );
          onSuccessCallback();
        })
        .catch((e) => console.log('err', e))
        .finally(() => setIsLoading(false));
    },
    [
      getCategoryBySalesTableData,
      storeId,
      orderingDirectionAnalytics,
      orderingKeyAnalytics,
    ]
  );

  const fetchTopProductsByUnitsSold = useCallback(
    (dateRangeParams) => {
      setIsTopProductsByUnitsLoading(true);
      getRequest({
        url: ANALYTICS_TOP_PRODUCTS_BY_UNITS_SOLD(storeId),
        data: {
          ...dateRangeParams,
        },
        retries: 3,
      })
        .then((topProducts) => {
          const { distribution } = topProducts.data ?? {};
          const topProductsData = getFormattedTopProductsByUnits(distribution);
          setTopProductsByUnits(topProductsData);
        })
        .catch((e) => console.log('err', e))
        .finally(() => setIsTopProductsByUnitsLoading(false));
    },
    [getFormattedTopProductsByUnits, storeId]
  );

  const fetchSalesByProduct = useCallback(
    (dateRangeParams, onSuccessCallback = noop) => {
      setIsLoading(true);
      getRequest({
        url: ANALYTICS_TOP_PRODUCTS_BY_UNITS_SOLD(storeId),
        data: {
          ...dateRangeParams,
          timezone,
          ...orderingParams,
        },
        retries: 3,
      })
        .then((res) => {
          const { distribution } = res?.data ?? [];
          setSalesByProductData(getFormattedSalesByProductData(distribution));
          onSuccessCallback();
        })
        .finally(() => setIsLoading(false));
    },
    [
      getFormattedSalesByProductData,
      storeId,
      timezone,
      orderingDirectionAnalytics,
      orderingKeyAnalytics,
    ]
  );

  const fetchSalesBreakdownData = useCallback((dateRangeParams) => {
    setIsSalesBreakdownDataLoading(true);
    getRequest({
      url: ANALYTICS_SALES_BREAKDOWN_API(storeId),
      data: {
        ...dateRangeParams,
        timezone,
      },
      retries: 3,
    })
      .then((response) => {
        const {
          total_gross_sales: grossSales = null,
          total_gross_order_count: grossOrderCount = null,
          terminated_non_delivered_total_sales: cancellationsSales = null,
          terminated_non_delivered_total_count: cancellationsOrder = null,
          return_replacement_value: returnSales = null,
          return_replacement_count: returnOrder = null,
          total_sales: totalSales = null,
          total_sales_order_count: totalOrder = null,
        } = response?.data || {};

        setSalesBreakdownData([
          {
            orders: grossOrderCount,
            amount: grossSales,
            key: 'grossSales',
          },
          {
            orders: returnOrder,
            amount: returnSales,
            key: 'returns',
          },
          {
            orders: cancellationsOrder,
            amount: cancellationsSales,
            key: 'cancellations',
          },
          {
            orders: totalOrder,
            amount: totalSales,
            key: 'totalSales',
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
        setSalesBreakdownData([]);
      })
      .finally(() => {
        setIsSalesBreakdownDataLoading(false);
      });
  });

  const contextValue = {
    isLoading,
    analyticsOverviewData,
    totalOrdersOverviewData,
    totalOrdersGraphData,
    totalSalesOverviewData,
    totalSalesGraphData,
    conversionData,
    regionSalesChart,
    regionsTableData,
    regionPercentChange,
    trafficSourceSalesData,
    frequency,
    ordersSalesTableData,
    businessForwardedDateParams,
    sessionDayWiseOverviewData,
    sessionDayWiseGraphData,
    storeOverviewData,
    storeSessionsChartData,
    storeViewsOverviewData,
    sessionsByPageChart,
    sessionsByPageComparisonData,
    sessionsByPageTable,
    storeViewsGraphData,
    storeViewsTableData,
    productViewsGraphOverview,
    topCategoryBySales,
    productOverviewData,
    productViewsGraphData,
    topProductsByUnits,
    productViewsTableData,
    isLoadingAnalyticsOverviewData,
    isOrdersSalesDataLoading,
    isCoversionDataLoading,
    isRegionsDataLoading,
    isTrafficSourceDataLoading,
    isUtmDataLoading,
    isStoreOverviewStatsLoading,
    isStoreViewsGraphViewLoading,
    isProductOverviewDataLoading,
    isProductViewsReportLoading,
    isCategoryBySalesLoading,
    isTopProductsByUnitsLoading,
    salesByProductData,
    salesByCategoryTableData,
    salesByTrafficSourceTableData,
    showErrorState,
    orderingKeyAnalytics,
    orderingDirectionAnalytics,
    isSalesBreakdownDataLoading,
    salesBreakdownData,
    showErrorStateSalesStats,
    sessionsByUTMGraphData,
    sessionsByUTMTableData,
    fetchOrdersSalesStats,
    fetchTotalOrdersSalesData,
    fetchRegionsData,
    fetchTrafficSourceData,
    fetchUTMData,
    fetchOrdersSalesTableData,
    fetchConvertionGraphData,
    setBusinessForwardedDateParams,
    fetchStoreOverviewStats,
    fetchStoreViewsGraphView,
    fetchStoreViewsTableData,
    fetchStoreSessionsTableData,
    fetchProductOverviewData,
    fetchProductViewsReport,
    fetchCategoryBySales,
    fetchTopProductsByUnitsSold,
    fetchProductViewsTableData,
    fetchSalesByProduct,
    fetchTopCategoryBySalesTable,
    fetchTrafficSourceTableData,
    onSortAnalytics,
    onSortSessionsByPage,
    resetOrderingDirection,
    fetchSalesBreakdownData,
  };

  return (
    <AnalyticsDataContext.Provider value={contextValue}>
      {children}
    </AnalyticsDataContext.Provider>
  );
};

export default AnalyticsProvider;
