import React, { useState, useEffect, useRef, useCallback } from 'react';
import Modal from './Modal';
import ImageCropper from './ImageCropper';
import useUploadMedia from '../hooks/useUploadMedia';
import SpinnerButton from './SpinnerButton';
import { noop } from '../utils';
import { isObjectEmpty } from '../utils/collection';
import useEnterPressAction from '../hooks/useEnterPressAction';

const ImageCropModal = ({
  image: imageSrc,
  open = false,
  handleClose = noop,
  closeModal = noop,
  onUploadSuccess = noop,
  form,
  modalTitle = 'Crop image',
  modalBtnText = 'Select',
  name = 'image_upload',
  width = 1296,
  height = 568,
  customCrop = {},
  imageStyles = {},
  reactCroperStyles = {},
}) => {
  const aspect = width / height;
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    ...(!isObjectEmpty(customCrop)
      ? { ...customCrop }
      : {
          aspect,
          height: 200,
          width: aspect * 200,
          unit: 'px',
        }),
  });

  const { uploading, upload } = useUploadMedia({});

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const uploadCroppedImage = async (canvas, cropConfig) => {
    if (!cropConfig || !canvas) {
      return;
    }
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, 'image/png', 1)
    );
    const { cdnURL } = await upload(blob);
    return cdnURL;
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const newImage = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crp = completedCrop;

    const scaleX = newImage.naturalWidth / newImage.width;
    const scaleY = newImage.naturalHeight / newImage.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crp.width * pixelRatio;
    canvas.height = crp.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      newImage,
      crp.x * scaleX,
      crp.y * scaleY,
      crp.width * scaleX,
      crp.height * scaleY,
      0,
      0,
      crp.width,
      crp.height
    );
  }, [completedCrop]);

  const handleSubmit = async () => {
    const imageUrl = await uploadCroppedImage(
      previewCanvasRef.current,
      completedCrop
    );
    form.mutators.modifyFormField(name, imageUrl);
    onUploadSuccess(imageUrl);
    closeModal();
  };

  useEnterPressAction(open, uploading ? noop : handleSubmit);

  return (
    <Modal open={open} closeModal={handleClose} paperClass="lg">
      <h2 className="section-text-4 mb24">{modalTitle}</h2>
      <ImageCropper
        crop={crop}
        src={imageSrc}
        onImageLoaded={onLoad}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        imageStyles={imageStyles}
        reactCroperStyles={reactCroperStyles}
      />
      <canvas
        ref={previewCanvasRef}
        style={{
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
          display: 'none',
        }}
      />
      <div className="d-flex-c-c">
        <SpinnerButton
          showAnimation
          isLoading={uploading}
          type="button"
          className="btn btn-primary-4 mt24"
          disabled={uploading}
          onClick={handleSubmit}
        >
          {modalBtnText}
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default ImageCropModal;
