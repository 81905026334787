import { useEffect } from 'react';

const useEnterPressAction = (open, callback) => {
  useEffect(() => {
    const handleEvent = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        callback();
      }
    };
    if (open) {
      document.addEventListener('keyup', handleEvent);
      return () => {
        document.removeEventListener('keyup', handleEvent);
      };
    }
  }, [callback, open]);
};

export default useEnterPressAction;
