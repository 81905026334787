import { useState } from 'react';
import { UploadedImage } from '../..';
import { noop } from '../../../utils';

const EachFile = (props) => {
  const {
    allowMultipleFiles = false,
    file,
    fileId,
    multipleSelectedFiles = [],
    productAttributeFileType = false,
    removeFile = noop,
    setValue = noop,
    setMultipleSelectedFiles = noop,
    value,
    handleFileSelect = noop,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const isSelected = multipleSelectedFiles.some((item) => item === file.imgUrl);
  const { name: uploadedFileName = '' } = file;

  return (
    <div
      className="uploaded-image cur-p"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={() => handleFileSelect(file)}
    >
      <UploadedImage
        imgUrl={file.imgUrl}
        name={uploadedFileName}
        onRemoveClick={(e) => {
          e.stopPropagation();
          removeFile(fileId, file.imgUrl);
        }}
        showRemoveIcon={isHovered && !isSelected}
        multiple
        isSelected={isSelected}
        updateAttributeImage
        productAttributeFileType={productAttributeFileType}
        height={108}
        width={108}
        breathingClass="br4"
      />
    </div>
  );
};

export default EachFile;
