import React from 'react';
import { noop } from '../../utils';
import { ColorPickerPopper } from './FormColorSelect';

const FontColorPicker = ({ color, onChange = noop }) => (
  <ColorPickerPopper
    popperClass="color-popper"
    color={color}
    onColorChange={({ hex }) => {
      onChange(hex);
    }}
  >
    <div className="font-color-picker">
      <p>A</p>
      <div className="font-color-preview" style={{ backgroundColor: color }} />
    </div>
  </ColorPickerPopper>
);

export default FontColorPicker;
