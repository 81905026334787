import React from 'react';
import { Field } from 'react-final-form';
import CheckboxField from '../CheckboxField';
import { noop } from '../../utils';

const FormCheckbox = ({
  name,
  validate,
  type = 'checkbox',
  onChange = noop,
  ...restProps
}) => (
  <Field name={name} validate={validate} type={type}>
    {({ input }) => {
      const handleChange = (e) => {
        input.onChange(e);
        onChange(e);
      };
      return (
        <CheckboxField
          {...restProps}
          {...input}
          onChange={handleChange}
          checked={restProps.checked || input.checked}
        />
      );
    }}
  </Field>
);

export default FormCheckbox;
