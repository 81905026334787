import { Redirect, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { DeleteIconDark, SuccessBlueIcon } from '../SvgIcon';

import { authUrls } from '../Urls';

import useQuery from '../hooks/useQuery';
import { STAFF_ROLE_OPTIONS } from './constants';
import { checkIsPhone } from '../shared/Form/Validation';

import '../../scss/pages/staff_verify.scss';
import useResponsive from '../hooks/useResponsive';

const StaffVerifyLayout = ({
  Icon,
  title = '',
  subtitle = '',
  description = '',
  actionTitle = '',
  actionHref = '/',
  mobileDescription = '',
  roleName = '',
}) => {
  const { isTablet } = useResponsive();
  const history = useHistory();

  return (
    <div className={cx('staff-verify-layout', { mobile: isTablet })}>
      {!isTablet ? (
        <div className="page">
          <div className="body">
            {Icon && <Icon width="96" height="96" />}
            <div className="main">
              <p className="title">{title}</p>
              <p className="subtitle">{subtitle}</p>
            </div>
          </div>
          <div className="action">
            <p className="description">{description}</p>
            {!isTablet && (
              <button
                type="button"
                className="btn btn-primary-48"
                onClick={() => {
                  history.push(actionHref);
                }}
              >
                {actionTitle}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="page">
          <div className="body">
            {Icon && <Icon width="72" height="72" />}
            <div className="main">
              <p className="title">{title}</p>
              <p className="subtitle">{subtitle}</p>
            </div>
            <div className="horizontal-border" />

            <div className="action notes-timeline action-timeline">
              <div className="d-flex pb20 timeline">
                <span className={cx('ellipses')}>
                  <p className="paragraph">1</p>
                </span>
                <div className="d-flex pl8">
                  <span className="text-2" style={{ textAlign: 'left' }}>
                    Now open <span className="text-font">web.mydukaan.io</span>{' '}
                    on your computer.
                  </span>
                </div>
              </div>
              <div className="d-flex pb20 timeline">
                <span className={cx('ellipses')}>
                  <p className="paragraph">2</p>
                </span>
                <div className="d-flex space-between pl8">
                  <span
                    className="mobile-description"
                    style={{ textAlign: 'left' }}
                  >
                    {mobileDescription}
                  </span>
                </div>
              </div>
              <div className="d-flex pb20 timeline">
                <span className={cx('ellipses')}>
                  <p className="paragraph">3</p>
                </span>
                <div className="d-flex space-between pl8">
                  <span className="text-2" style={{ textAlign: 'left' }}>
                    Start managing the store as a {roleName}.
                  </span>
                </div>
              </div>

              {!isTablet && (
                <button
                  type="button"
                  className="btn btn-primary-48"
                  onClick={() => {
                    history.push(actionHref);
                  }}
                >
                  {actionTitle}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const StaffAccountSuccess = () => {
  const query = useQuery();
  const userNameLabel = query.get('username')?.includes('@')
    ? 'email'
    : 'number';

  const roleName = STAFF_ROLE_OPTIONS.find(
    (role) => role.value === query.get('role_name')?.toLowerCase()
  )?.label;

  return (
    <StaffVerifyLayout
      Icon={SuccessBlueIcon}
      title="Congratulations!"
      subtitle={
        <>
          You can now access <span>{query.get('store')}</span> as a {roleName}.
        </>
      }
      description={
        <>
          Use your {userNameLabel}{' '}
          <span>“{decodeURIComponent(query.get('username'))}”</span> to log in
          and get access to the store.
        </>
      }
      mobileDescription={
        <>
          Use your {userNameLabel}{' '}
          <span>
            {userNameLabel === 'number' ? '+' : ''}
            {query.get('username')}
          </span>{' '}
          to log into the store.
        </>
      }
      actionTitle="Get started"
      actionHref={`${authUrls.loginPath}?${userNameLabel}=${query.get(
        'username'
      )}`}
      roleName={roleName}
    />
  );
};

export const StaffAccountError = () => <Redirect to={authUrls.loginPath} />;

export const StaffAccountAccessRevoked = () => {
  const query = useQuery();
  const roleName = STAFF_ROLE_OPTIONS.find(
    (role) => role.value === query.get('role_name')
  )?.label;
  const store = query.get('store');

  return (
    <div className="d-flex-c-c flex-column full-vh">
      <DeleteIconDark width={96} height={96} />
      <p className="section-text-3 mt32 mb6">
        You can no longer access this store!
      </p>
      <p className="text-2 c-black-3 text-center">
        The owner of {store} has removed your {roleName} account.
      </p>
      <p className="text-2 c-black-3 text-center">
        Kindly contact the store owner if you wish to access the store again.
      </p>
    </div>
  );
};
