import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { TINY_MCE_KEY } from '../../constants';
import { noop } from '../../utils';
import imageUploadHandler from './imageUploadHandler';
import { BounceLoaderSmall } from '../BounceLoader';

const config = {
  apiKey: TINY_MCE_KEY,
  init: {
    branding: false,
    height: 500,
    menubar: false,
    elementpath: false,
    images_upload_handler: imageUploadHandler,
    plugins: [
      'autolink',
      'code',
      'link',
      'image',
      'preview',
      'fullscreen',
      'table',
      'lists',
    ],
    toolbar:
      'blocks bold italic backcolor link image table aligngroup bullist numlist blockquote code fullscreen',
    content_css: '/css/tinymce.css',
    block_formats:
      'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
    toolbar_groups: {
      aligngroup: {
        icon: 'align-left',
        tooltip: 'Paragraph format',
        items: ' alignleft aligncenter alignright alignjustify',
      },
    },
    extended_valid_elements: 'style,link[href|rel]',
    custom_elements: 'style,link,~link',
    valid_children: '+body[style]',
  },
};

const TinyMCEEditor = ({ data = '', onChange = noop, onFocus = noop }) => {
  const [editorRef, setEditorRef] = useState(null);

  return (
    <>
      {!editorRef && <BounceLoaderSmall inline />}
      <Editor
        tinymceScriptSrc={
          process.env.PUBLIC_URL + '/tinymce/js/tinymce/tinymce.min.js'
        }
        value={data}
        onEditorChange={onChange}
        onInit={(evt, editor) => setEditorRef(editor.id)}
        onFocus={onFocus}
        {...config}
      />
    </>
  );
};

export default TinyMCEEditor;
