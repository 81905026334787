import axios from 'axios';
import { SELECTED_STORE_ID_KEY } from '../context/AppProvider';
import { ACTIVE_PLAN_NAME_KEY } from '../context/SubscriptionProvider';
import { getItem } from './storage';
import { getAuthToken } from './user';

function clean(object) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      clean(v);
    }
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
}

export const http = (method, config) => {
  let { url } = config;
  const {
    headers = {},
    data = {},
    paramsSerializer,
    options,
    Auth = null,
    doClean = true,
    deleteInParams = true,
  } = config;
  const paramsKey =
    method === 'get' || (method === 'delete' && deleteInParams)
      ? 'params'
      : 'data';

  let requestHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-Mode': 'seller-web',
    ...headers,
  };

  const token = getAuthToken();
  if (token) {
    requestHeaders.Authorization = Auth || `Bearer ${token}`;
  }

  let planDetails = null;

  if (!planDetails && getItem(SELECTED_STORE_ID_KEY)) {
    planDetails = {
      'x-dukaan-store-plan': getItem(ACTIVE_PLAN_NAME_KEY),
      'x-dukaan-store-id': getItem(SELECTED_STORE_ID_KEY),
    };
  }

  if (
    planDetails &&
    (url.includes('api.mydukaan.io') ||
      url.includes('api-enterprise.mydukaan.io'))
  ) {
    requestHeaders = { ...requestHeaders, ...planDetails };
  }

  const axiosOptions = {
    method,
    url,
    headers: requestHeaders,
    [paramsKey]: doClean ? clean(data) : data,
    ...options,
  };

  if (paramsSerializer) {
    axiosOptions.paramsSerializer = paramsSerializer;
  }

  return new Promise((resolve, reject) => {
    axios(axiosOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          reject(response);
        } else {
          reject(new Error('Something went wrong'));
        }
      });
  });
};

export const getRequest = (config) => http('get', config);

export const postRequest = (config) => http('post', config);

export const putRequest = (config) => http('put', config);

export const patchRequest = (config) => http('patch', config);

export const deleteRequest = (config) => http('delete', config);
