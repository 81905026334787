import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { CATEGORIES_URL, CATEGORIES_BULK_ACTIONS_URL } from '../ApiUrls';
import { MULTI_LEVEL_CATEGORY_MAX_DEPTH_SELECT_CATEGORY } from '../Catalogue/constants';
import {
  LIST_VIEW,
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../constants';
import { CATEGORY_FILTER_KEYS } from '../Catalogue/constants';
import { noop } from '../utils';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';
import useLocalStorage from './useLocalStorage';

const DEFAULT_PAYLOAD = {
  page: 1,
  search: '',
  orderingKey: null,
  orderingDirection: '',
};

const useCategoriesOptions = (props) => {
  const {
    canFetch = true,
    firstOption = null,
    maxDepth = MULTI_LEVEL_CATEGORY_MAX_DEPTH_SELECT_CATEGORY,
    hideSubCategory = false,
    flattenTree = false,
  } = props || {};
  const { enqueueSnackbar } = useCustomSnackbar();
  const [categoryIdMap, setCategoryIdMap] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [isUpdatingOrderCategories, setIsUpdatingOrderCategories] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [loadAfterBulkUpdate, setLoadAfterBulkUpdate] = useState(false);

  const [options, setOptions] = useState([]);
  const [payload, setPayload] = useState(DEFAULT_PAYLOAD);

  const [prevSearchText, setPrevSearchText] = useState('');
  const [filters, setFilters] = useState({});
  const [categoriesCount, setCategoriesCount] = useLocalStorage(
    'categoryCount',
    null
  );
  const [layout, setLayout] = useLocalStorage('__category_view__', LIST_VIEW);
  const recurrsivelyPushToTree = (root, parent, child) => {
    // if current root is parent,
    // check if parent already has sub_catgeries
    // push child accordinly
    // return true for child item pushed to tree
    if (root.id === parent.id) {
      const temp = [...(root?.sub_categories ?? [])];
      temp.push(child);
      root.sub_categories = temp;
      return true;
    }
    // else, for each root go to its sub_category and find the parent
    if (root?.sub_categories) {
      for (const newRoot of root.sub_categories) {
        const checkIsPushed = recurrsivelyPushToTree(newRoot, parent, child);
        if (checkIsPushed) return true;
      }
    }
  };
  const formSubCategoryTree = (subCategoryTree, subcategories) => {
    // 1. find all depth 1 items
    // 2. push each item to tree with a new sub_categories key
    const depth1Items = subcategories.filter((item) => item.depth === 1);
    depth1Items.forEach((item) =>
      subCategoryTree.push({
        ...item,
        label: item.name,
        value: item.id,
        sub_categories: [],
      })
    );
    // for other items,
    // for isRadiosSelect = true, follow maxDepthForRadio value to form tree
    // 1. sort in asc order of depth
    // 2. find parent in subcategories
    // 3. find parent from step 2 in subCategoryTree
    // 4. push item to step 2 parent.sub_categories in subCategoryTree
    const greaterDepthItems = subcategories.filter(
      (item) => item.depth > 1 && item.depth <= maxDepth
    );
    if (greaterDepthItems.length > 0) {
      if (flattenTree) {
        // if flat array is required, push child item after the parent item in subCategoryTree
        greaterDepthItems
          .sort((a, b) => a.depth - b.depth)
          .forEach((child) => {
            const parentIndex = subCategoryTree.findIndex(
              (parent) => parent.id === (child?.parent_id || child?.parent)
            );
            subCategoryTree.splice(parentIndex + 1, 0, {
              ...child,
              label: child.name,
              value: child.id,
            });
          });
      } else {
        greaterDepthItems
          .sort((a, b) => a.depth - b.depth)
          .forEach((child) => {
            const childObject = {
              ...child,
              label: child.name,
              value: child.id,
            };
            const parentObject = subcategories.find(
              (parent) =>
                parent.id === (childObject?.parent_id || childObject?.parent)
            );
            for (const root of subCategoryTree) {
              const isChildPushed = recurrsivelyPushToTree(
                root,
                parentObject,
                childObject
              );
              if (isChildPushed) break;
            }
          });
      }
    }
  };
  const formatResults = (results) => {
    const finalOptions = [];
    const categoryIDMap = {};
    results.forEach((parentCat) => {
      const subCategoryTree = [];
      if (parentCat?.sub_categories?.length > 0 && !hideSubCategory) {
        formSubCategoryTree(subCategoryTree, parentCat?.sub_categories);
      }
      if (flattenTree) {
        // if flat array is required, push depth 1 category directly to finalOptions
        finalOptions.push(
          {
            ...parentCat,
            label: parentCat.name,
            value: parentCat.id,
            productCount: parentCat.product_count,
          },
          ...subCategoryTree
        );
      } else {
        finalOptions.push({
          ...parentCat,
          label: parentCat.name,
          value: parentCat.id,
          productCount: parentCat.product_count,
          sub_categories: subCategoryTree,
        });
      }
      parentCat?.sub_categories?.forEach(
        (each) => (categoryIDMap[each.id] = each.name)
      );
      categoryIDMap[parentCat.id] = parentCat.name;
    });
    return { categoryIDMap, finalOptions };
  };
  const fetchCategories = useCallback(
    (callbackFunc = noop) => {
      if (loading) {
        return false;
      }
      let cancel;
      setLoading(true);
      const params = { ...payload };
      if (params.search === '') {
        delete params.search;
      }
      if (params.orderingKey !== '' && params.orderingKey !== null) {
        params.ordering = `${
          params.orderingDirection === SORTING_ORDER_ASCENDING ? '' : '-'
        }${params.orderingKey}`;
      }
      delete params.orderingDirection;

      // Remove unused filter keys from payload
      const newFilterKeys = Object.keys(filters);
      CATEGORY_FILTER_KEYS.forEach((key) => {
        if (!newFilterKeys.includes(key)) delete params[key];
      });

      getRequest({
        url: CATEGORIES_URL,
        data: { ...params, page_size: 30, v2: true },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then(({ count, results }) => {
          setCategoriesCount(count);
          const op = [];
          if (firstOption && payload.page === 1 && payload.search === '') {
            op.push(firstOption);
          }
          if (payload.page === 1) {
            setHasMore(results.length < count);
            const { categoryIDMap, finalOptions } = formatResults(results);
            setCategoryIdMap({ ...categoryIDMap });
            setOptions([...op, ...finalOptions]);
          } else {
            setHasMore(options.length + results.length < count);
            const { categoryIDMap, finalOptions } = formatResults(results);
            setCategoryIdMap((prev) => ({ ...prev, ...categoryIDMap }));
            setOptions((prev) => [...prev, ...finalOptions]);
          }
          callbackFunc();
        })
        .catch((e) => {
          console.log(e);
          if (hasMore) setHasMore(false);
          if (axios.isCancel(e)) {
            return false;
          }
        })
        .finally(() => {
          setSortLoading(false);
          setLoading(false);
          setLoadAfterBulkUpdate(false);
        });
      return () => cancel();
    },
    [payload]
  );
  const updatePayload = (newParams, reason) => {
    // console reason to get the cause for update
    // console.log({ newParams, reason });
    setPayload((prevPayload) => ({ ...prevPayload, ...newParams }));
  };
  const onSearch = (search) => {
    const { search: prevSearch } = payload;
    setPrevSearchText(prevSearch);
    updatePayload({ page: 1, search }, 'search ');
  };
  const setPageNumber = () => {
    const { page } = payload;
    updatePayload({ page: page + 1 }, 'scroll');
  };

  const toggleOrderingSort = (
    newOrderingKey,
    currentOrderingKey,
    orderDir = null
  ) => {
    if (loading) {
      return;
    }
    setSortLoading(true);
    const { orderingDirection } = payload;
    let newKey = newOrderingKey;
    let newDirection;

    if (orderDir) {
      newDirection = orderDir;
    } else {
      if (currentOrderingKey !== newOrderingKey) {
        newKey = newOrderingKey;
        newDirection = SORTING_ORDER_DESCENDING;
      } else if (orderingDirection === SORTING_ORDER_DESCENDING) {
        newDirection = SORTING_ORDER_ASCENDING;
      } else {
        newKey = ''; // reset case
      }
    }
    updatePayload(
      {
        page: 1,
        orderingDirection: newDirection,
        orderingKey: newKey,
      },
      'toggle direction'
    );
  };
  const refetchCategories = () => {
    setPayload(DEFAULT_PAYLOAD);
  };

  const updateOrderOfCategories = (updatedCategories, saveCallback = noop) => {
    const transformedCategories = updatedCategories.map((el) => ({
      id: el.id,
      position: el.position,
    }));
    setIsUpdatingOrderCategories(true);
    patchRequest({
      url: CATEGORIES_URL,
      data: {
        categories: transformedCategories,
      },
    })
      .then(() => {
        setIsUpdatingOrderCategories(false);
        fetchCategories();
        saveCallback();
        enqueueSnackbar('Categories rearranged successfully.');
      })
      .catch(() => {
        setIsUpdatingOrderCategories(false);
        enqueueSnackbar('Something went wrong.', 'error');
        saveCallback();
      });
  };

  const updateCategoriesBulk = (
    payload = {},
    successCallback = noop,
    errorCallback = noop
  ) => {
    postRequest({
      url: CATEGORIES_BULK_ACTIONS_URL,
      data: payload,
    })
      .then((res) => {
        enqueueSnackbar('Bulk update is being processed', 'info');
        successCallback(res);
      })
      .catch((e) => {
        console.log(e);
        const error =
          e?.data?.data?.error ||
          e?.data?.data?.non_field_errors?.join(', ') ||
          'Something went wrong. Try again later.';
        enqueueSnackbar(error, 'error');
        errorCallback(e);
      })
      .finally(noop);
  };

  const applyCategoryFilters = (newFilters) => {
    setFilters(newFilters);
    updatePayload(
      {
        page: 1,
        ...newFilters,
      },
      'filters'
    );
  };

  useEffect(() => {
    if (canFetch) {
      fetchCategories();
    }
  }, [canFetch, fetchCategories, payload]);

  return {
    categoryIdMap,
    categoryOptions: options,
    categoriesCount,
    fetchCategories,
    isUpdatingOrderCategories,
    hasMore,
    layout,
    loading,
    sortLoading,
    onSearch,
    ...payload,
    prevSearchText,
    refetchCategories,
    setCategoryOptions: setOptions,
    setLayout,
    setPageNumber,
    toggleOrderingSort,
    updateOrderOfCategories,
    updateCategoriesBulk,
    filters,
    applyCategoryFilters,
    loadAfterBulkUpdate,
    setLoadAfterBulkUpdate,
  };
};

export default useCategoriesOptions;
