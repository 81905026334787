import React, { Component } from 'react';
import cx from 'classnames';
import FullscreenBounceLoader from './FullscreenBounceLoader';
import SpinnerButton from './SpinnerButton';
import { logoutUser } from '../utils/user';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    const { props, state } = this;
    const { innerError = false } = props;
    if (state.hasError) {
      if (localStorage.getItem('firstError')) {
        localStorage.setItem('firstError', false);
      } else {
        localStorage.setItem('firstError', true);
        document.location.reload();
        return <FullscreenBounceLoader />;
      }
      return (
        <>
          <div
            className={cx('d-flex justify-end underline-anchor-section bg-white-i', {
              'inner-error': innerError,
            })}
          >
            <a
              onClick={() => logoutUser()}
              className="section-text-4 c-black-3 mt16 mr16 cur-p underline-anchor-text"
            >
              Sign out
            </a>
          </div>
          <div
            className={cx(`auth-layout d-flex full-h bg-white-i`, {
              'inner-error': innerError,
            })}
          >
            <div className="d-flex-c-c flex-column card-layout mt0">
              <img
                src="/images/ghosts.svg"
                alt="ghosts"
                height={188}
                width={256}
                className="mb48"
              />
              <p className="section-text-1 mb12">Something went wrong!</p>
              <p className="text-1 c-black-3 mb40">
                Please refresh the page or try again later
              </p>
              <SpinnerButton
                showAnimation
                label="Refresh page"
                onClick={() => document.location.reload()}
              />
            </div>
          </div>
        </>
      );
    }
    return props.children;
  }
}
