import { useCallback, useEffect, useState } from 'react';

import { getRequest } from '../utils/http';
import { CATEGORY_URL } from '../ApiUrls';

const useCategoryDetails = (uuid) => {
  const [loading, setLoading] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({});

  const url = CATEGORY_URL(uuid);

  const refetch = useCallback(() => {
    if (!uuid) return;
    setLoading(true);
    getRequest({ url })
      .then(({ data }) => {
        setLoading(false);
        setCategoryDetails(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [url]);

  useEffect(refetch, [refetch]);

  return [loading, categoryDetails, refetch];
};

export default useCategoryDetails;
