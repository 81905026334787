export const handleBounceLoaderPadding = (length) => {
  const bounceLoaderPadding = ((24 + 16 + 12) * length - 28 - 26) / 2;
  if (bounceLoaderPadding % 2 === 0)
    return { padding: `${bounceLoaderPadding}px 0` };
  return { padding: `${bounceLoaderPadding + 1}px 0` };
};

export const handleGetFieldValue = (
  values,
  languageDataFromState = [],
  option,
  fieldName = 'name'
) =>
  (languageDataFromState.length
    ? languageDataFromState ?? []
    : values?.language_data ?? []
  )
    .find((el) => el.store_language === option.id)
    ?.fields.find((el) => el.field_name === fieldName)?.field_value ?? '';
