import React from 'react';
import cx from 'classnames';
import { useTheme } from '../context/ThemeProvider';

const ImageOverlayView = ({ percentage, src, large, className }) => {
  const { isProductImageAspectRatioDifferent: isAspectRatioDifferent } =
    useTheme();

  return (
    <div
      className={cx('upload-image-with-overlay', className, {
        large,
        'theme-image-aspect-ratio': isAspectRatioDifferent === true,
      })}
    >
      {percentage && (
        <div className="overlay" style={{ width: `${100 - percentage}%` }} />
      )}
      <img src={src} alt="" />
    </div>
  );
};

export default ImageOverlayView;
