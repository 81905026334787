import React from 'react';
import { FormHelperText } from '@material-ui/core';

export const FieldInfo = ({ helpText }) => {
  if (helpText) {
    return <FormHelperText className="field-info">{helpText}</FormHelperText>;
  }
  return null;
};

export const modifyFormField = ([name, val], state, { changeValue }) => {
  changeValue(state, name, () => val);
};

export const isNotEmptyOrNull = ({ value }) =>
  value !== '' && value !== null && value !== undefined;

export const areTwoValuesSame = (existingValue, updatedValue) =>
  [existingValue, undefined, null, ''].includes(updatedValue);
