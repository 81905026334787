export const DEFAULT_ALLOWED_TAGS = [
  'a',
  'b',
  'i',
  'u',
  'li',
  'ol',
  'ul',
  'p',
  'br',
  'hr',
  'strike',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'sup',
  'sub',
  'strong',
  'em',
  'ins',
  'del',
];

export const DEFAULT_ALLOWED_ATTR = ['style', 'class', 'href', 'target'];

export const THUMBNAIL_TYPE = {
  YOUTUBE: 'youtube',
};

export const FLOAT_VALUE_LIMIT = 999999999;
export const FLOAT_VALUE_LIMIT_IN_THOUSANDS = 99999;

export const SNACKBAR_VARIANTS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};
