import React, { useState } from 'react';
import { ORDER_DUKAAN_SHIPMENT_LABEL_URL } from '../ApiUrls';
import { useAppContext } from '../context/AppContext';
import { EVENT_ORDER_SHARE_RECEIPT } from '../events';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useFileDownload from '../hooks/useFileDownload';
import { noop } from '../utils';
import { TrackEvent } from '../utils/analytics';
import { getRequest } from '../utils/http';
import Modal from './Modal';

const DownloadInvoiceLabelModal = ({
  isBulk = false,
  shipmentUuid,
  open,
  closeModal,
  printType = 'label',
  downloadInvoiceAndLabel = noop,
}) => {
  const [recLoading, setRecLoading] = useState(false);

  const { business } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { downloadFile } = useFileDownload();

  const handleDownload = (fileType = 'a4') => {
    if (!recLoading) {
      TrackEvent(EVENT_ORDER_SHARE_RECEIPT, business);
      setRecLoading(true);
      enqueueSnackbar('Your receipt download will begin shortly!');
      getRequest({
        url: ORDER_DUKAAN_SHIPMENT_LABEL_URL(shipmentUuid),
        data: { pdf_format: fileType },
      })
        .then((res) => {
          let fileLink = null;

          if (printType === 'label') {
            fileLink = res.data?.label_link;
          }

          if (printType === 'invoice') {
            fileLink = res.data?.invoice_link;
          }

          downloadFile(fileLink, true);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setRecLoading(false);
        });

      closeModal();
    }
  };

  const handleBulkDownload = (fileType = 'a4') => {
    downloadInvoiceAndLabel({ pdf_format: fileType });
    closeModal();
  };

  return (
    <Modal
      open={open}
      maxWidth="lg"
      closeModal={closeModal}
      className="download-report-modal order-report-modal"
    >
      <div className="download-report-modal-content">
        <h2 className="section-text-4">Choose receipt type</h2>
        <div className="download-via mt8">
          <div
            className="tile order-receipt-type-icon mr24"
            onClick={() => {
              if (isBulk) {
                handleBulkDownload('a4');
              } else {
                handleDownload('a4');
              }
            }}
          >
            <img src="/images/a4-pdf.svg" alt="A4" />
            <span className="section-text-5">A4</span>
          </div>
          <div
            className="tile order-receipt-type-icon mr24"
            onClick={() => {
              if (isBulk) {
                handleBulkDownload('thermal');
              } else {
                handleDownload('thermal');
              }
            }}
          >
            <img src="/images/print.svg" alt="Thermal" />
            <span className="section-text-5">Thermal</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadInvoiceLabelModal;
