import { GST_PERCENTAGE } from '../Subscription/constants';
import { defaultCurrency } from './currencies';

export const numberWithPower = (number, power) => Number(number) ** power;

export const powerWithTen = (power) => numberWithPower(10, power);

export const roundTo = (number, decimal = 2) => {
  const power = powerWithTen(decimal) || 1;
  return Math.round(number * power) / power;
};

export const numberWithCommas = (num = 0) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatNumber = (num = 0, country = 'en-IN', values = {}) =>
  num.toLocaleString(country, values);

export const formatNumberToK = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
};

export const formatMoney = (
  num,
  places = 2,
  symbol = defaultCurrency.symbol,
  thousand = ',',
  decimal = '.'
) => {
  let number = roundTo(num);
  const negative = number < 0 ? '-' : '';
  const i = `${parseInt(
    (number = Math.abs(+number || 0).toFixed(places)),
    10
  )}`;

  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  let last = '';

  if (places && Math.abs(number - i)) {
    last = `${decimal}${Math.abs(number - i)
      .toFixed(places)
      .slice(2)}`;
  }

  return (
    negative +
    symbol +
    (j ? i.substr(0, j) + thousand : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousand}`) +
    last
  );
};

export const gstValue = (num) => Math.floor((GST_PERCENTAGE / 100) * num);

export const getPlanPriceAsObject = (price, taxPercentage = GST_PERCENTAGE) => {
  if (isNaN(price)) {
    throw new Error('price must be a valid number');
  }

  // Ensure number2 is a valid number
  if (isNaN(taxPercentage)) {
    throw new Error('taxPercentage must be a valid number');
  }

  // Calculate basePrice
  const basePrice = price / (1 + taxPercentage / 100);

  // Calculate taxAmount
  const taxAmount = price - basePrice;

  // Return the result as an object
  return {
    basePrice: Math.round(basePrice),
    taxAmount: Math.round(taxAmount),
  };
};
