import React, { useEffect, useState } from 'react';
import useCategoriesOptions from '../../hooks/useCategoriesOptions';
import useModal from '../../hooks/useModal';
import CategoryModal from '../../shared/CategoryModalNew';
import { FormInput } from '../../shared/Form';
import { CrossIcon } from '../../SvgIcon';

const SelectedCategoriesPickerField = ({
  form,
  label,
  prefixName,
  fieldIndex,
  data = [],
  isAttribute = false,
}) => {
  const categoryContext = useCategoriesOptions({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesWhole, setSelectedCategoriesWhole] = useState([]);

  const pickerLabel =
    data.length > 0
      ? `${data.length} categor${data.length > 1 ? 'ies' : 'y'} selected`
      : '';

  const {
    isOpen: isCategoryModalOpen,
    openModal: openCategoryModal,
    closeModal: closeCategoryModal,
  } = useModal();

  useEffect(() => {
    const selected = data?.value ?? [];

    if (isAttribute) {
      setSelectedCategories(data);
    } else {
      setSelectedCategoriesWhole(selected);
      setSelectedCategories(selected.map((item) => item.id));
    }
  }, []);

  return (
    <>
      <FormInput
        labelText={label}
        name={`${prefixName}[${fieldIndex}].category_names`}
        placeholder="Select categories"
        onClick={openCategoryModal}
        {...(isAttribute ? { value: pickerLabel } : {})}
        readOnly
        appendText={
          <>
            {data?.length || data?.value?.length ? (
              <CrossIcon
                height={16}
                width={16}
                className="cur-p mr8"
                onClick={() => {
                  if (isAttribute) {
                    form.mutators.modifyFormField(prefixName, []);
                  } else {
                    form.mutators.modifyFormField(
                      `${prefixName}[${fieldIndex}].value`,
                      []
                    );
                    form.mutators.modifyFormField(
                      `${prefixName}[${fieldIndex}].category_names`,
                      ''
                    );
                  }
                  setSelectedCategories([]);
                  setSelectedCategoriesWhole([]);
                }}
              />
            ) : null}
          </>
        }
      />
      {isCategoryModalOpen && (
        <CategoryModal
          canSelectedCategoriesBeEmpty
          categoryContext={categoryContext}
          initiallySelectedCategoriesWhole={selectedCategoriesWhole}
          handleClose={({
            categories,
            selectedCategoriesWhole: selectedCategoriesWholeArg,
          }) => {
            setSelectedCategories(categories);
            setSelectedCategoriesWhole(selectedCategoriesWholeArg);
            const formattedCategories = selectedCategoriesWholeArg.map(
              (category) => ({
                id: category.id,
                name: category.name,
                uuid: category.uuid,
                slug: category.slug,
                type: 'category',
                images: category?.image ?? category?.images ?? '',
              })
            );
            if (isAttribute) {
              form.mutators.modifyFormField(prefixName, categories);
            } else {
              form.mutators.modifyFormField(
                `${prefixName}[${fieldIndex}].value`,
                formattedCategories
              );
              form.mutators.modifyFormField(
                `${prefixName}[${fieldIndex}].category_names`,
                formattedCategories.map((item) => item.name).join(', ')
              );
            }
            closeCategoryModal();
          }}
          hideAddCategoryBtn
          open={isCategoryModalOpen}
          selected={selectedCategories}
          setselectedCategories={setSelectedCategories}
        />
      )}
    </>
  );
};

export default SelectedCategoriesPickerField;
