import { useState } from 'react';
import { patchRequest } from '../utils/http';
import {
  PRODUCT_URL_ADVANCE,
  MISC_PRODUCT_URL,
  PRODUCTS_BULK_UPDATE_URL_ADVANCE,
  PRODUCT_PARTIAL_UPDATE,
} from '../ApiUrls';
import useCustomSnackbar from './useCustomSnackbar';
import { noop } from '../utils';
import { useAppContext } from '../context/AppContext';

const useProductUpdate = ({ uuid, storeId }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useCustomSnackbar();
  const { isVendor } = useAppContext();

  const updateMiscData = (miscData, miscDataUpdateCb = noop) => {
    patchRequest({
      url: MISC_PRODUCT_URL(uuid),
      data: miscData,
      doClean: false,
    })
      .then((miscRes) => {
        if (miscRes.data) {
          miscDataUpdateCb(miscRes.data);
        }
      })
      .catch(console.log);
  };

  const updateProduct = (
    params,
    productUpdateSuccessCb,
    miscData = null,
    miscDataUpdateCb = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: PRODUCT_URL_ADVANCE({ productUId: uuid, storeId }),
      data: params,
      doClean: false,
    })
      .then((res) => {
        if (res.data) {
          productUpdateSuccessCb(res.data);
          if (miscData) {
            return updateMiscData(miscData, (value) => {
              miscDataUpdateCb(value);
            });
          }
        }
      })
      .catch((err) => {
        if (!isVendor)
          enqueueSnackbar(
            err.data?.data?.description?.length
              ? 'Please reduce product description, content length exceeded!'
              : 'Something went wrong.',
            'error'
          );
      })
      .finally(() => setSubmitting(false));
  };

  const bulkUpdateProducts = (payload, callback = noop) => {
    setSubmitting(true);
    patchRequest({
      url: PRODUCTS_BULK_UPDATE_URL_ADVANCE(storeId),
      data: payload,
      doClean: false,
    })
      .then((res) => {
        callback(res.data);
        enqueueSnackbar('Products updated successfully!');
      })
      .catch((e) => {
        if (e?.data?.data && Array.isArray(e?.data?.data)) {
          const errors = e?.data?.data[0];

          Object.keys(errors)?.map((key) =>
            errors[key].map((err) => enqueueSnackbar(err, 'error'))
          );
        } else {
          enqueueSnackbar('Something went wrong.', 'error');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const updateProductPartially = (payload, successCb, errorCb) => {
    patchRequest({
      url: PRODUCT_PARTIAL_UPDATE(storeId, uuid),
      data: payload,
    })
      .then((res) => {
        successCb(res?.data ?? {});
      })
      .catch((err) => errorCb(err));
  };

  return {
    isSubmitting,
    updateProduct,
    bulkUpdateProducts,
    updateMiscData,
    updateProductPartially,
  };
};

export default useProductUpdate;
