import React, { useState } from 'react';
import cx from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { FormControlLabel } from '@material-ui/core';
import Mustache from 'mustache';
import CustomRadio from '../../shared/CustomRadio';
import Modal from '../../shared/Modal';
import { noop, parseHTML, urlify } from '../../utils';
import SpinnerButton from '../../shared/SpinnerButton';
import useQuery from '../../hooks/useQuery';
import { convertToVariablesInjectedTags } from '../constants';
import { toTitleCase } from '../../utils/string';
import useEnterPressAction from '../../hooks/useEnterPressAction';

export const TemplateItem = ({
  body = '',
  selected = false,
  onClick = noop,
  templateVariables = {},
  title,
  className = '',
}) => {
  const query = useQuery();
  const selectedChannelId = Number(query.get('channel'));

  return (
    <div
      className={cx(`template-message-item d-flex-c-s ${className} `, {
        selected,
      })}
      onClick={onClick}
    >
      <div className="mr10">
        {selectedChannelId === 2 ? (
          <>
            <div className="fw600 text-8 mb4">{toTitleCase(title)}</div>
            <span>
              {ReactHtmlParser(
                urlify(
                  Mustache.render(
                    convertToVariablesInjectedTags(body.replaceAll('*', '')),
                    templateVariables
                  )
                )
              )}
            </span>
          </>
        ) : (
          <span>{ReactHtmlParser(parseHTML(urlify(body)))}</span>
        )}
      </div>
      <FormControlLabel
        control={<CustomRadio className="p0-i" />}
        checked={selected}
        onClick={onClick}
      />
    </div>
  );
};

const MessageTemplatesModal = ({ items = [], open, closeModal, onSubmit }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEnterPressAction(
    open,
    !selectedTemplate ? noop : () => onSubmit(selectedTemplate)
  );

  return (
    <Modal open={open} closeModal={closeModal} maxWidth="sm">
      <h2 className="section-text-4 mb32">Message templates</h2>
      <div className="template-messages-grid show-scrollbar">
        {items.map((template) => (
          <TemplateItem
            body={template.body}
            selected={selectedTemplate?.id === template.id}
            onClick={() => setSelectedTemplate(template)}
            key={template.id}
          />
        ))}
      </div>

      <div className="text-center mt24">
        <SpinnerButton
          type="button"
          className="btn-primary-4"
          disabled={!selectedTemplate}
          onClick={() => onSubmit(selectedTemplate)}
        >
          Use template
        </SpinnerButton>
      </div>
    </Modal>
  );
};
export default MessageTemplatesModal;
