import React from 'react';
import cx from 'classnames';
import Modal from '../shared/Modal';
import { ReactComponent as SuccessBlueIcon } from '../../images/icons/success-blue.svg';
import SpinnerButton from '../shared/SpinnerButton';
import useResponsive from '../hooks/useResponsive';
import { capitaliseFirst } from '../utils/string';
import useEnterPressAction from '../hooks/useEnterPressAction';

const AcceptReturnModal = (props) => {
  const { isMobile } = useResponsive();
  const {
    open,
    closeModal,
    updatedOrder: {
      order: {
        active_returns: [{ return_shipment: returnShipment = {} } = {}] = [],
      } = {},
    } = {},
  } = props;

  const {
    carrier_meta: { carrier_json: { name: carrier } = {} } = {},
    awb = '',
    tracking_link: trackingLink = '',
  } = returnShipment || {};

  const handleClick = () => {
    closeModal();
    window.location.reload();
  };

  useEnterPressAction(open, handleClick);

  return (
    <Modal
      open={open}
      className="shipment-created-modal"
      closeModal={closeModal}
      showCloseIcon={false}
      paperClass={cx({ 'mobile-modal': isMobile })}
    >
      <SuccessBlueIcon className="header-svg-icon" />
      <div className="ship-success-header">
        <div className="text-center">
          <h2 className="ship-header-2">Return accepted</h2>
          <h6 className="ship-header-6">
            The return shipment has been created &#38; the customer will be
            notified regarding the pickup shortly.
          </h6>
        </div>
        <div className="ship-details-table-container">
          <div className="ship-details-table">
            <div className="ship-details-table-row">
              <div className="ship-details-table-column">
                <p className="text-medium mb2">Shipping via</p>
                <p className="d-flex">{capitaliseFirst(carrier)}</p>
              </div>
              <div className="ship-details-table-column">
                <p className="text-medium mb2">Tracking ID</p>
                <a
                  rel="noreferrer"
                  href={trackingLink}
                  target="_blank"
                  className={cx('cur-p', {
                    'anchor-1 underline': trackingLink,
                  })}
                >
                  {awb}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt32 text-center">
        <SpinnerButton
          type="button"
          className="btn btn-primary shipment-success-btn"
          onClick={handleClick}
        >
          <span className=" text-2 text-medium">Got it</span>
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default AcceptReturnModal;
