import axios from 'axios';
import { useEffect, useState } from 'react';
import qs from 'query-string';
import {
  CAMPAIGN_CHANNELS_URL,
  CAMPAIGN_DETAILS_URL,
  CAMPAIGN_ESTIMATE_URL,
  CAMPAIGN_SEND_TEST_MESSAGE_URL,
  CAMPAIGN_STATS_URL,
  CAMPAIGN_TEMPLATES_BY_CHANNEL_URL,
  CAMPAIGN_URL,
  PRODUCT_SHORTLINK_URL,
} from '../ApiUrls';
import {
  CHANNEL_NAMES_STATS_MAP,
  EMAIL_CHANNEL_ID,
} from '../Campaign/constants';
import { useAppContext } from '../context/AppContext';
import {
  EVENT_CAMPAIGN_PUBLISH_FAILURE,
  EVENT_CAMPAIGN_PUBLISH_SUCCESS,
} from '../events';
import { noop } from '../utils';
import { TrackEvent } from '../utils/analytics';
import { getRequest, postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';
import { copyToClipboard } from '../shared/CopyLinkInput';
import { useCountry } from '../context/CountryProvider';
import {
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING,
} from '../constants';

const useCampaign = (fetchOnLoad = true) => {
  const { business } = useAppContext();
  const { isInternational } = useCountry();

  const [loading, setLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [campaignStats, setCampaignStats] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isChannelsLoading, setIsChannelsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [filters, setFilters] = useState({});
  const [templateVariables, setTemplateVariables] = useState({});
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [pageNumberTemplates, setPageNumberTemplates] = useState(1);
  const [hasMoreTemplates, setHasMoreTemplates] = useState(false);

  const [orderingKey, setOrderingKey] = useState('created_at');
  const [orderingDirection, setOrderingDirection] = useState(
    SORTING_ORDER_DESCENDING
  ); // asc or desc

  const { enqueueSnackbar } = useCustomSnackbar();

  const toggleOrderingSort = (key) => {
    if (campaignsLoading) {
      return;
    }
    if (orderingKey !== key) {
      setOrderingKey(key);
      setOrderingDirection(SORTING_ORDER_DESCENDING);
    } else if (orderingDirection === SORTING_ORDER_DESCENDING) {
      setOrderingDirection(SORTING_ORDER_ASCENDING);
    } else {
      setOrderingDirection(SORTING_ORDER_DESCENDING); // reset case
    }
    setPageNumber(1);
  };

  const fetchCampaigns = () => {
    if (campaignsLoading || !fetchOnLoad) {
      return false;
    }
    setCampaignsLoading(true);
    const params = {
      page: pageNumber,
      ...filters,
    };

    if (orderingKey !== '') {
      params.ordering = `${
        orderingDirection === SORTING_ORDER_DESCENDING ? '-' : ''
      }${orderingKey}`;
    }

    getRequest({
      url: CAMPAIGN_URL(business.uuid),
      data: params,
      cancelToken: new axios.CancelToken((c) => c),
    })
      .then((res) => {
        const { count, results } = res;

        if (pageNumber === 1) {
          setHasMore(results.length < count);
          setCampaigns(results);
        } else {
          setHasMore(campaigns.length + results.length < count);
          setCampaigns((prevState) => [...new Set([...prevState, ...results])]);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return false;
        }
      })
      .finally(() => setCampaignsLoading(false));
  };

  const fetchCampaignStats = () => {
    if (!fetchOnLoad) {
      return false;
    }
    setLoading(true);
    getRequest({ url: CAMPAIGN_STATS_URL(business.uuid) })
      .then((res) => {
        const { data } = res;

        const stats = [
          {
            title: `ORDERS FROM MARKETING`,
            value: data?.orders_from_marketing ?? 0,
          },
          {
            title: 'SALES FROM MARKETING',
            value: data?.sales_from_marketing ?? 0,
            type: 'cost',
          },
          ...(!isInternational
            ? [
                {
                  title: `MARKETING COST`,
                  value: data?.marketing_cost ?? 0,
                  type: 'cost',
                },
              ]
            : []),
        ];

        if (
          Array.isArray(data.channel_stats) &&
          data.channel_stats.length > 0
        ) {
          data.channel_stats
            .filter((channel) =>
              isInternational
                ? channel.event === 'msg_sent' &&
                  channel.channel === EMAIL_CHANNEL_ID
                : channel.event === 'msg_sent' &&
                  channel.channel !== EMAIL_CHANNEL_ID
            )
            .map((channel) =>
              stats.push({
                title: CHANNEL_NAMES_STATS_MAP[channel.channel],
                value: channel.total,
              })
            );
        }
        setCampaignStats(stats);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const fetchChannels = () => {
    setIsChannelsLoading(true);
    getRequest({ url: CAMPAIGN_CHANNELS_URL })
      .then((res) => {
        const { results } = res;
        setChannels(results);
      })
      .catch(console.log)
      .finally(() => {
        setIsChannelsLoading(false);
      });
  };

  const fetchCampaignDetails = (campaignId) => {
    setLoading(true);
    getRequest({ url: CAMPAIGN_DETAILS_URL(business.uuid, campaignId) })
      .then((res) => {
        const { data } = res;
        setCampaignData(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const fetchTemplates = (channelId, data, callback = noop, sb = noop) => {
    setTemplatesLoading(true);
    const params = {
      page: pageNumberTemplates,
      editable: true,
      ...data,
    };

    getRequest({
      url: CAMPAIGN_TEMPLATES_BY_CHANNEL_URL(business.uuid, channelId),
      data: params,
      paramsSerializer: (myParams) =>
        qs.stringify(myParams, { arrayFormat: 'repeat' }),
    })
      .then((res) => {
        const { count, results } = res;
        if (pageNumberTemplates === 1) {
          setHasMoreTemplates(results.length < count);
          setTemplates(results);
        } else {
          setHasMoreTemplates(templates.length + results.length < count);
          setTemplates((prevData) => [...new Set([...prevData, ...results])]);
        }
        sb(results);
      })
      .catch(console.log)
      .finally(() => {
        setTemplatesLoading(false);
        callback();
      });
  };

  const fetchCampaignCostEstimate = (payload, cb = noop) => {
    setLoading(true);
    postRequest({ url: CAMPAIGN_ESTIMATE_URL(business.uuid), data: payload })
      .then((res) => {
        const { data } = res;
        cb(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const sendCampaignTestMessage = (
    payload,
    cb = noop,
    isOpenModal = noop,
    isVerified = false
  ) => {
    if (!isVerified) {
      isOpenModal();
      return;
    }
    setSubmitting(true);
    postRequest({
      url: CAMPAIGN_SEND_TEST_MESSAGE_URL(business.uuid),
      data: payload,
    })
      .then((res) => {
        const { data } = res;
        cb(data);
      })
      .catch(() => {
        enqueueSnackbar('Testing messages limit reached for today!', 'error');
      })
      .finally(() => setSubmitting(false));
  };

  const createCampaign = (payload, cb = noop) => {
    setSubmitting(true);
    postRequest({ url: CAMPAIGN_URL(business.uuid), data: payload })
      .then((res) => {
        TrackEvent(EVENT_CAMPAIGN_PUBLISH_SUCCESS, business);
        cb(res);
      })
      .catch((e) => {
        TrackEvent(EVENT_CAMPAIGN_PUBLISH_FAILURE, business);
        if (e?.data?.data?.message) {
          enqueueSnackbar(e.data.data.message[0], 'error');
        } else {
          enqueueSnackbar('Something went wrong!', 'error');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const fetchProductShortlink = (productId) => {
    setLoading(true);
    postRequest({ url: PRODUCT_SHORTLINK_URL(business.uuid, productId) })
      .then(({ data }) => {
        copyToClipboard(data?.tracking_link, 'copy-product-link');
        enqueueSnackbar('Product short link copied to clipboard!');
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCampaigns();
  }, [pageNumber, orderingKey, orderingDirection]);

  useEffect(() => {
    fetchCampaignStats();
  }, []);

  return {
    campaigns,
    channels,
    templates,
    campaignData,
    fetchCampaignDetails,
    fetchTemplates,
    campaignStats,
    fetchCampaigns,
    fetchCampaignCostEstimate,
    createCampaign,
    loading,
    campaignsLoading,
    submitting,
    pageNumber,
    setPageNumber,
    hasMore,
    filters,
    setFilters,
    orderingKey,
    orderingDirection,
    toggleOrderingSort,
    fetchCampaignStats,
    fetchChannels,
    sendCampaignTestMessage,
    templateVariables,
    setTemplateVariables,
    templatesLoading,
    setTemplates,
    fetchProductShortlink,
    isChannelsLoading,
    hasMoreTemplates,
    pageNumberTemplates,
    setPageNumberTemplates,
  };
};

export default useCampaign;
