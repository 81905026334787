import { Menu } from '@material-ui/core';
import { useState } from 'react';
import { MenuItems } from '../shared/Menu';
import { ArrowIcon, UpArrowCloseIcon } from '../SvgIcon';
import useOrderUpdate from '../hooks/useOrderUpdate';
import useModal from '../hooks/useModal';
import ManualPaymentStatusModal from './ManualPaymentStatusModal';
import { noop } from '../utils';

const ManualPaymentStatusToggle = ({ uuid, successCallback = noop }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { markManualOrderPayment } = useOrderUpdate(uuid);
  const {
    isOpen: isPaymentStatusModalOpen,
    openModal: openPaymentStatusModal,
    closeModal: closePaymentStatusModal,
  } = useModal();

  const menuItems = [
    {
      labelText: 'Change to Paid',
      onClick: () => {
        setSelectedOption({ label: 'Paid', value: 'PAID' });
        openPaymentStatusModal();
      },
    },
    {
      labelText: 'Change to Cash on Delivery',
      onClick: () => {
        setSelectedOption({ label: 'Cash on Delivery', value: 'COD' });
        openPaymentStatusModal();
      },
    },
  ];

  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: '',
  });

  const handleButtonClick = (option) => {
    markManualOrderPayment(option, successCallback);
    closePaymentStatusModal();
  };

  return (
    <div>
      <div
        className="special-filter-btn manual-payment-dropdown"
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <span className="text-medium">Change payment status</span>

        <span className="d-inline-flex">
          {menuAnchorEl ? (
            <UpArrowCloseIcon height={16} width={16} />
          ) : (
            <ArrowIcon height={16} width={16} />
          )}
        </span>
      </div>
      <Menu
        id="special-filter-menu"
        className="menu-content-max-h"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginTop: 44,
            width: 215,
            paddingTop: 10,
            paddingBottom: 10,
          },
        }}
      >
        <MenuItems
          menuItems={menuItems}
          onCloseMenu={() => setMenuAnchorEl(null)}
        />
      </Menu>
      {isPaymentStatusModalOpen && (
        <ManualPaymentStatusModal
          open={isPaymentStatusModalOpen}
          closeModal={closePaymentStatusModal}
          selectedOption={selectedOption}
          onButtonClick={handleButtonClick}
        />
      )}
    </div>
  );
};

export default ManualPaymentStatusToggle;
