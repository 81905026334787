import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import cx from 'classnames';

import Switch from '../../../shared/Switch';
import useProductUpdate from '../../../hooks/useProductUpdateAdvance';
import useCustomSnackbar from '../../../hooks/useCustomSnackbar';
import QuantityInput from '../QuantityInput';
import { TrackEvent } from '../../../utils/analytics';
import { EVENT_PROD_SHOW } from '../../../events';
import { useAppContext } from '../../../context/AppContext';
import { useCountry } from '../../../context/CountryProvider';
import Modal from '../../../shared/Modal';
import { FormNumberInput, HiddenInput } from '../../../shared/Form';
import { noop, modifyFormField } from '../../../utils';
import SpinnerButton from '../../../shared/SpinnerButton';
import { ArrowDownIcon, ArrowUpIcon } from '../../../SvgIcon';
import ImageLoader from '../../../shared/ImageLoader';
import { editProductUrl } from '../../../UrlHelper';
import { isNotEmptyOrNull } from '../../../shared/Form/Shared';
import useModal from '../../../hooks/useModal';
import SKUFileUploadModal from '../SKUFileUploadModal';
import { deepClone } from '../../../Appearance/utils';
import useEnterPressAction from '../../../hooks/useEnterPressAction';
import useProductDetails from '../../../hooks/useProductDetailsAdvance';
import BounceLoader from '../../../shared/BounceLoader';
import { VARIANT_COLOR_TYPE } from '../../constants';

export const ProductHiddenSwitch = ({
  productUid,
  productDetails = {},
  checked,
  inStock,
  onUpdateStock,
  onUpdateHidden,
  updateSpecificProductCb = noop,
}) => {
  const [isVisible, setVisible] = useState(checked);
  const { enqueueSnackbar } = useCustomSnackbar();
  const { business, isVendor } = useAppContext();
  const { uuid: storeId } = business;

  const { updateProduct, updateProductPartially } = useProductUpdate({
    uuid: productUid,
    storeId,
  });
  const { refetchPromise: refetchProductDetailsPromise } = useProductDetails({
    uuid: productUid,
    storeId,
  });

  const [submitting, setSubmitting] = useState(false);

  const [open, setOpen] = useState(false);
  const {
    isOpen: showSKUFileUploadModal,
    closeModal: closeSSKUFileUploadModal,
    openModal: openSKUFileUploadModal,
  } = useModal();

  useEffect(() => {
    setVisible(checked);
  }, [checked]);

  const handleCheckChange = async (visible) => {
    if (visible) {
      const { error } = await refetchProductDetailsPromise();
      if (error) {
        console.log(error);
        enqueueSnackbar('Something went wrong', 'error');
        return;
      }
      const payload = { is_active: true };
      updateProductPartially(
        payload,
        (data) => {
          updateSpecificProductCb({
            ...data,
          });
          onUpdateStock(true);
          onUpdateHidden(false);
          TrackEvent(EVENT_PROD_SHOW, business);
          enqueueSnackbar(`Product is set to active`);
        },
        () => {
          enqueueSnackbar(
            'Something went wrong in updating the product',
            'error'
          );
        }
      );
    } else {
      const { error } = await refetchProductDetailsPromise();
      if (error) {
        console.log(error);
        enqueueSnackbar('Something went wrong', 'error');
        return;
      }

      const payload = { is_active: false };

      updateProductPartially(
        payload,
        (data) => {
          updateSpecificProductCb({
            ...data,
          });

          onUpdateHidden(true);
          enqueueSnackbar(`Product is set to hidden`);

          setVisible(false);
        },
        () => {
          enqueueSnackbar(
            'Something went wrong in updating the product',
            'error'
          );
        }
      );
    }
    if (!isVendor || (isVendor && !visible)) {
      setVisible(!isVisible);
    }
  };

  const handleInventoryUpdate = (payload = {}) => {
    setSubmitting(true);
    let outOfStockVariants = 0;
    payload.skus.forEach((sku) => {
      if (
        sku.inventory !== null &&
        sku.inventory !== '' &&
        Number(sku.inventory) === 0
      ) {
        outOfStockVariants += 1;
      }
    });
    if (payload.skus.length === 1) {
      if (outOfStockVariants === 1) payload.inventory_quantity = 0;
      else payload.inventory_quantity = payload.skus[0].inventory;
    }

    if (outOfStockVariants === payload?.skus?.length) {
      setSubmitting(false);
      setOpen(false);
      setVisible(!isVisible);
    } else {
      if (payload?.skus?.length === 1) {
        if (
          !isNotEmptyOrNull({ value: payload.skus[0].inventory }) ||
          !('inventory' in payload.skus[0])
        ) {
          payload.skus[0].inventory = null;
          payload.inventory_quantity = null;
        }
        payload.in_stock = true;
      }
      updateProduct(payload, (product) => {
        updateSpecificProductCb({
          ...product,
        });
        enqueueSnackbar(`Product is set in stock`);
        onUpdateStock(true);
        setSubmitting(false);
        setOpen(false);
      });
    }
  };

  return (
    <>
      <Switch
        dependsOnChecked
        checked={isVisible}
        onChange={handleCheckChange}
      />

      <InventoryModal
        open={open}
        submitting={submitting}
        closeModal={() => {
          setVisible(!isVisible);
          setOpen(false);
        }}
        productUuid={productDetails.uuid}
        onSubmit={handleInventoryUpdate}
        isProductVisible={isVisible}
      />
      {showSKUFileUploadModal && (
        <SKUFileUploadModal
          open={showSKUFileUploadModal}
          closeModal={closeSSKUFileUploadModal}
          isSubmitting={noop}
        />
      )}
    </>
  );
};

const InventoryUpdateInput = ({
  form,
  values,
  name = 'inventory',
  labelText = 'Quantity',
  initialValue = 0,
  classNames,
  placeholder,
}) => {
  const increment = useCallback(() => {
    const currentVal = Number(form.getFieldState(name)?.value) || 0;
    if (currentVal < 100000)
      form.mutators.modifyFormField(name, currentVal + 1);
  }, [form, values]);
  const decrement = useCallback(() => {
    const currentVal = Number(form.getFieldState(name)?.value) || 0;
    if (currentVal !== 0) form.mutators.modifyFormField(name, currentVal - 1);
    else form.mutators.modifyFormField(name, 0);
  }, [form, values]);

  return (
    <FormNumberInput
      labelText={labelText}
      name={name}
      className={`fill-available input-m0 ${classNames}`}
      placeholder={placeholder}
      initialValue={initialValue}
      isAllowed={(e) => {
        const { floatValue, value } = e;
        if (value.indexOf('.') !== -1) return false;

        return (
          !isNotEmptyOrNull({ value: floatValue }) ||
          (Number.isInteger(floatValue) && floatValue <= 999999999)
        );
      }}
      appendText={
        <div className="number-input-arrows">
          <ArrowUpIcon width={16} height={16} onClick={increment} />
          <ArrowDownIcon width={16} height={16} onClick={decrement} />
        </div>
      }
    />
  );
};

export const InventoryModal = ({
  productUuid,
  submitting = false,
  open,
  closeModal,
  onSubmit,
  isProductVisible,
}) => {
  const history = useHistory();
  const { business } = useAppContext();
  const { uuid: storeId } = business;
  const { loading, productDetails, refetch } = useProductDetails({
    uuid: productUuid,
    storeId,
  });
  const [valuesEmpty, setValuesEmpty] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const hasVariants = Boolean(
    productDetails.sku_data?.length &&
      productDetails.sku_data[0]?.attributes?.length > 0
  );

  useEffect(() => {
    if (open) refetch();
  }, [open]);

  const { enqueueSnackbar } = useCustomSnackbar();

  useEnterPressAction(
    open,
    submitting || valuesEmpty
      ? noop
      : () => {
          onSubmit(formValues);
        }
  );

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      maxWidth={hasVariants ? 'lg' : 'sm'}
      className="update-product-inventory-modal"
    >
      <h2 className="section-text-4 mb24">Update Inventory</h2>

      <Form
        mutators={{ ...arrayMutators, modifyFormField }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        render={({ form, values, handleSubmit }) => {
          const areAllValuesEmpty = values?.skus?.every(
            (sku) => !isNotEmptyOrNull({ value: sku.inventory })
          );
          setValuesEmpty(areAllValuesEmpty);
          setFormValues(values);

          if (loading || !Object.keys(productDetails).length)
            return <BounceLoader />;
          return (
            <>
              <HiddenInput form={form} name="id" value={productDetails.id} />
              <HiddenInput
                form={form}
                name="uuid"
                value={productDetails.uuid}
              />
              {productDetails?.sku_data?.map((sku, i) => (
                <>
                  <HiddenInput
                    form={form}
                    name={`skus[${i}].id`}
                    value={sku.id}
                  />
                  <HiddenInput
                    form={form}
                    name={`skus[${i}].uuid`}
                    value={sku.uuid}
                  />
                  <HiddenInput
                    form={form}
                    name={`skus[${i}].original_price`}
                    value={sku?.original_price}
                  />
                  <HiddenInput
                    form={form}
                    name={`skus[${i}].selling_price`}
                    value={sku?.selling_price}
                  />
                  <HiddenInput
                    form={form}
                    name={`skus[${i}].attributes`}
                    value={sku?.attributes}
                  />
                </>
              ))}
              {!hasVariants ? (
                <div className="d-flex align-center mb12">
                  <InventoryUpdateInput
                    form={form}
                    values={values}
                    name="skus[0].inventory"
                    initialValue={
                      productDetails?.sku_data[0]?.inventory || null
                    }
                    isAllowed={(e) => {
                      const { floatValue, value } = e;
                      if (value.indexOf('.') !== -1) return false;
                      if (floatValue && floatValue > 999999999) {
                        enqueueSnackbar('Inventory limit exceeded', 'error');
                        return false;
                      }
                      return (
                        !isNotEmptyOrNull({ value: floatValue }) ||
                        (Number.isInteger(floatValue) &&
                          floatValue <= 999999999)
                      );
                    }}
                  />
                </div>
              ) : (
                <div className="variants-inventory-update-table">
                  <div className="table-header-wrapper">
                    <div className="table-header product">Product</div>
                    <div className="table-header unit">Unit / Variant</div>
                    <div className="table-header input">Quantity</div>
                  </div>
                  <div className="table-data-container">
                    <div className="table-data-row">
                      <div className="product c-purple-1">
                        <ImageLoader
                          src={productDetails?.all_images?.[0]}
                          width={48}
                          height={48}
                        />
                        <div
                          onClick={() =>
                            history.push(editProductUrl(productDetails.uuid))
                          }
                          className="cur-p"
                        >
                          {productDetails.name}
                        </div>
                      </div>
                      <div className="unit">
                        {productDetails.sku_data?.map((sku, i) => {
                          const sizesLabel =
                            sku.attributes?.filter(
                              (attr) =>
                                attr.master_attribute !== VARIANT_COLOR_TYPE
                            )?.[0]?.master_attribute || 'size';
                          const color = sku.attributes?.find(
                            (attr) =>
                              attr.master_attribute === VARIANT_COLOR_TYPE
                          )?.value;
                          const size = sku.attributes?.find(
                            (attr) => attr.master_attribute === sizesLabel
                          )?.value;

                          return (
                            <div>
                              {[
                                size,
                                color && size ? '|' : '',
                                color ? window.ntc.name(color)[1] : '',
                              ].join(' ')}
                            </div>
                          );
                        })}
                      </div>
                      <div className="input">
                        {productDetails.sku_data?.map((sku, i) => (
                          <InventoryUpdateInput
                            form={form}
                            values={values}
                            classNames="inventory-update"
                            name={`skus[${i}].inventory`}
                            labelText={null}
                            placeholder=""
                            initialValue={sku.inventory || null}
                            isAllowed={(e) => {
                              const { floatValue, value } = e;
                              if (value.indexOf('.') !== -1) return false;
                              if (floatValue && floatValue > 999999999) {
                                enqueueSnackbar(
                                  'Inventory limit exceeded',
                                  'error'
                                );
                                return false;
                              }
                              return (
                                !isNotEmptyOrNull({ value: floatValue }) ||
                                (Number.isInteger(floatValue) &&
                                  floatValue <= 999999999)
                              );
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="text-center mt24">
                <SpinnerButton
                  type="submit"
                  className="btn-primary-4"
                  disabled={submitting || areAllValuesEmpty}
                  onClick={handleSubmit}
                >
                  Update
                </SpinnerButton>
                <br />
                <SpinnerButton
                  type="button"
                  className="btn-no-default p0 mt16 section-text-5 underline c-black-3"
                  onClick={(e) => {
                    form.mutators.modifyFormField(
                      'skus',
                      values.skus.map((sku) => ({ ...sku, inventory: null }))
                    );
                    form.mutators.modifyFormField(
                      'is_active',
                      isProductVisible
                    );
                    handleSubmit(e);
                  }}
                >
                  Update later
                </SpinnerButton>
              </div>
            </>
          );
        }}
      />
    </Modal>
  );
};

export const ProductCost = (props) => {
  const { formatLocalMoney } = useCountry();
  const {
    showIfOutOfStock = false,
    selling_price: sellingPrice,
    original_price: originalPrice,
    inventory_quantity: inventory,
  } = props;

  const isOutOfStock = inventory === 0;

  return (
    <div className="product-cost">
      {isOutOfStock && showIfOutOfStock ? (
        <span className="c-red-1 text-8 text-normal">Out of stock</span>
      ) : (
        <>
          {formatLocalMoney(sellingPrice)}
          {!!originalPrice && originalPrice !== sellingPrice && (
            <span className="original-cost">
              {formatLocalMoney(originalPrice)}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export const ProductInventory = ({
  inventoryQuantity = null,
  inStock = true,
}) => {
  if (inventoryQuantity === null) {
    return <p className="text-4  break-word">-</p>;
  }
  if (inStock) {
    return (
      <p className="text-2 break-word d-flex">
        <p
          className={cx({
            'c-red-1': inventoryQuantity <= 5 && inventoryQuantity !== 0,
          })}
        >
          {inventoryQuantity || `-`}&nbsp;
        </p>
      </p>
    );
  }
  return (
    <p className="text-2 break-word c-red-1">
      <p>{inventoryQuantity}</p>
    </p>
  );
};

export const QuantityInputRow = ({ values, onClick, hasVariants = false }) => {
  const { base_qty: baseQty, unit } = values;
  const hasText = baseQty && unit;
  const hideBaseQty = baseQty === 1;

  return (
    <div className="row">
      <div className="col-md-6 col-xs-12">
        <QuantityInput unit={unit} onClick={onClick} />
      </div>
      <div className="col-md-6 col-xs-12">
        {!!hasText && (
          <div className="per-qty-text-section">
            <span>
              per {hideBaseQty ? '' : baseQty} {unit}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const getProductFormData = (
  allImages,
  formValues,
  initialValues = {},
  localVariants = []
) => {
  const NO_VARIANTS = 0;
  const languages = (formValues?.languages ?? []).map((item) => ({
    store_language: item.language.store_language,
    fields: item?.fields,
  }));
  const originalPrice = Number(formValues.original_price);
  const sellingPrice = isNotEmptyOrNull({ value: formValues.selling_price })
    ? Number(formValues.selling_price)
    : originalPrice;
  const formData = {
    name: formValues.name,
    all_images: allImages.map((image) => image.imgUrl) || [],
    selling_price: sellingPrice,
    original_price: originalPrice,
    unit: formValues.unit,
    base_qty: Number(formValues.base_qty),
    description: formValues.description,
    categories: formValues.categories,
    store: formValues.store,
    misc_data: {
      ...formValues?.misc_data,
      timer: { ...formValues.timer, updatedAt: Date.now() },
    },
    sku_code: formValues.sku_code,
    skus: deepClone(
      localVariants.map((_, idx) => ({
        ...localVariants[idx],
        metafields: formValues?.skus[idx]?.metafields ?? [],
      }))
    ),
    hsn_code: formValues.hsn_code,
    gtin: formValues.gtin,
    google_product_category: formValues.google_product_category,
    gst_rate:
      formValues.gst_rate !== null && formValues.gst_rate !== ''
        ? Number(formValues.gst_rate)
        : formValues.gst_rate,
    weight: formValues.weight,
    weight_unit: formValues.weight_unit,
    product_food_type: formValues.product_food_type,
    product_attributes: formValues.product_attributes.map((eachFilter) => ({
      store_attribute: eachFilter.store_attribute,
      key: eachFilter.key,
      values: eachFilter.values,
    })),
    staffs:
      localVariants.length > 1
        ? []
        : formValues?.staffs?.map((each, idx) => ({
            ...each,
            is_preferred: idx === 0,
          })) ?? [],
    language_data: formValues?.languageDataPayload || languages || [],
    tags: formValues?.tags?.map((item) => item.tag_id) ?? [],
  };

  formData.product_brand =
    formValues?.product_brand &&
    ['number', 'string'].includes(typeof formValues?.product_brand)
      ? formValues.product_brand
      : null;

  if (formValues?.warehouse_inventory_items?.length) {
    formData.warehouse_inventory_items =
      formValues?.warehouse_inventory_items?.map((each) => {
        if (!each.quantity_available) {
          each.quantity_available = 0;
        }
        return each;
      });
  }

  if (Object.keys(formValues?.metafields || {})?.length > 0) {
    const fieldList = [];
    for (const [key, value] of Object.entries(formValues.metafields)) {
      const metafield = key.split('__')?.[1] || '';
      if (metafield !== 'undefined' && isNotEmptyOrNull({ value })) {
        fieldList.push({ metafield, value: JSON.stringify(value) });
      }
    }
    formData.metafields = fieldList;
  }

  if (
    formValues.product_type === NO_VARIANTS &&
    formValues.skus?.length === 1 &&
    formValues?.warehouse_inventory_items?.length
  ) {
    formData.skus[0].warehouse_inventory_items = deepClone(
      formData.warehouse_inventory_items
    );
  }

  if (!(formValues.is_taxable === undefined || formValues.is_taxable === null))
    formData.is_taxable = formValues.is_taxable;

  // if any variant has null selling price then set it to its original price
  formData?.skus?.forEach((each, index) => {
    if (each.warehouse_inventory_items?.length) {
      each.warehouse_inventory_items = each?.warehouse_inventory_items?.map(
        (item) => {
          if (!item.quantity_available) {
            item.quantity_available = 0;
          }
          return item;
        }
      );
    }
    formData.skus[index].selling_price =
      formData.skus[index].selling_price === '' ||
      formData.skus[index].selling_price === null ||
      formData.skus[index].selling_price === undefined
        ? formData.skus[index].original_price
        : formData.skus[index].selling_price;

    formData.skus[index].inventory =
      formData.skus[index].inventory === '' ||
      formData.skus[index].inventory === null ||
      formData.skus[index].inventory === undefined
        ? null
        : formData.skus[index].inventory;

    formData.skus[index].staffs =
      each?.staffs?.map((staff, idx) => ({
        ...staff,
        is_preferred: idx === 0,
      })) ?? [];

    if (Object.keys(formData.skus[index].metafields || {})?.length > 0) {
      const fieldList = [];
      for (const [key, value] of Object.entries(
        formData.skus[index].metafields
      )) {
        const metafield = key.split('__')?.[1] || '';
        if (metafield !== 'undefined' && isNotEmptyOrNull({ value })) {
          fieldList.push({ metafield, value: JSON.stringify(value) });
        }
      }
      formData.skus[index].metafields = fieldList;
    }
  });

  if (formValues.seo_data?.title && formValues.seo_data?.description) {
    formData.seo_data = { ...formValues.seo_data };
  }

  formData.seo_data = {
    ...formValues?.seo_data,
    image: formValues.seo_data?.imageUrl || '',
  };
  delete formData.seo_data.imageUrl;

  // if inventory was being tracked previously but now removed, then we need to update that
  if (initialValues.inventory_quantity !== formValues.inventory_quantity) {
    if (
      formValues.inventory_quantity === null ||
      formValues.inventory_quantity === ''
    ) {
      formData.inventory_quantity = null;
      formData.in_stock = initialValues.in_stock;
    } else if (Number(formValues.inventory_quantity) !== 0) {
      formData.inventory_quantity = formValues.inventory_quantity;
      formData.in_stock = true;
    } else {
      formData.inventory_quantity = 0;
      formData.in_stock = false;
    }
  }

  const addOns = [];
  if (formValues.add_ons) {
    formValues.add_ons.forEach(({ store_add_on: storeAddOn, uuid }) => {
      const data = {};
      if (formValues.add_ons_mandatory) data.price = 0;
      addOns.push({ ...data, store_add_on: storeAddOn || uuid });
    });

    formData.add_ons = addOns;
  }

  return formData;
};

export const cleanWarehouseData = (warehouseInventoryItems) =>
  warehouseInventoryItems
    ?.map((eachItem) => {
      if (eachItem?.is_dummy_record && eachItem?.quantity_available === 0) {
        return;
      }

      return eachItem;
    })
    ?.filter(Boolean);

export const formatProductWarehouseData = (productData) => {
  const updatedData = deepClone(productData);

  const skusData = productData?.sku_data?.map((eachSku) => {
    const updatedSkuData = cleanWarehouseData(
      eachSku?.warehouse_inventory_items
    );

    return { ...eachSku, warehouse_inventory_items: updatedSkuData };
  });

  return { ...updatedData, sku_data: skusData.flat() };
};

export const DiscountBadge = (props) => {
  const { sellingPrice, originalPrice } = props;
  const showDiscount =
    originalPrice && Number(sellingPrice) < Number(originalPrice);

  if (showDiscount) {
    const discount = Math.round(
      ((originalPrice - sellingPrice) * 100) / originalPrice
    );
    if (discount) {
      return (
        <span className="product-discount-badge">{`${discount}% OFF`}</span>
      );
    }
  }
  return null;
};
