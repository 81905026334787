import React from 'react';
import Lottie from 'react-lottie';
import cx from 'classnames';

import * as BounceLoaderJson from './lottie/bounce-loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: BounceLoaderJson,
};

const BounceLoader = ({ inline, noLayout = false, overlayLoader = false }) => (
  <div
    className={cx({
      '': inline && !noLayout,
      'absolute-center': !inline && !noLayout,
      'no-layout-center': noLayout,
      'overlay-spinner-container': overlayLoader,
    })}
  >
    <Lottie options={defaultOptions} height={50} width={50} />
  </div>
);

export const BounceLoaderSmall = ({ inline, noLayout = false }) => (
  <div
    className={cx({
      '': inline && !noLayout,
      'absolute-center': !inline && !noLayout,
      'no-layout-center': noLayout,
    })}
  >
    <Lottie options={defaultOptions} height={24} width={24} />
  </div>
);

export default BounceLoader;
