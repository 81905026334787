import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { APPSUMO_REDIRECTION_URL } from '../Account/Sections/constants';
import { useAppContext } from '../context/AppContext';
import { useSubscription } from '../context/SubscriptionProvider';
import { EVENT_ACC_SIGNOUT } from '../events';
import BounceLoader from '../shared/BounceLoader';
import CheckboxField from '../shared/CheckboxField';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { TrackEvent } from '../utils/analytics';
import { logoutUser } from '../utils/user';
import { accountUrls } from '../Urls';

const DeleteStoreModal = ({ open, sellerLimits, onClick }) => {
  const { stores, business, loading, isAppSumoStore } = useAppContext();
  const { activePlanName } = useSubscription();

  const history = useHistory();

  const [selectedStores, setSelctedStores] = useState([]);

  const { max_limit: maxLimit, used_limit: usedLimit } = sellerLimits ?? {};
  const remainingStore = usedLimit - maxLimit;

  const tierName = activePlanName?.split(' ').splice(2).join(' ');

  const handleChange = ({ target: { name, checked } }) => {
    if (!checked) {
      setSelctedStores(selectedStores.filter((item) => item !== name));
    } else {
      setSelctedStores([...selectedStores, name]);
    }
  };

  return (
    <Modal
      paperClass="delete-store-modal"
      open={open}
      maxWidth="xm"
      showCloseIcon={false}
    >
      <div>
        <div className="delete-store-content">
          <div className="d-flex-c-s">
            <div className="section-text-4">
              Delete stores ({selectedStores.length}/{remainingStore})
            </div>
            <div
              className="section-text-5 cur-p underline c-black-3"
              onClick={() => {
                TrackEvent(EVENT_ACC_SIGNOUT, business);
                logoutUser();
              }}
            >
              Sign out
            </div>
          </div>
          <div className="mt24 py8 px16 msg d-flex-c-c flex-column">
            <div className="text-center">
              You have downgraded to {tierName} plan which has {maxLimit}{' '}
              {maxLimit > 1 ? 'stores' : 'store'} limit.
            </div>
            <div>
              Please delete the other {remainingStore}{' '}
              {remainingStore > 1 ? 'stores' : 'store'} to continue.
            </div>
          </div>
          <div className="mt24 section-text-5">
            Showing all {stores.length} stores
          </div>
          {loading ? (
            <div className="my12">
              <BounceLoader inline />
            </div>
          ) : (
            <div className="mt16 store-card-list show-scrollbar pr4">
              {stores?.map((item) => (
                <div
                  key={item.id}
                  className="store-card mb16 d-flex-c-s py12 px16"
                >
                  <div>
                    <div className="section-text-5">{item.name}</div>
                    <a
                      href={item.link}
                      target="_blank"
                      className="mt2 text-8 c-gray-1"
                      rel="noreferrer"
                    >
                      {item.link}
                    </a>
                  </div>
                  <div>
                    <CheckboxField
                      checked={selectedStores.includes(item.uuid)}
                      name={item.uuid}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="footer p16">
          <div className="d-flex-c-c flex-column">
            <SpinnerButton
              label={`Delete ${
                selectedStores?.length > 1 ? 'stores' : 'store'
              }`}
              disabled={
                selectedStores.length === 0 ||
                !(remainingStore <= selectedStores.length) ||
                stores.length === selectedStores.length
              }
              onClick={() => onClick(true, selectedStores)}
            />
            <span
              className="mt16 section-text-5 anchor-1 c-purple-1 underline cur-p"
              onClick={() => {
                if (isAppSumoStore) {
                  window.open(APPSUMO_REDIRECTION_URL, '_blank');
                } else {
                  history.push(accountUrls.subscriptionPurchasePath);
                }
              }}
            >
              or upgrade your plan
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteStoreModal;
