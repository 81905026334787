import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { CaretDown } from '../SvgIcon';
import { noop } from '../utils';

const CustomAccordion = (props) => {
  const {
    heading,
    details,
    children,
    expandIcon,
    className,
    defaultExpanded = false,
    onChange,
    ...restProps
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleChange = useCallback(
    (e, isExpanded) => {
      onChange(isExpanded);
      setExpanded(isExpanded);
    },
    [onChange]
  );

  return (
    <Accordion
      className={cx('custom-accordion', className)}
      expanded={expanded}
      {...restProps}
      onChange={handleChange}
    >
      <AccordionSummary expandIcon={expandIcon}>{heading}</AccordionSummary>
      <AccordionDetails>{!details ? children : details}</AccordionDetails>
    </Accordion>
  );
};

CustomAccordion.defaultProps = {
  id: 'default-id',
  heading: '',
  details: '',
  expandIcon: <CaretDown />,
  onChange: noop,
};

export default CustomAccordion;
