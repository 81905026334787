import React from 'react';
import cx from 'classnames';
import { LightTooltip } from '../../../shared/View/LightTooltip';
import { GrayInfoIcon } from '../../../SvgIcon';
import { getCharecterCount } from '../../../shared';
import { BUNDLE_DESC_MAX_COUNT } from '../../constants';
import { Editor } from '../../../shared/Editor';

const BundleContent = (props) => {
  const { form, values } = props;
  const descriptionCount = getCharecterCount(
    values?.category_description || ''
  );

  return (
    <div className="card p24" id="category-content">
      <div>
        <div className="d-flex align-center mb24">
          <p className="section-text-5">Bundle content</p>
          <LightTooltip
            className="cur-p"
            PopperProps={{ disablePortal: true }}
            title="These fields will appear in the footer and will be used for SEO optimization"
            placement="top"
          >
            <GrayInfoIcon height={16} width={16} className="ml6" />
          </LightTooltip>
        </div>
        <div className="mb8 d-flex space-between">
          <p className="c-black-3">Bundle Heading and Description</p>
          {descriptionCount ? (
            <div className="black-3">
              <span
                className={cx({
                  'c-red-1': descriptionCount > BUNDLE_DESC_MAX_COUNT,
                })}
              >
                {descriptionCount}
              </span>
              /<span className="text-medium">{BUNDLE_DESC_MAX_COUNT}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="catalogue-ckeditor mb24">
        <Editor
          data={values.description}
          onChange={(val) => {
            form.mutators.modifyFormField('description', val);
          }}
        />
      </div>
    </div>
  );
};

export default BundleContent;
