import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { BlueInfoIcon, ExclamationLightIcon, GrayInfoIcon } from '../SvgIcon';
import SpinnerButton from '../shared/SpinnerButton';
import CustomRadio from '../shared/CustomRadio';
import ImageLoader from '../shared/ImageLoader';
import ToggleButtonGroup from '../shared/ToggleButtonGroup';
import {
  isHyperlocalSelected,
  useMultipleDeliveryContext,
} from '../context/DeliveryProvider';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import Layout from '../Layout';
import useResponsive from '../hooks/useResponsive';
import { rootUrls } from '../Urls';
import useOrders from '../hooks/useOrders';
import { mobileClose } from './components/SelectShipmentPartner';
import { TrackEvent } from '../utils/analytics';
import { useAppContext } from '../context/AppContext';
import { ORDERS_SHIP_MULTIPLE_TYPE } from '../events';
import { BULK_DELIVERY_BATCH_SIZE, HYPERLOCAL_TOOLTIP_TEXT } from './constants';
import { useStoreWarehouse } from '../context/StoreWarehouseProvider';

export const partnerImgStyle = (height = 16, width = 'auto') => ({
  height,
  width,
});

export const comparePartnerSort = (a, b) => {
  if (
    a.carrier_service.starting_price_inr < b.carrier_service.starting_price_inr
  ) {
    return -1;
  }
  if (
    a.carrier_service.starting_price_inr > b.carrier_service.starting_price_inr
  ) {
    return 1;
  }
  return 0;
};

const CourierRow = (props) => {
  const { isMobile } = useResponsive();
  const {
    isHyperlocal,
    isRecommanded,
    onPartnerChange,
    selectedPartner,
    acceptedServiceableRes,
    index,
    hideSaveOnRecommand,
    ...partnerInfo
  } = props;

  let isChecked = false;
  if (Object.keys(selectedPartner || {}).length) {
    if (selectedPartner?.carrier && selectedPartner?.carrier_service) {
      isChecked =
        selectedPartner?.carrier_service.id === partnerInfo?.carrier_service.id;
    } else if (!selectedPartner?.carrier_service && !selectedPartner?.carrier) {
      isChecked =
        !partnerInfo?.carrier_service &&
        !partnerInfo?.carrier &&
        partnerInfo?.logos;
    } else {
      isChecked = selectedPartner?.carrier?.id === partnerInfo?.carrier?.id;
    }
  }

  return (
    <div
      key={partnerInfo?.carrier_service?.id + index}
      className={cx('delivery-partner-row', {
        'flex-column d-flex': isMobile,
        'pb0-i': isHyperlocal,
      })}
      style={{ maxHeight: isMobile ? 'inherit' : 83 }}
      onClick={() => onPartnerChange(partnerInfo)}
    >
      <div
        className="delivery-partner-name-box d-flex top-sm"
        style={{ marginLeft: !isMobile ? 12 : 0 }}
      >
        <CustomRadio className="align-baseline" checked={isChecked} />
        <div className="d-flex flex-column">
          {partnerInfo?.logos ? (
            <div className="d-flex">
              {partnerInfo?.logos?.map((logo, idx) => (
                <>
                  <div className="d-flex">
                    <ImageLoader
                      src={logo}
                      style={partnerImgStyle(14)}
                      {...partnerImgStyle(14, 100)}
                      cdnImgHeight={100}
                      alt="Shipment logo"
                    />
                  </div>
                  {idx + 1 !== partnerInfo?.logos.length && (
                    <img
                      className="mx6"
                      src="/images/plus-icon.svg"
                      alt="plus-icon"
                    />
                  )}
                </>
              ))}
            </div>
          ) : (
            <div className="d-flex">
              <ImageLoader
                src={partnerInfo?.carrier?.logo}
                style={partnerImgStyle()}
                {...partnerImgStyle(16, 100)}
                cdnImgHeight={100}
                alt="Shipment logo"
              />
            </div>
          )}
          {!partnerInfo?.carrier_service ? (
            <p className="mt8 c-black-1">
              <span className="text-medium">
                {partnerInfo?.serviceable_count}/
                {acceptedServiceableRes?.serviceable_orders_count +
                  acceptedServiceableRes?.unserviceable_orders_count}{' '}
              </span>
              serviceable pin{' '}
              {acceptedServiceableRes.count > 1 ? 'codes' : 'code'}
            </p>
          ) : (
            <p className="mt8 c-black-3">
              {isHyperlocal ? (
                <>
                  Max weight: <span className="text-medium">20 kgs</span>
                </>
              ) : (
                <>{partnerInfo?.carrier_service?.name}</>
              )}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          marginLeft: !isMobile ? 0 : 32,
          marginTop: !isMobile ? -6 : 4,
        }}
      >
        <div className="d-flex align-center">
          <p className="text-2">From &nbsp;</p>
          <span className="text-medium">
            ₹
            {partnerInfo?.carrier && partnerInfo?.carrier_service
              ? partnerInfo?.carrier_service?.starting_price_inr
              : partnerInfo?.carrier?.starting_price_inr || '31'}
          </span>
          {isHyperlocal && (
            <>
              <p>*&nbsp;</p>
              <Tooltip
                title={HYPERLOCAL_TOOLTIP_TEXT}
                placement="top"
                enterDelay={0}
                enterTouchDelay={0}
              >
                <GrayInfoIcon />
              </Tooltip>
            </>
          )}
          {partnerInfo?.logos && !hideSaveOnRecommand && (
            <span className="save-delivery-charge ml8">SAVE UP TO 20%</span>
          )}
        </div>
      </div>
    </div>
  );
};

const CourierHeader = (rateText) => (
  <div className="delivery-partner-header">
    <div className="px44">
      <p>Shipping partner</p>
    </div>
    <div>
      <p>
        <span className="text-medium">
          Shipping charge{' '}
          <span className="text-normal">
            ({rateText.rateText || 'per 500g'})
          </span>
        </span>
      </p>
    </div>
  </div>
);

export const CourierTable = () => {
  const { isMobile } = useResponsive();
  const [tabOptions, setTabOptions] = useState([]);
  const [tabValue, setTabvalue] = useState(0);
  const { acceptedServiceableRes, onPartnerChange, selectedPartner } =
    useMultipleDeliveryContext();

  const isHyperlocal = isHyperlocalSelected(selectedPartner);

  const tabValueChange = (val) => {
    setTabvalue(val);
    onPartnerChange(tabOptions[val]?.tabChoices[0]);
  };

  const getDay = () => {
    if (isHyperlocal) {
      return moment().format(`[Today], DD MMM`);
    }
    const time = Number(moment().format('HHmm'));
    if (time <= 1400) return moment().format(`[Today], DD MMM`);
    return moment().add(1, 'day').format(`[Tomorrow], DD MMM`);
  };

  const getTime = () => moment().add(30, 'minute').format(`LT`);

  useEffect(() => {
    if (!tabOptions.length && Object.keys(acceptedServiceableRes).length) {
      const newOptions = acceptedServiceableRes?.tabs?.map(
        (
          { tab_name: tabName, tab_choices: tabChoices, tab_info: tabInfo },
          index
        ) => {
          tabChoices?.sort((a, b) =>
            a?.carrier_service?.starting_price_inr <
            b?.carrier_service?.starting_price_inr
              ? -1
              : 1
          );
          return {
            label: tabName,
            count: 0,
            value: index,
            helpText: tabInfo,
            tabChoices,
          };
        }
      );
      setTabOptions(newOptions);
      onPartnerChange(newOptions[0]?.tabChoices?.[0]);
    }
  }, [acceptedServiceableRes]);

  const hideSaveOnRecommand = tabOptions?.[0]?.tabChoices?.every(
    (each, i, array) => each?.serviceable_count === array[0]?.serviceable_count
  );

  return (
    <div className="delivery-partner-table new-shipping-partners">
      {!isMobile && (
        <ToggleButtonGroup
          options={tabOptions}
          onChange={tabValueChange}
          defaultSelected={0}
          propsSetSelected={tabValue}
          className="sticky-toggle-group bg-white-1"
        />
      )}
      <div
        className={cx('d-flex-c-s pos-rel card', {
          'px12 py24 mt12 mb24': !isMobile,
          'p16 mx16 mt4 mb16': isMobile,
        })}
      >
        <div className={cx('row w-100', { mx12: !isMobile })}>
          <div className={cx(' w-100', { 'col-md-6 pr12': !isMobile })}>
            <div style={{ minWidth: '144px' }}>
              <p className="mb8 c-black-3">Pickup by</p>
              <div className="without-bg-fake-field mb12 text-2 disabled w-100">
                {getDay()}
              </div>
            </div>
          </div>
          {isHyperlocal && (
            <div className={cx(' w-100', { 'col-md-6 pl12': !isMobile })}>
              <div style={{ minWidth: '144px' }}>
                <p className="mb8 c-black-3">Time</p>
                <div className="without-bg-fake-field mb12 text-2 disabled w-100">
                  {getTime()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx('card', {
          'px12 pt24': !isMobile,
          'p16 mx16 ': isMobile,
        })}
        style={{ marginBottom: isMobile ? 102 : 0 }}
      >
        <div
          className={cx('text-2 text-medium', {
            'px12 mb16': !isMobile,
            mb4: isMobile,
          })}
        >
          Select shipping partner
        </div>
        {!isMobile && (
          <CourierHeader
            rateText={
              tabOptions[tabValue]?.tabChoices[0]?.carrier_service
                ?.service_type_json?.rate_text
            }
          />
        )}
        <div className="delivery-partner-row-container">
          {tabOptions?.[tabValue]?.tabChoices?.map((item, index) => (
            <CourierRow
              {...item}
              selectedPartner={selectedPartner}
              onPartnerChange={onPartnerChange}
              acceptedServiceableRes={acceptedServiceableRes}
              isRecommanded={index === 0}
              hideSaveOnRecommand={hideSaveOnRecommand}
              isHyperlocal={isHyperlocal}
            />
          ))}
        </div>
        {isHyperlocal && (
          <div className={cx({ pb24: !isMobile, pt12: isMobile })}>
            <div
              className={cx('hyperlocal-info-stripe', {
                'py8 px12': isMobile,
                ml12: !isMobile,
              })}
            >
              <div className="d-flex align-center">
                <div className="d-flex align-center">
                  <BlueInfoIcon height={16} width={16} />
                </div>
                <span className={cx('ml8', { 'text-10': isMobile })}>
                  Best serviceable between 10 AM-12 PM &#38; 3:30 PM-7:30 PM.
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

let timeoutID;
const MultipleDeliveryPartners = () => {
  const { business } = useAppContext();
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();
  const urlParams = qs.parse(window.location.search);
  const { constructParams } = useOrders(urlParams, false, true);
  const filters = constructParams();
  const history = useHistory();
  const { isMobile } = useResponsive();
  const [percentage, setPercentage] = useState(0);
  const {
    selectedPartner,
    fetchAcceptedServiceableOrders,
    resetFromConfirmDelivery,
    acceptedServiceableRes,
  } = useMultipleDeliveryContext();
  const {
    count,
    serviceable_orders_count: serviceableCount,
    unserviceable_orders_count: unserviceableCount,
  } = acceptedServiceableRes;
  const isHyperlocal = isHyperlocalSelected(selectedPartner);

  const onPartnerProceed = (partner = {}) => {
    const eventData = {
      total_lineitems: count,
      success_lineitems: serviceableCount,
      failed_lineitems: unserviceableCount,
    };

    const partnerCarrierService = partner.carrier_service;
    let tabId;
    acceptedServiceableRes.tabs.forEach(
      ({ tab_choices: tabChoices }, index) => {
        tabChoices.forEach((choice) => {
          if (
            choice.carrier_service === null &&
            partnerCarrierService === null
          ) {
            tabId = acceptedServiceableRes.tabs[index].tab_id;
          } else if (
            choice?.carrier_service?.id === partnerCarrierService?.id
          ) {
            tabId = acceptedServiceableRes.tabs[index].tab_id;
          }
        });
      }
    );

    TrackEvent(
      ORDERS_SHIP_MULTIPLE_TYPE(tabId, isActiveStoreWarehouseExist),
      business,
      eventData
    );
    if (isHyperlocal) {
      history.push(
        `${rootUrls.orderHyperlocalPath}?${qs.stringify(
          { ...urlParams, tab_id: tabId },
          {
            arrayFormat: 'repeat',
          }
        )}`
      );
      return;
    }

    return history.push({
      pathname: rootUrls.ordersMultipleShipPath,
      state: { ...constructParams(), tabId },
    });
  };

  useEffect(() => {
    fetchAcceptedServiceableOrders(true, BULK_DELIVERY_BATCH_SIZE, filters);
    return () => {
      setPercentage(0);
      clearTimeout(timeoutID);
      resetFromConfirmDelivery(null, true);
    };
  }, []);

  useEffect(() => {
    if (percentage < 95) {
      timeoutID = setTimeout(() => {
        setPercentage((pre) => pre + 5);
      }, 1000);
    } else {
      clearTimeout(timeoutID);
    }
  }, [percentage]);

  const servicableOrdersCount = acceptedServiceableRes?.orders?.length || 0;
  const acceptedCount = acceptedServiceableRes?.count || 0;

  return (
    <Layout
      documentTitle="Ship multiple orders"
      headerText="Ship multiple orders"
      backLink={isMobile ? mobileClose : ''}
      hideSideNavigation={isMobile}
      hideSubscription
      showMobileHeader={isMobile}
      progressBar={30}
      headerParentClass="fixed mw-760"
      headerActions={
        servicableOrdersCount > 0 && (
          <div className="header-actions">
            <div className="header-action-container">
              <div className="text-right">
                <SpinnerButton
                  showAnimation
                  type="button"
                  className="btn btn-primary-4"
                  onClick={() => {
                    onPartnerProceed(selectedPartner);
                    resetFromConfirmDelivery(null, true);
                  }}
                >
                  Continue
                </SpinnerButton>
              </div>
            </div>
          </div>
        )
      }
    >
      <div className="new-shipment-modal">
        <div
          className={cx('new-shipment-modal-container card-layout', {
            mt18: isMobile,
            mt20: !isMobile,
          })}
        >
          <BounceLoaderContainer
            isLoading={!Object.keys(acceptedServiceableRes).length}
          >
            {servicableOrdersCount <= 0 && (
              <UnserviceableOrders
                ordersCount={acceptedCount}
                closeModal={() => {
                  mobileClose();
                  history.goBack();
                }}
              />
            )}
            {servicableOrdersCount > 0 && <CourierTable />}
            {servicableOrdersCount > 0 && (
              <div
                className={cx('d-flex justify-end py32', {
                  'btn-fixed-bottom card px16 py12': isMobile,
                })}
              >
                <SpinnerButton
                  className={cx('btn-primary-4', { 'w-100': isMobile })}
                  showAnimation
                  onClick={() => {
                    onPartnerProceed(selectedPartner);
                    resetFromConfirmDelivery(null, true);
                  }}
                >
                  Continue
                </SpinnerButton>
              </div>
            )}
          </BounceLoaderContainer>
          {Object.keys(acceptedServiceableRes).length <= 0 && (
            <div className="loading-percentage section-text-4">{percentage}%</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MultipleDeliveryPartners;

export const UnserviceableOrders = ({
  showSelfShip,
  closeModal,
  ordersCount,
  attentionText = `Your ${ordersCount} accepted order${
    ordersCount > 1 ? 's are' : ' is'
  } currently unserviceable by Dukaan Delivery. You can self ship ${
    ordersCount > 1 ? 'them' : 'it'
  } or try again later.`,
}) => {
  if (showSelfShip)
    return (
      <div className="pt48">
        <div className="error-stripe mb24">
          The destination pin codes are unserviceable via Dukaan Delivery.
        </div>
      </div>
    );
  return (
    <div className="card p24 mt32">
      <div className="d-flex-c-c my12">
        <ExclamationLightIcon />
      </div>
      <p className="text-1 text-medium mb6 d-flex-c-c text-center">
        Orders unserviceable by Dukaan Delivery
      </p>
      <p className="text-2 c-black-3 d-flex-c-c mb28 text-center">
        {attentionText}
      </p>
      {!showSelfShip && (
        <div className="d-flex-c-c mb12">
          <SpinnerButton showAnimation onClick={closeModal}>
            Got it
          </SpinnerButton>
        </div>
      )}
    </div>
  );
};
