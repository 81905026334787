import { useEffect, useState } from 'react';
import cx from 'classnames';
import { ATTRIBUTE_FIELD_TYPES } from '../../../Account/Sections/CategoryAttributes/constants';
import useFormFieldHelper from '../../../Orders/CreateOrder/AdditionalInformationCard/FormFieldHelper';
import CustomAccordion from '../../../shared/CustomAccordian';
import SpinnerButton from '../../../shared/SpinnerButton';

const DisplayAttributes = ({
  isExpanded = false,
  formProps,
  attributeProps,
  attributeExist = false,
  isCreateableDropdown = false,
  isLargeFileText = false,
  onUpdateClick,
  description = 'Add attributes like pattern, style, etc. to the product',
  metaList = [],
  isBundle = false,
  isOrderPage = false,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const { loading, submitting } = attributeProps;
  const [isFileUploading, setIsFileUploading] = useState(true);

  const fieldType = metaList?.map((each) => ({
    field_label: each.key,
    field_class: ATTRIBUTE_FIELD_TYPES?.[each.metafield_type.id]?.field_class,
    field_name: `metafields.attribute__${each.id}`,
    field_type: ATTRIBUTE_FIELD_TYPES?.[each.metafield_type.id]?.field_type,
    is_active: each.metafield_type.is_active ? 'isActive' : false,
    field_meta: {
      options: each?.possible_values || [],
      submitting,
    },
    metafield_id: each.id,
    metafield_type_id: each.metafield_type.id,
    is_digital: each?.key?.toLowerCase()?.split(' ')?.includes('digital'),
    handleAddOption: (newValue) => onUpdateClick(newValue, each),
    uuid: `metafields.attribute__${each.id}`,
    allow_multiple_files: each?.rules?.allow_multiple_files ?? false,
  }));

  const { getField } = useFormFieldHelper();

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  if (loading || metaList.length <= 0) return null;

  return (
    <CustomAccordion
      defaultExpanded={expanded}
      onChange={(isOpen) => {
        setExpanded(isOpen);
      }}
      heading={
        <div>
          <div className="attributes-panel-header">
            <span
              className={cx('section-text-5 mr8', {
                'text-semibold': isOrderPage,
              })}
            >
              Attributes
            </span>
          </div>
          {expanded && Boolean(description) && (
            <p className="c-black-3 mt2">{description}</p>
          )}
        </div>
      }
      id="attribute-accordion"
      className={cx('attribute-form', { 'mt0-i': isBundle })}
      details={
        <div className="full-w pr8 pl8 pb8">
          <div className="divider" />
          <div className="row mx-12 mb-24 attribute-container">
            {fieldType.map((field, idx) => (
              <div key={idx} className={cx('px12 mb24', field?.field_class)}>
                {getField(
                  field,
                  formProps.form,
                  idx,
                  formProps.values,
                  formProps,
                  isFileUploading,
                  attributeExist,
                  isCreateableDropdown,
                  isLargeFileText
                )}
              </div>
            ))}

            {/* Show update when onUpdateClick is passed */}
            {Boolean(onUpdateClick) && (
              <div className="px12 mb24 d-block w-100">
                <SpinnerButton
                  showAnimation
                  type="button"
                  className="btn-outline-gray-1"
                  isLoading={submitting}
                  onClick={onUpdateClick}
                >
                  Update
                </SpinnerButton>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

export default DisplayAttributes;
