import { useEffect, useState } from 'react';
import { LOGIN_URL } from '../../ApiUrls';
import { EVENT_OB_OTP_REQUESTED } from '../../events';
import { TrackDruidEvent, TrackEvent } from '../../utils/analytics';
import { getRequest } from '../../utils/http';

const useMobileLogin = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [loginMobile, setLoginMobile] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [errors, setErrors] = useState([]);

  const onLogin = (params) => {
    const { mobile } = params;
    setErrors([]);
    setSubmitting(true);
    TrackDruidEvent('web', mobile, 'OTP-TRIGGERED');
    TrackEvent(EVENT_OB_OTP_REQUESTED);
    getRequest({
      url: LOGIN_URL,
      data: params,
    })
      .then((res) => {
        if (res.status === 'success') {
          setLoginMobile(mobile);
          setShowOtp(true);
        } else {
          TrackDruidEvent('web', mobile, 'OTP-FAILED');
          setErrors(['Something went wrong. Please try again.']);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        if (err.status && err.status === 429) {
          // rate limited from backend
          setErrors(['Too many requests, please try again after 30 seconds.']);
        } else if (err.status && err.status === 400) {
          setErrors([err?.data?.data?.error]);
        } else {
          setErrors(['Something went wrong. Please try again.']);
        }
        TrackDruidEvent('web', mobile, 'OTP-FAILED');
        setSubmitting(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrors([]);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [errors.length]);

  return {
    isSubmitting,
    loginMobile,
    errors,
    onLogin,
    showOtp,
    setErrors,
    setMobileLoading: setSubmitting,
  };
};

export default useMobileLogin;
