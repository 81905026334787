import React from 'react';
import {
  ACTIVATE_PAYMENT_GATEWAY,
  DEACTIVATE_PAYMENT_GATEWAY,
} from '../../ApiUrls';
import { useAppContext } from '../../context/AppContext';
import { useCountry } from '../../context/CountryProvider';
import { COD_KEY, usePayments } from '../../context/PaymentsProvider';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import Switch from '../../shared/Switch';
import { noop } from '../../utils';
import { postRequest } from '../../utils/http';
import { COD_PAYMENT_METHOD, PAYMENT_GATEWAY_IDS } from '../Payments/constants';
import PremiumContentModal from '../../Layout/PremiumContentModal';
import useModal from '../../hooks/useModal';

const CODToggle = ({ callback = noop }) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { paymentKeys } = usePayments();

  const { isInternational } = useCountry();
  const { payment_mode: paymentMode } = business;
  const { enqueueSnackbar } = useCustomSnackbar();

  const isCodAllowed = true;

  const { isOpen, openModal, closeModal } = useModal();

  const handleToggle = (visible) => {
    if (!isCodAllowed && visible) {
      openModal();
      return;
    }
    const CODPaymentId = PAYMENT_GATEWAY_IDS.COD;

    if (visible && isInternational) callback();

    postRequest({
      url: visible
        ? ACTIVATE_PAYMENT_GATEWAY(business.uuid, CODPaymentId)
        : DEACTIVATE_PAYMENT_GATEWAY(business.uuid, CODPaymentId),
    })
      .then((res) => {
        if (res.status) {
          fetchBusinessDetails();
          let snackbarText = '';
          if (visible) {
            snackbarText = 'Cash on delivery enabled successfully!';
          } else {
            snackbarText = 'Cash on delivery disabled successfully!';
          }
          enqueueSnackbar(snackbarText);
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.data?.data?.error || 'Something went wrong!',
          'error'
        );
      });
  };
  return (
    <>
      <Switch
        checked={
          paymentKeys.find((key) => key.provider === COD_KEY)?.is_active ||
          false
        }
        dependsOnChecked={paymentMode === COD_PAYMENT_METHOD || !isCodAllowed}
        onChange={handleToggle}
      />
      <PremiumContentModal open={isOpen} closeModal={closeModal} />
    </>
  );
};

export default CODToggle;
