import { CUSTOMER_SUPPORT_EMAIL } from '../../constants';
import { useAppContext } from '../../context/AppContext';
import { getGmailEmailLink, getMailtoEmailLink } from '../../Layout/constant';

const CONTACT_EMAIL_LINK = (emailInformationData) => {
  const {
    email,
    sendTo = CUSTOMER_SUPPORT_EMAIL,
    subject,
    body,
    link,
    storeId,
  } = emailInformationData ?? {};

  const fromGmail = email?.indexOf('gmail.com') === -1;

  return fromGmail
    ? getGmailEmailLink(sendTo, subject, body, link, storeId)
    : getMailtoEmailLink(sendTo, subject, body, link, storeId);
};

const useEmailSupport = () => {
  const { business } = useAppContext();
  const { email, link, id: storeId } = business;

  const sendEmail = (
    emailSubject = 'Feedback report',
    emailBody = 'Type your feedback/issue here.',
    sendTo = CUSTOMER_SUPPORT_EMAIL
  ) => {
    const emailInformationData = {
      email,
      sendTo: sendTo || CUSTOMER_SUPPORT_EMAIL,
      subject: encodeURIComponent(`${emailSubject} | Store: ${link}`),
      body: encodeURIComponent(emailBody),
      link,
      storeId,
    };
    window.open(CONTACT_EMAIL_LINK(emailInformationData));
  };

  return {
    sendEmail,
  };
};

export default useEmailSupport;
