import { deepClone } from '../Appearance/utils';
import { useOrdersContext } from '../context/OrdersProvider';
import Chip from '../shared/Chip';
import { noop } from '../utils';
import { capitaliseFirst } from '../utils/string';
import { useCountry } from '../context/CountryProvider';
import {
  CASH_PAYMENT_MODE,
  CHANNEL,
  CHANNEL_MODE_LABEL,
  ONLINE_PAYMENT_MODE,
  ORDER_CREATION,
  OTHERS,
  OTHERS_MODE_LABEL,
  PAYMENT_MODE,
  PAYMENT_MODE_LABEL,
  PREPAID_ORDER,
  SOURCE,
  TAGS,
  UNPAID_ORDER,
} from './constants';

const OrdersSelectedFilters = ({ updateUrlOnPageUpdate = noop }) => {
  const { orderParams, updateOrderParams, orderTags = [] } = useOrdersContext();
  const { currencySymbol } = useCountry();

  let appliedFilters = deepClone(orderParams);
  appliedFilters = { ...appliedFilters, ...appliedFilters.filters };

  const getAmountAndQuantityChipValue = (label = 'amount') => {
    if (
      appliedFilters.filters[`order_${label}_gte`] &&
      appliedFilters.filters[`order_${label}_lte`]
    )
      return [
        `${label === 'amount' ? currencySymbol : ''}${
          appliedFilters.filters[`order_${label}_gte`]
        } - ${label === 'amount' ? currencySymbol : ''}${
          appliedFilters.filters[`order_${label}_lte`]
        }`,
      ];

    if (appliedFilters.filters[`order_${label}_gt`])
      return [
        `> ${label === 'amount' ? currencySymbol : ''}${
          appliedFilters.filters[`order_${label}_gt`]
        }`,
      ];

    if (appliedFilters.filters[`order_${label}_lt`])
      return [
        `< ${label === 'amount' ? currencySymbol : ''}${
          appliedFilters.filters[`order_${label}_lt`]
        }`,
      ];

    if (appliedFilters.filters[`order_${label}`])
      return [
        `= ${label === 'amount' ? currencySymbol : ''}${
          appliedFilters.filters[`order_${label}`]
        }`,
      ];
  };

  const filtersMap = {
    payment_mode: {
      [ONLINE_PAYMENT_MODE]: {
        key: ONLINE_PAYMENT_MODE,
        source_key: 'payment_mode',
        label: PAYMENT_MODE_LABEL,
        value: 'Paid',
      },
      [CASH_PAYMENT_MODE]: {
        key: CASH_PAYMENT_MODE,
        source_key: 'payment_mode',
        label: PAYMENT_MODE_LABEL,
        value: 'COD',
      },
      [UNPAID_ORDER]: {
        key: UNPAID_ORDER,
        source_key: 'payment_mode',
        label: PAYMENT_MODE_LABEL,
        value: 'Unpaid',
      },
      [PREPAID_ORDER]: {
        key: PREPAID_ORDER,
        source_key: 'payment_mode',
        label: PAYMENT_MODE_LABEL,
        value: 'Prepaid',
      },
    },

    channel: {
      [ORDER_CREATION.ONLINE]: {
        key: ORDER_CREATION.ONLINE,
        source_key: 'channel',
        label: CHANNEL_MODE_LABEL,
        value: 'Online',
      },
      [ORDER_CREATION.MANUAL]: {
        key: ORDER_CREATION.MANUAL,
        source_key: 'channel',
        label: CHANNEL_MODE_LABEL,
        value: 'Manual',
      },
    },

    others: {
      dukaan_ship: {
        key: 'dukaan_ship',
        label: OTHERS_MODE_LABEL,
        value: 'Dukaan delivery orders',
      },

      self_ship: {
        key: 'self_ship',
        label: OTHERS_MODE_LABEL,
        value: 'Self shipped orders',
      },

      returned_orders: {
        key: 'returned_orders',
        label: OTHERS_MODE_LABEL,
        value: 'Returned orders',
      },

      vendor_rejected_unassigned_orders: {
        key: 'vendor_rejected_unassigned_orders',
        label: OTHERS_MODE_LABEL,
        value: 'Rejected by vendor',
      },
    },
    tags: {
      key: 'tags',
      label: 'Tags',
    },
    source: {
      key: 'sources',
      label: 'Sources',
    },
  };

  const filtersLabelMap = Object.keys(filtersMap).reduce((acc, key) => {
    if (key === PAYMENT_MODE || key === CHANNEL || key === PREPAID_ORDER) {
      Object.keys(filtersMap?.[key]).forEach((each) => {
        if (appliedFilters?.filters?.[key]?.includes(Number(each))) {
          const { label, value } = filtersMap?.[key]?.[each];
          if (acc[label]) {
            acc[label].push(value);
          } else {
            acc[label] = [value];
          }
        }

        if (appliedFilters?.filters[PREPAID_ORDER]) {
          const { label, value } = filtersMap?.payment_mode?.[PREPAID_ORDER];
          if (acc[label]) {
            if (!acc[label].includes(value)) acc[label].push(value);
          } else {
            acc[label] = [value];
          }
        }
      });
    }

    if (key === OTHERS) {
      Object.keys(filtersMap.others).forEach((each) => {
        if (appliedFilters[each]) {
          const { label, value } = filtersMap?.others?.[each];
          if (acc[label]) {
            acc[label].push(value);
          } else {
            acc[label] = [value];
          }
        }
      });
    }

    if (
      appliedFilters?.filters?.order_amount_gt ||
      appliedFilters?.filters?.order_amount ||
      appliedFilters?.filters?.order_amount_gte ||
      appliedFilters?.filters?.order_amount_lt ||
      appliedFilters?.filters?.order_amount_lte
    )
      acc.Amount = getAmountAndQuantityChipValue('amount');

    if (
      appliedFilters?.filters?.order_item_gt ||
      appliedFilters?.filters?.order_item ||
      appliedFilters?.filters?.order_item_gte ||
      appliedFilters?.filters?.order_item_lt ||
      appliedFilters?.filters?.order_item_lte
    )
      acc.Quantity = getAmountAndQuantityChipValue('item');

    if (appliedFilters.tags.length) {
      const appliedTags = (orderTags || [])?.filter((each) =>
        appliedFilters?.tags?.includes(each.id)
      );
      acc.Tags = [...appliedTags.map((each) => each.name)];
    }

    if (appliedFilters.source.length) acc.Source = [...appliedFilters.source];

    return acc;
  }, {});

  const filtersKeyMap = Object.keys(filtersMap).reduce((acc, key) => {
    if (key === PAYMENT_MODE || key === CHANNEL || key === PREPAID_ORDER) {
      Object.keys(filtersMap?.[key]).forEach((each) => {
        if (appliedFilters?.filters?.[key]?.includes(Number(each))) {
          const { label } = filtersMap?.[key]?.[each];
          acc[label] = key;
        }
      });
    }

    if (key === OTHERS) {
      Object.keys(filtersMap.others).forEach((each) => {
        const { label } = filtersMap?.others?.[each];
        acc[label] = key;
      });
    }

    if (appliedFilters.tags.length) acc.Tags = 'tags';
    if (appliedFilters.source.length) acc.Sources = 'sources';

    return acc;
  }, {});

  const handleRemoveChip = (key) => {
    const currentChips = deepClone(orderParams);

    if (key === capitaliseFirst(OTHERS)) {
      delete currentChips.filters.dukaan_ship;
      delete currentChips.filters.returned_orders;
      delete currentChips.filters.self_ship;
      delete currentChips.filters.vendor_rejected_unassigned_orders;
    } else if (key === capitaliseFirst(TAGS)) {
      currentChips.tags = [];
    } else if (key === capitaliseFirst(SOURCE)) {
      currentChips.source = [];
    } else if (key === PAYMENT_MODE_LABEL) {
      delete currentChips.filters.payment_mode;
      delete currentChips.filters.seller_marked_prepaid;
    } else if (key === 'Amount') {
      delete currentChips.filters.order_amount_gt;
      delete currentChips.filters.order_amount_gte;
      delete currentChips.filters.order_amount;
      delete currentChips.filters.order_amount_lt;
      delete currentChips.filters.order_amount_lte;
    } else if (key === 'Quantity') {
      delete currentChips.filters.order_item_gt;
      delete currentChips.filters.order_item_gte;
      delete currentChips.filters.order_item;
      delete currentChips.filters.order_item_lt;
      delete currentChips.filters.order_item_lte;
    } else delete currentChips?.filters?.[filtersKeyMap?.[key]];

    currentChips.page = 1;
    updateOrderParams(currentChips, 'special filter');
    updateUrlOnPageUpdate(1, 'special filter');
  };

  const handleClear = () => {
    const initialFilter = {
      filters: {},
      tags: [],
      source: [],
      page: 1,
    };
    updateOrderParams(initialFilter, 'special filter');
    updateUrlOnPageUpdate(1, 'special filter');
  };

  return (
    Object.keys(filtersLabelMap).length > 0 && (
      <div className="filters-chip">
        {Object.keys(filtersLabelMap)?.map((each) => (
          <Chip
            isRemovable
            key={each}
            onClick={() => {
              delete filtersLabelMap?.[each];
              handleRemoveChip(each);
            }}
          >
            <span className="section-text-7 c-black-3">{each} : </span>
            <span className="section-text-14 c-black-3">
              {filtersLabelMap[each].join(', ')}
            </span>
          </Chip>
        ))}

        {Object.keys(filtersLabelMap).length > 0 && (
          <span
            className="section-text-14 underline d-flex align-center c-black-3 cur-p"
            onClick={handleClear}
          >
            Clear all filters
          </span>
        )}
      </div>
    )
  );
};

export default OrdersSelectedFilters;
