import FullScreenDialog from '../../shared/FullscreenModal';
import ImageCarousel from '../../Themes/components/ImageCarousel';

const ReturnShipmentImagesModal = ({
  open,
  closeModal,
  returnShipmentImages,
}) => (
  <FullScreenDialog
    open={open}
    onClose={closeModal}
    closeModal={closeModal}
    maxWidth="lg"
  >
    <div className="return-shipment-images-modal pb16">
      <div className="flex-center">
        <ImageCarousel
          slides={returnShipmentImages.slides}
          thumbnails={returnShipmentImages.thumbnails}
          selectedIndex={returnShipmentImages?.selectedIndex}
          isThumbnailsBottom
          isAutoPlayOn={false}
        />
      </div>
    </div>
  </FullScreenDialog>
);
export default ReturnShipmentImagesModal;
