import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { noop, stickyTopStyle } from '../../utils';
import { SortDownIcon, SortUpIcon } from '../../SvgIcon';
import { formatOrderTime } from '../../utils/date';
import {
  DINING_PAYMENT_MODE_SHORT_MAP,
  ORDER_STATUS_MAP,
  PAYMENT_MODE_SHORT_MAP,
  RESTAURANT_ORDER_STATUS_MAP,
} from '../constants';
import { useCountry } from '../../context/CountryProvider';
import { orderDetailsUrl } from '../../UrlHelper';
import { EVENT_ORDER_VIEW_DETAILS } from '../../events';
import { useAppContext } from '../../context/AppContext';
import { TrackEvent } from '../../utils/analytics';
import BounceLoader from '../../shared/BounceLoader';
import CheckboxField from '../../shared/CheckboxField';
import useScrollingRef from '../../hooks/useScrollingRef';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';

const NonServiceableOrdersTable = ({
  columns = [],
  data = [],
  orderingKey,
  orderingDirection,
  onSort = noop,
  loading = false,
  pageNumber,
  hasMore = false,
  preselectOrders = true,
  ordersCount,
  setPageNumber = noop,
  tableClass = 'card p12 mx32',
  showBuyerAddress = false,
  ignoreRowClick = false,
  openOrdersInNewTab = false,
  columnStyle = {},
  selectedSelfShipMap = {},
  unselectedSelfShipMap = {},
  setSelectedSelfShipMap = noop,
  setUnselectedSelfShipMap = noop,
  orderSelectKey = 'uuid',
  isAllOrderChecked = preselectOrders,
  setAllOrderChecked = noop,
  hideCheckbox = false,
  maxCheckable = false,
  onOrderCheck = null,
  hideHeaderCheckbox = false,
}) => {
  const selectedMapLength = Object.keys(selectedSelfShipMap).length;
  const isHalfChecked = !(
    ordersCount === selectedMapLength || selectedMapLength === data.length
  );
  const isSelectedOrderExist = selectedMapLength > 0;
  const { formatLocalMoney } = useCountry();
  const history = useHistory();
  const { business } = useAppContext();
  const loadingElement = useScrollingRef(loading, hasMore, setPageNumber);
  const { enqueueSnackbar } = useCustomSnackbar();

  const oldOnOrderCheck = (uuid) => {
    if (selectedSelfShipMap[uuid]) {
      unselectedSelfShipMap[uuid] = true;
      delete selectedSelfShipMap[uuid];
    } else {
      selectedSelfShipMap[uuid] = true;
      delete unselectedSelfShipMap[uuid];
    }
    setSelectedSelfShipMap({ ...selectedSelfShipMap });
    setUnselectedSelfShipMap({ ...unselectedSelfShipMap });
  };

  const selectAllOrders = (ordersList) => {
    setAllOrderChecked(true);
    const newData = {};
    if (maxCheckable && maxCheckable <= ordersList.length) {
      Array.from(Array(Number(maxCheckable))).forEach((x, i) => {
        newData[ordersList[i][orderSelectKey]] = true;
      });
    } else {
      for (const each of ordersList) {
        newData[each[orderSelectKey]] = true;
      }
    }
    setSelectedSelfShipMap({ ...newData });
  };

  const onAllOrderCheckChange = (ordersList) => {
    const selectedOrdersMapLength = Object.keys(selectedSelfShipMap).length;
    if (selectedOrdersMapLength === 0) {
      return selectAllOrders(ordersList);
    }
    setAllOrderChecked(false);
    setSelectedSelfShipMap({});
    setUnselectedSelfShipMap({});
  };

  const onOrderClick = ({ uuid }) => {
    if (openOrdersInNewTab) {
      window.open(`/orders/${uuid}`, '_blank');
    }
    if (ignoreRowClick) return;
    TrackEvent(EVENT_ORDER_VIEW_DETAILS, business);
    history.push(orderDetailsUrl(uuid));
  };

  const getPaymentBadge = (paymentMode) => PAYMENT_MODE_SHORT_MAP[paymentMode];

  const getAddress = ({ line = '', city = '', pin = '' } = {}) =>
    `${line}${city ? `, ${city}` : ''}${pin ? ` - ${pin}` : ''}`;

  useEffect(() => {
    if (preselectOrders && isAllOrderChecked) selectAllOrders(data);
  }, [data]);

  return (
    <div className={tableClass}>
      <div className="pt12" style={{ ...stickyTopStyle(88), ...columnStyle }}>
        <div className="table-header-wrapper non-serviceable-orders-table">
          {columns.map((col) => (
            <div className="table-header">
              <div
                className="d-flex align-center cur-p"
                onClick={() => onSort(col.key)}
              >
                {!hideHeaderCheckbox && col.hasCheckbox && (
                  <CheckboxField
                    checked={isSelectedOrderExist}
                    onChange={(event) => {
                      event.stopPropagation();
                      onAllOrderCheckChange(data);
                    }}
                    className="mr8"
                    isHalfChecked={isHalfChecked}
                  />
                )}
                <span className="mr8">{col.title}</span>
                {col.sortable && orderingKey === col.key && (
                  <>
                    {orderingDirection === 'asc' && <SortDownIcon />}
                    {orderingDirection === 'desc' && <SortUpIcon />}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="table-data-container">
        {data.map((row) => (
          <div
            className={cx('table-data-row non-serviceable-orders-table', {
              'cur-p': !ignoreRowClick,
            })}
            style={{ paddingLeft: 16, paddingRight: 16 }}
            onClick={() => {
              if (!ignoreRowClick) {
                onOrderClick(row);
              }
            }}
          >
            <div className="d-flex">
              <span className="d-flex align-start">
                {!hideCheckbox && (
                  <CheckboxField
                    checked={!!selectedSelfShipMap[row[orderSelectKey]]}
                    onChange={() => {
                      if (
                        maxCheckable &&
                        !selectedSelfShipMap[row[orderSelectKey]] &&
                        selectedMapLength >= maxCheckable
                      ) {
                        enqueueSnackbar(
                          `Only 4 orders can be selected`,
                          'error'
                        );
                      } else if (onOrderCheck) {
                        onOrderCheck(row);
                      } else {
                        oldOnOrderCheck(row[orderSelectKey]);
                      }
                    }}
                    className="mr8"
                  />
                )}
                <span
                  className="anchor-1 text-medium mr2"
                  onClick={() => {
                    if (openOrdersInNewTab) {
                      onOrderClick(row);
                    }
                  }}
                >
                  #{row.display_order_id}
                </span>
              </span>

              {row.is_new && <span className="new-order-tag">NEW</span>}
            </div>
            <div>
              <span>{formatOrderTime(row.created_at)}</span>
            </div>
            <div className="d-flex flex-column">
              <span>
                {row?.buyer_address?.buyer?.name ||
                  row?.order_meta?.buyer_address?.buyer?.name}
              </span>
              {showBuyerAddress && (
                <span>
                  {getAddress(
                    row?.buyer_address || row?.order_meta?.buyer_address
                  )}
                </span>
              )}
            </div>
            <div>
              <span>{row.product_count}</span>
            </div>
            <div>
              <span
                className={`payment-mode-static ${getPaymentBadge(
                  row.payment_mode
                )}`}
              >
                {getPaymentBadge(row.payment_mode)}
              </span>
            </div>
            <div>
              <div className="d-flex align-center">
                <div
                  className={cx(
                    'status-dot',
                    'mr6',
                    (row.type === 1
                      ? RESTAURANT_ORDER_STATUS_MAP
                      : ORDER_STATUS_MAP)[row.status]
                  )}
                />
                <span className="text-capitalize">
                  {
                    (row.type === 1
                      ? RESTAURANT_ORDER_STATUS_MAP
                      : ORDER_STATUS_MAP)[row.status]
                  }
                </span>
              </div>
            </div>
            <div>
              <span>{formatLocalMoney(row.total_cost)}</span>
            </div>
          </div>
        ))}
      </div>
      {pageNumber && <div ref={loadingElement} style={{ height: 1 }} />}
      {loading && hasMore && (
        <div className="more-table-row">
          <BounceLoader inline />
        </div>
      )}
    </div>
  );
};

export default NonServiceableOrdersTable;
