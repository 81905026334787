import { FormControlLabel } from '@material-ui/core';
import cx from 'classnames';
import { Field } from 'react-final-form';
import CheckboxField from '../CheckboxField';
import CustomRadio from '../CustomRadio';
import ImageLoader from '../ImageLoader';

const CategoryOption = (props) => {
  const { isChecked, isDisabled, isRadioSelect, onChange, option } = props;
  const { label = '', name, product_count: productCount, depth = 0 } = option;
  return (
    <div
      className={cx('category-option-container', { 'cur-default': isDisabled })}
      style={{ marginLeft: depth * 20 }}
    >
      <div
        className={cx('category-option', {
          selected: isChecked,
          'cur-default': isDisabled,
        })}
        onClick={() => {
          if (!isDisabled) onChange();
        }}
        role="button"
        tabIndex={-1}
      >
        <ImageLoader
          alt={option.label}
          src={option?.image}
          height={48}
          width={48}
          className="category-option-image"
        />
        <div className="ml16">
          <p
            className="section-text-7 text-ellipsis"
            style={{
              width: `calc(400px - ( ${depth} * 20px ))`,
            }}
          >
            {label || name}
          </p>

          {productCount !== null && (
            <p className="text-8 c-black-3 mt2">
              {productCount}
              {productCount === 1 ? ' product' : ' products'}
            </p>
          )}
        </div>
      </div>
      {isRadioSelect ? (
        <FormControlLabel
          control={<CustomRadio disabled={isDisabled} />}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
          style={{ margin: 0 }}
        />
      ) : (
        <Field name={`checkbox-${label}`} type="checkbox">
          {(fieldProps) => (
            <CheckboxField
              {...fieldProps.input}
              onChange={onChange}
              checked={isChecked}
              disabled={isDisabled}
            />
          )}
        </Field>
      )}
    </div>
  );
};
export default CategoryOption;
