import React from 'react';
import cx from 'classnames';
import { components } from 'react-select';
import { FormCustomSelect, FormInput, Validation } from '../shared/Form';
import useModal from '../hooks/useModal';
import RequestCountryChangeModal from '../Account/RequestCountryChangeModal';
import { useAppContext } from '../context/AppContext';
import { countriesWithCurrency } from '../utils/countries';
import { noop } from '../utils';
import { getCurrencyByCode } from '../utils/currencies';

export const FormatCountryOptionWithoutLabel = ({
  country_code: countryCode,
  icon: Icon,
}) => (
  <div className="d-flex-c-start">
    <img width={30} src={Icon} alt={countryCode} className="mr8" />
  </div>
);

export const CountryOption = (props) => {
  const { Option } = components;
  const {
    data: {
      icon: Icon,
      label,
      country_code: countryCode,
      phone_code: phoneCode,
    },
    isWhatsappPlugin,
  } = props;

  return (
    <Option {...props}>
      <div className="d-flex align-center space-between">
        <div className="d-flex-c-start">
          <img width={24} src={Icon} alt={countryCode} className="mr12" />
          <span>
            {label} {isWhatsappPlugin && `(+${phoneCode})`}
          </span>
        </div>
      </div>
    </Option>
  );
};

const CurrencyOption = (props) => {
  const { Option } = components;
  const {
    data: { cc, symbol },
  } = props;
  return (
    <Option {...props}>
      <div className="d-flex-c-start">
        <span>
          {cc} ({symbol})
        </span>
      </div>
    </Option>
  );
};

const CountrySelect = ({
  form,
  values,
  className,
  isSubmitting = false,
  isCountryChangeAllowed = true,
  onChange = noop,
}) => {
  const { business } = useAppContext();
  const { symbol } = business?.currency ?? {};
  const { isOpen, openModal, closeModal } = useModal();
  return (
    <>
      {isCountryChangeAllowed ? (
        <FormCustomSelect
          name="country"
          className={cx('registration-select left', className)}
          options={countriesWithCurrency}
          disabled={isSubmitting}
          components={{
            Option: CountryOption,
          }}
          labelText="Country"
          placeholder="Choose country"
          required
          showRequired
          onChange={(value) => {
            onChange(value);
            form.mutators.modifyFormField(
              'currency',
              getCurrencyByCode(value.currency_code)
            );
          }}
          validate={Validation.required()}
        />
      ) : (
        <FormInput
          name="country"
          className={cx('country-input left', className)}
          labelText="Country"
          placeholder="Country"
          required
          showRequired
          disabled={isSubmitting}
          validate={Validation.required()}
          onClick={openModal}
          value={`${values?.country_mobile?.label ?? ''} (${symbol || ''})`}
          readOnly
        />
      )}
      {isOpen && (
        <RequestCountryChangeModal open={isOpen} closeModal={closeModal} />
      )}
    </>
  );
};

export default CountrySelect;
