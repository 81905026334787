import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { FormControlLabel, Tooltip } from '@material-ui/core';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { modifyFormField, noop } from '../utils';
import CustomRadio from '../shared/CustomRadio';
import { useAppContext } from '../context/AppContext';
import BounceLoaderContainer from '../shared/BounceLoaderContainer';
import { isHyperlocalSelected, OrderWeight } from '../context/DeliveryProvider';
import {
  ConnectedParnterLogo,
  DukaanDeliveryPoweredIcon,
  GrayInfoIcon,
  ShipRocketPoweredIcon,
  Tag100Off,
} from '../SvgIcon';
import { partnerImgStyle } from './MultipleDeliveryPartners';
import ImageLoader from '../shared/ImageLoader';
import { useCountry } from '../context/CountryProvider';
import CheckboxField from '../shared/CheckboxField';
import { HYPERLOCAL_TOOLTIP_TEXT } from './constants';
import useIntlOrderShipment from '../hooks/useIntlOrderShipment';
import { pluginsUrls } from '../Urls';
import { SHIPPO_ID } from '../Plugins/constants';
import useEnterPressAction from '../hooks/useEnterPressAction';
import { useStoreWarehouse } from '../context/StoreWarehouseProvider';
import useModal from '../hooks/useModal';
import PremiumContentModal from '../Layout/PremiumContentModal';
import { useSubscription } from '../context/SubscriptionProvider';
import { CONNECT_SHIPPING_ACCOUNTS_FEATURE } from '../constants';
import {
  DELIVERY_SERVICE_NAMES,
  DUKAAN_DELIVERY_CONNECT_SHIPPING_MODE,
  DUKAAN_DELIVERY_SHIPPING_MODE,
  SHIPROCKET_SHIPPING_MODE,
} from '../Account/Sections/Shipping/constants';
import useCorePlugin from '../hooks/useCorePlugin';
import {
  CONNECT_SHIPPING_ACCOUNT_ID,
  SHIPROCKET_CORE_PLUGIN_ID,
} from '../Account/Sections/constants';
import ConnectShiprocketModal from './components/ConnectShiprocketModal';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

export const CUSTOM_WAREHOUSE_PINCODE = 400001;

const ddActiveContainerStyle = {
  border: '1px solid #146EB4',
  boxSizing: 'border-box',
  borderRadius: '4px',
};

const ddContainerStyle = {
  border: '1px solid #E6E6E6',
  boxSizing: 'border-box',
  borderRadius: '4px',
};

const DUKAAN_DELIVERY = 'dukaan-delivery';
const SHIPPO_KEY = 'shippo_shipping';
const SELF_SHIP = 'self_hook';

const NewShipOptionsModal = (props) => {
  const history = useHistory();
  const {
    isOpen: showUpgradePremiumModal,
    openModal: openUpgradePremiumModal,
    closeModal: closeUpgradePremiumModal,
  } = useModal();
  const {
    isOpen: isOpenConnectShiprocketModal,
    openModal: openConnectShiprocketModal,
    closeModal: closeConnectShiprocketModal,
  } = useModal();
  const { isOnTrial } = useSubscription();
  const { formatLocalMoney, isInternational } = useCountry();
  const [isTracking, setTracking] = useState(false);
  const [initialValue, setInitialValues] = useState({
    shippingMode: SELF_SHIP,
  });
  const {
    title = 'Ship order via...',
    open,
    closeModal,
    plugins,
    selfShipAction,
    otherShipAction,
    deliveryShipAction,
    orderShipmentObj,
    orderId,
    isCodOrder,
    handleKeyboardKeys = noop,
    isReturnedOrderExist = false,
  } = props;
  const { business, canAvailFreeDukaanDelivery } = useAppContext();
  const { meta: { feature_whitelist: featureWhitelist } = {} } = business;
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();
  const isShippoInstalled = !!business?.meta?.misc_data?.shippo_token;
  const {
    loading,
    orderShippingDetails,
    getShippingDetails,
    orderSeviceable,
    servicebleError,
    shipmentErrorMsg,
  } = orderShipmentObj;
  const { rates = [] } = orderShippingDetails || {};
  const [shipmentPartners, setShipmentPartners] = useState([]);
  const [shippoServicable, setShippoServicable] = useState(false);
  const [serviceName, setServiceName] = useState(
    DELIVERY_SERVICE_NAMES[DUKAAN_DELIVERY_SHIPPING_MODE]
  );
  const [shippoServicableError, setShippoServicableError] = useState(false);
  const [selectedshipping, setSelectedShipping] = useState(SELF_SHIP);
  const { loading: intlLoading, fetchShippingRatesForOrder } =
    useIntlOrderShipment();
  const getAction = (link, key) => {
    if (link) {
      return otherShipAction(link);
    }
    if (key === SELF_SHIP) {
      return selfShipAction(isTracking);
    }
    if (isOnTrial) return openUpgradePremiumModal();
    return deliveryShipAction(key);
  };

  const {
    isSubmitting: isShiprocketSubmitting,
    setIsSubmitting,
    corePluginDetails: shiprocketDetails,
    fetchCorePluginDetails,
    postCorePluginDetails,
  } = useCorePlugin(SHIPROCKET_CORE_PLUGIN_ID);

  const {
    corePluginDetails: connectDetails,
    fetchCorePluginDetails: fetchConnectDetails,
  } = useCorePlugin(CONNECT_SHIPPING_ACCOUNT_ID);

  const { enqueueSnackbar } = useCustomSnackbar();

  const handleConnectShiprocket = (values) => {
    postCorePluginDetails(
      values,
      () => {
        setIsSubmitting(false);
        // error callback
      },
      {},
      () => {
        // success callback
        fetchCorePluginDetails(false);
        closeConnectShiprocketModal();
        enqueueSnackbar('Shiprocket account connected successfully!');
      }
    );
  };

  const getErrors = () => {
    let error = '';
    if (isInternational && !isShippoInstalled) {
      return (
        <div className="green-info-stripe mb24">
          Now connect your Shippo account & ship seamlessly!{' '}
          <span
            className="text-medium underline cur-p"
            onClick={() =>
              history.push(
                pluginsUrls.pluginDetailsPath.replace(':uuid', SHIPPO_ID)
              )
            }
          >
            Get started
          </span>
        </div>
      );
    }
    if (shipmentErrorMsg && !isInternational) error = shipmentErrorMsg;
    else if (!isInternational && !orderSeviceable && !servicebleError) {
      error = `The destination pin code is unserviceable via ${serviceName}`;
    } else if (!isInternational && servicebleError) {
      error = `${serviceName} is currently unavailable. Try again after some time.`;
    } else if (
      isInternational &&
      isShippoInstalled &&
      ((!shippoServicable && !shippoServicableError) || isCodOrder)
    ) {
      error = isCodOrder
        ? 'Cod orders are currently not supported via Shippo.'
        : 'The destination address is unserviceable via Shippo.';
    } else if (
      isInternational &&
      isShippoInstalled &&
      !shippoServicable &&
      shippoServicableError
    ) {
      error = 'Shippo is currently unavailable. Try again after some time.';
    }
    if (!error) return null;
    return <div className="error-stripe mb24">{error}</div>;
  };

  useEffect(() => {
    if (!open) {
      setTracking(false);
    } else {
      fetchCorePluginDetails();
      fetchConnectDetails();
    }
  }, [open]);

  const getGetShippingPayload = (...resArgs) => {
    const payload = { orders: [new OrderWeight(...resArgs)] };
    if (!isActiveStoreWarehouseExist)
      payload.custom_warehouse_pincode = CUSTOM_WAREHOUSE_PINCODE;

    return payload;
  };

  useEffect(() => {
    if (orderId && !isInternational) {
      getShippingDetails(
        getGetShippingPayload(orderId, 0.1, null, null, isReturnedOrderExist)
      );
    } else if (orderId && isShippoInstalled && isInternational && !isCodOrder) {
      fetchShippingRatesForOrder(
        { order_id: orderId, weight: 1 },
        (data) => {
          setShippoServicable(data.length > 0);
          setSelectedShipping(SHIPPO_KEY);
          setInitialValues({ shippingMode: SHIPPO_KEY });
        },
        () => setShippoServicableError(true)
      );
    }
  }, [orderId, shiprocketDetails]);

  useEffect(() => {
    if (
      rates.length > 0 &&
      (!shiprocketDetails?.id || !shiprocketDetails?.is_active)
    ) {
      const partnerMap = {};
      rates.forEach((item) => {
        if (!partnerMap[item.carrier.id]) partnerMap[item.carrier.id] = item;
      });
      setShipmentPartners(
        Object.values(partnerMap).sort(function (a, b) {
          if (a.carrier.starting_price_inr < b.carrier.starting_price_inr) {
            return -1;
          }
          if (a.carrier.starting_price_inr > b.carrier.starting_price_inr) {
            return 1;
          }
          return 0;
        })
      );
      setSelectedShipping(DUKAAN_DELIVERY);
      setInitialValues({ shippingMode: DUKAAN_DELIVERY });
    }
  }, [rates]);

  useEffect(() => {
    if (shiprocketDetails?.is_active) {
      setSelectedShipping(SHIPROCKET_SHIPPING_MODE);
      setInitialValues({ shippingMode: SHIPROCKET_SHIPPING_MODE });
      setServiceName(DELIVERY_SERVICE_NAMES[SHIPROCKET_SHIPPING_MODE]);
    }
  }, [shiprocketDetails]);

  useEffect(() => {
    if (connectDetails?.is_active) {
      setInitialValues({ shippingMode: DUKAAN_DELIVERY_CONNECT_SHIPPING_MODE });
      setServiceName(
        DELIVERY_SERVICE_NAMES[DUKAAN_DELIVERY_CONNECT_SHIPPING_MODE]
      );
    }
  }, [connectDetails]);

  useEffect(() => {
    document.removeEventListener('keydown', handleKeyboardKeys);
  }, []);

  const handleProceed = () => {
    if (
      selectedshipping === SHIPROCKET_SHIPPING_MODE &&
      !shiprocketDetails?.id
    ) {
      // if shiprocket not installed, open connect shiprocket modal, and on success of it do stuff
      openConnectShiprocketModal();
      return;
    }
    const plugin = plugins.find((each) => each.key === selectedshipping);
    getAction(plugin?.link, plugin?.key);
  };

  const isConnectOwnShippingPartnerFeatureEnabled =
    Boolean(featureWhitelist?.[CONNECT_SHIPPING_ACCOUNTS_FEATURE]) ||
    connectDetails?.is_active;

  useEnterPressAction(open, handleProceed);

  return (
    <Modal
      open={open}
      className="shipment-created-modal new-shipment-modal"
      closeModal={closeModal}
      maxWidth="xm"
      title={title}
    >
      <div style={{ minHeight: 200, paddingTop: 24 }}>
        <BounceLoaderContainer
          isLoading={loading || intlLoading || isShiprocketSubmitting}
        >
          <Form
            onSubmit={noop}
            keepDirtyOnReinitialize
            initialValues={initialValue}
            mutators={{ modifyFormField }}
          >
            {({ form, values }) => (
              <div className="mt32">
                {!loading && !intlLoading && getErrors()}
                {orderSeviceable &&
                  !shiprocketDetails?.is_active &&
                  !isInternational && (
                    <div
                      style={
                        values.shippingMode === DUKAAN_DELIVERY
                          ? ddActiveContainerStyle
                          : ddContainerStyle
                      }
                      className="p24 cur-p pos-rel"
                      onClick={() => {
                        setSelectedShipping(DUKAAN_DELIVERY);
                        form.mutators.modifyFormField(
                          'shippingMode',
                          DUKAAN_DELIVERY
                        );
                      }}
                    >
                      <div className="d-flex-c-s mb24">
                        <div>
                          {connectDetails?.is_active ? (
                            <ConnectedParnterLogo height={40} width={131} />
                          ) : (
                            <DukaanDeliveryPoweredIcon />
                          )}
                        </div>
                        <div>
                          <FormControlLabel
                            control={<CustomRadio />}
                            label=""
                            className="mx-12 pr2"
                            checked={values.shippingMode === DUKAAN_DELIVERY}
                            onChange={() =>
                              form.mutators.modifyFormField(
                                'shippingMode',
                                DUKAAN_DELIVERY
                              )
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex-c-s bg-white-1 px12 py10 mx-12"
                        style={{
                          borderRadius: 2,
                        }}
                      >
                        <div className="text-10 text-medium c-black-3">
                          Partner
                        </div>
                        <div
                          className="text-10 text-medium c-black-3 pr16"
                          style={{ minWidth: 102 }}
                        >
                          Charges from
                        </div>
                      </div>
                      {shipmentPartners.map((each) => (
                        <div key={each.carrier.id} className="d-flex-c-s pt16">
                          <div
                            style={{ maxWidth: 170 }}
                            className=" d-flex align-center"
                          >
                            <ImageLoader
                              src={each.carrier.logo}
                              style={partnerImgStyle(14)}
                              alt="Shipment logo"
                            />
                          </div>
                          <div
                            className="text-8 text-medium d-flex align-center"
                            style={{ minWidth: 102 }}
                          >
                            <span>
                              {formatLocalMoney(
                                each.carrier.starting_price_inr
                              )}
                            </span>
                            <span className="c-gray-1 text-normal d-flex align-center ml4 text-10">
                              <span>
                                (
                                {
                                  each.carrier_service.service_type_json
                                    .rate_text
                                }
                                )
                              </span>
                              {isHyperlocalSelected(each) && (
                                <Tooltip
                                  title={HYPERLOCAL_TOOLTIP_TEXT}
                                  placement="top"
                                  enterDelay={0}
                                  enterTouchDelay={0}
                                >
                                  <GrayInfoIcon className="ml4" />
                                </Tooltip>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                {!isInternational &&
                  (shiprocketDetails?.id ? !getErrors() : true) && (
                    <div
                      className="px24 py20 mt16 cur-p"
                      style={
                        values.shippingMode === SHIPROCKET_SHIPPING_MODE
                          ? ddActiveContainerStyle
                          : ddContainerStyle
                      }
                      onClick={() => {
                        setSelectedShipping(SHIPROCKET_SHIPPING_MODE);
                        form.mutators.modifyFormField(
                          'shippingMode',
                          SHIPROCKET_SHIPPING_MODE
                        );
                      }}
                    >
                      <div className="d-flex-c-s">
                        <div
                          className="d-flex align-center"
                          style={{ width: '80%' }}
                        >
                          <ShipRocketPoweredIcon />
                        </div>
                        <FormControlLabel
                          control={<CustomRadio />}
                          label=""
                          className="mx-12 pr2"
                          checked={
                            values.shippingMode === SHIPROCKET_SHIPPING_MODE
                          }
                          onChange={() =>
                            form.mutators.modifyFormField(
                              'shippingMode',
                              SHIPROCKET_SHIPPING_MODE
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                {plugins.map((each) => {
                  if (each.key === 'dukaan_shipping') return null;
                  if (each.key === SHIPPO_KEY && !shippoServicable) return null;
                  return (
                    <div
                      className="px24 py20 mt16 cur-p"
                      style={
                        values.shippingMode === each.key
                          ? ddActiveContainerStyle
                          : ddContainerStyle
                      }
                      onClick={() => {
                        setSelectedShipping(each.key);
                        form.mutators.modifyFormField('shippingMode', each.key);
                      }}
                    >
                      <div className="d-flex-c-s">
                        <div
                          className="d-flex align-center"
                          style={{ width: '80%' }}
                        >
                          <ImageLoader
                            src={each.icon}
                            style={partnerImgStyle(24, 24)}
                            alt="Shipment logo"
                          />
                          <div className="text-2 ml12">{each.action_text}</div>
                        </div>
                        <FormControlLabel
                          control={<CustomRadio />}
                          label=""
                          className="mx-12 pr2"
                          checked={values.shippingMode === each.key}
                          onChange={() =>
                            form.mutators.modifyFormField(
                              'shippingMode',
                              each.key
                            )
                          }
                        />
                      </div>
                      {each.key === SELF_SHIP &&
                        values.shippingMode === SELF_SHIP && (
                          <>
                            <div className="mt16 mb12 divider" />
                            <div className={cx('mt8')}>
                              <CheckboxField
                                checked={isTracking}
                                onChange={() => setTracking(!isTracking)}
                                label="Add tracking details in the next step"
                              />
                            </div>
                          </>
                        )}
                    </div>
                  );
                })}

                <div className="mt32 d-flex-c-c">
                  <SpinnerButton
                    showAnimation
                    disabled={servicebleError && plugins?.length === 0}
                    onClick={handleProceed}
                  >
                    Proceed
                  </SpinnerButton>
                </div>
              </div>
            )}
          </Form>
        </BounceLoaderContainer>
        {showUpgradePremiumModal && (
          <PremiumContentModal
            open={showUpgradePremiumModal}
            closeModal={closeUpgradePremiumModal}
          />
        )}
        {isOpenConnectShiprocketModal && (
          <ConnectShiprocketModal
            submitting={isShiprocketSubmitting}
            open={isOpenConnectShiprocketModal}
            handleSubmit={handleConnectShiprocket}
            closeModal={closeConnectShiprocketModal}
          />
        )}
      </div>
    </Modal>
  );
};

export default NewShipOptionsModal;
