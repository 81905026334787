import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import { noop } from '../../utils';
import FormGroup from './FormGroup';

const FormTextArea = (props) => {
  const {
    className,
    name,
    validate,
    labelText,
    pattern,
    required,
    onChange,
    size,
    showRequired,
    showCharLimit,
    badgeOnRight,
    textAreaClassName,
    charLimitAtBottom = false,
    ...restTextAreaProps
  } = props;

  return (
    <Field name={name} validate={validate}>
      {({ input }) => {
        const handleOnChange = (e) => {
          if (pattern) {
            const regex = new RegExp(pattern);
            if (!regex.test(e?.target?.value)) return;
          }
          input.onChange(e);
          onChange(e);
        };
        const { maxLength } = restTextAreaProps;
        const { value } = input;
        return (
          <FormGroup
            labelText={labelText}
            required={required}
            size={size}
            showRequired={showRequired}
            badgeOnRight={badgeOnRight}
            className={cx('textarea-wrap', className)}
            charLimitText={
              showCharLimit && value.length > 0 ? (
                <span>
                  {`${value.length}/`}
                  <span className="text-medium">{maxLength}</span>
                </span>
              ) : null
            }
            charLimitAtBottom={charLimitAtBottom}
          >
            <textarea
              className={textAreaClassName}
              {...input}
              {...restTextAreaProps}
              onChange={handleOnChange}
            />
          </FormGroup>
        );
      }}
    </Field>
  );
};

FormTextArea.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: false,
  disabled: false,
  onChange: noop,
  size: 'lg', // md
  showRequired: false,
};

export default FormTextArea;
