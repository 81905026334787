import { useEffect } from 'react';
import { getAuthToken } from '../utils/user';

const useStorageListenReload = () => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'sl_at_to') {
        if (getAuthToken() !== window.currentAuthToken)
          setTimeout(() => {
            window.location.reload();
          }, 3000);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);
};

export default useStorageListenReload;
