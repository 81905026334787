import { HELP_CENTER_URL } from '../constants';

export const getErrorMessageFromResponse = (
  e = {},
  defaultErrorMessage = 'Something went wrong'
) => e?.data?.data?.error ?? defaultErrorMessage;

export const replaceMediaURLSegment = (url = '') => {
  // Check if "original/media" exists in the URL
  if (url.includes('/original/media/')) {
    // Replace "original/media" with "original/jpeg/media"
    url = url.replace('/original/media/', '/original/webp/media/');
  }
  return url;
};

export const formatUrlToWords = (url = '') => {
  // Remove protocol, subdomain, and query parameters
  const cleanedUrl = url.replace(/(^\w+:|^)\/\/(www\.)?|\/\?.*$/g, '');

  // Extract the path from the URL
  const path = cleanedUrl.split('/').filter(Boolean);

  // Extract and format the last part of the path, omitting numbers
  const lastPart = path[path.length - 1];
  const formattedWords = lastPart
    .replace(/[_-]/g, ' ')
    .replace(/\d+/g, '')
    .toLowerCase();

  return formattedWords.trim(); // Remove leading/trailing spaces
};

export const getDukaanHelpCenterUrl = (intercomUrl = '') => {
  const query = formatUrlToWords(intercomUrl);
  return `${HELP_CENTER_URL}/search?query=${query}`;
};
