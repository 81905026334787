import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { MULTI_LEVEL_CATEGORY_MAX_DEPTH_SELECT_CATEGORY } from '../../Catalogue/constants';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import useScrollingRef from '../../hooks/useScrollingRef';
import { noop } from '../../utils';
import { BounceLoaderSmall } from '../BounceLoader';
import Modal from '../Modal';
import SearchFilter from '../SearchFilter';
import SpinnerButton from '../SpinnerButton';
import CategoryOption from './CategoryOption';

const CategoryModal = (props) => {
  const {
    canSelectedCategoriesBeEmpty = false,
    categoryContext = {},
    categoryDetails = {},
    handleAddCategoryClick = noop,
    handleClose = noop,
    heading = 'Select category',
    hideAddCategoryBtn = false,
    initiallySelectedCategoriesWhole = [],
    isRadioSelect = false,
    open = false,
    searchPlaceholder = 'Search categories...',
    selectButtonText = 'Select',
    selected = [],
    setselectedCategories = noop,
  } = props;
  const { categoryOptions, hasMore, loading, onSearch, setPageNumber } =
    categoryContext;
  const loadingElement = useScrollingRef(loading, hasMore, setPageNumber);
  // selected category id
  const [categories, setCategories] = useState([...selected]);
  // selected category object
  const [selectedCategoriesWhole, setSelectedCatgeoriesWhole] = useState([
    ...initiallySelectedCategoriesWhole,
  ]);

  const isChecked = (option) => categories.some((item) => item === option.id);

  // siblings of the sub category will be disabled
  const isDisabled = (option) =>
    categoryDetails?.id === option.id ||
    (categoryDetails?.parent || categoryDetails?.parent_id) ===
      (option?.parent || option?.parent_id);

  const onChange = (option) => {
    if (isRadioSelect) {
      setCategories([option.id]);
      setSelectedCatgeoriesWhole([option]);
    } else {
      // checkbox case
      // array of selected id
      let temp = [...categories];
      // array of selected object
      let tempWholeObject = [...selectedCategoriesWhole];
      if (isChecked(option)) {
        temp = temp.filter((item) => item !== option.id);
        tempWholeObject = tempWholeObject.filter(
          (item) => item.id !== option.id
        );
        setCategories(temp);
        setSelectedCatgeoriesWhole(tempWholeObject);
      } else {
        temp.push(option.id);
        tempWholeObject.push(option);
        setCategories(temp);
        setSelectedCatgeoriesWhole(tempWholeObject);
      }
    }
  };

  const subCategoryMapping = (item) => {
    const hasSubCategories = item?.sub_categories?.length > 0;
    return (
      // recurrsive function for mapping sub categories and sub sub categories
      <>
        <CategoryOption
          isChecked={isChecked(item)}
          isDisabled={isDisabled(item)}
          isRadioSelect={isRadioSelect}
          onChange={() => onChange(item)}
          option={item}
        />
        {/* in case of create new category is opened from inside this category modal,
         limit the depth shown to the user to maximum depth for create new category */}
        {isRadioSelect ? (
          item.depth < MULTI_LEVEL_CATEGORY_MAX_DEPTH_SELECT_CATEGORY &&
          hasSubCategories &&
          item.sub_categories.map((newRootItem) => (
            <React.Fragment key={newRootItem.id}>
              {subCategoryMapping(newRootItem)}
            </React.Fragment>
          ))
        ) : (
          <>
            {hasSubCategories ? (
              item.sub_categories.map((newRootItem) => (
                <React.Fragment key={newRootItem.id}>
                  {subCategoryMapping(newRootItem)}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  };

  const saveCategory = () => {
    setselectedCategories([...categories]);
    handleClose({ categories, selectedCategoriesWhole });
  };

  useEnterPressAction(open, saveCategory);

  return (
    <Modal
      open={open}
      closeModal={() => {
        handleClose({
          categories: selected,
          selectedCategoriesWhole: initiallySelectedCategoriesWhole,
          closeWithoutSave: true,
        });
      }}
      paperClass="select-category-modal"
    >
      <h2 className="section-text-4">{heading}</h2>
      <SearchFilter
        debounceTime={250}
        minLength={0}
        maxLength={30}
        placeholder={searchPlaceholder}
        onFilter={onSearch}
        className="mt12 full-w"
      />
      <hr className="hr-line mt16 mb8" />
      <div className="category-list overflow-overlay show-scrollbar">
        <Form
          onSubmit={noop}
          render={() => (
            <>
              {categoryOptions?.length > 0 ? (
                <>
                  {categoryOptions?.map((option) => {
                    const { id, sub_categories: subcategories = [] } = option;
                    return (
                      <React.Fragment key={id}>
                        <CategoryOption
                          isChecked={isChecked(option)}
                          isDisabled={isDisabled(option)}
                          isRadioSelect={isRadioSelect}
                          onChange={() => onChange(option)}
                          option={option}
                        />
                        {subcategories.length > 0 &&
                          subcategories.map((item) => (
                            <React.Fragment key={item.id}>
                              {subCategoryMapping(item)}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    );
                  })}
                  <div ref={loadingElement} style={{ height: 1 }} />
                  {loading && hasMore && <BounceLoaderSmall inline />}
                </>
              ) : (
                <p className="d-flex align-center justify-center">
                  No categories found
                </p>
              )}
            </>
          )}
        />
      </div>
      <hr className="hr-line mt8 mb16 " />
      {!hideAddCategoryBtn && (
        <SpinnerButton
          className="btn btn-outline-4 text-medium btn-outline-warning full-w"
          onClick={() => handleAddCategoryClick(categoryOptions[0])}
          showAnimation
        >
          + Add new category or subcategory
        </SpinnerButton>
      )}
      <div className="text-center mt24">
        <SpinnerButton
          className="btn-primary-4"
          disabled={!canSelectedCategoriesBeEmpty && categories.length === 0}
          onClick={saveCategory}
          showAnimation
        >
          {selectButtonText}
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default CategoryModal;
