import React, { useState, useEffect } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import useFirebaseOauth from '../hooks/useFirebaseOauth';
import SpinnerButton from '../shared/SpinnerButton';
import { NewFbLogo, NewGoogleLogo } from '../SvgIcon';
import { FACEBOOK_APP_ID } from '../constants';
import useCustomSnackbar from '../hooks/useCustomSnackbar';

const OAuthButtons = ({ setLoading }) => {
  const [facebookToken, setFacebookToken] = useState(null);
  const [facebookEmail, setFacebookEmail] = useState(null);

  const { oauth, handleAuthSuccess } = useFirebaseOauth();
  const { enqueueSnackbar } = useCustomSnackbar();

  const handleClick = (platform) => {
    setLoading(true);
    oauth(
      platform,
      () => {},
      () => setLoading(false)
    );
  };

  useEffect(() => {
    if (facebookToken && facebookEmail) {
      const payload = {
        additionalUserInfo: { profile: { email: facebookEmail } },
        credential: { accessToken: facebookToken },
      };
      handleAuthSuccess(payload, 'facebook');
    }
  }, [facebookToken, facebookEmail]);

  return (
    <div className="login-other-options d-flex">
      <SpinnerButton
        type="button"
        className="btn-outline-auth"
        onClick={() => handleClick('google')}
      >
        <NewGoogleLogo className="mr8" />
        <span>Google</span>
      </SpinnerButton>
      {/* <FacebookLogin
        appId={FACEBOOK_APP_ID}
        scope="email"
        onSuccess={(response) => {
          setFacebookToken(response.accessToken);
        }}
        onFail={(error) => {
          console.log('Login Failed!', error);
          setLoading(false);
        }}
        onProfileSuccess={(response) => {
          if (response.email) {
            setFacebookEmail(response.email);
          } else {
            setLoading(false);
            enqueueSnackbar(
              'Can not login with facebook account without email address.',
              'error'
            );
          }
        }}
        render={({ onClick }) => (
          <SpinnerButton
            type="button"
            className="btn-outline-auth"
            onClick={() => {
              setLoading(true);
              onClick();
            }}
          >
            <NewFbLogo className="mr8" />
            <span>Facebook</span>
          </SpinnerButton>
        )}
      /> */}
    </div>
  );
};

export default OAuthButtons;
