import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { PIN_CODE_DATA_API } from '../../../../ApiUrls';
import { useCountry } from '../../../../context/CountryProvider';
import useEnterPressAction from '../../../../hooks/useEnterPressAction';
import useOrderForm from '../../../../hooks/useOrderForm';
import BounceLoader from '../../../../shared/BounceLoader';
import { Validation } from '../../../../shared/Form';
import { modifyFormField } from '../../../../shared/Form/Shared';
import Modal from '../../../../shared/Modal';
import SpinnerButton from '../../../../shared/SpinnerButton';
import { countriesByPhoneCode } from '../../../../utils/countries';
import { getRequest } from '../../../../utils/http';
import { toTitleCase } from '../../../../utils/string';
import useFormFieldHelper from './FormFieldHelper';

const CustomerModal = ({
  buttonText = 'Add customer',
  closeModal,
  customer = {},
  open,
  title = 'Add new customer',
}) => {
  const { country, isInternational } = useCountry();

  const phoneCode = customer?.phone_code || country?.phone_code;

  const {
    orderFormData: customerDetailsFields,
    preferencesLoading: isCustomerDetailsFieldsLoading,
    fetchStoreOrderFormDetails,
  } = useOrderForm();

  const [countryMobile, setCountryMobile] = useState(
    countriesByPhoneCode.find(
      (eachCountry) =>
        Number(eachCountry.phone_code) === Number(phoneCode) &&
        eachCountry.currency_code === country.currency_code
    )
  );
  const [formValues, setFormValues] = useState({});
  const [formInvalid, setFormInvalid] = useState(false);
  const [formPristine, setFormPristine] = useState(true);

  const getPhoneCode = (countryCode) => {
    const selectedCountry = countriesByPhoneCode.find(
      (eachCountry) =>
        eachCountry.country_code.toLocaleLowerCase() === countryCode
    );
    return selectedCountry?.phone_code;
  };

  const mobileValidation = (values, phoneCodeData) => {
    if (isInternational) {
      if (values.mobile) {
        return Validation.composeValidators(
          (value) =>
            Validation.minLengthValidation(
              value,
              phoneCodeData.mobile_length_min,
              'Please enter a valid mobile number'
            ),
          (value) =>
            Validation.maxLengthValidation(
              value,
              phoneCodeData.mobile_length_max,
              'Please enter a valid mobile number'
            )
        );
      }
      return null;
    }
    return Validation.mobileValidation(
      phoneCodeData.mobile_length_min,
      phoneCodeData.mobile_length_max
    );
  };

  const onPincodeChange = (event, form) => {
    const {
      target: { value: pin },
    } = event;
    if (String(pin).length < 6) {
      form.mutators.modifyFormField('city', '');
      form.mutators.modifyFormField('state', null);
      return;
    }
    getRequest({
      url: PIN_CODE_DATA_API(pin),
    })
      .then(({ data }) => {
        const pinCodeData = data[0];
        form.mutators.modifyFormField('city', toTitleCase(pinCodeData.city));
        form.mutators.modifyFormField('state', toTitleCase(pinCodeData.state));
      })
      .catch(() => {});
  };

  const handleFormSubmit = (values) => {
    closeModal(values, formInvalid, formPristine);
  };

  useEnterPressAction(open && !formInvalid && !formPristine, () =>
    handleFormSubmit(formValues)
  );

  const { getField } = useFormFieldHelper({
    countryMobile,
    mobileValidation,
    onPincodeChange,
    setCountryMobile,
  });

  useEffect(() => {
    fetchStoreOrderFormDetails(customer?.country ?? false);
  }, []);

  useEffect(() => {
    if (formValues.country) {
      fetchStoreOrderFormDetails(formValues?.country ?? false);
    }
  }, [formValues.country]);

  return (
    <Modal
      open={open}
      closeModal={() => {
        closeModal(formInvalid ? null : formValues, formInvalid, formPristine);
      }}
      paperClass="md-lg customer-form-modal"
    >
      <p className="section-text-4 mb24">{title}</p>
      <div>
        {isCustomerDetailsFieldsLoading ? (
          <div className="min-h-250">
            <BounceLoader />
          </div>
        ) : (
          <Form
            onSubmit={handleFormSubmit}
            initialValues={{
              ...(customer || {}),
              phone_code: getPhoneCode(
                customerDetailsFields?.find((field) => field.name === 'country')
                  ?.default_value
              ),
              mobile:
                customer?.mobile?.split('-')[1] || customer?.mobile || null,
              line: customer?.address_line || customer?.line || null,
              state: toTitleCase(customer?.state ?? ''),
              country: customerDetailsFields?.find(
                (field) => field.name === 'country'
              )?.default_value,
            }}
            mutators={{ modifyFormField }}
            render={({ form, handleSubmit, invalid, pristine, values }) => {
              setFormValues(values);
              setFormInvalid(invalid);
              setFormPristine(pristine);

              return (
                <>
                  <div className="customer-form show-scrollbar">
                    {customerDetailsFields.map((field) =>
                      getField(field, form, values)
                    )}
                  </div>

                  <div className="text-center mt24">
                    <SpinnerButton
                      className="btn-primary-4"
                      disabled={invalid || pristine}
                      onClick={handleSubmit}
                      showAnimation
                    >
                      {buttonText}
                    </SpinnerButton>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default CustomerModal;
