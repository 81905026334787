import React from 'react';
import cx from 'classnames';
import { noop } from '../../utils';
import { padZeros } from '../../utils/string';
import SpinnerButton from '../SpinnerButton';

const NumberStepper = ({
  value = 0,
  limit = 0,
  min = 0,
  onChange = noop,
  className = '',
  ...rest
}) => {
  const onIncrement = () => {
    if (value < limit) {
      onChange(value + 1);
    }
  };
  const onDecrement = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  return (
    <div className={cx('stepper-container', className)} {...rest}>
      <div className="d-flex-c-c content-wrap cur-p" onClick={onDecrement}>
        <SpinnerButton type="button" className="stepper-btn decrement">
          {' '}
        </SpinnerButton>
      </div>
      <div className="stepper-divider" />
      <div className="stepper-value">
        <span>{padZeros(value)}</span>
      </div>
      <div className="stepper-divider" />
      <div className="d-flex-c-c content-wrap cur-p" onClick={onIncrement}>
        <SpinnerButton type="button" className="stepper-btn increment">
          {' '}
        </SpinnerButton>
      </div>
    </div>
  );
};

export default NumberStepper;
