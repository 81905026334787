import { CircularProgress } from '@material-ui/core';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { CURRENCY_SYMBOL } from '../constants';
import { useAppContext } from '../context/AppContext';
import { useCountry } from '../context/CountryProvider';
import { OrderWeight } from '../context/DeliveryProvider';
import { useStoreWarehouse } from '../context/StoreWarehouseProvider';
import { OrangeBadge } from '../shared/Badge';
import { FormNumberInput, Validation } from '../shared/Form';
import { modifyFormField } from '../shared/Form/Shared';
import Modal from '../shared/Modal';
import SpinnerButton from '../shared/SpinnerButton';
import { noop, stickyTopStyle } from '../utils';

const columns = [
  {
    key: 'products',
    title: 'Products',
  },
  {
    key: 'weight',
    title: 'Unit weight x Qty',
  },
  {
    key: 'delivery_charge',
    title: 'Shipping charge',
  },
];

const ProductWeightModal = ({
  open: { mode, carrier, pincode } = {},
  closeModal,
  ...restProps
}) => (
  <Modal
    open={!!mode}
    className="shipment-created-modal"
    closeModal={closeModal}
    title={<span className="mb2">Add product weight</span>}
    paperClass="md-lg"
  >
    <Form
      onSubmit={noop}
      mutators={{ modifyFormField }}
      keepDirtyOnReinitialize
      initialValues={{}}
      render={(props) => (
        <FormWeight
          {...props}
          {...restProps}
          closeModal={closeModal}
          mode={mode}
          carrier={carrier}
          pincode={pincode}
        />
      )}
    />
  </Modal>
);

const FormWeight = ({
  handleShip,
  errors,
  values,
  form,
  invalid,
  products,
  orderId,
  mode,
  carrier,
  pincode,
  orderShipmentObj,
}) => {
  const { canAvailFreeDukaanDelivery: freeDelivery } = useAppContext();
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();
  const {
    loading: isPriceCalculating,
    getShippingDetails,
    orderShippingDetails,
  } = orderShipmentObj;
  const { formatLocalMoney } = useCountry();
  const [formTouched, setFormTouched] = useState(false);
  const getTotalWeight = () => {
    const weight = Object.entries(values).reduce(
      (acc, each) => acc + (+each[1]?.weight || 0) * (each[1]?.qty || 1),
      0
    );
    return weight;
  };
  const totalShipmentWeight = getTotalWeight();
  const getGetShippingPayload = (...resArgs) => {
    const payload = { orders: [new OrderWeight(...resArgs)] };
    if (!isActiveStoreWarehouseExist)
      payload.custom_warehouse_pincode = Number(pincode);
    return payload;
  };

  const calculatePrice = () => {
    if (totalShipmentWeight > 0) {
      const payload = getGetShippingPayload(
        orderId,
        totalShipmentWeight,
        mode,
        carrier
      );
      getShippingDetails(payload);
    }
  };

  useEffect(calculatePrice, [values]);
  const isRequiredError =
    errors &&
    Object.values(errors).some((each) =>
      Object.values(each || {}).some((err) => err?.includes('required'))
    );
  const isLessThanError =
    errors &&
    Object.values(errors).some((each) =>
      Object.values(each || {}).some((err) =>
        err?.includes('Number is less than')
      )
    );

  let errorText = '';

  if (isRequiredError) {
    errorText = 'Add product weight first.';
  } else if (isLessThanError) {
    errorText = 'Added weight must be greater than 0 kg.';
  } else {
    errorText = 'Maximum allowed weight 100 kg.';
  }

  const isAllowed = ({ floatValue }) =>
    floatValue === undefined || floatValue <= 100;

  const productCount =
    products.length -
    products.filter(
      (val) => val.quantity + Number(val?.quantity_freed > 0).length
    );

  return (
    <div>
      <p className="mt24 c-black-3 text-2">
        Add individual product weight(s) to calculate the shipping charge.
      </p>
      {formTouched && invalid && (
        <div className="error-stripe mt16">{errorText}</div>
      )}
      <div>
        <div className="pt24 mx-12" style={stickyTopStyle(0)}>
          <div className="table-header-wrapper product-weight-table">
            {columns.map((col) => (
              <div key={col.key} className="table-header">
                <div className="d-flex align-center cur-p">
                  <span className="mr8">{col.title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="table-data-container mx-12 show-scrollbar"
          style={{ maxHeight: 380, overflow: 'scroll' }}
        >
          <div className="table-data-row product-weight-table">
            <div className="d-flex flex-column">
              {products.map((product, idx) => {
                if (product.quantity + Number(product?.quantity_freed) <= 0)
                  return null;
                return (
                  <span
                    key={`product_${idx}`}
                    className="d-flex align-center product"
                  >
                    <img
                      src={product.image}
                      alt="product"
                      width="48px"
                      height="48px"
                    />
                    <span className="d-flex flex-column product-name">
                      <span className="text-ellipsis">{product.name}</span>
                      <span>
                        {product.base_qty} {product.unit}
                      </span>
                    </span>
                  </span>
                );
              })}
            </div>
            <div
              className={cx({
                'right-horizontal-line': products.length > 1,
              })}
              style={{ height: productCount * 48 + (productCount - 1) * 16 }}
            >
              {products.map((product, idx) => {
                if (product.quantity + Number(product?.quantity_freed) === 0)
                  return null;
                return (
                  <span
                    key={`product_${idx}`}
                    className="d-flex align-center product"
                  >
                    <FormNumberInput
                      autoFocus={idx === 0}
                      name={`product_${product.product_id}.weight`}
                      max={100}
                      maxLength={6}
                      decimalScale={3}
                      required
                      showRequired
                      placeholder="eg. 0.7"
                      appendText="kg"
                      className="mb0 mr6"
                      validate={Validation.validateNumber(0, 100)}
                      onChange={(e) => {
                        form.mutators.modifyFormField(
                          `product_${product.product_id}.qty`,
                          product.quantity + Number(product?.quantity_freed)
                        );
                      }}
                      error={
                        formTouched &&
                        errors?.[`product_${product.product_id}`]?.weight
                      }
                      isAllowed={isAllowed}
                    />
                    x {product.quantity + Number(product?.quantity_freed)}
                  </span>
                );
              })}
            </div>
            <div
              className="d-flex align-center"
              style={{
                height: productCount * 48 + (productCount - 1) * 16,
              }}
            >
              <span className="d-flex align-center">
                {!isPriceCalculating && (
                  <div>
                    {orderShippingDetails?.original_delivery_charge >= 0 &&
                    totalShipmentWeight > 0 ? (
                      <>
                        {freeDelivery && (
                          <div className="d-flex flex-column product-cost text-medium">
                            <div className="d-flex align-center">
                              {orderShippingDetails?.original_delivery_charge <=
                              100 ? (
                                <span className="c-green-1">FREE</span>
                              ) : (
                                formatLocalMoney(
                                  orderShippingDetails?.original_delivery_charge -
                                    100
                                )
                              )}
                              <span className="original-cost strike text-8 text-normal">
                                {formatLocalMoney(
                                  orderShippingDetails?.original_delivery_charge
                                )}
                              </span>
                            </div>
                            {orderShippingDetails?.original_delivery_charge >
                              100 && (
                              <span className="c-orange-1 text-10 w-100">
                                ({formatLocalMoney(100)} OFF)
                              </span>
                            )}
                          </div>
                        )}
                        {!freeDelivery && (
                          <div>
                            <div>
                              <span className="mr4">
                                {formatLocalMoney(
                                  orderShippingDetails?.delivery_charge
                                )}
                              </span>
                              {orderShippingDetails?.discount_percentage >
                                0 && (
                                <span className="c-gray-1 strikethrough">
                                  {formatLocalMoney(
                                    orderShippingDetails?.original_delivery_charge
                                  )}
                                </span>
                              )}
                            </div>
                            {orderShippingDetails?.discount_percentage > 0 && (
                              <OrangeBadge
                                text={`Extra ${orderShippingDetails?.discount_percentage}% off`}
                              />
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      `${CURRENCY_SYMBOL}-`
                    )}
                  </div>
                )}
                {isPriceCalculating && (
                  <>
                    <CircularProgress
                      className="mr6"
                      style={{ width: 20, height: 20 }}
                    />
                    Calculating
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex-c-c mt12">
        <SpinnerButton
          type="button"
          showAnimation
          onClick={() => {
            setFormTouched(true);
            if (!invalid)
              handleShip(
                Number(Number(totalShipmentWeight).toFixed(3)),
                mode,
                carrier,
                pincode
              );
          }}
        >
          Proceed to Ship
        </SpinnerButton>
      </div>
    </div>
  );
};

export default ProductWeightModal;
