import React from 'react';
import {
  DeclineOrderIcon,
  DeliverOrderIcon,
  DeliverDiningOrderIcon,
  PaypalOrderLogo,
  RazorpayOrderLogo,
  BraintreeIcon,
  TwoCTwoPIcon,
  XenditIcon,
  StripeIcon,
  PaymongoIcon,
  DLocalIcon,
} from '../SvgIcon';
import { rootUrls } from '../Urls';
import { getOptionsNameMap } from '../utils';
import { capitaliseFirst } from '../utils/string';

export const MODIFIED_STATUS = -2;
export const ALL_STATUS = -3;
export const ABANDONED_STATUS = -1;
export const MANUAL_PAYMENT_METHOD_DRAFT_STATUS = -1;
export const PENDING_STATUS = 0;
export const ACCEPTED_STATUS = 1;
export const REJECTED_STATUS = 2;
export const SHIPPED_STATUS = 3;
export const CANCELLED_STATUS = 4;
export const DELIVERED_STATUS = 5;
export const FAILED_STATUS = 6;
export const CANCELLED_BY_CUSTOMER = 7;
export const RETURN_STATUS = 10;
export const RTO_STATUS = 11;
export const REJECT_SHIPMENT_STATUS = 15;
export const PAGE_SIZE = 30;

// Order Reverse Journey
export const ALL_RETURN = -1;
export const DRAFT_RETURN = 0;
export const REQUESTED_RETURN = 1;
export const APPROVED_RETURN = 2;
export const REJECTED_RETURN = 3;
export const COMPLETED_RETURN = 4;

// Return replacement constant
export const RETURN_REPLACEMENT_MAP = {
  RETURN: 0,
  REPLACEMENT: 1,
  0: 'RETURN',
  1: 'REPLACEMENT',
  UPDATED: 'UPDATED',
};

// abandoned
export const RECOVERABLE = 12;
export const NOT_RECOVERED = 13;
export const RECOVERED = 14;

export const DEFAULT_ORDER_CREATION = 0;
export const DEFAULT_ACTIVE_ORDER_STATUS = PENDING_STATUS;

export const HYPERLOCAL_CARRIER_ID = 4;
export const SURFACE_CARRIER_ID = 1;
export const EXPRESS_CARRIER_ID = 2;
export const HEAVY_CARRIER_ID = 3;
export const INTERNATIONAL_CARRIER_ID = 5;
export const HYPERLOCAL_TOOLTIP_TEXT =
  '₹40 for first 2 km, then ₹12 for every other km.';

export const SURFACE_MULTIPLE_TAB_ID = 1;
export const EXPRESS_MULTIPLE_TAB_ID = 2;
export const HEAVY_MULTIPLE_TAB_ID = 3;
export const LOCAL_MULTIPLE_TAB_ID = 4;
export const RECOMMENDED_MULTIPLE_TAB_ID = 'recommended';

export const SHIPPING_MULTIPLE_MODE_MAP = {
  [SURFACE_MULTIPLE_TAB_ID]: 'surface',
  [EXPRESS_MULTIPLE_TAB_ID]: 'express',
  [HEAVY_MULTIPLE_TAB_ID]: 'surface-heavy',
  [LOCAL_MULTIPLE_TAB_ID]: 'local',
  [RECOMMENDED_MULTIPLE_TAB_ID]: 'recommended',
};

export const SHIPPING_MODE_MAP = {
  [SURFACE_CARRIER_ID]: 'surface',
  [HYPERLOCAL_CARRIER_ID]: 'local',
  [EXPRESS_CARRIER_ID]: 'express',
  [HEAVY_CARRIER_ID]: 'surface-heavy',
};

export const AUTO_ACCEPTED_ORDER_TOOLTIP_TEXT = 'Auto-accepted order';
export const AUTO_REJECTED_ORDER_TOOLTIP_TEXT = 'Auto-rejected order';

export const RESTAURANT_ORDER_ACTION_MAPS = {
  [PENDING_STATUS]: [
    {
      buttonText: 'Reject order',
      buttonClass: 'btn-no-default mr6 c-red-1',
      newStatus: REJECTED_STATUS,
      message: 'Order rejected',
      confirmation: {
        header: 'Reject order',
        content: 'Once you reject the order, you can’t accept it anymore.',
        // icon: <DeclineOrderIcon className="header-svg-icon" />,
        confirmText: 'Yes, reject',
        confirmBtnClass: 'btn-danger-4',
        showAnimation: true,
      },
    },
    {
      buttonText: 'Accept order',
      buttonClass: 'btn-success-4',
      newStatus: ACCEPTED_STATUS,
      message: 'Order accepted',
      showAnimation: true,
    },
  ],
  [ACCEPTED_STATUS]: [
    {
      buttonText: 'Mark as served',
      buttonClass: 'btn-dining-4',
      newStatus: DELIVERED_STATUS,
      message: 'Order served',
      confirmation: {
        header: 'Mark as served',
        content: 'Are you sure that you have served the order?',
        // icon: <DeliverDiningOrderIcon className="header-svg-icon" />,
        confirmText: 'Yes, served',
        confirmBtnClass: 'btn-primary-4 btn-dining',
        showAnimation: true,
      },
      showAnimation: true,
    },
  ],
};

export const ORDER_ACTION_MAPS = {
  [PENDING_STATUS]: [
    {
      buttonText: 'Reject order',
      buttonClass: 'btn-no-default mr6 c-red-1',
      newStatus: REJECTED_STATUS,
      message: 'Order rejected',
      confirmation: {
        header: 'Reject order',
        content: 'Once you reject the order, you can’t accept it anymore.',
        confirmText: 'Yes, reject',
        confirmBtnClass: 'btn-danger-4',
        showAnimation: true,
      },
      showForStaff: true,
    },
    {
      buttonText: 'Accept order',
      buttonClass: 'btn-success-4',
      newStatus: ACCEPTED_STATUS,
      message: 'Order accepted',
      showAnimation: true,
    },
  ],
  [ACCEPTED_STATUS]: [
    {
      buttonText: 'Cancel order',
      buttonClass: 'btn-no-default text-medium mr6 c-red-1',
      newStatus: CANCELLED_STATUS,
      message: 'Order cancelled',
      confirmation: {
        header: 'Cancel Order',
        content: 'Once you cancel the order, you can’t ship it anymore.',
        confirmText: 'Yes, cancel',
        confirmBtnClass: 'btn-danger-4',
        showAnimation: true,
      },
      showForStaff: true,
    },
    {
      buttonText: 'Ship order',
      buttonClass: 'btn-warning-4',
      newStatus: SHIPPED_STATUS,
      message: 'Order shipped',
      showAnimation: true,
    },
  ],
  [SHIPPED_STATUS]: [
    {
      buttonText: 'Failed',
      buttonClass: 'btn-no-default mr6 c-red-1',
      newStatus: FAILED_STATUS,
      message: 'Order not delivered',
      confirmation: {
        header: 'Order failed',
        content: 'Do you really want to mark this order as failed?',
        // icon: <DeclineOrderIcon className="header-svg-icon" />,
        confirmText: 'Yes, mark as failed',
        confirmBtnClass: 'btn-danger-4',
        showAnimation: true,
      },
    },
    {
      buttonText: 'Delivered',
      buttonClass: 'btn-primary-4',
      newStatus: DELIVERED_STATUS,
      message: 'Order delivered',
      confirmation: {
        header: 'Confirm delivery',
        content: 'Are you sure that you have delivered the order?',
        // icon: <DeliverOrderIcon className="header-svg-icon" />,
        confirmText: 'Yes, delivered',
        confirmBtnClass: 'btn-primary-4',
        showAnimation: true,
      },
      showAnimation: true,
    },
  ],
  [MANUAL_PAYMENT_METHOD_DRAFT_STATUS]: [
    {
      buttonText: 'Reject order',
      buttonClass: 'btn-no-default c-red-1 mr18',
      newStatus: REJECTED_STATUS,
      message: 'Order rejected',
      confirmation: {
        header: 'Reject order',
        content: 'Once you reject the order, you can’t accept it anymore.',
        // icon: <DeclineOrderIcon className="header-svg-icon" />,
        confirmText: 'Yes, reject',
        confirmBtnClass: 'btn-danger-4',
        showAnimation: true,
      },
    },
  ],
};

export const ORDER_STATUS_OPTIONS = [
  {
    value: ALL_STATUS,
    label: 'All',
  },
  {
    value: PENDING_STATUS,
    label: 'Pending',
  },
  {
    value: ACCEPTED_STATUS,
    label: 'Accepted',
  },
  {
    value: SHIPPED_STATUS,
    label: 'Shipped',
  },
  {
    value: DELIVERED_STATUS,
    label: 'Delivered',
  },
  {
    value: FAILED_STATUS,
    label: 'Failed',
  },
];

export const RETURN_STATUS_OPTIONS = [
  {
    value: ALL_RETURN,
    label: 'All',
  },
  {
    value: REQUESTED_RETURN,
    label: 'Pending request',
  },
  {
    value: APPROVED_RETURN,
    label: 'Approved',
  },
  {
    value: REJECTED_RETURN,
    label: 'Rejected',
  },
  {
    value: COMPLETED_RETURN,
    label: 'Completed',
  },
];

export const ORDER_STATUS_MAP = {
  [MODIFIED_STATUS]: 'modified',
  [ALL_STATUS]: 'all',
  [ABANDONED_STATUS]: 'abandoned',
  [PENDING_STATUS]: 'pending',
  [ACCEPTED_STATUS]: 'accepted',
  [REJECTED_STATUS]: 'rejected',
  [SHIPPED_STATUS]: 'shipped',
  [CANCELLED_STATUS]: 'cancelled',
  [DELIVERED_STATUS]: 'delivered',
  [FAILED_STATUS]: 'failed',
  [RTO_STATUS]: 'failed',
  [RETURN_STATUS]: 'returned',
  [CANCELLED_BY_CUSTOMER]: 'cancelled by buyer',
};

export const RETURN_STATUS_MAP = {
  [ALL_RETURN]: 'all',
  [DRAFT_RETURN]: 'Draft request',
  [REQUESTED_RETURN]: 'Pending request',
  [APPROVED_RETURN]: 'Return in-progress',
  [REJECTED_RETURN]: 'Return rejected',
  [COMPLETED_RETURN]: 'Return completed',
};

export const GET_RETURN_STATUS_MAP = (returnType = '') => ({
  [DRAFT_RETURN]: `Draft ${returnType}`,
  [REQUESTED_RETURN]: `${capitaliseFirst(returnType)} requested`,
  [APPROVED_RETURN]: `${capitaliseFirst(returnType)} in-progress`,
  [REJECTED_RETURN]: `${capitaliseFirst(returnType)} rejected`,
  [COMPLETED_RETURN]: `${capitaliseFirst(returnType)} completed`,
});

export const RESTAURANT_ORDER_STATUS_MAP = {
  ...ORDER_STATUS_MAP,
  [DELIVERED_STATUS]: 'served',
};

export const NAV_BACK_ORDER_STATUSES = [
  REJECTED_STATUS,
  CANCELLED_STATUS,
  FAILED_STATUS,
  DELIVERED_STATUS,
];

export const ACTIVE_ORDER_STATUS_OPTIONS = [
  {
    value: PENDING_STATUS,
    label: 'Pending',
    count: 0,
  },
  {
    value: ACCEPTED_STATUS,
    label: 'Accepted',
    count: 0,
  },
  {
    value: SHIPPED_STATUS,
    label: 'Shipped',
    count: 0,
  },
];

export const ACTIVE_ORDER_STATUS_OPTIONS_DYNAMIC_DB = [
  {
    value: PENDING_STATUS,
    label: 'Pending',
    count: 0,
  },
  {
    value: ACCEPTED_STATUS,
    label: 'Accepted',
    count: 0,
  },
];

export const MAIN_ORDER_FILTERS = [
  {
    value: ALL_STATUS,
    label: 'All',
    count: 0,
  },
  {
    value: [PENDING_STATUS, MODIFIED_STATUS],
    label: 'Pending',
    count: 0,
  },
  {
    value: ACCEPTED_STATUS,
    label: 'Accepted',
    count: 0,
  },
  {
    value: SHIPPED_STATUS,
    label: 'Shipped',
    count: 0,
  },
  {
    value: DELIVERED_STATUS,
    label: 'Delivered',
    count: 0,
  },
  {
    value: [
      REJECTED_STATUS,
      CANCELLED_BY_CUSTOMER,
      CANCELLED_STATUS,
      FAILED_STATUS,
      RETURN_STATUS,
    ],
    label: 'Others',
    count: 0,
  },
];

export const RESTAURANT_ORDER_FILTERS = [
  {
    value: ALL_STATUS,
    label: 'All',
    count: 0,
  },
  {
    value: [PENDING_STATUS, MODIFIED_STATUS],
    label: 'Pending',
    count: 0,
  },
  {
    value: ACCEPTED_STATUS,
    label: 'Accepted',
    count: 0,
  },
  {
    value: SHIPPED_STATUS,
    label: 'Shipped',
    count: 0,
  },
  {
    value: DELIVERED_STATUS,
    label: 'Delivered',
    count: 0,
  },
  {
    value: [
      REJECTED_STATUS,
      CANCELLED_BY_CUSTOMER,
      CANCELLED_STATUS,
      FAILED_STATUS,
    ],
    label: 'Others',
    count: 0,
  },
];

export const OTHER_ORDER_FILTERS = [
  {
    value: REJECTED_STATUS,
    label: 'Rejected',
    count: 0,
  },
  {
    value: [CANCELLED_BY_CUSTOMER, CANCELLED_STATUS],
    label: 'Cancelled',
    count: 0,
  },
  {
    value: FAILED_STATUS,
    label: 'Failed',
    count: 0,
  },
];

export const AMOUNT_AND_QUANTITY_FILTER_OPTIONS = {
  LESS_THAN: {
    label: 'Less than',
    value: 'less_than',
  },
  GREATER_THAN: {
    label: 'Greater than',
    value: 'greater_than',
  },
  EQUAL_TO: {
    label: 'Equal to',
    value: 'equal_to',
  },
  IS_BETWEEN: {
    label: 'Is between',
    value: 'is_between',
  },
};

export const AMOUNT_AND_QUANTITY_FILTER_KEYS = [
  'order_amount',
  'order_amount_lt',
  'order_amount_lte',
  'order_amount_gt',
  'order_amount_gte',
  'order_item',
  'order_item_lt',
  'order_item_lte',
  'order_item_gt',
  'order_item_gte',
];

export const ORDER_CREATION_OPTIONS = [
  {
    value: 0,
    label: 'Lifetime',
  },
  {
    value: 1,
    label: 'Today',
  },
  {
    value: 2,
    label: 'Yesterday',
  },
  {
    value: 3,
    label: 'This Week',
  },
  {
    value: 4,
    label: 'This Month',
  },
  {
    value: 5,
    label: 'Last Month',
  },
];

export const DURATION_ORDER_CREATION_MAP = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 0,
};

export const DURATION_FILTER_OPTIONS = [
  {
    value: 1,
    label: 'Today',
  },
  {
    value: 2,
    label: 'Yesterday',
  },
  {
    value: 3,
    label: 'This Week',
  },
  {
    value: 4,
    label: 'This Month',
  },
  {
    value: 5,
    label: 'Last Month',
  },
  {
    value: 0,
    label: 'Lifetime',
  },
];

export const DURATION_FILTER_OPTIONS_MAP = getOptionsNameMap(
  DURATION_FILTER_OPTIONS
);

export const CASH_PAYMENT_MODE = 0;
export const ONLINE_PAYMENT_MODE = 1;
export const UNPAID_ORDER = 2;
export const PREPAID_ORDER = 'seller_marked_prepaid';
export const MANUAL_PAYMENT_METHOD = 'MANUAL';

export const PAYMENT_MODE_LABEL = 'Payment';
export const CHANNEL_MODE_LABEL = 'Channel';
export const OTHERS_MODE_LABEL = 'Others';

export const PAYMENT_MODE_MAP = {
  [CASH_PAYMENT_MODE]: 'Cash on Delivery',
  [ONLINE_PAYMENT_MODE]: 'Online Payment',
};

export const DINING_PAYMENT_MODE_MAP = {
  [CASH_PAYMENT_MODE]: 'Cash',
  [ONLINE_PAYMENT_MODE]: 'Online Payment',
};

export const PAYMENT_MODE_SHORT_MAP = {
  [CASH_PAYMENT_MODE]: 'COD',
  [ONLINE_PAYMENT_MODE]: 'PAID',
  [UNPAID_ORDER]: 'UNPAID',
  [PREPAID_ORDER]: 'PREPAID',
};

export const DINING_PAYMENT_MODE_SHORT_MAP = {
  [CASH_PAYMENT_MODE]: 'CASH',
  [ONLINE_PAYMENT_MODE]: 'PAID',
  [UNPAID_ORDER]: 'UNPAID',
};

export const MANUAL_PAYMENT_METHOD_CHECK = {
  TYPE: 0,
  PAYMENT_MODE: 2,
};

export const TIME_SLOTS = [
  { label: '30-60 minutes', value: 1 },
  { label: '1-4 hours', value: 2 },
  { label: '6-24 hours', value: 3 },
  { label: '1-3 days', value: 4 },
  { label: '3-5 days', value: 5 },
  { label: '5-10 days', value: 6 },
  { label: '10+ days', value: 7 },
];

export const TIME_SLOTS_LABEL = [
  '30-60 minutes',
  '1-4 hours',
  '6-24 hours',
  '1-3 days',
  '3-5 days',
  '5-10 days',
  '10+ days',
];

export const NUMBER_OF_ITEMS_TO_LOAD = 6;

export const DEFAULT_ORDER_STATE = 0;
export const MINI_ORDER_STATE = 1;
export const CLUBBED_ORDER_STATE = 2;

export const persistKeys = [
  'orderFilters',
  'ordersSearch',
  'orderOptions',
  'orderFilterOptions',
  'orderToggleSelected',
  'orderDurationFilter',
  'ordersHeaderText',
  'ordersStatusLabel',
  'mainDuration',
];

export const tableOrdersPersistKeys = ['tableOptions', 'tableFilters'];

export const FORM_FIELD_TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  DATE_PICKER: 'date_picker',
  TIME_PICKER: 'time_picker',
  FILE_PICKER: 'file_picker',
  DROPDOWN: 'dropdown',
};

const numberifyArray = (array = []) => array.map((item) => Number(item));

export const getOrderStatusLabel = (status = ALL_STATUS, type) => {
  if (Number(status) === DELIVERED_STATUS) {
    return 'Delivered';
  }
  if (Array.isArray(status)) {
    const statuses = numberifyArray(status);
    if (statuses.includes(PENDING_STATUS)) {
      return 'Pending';
    }
    if (statuses.includes(CANCELLED_STATUS)) {
      return 'Cancelled';
    }
  }
  return ORDER_STATUS_MAP[Number(status)];
};

// READY_TO_SHIP = 1
// PICK_UP_REQUESTED = 2

export const SHIPMENT_CANCELLABLE_STATUSES = [1, 2, 16, 17, 18];

export const PAYMENT_PROVIDERS = {
  PADDLE: 'paddle',
  RAZOR_PAY: 'razor_pay',
  CASHFREE: 'cashfree',
  PAYPAL: 'paypal',
  MANUAL_TRANSFER: 'manual_transfer',
  STRIPE: 'stripe',
  BRAINTREE: 'braintree',
  TWO_C_TWO_P: 'two_c_two_p',
  XENDIT: 'xendit',
  PAYMONGO: 'paymongo',
  D_LOCAL: 'dlocal',
};

export const INTL_PAYMENT_LOGOS = {
  [PAYMENT_PROVIDERS.STRIPE]: StripeIcon,
  [PAYMENT_PROVIDERS.BRAINTREE]: BraintreeIcon,
  [PAYMENT_PROVIDERS.TWO_C_TWO_P]: TwoCTwoPIcon,
  [PAYMENT_PROVIDERS.XENDIT]: XenditIcon,
  [PAYMENT_PROVIDERS.PAYMONGO]: PaymongoIcon,
  [PAYMENT_PROVIDERS.D_LOCAL]: DLocalIcon,
  [PAYMENT_PROVIDERS.PAYPAL]: PaypalOrderLogo,
  [PAYMENT_PROVIDERS.RAZOR_PAY]: RazorpayOrderLogo,
};

export const OrderPaymentProviderLogo = ({ provider }) => {
  let Logo = null;
  if (INTL_PAYMENT_LOGOS[provider]) {
    Logo = INTL_PAYMENT_LOGOS[provider];
    return <Logo />;
  }
  return (
    <div>
      <span className="text-2">Online Payment</span>
    </div>
  );
};

export const DukaanDeliveryPartnersList = [
  {
    id: 'dehlivery',
    name: 'Delhivery',
    logo: 'https://dms.mydukaan.io/original/webp/logistics-provider-logos/delhivery.png',
    starting_price_inr: 31,
    rate_text: 'per 500g',
  },
  {
    id: 'xpressbees',
    name: 'Xpressbees',
    logo: 'https://dms.mydukaan.io/original/webp/logistics-provider-logos/xpressbees.png',
    starting_price_inr: 37,
    rate_text: 'per 500g',
  },
  {
    id: 'Blue Dart',
    name: 'Blue Dart',
    logo: 'https://dms.mydukaan.io/original/webp/logistics-provider-logos/bluedart.png',
    starting_price_inr: 38,
    rate_text: 'per 500g',
  },
  {
    id: 'borzo',
    name: 'Borzo',
    logo: 'https://dms.mydukaan.io/original/webp/logistics-provider-logos/borzo.png',
    starting_price_inr: 12,
    rate_text: 'per km',
    is_hyperlocal: true,
  },
];

export const ORDER_LABELS_NAME_MAP = {
  name: 'Name',
  email: 'Email',
  mobile: 'Mobile',
  line: 'Address',
  pin: 'Zip/Pin Code',
  city: 'City',
  country: 'Country',
  state: 'State',
  landmark: 'Landmark',
  area: 'Locality / Area',
  province: 'Province',
  region: 'Region',
  perfecture: 'Perfecture',
  emirate: 'Emirate',
  county: 'County',
  governorate: 'Governorate',
};
export const RECOVERY_CHANNEL_CAMPAIGN_ID = {
  WHATSAPP: 2,
  SMS: 1,
};

// RTO Constants

export const RTO_HIGH_RISK = 'HIGH';
export const RTO_MEDIUM_RISK = 'MEDIUM';
export const RTO_LOW_RISK = 'LOW';

export const RTO_PARTIAL_PAYMENT = 'PARTIAL';
export const RTO_FULL_PAYMENT = 'FULL';

export const MINIMUM_CREDITS_TO_CHECK_RTO_STATUS = 250;

export const ORDER_CREATION = {
  ONLINE: 0,
  MANUAL: 1,
};

export const CHANNEL_TEXT = {
  [ORDER_CREATION.ONLINE]: 'Online',
  [ORDER_CREATION.MANUAL]: 'Manual',
};

export const ORDER_TYPES = {
  COD: 0,
  ONLINE: 1,
  MANUAL: 5,
};

export const RTO_SCORE_LOWER_LIMIT = 35;
export const RTO_SCORE_UPPER_LIMIT = 56;

export const ORDER_TAG = 'order';
export const PRODUCT_TAG = 'product';
export const CUSTOMER_TAG = 'storelead';

export const CONTENT_TYPE = {
  [ORDER_TAG]: 12,
  [PRODUCT_TAG]: 13,
  [CUSTOMER_TAG]: 170,
};

export const TAGS_FOR = {
  ORDER: ORDER_TAG,
  PRODUCT: PRODUCT_TAG,
  CUSTOMER: CUSTOMER_TAG,
};

export const getCurrentPathWithUuid = (path, uuid) =>
  path.replace(':uuid', uuid);

export const generateSelectDeliveryPartnerLabels = (uuid) => {
  const {
    orderReturnPickupPartnerPath,
    orderReturnPickupCostPath,
    orderReplacementPickupPartnerPath,
    orderReplacementPickupCostPath,
    orderReplacementForwardPartnerPath,
    orderReplacementForwardCostPath,
  } = rootUrls;

  return {
    [getCurrentPathWithUuid(orderReturnPickupPartnerPath, uuid)]: {
      layoutHeading: 'Select partner for return pickup',
      routePath: getCurrentPathWithUuid(orderReturnPickupCostPath, uuid),
    },
    [getCurrentPathWithUuid(orderReplacementPickupPartnerPath, uuid)]: {
      layoutHeading: 'Select partner for replacement pickup',
      routePath: getCurrentPathWithUuid(orderReplacementPickupCostPath, uuid),
    },
    [getCurrentPathWithUuid(orderReplacementForwardPartnerPath, uuid)]: {
      layoutHeading: 'Select partner for replacement delivery',
      routePath: getCurrentPathWithUuid(orderReplacementForwardCostPath, uuid),
    },
  };
};

export const generateConfirmDeliveryLabels = (uuid) => {
  const {
    orderDeliveryCostPath,
    orderReturnPickupCostPath,
    orderReplacementPickupCostPath,
    orderReplacementForwardCostPath,
  } = rootUrls;

  return {
    [getCurrentPathWithUuid(orderDeliveryCostPath, uuid)]: {
      layoutHeading: 'Confirm delivery',
      deliveryHeading: 'Shipping via',
      addressHeading: 'Delivering to',
      chargeLabel: 'Shipping charge',
      totalChargeLabel: 'Total delivery charge',
      requestBtnLabel: 'delivery',
      confirmText: 'Shipment created',
    },
    [getCurrentPathWithUuid(orderReturnPickupCostPath, uuid)]: {
      layoutHeading: 'Confirm return pickup from customer',
      deliveryHeading: 'Pickup via',
      addressHeading: 'Pickup from',
      chargeLabel: 'Pickup charge',
      totalChargeLabel: 'Total pickup charge',
      requestBtnLabel: 'pickup',
      confirmText: 'Return pickup confirmed!',
      hideInvoiceAndLabel: true,
      redirectPathAfterSuccess: getCurrentPathWithUuid(
        rootUrls.orderDetailsPath,
        uuid
      ),
    },
    [getCurrentPathWithUuid(orderReplacementPickupCostPath, uuid)]: {
      layoutHeading: 'Confirm replacement pickup from customer',
      deliveryHeading: 'Pickup via',
      addressHeading: 'Pickup from',
      chargeLabel: 'Pickup charge',
      totalChargeLabel: 'Total pickup charge',
      requestBtnLabel: 'pickup',
      confirmText: 'Replacement pickup confirmed!',
      hideInvoiceAndLabel: true,
      redirectPathAfterSuccess: getCurrentPathWithUuid(
        rootUrls.orderDetailsPath,
        uuid
      ),
    },
    [getCurrentPathWithUuid(orderReplacementForwardCostPath, uuid)]: {
      layoutHeading: 'Confirm replacement delivery',
      deliveryHeading: 'Shipping via',
      addressHeading: 'Delivering to',
      chargeLabel: 'Shipping charge',
      totalChargeLabel: 'Total delivery charge',
      requestBtnLabel: 'delivery',
      confirmText: 'Shipment created',
      redirectPathAfterSuccess: getCurrentPathWithUuid(
        rootUrls.orderDetailsPath,
        uuid
      ),
    },
  };
};

export const BULK_DELIVERY_BATCH_SIZE = 50;

export const RETURN_REFUND_STATUS_MAP = {
  INITIATED: 'initiated',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
export const REFUND_ACCOUNT_TYPE = {
  BANK: 'bank',
  UPI: 'upi',
};

export const SHOW_SELF_SHIP = 'SHOW_SELF_SHIP';
export const SELECT_ORDERS_MAP = {
  [PENDING_STATUS]: {
    title: 'Pending orders',
    btnText: 'Change status to Accepted',
    status: PENDING_STATUS,
    nextAction: 'accepted',
    btnClass: 'btn-success-4',
  },
  [ACCEPTED_STATUS]: {
    title: 'Accepted orders',
    btnText: 'Proceed to self ship',
    status: ACCEPTED_STATUS,
    nextAction: 'shipped',
    btnClass: 'btn-warning-4',
  },
  [SHIPPED_STATUS]: {
    title: 'Self shipped orders',
    btnText: 'Change status to Delivered',
    status: SHIPPED_STATUS,
    nextAction: 'delivered',
    btnClass: 'btn-primary-4',
  },
};

export const PAYMENT_MODE = 'payment_mode';
export const CHANNEL = 'channel';
export const OTHERS = 'others';
export const TAGS = 'tags';
export const SOURCE = 'source';

export const FREEBIE_DISCOUNT_COUPON_KEY = 'freebie';
export const LOYALTY_POINTS_COUPON_KEY = 'loyalty_reward';
export const DISCOUNT_TYPE_COUPON = 'coupon';
export const DISCOUNT_TYPE_MANUAL = 'manual_discount';
export const DISCOUNT_TYPE_MEMBERSHIP = 'membership_discount';

export const ORDER_TAGS_COLUMN_PREFERENCE_KEY = 'tags';

export const LOGIN_AS_CUSTOMER_ENABLED = 'allow_seller_to_login_as_buyer';

export const DEBOUNCING_DELAY = 500;
