import { useEffect, useState } from 'react';
import cx from 'classnames';
import { CircularProgress, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { Form } from 'react-final-form';
import { BlueInfoIcon, GrayInfoIcon, PercentDiscount } from '../../SvgIcon';
import { useAppContext } from '../../context/AppContext';
import { useCountry } from '../../context/CountryProvider';
import CustomRadio from '../../shared/CustomRadio';
import ImageLoader from '../../shared/ImageLoader';
import { partnerImgStyle } from '../MultipleDeliveryPartners';
import ToggleButtonGroup from '../../shared/ToggleButtonGroup';
import { FormCustomSelect, FormInput, Validation } from '../../shared/Form';
import { noop, modifyFormField } from '../../utils';
import useResponsive from '../../hooks/useResponsive';
import { isHyperlocalSelected } from '../../context/DeliveryProvider';
import { HEAVY_CARRIER_ID, HYPERLOCAL_TOOLTIP_TEXT } from '../constants';
import { PREFERRED_WAREHOUSE_STORES } from '../../constants';
import { useStoreWarehouse } from '../../context/StoreWarehouseProvider';

const OptionsTableInputs = ({
  isActiveStoreWarehouseExist,
  isCalculateShipment,
  getDay,
  tabOptions,
  tabValue,
  isHyperlocal,
  formDirty,
  pincode,
  isDukaanDelivery,
  priceLoading,
  setPincode,
  warehouseFromURL,
  selectedWarehouse,
  setSelectedWarehouse = noop,
}) => {
  const {
    business: { id: storeId },
  } = useAppContext();
  const { isMobile } = useResponsive();
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const { fetchWarehouseList } = useStoreWarehouse(false);
  const validatePincodeFeild = () => {
    Validation.composeValidators(
      Validation.required('Pincode is required'),
      (value) => {
        if (String(value).length < 6) {
          return 'Enter valid pincode';
        }
        return null;
      }
    );
  };

  useEffect(() => {
    fetchWarehouseList({ is_active: true, page_size: 50 }, (warehouseList) => {
      const newOptionsList = warehouseList.map((each) => ({
        label: each.name,
        value: each.uuid,
        pincode: each.pincode,
      }));
      setWarehouseOptions(newOptionsList);
      if (warehouseFromURL)
        setSelectedWarehouse(
          newOptionsList?.find((each) => each?.value === warehouseFromURL) ??
            null
        );
    });
  }, []);

  return isActiveStoreWarehouseExist && !isCalculateShipment ? (
    <div className={cx('row w-100', { 'mx-12': !isMobile })}>
      <div className={cx({ 'col-md-6 px12': !isMobile, 'w-100': isMobile })}>
        <div style={{ minWidth: '144px' }}>
          <p className="mb8 c-black-3">Pickup by</p>
          <div className="without-bg-fake-field mb12 text-2 px16 py12 disabled w-100">
            {moment(
              tabOptions?.[tabValue]?.tabChoices[0]?.estimated_pickup_time
            ).format(`${getDay() ? `[${getDay()}], ` : ''}DD MMM`)}
          </div>
        </div>
      </div>
      {isHyperlocal && (
        <div
          className={cx({
            'col-md-6 px12': !isMobile,
            'w-100': isMobile,
          })}
        >
          <div style={{ minWidth: '144px' }}>
            <p className="mb8 c-black-3">Time</p>
            <div className="without-bg-fake-field mb12 px16 py12 text-2 disabled w-100">
              {moment(
                tabOptions?.[tabValue]?.tabChoices[0]?.estimated_pickup_time
              ).format(`LT`)}
            </div>
          </div>
        </div>
      )}
      {/* {PREFERRED_WAREHOUSE_STORES.includes(storeId) && (
        <div
          className={cx({
            'col-md-6 px12': !isMobile,
            'w-100': isMobile,
          })}
        >
          <div style={{ minWidth: '144px' }}>
            <FormCustomSelect
              options={warehouseOptions}
              name="preferred_warehouse"
              labelText="Preferred warehouse"
              value={selectedWarehouse}
              placeholder="Select preferred warehouse"
              onChange={setSelectedWarehouse}
              isSearchable={false}
            />
          </div>
        </div>
      )} */}
    </div>
  ) : (
    <Form
      onSubmit={noop}
      keepDirtyOnReinitialize
      mutators={{ modifyFormField }}
      initialValues={{
        pincode: isActiveStoreWarehouseExist
          ? warehouseOptions[0]?.pincode
          : pincode,
      }}
    >
      {({ errors }) => (
        <div className={cx('d-flex w-100', { pb12: !isMobile })}>
          <div className="w-100" style={{ maxWidth: '400px' }}>
            <div className="d-flex w-100">
              <div className="w-100">
                <FormInput
                  autoFocus
                  disabled={isActiveStoreWarehouseExist}
                  labelText="Pickup pin code"
                  name="pincode"
                  maxLength={6}
                  required
                  showRequired
                  placeholder="eg. 401203"
                  pattern="(^[1-9][0-9]*$|^$)"
                  className="shipment-weight-input m0"
                  validate={validatePincodeFeild()}
                  style={{ width: isMobile ? '100%' : 344 }}
                  error={
                    !priceLoading &&
                    formDirty &&
                    (errors?.pincode || !isDukaanDelivery)
                  }
                  onChange={({ target: { value } }) => {
                    if (value.length >= 6) {
                      setPincode(value);
                    }
                    if (value.length <= 0) {
                      setPincode('0');
                    }
                  }}
                  helpText={
                    isMobile &&
                    !priceLoading &&
                    formDirty &&
                    (errors?.pincode || !isDukaanDelivery) && (
                      <div className="c-red-1">
                        {errors?.pincode || 'Non-serviceable Pincode'}
                      </div>
                    )
                  }
                />
              </div>
              <div>
                {!isMobile &&
                  !isCalculateShipment &&
                  !priceLoading &&
                  formDirty &&
                  (errors?.pincode || !isDukaanDelivery) && (
                    <div
                      className="c-red-1 ml16 mt44 w-100"
                      style={{ width: '200px' }}
                    >
                      {errors?.pincode || 'Non-serviceable Pincode'}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

const OptionsForwardShipmentHeader = ({
  showDeliveryCharge,
  shipmentMode,
  showRadioBtn,
  tabOptions,
  tabValue,
}) => (
  <div className="table-header-wrapper new-shipping-partners-table">
    <span
      className={cx('text-medium', {
        pl44: showRadioBtn,
        pl12: !showRadioBtn,
      })}
    >
      Shipping partner
    </span>
    <span className="text-medium">
      Delivery by <span className="text-normal">(est.)</span>
    </span>
    <span className="text-medium">Shipping charge</span>
  </div>
);

const OptionsReturnReplacementHeader = ({ showRadioBtn }) => (
  <div className="table-header-wrapper replacement-shipping-partner-table">
    <span
      className={cx('text-medium', {
        pl44: showRadioBtn,
        pl12: !showRadioBtn,
      })}
    >
      Shipping partner
    </span>
    <span className="text-medium">
      Pickup by <span className="text-normal">(est.)</span>
    </span>
    <span className="text-medium">
      Delivery by <span className="text-normal">(est.)</span>
    </span>
    <span className="text-medium">Pickup charge</span>
  </div>
);

const OptionsReturnReplacementRows = ({
  tabOptions,
  tabValue,
  showRadioBtn,
  formValues,
  priceLoading,
  showDeliveryCharge = true,
  isActiveStoreWarehouseExist,
  pincode,
  isHyperlocal,
  isPincodeError,
  svgHeight,
  freeDelivery,
  customPincode,
  isCalculateShipment,
  form,
  isDukaanDelivery,
  shipmentMode,
}) => {
  const { isMobile } = useResponsive();
  const { formatLocalMoney, currencySymbol } = useCountry();
  if (tabOptions.length === 0) {
    return null;
  }
  const getDay = (time) => {
    let isSame = moment(new Date()).isSame(time, 'day');
    if (isSame) return 'Today';
    isSame = moment(new Date()).add(1, 'day').isSame(time, 'day');
    if (isSame) return 'Tomorrow';
    return '';
  };
  return tabOptions?.[tabValue]?.tabChoices?.map((row) => {
    const price = row.carrier_service.starting_price_inr;
    const startingPrice = showDeliveryCharge
      ? row.original_delivery_charge
      : price;
    return (
      <div
        className={cx(
          'table-data-row replacement-shipping-partner-table cur-p',
          { 'flex-column': isMobile, 'pb0-i': isHyperlocal }
        )}
        key={row.value}
        onClick={() => {
          if (showRadioBtn) {
            form.mutators.modifyFormField(
              'shippingMode',
              row.carrier_service.id
            );
            form.mutators.modifyFormField('shippingPartner', row.carrier.id);
          }
        }}
      >
        <div
          className={cx('d-flex', {
            pl12: !isMobile,
          })}
          style={{
            ...((!showRadioBtn && { marginLeft: 0 }) || {}),
          }}
        >
          {showRadioBtn && (
            <CustomRadio
              className="align-baseline"
              checked={row.carrier_service.id === formValues.shippingMode}
            />
          )}
          <div
            className={cx('d-flex flex-column', {
              pl4: showRadioBtn,
            })}
          >
            <div className="d-flex align-center" style={partnerImgStyle()}>
              <ImageLoader
                src={row.carrier.logo}
                style={partnerImgStyle(isMobile ? 14 : 16)}
                alt="Shipment logo"
              />
            </div>
            <p className="mt8">
              {isHyperlocal ? (
                <p className="text-4">
                  Max weight: <span className="text-medium">20 kgs</span>
                </p>
              ) : (
                <>{row?.carrier_service?.name}</>
              )}
            </p>
          </div>
        </div>
        <div
          className={cx({
            pl32: isMobile && showRadioBtn,
          })}
          style={{
            minHeight: !isMobile && 48,
          }}
        >
          <p
            className={cx({
              'text-2': !isMobile,
              'text-4 mt4 d-flex align-center': isMobile,
            })}
          >
            {isMobile && 'Delivery by '}
            {isMobile && priceLoading && (
              <CircularProgress
                style={{
                  width: svgHeight,
                  height: svgHeight,
                  marginLeft: 6,
                }}
              />
            )}
            {!isMobile && priceLoading && (
              <div className="d-flex align-center">
                <CircularProgress
                  className="mr6"
                  style={{ width: svgHeight, height: svgHeight }}
                />
              </div>
            )}
            {!priceLoading &&
              ((!showDeliveryCharge && isActiveStoreWarehouseExist) ||
              (!showDeliveryCharge &&
                !isActiveStoreWarehouseExist &&
                String(pincode).length === 6) ||
              (showDeliveryCharge &&
                (String(pincode).length === 6 || formValues?.weight > 0)) ||
              (showDeliveryCharge &&
                isActiveStoreWarehouseExist &&
                isHyperlocal) ? (
                <>
                  {isHyperlocal ? (
                    isPincodeError ? (
                      '-'
                    ) : (
                      <>
                        {moment(row.estimated_pickup_time).format(
                          `${
                            getDay(row.estimated_pickup_time)
                              ? `[${getDay(row.estimated_pickup_time)}], `
                              : ''
                          }`
                        )}
                        {!isMobile && <br />}
                        {moment(row?.estimated_pickup_time)
                          .add(30, 'minute')
                          .format(`hh:mm`)}
                        -
                        {moment(row?.estimated_pickup_time)
                          .add(90, 'minute')
                          .format(`LT`)}
                      </>
                    )
                  ) : isPincodeError ? (
                    '-'
                  ) : (
                    `${moment(row.estimated_delivery_time).format('DD MMM')} ${
                      isCalculateShipment && isMobile ? '(est.)' : ''
                    } `
                  )}
                </>
              ) : (
                '-'
              ))}
          </p>
        </div>
        <div
          className={cx({
            'text-2': !isMobile,
            'mt4 text-8': isMobile,
            pl32: showRadioBtn && isMobile,
          })}
          style={{ minHeight: 20 }}
        >
          {priceLoading && (
            <div className="d-flex align-center">
              <CircularProgress
                className="mr6"
                style={{ width: svgHeight, height: svgHeight }}
              />
            </div>
          )}
          {!priceLoading && (
            <div className="d-flex align-center">
              {getDay(row?.estimated_delivery_time)
                ? `${getDay(row?.estimated_delivery_time)}, `
                : moment(row.estimated_delivery_time).format('DD MMM ')}
              {isHyperlocal &&
                moment(row?.estimated_delivery_time)
                  .add(60, 'minutes')
                  .format(`LT`)}
            </div>
          )}
        </div>
        <div
          className={cx({
            'text-2': !isMobile,
            'mt4 text-8': isMobile,
            pl32: showRadioBtn && isMobile,
          })}
          style={{ minHeight: 20 }}
        >
          {priceLoading && (
            <div className="d-flex align-center">
              <CircularProgress
                className="mr6"
                style={{ width: svgHeight, height: svgHeight }}
              />
            </div>
          )}
          {showDeliveryCharge &&
            !isHyperlocal &&
            !(formValues?.weight > 0) &&
            !priceLoading &&
            `${currencySymbol}-`}
          {((showDeliveryCharge && (formValues?.weight > 0 || isHyperlocal)) ||
            !showDeliveryCharge) &&
            !priceLoading &&
            (freeDelivery ? (
              <div className="d-flex flex-column product-cost text-medium">
                <div className="d-flex align-center">
                  {formatLocalMoney(startingPrice)}
                </div>
              </div>
            ) : (
              <div className="d-flex align-center">
                {(!isActiveStoreWarehouseExist &&
                  customPincode.length >= 6 &&
                  isDukaanDelivery) ||
                isActiveStoreWarehouseExist ? (
                  <>
                    {((!showDeliveryCharge && !isHyperlocal) ||
                      (showDeliveryCharge &&
                        !isActiveStoreWarehouseExist &&
                        !isHyperlocal)) &&
                      !isCalculateShipment &&
                      'from'}{' '}
                    {!isActiveStoreWarehouseExist &&
                    isHyperlocal &&
                    String(pincode).length < 6
                      ? `${currencySymbol}-`
                      : formatLocalMoney(startingPrice)}
                    {isMobile &&
                      !isCalculateShipment &&
                      shipmentMode !== HEAVY_CARRIER_ID && (
                        <span className="c-gray-1 ml4">
                          (
                          {
                            tabOptions[tabValue]?.tabChoices[0]?.carrier_service
                              ?.service_type_json?.rate_text
                          }
                          )
                        </span>
                      )}
                    {isHyperlocal &&
                      (isActiveStoreWarehouseExist ||
                        (!isActiveStoreWarehouseExist &&
                          String(pincode).length === 6)) && (
                        <>
                          <p>*&nbsp;</p>
                          <Tooltip
                            title={HYPERLOCAL_TOOLTIP_TEXT}
                            placement="top"
                            enterDelay={0}
                            enterTouchDelay={0}
                          >
                            <GrayInfoIcon />
                          </Tooltip>
                        </>
                      )}
                  </>
                ) : (
                  `${currencySymbol}-`
                )}
              </div>
            ))}
        </div>
      </div>
    );
  });
};

const NewShippingOptionsTable = ({
  form,
  priceLoading,
  orderShippingDetails,
  isDukaanDelivery,
  setFormInitialValues,
  formValues,
  showRadioBtn = false,
  showDeliveryCharge = false,
  children = '',
  setPincode,
  formDirty = false,
  customPincode = '400001',
  pincode = '',
  title = 'Select shipping partner',
  onTabValueChange = noop,
  isCalculateShipment = false,
  isReplacementReturnShipment,
  warehouseFromURL,
  selectedWarehouse,
  setSelectedWarehouse,
}) => {
  const { isMobile } = useResponsive();
  const { canAvailFreeDukaanDelivery: freeDelivery } = useAppContext();
  const { isActiveStoreWarehouseExist } = useStoreWarehouse();

  const { formatLocalMoney, currencySymbol } = useCountry();
  const { tabs } = orderShippingDetails || {};
  const [tabValue, setTabvalue] = useState(0);
  const [tabOptions, setTabOptions] = useState([]);
  const isHyperlocal = isHyperlocalSelected(
    tabOptions?.[tabValue]?.tabChoices[0]
  );

  const shipmentMode =
    tabOptions[tabValue]?.tabChoices[0]?.carrier_service?.service_type_json?.id;

  const getDay = () => {
    let isSame = moment(new Date()).isSame(
      tabOptions?.[tabValue]?.tabChoices[0]?.estimated_pickup_time,
      'day'
    );
    if (isSame) return 'Today';
    isSame = moment(new Date())
      .add(1, 'day')
      .isSame(
        tabOptions?.[tabValue]?.tabChoices[0]?.estimated_pickup_time,
        'day'
      );
    if (isSame) return 'Tomorrow';
    return '';
  };

  const tabValueChange = (val) => {
    setTabvalue(val);
    setFormInitialValues((prev) => ({
      ...prev,
      shippingMode: tabOptions[val]?.tabChoices[0]?.carrier_service?.id,
      shippingPartner: tabOptions[val]?.tabChoices[0]?.carrier?.id,
    }));
    onTabValueChange(val, tabOptions);
  };

  useEffect(() => {
    if (tabs?.length > 0) {
      const newOptions = tabs?.map(
        (
          { tab_name: tabName, tab_info: tabInfo, tab_rates: tabChoices },
          index
        ) => {
          if (!showDeliveryCharge) {
            tabChoices?.sort((a, b) =>
              a?.carrier_service?.starting_price_inr <
              b?.carrier_service?.starting_price_inr
                ? -1
                : 1
            );
          }
          return {
            label: tabName,
            count: 0,
            value: index,
            helpText: tabInfo,
            tabChoices,
          };
        }
      );
      setTabOptions(newOptions);
      onTabValueChange(tabValue, newOptions);
      if (newOptions?.length - 1 < tabValue) {
        setTabvalue(newOptions?.length - 1);
      }
      setFormInitialValues((prev) => ({
        ...prev,
        shippingMode: newOptions[tabValue]?.tabChoices[0]?.carrier_service?.id,
        shippingPartner: newOptions[tabValue]?.tabChoices[0]?.carrier?.id,
      }));
    }
  }, [tabs]);

  let svgHeight = 20;
  if (isMobile) svgHeight = 16;
  const isPincodeError = !priceLoading && formDirty && !isDukaanDelivery;
  return (
    <div className={cx('new-shipping-partners')} style={{ paddingBottom: 0 }}>
      {freeDelivery && !isReplacementReturnShipment && (
        <div
          className={cx({
            pt12: !isMobile,
            'pt20 px16': isMobile,
          })}
        >
          <div
            className={cx('info-stripe d-flex-c-c', {
              'px16 pt8 pb8 mb4 ': isMobile,
              mb12: !isMobile,
            })}
          >
            <div className="d-flex align-center">
              <div className="d-flex align-center">
                <PercentDiscount />
              </div>
              <span className="ml8 c-black-3 text-medium ">
                Get up to ₹100 off on this delivery.
              </span>
            </div>
          </div>
        </div>
      )}
      {!isReplacementReturnShipment && (
        <ToggleButtonGroup
          options={tabOptions}
          onChange={tabValueChange}
          defaultSelected={0}
          propsSetSelected={tabValue}
          className="sticky-toggle-group bg-white-1"
        />
      )}
      {tabOptions[tabValue]?.helpText && isMobile && (
        <div className="pt4 pb16 mx16 text-italic c-gray-1 text-center">
          {tabOptions[tabValue]?.helpText}
        </div>
      )}
      {!(isHyperlocal && isReplacementReturnShipment) && (
        <div
          className={cx(' align-start pos-rel card', {
            'px24 pt24 pb12 mt12 mb24 d-flex': !isMobile,
            'p16 mx16 mt4 mb16': isMobile,
          })}
        >
          {!isReplacementReturnShipment && (
            <OptionsTableInputs
              isActiveStoreWarehouseExist={isActiveStoreWarehouseExist}
              isCalculateShipment={isCalculateShipment}
              getDay={getDay}
              tabOptions={tabOptions}
              tabValue={tabValue}
              isHyperlocal={isHyperlocal}
              formDirty={formDirty}
              pincode={pincode}
              isDukaanDelivery={isDukaanDelivery}
              priceLoading={priceLoading}
              setPincode={setPincode}
              warehouseFromURL={warehouseFromURL}
              selectedWarehouse={selectedWarehouse}
              setSelectedWarehouse={setSelectedWarehouse}
            />
          )}

          {children}
        </div>
      )}
      <div
        className={cx('card', {
          'px12 pt24': !isMobile,
          'p16 mx16': isMobile,
        })}
        style={{
          marginBottom: isMobile ? 96 : 0,
        }}
      >
        <div
          className={cx('text-2 text-medium', {
            'px12 mb16': !isMobile,
            mb4: isMobile,
          })}
        >
          {title}
        </div>
        {!isMobile &&
          (!isReplacementReturnShipment ? (
            <OptionsForwardShipmentHeader
              shipmentMode={shipmentMode}
              showDeliveryCharge={showDeliveryCharge}
              showRadioBtn={showRadioBtn}
              tabOptions={tabOptions}
              tabValue={tabValue}
            />
          ) : (
            <OptionsReturnReplacementHeader showRadioBtn={showRadioBtn} />
          ))}
        <div className="table-data-container">
          {!isReplacementReturnShipment ? (
            <>
              {tabOptions?.length === 0 && (
                <div className='d-flex-c-c mt16 pb32'>
                  <span>No delivery partners found for the pincode</span>
                </div>
              )}
              {tabOptions?.[tabValue]?.tabChoices?.map((row) => {
                const price = row.carrier_service.starting_price_inr;
                const startingPrice = showDeliveryCharge
                  ? row.original_delivery_charge
                  : price;
                return (
                  <div
                    className={cx(
                      'table-data-row new-shipping-partners-table cur-p',
                      { 'flex-column': isMobile, 'pb0-i': isHyperlocal }
                    )}
                    key={row.value}
                    onClick={() => {
                      if (showRadioBtn) {
                        form.mutators.modifyFormField(
                          'shippingMode',
                          row.carrier_service.id
                        );
                        form.mutators.modifyFormField(
                          'shippingPartner',
                          row.carrier.id
                        );
                      }
                    }}
                  >
                    <div
                      className={cx('d-flex', {
                        pl12: !isMobile,
                      })}
                      style={{
                        ...((!showRadioBtn && { marginLeft: 0 }) || {}),
                      }}
                    >
                      {showRadioBtn && (
                        <CustomRadio
                          className="align-baseline"
                          checked={
                            row.carrier_service.id === formValues.shippingMode
                          }
                        />
                      )}
                      <div
                        className={cx('d-flex flex-column', {
                          pl4: showRadioBtn,
                        })}
                      >
                        <div
                          className="d-flex align-center"
                          style={partnerImgStyle()}
                        >
                          <ImageLoader
                            src={row.carrier.logo}
                            style={partnerImgStyle(isMobile ? 14 : 16)}
                            alt="Shipment logo"
                          />
                        </div>
                        <p className="mt8">
                          {isHyperlocal ? (
                            <p className="text-4">
                              Max weight:{' '}
                              <span className="text-medium">20 kgs</span>
                            </p>
                          ) : (
                            <>{row?.carrier_service?.name}</>
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={cx({
                        pl32: isMobile && showRadioBtn,
                      })}
                      style={{
                        minHeight: !isMobile && 48,
                      }}
                    >
                      <p
                        className={cx({
                          'text-2': !isMobile,
                          'text-4 mt4 d-flex align-center': isMobile,
                        })}
                      >
                        {isMobile && 'Delivery by '}
                        {isMobile && priceLoading && (
                          <CircularProgress
                            style={{
                              width: svgHeight,
                              height: svgHeight,
                              marginLeft: 6,
                            }}
                          />
                        )}
                        {!isMobile && priceLoading && (
                          <div className="d-flex align-center">
                            <CircularProgress
                              className="mr6"
                              style={{ width: svgHeight, height: svgHeight }}
                            />
                          </div>
                        )}
                        {!priceLoading &&
                          ((!showDeliveryCharge &&
                            isActiveStoreWarehouseExist) ||
                          (!showDeliveryCharge &&
                            !isActiveStoreWarehouseExist &&
                            String(pincode).length === 6) ||
                          (showDeliveryCharge &&
                            (String(pincode).length === 6 ||
                              formValues?.weight > 0)) ||
                          (showDeliveryCharge &&
                            isActiveStoreWarehouseExist &&
                            isHyperlocal) ? (
                            <>
                              {isHyperlocal ? (
                                isPincodeError ? (
                                  '-'
                                ) : (
                                  <>
                                    {moment(row.estimated_delivery_time).format(
                                      `${getDay() ? `[${getDay()}], ` : ''}`
                                    )}
                                    {!isMobile && <br />}
                                    {moment(row?.estimated_pickup_time)
                                      .add(30, 'minute')
                                      .format(`hh:mm`)}
                                    -
                                    {moment(row?.estimated_pickup_time)
                                      .add(90, 'minute')
                                      .format(`LT`)}
                                  </>
                                )
                              ) : isPincodeError ? (
                                '-'
                              ) : (
                                `${moment(row.estimated_delivery_time).format(
                                  'DD MMM'
                                )} ${
                                  isCalculateShipment && isMobile
                                    ? '(est.)'
                                    : ''
                                } `
                              )}
                            </>
                          ) : (
                            '-'
                          ))}
                      </p>
                    </div>
                    <div
                      className={cx({
                        'text-2': !isMobile,
                        'mt4 text-8': isMobile,
                        pl32: showRadioBtn && isMobile,
                      })}
                      style={{ minHeight: 20 }}
                    >
                      {priceLoading && (
                        <div className="d-flex align-center">
                          <CircularProgress
                            className="mr6"
                            style={{ width: svgHeight, height: svgHeight }}
                          />
                        </div>
                      )}
                      {showDeliveryCharge &&
                        !isHyperlocal &&
                        !(formValues?.weight > 0) &&
                        !priceLoading &&
                        `${currencySymbol}-`}
                      {((showDeliveryCharge &&
                        (formValues?.weight > 0 || isHyperlocal)) ||
                        !showDeliveryCharge) &&
                        !priceLoading &&
                        (freeDelivery ? (
                          <div className="d-flex flex-column product-cost text-medium">
                            <div className="d-flex align-center">
                              {formatLocalMoney(startingPrice)}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-center">
                            {(!isActiveStoreWarehouseExist &&
                              customPincode.length >= 6 &&
                              isDukaanDelivery) ||
                            isActiveStoreWarehouseExist ? (
                              <>
                                {formatLocalMoney(row?.total_delivery_charge)}
                                {isMobile &&
                                  !isCalculateShipment &&
                                  shipmentMode !== HEAVY_CARRIER_ID && (
                                    <span className="c-gray-1 ml4">
                                      (
                                      {
                                        tabOptions[tabValue]?.tabChoices[0]
                                          ?.carrier_service?.service_type_json
                                          ?.rate_text
                                      }
                                      )
                                    </span>
                                  )}
                                {isHyperlocal &&
                                  (isActiveStoreWarehouseExist ||
                                    (!isActiveStoreWarehouseExist &&
                                      String(pincode).length === 6)) && (
                                    <>
                                      <p>*&nbsp;</p>
                                      <Tooltip
                                        title={HYPERLOCAL_TOOLTIP_TEXT}
                                        placement="top"
                                        enterDelay={0}
                                        enterTouchDelay={0}
                                      >
                                        <GrayInfoIcon />
                                      </Tooltip>
                                    </>
                                  )}
                              </>
                            ) : (
                              `${currencySymbol}-`
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <OptionsReturnReplacementRows
              tabOptions={tabOptions}
              tabValue={tabValue}
              showRadioBtn={showRadioBtn}
              formValues={formValues}
              priceLoading={priceLoading}
              showDeliveryCharge
              isActiveStoreWarehouseExist={isActiveStoreWarehouseExist}
              pincode={pincode}
              isHyperlocal={isHyperlocal}
              isPincodeError={isPincodeError}
              svgHeight={svgHeight}
              freeDelivery={freeDelivery}
              customPincode={customPincode}
              isCalculateShipment={isCalculateShipment}
              form={form}
              isDukaanDelivery={isDukaanDelivery}
              shipmentMode={shipmentMode}
            />
          )}
        </div>
        {isHyperlocal && (
          <div className={cx('pt12', { pb24: !isMobile })}>
            <div
              className={cx('hyperlocal-info-stripe', {
                'mb12  py8 px12 ': isMobile,
                ml12: !isMobile,
              })}
            >
              <div className="d-flex align-center">
                <div className="d-flex align-center">
                  <BlueInfoIcon height={16} width={16} />
                </div>
                <span className={cx('ml8 c-black-3', { 'text-10': isMobile })}>
                  Best serviceable between 10 AM-12 PM &#38; 3:30 PM-7:30 PM.
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewShippingOptionsTable;
