import React, { useEffect } from 'react';
import { trackPage } from '../utils';

const withTracker = (WrappedComponent) => {
  const HOC = (props) => {
    const {
      location: { pathname },
    } = props;

    useEffect(() => trackPage(pathname), [pathname]);
    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
