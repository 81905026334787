export const GINI_AND_JONY_TEMPLATES = [
  {
    templateName: "Boy's Top (TSHIRT- POLO)",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11', '14', '9.5', '9 - 12 Months'],
      ['18M', '11.5', '14.75', '10', '12 - 18 Months'],
      ['24M', '12', '15.5', '10.5', '18 - 24 Months'],
      ['2', '12.5', '16.5', '11', '3 - 4 Years'],
      ['4', '13.5', '18', '12', '4 - 5 Years'],
      ['6', '14.5', '19.5', '13', '5 - 6 Years'],
      ['8', '15.5', '21', '14', '7 - 8 Years'],
      ['10', '16.5', '22.5', '14.5', '9 - 10 Years'],
      ['12', '17.5', '23.8', '15.3', '11 - 12 Years'],
      ['14', '18.3', '25', '16', '13 - 14 Years'],
      ['16', '19', '26.5', '16.8', '15 - 16 Years'],
    ],
  },
  {
    templateName: "Boy's Top (SWEATSHIRTS  HOODIES)",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11.5', '14', '10', '9 - 12 Months'],
      ['18M', '12', '14.75', '10.5', '12 - 18 Months'],
      ['24M', '12.5', '15.5', '11', '18 - 24 Months'],
      ['2', '13.3', '16.5', '11.5', '3 - 4 Years'],
      ['4', '14.3', '18', '12.5', '4 - 5 Years'],
      ['6', '15.5', '20', '13.5', '5 - 6 Years'],
      ['8', '16.8', '21.5', '14.5', '7 - 8 Years'],
      ['10', '18', '23', '15.5', '9 - 10 Years'],
      ['12', '19', '24', '16.3', '11 - 12 Years'],
      ['14', '20', '25', '17', '13 - 14 Years'],
      ['16', '21', '26', '17.8', '15 - 16 Years'],
    ],
  },
  {
    templateName: "Boy's Top (T-SHIRT (Co-ordinates  Sets))",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11', '14', '9.5', '9 - 12 Months'],
      ['18M', '11.5', '14.75', '10', '12 - 18 Months'],
      ['24M', '12', '15.5', '10.5', '18 - 24 Months'],
      ['2', '12.5', '16.5', '11', '3 - 4 Years'],
      ['4', '13.5', '18', '12', '4 - 5 Years'],
      ['6', '14.5', '19.5', '13', '5 - 6 Years'],
      ['8', '15.5', '21', '14', '7 - 8 Years'],
      ['10', '16.5', '22.5', '14.5', '9 - 10 Years'],
      ['12', '17.5', '23.8', '15.3', '11 - 12 Years'],
      ['14', '18.3', '25', '16', '13 - 14 Years'],
      ['16', '19', '26.5', '16.8', '15 - 16 Years'],
    ],
  },
  {
    templateName: "Boy's Top (SHIRT)",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11.50', '  14.25', '9.5', '9 - 12 Months'],
      ['18M', '12.00', '15.25', '10', '12 - 18 Months'],
      ['24M', '12.50', '16.25', '10.5', '18 - 24 Months'],
      ['2', '13', '16.5', '11.5', '3 - 4 Years'],
      ['4', '14', '18', '12.3', '4 - 5 Years'],
      ['6', '15', '20', '13.3', '5 - 6 Years'],
      ['8', '16.3', '21.5', '14.3', '7 - 8 Years'],
      ['10', '17.5', '23', '15.3', '9 - 10 Years'],
      ['12', '18.5', '24', '16.3', '11 - 12 Years'],
      ['14', '19.5', '25', '17.3', '13 - 14 Years'],
      ['16', '20', '26', '17.8', '15 - 16 Years'],
    ],
  },
  {
    templateName: "Boy's Top (SWEATERS)",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11', '  14.2', '9.8', '9 - 12 Months'],
      ['18M', '11.42', '14.9', '10.2', '12 - 18 Months'],
      ['24M', '11.81', '15.7', '10.6', '18 - 24 Months'],
      ['2', '0', '16.5', '0', '3 - 4 Years'],
      ['4', '12.5', '18', '10.5', '4 - 5 Years'],
      ['6', '13.5', '20', '11.5', '5 - 6 Years'],
      ['8', '14.5', '21.5', '12.5', '7 - 8 Years'],
      ['10', '16.5', '23', '14.5', '9 - 10 Years'],
      ['12', '17.5', '24', '15.5', '11 - 12 Years'],
      ['14', '18.5', '25', '16.5', '13 - 14 Years'],
      ['16', '0', '26', '0', '15 - 16 Years'],
    ],
  },
  {
    templateName: "Boy's Top (JACKETS  BLAZERS)",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11.5', '12', '10', '9 - 12 Months'],
      ['18M', '12', '13', '10.5', '12 - 18 Months'],
      ['24M', '12.5', '14', '11', '18 - 24 Months'],
      ['2', '13', '15', '11.5', '3 - 4 Years'],
      ['4', '13.8', '16', '12', '4 - 5 Years'],
      ['6', '14.8', '17.5', '13', '5 - 6 Years'],
      ['8', '15.8', '19', '14', '7 - 8 Years'],
      ['10', '17', '20.5', '15', '9 - 10 Years'],
      ['12', '18', '21.5', '16', '11 - 12 Years'],
      ['14', '19', '22.5', '17', '13 - 14 Years'],
      ['16', '20', '23.5', '18', '15 - 16 Years'],
    ],
  },
  {
    templateName: "Boy's Top (COATS)",
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder', 'Age Group'],
      ['12M', '11.75', '  12.5', '9.75', '9 - 12 Months'],
      ['18M', '12.25', '13.25', '10.25', '12 - 18 Months'],
      ['24M', '12.75', '14', '10.75', '18 - 24 Months'],
      ['2', '14.25', '17.5', '12', '3 - 4 Years'],
      ['4', '15.25', '18.5', '12.75', '4 - 5 Years'],
      ['6', '16.25', '19.5', '13.5', '5 - 6 Years'],
      ['8', '17.25', '20.5', '14.5', '7 - 8 Years'],
      ['10', '18.5', '22', '15.5', '9 - 10 Years'],
      ['12', '19.5', '23.5', '16.5', '11 - 12 Years'],
      ['14', '20.5', '25', '17.5', '13 - 14 Years'],
      ['16', '21.5', '26.5', '18.5', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Boys Bottom (JEANS Fixed Waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '16.5', '12.75', '19'],
      ['20', '', '17', '14.5', '21'],
      ['22', '', '17.5', '16.25', '23'],
      ['24', '', '21.5', '18', '25.5'],
      ['26', '', '22.5', '19.8', '27.5'],
      ['28', '', '23.5', '21.3', '29.5'],
      ['30', '', '24.5', '23', '31.5'],
      ['32', '', '25.5', '24.8', '33.5'],
      ['34', '', '26.5', '26.8', '33.5'],
      ['36', '', '27.5', '28.3', '37.5'],
      ['38', '', '28.5', '30', '39.5'],
      ['40', '', '29.5', '31.8', '41.5'],
    ],
  },
  {
    templateName: 'Boys Bottom (JEANS Elasticated Waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '16.5', '13.00', '19.00'],
      ['20', '', '17.00', '14.75', '21.00'],
      ['22', '', '17.50', '16.50', '23.00'],
      ['24', '', '19', '18', '25.5'],
      ['26', '', '20', '19.75', '27.5'],
      ['28', '', '21', '21.5', '29.5'],
      ['30', '', '22', '23.25', '31.5'],
      ['32', '', '23', '24.75', '33.5'],
      ['34', '', '24', '26.5', '35.5'],
      ['36', '', '25', '28', '37.5'],
      ['38', '', '26', '29.75', '39.5'],
      ['40', '', '26.5', '31.5', '41.5'],
    ],
  },
  {
    templateName: 'Boys Bottom (Joggers Fixed Waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '18', '10.25', '17.5'],
      ['20', '', '18.5', '12', '19.5'],
      ['22', '', '19', '13.75', '21.5'],
      ['24', '', '19', '16.3', '24.5'],
      ['26', '', '20', '18.3', '26.5'],
      ['28', '', '21', '20', '28.5'],
      ['30', '', '22', '21.8', '30.5'],
      ['32', '', '23', '23.3', '32.5'],
      ['34', '', '24', '25', '34.5'],
      ['36', '', '25', '26.5', '36.5'],
      ['38', '', '26', '28.3', '38.5'],
      ['40', '', '27', '29.5', '40'],
    ],
  },
  {
    templateName: 'Boys Bottom (Joggers Elasticated Waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '18', '10.5', '17.5'],
      ['20', '', '18.5', '12.25', '19.5'],
      ['22', '', '19', '13.75', '21.5'],
      ['24', '', '21.5', '16.25', '24.5'],
      ['26', '', '22.5', '18', '26.5'],
      ['28', '', '23.5', '19.75', '28.5'],
      ['30', '', '24.5', '21.5', '30.5'],
      ['32', '', '25.5', '23.25', '32.5'],
      ['34', '', '26.5', '25', '34.5'],
      ['36', '', '27.5', '26.5', '36.5'],
      ['38', '', '28.5', '28.25', '38.5'],
      ['40', '', '29.5', '29.5', '40'],
    ],
  },
  {
    templateName: 'Boys Bottom (Shorts Fixed Waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '16.5', '2.25', '9'],
      ['20', '', '17', '2.50', '9.5'],
      ['22', '', '17.5', '2.75', '10'],
      ['2', '', '18.5', '3.3', '10.5'],
      ['4', '', '19', '3.5', '11'],
      ['6', '', '20', '4.3', '12'],
      ['8', '', '21', '4.8', '13'],
      ['10', '', '23', '5.3', '14'],
      ['12', '', '24', '5.5', '14.8'],
      ['14', '', '25', '6', '15.5'],
      ['16', '', '25.5', '6.5', '16.3'],
    ],
  },
  {
    templateName: 'Boys Bottom (Bermuda - Fixed waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '16.50', '3.50', '10.5'],
      ['20', '', '17.00', '4.25', '11.5'],
      ['22', '', '17.50', '5', '12.5'],
      ['2', '', '20', '6.5', '14'],
      ['4', '', '21', '7.3', '15'],
      ['6', '', '22', '8', '16'],
      ['8', '', '23', '9', '17.5'],
      ['10', '', '24', '10.3', '19'],
      ['12', '', '25', '10.8', '20'],
      ['14', '', '26', '11.3', '21'],
      ['16', '', '26.5', '12.3', '22'],
    ],
  },
  {
    templateName: 'Boys Bottom (Bermuda - Elasticated waist)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '17.50', '3.50', '10.5'],
      ['20', '', '18.00', '4.25', '11.5'],
      ['22', '', '18.50', '5', '12.5'],
      ['2', '', '20', '6.5', '14'],
      ['4', '', '21', '7.3', '15'],
      ['6', '', '22', '8', '16'],
      ['8', '', '23', '9', '17.5'],
      ['10', '', '24', '10.3', '19'],
      ['12', '', '25', '10.8', '20'],
      ['14', '', '26', '11.3', '21'],
      ['16', '', '26.5', '12.3', '22'],
    ],
  },
  {
    templateName: 'Girls Top (Knits Top)',
    values: [
      [
        'Brand size',
        'Chest ',
        'Front Length ',
        'Across Shoulder ',
        'Age Group',
      ],
      ['12M', '10.5', '13', '9', '9 - 12 Months'],
      ['18M', '11', '13.75', '9.5', '12 - 18 Months'],
      ['24M', '11.5', '14.5', '10', '18 - 24 Months'],
      ['2', '12', '15', '10.5', '3 - 4 Years'],
      ['4', '12.5', '16.25', '11', '4 - 5 Years'],
      ['6', '13', '17.5', '11.5', '5 - 6 Years'],
      ['8', '13.5', '17.5', '11.5', '7 - 8 Years'],
      ['10', '14', '20', '12.5', '9 - 10 Years'],
      ['12', '14.75', '21.25', '13.25', '11 - 12 Years'],
      ['14', '15.5', '22.5', '14', '13 - 14 Years'],
      ['16', '26.5', '24', '14.75', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (Woven Top)',
    values: [
      [
        'Brand size',
        'Chest ',
        'Front Length ',
        'Across Shoulder ',
        'Age Group',
      ],
      ['12M', '10', '13', '9', '9 - 12 Months'],
      ['18M', '10.5', '14', '10', '12 - 18 Months'],
      ['24M', '11', '15', '10', '18 - 24 Months'],
      ['2', '11.5', '16', '10.5', '3 - 4 Years'],
      ['4', '12', '17', '11', '4 - 5 Years'],
      ['6', '12.75', '18', '11', '5 - 6 Years'],
      ['8', '13.5', '19', '12', '7 - 8 Years'],
      ['10', '14.5', '20', '12.5', '9 - 10 Years'],
      ['12', '15.25', '21.25', '13', '11 - 12 Years'],
      ['14', '15.75', '22.5', '3.5', '13 - 14 Years'],
      ['16', '16.25', '23.5', '14', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (SWEATSHIRTS | HOODIES)',
    values: [
      [
        'Brand size',
        'Chest ',
        'Front Length ',
        'Across Shoulder ',
        'Age Group',
      ],
      ['12M', '11', '13.50', '9.5', '9 - 12 Months'],
      ['18M', '11.5', '14.25', '10', '12 - 18 Months'],
      ['24M', '12', '15', '10.5', '18 - 24 Months'],
      ['2', '12.5', '15.5', '10.5', '3 - 4 Years'],
      ['4', '13.25', '16.75', '11.25', '4 - 5 Years'],
      ['6', '14', '18', '12', '5 - 6 Years'],
      ['8', '15', '19.25', '12.75', '7 - 8 Years'],
      ['10', '16', '20.5', '13.5', '9 - 10 Years'],
      ['12', '16.75', '21.25', '14.25', '11 - 12 Years'],
      ['14', '17.5', '22.5', '15', '13 - 14 Years'],
      ['16', '18.5', '23.5', '15.5', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (Jacket)',
    values: [
      [
        'Brand size',
        'Chest ',
        'Front Length ',
        'Across Shoulder ',
        'Age Group',
      ],
      ['12M', '11', '12.25', '10', '9 - 12 Months'],
      ['18M', '11.5', '13.25', '10.5', '12 - 18 Months'],
      ['24M', '12', '14.5', '11', '18 - 24 Months'],
      ['2', '12.5', '15.25', '11.5', '3 - 4 Years'],
      ['4', '13', '16.25', '12', '4 - 5 Years'],
      ['6', '13.75', '17.25', '12.5', '5 - 6 Years'],
      ['8', '14.5', '18.25', '13', '7 - 8 Years'],
      ['10', '15.25', '19.5', '13.5', '9 - 10 Years'],
      ['12', '16', '20.25', '14', '11 - 12 Years'],
      ['14', '16.5', '21.25', '14.75', '13 - 14 Years'],
      ['16', '17', '22.25', '15.25', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (Coats)',
    values: [
      [
        'Brand size',
        'Chest ',
        'Front Length ',
        'Across Shoulder ',
        'Age Group',
      ],
      ['12M', '11', '12.25', '10', '9 - 12 Months'],
      ['18M', '11.5', '13.25', '10.5', '12 - 18 Months'],
      ['24M', '12', '14.25', '11', '18 - 24 Months'],
      ['2', '13.75', '17.5', '0', '3 - 4 Years'],
      ['4', '14.5', '18.5', '', '4 - 5 Years'],
      ['6', '15.25', '19.5', '0', '5 - 6 Years'],
      ['8', '16', '20.5', '0', '7 - 8 Years'],
      ['10', '17', '22', '0', '9 - 10 Years'],
      ['12', '17.75', '23.25', '0', '11 - 12 Years'],
      ['14', '18.5', '24.5', '0', '13 - 14 Years'],
      ['16', '19', '25.5', '0', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (SWEATERS)',
    values: [
      [
        'Brand size',
        'Chest ',
        'Front Length ',
        'Across Shoulder ',
        'Age Group',
      ],
      ['12M', '11', '14.2', '9.8', '9 - 12 Months'],
      ['18M', '11.42', '14.9', '10.2', '12 - 18 Months'],
      ['24M', '11.81', '15.7', '10.6', '18 - 24 Months'],
      ['2', '0', '0', '0', '3 - 4 Years'],
      ['4', '12.5', '17', '10.5', '4 - 5 Years'],
      ['6', '13.5', '18', '11.5', '5 - 6 Years'],
      ['8', '14.5', '19', '12.5', '7 - 8 Years'],
      ['10', '15.5', '20', '13.5', '9 - 10 Years'],
      ['12', '16.5', '21', '14.5', '11 - 12 Years'],
      ['14', '17.5', '22', '15.5', '13 - 14 Years'],
      ['16', '0', '0', '0', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (DRESS without Sleeves)',
    values: [
      [
        'Brand size',
        'Waist ',
        'Front Length ',
        'Hips ',
        'Across Shoulder ',
        'Chest ',
        'Age Group',
      ],
      ['12M', '9.25', '14', '22', '8', '9.5', '9 - 12 Months'],
      ['18M', '9.75', '16', '22.75', '8.5', '10', '12 - 18 Months'],
      ['24M', '10.25', '18', '23.5', '9', '10.5', '18 - 24 Months'],
      ['2', '10.75', '18', '23.5', '9', '10.5', '3 - 4 Years'],
      ['4', '11.25', '23', '25.25', '10', '11.5', '4 - 5 Years'],
      ['6', '12', '25', '26.75', '10.5', '12.25', '5 - 6 Years'],
      ['8', '12.75', '27', '28', '11', '13', '7 - 8 Years'],
      ['10', '13.75', '29', '29.5', '11.75', '14', '9 - 10 Years'],
      ['12', '14.75', '30.5', '30.5', '12.5', '14.75', '11 - 12 Years'],
      ['14', '15.25', '32', '31.5', '13.25', '15.25', '13 - 14 Years'],
      ['16', '15.75', '33.5', '32.5', '14', '15.75', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Top (DRESS with sleeves)',
    values: [
      [
        'Brand size',
        'Waist ',
        'Front Length ',
        'Hips ',
        'Across Shoulder ',
        'Chest ',
        'Age Group',
      ],
      ['12M', '9.375', '14', '22', '8.5', '10', '9 - 12 Months'],
      ['18M', '9.75', '16', '22.75', '9', '10.5', '12 - 18 Months'],
      ['24M', '10.25', '18', '23.5', '9.5', '11', '18 - 24 Months'],
      ['2', '10.75', '21', '24', '10', '11.5', '3 - 4 Years'],
      ['4', '11.25', '23', '25.25', '10.5', '12', '4 - 5 Years'],
      ['6', '12', '25', '26.75', '11', '12.75', '5 - 6 Years'],
      ['8', '12.75', '27', '28', '11.5', '13.5', '7 - 8 Years'],
      ['10', '13.75', '29', '29.5', '12.5', '14.5', '9 - 10 Years'],
      ['12', '14.75', '30.5', '30.5', '13.25', '15.25', '11 - 12 Years'],
      ['14', '15.25', '32', '31.5', '14.75', '15.75', '13 - 14 Years'],
      ['16', '15.75', '33.5', '32.5', '15.25', '16.25', '15 - 16 Years'],
    ],
  },
  {
    templateName: 'Girls Bottom (Jeans)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '17', '13', '19'],
      ['20', '', '18', '14.75', '21'],
      ['22', '', '18', '16.5', '23'],
      ['24', '', '21', '17.75', '25'],
      ['26', '', '22', '19.5', '27'],
      ['28', '', '23', '21.25', '29'],
      ['30', '', '24', '22.5', '30.5'],
      ['32', '', '25', '23.75', '32'],
      ['34', '', '26', '25.5', '34'],
      ['36', '', '27', '27.5', '36'],
      ['38', '', '28', '29', '38'],
      ['40', '', '29', '30.75', '40'],
    ],
  },
  {
    templateName: 'Girls Bottom (TROUSERS | LEGGINGS)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '17', '13', '19'],
      ['20', '', '17.5', '14.75', '21'],
      ['22', '', '18', '17', '23'],
      ['24', '', '21', '17.75', '25'],
      ['26', '', '22', '19.5', '27'],
      ['28', '', '23', '21.25', '29'],
      ['30', '', '24', '22.5', '30.5'],
      ['32', '', '25', '23.75', '32'],
      ['34', '', '26', '25.5', '34'],
      ['36', '', '27', '27.5', '36'],
      ['38', '', '28', '29', '38'],
      ['40', '', '29', '30.75', '40'],
    ],
  },
  {
    templateName: 'Girls Bottom (TRACKPANTS)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['18', '', '15', '11.5', '18'],
      ['20', '', '15.5', '13.25', '20'],
      ['22', '', '16', '15', '22'],
      ['24', '', '16.5', '16.75', '24'],
      ['26', '', '17', '18.5', '26'],
      ['28', '', '17.5', '20', '28'],
      ['30', '', '18', '21.625', '30'],
      ['32', '', '19', '23.25', '32'],
      ['34', '', '20', '25', '34'],
      ['36', '', '21', '26.5', '36'],
      ['38', '', '22', '29.25', '38'],
      ['40', '', '23', '30', '40'],
    ],
  },
  {
    templateName: 'Girls Bottom (Shorts)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['12M', '', '17', '1.75', '8'],
      ['18M', '', '17.5', '2', '8.5'],
      ['24M', '', '18', '2.25', '9'],
      ['2', '', '20.5', '2.875', '9'],
      ['4', '', '21.5', '3.125', '9.5'],
      ['6', '', '23', '3.25', '10'],
      ['8', '', '24.5', '3.375', '10.5'],
      ['10', '', '26', '3.5', '11'],
      ['12', '', '27', '3.75', '11.5'],
      ['14', '', '28', '4', '12'],
      ['16', '', '29', '4.25', '12.5'],
    ],
  },
  {
    templateName: 'Girls Bottom (Skirts)',
    values: [
      ['Brand size', 'Age', 'To fit Waist ', 'Inseam length '],
      ['12M', '', '16', '7.75'],
      ['18M', '', '16.5', '8.25'],
      ['24M', '', '17', '8.75'],
      ['2', '', '21.25', '9.25'],
      ['4', '', '22.5', '10'],
      ['6', '', '23.75', '11'],
      ['8', '', '25', '12'],
      ['10', '', '26', '13'],
      ['12', '', '27', '13.75'],
      ['14', '', '28', '14.5'],
      ['16', '', '29', '15.25'],
    ],
  },
  {
    templateName: 'Girls Bottom (DUNGAREE | JUMPSUITS)',
    values: [
      ['Brand size', 'Age', 'Chest ', 'Front Length ', 'Across Shoulder '],
      ['12M', '', '10.5', '13', '9'],
      ['18M', '', '11', '13.75', '9.5'],
      ['24M', '', '11.5', '14.5', '10'],
      ['2', '', '20.25', '16.5', '25'],
      ['4', '', '21.25', '17.5', '26.25'],
      ['6', '', '22.75', '18.25', '27.25'],
      ['8', '', '24.25', '19.25', '30'],
      ['10', '', '25.75', '19.25', '30'],
      ['12', '', '26.75', '21', '32.5'],
      ['14', '', '27.25', '21.75', '33.75'],
      ['16', '', '28.75', '22.75', '35'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (TSHIRT/ POLO)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.5', '11.5', '8'],
      ['6M', '10', '12.25', '8.5'],
      ['9M', '10.5', '13', '9'],
      ['12M', '11', '13.5', '9.5'],
      ['18M', '11.5', '14.25', '10'],
      ['24M', '12', '15', '10.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Shirt)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '10', '11.5', '8.25'],
      ['6M', '10.5', '12.25', '8.75'],
      ['9M', '11', '13.25', '9.25'],
      ['12M', '11.50', '14', '9.5'],
      ['18M', '12', '15', '10'],
      ['24M', '12.75', '16', '10.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (JACKETS | BLAZERS)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '10.25', '10', '8.25'],
      ['6M', '10.75', '10.75', '8.75'],
      ['9M', '11.25', '11.5', '9.25'],
      ['12M', '11.75', '12.5', '9.75'],
      ['18M', '12.25', '13.25', '10.25'],
      ['24M', '12.75', '14', '10.75'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (SWEATSHIRTS | HOODIES)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '10', '11.75', '8.5'],
      ['6M', '10.5', '12.5', '9'],
      ['9M', '11', '13.25', '9.5'],
      ['12M', '11.5', '14', '10'],
      ['18M', '12', '14.75', '10.5'],
      ['24M', '12.5', '15.5', '11'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (COATS)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '10.25', '10', '8.25'],
      ['6M', '10.75', '10.75', '8.75'],
      ['9M', '11.25', '11.5', '9.25'],
      ['12M', '11.75', '12.5', '9.75'],
      ['18M', '12.25', '13.25', '10.25'],
      ['24M', '12.75', '14', '10.75'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Sweaters)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.8', '11.4', '8.7'],
      ['6M', '10.2', '12.2', '9.1'],
      ['9M', '10.6', '13', '9.4'],
      ['12M', '11', '14.2', '9.8'],
      ['18M', '11.42', '14.9', '10.2'],
      ['24M', '11.81', '15.7', '10.6'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (JEANS - Back Elasticated Waist)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '13', '6.25', '12'],
      ['6M', '13.50', '7.5', '14'],
      ['9M', '14', '8.75', '15'],
      ['12M', '15', '9.75', '16.5'],
      ['18M', '16', '11', '18'],
      ['24M', '16', '12.25', '19.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (JEANS - Full Elasticated Waist)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '14', '6.3', '12'],
      ['6M', '14.5', '7.5', '13.5'],
      ['9M', '15', '8.8', '15'],
      ['12M', '15.5', '9.8', '16.5'],
      ['18M', '16', '11', '18'],
      ['24M', '16.5', '12.3', '19.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Trouser - Elasticated Waist)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '13', '6.25', '12'],
      ['6M', '13.5', '7.5', '13.5'],
      ['9M', '14', '8.75', '15'],
      ['12M', '14.5', '9.75', '16.5'],
      ['18M', '15.5', '11', '18'],
      ['24M', '16', '12.25', '19.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Trouser - Full Elasticated Waist)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '14', '6.3', '12'],
      ['6M', '14.5', '7.5', '13.5'],
      ['9M', '15', '8.8', '15'],
      ['12M', '15.5', '9.8', '16.5'],
      ['18M', '16', '11', '18'],
      ['24M', '16.5', '12.3', '19.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Short)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '15', '1.5', '7.5'],
      ['6M', '15.5', '1.75', '8'],
      ['9M', '16', '2', '8.5'],
      ['12M', '16.5', '2.25', '9'],
      ['18M', '17', '2.50', '9.5'],
      ['24M', '17.5', '2.75', '10'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Bermuda)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '14.5', '2.8', '8.3'],
      ['6M', '15', '3.3', '9'],
      ['9M', '15.5', '3.8', '9.8'],
      ['12M', '16', '4.3', '10.5'],
      ['18M', '16.5', '4.5', '11.3'],
      ['24M', '17.0', '5.5', '12.3'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Category)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '13.5', '6.25', '12'],
      ['6M', '14', '8', '14'],
      ['9M', '14.5', '9.75', '16'],
      ['12M', '15', '11.5', '18'],
      ['18M', '15.5', '13.25', '20'],
      ['24M', '16', '15', '22'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Dungree-Tshirts)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.5', '11.5', '8'],
      ['6M', '10', '12.3', '8.5'],
      ['9M', '10.5', '13', '9'],
      ['12M', '11', '13.5', '9.5'],
      ['18M', '11.5', '14.3', '10'],
      ['24M', '12', '15', '10.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Full Dungree)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '18.8', '7.3', '21.5'],
      ['6M', '19.8', '8.5', '22.5'],
      ['9M', '20.8', '9.8', '25'],
      ['12M', '21.8', '10.8', '26.8'],
      ['18M', '22.8', '11.8', '28.5'],
      ['24M', '23.8', '13', '30.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (T-Shirt/POLO Co-ordinates / Sets)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.5', '11.5', '8'],
      ['6M', '10', '12.3', '8.5'],
      ['9M', '10.5', '13', '9'],
      ['12M', '11.0', '13.5', '9.5'],
      ['18M', '11.5', '14.3', '10'],
      ['24M', '12', '15', '10.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Shirts)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '10', '11.5', '8.3'],
      ['6M', '10.5', '12.3', '8.8'],
      ['9M', '11', '13.3', '9.3'],
      ['12M', '11.5', '14', '9.5'],
      ['18M', '12', '15', '10'],
      ['24M', '12.8', '16', '10.5'],
    ],
  },
  {
    templateName: 'GJ Baby Boys (Full Bottom)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '13', '6.3', '12'],
      ['6M', '13.5', '7.5', '13.5'],
      ['9M', '14', '8.8', '15'],
      ['12M', '14.5', '9.8', '16.5'],
      ['18M', '15.5', '11', '18'],
      ['24M', '16', '12.3', '19.5'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Knits Top)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9', '11', '7.5'],
      ['6M', '9.5', '11.75', '8'],
      ['9M', '10', '12.5', '8.5'],
      ['12M', '10.5', '13.00', '9'],
      ['18M', '11', '13.75', '9.5'],
      ['24M', '11.50', '14.5', '10'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Woven Top)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.38', '10.75', '8'],
      ['6M', '9.75', '11.5', '8.25'],
      ['9M', '10.25', '12.25', '8.5'],
      ['12M', '10.50', '13.25', '9'],
      ['18M', '11.00', '14.25', '9.5'],
      ['24M', '11.5', '15.25', '10.0'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (SWEATSHIRTS | HOODIES)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.5', '11.25', '8'],
      ['6M', '10', '12', '8.5'],
      ['9M', '10.5', '12.75', '9'],
      ['12M', '11', '13.50', '9.5'],
      ['18M', '11.5', '14.25', '10'],
      ['24M', '12', '15', '10.5'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Jacket)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.5', '9.25', '8.5'],
      ['6M', '10', '10.25', '9'],
      ['9M', '10.5', '11.25', '9.5'],
      ['12M', '11', '12.25', '10'],
      ['18M', '11.5', '13.25', '10.5'],
      ['24M', '12', '14.25', '11'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Coats)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.5', '9.25', '8.5'],
      ['6M', '10', '10.25', '9'],
      ['9M', '10.5', '11.25', '9.5'],
      ['12M', '11', '12.25', '10'],
      ['18M', '11.5', '13.25', '10.5'],
      ['24M', '12', '14.25', '11'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (SWEATERS)',
    values: [
      ['Brand size', 'Chest', 'Front Length', 'Across Shoulder'],
      ['3M', '9.8', '11.4', '8.7'],
      ['6M', '10.2', '12.2', '9.1'],
      ['9M', '10.6', '13', '9.4'],
      ['12M', '11', '14.2', '9.8'],
      ['18M', '11.42', '14.9', '10.2'],
      ['24M', '11.81', '15.7', '10.6'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Jeans)',
    values: [
      ['Brand size', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['3M', '14', '5.5', '10.5'],
      ['6M', '14.5', '6.75', '12'],
      ['9M', '15', '8', '13.5'],
      ['12M', '16', '9.5', '15'],
      ['18M', '16.5', '10.75', '16.5'],
      ['24M', '17', '12', '18'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (TROUSERS | LEGGINGS)',
    values: [
      ['Brand size', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['3M', '14', '5.50', '10.5'],
      ['6M', '14.50', '6.75', '12'],
      ['9M', '15', '8.00', '13.5'],
      ['12M', '16', '9.50', '15'],
      ['18M', '16.5', '10.75', '16.5'],
      ['24M', '17', '12', '18'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (TRACKPANTS)',
    values: [
      ['Brand size', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['3M', '14.50', '6.25', '12.00'],
      ['6M', '14.50', '7.50', '13.50'],
      ['9M', '15.00', '8.75', '15.00'],
      ['12M', '15.50', '10.25', '16.50'],
      ['18M', '16.00', '11.50', '18.00'],
      ['24M', '16.50', '12.75', '19.50'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Shorts)',
    values: [
      ['Brand size', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['3M', '15.5', '1', '6.5'],
      ['6M', '16', '1.25', '7'],
      ['9M', '16.5', '1.5', '7.5'],
      ['12M', '17', '1.75', '8'],
      ['18M', '17.5', '2', '8.5'],
      ['24M', '18', '2.25', '9'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Skirt)',
    values: [
      ['Brand size', 'To fit Waist ', 'Full length '],
      ['3M', '14.5', '6.25'],
      ['6M', '15', '6.75'],
      ['9M', '15.5', '7.25'],
      ['12M', '16', '7.75'],
      ['18M', '16.5', '8.25'],
      ['24M', '17', '8.75'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (DRESS without Sleeves)',
    values: [
      ['Brand size', 'Waist ', 'Front Length', 'Across Shoulder', 'Chest'],
      ['3M', '9.13', '14.5', '8.25', '9.13'],
      ['6M', '9.5', '15.5', '8.50', '9.5'],
      ['9M', '10.00', '17.00', '8.75', '10'],
      ['12M', '10.25', '18.5', '9.25', '10.25'],
      ['18M', '10.75', '20', '9.75', '10.75'],
      ['24M', '11.25', '21.5', '10.25', '11.25'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (DRESS with sleeves)',
    values: [
      ['Brand size', 'Waist ', 'Front Length', 'Across Shoulder', 'Chest'],
      ['3M', '9.13', '14.5', '8', '9.13'],
      ['6M', '9.5', '15.5', '8.25', '9.5'],
      ['9M', '10', '17', '8.5', '10'],
      ['12M', '10.25', '18.5', '9', '10.25'],
      ['18M', '10.75', '20', '9.50', '10.75'],
      ['24M', '11.25', '21.5', '10', '11.25'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Dungree-Top)',
    values: [
      ['Brand size', 'Chest ', 'Front Length ', 'Across Shoulder '],
      ['3M', '9', '11', '7.5'],
      ['6M', '9.50', '11.75', '8'],
      ['9M', '10.00', '12.50', '8.5'],
      ['12M', '10.50', '13', '9'],
      ['18M', '11.00', '13.75', '9.5'],
      ['24M', '11.50', '14.50', '10'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Dungree)',
    values: [
      ['Brand size', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['3M', '18.75', '7.25', '21.5'],
      ['6M', '19.75', '8.50', '22.5'],
      ['9M', '20.75', '9.75', '25'],
      ['12M', '21.75', '10.75', '26.75'],
      ['18M', '22.75', '11.75', '28.5'],
      ['24M', '23.75', '13.00', '30.5'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Dungree-Top)',
    values: [
      ['Brand size', 'Chest ', 'Front Length ', 'Across Shoulder '],
      ['3M', '9', '11', '7.5'],
      ['6M', '9.50', '11.75', '8'],
      ['9M', '10.00', '12.50', '8.5'],
      ['12M', '10.50', '13', '9'],
      ['18M', '11.00', '13.75', '9.5'],
      ['24M', '11.50', '14.50', '10'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Co-ordinates / Sets Top)',
    values: [
      ['Brand size', 'Chest ', 'Front Length ', 'Across Shoulder '],
      ['3M', '9.00', '11.00', '7.50'],
      ['6M', '9.50', '11.75', '8.00'],
      ['9M', '10.00', '12.50', '8.50'],
      ['12M', '10.50', '13.00', '9.00'],
      ['18M', '11.00', '13.75', '9.50'],
      ['24M', '11.50', '14.50', '10.00'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Co-ordinates / Sets Woven Top)',
    values: [
      ['Brand size', 'Chest ', 'Front Length ', 'Across Shoulder '],
      ['3M', '9.38', '10.75', '8.00'],
      ['6M', '9.75', '11.50', '8.25'],
      ['9M', '10.25', '12.25', '8.50'],
      ['12M', '10.50', '13.25', '9.00'],
      ['18M', '11.00', '14.25', '9.50'],
      ['24M', '11.5', '15.25', '10.00'],
    ],
  },
  {
    templateName: 'GJ Baby Girls (Full Bottoms)',
    values: [
      ['Brand size', 'To fit Waist ', 'Inseam length ', 'Full length '],
      ['3M', '13.00', '6.25', '12.00'],
      ['6M', '13.50', '7.50', '13.50'],
      ['9M', '14.00', '8.75', '15.00'],
      ['12M', '14.50', '9.75', '16.50'],
      ['18M', '15.50', '11.00', '18.00'],
      ['24M', '16.00', '12.25', '19.50'],
    ],
  },
];

export const DEFAULT_TEMPLATES = [
  {
    templateName: 'Men’s Topwear (Tshirts/Shirts)',
    values: [
      ['Size', 'Chest ', 'Length ', 'Shoulder '],
      ['S', '30', '15', '12'],
      ['M', '32', '16', '13'],
      ['L', '34', '17', '14'],
      ['Xl', '36', '18', '15'],
    ],
  },
  {
    templateName: 'Men’s Bottomwear (Trousers/Shorts)',
    values: [
      ['Size', 'Waist ', 'Inseam '],
      ['S', '30', '15'],
      ['M', '32', '15'],
      ['L', '33', '15'],
      ['XL', '34', '15'],
    ],
  },
  {
    templateName: 'Women’s Topwear (Tshirts/Shirts)',
    values: [
      ['Size', 'Chest ', 'Length ', 'Shoulder '],
      ['S', '30', '15', '12'],
      ['M', '32', '16', '13'],
      ['L', '34', '17', '14'],
      ['Xl', '36', '18', '15'],
    ],
  },
  {
    templateName: 'Women’s Bottomwear (Trousers/Shorts)',
    values: [
      ['Size', 'Waist ', 'Inseam '],
      ['S', '30', '15'],
      ['M', '32', '15'],
      ['L', '33', '15'],
      ['XL', '34', '15'],
    ],
  },
  {
    templateName: 'Kids’ Clothing',
    values: [
      ['Age group', 'Chest ', 'Length '],
      ['0-6 months', '5', '7'],
      ['6-8 months', '6', '8'],
      ['8-12 months', '7', '9'],
      ['1-2 yrs', '7', '10'],
    ],
  },
  {
    templateName: 'Footwear',
    values: [
      ['US', 'Footlength'],
      ['5', '24'],
      ['6', '25'],
      ['7', '26'],
      ['8', '27'],
    ],
  },
  {
    templateName: 'Rings',
    values: [
      ['Ring size', 'Diameter'],
      ['5', '24'],
      ['6', '25'],
      ['7', '26'],
      ['8', '27'],
    ],
  },
];

export const CREATE_YOUR_OWN_TEMPLATE = {
  templateName: 'Create custom',
  values: [
    ['Title 1', 'Title 2'],
    ['Value 1', 'Value 1'],
    ['Value 2', 'Value 2'],
    ['Value 3', 'Value 3'],
  ],
};

export const CUSTOM_IDS = {
  product: 1,
  category: 0,
};

export const giniAndJonyStoreId = '6ae30772-7df0-44e0-925b-5d268993de5d';

export const measurementOptionsList = [
  {
    labelText: 'in',
    value: 'inches',
  },
  {
    labelText: 'cm',
    value: 'centimeters',
  },
  {
    labelText: 'mm',
    value: 'millimeters',
  },
];

export const DEFAULT_MEASUREMENT_UNIT = 'in'
