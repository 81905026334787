import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import { formatDate } from '../utils/date';
import { useAppContext } from '../context/AppContext';
import { useCountry } from '../context/CountryProvider';
import { useSubscription } from '../context/SubscriptionProvider';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import { useRazorpayCheckout } from '../Subscription/NewSubscription/PaymentButtons/RazorpayButton';
import { INVOICE_PAYMENT_URL } from '../ApiUrls';
import { DukaanIcon } from '../SvgIcon';
import { EVENT_ACC_SIGNOUT } from '../events';
import { logoutUser } from '../utils/user';
import {
  INVOICE_ITEMS_MAP,
  INVOICE_ITEM_TYPES,
} from '../Subscription/constants';
import { accountUrls } from '../Urls';
import SpinnerButton from '../shared/SpinnerButton';
import { TrackEvent } from '../utils/analytics';

const InvoiceContent = ({ type = 'modal' }) => {
  const { business, userDetails } = useAppContext();
  const { formatLocalMoney, currencyCode } = useCountry();

  const history = useHistory();

  const { name: storeName } = business;
  const { mobile } = userDetails;

  const { dueInvoiceData } = useSubscription();
  const { enqueueSnackbar } = useCustomSnackbar();

  const openCheckout = useRazorpayCheckout({
    url: INVOICE_PAYMENT_URL(dueInvoiceData?.uuid),
    payload: {},
    enqueueSnackbar,
  });

  const handleClickPay = () => {
    const config = {
      name: 'Dukaan Invoice',
      currency: currencyCode,
      description: `Payment for Dukaan Invoice`,
      prefill: {
        name: storeName,
        email: 'orders@mydukaan.io',
        contact: mobile,
      },
      handler: () => {
        window.location.reload();
      },
    };
    openCheckout(config);
  };

  const invoicePastDueDate = moment(dueInvoiceData?.due_date).isBefore(
    moment()
  );

  if (dueInvoiceData?.status !== 'pending') {
    return (
      <div className="card p24">
        <p className="section-text-5">Outstanding bill</p>

        <div className="d-flex-c-c text-center mt16 ">
          <p className="c-black-3">You don't have any bills to clear.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={cx({ 'card p24': type === 'card' })}>
      {type === 'modal' && (
        <>
          <div className="d-flex-c-s mb40">
            <DukaanIcon alt="dukaan" height={56} />
            {invoicePastDueDate && (
              <span
                className="section-text-5 underline cur-p"
                onClick={() => {
                  TrackEvent(EVENT_ACC_SIGNOUT, business);
                  logoutUser();
                }}
              >
                Sign out
              </span>
            )}
          </div>

          <div className="d-flex-c-s mb24">
            <p className="section-text-11">
              {invoicePastDueDate
                ? 'Pay your dues!'
                : 'Your latest bill is here!'}
            </p>
            <div className="plan-expired-badge">
              <span className="section-text-5 text-white">
                Due date: {formatDate(dueInvoiceData?.due_date, 'D MMM, YYYY')}
              </span>
            </div>
          </div>
        </>
      )}
      {type === 'card' && (
        <div className="d-flex-c-s align-center mb16">
          <p className="section-text-5">Outstanding bill</p>
          {dueInvoiceData?.status === 'pending' && (
            <p className="section-text-5 c-red-1">
              Due date: {formatDate(dueInvoiceData?.due_date, 'D MMM, YYYY')}
            </p>
          )}
        </div>
      )}
      <div>
        <p className="section-text-5 mb2">Duration:</p>
        <p className="c-black-3 text-2">
          {formatDate(dueInvoiceData?.start_date, 'D MMM, YYYY')} -{' '}
          {formatDate(dueInvoiceData?.end_date, 'D MMM, YYYY')}
        </p>
      </div>
      <div className="divider-dashed my16" />
      {dueInvoiceData?.invoice_items?.map((it) => (
        <div className="mb8" key={it.uuid}>
          <div className="d-flex-c-s align-center text-2">
            <p>
              {INVOICE_ITEMS_MAP[it.type]}
              {it.type === INVOICE_ITEM_TYPES.ORDER_TRANSACTION_FEES && (
                <span
                  className="anchor-1 text-medium underline ml12"
                  onClick={() =>
                    history.push(
                      accountUrls.transactionFeeForOrders.replace(
                        ':uuid',
                        it.uuid
                      ),
                      {
                        amount: it.total_amount,
                        start_date: dueInvoiceData?.start_date,
                        end_date: dueInvoiceData?.end_date,
                        due_date: dueInvoiceData?.due_date,
                      }
                    )
                  }
                >
                  View orders
                </span>
              )}
            </p>
            <span>{formatLocalMoney(Number(it.total_amount))}</span>
          </div>
        </div>
      ))}
      <div className="mb8">
        <div className="d-flex-c-s align-center text-2">
          <p>Tax</p>
          <span>{formatLocalMoney(Number(dueInvoiceData?.total_tax))}</span>
        </div>
      </div>
      <div className="divider-dashed my16" />
      <div className="d-flex-c-s align-center mb32">
        <p className="section-text-4">Total</p>
        <p className="section-text-11">
          {formatLocalMoney(dueInvoiceData?.total_invoice_amount)}
        </p>
      </div>
      <SpinnerButton
        label={`Pay ${formatLocalMoney(dueInvoiceData?.total_invoice_amount)}`}
        onClick={handleClickPay}
      />
    </div>
  );
};

export default InvoiceContent;
