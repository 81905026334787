import React, { useEffect, useState } from 'react';
import useModal from '../../../hooks/useModal';
import { FormInput } from '../../../shared/Form';
import SelectAttributeModal from './SelectAttributeModal';

const SingleSelectRadioAttribute = ({ field = {}, values = {}, form }) => {
  const {
    field_name: name,
    field_label: label = '',
    field_meta: fieldMeta,
    metafield_id: metafieldId,
  } = field ?? {};

  const { isOpen, openModal, closeModal } = useModal();
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    const metaFieldName = name.substring(name.indexOf('.') + 1);
    const formattedSelectedOptions = values?.metafields?.[metaFieldName];
    setSelectedOption(
      formattedSelectedOptions ? [formattedSelectedOptions] : []
    );
  }, [values]);

  return (
    <div>
      <FormInput
        labelText={label || name}
        name={name}
        label={label}
        placeholder="Select options"
        onClick={openModal}
        className="mb0"
        readOnly
      />
      {isOpen && (
        <SelectAttributeModal
          open={isOpen}
          closeModal={closeModal}
          options={fieldMeta?.options}
          selectedOptions={selectedOption}
          setSelectedOptions={setSelectedOption}
          name={name}
          onSelectClick={(val) => {
            form.mutators.modifyFormField(name, val?.[0]);
            closeModal();
          }}
          metafieldId={metafieldId}
          isRadioSelect
        />
      )}
    </div>
  );
};

export default SingleSelectRadioAttribute;
