import { useState, useEffect } from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import useEnterPressAction from '../../../../hooks/useEnterPressAction';
import useScrollingRef from '../../../../hooks/useScrollingRef';
import BounceLoader, {
  BounceLoaderSmall,
} from '../../../../shared/BounceLoader';
import CheckboxField from '../../../../shared/CheckboxField';
import Modal from '../../../../shared/Modal';
import SearchFilter from '../../../../shared/SearchFilter';
import SpinnerButton from '../../../../shared/SpinnerButton';
import EachProduct from './EachProduct';
import EachSku from './EachSku';
import { noop } from '../../../../utils';

const SelectProductsModal = ({
  open,
  closeModal,
  selected = [],
  classNames = {
    modal: '',
  },
  paperClass = 'sm',
  setSelectedProducts,
  productsProps,
  conditionOnSelectedProducts = false,
  selectedProductsCondition = noop,
  skuMode = false,
  loading = false,
}) => {
  const {
    products,
    loading: productsLoading,
    hasMore,
    pageNumber,
    setPageNumber,
    onSearch: onProductSearch,
  } = productsProps;

  const loadingRef = useScrollingRef(productsLoading, hasMore, setPageNumber);

  const [productOptions, setProductOptions] = useState(selected);

  const handleSkuSelect = (event, product, sku) => {
    const isChecked = event.target.checked;
    const newSkuObject = [];
    if (isChecked) {
      if (Array.isArray(sku)) {
        sku.forEach((item) => {
          if (item.inventory !== 0) {
            // only if inventory greater not 0 (out of stock)
            newSkuObject.push({
              sku: item.uuid,
              product: {
                name: product.name,
                base_qty: product.base_qty,
                unit: item.unit || product.unit,
                image: item?.all_images?.[0] || product.all_images?.[0] || '',
              },
              ...item,
              attributes: item?.attributes || [],
              quantity: 1,
              product_key: product.uuid,
            });
          }
        });
      } else
        newSkuObject.push({
          sku: sku.uuid,
          product: {
            name: product.name,
            base_qty: product.base_qty,
            unit: sku.unit || product.unit,
            image: sku?.all_images?.[0] || product.all_images?.[0] || '',
          },
          ...sku,
          attributes: sku?.attributes || [],
          quantity: 1,
          product_key: product.uuid,
        });
      const finalOptions = [...productOptions, ...newSkuObject];
      setProductOptions(finalOptions);
    } else {
      let tempUpdatedProductOptions = [...productOptions];
      let updatedProductOptions = [];
      if (Array.isArray(sku)) {
        sku.forEach((item) => {
          tempUpdatedProductOptions = [
            ...tempUpdatedProductOptions.filter(
              (productOption) => productOption.sku !== item.uuid
            ),
          ];
        });
      }
      if (skuMode) {
        updatedProductOptions = tempUpdatedProductOptions.filter(
          (productOption) => productOption.id !== sku.id
        );
      } else {
        updatedProductOptions = tempUpdatedProductOptions.filter(
          (productOption) => productOption.sku !== sku.uuid
        );
      }
      setProductOptions(updatedProductOptions);
    }
  };

  const handleSelectClick = () => {
    if (conditionOnSelectedProducts) return selectedProductsCondition();
    setSelectedProducts(productOptions);
    closeModal(productOptions);
  };

  useEffect(() => {
    // if some skus are updated, listen to this and call setSelectedProducts with correct payload
    setSelectedProducts(productOptions);
  }, [productOptions]);

  return (
    <Modal
      open={open}
      closeModal={() => closeModal(selected)}
      paperClass={cx('sm', paperClass, classNames.modal)}
    >
      <p className="section-text-4">Select products</p>

      <SearchFilter
        maxLength={120}
        placeholder="Search products..."
        className="mt12 full-w"
        onFilter={onProductSearch}
        autoFocus
      />

      <hr className="hr-line mt16 mb20" />
      {products.length ? (
        <form>
          <div className="select-products-list show-scrollbar">
            {productsLoading && !hasMore ? (
              <BounceLoader inline />
            ) : (
              <>
                {products.map((product, index) => {
                  const hasSkus = Boolean(product?.skus?.length);
                  const productSkus = hasSkus
                    ? product?.skus?.filter((item) =>
                        Boolean(item.attributes.length)
                      )
                    : [];
                  const isProductFullCheck = () => {
                    if (productSkus.length > 0) {
                      return productSkus.some((sku) =>
                        productOptions.some((item) => item.sku === sku.uuid)
                      );
                    }
                    if (skuMode) {
                      return productOptions.find(
                        (item) => item.id === product.skus[0].id
                      );
                    }
                    return productOptions.find(
                      (item) => item.sku === product.skus[0].uuid
                    );
                  };
                  const isProductHalfChecked = () => {
                    if (productSkus.length > 0) {
                      const selectedSKUS = productOptions.filter(
                        (item) => item.product_key === product.uuid
                      );
                      return (
                        selectedSKUS.length < productSkus.length &&
                        selectedSKUS.length > 0
                      );
                    }
                    return false;
                  };

                  return (
                    <>
                      <div className="parent-product-grid">
                        <div className="product-grid">
                          <Field
                            name={`checkbox-${product.label}`}
                            key={index}
                            type="checkbox"
                          >
                            {(fieldProps) => (
                              <CheckboxField
                                {...fieldProps.input}
                                onChange={(event) =>
                                  handleSkuSelect(
                                    event,
                                    product,
                                    productSkus.length > 0
                                      ? productSkus
                                      : product.skus[0]
                                  )
                                }
                                checked={isProductFullCheck(product)}
                                isHalfChecked={isProductHalfChecked(product)}
                                label={
                                  <EachProduct
                                    key={product.label}
                                    product={product}
                                    hasAttributes={Boolean(productSkus.length)}
                                  />
                                }
                                hideCheckbox={
                                  skuMode && Boolean(productSkus.length)
                                }
                                labelPlacement="start"
                                className={cx('d-flex gap16 full-w', {
                                  'd-none': true,
                                })}
                              />
                            )}
                          </Field>
                        </div>
                        {productSkus.length > 0 && (
                          <div className="sub-product-grid">
                            {productSkus.map((sku, skuIndex) => (
                              <div className="product-grid" key={sku.uuid}>
                                <Field
                                  name={`checkbox-${sku.uuid}`}
                                  key={skuIndex}
                                  type="checkbox"
                                >
                                  {(fieldProps) => (
                                    <CheckboxField
                                      {...fieldProps.input}
                                      onChange={(event) =>
                                        handleSkuSelect(event, product, sku)
                                      }
                                      checked={productOptions.some((item) =>
                                        skuMode
                                          ? item.id === sku.id
                                          : item.sku === sku.uuid
                                      )}
                                      label={<EachSku sku={sku} />}
                                      labelPlacement="start"
                                      className="d-flex gap16 full-w"
                                    />
                                  )}
                                </Field>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </>
            )}

            <div ref={loadingRef}>
              {productsLoading && pageNumber > 1 && <BounceLoaderSmall inline />}
            </div>
          </div>
        </form>
      ) : (
        <p className="d-flex align-center justify-center">No products found</p>
      )}

      <hr
        className={cx('hr-line mb16', {
          mt8: products.length,
          mt20: products.length === 0,
        })}
      />

      <div className="text-center mt16">
        <SpinnerButton
          className="btn-primary-4"
          disabled={!productOptions.length || !products.length || loading}
          onClick={handleSelectClick}
        >
          Select {productOptions.length === 1 ? 'product' : 'products'}
        </SpinnerButton>
      </div>
    </Modal>
  );
};

export default SelectProductsModal;
