import React from 'react';
import cx from 'classnames';
import { TickIcon, ShipmentCancelIcon } from '../../SvgIcon';
import useResponsive from '../../hooks/useResponsive';

export const VerticalStep = ({
  title,
  desc,
  isCompleted,
  isActive,
  isCancelled,
}) => {
  const { isMobile } = useResponsive();
  const getIcon = () => {
    const size = isMobile ? '12px' : '16px';

    if (isCancelled) return <ShipmentCancelIcon width={size} height={size} />;

    if (isCompleted) return <TickIcon width={size} height={size} />;

    if (isActive) return <div className="stepper-dot" />;

    return '';
  };
  return (
    <div
      className={cx('stepper-step', {
        'stepper-pending': isActive,
        'stepper-completed': isCompleted,
      })}
    >
      <div className="stepper-indicator">
        <span
          className={cx('stepper-info', {
            'stepper-danger': isCancelled,
          })}
        >
          {getIcon()}
        </span>
      </div>
      <div className="stepper-label">
        <div
          className={cx('stepper-title section-text-7', {
            'text-completed': isCompleted,
            'text-pending': isActive,
          })}
        >
          {title}
        </div>
        {desc ? <div className="stepper-desc text-10">{desc}</div> : null}
      </div>
    </div>
  );
};

const VeticalStepper = ({ children }) => (
  <div className="stepper stepper-vertical">{children}</div>
);

export default VeticalStepper;
